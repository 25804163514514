import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { useDispatchGetNextPossibleMontageDate } from '../../../Redux/Action/CalendarAction';
import Panel from '../../Panel';
import { formatDate } from '../../../Library/Functions';
import ModalDialog from '../../ModalDialog';
import { useDispatchUpdateDate } from '../../../Redux/Action/ObjectAction';

/**
 * ShowInternalNextMontageDates()
 * @param props
 * @constructor
 */
export default function ShowInternalNextMontageDates(props) {
  const {
    order, object, visible,
  } = props;
  const dispatchUpdateDate = useDispatchUpdateDate();
  const dispatchGetNextPossibleDates = useDispatchGetNextPossibleMontageDate();

  const [dates, setDates] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [loadingData, setLoadingData] = React.useState(false);

  const callMontageDates = React.useCallback(() => {
    if (!object.montageDate.date && order.internal && visible && object.montageDate.executionTime) {
      setLoadingData(true);
      dispatchGetNextPossibleDates(
        object.montageDate.executionTime,
        object.montageDate.startTime,
      ).then((response) => {
        setDates([...response.slice(0, 10)]);
        setLoadingData(false);
      }).catch(() => {
        setLoadingData(false);
        setDates([]);
      });
    }
  }, [object.montageDate, dispatchGetNextPossibleDates, order.internal, visible]);

  React.useEffect(() => {
    callMontageDates();
  }, [callMontageDates]);

  const renderLoading = () => {
    if (loadingData) {
      return <i className="fas fa-spin fa-spinner" style={{ marginLeft: 10 }} />;
    }
    return null;
  };

  const handleUpdateOrder = () => {
    dispatchUpdateDate(object, { ...object.montageDate, date: selectedDate }).then(() => {
      setSelectedDate(null);
      callMontageDates();
    });
  };

  const renderContent = () => {
    if (!object.montageDate.executionTime) {
      return (
        <div className="alert alert-info text-center" style={{ marginBottom: 0, marginTop: 20 }}>
          <div><i className="fas fa-calendar-check" style={{ fontSize: 30, marginBottom: 10 }} /></div>
          Wir kalkulieren die möglichen Montagetermine anhand der geschätzten Montagezeit und der Ankunftszeit
          beim Kunden.
          <br />
          Bitte tragen Sie eine geschätzte Montagezeit und ggf. eine passende Startzeit ein, damit Ihnen passende
          Terminvorschläge angezeigt werden können.
        </div>
      );
    }
    if (dates && dates.length > 0) {
      return (
        <>
          <p>
            Auf der Basis Ihrer aktuellen Disposition und der Verfügbarkeit Ihrer Monteure und der eingestellten
            Parameter für diesen Auftrag, ergeben sich die folgenden nächstmöglichen Montagetermine. Es sind
            mindesten 2 Ihrer Monteure für die Dauer dieses Auftrags zu den jeweils angezeigten Terminen verfügbar!
            Um einen dieser Termine zu übernehmen, klicken Sie bitte einfach auf das entsprechende Datum! Die
            Zuweisung der Monteure zu diesem Termin erfolgt nicht automatisch. Die errechneten freien Tage ergeben sich
            aus der freien verfügbaren Zeit Ihrer Monteure.
          </p>
          <table className="table table-bordered table-striped table-hover">
            <tbody>
              {dates.map((item) => (
                <tr onClick={() => setSelectedDate(item)} key={item}>
                  <td>
                    {`${formatDate(item)}`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      );
    }
    return null;
  };

  if (!object.montageDate.date && order.internal && visible) {
    return (
      <Panel marginBottom={25}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            <h6>
              Vorschläge für passende Montagetermine
              {renderLoading()}
            </h6>
          </div>
        </div>
        {renderContent()}

        <ModalDialog
          onClose={() => setSelectedDate(null)}
          visible={(selectedDate !== null)}
          width={600}
          confirmCaption="Termin übernehmen"
          onConfirm={handleUpdateOrder}
        >
          <h5>Termin übernehmen</h5>
          <p>
            Bitte bestätigen Sie das Sie den ausgewählten Termin
            <strong>{` ${formatDate(selectedDate)} `}</strong>
            für diesen Auftrag übernehmen möchten! Sie können den Termin jederzeit wieder ändern oder anpassen.
          </p>
        </ModalDialog>
      </Panel>
    );
  }
  return null;
}

ShowInternalNextMontageDates.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool,
};

ShowInternalNextMontageDates.defaultProps = {
  visible: true,
};
