import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import Panel from '../../../../Components/Panel';
import MapMarker from './MapMarker';
import { getAddressesList, getList } from '../../Redux/Action/ClientAction';

/**
 * ClientMap()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function ClientMap(props) {
  const {
    addresses, clients, dispatchGetAddresses, dispatchGetClients,
  } = props;

  useEffect(() => {
    dispatchGetClients();
    dispatchGetAddresses();
  }, [dispatchGetAddresses, dispatchGetClients]);

  const renderAddressMarkers = () => {
    if (addresses) {
      return addresses.map((address) => (
        <MapMarker
          lat={address.latitude}
          lng={address.longitude}
          clients={clients}
          address={address}
          key={address.addressId}
        />
      ));
    }
    return null;
  };

  return (
    <Panel marginBottom={20} boxPadding={0} containerStyle={{ display: 'flex', height: window.innerHeight - 290 }}>
      <div style={{ width: '100%', height: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBgsVfRCdiXwfSxtNcKFgj_jdBT5l_7Q8Y' }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={{ lat: 51, lng: 10 }}
          defaultZoom={6.8}
        >
          {renderAddressMarkers()}
        </GoogleMapReact>
      </div>
    </Panel>
  );
}

ClientMap.propTypes = {
  dispatchGetAddresses: PropTypes.func.isRequired,
  dispatchGetClients: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Array),
  addresses: PropTypes.instanceOf(Array),
};

ClientMap.defaultProps = {
  clients: null,
  addresses: null,
};

function mapStoreToProps(store) {
  return {
    clients: store.systemClient.clients,
    addresses: store.systemClient.addresses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetAddresses: () => dispatch(getAddressesList()),
    dispatchGetClients: () => dispatch(getList()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(ClientMap);
