import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import Input from '../../Input';
import CustomReactSelect from '../../CustomReactSelect';
import HelpDialog from '../../HelpDialog';
import Textarea from '../../Textarea';
import {
  COUNTERTOP_DELIVERY_OPTION_EXTERN,
  COUNTERTOP_OPTION_STONE, countertopDeliveryOptions,
  countertopOptions, workOptions,
} from '../../../Library/Options/KitchenOptions';
import SelectPanel from '../../SelectPanels';
import { YesNoOption } from '../../../Library/Types';

export const initialKitchen = {
  // renderPriceFields
  price: null,
  balance: null,
  externalId: null,

  // renderModification
  withoutGrip: false,
  nicheTrim: true,
  nicheMeasuring: false,
  wallUnit: true,
  runningMeterBottom: null,

  // renderExtractorHood
  exhaustHood: false,

  // renderWorkOptions
  workOptionElectric: false,
  workOptionWater: false,
  workOptionAir: false,
  workOptionLight: false,
  workOptionQuooker: false,

  // renderCountertopOptions
  countertopType: null,
  countertopOption: null,

  // renderComment
  comment: null,
};

export default function EditKitchenOptions(props) {
  const {
    kitchen, onChange, headlinePrefix, headline, isOrderTypeSingle, errors,
  } = props;

  // const client = Redux.useSelector((state) => state.client.client);

  const [showHelp, setShowHelp] = useState(false);

  const selected = Object.keys(kitchen)
    .filter((key) => key.startsWith('workOption'))
    .filter((key) => kitchen[key])
    .map((key) => key.slice(10).toUpperCase());

  let counter = 0;
  const getCounter = () => {
    counter += 1;
    return `${headlinePrefix.trimEnd()}${counter}. `;
  };
  const renderSubHeadline = (subHeadline) => (
    <h6 style={{ marginTop: 30 }}>
      {getCounter() + subHeadline}
    </h6>
  );

  const updateValue = (update) => {
    if (onChange) {
      onChange({
        ...kitchen,
        ...update,
      });
    }
  };

  const renderPriceFields = () => ((isOrderTypeSingle) ? (
    <div className="row">
      <Input
        value={kitchen.price}
        onChange={(price) => updateValue({ price })}
        rowClass="col-lg-6"
        label="Küchenpreis (brutto)"
        rightIcon="fas fa-euro-sign"
        placeholder="Beispiel: 1590.00"
        hasError={errors.price}
        errorMessage={errors.priceMessage}
      />
      <Input
        value={kitchen.balance}
        onChange={(balance) => updateValue({ balance })}
        label="Offener Rechnungsbetrag"
        rightIcon="fas fa-euro-sign"
        placeholder="Beispiel: 1590.00"
        rowClass="col-lg-6"
        hasError={errors.balance}
        errorMessage={errors.balanceMessage}
      />
    </div>
  ) : null);

  const renderPriceAndExternalId = () => (
    <>
      {renderSubHeadline(isOrderTypeSingle ? 'Zahlung / Preis' : 'Kommissionsnummer')}
      {renderPriceFields()}
      <Input
        value={kitchen.externalId}
        onChange={(externalId) => updateValue({ externalId })}
        label="Kommissionsnummer"
        hasError={errors.externalId}
        errorMessage={errors.externalIdMessage}
      />
    </>
  );

  const renderNicheTrim = () => (
    <CustomReactSelect
      value={kitchen.nicheTrim}
      onChange={(nicheTrim) => updateValue({ nicheTrim })}
      label="Nischenverkleidungen"
      options={YesNoOption}
      rowClass="col-lg-6"
    />
  );

  const renderNicheMeasuring = () => (
    <CustomReactSelect
      value={kitchen.nicheMeasuring}
      onChange={(nicheMeasuring) => updateValue({ nicheMeasuring })}
      label="Nischenaufmaß notwendig?"
      options={YesNoOption}
      rowClass="col-lg-6"
    />
  );

  const renderRunningMeter = () => (
    <Input
      value={kitchen.runningMeterBottom}
      onChange={(runningMeterBottom) => updateValue({ runningMeterBottom })}
      label="Laufende Meter (Hinterkante)"
      rightText="m"
      rowClass="col-lg-6"
      hasError={errors.runningMeterBottom}
      errorMessage={errors.runningMeterBottomMessage}
      showHelpButton
      onHelpPress={() => setShowHelp(true)}
    />
  );

  const renderNicheOption = () => ((kitchen.nicheTrim) ? (
    <>
      <div className="row">
        {renderNicheTrim()}
        {renderNicheMeasuring()}
      </div>
      <div className="row">
        {renderRunningMeter()}
      </div>
    </>
  ) : (
    <div className="row">
      {renderNicheTrim()}
      {renderRunningMeter()}
    </div>
  ));

  const renderModification = () => (isOrderTypeSingle ? (
    <>
      {renderSubHeadline('Ausstattung')}
      <div className="row">
        <CustomReactSelect
          value={kitchen.withoutGrip}
          onChange={(withoutGrip) => updateValue({ withoutGrip })}
          label="Grifflos"
          options={YesNoOption}
          rowClass="col-lg-6"
        />
        <CustomReactSelect
          value={kitchen.wallUnit}
          onChange={(wallUnit) => updateValue({ wallUnit })}
          label="Oberschränke vorhanden"
          options={YesNoOption}
          rowClass="col-lg-6"
        />
      </div>
      {renderNicheOption()}
    </>
  ) : null);

  const renderExtractorHood = () => (isOrderTypeSingle ? (
    <>
      {renderSubHeadline('Spezielle Dunstabzugshaube')}
      <p className="text-muted">
        Handelt es sich bei der Dunstabzugshaube um eine Inselhaube, flächenbündige Deckenhaube, eine Haube
        integriert im Kochfeld oder eine Arbeitsplattenhaube?
      </p>
      <div className="row">
        <CustomReactSelect
          value={kitchen.exhaustHood}
          onChange={(exhaustHood) => updateValue({ exhaustHood })}
          options={YesNoOption}
        />
      </div>
    </>
  ) : null);

  const handleOptionChange = (value) => {
    if (onChange) {
      const key = `workOption${Lodash.capitalize(value.toLowerCase())}`;
      onChange({ ...kitchen, [key]: !kitchen[key] });
    }
  };

  const renderWorkOptions = () => (
    <>
      {renderSubHeadline('Anschlussarbeiten')}
      <SelectPanel
        data={workOptions}
        selected={selected}
        onChange={handleOptionChange}
        returnSingleKey
      />
    </>
  );

  const renderCountertopDeliveryOption = () => (
    (kitchen.countertopType === COUNTERTOP_OPTION_STONE) ? (
      <div className="row" style={{ marginTop: 10 }}>
        <br />
        <CustomReactSelect
          options={countertopDeliveryOptions}
          value={kitchen.countertopOption}
          onChange={(countertopOption) => updateValue({ countertopOption })}
          label="Haben Sie externe Dienstleister für die Arbeitsplatte?"
        />
      </div>
    ) : null);

  const handleCountertopOptionChange = (countertopType) => {
    if (countertopType === COUNTERTOP_OPTION_STONE) {
      updateValue({ countertopType, countertopOption: COUNTERTOP_DELIVERY_OPTION_EXTERN });
    } else {
      updateValue({ countertopType, countertopOption: null });
    }
  };

  const renderCountertopOptions = () => (
    <>
      {renderSubHeadline('Material der Arbeitsplatte')}
      <SelectPanel
        data={countertopOptions}
        selected={kitchen.countertopType}
        onChange={handleCountertopOptionChange}
        returnSingleKey
        hasError={errors.countertopType}
      />
      {renderCountertopDeliveryOption()}
    </>
  );

  const renderComment = () => (
    <>
      {renderSubHeadline('Montagehinweise')}
      <Textarea
        value={kitchen.comment}
        onChange={(comment) => updateValue({ comment })}
        rows={4}
        hasError={!!errors.comment}
      />
    </>
  );

  return (
    <div>
      <h5>
        {headlinePrefix}
        {headline}
      </h5>
      <p className="text-muted">
        Wir benötigen noch ein paar Informationen zur Küche
      </p>

      {renderPriceAndExternalId()}
      {renderModification()}
      {renderExtractorHood()}
      {renderWorkOptions()}
      {renderCountertopOptions()}
      {renderComment()}

      <HelpDialog context="calculateMeterHint" visible={showHelp} onClose={() => setShowHelp(false)} />
    </div>
  );
}

EditKitchenOptions.propTypes = {
  kitchen: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  headlinePrefix: PropTypes.string,
  headline: PropTypes.string,
  isOrderTypeSingle: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
};

EditKitchenOptions.defaultProps = {
  kitchen: initialKitchen,
  onChange: undefined,
  headlinePrefix: '',
  headline: 'Kücheninformationen',
  isOrderTypeSingle: false,
  errors: {},
};
