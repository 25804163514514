import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { updateSettings } from '../../../Redux/Action/MandantAction';
import Panel from '../../../Components/Panel';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import { YesNoOption } from '../../../Library/Types';
import { getList } from '../../../Redux/Action/ClientAddressAction';
import { getAll as getUsers } from '../../../Redux/Action/UserAction';
import Button from '../../../Components/Button';

/**
 * MandantSettings()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function MandantSettings(props) {
  const {
    partners, dispatchUpdate, dispatchGetClientAddresses, clientAddresses, history, dispatchGetUsers, users,
  } = props;
  const { mandantId } = useParams();
  const [partner, setPartner] = React.useState(null);
  const [settings, setSettings] = React.useState(null);
  const [lastSettings, setLastSettings] = React.useState(null);
  const [addressesOption, setAddressesOption] = React.useState(null);
  const [usersOption, setUsersOption] = React.useState([]);

  React.useEffect(() => {
    dispatchGetClientAddresses();
    dispatchGetUsers();
  }, [dispatchGetClientAddresses, dispatchGetUsers]);

  React.useEffect(() => {
    if (users) {
      const mapped = users.map((item) => ({
        label: `${item.lastname}, ${item.firstname}`,
        value: item.userId,
      }));
      setUsersOption(mapped);
    }
  }, [users]);

  React.useEffect(() => {
    if (clientAddresses) {
      const options = clientAddresses.map((item) => ({
        label: item.name,
        value: item.addressId,
      }));
      setAddressesOption(options);
    }
  }, [clientAddresses]);

  React.useEffect(() => {
    if (partners) {
      const found = partners.filter((item) => item.id === parseInt(mandantId, 0))[0];
      if (found) {
        setPartner(found.partner);
        setSettings(found.settings);
        setLastSettings(found.settings);
      }
    }
  }, [mandantId, partners]);

  React.useEffect(() => {
    if (settings) {
      if (JSON.stringify(settings) !== JSON.stringify(lastSettings)) {
        dispatchUpdate(settings);
      }
    }
  }, [settings, dispatchUpdate, lastSettings]);

  const getAddressName = (id) => {
    if (clientAddresses) {
      const filtered = clientAddresses.filter((item) => item.addressId === id)[0];
      if (filtered) {
        return filtered.name;
      }
    }
    return 'Kein Lager ausgewählt';
  };

  const getResponsibleUserName = () => {
    if (settings.responsibleUserId && settings.responsibleUserId > 0 && users) {
      const user = users.filter((item) => item.userId === settings.responsibleUserId)[0];
      return `${user.lastname}, ${user.firstname}`;
    }
    return 'Kein verantwortlicher Benutzer ausgewählt';
  };

  if (partner && settings) {
    return (
      <div className="row">
        <div className="col-xl-12">
          <Panel marginBottom={25}>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column">
                <h4 style={{ marginBottom: 4 }}>Partnereinstellungen</h4>
                <h6 style={{ color: '#838383' }}>{partner.name}</h6>
                <p style={{ margin: 0, padding: 0 }}>
                  Mit den individuellen Einstellungen zu Ihrem Partner können Sie verschiedenen Optionen festlegen
                  welche bei der Erstellung / Verwaltung von Aufträgen über Ihren Partner angewendet werden.
                </p>
              </div>
            </div>
          </Panel>

          <Panel marginBottom={25}>
            <h6>Verantwortlicher Benutzer</h6>
            <p style={{ marginBottom: 25 }}>
              Hier können Sie einen Benutzer festlegen, der für den Partner
              <span>
                {` "${partner.name}" `}
              </span>
              verantwortlich ist.
              <br />
              Dieser Benutzer erhält alle relevanten Benachrichtigungen zu Partneraufträgen oder Teilaufträgen
              dieses Partners.
            </p>
            <KeyValueEditRow
              title={`Verantwortlicher Benutzer für "${partner.name}"`}
              type="select"
              selectData={usersOption}
              displayText={getResponsibleUserName()}
              onChange={(data) => setSettings({ ...settings, responsibleUserId: data })}
              initialValue={settings.responsibleUserId}
              onClear={() => setSettings({ ...settings, responsibleUserId: 0 })}
              clearTitle="Verantwortlichen Benutzer entfernen"
            />
          </Panel>

          <Panel marginBottom={25}>
            <h6>Lageroption</h6>
            <p style={{ marginBottom: 25 }}>
              Hier können Sie festlegen, ob der Partner bei der Erstellung von Aufträgen Ihr Lager benutzen darf.
              Wenn diese Option aktiviert ist, kann der Partner bei der Erstellung von Aufträgen auswählen, das die
              Ware an Ihr Lager geliefert wird. Dem Partner wird das von Ihnen eingestellte Standardlager als
              Lieferadresse für die Ware angezeigt.
            </p>
            <KeyValueEditRow
              title="Partner darf Lageroption verwenden"
              type="select"
              selectData={YesNoOption}
              displayText={(settings.allowStorage) ? 'Ja' : 'Nein'}
              onChange={(data) => setSettings({ ...settings, allowStorage: data })}
              initialValue={settings.allowStorage}
            />

            <KeyValueEditRow
              title="Standardlager für Aufträge mit Lageroption"
              type="select"
              selectData={addressesOption}
              displayText={getAddressName(settings.defaultClientAddressId)}
              onChange={(data) => setSettings({ ...settings, defaultClientAddressId: data })}
              initialValue={settings.defaultClientAddressId}
            />
          </Panel>

          <Panel withPadding={false}>
            <div className="d-flex justify-content-start">
              <Button onClick={() => history.goBack()} type="btn-light">
                <i className="fas fa-angle-double-left" />
                Zurück zur Partnerübersicht
              </Button>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
  return null;
}

MandantSettings.propTypes = {
  dispatchUpdate: PropTypes.func.isRequired,
  dispatchGetClientAddresses: PropTypes.func.isRequired,
  dispatchGetUsers: PropTypes.func.isRequired,
  partners: PropTypes.instanceOf(Array),
  clientAddresses: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
  users: PropTypes.instanceOf(Array),
};

MandantSettings.defaultProps = {
  partners: null,
  clientAddresses: null,
  users: null,
};

function mapStoreToProps(store) {
  return {
    partners: store.client.partners,
    clientAddresses: store.clientAddress.addresses,
    users: store.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdate: (settings) => dispatch(updateSettings(settings)),
    dispatchGetClientAddresses: () => dispatch(getList()),
    dispatchGetUsers: () => dispatch(getUsers()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(MandantSettings));
