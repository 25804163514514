import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomReactSelect from '../CustomReactSelect';

export const getDisplayString = (week) => {
  if (week && week !== '0000-00-00') {
    const currentWeek = moment(week).format('ww');
    const currentYear = moment(week).format('y');
    const startOfWeek = moment(week).startOf('week');
    const endOfWeek = moment(week).endOf('week');
    return (
      `KW ${currentWeek}/${currentYear} (${startOfWeek.format('DD.MM.')} - ${endOfWeek.format('DD.MM.')})`
    );
  }
  return '';
};

export const getWeekOption = (week) => ({
  label: getDisplayString(week),
  value: week.startOf('week').format('YYYY-MM-DD'),
});

export default function WeekPickerInput(props) {
  const {
    value, onChange, rowClass,
  } = props;

  const [options, setOptions] = React.useState(null);

  React.useEffect(() => {
    if (!options) {
      const weeks = [];
      weeks.push({ label: '', value: null });
      for (let i = 0; i < 52; i += 1) {
        const week = moment().add(i, 'weeks');
        weeks.push(getWeekOption(week));
      }
      setOptions(weeks);
    }
  }, [options]);

  return (
    <div className={rowClass} style={{ marginTop: 0 }}>
      <CustomReactSelect
        value={value}
        onChange={onChange}
        options={options}
      />
    </div>
  );
}

WeekPickerInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  rowClass: PropTypes.string,
};

WeekPickerInput.defaultProps = {
  value: null,
  onChange: undefined,
  rowClass: 'row',
};
