/* eslint-disable max-len */
import axios from '../../../../Library/Axios';

const defaultUri = '/system/statistic';

export const SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_START = 'SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_START';
export const SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_SUCCESS = 'SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_SUCCESS';
export const SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_ERROR = 'SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_ERROR';

/**
 * getExternalOrdersByMonth()
 * @returns {function(*): Promise<any | never>}
 */
export function getExternalOrdersByMonth() {
  return (dispatch) => {
    dispatch({ type: SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_START });

    return axios().get(defaultUri, { params: { action: 'externalByMonth' } }).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_START = 'SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_START';
export const SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_SUCCESS = 'SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_SUCCESS';
export const SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_ERROR = 'SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_ERROR';

/**
 * getTransactionsByMonth()
 * @returns {function(*): Promise<any | never>}
 */
export function getTransactionsByMonth() {
  return (dispatch) => {
    dispatch({ type: SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_START });

    return axios().get(defaultUri, { params: { action: 'transactionsByMonth' } }).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
