import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { NavLink } from 'react-router-dom';

/**
 * NotAllowedHint()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function NotAllowedHint(props) {
  const {
    isExternal, isNotAllowed, style,
  } = props;

  if (isExternal && isNotAllowed) {
    return (
      <div className="alert alert-hk-primary d-flex flex-row" style={style}>
        <div>
          <i className="fa fa-exclamation-circle" style={{ marginRight: 10 }} />
        </div>
        <div>
          Bevor Sie Angebote einholen können müssen Sie noch einige Informationen in den Einstellungen
          bereitstellen! Wir benötigen Ihre vollständige Adresse inkl. Ihrer Umsatzsteuer-Identifikationsnummer
          sowie Ihr Firmen-Logo und mindestens eine Lageradresse. Des weiteren benötigen wir Ihre
          Gläubiger-Identifikationsnummer damit wir Ihnen das SEPA-Lastschriftmandat für den Kunden bereitstellen
          können, falls bei einem Auftrag Restbeträge anfallen.
          <br />
          <NavLink className="alert-link" to="/admin/client" style={{ textDecoration: 'underline' }}>
            Einstellungen öffnen
          </NavLink>
          <br />
          <br />
          Sie können diese Seite einfach verlassen. Ihr Angebot ist gespeichert und Sie können es jederzeit annehmen,
          nachdem Sie alle benötigten Informationen ausgefüllt haben! Sie finden das Angebot unter dem Menüpunkt
          "Alle Aufträge".
        </div>
      </div>
    );
  }
  return null;
}

NotAllowedHint.propTypes = {
  isExternal: PropTypes.bool.isRequired,
  isNotAllowed: PropTypes.bool.isRequired,
  style: PropTypes.instanceOf(Object),
};

NotAllowedHint.defaultProps = {
  style: {},
};
