import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import FileSaver from 'file-saver';
import {
  downloadImages,
  get as getReport,
  getPdf,
  useDispatchGetMeasurementPdf,
} from '../../../Redux/Action/ServiceReportAction';
import { get as getOrder } from '../../../Redux/Action/OrderAction';
import Panel from '../../../Components/Panel';
import DeviceList from '../../../Components/DeviceList';
import Button from '../../../Components/Button';
import LoadingModal from '../../../Components/LoadingModal';
import CompletedImages from '../../../Components/ReportComponents/CompletedImages';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import Overview from './Components/Overview';
import CustomerBalance from './Components/CustomerBalance';
import UsedMaterial from '../../../Components/UsedMaterial';
import {
  CLIENTTYPE_MONTEUR, DEVICETYPE_ADDITIONALWORK,
  DEVICETYPE_DAMAGE, DEVICETYPE_PREDAMAGE,
  DEVICETYPE_RECLAMATION, DEVICETYPE_RETURN,
  DEVICEWORKTYPE_CUSTOMERPLUGGED,
  DEVICEWORKTYPE_PLUGGED,
  DEVICEWORKTYPE_UNPLUGGED,
} from '../../../Library/Types';
import Information from './Components/Information';
import StatusInProgress from '../../../Components/ReportComponents/StatusInProgress';
import InventoryImages from '../../../Components/ReportComponents/InventoryImages';
import SignaturesBox from '../../../Components/ReportComponents/SignaturesBox';
import { getList } from '../../../Redux/Action/TeamsAction';
import MeasurementList from './Components/MeasurementList';

/**
 * ServiceReport()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function ServiceReport(props) {
  const {
    report, getReportDetails, order, getOrderDetails, downloadPdf, teams, client,
    history, dispatchDownloadImages, dispatchGetTeamList,
  } = props;

  const [customer, setCustomer] = useState();
  const [users, setUsers] = useState();

  const [pluggedDevices, setPluggedDevices] = useState([]);
  const [unpluggedDevices, setUnpluggedDevices] = useState([]);
  const [customerDevices, setCustomerDevices] = useState([]);
  const [reclamations, setReclamations] = useState([]);
  const [damages, setDamages] = useState([]);
  const [preDamages, setPreDamages] = useState([]);
  const [addtionalWork, setAdditionalWork] = useState([]);
  const [returns, setReturns] = useState([]);
  const { reportId, orderId, objectId } = useParams();
  const [object, setObject] = useState();
  const [downloadingImages, setDownloadingImages] = useState(false);
  const [reportDownloading, setReportDownloading] = useState(false);

  const dispatchGetMeasurementPdf = useDispatchGetMeasurementPdf();

  const loadData = React.useCallback(() => {
    getOrderDetails(orderId).then((orderResponse) => getReportDetails(orderId, objectId, reportId).then((data) => {
      const selectedObject = orderResponse.objects.filter((item) => item.objectId === data.objectId)[0];
      if (selectedObject) {
        setCustomer(selectedObject.customer);
        if (selectedObject.montageDate) {
          setUsers(selectedObject.montageDate.users);
        }
      }
      setPluggedDevices(data.devices.filter((item) => item.workType === DEVICEWORKTYPE_PLUGGED));
      setUnpluggedDevices(data.devices.filter((item) => item.workType === DEVICEWORKTYPE_UNPLUGGED));
      setCustomerDevices(data.devices.filter((item) => item.workType === DEVICEWORKTYPE_CUSTOMERPLUGGED));
      setReclamations(data.devices.filter((item) => item.type === DEVICETYPE_RECLAMATION));
      setDamages(data.devices.filter((item) => item.type === DEVICETYPE_DAMAGE));
      setPreDamages(data.devices.filter((item) => item.type === DEVICETYPE_PREDAMAGE));
      setReturns(data.devices.filter((item) => item.type === DEVICETYPE_RETURN));
      setAdditionalWork(data.devices.filter((item) => item.type === DEVICETYPE_ADDITIONALWORK));
    })).catch(() => {
      history.push('/dashboard');
    });
  }, [getOrderDetails, getReportDetails, reportId, orderId, objectId, history]);

  React.useEffect(() => {
    if (report && order) {
      const objectFiltered = order.objects.filter((item) => item.objectId === report.objectId)[0];
      setObject(objectFiltered);
    }
  }, [report, order]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  React.useEffect(() => {
    if (object && teams && users && users.length === 0) {
      if (object.teamId) {
        const team = teams.filter((teamData) => teamData.teamId === object.teamId)[0];
        if (team) {
          setUsers(team.users);
        }
      }
    }
  }, [object, users, teams]);

  React.useEffect(() => {
    dispatchGetTeamList();
  }, [dispatchGetTeamList]);

  const handleDownload = () => {
    setReportDownloading(true);
    downloadPdf(orderId, objectId, reportId).then((data) => {
      setReportDownloading(false);
      FileSaver.saveAs(data, `Montagebericht_${orderId}.pdf`);
    }).catch(() => setReportDownloading(false));
  };

  const handleDownloadImages = () => {
    setDownloadingImages(true);
    dispatchDownloadImages(orderId, objectId, reportId).then((data) => {
      FileSaver.saveAs(data, `MontageberichtAlleBilder${orderId}.zip`);
      setDownloadingImages(false);
    }).catch(() => setDownloadingImages(false));
  };

  const downloadMeasurementReport = () => {
    dispatchGetMeasurementPdf(object.objectId).then((data) => {
      FileSaver.saveAs(data, `Nischenaufmaße_${object.objectId}.pdf`);
    }).catch(() => {});
  };

  const renderMeasurementDownload = () => {
    if (report.measurements && report.measurements.length > 0) {
      return (
        <Button onClick={() => downloadMeasurementReport()} style={{ marginLeft: 8 }}>
          <i className="fas fa-cloud-download-alt" />
          Nischenaufmaße
        </Button>
      );
    }
    return null;
  };

  const renderDownloadButton = () => {
    if (report.finished) {
      return (
        <div className="d-flex flex-row" style={{ textAlign: 'right' }}>
          <Button onClick={() => handleDownloadImages()} style={{ marginRight: 8 }}>
            <i className="fas fa-cloud-download-alt" />
            Alle Bilder
          </Button>
          <Button onClick={() => handleDownload()}>
            <i className="fas fa-cloud-download-alt" />
            Bericht als PDF
          </Button>
          {renderMeasurementDownload()}
        </div>
      );
    }
    return null;
  };

  const renderMeasurements = () => {
    const { measurements } = report;
    if (!measurements || measurements.length < 1) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-lg-12 d-flex">
          <Panel marginBottom={25}>
            <h6 style={{ marginBottom: 45 }}>Nischenaufmaße</h6>
            {measurements.map((measurement) => (
              <MeasurementList colSize={6} key={measurement.measurementId} measurement={measurement} />
            ))}
          </Panel>
        </div>
      </div>
    );
  };

  if (report && order && customer && users) {
    return (
      <div>
        <Panel marginBottom={25}>
          <div className="d-flex">
            <div className="flex-grow-1 d-flex align-items-center align-content-center">
              <h4 className="PrimaryHeadline" style={{ marginBottom: 0 }}>
                Montagebericht
              </h4>
            </div>
            {renderDownloadButton()}
          </div>
        </Panel>

        <StatusInProgress report={report} />

        <div className="row">
          <div className={`d-flex ${(report.customerPaidValue > 0 && report.finished) ? 'col-lg-8' : 'col-lg-12'}`}>
            <Overview report={report} object={object} />
          </div>

          <CustomerBalance report={report} object={object} />
        </div>

        <div className="row">
          <div className="col-lg-6 d-flex">
            <Panel marginBottom={25}>
              <h6>Kundendaten</h6>
              <p>
                Hier sehen Sie die Kontaktdaten des Kunden
              </p>

              <KeyValueEditRow title="Name" type="text" displayText={`${customer.firstname} ${customer.lastname}`} />
              <KeyValueEditRow
                title="Adresse"
                type="text"
                displayText={`${customer.street} ${customer.streetNo}, ${customer.zip} ${customer.city}`}
              />
              <KeyValueEditRow title="E-Mail Adresse" type="text" displayText={customer.mail} />
              <KeyValueEditRow title="Telefon" type="text" displayText={customer.phone} />
              <KeyValueEditRow title="Mobil" type="text" displayText={customer.mobile} />
              <KeyValueEditRow
                title="Kommentar"
                type="text"
                displayText={customer.comment}
                visible={customer.comment !== null && customer.comment.length > 0}
              />
            </Panel>
          </div>
          <div className="col-lg-6 d-flex">
            <Information report={report} teams={teams} object={object} order={order} client={client} />
          </div>
        </div>

        <InventoryImages images={report.inventoryImages} />

        <div className="row">
          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={preDamages}
            caption="Dokumentierte Vorschäden"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={pluggedDevices}
            caption="Angeschlossene Geräte"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={unpluggedDevices}
            caption="Nicht angeschlossene Geräte"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={customerDevices}
            caption="Kundeneigene Geräte"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={reclamations}
            caption="Beanstandungen"
            isMonteur={(client.type === CLIENTTYPE_MONTEUR)}
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={damages}
            caption="Beschädigungen / Versicherungsfall"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={addtionalWork}
            caption="Sonderarbeiten"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={returns}
            caption="Retouren"
          />

          <div className="col-lg-12">
            <UsedMaterial materials={report.materials} />
          </div>
        </div>

        {renderMeasurements()}

        <CompletedImages images={report.completedImages} />

        <SignaturesBox
          customer={customer}
          user={(users) ? users[0] : null}
          customerSignaturePath={(report.signatureCustomer.absolutePath) || null}
          monteurSignaturePath={(report.signatureMonteur.absolutePath) || null}
        />

        <LoadingModal
          visible={reportDownloading}
          headline="Montagebericht wird erstellt"
          text="Der Montagebericht wird im Anschluss automatisch heruntergeladen!"
        />

        <LoadingModal
          visible={downloadingImages}
          headline="Bilder werden komprimiert..."
          text="Der Download wird im Anschluss automatisch gestartet!"
        />

      </div>
    );
  }
  return <LoadingModal visible text="Montagebericht wird geladen..." />;
}

function mapStoreToProps(store) {
  return {
    report: store.reports.serviceReport,
    order: store.order.order,
    teams: store.teams.teams,
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrderDetails: (orderId) => dispatch(getOrder(orderId)),
    getReportDetails: (orderId, objectId, reportId) => dispatch(getReport(orderId, objectId, reportId)),
    downloadPdf: (orderId, objectId, reportId) => dispatch(getPdf(orderId, objectId, reportId, true)),
    dispatchDownloadImages: (orderId, objectId, reportId) => dispatch(downloadImages(orderId, objectId, reportId)),
    dispatchGetTeamList: () => dispatch(getList(false)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ServiceReport));

ServiceReport.propTypes = {
  report: PropTypes.instanceOf(Object),
  order: PropTypes.instanceOf(Object),
  getReportDetails: PropTypes.func,
  getOrderDetails: PropTypes.func,
  dispatchDownloadImages: PropTypes.func.isRequired,
  downloadPdf: PropTypes.func,
  teams: PropTypes.instanceOf(Array),
  client: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  dispatchGetTeamList: PropTypes.func.isRequired,
};

ServiceReport.defaultProps = {
  report: null,
  order: null,
  getReportDetails: () => {},
  getOrderDetails: () => {},
  downloadPdf: () => {},
  teams: null,
};
