import axios from '../../../Library/Axios';
import { defaultUri } from '../UserAction';

/**
 * Get the default request uri for user tasks
 * @param userId
 * @returns {string}
 */
function getDefaultUri(userId) {
  return `${defaultUri}/${userId}/task`;
}

export const USER_TASK_GET_START = 'USER_TASK_GET_START';
export const USER_TASK_GET_SUCCESS = 'USER_TASK_GET_SUCCESS';
export const USER_TASK_GET_ERROR = 'USER_TASK_GET_ERROR';

/**
 * get()
 * @param taskId
 * @returns {function(*): *}
 */
export function get(taskId) {
  return (dispatch) => {
    dispatch({ type: USER_TASK_GET_START });

    const uri = `${getDefaultUri(999999)}/${taskId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_TASK_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_TASK_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const USER_TASK_GETLIST_START = 'USER_TASK_GETLIST_START';
export const USER_TASK_GETLIST_SUCCESS = 'USER_TASK_GETLIST_SUCCESS';
export const USER_TASK_GETLIST_ERROR = 'USER_TASK_GETLIST_ERROR';

/**
 * getList()
 * @param user
 * @returns {function(*): *}
 */
export function getList(user) {
  return (dispatch) => {
    dispatch({ type: USER_TASK_GETLIST_START });

    return axios().get(getDefaultUri(user.userId)).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_TASK_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_TASK_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const USER_TASK_CREATE_START = 'USER_TASK_CREATE_START';
export const USER_TASK_CREATE_SUCCESS = 'USER_TASK_CREATE_SUCCESS';
export const USER_TASK_CREATE_ERROR = 'USER_TASK_CREATE_ERROR';

/**
 * create()
 * @param currentUser
 * @param task
 * @returns {function(*): *}
 */
export function create(currentUser, task) {
  return (dispatch) => {
    dispatch({ type: USER_TASK_CREATE_START });

    return axios().post(getDefaultUri(currentUser.userId), task).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_TASK_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_TASK_CREATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const USER_TASK_UPDATE_START = 'USER_TASK_UPDATE_START';
export const USER_TASK_UPDATE_SUCCESS = 'USER_TASK_UPDATE_SUCCESS';
export const USER_TASK_UPDATE_ERROR = 'USER_TASK_UPDATE_ERROR';

/**
 * update()
 * @param currentUser
 * @param task
 * @returns {function(*): *}
 */
export function update(currentUser, task) {
  return (dispatch) => {
    dispatch({ type: USER_TASK_UPDATE_START });

    const uri = `${getDefaultUri(currentUser.userId)}/${task.taskId}`;

    return axios().put(uri, task).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_TASK_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_TASK_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const USER_TASK_DELETE_START = 'USER_TASK_DELETE_START';
export const USER_TASK_DELETE_SUCCESS = 'USER_TASK_DELETE_SUCCESS';
export const USER_TASK_DELETE_ERROR = 'USER_TASK_DELETE_ERROR';

/**
 * deleteTask()
 * @param currentUser
 * @param task
 * @returns {function(*): *}
 */
export function deleteTask(currentUser, task) {
  return (dispatch) => {
    dispatch({ type: USER_TASK_DELETE_START });

    const uri = `${getDefaultUri(currentUser.userId)}/${task.taskId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_TASK_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_TASK_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
