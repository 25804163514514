/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../../../Components/Panel';
import { formatDate, formatTime } from '../../../../Library/Functions';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import ReclamationMetadata from './ReclamationMetadata';

import './style.css';
import { timeOptions } from '../../../../Library/Types';

/**
 * ReclamationInformation()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ReclamationInformation(props) {
  const {
    reclamation, onChange, order, editable,
  } = props;

  const noInfo = () => (<i className="text-muted">Keine Daten eingetragen</i>);

  return (
    <div className="row">
      <div className="col-lg-8">
        <Panel marginBottom={20} className="ProjectInformation">
          <h6>Angaben zum Reklamationsauftrag</h6>
          <p>
            Bitte geben Sie die allgemeinen Informationen zum Reklamationsauftrag an.
          </p>

          <KeyValueEditRow
            title="Titel"
            displayText={reclamation.title}
            initialValue={reclamation.title}
            actionIcon="fas fa-pencil-alt"
            onChange={(editable) ? (data) => onChange('title', data) : null}
            type="text"
          />
          <KeyValueEditRow
            title="Datum der Ausführung"
            displayText={(reclamation.executionDate) ? formatDate(reclamation.executionDate) : noInfo()}
            initialValue={reclamation.executionDate}
            actionIcon="fas fa-pencil-alt"
            onChange={(order.internal && editable) ? (data) => onChange('executionDate', data) : null}
            onClear={(order.internal && editable) ? () => onChange('executionDate', null) : null}
            type="date"
          />

          <div className="row">
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Ankunft Monteur von"
                displayText={(reclamation.executionFromTime) ? `${formatTime(reclamation.executionFromTime)} Uhr` : noInfo()}
                initialValue={reclamation.executionFromTime}
                actionIcon="fas fa-pencil-alt"
                onChange={(order.internal && editable) ? (data) => onChange('executionFromTime', data) : null}
                type="select"
                selectData={timeOptions}
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Ankunft Monteur bis"
                displayText={(reclamation.executionToTime) ? `${formatTime(reclamation.executionToTime)} Uhr` : noInfo()}
                initialValue={reclamation.executionToTime}
                actionIcon="fas fa-pencil-alt"
                onChange={(order.internal && editable) ? (data) => onChange('executionToTime', data) : null}
                type="select"
                selectData={timeOptions}
              />
            </div>
          </div>

          <KeyValueEditRow
            title="Zusätzliche Informationen zum Reklamationsauftrag"
            displayText={(reclamation.information) ? reclamation.information : noInfo()}
            initialValue={reclamation.information}
            actionIcon="fas fa-pencil-alt"
            onChange={(editable) ? (data) => onChange('information', data) : null}
            type="text"
          />
          <KeyValueEditRow
            title="Geschätzte Arbeitszeit"
            displayText={`${reclamation.assemblyTime} Minuten`}
            initialValue={reclamation.assemblyTime}
            actionIcon="fas fa-pencil-alt"
            onChange={(editable) ? (data) => onChange('assemblyTime', data) : null}
            type="text"
          />

        </Panel>
      </div>
      <div className="col-lg-4" style={{ display: 'flex' }}>
        <ReclamationMetadata reclamation={reclamation} order={order} />
      </div>
    </div>
  );
}

ReclamationInformation.propTypes = {
  reclamation: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  editable: PropTypes.bool.isRequired,
};
