const calculateMeterHintImage = require('../../Assets/Images/calculate-meter-hint.png');

export default {
  devoutIdentificationNumber: {
    headline: 'Gläubiger-Identifikationsnummer',
    text: 'Die Gläubiger-Identifikationsnummer wird benötigt um Lastschriftmandate zu generieren. Wenn Sie bei '
      + 'einer Küche einen Restbetrag offen haben und diesen nach erfolgreicher Montage vom Kundenkonto einziehen'
      + ' möchten, müssen Sie eine gültige Gläubiger-Identifikationsnummer angeben. Während des Montageberichts muss '
      + 'der Kunde dann seine Daten angeben und das Lastschriftmandat unterzeichnen. Dieses können Sie dann im '
      + 'Bedarfsfall herunterladen und die offene Summe vom Kundenkonto einfach und sicher abbuchen!',
  },
  calculateMeterHint: {
    headline: 'Berechnung der Küchenlänge',
    text: 'Bitte berechnen Sie die Küchenlänge anhand der rot markierten Linie auf unserem Bild. Wenn Sie Fragen '
      + 'zur Berechnung oder Besonderheiten haben, wendet Sie sich bitte an support@hey.kitchen',
    image: calculateMeterHintImage,
  },
  addressRadius: {
    headline: 'Wofür ist die Umkreissuche / Radius?',
    text: 'Der Radius bestimmt den Umkreis in welchem Sie über neue Aufträge innerhalb Hey.Kitchen '
      + 'informiert werden. Wenn ein neuer Auftrag eingestellt wird und dieser ist innerhalb Ihres angegeben Radius '
      + 'des Standortes, erhalten Sie automatisch eine Benachrichtigung per E-Mail zu diesem Auftrag. Damit haben '
      + 'Sie die Möglichkeit schnellst möglich auf neue Aufträge zu reagieren. So verpassen Sie keinen interessanten '
      + 'Auftrag mehr! Wenn Sie keine Benachrichtigungen erhalten möchten stellen Sie den Wert einfach auf "0".',
  },
  objectExternalId: {
    headline: 'Auftragsbezeichnung für Objektaufträge',
    text: 'Sie können dem übergeordneten Objektauftrag eine eigene für Sie eindeutige und frei wählbare '
      + 'Auftragsbezeichnung geben. Der Objektauftrag wird dann im System immer unter dieser Bezeichnung angezeigt. '
      + 'Vergeben Sie keine eigene Auftragsbezeichnung wird immer die eindeutige system interne Auftragsnummer '
      + 'angezeigt. Diese Auftragsnummer hat nichts mit den eigentlichen Kommissionsnummern der einzelnen Objekte zu '
      + 'tun. Dies dient lediglich der besseren Auffindbarkeit des Objektauftrags im System.',
  },
  invoiceMail: {
    headline: 'E-Mail Adresse(n) für Rechnungsversand',
    text: 'Wenn Sie hier eine gültige E-Mail Adresse eintragen, werden alle vom System generierten Rechnungen an '
      + 'diese E-Mail Adresse versendet. Ist keine E-Mail hinterlegt werden die Rechnungen immer an die '
      + '"normale" E-Mail Adresse gesendet, welche Sie im Feld oben drüber eingetragen haben. \n\nSie können '
      + 'mehrere Empfängeradressen mit Semikolon ";" getrennt angeben, wenn Sie die Rechnung an mehrere '
      + 'verschiedenen E-Mail Adressen senden möchten.',
  },
  serviceReportMail: {
    headline: 'E-Mail Adresse(n) für Montageberichte',
    text: 'Wenn Sie hier eine gültige E-Mail Adresse eintragen, werden alle generierten Montageberichte an '
      + 'diese E-Mail Adresse versendet. Ist keine E-Mail hinterlegt werden die Berichte immer an die '
      + 'E-Mail Adresse gesendet des Benutzers der den Auftrag im System erstellt hat. \n\nSie können '
      + 'mehrere Empfängeradressen mit Semikolon ";" getrennt angeben, wenn Sie die Berichte an mehrere '
      + 'verschiedenen E-Mail Adressen senden möchten.',
  },
  reclamationReportMail: {
    headline: 'E-Mail Adresse(n) für Reklamationsberichte',
    text: 'Wenn Sie hier eine gültige E-Mail Adresse eintragen, werden alle generierten Reklamationsberichte an '
      + 'diese E-Mail Adresse versendet. Ist keine E-Mail hinterlegt werden die Berichte immer an die '
      + 'E-Mail Adresse gesendet des Benutzers der den Auftrag im System initial erstellt hat. \n\nSie können '
      + 'mehrere Empfängeradressen mit Semikolon ";" getrennt angeben, wenn Sie die Berichte an mehrere '
      + 'verschiedenen E-Mail Adressen senden möchten.',
  },
  userInitials: {
    headline: 'Initialen des Mitarbeiter',
    text: 'Die Initialen sind 2-3 stellige Kürzel des Mitarbeiters. In der Regel der erste Buchstabe des Vornamens '
      + 'und die ersten beiden des Nachnamens. Die Initialen werden innerhalb hey.kitchen an vielen Stellen '
      + 'angezeigt anstelle des vollen Namens. Wenn Sie keine Initialen vergeben werden diese automatisch vom System '
      + 'generiert. Die Initialen werden automatisch immer in Großbuchstaben konvertiert!',
  },
  daysOrdersShownMonteur: {
    headline: 'Zugewiesenen Aufträge in der Zukunft',
    text: 'Mit dieser Einstellung können Sie festlegen wie viel Tag im voraus dem Monteur die Ihm '
      + 'zugewiesenen Aufträge innerhalb der App und der Webseite angezeigt bekommt. Ist die Einstellung '
      + '"deaktiviert" werden dem Monteur alle zukünftig zugewiesenen Aufträge angezeigt. Bei Benutzern mit '
      + 'Administrationsrechten hat diese Einstellung keine Auswirkung. Administratoren werden immer alle Aufträge '
      + 'angezeigt.',
  },
};
