import axios from '../../Library/Axios';

const defaultUri = '/auth';

export const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';

/**
 * login()
 * @param mail
 * @param password
 * @returns {function(*): Promise<any | never>}
 */
export function login(mail, password) {
  return (dispatch) => {
    dispatch({ type: AUTH_LOGIN_START });

    return axios().post(defaultUri, { mail, password }).then((response) => {
      const { data } = response.data;
      dispatch({ type: AUTH_LOGIN_SUCCESS, payload: data });
      document.cookie = 'token=123456';
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: AUTH_LOGIN_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

/**
 * logout()
 * @returns {Function}
 */
export function logout() {
  return (dispatch) => {
    dispatch({ type: AUTH_LOGOUT_SUCCESS });
    localStorage.clear();
  };
}

export const AUTH_USER_UPDATE = 'AUTH_USER_UPDATE';

/**
 * updateUserData()
 * Update the user of the auth object
 * @param data
 * @returns {Function}
 */
export function updateUserData(data) {
  return (dispatch) => {
    dispatch({ type: AUTH_USER_UPDATE, payload: data });
  };
}
