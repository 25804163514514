import { useDispatch } from 'react-redux';
import React from 'react';
import axios from '../../../../../Library/Axios';
import { addItemDocument } from './DocumentAction';
import { defaultUri as serviceDefaultUri, get as getObjectService } from '../ServiceAction';

/**
 * Prepare default uri and replace all placeholders
 * @param service
 * @returns {string}
 */
function prepareUrl(service) {
  const defaultUri = `${serviceDefaultUri}/{serviceId}/item`;
  return defaultUri.replace('{orderId}', service.orderId)
    .replace('{objectId}', service.objectId)
    .replace('{serviceId}', service.serviceId);
}

export const OBJECT_SERVICE_ITEM_CREATE_START = 'OBJECT_SERVICE_ITEM_CREATE_START';
export const OBJECT_SERVICE_ITEM_CREATE_SUCCESS = 'OBJECT_SERVICE_ITEM_CREATE_SUCCESS';
export const OBJECT_SERVICE_ITEM_CREATE_ERROR = 'OBJECT_SERVICE_ITEM_CREATE_ERROR';

/**
 * create()
 * @param service
 * @param item
 * @param sendNotification
 * @returns {function(*): *}
 */
export function create(service, item, sendNotification = true) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_ITEM_CREATE_START, payload: service });
    const config = {
      params: { sendNotification: (sendNotification) ? 1 : 0 },
    };
    return axios().post(prepareUrl(service), item, config).then((response) => {
      const { data } = response.data;

      const promiseAll = [];
      item.documents.forEach((document) => {
        const promise = dispatch(addItemDocument(service, data, document.file));
        promiseAll.push(promise);
      });

      return Promise.all(promiseAll).then(() => {
        dispatch({ type: OBJECT_SERVICE_ITEM_CREATE_SUCCESS, payload: data });
        return Promise.resolve(data);
      });
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_ITEM_CREATE_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

export const OBJECT_SERVICE_ITEM_DELETE_START = 'OBJECT_SERVICE_ITEM_DELETE_START';
export const OBJECT_SERVICE_ITEM_DELETE_SUCCESS = 'OBJECT_SERVICE_ITEM_DELETE_SUCCESS';
export const OBJECT_SERVICE_ITEM_DELETE_ERROR = 'OBJECT_SERVICE_ITEM_DELETE_ERROR';

/**
 * create()
 * @param service
 * @param itemId
 * @returns {function(*): *}
 */
export function deleteItem(service, itemId) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_ITEM_DELETE_START, payload: service });

    const uri = `${prepareUrl(service)}/${itemId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_ITEM_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_ITEM_DELETE_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

export const OBJECT_SERVICE_ITEM_UPDATE_START = 'OBJECT_SERVICE_ITEM_UPDATE_START';
export const OBJECT_SERVICE_ITEM_UPDATE_SUCCESS = 'OBJECT_SERVICE_ITEM_UPDATE_SUCCESS';
export const OBJECT_SERVICE_ITEM_UPDATE_ERROR = 'OBJECT_SERVICE_ITEM_UPDATE_ERROR';

/**
 * useDispatchUpdateObjectServiceItem()
 * @returns {function(*=): *}
 */
export function useDispatchUpdateObjectServiceItem() {
  const dispatch = useDispatch();

  return React.useCallback((item) => {
    const uri = `${prepareUrl(item.serviceId)}/${item.itemId}`;
    dispatch({ type: OBJECT_SERVICE_ITEM_UPDATE_START, payload: { uri } });

    return axios().put(uri, item).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_ITEM_UPDATE_SUCCESS, payload: data });
      dispatch(getObjectService(item.serviceId));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_ITEM_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
