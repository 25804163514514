import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled, { useTheme } from 'styled-components';
import AppointmentContainer from '../Appointment/AppointmentContainer';
import UserHourCell from './UserHourCell';
import prepareOverlappingAppointments from '../Functions';

const Container = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => props.theme.timelineWidth}px;
`;

/**
 * UserTimeLineRow()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UserTimeLineRow(props) {
  const {
    data, selectedDate, rowHeight, onDrop, onClickAppointment, onClickHour,
  } = props;
  const theme = useTheme();

  const renderTimes = () => {
    const {
      startHour, endHour, workDayStart, workDayEnd,
    } = theme;
    const startHourInt = parseInt(moment(workDayStart, 'HH:mm:ss').format('H'), 0);
    const endHourInt = parseInt(moment(workDayEnd, 'HH:mm:ss').format('H'), 0);
    const result = [];

    for (let i = startHour; i <= endHour; i += 1) {
      result.push(
        <UserHourCell
          key={i}
          hour={i}
          cellHeight={rowHeight}
          onDrop={onDrop}
          data={data}
          selectedDate={selectedDate}
          isInWorkTime={(i >= startHourInt && i < endHourInt)}
          onClick={(hour) => onClickHour(hour, data)}
        />,
      );
    }

    return result;
  };

  const renderAppointment = () => {
    const { cellWidth } = theme;
    const filtered = data.data.filter((param) => moment(param.date).isSame(moment(selectedDate), 'day'));
    const prepared = prepareOverlappingAppointments(filtered, rowHeight);

    return prepared.map((param) => (
      <AppointmentContainer
        key={param.key}
        appointment={param}
        cellWidth={cellWidth}
        rowHeight={(param.height) || rowHeight}
        onClick={onClickAppointment}
        top={param.top}
      />
    ));
  };

  return (
    <Container>
      {renderAppointment()}
      {renderTimes()}
    </Container>
  );
}

UserTimeLineRow.propTypes = {
  onDrop: PropTypes.func,
  data: PropTypes.instanceOf(Object).isRequired,
  selectedDate: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
  onClickAppointment: PropTypes.func.isRequired,
  onClickHour: PropTypes.func.isRequired,
};

UserTimeLineRow.defaultProps = {
  onDrop: null,
};
