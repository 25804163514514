import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { get, update } from '../../../Redux/Action/ClientSubcontractorAction';
import { getAll, update as updateUser } from '../../../Redux/Action/UserAction';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import Panel from '../../../Components/Panel';
import Button from '../../../Components/Button';
import LoadingModal from '../../../Components/LoadingModal';
import { legalFormOptions, YesNoOption } from '../../../Library/Types';

const initialState = {
  name: '',
  legalForm: '',
  zip: '',
  city: '',
  street: '',
  streetNo: '',
  phone: '',
  mobile: '',
  fax: '',
  mail: '',
  web: '',
  contactPerson: '',
  active: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload, changed: true };
    case 'legalForm':
      return { ...state, legalForm: action.payload, changed: true };
    case 'zip':
      return { ...state, zip: action.payload, changed: true };
    case 'city':
      return { ...state, city: action.payload, changed: true };
    case 'street':
      return { ...state, street: action.payload, changed: true };
    case 'streetNo':
      return { ...state, streetNo: action.payload, changed: true };
    case 'phone':
      return { ...state, phone: action.payload, changed: true };
    case 'mobile':
      return { ...state, mobile: action.payload, changed: true };
    case 'fax':
      return { ...state, fax: action.payload, changed: true };
    case 'mail':
      return { ...state, mail: action.payload, changed: true };
    case 'web':
      return { ...state, web: action.payload, changed: true };
    case 'contactPerson':
      return { ...state, contactPerson: action.payload, changed: true };
    case 'active':
      return { ...state, active: action.payload, changed: true };
    case 'init':
      return { ...action.payload, changed: false };

    default:
      throw new Error();
  }
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Update(props) {
  const {
    dispatchUpdateSubcontractor, dispatchGet, dispatchUpdateUser, getAllUsers, history, client, users,
  } = props;
  const { subcontractorId } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [currentTabIndex, setCurrentTabIndex] = React.useState(null);
  const [subcontractor, setSubcontractor] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [errors, setError] = useState({});

  useEffect(() => {
    if (subcontractorId) {
      dispatchGet(subcontractorId).then((response) => {
        if (response.clientId === client.clientId) {
          setSubcontractor(response);
          dispatch({ type: 'init', payload: response });
          getAllUsers();
        } else {
          history.goBack();
        }
      });
    }
  }, [dispatchGet, subcontractorId, client.clientId, history, getAllUsers]);

  const saveData = () => {
    setSaving(true);
    dispatchUpdateSubcontractor(subcontractorId, state).then(() => {
      users.filter((user) => user.subcontractorId === parseInt(subcontractorId, 10)).forEach(
        (entry) => {
          const updateEntry = entry;
          updateEntry.active = state.active;
          dispatchUpdateUser(updateEntry.userId, updateEntry).then(() => {
          });
        },
      );
      setSaving(false);
      history.push('/admin/subcontractor');
    }).catch((error) => {
      if (error.data && error.data.errorMessage) {
        let errorData = {};
        Object.keys(error.data.errorMessage).forEach((key) => {
          errorData = { ...errorData, [key]: true };
        });
        setError(errorData);
        setSaving(false);
      }
    });
  };

  if (subcontractor) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Panel marginBottom={25}>
            <h4 style={{ margin: 0 }}>Subunternehmen bearbeiten</h4>
          </Panel>
        </div>
        <div className="col-lg-12">
          <Panel marginBottom={25}>
            <div className="row">
              <div className="col-lg-12">
                <KeyValueEditRow
                  title="Name"
                  initialValue={state.name}
                  displayText={state.name}
                  onChange={(value) => dispatch({ type: 'name', payload: value })}
                  containerTabIndex={1}
                  startEditMode={(currentTabIndex === 1)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.name)}
                  required
                />
              </div>
              <div className="col-lg-12">
                <KeyValueEditRow
                  title="Rechtsform"
                  initialValue={state.legalForm}
                  displayText={
                    (state.legalForm) ? legalFormOptions.filter((item) => item.value === state.legalForm)[0].label : ''
                  }
                  onChange={(value) => dispatch({ type: 'legalForm', payload: value })}
                  containerTabIndex={2}
                  startEditMode={(currentTabIndex === 2)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  type="select"
                  selectData={legalFormOptions}
                />
              </div>
              <div className="col-lg-12">
                <KeyValueEditRow
                  title="E-mail"
                  initialValue={state.mail}
                  displayText={state.mail}
                  onChange={(value) => dispatch({ type: 'mail', payload: value })}
                  containerTabIndex={3}
                  startEditMode={(currentTabIndex === 3)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.mail)}
                  required
                />
              </div>
              <div className="col-lg-4">
                <KeyValueEditRow
                  title="Postleitzahl"
                  initialValue={state.zip}
                  displayText={state.zip}
                  onChange={(value) => dispatch({ type: 'zip', payload: value })}
                  containerTabIndex={4}
                  startEditMode={(currentTabIndex === 4)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-8">
                <KeyValueEditRow
                  title="Stadt"
                  initialValue={state.city}
                  displayText={state.city}
                  onChange={(value) => dispatch({ type: 'city', payload: value.toString().toUpperCase() })}
                  containerTabIndex={5}
                  startEditMode={(currentTabIndex === 5)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-8">
                <KeyValueEditRow
                  title="Straße"
                  initialValue={state.street}
                  displayText={state.street}
                  onChange={(value) => dispatch({ type: 'street', payload: value })}
                  containerTabIndex={6}
                  startEditMode={(currentTabIndex === 6)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-4">
                <KeyValueEditRow
                  title="Straßennummer"
                  initialValue={state.streetNo}
                  displayText={state.streetNo}
                  onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
                  containerTabIndex={7}
                  startEditMode={(currentTabIndex === 7)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: 30 }}>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Telefonnummer"
                  initialValue={state.phone}
                  displayText={state.phone}
                  onChange={(value) => dispatch({ type: 'phone', payload: value })}
                  containerTabIndex={8}
                  startEditMode={(currentTabIndex === 8)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Mobilfunknummer"
                  initialValue={state.mobile}
                  displayText={state.mobile}
                  onChange={(value) => dispatch({ type: 'mobile', payload: value })}
                  containerTabIndex={9}
                  startEditMode={(currentTabIndex === 9)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Fax"
                  initialValue={state.fax}
                  displayText={state.fax}
                  onChange={(value) => dispatch({ type: 'fax', payload: value })}
                  containerTabIndex={10}
                  startEditMode={(currentTabIndex === 10)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Webseite"
                  initialValue={state.web}
                  displayText={state.web}
                  onChange={(value) => dispatch({ type: 'web', payload: value })}
                  containerTabIndex={11}
                  startEditMode={(currentTabIndex === 11)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Kontaktperson"
                  initialValue={state.contactPerson}
                  displayText={state.contactPerson}
                  onChange={(value) => dispatch({ type: 'contactPerson', payload: value })}
                  containerTabIndex={12}
                  startEditMode={(currentTabIndex === 12)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  type="select"
                  title="Status Subunternehmen"
                  selectData={YesNoOption}
                  initialValue={state.active}
                  displayText={
                    (state.active)
                      ? 'Aktiv, Anmeldung für zugewiesene Benutzer möglich'
                      : 'Inaktiv, keine Anmeldung für zugewiesene Benutzer möglich'
                  }
                  onChange={(value) => (dispatch({ type: 'active', payload: value }))}
                  containerTabIndex={12}
                  startEditMode={(currentTabIndex === 12)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-lg-12">
          <Panel withPadding={false}>
            <div className="ButtonContainer d-flex justify-content-end">
              <Button type="btn-light" onClick={() => history.goBack()}>Abbrechen</Button>
              <Button disabled={!state.changed} onClick={saveData}>
                Änderungen speichern
              </Button>
            </div>
          </Panel>
        </div>

        <LoadingModal visible={saving} text="Daten werden gespeichert..." />
      </div>
    );
  }
  return null;
}

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    users: store.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: (subcontractorId) => dispatch(get(subcontractorId)),
    dispatchUpdateSubcontractor: (subcontractorId, subcontractorData) => dispatch(
      update(subcontractorId, subcontractorData),
    ),
    getAllUsers: () => dispatch(getAll(false)),
    dispatchUpdateUser: (userId, userData) => dispatch(updateUser(userId, userData)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Update));

Update.propTypes = {
  dispatchUpdateSubcontractor: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  dispatchUpdateUser: PropTypes.func.isRequired,
  dispatchGet: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  users: PropTypes.instanceOf(Object).isRequired,
};
