import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import './style.css';

/**
 * ProfileImage()
 * @param props
 * @returns {*}
 * @constructor
 */
function Avatar(props) {
  const {
    size, imagePath, iconSize,
  } = props;
  const [style, setStyle] = useState({});

  useEffect(() => {
    const styler = { width: size, height: size, borderRadius: (size / 2) };
    setStyle(styler);
  }, [size]);

  const renderIcon = () => {
    if (!imagePath) {
      return (
        <i className="fas fa-user-tie" style={{ fontSize: (iconSize) || size }} />
      );
    }
    return null;
  };

  return (
    <div className="Avatar">
      <div className="Container" style={style}>
        {renderIcon()}
      </div>
    </div>
  );
}

Avatar.propTypes = {
  size: PropTypes.number,
  imagePath: PropTypes.string,
  iconSize: PropTypes.number,
};

Avatar.defaultProps = {
  size: 200,
  imagePath: null,
  iconSize: null,
};

function mapStoreToProps(store) {
  return {
    isLoading: store.user.isLoading,
  };
}

function mapDispatchToProps() {
  return {

  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Avatar));
