import React from 'react';
import PropTypes from 'prop-types/prop-types';

import './style.css';

const headerHk = require('../../../../Assets/Images/partner-fitter.jpg');
const appAdv = require('../../../../Assets/Images/App/iphone_screen2.png');

/**
 * MonteurHeader()
 * @returns {*}
 * @constructor
 */
export default function MonteurHeader(props) {
  const { image, withText, height } = props;

  const renderChildren = () => {
    if (withText) {
      return (
        <div className="container d-flex flex-column">
          <div className="container d-flex flex-row">
            <div>
              <div className="Headline">UMSATZ </div>
              <div className="Headline">ERHÖHEN </div>
              <div className="Headline">MIT DER </div>
              <div className="Headline hk-text-primary">HEY.KITCHEN</div>
              <div className="Headline">PARTNER APP</div>
            </div>
            <div className="d-flex justify-content-end flex-fill">
              <div>
                <img src={appAdv} alt="App Werbung" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className="MonteurHeader FullPageBox Light"
      style={{ backgroundImage: `url(${(image) || headerHk})`, height: (height) || 'auto' }}
    >
      {renderChildren()}
    </div>
  );
}

MonteurHeader.propTypes = {
  image: PropTypes.string,
  withText: PropTypes.bool,
  height: PropTypes.number,
};

MonteurHeader.defaultProps = {
  image: null,
  withText: true,
  height: null,
};
