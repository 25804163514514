/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getDaysOfMonth } from '../../Library/Functions';
import MonthSelector from './Components/MonthSelector';

import './style.css';

/**
 * DayNames()
 * @returns {*}
 * @constructor
 */
function DayNames(props) {
  const { showSunday } = props;

  const renderSunday = () => {
    if (showSunday) {
      return (
        <div className="DayName">
          Sonntag
        </div>
      );
    }
    return null;
  };

  const getClasses = () => {
    if (showSunday) {
      return 'DayName';
    }
    return 'DayName WithoutSunday';
  };

  return (
    <div className="DayNamesContainer">
      <div className={getClasses()}>
        Montag
      </div>
      <div className={getClasses()}>
        Dienstag
      </div>
      <div className={getClasses()}>
        Mittwoch
      </div>
      <div className={getClasses()}>
        Donnerstag
      </div>
      <div className={getClasses()}>
        Freitag
      </div>
      <div className={getClasses()}>
        Samstag
      </div>
      {renderSunday()}
    </div>
  );
}

DayNames.propTypes = {
  showSunday: PropTypes.bool.isRequired,
};

/**
 * SchedulerTile()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function SchedulerTile(props) {
  const {
    calendarData, userCount, teamCount, onMonthChange, currentMonth, onSelectDay, showSunday, overallAssemblyTime,
    BadgeComponent, monthSelectorLeftActions, monthSelectorRightActions,
  } = props;
  const containerRef = useRef(null);

  const handlePressDay = (item) => {
    onSelectDay(item);
  };

  return (
    <div className="Scheduler">
      <MonthSelector
        currentMonth={currentMonth}
        onNext={onMonthChange}
        onPrev={onMonthChange}
        leftContainer={monthSelectorLeftActions}
        rightContainer={monthSelectorRightActions}
      />
      <hr />
      <DayNames showSunday={showSunday} />
      <div className="CalendarContainer" ref={containerRef}>
        {getDaysOfMonth(currentMonth).map((item) => (
          <BadgeComponent
            item={item}
            key={moment(item).format('YYYY-MM-DD')}
            onPress={handlePressDay}
            {...props}
          />
        ))}
      </div>
    </div>
  );
}

SchedulerTile.propTypes = {
  calendarData: PropTypes.instanceOf(Object),
  userCount: PropTypes.number,
  teamCount: PropTypes.number,
  onMonthChange: PropTypes.func.isRequired,
  currentMonth: PropTypes.instanceOf(Object).isRequired,
  onSelectDay: PropTypes.func.isRequired,
  showSunday: PropTypes.bool,
  overallAssemblyTime: PropTypes.number,
  BadgeComponent: PropTypes.instanceOf(Object).isRequired,
  monthSelectorLeftActions: PropTypes.instanceOf(Object),
  monthSelectorRightActions: PropTypes.instanceOf(Object),
};

SchedulerTile.defaultProps = {
  calendarData: {},
  userCount: 0,
  teamCount: 0,
  showSunday: false,
  overallAssemblyTime: 0,
  monthSelectorLeftActions: null,
  monthSelectorRightActions: null,
};
