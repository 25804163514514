import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../ModalDialog';
import { formatCurrency } from '../../../Library/Functions';

/**
 * OrderPriceChangeAlert()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function OrderPriceChangeAlert(props) {
  const {
    calculation, visible, onClose, onConfirm, order,
  } = props;

  const renderDiscount = () => {
    if (calculation.discountPrice && calculation.discountPrice > 0) {
      return (
        <tr>
          <td>Rabatt durch Gutschein:</td>
          <td colSpan={3}>{formatCurrency(calculation.discountPrice)}</td>
        </tr>
      );
    }
    return null;
  };

  const diffDealer = () => (calculation.dealerPrice - order.dealerPrice);

  const diffMonteur = () => (calculation.monteurPrice - order.mechanicPrice);

  if (calculation && visible && order) {
    return (
      <ModalDialog onClose={onClose} visible={visible} onConfirm={onConfirm} confirmCaption="Preise übernehmen">
        <h5>Achtung Preisänderung!</h5>
        <p>
          Die Änderungen ergeben einen neuen Preis. Möchten Sie den neuen Preis übernehmen?
        </p>

        <table className="table" style={{ marginTop: 30 }}>
          <thead>
            <tr>
              <th />
              <th>Alter Preis</th>
              <th>Neuer Preis</th>
              <th>Preisdifferenz</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Verkäufer:</td>
              <td>{formatCurrency(order.dealerPrice)}</td>
              <td><strong>{formatCurrency(calculation.dealerPrice)}</strong></td>
              <td className={(diffDealer() > 0) ? 'text-success' : 'text-danger'}>
                {formatCurrency(diffDealer())}
              </td>
            </tr>
            <tr>
              <td>Monteur:</td>
              <td>{formatCurrency(order.mechanicPrice)}</td>
              <td><strong>{formatCurrency(calculation.monteurPrice)}</strong></td>
              <td className={(diffMonteur() > 0) ? 'text-success' : 'text-danger'}>
                {formatCurrency(diffMonteur())}
              </td>
            </tr>
            {renderDiscount()}
          </tbody>
        </table>
      </ModalDialog>
    );
  }
  return null;
}

OrderPriceChangeAlert.propTypes = {
  calculation: PropTypes.instanceOf(Object),
  order: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

OrderPriceChangeAlert.defaultProps = {
  order: null,
  calculation: null,
  visible: false,
};
