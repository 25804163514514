import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PublicLayout from '../Components/Layout/PublicLayout';
import PublicNavigation from '../Components/Navigation/PublicNavigation';
import Web from '../Pages/Frontend/Saas/Web';
import App from '../Pages/Frontend/Saas/App';
import Security from '../Pages/Frontend/Security';
import Imprint from '../Pages/Frontend/Imprint';
import Login from '../Pages/Login';
import ResendPassword from '../Pages/ResendPassword';
import ApproveRegistration from '../Pages/ApproveRegistration';
import Register from '../Pages/Register';
import MonteurStartPage from '../Pages/Frontend/Monteur/Startpage';
import LandingPage from '../Pages/Frontend/LandingPage';
import Contact from '../Pages/Frontend/Contact';
import ErklaerVideo from '../Pages/Frontend/ErklaerVideo';
import Aktion2020 from '../Pages/Frontend/Aktion2020';
import Invite from '../Pages/Invite';

/**
 * PublicRoute()
 * @returns {*}
 * @constructor
 */
export default function PublicRoute() {
  return (
    <PublicLayout navigation={<PublicNavigation />}>
      <Switch>
        <Route exact path="/web"><Web /></Route>
        <Route exact path="/app"><App /></Route>
        <Route exact path="/datenschutz"><Security /></Route>
        <Route exact path="/impressum"><Imprint /></Route>
        <Route exact path="/erklaervideo"><ErklaerVideo /></Route>
        <Route exact path="/aktion2020"><Aktion2020 /></Route>
        <Route path="/kontakt"><Contact /></Route>

        <Route path="/login"><Login /></Route>
        <Route path="/register/approve/:clientId/:hash"><ApproveRegistration /></Route>
        <Route path="/register"><Register /></Route>
        <Route path="/invite/:hash"><Invite /></Route>
        <Route path="/newpassword"><ResendPassword /></Route>

        <Route path="/partner"><MonteurStartPage /></Route>
        <Route path="/"><LandingPage /></Route>
        <Route render={() => <Redirect to="/saas" />} />
      </Switch>
    </PublicLayout>
  );
}
