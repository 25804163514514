/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { formatCurrency, formatDate } from '../../Library/Functions';
import StatusBadge from '../StatusBadge';
import Button from '../Button';

/**
 * Row()
 * @param props
 * @returns {*}
 * @constructor
 */
function Row(props) {
  const {
    item, onAction, actionIcon, onDownload, onCheckItem, selectable,
  } = props;

  const renderTypeIcon = () => {
    if (item.isSubscription) {
      return <i className="far fa-credit-card hk-text-primary" title="Mitgliedschaftsverlängerung" />;
    }
    if (item.isDebit) {
      return (
        <i
          className="far fa-money-bill-alt hk-text-primary"
          title="Rechnung zur Küchenmontage oder Reklamationsauftrag"
        />
      );
    }
    if (item.isVoucher) {
      return (
        <i
          className="fas fa-money-check text-success"
          title="Gutschrift zur Küchenmontage oder Reklamationsauftrag"
        />
      );
    }
    return null;
  };

  const getPaymentMethod = () => {
    if (item.paymentMethod === 'debit') {
      if (item.isVoucher) {
        return 'Überweisung';
      }
      return 'Lastschrifteinzug';
    }
    return 'Rechnung';
  };

  const renderStatus = () => {
    if (!item.processed) {
      return <StatusBadge icon="fas fa-times-circle" caption="Offen" type="Danger" />;
    }
    if (item.isVoucher) {
      return <StatusBadge icon="fas fa-check-circle" caption="Überwiesen" type="Success" />;
    }
    return <StatusBadge icon="fas fa-check-circle" caption="Bezahlt" type="Success" />;
  };

  const getPrice = () => {
    if (item.isVoucher) {
      return <td className={`${(!item.processed) ? 'text-success' : ''}`}>{`${formatCurrency(item.priceBrutto)}`}</td>;
    }
    return <td className={`${(!item.processed) ? 'hk-text-primary' : ''}`}>{`${formatCurrency(item.priceBrutto)}`}</td>;
  };

  const handleOnDownload = (e) => {
    e.stopPropagation();
    onDownload(item);
  };

  const renderDownloadButton = () => {
    if (onDownload) {
      return (
        <Button onClick={handleOnDownload} className="btn-sm" type="btn-light">
          <i className="fas fa-file-pdf" />
        </Button>
      );
    }
    return null;
  };

  const renderSelectable = () => {
    if (selectable) {
      return (
        <td onClick={() => onCheckItem(item)} tabIndex={-1} onKeyPress={() => {}}>
          <input type="checkbox" checked={item.selected} onChange={() => onCheckItem(item)} />
        </td>
      );
    }
    return null;
  };

  const handleOnAction = (e) => {
    e.stopPropagation();
    onAction(item);
  };

  return (
    <tr onClick={(onCheckItem) ? () => onCheckItem(item) : () => {}} tabIndex={-1} onKeyPress={() => {}}>
      {renderSelectable()}
      <td>{renderTypeIcon()}</td>
      <td>{item.invoiceNumber}</td>
      <td>{formatDate(item.createdDate)}</td>
      <td>{formatDate(item.dueDate)}</td>
      <td>{getPaymentMethod()}</td>
      {getPrice()}
      <td>{renderStatus()}</td>
      <td width={80}>
        <div className="d-flex flex-row">
          <Button onClick={handleOnAction} className="btn-sm" type="btn-light" style={{ marginRight: 10 }}>
            <i className={actionIcon} />
          </Button>
          {renderDownloadButton()}
        </div>
      </td>
    </tr>
  );
}

Row.propTypes = {
  onCheckItem: PropTypes.func,
  item: PropTypes.instanceOf(Object).isRequired,
  onAction: PropTypes.func.isRequired,
  actionIcon: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
  selectable: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  onDownload: null,
  onCheckItem: null,
};

/**
 * InvoiceTable()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function InvoiceTable(props) {
  const {
    transactions, onAction, actionIcon, onDownload, onCheckItem, selectable,
  } = props;

  const renderSelectableHeader = () => {
    if (selectable) {
      return <th />;
    }
    return null;
  };

  if (transactions && transactions.length > 0) {
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            {renderSelectableHeader()}
            <th />
            <th>Rg. Nummer</th>
            <th>Rg. Datum</th>
            <th>Fälligkeitsdatum</th>
            <th>Zahlungsart</th>
            <th>Betrag</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {transactions.map((item) => (
            <Row
              item={item}
              key={item.transactionId}
              onAction={onAction}
              actionIcon={actionIcon}
              onDownload={onDownload}
              onCheckItem={onCheckItem}
              selectable={selectable}
            />
          ))}
        </tbody>
      </table>
    );
  }
  return null;
}

InvoiceTable.propTypes = {
  onCheckItem: (props) => {
    const { selectable, onCheckItem } = props;
    if (selectable && !onCheckItem) {
      return new Error('onCheckItem must be defined when selectable');
    }
    return null;
  },
  selectable: PropTypes.bool,
  transactions: PropTypes.instanceOf(Array),
  onAction: PropTypes.func.isRequired,
  actionIcon: PropTypes.string,
  onDownload: PropTypes.func,
};

InvoiceTable.defaultProps = {
  onCheckItem: null,
  actionIcon: 'fas fa-file-pdf',
  transactions: null,
  onDownload: null,
  selectable: false,
};
