import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import DatePickerInput from '../../../../Components/DatePickerInput';
import { calculate } from '../../../../Redux/Action/OrderAction';
import { useDispatchGetNextPossibleMontageDate } from '../../../../Redux/Action/CalendarAction';
import { formatDate } from '../../../../Library/Functions';
import Button from '../../../../Components/Button';
import WeekPickerInput from '../../../../Components/WeekPickerInput';
import { useAccessCanScheduleObjects } from '../../../../Library/AccessChecks';
import { ORDERTYPE_SINGLE } from '../../../../Library/Types';

function initReducer(data) {
  return { ...data };
}

function reducer(state, action) {
  switch (action.type) {
    case 'deliveryDateStart':
      return { ...state, deliveryDateStart: action.payload, deliveryWeekStart: null };
    case 'deliveryDateEnd':
      return { ...state, deliveryDateEnd: action.payload };
    case 'deliveryWeekStart':
      return { ...state, deliveryWeekStart: action.payload, deliveryDateStart: null };

    default:
      throw new Error();
  }
}

/**
 * DeliveryDate()
 * @param props
 * @returns {*}
 * @constructor
 */
function DeliveryDate(props) {
  const {
    onChange, editableData, errors, orderType, isInternal, createOrderData, dispatchCalculateOrder, isMandantOrder,
  } = props;
  const dispatchGetNextPossibleDates = useDispatchGetNextPossibleMontageDate();

  const isTerminable = useAccessCanScheduleObjects();
  const [state, dispatch] = useReducer(reducer, editableData, initReducer);
  const [prevState, setPrevState] = useState(JSON.stringify(state));
  const [showNextDates, setShowNextDates] = useState(false);
  const [nextDates, setNextDates] = useState([]);
  const [nextDatesError, setNextDatesError] = useState(false);
  const [loadingDates, setLoadingDates] = useState(false);
  const [clearDeliveryDate, setClearDeliveryDate] = useState(null);
  const [clearDeliveryWeek, setClearDeliveryWeek] = useState(null);

  useEffect(() => {
    if (JSON.stringify(state) !== prevState) {
      if (onChange) {
        onChange(state);
        setPrevState(JSON.stringify(state));
      }
    }
  }, [onChange, state, prevState]);

  useEffect(() => {
    if (showNextDates && createOrderData) {
      setLoadingDates(true);
      setNextDatesError(false);
      dispatchCalculateOrder(createOrderData).then((calculationResult) => {
        dispatchGetNextPossibleDates(calculationResult.assemblyTime).then((dateResponse) => {
          setNextDates(dateResponse);
          setLoadingDates(false);
        }).catch(() => setLoadingDates(false));
      }).catch(() => {
        setShowNextDates(false);
        setNextDatesError(true);
        setLoadingDates(false);
      });
    }
  }, [createOrderData, showNextDates, dispatchCalculateOrder, dispatchGetNextPossibleDates]);

  const getCaption = () => {
    if (orderType === 'SINGLE') {
      if (isInternal && !isTerminable) {
        return 'Wunsch-Kalenderwoche';
      }
      return 'Montagetermin';
    }
    return null;
  };

  const getDescription = () => {
    if (!isInternal) {
      return (
        <p className="text-muted">
          Bitte geben Sie Ihr gewünschten Montagetermin an. Sollte der gewünschte Termin innerhalb der nächsten 4
          Wochen liegen, wird Ihr Auftrag manuell geprüft und Sie erhalten zeitnah ein passendes Angebot von uns!
        </p>
      );
    }
    if (isInternal && !isTerminable) {
      return (
        <p className="text-muted">
          Bitte geben Sie eine Kalenderwoche an, ab welcher die Montage ausgeführt werden kann/soll. Der genaue Termin
          wird von der Disposition mit dem Kunden abgestimmt.
        </p>
      );
    }

    return null;
  };

  const getMinDate = () => {
    if (isInternal) {
      return moment().toDate();
    }
    return moment().add(1, 'days').toDate();
  };

  const handleSelectDate = (data) => {
    dispatch({ type: 'deliveryDateStart', payload: data });
    setShowNextDates(false);
  };

  const renderNextPossibleDates = () => {
    if (showNextDates && createOrderData !== null && nextDates && nextDates.length > 0) {
      return (
        <>
          <p>
            Folgende Termine sind auf Basis Ihrer Disposition und der Verfügbarkeit Ihrer Monteure für
            diesen Auftrag möglich. Klicken Sie auf einen Termin um diesen zu übernehmen.
          </p>
          <table className="table table-hover table-bordered table-striped">
            <tbody>
              {nextDates.map((data) => (
                <tr key={data} onClick={() => handleSelectDate(data)}>
                  <td>{formatDate(data)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      );
    }
    return null;
  };

  const renderShowDatesButton = () => {
    if (isInternal && !isMandantOrder) {
      if (!showNextDates) {
        return (
          <Button type="btn btn-sm btn-light" onClick={() => setShowNextDates(!showNextDates)}>
            <i className="fas fa-calendar-alt" />
            Mögliche Termine anzeigen
          </Button>
        );
      }
      return (
        <Button type="btn btn-sm btn-light" onClick={() => setShowNextDates(!showNextDates)}>
          <i className="fas fa-times-circle" />
          Termine ausblenden
        </Button>
      );
    }
    return null;
  };

  const renderNextDatesError = () => {
    if (nextDatesError) {
      return (
        <div className="alert alert-danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          Bitte füllen Sie erst alle benötigten Angaben zum Auftrag aus und versuchen Sie dann erneut
          die Termine aufzurufen!
        </div>
      );
    }
    return null;
  };

  const renderLoadingDates = () => {
    if (loadingDates) {
      return (
        <div className="alert alert-dark d-flex justify-content-center">
          <div>
            <i className="fas fa-spin fa-spinner" style={{ marginRight: 10 }} />
            Termine werden geladen...
          </div>
        </div>
      );
    }
    return null;
  };

  const onChangeWeekPickerInput = (value) => {
    dispatch({ type: 'deliveryWeekStart', payload: value });
    if (clearDeliveryDate && value) {
      setClearDeliveryDate(false);
    }
    if (!clearDeliveryDate && value) {
      setClearDeliveryDate(true);
    }
  };

  const onChangeDatePickerInput = (value) => {
    dispatch({ type: 'deliveryDateStart', payload: value });
    if (clearDeliveryWeek) {
      setClearDeliveryWeek(false);
    } else {
      setClearDeliveryWeek(true);
    }
  };

  const renderWeekPickerInput = () => {
    if (isInternal) {
      return (
        <div className="d-flex flex-row" style={{ marginTop: 30 }}>
          <div className="flex-grow-1">
            <DatePickerInput
              onChange={(value) => onChangeDatePickerInput(value)}
              label={getCaption()}
              hasError={!!errors.deliveryDateStart}
              rightIcon="far fa-calendar-alt"
              initialValue={state.deliveryDateStart}
              minDate={getMinDate()}
              clearValue={clearDeliveryDate}
            />
          </div>
          <div style={{
            marginTop: 35, marginLeft: 0, marginRight: 15, textAlign: 'center', width: 100,
          }}
          >
            oder
          </div>
          <div className="flex-grow-1">
            <div>
              <label style={{ fontSize: '14px' }}>
                Wunsch-Kalenderwoche
              </label>
              <WeekPickerInput
                value={state.deliveryWeekStart}
                onChange={(value) => onChangeWeekPickerInput(value)}
                clearValue={clearDeliveryWeek}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <DatePickerInput
        onChange={(value) => onChangeDatePickerInput(value)}
        label={getCaption()}
        hasError={!!errors.deliveryDateStart}
        rightIcon="far fa-calendar-alt"
        initialValue={state.deliveryDateStart}
        minDate={getMinDate()}
        clearValue={clearDeliveryDate}
      />
    );
  };

  if (orderType === ORDERTYPE_SINGLE) {
    if (!isTerminable && isInternal) {
      return (
        <div>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h5>{`7. ${getCaption()}`}</h5>
              {getDescription()}
            </div>
          </div>
          <div className="flex-grow-1">
            <div>
              <label style={{ fontSize: '14px' }}>
                Wunsch-Kalenderwoche
              </label>
              <WeekPickerInput
                onChange={(value) => onChangeWeekPickerInput(value)}
                clearValue={clearDeliveryWeek}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            <h5>{`7. ${getCaption()}`}</h5>
            {getDescription()}
          </div>
          <div>
            {renderShowDatesButton()}
          </div>
        </div>
        {renderWeekPickerInput()}
        {renderLoadingDates()}
        {renderNextDatesError()}
        {renderNextPossibleDates()}
      </div>
    );
  }

  return (
    <div>
      <h5>5. Lieferzeitraum</h5>
      <p className="text-muted">
        Bitte geben Sie Ihr gewünschtes Lieferzeitraum an
      </p>

      <div className="row">
        <DatePickerInput
          onChange={(value) => dispatch({ type: 'deliveryDateStart', payload: value })}
          label="Lieferzeitraum von"
          hasError={!!errors.deliveryDateStart}
          rightIcon="far fa-calendar-alt"
          initialValue={state.deliveryDateStart}
          minDate={moment().add(1, 'days').toDate()}
          rowClass="col-lg-6"
        />
        <DatePickerInput
          onChange={(value) => dispatch({ type: 'deliveryDateEnd', payload: value })}
          label="Lieferzeitraum bis"
          hasError={!!errors.deliveryDateEnd}
          rightIcon="far fa-calendar-alt"
          initialValue={state.deliveryDateEnd}
          minDate={(state.deliveryDateStart) ? moment(state.deliveryDateStart).add(1, 'days').toDate() : null}
          rowClass="col-lg-6"
        />
      </div>
    </div>
  );
}

DeliveryDate.propTypes = {
  dispatchCalculateOrder: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  editableData: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  orderType: PropTypes.oneOf(['SINGLE', 'OBJECT']).isRequired,
  isInternal: PropTypes.bool,
  createOrderData: PropTypes.instanceOf(Object),
  isMandantOrder: PropTypes.bool,
};

DeliveryDate.defaultProps = {
  editableData: {},
  errors: {},
  isInternal: false,
  createOrderData: null,
  isMandantOrder: false,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchCalculateOrder: (orderData) => dispatch(calculate(orderData)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(DeliveryDate);
