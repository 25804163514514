import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Button from '../../../Button';

/**
 * DeleteButton()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function DeleteButton(props) {
  const { disposition, onClick } = props;

  if (disposition && disposition.dispositionId) {
    return (
      <Button onClick={onClick} type="btn btn-outline-danger">Löschen</Button>
    );
  }
  return null;
}

DeleteButton.propTypes = {
  disposition: PropTypes.instanceOf(Object),
  onClick: PropTypes.func.isRequired,
};

DeleteButton.defaultProps = {
  disposition: null,
};
