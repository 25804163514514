import axios from '../../../../Library/Axios';

const defaultUri = '/system/client/{clientId}/note';

export const SYSTEM_CLIENTNOTE_GETLIST_START = 'SYSTEM_CLIENTNOTE_GETLIST_START';
export const SYSTEM_CLIENTNOTE_GETLIST_SUCCESS = 'SYSTEM_CLIENTNOTE_GETLIST_SUCCESS';
export const SYSTEM_CLIENTNOTE_GETLIST_ERROR = 'SYSTEM_CLIENTNOTE_GETLIST_ERROR';

/**
 * getList()
 * @param clientId
 * @returns {function(*): Promise<unknown>}
 */
export function getList(clientId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENTNOTE_GETLIST_START });

    const uri = defaultUri.replace('{clientId}', clientId);

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENTNOTE_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENTNOTE_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_CLIENTNOTE_CREATE_START = 'SYSTEM_CLIENTNOTE_CREATE_START';
export const SYSTEM_CLIENTNOTE_CREATE_SUCCESS = 'SYSTEM_CLIENTNOTE_CREATE_SUCCESS';
export const SYSTEM_CLIENTNOTE_CREATE_ERROR = 'SYSTEM_CLIENTNOTE_CREATE_ERROR';

/**
 * create()
 * @param note
 * @returns {function(*): Promise<unknown>}
 */
export function create(note) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENTNOTE_CREATE_START });

    const uri = defaultUri.replace('{clientId}', note.clientId);

    return axios().post(uri, note).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENTNOTE_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENTNOTE_CREATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_CLIENTNOTE_UPDATE_START = 'SYSTEM_CLIENTNOTE_UPDATE_START';
export const SYSTEM_CLIENTNOTE_UPDATE_SUCCESS = 'SYSTEM_CLIENTNOTE_UPDATE_SUCCESS';
export const SYSTEM_CLIENTNOTE_UPDATE_ERROR = 'SYSTEM_CLIENTNOTE_UPDATE_ERROR';

/**
 * update()
 * @param note
 * @returns {function(*): Promise<unknown>}
 */
export function update(note) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENTNOTE_UPDATE_START });

    const uri = `${defaultUri.replace('{clientId}', note.clientId)}/${note.id}`;

    return axios().put(uri, note).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENTNOTE_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENTNOTE_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_CLIENTNOTE_DELETE_START = 'SYSTEM_CLIENTNOTE_DELETE_START';
export const SYSTEM_CLIENTNOTE_DELETE_SUCCESS = 'SYSTEM_CLIENTNOTE_DELETE_SUCCESS';
export const SYSTEM_CLIENTNOTE_DELETE_ERROR = 'SYSTEM_CLIENTNOTE_DELETE_ERROR';

/**
 * deleteNote()
 * @param note
 * @returns {function(*): Promise<unknown>}
 */
export function deleteNote(note) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENTNOTE_DELETE_START });

    const uri = `${defaultUri.replace('{clientId}', note.clientId)}/${note.id}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENTNOTE_DELETE_SUCCESS, payload: data.id });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENTNOTE_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
