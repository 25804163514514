import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Switch from 'react-switch';
import * as Redux from 'react-redux';
import {
  getOrderOffers,
  useDispatchGetOpenOffers,
  getOrders,
  getReclamations,
  getServices,
} from '../../Redux/Action/ClientAction';
import {
  getOrders as getUserOrders,
  getReclamations as getUserReclamations,
  getServices as getUserServices,
} from '../../Redux/Action/UserAction';
import {
  LOCALSTORAGE_DASHBOARD_CONFIG,
  NAVIGATION_OBJECT_DETAILS,
  NAVIGATION_OBJECT_SERVICE_DETAILS,
  NAVIGATION_RECLAMATION_DETAILS,
} from '../../Library/Types';
import InternalBoxes from './Components/InternalBoxes';
import ExternalBoxes from './Components/ExternalBoxes';
import Panel from '../../Components/Panel';
import SwitchHintModal from './Components/SwitchHintModal';
import { ORDERSTATE_WAITING_APPROVEMENT } from '../../Library/StateTypes';
import { useAccessCanSeeAllClientOrders } from '../../Library/AccessChecks';

const localStorageDashboardLastView = '@dashboardLastView';

/**
 * Dashboard()
 * @returns {*|null}
 * @constructor
 */
function Dashboard(props) {
  const {
    dispatchGetReclamations, dispatchGetOrders, history, client, openOffers, offers, dispatchGetServices,
    dispatchGetUserOrders, dispatchGetUserReclamations, dispatchGetOrderOffers,
    dispatchGetUserServices, userGroups,
  } = props;
  const canSeeAllClientOrders = useAccessCanSeeAllClientOrders();
  const variant = (canSeeAllClientOrders) ? 'client' : 'user';
  const [showIntern, setShowIntern] = React.useState(true);
  const [showSwitchHint, setShowSwitchHint] = React.useState(false);
  const [showSwitch, setShowSwitch] = React.useState(false);
  const [ordersLoading, setOrdersLoading] = React.useState(false);
  const [reclamationsLoading, setReclamationsLoading] = React.useState(false);
  const [servicesLoading, setServicesLoading] = React.useState(false);
  const [storageConfig, setStorageConfig] = React.useState(null);

  const {
    internalOrders, externalOrders, internalReclamations, externalReclamations,
    internalServices, externalServices,
  } = Redux.useSelector((state) => state[variant]);

  const dispatchGetOpenOffers = useDispatchGetOpenOffers();

  React.useEffect(() => {
    const storageData = JSON.parse(localStorage.getItem(LOCALSTORAGE_DASHBOARD_CONFIG));
    setStorageConfig(storageData);
  }, []);

  React.useEffect(() => {
    setOrdersLoading(true);
    setReclamationsLoading(true);
    setServicesLoading(true);

    if (canSeeAllClientOrders) {
      dispatchGetOrders().then(() => dispatchGetOpenOffers().then(() => dispatchGetOrderOffers().then(() => {
        setOrdersLoading(false);
        return dispatchGetReclamations().then(() => {
          setReclamationsLoading(false);
          return dispatchGetServices().then(() => setServicesLoading(false));
        });
      }))).catch(() => {
        setOrdersLoading(false);
        setReclamationsLoading(false);
        setServicesLoading(false);
      });
    } else {
      dispatchGetUserOrders().then(() => {
        setOrdersLoading(false);
        dispatchGetUserReclamations().then(() => {
          setReclamationsLoading(false);
          dispatchGetUserServices().then(() => setServicesLoading(false));
        });
      }).catch(() => {
        setOrdersLoading(false);
        setReclamationsLoading(false);
        setServicesLoading(false);
      });
    }
  }, [
    dispatchGetOrders,
    dispatchGetReclamations,
    dispatchGetServices,
    dispatchGetUserOrders,
    dispatchGetUserReclamations,
    dispatchGetUserServices,
    userGroups,
    canSeeAllClientOrders,
    dispatchGetOpenOffers,
    dispatchGetOrderOffers,
  ]);

  React.useEffect(() => {
    if (internalReclamations.length === 0 && internalOrders.length === 0 && internalServices.length === 0) {
      setShowSwitch(false);
      setShowIntern(false);
    } else if (externalOrders.length === 0
              && externalReclamations.length === 0
              && externalServices.length === 0
              && openOffers.length === 0) {
      setShowSwitch(false);
      setShowIntern(true);
    } else {
      setShowSwitch(true);
      const lastView = localStorage.getItem(localStorageDashboardLastView);
      if (lastView) {
        setShowIntern((lastView === 'true'));
      } else {
        setShowIntern(true);
      }
    }
  }, [internalOrders, externalOrders, internalReclamations, externalReclamations, internalServices,
    externalServices, openOffers]);

  const onRestoreOrderBoxes = (identifier) => {
    const storageData = JSON.parse(localStorage.getItem(LOCALSTORAGE_DASHBOARD_CONFIG));
    const merged = { ...storageData };
    Object.keys(storageData).forEach((item) => {
      if (item.indexOf(identifier) > -1) {
        delete merged[item];
      }
    });

    localStorage.setItem(LOCALSTORAGE_DASHBOARD_CONFIG, JSON.stringify(merged));
    setStorageConfig(merged);
  };

  const onPressHideBox = (identifier) => {
    const config = JSON.parse(localStorage.getItem(LOCALSTORAGE_DASHBOARD_CONFIG));
    if (!config) {
      const newConfig = { [identifier]: false };
      localStorage.setItem(LOCALSTORAGE_DASHBOARD_CONFIG, JSON.stringify(newConfig));
      setStorageConfig(newConfig);
    } else {
      const merged = { ...config, [identifier]: false };
      localStorage.setItem(LOCALSTORAGE_DASHBOARD_CONFIG, JSON.stringify(merged));
      setStorageConfig(merged);
    }
  };

  const handleOrderClick = (clickedOrder) => {
    switch (clickedOrder.orderState) {
      case ORDERSTATE_WAITING_APPROVEMENT: {
        history.push(`/create/${clickedOrder.orderId}/approve`);
        break;
      }
      default: {
        const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', clickedOrder.orderId)
          .replace('{objectId}', clickedOrder.objectId);
        history.push(uri);
      }
    }
  };

  const handleReclamationClick = (item) => {
    if (item.reclamationId) {
      const uri = NAVIGATION_RECLAMATION_DETAILS.replace('{orderId}', item.orderId)
        .replace('{objectId}', item.objectId)
        .replace('{reclamationId}', item.reclamationId);
      history.push(uri);
    } else {
      const uri = NAVIGATION_OBJECT_SERVICE_DETAILS.replace('{orderId}', item.orderId)
        .replace('{objectId}', item.objectId)
        .replace('{serviceId}', item.serviceId);
      history.push(uri);
    }
  };

  const onChangeSwitch = (state) => {
    setShowSwitchHint(true);
    localStorage.setItem(localStorageDashboardLastView, state);
    setShowIntern(state);
  };

  const renderSwitch = () => {
    if (showSwitch) {
      return (
        <Switch
          onChange={onChangeSwitch}
          checked={showIntern}
          height={40}
          width={110}
          checkedIcon={(
            <div
              className="d-flex justify-content-center align-item-center"
              style={{
                color: '#fff', height: 30, lineHeight: '38px', paddingLeft: 16,
              }}
            >
              Intern
            </div>
          )}
          uncheckedIcon={(
            <div
              className="d-flex justify-content-center align-item-center"
              style={{
                color: '#fff', height: 30, lineHeight: '38px', paddingRight: 16,
              }}
            >
              Extern
            </div>
          )}
          offColor="#e7184e"
          onColor="#008C1C"
        />
      );
    }
    return null;
  };

  const getHeadline = () => {
    if (showIntern) {
      return 'Startseite für interne Aufträge';
    }
    return 'Startseite für externe Aufträge';
  };

  return (
    <div className="Dashboard">
      <Panel marginBottom={25}>
        <div className="d-flex">
          <div className="flex-grow-1">
            <h2 className="hk-text-primary" style={{ margin: 0, textTransform: 'none' }}>
              {getHeadline()}
            </h2>
          </div>
          <div className="d-flex align-items-center">
            {renderSwitch()}
          </div>
        </div>
      </Panel>

      <InternalBoxes
        orders={internalOrders}
        reclamations={internalReclamations}
        services={internalServices}
        visible={showIntern}
        onReclamationClick={handleReclamationClick}
        onOrderClick={handleOrderClick}
        onRestoreBoxes={onRestoreOrderBoxes}
        onHideBox={onPressHideBox}
        storageConfig={storageConfig}
        ordersLoading={ordersLoading}
        reclamationsLoading={reclamationsLoading}
        client={client}
      />
      <ExternalBoxes
        orders={externalOrders}
        reclamations={externalReclamations}
        services={externalServices}
        visible={!showIntern}
        client={client}
        onReclamationClick={handleReclamationClick}
        onOrderClick={handleOrderClick}
        onRestoreBoxes={onRestoreOrderBoxes}
        onHideBox={onPressHideBox}
        storageConfig={storageConfig}
        ordersLoading={ordersLoading}
        reclamationsLoading={reclamationsLoading || servicesLoading}
        openOffers={openOffers}
        clientOffers={offers}
      />

      <SwitchHintModal visible={showSwitchHint} />
    </div>
  );
}

Dashboard.propTypes = {
  dispatchGetReclamations: PropTypes.func.isRequired,
  dispatchGetOrders: PropTypes.func.isRequired,
  dispatchGetServices: PropTypes.func.isRequired,
  dispatchGetUserReclamations: PropTypes.func.isRequired,
  dispatchGetUserOrders: PropTypes.func.isRequired,
  dispatchGetUserServices: PropTypes.func.isRequired,
  dispatchGetOrderOffers: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  openOffers: PropTypes.instanceOf(Array),
  offers: PropTypes.instanceOf(Array),
  userGroups: PropTypes.instanceOf(Array),

};

Dashboard.defaultProps = {
  openOffers: null,
  offers: null,
  userGroups: [],
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    userGroups: store.auth.groups,
    openOffers: store.client.openOffers,
    offers: store.client.offers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetOrders: () => dispatch(getOrders()),
    dispatchGetReclamations: () => dispatch(getReclamations()),
    dispatchGetServices: () => dispatch(getServices()),
    dispatchGetUserOrders: () => dispatch(getUserOrders()),
    dispatchGetUserReclamations: () => dispatch(getUserReclamations()),
    dispatchGetUserServices: () => dispatch(getUserServices()),
    dispatchGetOrderOffers: () => dispatch(getOrderOffers()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Dashboard));
