import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ModalDialog from '../../../Components/ModalDialog';
import { request } from '../../../Redux/Action/MandantAction';
import Input from '../../../Components/Input';
import { isValidateEmail } from '../../../Library/Functions';
import CreateInvite from './Components/CreateInvite';
import { create } from '../../../Redux/Action/ClientInviteAction';
import Alert from '../../../Components/Alert';
import Button from '../../../Components/Button';

function reducer(state, action) {
  switch (action.type) {
    case 'firstname':
      return { ...state, firstname: action.payload };
    case 'lastname':
      return { ...state, lastname: action.payload };
    case 'name':
      return { ...state, name: action.payload };
    case 'legalForm':
      return { ...state, legalForm: action.payload };
    case 'mail':
      return { ...state, mail: action.payload };
    case 'web':
      return { ...state, web: action.payload };
    case 'zip':
      return { ...state, zip: action.payload.trim() };
    case 'city':
      return { ...state, city: action.payload };
    case 'street':
      return { ...state, street: action.payload };
    case 'streetNo':
      return { ...state, streetNo: action.payload };
    case 'phone':
      return { ...state, phone: action.payload };
    case 'fax':
      return { ...state, fax: action.payload };
    case 'vat':
      return { ...state, vat: action.payload };
    case 'type':
      return { ...state, type: action.payload };
    case 'password':
      return { ...state, password: action.payload };
    case 'errorMail':
      return { ...state, errorMail: action.payload };
    case 'errorName':
      return { ...state, errorName: action.payload };
    case 'reset':
      return {};

    default:
      throw new Error();
  }
}

/**
 * RequestModal()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RequestModal(props) {
  const {
    dispatchRequest, client, visible, onClose, onRequested, dispatchCreateInvite,
  } = props;
  const [formError, setFormError] = useState({});
  const [showClientMailAreadyExists, setShowClientMailAreadyExists] = React.useState(false);
  const [selected, setSelected] = useState('existingClient');
  const [state, dispatch] = useReducer(reducer, {});

  const onSend = () => {
    if (selected === 'existingClient') {
      dispatch({ type: 'errorMail', payload: '' });
      if (state.mail && isValidateEmail(state.mail)) {
        dispatchRequest(client, state.mail).then(() => {
          dispatch({ type: 'reset' });
          if (onRequested) {
            onRequested();
          }
        }).catch((errorResponse) => {
          if (errorResponse && errorResponse.data) {
            switch (errorResponse.data.errorCode) {
              case 'CLIENT_NOT_FOUND':
                setFormError({ mail: 'CLIENT_NOT_FOUND' });
                dispatch({
                  type: 'errorMail',
                  payload: 'Für diese E-Mail Adresse existiert kein hey.kitchen Account!',
                });
                break;
              case 'MANDANT_ALREADY_ASSIGNED':
                setFormError({ mail: 'MANDANT_ALREADY_ASSIGNED' });
                dispatch({
                  type: 'errorMail',
                  payload: 'Sie haben bereits eine Partnerschaft mit diesem Account!',
                });
                break;
              default:
                setFormError({ mail: 'UNKNOWN_ERROR' });
                dispatch({
                  type: 'errorMail',
                  payload: '400 - Unbekannter Fehler!',
                });
            }
          } else {
            setFormError({ mail: 'UNKNOWN_ERROR' });
            dispatch({
              type: 'errorMail',
              payload: '400 - Unbekannter Fehler!',
            });
          }
        });
      } else {
        setFormError({ mail: 'CLIENT_MAIL_INVALID' });
        dispatch({
          type: 'errorMail',
          payload: 'Bitte geben Sie eine gültige E-Mail Adresse ein!',
        });
      }
    } else {
      /** send invite */
      dispatchCreateInvite(state, client.clientId).then(() => {
        onRequested();
        dispatch({ type: 'reset' });
        setFormError({});
      }).catch((errorResponse) => {
        if (errorResponse.data) {
          const { errorCode, errorMessage } = errorResponse.data;
          if (errorCode === 'FORMDATA_INVALID') {
            setFormError(errorMessage);
          } else if (errorCode === 'USER_MAIL_ALREADY_EXISTING') {
            setFormError({ mail: 'USER_MAIL_ALREADY_EXISTING' });
            dispatch({
              type: 'errorMail',
              payload: 'Für diese E-Mail Adresse existiert bereits ein hey.kitchen Account!',
            });
          } else if (errorCode === 'CLIENT_MAIL_ALREADY_EXISTING') {
            setFormError({ mail: 'CLIENT_MAIL_ALREADY_EXISTING' });
            dispatchRequest(client, state.mail).then(() => {
              dispatch({ type: 'reset' });
              setFormError({});
              onClose();
              setShowClientMailAreadyExists(true);
            }).catch((errorResponsee) => {
              if (errorResponsee && errorResponsee.data) {
                if (errorResponsee.data.errorCode === 'MANDANT_ALREADY_ASSIGNED') {
                  dispatch({
                    type: 'errorMail',
                    payload: 'Sie haben bereits eine Partnerschaft mit diesem Account!',
                  });
                }
              }
            });
            dispatch({
              type: 'errorMail',
              payload: 'Für diese E-Mail Adresse existiert bereits ein hey.kitchen Account!',
            });
          } else if (errorCode === 'CLIENT_NAME_ALREADY_EXISTING') {
            setFormError({ name: 'CLIENT_NAME_ALREADY_EXISTING' });
            dispatch({
              type: 'errorName',
              payload: 'Für diesen Firmennamen existiert bereits ein hey.kitchen Account!',
            });
          }
        }
      });
    }
  };

  const renderHeaderTab = () => (
    <div
      className="d-flex flex-row"
      style={{ marginBottom: 30 }}
    >
      <Button
        type="button"
        className={`btn ${(selected === 'existingClient') ? 'btn-hk-primary' : 'btn-light'}`}
        onClick={() => setSelected('existingClient')}
        style={{ flexGrow: 1, fontSize: 18, padding: 10 }}
      >
        Partner nutzt bereits hey.kitchen
      </Button>
      <Button
        type="button"
        className={`btn ${(selected === 'inviteClient') ? 'btn-hk-primary' : 'btn-light'}`}
        onClick={() => setSelected('inviteClient')}
        style={{ flexGrow: 1, fontSize: 18, padding: 10 }}
      >
        Partner zu hey.kitchen einladen
      </Button>
    </div>
  );

  const handleClose = () => {
    dispatch({ type: 'reset' });
    setFormError({});
    onClose();
  };

  const renderExistingUserForm = () => {
    if (selected === 'existingClient') {
      return (
        <div>
          <Input
            onChange={(value) => dispatch({ type: 'mail', payload: value })}
            value={state.mail}
            label="E-Mail Adresse des Partneraccounts"
            placeholder="E-Mail Adresse"
            autoFocus
            hasError={!!(formError.mail)}
            errorMessage={state.errorMail}
          />
        </div>
      );
    }
    return null;
  };

  const renderInviteClientForm = () => {
    if (selected === 'inviteClient') {
      return (
        <CreateInvite dispatch={dispatch} state={state} formError={formError} />
      );
    }
    return null;
  };

  const getCaption = () => {
    if (selected === 'existingClient') {
      return 'Anfrage jetzt senden';
    }
    return 'Partner jetzt einladen';
  };

  const renderHeadline = () => {
    if (selected === 'existingClient') {
      return (
        <>
          <p>
            Wenn Ihr Partner bereits einen Account bei hey.kitchen angemeldet hat,
            benötigen Sie die E-Mail Adresse des Partners, mit welcher er sich bei hey.kitchen registriert hat.
            Der Partner erhält dann sowohl im System als auch per E-Mail Ihre Anfrage und kann diese bestätigen.
          </p>
        </>
      );
    }
    return (
      <>
        <p>
          Wenn Ihr Partner noch keinen Account bei hey.kitchen hat, können Sie, wenn gewünscht, alle Ihnen bekannten
          Informationen für Ihren Partner bereits ausfüllen. Ihr Partner erhält daraufhin einen Einladungslink,
          bei dem er alle nicht vorausgefüllten Informationen nachtragen kann. Bestätigt der Partner Ihre Einladung,
          werden Sie darüber informiert und die Partnerschaft ist bereits im System hinterlegt.
        </p>
      </>
    );
  };

  return (
    <div>
      <ModalDialog
        visible={visible}
        onClose={handleClose}
        confirmCaption={getCaption()}
        onConfirm={onSend}
        width={1000}
      >
        {renderHeaderTab()}
        {renderHeadline()}
        <hr style={{ marginTop: 25, marginBottom: 25 }} />
        {renderExistingUserForm()}
        {renderInviteClientForm()}
      </ModalDialog>
      <Alert
        visible={showClientMailAreadyExists}
        type="exclamation"
        onClose={() => setShowClientMailAreadyExists(false)}
      >
        <strong>Partner existiert bereits</strong>
        <br />
        Der Partner besitzt bereits einen Hey.Kitchen Account! Es wurde trotzdem eine Partneranfrage gestellt.
      </Alert>
    </div>
  );
}

RequestModal.propTypes = {
  dispatchRequest: PropTypes.func.isRequired,
  dispatchCreateInvite: PropTypes.func.isRequired,
  onRequested: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool,
};

RequestModal.defaultProps = {
  visible: false,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRequest: (client, mail) => dispatch(request(client, mail)),
    dispatchCreateInvite: (userdata, createdClientId) => dispatch(create(userdata, createdClientId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(RequestModal));
