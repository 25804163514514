import {
  SUBCONTRACTOR_GETALL_ERROR,
  SUBCONTRACTOR_GETALL_START,
  SUBCONTRACTOR_GETALL_SUCCESS,
  SUBCONTRACTOR_UPDATE_SUCCESS,
} from '../Action/ClientSubcontractorAction';

const defaultValues = {
  subcontractors: null,
  isLoading: false,
  statusCode: null,
  errorCode: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /** GET ALL */
    case SUBCONTRACTOR_GETALL_START: {
      return { ...state, isLoading: true };
    }
    case SUBCONTRACTOR_GETALL_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        subcontractors: payload,
      };
    }
    case SUBCONTRACTOR_GETALL_ERROR: {
      if (action.payload) {
        const { status } = action.payload;
        return {
          ...state, isLoading: false, statusCode: status,
        };
      }
      return { ...state, isLoading: false, statusCode: 500 };
    }

    /** SUBCONTRACTOR UDPATE */
    case SUBCONTRACTOR_UPDATE_SUCCESS: {
      const { payload } = action;

      if (state.subcontractors) {
        const mergedSubcontractors = state.subcontractors.map((item) => {
          if (item.subcontractorId === payload.subcontractorId) {
            return payload;
          }
          return item;
        });

        return { ...state, subcontractors: mergedSubcontractors };
      }
      return { ...state };
    }

    default: return { ...state };
  }
}
