import {
  OBJECT_SERVICE_UPDATE_ERROR, OBJECT_SERVICE_CREATE_ERROR, OBJECT_SERVICE_GETLIST_ERROR, OBJECT_SERVICE_CREATE_SUCCESS,
  OBJECT_SERVICE_GET_ERROR, OBJECT_SERVICE_GET_SUCCESS, OBJECT_SERVICE_UPDATE_SUCCESS, OBJECT_SERVICE_GETLIST_SUCCESS,
} from '../Action/Order/Object/ServiceAction';
import {
  OBJECT_SERVICE_USER_CREATE_SUCCESS, OBJECT_SERVICE_USER_DELETE_SUCCESS,
} from '../Action/Order/Object/Service/UserAction';
import { OBJECT_SERVICE_ITEM_DELETE_SUCCESS } from '../Action/Order/Object/Service/ItemAction';
import {
  OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_SUCCESS, OBJECT_SERVICE_DOCUMENT_DELETE_SUCCESS,
} from '../Action/Order/Object/Service/DocumentAction';

import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  services: null,
  service: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case OBJECT_SERVICE_GETLIST_SUCCESS: {
      return { ...state, services: action.payload };
    }
    case OBJECT_SERVICE_GETLIST_ERROR: {
      return { ...state, services: null };
    }

    case OBJECT_SERVICE_CREATE_ERROR:
    case OBJECT_SERVICE_UPDATE_ERROR:
    case OBJECT_SERVICE_GET_ERROR: {
      return { ...state };
    }

    case OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_SUCCESS:
    case OBJECT_SERVICE_DOCUMENT_DELETE_SUCCESS:
    case OBJECT_SERVICE_ITEM_DELETE_SUCCESS:
    case OBJECT_SERVICE_USER_CREATE_SUCCESS:
    case OBJECT_SERVICE_USER_DELETE_SUCCESS:
    case OBJECT_SERVICE_GET_SUCCESS:
    case OBJECT_SERVICE_UPDATE_SUCCESS:
    case OBJECT_SERVICE_CREATE_SUCCESS: {
      if (state.services) {
        const mappedServices = state.services.map((item) => {
          if (item.serviceId === action.payload.serviceId) {
            return { ...action.payload };
          }
          return { ...item };
        });
        return { ...state, service: action.payload, services: mappedServices };
      }
      return { ...state, service: action.payload };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
