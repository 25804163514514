import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * FileInput()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function FileInput(props) {
  const {
    onChange, hasError, placeholder, rowClass, value, onDelete,
  } = props;

  const [file, setFile] = useState(value);
  const fileInputRef = useRef();

  useEffect(() => {
    if (value) {
      setFile(value);
    }
  }, [value]);

  const getInputClass = () => {
    if (hasError) {
      return 'form-control is-invalid';
    }
    return 'form-control';
  };

  const handleOnChange = (event) => {
    setFile(event.target.files[0]);
    if (onChange) {
      onChange(event.target.files[0]);
    }
  };

  const handleOnDelete = () => {
    fileInputRef.current.value = null;
    setFile(null);
    if (onDelete) {
      onDelete(value);
    }
  };

  return (
    <div className={`FileInput ${rowClass}`}>
      <div className="form-group">
        <RenderLabel {...props} />
        <div className="input-group">
          <input
            type="text"
            placeholder={placeholder}
            className={getInputClass()}
            value={(file) ? file.name : ''}
            onChange={(e) => onChange(e.target.value)}
            disabled
          />
          <input
            type="file"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={(e) => handleOnChange(e)}
          />
          <RenderErrorMessage {...props} />
          <ButtonAddon
            {...props}
            file={file}
            onSelect={() => fileInputRef.current.click()}
            onDelete={() => handleOnDelete()}
          />
        </div>
      </div>
    </div>
  );
}

FileInput.propTypes = {
  rightIcon: PropTypes.string,
  rightText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  rowClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object]),
  onDelete: PropTypes.func.isRequired,
};

FileInput.defaultProps = {
  rightIcon: null,
  rightText: null,
  label: null,
  hasError: false,
  errorMessage: '',
  placeholder: '',
  rowClass: '',
  value: null,
};

/**
 * RenderLabel()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderLabel(props) {
  // eslint-disable-next-line react/prop-types
  const { label, tooltip } = props;

  const renderTooltip = () => {
    if (tooltip && tooltip !== '') {
      return (
        <i
          className="fas fa-info-circle"
          data-toggle="tooltip"
          data-placement="right"
          title={tooltip}
        />
      );
    }
    return null;
  };

  if (label) {
    return (
      <label>
        {label}
        {' '}
        {renderTooltip()}
      </label>
    );
  }
  return null;
}

/**
 * ButtonAddon()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function ButtonAddon(props) {
  // eslint-disable-next-line react/prop-types
  const { onSelect, onDelete, file } = props;

  const renderDeleteButton = () => {
    if (file) {
      return (
        <button className="btn btn-outline-danger" type="button" onClick={() => onDelete()}>
          <i className="far fa-trash-alt" />
        </button>
      );
    }
    return null;
  };

  return (
    <div className="input-group-append">
      {renderDeleteButton()}
      <button className="btn btn-outline-secondary" type="button" onClick={() => onSelect()}>
        <i className="far fa-folder-open" />
      </button>
    </div>
  );
}

/**
 * RenderErrorMessage()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderErrorMessage(props) {
  // eslint-disable-next-line react/prop-types
  const { errorMessage } = props;

  if (errorMessage) {
    return (
      <div className="invalid-tooltip">
        {errorMessage}
      </div>
    );
  }
  return null;
}
