import React from 'react';
import PropTypes from 'prop-types';
import * as Redux from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ORDER_EXTERNAL, ORDER_INTERNAL } from '../../../Library/Types/OrderTypes';
import SelectPanel from '../../SelectPanels';
import CustomReactSelect from '../../CustomReactSelect';
import { CLIENT_DOCUMENTSTYPE_LOGO, CLIENTTYPE_DEALER, YesNoOption } from '../../../Library/Types';
import Panel from '../../Panel';

const hkLogo = require('../../../Assets/Images/hk_logo_full.jpg');

export default function SelectPartner(props) {
  const {
    state, onChange, headline, balance, optionDelivery, errors,
  } = props;

  const client = Redux.useSelector((store) => store.client.client);
  const partners = Redux.useSelector((store) => store.client.partners);

  const [options, setOptions] = React.useState([]);

  const showStorage = React.useCallback((internal, mandantId) => {
    if (!optionDelivery) {
      return false;
    }

    if (internal) {
      if (mandantId) {
        const partner = partners.find((item) => item.partner.clientId === mandantId);

        return partner
          && partner.partnerSettings
          && partner.partnerSettings.allowStorage
          && partner.partnerSettings.defaultClientAddressId;
      }
      const partner = partners.find(
        (item) => item.partnerSettings
          && item.partnerSettings.allowStorage
          && item.partnerSettings.defaultClientAddressId,
      );

      return !!(partner);
    }
    return true; // external is alway true
  }, [optionDelivery, partners]);

  const handleOnChange = React.useCallback((value) => {
    if (onChange) {
      switch (value) {
        case ORDER_INTERNAL:
          onChange({
            ...state,
            internal: true,
            mandantId: null,
            optionStorage: showStorage(true, null) ? state.optionStorage : false,
            storageClientId: null,
          });
          return;
        case ORDER_EXTERNAL:
          onChange({
            ...state,
            internal: false,
            mandantId: null,
            optionStorage: showStorage(false, null) ? state.optionStorage : false,
            storageClientId: null,
          });
          return;
        default:
          onChange({
            ...state,
            internal: true,
            mandantId: value,
            optionStorage: showStorage(true, value) ? state.optionStorage : false,
            storageClientId: null,
          });
      }
    }
  }, [state, onChange, showStorage]);

  React.useEffect(() => {
    const internalLogo = client.documents.find((doc) => doc.type === CLIENT_DOCUMENTSTYPE_LOGO);
    const internal = {
      caption: 'Interner Auftrag',
      icon: internalLogo ? null : 'fas fa-network-wired',
      image: internalLogo ? internalLogo.absolutePath : null,
      key: ORDER_INTERNAL,
    };

    const externalLogo = hkLogo; // TODO: Welches Logo
    const external = {
      caption: 'hey.kitchen',
      icon: externalLogo ? null : 'fas fa-network-wired',
      image: externalLogo || null,
      key: ORDER_EXTERNAL,
    };

    let partnerOptions = [];
    if (client.type === CLIENTTYPE_DEALER && partners && partners.length > 0) {
      partnerOptions = partners.filter(
        (partner) => partner.active && partner.approved && partner.clientId !== client.clientId,
      ).map(
        (partner) => {
          const logo = partner.partner.documents.find((doc) => doc.type === CLIENT_DOCUMENTSTYPE_LOGO);
          return ({
            caption: partner.partner.name,
            icon: (logo) ? null : 'fas fa-network-wired',
            image: (logo) ? logo.absolutePath : null,
            key: partner.partner.clientId,
          });
        },
      );
    }

    if (client.type === CLIENTTYPE_DEALER) {
      setOptions([internal, ...partnerOptions, external]);
    } else {
      if (state.internal == null) {
        handleOnChange(ORDER_INTERNAL);
      }
      setOptions([internal]);
    }
  }, [state, client, partners, setOptions, handleOnChange]);

  // devout identification number
  const showDINWarning = () => (!state.internal && balance && balance > 0 && !client.devoutIdentificationNumber);

  const handleUpdateStorage = () => {
    if (onChange) {
      onChange({
        ...state,
        optionStorage: !state.optionStorage,
      });
    }
  };

  const renderDevoutIdentificationNumberHint = () => ((showDINWarning()) ? (
    <div style={{ marginTop: 10 }}>
      <div className="alert alert-danger">
        <i className="fas fa-exclamation-circle" style={{ marginRight: 6 }} />
        Der Auftrag enthält einen offenen Rechnungsbetrag
        <br />
        <br />
        Für Aufträge mit offenen Rechnungsbetrag an hey.kithcnen muss eine Gläubiger-Identifikationsnummer in ihren
        Firmendaten hinterlegt sein. Mit Ihrer Gläubiger-Identifikationsnummer wird automatisch ein
        SEPA-Lastschriftmandat generiert welches Sie berechtigt den Restbetrag des Kunden von seinem angegebenen
        Bankkonto ein zu ziehen.
        <NavLink to="/admin/client" className="alert-link" style={{ marginTop: 10, display: 'block' }}>
          <i className="fas fa-cogs" style={{ marginRight: 10 }} />
          Eintellungen öffnen
        </NavLink>
      </div>
    </div>
  ) : null);

  const renderStorageHint = () => (
    (!state.internal && state.optionStorage) ? (
      <div className="alert alert-warning" role="alert">
        Die Adresse des Lagers, an welches Sie die Küche liefern müssen, werden wir Ihnen
        in kürze per E-Mail mitteilen!
      </div>
    ) : null
  );

  const getStorageHeadline = () => {
    if (state.internal) {
      if (state.mandantId) {
        return 'Soll die Lagerung durch ihren Partner erfolgen?';
      }
      return 'Soll die Lagerung extern durch einen Partner erfolgen?';
    }
    return 'Soll die Lagerung durch hey.kitchen erfolgen?';
  };

  const renderStorageOption = () => (showStorage(state.internal, state.mandantId) ? (
    <div style={{ marginTop: 20 }}>
      <h6>{getStorageHeadline()}</h6>
      <div className="row" style={{ marginTop: 10 }}>
        <CustomReactSelect
          value={state.optionStorage}
          onChange={handleUpdateStorage}
          options={YesNoOption}
        />
      </div>
      {renderStorageHint()}
    </div>
  ) : null);

  const getSelected = () => {
    if (state.internal != null) {
      return [state.internal ? state.mandantId || ORDER_INTERNAL : ORDER_EXTERNAL];
    }
    return [];
  };

  if (options && options.length > 1) {
    return (
      <Panel marginBottom={30}>
        <h5>{headline}</h5>
        <p className="text-muted">
          Bitte wählen Sie für welchen Partner Sie einen Auftrag erstellen möchten!
        </p>
        <SelectPanel
          data={options}
          selected={getSelected()}
          onChange={handleOnChange}
          hasError={errors.internal}
        />
        {renderDevoutIdentificationNumberHint()}
        {renderStorageOption()}
      </Panel>
    );
  }

  return null;
}

SelectPartner.propTypes = {
  state: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  headline: PropTypes.string,
  balance: PropTypes.number,
  optionDelivery: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
};

SelectPartner.defaultProps = {
  state: {
    internal: true,
    mandantId: null,
    optionStorage: false,
  },
  onChange: undefined,
  headline: 'Partnerauswahl',
  balance: null,
  optionDelivery: false,
  errors: {},
};
