// FLOOR OPTIONS
export const FLOOR_OPTION_GROUND = 0;
export const FLOOR_OPTION_FIRST = 1;
export const FLOOR_OPTION_SECOND = 2;
export const FLOOR_OPTION_THIRD = 3;
export const FLOOR_OPTION_FOURTH = 4;
export const FLOOR_OPTION_FIFTH = 5;
export const FLOOR_OPTION_SIXTH = 6;

const floorOptionLabel = {
  [FLOOR_OPTION_GROUND]: 'Erdgeschoss',
  [FLOOR_OPTION_FIRST]: '1. Etage',
  [FLOOR_OPTION_SECOND]: '2. Etage',
  [FLOOR_OPTION_THIRD]: '3. Etage',
  [FLOOR_OPTION_FOURTH]: '4. Etage',
  [FLOOR_OPTION_FIFTH]: '5. Etage',
  [FLOOR_OPTION_SIXTH]: '6. Etage',
};

export const getFloorOptionLabel = (option) => (floorOptionLabel[option] || 'Unbekannt');
export const getFloorOption = (option) => ({ label: getFloorOptionLabel(option), value: option });
export const floorOption = [
  getFloorOption(FLOOR_OPTION_GROUND),
  getFloorOption(FLOOR_OPTION_FIRST),
  getFloorOption(FLOOR_OPTION_SECOND),
  getFloorOption(FLOOR_OPTION_THIRD),
  getFloorOption(FLOOR_OPTION_FOURTH),
  getFloorOption(FLOOR_OPTION_FIFTH),
  getFloorOption(FLOOR_OPTION_SIXTH),
];

// PARKING OPTIONS
export const PARKING_OPTION_NONE = 0;
export const PARKING_OPTION_GOOD = 1;
export const PARKING_OPTION_SMALL_VEHICLE = 2;
export const PARKING_OPTION_NO_PARKING_ZONE = 3;

const parkingOptionLabel = {
  [PARKING_OPTION_NONE]: 'Keine Angaben/Informationen zur Parkplatzsituation',
  [PARKING_OPTION_GOOD]: 'Genügend Freifläche als Parkplatz vorhanden',
  [PARKING_OPTION_SMALL_VEHICLE]: 'Kleines Fahrzeug notwendig',
  [PARKING_OPTION_NO_PARKING_ZONE]: 'Halteverbotszone muss eingerichtet werden',
};

export const getParkingOptionLabel = (option) => (parkingOptionLabel[option] || 'Unbekannt');
export const getParkingOption = (option) => ({ label: getParkingOptionLabel(option), value: option });
export const parkingOption = [
  getParkingOption(PARKING_OPTION_NONE),
  getParkingOption(PARKING_OPTION_GOOD),
  getParkingOption(PARKING_OPTION_SMALL_VEHICLE),
  getParkingOption(PARKING_OPTION_NO_PARKING_ZONE),
];

// SALUTATION OPTIONS
export const SALUTATION_OPTION_MR = 1;
export const SALUTATION_OPTION_MRS = 2;
export const SALUTATION_OPTION_COMPANY = 3;
export const SALUTATION_OPTION_OTHER = 4;

const salutationOptionLabel = {
  [SALUTATION_OPTION_MR]: 'Herr',
  [SALUTATION_OPTION_MRS]: 'Frau',
  [SALUTATION_OPTION_COMPANY]: 'Firma',
  [SALUTATION_OPTION_OTHER]: 'An',
};

export const getSalutationOptionLabel = (option) => (salutationOptionLabel[option] || 'Unbekannt');
export const getSalutationOption = (option) => ({ label: getSalutationOptionLabel(option), value: option });
export const salutationOption = [
  getSalutationOption(SALUTATION_OPTION_MR),
  getSalutationOption(SALUTATION_OPTION_MRS),
  getSalutationOption(SALUTATION_OPTION_COMPANY),
  getSalutationOption(SALUTATION_OPTION_OTHER),
];
