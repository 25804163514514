import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Panel from '../../Panel';
import { get } from '../../../Redux/Action/ClientAddressAction';
import Button from '../../Button';
import UpdateLoadingAddress from '../UpdateLoadingAddress';
import { updateLoadingAddress, get as getOrder } from '../../../Redux/Action/OrderAction';
import ExternalStorageContainer from './ExternalStorageContainer';
import { useIsDealerOrAssignedClient } from '../../../Library/HelperHooks';

/**
 * LoadingAddress()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function LoadingAddress(props) {
  const {
    order, object, client, dispatchGet, dispatchUpdate, dispatchGetOrder, editable, partners,
  } = props;
  const [showEdit, setShowEdit] = React.useState(false);
  const [address, setAddress] = React.useState(null);

  const isDealerOrAssignedClient = useIsDealerOrAssignedClient(order, object);

  React.useEffect(() => {
    if (!order.internal && object.montageDate.clientId && object.assignedClientAddressId) {
      dispatchGet(object.assignedClientId, object.assignedClientAddressId).then((response) => {
        setAddress(response);
      });
    }
  }, [dispatchGet, order, object]);

  const handleUpdateAddress = (addressData) => {
    dispatchUpdate(order.orderId, addressData).then(() => {
      setShowEdit(false);
      dispatchGetOrder(order.orderId);
    });
  };

  const renderLoadingAddress = () => (
    <div>
      <i className="fas fa-spin fa-spinner" style={{ marginRight: 10 }} />
      Adresse wird geladen...
    </div>
  );

  const renderEditButton = () => {
    if (editable) {
      return (
        <Button onClick={() => setShowEdit(true)} type="btn-light">
          <i
            className="fas fa-pencil-alt"
            onClick={() => setShowEdit(true)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
            style={{ margin: 0 }}
          />
        </Button>
      );
    }
    return null;
  };

  const renderAddress = () => {
    const { loadingAddress } = order;
    if (!loadingAddress.name) {
      return (
        <p style={{ color: 'red' }}>
          Es wurde noch keine Beladestelle ausgewählt!
        </p>
      );
    }
    return (
      <address style={{ marginBottom: 0, marginTop: 20 }}>
        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          <strong>
            {loadingAddress.name}
          </strong>
        </div>
        <div>{`${loadingAddress.street} ${loadingAddress.streetNo}`}</div>
        <div>{`${loadingAddress.zip} ${loadingAddress.city}`}</div>
        <div>{loadingAddress.contactPhone}</div>
        <div>{loadingAddress.contactName}</div>
        <div>{loadingAddress.comment}</div>
      </address>
    );
  };

  const renderInternalStorage = () => {
    const { loadingAddress } = order;

    if (order.internal) {
      if (order.mandantId && order.optionStorage) {
        return (
          <Panel marginBottom={20}>
            <h6>Beladestelle</h6>

            <address style={{ marginBottom: 0, marginTop: 20 }}>
              <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                <strong>
                  {loadingAddress.name}
                </strong>
              </div>
              <div>{`${loadingAddress.street} ${loadingAddress.streetNo}`}</div>
              <div>{`${loadingAddress.zip} ${loadingAddress.city}`}</div>
              <div style={{ marginTop: 10 }}>{`Kontakt: ${loadingAddress.contactName}`}</div>
              <div>{`Telefon: ${loadingAddress.contactPhone}`}</div>
              <div>{loadingAddress.comment}</div>
            </address>
          </Panel>
        );
      }

      if (!order.mandantId && order.optionStorage && partners) {
        return (
          <ExternalStorageContainer
            order={order}
            object={object}
            client={client}
          />
        );
      }

      if (order.optionDelivery && !order.optionStorage) {
        return (
          <Panel marginBottom={20}>
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6>Beladestelle</h6>
              </div>
              <div>
                {renderEditButton()}
              </div>
            </div>
            {renderAddress()}
            <UpdateLoadingAddress
              onSave={handleUpdateAddress}
              order={order}
              onClose={() => setShowEdit(false)}
              visible={showEdit}
            />
          </Panel>
        );
      }
      return (
        <Panel marginBottom={20}>
          <h6>Beladestelle</h6>

          <i className="text-muted">
            Für diesen Auftrag ist keine Lieferung vorgesehen!
          </i>
        </Panel>
      );
    }
    return null;
  };

  const renderStorageAddress = (storageAddress) => {
    const {
      street, streetNo, zip, city, contactName, contactPhone, comment,
    } = storageAddress;

    return (
      <address style={{ marginBottom: 0, marginTop: 20 }}>
        <div>{`${street} ${streetNo}`}</div>
        <div>{`${zip} ${city}`}</div>
        <div style={{ marginTop: 10 }}>{`Kontakt: ${contactName}`}</div>
        <div>{`Telefon: ${contactPhone}`}</div>
        <div>{comment}</div>
      </address>
    );
  };

  const renderExternalStorageAddress = () => {
    if (order.internal) {
      return null;
    }

    if (isDealerOrAssignedClient) {
      if (order.optionStorage) {
        if (client.type === 'DEALER' && !object.assignedClientAddressId) {
          return (
            <div className="hk-text-primary">
              <small>
                Die Lageradresse zu Ihrem Auftrag wird Ihnen in kürze per E-Mail mitgeteilt.
                Danach wird die Adresse hier permanent sichtbar sein.
              </small>
            </div>
          );
        }
        if (address) {
          return renderStorageAddress(address);
        }
        return renderLoadingAddress();
      }
      return renderStorageAddress(order.loadingAddress);
    }

    if (order.optionStorage) {
      return (
        <div className="hk-text-primary" style={{ marginTop: 20 }}>
          <small>
            Die Adresse wird angezeigt nachdem Sie den Auftrag übernommen haben und Ihr entsprechendes Lager
            ausgewählt haben.
          </small>
        </div>
      );
    }

    if (order.optionDelivery) {
      return (
        <>
          <address style={{ marginBottom: 0, marginTop: 20 }}>
            <div>{`${order.loadingAddress.zip} ${order.loadingAddress.city}`}</div>
          </address>

          <div className="hk-text-primary">
            <small>
              Die vollständige Adresse der Beladestelle wird angezeigt nachdem Sie den Auftrag übernommen haben.
            </small>
          </div>
        </>
      );
    }

    return null;
  };

  const renderExternalStorage = () => {
    if (order.internal || (!order.optionStorage && !order.optionDelivery)) {
      return null;
    }
    const title = order.optionStorage ? 'Lageradresse' : 'Beladestelle';
    return (
      <Panel marginBottom={20}>
        <h6>{title}</h6>

        {renderExternalStorageAddress()}
      </Panel>
    );
  };

  if (order.optionDelivery || order.optionStorage) {
    return (
      <>
        {renderInternalStorage()}
        {renderExternalStorage()}
      </>
    );
  }

  return (
    <Panel marginBottom={20}>
      <h6>Beladestelle</h6>

      <i className="text-muted" style={{ marginTop: 20 }}>
        Für diesen Auftrag ist keine Lieferung oder Lagerung vorgesehen!
      </i>
    </Panel>
  );
}

LoadingAddress.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  dispatchGet: PropTypes.func.isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  partners: PropTypes.instanceOf(Array),
};

LoadingAddress.defaultProps = {
  editable: false,
  partners: null,
};

function mapStoreToProps(store) {
  return {
    partners: store.client.partners,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: (clientId, addressId) => dispatch(get(clientId, addressId)),
    dispatchUpdate: (orderId, address) => dispatch(updateLoadingAddress(orderId, address)),
    dispatchGetOrder: (orderId) => dispatch(getOrder(orderId, true)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(LoadingAddress);
