import * as Redux from 'react-redux';
import React from 'react';
import axios from '../../Library/Axios';

const defaultUri = '/client/{clientId}/mandant';
const settingsUri = `${defaultUri}/{mandantId}/settings/{settingsId}`;

export const MANDANT_REQUEST_START = 'MANDANT_REQUEST_START';
export const MANDANT_REQUEST_SUCCESS = 'MANDANT_REQUEST_SUCCESS';
export const MANDANT_REQUEST_ERROR = 'MANDANT_REQUEST_ERROR';

/**
 * request()
 * @param client
 * @param mail
 * @returns {function(*): Promise<any | never>}
 */
export function request(client, mail) {
  return (dispatch) => {
    dispatch({ type: MANDANT_REQUEST_START });

    const uri = defaultUri.replace('{clientId}', client.clientId);

    return axios().post(uri, { mail }).then((response) => {
      const { data } = response.data;
      dispatch({ type: MANDANT_REQUEST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: MANDANT_REQUEST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const MANDANT_UPDATE_START = 'MANDANT_UPDATE_START';
export const MANDANT_UPDATE_SUCCESS = 'MANDANT_UPDATE_SUCCESS';
export const MANDANT_UPDATE_ERROR = 'MANDANT_UPDATE_ERROR';

/**
 * update()
 * @param client
 * @param mandant
 * @returns {function(*): Promise<any | never>}
 */
export function update(client, mandant) {
  return (dispatch) => {
    dispatch({ type: MANDANT_UPDATE_START, mandant });

    const uri = `${defaultUri.replace('{clientId}', client.clientId)}/${mandant.id}`;

    return axios().put(uri, mandant).then((response) => {
      const { data } = response.data;
      dispatch({ type: MANDANT_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: MANDANT_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const MANDANT_DELETE_START = 'MANDANT_DELETE_START';
export const MANDANT_DELETE_SUCCESS = 'MANDANT_DELETE_SUCCESS';
export const MANDANT_DELETE_ERROR = 'MANDANT_DELETE_ERROR';

/**
 * remove()
 * @param client
 * @param mandantId
 * @returns {function(*): Promise<any | never>}
 */
export function remove(client, mandantId) {
  return (dispatch) => {
    dispatch({ type: MANDANT_DELETE_START });

    const uri = `${defaultUri.replace('{clientId}', client.clientId)}/${mandantId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: MANDANT_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: MANDANT_DELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const MANDANT_HASREQUESTPENDING_START = 'MANDANT_HASREQUESTPENDING_START';
export const MANDANT_HASREQUESTPENDING_SUCCESS = 'MANDANT_HASREQUESTPENDING_SUCCESS';
export const MANDANT_HASREQUESTPENDING_ERROR = 'MANDANT_HASREQUESTPENDING_ERROR';

/**
 * remove()
 * @param client
 * @returns {function(*): Promise<any | never>}
 */
export function hasRequestPending(client) {
  return (dispatch) => {
    dispatch({ type: MANDANT_HASREQUESTPENDING_START });

    const uri = defaultUri.replace('{clientId}', client.clientId);
    const config = { params: { action: 'pending' } };

    return axios().get(uri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: MANDANT_HASREQUESTPENDING_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: MANDANT_HASREQUESTPENDING_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const MANDANT_APPROVE_START = 'MANDANT_APPROVE_START';
export const MANDANT_APPROVE_SUCCESS = 'MANDANT_APPROVE_SUCCESS';
export const MANDANT_APPROVE_ERROR = 'MANDANT_APPROVE_ERROR';

/**
 * approve()
 * @param client
 * @param mandantId
 * @returns {function(*): Promise<any | never>}
 */
export function approve(client, mandantId) {
  return (dispatch) => {
    dispatch({ type: MANDANT_APPROVE_START });

    const uri = `${defaultUri.replace('{clientId}', client.clientId)}/${mandantId}`;
    const config = { params: { action: 'approve' } };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: MANDANT_APPROVE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: MANDANT_APPROVE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const MANDANT_GETLIST_START = 'MANDANT_GETLIST_START';
export const MANDANT_GETLIST_SUCCESS = 'MANDANT_GETLIST_SUCCESS';
export const MANDANT_GETLIST_ERROR = 'MANDANT_GETLIST_ERROR';

/**
 * getList()
 * @param client
 * @returns {function(*): Promise<any | never>}
 */
export function getList(client) {
  return (dispatch) => {
    dispatch({ type: MANDANT_GETLIST_START });

    const uri = defaultUri.replace('{clientId}', client.clientId);

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: MANDANT_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: MANDANT_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const MANDANT_UPDATE_SETTINGS_START = 'MANDANT_UPDATE_SETTINGS_START';
export const MANDANT_UPDATE_SETTINGS_SUCCESS = 'MANDANT_UPDATE_SETTINGS_SUCCESS';
export const MANDANT_UPDATE_SETTINGS_ERROR = 'MANDANT_UPDATE_SETTINGS_ERROR';

/**
 * updateSettings()
 * @param settings
 * @returns {function(*, *): *}
 */
export function updateSettings(settings) {
  return (dispatch, getState) => {
    const { client } = getState().client;
    dispatch({ type: MANDANT_UPDATE_SETTINGS_START });

    const uri = settingsUri.replace('{clientId}', settings.clientId)
      .replace('{mandantId}', settings.mandantId)
      .replace('{settingsId}', settings.id);

    return axios().put(uri, settings).then((response) => {
      const { data } = response.data;
      dispatch({ type: MANDANT_UPDATE_SETTINGS_SUCCESS, payload: data });
      dispatch(getList(client));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: MANDANT_UPDATE_SETTINGS_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const MANDANT_GET_ADDRESS_LIST_START = 'MANDANT_GET_ADDRESS_LIST_START';
export const MANDANT_GET_ADDRESS_LIST_SUCCESS = 'MANDANT_GET_ADDRESS_LIST_SUCCESS';
export const MANDANT_GET_ADDRESS_LIST_ERROR = 'MANDANT_GET_ADDRESS_LIST_ERROR';

/**
 *
 * @returns {function(*=): *}
 */
export const useDispatchMandantAddressList = () => {
  const dispatch = Redux.useDispatch();

  return React.useCallback((clientId) => {
    const uri = defaultUri.replace('{clientId}', clientId);
    const config = { params: { action: 'mandantAddress' } };

    dispatch({ type: MANDANT_GET_ADDRESS_LIST_START, payload: { uri } });

    return axios().get(uri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: MANDANT_GET_ADDRESS_LIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: MANDANT_GET_ADDRESS_LIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
};
