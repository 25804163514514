import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Panel from '../../Panel';
import UpdateCustomerAddress from '../UpdateCustomerAddress';
import Button from '../../Button';
import { get } from '../../../Redux/Action/OrderAction';
import { parkingOption, salutationOption } from '../../../Library/Types';
import { useIsDealerOrAssignedClient } from '../../../Library/HelperHooks';
import { useDispatchCustomerUpdate } from '../../../Redux/Action/Order/Object/CustomerAction';

/**
 * CustomerAddress
 * @param props
 * @returns {*}
 * @constructor
 */
function CustomerAddress(props) {
  const {
    customer, client, order, object, dispatchGetOrder, editable,
  } = props;
  const dispatchUpdateCustomer = useDispatchCustomerUpdate();
  const [showEdit, setShowEdit] = React.useState(false);
  const isDealerOrAssignedClient = useIsDealerOrAssignedClient(order, object);

  const renderPhone = () => {
    if (customer.phone && customer.phone.length > 0) {
      return <div>{`Telefon: ${customer.phone}`}</div>;
    }
    return null;
  };

  const renderMobile = () => {
    if (customer.mobile && customer.mobile.length > 0) {
      return <div>{`Mobil: ${customer.mobile}`}</div>;
    }
    return null;
  };

  const renderEditButton = () => {
    if (editable) {
      return (
        <Button onClick={() => setShowEdit(true)} type="btn-light">
          <i
            className="fas fa-pencil-alt"
            onClick={() => setShowEdit(true)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
            style={{ margin: 0 }}
          />
        </Button>
      );
    }
    return null;
  };

  const renderFloor = () => {
    switch (customer.floor) {
      case 0: return (<div style={{ marginBottom: 6 }}>Etage: Erdgeschoss</div>);
      default: return (<div style={{ marginBottom: 6 }}>{`${customer.floor}. Etage`}</div>);
    }
  };

  const renderParking = () => {
    const option = parkingOption.filter((item) => item.value === customer.parking)[0];
    const text = (option) ? option.label : 'Keine Angaben/Informationen zur Parkplatzsituation';

    let icon;
    switch (customer.parking) {
      case 1: {
        icon = <i className="fas fa-info-circle text-success" style={{ marginRight: 6 }} />;
        break;
      }
      case 2: {
        icon = <i className="fas fa-info-circle text-warning" style={{ marginRight: 6 }} />;
        break;
      }
      case 3: {
        icon = <i className="fas fa-info-circle text-danger" style={{ marginRight: 6 }} />;
        break;
      }
      default: {
        icon = <i className="fas fa-info-circle" style={{ marginRight: 6 }} />;
      }
    }

    return (
      <div style={{ marginBottom: 6, marginTop: 6 }}>
        {icon}
        {text}
      </div>
    );
  };

  const getSalutation = () => {
    if (customer.salutation) {
      return salutationOption.filter((item) => item.value === customer.salutation)[0].label;
    }
    return '';
  };

  const handleUpdateCustomer = (customerData) => {
    dispatchUpdateCustomer(object, customerData).then(() => {
      dispatchGetOrder(object.orderId);
      setShowEdit(false);
    });
  };

  if (client.type === 'DEALER'
    || order.internal
    || isDealerOrAssignedClient
  ) {
    return (
      <Panel marginBottom={20} className="CustomerAddress">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6>Lieferadresse</h6>
          </div>
          <div>
            {renderEditButton()}
          </div>
        </div>

        <address style={{ marginBottom: 0, marginTop: 20 }}>
          <div>
            <strong>
              {`${getSalutation()} ${customer.firstname} ${customer.lastname}`}
            </strong>
          </div>
          <div>{`${customer.street} ${customer.streetNo}`}</div>
          <div>{`${customer.zip} ${customer.city}`}</div>
          {renderFloor()}
          {renderPhone()}
          {renderMobile()}
          <div>{customer.mail}</div>
          {renderParking()}
          <small>{customer.comment}</small>
        </address>

        <UpdateCustomerAddress
          onClose={() => setShowEdit(false)}
          object={object}
          onSave={handleUpdateCustomer}
          visible={showEdit}
        />
      </Panel>
    );
  }

  return (
    <Panel marginBottom={20}>
      <h6>Lieferadresse</h6>

      <address style={{ marginBottom: 0, marginTop: 20 }}>
        <div>{`${customer.zip} ${customer.city}`}</div>
        {renderFloor()}
        <div>{customer.comment}</div>
        <div>{renderParking()}</div>
        <div className="hk-text-primary">
          <small>
            Die vollständigen Kontaktdaten erhalten Sie erst nach erfolgreicher Übernahme des Angebots!
          </small>
        </div>
      </address>
    </Panel>
  );
}

CustomerAddress.propTypes = {
  dispatchGetOrder: PropTypes.func.isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  editable: PropTypes.bool,
};

CustomerAddress.defaultProps = {
  editable: false,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(CustomerAddress);
