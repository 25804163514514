import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../Components/ModalDialog';
import { LOCALSTORAGE_DASHBOARD_SWITCH_HINT_SHOWN } from '../../../Library/Types';

/**
 * SwitchHintModal()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function SwitchHintModal(props) {
  const { visible } = props;
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (visible) {
      const storageData = localStorage.getItem(LOCALSTORAGE_DASHBOARD_SWITCH_HINT_SHOWN);
      if (!storageData) {
        setShow(true);
      }
    }
  }, [visible]);

  const handleClose = () => {
    localStorage.setItem(LOCALSTORAGE_DASHBOARD_SWITCH_HINT_SHOWN, 'true');
    setShow(false);
  };

  return (
    <ModalDialog onClose={handleClose} hideConfirmButton closeCaption="OK, verstanden" visible={show} centered>
      <h5>
        <i className="fas fa-toggle-on" style={{ marginRight: 8 }} />
        Ansicht wechseln zwischen Intern & Extern
      </h5>
      <p>
        Sie haben die Ansicht der Startseite gewechselt. Die Startseite ist in 2 Bereiche unterteilt. Zum einen
        sehen Sie eine Übersicht aller internen Aufträge und Reklamationen und zum andern aller externen Aufträge
        und Reklamationen. Es wird gespeichert welche Ansicht Sie zuletzt aktiv hatten. Wenn Sie aus einer Unterseite
        oder bei einem erneuten Besuch der Webseite wieder die Startseite
        aufrufen, erhalten Sie immer die von Ihnen zuletzt ausgewählt Ansicht.
      </p>
    </ModalDialog>
  );
}

SwitchHintModal.propTypes = {
  visible: PropTypes.bool,
};

SwitchHintModal.defaultProps = {
  visible: false,
};
