import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './HolidayAndOrderBadge.css';

/**
 * HolidayAndOrderBadge()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function HolidayAndOrderBadge(props) {
  const {
    item, calendarData, onPress, currentMonth, showSunday,
  } = props;
  const formattedDate = moment(item).format('YYYY-MM-DD');
  const [data, setData] = useState(null);
  const [isSunday, setIsSunday] = useState(false);

  useEffect(() => {
    if (calendarData) {
      setData((calendarData[formattedDate]) ? calendarData[formattedDate] : null);

      const day = item.day();
      setIsSunday((day === 0));
    }
  }, [calendarData, formattedDate, item]);

  const getStyle = () => {
    if (!moment(item).isSame(moment(currentMonth), 'month')) {
      return { opacity: 0.2 };
    }
    return {};
  };

  const getClass = () => {
    let classNames = 'HolidayAndOrderBadge';
    if (moment(item).isSame(moment(), 'day')) {
      classNames = `${classNames} Today`;
    }
    if (!showSunday) {
      classNames = `${classNames} WithoutSunday`;
    }
    return classNames;
  };

  if (!showSunday && isSunday) {
    return null;
  }

  const getCaption = (valueData) => {
    if (valueData.id) {
      if (valueData.approved) {
        return (
          <>
            <i className="fas fa-plane" />
            Urlaub
          </>
        );
      }
      return (
        <>
          <i className="fas fa-spin fa-life-ring" />
          Urlaubsantrag
        </>
      );
    } if (valueData.reclamationId) {
      return (
        <>
          <i className="fas fa-sync" />
          Reklamation
        </>
      );
    } if (valueData.orderId) {
      return (
        <>
          <i className="fas fa-truck" />
          Montage
        </>
      );
    }
    return null;
  };

  const getRowClass = (valueData) => {
    if (valueData.id) {
      if (valueData.approved) {
        return `BadgeRow Holiday ${(valueData.isStart) ? 'Start' : ''} ${(valueData.isEnd) ? 'End' : ''}`;
      }
      return `BadgeRow HolidayPending ${(valueData.isStart) ? 'Start' : ''} ${(valueData.isEnd) ? 'End' : ''}`;
    } if (valueData.reclamationId) {
      return 'BadgeRow Reclamation StartAndEnd';
    } if (valueData.orderId) {
      return 'BadgeRow Order StartAndEnd';
    }
    return null;
  };

  const handleOnPressItem = (e, param) => {
    e.stopPropagation();
    onPress(param);
  };

  const renderContent = () => {
    if (data) {
      return data.map((param) => (
        <div
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
          className={`${getRowClass(param)} `}
          key={Math.random()}
          onClick={(e) => handleOnPressItem(e, param)}
        >
          {getCaption(param)}
        </div>
      ));
    }
    return null;
  };

  if (calendarData) {
    return (
      <div
        className={getClass()}
        style={getStyle()}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
        onClick={() => onPress(formattedDate)}
      >
        <div className="Container Front">
          <div className="Headline">
            <div className="Left" />
            <div className="DayNumber">
              {moment(item).format('D')}
            </div>
          </div>
          <div className="Content">
            {renderContent()}
          </div>
          <div className="CalendarWeek">
            {`KW ${moment(item).format('WW')}`}
          </div>
        </div>
      </div>
    );
  }
  return null;
}

HolidayAndOrderBadge.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  calendarData: PropTypes.instanceOf(Object),
  onPress: PropTypes.func.isRequired,
  currentMonth: PropTypes.instanceOf(Object).isRequired,
  showSunday: PropTypes.bool,
};

HolidayAndOrderBadge.defaultProps = {
  calendarData: null,
  showSunday: false,
};
