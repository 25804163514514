import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import { getDeviceType } from '../../Library/Functions';
import ImageContainer from './ImageContainer';
import ImagePreviewOverlay from './ImagePreviewOverlay';
import {
  DEVICE_OTHER,
  DEVICE_OTHERDEVICE,
  DEVICETYPE_DAMAGE,
  DEVICETYPE_PREDAMAGE,
  DEVICETYPE_RECLAMATION,
  DEVICETYPE_RETURN,
  originatorTypeCaptions,
} from '../../Library/Types';
import ShowHideContainer from '../ShowHideContainer';

import './style.css';

/**
 * DamageItem()
 * @param props
 * @returns {*}
 * @constructor
 */
function DamageItem(props) {
  const { item, onPress } = props;

  const getDescription = () => {
    if (item.type === DEVICETYPE_PREDAMAGE || item.type === DEVICETYPE_DAMAGE) {
      return item.comment;
    }
    return item.deviceDescription;
  };

  const renderDeviceNumber = () => {
    if (item.type !== DEVICETYPE_PREDAMAGE && item.deviceNumber && item.deviceNumber.length > 0) {
      return <small>{` (${item.deviceNumber})`}</small>;
    }
    return null;
  };

  return (
    <div
      className={`Device ${(onPress) ? 'Clickable' : null}`}
      onClick={() => onPress(item)}
      tabIndex={-1}
      role="button"
      onKeyPress={() => {}}
    >
      <ImageContainer documents={item.documents} />
      <div className="Text">
        <h6>
          {item.deviceDescription}
          {renderDeviceNumber()}
        </h6>
        <div className="Comment">{getDescription()}</div>
      </div>
    </div>
  );
}

DamageItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onPress: PropTypes.func.isRequired,
};

/**
 * DeviceItem()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function DeviceItem(props) {
  const { item, onPress, isMonteur } = props;

  const handleClick = () => {
    if (onPress) {
      onPress(item);
    }
  };

  const renderDeviceNumber = () => {
    if (item.deviceNumber && item.deviceNumber.length > 0) {
      return <small>{` (${item.deviceNumber})`}</small>;
    }
    return null;
  };

  const renderHedline = () => {
    if (item.device !== DEVICE_OTHER && item.device !== DEVICE_OTHERDEVICE) {
      return (
        <h6>
          {getDeviceType(item.device).label}
          {renderDeviceNumber()}
        </h6>
      );
    }
    return (
      <h6>
        {item.deviceDescription}
        {renderDeviceNumber()}
      </h6>
    );
  };

  const renderOriginator = () => {
    if (item.type === DEVICETYPE_RECLAMATION && item.originator && isMonteur) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>
          {`Verursacher: ${originatorTypeCaptions[item.originator]}`}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`Device ${(onPress) ? 'Clickable' : null}`}
      onClick={handleClick}
      tabIndex={-1}
      role="button"
      onKeyPress={() => {}}
    >
      <ImageContainer documents={item.documents} />
      <div className="Text" style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        {renderHedline()}
        <div className="Comment">{item.comment}</div>
        {renderOriginator()}
      </div>
    </div>
  );
}

DeviceItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onPress: PropTypes.func,
  isMonteur: PropTypes.bool,
};

DeviceItem.defaultProps = {
  onPress: null,
  isMonteur: false,
};

/**
 * ServiceItem()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function ServiceItem(props) {
  const { item, onPress, isMonteur } = props;

  const handleClick = () => {
    if (onPress) {
      onPress(item);
    }
  };

  const renderHeadline = () => (
    <h6>
      {item.title}
      <ShowHideContainer visible={(item.number !== null && item.number.length > 0)}>
        <small>{` (${item.number})`}</small>
      </ShowHideContainer>
    </h6>
  );

  const renderOriginator = () => {
    if (item.type === DEVICETYPE_RECLAMATION && item.originator && isMonteur) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end' }}>
          {`Verursacher: ${originatorTypeCaptions[item.originator]}`}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`Device ${(onPress) ? 'Clickable' : null}`}
      onClick={handleClick}
      tabIndex={-1}
      role="button"
      onKeyPress={() => {}}
    >
      <ImageContainer documents={item.documents} />
      <div className="Text" style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        {renderHeadline()}
        <div className="Comment">{item.description}</div>
        {renderOriginator()}
      </div>
    </div>
  );
}

ServiceItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onPress: PropTypes.func,
  isMonteur: PropTypes.bool,
};

ServiceItem.defaultProps = {
  onPress: null,
  isMonteur: false,
};

/**
 * DeviceList()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function DeviceList(props) {
  const {
    devices, caption, onPress, colSize, showPreview, showImageOpenHint, isMonteur,
  } = props;
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);

  const handleOnPress = (item) => {
    if (showPreview && item.documents && item.documents.length > 0) {
      setShowImagePreview(true);
      setClickedItem(item);
    } else if (onPress) {
      onPress(item);
    }
  };

  const renderDevices = (itemData) => {
    if (itemData.itemId) {
      return <ServiceItem item={itemData} key={Math.random()} onPress={handleOnPress} isMonteur={isMonteur} />;
    }

    if (itemData.type === DEVICETYPE_DAMAGE || itemData.type === DEVICETYPE_PREDAMAGE) {
      return <DamageItem item={itemData} key={Math.random()} onPress={handleOnPress} />;
    }
    return <DeviceItem item={itemData} key={Math.random()} onPress={handleOnPress} isMonteur={isMonteur} />;
  };

  const renderImageOpenHint = () => {
    if (showImageOpenHint) {
      if (devices[0].type === DEVICETYPE_RETURN) {
        return (
          <p style={{ marginBottom: 30 }}>
            Hier sehen Sie alle Warenrücksendungen bzw. Rückläufer.
          </p>
        );
      }
      return (
        <p style={{ marginBottom: 30 }}>
          Zum Vergrößern der Bilder klicken Sie bitte auf einen Eintrag!
        </p>
      );
    }
    return null;
  };

  if (devices && devices.length > 0) {
    return (
      <div className={`DeviceList ${colSize} d-flex`}>
        <Panel boxPadding={10} marginBottom={25}>
          <h6>{caption}</h6>
          {renderImageOpenHint()}
          {devices.map((item) => renderDevices(item))}
        </Panel>

        <ImagePreviewOverlay
          visible={showImagePreview}
          onClose={() => setShowImagePreview(false)}
          documents={(clickedItem) ? clickedItem.documents : null}
        />
      </div>
    );
  }
  return null;
}

DeviceList.propTypes = {
  devices: PropTypes.instanceOf(Array),
  caption: PropTypes.string,
  onPress: PropTypes.func,
  colSize: PropTypes.string,
  showPreview: PropTypes.bool,
  showImageOpenHint: PropTypes.bool,
  isMonteur: PropTypes.bool,
};

DeviceList.defaultProps = {
  devices: [],
  caption: '',
  onPress: null,
  colSize: 'col-lg-12',
  showPreview: false,
  showImageOpenHint: true,
  isMonteur: false,
};
