import React from 'react';
import PropTypes from 'prop-types';

/**
 * ClickableTableRow()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ClickableTableRow(props) {
  const { onClick, children, style } = props;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <td onClick={onClick} tabIndex={-1} onKeyPress={() => {}} style={style}>
      {children}
    </td>
  );
}

ClickableTableRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.instanceOf(Object),
};

ClickableTableRow.defaultProps = {
  children: '',
  style: {},
};
