import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { markRead, remove, useDispatchGetNotification } from '../../../Redux/Action/NotificationAction';
import { get as getDemontageReport } from '../../../Redux/Action/DemontageReportAction';
import { get as getDeliveryReport } from '../../../Redux/Action/DeliveryReportAction';
import { get as getTask } from '../../../Redux/Action/User/TaskAction';
import LoadingModal from '../../../Components/LoadingModal';
import {
  NAVIGATION_DELIVERY_REPORT_DETAILS,
  NAVIGATION_DEMONTAGE_REPORT_DETAILS,
  NAVIGATION_OBJECT_DETAILS,
  NAVIGATION_OBJECT_TASK,
  NAVIGATION_SYSTEM_OBJECT_DETAILS,
  NOTIFICATION_REFERENCETYPE_APPOINTMENT_ADD_DATES,
  NOTIFICATION_REFERENCETYPE_APPOINTMENT_DATE_SELECTED,
  NOTIFICATION_REFERENCETYPE_APPOINTMENT_SELECT_DATE,
  NOTIFICATION_REFERENCETYPE_CLIENT_INVITE_ACCEPTED,
  NOTIFICATION_REFERENCETYPE_CLIENT_INVITE_EXPIRED,
  NOTIFICATION_REFERENCETYPE_CLIENTDOCUMENTINSURANCE,
  NOTIFICATION_REFERENCETYPE_CLIENTNOTEREMINDER,
  NOTIFICATION_REFERENCETYPE_DELIVERYREPORTFINISHED,
  NOTIFICATION_REFERENCETYPE_DEMONTAGEREPORTFINISHED,
  NOTIFICATION_REFERENCETYPE_MANDANT_REQUESTED,
  NOTIFICATION_REFERENCETYPE_MONTHLY_BILL,
  NOTIFICATION_REFERENCETYPE_NEWRECLAMATION,
  NOTIFICATION_REFERENCETYPE_OBJECTALLDOCUMENTSFINISHED,
  NOTIFICATION_REFERENCETYPE_OBJECTNEWDOCUMENT,
  NOTIFICATION_REFERENCETYPE_OBJECTNEWMESSAGE,
  NOTIFICATION_REFERENCETYPE_OBJECTPARTASSIGNED,
  NOTIFICATION_REFERENCETYPE_OBJECTPARTUNASSIGNED,
  NOTIFICATION_REFERENCETYPE_ORDERCREATEDBYMANDANT,
  NOTIFICATION_REFERENCETYPE_ORDERNEWMESSAGE,
  NOTIFICATION_REFERENCETYPE_ORDERNEWOFFER,
  NOTIFICATION_REFERENCETYPE_ORDEROFFERCANCELED,
  NOTIFICATION_REFERENCETYPE_PARTNERDATECHANGED,
  NOTIFICATION_REFERENCETYPE_RECLAMATIONAPPROVED,
  NOTIFICATION_REFERENCETYPE_RECLAMATIONCREATEDFORPARTNER,
  NOTIFICATION_REFERENCETYPE_SERVICE_CREATED,
  NOTIFICATION_REFERENCETYPE_SERVICE_ITEM_ADDED,
  NOTIFICATION_REFERENCETYPE_SERVICE_PARTNER_ASSIGNED,
  NOTIFICATION_REFERENCETYPE_SERVICE_PARTNER_UNASSIGNED,
  NOTIFICATION_REFERENCETYPE_SERVICECREATEDFORPARTNER,
  NOTIFICATION_REFERENCETYPE_USER_TASK_ASSIGNED,
  NOTIFICATION_REFERENCETYPE_USER_TASK_DUE_DATE,
} from '../../../Library/Types';
import { formatDateTime } from '../../../Library/Functions';
import { get } from '../../../Redux/Action/ObjectAction';

import './styles.css';

/**
 * DefaultItem()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DefaultItem(props) {
  const {
    item, dispatchMarkRead, history, dispatchDelete, dispatchGetObject, dispatchGetDemontageReport,
    dispatchGetDeliveryReport, dispatchGetTask,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatchGetNotification = useDispatchGetNotification();

  const onNavigate = () => {
    dispatchMarkRead([item.notificationId]).then(() => {
      let uri = null;

      switch (item.referenceType) {
        case NOTIFICATION_REFERENCETYPE_APPOINTMENT_ADD_DATES:
        case NOTIFICATION_REFERENCETYPE_APPOINTMENT_SELECT_DATE:
        case NOTIFICATION_REFERENCETYPE_APPOINTMENT_DATE_SELECTED:
        case NOTIFICATION_REFERENCETYPE_SERVICE_CREATED:
        case NOTIFICATION_REFERENCETYPE_SERVICE_ITEM_ADDED:
        case NOTIFICATION_REFERENCETYPE_SERVICE_PARTNER_ASSIGNED: {
          dispatchGetNotification(item.notificationId).then((notification) => {
            uri = '/dashboard';
            if (notification && notification.redirectUri) {
              uri = notification.redirectUri;
            }
            history.push(uri);
          });
          break;
        }
        case NOTIFICATION_REFERENCETYPE_CLIENTNOTEREMINDER: {
          uri = `/system/client/${item.referenceId}/details`;
          break;
        }
        case NOTIFICATION_REFERENCETYPE_ORDERNEWOFFER: {
          uri = `/system/order/${item.referenceId}/offers`;
          break;
        }
        case NOTIFICATION_REFERENCETYPE_NEWRECLAMATION:
        case NOTIFICATION_REFERENCETYPE_RECLAMATIONAPPROVED:
        case NOTIFICATION_REFERENCETYPE_ORDERCREATEDBYMANDANT:
        case NOTIFICATION_REFERENCETYPE_ORDERNEWMESSAGE: {
          uri = `/order/${item.referenceId}`;
          if (item.isSystem) {
            uri = `/system/order/${item.referenceId}`;
          }
          break;
        }
        case NOTIFICATION_REFERENCETYPE_OBJECTNEWDOCUMENT:
        case NOTIFICATION_REFERENCETYPE_OBJECTPARTASSIGNED:
        case NOTIFICATION_REFERENCETYPE_OBJECTALLDOCUMENTSFINISHED:
        case NOTIFICATION_REFERENCETYPE_RECLAMATIONCREATEDFORPARTNER:
        case NOTIFICATION_REFERENCETYPE_SERVICECREATEDFORPARTNER:
        case NOTIFICATION_REFERENCETYPE_PARTNERDATECHANGED:
        case NOTIFICATION_REFERENCETYPE_OBJECTNEWMESSAGE: {
          setLoading(true);
          dispatchGetObject(item.referenceId).then((object) => {
            uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', object.orderId)
              .replace('{objectId}', object.objectId);
            if (item.isSystem) {
              uri = NAVIGATION_SYSTEM_OBJECT_DETAILS.replace('{orderId}', object.orderId)
                .replace('{objectId}', object.objectId);
            }

            history.push(uri);
          });
          break;
        }
        case NOTIFICATION_REFERENCETYPE_ORDEROFFERCANCELED: {
          uri = `/system/order/${item.referenceId}/offers`;
          break;
        }
        case NOTIFICATION_REFERENCETYPE_CLIENTDOCUMENTINSURANCE: {
          uri = '/admin/documents';
          break;
        }
        case NOTIFICATION_REFERENCETYPE_MONTHLY_BILL: {
          uri = '/admin/invoices';
          break;
        }
        case NOTIFICATION_REFERENCETYPE_MANDANT_REQUESTED: {
          uri = '/admin/mandant';
          break;
        }
        case NOTIFICATION_REFERENCETYPE_CLIENT_INVITE_ACCEPTED: {
          uri = '/admin/mandant';
          break;
        }
        case NOTIFICATION_REFERENCETYPE_CLIENT_INVITE_EXPIRED: {
          uri = '/admin/mandant';
          break;
        }
        case NOTIFICATION_REFERENCETYPE_DEMONTAGEREPORTFINISHED: {
          setLoading(true);
          dispatchGetDemontageReport(item.referenceId).then((response) => {
            uri = NAVIGATION_DEMONTAGE_REPORT_DETAILS.replace('{orderId}', response.orderId)
              .replace('{objectId}', response.objectId)
              .replace('{demontageReportId}', response.demontageReportId);
            history.push(uri);
          });
          break;
        }
        case NOTIFICATION_REFERENCETYPE_DELIVERYREPORTFINISHED: {
          setLoading(true);
          dispatchGetDeliveryReport(item.referenceId).then((response) => {
            uri = NAVIGATION_DELIVERY_REPORT_DETAILS.replace('{orderId}', response.orderId)
              .replace('{objectId}', response.objectId)
              .replace('{deliveryReportId}', response.deliveryReportId);
            history.push(uri);
          });
          break;
        }
        case NOTIFICATION_REFERENCETYPE_USER_TASK_DUE_DATE:
        case NOTIFICATION_REFERENCETYPE_USER_TASK_ASSIGNED: {
          setLoading(true);
          dispatchGetTask(item.referenceId).then((response) => {
            if (response.objectId && response.orderId) {
              uri = NAVIGATION_OBJECT_TASK.replace('{orderId}', response.orderId)
                .replace('{objectId}', response.objectId);
            } else {
              uri = '/profile/task';
            }
            history.push(uri);
          });
          break;
        }
        default:
          history.push('/dashboard');
          return null;
      }

      if (uri !== null) {
        history.push(uri);
      }
      return null;
    });
  };

  const onDelete = (e) => {
    e.stopPropagation();
    dispatchDelete(item.notificationId);
  };

  const getIcon = () => {
    switch (item.referenceType) {
      case NOTIFICATION_REFERENCETYPE_APPOINTMENT_ADD_DATES:
      case NOTIFICATION_REFERENCETYPE_APPOINTMENT_SELECT_DATE:
      case NOTIFICATION_REFERENCETYPE_APPOINTMENT_DATE_SELECTED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-info" />
            <i className="fas fa-clipboard fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_CLIENTNOTEREMINDER:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-info" />
            <i className="fas fa-clipboard fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_ORDERNEWOFFER:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-danger" />
            <i className="fas fa-comment-dollar fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_OBJECTNEWMESSAGE:
      case NOTIFICATION_REFERENCETYPE_ORDERNEWMESSAGE:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-warning" />
            <i className="fas fa-comment-medical fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_ORDEROFFERCANCELED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-info" />
            <i className="fas fa-ban fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_SERVICE_CREATED:
      case NOTIFICATION_REFERENCETYPE_SERVICE_ITEM_ADDED:
      case NOTIFICATION_REFERENCETYPE_NEWRECLAMATION:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-success" />
            <i className="fas fa-sync fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_RECLAMATIONAPPROVED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-success" />
            <i className="fas fa-sync fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_CLIENTDOCUMENTINSURANCE:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-danger" />
            <i className="fas fa-exclamation fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_MANDANT_REQUESTED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-success" />
            <i className="fas fa-user-check fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_CLIENT_INVITE_ACCEPTED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-success" />
            <i className="fas fa-user-check fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_CLIENT_INVITE_EXPIRED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-danger" />
            <i className="fas fa-ban fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_ORDERCREATEDBYMANDANT:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-primary" />
            <i className="fas fa-user-check fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_DEMONTAGEREPORTFINISHED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-primary" />
            <i className="fas fa-trash fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_DELIVERYREPORTFINISHED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-primary" />
            <i className="fas fa-truck fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_OBJECTNEWDOCUMENT:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-primary" />
            <i className="fas fa-file-medical fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_MONTHLY_BILL:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-success" />
            <i className="fas fa-euro-sign fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_PARTNERDATECHANGED:
      case NOTIFICATION_REFERENCETYPE_SERVICECREATEDFORPARTNER:
      case NOTIFICATION_REFERENCETYPE_RECLAMATIONCREATEDFORPARTNER:
      case NOTIFICATION_REFERENCETYPE_OBJECTALLDOCUMENTSFINISHED:
      case NOTIFICATION_REFERENCETYPE_OBJECTPARTASSIGNED:
      case NOTIFICATION_REFERENCETYPE_SERVICE_PARTNER_ASSIGNED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-info" />
            <i className="fas fa-info fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_USER_TASK_ASSIGNED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-success" />
            <i className="fas fa-tasks fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_USER_TASK_DUE_DATE:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-danger" />
            <i className="fas fa-tasks fa-stack-1x fa-inverse" />
          </>
        );
      case NOTIFICATION_REFERENCETYPE_OBJECTPARTUNASSIGNED:
      case NOTIFICATION_REFERENCETYPE_SERVICE_PARTNER_UNASSIGNED:
        return (
          <>
            <i className="fas fa-circle fa-stack-2x text-danger" />
            <i className="fas fa-exclamation fa-stack-1x fa-inverse" />
          </>
        );
      default:
        return null;
    }
  };

  const renderCompanyHint = () => {
    if (item.clientId && !item.userId && !item.isSystem) {
      return (
        <div style={{ fontSize: 12, fontStyle: 'italic', marginTop: 20 }}>
          <i className="fas fa-info-circle text-warning" style={{ marginRight: 6 }} />
          Diese Mitteilung ist für mehrere Personen in Ihrem Unternehmen sichtbar. Das Löschen der Mitteilung führt
          dazu, dass diese bei allen Personen gelöscht wird!
        </div>
      );
    }
    return null;
  };

  const renderSystemHint = () => {
    if (item.isSystem) {
      return (
        <div style={{ fontSize: 12, fontStyle: 'italic', marginTop: 20 }}>
          <i className="fas fa-exclamation-triangle text-danger" style={{ marginRight: 6 }} />
          Systemmitteilung! Diese Mitteilung wird allen Benutzern angezeigt die Systemrechte haben. Das löschen der
          Mitteilung löscht diese für alle Benutzer!
        </div>
      );
    }
    return null;
  };

  return (
    <div className="NoteItemContainer" onClick={onNavigate} tabIndex={-1} role="button" onKeyPress={() => {}}>
      <div className="LeftContainer">
        <span className="fa-stack fa-2x">
          {getIcon()}
        </span>
      </div>
      <div className="MiddleContainer">
        <div className={`Caption ${(item.read) ? 'Read' : null}`}>
          {item.caption}
        </div>
        <div style={{ marginBottom: 10, color: '#acacac' }}>
          {formatDateTime(item.createdDate)}
        </div>
        <div className="Description">
          {item.description}
        </div>
        {renderCompanyHint()}
        {renderSystemHint()}
      </div>
      <div className="RightContainer">
        <i className="fas fa-trash" onClick={onDelete} tabIndex={-1} role="button" onKeyPress={() => {}} />
        <i className="fas fa-arrow-circle-right" />
      </div>

      <LoadingModal visible={loading} text="Sie werden weitergeleitet..." />
    </div>
  );
}

DefaultItem.propTypes = {
  dispatchDelete: PropTypes.func.isRequired,
  dispatchMarkRead: PropTypes.func.isRequired,
  dispatchGetDeliveryReport: PropTypes.func.isRequired,
  dispatchGetDemontageReport: PropTypes.func.isRequired,
  dispatchGetTask: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  dispatchGetObject: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchMarkRead: (ids) => dispatch(markRead(ids)),
    dispatchDelete: (id) => dispatch(remove(id)),
    dispatchGetObject: (objectId) => dispatch(get(objectId)),
    dispatchGetDemontageReport: (reportId) => dispatch(getDemontageReport(reportId)),
    dispatchGetDeliveryReport: (reportId) => dispatch(getDeliveryReport(reportId)),
    dispatchGetTask: (taskId) => dispatch(getTask(taskId)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(withRouter(DefaultItem));
