import React from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../../../../Library/Axios';
import { defaultUri as serviceDefaultUri } from '../ServiceAction';

/**
 * Prepare default uri and replace all placeholders
 * @param service
 * @returns {string}
 */
function prepareUrl(service) {
  const defaultUri = `${serviceDefaultUri}/{serviceId}/user`;
  return defaultUri.replace('{orderId}', service.orderId)
    .replace('{objectId}', service.objectId)
    .replace('{serviceId}', service.serviceId);
}

export const OBJECT_SERVICE_USER_CREATE_START = 'OBJECT_SERVICE_USER_CREATE_START';
export const OBJECT_SERVICE_USER_CREATE_SUCCESS = 'OBJECT_SERVICE_USER_CREATE_SUCCESS';
export const OBJECT_SERVICE_USER_CREATE_ERROR = 'OBJECT_SERVICE_USER_CREATE_ERROR';

/**
 * useDispatchServiceCreateUser()
 * @returns {function(service:Object, userId:number, disableDispositionChecks:boolean = false): *}
 */
export function useDispatchServiceCreateUser() {
  const dispatch = useDispatch();

  return React.useCallback((service, userId, disableDispositionChecks = false) => {
    dispatch({ type: OBJECT_SERVICE_USER_CREATE_START, payload: { service, userId } });
    const config = { params: { disableDispositionChecks: (!disableDispositionChecks) ? 0 : 1 } };

    return axios().post(prepareUrl(service), { userId }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_USER_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_USER_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const OBJECT_SERVICE_USER_DELETE_START = 'OBJECT_SERVICE_USER_DELETE_START';
export const OBJECT_SERVICE_USER_DELETE_SUCCESS = 'OBJECT_SERVICE_USER_DELETE_SUCCESS';
export const OBJECT_SERVICE_USER_DELETE_ERROR = 'OBJECT_SERVICE_USER_DELETE_ERROR';

/**
 * useDispatchServiceDeleteUser()
 * @returns {function(service:Object, userId:number): *}
 */
export function useDispatchServiceDeleteUser() {
  const dispatch = useDispatch();

  return React.useCallback((service, userId) => {
    dispatch({ type: OBJECT_SERVICE_USER_DELETE_START, payload: { userId } });

    const uri = `${prepareUrl(service)}/${userId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_USER_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_USER_DELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
