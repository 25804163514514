import 'jquery/dist/jquery';
import 'popper.js/dist/popper';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
// eslint-disable-next-line no-unused-vars
import localId from 'moment/locale/de';
import { ThemeProvider } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import AppRouter from './Router/AppRouter';
import store from './Redux/Store';
import DefaultTheme from './Assets/DefaultTheme';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={DefaultTheme}>
      <AppRouter />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
