import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import * as Redux from 'react-redux';
import Panel from '../../Panel';

/**
 * SystemOrderNavigation()
 * @returns {*}
 * @constructor
 */
function SystemOrderNavigation(props) {
  const {
    internalObjects, internalLoading,
    externalObjects, externalLoading,
  } = props;
  const internalServices = Redux.useSelector((state) => state.systemService.internal.services);
  const externalServices = Redux.useSelector((state) => state.systemService.external.services);
  const reclamation = Redux.useSelector((state) => state.systemReclamation.reclamations);

  const renderInternalOrder = () => {
    let caption = 'Interne Aufträge';
    if (internalObjects && internalObjects.length > 0) {
      caption += ` (${internalObjects.length})`;
    }

    let icon = 'fas fa-home';
    if (internalLoading) {
      icon = 'fas fa-spinner';
    }

    return (
      <li>
        <NavLink className="nav-link" to="/system/order/internal">
          <i className={icon} />
          {caption}
        </NavLink>
      </li>
    );
  };

  const renderExternalOrder = () => {
    let caption = 'Externe Aufträge';
    if (externalObjects && externalObjects.length > 0) {
      caption += ` (${externalObjects.length})`;
    }

    let icon = 'fas fa-cubes';
    if (externalLoading) {
      icon = 'fas fa-spinner';
    }

    return (
      <li>
        <NavLink className="nav-link" to="/system/order/external">
          <i className={icon} />
          {caption}
        </NavLink>
      </li>
    );
  };

  const renderExternalService = () => {
    let caption = 'Externe Services';
    if (externalServices && externalServices.length > 0) {
      caption += ` (${externalServices.length})`;
    }

    let icon = 'fas fa-sync';
    if (externalLoading) {
      icon = 'fas fa-spinner';
    }

    return (
      <li>
        <NavLink className="nav-link" to="/system/service/external">
          <i className={icon} />
          {caption}
        </NavLink>
      </li>
    );
  };

  const renderInternalService = () => {
    let caption = 'Interne Services';
    if (internalServices && internalServices.length > 0) {
      caption += ` (${internalServices.length})`;
    }

    let icon = 'fas fa-home';
    if (externalLoading) {
      icon = 'fas fa-spinner';
    }

    return (
      <li>
        <NavLink className="nav-link" to="/system/service/internal">
          <i className={icon} />
          {caption}
        </NavLink>
      </li>
    );
  };

  const renderReclamation = () => {
    let caption = 'Reklamationen';
    if (reclamation && reclamation.length > 0) {
      caption += ` (${reclamation.length})`;
    }

    let icon = 'fas fa-sync';
    if (externalLoading) {
      icon = 'fas fa-spinner';
    }

    return (
      <li>
        <NavLink className="nav-link" to="/system/reclamation">
          <i className={icon} />
          {caption}
        </NavLink>
      </li>
    );
  };

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            Auftragsübersicht
          </h6>
        </div>
        <hr />
        <ul>
          {renderExternalOrder()}
          {renderInternalOrder()}
          <hr />
          {renderExternalService()}
          {renderInternalService()}
          <hr />
          {renderReclamation()}
          <hr />
          <li>
            <NavLink className="nav-link" to="/system" activeClassName={null}>
              <i className="fas fa-angle-double-left" />
              Zurück
            </NavLink>
          </li>
        </ul>
      </div>
    </Panel>
  );
}

SystemOrderNavigation.propTypes = {
  internalObjects: PropTypes.instanceOf(Array),
  internalLoading: PropTypes.bool,
  externalObjects: PropTypes.instanceOf(Array),
  externalLoading: PropTypes.bool,
};

SystemOrderNavigation.defaultProps = {
  internalObjects: null,
  internalLoading: false,
  externalObjects: null,
  externalLoading: false,
};

function mapStoreToProps(store) {
  return {
    internalObjects: store.systemOrder.internal.objects,
    internalLoading: store.systemOrder.internal.loading,
    externalObjects: store.systemOrder.external.objects,
    externalLoading: store.systemOrder.external.loading,
  };
}

export default connect(mapStoreToProps)(withRouter(SystemOrderNavigation));
