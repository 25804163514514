import PropTypes from 'prop-types/prop-types';

/**
 * ShowHideContainer();
 * @param props
 * @returns {ShowHideContainer.props.children|null}
 * @constructor
 */
export default function ShowHideContainer(props) {
  const { visible, children } = props;

  if (visible) {
    return children;
  }
  return null;
}

ShowHideContainer.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ShowHideContainer.defaultProps = {
  visible: true,
};
