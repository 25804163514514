import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: ${(props) => props.theme.cellSeparatorHeight}px;
  border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  border-left: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.backgroundGrayLight};
  background-color: ${(props) => props.theme.color.backgroundGrayLight};
  width: ${(props) => props.theme.timelineWidth}px;
`;

/**
 * GroupSeparator()
 * @returns {*}
 * @constructor
 */
export default function GroupSeparator() {
  return (
    <Container key="Separator" />
  );
}
