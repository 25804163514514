import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import {
  NavLink, useRouteMatch, withRouter,
} from 'react-router-dom';
import Panel from '../../Panel';
import ModalDialog from '../../ModalDialog';
import { loginAsClient, update } from '../../../Pages/System/Redux/Action/ClientAction';
import { CLIENTTYPE_MONTEUR } from '../../../Library/Types';
import StatusBadge from '../../StatusBadge';

/**
 * SystemClientDetailsNavigation()
 * @returns {*}
 * @constructor
 */
function SystemClientDetailsNavigation(props) {
  const {
    client, history, dispatchLoginAsClient, dispatchUpdate, user,
  } = props;
  const { url } = useRouteMatch();
  const [showLoginAs, setShowLoginAs] = React.useState(false);
  const [showApprove, setShowApprove] = React.useState(false);

  const handleLoginAsClient = () => {
    dispatchLoginAsClient(client.clientId).then(() => {
      setShowLoginAs(false);
      history.push('/dashboard');
    });
  };

  const handleApprove = () => {
    dispatchUpdate(client.clientId, { approved: true, approvedId: user.userId }).then(() => {
      setShowApprove(false);
    });
  };

  const renderName = () => {
    if (client) {
      return client.name;
    }
    return null;
  };

  const renderApproveLink = () => {
    if (client && client.type === CLIENTTYPE_MONTEUR && !client.approved) {
      return (
        <li>
          {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
          <a className="nav-link" href={null} onClick={() => setShowApprove(true)} style={{ cursor: 'pointer' }}>
            <i className="fas fa-check-circle" />
            Monteur freischalten
          </a>
        </li>
      );
    }
    return null;
  };

  const renderNotApprovedBadge = () => {
    if (client && client.type === CLIENTTYPE_MONTEUR && !client.approved) {
      return (
        <StatusBadge
          type="Danger"
          icon="fas fa-times-circle"
          caption="Monteur nicht freigegeben"
          containerStyle={{ marginTop: 10 }}
        />
      );
    }
    return null;
  };

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            <div>Kundendetails</div>
            <small className="text-muted">
              {renderName()}
            </small>
          </h6>

          <StatusBadge
            type="Primary"
            icon={(client && client.type === CLIENTTYPE_MONTEUR) ? 'fas fa-tools' : 'fas fa-building'}
            caption={(client && client.type === CLIENTTYPE_MONTEUR) ? 'Monteur' : 'Verkäufer'}
          />
          {renderNotApprovedBadge()}
        </div>

        <hr />

        <ul>
          <li>
            <NavLink className="nav-link" to={`${url}/details`}>
              <i className="fas fa-info-circle" />
              Details
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/orders`}>
              <i className="fas fa-sign-out-alt" />
              Alle Aufträge
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/invoices`}>
              <i className="fas fa-euro-sign" />
              Rechnungen
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/notes`}>
              <i className="far fa-comment-alt" />
              Notizen
            </NavLink>
          </li>
          <li>
            {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
            <a className="nav-link" href={null} onClick={() => setShowLoginAs(true)} style={{ cursor: 'pointer' }}>
              <i className="fas fa-sign-in-alt" />
              Anmelden als...
            </a>
          </li>
          {renderApproveLink()}
          <hr />
          <li>
            <NavLink
              className="nav-link"
              to="/system"
              activeClassName={null}
            >
              <i className="fas fa-angle-double-left" />
              Zurück
            </NavLink>
          </li>
        </ul>
      </div>

      <ModalDialog
        onClose={() => setShowLoginAs(false)}
        confirmCaption="Jetzt anmelden als..."
        onConfirm={handleLoginAsClient}
        visible={showLoginAs}
        centered
      >
        <h5 className="hk-text-primary">
          <i className="fas fa-sign-in-alt" style={{ marginRight: 10 }} />
          Anmelden als...
        </h5>
        <p>
          Sie wechseln jetzt die Anmeldung und werden als
          <strong>{` "${(client) ? client.name : ''}" `}</strong>
          angemeldet. Sie haben dann vollen Adminzugriff auf alle Daten des Kunden. Um den Modus zu verlassen
          müssen Sie sich mit dem Kunden regulär abmelden und erneut mit Ihren eigenen Zugangsdaten wieder anmelden!
        </p>
        <p>
          Dieser Vorgang wird protokolliert und sollte nur in Ausnahmefällen verwendet werden!
        </p>
      </ModalDialog>

      <ModalDialog
        onClose={() => setShowApprove(false)}
        confirmCaption="Jetzt freigeben"
        onConfirm={handleApprove}
        visible={showApprove}
        centered
      >
        <h5 className="hk-text-primary">
          <i className="fas fa-check-circle" style={{ marginRight: 10 }} />
          Monteur freigeben
        </h5>
        <p>
          Bitte bestätigen Sie das Sie den Monteur
          <strong>{` "${(client) ? client.name : ''}" `}</strong>
          für die Nutzung der exterenn Angebote freischalten möchten. Der Monteur kann dann die exterenen Angebot
          einsehen und annehmen.
        </p>
      </ModalDialog>
    </Panel>
  );
}

SystemClientDetailsNavigation.propTypes = {
  dispatchLoginAsClient: PropTypes.func.isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

SystemClientDetailsNavigation.defaultProps = {
  client: null,
};

function mapStoreToProps(store) {
  return {
    client: store.systemClient.selectedClient,
    user: store.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLoginAsClient: (clientId) => dispatch(loginAsClient(clientId)),
    dispatchUpdate: (clientId, fields) => dispatch(update(clientId, fields)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(SystemClientDetailsNavigation));
