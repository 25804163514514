import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Panel from '../../../../Components/Panel';
import { CLIENTTYPE_DEALER } from '../../../../Library/Types';
import { ORDERSTATE_PARTNER_CREATED } from '../../../../Library/StateTypes';

/**
 * PartnerHint
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function PartnerHint(props) {
  const { order, client, partners } = props;
  const [partner, setPartner] = React.useState(null);

  React.useEffect(() => {
    if (order && partners) {
      let filtered = [];
      if (client.type === CLIENTTYPE_DEALER) {
        filtered = partners.filter((item) => item.partner.clientId === order.clientId);
      } else {
        filtered = partners.filter((item) => item.partner.clientId === order.mandantId);
      }
      if (filtered && filtered.length > 0) {
        setPartner(filtered[0].partner);
      }
    }
  }, [order, partners, client.type]);

  const renderHint = () => {
    if (order.state === ORDERSTATE_PARTNER_CREATED) {
      return (
        'Der Auftrag wurde vom Partner noch nicht bestätigt. Solange der Auftrag noch nicht bestätigt ist, '
        + 'können Sie den Auftrag bearbeiten oder ändern. Nach der Bestätigung, kann nur noch der Monteur '
        + 'den Auftrag anpassen.'
      );
    }
    return (
      'Der Auftrag wurde vom Partner bestätigt. Sie können den Auftrag nicht mehr selber bearbeiten.'
    );
  };

  const renderDependingContent = () => {
    if (order.mandantId === client.clientId) {
      return (
        <>
          <strong>
            {`Dies ist ein Partnerauftrag, welchen Sie für Ihren Partner ${partner.name} erstellt haben. `}
          </strong>
          <br />
          {renderHint()}
        </>
      );
    }
    if (order.state !== ORDERSTATE_PARTNER_CREATED) {
      return (
        <>
          <strong>
            {`Dieser Partnerauftrag wurde für Sie erstellt von ${partner.name}.`}
          </strong>
          <br />
          Sie haben den Auftrag bestätigt und können diesen jetzt wie einen eigenen internen Auftrag durchführen!
        </>
      );
    }
    return null;
  };

  // If not approved by the client the client will see another message with the information of the created user/partner
  if (order.clientId === client.clientId && order.state === ORDERSTATE_PARTNER_CREATED) {
    return null;
  }

  if (order.mandantId && partner) {
    return (
      <Panel withPadding={false} marginBottom={20}>
        <div className="text-center">
          <i className="fas fa-user-check text-info" />
          <br />
          {renderDependingContent()}
        </div>
      </Panel>
    );
  }
  return null;
}

PartnerHint.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  partners: PropTypes.instanceOf(Array),
};

PartnerHint.defaultProps = {
  partners: null,
};

function mapStoreToProps(store) {
  return {
    partners: store.client.partners,
  };
}

export default connect(mapStoreToProps, () => ({}))(PartnerHint);
