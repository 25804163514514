import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import Input from '../../../../Components/Input';
import { isValidFloatNumberFormat, formatDate } from '../../../../Library/Functions';

/**
 * ApproveOfferDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ApproveOfferDialog(props) {
  const {
    offer, visible, onClose, onSave,
  } = props;
  const [dealerPrice, setDealerPrice] = React.useState('');
  const [dealerPriceError, setDealerPriceError] = React.useState(false);

  React.useEffect(() => {
    if (offer) {
      setDealerPrice((offer.price * 1.25).toFixed(2));
    }
  }, [offer]);

  const handleOnSave = () => {
    setDealerPriceError(false);
    let hasError = false;

    if (!dealerPrice || dealerPrice === '0' || !isValidFloatNumberFormat(dealerPrice)) {
      setDealerPriceError(true);
      hasError = true;
    }

    if (!hasError) {
      onSave({ offer, dealerPrice });
    }
  };

  const renderAlternativeDatHint = () => {
    if (offer && offer.alternativeDate) {
      return (
        <div className="alert alert-danger">
          <strong>ACHTUNG: </strong>
          Es dieses Angebot ist mit einem Alternativdatum versehen! Wenn Sie dieses Angebot annehmen wird
          das Montagedatum des Auftrags auf
          <strong>
            {` ${formatDate(offer.alternativeDate)} `}
          </strong>
          geändert! Bitte informieren Sie vorab den Verkäufer über das neue Datum!
        </div>
      );
    }
    return null;
  };

  return (
    <ModalDialog
      onClose={onClose}
      visible={visible}
      confirmCaption="Angebot jetzt übernehmen"
      onConfirm={handleOnSave}
    >
      <h5>Angebot übernehmen</h5>
      <p>
        Bitte vergeben Sie einen Preis für den Verkäufer oder übernehmen Sie den autom. berechneten Verkäuferpreis. Der
        berechnete Verkäuferpreis ist 25% höher als das abgegebene Angebot! Nach erfolgreicher übernahme des Angebots
        wird der Verkäufer per E-Mail darüber informiert das sein Angebot berechnet wurde!
      </p>
      <hr />

      {renderAlternativeDatHint()}

      <Input
        onChange={() => {}}
        value={(offer) ? offer.price : ''}
        disabled
        label="Angebotspreis"
        rightIcon="fas fa-euro-sign"
      />
      <Input
        onChange={setDealerPrice}
        value={dealerPrice}
        label="Verkäuferpreis"
        rightIcon="fas fa-euro-sign"
        autoFocus
        hasError={dealerPriceError}
      />

    </ModalDialog>
  );
}

ApproveOfferDialog.propTypes = {
  offer: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

ApproveOfferDialog.defaultProps = {
  visible: false,
  offer: null,
};
