import React from 'react';
import PropTypes from 'prop-types';
import * as Redux from 'react-redux';
import ProposedDate from '../ProposedDate/ProposedDate';
import { useDispatchGetProposedDates } from '../../../Redux/Action/Order/Object/Service/Appointment/ProposedDateAction';

/**
 * ProposedDates()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProposedDates(props) {
  const {
    orderId, objectId, serviceId, appointmentId, onChange,
  } = props;

  const proposedDates = Redux.useSelector((state) => state.appointment.current.proposedDates);

  const clientId = Redux.useSelector((state) => state.client.client.clientId);
  const dealerClientId = Redux.useSelector((state) => state.order.order.clientId);
  const monteurClientId = Redux.useSelector((state) => state.objectService.service.assignedClientId);

  const dispatchGetProposedDates = useDispatchGetProposedDates(orderId, objectId, serviceId, appointmentId);

  const display = proposedDates.map((date) => date.selected).includes(true);

  const isDeletable = () => (display ? false : clientId === monteurClientId);
  const isEditable = () => (display ? false : clientId === monteurClientId);
  const isSelectable = () => (display ? false : clientId === dealerClientId);

  const handleOnChange = () => {
    dispatchGetProposedDates().then(() => {
      if (onChange) {
        onChange();
      }
    });
  };

  const renderDates = () => proposedDates.map((data) => {
    const {
      id, date, startTime, comment, selected,
    } = data;
    return (
      <ProposedDate
        key={id}
        orderId={orderId}
        objectId={objectId}
        serviceId={serviceId}
        appointmentId={appointmentId}
        dateId={id}
        date={date}
        startTime={startTime}
        comment={comment}
        selected={selected}
        deletable={isDeletable()}
        editable={isEditable()}
        selectable={isSelectable()}
        onChange={handleOnChange}
        onSelect={onChange}
      />
    );
  });

  if (proposedDates && proposedDates.length > 0) {
    return (
      <div style={{ marginBottom: 30 }}>
        {renderDates()}
      </div>
    );
  }
  return (
    <div className="alert alert-info">
      <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
      Noch keine Terminvorschläge eingetragen!
    </div>
  );
}

ProposedDates.propTypes = {
  orderId: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  serviceId: PropTypes.number.isRequired,
  appointmentId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

ProposedDates.defaultProps = {
  onChange: undefined,
};
