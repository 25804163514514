import axios from '../../Library/Axios';

export const defaultUri = '/order/{orderId}/message';

export const ORDERMESSAGE_GETLIST_START = 'ORDERMESSAGE_GETLIST_START';
export const ORDERMESSAGE_GETLIST_SUCCESS = 'ORDERMESSAGE_GETLIST_SUCCESS';
export const ORDERMESSAGE_GETLIST_ERROR = 'ORDERMESSAGE_GETLIST_ERROR';

/**
 * getList()
 * @param orderId
 * @returns {function(*): Promise<any | never>}
 */
export function getList(orderId) {
  return (dispatch) => {
    dispatch({ type: ORDERMESSAGE_GETLIST_START });

    const uri = `${defaultUri.replace('{orderId}', orderId)}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDERMESSAGE_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDERMESSAGE_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const ORDERMESSAGE_CREATE_START = 'ORDERMESSAGE_CREATE_START';
export const ORDERMESSAGE_CREATE_SUCCESS = 'ORDERMESSAGE_CREATE_SUCCESS';
export const ORDERMESSAGE_CREATE_ERROR = 'ORDERMESSAGE_CREATE_ERROR';

/**
 * create()
 * @param orderId
 * @param message
 * @returns {function(*): Promise<unknown>}
 */
export function create(orderId, message) {
  return (dispatch) => {
    dispatch({ type: ORDERMESSAGE_CREATE_START });

    const uri = `${defaultUri.replace('{orderId}', orderId)}`;

    return axios().post(uri, { message }).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDERMESSAGE_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDERMESSAGE_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const ORDERMESSAGE_DELETE_START = 'ORDERMESSAGE_DELETE_START';
export const ORDERMESSAGE_DELETE_SUCCESS = 'ORDERMESSAGE_DELETE_SUCCESS';
export const ORDERMESSAGE_DELETE_ERROR = 'ORDERMESSAGE_DELETE_ERROR';

/**
 * deleteMessage()
 * @param message
 * @returns {function(*): Promise<unknown>}
 */
export function deleteMessage(message) {
  return (dispatch) => {
    dispatch({ type: ORDERMESSAGE_DELETE_START });

    const uri = `${defaultUri.replace('{orderId}', message.orderId)}/${message.messageId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDERMESSAGE_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDERMESSAGE_DELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const ORDERMESSAGE_APPROVE_START = 'ORDERMESSAGE_APPROVE_START';
export const ORDERMESSAGE_APPROVE_SUCCESS = 'ORDERMESSAGE_APPROVE_SUCCESS';
export const ORDERMESSAGE_APPROVE_ERROR = 'ORDERMESSAGE_APPROVE_ERROR';

/**
 * approve()
 * @param message
 * @returns {function(*): Promise<unknown>}
 */
export function approve(message) {
  return (dispatch) => {
    dispatch({ type: ORDERMESSAGE_APPROVE_START });

    const uri = `${defaultUri.replace('{orderId}', message.orderId)}/${message.messageId}`;
    const config = {
      params: { action: 'approve' },
    };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDERMESSAGE_APPROVE_SUCCESS, payload: data });
      dispatch(getList(message.orderId));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDERMESSAGE_APPROVE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
