import React, { useRef } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Panel from '../../Panel';
import { get, isValid, redeem } from '../../../Redux/Action/ClientVoucherAction';
import Input from '../../Input';
import { CLIENTTYPE_DEALER, ERRORCODE_VOUCHER_WRONG_TYPE, VOUCHERTYPE_RUNTIME } from '../../../Library/Types';
import Button from '../../Button';
import VoucherInformation from './VoucherInformation';

/**
 * RuntimeVoucher()
 * @param props
 * @returns {*}
 * @constructor
 */
function RuntimeVoucher(props) {
  const {
    client, dispatchGet, dispatchRedeem, voucher, dispatchIsValid, onSuccess, onDeleted,
  } = props;
  const [fullCode, setFullCode] = React.useState();
  const [voucherValid, setVoucherValid] = React.useState(null);
  const [codeOne, setCodeOne] = React.useState();
  const [codeTwo, setCodeTwo] = React.useState();
  const [codeThree, setCodeThree] = React.useState();
  const [codeFour, setCodeFour] = React.useState();
  const refOne = useRef();
  const refTwo = useRef();
  const refThree = useRef();
  const refFour = useRef();

  React.useEffect(() => {
    dispatchGet(client.clientId).then(() => {}).catch(() => {});
  }, [dispatchGet, client.clientId]);

  React.useEffect(() => {
    if (codeOne && codeOne.length === 4) {
      refTwo.current.focus();
    }
  }, [codeOne]);

  React.useEffect(() => {
    if (codeTwo && codeTwo.length === 4) {
      refThree.current.focus();
    }
  }, [codeTwo]);

  React.useEffect(() => {
    if (codeThree && codeThree.length === 4) {
      refFour.current.focus();
    }
  }, [codeThree]);

  React.useEffect(() => {
    if (codeOne && codeTwo && codeThree && codeFour) {
      const code = `${codeOne}${codeTwo}${codeThree}${codeFour}`;
      setFullCode(code);

      if (code.length === 16) {
        dispatchIsValid(client.clientId, code).then((response) => {
          setVoucherValid(response);
        });
      } else {
        setVoucherValid(null);
      }
    } else {
      setFullCode(null);
      setVoucherValid(null);
    }
  }, [codeOne, codeTwo, codeThree, codeFour, client.clientId, dispatchIsValid]);

  const handleOnRedeem = () => {
    dispatchRedeem(client.clientId, fullCode).then(() => {
      onSuccess();
      dispatchGet(client.clientId, fullCode);
    });
  };

  if (client.type !== CLIENTTYPE_DEALER) {
    return null;
  }

  const renderHint = () => {
    if (voucherValid !== null) {
      if (!voucherValid.result) {
        if (voucherValid.data[0] === ERRORCODE_VOUCHER_WRONG_TYPE) {
          return (
            <div className="d-flex justify-content-center">
              <small className="text-info text-center">
                <i className="fas fa-exclamation-circle" style={{ marginRight: 4 }} />
                <br />
                Dieser Gutschein kann nur bei einem einzelnen Angebot verwendet werden.
                <br />
                Bitte verwenden Sie den Gutschein unter "Angebot einholen" am Ende des Formulars!
              </small>
            </div>
          );
        }
        return (
          <div className="d-flex justify-content-center">
            <small className="text-danger">
              <i className="fas fa-times-circle" style={{ marginRight: 4 }} />
              Dieser Gutscheincode ist ungültig!
            </small>
          </div>
        );
      }
    }
    return null;
  };

  if (voucher === null || voucher.length === 0) {
    return (
      <Panel>
        <h4>
          Gutscheine
        </h4>
        <p style={{ marginBottom: 40 }}>
          Sollten Sie einen Hey.Kitchen Gutschein erhalten haben, können Sie diesen
          hier einlösen und hinterlegen. Die Gutscheine haben eine bestimmte Laufzeit, die ab dem Tag der Einlösung
          aktiviert wird. Es kann immer nur jeweils ein Gutschein aktiv sein! Nach erfolgreicher Einlösung erhalten
          Sie automatisch den gewährten Rabatt auf alle Angebote die Sie in dem Zeitraum der Gutscheingültigkeit
          einholen.
        </p>

        <div className="d-flex flex-column align-items-center">
          <div style={{ maxWidth: 500 }}>
            <div className="row">
              <div className="col-sm-3">
                <Input
                  onChange={(value) => setCodeOne(value.toUpperCase())}
                  centerInput
                  maxLength={4}
                  value={codeOne}
                  inputRef={refOne}
                />
              </div>
              <div className="col-sm-3">
                <Input
                  onChange={(value) => setCodeTwo(value.toUpperCase())}
                  centerInput
                  maxLength={4}
                  value={codeTwo}
                  inputRef={refTwo}
                />
              </div>
              <div className="col-sm-3">
                <Input
                  onChange={(value) => setCodeThree(value.toUpperCase())}
                  centerInput
                  maxLength={4}
                  value={codeThree}
                  inputRef={refThree}
                />
              </div>
              <div className="col-sm-3">
                <Input
                  onChange={(value) => setCodeFour(value.toUpperCase())}
                  centerInput
                  maxLength={4}
                  value={codeFour}
                  inputRef={refFour}
                />
              </div>
            </div>

            {renderHint()}

            <div className="col-sm-12 d-flex justify-content-center" style={{ marginTop: 20 }}>
              <Button
                onClick={() => handleOnRedeem()}
                disabled={(voucherValid && !voucherValid.result) || (voucherValid === null)}
                type={(voucherValid && voucherValid.result) ? 'btn-success' : 'btn-light'}
              >
                Gutschein einlösen
              </Button>
            </div>
          </div>
        </div>
      </Panel>
    );
  }

  return <VoucherInformation onDeleted={onDeleted} />;
}

RuntimeVoucher.propTypes = {
  dispatchGet: PropTypes.func.isRequired,
  dispatchRedeem: PropTypes.func.isRequired,
  dispatchIsValid: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  voucher: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

RuntimeVoucher.defaultProps = {
  voucher: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    voucher: store.clientVoucher.voucher,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: (clientId) => dispatch(get(clientId)),
    dispatchRedeem: (clientId, code) => dispatch(redeem(clientId, code)),
    dispatchIsValid: (clientId, code) => dispatch(isValid(clientId, code, VOUCHERTYPE_RUNTIME)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(RuntimeVoucher);
