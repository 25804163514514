import React from 'react';
import PropTypes from 'prop-types';
import StatusBadge from '../StatusBadge';
import {
  RECLAMATIONSTATE_ASSIGNED,
  RECLAMATIONSTATE_CLOSED,
  RECLAMATIONSTATE_OPEN,
  RECLAMATIONSTATE_REPORT_FINISHED,
  RECLAMATIONSTATE_SCHEDULED,
  RECLAMATIONSTATE_WAITING_APPOINTMENT,
  RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT, reclamationStateCaption,
} from '../../Library/StateTypes';

/**
 * ReclamationStatusBadge()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ReclamationStatusBadge(props) {
  const {
    reclamation, containerStyle, visible, alignContainer, iconOnly, useMediaQuery,
  } = props;

  const getIcon = () => {
    switch (reclamation.state) {
      case RECLAMATIONSTATE_OPEN: return 'fas fa-folder-open';
      case RECLAMATIONSTATE_WAITING_APPOINTMENT: return 'far fa-calendar-alt';
      case RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT: return 'far fa-calendar-check';
      case RECLAMATIONSTATE_ASSIGNED: return 'fas fa-user-check';
      case RECLAMATIONSTATE_REPORT_FINISHED: return 'fas fa-check-circle';
      case RECLAMATIONSTATE_CLOSED: return 'fas fa-check-circle';
      case RECLAMATIONSTATE_SCHEDULED: return 'fas fa-calendar';
      default: return 'fas fa-times';
    }
  };

  const getCaption = () => ((!iconOnly) ? reclamationStateCaption[reclamation.state] : null);

  const getType = () => {
    switch (reclamation.state) {
      case RECLAMATIONSTATE_OPEN: return 'Danger';
      case RECLAMATIONSTATE_WAITING_APPOINTMENT: return 'Warning';
      case RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT: return 'Primary';
      case RECLAMATIONSTATE_ASSIGNED: return 'Primary';
      case RECLAMATIONSTATE_REPORT_FINISHED: return 'Success';
      case RECLAMATIONSTATE_CLOSED: return 'Success';
      case RECLAMATIONSTATE_SCHEDULED: return 'Warning';
      default: return 'Primary';
    }
  };

  return (
    <StatusBadge
      type={getType()}
      icon={getIcon()}
      visible={visible}
      useMediaQuery={useMediaQuery}
      containerStyle={containerStyle}
      iconOnly={iconOnly}
      alignContainer={alignContainer}
    >
      {getCaption()}
    </StatusBadge>
  );
}

ReclamationStatusBadge.propTypes = {
  reclamation: PropTypes.instanceOf(Object),
  containerStyle: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  alignContainer: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  iconOnly: PropTypes.bool,
  useMediaQuery: PropTypes.bool,
};

ReclamationStatusBadge.defaultProps = {
  reclamation: null,
  containerStyle: {},
  visible: true,
  alignContainer: 'flex-start',
  iconOnly: false,
  useMediaQuery: false,
};
