import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Panel from '../../../../Components/Panel';
import TextLink from '../../../../Components/TextLink';
import AddAppointmentDialog from './AddAppointmentDialog';
import {
  createAppointment, deleteAppointments, get, getAppointments,
} from '../../../../Redux/Action/ReclamationAction';
import AppointmentListView from './AppointmentListView';
import {
  RECLAMATIONSTATE_WAITING_APPOINTMENT,
  RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT,
} from '../../../../Library/StateTypes';

/**
 * AppointmentsDealer()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function AppointmentsDealer(props) {
  const {
    order, reclamation, client, dispatchCreateAppointment, dispatchGetAppointments, dispatchDeleteAppointment,
    dispatchGetReclamation,
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [appointments, setAppointments] = useState(null);

  useEffect(() => {
    dispatchGetAppointments(reclamation).then((data) => {
      setAppointments(data);
    });
  }, [dispatchGetAppointments, reclamation]);

  const handleOnAdd = (data) => {
    dispatchCreateAppointment(reclamation, data).then(() => {
      dispatchGetReclamation(order.orderId, reclamation.objectId, reclamation.reclamationId);
      dispatchGetAppointments(reclamation).then((apppointments) => {
        setAppointments(apppointments);
        setShowDialog(false);
      });
    });
  };

  const handleOnDelete = (appointment) => {
    dispatchDeleteAppointment(reclamation, appointment.appointmentId).then(() => {
      dispatchGetReclamation(order.orderId, reclamation.objectId, reclamation.reclamationId);
      dispatchGetAppointments(reclamation).then((data) => {
        setAppointments(data);
      });
    });
  };

  if (reclamation.state === RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT
    || reclamation.state === RECLAMATIONSTATE_WAITING_APPOINTMENT
  ) {
    if (!order.internal && order.clientId === client.clientId) {
      return (
        <Panel marginBottom={20}>
          <h6>Terminplanung</h6>
          <p>
            Bitte stimmen Sie
            <strong> mindestens 3 Termine </strong>
            mit dem Kunden ab und tragen Sie diese hier ein. Der Monteur wird dann
            einen für Ihn passenden Termin auswählen und bestätigen.
          </p>

          <AppointmentListView
            appointments={appointments}
            onDelete={handleOnDelete}
          />

          <TextLink
            caption="Termin hinzufügen"
            onPress={() => setShowDialog(true)}
            icon="fas fa-plus-square"
            style={{ marginTop: 20 }}
          />

          <AddAppointmentDialog
            onSave={handleOnAdd}
            onClose={() => setShowDialog(false)}
            visible={showDialog}
          />
        </Panel>
      );
    }
  }
  return null;
}

AppointmentsDealer.propTypes = {
  dispatchCreateAppointment: PropTypes.func.isRequired,
  dispatchGetAppointments: PropTypes.func.isRequired,
  dispatchDeleteAppointment: PropTypes.func.isRequired,
  dispatchGetReclamation: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  reclamation: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreateAppointment: (reclamation, data) => dispatch(createAppointment(reclamation, data)),
    dispatchGetAppointments: (reclamation) => dispatch(getAppointments(reclamation)),
    dispatchDeleteAppointment: (reclamation, id) => dispatch(deleteAppointments(reclamation, id)),
    dispatchGetReclamation: (orderId, objectId, reclamationId) => dispatch(
      get(orderId, objectId, reclamationId, true),
    ),
  };
}

export default connect(() => ({}), mapDispatchToProps)(AppointmentsDealer);
