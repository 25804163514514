import axios from '../../../../Library/Axios';

const defaultUri = '/system/material';

export const SYSTEM_MATERIAL_GETLIST_START = 'SYSTEM_MATERIAL_GETLIST_START';
export const SYSTEM_MATERIAL_GETLIST_SUCCESS = 'SYSTEM_MATERIAL_GETLIST_SUCCESS';
export const SYSTEM_MATERIAL_GETLIST_ERROR = 'SYSTEM_MATERIAL_GETLIST_ERROR';

/**
 * getList()
 * @returns {function(*): Promise<any | never>}
 */
export function getList() {
  return (dispatch) => {
    dispatch({ type: SYSTEM_MATERIAL_GETLIST_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_MATERIAL_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_MATERIAL_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_MATERIAL_ADD_START = 'SYSTEM_MATERIAL_ADD_START';
export const SYSTEM_MATERIAL_ADD_SUCCESS = 'SYSTEM_MATERIAL_ADD_SUCCESS';
export const SYSTEM_MATERIAL_ADD_ERROR = 'SYSTEM_MATERIAL_ADD_ERROR';

/**
 * create()
 * @returns {function(*): Promise<any | never>}
 */
export function create(material) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_MATERIAL_ADD_START, material });

    return axios().post(defaultUri, material).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_MATERIAL_ADD_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_MATERIAL_ADD_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_MATERIAL_DELETE_START = 'SYSTEM_MATERIAL_DELETE_START';
export const SYSTEM_MATERIAL_DELETE_SUCCESS = 'SYSTEM_MATERIAL_DELETE_SUCCESS';
export const SYSTEM_MATERIAL_DELETE_ERROR = 'SYSTEM_MATERIAL_DELETE_ERROR';

/**
 * reomve()
 * @param materialId
 * @returns {function(*): Promise<unknown>}
 */
export function remove(materialId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_MATERIAL_DELETE_START, materialId });

    const uri = `${defaultUri}/${materialId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_MATERIAL_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_MATERIAL_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
