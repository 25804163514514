import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import styled, { useTheme } from 'styled-components';
import { darken } from 'polished';
import {
  HOLIDAY_REASON_DISEASE,
  HOLIDAY_REASON_HOLIDAY,
  HOLIDAY_REASON_OTHER,
  holidayReasonCaptions,
} from '../../../../Library/Types';

export const Container = styled.div`
  ${(props) => props.theme.linearGradient.unavailable}
  width: ${(props) => props.theme.width}px;
  position: absolute;
  height: ${(props) => props.theme.height}px;
  left: ${(props) => props.theme.left}px;
  color: ${(props) => props.theme.fontColor || '#ffffff'};
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: ${() => darken(0.6, '#ffffff')};
  font-family: "DIN Next LT W01 Bold", sans-serif;
  font-size: 18px;
  
  > i {
    margin-right: 10px;
  }
`;

/**
 * UnavailablePlaceholder()
 * @returns {*}
 * @constructor
 */
export default function UnavailablePlaceholder(props) {
  const { appointment, cellWidth, rowHeight } = props;
  const [left, setLeft] = React.useState(null);
  const [width, setWidth] = React.useState(null);
  const theme = useTheme();

  React.useEffect(() => {
    if (appointment) {
      const { startHour } = theme;
      const borderWidth = theme.border.width;
      const pixelPerMinute = (cellWidth + borderWidth) / 60;

      if (appointment.start && appointment.end) {
        const calcLeft = moment(appointment.start, 'HH:mm')
          .diff(moment(startHour, 'HH:mm'), 'minute');
        setLeft((calcLeft * pixelPerMinute));

        const calcWidth = moment(appointment.end, 'HH:mm')
          .diff(moment(appointment.start, 'HH:mm'), 'minute');
        // Calc the amount of cells an add the border to the width of the appointment
        setWidth(calcWidth * pixelPerMinute);
      } else {
        setLeft(0);

        const calcWidth = moment(theme.endHour, 'HH:mm')
          .diff(moment(theme.startHour, 'HH:mm'), 'minute');
        setWidth(((calcWidth + 60) * pixelPerMinute));
      }
    }
  }, [appointment, theme, cellWidth]);

  const renderIcon = () => {
    switch (appointment.source.reason) {
      case HOLIDAY_REASON_HOLIDAY:
        return 'fas fa-umbrella-beach';
      case HOLIDAY_REASON_DISEASE:
        return 'fas fa-briefcase-medical';
      case HOLIDAY_REASON_OTHER:
        return 'fas fa-question-circle';
      default:
        return 'fas fa-umbrella-beach';
    }
  };

  if (left !== null && width !== null) {
    return (
      <Container
        theme={{
          ...theme,
          height: rowHeight,
          left,
          width,
          bgColor: appointment.bgColor,
          fontColor: appointment.fontColor,
        }}
        title="Nicht verfügbar"
      >
        <i className={renderIcon()} />
        {`Abwesend: ${holidayReasonCaptions[appointment.source.reason]}`}
      </Container>
    );
  }
  return null;
}

UnavailablePlaceholder.propTypes = {
  appointment: PropTypes.instanceOf(Object).isRequired,
  cellWidth: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
};
