import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import Panel from '../../Panel';
import MapMarker from '../../MapMarker';
import InfoBox from '../../InfoBox';
import { get } from '../../../Redux/Action/ClientAddressAction';
import { hasObjectAccess } from '../../../Library/Functions';

/**
 * DetailsMapView()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function DetailsMapView(props) {
  const {
    object, client, order, dispatchGet, address,
  } = props;
  const { customer } = object;
  const [distanceObj, setDistanceObj] = useState();
  const [durationObj, setDurationObj] = useState();
  const [mapKey, setMapKey] = useState(Math.random());

  useEffect(() => {
    if (!order.internal && object.assignedClientId && object.assignedClientAddressId) {
      dispatchGet(object.assignedClientId, object.assignedClientAddressId).then(() => {
        setTimeout(() => setMapKey(Math.random()), 200);
      });
    }
  }, [dispatchGet, order, object]);

  const getCustomerPosition = () => {
    if (customer.latitude && customer.longitude) {
      return { lat: parseFloat(customer.latitude), lng: parseFloat(customer.longitude) };
    }
    return null;
  };

  const getLoadingAddressPosition = () => {
    if (!order.internal && !object.assignedClientId && order.clientId !== client.clientId) {
      return null;
    }

    if (!order.optionDelivery && !order.optionStorage) {
      if (client.latitude && client.longitude) {
        return { lat: parseFloat(client.latitude), lng: parseFloat(client.longitude) };
      }
    }

    if (order.optionDelivery && !order.optionStorage) {
      const { loadingAddress } = order;
      if (loadingAddress.latitude && loadingAddress.longitude) {
        return { lat: parseFloat(loadingAddress.latitude), lng: parseFloat(loadingAddress.longitude) };
      }
    }
    if (order.optionStorage && address && !order.internal) {
      if (address.latitude && address.longitude) {
        return { lat: parseFloat(address.latitude), lng: parseFloat(address.longitude) };
      }
    }
    if (order.optionStorage && order.internal) {
      const { loadingAddress } = order;
      if (loadingAddress.latitude && loadingAddress.longitude) {
        return { lat: parseFloat(loadingAddress.latitude), lng: parseFloat(loadingAddress.longitude) };
      }
    }
    return null;
  };

  const setMap = (map, maps) => {
    if (getCustomerPosition() && getLoadingAddressPosition()) {
      const service = new maps.DistanceMatrixService();
      service.getDistanceMatrix({
        origins: [getCustomerPosition()],
        destinations: [getLoadingAddressPosition()],
        travelMode: 'DRIVING',
      }, (response, status) => {
        if (status === 'OK') {
          const { distance, duration } = response.rows[0].elements[0];
          setDistanceObj(distance);
          setDurationObj(duration);
        }
      });

      const directionsService = new maps.DirectionsService();
      const directionsDisplay = new maps.DirectionsRenderer();
      directionsService.route({
        origin: getCustomerPosition(),
        destination: getLoadingAddressPosition(),
        travelMode: 'DRIVING',
        optimizeWaypoints: true,
      }, (response, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(response);
          const routePolyline = new window.google.maps.Polyline({
            path: response.routes[0].overview_path,
            strokeColor: '#699ff3',
            strokeOpacity: 0.9,
            strokeWeight: 6,
          });
          routePolyline.setMap(map);
        }
      });
    }
  };

  const renderCustomerMarker = () => {
    if ((order.internal && hasObjectAccess(order, object, client))
      || (!order.internal && object.assignedClientId === client.clientId)
    ) {
      if (getCustomerPosition()) {
        return <MapMarker {...getCustomerPosition()} />;
      }
    }
    return null;
  };

  const renderDeliveryMarker = () => {
    if ((order.internal && hasObjectAccess(order, object, client))
      || (!order.internal && object.assignedClientId === client.clientId)
    ) {
      if (getLoadingAddressPosition()) {
        return <MapMarker {...getLoadingAddressPosition()} />;
      }
    }
    return null;
  };

  const renderMetadata = () => {
    if (distanceObj && durationObj) {
      return (
        <div className="InfoBoxContainer" style={{ marginBottom: 20 }}>
          <InfoBox icon="fas fa-directions" title="Entfernung" text={distanceObj.text} />
          <InfoBox icon="far fa-clock" title="Fahrzeit ca." text={durationObj.text} />
        </div>
      );
    }
    return null;
  };

  if (customer.latitude && customer.longitude) {
    return (
      <Panel marginBottom={20} containerStyle={{ display: 'flex' }}>
        {renderMetadata()}
        <div style={{ width: '100%', height: '450px' }}>
          <GoogleMapReact
            key={mapKey}
            bootstrapURLKeys={{ key: 'AIzaSyBgsVfRCdiXwfSxtNcKFgj_jdBT5l_7Q8Y' }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={getCustomerPosition()}
            defaultZoom={12}
            onGoogleApiLoaded={({ map, maps }) => setMap(map, maps)}
          >
            {renderCustomerMarker()}
            {renderDeliveryMarker()}
          </GoogleMapReact>
        </div>
      </Panel>
    );
  }
  return null;
}

DetailsMapView.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  dispatchGet: PropTypes.func.isRequired,
  address: PropTypes.instanceOf(Object),
};

DetailsMapView.defaultProps = {
  address: null,
};

function mapStoreToProps(store) {
  return {
    address: store.clientAddress.address,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: (clientId, addressId) => dispatch(get(clientId, addressId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(DetailsMapView);
