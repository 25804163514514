import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, useTheme } from 'styled-components';
import UserContent from './UserContent';
import Headline from './Headline';

const ContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  
  .MainContainer {
    display: flex;
    flex-direction: row;
  }
`;

/**
 * DayView()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function DayView(props) {
  const {
    data, selectedDate, onDrop, onClickAppointment, onChangeDate, onClickHour,
  } = props;
  const theme = useTheme();
  const [timelineWidth, setTimelineWidth] = React.useState(100);
  const [scrollContainerWidth, setScrollContainerWidth] = React.useState(2000);

  const updateWindowSize = React.useCallback(() => {
    setScrollContainerWidth(window.innerWidth - 450);
  }, []);

  React.useEffect(() => {
    updateWindowSize();
    window.addEventListener('resize', updateWindowSize);

    return () => window.removeEventListener('resize', updateWindowSize);
  }, [updateWindowSize]);

  React.useEffect(() => {
    const { startHour, endHour, cellWidth } = theme;

    const width = (endHour - startHour + 1) * cellWidth;
    setTimelineWidth(width);
  }, [theme]);

  return (
    <ThemeProvider
      theme={{ ...theme, timelineWidth, containerWidth: scrollContainerWidth }}
    >
      <ContainerCol>
        <Headline selectedDate={selectedDate} onChange={onChangeDate} />
        <div className="MainContainer">
          <UserContent
            data={data}
            selectedDate={selectedDate}
            onDrop={onDrop}
            onClickAppointment={onClickAppointment}
            onClickHour={(hour, dataParam) => onClickHour({ hour, date: selectedDate, data: dataParam })}
          />
        </div>
      </ContainerCol>
    </ThemeProvider>
  );
}

DayView.propTypes = {
  onDrop: PropTypes.func,
  data: PropTypes.instanceOf(Array).isRequired,
  selectedDate: PropTypes.string.isRequired,
  onClickAppointment: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onClickHour: PropTypes.func.isRequired,
};

DayView.defaultProps = {
  onDrop: null,
};
