import { isObjectEmpty } from '../../../../Library/Functions';
import customerFormHasError from './CustomerValidator';
import filesFormHasError from './FilesValidator';
import kitchenInformationFormHasError from './KitchenInformationValidator';

/**
 * Validate if the forms of the object container have errors
 * @param object
 * @param orderType
 * @param objects
 * @param internalMontageInfoMandatory
 * @param isInternal
 * @param isEdit
 * @returns {{}|boolean}
 */
export default function validateObjectContainerHasErrors(
  object, orderType, objects, internalMontageInfoMandatory, isInternal, isEdit = false,
) {
  const customerErrors = customerFormHasError(object.customer, isInternal);
  const kitchenInformationErrors = kitchenInformationFormHasError(
    object, orderType, objects, internalMontageInfoMandatory, isInternal,
  );

  let fileErrors = false;
  if (!isEdit) {
    fileErrors = filesFormHasError(object.documents);
  }

  let errorsObject = {};

  if (customerErrors !== false) {
    errorsObject = { ...errorsObject, customerErrors };
  }
  if (kitchenInformationErrors !== false) {
    errorsObject = { ...errorsObject, kitchenInformationErrors };
  }
  if (fileErrors !== false) {
    errorsObject = { ...errorsObject, fileErrors };
  }

  if (isObjectEmpty(errorsObject)) {
    return false;
  }

  return errorsObject;
}
