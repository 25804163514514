/**
 * This is the configuration file for environment depending settings for the project!
 * @type {{domain: string, apiGateway: {url: string}, env: string}}
 */

const dev = {
  env: 'dev',
  domain: 'http://localhost:3000',
  apiGateway: {
    url: 'http://localhost:8080',
    cookieDomain: 'localhost',
  },
  firebase: {
    config: {
      apiKey: 'AIzaSyAW1lpNzA6IOvRTxw2bruDkvzu-brzz3TU',
      authDomain: 'hey-kitchen-production.firebaseapp.com',
      databaseURL: 'https://hey-kitchen-production.firebaseio.com',
      projectId: 'hey-kitchen-production',
      storageBucket: 'hey-kitchen-production.appspot.com',
      messagingSenderId: '385631637012',
      appId: '1:385631637012:web:5088a0e7ddc83a380d2101',
      measurementId: 'G-N8J005N5HR',
    },
    firestore: {
      messages: 'develop_messages',
    },
  },
};

const staging = {
  env: 'staging',
  domain: 'https://staging.hey.kitchen',
  apiGateway: {
    url: 'https://stagingapi.hey.kitchen',
    cookieDomain: '.hey.kitchen',
  },
  firebase: {
    config: {
      apiKey: 'AIzaSyAW1lpNzA6IOvRTxw2bruDkvzu-brzz3TU',
      authDomain: 'hey-kitchen-production.firebaseapp.com',
      databaseURL: 'https://hey-kitchen-production.firebaseio.com',
      projectId: 'hey-kitchen-production',
      storageBucket: 'hey-kitchen-production.appspot.com',
      messagingSenderId: '385631637012',
      appId: '1:385631637012:web:5088a0e7ddc83a380d2101',
      measurementId: 'G-N8J005N5HR',
    },
    firestore: {
      messages: 'staging_messages',
    },
  },
};

const demo = {
  env: 'staging',
  domain: 'https://demo.hey.kitchen',
  apiGateway: {
    url: 'https://demoapi.hey.kitchen',
    cookieDomain: '.hey.kitchen',
  },
  firebase: {
    config: {
      apiKey: 'AIzaSyAW1lpNzA6IOvRTxw2bruDkvzu-brzz3TU',
      authDomain: 'hey-kitchen-production.firebaseapp.com',
      databaseURL: 'https://hey-kitchen-production.firebaseio.com',
      projectId: 'hey-kitchen-production',
      storageBucket: 'hey-kitchen-production.appspot.com',
      messagingSenderId: '385631637012',
      appId: '1:385631637012:web:5088a0e7ddc83a380d2101',
      measurementId: 'G-N8J005N5HR',
    },
    firestore: {
      messages: 'staging_messages',
    },
  },
};

const production = {
  env: 'production',
  domain: 'https://www.hey.kitchen',
  apiGateway: {
    url: 'https://api.hey.kitchen',
    cookieDomain: '.hey.kitchen',
  },
  firebase: {
    config: {
      apiKey: 'AIzaSyAW1lpNzA6IOvRTxw2bruDkvzu-brzz3TU',
      authDomain: 'hey-kitchen-production.firebaseapp.com',
      databaseURL: 'https://hey-kitchen-production.firebaseio.com',
      projectId: 'hey-kitchen-production',
      storageBucket: 'hey-kitchen-production.appspot.com',
      messagingSenderId: '385631637012',
      appId: '1:385631637012:web:5088a0e7ddc83a380d2101',
      measurementId: 'G-N8J005N5HR',
    },
    firestore: {
      messages: 'messages',
    },
  },
};

let config = production;
if (process.env.REACT_APP_STAGE === 'dev') {
  config = dev;
} else if (process.env.REACT_APP_STAGE === 'staging') {
  config = staging;
} else if (process.env.REACT_APP_STAGE === 'demo') {
  config = demo;
}

export default { ...config };
