import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import * as Redux from 'react-redux';
import Panel from '../../../Components/Panel';
import Input from '../../../Components/Input';
import ServiceList from './ServicePage/ServiceList';
import ServiceStateFilter from './ServicePage/ServiceListFilter/ServiceStateFilter';
import { updateFilters, useDispatchApplyServiceFilters } from '../Redux/Action/ServiceAction';

/**
 * ServicePage(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ServicePage(props) {
  const {
    variant, getHeadline, dispatchGetObjects, onClick,
  } = props;

  const services = Redux.useSelector((store) => (store.systemService[variant].services));
  const lastUpdated = Redux.useSelector((store) => (store.systemService[variant].lastUpdated));
  const isLoading = Redux.useSelector((store) => (store.systemService[variant].isLoading));
  const filters = Redux.useSelector((store) => (store.systemService[variant].filters));
  const filteredServices = Redux.useSelector((store) => (store.systemService[variant].filteredServices));

  const prevFilteredServices = React.useRef(null);
  const dispatch = Redux.useDispatch();
  const dispatchUpdateFilters = (newFilter) => dispatch(updateFilters(variant, newFilter));
  const dispatchApplyFilters = useDispatchApplyServiceFilters();

  const [searchBuffer, setSearchBuffer] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [all, setAll] = React.useState(null);
  const [today, setToday] = React.useState(null);
  const [yesterday, setYesterday] = React.useState(null);

  const loadObjects = React.useCallback(() => {
    dispatchGetObjects();
  }, [dispatchGetObjects]);

  const updateObjects = React.useCallback(() => {
    const now = new Date().getTime();
    const deltaMilliseconds = now - lastUpdated;

    if (deltaMilliseconds > 300000 && !isLoading) {
      loadObjects();
    }
  }, [lastUpdated, loadObjects, isLoading]);

  React.useEffect(() => {
    prevFilteredServices.current = filteredServices;
  }, [filteredServices]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(searchBuffer);
    }, 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [searchBuffer]);

  React.useEffect(() => {
    updateObjects();
  }, [updateObjects]);

  const dayFilter = (service, day) => moment(service.executionDate).isSame(day, 'day');

  const searchFilter = React.useCallback((service) => {
    if (!searchValue) return true;

    if (service.serviceId && service.serviceId.toString().includes(searchValue)) return true;
    if (service.objectExternalId && service.objectExternalId.includes(searchValue)) return true;
    return !!(service.ownerName && service.ownerName.includes(searchValue));
  }, [searchValue]);

  React.useEffect(() => {
    if (filteredServices && filteredServices.length > 0) {
      const allData = filteredServices.filter((service) => searchFilter(service));
      setAll(allData);

      const todayDate = moment(new Date());
      const todayData = allData.filter((service) => dayFilter(service, todayDate));
      setToday(todayData);

      const yesterdayDate = todayDate.subtract(1, 'day');
      const yesterdayData = allData.filter((service) => dayFilter(service, yesterdayDate));
      setYesterday(yesterdayData);
    }
  }, [filteredServices, setToday, setYesterday, searchFilter]);

  React.useEffect(() => {
    if (services && filters) {
      dispatchApplyFilters(variant, services, filters, prevFilteredServices);
    }
  }, [services, filters, prevFilteredServices, dispatchApplyFilters, variant]);

  const renderHeadline = () => (
    <h5 style={{ margin: 0 }}>
      {getHeadline()}
    </h5>
  );

  const renderSubHeadline = () => {
    if (all) {
      return (
        <p style={{ margin: 0 }}>
          {`In den letzten 3 Wochen: ${(all.length > 0) ? all.length : 'Keine'} Services`}

          {searchValue ? ` für "${searchValue}"` : ''}
        </p>
      );
    }
    return null;
  };

  const renderSearch = () => (
    <div>
      <Input
        placeholder="Suchen..."
        style={{ margin: 0 }}
        rightIcon={`fas ${(isLoading) ? 'fa-spin fa-spinner' : 'fa-search'}`}
        value={searchBuffer}
        onChange={setSearchBuffer}
      />
    </div>
  );

  const renderFilter = () => {
    if (services && services.length > 0) {
      return (
        <Panel marginBottom={25}>
          <div className="FilterCardContainer">
            <div className="Caption">
              Filter
            </div>
            <ServiceStateFilter
              services={services}
              onFilterChange={(payload) => {
                dispatchUpdateFilters(payload);
              }}
            />
          </div>
        </Panel>
      );
    }
    return null;
  };

  const renderTodayObjects = () => {
    if (today && today.length > 0) {
      return (
        <Panel marginBottom={25}>
          <h6 className="hk-text-primary">
            Services heute
          </h6>
          <hr />
          <ServiceList services={today} variant={variant} onClick={onClick} />
        </Panel>
      );
    }
    return null;
  };

  const renderYesterdayObjects = () => {
    if (yesterday && yesterday.length > 0) {
      return (
        <Panel marginBottom={25}>
          <h6 className="hk-text-primary">
            Services gestern
          </h6>
          <hr />
          <ServiceList services={yesterday} variant={variant} onClick={onClick} />
        </Panel>
      );
    }
    return null;
  };

  const renderAllObjects = () => {
    if (all && all.length > 0) {
      return (
        <Panel marginBottom={25}>
          <ServiceList services={all} variant={variant} onClick={onClick} />
        </Panel>
      );
    }
    return null;
  };

  const renderBody = () => {
    if (services && services.length > 0) {
      return (
        <>
          {renderTodayObjects()}
          {renderYesterdayObjects()}
          {renderAllObjects()}
        </>
      );
    }

    if (isLoading) {
      return (
        <Panel marginBottom={25}>
          <center>
            <i className="fas fa-spinner fa-spin fa-5x" />
          </center>
        </Panel>
      );
    }

    return null;
  };

  return (
    <div>
      <Panel boxPadding={10} marginBottom={25}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            {renderHeadline()}
            {renderSubHeadline()}
          </div>
          {renderSearch()}
        </div>
      </Panel>
      {renderFilter()}
      {renderBody()}
    </div>
  );
}

ServicePage.propTypes = {
  variant: PropTypes.string.isRequired,
  getHeadline: PropTypes.func.isRequired,
  dispatchGetObjects: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

ServicePage.defaultProps = {
  onClick: () => {},
};
