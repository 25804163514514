import React from 'react';
import PropTypes from 'prop-types';
import { reclamationStateCaption } from '../../../../../Library/StateTypes';
import ObjectArrayFilter from '../../Filter/ObjectArrayFilter';

/**
 * ReclamationStateFilter(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReclamationStateFilter(props) {
  const {
    reclamations, onFilterChange,
  } = props;

  const filter = 'state';
  const getFilterParameter = (reclamation) => reclamation.state;
  const getCaption = (option, count) => `${reclamationStateCaption[Number(option)]} (${count})`;

  if (reclamations && reclamations.length > 0) {
    return (
      <ObjectArrayFilter
        objectsArray={reclamations}
        getFilterParameter={getFilterParameter}
        getCaption={getCaption}
        filter={filter}
        onFilterChange={onFilterChange}
      />
    );
  }
  return null;
}

ReclamationStateFilter.propTypes = {
  reclamations: PropTypes.instanceOf(Array).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
