import {
  SYSTEM_USER_GETLIST_START, SYSTEM_USER_GETLIST_ERROR, SYSTEM_USER_GETLIST_SUCCESS,
} from '../Action/UserAction';

const defaultValues = {
  users: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case SYSTEM_USER_GETLIST_START: {
      return { ...state };
    }
    case SYSTEM_USER_GETLIST_SUCCESS: {
      return { ...state, users: action.payload };
    }
    case SYSTEM_USER_GETLIST_ERROR: {
      return { ...state, users: null };
    }

    default: return { ...state };
  }
}
