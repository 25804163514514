import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import {
  formatCurrency,
  formatDate,
} from '../../Library/Functions';
import InfoBox from '../InfoBox';
import OrderOptionIcons from './OrderOptionIcons';
import { CLIENTTYPE_MONTEUR, ORDERTYPE_OBJECT } from '../../Library/Types';
import { ORDERSTATE_WAITING_CALCULATION } from '../../Library/StateTypes';

/**
 * OrderInformationPanel()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function OrderInformationPanel(props) {
  const { order, client } = props;

  const getFormattedDate = () => {
    const start = formatDate(order.deliveryDateStart, 'dd. DD.MM');
    const end = formatDate(order.deliveryDateEnd, 'dd. DD.MM.YYYY');
    return `${start} - ${end}`;
  };

  const renderPrice = () => {
    if (client) {
      if (order
        && !order.internal
        && order.type === ORDERTYPE_OBJECT
        && order.state !== ORDERSTATE_WAITING_CALCULATION
      ) {
        let price = formatCurrency(order.dealerPrice);
        if (client.type === CLIENTTYPE_MONTEUR) {
          price = formatCurrency(order.mechanicPrice);
        }
        return <InfoBox icon="fas fa-euro-sign" title="Preis" text={price} />;
      }
    }

    return null;
  };

  if (order) {
    return (
      <Panel marginBottom={20} className="ProjectInformation">
        <h6>Auftragsinformationen</h6>
        <p>
          Hier sehen Sie die Informationen zum Auftrag
        </p>

        <div className="InfoBoxContainer">
          <InfoBox
            icon="fas fa-check-circle"
            title="Auftragsoptionen"
            text={<OrderOptionIcons order={order} withMarginRight />}
          />
          <InfoBox
            icon="fas fa-check-circle"
            title="Objektanzahl"
            text={order.objects.length}
          />
          <InfoBox
            icon="far fa-calendar"
            title="Montagedatum"
            text={getFormattedDate()}
          />
          {renderPrice()}
        </div>
      </Panel>
    );
  }
  return null;
}

OrderInformationPanel.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object),
};

OrderInformationPanel.defaultProps = {
  client: null,
};
