import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import * as Redux from 'react-redux';
import Panel from '../Panel';
import {
  approve, create, deleteMessage, getList,
} from '../../Redux/Action/ObjectMessagesAction';
import MessageView from '../MessageView';
import { get } from '../../Redux/Action/OrderAction';
import { ORDERTYPE_SINGLE } from '../../Library/Types';
import CreateTaskModal from '../Tasks/CreateTaskModal';
import { hasObjectAccess } from '../../Library/Functions';

/**
 * Messages()
 * @returns {*}
 * @constructor
 */
function Messages(props) {
  const {
    messages, dispatchGetList, order, dispatchGetOrder, dispatchCreate, dispatchDelete, isAdmin, user,
    dispatchApprove, history,
  } = props;
  const [object, setObject] = React.useState();
  const [selectedMessage, setSelectedMessage] = React.useState(null);
  const [showAddTask, setShowAddTask] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const { orderId, objectId } = useParams();

  const { client } = Redux.useSelector((state) => state.auth);

  React.useEffect(() => {
    if (order && order.orderId && object && object.objectId && client && client.clientId) {
      if (hasObjectAccess(order, object, client) || isAdmin) {
        setVisible(true);
      }
    }
  }, [order, object, client, history, isAdmin]);

  React.useEffect(() => {
    dispatchGetOrder(orderId);
  }, [dispatchGetOrder, orderId]);

  React.useEffect(() => {
    if (object) {
      dispatchGetList(object);
    }
  }, [object, dispatchGetList]);

  React.useEffect(() => {
    if (order) {
      if (order.type === ORDERTYPE_SINGLE && !objectId) {
        setObject(order.objects[0]);
      } else {
        const data = order.objects.filter((item) => item.objectId === parseInt(objectId, 0))[0];
        setObject(data);
      }
    }
  }, [order, objectId]);

  const handleCreate = (data) => {
    if (object) {
      dispatchCreate(object, data.message).then(() => {
        dispatchGetList(object);
      });
    }
  };

  const handleDelete = (message) => {
    if (object) {
      dispatchDelete(message).then(() => {
        dispatchGetList(object);
      });
    }
  };

  const handleApprove = (message) => {
    dispatchApprove(message).then(() => {
      if (object) {
        dispatchGetList(object);
      }
    });
  };

  const handleShowAddTask = (data) => {
    setSelectedMessage(data);
    setShowAddTask(true);
  };

  const handleCloseAddTask = () => {
    setSelectedMessage(null);
    setShowAddTask(false);
  };

  if (order && visible) {
    return (
      <div>
        <Panel marginBottom={25}>
          <h4 className="hk-text-primary">Mitteilungen</h4>
          <p style={{ marginBottom: 0 }}>
            Bei Fragen zum Auftrag können Sie bequem und einfach über die Mitteilungen mit Ihrem Monteur kommunizieren.
            Die Mitteilungen werden sofort in der App oder auf der Webseite angezeigt. Mit den Mitteilungen haben Sie
            ebenfalls jederzeit den Kommunikationsverlauf zum Auftrag gespeichert.
          </p>
        </Panel>

        <MessageView
          messages={messages}
          order={order}
          onSend={handleCreate}
          object={object}
          onDelete={handleDelete}
          isAdmin={isAdmin}
          onApprove={handleApprove}
          user={user}
          onAddTask={handleShowAddTask}
        />

        <CreateTaskModal
          visible={showAddTask}
          onClose={handleCloseAddTask}
          object={{ ...object }}
          initialTitle={`Mitteilung beachten zu Kommission "${(object) ? object.externalId : null}"`}
          initialDescription={(selectedMessage) ? selectedMessage.message : ''}
        />
      </div>
    );
  }
  return null;
}

Messages.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchApprove: PropTypes.func.isRequired,
  dispatchCreate: PropTypes.func.isRequired,
  dispatchDelete: PropTypes.func.isRequired,
  messages: PropTypes.instanceOf(Array),
  order: PropTypes.instanceOf(Object),
  isAdmin: PropTypes.bool,
  user: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

Messages.defaultProps = {
  messages: null,
  order: null,
  isAdmin: false,
};

function mapStoreToProps(store) {
  return {
    messages: store.objectMessage.messages,
    order: store.order.order,
    user: store.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: (object) => dispatch(getList(object)),
    dispatchCreate: (object, message) => dispatch(create(object, message)),
    dispatchApprove: (message) => dispatch(approve(message)),
    dispatchGetOrder: (orderId) => dispatch(get(orderId)),
    dispatchDelete: (message) => dispatch(deleteMessage(message)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Messages));
