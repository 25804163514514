import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ModalDialog from '../../../ModalDialog';
import Input from '../../../Input';
import Textarea from '../../../Textarea';
import DatePickerInput from '../../../DatePickerInput';
import CustomReactSelect from '../../../CustomReactSelect';
import {
  DISPOSITION_TYPE_MANUAL,
  ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE,
  recurringDayOptions,
  timeOptions,
  weekIntervalTimeOptions,
} from '../../../../Library/Types';
import { useDispatchUserGetAll } from '../../../../Redux/Action/UserAction';
import {
  useDispatchDispositionCreate,
  useDispatchDispositionDelete,
  useDispatchDispositionUpdate,
} from '../../../../Redux/Action/DispositionAction';
import { useDispatchTeamsGetList } from '../../../../Redux/Action/TeamsAction';
import DeleteButton from './DeleteButton';
import CustomSwitch from '../../../CustomSwitch';
import ShowHideContainer from '../../../ShowHideContainer';

const initialState = {
  dateId: null,
  title: null,
  isRecurring: false,
  type: DISPOSITION_TYPE_MANUAL,
  description: '',
  date: null,
  endDate: null,
  startTime: null,
  endTime: null,
  weekInterval: 1,
  weekDays: [],
  userId: null,
  teamId: null,
  bgColor: 'blue',
  errors: {},
};

const bgColorOptions = [
  {
    label: (<i className="fas fa-circle" style={{ color: '#0040FF' }} />),
    value: 'blue',
  },
  {
    label: (<i className="fas fa-circle" style={{ color: '#04B431' }} />),
    value: '#0B6121',
  },
  {
    label: (<i className="fas fa-circle" style={{ color: '#AC58FA' }} />),
    value: '#4B088A',
  },
  {
    label: (<i className="fas fa-circle" style={{ color: '#6E6E6E' }} />),
    value: '#2E2E2E',
  },
  {
    label: (<i className="fas fa-circle" style={{ color: '#DF7401' }} />),
    value: '#8A4B08',
  },
];

function reducer(state, action) {
  switch (action.type) {
    case 'title': return { ...state, title: action.payload, errors: { ...state.errors, title: false } };
    case 'description': return { ...state, description: action.payload };
    case 'isRecurring': return { ...state, isRecurring: action.payload };
    case 'date': return { ...state, date: action.payload, errors: { ...state.errors, date: false } };
    case 'endDate': return { ...state, endDate: action.payload, errors: { ...state.errors, endDate: false } };
    case 'startTime': return { ...state, startTime: action.payload, errors: { ...state.errors, startTime: false } };
    case 'weekDays': return { ...state, weekDays: action.payload, errors: { ...state.errors, weekDays: false } };
    case 'endTime': return { ...state, endTime: action.payload, errors: { ...state.errors, endTime: false } };
    case 'weekInterval': return { ...state, weekInterval: action.payload };
    case 'userId': return {
      ...state, userId: action.payload, teamId: null, errors: { ...state.errors, userId: false, teamId: false },
    };
    case 'teamId': return {
      ...state, teamId: action.payload, userId: null, errors: { ...state.errors, teamId: false, userId: false },
    };
    case 'bgColor': return { ...state, bgColor: action.payload };

    case 'errors': return { ...state, errors: action.payload };
    case 'init': return { ...initialState, ...action.payload, errors: {} };
    case 'clear': return { ...initialState };

    default:
      throw new Error();
  }
}

/**
 * ManualDispositionModal()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ManualDispositionModal(props) {
  const {
    onClose, visible, onCreated, initialData, onDeleted,
  } = props;
  const dispatchGetUsers = useDispatchUserGetAll();
  const dispatchGetTeams = useDispatchTeamsGetList();
  const dispatchCreate = useDispatchDispositionCreate();
  const dispatchUpdate = useDispatchDispositionUpdate();
  const dispatchDelete = useDispatchDispositionDelete();
  const { monteureOnly } = useSelector((state) => state.user);
  const { teams } = useSelector((state) => state.teams);

  const [state, dispatch] = React.useReducer(reducer, initialState, (stateParam) => stateParam);
  const [userOptions, setUserOptions] = React.useState(null);
  const [teamOptions, setTeamOptions] = React.useState(null);
  const [conflicted, setConflicted] = React.useState(false);

  React.useEffect(() => {
    dispatchGetUsers().then(() => {});
    dispatchGetTeams().then(() => {});
  }, [dispatchGetUsers, dispatchGetTeams]);

  React.useEffect(() => {
    if (initialData) {
      dispatch({ type: 'init', payload: initialData });
    }
  }, [initialData]);

  React.useEffect(() => {
    if (!visible) {
      setConflicted(false);
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  React.useEffect(() => {
    if (monteureOnly) {
      const options = monteureOnly.map((item) => (
        { label: `${item.lastname}, ${item.firstname}`, value: item.userId }
      ));
      options.unshift({ label: (<i>Keine Auswahl</i>), value: null });
      setUserOptions(options);
    }
  }, [monteureOnly]);

  React.useEffect(() => {
    if (teams) {
      const options = teams.map((item) => (
        { label: item.name, value: item.teamId }
      ));
      options.unshift({ label: (<i>Keine Auswahl</i>), value: null });
      setTeamOptions(options);
    }
  }, [teams]);

  const isValid = () => {
    setConflicted(false);
    let errors = {};

    if (!state.title || state.title.length <= 0) {
      errors = { ...errors, title: true };
    }
    if (!state.date || state.date.length <= 0) {
      errors = { ...errors, date: true };
    }
    if (!state.startTime || state.startTime.length <= 0) {
      errors = { ...errors, startTime: true };
    }
    if (!state.endTime || state.endTime.length <= 0) {
      errors = { ...errors, endTime: true };
    }

    if (!state.userId && !state.teamId) {
      errors = { ...errors, userId: true, teamId: true };
    }

    if (state.startTime && state.endTime) {
      if (moment(state.startTime, 'HH:mm').isSameOrAfter(moment(state.endTime, 'HH:mm'), 'minute')) {
        errors = { ...errors, endTime: true, startTime: true };
      }
    }

    if (Object.keys(errors).length > 0) {
      dispatch({ type: 'errors', payload: errors });
      return false;
    }
    return true;
  };

  const handleOnSave = () => {
    if (isValid()) {
      if (!state.dispositionId) {
        dispatchCreate(state, state.isRecurring).then((item) => {
          if (item.result) {
            dispatch({ type: 'clear' });
            onCreated(item.data);
          }
        }).catch((error) => {
          setConflicted((error.data.errorCode === ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE));
        });
      } else {
        dispatchUpdate(state).then((item) => {
          if (item.result) {
            dispatch({ type: 'clear' });
            onCreated(item);
          }
        }).catch((error) => {
          setConflicted((error.data.errorCode === ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE));
        });
      }
    }
  };

  const handleDelete = () => {
    dispatchDelete(state.dispositionId).then(() => {
      onDeleted(state.dispositionId);
    });
  };

  const renderConflictError = () => {
    if (conflicted) {
      return (
        <div className="alert alert-danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          Der Eintrag kollidiert mt einem bereits vorhandenen Eintrag!
        </div>
      );
    }
    return null;
  };

  const renderContent = () => (
    <div className="row">
      <Input
        onChange={(payload) => dispatch({ type: 'title', payload })}
        value={state.title}
        label="Titel"
        rowClass="col-lg-12"
        autoFocus
        hasError={!!state.errors.title}
      />
      <Textarea
        onChange={(payload) => dispatch({ type: 'description', payload })}
        value={state.description}
        placeholder="Weitere Informationen..."
        rowClass="col-lg-12"
        rows={4}
      />
      <ShowHideContainer visible={!state.dispositionId}>
        <CustomSwitch
          caption="Serientermin"
          checked={state.isRecurring}
          onChange={(payload) => dispatch({ type: 'isRecurring', payload })}
          containerStyle={{ marginLeft: 15, marginBottom: 10, paddingRight: 5000 }}
        />
      </ShowHideContainer>
      <DatePickerInput
        onChange={(payload) => dispatch({ type: 'date', payload })}
        initialValue={state.date}
        label={(state.isRecurring) ? 'Startdatum' : 'Datum'}
        rowClass={(state.isRecurring) ? 'col-lg-6' : 'col-lg-12'}
        minDate={moment().toDate()}
        hasError={!!state.errors.date}
      />
      <ShowHideContainer visible={state.isRecurring}>
        <DatePickerInput
          onChange={(payload) => dispatch({ type: 'endDate', payload })}
          initialValue={state.endDate}
          label="Enddatum"
          rowClass="col-lg-6"
          minDate={moment().toDate()}
          hasError={!!state.errors.endDate}
        />
        <CustomReactSelect
          options={weekIntervalTimeOptions}
          onChange={(payload) => dispatch({ type: 'weekInterval', payload })}
          value={state.weekInterval}
          rowClass="col-lg-6"
          label="Wiederholungsinterval"
          hasError={!!state.errors.weekInterval}
        />
        <CustomReactSelect
          options={recurringDayOptions}
          onChange={(payload) => dispatch({ type: 'weekDays', payload })}
          value={state.weekDays}
          rowClass="col-lg-6"
          label="Wochentage"
          hasError={!!state.errors.weekDays}
          isMulti
        />
      </ShowHideContainer>
      <CustomReactSelect
        options={timeOptions}
        onChange={(payload) => dispatch({ type: 'startTime', payload })}
        value={state.startTime}
        rowClass="col-lg-6"
        label="Startzeit"
        hasError={!!state.errors.startTime}
      />
      <CustomReactSelect
        options={timeOptions}
        onChange={(payload) => dispatch({ type: 'endTime', payload })}
        value={state.endTime}
        rowClass="col-lg-6"
        label="Endzeit"
        hasError={!!state.errors.endTime}
      />
      <CustomReactSelect
        options={userOptions || []}
        onChange={(payload) => dispatch({ type: 'userId', payload })}
        value={state.userId}
        rowClass="col-lg-5"
        label="Monteur wählen"
        hasError={!!state.errors.userId}
      />
      <CustomReactSelect
        options={teamOptions || []}
        onChange={(payload) => dispatch({ type: 'teamId', payload })}
        value={state.teamId}
        rowClass="col-lg-5"
        label="Team wählen"
        hasError={!!state.errors.teamId}
      />
      <CustomReactSelect
        options={bgColorOptions || []}
        onChange={(payload) => dispatch({ type: 'bgColor', payload })}
        value={state.bgColor}
        rowClass="col-lg-2"
        label="Farbe"
        hasError={!!state.errors.teamId}
      />
    </div>
  );

  return (
    <ModalDialog
      onClose={onClose}
      visible={visible}
      onConfirm={handleOnSave}
      leftButtonContent={<DeleteButton onClick={handleDelete} disposition={state} />}
    >
      <h4>Dispositionseintrag erstellen</h4>
      <p>
        Erstellen Sie einen manuellen Eintrag in der Disposition. Der manuell erstellt Eintrag wird nicht bei
        den Monteuren in der Montageapp angezeigt. Dieser dient ausschließlich der Planung innerhalb der Disposition.
        Manuelle Einträge werden bei der Überprüfung der Zuweisung von Monteuren oder Teams berücksichtigt.
      </p>
      <hr />

      {renderConflictError()}
      {renderContent()}
    </ModalDialog>
  );
}

ManualDispositionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onCreated: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  initialData: PropTypes.instanceOf(Object),
};

ManualDispositionModal.defaultProps = {
  visible: false,
  initialData: null,
};
