import React from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import Button from '../../../../Components/Button';

import './style.css';

/**
 * WebdemoRow()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function WebdemoRow(props) {
  const { style } = props;

  return (
    <div className="WebdemoRow FullPageBox Dark" style={{ padding: '20px 0', ...style }}>
      <div className="container d-flex">
        <div className="flex-grow-1">
          <div className="Bold">Kostenlose und unverbindliche Onlinedemo in nur 15 Minuten</div>
          <div>In der Onlinedemo stellen wir Ihnen unser System vor und gehen auf Ihre individuellen Fragen ein</div>
        </div>
        <div className="Button d-flex justify-content-center align-items-center align-content-center ">
          <HashLink to="#webdemo">
            <Button onClick={() => {}}>
              Onlinedemo anfragen
            </Button>
          </HashLink>
        </div>
      </div>
    </div>
  );
}

WebdemoRow.propTypes = {
  style: PropTypes.instanceOf(Object),
};

WebdemoRow.defaultProps = {
  style: {},
};
