import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';
import { FACILITY_GET_LIST_ERROR, FACILITY_GET_LIST_START, FACILITY_GET_LIST_SUCCESS } from '../Action/FacilityAction';

const defaultValues = {
  facilities: [],
  isLoading: false,
  lastSelectedStartDate: null,
  lastSelectedEndDate: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /** GET LIST */
    case FACILITY_GET_LIST_START: {
      return { ...state, isLoading: true };
    }
    case FACILITY_GET_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        facilities: action.payload,
      };
    }
    case FACILITY_GET_LIST_ERROR: {
      return {
        ...state, isLoading: false, facilities: [],
      };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
