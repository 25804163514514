import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import KeyValueEditRow from '../../KeyValueEditRow';
import { formatCurrency, formatDate } from '../../../Library/Functions';
import CounterTopIcon from '../../CounterTopIcon';
import Panel from '../../Panel';
import { get, singleUpdate as singleUpdateOrder, update } from '../../../Redux/Action/OrderAction';
import { getDisplayString } from '../../WeekPickerInput';
import {
  singleUpdate as singleUpdateObject,
  updateValues,
} from '../../../Redux/Action/ObjectAction';
import {
  countertopOptions, OBJECT_DATE_TYPE_ALL,
  YesNoOption,
} from '../../../Library/Types';
import UpdateWorkOptions from '../UpdateWorkOptions';
import OrderErrorAlert from '../OrderErrorAlert';
import { ORDERSTATE_FINISHED } from '../../../Library/StateTypes';
import { useAccessCanScheduleObjects, useAccessIsObjectEditable } from '../../../Library/AccessChecks';
import SelectOrderOptions from '../../Order/Edit/SelectOrderOptions';
import ModalDialog from '../../ModalDialog';

const exhaustHoodString = 'Ja (Inselhaube, flächenbündige Deckenhaube oder Arbeitsplattenhaube)';

/**
 * ObjectInformation()
 * @param props
 * @returns {*}
 * @constructor
 */
function ObjectInformation(props) {
  const {
    order, object, dispatchUpdateOrder, dispatchUpdateObject, dispatchGetOrder, clientId, onChanged,
    dispatchUpdateObjectValues, onUpdateDate, isSystemView, dispatchSingleUpdateOrder,
  } = props;

  const [showEditWorkOptions, setShowEditWorkOptions] = React.useState(false);
  const [showEditOrderOptions, setShowEditOrderOptions] = React.useState(false);
  const [editOrderOptionObject, setEditOrderOptionObject] = React.useState(null);
  const [lastError, setLastError] = React.useState(null);
  const [allowChangeExternalId, setAllowChangeExternalId] = React.useState(false);

  const isEditable = useAccessIsObjectEditable(object) || isSystemView;
  const isTerminable = useAccessCanScheduleObjects();
  const canEditBalance = ((!order.mandantId && order.clientId === clientId)
    || order.mandantId === clientId
    || isSystemView);

  React.useEffect(() => {
    if (order) {
      const allow = (order.state !== ORDERSTATE_FINISHED
        && order.clientId === clientId
      );
      setAllowChangeExternalId(allow);
    }
  }, [order, clientId]);

  React.useEffect(() => {
    setEditOrderOptionObject({
      optionRemoval: order.optionRemoval,
      optionDelivery: order.optionDelivery,
      optionMontage: order.optionMontage,
      optionStorage: order.optionStorage,
      removalMeter: order.removalMeter,
    });
  }, [order]);

  const updateMontageDate = (montageDate, key) => {
    onUpdateDate(montageDate, key, OBJECT_DATE_TYPE_ALL);
  };

  const updateBalance = (newBalance) => {
    /** valid number : * digits followed by optional , or . followed by 2 digits */
    const regExpress = newBalance.match(/^\d*(?:[,.]\d{2})?$/);
    if (!regExpress) {
      return;
    }
    if (newBalance) {
      if (parseFloat(newBalance) <= object.price) {
        dispatchUpdateObject(object, 'balance', newBalance.replace(',', '.')).then(() => {
          dispatchGetOrder(order.orderId);
        });
      }
    }
  };

  const updateObjectField = (field, value) => {
    dispatchUpdateObject(object, field, value).then(() => {
      dispatchGetOrder(order.orderId);
      onChanged();
    });
  };

  const updateOrderField = (field, value) => {
    dispatchSingleUpdateOrder(order.orderId, field, value).then(() => onChanged()).catch((error) => {
      if (error && error.data) {
        setLastError(error.data.errorCode);
      }
    });
  };

  const updateWorkOptions = (options) => {
    dispatchUpdateObjectValues(object, options).then(() => {
      dispatchGetOrder(object.orderId);
      setShowEditWorkOptions(false);
      onChanged();
    }).catch(() => setShowEditWorkOptions(false));
  };

  const renderDelivery = () => {
    if (order.internal && !object.splitted) {
      return (
        <>
          <hr />
          <KeyValueEditRow
            title="Wunsch-Kalenderwoche"
            onChange={(!isEditable) ? null : (value) => updateOrderField('deliveryWeekStart', value)}
            type="weekSelect"
            initialValue={order.deliveryWeekStart}
            displayText={(order.deliveryWeekStart) ? getDisplayString(order.deliveryWeekStart) : ''}
            onClear={
              (isEditable && order.internal && order.deliveryWeekStart)
                ? () => updateOrderField('deliveryWeekStart', null)
                : null
            }
          />
          <KeyValueEditRow
            title="Ausführungsdatum"
            type="date"
            initialValue={object.montageDate.date}
            displayText={(object.montageDate.date) ? formatDate(object.montageDate.date) : ''}
            onChange={(!isEditable) ? null : (date) => updateMontageDate({ ...object.montageDate, date }, 'date')}
            onClear={
              (isEditable && order.internal && object.montageDate.date)
                ? () => updateMontageDate({ ...object.montageDate, date: null }, 'date')
                : null
            }
            editable={isEditable && isTerminable}
          />
        </>
      );
    }
    return null;
  };

  const renderMontageComment = () => {
    if (!object.splitted) {
      return (
        <KeyValueEditRow
          title="Montagehinweise"
          type="textarea"
          displayText={object.montageDate.comment}
          initialValue={object.montageDate.comment}
          onChange={(isEditable) ? (comment) => updateMontageDate({ ...object.montageDate, comment }, 'comment') : null}
        />
      );
    }
    return null;
  };

  const getWorkOptionsAsString = () => {
    const resultArray = [];
    if (object.workOptionElectric) resultArray.push('Elektro');
    if (object.workOptionWater) resultArray.push('Wasser');
    if (object.workOptionAir) resultArray.push('Abluft');
    if (object.workOptionLight) resultArray.push('Beleuchtung');
    if (object.workOptionQuooker) resultArray.push('Quooker');

    if (resultArray.length > 0) {
      return resultArray.join(', ');
    }
    return <i>Keine Optionen gewählt</i>;
  };

  const getOrderOptionsAsString = () => {
    const resultArray = [];
    if (order.optionRemoval) resultArray.push('Demontage');
    if (order.optionDelivery) resultArray.push('Lieferung');
    if (order.optionMontage) resultArray.push('Montage');
    if (order.optionStorage) resultArray.push('Lagerung');

    if (resultArray.length > 0) {
      return resultArray.join(', ');
    }
    return <i>Keine Optionen gewählt</i>;
  };

  return (
    <Panel marginBottom={20} containerStyle={{ display: 'flex' }}>
      <h6 className="Headline">Objektdetails</h6>
      <p>Hier sehen Sie alle Informationen zum Objekt</p>

      <KeyValueEditRow
        title="Kommissionsnummer"
        type="text"
        displayText={object.externalId}
        onChange={(allowChangeExternalId) ? (value) => updateObjectField('externalId', value) : null}
        initialValue={object.externalId}
      />
      <KeyValueEditRow
        title="Auftragsoptionen"
        type="text"
        displayText={getOrderOptionsAsString()}
        initialValue={getOrderOptionsAsString()}
        useCustomAction
        onCustomAction={(isEditable) ? () => setShowEditOrderOptions(true) : () => {}}
      />
      <KeyValueEditRow
        title="Anschlussarbeiten"
        type="text"
        displayText={getWorkOptionsAsString()}
        initialValue={getWorkOptionsAsString()}
        useCustomAction
        onCustomAction={(isEditable) ? () => setShowEditWorkOptions(true) : () => {}}
      />

      <div className="KeyValueEditRowContainer">
        <KeyValueEditRow
          title="Bruttopreis"
          type="text"
          displayText={formatCurrency(object.price)}
          onChange={(isEditable) ? (value) => updateObjectField('price', value) : null}
          initialValue={object.price}
          isFloat
          visible={(object.priceNetto > 0)}
        />
        <KeyValueEditRow
          title="Nettopreis"
          type="text"
          displayText={formatCurrency(object.priceNetto)}
          visible={(object.priceNetto > 0)}
        />
        <KeyValueEditRow
          title="Offener Betrag"
          type="text"
          displayText={formatCurrency(object.balance)}
          onChange={canEditBalance ? updateBalance : null}
          initialValue={object.balance}
          visible={(object.priceNetto > 0)}
          isFloat
        />
      </div>

      <div className="KeyValueEditRowContainer">
        <KeyValueEditRow
          title="Grifflos"
          type="select"
          selectData={YesNoOption}
          displayText={(object.withoutGrip) ? 'Ja' : 'Nein'}
          onChange={(isEditable) ? (value) => updateObjectField('withoutGrip', value) : null}
          initialValue={object.withoutGrip}
          visible={(object.withoutGrip !== null)}
        />
        <KeyValueEditRow
          title="Oberschränke"
          type="select"
          selectData={YesNoOption}
          displayText={(object.wallUnit) ? 'Ja' : 'Nein'}
          onChange={(isEditable) ? (value) => updateObjectField('wallUnit', value) : null}
          initialValue={object.wallUnit}
          visible={(object.wallUnit !== null)}
        />
      </div>
      <div className="KeyValueEditRowContainer">
        <KeyValueEditRow
          title="Nischenverkleidung"
          type="select"
          selectData={YesNoOption}
          displayText={(object.nicheTrim) ? 'Ja' : 'Nein'}
          onChange={(isEditable) ? (value) => updateObjectField('nicheTrim', value) : null}
          initialValue={object.nicheTrim}
          visible={(object.nicheTrim !== null)}
        />
        <KeyValueEditRow
          title="Nischenaufmaß notwendig?"
          type="select"
          selectData={YesNoOption}
          displayText={(object.nicheMeasuring) ? 'Ja' : 'Nein'}
          onChange={(isEditable) ? (value) => updateObjectField('nicheMeasuring', value) : null}
          initialValue={object.nicheMeasuring}
          visible={(object.nicheTrim)}
        />
      </div>
      <div className="KeyValueEditRowContainer">
        <KeyValueEditRow
          title="Küchenlänge"
          type="text"
          displayText={`${object.runningMeterBottom} Meter`}
          onChange={(isEditable) ? (value) => updateObjectField('runningMeterBottom', value) : null}
          initialValue={object.runningMeterBottom}
          visible={(object.runningMeterBottom !== null)}
          isFloat
        />
      </div>
      <div className="KeyValueEditRowContainer">
        <KeyValueEditRow
          title="Spezielle Dunstabzugshaube"
          type="select"
          selectData={YesNoOption}
          displayText={(object.exhaustHood) ? exhaustHoodString : 'Nein'}
          onChange={(isEditable) ? (value) => updateObjectField('exhaustHood', value) : null}
          initialValue={object.exhaustHood}
          visible={(object.exhaustHood !== null)}
        />
        <KeyValueEditRow
          title="Arbeitsplatte"
          type="select"
          selectData={countertopOptions}
          displayText={<CounterTopIcon object={object} />}
          onChange={(isEditable) ? (value) => updateObjectField('countertopType', value) : null}
          initialValue={object.countertopType}
        />
      </div>
      <KeyValueEditRow
        title="Länge der zu demontierenden Küche"
        type="text"
        displayText={order.removalMeter}
        initialValue={order.removalMeter}
        onChange={(isEditable) ? (value) => updateOrderField('removalMeter', value.replace(',', '.')) : null}
        visible={(object.removalMeter > 0)}
      />
      {renderMontageComment()}

      {renderDelivery()}

      <UpdateWorkOptions
        onClose={() => setShowEditWorkOptions(false)}
        onSave={updateWorkOptions}
        object={object}
        visible={showEditWorkOptions}
      />
      <ModalDialog
        onClose={() => setShowEditOrderOptions(false)}
        visible={showEditOrderOptions}
        onConfirm={() => {
          dispatchUpdateOrder({ ...order, ...editOrderOptionObject });
          setShowEditOrderOptions(false);
        }}
      >
        <SelectOrderOptions
          state={editOrderOptionObject}
          onChange={(orderOptions) => setEditOrderOptionObject(orderOptions)}
        />
      </ModalDialog>

      <OrderErrorAlert onClose={() => setLastError(null)} errorCode={lastError} />
    </Panel>
  );
}

ObjectInformation.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  dispatchUpdateOrder: PropTypes.func.isRequired,
  dispatchUpdateObject: PropTypes.func.isRequired,
  dispatchSingleUpdateOrder: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchUpdateObjectValues: PropTypes.func.isRequired,
  onUpdateDate: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  onChanged: PropTypes.func,
  isSystemView: PropTypes.bool,
};

ObjectInformation.defaultProps = {
  onChanged: () => {},
  isSystemView: false,
};

function mapStoreToProps(store) {
  return {
    clientId: store.auth.client.clientId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSingleUpdateOrder: (orderId, field, value) => dispatch(singleUpdateOrder(orderId, field, value)),
    dispatchUpdateOrder: (orderData) => dispatch(update(orderData, true)),
    dispatchUpdateObject: (object, field, value) => dispatch(singleUpdateObject(object, field, value)),
    dispatchUpdateObjectValues: (object, values) => dispatch(updateValues(object, values)),
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ObjectInformation));
