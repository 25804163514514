import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Panel from '../../../../Components/Panel';
import BoxHideIcon from '../BoxHideIcon';
import { ORDERSTATE_WAITING_CALCULATION } from '../../../../Library/StateTypes';
import DefaultRow from './DefaultRow';
import OrderStatusBadge from '../../../../Components/OrderComponents/OrderStatusBadge';

/**
 * ExternalObjectWaitingBox()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ExternalObjectWaitingBox(props) {
  const {
    orders, headline, rowCount, onClick, subHeadline, client,
    identifier, storageDashboardSettings, onHideBox,
  } = props;
  const [filtered, setFiltered] = React.useState(null);
  const [visibleByConfig, setVisibleByConfig] = React.useState(false);

  React.useEffect(() => {
    setVisibleByConfig((
      !storageDashboardSettings
      || storageDashboardSettings[identifier] === undefined
      || storageDashboardSettings[identifier] === true
    ));
  }, [identifier, storageDashboardSettings]);

  React.useEffect(() => {
    if (orders) {
      const filterData = orders.filter((item) => (
        item.orderState === ORDERSTATE_WAITING_CALCULATION
      ))
        .sort((a, b) => {
          if (!a.date) {
            return -1;
          }
          if (!b.date) {
            return 1;
          }
          return (moment(a.date).isAfter(moment(b.date)));
        });
      setFiltered(filterData);
    }
  }, [orders, client]);

  const renderOrders = () => {
    const result = [];
    let currentCount = 1;

    if (filtered) {
      filtered.forEach((order) => {
        if (currentCount <= rowCount) {
          result.push(
            <DefaultRow
              key={order.dateId}
              order={order}
              onClick={(item) => onClick(item)}
              state={ORDERSTATE_WAITING_CALCULATION}
              isIntern={false}
              client={client}
            />,
          );
          currentCount += 1;
        }
      });
    }

    return result;
  };

  const renderSubHeadline = () => {
    if (subHeadline) {
      return (
        <div style={{ fontSize: 16, color: '#9f9f9f' }}>{subHeadline}</div>
      );
    }
    return null;
  };

  const renderHeadline = () => {
    if (React.isValidElement(headline)) {
      return headline;
    }
    return (
      <div className="d-flex flex-row">
        <div className="flex-grow-1">
          <h6 className="SecondaryHeadline" style={{ margin: 0 }}>
            {headline}
          </h6>
          {renderSubHeadline()}
        </div>
        <div>
          <OrderStatusBadge orderState={ORDERSTATE_WAITING_CALCULATION} />
        </div>
      </div>
    );
  };

  if (filtered && filtered.length > 0 && visibleByConfig) {
    return (
      <div style={{ display: 'flex' }}>
        <Panel marginBottom={25} containerStyle={{ flex: 1 }}>
          <BoxHideIcon onPress={() => onHideBox(identifier)} />

          {renderHeadline()}

          <table className="table table-hover linked" style={{ marginTop: 25 }}>
            <thead>
              <tr>
                <th>Typ</th>
                <th>Ausführungsdatum</th>
                <th>Kommission</th>
                <th>Plz & Ort</th>
                <th className="text-center">Optionen</th>
              </tr>
            </thead>
            <tbody>
              {renderOrders()}
            </tbody>
          </table>
        </Panel>
      </div>
    );
  }
  return null;
}

ExternalObjectWaitingBox.propTypes = {
  orders: PropTypes.instanceOf(Array),
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rowCount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  subHeadline: PropTypes.string,
  client: PropTypes.instanceOf(Object),
  identifier: PropTypes.string.isRequired,
  storageDashboardSettings: PropTypes.instanceOf(Object),
  onHideBox: PropTypes.func.isRequired,
};

ExternalObjectWaitingBox.defaultProps = {
  orders: [],
  client: null,
  headline: '',
  rowCount: 100,
  subHeadline: null,
  storageDashboardSettings: null,
};
