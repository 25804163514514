import React, { useState } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import firebase from 'firebase/app';
import { LastLocationProvider } from 'react-router-last-location';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../Pages/Dashboard/Dashboard';
import OrderEdit from '../Pages/Order/Create/Edit';
import ApproveOrder from '../Pages/Order/Create/ApproveOrder';
import ProcessingOrder from '../Pages/Order/Create/ProcessingOrder';
import FindExternalOrders from '../Pages/Order/FindExternalOrders';
import FilterOrders from '../Pages/Order/Filter';
import CalendarDetails from '../Pages/Calendar/Details';
import Notifications from '../Pages/Notifications';
import ClientAdminRoute from './ClientAdminRoute';
import SystemRoute from './System/SystemRouter';
import OrderObjectDetailsRoute from './OrderObjectDetailsRoute';
import DetailsHandler from '../Pages/Order/Details/DetailsHandler';
import ProfileRoute from './ProfileRoute';
import DownloadReport from '../Pages/Public/DownloadReport';
import Config from '../Assets/Config';
import 'firebase/firestore';
import NoAccess from '../Pages/NoAccess';
import OrderDetails from '../Pages/Order/Details/OrderDetails';
import RightNavigationLayout from '../Components/Layout/RightNavigationLayout';
import OrderDetailsRightNavigation from '../Components/Navigation/OrderDetailsRightNavigation';
import Calendar from '../Pages/Calendar/Calendar';
import Disposition from '../Pages/Disposition/Disposition';
import CreateNew from '../Pages/Order/Create/CreateNew';
import Prefilled from '../Pages/Order/Create/Prefilled';
import SupportRoute from './SupportRoute';

/**
 * AppRouter()
 * @returns {*}
 * @constructor
 */
export default function AppRouter() {
  const [gaInitialized, setGaInitialized] = useState(false);

  if (!firebase.apps.length) {
    firebase.initializeApp(Config.firebase.config);
  }

  if (!gaInitialized) {
    ReactGA.initialize('G-EY6HHQ0X46', {
      gaOptions: {
        cookieDomain: 'none',
      },
    });
    setGaInitialized(true);
  }

  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Switch>
          <ProtectedRoute path="/dashboard"><Dashboard /></ProtectedRoute>
          <ProtectedRoute path="/notifications"><Notifications /></ProtectedRoute>
          <ProtectedRoute exact path="/order"><FilterOrders /></ProtectedRoute>
          <ProtectedRoute path="/find"><FindExternalOrders /></ProtectedRoute>

          <ProtectedRoute path="/create/:orderId/edit"><OrderEdit /></ProtectedRoute>
          <ProtectedRoute path="/create/:orderId/approve"><ApproveOrder /></ProtectedRoute>
          <ProtectedRoute path="/create/:orderId/processing"><ProcessingOrder /></ProtectedRoute>

          <ProtectedRoute path="/prefilled/:guid"><Prefilled /></ProtectedRoute>
          <ProtectedRoute path="/create"><CreateNew /></ProtectedRoute>

          <ProtectedRoute path="/calendar/:date"><CalendarDetails /></ProtectedRoute>
          <ProtectedRoute path="/calendar"><Calendar /></ProtectedRoute>
          <ProtectedRoute path="/disposition/:date"><Disposition /></ProtectedRoute>
          <ProtectedRoute path="/disposition"><Disposition /></ProtectedRoute>
          <ProtectedRoute path="/accessdenied"><NoAccess /></ProtectedRoute>

          <Route path="/public/downloadreport/:reportId"><DownloadReport /></Route>

          <Route path="/order/:orderId/object/:objectId"><OrderObjectDetailsRoute /></Route>
          <Route path="/order/:orderId/handle"><DetailsHandler /></Route>
          <ProtectedRoute path="/order/:orderId">
            <RightNavigationLayout navigation={<OrderDetailsRightNavigation />}>
              <OrderDetails />
            </RightNavigationLayout>
          </ProtectedRoute>
          <Route path="/admin"><ClientAdminRoute /></Route>
          <Route path="/system"><SystemRoute /></Route>
          <Route path="/profile"><ProfileRoute /></Route>
          <Route path="/support"><SupportRoute /></Route>
          <Route path="/"><PublicRoute /></Route>
        </Switch>
      </LastLocationProvider>
    </BrowserRouter>
  );
}
