import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

import './style.css';

/**
 * Item()
 * @param props
 * @returns {*}
 * @constructor
 */
function Item(props) {
  const { item } = props;

  const renderLink = () => {
    if (item.location) {
      return (
        <>
          <NavLink to={item.location}>
            {item.caption}
          </NavLink>
          <span>/</span>
        </>
      );
    }
    return (
      <div>{item.caption}</div>
    );
  };

  return (
    <div className="Item">
      {renderLink()}
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

/**
 * Breadcrumb()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function Breadcrumb(props) {
  const { data } = props;

  if (data) {
    return (
      <div className="Breadcrumb">
        {data.map((item) => <Item item={item} key={item.caption} />)}
      </div>
    );
  }
  return null;
}

Breadcrumb.propTypes = {
  data: PropTypes.instanceOf(Array),
};

Breadcrumb.defaultProps = {
  data: null,
};

export default withRouter(Breadcrumb);
