import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HideIcon = styled.i`
  cursor: pointer;
  padding: 12px;
  color: #d9d9d9;
  
  &:hover {
    background-color: ${(props) => props.theme.color.primaryRedColor};
    color: #fff;
    border-radius: 6px;
  }
`;

/**
 * BoxHideIcon()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function BoxHideIcon(props) {
  const { onPress } = props;

  return (
    <>
      <HideIcon className="fas fa-eye" onClick={() => onPress()} title="Alle Boxen wieder einblenden" />
    </>
  );
}

BoxHideIcon.propTypes = {
  onPress: PropTypes.func.isRequired,
};
