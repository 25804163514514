import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../../../../Components/Panel';
import { CLIENT_DOCUMENTSTYPE_LOGO, CLIENTTYPE_DEALER, CLIENTTYPE_MONTEUR } from '../../../../Library/Types';
import Button from '../../../../Components/Button';
import ModalDialog from '../../../../Components/ModalDialog';

import DefaultCompanyLogo from '../../../../Assets/Images/default_company_logo.png';

/**
 * ClientMandantContainer()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ClientMandantContainer(props) {
  const {
    client, item, onDelete, onToggleActive, onApprove, onSettings,
  } = props;
  const [showAskDelete, setShowAskDelete] = React.useState(false);
  const [showNotApprove, setShowNotApprove] = React.useState(false);
  const [hasRequested, setHasRequested] = React.useState(false);
  const [logo, setLogo] = React.useState(null);
  const [partner] = React.useState(item.partner);

  React.useEffect(() => {
    const logoParam = partner.documents.filter((data) => data.type === CLIENT_DOCUMENTSTYPE_LOGO)[0];
    setLogo(logoParam);
  }, [client, partner.documents]);

  React.useEffect(() => {
    setHasRequested((item.clientId === client.clientId));
  }, [item, client]);

  const renderStatus = () => {
    if (item.approved) {
      if (item.active) {
        return (
          <div>
            <i className="fas fa-check-circle text-success" style={{ marginRight: 6 }} />
            Partnerschaft aktiv
          </div>
        );
      }
      return (
        <div>
          <i className="fas fa-pause-circle text-warning" style={{ marginRight: 6 }} />
          Partnerschaft pausiert / inaktiv
        </div>
      );
    }
    return (
      <div>
        <i className="fas fa-question-circle text-info" style={{ marginRight: 6 }} />
        Wartet auf Bestätigung...
      </div>
    );
  };

  const handleConfirmDelete = () => {
    setShowAskDelete(false);
    setShowNotApprove(false);
    if (onDelete) {
      onDelete(item.id);
    }
  };

  const renderActions = () => {
    if (!hasRequested && !item.approved) {
      return (
        <>
          <Button
            type="btn-success btn-sm"
            onClick={() => onApprove(item.id)}
            key="approve"
            style={{ marginRight: 8 }}
          >
            Bestätigen
          </Button>
          <Button type="btn-primary btn-sm" onClick={() => setShowNotApprove(true)} key="decline">
            Ablehnen
          </Button>
        </>
      );
    }

    let settingsAction = null;
    if (client.type === CLIENTTYPE_MONTEUR && partner.type === CLIENTTYPE_DEALER) {
      settingsAction = (
        <Button
          type="btn-outline-dark btn-sm"
          onClick={() => onSettings(item)}
          key="settings"
        >
          <i className="fas fa-cogs" style={{ margin: 0 }} />
        </Button>
      );
    }

    const deleteAction = (
      <Button
        type="btn-outline-danger btn-sm"
        onClick={() => setShowAskDelete(true)}
        key="delete"
        style={{ marginRight: 8 }}
      >
        <i className="fas fa-trash" style={{ margin: 0 }} />
      </Button>
    );

    let activeAction;
    if (onToggleActive && item.approved && hasRequested) {
      if (item.active) {
        activeAction = (
          <Button
            type="btn-outline-warning btn-sm"
            onClick={() => onToggleActive(item.id)}
            key="active"
            style={{ marginRight: 8 }}
            title="Partnerschaft pausieren"
          >
            <i className="fas fa-pause" style={{ margin: 0 }} />
          </Button>
        );
      } else {
        activeAction = (
          <Button
            type="btn-outline-success btn-sm"
            onClick={() => onToggleActive(item.id)}
            key="active"
            style={{ marginRight: 8 }}
            title="Partnerschaft reaktivieren"
          >
            <i className="fas fa-play" style={{ margin: 0 }} />
          </Button>
        );
      }
    }

    return [activeAction, deleteAction, settingsAction];
  };

  const renderCostHint = () => {
    let text;
    let icon;

    if (client.type === CLIENTTYPE_MONTEUR) {
      if (item.clientId === client.clientId) {
        text = 'Aufträge, die Ihnen von diesem Partner eingestellt werden, sind für Sie kostenpflichtig';
        icon = 'far fa-money-bill-alt text-danger';
      } else {
        text = 'Aufträge, die Ihnen von diesem Partner eingestellt werden, sind für Sie kostenlos';
        icon = 'fab fa-creative-commons-nc-eu text-success';
      }
    } else if (item.clientId === client.clientId) {
      text = 'Aufträge, die Sie für diesen Partner einstellen, sind für Sie kostenpflichtig';
      icon = 'far fa-money-bill-alt text-danger';
    } else {
      text = 'Aufträge, die Sie für diesen Partner einstellen, sind für Sie kostenlos';
      icon = 'fab fa-creative-commons-nc-eu text-success';
    }

    return (
      <div style={{ marginTop: 20 }}>
        <i className={icon} style={{ marginRight: 8 }} />
        {text}
      </div>
    );
  };

  return (
    <Panel marginBottom={25}>
      <div className="d-flex flex-row">
        <div
          style={{ marginRight: 40 }}
          className="align-items-center d-flex"
        >
          <img src={(logo) ? logo.absolutePath : DefaultCompanyLogo} width={200} alt="CompanyLogo" />
        </div>
        <div className="flex-grow-1">
          <div><strong>{partner.name}</strong></div>
          <div style={{ fontSize: 14, marginBottom: 8 }}>
            {`Partner-ID: ${partner.clientId}`}
          </div>

          <div>{`${partner.street} ${partner.streetNo}, ${partner.zip} ${partner.city}`}</div>
          <div>{`Telefon: ${partner.phone}`}</div>
          <div>{`E-Mail: ${partner.mail}`}</div>

          {renderCostHint()}
        </div>
        <div className="d-flex flex-column">
          <div className="justify-content-end align-items-start d-flex flex-grow-1">
            {renderActions()}
          </div>
          <div className="d-flex align-items-center">
            {renderStatus()}
          </div>
        </div>
      </div>

      <ModalDialog
        visible={showAskDelete}
        onClose={() => setShowAskDelete(false)}
        centered
        confirmCaption="Partnerschaft beenden"
        onConfirm={handleConfirmDelete}
      >
        <h5>Partnerschaft beenden</h5>
        <p>
          Bitte bestätigen Sie, dass Sie die Partnerschaft beenden möchten. Sobald Sie die Partnerschaft beendet
          haben können Sie keine weiteren Aufträge gegenseitig verwalten. Die bereist erstellten Aufträge bleiben
          bestehen! Sie können den Partner jederzeit wieder zu einer neuen Partnerschaft einladen oder von dem
          Partner zu einer erneuten Partnerschaft eingeladen werden!
        </p>
      </ModalDialog>

      <ModalDialog
        visible={showNotApprove}
        onClose={() => setShowNotApprove(false)}
        centered
        confirmCaption="Anfrage ablehnen"
        onConfirm={handleConfirmDelete}
      >
        <h5>Partnerschaftsanfrage ablehnen</h5>
        <p>
          Bitte bestätigen Sie, dass Sie die Partnerschaft ablehnen möchten!
        </p>
      </ModalDialog>
    </Panel>
  );
}

ClientMandantContainer.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onToggleActive: PropTypes.func,
  onSettings: PropTypes.func.isRequired,
  onApprove: PropTypes.func,
  item: PropTypes.instanceOf(Object).isRequired,
};

ClientMandantContainer.defaultProps = {
  onToggleActive: null,
  onApprove: null,
};
