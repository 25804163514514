import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

import './style.css';

/**
 * ModalDialog()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ModalDialog(props) {
  const {
    visible, onClose, width, children, onConfirm, confirmCaption, confirmClass, hideConfirmButton, closeCaption,
    disableButtons, hideButtons, className, centered, disableConfirm, leftButtonContent,
  } = props;

  const handleOnConfirm = (e) => {
    e.stopPropagation();
    onConfirm();
  };

  const renderConfirmButton = () => {
    if (!hideConfirmButton) {
      return (
        <Button onClick={handleOnConfirm} type={confirmClass} disabled={disableButtons || disableConfirm}>
          {confirmCaption}
        </Button>
      );
    }
    return null;
  };

  const renderLeftButtons = () => {
    if (leftButtonContent) {
      return (
        <div className="float-left">
          {leftButtonContent}
        </div>
      );
    }
    return null;
  };

  const handleOnClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  const renderButtons = () => {
    if (!hideButtons) {
      return (
        <div className="ModalFooter clearfix">
          <hr />
          {renderLeftButtons()}
          <div className="float-right">
            <Button
              onClick={handleOnClose}
              style={{ marginRight: (!hideConfirmButton) ? '10px' : null }}
              type="btn-light"
              disabled={disableButtons}
            >
              {closeCaption}
            </Button>
            {renderConfirmButton()}
          </div>
        </div>
      );
    }
    return null;
  };

  if (visible) {
    return (
      <div className={`Modal ${(centered) ? 'Centered' : ''} ${className}`}>
        <div className="ModalContainer" style={{ width: `${width}px` }}>
          <div className="Body">
            {children}
          </div>
          {renderButtons()}
        </div>
      </div>
    );
  }
  return null;
}

ModalDialog.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  width: PropTypes.number,
  children: PropTypes.node.isRequired,
  confirmCaption: PropTypes.string,
  confirmClass: PropTypes.string,
  hideConfirmButton: PropTypes.bool,
  closeCaption: PropTypes.string,
  disableButtons: PropTypes.bool,
  hideButtons: PropTypes.bool,
  className: PropTypes.string,
  centered: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  leftButtonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object), PropTypes.node]),
};

ModalDialog.defaultProps = {
  visible: false,
  width: 800,
  confirmCaption: 'Speichern',
  confirmClass: 'btn-primary',
  hideConfirmButton: false,
  onConfirm: () => {},
  closeCaption: 'Abbrechen',
  disableButtons: false,
  hideButtons: false,
  className: '',
  centered: false,
  disableConfirm: false,
  leftButtonContent: null,
};
