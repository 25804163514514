import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Switch from 'react-switch';
import styled from 'styled-components';

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  align-items: center;
  
  .Caption {
    color: ${(props) => ((props.theme.disabled) ? '#bcbcbc' : '')};
    text-decoration: ${(props) => ((props.theme.disabled) ? 'line-through' : '')};
    justify-content: center;
  }
`;

/**
 * CustomSwitch()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CustomSwitch(props) {
  const {
    checked, onChange, caption, containerStyle, disabled,
  } = props;

  const handleOnChange = () => {
    if (!disabled) {
      onChange(!checked);
    }
  };

  return (
    <Container
      className="d-flex flex-row"
      style={containerStyle}
      onClick={handleOnChange}
      role="button"
      tabIndex={-1}
      onKeyPress={() => {}}
      theme={{ disabled }}
    >
      <Switch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <div style={{ marginLeft: 10 }} className="Caption">
        {caption}
      </div>
    </Container>
  );
}

CustomSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  caption: PropTypes.string,
  containerStyle: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
};

CustomSwitch.defaultProps = {
  caption: '',
  containerStyle: null,
  disabled: false,
};
