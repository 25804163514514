import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import styled, { useTheme } from 'styled-components';
import { lighten } from 'polished';
import UnavailablePlaceholder from './UnavailablePlaceholder';
import ObjectDateInformation from './ObjectDateInformation';
import ServiceDateInformation from './ServiceDateInformation';
import DispositionInformation from './DispositionInformation';
import {
  DISPOSITION_TYPE_HOLIDAY,
  DISPOSITION_TYPE_MANUAL,
  DISPOSITION_TYPE_OBJECT_DATE,
  DISPOSITION_TYPE_SERVICE,
} from '../../../../Library/Types';

export const Container = styled.div`
  display: flex;
  background-color: ${(props) => lighten(0.2, props.theme.bgColor || props.theme.color.primary)};
  max-width: ${(props) => props.theme.width}px;
  min-width: ${(props) => props.theme.width}px;
  border-left: 
    ${(props) => props.theme.appointment.borderLeftWidth}px 
    solid 
    ${(props) => props.theme.bgColor || props.theme.color.primary};
  position: absolute;
  max-height: ${(props) => props.theme.height}px;
  min-height: ${(props) => props.theme.height}px;
  left: ${(props) => props.theme.left}px;
  top: ${(props) => props.theme.top}px;
  color: ${(props) => props.theme.fontColor || '#ffffff'};
  cursor: pointer;
  
  &:hover {
    background-color: ${(props) => lighten(0, props.theme.bgColor || props.theme.color.primary)};
  }
  
  .Content {
    max-width: ${(props) => props.theme.width - 20}px;
    min-width: ${(props) => props.theme.width - 20}px;
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0 0 0 10px;
    line-height: 20px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    .Left {
      display: flex;
      flex-grow: 1;
      
      .Caption {
        width: 100%;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-grow: 1;

        .SubCaption {
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .Time {
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    
    .NextIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
`;

/**
 * AppointmentContainer()
 * @returns {*}
 * @constructor
 */
export default function AppointmentContainer(props) {
  const {
    appointment, cellWidth, rowHeight, onClick, top,
  } = props;
  const [left, setLeft] = React.useState(null);
  const [width, setWidth] = React.useState(null);
  const theme = useTheme();

  React.useEffect(() => {
    if (appointment) {
      const { startHour } = theme;
      const borderWidth = theme.border.width;
      const { borderLeftWidth } = theme.appointment;
      const pixelPerMinute = (cellWidth + borderWidth) / 60;

      const calcLeft = moment(appointment.start, 'HH:mm')
        .diff(moment(startHour, 'HH:mm'), 'minute');
      setLeft((calcLeft * pixelPerMinute));

      const calcWidth = moment(appointment.end, 'HH:mm')
        .diff(moment(appointment.start, 'HH:mm'), 'minute');
      setWidth((calcWidth * pixelPerMinute) - borderLeftWidth);
    }
  }, [appointment, theme, cellWidth]);

  const renderInformation = () => {
    switch (appointment.type) {
      case DISPOSITION_TYPE_OBJECT_DATE:
        return <ObjectDateInformation appointment={appointment} height={rowHeight} />;
      case DISPOSITION_TYPE_SERVICE:
        return <ServiceDateInformation appointment={appointment} height={rowHeight} />;
      case DISPOSITION_TYPE_MANUAL:
        return <DispositionInformation appointment={appointment} height={rowHeight} />;
      default:
        return null;
    }
  };

  const renderNextIcon = () => {
    if (appointment.hasNext) {
      return (
        <div className="NextIcon" title="Termin geht am Folgetag weiter!">
          <i className="fas fa-arrow-right" />
        </div>
      );
    }
    return null;
  };

  if (left !== null && width !== null) {
    if (appointment.type && appointment.type === DISPOSITION_TYPE_HOLIDAY) {
      return <UnavailablePlaceholder appointment={{ ...appointment }} cellWidth={cellWidth} rowHeight={rowHeight} />;
    }

    return (
      <Container
        theme={{
          ...theme,
          left,
          top,
          width,
          height: rowHeight,
          bgColor: appointment.bgColor,
          fontColor: appointment.fontColor,
        }}
        onClick={() => onClick(appointment)}
      >
        <div className="Content">
          <div className="Left">
            {renderInformation()}
          </div>
          {renderNextIcon()}
        </div>
      </Container>
    );
  }
  return null;
}

AppointmentContainer.propTypes = {
  appointment: PropTypes.instanceOf(Object).isRequired,
  cellWidth: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  top: PropTypes.number,
};

AppointmentContainer.defaultProps = {
  onClick: () => {},
  top: 0,
};
