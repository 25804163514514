import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Panel from '../../Panel';
import KeyValueEditRow from '../../KeyValueEditRow';
import TextLink from '../../TextLink';
import { useDispatchDateDeleteUser } from '../../../Redux/Action/ObjectAction';
import TeamMonteurPartnerRow from '../../TeamMonteurPartner/TeamMonteurPartnerRow';
import SelectUserTeamPartner from '../../SelectUserTeamPartner';
import ObjectDateStatusBadge from '../ObjectDateStatusBadge';
import DispoInformation from './DispoInformation';
import {
  useAccessCanAssignObjects,
  useAccessCanScheduleObjects,
  useAccessIsObjectEditable,
} from '../../../Library/AccessChecks';
import { OBJECT_DATE_TYPE_REMOVAL } from '../../../Library/Types';
import { ORDERSTATE_PARTNER_CREATED } from '../../../Library/StateTypes';

/**
 * AssignRemovalContainer()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function AssignRemovalContainer(props) {
  const {
    object, onUserAdd, onTeamAdd, onPartnerAdd, onUpdate,
  } = props;
  const { client } = useSelector((state) => state.client);
  const { order } = useSelector((state) => state.order);
  const { demontageReport } = useSelector((state) => state.reports);

  const dispatchDateDeleteUser = useDispatchDateDeleteUser();

  const isEditable = useAccessIsObjectEditable(object);
  const isAssignable = useAccessCanAssignObjects();
  const isTerminable = useAccessCanScheduleObjects();
  const [canUsePartners, setCanUsePartners] = React.useState(false);
  const [allowEdit, setAllowEdit] = React.useState(false);
  const [showSelect, setShowSelect] = React.useState(false);
  const [maxDate, setMaxDate] = React.useState(null);
  const [montageDate, setMontageDate] = React.useState(null);
  const [demontageDate, setDemontageDate] = React.useState(null);

  React.useEffect(() => {
    setMontageDate(object.montageDate);
    setDemontageDate(object.removalDate);
  }, [object]);

  React.useEffect(() => {
    if (montageDate && montageDate.date) {
      setMaxDate(montageDate.date);
    } else {
      setMaxDate(null);
    }
  }, [montageDate]);

  React.useEffect(() => {
    if (demontageDate) {
      setCanUsePartners(
        (client.clientId === order.clientId && (!demontageDate.users || demontageDate.users.length === 0)),
      );
      setAllowEdit((client.clientId === order.clientId && !demontageReport));
    }
  }, [client, order, demontageDate, demontageReport]);

  const handleAddTeam = ({ teamId }) => {
    onTeamAdd({ ...demontageDate, teamId });
    setShowSelect(false);
  };

  const handleAddPartner = ({ clientId }) => {
    onPartnerAdd({ ...demontageDate, clientId });
    setShowSelect(false);
  };

  const handleAddUser = (user) => {
    onUserAdd(demontageDate, user);
    setShowSelect(false);
  };

  const handleUpdate = (key, value, disableDispositionChecks = false) => {
    onUpdate({ ...demontageDate, [key]: value }, key, OBJECT_DATE_TYPE_REMOVAL, disableDispositionChecks);
  };

  const renderAddUsers = () => {
    if (demontageReport
        || !isAssignable
        || (order.clientId === client.clientId && demontageDate.clientId)
        || order.state === ORDERSTATE_PARTNER_CREATED) {
      return null;
    }

    if (demontageDate.clientId === client.clientId) {
      let caption = 'Team oder Monteur zuweisen';
      if (!demontageDate.teamId) {
        if (demontageDate.teamId && demontageDate.users.length > 0) {
          caption = 'Weitere Monteure zuweisen';
        }
        return (
          <TextLink
            caption={caption}
            onPress={() => setShowSelect(true)}
            icon="fas fa-plus-square"
            style={{ marginTop: 20 }}
          />
        );
      }
    }

    if (demontageDate.date && (!demontageDate.teamId && !demontageDate.clientId)) {
      let caption = 'Team/Monteur/Partner zuweisen';
      if (!canUsePartners) {
        caption = 'Team oder Monteur zuweisen';
      }

      if (demontageDate.users && demontageDate.users.length > 0) {
        caption = 'Weitere Monteure zuweisen';
      }

      return (
        <TextLink
          caption={caption}
          onPress={() => setShowSelect(true)}
          icon="fas fa-plus-square"
          style={{ marginTop: 20 }}
        />
      );
    }
    return null;
  };

  if (demontageDate && object.splitted && order && order.internal && order.optionRemoval
    && (demontageDate.clientId === client.clientId || order.clientId === client.clientId)
  ) {
    return (
      <Panel marginBottom={20} containerStyle={{ display: 'flex' }}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            <h6 className="Headline">
              <i className="fas fa-trash" style={{ marginRight: 10 }} />
              Demontage
            </h6>
            <p>
              Bitte geben Sie hier das Demontagedatum an. Sobald Sie ein Datum festgelegt haben können Sie ein
              Montageteam, Monteure oder einen Partner für die Demontage zuweisen
            </p>
          </div>
          <div>
            <ObjectDateStatusBadge objectDateState={object.removalDate.state} />
          </div>
        </div>

        <KeyValueEditRow
          title="Demontagedatum"
          type="date"
          initialValue={demontageDate.date}
          displayText={(demontageDate.date) ? moment(demontageDate.date).format('dddd DD.MM.YYYY') : ''}
          onChange={(allowEdit) ? (date) => handleUpdate('date', date) : null}
          onClear={(allowEdit) ? () => handleUpdate('date', null) : null}
          datePickerMaxDate={maxDate}
          datePickerMinDate={moment().format('YYYY-MM-DD')}
          editable={(isEditable && isTerminable)}
        />

        <KeyValueEditRow
          title="Demontagehinweis"
          type="textarea"
          initialValue={demontageDate.comment}
          displayText={demontageDate.comment}
          onChange={(allowEdit) ? (comment) => handleUpdate('comment', comment) : null}
        />

        <DispoInformation
          onChange={(key, value) => handleUpdate(key, value)}
          date={object.removalDate}
          object={object}
          order={order}
          client={client}
          editable={(isEditable && isTerminable)}
        />

        <TeamMonteurPartnerRow
          teamId={demontageDate.teamId}
          partnerId={demontageDate.clientId}
          users={demontageDate.users}
          client={client}
          onDeleteTeam={() => handleUpdate('teamId', null, true)}
          onDeletePartner={() => handleUpdate('clientId', null, true)}
          onDeleteUser={(user) => dispatchDateDeleteUser(object, demontageDate, user.userId)}
          hideUsersOrTeam={(demontageDate.clientId && demontageDate.clientId !== client.clientId)}
          disabled={demontageReport || !isAssignable}
        />
        {renderAddUsers()}

        <SelectUserTeamPartner
          onAddTeam={handleAddTeam}
          onAddPartner={handleAddPartner}
          onAddUser={handleAddUser}
          visible={showSelect}
          alreadyAddedUsers={demontageDate.users}
          showPartners={canUsePartners}
          showTeams={(demontageDate.users.length === 0)}
          onClose={() => setShowSelect(false)}
          executionDate={demontageDate.date}
        />
      </Panel>
    );
  }
  return null;
}

AssignRemovalContainer.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  onUserAdd: PropTypes.func.isRequired,
  onTeamAdd: PropTypes.func.isRequired,
  onPartnerAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
