import React from 'react';
import PropTypes from 'prop-types';
import EditCustomer, { initialCustomer } from '../../../../Components/Order/Edit/EditCustomer';
import Panel from '../../../../Components/Panel';
import EditKitchenOptions, { initialKitchen } from '../../../../Components/Order/Edit/EditKitchenOptions';
import EditDocuments, { initialDocuments } from '../../../../Components/Order/Edit/EditDocuments';

export const initialObject = {
  ...initialKitchen,
  customer: initialCustomer,
  documents: initialDocuments,
};

function reducer(state, action) {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };

    default:
      return state;
  }
}

function reducerLog(oldState, action) {
  const newState = reducer(oldState, action);

  // console.log('Old State:', oldState);
  // console.log('Action:', action);
  // console.log('New State:', newState);

  return newState;
}

export default function OrderObjectPanel(props) {
  const {
    initialState, onChange, initialCounter, isOrderTypeSingle, errors,
  } = props;

  const [state, dispatch] = React.useReducer(reducerLog, initialState);

  React.useEffect((() => {
    if (onChange) {
      onChange(state);
    }
  }), [state, onChange]);

  let counter = (isOrderTypeSingle) ? initialCounter : 0;

  const getCounter = () => {
    counter += 1;
    if (isOrderTypeSingle) {
      return `${counter}. `;
    }
    return `${initialCounter}.${counter}. `;
  };

  const dispatchLocalUpdate = (update) => {
    dispatch({ type: 'update', payload: { ...update } });
  };

  const renderCustomer = () => (
    <Panel marginBottom={30}>
      <EditCustomer
        state={state.customer || initialCustomer}
        onChange={(customer) => dispatchLocalUpdate({ customer })}
        headline={`${getCounter()}Kundenadresse`}
        errors={errors}
      />
    </Panel>
  );

  const renderKitchenOptions = () => (
    <Panel marginBottom={30}>
      <EditKitchenOptions
        kitchen={state || initialKitchen}
        onChange={(kitchenOptions) => dispatchLocalUpdate({ ...kitchenOptions })}
        headlinePrefix={`${getCounter()}`}
        isOrderTypeSingle={isOrderTypeSingle}
        errors={errors}
      />
    </Panel>
  );

  const renderDocuments = () => (
    <Panel marginBottom={30}>
      <EditDocuments
        documents={state.documents || initialDocuments}
        onChange={(documents) => dispatchLocalUpdate({ documents })}
        headline={`${getCounter()}Dokumente`}
        errors={errors}
      />
    </Panel>
  );

  return (
    <>
      {renderCustomer()}
      {renderKitchenOptions()}
      {renderDocuments()}
    </>
  );
}

OrderObjectPanel.propTypes = {
  initialState: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  initialCounter: PropTypes.number,
  isOrderTypeSingle: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
};

OrderObjectPanel.defaultProps = {
  initialState: initialObject,
  onChange: undefined,
  initialCounter: 0,
  isOrderTypeSingle: false,
  errors: undefined,
};
