import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Panel from '../../Panel';
import Button from '../../Button';
import { formatCurrency, formatDate } from '../../../Library/Functions';
import { get, remove } from '../../../Redux/Action/ClientVoucherAction';
import ModalDialog from '../../ModalDialog';

/**
 * VoucherInformation()
 * @param props
 * @returns {*}
 * @constructor
 */
function VoucherInformation(props) {
  const {
    voucher, client, dispatchRemove, dispatchGet, onDeleted,
  } = props;
  const [askDelete, setAskDelete] = React.useState(false);

  const handleDelete = () => {
    dispatchRemove(client.clientId, voucher.voucherId).then(() => {
      onDeleted();
      dispatchGet(client.clientId);
    });
  };

  const getDiscount = () => (
    (voucher.discountPrice) ? formatCurrency(voucher.discountPrice) : `${voucher.discountPercent}%`
  );

  return (
    <Panel>
      <h4>
        Ihr aktueller Gutschein
      </h4>
      <p style={{ marginBottom: 40 }}>
        Sie haben aktuell einen Hey.Kitchen Gutschein eingelöst und aktiviert. Dieser Gutschein wird automatisch
        auf die entsprechenden Aktionen angewendet.
      </p>

      <div className="d-flex flex-column align-items-center">
        <div style={{ maxWidth: 500, textAlign: 'center' }}>
          <h4 className="text-success">
            {`*** ${getDiscount()} Rabatt auf alle Angebote ***`}
          </h4>
          <div><strong>{`Gültig bis einschließlich: ${formatDate(voucher.endDate)}`}</strong></div>
          <div>{`Verwendeter Gutscheincode: ${voucher.formattedCode}`}</div>
          <div>{`Eingelöst am: ${formatDate(voucher.startDate)}`}</div>

          <div className="col-sm-12 d-flex justify-content-center" style={{ marginTop: 30 }}>
            <Button
              onClick={() => setAskDelete(true)}
              type="btn-light btn-sm"
            >
              Gutschein löschen
            </Button>
          </div>
        </div>
      </div>

      <ModalDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        confirmCaption="Gutschein jetzt löschen"
        onConfirm={() => handleDelete()}
        centered
      >
        <h5>Gutschein wirklich löschen?</h5>
        <p>
          Wenn Sie den Gutschein jetzt löschen verlieren Sie sofort den gewährten Rabatt und der Gutschein wird
          ungültig und kann nicht mehr erneut verwendet werden. Bitte bestätigen Sie das Sie den aktuelle Gutschein
          jetzt endgültig löschen möchten.
        </p>
      </ModalDialog>
    </Panel>
  );
}

VoucherInformation.propTypes = {
  dispatchRemove: PropTypes.func.isRequired,
  dispatchGet: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  voucher: PropTypes.instanceOf(Object),
};

VoucherInformation.defaultProps = {
  voucher: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    voucher: store.clientVoucher.voucher,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRemove: (clientId, voucherId) => dispatch(remove(clientId, voucherId)),
    dispatchGet: (clientId) => dispatch(get(clientId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(VoucherInformation);
