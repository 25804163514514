import React from 'react';
import PropTypes from 'prop-types/prop-types';

/**
 * FilterCard
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function FilterCard(props) {
  const {
    caption, selected, onClick,
  } = props;

  return (
    <div
      className={`FilterCard ${(selected) ? 'Selected' : null}`}
      onClick={onClick}
      tabIndex={-1}
      role="button"
      onKeyPress={() => {}}
    >
      {caption}
    </div>
  );
}

FilterCard.propTypes = {
  caption: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

FilterCard.defaultProps = {
  selected: false,
};
