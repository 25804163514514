import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { create } from '../../../Redux/Action/UserAction';
import GroupSelect from '../Component/GroupSelect';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import { YesNoOption } from '../../../Library/Types';
import HelpDialog from '../../../Components/HelpDialog';
import Panel from '../../../Components/Panel';
import Button from '../../../Components/Button';
import { getAll } from '../../../Redux/Action/ClientSubcontractorAction';

const initialState = {
  firstname: '',
  lastname: '',
  referenceId: '',
  subcontractorId: 0,
  mail: '',
  password: '',
  phone: null,
  groups: [],
  active: true,
  username: '',
  initials: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'firstname':
      return { ...state, firstname: action.payload, changed: true };
    case 'lastname':
      return { ...state, lastname: action.payload, changed: true };
    case 'referenceId':
      return { ...state, referenceId: action.payload, changed: true };
    case 'subcontractorId':
      return { ...state, subcontractorId: action.payload, changed: true };
    case 'mail':
      return { ...state, mail: action.payload, changed: true };
    case 'password':
      return { ...state, password: action.payload, changed: true };
    case 'active':
      return { ...state, active: action.payload, changed: true };
    case 'groups':
      return { ...state, groups: action.payload, changed: true };
    case 'phone':
      return { ...state, phone: action.payload, changed: true };
    case 'initials':
      return { ...state, initials: action.payload, changed: true };
    case 'reset':
      return { ...initialState, changed: false };

    default:
      throw new Error();
  }
}

/**
 * Add()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Add(props) {
  const {
    dispatchCreate, history, getAllSubcontractors, client, subcontractors,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errors, setError] = useState({});
  const [currentTabIndex, setCurrentTabIndex] = React.useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [helpContext] = useState('userInitials');
  const [subcontractorName, setSubcontractorName] = useState(null);

  React.useEffect(() => {
    if (!state.initials && state.firstname
      && state.firstname.length > 1 && state.lastname && state.lastname.length > 2
    ) {
      dispatch({
        type: 'initials',
        payload: `${state.firstname.substr(0, 1)}${state.lastname.substr(0, 2)}`.toUpperCase(),
      });
    }
  }, [state.lastname, state.firstname, state.initials]);

  React.useEffect(() => {
    getAllSubcontractors(client.clientId);
  }, [getAllSubcontractors, client.clientId]);

  const save = () => {
    dispatchCreate(state).then(() => {
      setError({});
      dispatch({ type: 'reset' });
      history.goBack();
    }).catch((error) => {
      if (error.data && error.data.errorMessage) {
        let errorData = {};
        Object.keys(error.data.errorMessage).forEach((key) => {
          errorData = { ...errorData, [key]: true };
        });
        setError(errorData);
      } else if (error.data.errorCode === 'USER_MAIL_ALREADY_EXISTING') {
        setError({ mail: true });
      }
    });
  };

  const onCancel = () => {
    setError({});
    dispatch({ type: 'reset' });
    history.goBack();
  };

  function getSelectDataSubcontractor() {
    return ((subcontractors)
      ? [{
        label: 'keine Zuweisung',
        value: 0,

      },
      ...subcontractors.map(
        (item) => ({
          label: item.name,
          value: item.subcontractorId,
        }),
      )]
      : {
        label: '',
        subcontractorId: 0,
      }
    );
  }

  function assignSubcontractor(value) {
    dispatch({ type: 'subcontractorId', payload: value });
    if (value > 0) {
      setSubcontractorName(subcontractors.find((subcontractor) => subcontractor.subcontractorId === value).name);
      dispatch({
        type: 'active',
        payload: subcontractors.find((subcontractor) => subcontractor.subcontractorId === value).active,
      });
    } else {
      setSubcontractorName('keine Zuweisung');
    }
  }

  function renderSubcontracor() {
    if (client.settings.allowSubcontractor) {
      return (
        <div className="col-lg-6">
          <KeyValueEditRow
            type="select"
            title="Subunternehmen zuweißen"
            selectData={getSelectDataSubcontractor()}
            initialValue={subcontractorName}
            displayText={subcontractorName}
            placeholder="keine Zuweisung"
            onChange={(value) => assignSubcontractor(value)}
            containerTabIndex={8}
            startEditMode={(currentTabIndex === 8)}
            onPressEnterGetNextTabIndex={setCurrentTabIndex}
          />
        </div>
      );
    }
    return null;
  }

  return (
    <div className="row">
      <Panel marginBottom={25}>
        <h4 style={{ margin: 0 }}>Benutzer erstellen</h4>
      </Panel>

      <Panel marginBottom={25}>
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Vorname"
                  initialValue={state.firstname}
                  displayText={state.firstname}
                  onChange={(value) => dispatch({ type: 'firstname', payload: value })}
                  containerTabIndex={1}
                  startEditMode={(currentTabIndex === 1)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.firstname)}
                  required
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Nachname"
                  initialValue={state.lastname}
                  displayText={state.lastname}
                  onChange={(value) => dispatch({ type: 'lastname', payload: value })}
                  containerTabIndex={2}
                  startEditMode={(currentTabIndex === 2)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.lastname)}
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Initialen"
                  initialValue={state.initials}
                  displayText={state.initials}
                  onChange={(value) => dispatch({ type: 'initials', payload: value.toString().toUpperCase() })}
                  containerTabIndex={5}
                  startEditMode={(currentTabIndex === 5)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  maxLength={3}
                  onHelpAction={() => setShowHelp(true)}
                  placeholder="Maximal 3 Zeichen als Kürzel für den Benutzer"
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="E-Mail Adresse"
                  initialValue={state.mail}
                  displayText={state.mail}
                  onChange={(value) => dispatch({ type: 'mail', payload: value })}
                  containerTabIndex={3}
                  startEditMode={(currentTabIndex === 3)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.mail)}
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Interne Mitarbeiternummer"
                  initialValue={state.referenceId}
                  displayText={state.referenceId}
                  onChange={(value) => dispatch({ type: 'referenceId', payload: value })}
                  containerTabIndex={4}
                  startEditMode={(currentTabIndex === 4)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Telefonnummer"
                  initialValue={state.phone}
                  displayText={state.phone}
                  onChange={(value) => dispatch({ type: 'phone', payload: value })}
                  containerTabIndex={5}
                  startEditMode={(currentTabIndex === 5)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Passwort"
                  initialValue={state.password}
                  displayText={state.password}
                  onChange={(value) => dispatch({ type: 'password', payload: value })}
                  containerTabIndex={7}
                  startEditMode={(currentTabIndex === 7)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.password)}
                  placeholder="Passwort mit mindestens 8 Zeichen"
                  required
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  type="select"
                  title="Status Benutzerkonto"
                  selectData={YesNoOption}
                  initialValue={state.active}
                  displayText={
                    (state.active)
                      ? 'Benutzer aktiv, anmeldung möglich'
                      : 'Benutzer inaktiv, keine Anmeldung im Web oder in der App möglich'
                  }
                  onChange={(value) => (dispatch({ type: 'active', payload: value }))}
                  containerTabIndex={7}
                  startEditMode={(currentTabIndex === 7)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              {renderSubcontracor()}
            </div>
          </div>
        </div>
      </Panel>

      <Panel marginBottom={25}>
        <GroupSelect
          onChange={(value) => dispatch({ type: 'groups', payload: value })}
        />
      </Panel>

      <Panel withPadding={false}>
        <div className="ButtonContainer d-flex justify-content-end">
          <Button type="btn-light" onClick={onCancel}>Abbrechen</Button>
          <Button disabled={!state.changed} onClick={save}>
            Änderungen speichern
          </Button>
        </div>
      </Panel>

      <HelpDialog context={helpContext} visible={showHelp} onClose={() => setShowHelp(false)} />
    </div>
  );
}

Add.propTypes = {
  dispatchCreate: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  getAllSubcontractors: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  subcontractors: PropTypes.instanceOf(Object).isRequired,
};

function mapStoreToProps(store) {
  return {
    isLoading: store.user.isLoggedin,
    subcontractors: store.subcontractor.subcontractors,
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreate: (userData) => dispatch(create(userData)),
    getAllSubcontractors: (clientId) => dispatch(getAll(clientId, false)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Add));
