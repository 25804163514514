import axios from '../../Library/Axios';

const defaultUri = '/user/{userId}/holiday';

export const USERHOLIDAY_CREATE_START = 'USERHOLIDAY_CREATE_START';
export const USERHOLIDAY_CREATE_SUCCESS = 'USERHOLIDAY_CREATE_SUCCESS';
export const USERHOLIDAY_CREATE_ERROR = 'USERHOLIDAY_CREATE_ERROR';

/**
 * create()
 * @param userId
 * @param holidayData
 * @returns {function(*): Promise<unknown>}
 */
export function create(userId, holidayData) {
  return (dispatch) => {
    dispatch({ type: USERHOLIDAY_CREATE_START, payload: { userId, holidayData } });

    const uri = defaultUri.replace('{userId}', userId);

    return axios().post(uri, holidayData).then((response) => {
      const { data } = response.data;
      dispatch({ type: USERHOLIDAY_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USERHOLIDAY_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const USERHOLIDAY_UPDATE_START = 'USERHOLIDAY_UPDATE_START';
export const USERHOLIDAY_UPDATE_SUCCESS = 'USERHOLIDAY_UPDATE_SUCCESS';
export const USERHOLIDAY_UPDATE_ERROR = 'USERHOLIDAY_UPDATE_ERROR';

/**
 * update()
 * @param userId
 * @param holidayData
 * @returns {function(*): Promise<unknown>}
 */
export function update(userId, holidayData) {
  return (dispatch) => {
    dispatch({ type: USERHOLIDAY_UPDATE_START, payload: { userId, holidayData } });

    const uri = `${defaultUri.replace('{userId}', userId)}/${holidayData.id}`;

    return axios().put(uri, holidayData).then((response) => {
      const { data } = response.data;
      dispatch({ type: USERHOLIDAY_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USERHOLIDAY_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const USERHOLIDAY_DELETE_START = 'USERHOLIDAY_DELETE_START';
export const USERHOLIDAY_DELETE_SUCCESS = 'USERHOLIDAY_DELETE_SUCCESS';
export const USERHOLIDAY_DELETE_ERROR = 'USERHOLIDAY_DELETE_ERROR';

/**
 * remove()
 * @param userId
 * @param holidayId
 * @returns {function(*): Promise<unknown>}
 */
export function remove(userId, holidayId) {
  return (dispatch) => {
    dispatch({ type: USERHOLIDAY_DELETE_START, payload: { userId, holidayId } });

    const uri = `${defaultUri.replace('{userId}', userId)}/${holidayId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: USERHOLIDAY_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USERHOLIDAY_DELETE_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}

export const USERHOLIDAY_GETCALENDARDATA_START = 'USERHOLIDAY_GETCALENDARDATA_START';
export const USERHOLIDAY_GETCALENDARDATA_SUCCESS = 'USERHOLIDAY_GETCALENDARDATA_SUCCESS';
export const USERHOLIDAY_GETCALENDARDATA_ERROR = 'USERHOLIDAY_GETCALENDARDATA_ERROR';

/**
 * getCalendarData()
 * @param userId
 * @param startDate
 * @param endDate
 * @returns {function(*): Promise<unknown>}
 */
export function getCalendarData(userId, startDate, endDate) {
  return (dispatch) => {
    dispatch({ type: USERHOLIDAY_GETCALENDARDATA_START, payload: { userId, startDate, endDate } });

    const uri = defaultUri.replace('{userId}', userId);

    const config = {
      params: { action: 'calendarData', startDate, endDate },
    };

    return axios().get(uri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: USERHOLIDAY_GETCALENDARDATA_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USERHOLIDAY_GETCALENDARDATA_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}
