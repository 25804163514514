import React from 'react';
import PropTypes from 'prop-types/prop-types';
import styled from 'styled-components';
import { formatDate, formatDateTime } from '../../../../Library/Functions';
import { clientNoteTypeOptions } from '../../../../Library/Types';
import ModalDialog from '../../../../Components/ModalDialog';

const NoteContainer = styled.div`
  background-color: #f3f3f3;
  border-radius: 4px;
  padding: 15px 20px;
  margin-bottom: 15px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  > .Header {
    border-bottom: 1px dashed #000;
    padding-bottom: 15px;
    margin-bottom: 15px;
    
    .Icon {
      margin-right: 10px;
    }
    
    .Actions {
      i {
        color: #d9d9d9;
        cursor: pointer;
        
        &:hover {
          color: #000;
        }
      }
    }
  }
`;

/**
 * Note()
 * @param props
 * @returns {*}
 * @constructor
 */
function Note(props) {
  const { item, onUpdate, onDelete } = props;
  const [user] = React.useState(item.createdUser);
  const [askDelete, setAskDelete] = React.useState(false);

  const renderIcon = () => {
    const typeItem = clientNoteTypeOptions.filter((data) => data.value === item.type)[0];

    if (typeItem) {
      return (
        <span className="fa-stack fa-2x" style={{ fontSize: 24 }}>
          <i className="fas fa-circle fa-stack-2x" />
          <i className={`${typeItem.icon} fa-stack-1x fa-inverse`} />
        </span>
      );
    }
    return null;
  };

  const renderResubmissionInfo = () => {
    if (item.resubmissionDate) {
      return (
        <div className="text-warning">
          <i className="fas fa-bell" style={{ marginRight: 10 }} />
          {`Wiedervorlage am ${formatDate(item.resubmissionDate)}`}
        </div>
      );
    }
    return null;
  };

  return (
    <NoteContainer>
      <div className="Header">
        <div className="d-flex flex-row">
          <div className="Icon">
            {renderIcon()}
          </div>
          <div className="flex-grow-1">
            <div>
              <strong>{`${user.firstname} ${user.lastname}`}</strong>
            </div>
            <div style={{ marginTop: -4 }}>{`${formatDateTime(item.createdDate)} Uhr`}</div>
            {renderResubmissionInfo()}
          </div>
          <div className="Actions d-flex flex-row">
            <i
              className="fas fa-pencil-alt"
              style={{ marginRight: 15 }}
              onClick={() => onUpdate(item)}
              tabIndex={-1}
              role="button"
              onKeyPress={() => {}}
            />
            <i
              className="fas fa-trash"
              onClick={() => setAskDelete(true)}
              tabIndex={-1}
              role="button"
              onKeyPress={() => {}}
            />
          </div>
        </div>
      </div>
      <div>
        {item.text}
      </div>

      <ModalDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        confirmCaption="Löschen"
        onConfirm={() => onDelete(item)}
        centered
      >
        <h5>Notiz wirklich löschen?</h5>
        <p>
          Bitte bestätigen Sie das Sie diese Notiz endgültig löschen möchten.
        </p>
      </ModalDialog>
    </NoteContainer>
  );
}

Note.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

/**
 * NoteList()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function NoteList(props) {
  const { notes, onUpdate, onDelete } = props;

  if (notes && notes.length > 0) {
    return (
      <div>
        {notes.map((item) => (
          <Note item={item} key={item.id} onUpdate={onUpdate} onDelete={onDelete} />
        ))}
      </div>
    );
  }
  return null;
}

NoteList.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  notes: PropTypes.instanceOf(Array),
};

NoteList.defaultProps = {
  notes: null,
};
