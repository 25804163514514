import {
  TEAMS_GETLIST_ERROR, TEAMS_GETLIST_START, TEAMS_GETLIST_SUCCESS,
} from '../Action/TeamsAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  teams: null,
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /**
     * GET LIST
     */
    case TEAMS_GETLIST_START: {
      return { ...state, isLoading: true };
    }
    case TEAMS_GETLIST_SUCCESS: {
      return { ...state, isLoading: false, teams: action.payload };
    }
    case TEAMS_GETLIST_ERROR: {
      return { ...state, teams: [], isLoading: false };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
