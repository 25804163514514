import { isValidFloatNumberFormat } from '../../../../Library/Functions';
import { ORDERTYPE_SINGLE } from '../../../../Library/Types';

export const validationFields = {
  price: false,
  balance: false,
  externalId: false,
  runningMeterBottom: false,
  workOptions: false,
  countertopType: false,
};

/**
 * kitchenInformationFormHasError()
 * @param data
 * @param orderType
 * @param objects
 * @param internalMontageInfoMandatory
 * @param isInternal
 * @returns {{workOptions: boolean, balance: boolean, runningMeterBottom: boolean, countertopType: boolean,
 * price: boolean, externalId: boolean}|boolean}
 */
export default function kitchenInformationFormHasError(
  data, orderType, objects, internalMontageInfoMandatory, isInternal,
) {
  let errorCodes = { ...validationFields };
  let hasError = false;

  if (orderType === ORDERTYPE_SINGLE) {
    if (!data.price || data.price === '') {
      errorCodes = { ...errorCodes, price: true };
      hasError = true;
    } else if (!isValidFloatNumberFormat(data.price)) {
      errorCodes = {
        ...errorCodes,
        price: true,
        priceMessage: 'Bitte geben Sie einen gültige Preis ein (Beispiel: 2500.50)',
      };
      hasError = true;
    }

    if (!data.balance || data.balance === '') {
      errorCodes = { ...errorCodes, balance: true };
      hasError = true;
    } else if (!isValidFloatNumberFormat(data.balance)) {
      errorCodes = {
        ...errorCodes,
        balance: true,
        balanceMessage: 'Bitte geben Sie einen gültige Preis ein (Beispiel: 2500.50)',
      };
      hasError = true;
    }

    if (data.balance && data.price && parseFloat(data.balance) > parseFloat(data.price)) {
      errorCodes = {
        ...errorCodes,
        balance: true,
        balanceMessage: 'Der Rechnungsbetrag darf nicht größer als der Küchenpreis sein!',
      };
      hasError = true;
    }

    if (!data.runningMeterBottom || data.runningMeterBottom === '') {
      errorCodes = { ...errorCodes, runningMeterBottom: true };
      hasError = true;
    } else if (!isValidFloatNumberFormat(data.runningMeterBottom)) {
      errorCodes = {
        ...errorCodes,
        runningMeterBottom: true,
        runningMeterBottomMessage: 'Bitte geben Sie eine gültige Länge ein (Beispiel: 2.5)',
      };
      hasError = true;
    }
  }

  if (!data.externalId || data.externalId === '') {
    errorCodes = { ...errorCodes, externalId: true };
    hasError = true;
  }

  if (!data.countertopType) {
    errorCodes = { ...errorCodes, countertopType: true };
    hasError = true;
  }

  if (objects) {
    let externalIdFound = false;
    objects.forEach((item) => {
      if (item.externalId === data.externalId && item.id !== data.id) {
        externalIdFound = true;
      }
    });
    if (externalIdFound) {
      errorCodes = {
        ...errorCodes,
        externalId: true,
        externalIdMessage: 'Diese Kommissionsnummer ist bereits vergeben',
      };
      hasError = true;
    }
  }

  if (internalMontageInfoMandatory && isInternal) {
    if (!data.comment || data.comment.length === 0) {
      errorCodes = { ...errorCodes, comment: true };
      hasError = true;
    }
  }

  if (hasError) {
    return errorCodes;
  }
  return false;
}
