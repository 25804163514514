import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import Panel from '../../Panel';

/**
 * SupportNavigation()
 * @returns {*}
 * @constructor
 */
export default function SupportNavigation() {
  const { url } = useRouteMatch();

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            Hilfe
          </h6>
        </div>
        <hr />
        <ul>
          <li>
            <NavLink className="nav-link" to={`${url}/videos`}>
              <i className="fa fa-solid fa-video" />
              Erklärvideos
            </NavLink>
            <NavLink className="nav-link" to={`${url}/feedback`}>
              <i className="fa fa-solid fa-envelope" />
              Feedback
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/material`}>
              <i className="fas fa-tools" />
              Materialliste
            </NavLink>
          </li>
        </ul>
      </div>
    </Panel>
  );
}
