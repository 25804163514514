import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../ModalDialog';

/**
 * ConfirmDeleteDialog()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function WarningAttachmentSizeDialog(props) {
  const { visible, onClose } = props;

  return (
    <ModalDialog
      visible={visible}
      hideConfirmButton
      closeCaption="OK, verstanden!"
      onClose={() => onClose()}
      centered
    >
      <h5 style={{ marginBottom: 20 }}>Maximale Größe der Kundenmail erreicht!</h5>
      <p style={{ fontSize: 15 }}>
        Die E-Mail an den Kunden darf eine maximal Größe 8 Megabyte nicht überschreiten. Die von Ihnen ausgewählten
        Dokumente überschreiten vermutlich die maximale Grenze von 8 Megabyte. Bitte reduzieren Sie die Anzahl
        der Dokumente oder reduzieren Sie die Größe der einzelnen Dokumente um alle Dokumente in der E-Mail an den
        Kunden zu übermitteln.
      </p>
    </ModalDialog>
  );
}

WarningAttachmentSizeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
