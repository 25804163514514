import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import * as Redux from 'react-redux';
import Panel from '../../../Components/Panel';
import ObjectList from './ObjectPage/ObjectList';
import ObjectListFilter from './ObjectPage/ObjectListFilter';
import Input from '../../../Components/Input';

/**
 * ObjectPage(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectPage(props) {
  const {
    variant, getHeadline, dispatchGetObjects, onClick,
  } = props;

  const objects = Redux.useSelector((store) => (store.systemOrder[variant].filteredObjects));
  const lastUpdated = Redux.useSelector((store) => (store.systemOrder[variant].lastUpdated));
  const isLoading = Redux.useSelector((store) => (store.systemOrder[variant].isLoading));

  const [searchBuffer, setSearchBuffer] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [all, setAll] = React.useState(null);
  const [today, setToday] = React.useState(null);
  const [yesterday, setYesterday] = React.useState(null);

  const loadObjects = React.useCallback(() => {
    dispatchGetObjects();
  }, [dispatchGetObjects]);

  const updateObjects = React.useCallback(() => {
    const now = new Date().getTime();
    const deltaMilliseconds = now - lastUpdated;
    if (deltaMilliseconds > 300000 && !isLoading) {
      loadObjects();
    }
  }, [lastUpdated, loadObjects, isLoading]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(searchBuffer);
    }, 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [searchBuffer]);

  React.useEffect(() => {
    updateObjects();
  }, [updateObjects]);

  const dayFilter = (object, day) => moment(object.date).isSame(day, 'day');

  const searchFilter = React.useCallback((object) => {
    if (!searchValue) return true;

    if (object.orderId.toString().includes(searchValue)) return true;
    if (object.objectId.toString().includes(searchValue)) return true;
    if (object.objectExternalId.includes(searchValue)) return true;
    if (object.ownerName && object.ownerName.includes(searchValue)) return true;

    return false;
  }, [searchValue]);

  React.useEffect(() => {
    if (objects && objects.length > 0) {
      const allData = objects.filter((object) => searchFilter(object));
      setAll(allData);

      const todayDate = moment(new Date());
      const todayData = allData.filter((object) => dayFilter(object, todayDate));
      setToday(todayData);

      const yesterdayDate = todayDate.subtract(1, 'day');
      const yesterdayData = allData.filter((object) => dayFilter(object, yesterdayDate));
      setYesterday(yesterdayData);
    }
  }, [objects, setToday, setYesterday, searchFilter]);

  const renderHeadline = () => (
    <h5 style={{ margin: 0 }}>
      {getHeadline()}
    </h5>
  );

  const renderSubHeadline = () => {
    if (all) {
      return (
        <p style={{ margin: 0 }}>
          {`${(all.length > 0) ? all.length : 'Keine'} Aufträge gefunden`}

          {searchValue ? ` für "${searchValue}"` : ''}
        </p>
      );
    }
    return null;
  };

  const renderSearch = () => (
    <div>
      <Input
        placeholder="Suchen..."
        style={{ margin: 0 }}
        rightIcon={`fas ${(isLoading) ? 'fa-spin fa-spinner' : 'fa-search'}`}
        value={searchBuffer}
        onChange={setSearchBuffer}
      />
    </div>
  );

  const renderFilter = () => (
    <Panel marginBottom={25}>
      <ObjectListFilter variant={variant} />
    </Panel>
  );

  const renderTodayObjects = () => {
    if (today && today.length > 0) {
      return (
        <Panel marginBottom={25}>
          <h6 className="hk-text-primary">
            Aufträge heute
          </h6>
          <hr />
          <ObjectList objects={today} variant={variant} onClick={onClick} />
        </Panel>
      );
    }
    return null;
  };

  const renderYesterdayObjects = () => {
    if (yesterday && yesterday.length > 0) {
      return (
        <Panel marginBottom={25}>
          <h6 className="hk-text-primary">
            Aufträge gestern
          </h6>
          <hr />
          <ObjectList objects={yesterday} variant={variant} onClick={onClick} />
        </Panel>
      );
    }
    return null;
  };

  const renderAllObjects = () => {
    if (all && all.length > 0) {
      return (
        <Panel marginBottom={25}>
          <ObjectList objects={all} variant={variant} onClick={onClick} />
        </Panel>
      );
    }
    return null;
  };

  const renderBody = () => {
    if (objects && objects.length > 0) {
      return (
        <>
          {renderTodayObjects()}
          {renderYesterdayObjects()}
          {renderAllObjects()}
        </>
      );
    }

    if (isLoading) {
      return (
        <Panel marginBottom={25}>
          <center>
            <i className="fas fa-spinner fa-spin fa-5x" />
          </center>
        </Panel>
      );
    }

    return null;
  };

  return (
    <div>
      <Panel boxPadding={10} marginBottom={25}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            {renderHeadline()}
            {renderSubHeadline()}
          </div>
          {renderSearch()}
        </div>
      </Panel>
      {renderFilter()}
      {renderBody()}
    </div>
  );
}

ObjectPage.propTypes = {
  variant: PropTypes.string.isRequired,
  getHeadline: PropTypes.func.isRequired,
  dispatchGetObjects: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

ObjectPage.defaultProps = {
  onClick: () => {},
};
