import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import * as Redux from 'react-redux';
import {
  get as getService,
  useDispatchUpdateService,
} from '../../../Redux/Action/Order/Object/ServiceAction';
import { get as getUser } from '../../../Redux/Action/UserAction';
import { get as getOrder } from '../../../Redux/Action/OrderAction';
import LoadingModal from '../../../Components/LoadingModal';
import Panel from '../../../Components/Panel';
import { formatDate, getObjectFromOrder, hasServiceAccess } from '../../../Library/Functions';
import ObjectInformationPanel from '../../../Components/ObjectComponents/ObjectInformationPanel';
import ServiceInformation from './Component/ServiceInformation';
import {
  ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE,
  NAVIGATION_OBJECT_DETAILS,
  OBJECT_SERVICE_TYPE_RENTAL_KITCHEN,
  objectServiceTypeCaptions,
} from '../../../Library/Types';
import ItemList from './Component/ItemList';
import {
  deleteItem,
  create as createItem,
  useDispatchUpdateObjectServiceItem,
} from '../../../Redux/Action/Order/Object/Service/ItemAction';
import AskAdaptAssemblyTime from './Component/AskAdaptAssemblyTime';
import ServiceStatusBadge from '../../../Components/ServiceComponents/ServiceStatusBadge';
import AssignContainer from '../../../Components/ServiceComponents/AssignContainer';
import DetailsActionButtons from './Component/DetailsActionButtons';
import ClosedHint from './Component/ClosedHint';
import DocumentListPanel from '../../../Components/Documents/DocumentListPanel';
import {
  addServiceDocument,
  deleteDocument,
  useDispatchToggleAttachToMail,
} from '../../../Redux/Action/Order/Object/Service/DocumentAction';
import { SERVICESTATE_CLOSED, SERVICESTATE_REPORT_FINISHED } from '../../../Library/States/ServiceStates';
import AppointmentPanel from '../../../Components/Appointment/AppointmentPanel';
import DispositionMonteurError from '../../../Components/Dialogs/DispositionMonteurError';
import { useDispatchServiceCreateUser } from '../../../Redux/Action/Order/Object/Service/UserAction';

const LAST_ACTION_TYPE_SERVICE_USER_ADD = 'USER_ADD';
const LAST_ACTION_TYPE_SERVICE_UPDATE = 'DATE_UPDATE';

/**
 * Details()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Details(props) {
  const {
    dispatchGetService, order, dispatchGetUser, dispatchDeleteItem, dispatchCreateItem,
    service, dispatchGetOrder, history, dispatchDeleteDocument, dispatchAddDocument, isSystemView,
  } = props;

  const clientId = Redux.useSelector((state) => state.client.client.clientId);

  const dispatchAddUser = useDispatchServiceCreateUser();
  const dispatchUpdateService = useDispatchUpdateService();
  const dispatchToggleAttachToMail = useDispatchToggleAttachToMail();
  const dispatchUpdateObjectServiceItem = useDispatchUpdateObjectServiceItem();

  const { orderId, objectId, serviceId } = useParams();
  const [object, setObject] = useState(null);
  const [createdUser, setCreatedUser] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showDispoError, setShowDispoError] = React.useState(false);
  const [showAskAdaptAssemblyTime, setShowAskAdaptAssemblyTime] = React.useState(false);
  const [adaptAssemblyTime, setAdaptAssemblyTime] = React.useState(null);
  const [lastActionType, setLastActionType] = React.useState(null);
  const [lastActionParam, setLastActionParam] = React.useState(null);
  const [visible, setVisible] = React.useState(false);

  const loadService = useCallback(
    () => {
      dispatchGetOrder(orderId).then((orderData) => dispatchGetService(serviceId)
        .then((serviceData) => dispatchGetUser(serviceData.createdId)
          .then((userResponse) => {
            setCreatedUser(userResponse);
            if (serviceData.orderId !== orderData.orderId) {
              history.push('/dashboard');
            }
          })))
        .catch(() => {
          const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', orderId)
            .replace('{objectId}', objectId);
          history.push(uri);
        });
    },
    [serviceId, dispatchGetService, dispatchGetUser, orderId, dispatchGetOrder, history, objectId],
  );

  React.useEffect(() => {
    if (order && objectId) {
      const obj = getObjectFromOrder(order, objectId);
      setObject(obj);
    }
  }, [order, objectId]);

  React.useEffect(() => {
    loadService();
  }, [serviceId, loadService]);

  React.useEffect(() => {
    if (order && order.orderId && service && service.serviceId && clientId) {
      if (hasServiceAccess({ clientId }, order, service) || isSystemView) {
        setVisible(true);
      } else {
        history.push('/dashboard');
      }
    }
  }, [order, clientId, service, history, isSystemView]);

  const canCreateItem = () => {
    if (service.state === SERVICESTATE_REPORT_FINISHED || service.state === SERVICESTATE_CLOSED) {
      return false;
    }
    return (service.assignedClientId !== clientId);
  };

  const handleCreateItem = (item) => {
    setLoading(true);
    dispatchCreateItem(service, item).then(() => {
      dispatchGetService(serviceId).then(() => {
        setLoading(false);

        setTimeout(() => {
          if (item.assemblyTime && item.assemblyTime > 0) {
            setShowAskAdaptAssemblyTime(true);
            setAdaptAssemblyTime(item.assemblyTime);
          }
        }, 500);
      });
    }).catch(() => setLoading(false));
  };

  const handleDeleteItem = (item) => {
    setLoading(true);
    dispatchDeleteItem(service, item.itemId).then(() => {
      setLoading(false);

      setTimeout(() => {
        if (item.assemblyTime && item.assemblyTime > 0) {
          setShowAskAdaptAssemblyTime(true);
          setAdaptAssemblyTime(-item.assemblyTime);
        }
      }, 500);
    }).catch(() => setLoading(false));
  };

  const handleUpdateService = (updatedService, disableDispositionChecks = false) => {
    dispatchUpdateService(updatedService, disableDispositionChecks).then(() => {}).catch((error) => {
      if (error.data.errorCode === ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE) {
        setLastActionType(LAST_ACTION_TYPE_SERVICE_UPDATE);
        setLastActionParam(updatedService);
        setTimeout(() => {
          setShowDispoError(true);
        }, 300);
      }
    });
  };

  const handleAddUser = (user, disableDispositionChecks = false) => {
    dispatchAddUser(service, user.userId, disableDispositionChecks).then(() => {}).catch((error) => {
      if (error.data.errorCode === ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE) {
        setLastActionType(LAST_ACTION_TYPE_SERVICE_USER_ADD);
        setLastActionParam(user);
        setTimeout(() => {
          setShowDispoError(true);
        }, 300);
      }
    });
  };

  const renderCreatedBy = () => {
    if (createdUser) {
      return (
        <span>
          {`Erstellt von ${createdUser.firstname} ${createdUser.lastname} am ${formatDate(service.createdDate)}`}
        </span>
      );
    }
    return null;
  };

  const handleAddDocument = (typeParam, file) => {
    setLoading(true);
    dispatchAddDocument(service, file, typeParam).then(() => {
      setLoading(false);
    });
  };

  const handleDeleteDocument = (document) => {
    setLoading(true);
    dispatchDeleteDocument(service, document.documentId).then(() => {
      setLoading(false);
    });
  };

  const handleAutoAdaptAssemblyTime = () => {
    let newTime = service.assemblyTime + adaptAssemblyTime;
    newTime = (newTime > 0) ? newTime : 0;
    setShowAskAdaptAssemblyTime(false);
    handleUpdateService({ ...service, assemblyTime: newTime });
  };

  const handleDisableDispositionChecks = () => {
    switch (lastActionType) {
      case LAST_ACTION_TYPE_SERVICE_UPDATE: {
        handleUpdateService(lastActionParam, true);
        break;
      }
      case LAST_ACTION_TYPE_SERVICE_USER_ADD: {
        handleAddUser(lastActionParam, true);
        break;
      }
      default:
        setShowDispoError(false);
    }

    setShowDispoError(false);
  };

  const renderAppointmentPanel = () => {
    if (service
      && (service.executionDate
        || service.state === SERVICESTATE_REPORT_FINISHED
        || service.state === SERVICESTATE_CLOSED)
    ) {
      return null;
    }
    if (order && order.internal) {
      return null;
    }
    return (
      <AppointmentPanel
        orderId={order.orderId}
        objectId={object.objectId}
        serviceId={service.serviceId}
      />
    );
  };

  if (service && order && object && visible) {
    return (
      <>
        <Panel marginBottom={20}>
          <div className="d-flex">
            <div className="flex-grow-1 d-flex align-items-center">
              <div style={{ marginRight: 30 }}>
                <ServiceStatusBadge serviceState={service.state} />
              </div>
              <div>
                <h4
                  className="PrimaryHeadline d-flex align-items-center"
                  style={{ marginBottom: 0, marginRight: 20 }}
                >
                  {`${objectServiceTypeCaptions[service.type]} "${object.externalId}"`}
                </h4>
                {renderCreatedBy()}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <DetailsActionButtons
                order={order}
                object={object}
                service={service}
                isSystemView={isSystemView}
              />
            </div>
          </div>
        </Panel>

        <ClosedHint service={service} />

        <ObjectInformationPanel object={object} order={order} isObjectServiceView />

        <ServiceInformation
          service={service}
          onChange={handleUpdateService}
          order={order}
          isExternalMonteur={(clientId === service.assignedClientId)}
          isSystemView={isSystemView}
        />

        {renderAppointmentPanel()}

        <DocumentListPanel
          documents={service.documents}
          order={order}
          onAdd={handleAddDocument}
          onDelete={handleDeleteDocument}
          onAttachToMail={(doc) => dispatchToggleAttachToMail(service, object, doc)}
          headline="Zusätzliche Dokumente"
          type="service"
          isSystemContext={isSystemView}
        >
          Hier können Sie zusätzliche Dokumente zum Serviceauftrag hinterlegen
        </DocumentListPanel>

        <ItemList
          service={service}
          onDelete={handleDeleteItem}
          onUpdate={(item) => dispatchUpdateObjectServiceItem(item)}
          onAdd={canCreateItem() ? handleCreateItem : undefined}
          visible={(service.type !== OBJECT_SERVICE_TYPE_RENTAL_KITCHEN)}
          isInCreateDialog={false}
          isInternal={order.internal}
        />

        <LoadingModal text="Daten werden aktualisiert..." visible={loading} />
        <AskAdaptAssemblyTime
          onConfirm={handleAutoAdaptAssemblyTime}
          onClose={() => setShowAskAdaptAssemblyTime(false)}
          service={service}
          visible={showAskAdaptAssemblyTime}
          assemblyTime={adaptAssemblyTime}
        />

        <AssignContainer onUpdate={handleUpdateService} onAddUser={handleAddUser} />

        <DispositionMonteurError
          onClose={() => setShowDispoError(false)}
          visible={showDispoError}
          onDisableDispoChecks={handleDisableDispositionChecks}
        />
      </>
    );
  }
  return <LoadingModal visible text="Serviceauftrag wird geladen..." />;
}

Details.propTypes = {
  dispatchGetService: PropTypes.func.isRequired,
  dispatchGetUser: PropTypes.func.isRequired,
  dispatchDeleteItem: PropTypes.func.isRequired,
  dispatchCreateItem: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchAddDocument: PropTypes.func.isRequired,
  dispatchDeleteDocument: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  service: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  isSystemView: PropTypes.bool,
};

Details.defaultProps = {
  order: null,
  service: null,
  isSystemView: false,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
    service: store.objectService.service,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetUser: (userId) => dispatch(getUser(userId)),
    dispatchGetService: (serviceId) => dispatch(getService(serviceId)),
    dispatchDeleteItem: (service, itemId) => dispatch(deleteItem(service, itemId)),
    dispatchCreateItem: (service, item) => dispatch(createItem(service, item)),
    dispatchGetOrder: (orderId) => dispatch(getOrder(orderId)),
    dispatchAddDocument: (service, file, typeParam) => dispatch(addServiceDocument(service, file, typeParam)),
    dispatchDeleteDocument: (service, id) => dispatch(deleteDocument(service, id)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Details));
