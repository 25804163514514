import React from 'react';
import PropTypes from 'prop-types';
import {
  Route, withRouter, Redirect, Switch, useRouteMatch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import RightNavigationLayout from '../Components/Layout/RightNavigationLayout';
import ProfileNavigation from '../Components/Navigation/ProfileNavigation';
import Profile from '../Pages/Profile/Profile';
import Holiday from '../Pages/Profile/Holiday';
import TaskOverview from '../Pages/Profile/TaskOverview';

/**
 * ProfileRoute()
 * @param props
 * @returns {*}
 * @constructor
 */
function ProfileRoute(props) {
  const {
    isLoggedin,
  } = props;
  const { path } = useRouteMatch();

  if (isLoggedin) {
    return (
      <RightNavigationLayout navigation={<ProfileNavigation />}>
        <Switch>
          <Route exact path={`${path}/details`}><Profile /></Route>
          <Route exact path={`${path}/holiday`}><Holiday /></Route>
          <Route exact path={`${path}/task`}><TaskOverview /></Route>
          <Redirect to={`${path}/details`} />
        </Switch>
      </RightNavigationLayout>
    );
  }
  return <Redirect to="/login" />;
}

ProfileRoute.propTypes = {
  isLoggedin: PropTypes.bool,
};

ProfileRoute.defaultProps = {
  isLoggedin: null,
};

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
    auth: store.auth,
  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(ProfileRoute));
