import React from 'react';
import PropTypes from 'prop-types/prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const Circle = styled.i`
  font-size: 24px;
  color: #939393;
  margin: 0;
  padding: 0;
  
  &:hover {
    color: #000;
  }
`;

/**
 * CircleContainer()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function CircleContainer(props) {
  const { task, onClick, user } = props;

  const handleOnPress = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick({ ...task, finished: !task.finished });
    }
  };

  if (task.assignedUserId !== user.userId) {
    return (
      <Circle
        className="fas fa-user-circle text-dark"
        title="Sie haben diese Aufgabe einen anderen Mitarbeiter zugewiesen"
      />
    );
  }

  return (
    <div
      onClick={handleOnPress}
      tabIndex={-1}
      onKeyPress={() => {}}
      role="button"
    >
      <Circle className={(task.finished) ? 'fas fa-check-circle text-success' : 'far fa-circle'} />
    </div>
  );
}

CircleContainer.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(CircleContainer));
