/* eslint-disable jsx-a11y/no-autofocus, no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

export default function Input(props) {
  const {
    value, onChange, hasError, type, placeholder, rowClass, disabled, autoComplete, required, readOnly, tooltip,
    inputRef, onFocus, onKeyPress, onBlur, autoFocus, centerInput, maxLength, onHelpPress, showHelpButton,
    style, visible, fireDelayed, inputClassName, id,
  } = props;
  const [internalValue, setInternalValue] = React.useState('');
  let timer;

  const getInputClass = () => {
    const classes = ['form-control'];

    if (hasError) {
      classes.push('is-invalid');
    }
    if (centerInput) {
      classes.push('CenterInput');
    }
    if (inputClassName) {
      classes.push(inputClassName);
    }
    return classes.join(' ');
  };

  if (!visible) {
    return null;
  }

  const handleOnChange = (data) => {
    if (!fireDelayed) {
      onChange(data);
    } else {
      setInternalValue(data);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => onChange(data), 600);
    }
  };

  return (
    <div className={rowClass}>
      <div className="form-group" style={style}>
        <RenderLabel {...props} />
        <div className="input-group">
          <input
            id={id}
            name={`${Math.random()}Input`}
            required={required}
            type={type}
            placeholder={placeholder}
            className={getInputClass()}
            value={(fireDelayed) ? internalValue : ((value === null) ? '' : value)}
            onChange={(e) => handleOnChange(e.target.value)}
            onKeyUp={onKeyPress}
            disabled={disabled}
            autoComplete={autoComplete}
            readOnly={readOnly}
            ref={inputRef}
            onFocus={onFocus}
            onBlur={onBlur}
            autoFocus={autoFocus}
            maxLength={maxLength}
          />
          <RenderErrorMessage {...props} />
          <RenderRightAddon {...props} />
        </div>
      </div>
    </div>
  );
}

Input.propTypes = {
  rightIcon: PropTypes.string,
  rightText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  rowClass: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.oneOf(['false', 'true']),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  tooltip: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  inputRef: PropTypes.instanceOf(Object),
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  centerInput: PropTypes.bool,
  maxLength: PropTypes.number,
  onHelpPress: PropTypes.func,
  showHelpButton: PropTypes.bool,
  visible: PropTypes.bool,
  fireDelayed: PropTypes.bool,
  inputClassName: PropTypes.string,
  id: PropTypes.string,
};

Input.defaultProps = {
  rightIcon: null,
  rightText: null,
  label: null,
  value: '',
  hasError: false,
  errorMessage: '',
  type: 'text',
  placeholder: '',
  rowClass: '',
  disabled: false,
  autoComplete: 'true',
  required: false,
  readOnly: false,
  tooltip: '',
  style: {},
  inputRef: null,
  onFocus: () => {},
  onKeyPress: () => {},
  onBlur: () => {},
  autoFocus: false,
  centerInput: false,
  maxLength: null,
  onHelpPress: () => {},
  showHelpButton: false,
  visible: true,
  fireDelayed: false,
  inputClassName: null,
  id: null,
};

/**
 * RenderLabel()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderLabel(props) {
  // eslint-disable-next-line react/prop-types
  const { label, tooltip, required } = props;

  const renderTooltip = () => {
    if (tooltip && tooltip !== '') {
      return (
        <i
          className="fas fa-info-circle"
          data-toggle="tooltip"
          data-placement="right"
          title={tooltip}
        />
      );
    }
    return null;
  };

  if (label) {
    return (
      <label>
        {(required) ? `${label}*` : label}
        {' '}
        {renderTooltip()}
      </label>
    );
  }
  return null;
}

/**
 * RenderRightAddon()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderRightAddon(props) {
  const {
    // eslint-disable-next-line react/prop-types
    rightIcon, rightText, showHelpButton, onHelpPress,
  } = props;

  const renderHelpButton = () => {
    if (showHelpButton) {
      return (
        <button className="btn btn-outline-secondary" type="button" onClick={onHelpPress}>
          <i className="fas fa-question-circle" />
        </button>
      );
    }
    return null;
  };

  if (rightIcon) {
    return (
      <div className="input-group-append">
        <span className="input-group-text">
          <i className={rightIcon} />
        </span>
        {renderHelpButton()}
      </div>
    );
  }

  if (rightText) {
    return (
      <div className="input-group-append">
        <span className="input-group-text">
          {rightText}
        </span>
        {renderHelpButton()}
      </div>
    );
  }

  if (showHelpButton) {
    return (
      <div className="input-group-append">
        {renderHelpButton()}
      </div>
    );
  }

  return null;
}

/**
 * RenderErrorMessage()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderErrorMessage(props) {
  // eslint-disable-next-line react/prop-types
  const { errorMessage } = props;

  if (errorMessage) {
    return (
      <div className="invalid-tooltip">
        {errorMessage}
      </div>
    );
  }
  return null;
}
