import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { useSelector } from 'react-redux';
import Panel from '../../Panel';
import { formatDate } from '../../../Library/Functions';

/**
 * ExternalStorageContainer()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ExternalStorageContainer(props) {
  const { client, object, order } = props;
  const { partners } = useSelector((state) => state.client);
  const [isAddressOwner, setIsAddressOwner] = React.useState(false);

  React.useEffect(() => {
    if (client && object && object.storageDate) {
      setIsAddressOwner((client.clientId === object.storageDate.clientId));
    }
  }, [client, object]);

  const renderOwner = () => {
    if (isAddressOwner) {
      const { loadingAddress } = order;
      return (
        <>
          <p>
            Die Lagerung erfolgt über Ihr Lager:
          </p>
          <address style={{ marginBottom: 0 }}>
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              <strong>
                {loadingAddress.name}
              </strong>
            </div>
            <div>{`${loadingAddress.street} ${loadingAddress.streetNo}`}</div>
            <div>{`${loadingAddress.zip} ${loadingAddress.city}`}</div>
            <br />
            <ydiv>Voraussichtliche Anlieferung:</ydiv>
            <div>
              {
                (object.storageDate.date)
                  ? <strong>{formatDate(object.storageDate.date)}</strong>
                  : <i className="text-muted">Noch nicht festgelegt</i>
              }
            </div>
          </address>
        </>
      );
    }
    return null;
  };

  const renderNotOwner = () => {
    if (!isAddressOwner) {
      const mandant = partners.filter((item) => item.partner.clientId === object.storageDate.clientId)[0];

      return (
        <p>
          Die Lagerung erfolgt über Ihren Partner
          <strong>
            {` "${(mandant) ? mandant.partner.name : ''}" `}
          </strong>
          . Weitere Details, die Adresse und das Datum der Anlieferung finden Sie weiter unten in der Ansicht.
        </p>
      );
    }
    return null;
  };

  if (object && client && object.storageDate) {
    return (
      <Panel marginBottom={20}>
        <h6 style={{ marginBottom: 6 }}>Externe Lageradresse</h6>
        {renderOwner()}
        {renderNotOwner()}
      </Panel>
    );
  }
  return null;
}

ExternalStorageContainer.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};
