import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import Textarea from '../../../../Components/Textarea';

/**
 * MarkClosedDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function MarkClosedDialog(props) {
  const {
    visible, onClose, onSave,
  } = props;
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment('');
  }, [visible]);

  const handleOnSave = () => {
    onSave(comment);
  };

  return (
    <ModalDialog
      visible={visible}
      onClose={onClose}
      onConfirm={handleOnSave}
      confirmCaption="Als erledigt markieren"
      confirmClass="btn-success"
      centered
    >
      <h5>Reklamation als erledigt markieren</h5>
      <p>
        Wenn Sie die Reklamation nicht weiter bearbeiten oder die Reklamation anderweitig erledigt haben, können Sie
        diese jetzt auf "Erledigt" stellen. Der Status "Erledigt" besagt das kein Reklamationsbericht erstellt wurde,
        die Reklamation aber dennoch abgeschlossen ist. Bitte geben Sie einen passenden Kommentar ein um nachvollziehen
        zu können warum die Reklamation als "Erledigt" markiert wurde.
      </p>
      <p>
        Wenn die Reklamation als erledigt markiert wurde ist keine weitere Bearbeitung mehr möglich!
      </p>

      <Textarea
        onChange={(data) => setComment(data)}
        value={comment}
        rows={3}
        label="Begründung / Information"
        autoFocus
      />

    </ModalDialog>
  );
}

MarkClosedDialog.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

MarkClosedDialog.defaultProps = {
  visible: false,
};
