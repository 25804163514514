import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FileSaver from 'file-saver';
import { getList, getPdf } from '../../../Redux/Action/ClientTransactionAction';
import Panel from '../../../Components/Panel';
import LoadingModal from '../../../Components/LoadingModal';
import InvoiceTable from '../../../Components/InvoiceTable';

/**
 * Invoices()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Invoices(props) {
  const {
    dispatchGetList, transactions, isLoading, dispatchGetPdf,
  } = props;
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    dispatchGetList();
  }, [dispatchGetList]);

  const handleDownload = (item) => {
    setIsDownloading(true);
    dispatchGetPdf(item.transactionId).then((data) => {
      setIsDownloading(false);
      FileSaver.saveAs(data, `Rechnung_${item.invoiceNumber}.pdf`);
    }).catch(() => setIsDownloading(false));
  };

  const renderNoInvoice = () => {
    if (!transactions || transactions.length === 0) {
      return (
        <div className="alert alert-primary d-flex" style={{ marginTop: 40, marginBottom: 0 }}>
          <div>
            <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
          </div>
          <div>
            Aktuell sind keine Rechnungsdaten vorhanden!
            <br />
            Wir informieren Sie, sobald eine neue Rechnung verfügbar ist.
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <Panel marginBottom={25}>
          <h4>Rechnungen</h4>
          <p style={{ marginBottom: 0 }}>
            Hier können Sie jederzeit Ihre Rechnungen und Gutschriften einsehen und herunterladen.
          </p>
        </Panel>

        <Panel marginBottom={25}>
          <InvoiceTable transactions={transactions} onAction={handleDownload} />
          {renderNoInvoice()}
        </Panel>
      </div>

      <LoadingModal visible={isLoading} text="Wird geladen..." />
      <LoadingModal visible={isDownloading} text="Rechnung wird vorbereitet..." />
    </div>
  );
}

Invoices.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGetPdf: PropTypes.func.isRequired,
  transactions: PropTypes.instanceOf(Array),
  isLoading: PropTypes.bool.isRequired,
};

Invoices.defaultProps = {
  transactions: [],
};

function mapStoreToProps(store) {
  return {
    transactions: store.clientTransaction.transactions,
    isLoading: store.clientTransaction.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: () => dispatch(getList()),
    dispatchGetPdf: (transactionId) => dispatch(getPdf(transactionId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Invoices));
