import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import { lighten } from 'polished';
import AppointmentContainer from '../Appointment/AppointmentContainer';
import prepareOverlappingAppointments from '../Functions';

const Container = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => props.theme.cellWeekDayWidth}px;
  
  .HourCell {
    display: flex;
    color: #fff;
    min-width: ${(props) => props.theme.width}px;
    max-width: ${(props) => props.theme.width}px;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-grow: 1;
    max-height: ${(props) => props.theme.height}px;
    min-height: ${(props) => props.theme.height}px;
    border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
    border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};

    &:hover {
      background-color: ${() => lighten(0.45, 'gray')} !important;
    }
    
    &.NoWorkHour {
      ${(props) => props.theme.linearGradient.noWorkHour}
    }
  }
`;

/**
 * UserTimeLineRow()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UserTimeLineRow(props) {
  const {
    data, selectedDay, rowHeight, onClickAppointment, onClickHour,
  } = props;
  const [width, setWidth] = React.useState(null);
  const theme = useTheme();

  React.useEffect(() => {
    const { startHour, endHour, cellWeekDayWidth } = theme;

    const cellWidth = (cellWeekDayWidth / (endHour - startHour + 1));
    setWidth(cellWidth);
  }, [theme]);

  const renderAppointment = () => {
    const filtered = data.data.filter((param) => moment(param.date).isSame(moment(selectedDay), 'day'));
    const prepared = prepareOverlappingAppointments(filtered, rowHeight);

    return prepared.map((param) => (
      <AppointmentContainer
        key={param.key}
        appointment={param}
        cellWidth={width}
        rowHeight={(param.height) || rowHeight}
        onClick={onClickAppointment}
        top={param.top}
      />
    ));
  };

  const renderTimes = () => {
    const {
      startHour, endHour, workDayStart, workDayEnd,
    } = theme;
    const startHourInt = parseInt(moment(workDayStart, 'HH:mm:ss').format('H'), 0);
    const endHourInt = parseInt(moment(workDayEnd, 'HH:mm:ss').format('H'), 0);
    const result = [];

    for (let i = startHour; i <= endHour; i += 1) {
      result.push((
        <div
          className={`HourCell ${(i < startHourInt || i >= endHourInt) ? 'NoWorkHour' : null}`}
          key={`${selectedDay}${i}`}
          onClick={() => onClickHour(moment(i, 'H').format('HH:00'))}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
        />
      ));
    }

    return result;
  };

  if (width) {
    return (
      <Container theme={{ ...theme, width, height: rowHeight }}>
        {renderAppointment()}
        {renderTimes()}
      </Container>
    );
  }
  return null;
}

UserTimeLineRow.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  selectedDay: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
  onClickAppointment: PropTypes.func.isRequired,
  onClickHour: PropTypes.func.isRequired,
};
