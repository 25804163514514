import React from 'react';
import {
  Route, withRouter, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import RightNavigationLayout from '../../Components/Layout/RightNavigationLayout';
import SystemStatisticNavigation from '../../Components/Navigation/SystemStatisticNavigation';
import ExternalOrdersByMonth from '../../Pages/System/Statistics/ExternalOrdersByMonth';
import TransactionsByMonth from '../../Pages/System/Statistics/TransactionsByMonth';

/**
 * SystemStatisticRoutes()
 * @returns {*}
 * @constructor
 */
function SystemStatisticRoutes() {
  const { path, url } = useRouteMatch();

  return (
    <RightNavigationLayout navigation={<SystemStatisticNavigation />}>
      <Switch>
        <Route exact path={`${path}/month`}><ExternalOrdersByMonth /></Route>
        <Route exact path={`${path}/transactions`}><TransactionsByMonth /></Route>
        <Route><Redirect to={`${url}/month`} /></Route>
      </Switch>
    </RightNavigationLayout>
  );
}

SystemStatisticRoutes.propTypes = {

};

SystemStatisticRoutes.defaultProps = {

};

function mapStoreToProps() {
  return {

  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(SystemStatisticRoutes));
