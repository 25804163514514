import React from 'react';
import PropTypes from 'prop-types/prop-types';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import Timeline from './Timeline';
import GroupSeparator from '../GroupSeparator';
import UserTimeLineRow from './UserTimeLineRow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 4px solid ${(props) => props.theme.color.borderGrayLight};
`;

const WeekDayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: ${(props) => props.theme.cellWeekDayWidth + 14}px;
  min-width: ${(props) => props.theme.cellWeekDayWidth + 14}px;
  height: ${(props) => props.theme.cellHeadlineHeight}px;
  background-color: ${(props) => props.theme.color.backgroundGrayLight};
  border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  font-family: "DIN Next LT W01 Bold", sans-serif;
  font-size: 20px;
  padding: 6px 0;
  cursor: pointer;
  
  &:hover {
    color: ${(props) => props.theme.color.primaryRedColor};
  }
`;

/**
 * WeekDay()
 * @returns {*}
 * @constructor
 */
export default function WeekDay(props) {
  const {
    selectedDay, data, onClickAppointment, onClickHour, onClickDate,
  } = props;
  const theme = useTheme();

  const renderData = () => {
    const mappedData = data.map((separator) => {
      const sep = <GroupSeparator key={separator.name} />;
      const result = separator.data.map((item) => (
        <UserTimeLineRow
          key={`${item.lastname}${item.firstname}`}
          data={item}
          selectedDay={selectedDay}
          rowHeight={(separator.rowHeight || theme.cellHeight)}
          onClickAppointment={onClickAppointment}
          onClickHour={(hour) => onClickHour({ hour, date: selectedDay, data: item })}
        />
      ));

      return [sep, ...result];
    });

    return [...mappedData];
  };

  return (
    <Container>
      <WeekDayContainer onClick={() => onClickDate(selectedDay)}>
        {moment(selectedDay).format('dddd, DD.MM.YYYY')}
      </WeekDayContainer>
      <Timeline />
      {renderData()}
    </Container>
  );
}

WeekDay.propTypes = {
  selectedDay: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  onClickAppointment: PropTypes.func.isRequired,
  onClickHour: PropTypes.func.isRequired,
  onClickDate: PropTypes.func.isRequired,
};
