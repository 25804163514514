import React from 'react';
import * as Redux from 'react-redux';
import axios from '../../../../../Library/Axios';
import * as ServiceAction from '../ServiceAction';

export const defaultUri = `${ServiceAction.defaultUriId}/appointment`;
export const defaultUriId = `${defaultUri}/{appointmentId}`;

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.orderId {number}
 * @param parameter.objectId {number}
 * @param parameter.serviceId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUri(parameter, uri = defaultUri) {
  return ServiceAction.prepareUriId(parameter, uri);
}

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.orderId {number}
 * @param parameter.objectId {number}
 * @param parameter.serviceId {number}
 * @param parameter.appointmentId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUriId(parameter, uri = defaultUriId) {
  const { appointmentId } = parameter;
  return prepareUri(parameter, uri).replace('{appointmentId}', appointmentId);
}

export const APPOINTMENT_GET_START = 'APPOINTMENT_GET_START';
export const APPOINTMENT_GET_SUCCESS = 'APPOINTMENT_GET_SUCCESS';
export const APPOINTMENT_GET_ERROR = 'APPOINTMENT_GET_ERROR';
/**
 * Get first valid appointment
 * @param orderId
 * @param objectId
 * @param serviceId
 * @returns {function(*=): *}
 */
export const useDispatchGetAppointment = (orderId, objectId, serviceId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUri({ orderId, objectId, serviceId });

  return React.useCallback((clear = false) => {
    dispatch({ type: APPOINTMENT_GET_START, payload: { clear } });

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      const appointment = data.find((a) => !a.deleted);
      if (appointment) {
        dispatch({ type: APPOINTMENT_GET_SUCCESS, payload: appointment });
        return Promise.resolve(appointment);
      }
      dispatch({ type: APPOINTMENT_GET_SUCCESS, payload: {} });
      return Promise.resolve({});
    }).catch((error) => {
      dispatch({ type: APPOINTMENT_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};

export const APPOINTMENT_CREATE_START = 'APPOINTMENT_CREATE_START';
export const APPOINTMENT_CREATE_SUCCESS = 'APPOINTMENT_CREATE_SUCCESS';
export const APPOINTMENT_CREATE_ERROR = 'APPOINTMENT_CREATE_ERROR';
/**
 * Create/Start new appointment
 * @param orderId
 * @param objectId
 * @param serviceId
 * @returns {function(*, *): *}
 */
export const useDispatchCreateAppointment = (orderId, objectId, serviceId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUri({ orderId, objectId, serviceId });

  return React.useCallback((earliestStartDate, earliestStartComment) => {
    dispatch({ type: APPOINTMENT_CREATE_START });

    return axios().post(uri, { earliestStartDate, earliestStartComment }).then((response) => {
      dispatch({ type: APPOINTMENT_CREATE_SUCCESS, payload: response.data.data });
      return Promise.resolve(response.data.data);
    }).catch((error) => {
      dispatch({ type: APPOINTMENT_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};

export const APPOINTMENT_UPDATE_START = 'APPOINTMENT_UPDATE_START';
export const APPOINTMENT_UPDATE_SUCCESS = 'APPOINTMENT_UPDATE_SUCCESS';
export const APPOINTMENT_UPDATE_ERROR = 'APPOINTMENT_UPDATE_ERROR';
/**
 * Update appointment
 * @param orderId
 * @param objectId
 * @param serviceId
 * @param appointmentId
 * @returns {function(*, *): *}
 */
export const useDispatchUpdateAppointment = (orderId, objectId, serviceId, appointmentId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUriId({
    orderId, objectId, serviceId, appointmentId,
  });

  return React.useCallback((earliestStartComment) => {
    dispatch({ type: APPOINTMENT_UPDATE_START });

    return axios().put(uri, { earliestStartComment }).then((response) => {
      dispatch({ type: APPOINTMENT_UPDATE_SUCCESS, payload: response.data.data });
      return Promise.resolve(response.data.data);
    }).catch((error) => {
      dispatch({ type: APPOINTMENT_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};
