import {
  AUTH_LOGIN_START, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_ERROR, AUTH_LOGOUT_SUCCESS, AUTH_USER_UPDATE,
} from '../Action/AuthAction';
import { CLIENT_UPDATE_SUCCESS, CLIENT_GET_SUCCESS } from '../Action/ClientAction';
import {
  USER_TASK_DELETE_SUCCESS, USER_TASK_GETLIST_SUCCESS,
} from '../Action/User/TaskAction';
import { USER_UPDATE_SUCCESS } from '../Action/UserAction';
import { SYSTEM_CLIENT_LOGINAS_SUCCESS } from '../../Pages/System/Redux/Action/ClientAction';
import { FACILITY_GET_CLIENT_AUTH_SUCCESS } from '../Action/FacilityAction';

const defaultValues = {
  user: null,
  tasks: null,
  groups: [],
  client: null,
  token: null,
  isLoggedin: false,
  isLoading: false,
  statusCode: null,
  errorCode: null,
  isMonteurOnly: false,
  needsReload: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case AUTH_LOGIN_START: {
      return {
        ...state, isLoading: true, isLoggedin: false, statusCode: null, errorCode: null,
      };
    }
    case SYSTEM_CLIENT_LOGINAS_SUCCESS:
    case AUTH_LOGIN_SUCCESS: {
      const { payload } = action;
      const { groups } = payload.user;

      const isMonteurOnly = (groups && groups.length === 1 && groups[0].groupInitials === 'MON');
      return {
        ...state,
        isLoading: false,
        isLoggedin: true,
        user: payload.user,
        token: payload.token,
        client: payload.client,
        groups: groups.map((item) => item.groupInitials),
        isMonteurOnly,
      };
    }
    case AUTH_LOGIN_ERROR: {
      if (action.payload) {
        const { status, data } = action.payload;
        return {
          ...state, isLoading: false, isLoggedin: false, statusCode: status, errorCode: data.errorCode,
        };
      }
      return {
        ...state, isLoading: false, isLoggedin: false, statusCode: 500,
      };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    case USER_UPDATE_SUCCESS: {
      const { payload } = action;
      if (payload.userId === state.user.userId) {
        return { ...state, user: { ...state.user, firstname: payload.firstname, lastname: payload.lastname } };
      }
      return { ...state };
    }

    case CLIENT_GET_SUCCESS:
    case CLIENT_UPDATE_SUCCESS: {
      const { payload } = action;
      return {
        ...state, client: payload,
      };
    }

    /**
     * HANDLE USER TASKS
     */
    case USER_TASK_DELETE_SUCCESS:
    case USER_TASK_GETLIST_SUCCESS: {
      return { ...state, tasks: action.payload };
    }

    case AUTH_USER_UPDATE: {
      return {
        ...state,
        user: action.payload,
        groups: action.payload.groups.map((item) => item.groupInitials),
      };
    }

    /**
     * Facility login as new client
     */
    case FACILITY_GET_CLIENT_AUTH_SUCCESS: {
      const { payload } = action;
      const { groups } = payload.user;

      const isMonteurOnly = (groups && groups.length === 1 && groups[0].groupInitials === 'MON');
      return {
        ...state,
        isLoading: false,
        isLoggedin: true,
        user: payload.user,
        token: payload.token,
        client: payload.client,
        groups: groups.map((item) => item.groupInitials),
        isMonteurOnly,
        needsReload: true,
      };
    }
    default: return { ...state };
  }
}
