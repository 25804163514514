import {
  CLIENTADDRESS_GETLIST_ERROR, CLIENTADDRESS_GETLIST_START, CLIENTADDRESS_GETLIST_SUCCESS,
  CLIENTADDRESS_CREATE_ERROR, CLIENTADDRESS_CREATE_START, CLIENTADDRESS_CREATE_SUCCESS, CLIENTADDRESS_DELETE_SUCCESS,
  CLIENTADDRESS_GET_START, CLIENTADDRESS_GET_SUCCESS, CLIENTADDRESS_GET_ERROR,
} from '../Action/ClientAddressAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  addresses: null,
  isLoading: false,
  address: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /**
     * GETLIST
     */
    case CLIENTADDRESS_GETLIST_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTADDRESS_DELETE_SUCCESS:
    case CLIENTADDRESS_GETLIST_SUCCESS: {
      const { payload } = action;
      return { ...state, addresses: payload, isLoading: false };
    }
    case CLIENTADDRESS_GETLIST_ERROR: {
      return { ...state, addresses: null, isLoading: false };
    }

    /**
     * CREATE
     */
    case CLIENTADDRESS_CREATE_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTADDRESS_CREATE_SUCCESS: {
      const { payload } = action;
      return { ...state, addresses: payload, isLoading: false };
    }
    case CLIENTADDRESS_CREATE_ERROR: {
      return { ...state, addresses: null, isLoading: false };
    }

    /**
     * GET
     */
    case CLIENTADDRESS_GET_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTADDRESS_GET_SUCCESS: {
      const { payload } = action;
      return { ...state, address: payload, isLoading: false };
    }
    case CLIENTADDRESS_GET_ERROR: {
      return { ...state, address: null, isLoading: false };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
