import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useAccessCanScheduleObjects } from '../../../Library/AccessChecks';
import DatePickerInput from '../../Fields/DatePickerInput';
import WeekPickerInput from '../../Fields/WeekPickerInput';
import Button from '../../Button';
import { formatDate } from '../../../Library/Functions';

export default function SelectDeliveryDate(props) {
  const {
    state, possibleDates, onChange, onClick, headline, headlinePrefix, isInternal, errors,
  } = props;

  const accessCanScheduleObjects = useAccessCanScheduleObjects();

  const getCaption = () => (accessCanScheduleObjects ? 'Montagetermin' : 'Wunsch-Kalenderwoche');

  const getHeadline = () => {
    const prefix = headlinePrefix ? `${headlinePrefix} ` : '';
    return prefix + (headline || getCaption());
  };

  const renderDescription = () => (!isInternal ? (
    <p className="text-muted">
      Bitte geben Sie Ihr gewünschten Montagetermin an. Sollte der gewünschte Termin innerhalb der nächsten
      4 Wochen liegen, wird Ihr Auftrag manuell geprüft und Sie erhalten zeitnah ein passendes Angebot von uns!
    </p>
  ) : null);

  const getMinDate = () => (isInternal ? moment().toDate() : moment().add(1, 'days').toDate());

  const handleChangeDeliveryDateStart = (deliveryDateStart) => {
    if (onChange) {
      onChange({
        ...state,
        deliveryDateStart,
        deliveryDateEnd: null,
        deliveryWeekStart: null,
      });
    }
  };

  const handleChangeDeliveryWeekStart = (deliveryWeekStart) => {
    if (onChange) {
      onChange({
        ...state,
        deliveryDateStart: null,
        deliveryDateEnd: null,
        deliveryWeekStart,
      });
    }
  };

  const renderDate = (date) => (
    <tr key={date} onClick={() => handleChangeDeliveryDateStart(date)}>
      <td>{formatDate(date)}</td>
    </tr>
  );

  const renderPossibleDates = () => (isInternal && possibleDates ? (
    <>
      <p>
        Folgende Termine sind auf Basis Ihrer Disposition und der Verfügbarkeit Ihrer Monteure für
        diesen Auftrag möglich. Klicken Sie auf einen Termin um diesen zu übernehmen.
      </p>
      <table className="table table-hover table-bordered table-striped">
        <tbody>
          {possibleDates.map(renderDate)}
        </tbody>
      </table>
    </>
  ) : null);

  const renderDateButton = () => (isInternal && onClick ? (
    <Button type="btn btn-sm btn-light" onClick={onClick}>
      <i className="fas fa-calendar-alt" />
      Mögliche Termine anzeigen
    </Button>
  ) : null);

  const renderDatePickerStart = () => (accessCanScheduleObjects ? (
    <div className="flex-grow-1">
      <DatePickerInput
        value={state.deliveryDateStart}
        label={getCaption()}
        onChange={handleChangeDeliveryDateStart}
        rightIcon="far fa-calendar-alt"
        hasError={errors.deliveryDateStart}
        errorMessage={errors.deliveryDateStartMessage}
        minDate={getMinDate()}
      />
    </div>
  ) : null);

  const renderOr = () => (isInternal && accessCanScheduleObjects ? (
    <div style={{
      marginTop: 35, marginLeft: 0, marginRight: 15, textAlign: 'center', width: 100,
    }}
    >
      oder
    </div>
  ) : null);

  const renderWeekPicker = () => (isInternal ? (
    <div className="flex-grow-1">
      <div>
        <label style={{ fontSize: '14px' }}>
          Wunsch-Kalenderwoche
        </label>
        <WeekPickerInput
          value={state.deliveryWeekStart}
          onChange={handleChangeDeliveryWeekStart}
        />
      </div>
    </div>
  ) : null);

  return (
    <div>
      <div className="d-flex flex-row">
        <div className="flex-grow-1">
          <h5>{getHeadline()}</h5>
          {renderDescription()}
        </div>
        {renderDateButton()}
      </div>
      {renderPossibleDates()}
      <br />
      <div className="d-flex flex-row">
        {renderDatePickerStart()}
        {renderOr()}
        {renderWeekPicker()}
      </div>
    </div>
  );
}

SelectDeliveryDate.propTypes = {
  state: PropTypes.instanceOf(Object),
  possibleDates: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  headline: PropTypes.string,
  headlinePrefix: PropTypes.string,
  isInternal: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
};

SelectDeliveryDate.defaultProps = {
  state: {
    deliveryDateStart: null,
    deliveryDateEnd: null,
    deliveryWeekStart: null,
  },
  possibleDates: null,
  onChange: undefined,
  onClick: undefined,
  headline: null,
  headlinePrefix: null,
  isInternal: false,
  errors: {},
};
