import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ReclamationRow from './ReclamationList/ReclamationRow';

/**
 * ReclamationList(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReclamationList(props) {
  const {
    reclamations, onClick,
  } = props;

  const renderHeader = () => (
    <thead>
      <tr>
        <th>ReklamationsId</th>
        <th>Titel</th>
        <th>Firma</th>
        <th>Ausführungsdatum</th>
        <th>Zugewiesen an</th>
        <th>Status</th>
      </tr>
    </thead>
  );

  const renderRows = () => {
    if (reclamations && reclamations.length > 0) {
      return reclamations.map((service) => (
        <ReclamationRow
          reclamationRow={service}
          isInternal={false}
          key={service.reclamationId}
          onClick={onClick}
        />
      ));
    }
    return null;
  };

  const renderBody = () => {
    if (reclamations && reclamations.length > 0) {
      return (
        <tbody>
          {renderRows()}
        </tbody>
      );
    }
    return null;
  };

  return (
    <table className="table table-hover">
      {renderHeader()}
      {renderBody()}
    </table>
  );
}

ReclamationList.propTypes = {
  reclamations: PropTypes.instanceOf(Array).isRequired,
  onClick: PropTypes.func.isRequired,
};
