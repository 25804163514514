import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './style.css';

/**
 * SelectBox()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function SelectBox(props) {
  const {
    data, onChange, selected, hasError, disabled, rowClass, style, visible, onBlur, inputRef, inputClassName,
  } = props;

  const [selectedValue, setSelectedValue] = useState(0);

  React.useEffect(() => {
    setSelectedValue(selected);
  }, [selected]);

  const handleOnChange = (changedValue) => {
    if (changedValue === 'true') {
      setSelectedValue(true);
      onChange(true);
    } else if (changedValue === 'false') {
      setSelectedValue(false);
      onChange(false);
    } else {
      setSelectedValue(changedValue);
      if (onChange) {
        onChange(changedValue);
      }
    }
  };

  const getInputClass = () => {
    const classes = ['custom-select'];

    if (hasError) {
      classes.push('is-invalid');
    }
    if (inputClassName) {
      classes.push(inputClassName);
    }
    return classes.join(' ');
  };

  if (visible) {
    return (
      <div className={rowClass} style={style}>
        <div className="form-group">
          <RenderLabel {...props} />
          <div className="input-group">
            <select
              className={getInputClass()}
              title="Bitte wählen..."
              onChange={(e) => handleOnChange(e.target.value)}
              value={selectedValue}
              disabled={disabled}
              onBlur={onBlur}
              ref={inputRef}
            >
              {data.map((item) => <Option key={item.key} item={item} selected={item.key === selectedValue} />)}
            </select>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

SelectBox.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  tooltip: PropTypes.string,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  rowClass: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  onBlur: PropTypes.func,
  inputRef: PropTypes.instanceOf(Object),
  inputClassName: PropTypes.string,
};

SelectBox.defaultProps = {
  selected: '',
  tooltip: '',
  hasError: false,
  label: '',
  disabled: false,
  rowClass: '',
  style: {},
  visible: true,
  onBlur: () => {},
  inputRef: null,
  inputClassName: null,
};

/**
 * RenderLabel()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderLabel(props) {
  const { label, tooltip } = props;

  const renderTooltip = () => {
    if (tooltip && tooltip !== '') {
      return (
        <i
          className="fas fa-info-circle"
          data-toggle="tooltip"
          data-placement="right"
          title={tooltip}
        />
      );
    }
    return null;
  };

  if (label) {
    return (
      <label>
        {label}
        {' '}
        {renderTooltip()}
      </label>
    );
  }
  return null;
}

RenderLabel.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
};

RenderLabel.defaultProps = {
  label: null,
  tooltip: null,
};

/**
 * Option()
 * @param props
 * @returns {*}
 * @constructor
 */
function Option(props) {
  const { item } = props;

  return (
    <option value={item.key}>
      {item.caption}
    </option>
  );
}

Option.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};
