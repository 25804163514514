import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Input from '../../../../Components/Input';
import Checkbox from '../../../../Components/Checkbox';
import Button from '../../../../Components/Button';
import { sendWebdemoContactMail } from '../../../../Redux/Action/FrontendAction';
import LoadingModal from '../../../../Components/LoadingModal';

/**
 * WebdemoContact()
 * @returns {*}
 * @constructor
 */
function WebdemoContact(props) {
  const { dispatchSendMail } = props;
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [agb, setAgb] = useState(false);
  const [send, setSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const sendMail = () => {
    setErrors({});

    if (agb && company && name && mail && phone) {
      setIsLoading(true);
      const data = {
        company, name, mail, phone,
      };
      dispatchSendMail(data).then(() => {
        setIsLoading(false);
        setSend(true);
      }).catch(() => {
        setIsLoading(false);
      });
    } else {
      const errorData = {
        company: (!company || company.length === 0),
        name: (!name || name.length === 0),
        mail: (!mail || mail.length === 0),
        phone: (!phone || phone.length === 0),
        agb: (!agb),
      };
      setErrors(errorData);
    }
  };

  const renderContent = () => {
    if (send) {
      return (
        <div className="row" style={{ marginTop: 50 }}>
          <div className="col-lg-12">
            <h4>
              <i className="fas fa-check-circle text-success" style={{ marginRight: 6 }} />
              <span className="text-success">
                Vielen Dank für Ihre Anfrage. Wir werden Sie in Kürze persönlich kontaktieren!
              </span>
            </h4>
          </div>
        </div>
      );
    }
    return (
      <div className="row" style={{ marginTop: 50 }}>
        <Input
          label="Firma"
          onChange={(data) => setCompany(data)}
          value={company}
          rowClass="col-lg-6"
          required
          hasError={!!(errors.company)}
        />
        <Input
          label="Name"
          onChange={(data) => setName(data)}
          value={name}
          rowClass="col-lg-6"
          required
          hasError={!!(errors.name)}
        />
        <Input
          label="Email Adresse"
          onChange={(data) => setMail(data)}
          value={mail}
          rowClass="col-lg-6"
          required
          hasError={!!(errors.mail)}
        />
        <Input
          label="Telefon"
          onChange={(data) => setPhone(data)}
          value={phone}
          rowClass="col-lg-6"
          required
          hasError={!!(errors.phone)}
        />

        <div className="col-lg-12">
          <Checkbox onChange={(data) => setAgb(data)} value={agb} hasError={!!(errors.agb)}>
            Mit der Nutzung dieses Formulars erklären Sie sich mit der Speicherung und Verarbeitung Ihrer
            Daten durch diese Website einverstanden.
            <br />
            Weitere Informationen erhalten Sie in der Datenschutzerklärung
          </Checkbox>
        </div>

        <div className="col-lg-12" style={{ marginTop: 30 }}>
          <Button onClick={() => sendMail()}>
            Abschicken
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="FullPageBox Dark">
      <div id="webdemo" style={{ marginTop: '-120px' }} />
      <div className="container d-flex flex-column">
        <h2>Kostenlose und unverbindliche Onlinedemo in nur 15 Minuten</h2>
        <p>
          In der Onlinedemo stellen wir Ihnen unser System vor und gehen auf Ihre individuellen Fragen ein.
        </p>
        {renderContent()}
      </div>

      <LoadingModal visible={isLoading} text="Ihre Anfrage wird versendet..." />
    </div>
  );
}

WebdemoContact.propTypes = {
  dispatchSendMail: PropTypes.func.isRequired,
};

WebdemoContact.defaultProps = {

};
function mapDispatchToProps(dispatch) {
  return {
    dispatchSendMail: (data) => dispatch(sendWebdemoContactMail(data)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(WebdemoContact);
