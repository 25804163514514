import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Button from '../../Button';

/**
 * TileListViewSwitcher()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function TileListViewSwitcher(props) {
  const { selected, onChange } = props;

  return (
    <>
      <Button
        onClick={() => onChange('list')}
        type="btn-outline"
        style={{ marginRight: 10 }}
        className={`${(selected === 'list') ? 'Active' : ''}`}
      >
        <i className="fas fa-list" style={{ margin: 0 }} />
      </Button>
      <Button
        onClick={() => onChange('tile')}
        type="btn-outline"
        className={`${(selected === 'tile') ? 'Active' : ''}`}
      >
        <i className="fas fa-th" style={{ margin: 0 }} />
      </Button>
    </>
  );
}

TileListViewSwitcher.propTypes = {
  selected: PropTypes.oneOf(['list', 'tile']).isRequired,
  onChange: PropTypes.func.isRequired,
};
