import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types/prop-types';

/**
 * ImagePreviewOverlay()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ImagePreviewOverlay(props) {
  const {
    documents, visible, onClose, selectedIndex, hideIndex,
  } = props;
  const [index, setIndex] = useState(0);
  const [height, setHeight] = useState(500);
  const containerRef = useRef();

  useEffect(() => {
    setIndex(0);
  }, [documents]);

  useEffect(() => {
    if (containerRef.current && visible) {
      setHeight(containerRef.current.clientHeight - 200);
    }
  }, [containerRef, visible]);

  useEffect(() => {
    if (selectedIndex && documents) {
      if (selectedIndex > documents.length) {
        setIndex(0);
      } else {
        setIndex(selectedIndex);
      }
    }
  }, [selectedIndex, documents]);

  const renderImage = () => {
    if (documents[index]) {
      return (
        <img src={documents[index].absolutePath} alt="Gerätebild" style={{ height }} />
      );
    }
    return null;
  };

  const renderNext = () => {
    if (documents.length > 1 && (index + 1) < documents.length) {
      return (
        <div
          className="Next"
          onClick={() => setIndex(index + 1)}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
          title="Nächste Bild"
        >
          <i className="fas fa-angle-right" />
        </div>
      );
    }
    return null;
  };

  const renderPrev = () => {
    if (documents.length > 1 && index > 0) {
      return (
        <div
          className="Prev"
          onClick={() => setIndex(index - 1)}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
          title="Vorheriges Bild"
        >
          <i className="fas fa-angle-left" />
        </div>
      );
    }
    return null;
  };

  const renderIndex = () => {
    if (hideIndex) {
      return null;
    }
    return `${index + 1} von ${documents.length}`;
  };

  if (visible && documents) {
    return (
      <div className="ImagePreviewOverlay">
        <div className="Container" ref={containerRef}>
          <div
            className="CloseButton"
            onClick={onClose}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
            title="Schließen"
          >
            <i className="fas fa-times" />
          </div>
          {renderNext()}
          <div className="Images">
            {renderImage()}
          </div>
          {renderPrev()}
          <div className="BottomCounter">
            {renderIndex()}
          </div>
        </div>
      </div>
    );
  }
  return null;
}

ImagePreviewOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  documents: PropTypes.instanceOf(Array),
  visible: PropTypes.bool,
  selectedIndex: PropTypes.number,
  hideIndex: PropTypes.bool,
};

ImagePreviewOverlay.defaultProps = {
  documents: null,
  visible: false,
  selectedIndex: null,
  hideIndex: false,
};
