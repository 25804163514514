import React from 'react';
import MobileDescriptionRow from '../MobileDescriptionRow';

import './style.css';

const screenApp01 = require('../../../../Assets/Images/App/iphone_screen7.png');

/**
 * MonteurBenefits()
 * @returns {*}
 * @constructor
 */
export default function MonteurBenefits() {
  return (
    <div className="ApplicationRow FullPageBox Light Center" style={{ marginBottom: 100 }}>
      <div className="container d-flex flex-column">
        <h2 style={{ marginBottom: 10 }} className="hk-text-primary">
          Unsere Leistungen
        </h2>

        <div className="ListingContainer" style={{ marginTop: 50 }}>
          <div className="Left">
            <MobileDescriptionRow headline="AUTOM. AUFTRÄGE ERHALTEN" icon="fas fa-chevron-left">
              Keine Auftragsschwankungen
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="TOP VERGÜTUNG" icon="fas fa-chevron-left">
              Auch für jede Reklamation
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="KEIN VERWALTUNGSAUFWAND" icon="fas fa-chevron-left">
              Keine Angebote erforderlich
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="EINFACH & SCHNELL" icon="fas fa-chevron-left">
              die hey.kitchen Montage App
            </MobileDescriptionRow>
          </div>
          <div className="Center">
            <img src={screenApp01} alt="HK Prozess Full" style={{ width: '100%' }} />
          </div>
          <div className="Right">
            <MobileDescriptionRow headline="AUTOMATISCHE GUTSCHRIFT" icon="fas fa-chevron-right" align="left">
              mit 14-tägiger Auszahlung
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="SICHERE BEZAHLUNG" icon="fas fa-chevron-right" align="left">
              Inkasso über hey.kitchen
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="100% KOSTENLOS" icon="fas fa-chevron-right" align="left">
              Für Transport & Montageunternehmen
            </MobileDescriptionRow>
            <MobileDescriptionRow headline="EINFACHE BEDIENUNG" icon="fas fa-chevron-right" align="left">
              einfach & schnell loslegen
            </MobileDescriptionRow>
          </div>
        </div>
      </div>
    </div>

  );
}
