import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Panel from '../../../../Components/Panel';
import { isExternalOrderOwner, isObjectDeletable } from '../../../../Library/Functions';
import Button from '../../../../Components/Button';
import { deleteOrder, get } from '../../../../Redux/Action/OrderAction';
import { deleteObject, sendCustomerMail } from '../../../../Redux/Action/ObjectAction';
import { get as getUser } from '../../../../Redux/Action/UserAction';
import ModalDialog from '../../../../Components/ModalDialog';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import LoadingModal from '../../../../Components/LoadingModal';
import Alert from '../../../../Components/Alert';
import {
  OBJECT_DATESTATE_ASSIGNED,
  OBJECT_DATESTATE_SCHEDULED,
  ORDERSTATE_PARTNER_CREATED,
} from '../../../../Library/StateTypes';
import { ORDERTYPE_SINGLE } from '../../../../Library/Types';

/**
 * ObjectHeadline()
 * @param props
 * @returns {*}
 * @constructor
 */
function ObjectHeadline(props) {
  const {
    order, object, client, dispatchRemoveOrder, dispatchSendCustomerMail, history, dispatchGetOrder,
    dispatchGetUser, dispatchDeleteObject,
  } = props;
  const { services } = useSelector((state) => state.objectService);

  const [showAskDelete, setShowAskDelete] = useState(false);
  const [showAskSendMail, setShowAskSendMail] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [showCustomerNoMail, setShowCustomerNoMail] = useState(false);
  const [createdUser, setCreatedUser] = React.useState(null);

  React.useEffect(() => {
    if (!createdUser && order.createdId) {
      dispatchGetUser(order.createdId).then((response) => setCreatedUser(response));
    }
  }, [dispatchGetUser, createdUser, order]);

  const handleOnSendClick = () => {
    if (object && object.customer.mail) {
      setShowAskSendMail(true);
    } else {
      setShowCustomerNoMail(true);
    }
  };

  const renderMailIcon = () => {
    if (object && object.montageDate && object.montageDate.state
        && (object.montageDate.state === OBJECT_DATESTATE_SCHEDULED
        || object.montageDate.state === OBJECT_DATESTATE_ASSIGNED)) {
      const buttonClass = (object.sendCustomerMail) ? 'btn-success' : 'btn-primary';
      const buttonTile = (object.sendCustomerMail)
        ? `Zuletzt gesendet ${moment(object.sendCustomerMailDate).format('DD.MM.YYYY HH:mm')} Uhr`
        : 'Noch keine Mail versendet!';

      return (
        <Button onClick={handleOnSendClick} type={buttonClass} title={buttonTile}>
          <i className="fas fa-envelope" style={{ margin: 0 }} />
        </Button>
      );
    }
    return null;
  };

  const renderDeleteIcon = () => {
    if (isObjectDeletable(order, object, client) && (!services || services.length === 0)) {
      return (
        <Button
          onClick={() => setShowAskDelete(true)}
          type="btn-danger"
          style={{ marginLeft: 10 }}
          title="Auftrag löschen"
        >
          <i className="fas fa-trash" style={{ margin: 0 }} />
        </Button>
      );
    }
    return null;
  };

  const renderActionButtons = () => {
    if (order.internal || isExternalOrderOwner(client.clientId, order)
      || (order.mandantId === client.clientId && order.state === ORDERSTATE_PARTNER_CREATED)
    ) {
      return (
        <div className="float-right d-flex">
          {renderMailIcon()}
          {renderDeleteIcon()}
        </div>
      );
    }
    return null;
  };

  const onHandleSendMail = () => {
    setShowAskSendMail(false);
    setIsSending(true);
    dispatchSendCustomerMail(object).then(() => {
      dispatchGetOrder(order.orderId).then(() => {
        setIsSending(false);
      });
    }).catch(() => {
      setIsSending(false);
    });
  };

  const handleDelete = () => {
    if (order.type === ORDERTYPE_SINGLE || order.objects.length <= 1) {
      dispatchRemoveOrder(order).then(() => {
        history.push('/dashboard');
      });
    } else {
      dispatchDeleteObject(object.orderId, object.objectId).then(() => {
        history.push(`/order/${order.orderId}`);
      });
    }
  };

  const renderCreator = () => {
    if (order.internal || isExternalOrderOwner(client.clientId, order)) {
      if (createdUser) {
        return (
          <div>
            Auftrag erstellt von
            <>{` "${createdUser.firstname} ${createdUser.lastname}" `}</>
            am
            <>{` ${moment(order.createdDate).format('DD.MM.YYYY')} `}</>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <Panel marginBottom={20}>
      <div className="d-flex">
        <div className="flex-grow-1 d-flex flex-column">
          <h4
            className="PrimaryHeadline d-flex align-items-center"
            style={{ marginBottom: 0 }}
          >
            {`Auftragsdetails "${object.externalId}"`}
          </h4>
          {renderCreator()}
        </div>
        <div>
          {renderActionButtons()}
        </div>
      </div>

      <ConfirmDeleteDialog
        onConfirm={handleDelete}
        onClose={() => setShowAskDelete(false)}
        visible={showAskDelete}
      />

      <ModalDialog
        onClose={() => setShowAskSendMail(false)}
        visible={showAskSendMail}
        confirmCaption="Jetzt senden"
        onConfirm={() => onHandleSendMail()}
      >
        <h5>Kundenbenachrichtigung versenden?</h5>
        <p>
          Bitte bestätigen Sie das Sie jetzt dem Kunden per E-Mail eine Benachrichtigung über den Termin der
          Küchenmontage senden möchten. Der Kunde erhält eine E-Mail mit allen wichtigen Informationen zu seiner
          Küchenmontage.
        </p>
      </ModalDialog>

      <LoadingModal visible={isSending} text="Benachrichtigung wird versendet..." />

      <Alert onClose={() => setShowCustomerNoMail(false)} visible={showCustomerNoMail} type="info">
        <div>
          Um eine Terminbestätigung versenden zu können muss der Kunde eine gültige E-Mail Adresse hinterlegt haben.
          Bitte hinterlegen Sie eine gültige E-Mail Adresse und versuchen Sie erneut die Terminbestätigung zu versenden.
        </div>
      </Alert>
    </Panel>
  );
}

ObjectHeadline.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object),
  dispatchRemoveOrder: PropTypes.func.isRequired,
  dispatchSendCustomerMail: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchGetUser: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  dispatchDeleteObject: PropTypes.func.isRequired,
};

ObjectHeadline.defaultProps = {
  client: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRemoveOrder: (order) => dispatch(deleteOrder(order)),
    dispatchSendCustomerMail: (object) => dispatch(sendCustomerMail(object)),
    dispatchDeleteObject: (orderId, objectId) => dispatch(deleteObject(orderId, objectId)),
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
    dispatchGetUser: (userId) => dispatch(getUser(userId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ObjectHeadline));
