import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from '../../Library/Axios';

const defaultUri = '/disposition';

export const DISPOSITION_GETLIST_START = 'DISPOSITION_GETLIST_START';
export const DISPOSITION_GETLIST_SUCCESS = 'DISPOSITION_GETLIST_SUCCESS';
export const DISPOSITION_GETLIST_ERROR = 'DISPOSITION_GETLIST_ERROR';

/**
 * useDispatchDispositionGetList()
 * @returns {function(*=, *=): *}
 */
export function useDispatchDispositionGetList() {
  const dispatch = useDispatch();

  return React.useCallback((startDate = null, endDate = null) => {
    dispatch({ type: DISPOSITION_GETLIST_START, payload: { startDate, endDate } });

    const config = {
      params: { startDate, endDate },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: DISPOSITION_GETLIST_SUCCESS, payload: { data, startDate, endDate } });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: DISPOSITION_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const DISPOSITION_CREATE_START = 'DISPOSITION_CREATE_START';
export const DISPOSITION_CREATE_SUCCESS = 'DISPOSITION_CREATE_SUCCESS';
export const DISPOSITION_CREATE_ERROR = 'DISPOSITION_CREATE_ERROR';

/**
 * useDispatchDispositionCreate()
 * @returns {function(*=, *): *}
 */
export function useDispatchDispositionCreate() {
  const dispatch = useDispatch();

  return React.useCallback((disposition, isRecurring) => {
    const config = {
      params: {
        action: (isRecurring) ? 'recurring' : 'single',
      },
    };

    dispatch({ type: DISPOSITION_CREATE_START, payload: disposition });

    return axios().post(defaultUri, disposition, config).then((response) => {
      dispatch({ type: DISPOSITION_CREATE_SUCCESS, payload: response.data });
      return Promise.resolve(response.data);
    }).catch((error) => {
      dispatch({ type: DISPOSITION_CREATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const DISPOSITION_UPDATE_START = 'DISPOSITION_UPDATE_START';
export const DISPOSITION_UPDATE_SUCCESS = 'DISPOSITION_UPDATE_SUCCESS';
export const DISPOSITION_UPDATE_ERROR = 'DISPOSITION_UPDATE_ERROR';

/**
 * useDispatchDispositionUpdate()
 * @returns {function(disposition): *}
 */
export function useDispatchDispositionUpdate() {
  const dispatch = useDispatch();

  return React.useCallback((disposition) => {
    dispatch({ type: DISPOSITION_UPDATE_START, payload: disposition });

    const uri = `${defaultUri}/${disposition.dispositionId}`;

    return axios().put(uri, disposition).then((response) => {
      const { data } = response.data;
      dispatch({ type: DISPOSITION_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(response.data);
    }).catch((error) => {
      dispatch({ type: DISPOSITION_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const DISPOSITION_DELETE_START = 'DISPOSITION_DELETE_START';
export const DISPOSITION_DELETE_SUCCESS = 'DISPOSITION_DELETE_SUCCESS';
export const DISPOSITION_DELETE_ERROR = 'DISPOSITION_DELETE_ERROR';

/**
 * useDispatchDispositionDelete()
 * @returns {function(dispositionId): *}
 */
export function useDispatchDispositionDelete() {
  const dispatch = useDispatch();

  return React.useCallback((dispositionId) => {
    dispatch({ type: DISPOSITION_DELETE_START, payload: dispositionId });

    const uri = `${defaultUri}/${dispositionId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: DISPOSITION_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: DISPOSITION_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

/**
 * Calculate the overall work load and the planed work load for the given user in the given date range
 * @returns {(function(*, *=, *=): (Promise<{overallWorkload, workLoad: number}>))|*}
 */
export function useDispatchDispositionCalculateWorkload() {
  const { users, teams } = useSelector((state) => state.disposition);
  const { settings } = useSelector((state) => state.client.client);

  return React.useCallback((type, id, startDate, endDate) => {
    if (users && users.length > 0) {
      let dispoEntries;
      if (type === 'user') {
        dispoEntries = users.filter((item) => item.id === id);
      } else {
        dispoEntries = teams.filter((item) => item.id === id);
      }

      if (dispoEntries && dispoEntries.length === 1) {
        const dates = dispoEntries[0].data.filter((item) => (
          moment(item.date).isSameOrAfter(moment(startDate)) && moment(item.date).isSameOrBefore(moment(endDate))
        ));

        let workLoad = 0;
        dates.forEach((item) => {
          const minutes = moment(item.start, 'HH:mm').diff(moment(item.end, 'HH:mm'), 'minute');
          workLoad += Math.abs(minutes);
        });

        const daysBetween = Math.abs(moment(startDate).diff(moment(endDate), 'days')) + 1;
        const globalWorkLoad = Math.abs(
          moment(settings.globalWorkDayStart, 'HH:mm')
            .diff(moment(settings.globalWorkDayEnd, 'HH:mm'), 'minute'),
        );
        const overallWorkLoad = (globalWorkLoad * daysBetween);
        const workLoadPercent = parseFloat(((workLoad * 100) / overallWorkLoad).toFixed(1));

        return Promise.resolve({
          overallWorkLoad,
          workLoad,
          workLoadRemaining: overallWorkLoad - workLoad,
          workLoadPercent,
          workLoadPercentRemaining: parseFloat((100 - workLoadPercent).toFixed(1)),
        });
      }
    }
    return Promise.reject();
  }, [users, teams, settings]);
}
