import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import Panel from '../../Panel';

/**
 * SystemRightNavigation()
 * @returns {*}
 * @constructor
 */
export default function SystemRightNavigation() {
  const { url } = useRouteMatch();

  const renderExternalOrders = () => (
    <li>
      <NavLink
        className="nav-link d-flex flex-row justify-content-center align-items-center"
        to={`${url}/order`}
      >
        <i className="fas fa-sign-out-alt" />
        <div className="flex-grow-1">
          Auftragsübersicht
        </div>
      </NavLink>
    </li>
  );

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            System-Backend
          </h6>
        </div>

        <hr />

        <ul>
          <li>
            <NavLink className="nav-link" to={`${url}/clients`}>
              <i className="fas fa-users" />
              Kundenübersicht
            </NavLink>
          </li>
          {renderExternalOrders()}
          <li>
            <NavLink className="nav-link" to={`${url}/reclamations`}>
              <i className="fas fa-sync" />
              Externe Reklamationen
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/invoices`}>
              <i className="fas fa-euro-sign" />
              Rechnungen
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/materials`}>
              <i className="fas fa-tools" />
              Materialverwaltung
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/vouchers`}>
              <i className="fas fa-money-check-alt" />
              Gutscheinverwaltung
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/statistics`}>
              <i className="fas fa-chart-bar" />
              Statistiken
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/settings`}>
              <i className="fas fa-cogs" />
              Systemeinstellungen
            </NavLink>
          </li>
        </ul>
      </div>
    </Panel>
  );
}
