import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../ModalDialog';
import {
  useDispatchUpdateProposedDate,
} from '../../../Redux/Action/Order/Object/Service/Appointment/ProposedDateAction';
import ProposedDateEdit from '../ProposedDate/ProposedDateEdit';

/**
 * EditProposedDateModal()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function EditProposedDateModal(props) {
  const {
    orderId, objectId, serviceId, appointmentId, dateId, initialDate, initialStartTime, initialComment,
    onSuccess, onClose,
  } = props;

  const [editData, setEditData] = React.useState(null);
  const [showErrorMissingDate, setShowErrorMissingDate] = React.useState(false);
  const [showErrorMissingStartTime, setShowErrorMissingStartTime] = React.useState(false);

  const dispatchUpdateProposedDate = useDispatchUpdateProposedDate(
    orderId,
    objectId,
    serviceId,
    appointmentId,
    dateId,
  );

  const handleOnConfirm = () => {
    const { date, startTime, comment } = editData;

    const dateIsMissing = date === 'Invalid date';
    setShowErrorMissingDate(dateIsMissing);
    const startTimeIsMissing = startTime === 'Invalid date';
    setShowErrorMissingStartTime(startTimeIsMissing);

    if (!dateIsMissing && !startTimeIsMissing) {
      dispatchUpdateProposedDate(date, startTime, comment).then(() => {
        onSuccess();
        onClose();
      });
    }
  };

  const renderCaption = () => (<h5>Terminvorschlag bearbeiten</h5>);

  const renderDescription = () => (
    <p>Bitte geben Sie mindestens ein Datum und eine Ankunftszeit für den Terminvorschlag ein!</p>
  );

  return (
    <ModalDialog
      visible
      onConfirm={handleOnConfirm}
      confirmCaption="Änderungen speichern"
      onClose={onClose}
    >
      {renderCaption()}
      {renderDescription()}
      <hr />
      <ProposedDateEdit
        initialDate={initialDate}
        initialStartTime={initialStartTime}
        initialComment={initialComment}
        onChange={setEditData}
        showErrorMissingDate={showErrorMissingDate}
        showErrorMissingStartTime={showErrorMissingStartTime}
      />
    </ModalDialog>
  );
}

EditProposedDateModal.propTypes = {
  orderId: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  serviceId: PropTypes.number.isRequired,
  appointmentId: PropTypes.number.isRequired,
  dateId: PropTypes.number.isRequired,
  initialDate: PropTypes.string,
  initialStartTime: PropTypes.string,
  initialComment: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditProposedDateModal.defaultProps = {
  initialDate: null,
  initialStartTime: null,
  initialComment: '',
};
