import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Button from '../../../../Components/Button';
import Panel from '../../../../Components/Panel';
import AddPositionModal from './AddPositionModal';
import { formatCurrency } from '../../../../Library/Functions';

import './style.css';

/**
 * PositionItem()
 * @param props
 * @constructor
 */
function PositionItem(props) {
  const { item, onDelete } = props;

  return (
    <div className="PositionItem">
      <div className="PositionIndex">
        {item.id}
      </div>
      <div className="PositionDescription">
        <div className="Title">{item.caption}</div>
        <div className="Description">{item.description}</div>
      </div>
      <div className="PositionPrice">
        {formatCurrency(item.priceNetto)}
      </div>
      <div className="PositionDelete" onClick={() => onDelete(item)} role="button" tabIndex={-1} onKeyPress={() => {}}>
        <i className="fas fa-trash" />
      </div>
    </div>
  );
}

PositionItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
};

/**
 * PositionList()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function PositionList(props) {
  const {
    positions, onAdd, onDelete, hasError, performanceDate,
  } = props;
  const [showAdd, setShowAdd] = React.useState(false);

  const handleOnAdd = (data) => {
    setShowAdd(false);
    onAdd(data);
  };

  const renderContent = () => {
    if (positions && positions.length > 0) {
      return positions.map((item) => <PositionItem item={item} onDelete={onDelete} key={item.id} />);
    }
    return (
      <div className={`alert ${(!hasError) ? 'alert-info' : 'alert-danger'}`}>
        <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
        Noch keine Positionen vorhanden! Bitte klicken Sie auf das "Plus"-Zeichen um eine Position zu erstellen.
      </div>
    );
  };

  return (
    <Panel marginBottom={25}>
      <div className="d-flex flex-row">
        <div className="flex-grow-1">
          <h6>Rechnungspositionen</h6>
        </div>
        <div>
          <Button onClick={() => setShowAdd(true)} type="btn-sm btn-primary">
            <i className="fas fa-plus" style={{ margin: 0 }} />
          </Button>
        </div>
      </div>

      <div>
        <hr />
      </div>

      <div className="d-flex flex-column">
        {renderContent()}
      </div>

      <AddPositionModal
        visible={showAdd}
        onAdd={handleOnAdd}
        onClose={() => setShowAdd(false)}
        performanceDate={performanceDate}
      />
    </Panel>
  );
}

PositionList.propTypes = {
  positions: PropTypes.instanceOf(Array).isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  performanceDate: PropTypes.string.isRequired,
};

PositionList.defaultProps = {
  hasError: false,
};
