import React from 'react';
import PropTypes from 'prop-types/prop-types';
import OrderOptionIcons from '../../../../Components/OrderComponents/OrderOptionIcons';
import { formatCurrency, formatDate } from '../../../../Library/Functions';
import ShowHideContainer from '../../../../Components/ShowHideContainer';
import { IMPORT_ACTION_NONE } from '../../../../Library/Types';
import getErrorMessage from '../../../../Library/ErrorCodeMappings';

function Row(props) {
  const { order, partners } = props;

  const getCustomer = () => {
    const { customer } = order.objects;
    return `${customer.lastname}, ${customer.firstname}`;
  };

  const renderOwner = () => {
    if (partners && partners.length > 0) {
      if (order.mandantId) {
        const partner = partners.filter((item) => item.partner.clientId === order.mandantId)[0];
        return (
          <td>{partner.partner.name}</td>
        );
      }
      return <td><i>Nein</i></td>;
    }
    return null;
  };

  if (order.action !== IMPORT_ACTION_NONE) {
    return (
      <tr>
        <td>{order.objects.externalId}</td>
        {renderOwner()}
        <td>{`${(order.deliveryDateStart) ? formatDate(order.deliveryDateStart) : 'Nicht definiert'}`}</td>
        <td><OrderOptionIcons order={order} /></td>
        <td>{getCustomer()}</td>
        <td>{formatCurrency(order.objects.price)}</td>
      </tr>
    );
  }
  return (
    <tr>
      <td>{order.objects.externalId}</td>
      <td className="text-right">{getErrorMessage(order.errorCode)}</td>
    </tr>
  );
}

Row.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  partners: PropTypes.instanceOf(Array),
};

Row.defaultProps = {
  partners: null,
};

/**
 * ImportOrderList()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ImportOrderList(props) {
  const { orders, partners, isError } = props;

  const renderHeadlines = () => {
    if (isError) {
      return (
        <>
          <th>Komm.-Nr.</th>
          <th className="text-right">Fehlerbeschreibung</th>
        </>
      );
    }
    return (
      <>
        <th>Komm.-Nr.</th>
        <ShowHideContainer visible={(partners && partners.length > 0)}>
          <th>Ist Partnerauftrag</th>
        </ShowHideContainer>
        <th>Montagedatum</th>
        <th>Optionen</th>
        <th>Kunde</th>
        <th>Preis (brutto)</th>
      </>
    );
  };

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          {renderHeadlines()}
        </tr>
      </thead>
      <tbody>
        {orders.map((item) => <Row order={item} key={item.objects.externalId} partners={partners} />)}
      </tbody>
    </table>
  );
}

ImportOrderList.propTypes = {
  orders: PropTypes.instanceOf(Array).isRequired,
  partners: PropTypes.bool,
  isError: PropTypes.bool,
};

ImportOrderList.defaultProps = {
  partners: null,
  isError: false,
};
