/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * MenuEntryWithPermission()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function MenuEntryWithPermission(props) {
  const {
    title, icon, allowedGroups, to, rightIcon, onClick,
  } = props;
  const userGroups = useSelector((state) => state.auth.groups);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (allowedGroups) {
      if (allowedGroups.length > 0) {
        allowedGroups.forEach((item) => {
          if (userGroups.indexOf(item) > -1) {
            setIsAllowed(true);
          }
        });
      } else {
        setIsAllowed(true);
      }
    }
  }, [userGroups, allowedGroups]);

  const renderRightIcon = () => {
    if (rightIcon) {
      return (<i className={rightIcon} />);
    }
    return null;
  };

  if (isAllowed) {
    if (!onClick && to) {
      return (
        <li>
          <NavLink className="nav-link d-flex flex-row justify-content-center align-items-center" to={to}>
            <i className={icon} />
            <div className="flex-grow-1">
              {title}
            </div>
            {renderRightIcon()}
          </NavLink>
        </li>
      );
    }
    return (
      <li>
        <a
          style={{ cursor: 'pointer' }}
          className="nav-link"
          onClick={onClick}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
        >
          <i className={icon} />
          {title}
        </a>
      </li>
    );
  }
  return null;
}

MenuEntryWithPermission.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  allowedGroups: PropTypes.instanceOf(Array).isRequired,
  to: PropTypes.string,
  rightIcon: PropTypes.string,
  onClick: PropTypes.func,
};

MenuEntryWithPermission.defaultProps = {
  rightIcon: null,
  onClick: null,
  to: null,
};
