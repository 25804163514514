import axios from '../../../Library/Axios';

const defaultUri = '/client/{clientId}/event';

export const CLIENT_EVENTS_GETLIST_START = 'CLIENT_EVENTS_GETLIST_START';
export const CLIENT_EVENTS_GETLIST_SUCCESS = 'CLIENT_EVENTS_GETLIST_SUCCESS';
export const CLIENT_EVENTS_GETLIST_ERROR = 'CLIENT_EVENTS_GETLIST_ERROR';

/**
 * getList()
 * @returns {function(*, *): Promise<unknown>}
 */
export function getList() {
  return (dispatch, getState) => {
    const { clientId } = getState().auth.client;

    dispatch({ type: CLIENT_EVENTS_GETLIST_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_EVENTS_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENT_EVENTS_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
