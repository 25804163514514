/* eslint-disable no-unused-vars */
import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import MonthSelector from './Components/MonthSelector';

/**
 * DayNames()
 * @returns {*}
 * @constructor
 */
function DayHeader(props) {
  const {
    showSunday, currentMonth, startDay, endDay,
  } = props;

  const renderDays = () => {
    const resultNodes = [];

    for (let i = startDay; i <= endDay; i += 1) {
      const dateString = moment(currentMonth).format(`${i}.MM.YYYY`);
      const momentDate = moment(dateString, 'D.MM.YYYY');
      const weekDay = momentDate.weekday();
      const isCurrentDay = momentDate.isSame(moment(), 'day');

      if (weekDay !== 6 || showSunday) {
        resultNodes.push((
          <th
            style={{ fontSize: 12 }}
            key={`${dateString}-header`}
            className={(isCurrentDay) ? 'Active' : ''}
          >
            {i}
          </th>
        ));
      } else {
        resultNodes.push((
          <th style={{ fontSize: 12, maxWidth: 4, backgroundColor: '#ededed' }} key={`${dateString}-header`} />
        ));
      }
    }
    return resultNodes;
  };

  return (
    <thead>
      <tr>
        <th width={250}>Mitarbeiter</th>
        {renderDays()}
      </tr>
    </thead>
  );
}

DayHeader.propTypes = {
  showSunday: PropTypes.bool.isRequired,
  currentMonth: PropTypes.instanceOf(Object).isRequired,
  startDay: PropTypes.number,
  endDay: PropTypes.number,
};

DayHeader.defaultProps = {
  startDay: 1,
  endDay: 31,
};

/**
 * SchedulerList()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function SchedulerList(props) {
  const {
    calendarData, onMonthChange, currentMonth, onSelectDay, showSunday, RowComponent, users,
    monthSelectorLeftActions, monthSelectorRightActions,
  } = props;
  const tableRef = useRef();
  const [containerWidth, setContainerWidth] = useState(0);
  const [maxDaysInMonth, setMaxDaysInMonth] = useState(moment(currentMonth).daysInMonth());

  useEffect(() => {
    setMaxDaysInMonth(moment(currentMonth).daysInMonth());
  }, [currentMonth]);

  useLayoutEffect(() => {
    if (tableRef.current) {
      setContainerWidth(tableRef.current.clientWidth);
    }
  }, [tableRef]);

  const handleResize = () => {
    if (tableRef.current) {
      setContainerWidth(tableRef.current.clientWidth);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderHorizontal = () => {
    if (containerWidth >= 1300) {
      return (
        <table className="table table-bordered table-striped">
          <DayHeader showSunday={showSunday} currentMonth={currentMonth} endDay={maxDaysInMonth} />
          <tbody>
            {users.map((item) => (
              <RowComponent
                item={item}
                key={Math.random()}
                onPress={onSelectDay}
                {...props}
                endDay={maxDaysInMonth}
              />
            ))}
          </tbody>
        </table>
      );
    }
    return null;
  };

  const renderVertical = () => {
    if (containerWidth < 1300) {
      return (
        <>
          <h6 className="hk-text-primary">
            1. Monatshälfte
          </h6>
          <table className="table table-bordered table-striped">
            <DayHeader showSunday={showSunday} currentMonth={currentMonth} startDay={1} endDay={15} />
            <tbody>
              {users.map((item) => (
                <RowComponent
                  item={item}
                  key={Math.random()}
                  onPress={onSelectDay}
                  {...props}
                  startDay={1}
                  endDay={15}
                />
              ))}
            </tbody>
          </table>

          <h6 style={{ marginTop: 25 }} className="hk-text-primary">
            2. Monatshälfte
          </h6>
          <table className="table table-bordered table-striped">
            <DayHeader showSunday={showSunday} currentMonth={currentMonth} startDay={16} endDay={maxDaysInMonth} />
            <tbody>
              {users.map((item) => (
                <RowComponent
                  item={item}
                  key={Math.random()}
                  onPress={onSelectDay}
                  {...props}
                  startDay={16}
                  endDay={maxDaysInMonth}
                />
              ))}
            </tbody>
          </table>
        </>
      );
    }
    return null;
  };

  return (
    <div className="SchedulerList" ref={tableRef}>
      <MonthSelector
        currentMonth={currentMonth}
        onNext={onMonthChange}
        onPrev={onMonthChange}
        leftContainer={monthSelectorLeftActions}
        rightContainer={monthSelectorRightActions}
      />
      <hr />
      {renderHorizontal()}
      {renderVertical()}
    </div>
  );
}

SchedulerList.propTypes = {
  calendarData: PropTypes.instanceOf(Object),
  onMonthChange: PropTypes.func.isRequired,
  currentMonth: PropTypes.instanceOf(Object).isRequired,
  onSelectDay: PropTypes.func.isRequired,
  showSunday: PropTypes.bool,
  RowComponent: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Array).isRequired,
  monthSelectorLeftActions: PropTypes.instanceOf(Object),
  monthSelectorRightActions: PropTypes.instanceOf(Object),
};

SchedulerList.defaultProps = {
  calendarData: {},
  showSunday: false,
  monthSelectorLeftActions: null,
  monthSelectorRightActions: null,
};
