import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import Panel from '../../../Components/Panel';
import { get } from '../../../Redux/Action/OrderAction';
import { ORDERTYPE_SINGLE } from '../../../Library/Types';
import NoteView from '../../../Components/NotesView/NotesView';
import { createNote, deleteNote } from '../../../Redux/Action/ObjectAction';
import { useAccessCanUseInternalNotes } from '../../../Library/AccessChecks';
import { useDispatchGetObjectNotes } from '../../../Redux/Action/Order/Object/NoteActions';

/**
 * Notes()
 * @returns {*}
 * @constructor
 */
function Notes(props) {
  const {
    order, dispatchGetOrder, dispatchCreateNote, user, dispatchDeleteNote, history,
  } = props;
  const isAllowed = useAccessCanUseInternalNotes();
  const [object, setObject] = React.useState();
  const [notes, setNotes] = React.useState();
  const { orderId, objectId } = useParams();

  const dispatchGetObjectNotes = useDispatchGetObjectNotes(orderId, objectId);

  const handleUpdateNotes = React.useCallback(() => {
    dispatchGetObjectNotes().then((data) => {
      setNotes(data);
    });
  }, [dispatchGetObjectNotes, setNotes]);

  React.useEffect(() => {
    if (!isAllowed) {
      history.push('/dashboard');
    }
  }, [isAllowed, history]);

  React.useEffect(() => {
    dispatchGetOrder(orderId);
  }, [dispatchGetOrder, orderId]);

  React.useEffect(() => {
    if (order) {
      if (order.type === ORDERTYPE_SINGLE && !objectId) {
        setObject(order.objects[0]);
      } else {
        const data = order.objects.filter((item) => item.objectId === parseInt(objectId, 0))[0];
        setObject(data);
      }
    }
  }, [order, objectId]);

  React.useEffect(handleUpdateNotes, [handleUpdateNotes]);

  if (order && object) {
    return (
      <div>
        <Panel marginBottom={25}>
          <h4 className="hk-text-primary">Interne Notizen</h4>
          <p style={{ marginBottom: 0 }}>
            Hier können Sie internen Notizen zu dem Auftrag speichern und ablegen. Diese Notizen sind nur für Sie und
            Ihre Kollegen in Ihrem eigenen Unternehmen sichtbar.
          </p>
        </Panel>

        <NoteView
          notes={(notes) || null}
          onAdd={(note) => dispatchCreateNote(object, note).then(handleUpdateNotes)}
          onDelete={(note) => dispatchDeleteNote(object, note).then(handleUpdateNotes)}
          user={user}
        />
      </div>
    );
  }
  return null;
}

Notes.propTypes = {
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchCreateNote: PropTypes.func.isRequired,
  dispatchDeleteNote: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

Notes.defaultProps = {
  order: null,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
    user: store.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetOrder: (orderId) => dispatch(get(orderId)),
    dispatchCreateNote: (object, note) => dispatch(createNote(object, note)),
    dispatchDeleteNote: (object, note) => dispatch(deleteNote(object, note)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Notes));
