import {
  CALENDAR_GETLIST_START,
  CALENDAR_GETLIST_SUCCESS,
  CALENDAR_GETLIST_ERROR,
  CALENDAR_GETTODAY_START,
  CALENDAR_GETTODAY_ERROR,
  CALENDAR_GETTODAY_SUCCESS,
  CALENDAR_GETORDERSREVICEDATA_START,
  CALENDAR_GETORDERSREVICEDATA_SUCCESS,
  CALENDAR_GETORDERSREVICEDATA_ERROR,
} from '../Action/CalendarAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  data: null,
  today: null,
  isLoading: false,
  orderServiceData: [],
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /** GET LIST */
    case CALENDAR_GETLIST_START: {
      return { ...state, isLoading: true };
    }
    case CALENDAR_GETLIST_SUCCESS: {
      const { payload } = action;
      return {
        ...state, isLoading: false, data: { ...state.data, ...payload },
      };
    }
    case CALENDAR_GETLIST_ERROR: {
      return {
        ...state, isLoading: false, data: null,
      };
    }

    /** GET TODAY */
    case CALENDAR_GETTODAY_START: {
      return { ...state, isLoading: true };
    }
    case CALENDAR_GETTODAY_SUCCESS: {
      const { payload } = action;
      if (payload) {
        return {
          ...state, isLoading: false, today: payload[0],
        };
      }
      return {
        ...state, isLoading: false, today: null,
      };
    }
    case CALENDAR_GETTODAY_ERROR: {
      return {
        ...state, isLoading: false, data: null,
      };
    }

    /** GET OrderServiceData */
    case CALENDAR_GETORDERSREVICEDATA_START: {
      return { ...state, isLoading: true };
    }
    case CALENDAR_GETORDERSREVICEDATA_SUCCESS: {
      const { payload } = action;
      if (payload) {
        return {
          ...state, isLoading: false, orderServiceData: payload,
        };
      }
      return {
        ...state, isLoading: false, orderServiceData: [],
      };
    }
    case CALENDAR_GETORDERSREVICEDATA_ERROR: {
      return {
        ...state, isLoading: false, orderServiceData: [],
      };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
