import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import Panel from '../../../../Components/Panel';
import { getList } from '../../Redux/Action/OrderLogAction';
import { formatDateTime } from '../../../../Library/Functions';
import Button from '../../../../Components/Button';
import LoadingModal from '../../../../Components/LoadingModal';

/**
 * History()
 * @param props
 * @returns {*}
 * @constructor
 */
function History(props) {
  const { dispatchGetList } = props;
  const { orderId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [showDiff, setShowDiff] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);
    dispatchGetList(orderId).then((response) => {
      setData(response);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [orderId, dispatchGetList]);

  const handleClickEntry = (item) => {
    setSelected(item);
    setShowDiff(true);
  };

  const handleCloseDiff = () => {
    setSelected(null);
    setShowDiff(false);
  };

  const renderDiff = () => {
    if (selected && showDiff) {
      return (
        <>
          <div>
            <Button onClick={handleCloseDiff}>
              <i className="fas fa-angle-double-left" />
              Zurück
            </Button>
          </div>
          <div>
            <hr />
          </div>
          <ReactDiffViewer
            oldValue={JSON.stringify(JSON.parse(selected.oldData), null, 2)}
            newValue={JSON.stringify(JSON.parse(selected.newData), null, 2)}
            splitView
          />
        </>
      );
    }
    return null;
  };

  const renderTable = () => {
    if (!showDiff) {
      if (data && data.length > 0) {
        return (
          <table className="table table-striped table-hover">
            <tbody>
              {data.map((item) => (
                <tr onClick={() => handleClickEntry(item)} key={item.id}>
                  <td width={50} align="center">{item.id}</td>
                  <td>{formatDateTime(item.createdDate)}</td>
                  <td align="right">{item.createdName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      return (
        <div className="alert alert-info">
          Keine Einträge vorhanden!
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Panel>
        <h4>Änderungshistorie</h4>
        <hr />
        {renderTable()}
        {renderDiff()}
      </Panel>

      <LoadingModal visible={loading} text="Historie wird geladen..." />
    </div>
  );
}

History.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
};

History.defaultProps = {

};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: (orderId) => dispatch(getList(orderId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(History));
