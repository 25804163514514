import PropTypes from 'prop-types/prop-types';
import React from 'react';
import * as Redux from 'react-redux';
import { formatDate } from '../../../../../Library/Functions';
import ReclamationStatusBadge from '../../../../../Components/ReclamationComponents/ReclamationStatusBadge';

/**
 * ReclamationRow(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReclamationRow(props) {
  const { reclamationRow, onClick, isInternal } = props;
  const { clients } = Redux.useSelector((state) => state.systemClient);

  const getClient = () => {
    if (reclamationRow.ownerName) {
      if (reclamationRow.ownerName.length > 40) {
        return `${reclamationRow.ownerName.substring(0, 40)}...`;
      }
      return reclamationRow.ownerName;
    }
    if (reclamationRow.clientId) {
      if (clients && clients.length > 0) {
        const foundClient = clients.find((client) => client.clientId === reclamationRow.clientId);
        if (foundClient && foundClient.name) {
          return (
            foundClient.name
          );
        }
      }
      return reclamationRow.clientId;
    }
    return null;
  };

  const getExternalId = () => {
    if (reclamationRow.title && reclamationRow.title.length > 20) {
      return `${reclamationRow.title.substring(0, 20)}...`;
    }
    return reclamationRow.title;
  };

  const renderAssignee = () => {
    if (!isInternal) {
      if (reclamationRow.assigneeName) {
        return (
          <td>{reclamationRow.assigneeName}</td>
        );
      }
      if (reclamationRow.assignedClientId) {
        if (clients && clients.length > 0) {
          const foundClient = clients.find((client) => client.clientId === reclamationRow.assignedClientId);
          if (foundClient && foundClient.name) {
            return (
              <td>{foundClient.name}</td>
            );
          }
        }
        return (
          <td>{reclamationRow.assignedClientId}</td>
        );
      }
      return <td />;
    }
    return null;
  };

  return (
    <tr onClick={() => onClick(reclamationRow)}>
      <td>{reclamationRow.reclamationId}</td>
      <td>{getExternalId()}</td>
      <td>{getClient()}</td>
      <td style={{ whiteSpace: 'nowrap' }}>
        {(reclamationRow.executionDate) ? formatDate(reclamationRow.executionDate) : ''}
      </td>
      {renderAssignee()}
      <td>
        <ReclamationStatusBadge
          reclamation={reclamationRow}
          alignContainer="flex-end"
          useMediaQuery
        />
      </td>
    </tr>
  );
}

ReclamationRow.defaultProps = {
  onClick: () => {},
};

ReclamationRow.propTypes = {
  reclamationRow: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  isInternal: PropTypes.bool.isRequired,
};
