import React from 'react';
import PropTypes from 'prop-types';
import {
  Route, withRouter, Redirect, useRouteMatch, Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import SystemDefaultRoutes from './SystemDefaultRoutes';
import SystemClientDetailsRoutes from './SystemClientDetailsRoutes';
import SystemInvoiceRoutes from './SystemInvoiceRoutes';
import SystemStatisticRoutes from './SystemStatisticRoutes';
import SystemOrderRoutes from './SystemOrderRoutes';
import SystemServiceRoutes from './SystemServiceRoutes';
import Reclamation from '../../Pages/System/Order/Object/Reclamation/Reclamation';
import RightNavigationLayout from '../../Components/Layout/RightNavigationLayout';
import SystemOrderNavigation from '../../Components/Navigation/SystemOrderNavigation';

/**
 * SystemRouter()
 * @param props
 * @returns {*}
 * @constructor
 */
function SystemRouter(props) {
  const { isLoggedIn, user } = props;
  const { path } = useRouteMatch();

  if (isLoggedIn && user.isSystemAdmin) {
    return (
      <Switch>
        <Route path={`${path}/order`}><SystemOrderRoutes /></Route>
        <Route path={`${path}/service`}><SystemServiceRoutes /></Route>
        <Route path={`${path}/reclamation`}>
          <RightNavigationLayout navigation={<SystemOrderNavigation />}>
            <Reclamation />
          </RightNavigationLayout>
        </Route>
        <Route path={`${path}/client/:clientId`}><SystemClientDetailsRoutes /></Route>
        <Route path={`${path}/invoices`}><SystemInvoiceRoutes /></Route>
        <Route path={`${path}/statistics`}><SystemStatisticRoutes /></Route>
        <Route path={`${path}`}><SystemDefaultRoutes /></Route>
      </Switch>
    );
  }
  return <Route><Redirect to="/accessdenied" /></Route>;
}

SystemRouter.propTypes = {
  isLoggedIn: PropTypes.bool,
  user: PropTypes.instanceOf(Object).isRequired,
};

SystemRouter.defaultProps = {
  isLoggedIn: null,
};

function mapStoreToProps(store) {
  return {
    isLoggedIn: store.auth.isLoggedin,
    user: store.auth.user,
  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(SystemRouter));
