import React from 'react';
import {
  Route, Redirect, Switch, useRouteMatch,
} from 'react-router-dom';
import * as Redux from 'react-redux';
import RightNavigationLayout from '../Components/Layout/RightNavigationLayout';
import SupportNavigation from '../Components/Navigation/SupportNavigation/SupportNavigation';
import Material from '../Pages/Support/Material/Material';
import Videos from '../Pages/Support/Videos/Videos';
import Feedback from '../Pages/Support/Feedback/Feedback';

/**
 * SupportRoute()
 * @returns {*}
 * @constructor
 */
export default function SupportRoute() {
  const { path } = useRouteMatch();
  const isLoggedIn = Redux.useSelector((state) => state.auth.isLoggedin);

  if (isLoggedIn) {
    return (
      <RightNavigationLayout navigation={<SupportNavigation />}>
        <Switch>
          <Route exact path={`${path}/videos`}><Videos /></Route>
          <Route exact path={`${path}/material`}><Material /></Route>
          <Route exact path={`${path}/feedback`}><Feedback /></Route>
          <Redirect to={`${path}/videos`} />
        </Switch>
      </RightNavigationLayout>
    );
  }
  return <Redirect to="/login" />;
}
