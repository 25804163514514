import * as Redux from 'react-redux';
import React from 'react';
import axios from '../../../../Library/Axios';

export const defaultUri = '/order/{orderId}/object/{objectId}/customer';

/**
 * Prepare default uri and replace all placeholders
 * @param object
 * @returns {string}
 */
function prepareUrl(object) {
  return defaultUri.replace('{orderId}', object.orderId)
    .replace('{objectId}', object.objectId);
}

export const OBJECT_CUSTOMER_UPDATE_START = 'OBJECT_CUSTOMER_UPDATE_START';
export const OBJECT_CUSTOMER_UPDATE_SUCCESS = 'OBJECT_CUSTOMER_UPDATE_SUCCESS';
export const OBJECT_CUSTOMER_UPDATE_ERROR = 'OBJECT_CUSTOMER_UPDATE_ERROR';

/**
 * useDispatchCustomerUpdate()
 * @returns {function(*=, *=): *}
 */
export const useDispatchCustomerUpdate = () => {
  const dispatch = Redux.useDispatch();

  return React.useCallback((object, customerData) => {
    const uri = `${prepareUrl(object)}/${customerData.customerId}`;

    dispatch({ type: OBJECT_CUSTOMER_UPDATE_START, payload: customerData });

    return axios().put(uri, customerData).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_CUSTOMER_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_CUSTOMER_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
};
