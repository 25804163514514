import React from 'react';
import PropTypes from 'prop-types';

/**
 * UserTable()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Checkbox(props) {
  const {
    label, value, onChange, hasError, children, disabled,
  } = props;

  const handleChange = () => {
    if (!disabled) {
      onChange(!value);
    }
  };

  const getLabelStyle = () => {
    if (disabled) {
      return { textDecoration: 'line-through' };
    }
    return { cursor: 'pointer' };
  };

  return (
    <div
      className="form-group form-check"
      onClick={() => handleChange()}
      tabIndex={-1}
      role="button"
      onKeyPress={() => {}}
    >
      <input
        type="checkbox"
        className="form-check-input"
        checked={(value) ? 'checked' : ''}
        onChange={() => {}}
        disabled={disabled}
      />
      <label className={`form-check-label ${(hasError) ? 'text-danger' : ''}`} style={getLabelStyle()}>
        {children || label}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: 'Missing Label or Children',
  value: false,
  children: null,
  hasError: false,
  disabled: false,
};
