/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Document from '../../../Components/ClientComponents/Document';
import {
  CLIENT_DOCUMENTSTYPE_EXEMPTIONCERTIFICATE,
  CLIENTTYPE_DEALER, CLIENTTYPE_MONTEUR,
} from '../../../Library/Types';
import { get } from '../../../Redux/Action/ClientAction';
import Panel from '../../../Components/Panel';

/**
 * Document()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function Documents(props) {
  const { currentClient, dispatchGetClient } = props;

  const handleOnChange = () => {
    dispatchGetClient(currentClient.clientId);
  };

  if (currentClient) {
    return (
      <>
        <Panel marginBottom={25}>
          <h4>
            Dokumente
          </h4>
          <p style={{ marginBottom: 0 }}>
            Alle Ihren Dokumente für die Nutzung von Hey.Kitchen
          </p>
        </Panel>

        <div className="row">
          <Document
            caption="Firmenlogo"
            type="logo"
            mandatory={(currentClient.type === CLIENTTYPE_DEALER)}
            client={currentClient}
            onChange={handleOnChange}
          />
          <Document
            caption="Versicherungsnachweis"
            type="insurance"
            mandatory={(currentClient.type === CLIENTTYPE_MONTEUR)}
            client={currentClient}
            onChange={handleOnChange}
            visible={(currentClient.type === CLIENTTYPE_MONTEUR)}
          />
          <Document
            caption="Zertifikat Elektroinstalltion"
            type="electriccertificate"
            monteurImportant={(currentClient.type === CLIENTTYPE_MONTEUR)}
            client={currentClient}
            onChange={handleOnChange}
            visible={(currentClient.type === CLIENTTYPE_MONTEUR)}
          />
          <Document
            caption="Zertifikat Wasserinstallation"
            type="watercertificate"
            monteurImportant={(currentClient.type === CLIENTTYPE_MONTEUR)}
            client={currentClient}
            onChange={handleOnChange}
            visible={(currentClient.type === CLIENTTYPE_MONTEUR)}
          />
          <Document
            caption="Handelsregisterauszug"
            type="registrationcertificate"
            mandatory={(currentClient.legalForm > 1)}
            client={currentClient}
            onChange={handleOnChange}
          />
          <Document
            caption="Gewerbeschein"
            type="businessregistration"
            mandatory={(currentClient.legalForm === 1)}
            client={currentClient}
            onChange={handleOnChange}
          />
          <Document
            caption="Freistellungsbescheinigung"
            type={CLIENT_DOCUMENTSTYPE_EXEMPTIONCERTIFICATE}
            client={currentClient}
            onChange={handleOnChange}
            visible={(currentClient.type === CLIENTTYPE_DEALER)}
          />
        </div>
      </>
    );
  }
  return null;
}

function mapStoreToProps(store) {
  return {
    currentClient: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetClient: (clientId) => dispatch(get(clientId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Documents));
