import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import WorkLoadField from './WorkLoadField';

export const Container = styled.div`
  min-width: 100px;
  max-width: 100px;
  flex-grow: 1;
`;

export const ContentRow = styled.div`
  border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: ${(props) => props.theme.height}px;
  
  &.TimeCaption {
    height: ${(props) => props.theme.cellHeadlineHeight}px;
    justify-content: center;
  }

  &.HeadlineWeekDays {
    background-color: ${(props) => props.theme.color.backgroundGrayLight};
    height: ${(props) => props.theme.cellHeadlineHeight}px;
    justify-content: flex-start;
    font-family: "DIN Next LT W01 Bold", sans-serif;
    font-size: 20px;
    padding: 6px 15px;
  }
  
  &.GroupSeparator {
    padding-left: 15px;
    display: flex;
    align-items: center;
    height: ${(props) => props.theme.cellSeparatorHeight}px;
    font-size: ${(props) => props.theme.cellSeparatorFontSize}px;
    border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
    border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.backgroundGrayLight};
    background-color: ${(props) => props.theme.color.backgroundGrayLight};
    width: auto;
    
    >i {
      margin-right: 10px;
    }
  }
`;

/**
 * WorkLoadContainer()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function WorkLoadContainer(props) {
  const {
    data, viewType, startDate, endDate,
  } = props;
  const theme = useTheme();

  const renderNames = () => {
    const headerRows = [];

    if (viewType === 'week') {
      headerRows.push(
        <ContentRow key="weekHeadline" className="HeadlineWeekDays" />,
      );
    }

    headerRows.push((
      <ContentRow key="timeline" className="TimeCaption" title="Auslastung">
        <i className="fas fa-tachometer-alt" style={{ fontSize: 26 }} />
      </ContentRow>
    ));

    const mappedData = data.map((separator) => {
      const height = separator.rowHeight || theme.cellHeight;

      const sep = (
        <ContentRow theme={{ ...theme, rightBorder: true }} key="Separator" className="GroupSeparator" />
      );
      const result = separator.data.map((item) => (
        <WorkLoadField
          item={item}
          key={`${item.lastname}${item.firstname}`}
          height={height}
          startDate={startDate}
          endDate={endDate}
        />
      ));

      return [sep, ...result];
    });

    return [...headerRows, ...mappedData];
  };

  return (
    <Container>
      {renderNames()}
    </Container>
  );
}

WorkLoadContainer.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  viewType: PropTypes.oneOf(['day', 'week']),
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
};

WorkLoadContainer.defaultProps = {
  viewType: 'day',
  endDate: null,
};
