import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatDate, getOrderIcon } from '../../../../Library/Functions';
import { ORDERTYPE_OBJECT } from '../../../../Library/Types';
import { OBJECT_DATESTATE_OPEN } from '../../../../Library/StateTypes';
import OptionIcons from '../../../../Components/ObjectComponents/OptionIcons';
import { useGetWorkTimeString } from '../../../../Library/HelperHooks';

/**
 * DefaultRow()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function DefaultRow(props) {
  const {
    order, onClick, state, isIntern, client,
  } = props;
  const getWorkTimeString = useGetWorkTimeString();

  const calcDeliveryDays = (date) => {
    const deliverDate = moment(date, 'YYYY-MM-DD').endOf('day');
    const days = deliverDate.diff(moment(new Date()).endOf('day'), 'days');
    if (days === 0) {
      return 'Heute';
    }
    return `${days} Tage`;
  };

  const renderDate = () => {
    if (order.date) {
      return `${formatDate(order.date, 'DD.MM.YYYY')} (${calcDeliveryDays(order.date)})`;
    }
    if (order.deliveryDateStart) {
      return `${formatDate(order.deliveryDateStart, 'DD.MM.YYYY')} (${calcDeliveryDays(order.deliveryDateStart)})`;
    }
    return '';
  };

  const getExternalId = () => {
    if (order.type === ORDERTYPE_OBJECT) {
      const orderId = (order.orderExternalId) || order.orderId;
      return `(${orderId}) - ${order.objectExternalId}`;
    }
    return order.objectExternalId;
  };

  const renderDeliveryDate = () => {
    if (state !== OBJECT_DATESTATE_OPEN) {
      return <td>{renderDate()}</td>;
    }
    return null;
  };

  const renderAddress = () => {
    const address = `${order.zip} ${order.city}`;
    return (<td>{address}</td>);
  };

  const renderAssemblyTime = () => {
    if (isIntern) {
      return <td className="text-right">{getWorkTimeString(order.internal, order.executionTime)}</td>;
    }
    return null;
  };

  return (
    <tr onClick={() => onClick(order)}>
      <td>{getOrderIcon(order, client)}</td>
      {renderDeliveryDate()}
      <td>{getExternalId()}</td>
      {renderAddress()}
      <td className="text-center">
        <OptionIcons order={order} />
      </td>
      {renderAssemblyTime()}
    </tr>
  );
}

DefaultRow.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  state: PropTypes.number.isRequired,
  isIntern: PropTypes.bool.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};
