import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ModalDialog from '../../../Components/ModalDialog';
import { formatCurrency } from '../../../Library/Functions';
import StatusBadge from '../../../Components/StatusBadge';
import Button from '../../../Components/Button';
import { get, update } from '../Redux/Action/InvoiceAction';

/**
 * PositionRow()
 * @param props
 * @returns {*}
 * @constructor
 */
function PositionRow(props) {
  const { position, index } = props;

  return (
    <tr>
      <td style={{ textAlign: 'center' }}>{index + 1}</td>
      <td>
        <div>{position.caption}</div>
        <div className="text-muted">{position.description}</div>
      </td>
      <td style={{ textAlign: 'right' }}>
        {formatCurrency(position.priceNetto)}
      </td>
    </tr>
  );
}

PositionRow.propTypes = {
  position: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
};

/**
 * MarkPayedButton()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function MarkPayedButton(props) {
  const { onClick, invoice } = props;

  if (invoice && !invoice.processed) {
    return (
      <Button onClick={() => onClick(invoice)} type="btn-success">
        <i className="fas fa-check-circle" style={{ marginRight: 10 }} />
        Als bezahlt markieren
      </Button>
    );
  }
  return null;
}

MarkPayedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  invoice: PropTypes.instanceOf(Object),
};

MarkPayedButton.defaultProps = {
  invoice: null,
};

/**
 * DetailsModal()
 * @param props
 * @returns {*}
 * @constructor
 */
function DetailsModal(props) {
  const {
    onClose, dispatchGet, dispatchUpdate, transactionId, onChange,
  } = props;
  const [invoice, setInvoice] = React.useState(null);

  React.useEffect(() => {
    if (transactionId) {
      dispatchGet(transactionId).then((response) => {
        setInvoice(response);
      });
    }
  }, [transactionId, dispatchGet]);

  const renderStatus = () => {
    if (!invoice.processed) {
      return <StatusBadge icon="fas fa-times-circle" caption="Offen" type="Danger" />;
    }
    if (invoice.isVoucher) {
      return <StatusBadge icon="fas fa-check-circle" caption="Überwiesen" type="Success" />;
    }
    return <StatusBadge icon="fas fa-check-circle" caption="Bezahlt" type="Success" />;
  };

  const handleOnClose = () => {
    setInvoice(null);
    onClose();
  };

  const handleMarkPayed = () => {
    const merged = { ...invoice, processed: true };
    dispatchUpdate(merged).then((response) => {
      setInvoice(response);
      onChange();
    });
  };

  if (invoice) {
    return (
      <ModalDialog
        onClose={handleOnClose}
        visible
        hideConfirmButton
        closeCaption="Schließen"
        leftButtonContent={<MarkPayedButton invoice={invoice} onClick={handleMarkPayed} />}
      >
        <h5>Rechnungsdetails</h5>
        <hr />

        <div>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <address>
                <div><strong>{invoice.name}</strong></div>
                <div>{`${invoice.street} ${invoice.streetNo}`}</div>
                <div>{`${invoice.zip} ${invoice.city}`}</div>
              </address>
              <div>
                {`Rechnungsnummer: ${invoice.invoiceNumber}`}
              </div>
              <div>
                {`Leistungszeitraum ${moment(invoice.performanceDate).format('DD.MM.YYYY')}`}
              </div>
            </div>
            <div>
              {renderStatus()}
            </div>
          </div>

          <table className="table table-bordered" style={{ marginTop: 30 }}>
            <thead>
              <tr>
                <th width={80}>Pos.</th>
                <th>Text</th>
                <th width={150} style={{ textAlign: 'right' }}>Preis</th>
              </tr>
            </thead>
            <tbody>
              {invoice.positions.map((item, index) => (
                <PositionRow position={item} index={index} key={item.positionId} />
              ))}
              <tr style={{ fontSize: 13 }}>
                <td colSpan={2}>Gesamtpreis netto</td>
                <td style={{ textAlign: 'right' }}>
                  {formatCurrency(invoice.priceNetto)}
                </td>
              </tr>
              <tr style={{ fontSize: 13 }}>
                <td colSpan={2}>zzgl. 19,00% Umsatzsteuer</td>
                <td style={{ textAlign: 'right' }}>
                  {formatCurrency(invoice.priceBrutto - invoice.priceNetto)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}><strong>Gesamtbetrag</strong></td>
                <td style={{ textAlign: 'right' }}>
                  <strong>{formatCurrency(invoice.priceBrutto)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalDialog>
    );
  }
  return null;
}

DetailsModal.propTypes = {
  dispatchUpdate: PropTypes.func.isRequired,
  dispatchGet: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  transactionId: PropTypes.number,
};

DetailsModal.defaultProps = {
  transactionId: null,
};

function mapStoreToProps(store) {
  return {
    invoices: store.systemInvoice.invoices,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdate: (invoice) => dispatch(update(invoice)),
    dispatchGet: (id) => dispatch(get(id)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(DetailsModal));
