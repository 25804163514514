import React from 'react';
import PropTypes from 'prop-types';
import {
  NavLink, useRouteMatch, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import Panel from '../../Panel';
import OrderStatusBadge from '../../OrderComponents/OrderStatusBadge';
import StatusBadge from '../../StatusBadge';
import './index.css';

/**
 * OrderDetailsRightNavigation()
 * @returns {*|null}
 * @constructor
 */
function OrderDetailsRightNavigation(props) {
  const {
    order,
  } = props;
  const { url } = useRouteMatch();

  const renderStatusIcon = () => {
    if (order.internal) {
      return (
        <StatusBadge
          type="Default"
          containerStyle={{ marginBottom: 10 }}
          icon="fas fa-home"
        >
          Montage: Intern
        </StatusBadge>
      );
    }
    return (
      <StatusBadge
        type="Default"
        containerStyle={{ marginBottom: 10 }}
        icon="fas fa-sign-out-alt"
      >
        Montage: Extern
      </StatusBadge>
    );
  };

  if (order) {
    return (
      <>
        <Panel boxPadding={10} marginBottom={20}>
          <div className="Navigation">
            <div className="Header">
              <h6 className="Headline">
                Auftragsübersicht
              </h6>

              {renderStatusIcon()}
              <OrderStatusBadge orderState={order.state} />
            </div>

            <hr />

            <div className="NavContainer">
              <ul>
                <li>
                  <NavLink className="nav-link" to={`${url}`}>
                    <i className="fas fa-bars" />
                    Übersicht
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

        </Panel>
      </>
    );
  }
  return null;
}

OrderDetailsRightNavigation.propTypes = {
  order: PropTypes.instanceOf(Object),
};

OrderDetailsRightNavigation.defaultProps = {
  order: null,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(OrderDetailsRightNavigation));
