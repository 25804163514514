import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Panel from '../../Components/Panel';
import DefaultItem from './Items/DefaultItem';

/**
 * Notifications()
 * @returns {*}
 * @constructor
 */
export default function Notifications() {
  const { notifications } = useSelector((state) => state.notification);

  if (notifications && notifications.length > 0) {
    return (
      <>
        <Panel marginBottom={25}>
          <h4 style={{ margin: 0 }} className="hk-text-primary">
            <i className="fas fa-bell" style={{ marginRight: 10 }} />
            Benachrichtigungen
          </h4>
        </Panel>

        <Panel className="NotificationsContainer">
          {notifications.map((item) => <DefaultItem item={item} key={item.notificationId} />)}
        </Panel>
      </>
    );
  }
  return <Redirect to="/dashboard" />;
}
