import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Redux from 'react-redux';
import ObjectPage from '../Component/ObjectPage';
import {
  NAVIGATION_SYSTEM_OBJECT_DETAILS,
  NAVIGATION_SYSTEM_ORDER_DETAILS,
  ORDERTYPE_SINGLE,
} from '../../../Library/Types';
import { useDispatchSystemGetObjectView } from '../Redux/Action/OrderAction';

/**
 * External()
 * @returns {JSX.Element}
 * @constructor
 */
export default function External() {
  const history = useHistory();

  const variant = 'external';
  const dispatchGetObjectView = useDispatchSystemGetObjectView(variant);

  const objects = Redux.useSelector((store) => (store.systemOrder[variant].filteredObjects));
  const lastUpdated = Redux.useSelector((store) => (store.systemOrder[variant].lastUpdated));
  const isLoading = Redux.useSelector((store) => (store.systemOrder[variant].isLoading));

  const getHeadline = () => 'Externe Aufträge';

  const navigateDetails = (order) => {
    if (order.type === ORDERTYPE_SINGLE) {
      const uri = NAVIGATION_SYSTEM_OBJECT_DETAILS.replace('{orderId}', order.orderId)
        .replace('{objectId}', order.objectId);
      history.push(uri);
    } else {
      const uri = NAVIGATION_SYSTEM_ORDER_DETAILS.replace('{orderId}', order.orderId);
      history.push(uri);
    }
  };

  return (
    <ObjectPage
      variant="external"
      objects={objects}
      lastUpdated={lastUpdated}
      isLoading={isLoading}
      getHeadline={getHeadline}
      onClick={navigateDetails}
      dispatchGetObjects={dispatchGetObjectView}
    />
  );
}
