import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { getMinuteDiff } from '../../../../Library/Functions';
import { objectServiceTypeCaptions } from '../../../../Library/Types';

/**
 * ServiceDateInformation()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ServiceDateInformation(props) {
  const { appointment, height } = props;

  const getTypeString = () => objectServiceTypeCaptions[appointment.source.serviceType];
  const getAddressString = () => `${appointment.source.zip} ${appointment.source.city}`;

  const renderAdditionalInformation = () => {
    if (height > 40) {
      return (
        <>
          <div className="SubCaption">
            {appointment.source.objectExternalId}
          </div>
          <div className="Time">
            {`Dauer: ${getMinuteDiff(appointment.start, appointment.end)} / ${getAddressString()}`}
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div className="Caption">
        <div>
          <i
            className="fas fa-sync"
            style={{ marginRight: 10 }}
          />
          <strong>{getTypeString()}</strong>
        </div>
        {renderAdditionalInformation()}
      </div>
    </>
  );
}

ServiceDateInformation.propTypes = {
  appointment: PropTypes.instanceOf(Object).isRequired,
  height: PropTypes.number.isRequired,
};
