import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { applyFilters, updateFilters } from '../../Redux/Action/OrderAction';
import ObjectTypeFilter from './ObjectListFilter/ObjectTypeFilter';
import ObjectStateFilter from './ObjectListFilter/ObjectStateFilter';

/**
 * ObjectListFilter(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ObjectListFilter(props) {
  const {
    objects,
    filters, filteredObjects, isWorking, dispatchUpdateFilters, dispatchApplyFilters,
  } = props;

  const prevFilteredObjects = React.useRef(null);
  React.useEffect(() => {
    prevFilteredObjects.current = filteredObjects;
  }, [filteredObjects]);

  React.useEffect(() => {
    if (objects && filters) {
      dispatchApplyFilters(objects, filters, prevFilteredObjects.current);
    }
  }, [objects, filters, dispatchApplyFilters]);

  const renderOrderTypeFilter = () => {
    if (objects && objects.length > 0) {
      return (
        <>
          <div className="Spacer" />
          <ObjectTypeFilter
            objects={objects}
            onFilterChange={(payload) => { dispatchUpdateFilters(payload); }}
          />
        </>
      );
    }
    return null;
  };

  const renderOrderStateFilter = () => {
    if (objects && objects.length > 0) {
      return (
        <>
          <div className="Spacer" />
          <ObjectStateFilter
            objects={objects}
            onFilterChange={(payload) => {
              dispatchUpdateFilters(payload);
            }}
          />
        </>
      );
    }
    return null;
  };

  const renderWorkingIndicatior = () => {
    if (isWorking) {
      return (
        <div>
          <center>
            <i className="fas fa-spinner fa-spin fa-5x" />
          </center>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="FilterCardContainer">
      <div className="Caption">
        Filter
      </div>
      {renderWorkingIndicatior()}
      {renderOrderTypeFilter()}
      {renderOrderStateFilter()}
    </div>
  );
}

ObjectListFilter.propTypes = {
  objects: PropTypes.instanceOf(Array),
  filters: PropTypes.instanceOf(Object),
  filteredObjects: PropTypes.instanceOf(Array),
  isWorking: PropTypes.bool,
  dispatchUpdateFilters: PropTypes.func.isRequired,
  dispatchApplyFilters: PropTypes.func.isRequired,
};

ObjectListFilter.defaultProps = {
  objects: null,
  filters: {},
  filteredObjects: null,
  isWorking: false,
};

function mapStoreToProps(store, ownProps) {
  const { variant } = ownProps;
  return {
    objects: store.systemOrder[variant].objects,
    filters: store.systemOrder[variant].filters,
    filteredObjects: store.systemOrder[variant].filteredObjects,
    isWorking: store.systemOrder[variant].isWorking,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { variant } = ownProps;
  return {
    dispatchUpdateFilters: (filters) => dispatch(updateFilters(variant, filters)),
    dispatchApplyFilters: (objects, filters, prevFilteredObjects) => dispatch(applyFilters(
      variant, objects, filters, prevFilteredObjects,
    )),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ObjectListFilter));
