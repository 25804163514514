import React from 'react';
import styled from 'styled-components';

const HideIcon = styled.i`
  padding: 12px;
  color: #9f9f9f;
`;

/**
 * BoxLoadingIcon()
 * @returns {null|*}
 * @constructor
 */
export default function BoxLoadingIcon() {
  return (
    <>
      <HideIcon className="fas fa-spin fa-spinner" title="Daten werden geladen..." />
    </>
  );
}
