import React from 'react';
import Panel from '../Components/Panel';

/**
 * NoAccess()
 * @returns {null|*}
 * @constructor
 */
export default function NoAccess() {
  return (
    <div className="row">
      <div className="col-lg-3" />
      <div className="col-lg-6">
        <Panel>
          <h2 className="hk-text-primary">
            <i className="fas fa-ban" style={{ marginRight: 10 }} />
            Zugriff verweigert!
          </h2>
          <p>
            Auf die von Ihnen angeforderte Seite oder den Bereich haben Sie keine Zugriff. Bitte kontaktieren Sie
            den Administrator Ihres Accounts damit er Ihnen ggf. Zugriff auf diesen Bereich von hey.kitchen geben
            kann. Sollten Sie der Administrator Ihres Accounts sein wenden Sie sich bitte an den hey.kitchen Support!
          </p>

          <div className="d-flex justify-content-center" style={{ marginTop: 50 }}>
            <a href="/dashboard" className="btn btn-danger">Zurück zur Startseite</a>
          </div>
        </Panel>
      </div>
      <div className="col-lg-3" />
    </div>
  );
}
