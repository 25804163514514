import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

/**
 * MobileDescriptionRow()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function MobileDescriptionRow(props) {
  const {
    children, icon, headline, align,
  } = props;

  const renderRight = () => {
    if (align === 'right') {
      return (
        <div className="Container Right">
          <div className="Text">
            <h4>{headline}</h4>
            <p>{children}</p>
          </div>
          <div className="Icon">
            <i className={icon} />
          </div>
        </div>
      );
    }
    return null;
  };

  const renderLeft = () => {
    if (align === 'left') {
      return (
        <div className="Container Left">
          <div className="Icon">
            <i className={icon} />
          </div>
          <div className="Text">
            <h4>{headline}</h4>
            <p>{children}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="MobileDescriptionRow">
      {renderRight()}
      {renderLeft()}
    </div>
  );
}

MobileDescriptionRow.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['left', 'right']),
};

MobileDescriptionRow.defaultProps = {
  align: 'right',
};
