import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Button from '../../../Components/Button';
import ShowHideContainer from '../../../Components/ShowHideContainer';

/**
 * Row()
 * @param props
 * @returns {*}
 * @constructor
 */
function Row(props) {
  const {
    item, onClick, clients,
  } = props;

  const getClient = () => {
    if (clients) {
      const client = clients.filter((data) => data.clientId === item.clientId)[0];
      return `${client.name} (${client.clientId})`;
    }
    return '';
  };

  const getSettlementMethode = () => {
    if (clients) {
      const client = clients.filter((data) => data.clientId === item.clientId);
      if (client && client.length > 0) {
        const { settlementMethode } = client[0];
        switch (settlementMethode) {
          case 1:
            return '0.4% Netto';
          default:
            return '10€/Auftrag';
        }
      }
    }
    return '';
  };

  const renderActionRow = () => {
    if (onClick) {
      return (
        <td style={{ textAlign: 'right' }}>
          <Button onClick={() => onClick(item)} className="btn-sm" type="btn-outline-danger">
            <i className="fas fa-dollar-sign" />
          </Button>
        </td>
      );
    }
    return null;
  };

  return (
    <tr onClick={(onClick) ? () => onClick(item) : () => {}}>
      <td>{getClient()}</td>
      <td>{getSettlementMethode()}</td>
      <td style={{ textAlign: 'center' }}>{item.intern}</td>
      <td style={{ textAlign: 'center' }}>{item.partner}</td>
      <td style={{ textAlign: 'center' }}>
        <strong>{item.overall}</strong>
      </td>
      {renderActionRow()}
    </tr>
  );
}

Row.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  clients: PropTypes.instanceOf(Array),
};

Row.defaultProps = {
  clients: null,
  onClick: null,
};

/**
 * ClearingList()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ClearingList(props) {
  const {
    items, onClick, clients,
  } = props;
  const [overall, setOverall] = React.useState(0);

  React.useEffect(() => {
    let all = 0;
    items.forEach((item) => { all += item.overall; });
    setOverall(all);
  }, [items]);

  const renderRows = () => items.map((item) => (
    <Row item={item} key={item.clientId} onClick={onClick} clients={clients} />
  ));

  const renderActionRow = () => {
    if (onClick) {
      return <th />;
    }
    return null;
  };

  const renderHeader = () => (
    <thead>
      <tr>
        <th>Kunde</th>
        <th>Abrechnungs-Methode</th>
        <th style={{ textAlign: 'center' }}>Intern</th>
        <th style={{ textAlign: 'center' }}>Partner</th>
        <th style={{ textAlign: 'center' }}>Gesamt</th>
        {renderActionRow()}
      </tr>
    </thead>
  );

  if (items && items.length > 0) {
    return (
      <>
        <table className="table table-hover table-striped">
          {renderHeader()}
          <tbody>
            {renderRows()}
            <tr>
              <td colSpan={3}>
                <strong>Gesamt (Noch nicht abgerechnet)</strong>
              </td>
              <td style={{ textAlign: 'center' }}><strong>{overall}</strong></td>
              <ShowHideContainer visible={onClick}>
                <td />
              </ShowHideContainer>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
  return null;
}

ClearingList.propTypes = {
  items: PropTypes.instanceOf(Array),
  onClick: PropTypes.func,
  clients: PropTypes.instanceOf(Array),
};

ClearingList.defaultProps = {
  items: null,
  clients: null,
  onClick: null,
};
