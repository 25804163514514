import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useAccessCanScheduleObjects } from '../../../Library/AccessChecks';
import DatePickerInput from '../../Fields/DatePickerInput';

export default function SelectDeliveryRange(props) {
  const {
    state, onChange, headline, isInternal, errors,
  } = props;

  const accessCanScheduleObjects = useAccessCanScheduleObjects();

  const renderDescription = () => (
    <p className="text-muted">
      Bitte geben Sie Ihr gewünschtes Lieferzeitraum an.
    </p>
  );

  const getMinDateStart = () => (isInternal ? moment().toDate() : moment().add(1, 'days').toDate());

  const getMinDateEnd = () => (
    state.deliveryDateStart ? moment(state.deliveryDateStart, 'YYYY-MM-DD').toDate() : getMinDateStart()
  );

  const handleChangeDeliveryDateStart = (deliveryDateStart) => {
    if (onChange) {
      onChange({
        ...state,
        deliveryDateStart,
        deliveryWeekStart: null,
      });
    }
  };

  const handleChangeDeliveryDateEnd = (deliveryDateEnd) => {
    if (onChange) {
      onChange({
        ...state,
        deliveryDateEnd,
        deliveryWeekStart: null,
      });
    }
  };

  const renderDatePickerStart = () => (accessCanScheduleObjects ? (
    <div className="flex-grow-1" style={{ marginRight: '10px' }}>
      <DatePickerInput
        value={state.deliveryDateStart}
        label="Lieferzeitraum von"
        onChange={handleChangeDeliveryDateStart}
        rightIcon="far fa-calendar-alt"
        hasError={errors.deliveryDateStart}
        errorMessage={errors.deliveryDateStartMessage}
        minDate={getMinDateStart()}
      />
    </div>
  ) : null);

  const renderDatePickerEnd = () => (accessCanScheduleObjects ? (
    <div className="flex-grow-1" style={{ marginLeft: '10px' }}>
      <DatePickerInput
        value={state.deliveryDateEnd}
        label="Lieferzeitraum bis"
        onChange={handleChangeDeliveryDateEnd}
        rightIcon="far fa-calendar-alt"
        hasError={errors.deliveryDateEnd}
        errorMessage={errors.deliveryDateEndMessage}
        minDate={getMinDateEnd()}
      />
    </div>
  ) : null);

  return (
    <div>
      <div className="d-flex flex-row">
        <div className="flex-grow-1">
          <h5>{headline}</h5>
          {renderDescription()}
        </div>
      </div>

      <div className="d-flex flex-row">
        {renderDatePickerStart()}
        {renderDatePickerEnd()}
      </div>
    </div>
  );
}

SelectDeliveryRange.propTypes = {
  state: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  headline: PropTypes.string,
  isInternal: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
};

SelectDeliveryRange.defaultProps = {
  state: {
    deliveryDateStart: null,
    deliveryDateEnd: null,
    deliveryWeekStart: null,
  },
  onChange: undefined,
  headline: 'Lieferzeitraum',
  isInternal: false,
  errors: {},
};
