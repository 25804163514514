import * as Redux from 'react-redux';
import React from 'react';
import axios from '../../Library/Axios';

export const defaultUri = '/notification';
export const defaultUriId = `${defaultUri}/{notificationId}`;

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.notificationId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUriId(parameter, uri = defaultUriId) {
  const { notificationId } = parameter;
  return uri.replace('{notificationId}', notificationId);
}

export const NOTIFICATION_GET_START = 'NOTIFICATION_GET_START';
export const NOTIFICATION_GET_SUCCESS = 'NOTIFICATION_GET_SUCCESS';
export const NOTIFICATION_GET_ERROR = 'NOTIFICATION_GET_ERROR';
/**
 * useDispatchGetNotification()
 * @returns {function(*=): *}
 */
export const useDispatchGetNotification = () => {
  const dispatch = Redux.useDispatch();

  return React.useCallback((notificationId) => {
    const uri = prepareUriId({ notificationId });
    dispatch({ type: NOTIFICATION_GET_START, payload: notificationId });

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      if (data) {
        dispatch({ type: NOTIFICATION_GET_SUCCESS, payload: data });
        return Promise.resolve(data);
      }
      dispatch({ type: NOTIFICATION_GET_SUCCESS, payload: {} });
      return Promise.resolve({});
    }).catch((error) => {
      dispatch({ type: NOTIFICATION_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
};

export const NOTIFICATION_GETUNREAD_START = 'NOTIFICATION_GETUNREAD_START';
export const NOTIFICATION_GETUNREAD_SUCCESS = 'NOTIFICATION_GETUNREAD_SUCCESS';
export const NOTIFICATION_GETUNREAD_ERROR = 'NOTIFICATION_GETUNREAD_ERROR';

/**
 * getUnread()
 * @returns {function(*): Promise<unknown>}
 */
export function getUnread() {
  return (dispatch) => {
    dispatch({ type: NOTIFICATION_GETUNREAD_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: NOTIFICATION_GETUNREAD_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: NOTIFICATION_GETUNREAD_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const NOTIFICATION_MARKREAD_START = 'NOTIFICATION_MARKREAD_START';
export const NOTIFICATION_MARKREAD_SUCCESS = 'NOTIFICATION_MARKREAD_SUCCESS';
export const NOTIFICATION_MARKREAD_ERROR = 'NOTIFICATION_MARKREAD_ERROR';

/**
 * markRead()
 * @returns {function(*): Promise<unknown>}
 */
export function markRead(ids) {
  return (dispatch) => {
    dispatch({ type: NOTIFICATION_MARKREAD_START });

    return axios().put(defaultUri, ids).then((response) => {
      const { data } = response.data;
      dispatch({ type: NOTIFICATION_MARKREAD_SUCCESS, payload: data });
      dispatch(getUnread());
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: NOTIFICATION_MARKREAD_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const NOTIFICATION_DELETE_START = 'NOTIFICATION_DELETE_START';
export const NOTIFICATION_DELETE_SUCCESS = 'NOTIFICATION_DELETE_SUCCESS';
export const NOTIFICATION_DELETE_ERROR = 'NOTIFICATION_DELETE_ERROR';

/**
 * markRead()
 * @returns {function(*): Promise<unknown>}
 */
export function remove(id) {
  return (dispatch) => {
    dispatch({ type: NOTIFICATION_DELETE_START });

    const uri = `${defaultUri}/${id}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: NOTIFICATION_DELETE_SUCCESS, payload: data });
      dispatch(getUnread());
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: NOTIFICATION_DELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const NOTIFICATION_PARTNER_DATE_CHANGED_START = 'NOTIFICATION_PARTNER_DATE_CHANGED_START';
export const NOTIFICATION_PARTNER_DATE_CHANGED_SUCCESS = 'NOTIFICATION_PARTNER_DATE_CHANGED_SUCCESS';
export const NOTIFICATION_PARTNER_DATE_CHANGED_ERROR = 'NOTIFICATION_PARTNER_DATE_CHANGED_ERROR';

/**
 * Notify the partner of the order that a date has been changed (manually)
 * notifyPartnerOrderDateChanged()
 * @param objectId
 * @param dateType
 * @returns {function(*): *}
 */
export function notifyPartnerOrderDateChanged(objectId, dateType) {
  return (dispatch) => {
    dispatch({ type: NOTIFICATION_PARTNER_DATE_CHANGED_START, payload: { objectId, dateType } });

    const config = {
      params: {
        action: 'notifyDateChanged',
        objectId,
        dateType,
      },
    };

    return axios().post(defaultUri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: NOTIFICATION_PARTNER_DATE_CHANGED_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: NOTIFICATION_PARTNER_DATE_CHANGED_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
