import React, { useEffect } from 'react';
import WebdemoRow from '../Components/WebdemoRow';
import WebdemoContact from '../Components/WebdemoContact';
import Footer from '../Components/Footer';
import WirStellenVor from '../Components/WirStellenVor';
import HowItWorks from '../Components/HowItWorks';
import StartpageHeader from '../Components/StartpageHeader';

const headerHk = require('../../../Assets/Images/header.jpg');

/**
 * Web()
 * @returns {*}
 * @constructor
 */
export default function Web() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-grow-1 flex-column" style={{ margin: -40 }}>
      <StartpageHeader image={headerHk} withText={false} height={600} />

      <WebdemoRow />
      <WirStellenVor />

      <div className="FullPageBox Dark Center">
        <div style={{ marginTop: '-120px' }} id="pricing" />
        <div className="container d-flex flex-column">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <h2 style={{ marginBottom: 0 }}>Wie funktioniert's?</h2>
          <p>
            Seien Sie dem Wettbewerb einen Schritt voraus und investieren Sie nur halb so viel wie Sie Zeit
            und Mitarbeiter sparen.
          </p>
          <p>
            Küchenmontage genial digital!
          </p>
        </div>
      </div>

      <HowItWorks />
      <WebdemoContact />
      <Footer />
    </div>
  );
}
