import {
  SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_ERROR, SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_START,
  SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_SUCCESS, SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_ERROR,
  SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_START, SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_SUCCESS,
} from '../Action/StatisticAction';

const defaultValues = {
  externalOrdersByMonth: null,
  transactionsByMonth: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_START: {
      return { ...state };
    }
    case SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_SUCCESS: {
      return { ...state, externalOrdersByMonth: action.payload };
    }
    case SYSTEM_STATISTIC_EXTERNALORDERSBYMONTH_ERROR: {
      return { ...state, externalOrdersByMonth: null };
    }

    case SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_START: {
      return { ...state };
    }
    case SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_SUCCESS: {
      return { ...state, transactionsByMonth: action.payload };
    }
    case SYSTEM_STATISTIC_TRANSACTIONSBYMONTHBYMONTH_ERROR: {
      return { ...state, transactionsByMonth: null };
    }

    default: return { ...state };
  }
}
