import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Toolipp from 'react-tooltip-lite';
import { upload, remove } from '../../../Redux/Action/ClientDocumentAction';
import ModalDialog from '../../ModalDialog';

import './style.css';

const pdfImageType = require('../../../Assets/Images/filetype_pdf.png');

const tooltippText = (
  <div>
    Diese Angabe wird benötigt damit Sie Aufträge
    <br />
    erstellen, Montagen buchen oder Aufträge
    <br />
    übernehmen können.
  </div>
);

const tooltippMonteurImportantText = (
  <div>
    Solange Sie diesen Zertifikatsnachweis nicht
    <br />
    bereitgestellt haben, erhalten Sie nur Aufträge
    <br />
    ohne diese Anschlussart!
  </div>
);

/**
 * Document()
 * @param props
 * @returns {*}
 * @constructor
 */
function Document(props) {
  const {
    type, caption, uploadDocument, deleteDocument, mandatory, monteurImportant, client, onChange, visible,
  } = props;
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [askDelete, setAskDelete] = useState(false);
  const fileInputRef = useRef();

  useEffect(() => {
    if (client) {
      const tmp = client.documents.filter((item) => item.type === type);
      if (tmp.length > 0) {
        setDocument(tmp[0]);
      } else {
        setDocument(null);
      }
    }
  }, [client, type]);

  const renderImage = () => {
    if (isLoading) {
      return (
        <i className="fas fa-spin fa-spinner" style={{ color: '#000' }} />
      );
    }

    if (!document) {
      return (
        <i className="fas fa-plus-circle" />
      );
    }

    if (document.extension === 'pdf') {
      return (
        <img src={pdfImageType} alt={document.filename} />
      );
    }

    return (
      <img src={document.absolutePath} alt={document.filename} />
    );
  };

  const handleDelete = () => {
    deleteDocument(document.documentId, client.clientId)
      .then(() => {
        setAskDelete(false);
        onChange();
      })
      .catch(() => setAskDelete(false));
  };

  const handleDownload = () => {
    window.open(document.absolutePath);
  };

  const renderOptions = () => {
    if (document) {
      return (
        <div className="Options">
          <i
            className="fas fa-download"
            onClick={() => handleDownload()}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
          />
          <i
            className="fas fa-times"
            onClick={() => setAskDelete(true)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
          />
        </div>
      );
    }
    return null;
  };

  const handleOnChange = (event) => {
    setIsLoading(true);
    uploadDocument(event.target.files[0], type, client.clientId).then(() => {
      setIsLoading(false);
      onChange();
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const renderMonteurImportantHint = () => {
    if (monteurImportant && !document && !mandatory) {
      return (
        <Toolipp
          className="fas Small"
          content={tooltippMonteurImportantText}
          useDefaultStyles
          direction="down"
          styles={{ position: 'absolute', right: 4, top: 20 }}
        >
          <i className="fas fa-exclamation-circle text-warning" style={{ marginRight: 20, fontSize: 20 }} />
        </Toolipp>
      );
    }
    return null;
  };

  const renderMandatoryHint = () => {
    if (mandatory && !document) {
      return (
        <Toolipp
          className="fas Small"
          content={tooltippText}
          useDefaultStyles
          direction="down"
          styles={{ position: 'absolute', right: 4, top: 20 }}
        >
          <i className="fas fa-exclamation-circle hk-text-primary" style={{ marginRight: 20, fontSize: 20 }} />
        </Toolipp>
      );
    }
    return null;
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="Document col-lg-3 col-md-4 col-sm-6">
      <div className="card">
        <div className="card-body">
          {renderOptions()}
          {renderMandatoryHint()}
          {renderMonteurImportantHint()}
          <div
            className="DocumentImage"
            onClick={() => fileInputRef.current.click()}
            tabIndex={-1}
            onKeyPress={() => {}}
            role="button"
          >
            {renderImage()}
          </div>
          <div className="DocumentFooter">
            <h6>{caption}</h6>
          </div>
        </div>
      </div>

      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(e) => handleOnChange(e)}
      />

      <ModalDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        centered
        confirmCaption="Löschen"
        onConfirm={handleDelete}
      >
        <h5>Dokument löschen?</h5>
        <p>
          Wollen Sie das ausgewählte Dokument wirklich löschen?
        </p>
      </ModalDialog>
    </div>
  );
}

Document.propTypes = {
  type: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  mandatory: PropTypes.bool,
  monteurImportant: PropTypes.bool,
  client: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func,
  visible: PropTypes.bool,
};

Document.defaultProps = {
  mandatory: false,
  monteurImportant: false,
  onChange: () => {},
  visible: true,
};

function mapStoreToProps() {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadDocument: (file, type, clientId) => dispatch(upload(file, type, clientId)),
    deleteDocument: (documentId, clientId) => dispatch(remove(documentId, clientId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Document));
