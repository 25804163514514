import React from 'react';
import PropTypes from 'prop-types';
import ReclamationTableView from './ReclamationTableView';
import Panel from '../../../Components/Panel';
import BoxSettingsIcon from './BoxSettingsIcon';
import BoxLoadingIcon from './BoxLoadingIcon';
import {
  OBJECT_DATESTATE_ASSIGNED,
  OBJECT_DATESTATE_OPEN,
  OBJECT_DATESTATE_SCHEDULED,
  ORDERSTATE_PARTNER_CREATED,
  RECLAMATIONSTATE_ASSIGNED,
  RECLAMATIONSTATE_OPEN,
  RECLAMATIONSTATE_SCHEDULED,
} from '../../../Library/StateTypes';
import {
  SERVICESTATE_ASSIGNED,
  SERVICESTATE_OPEN,
  SERVICESTATE_SCHEDULED,
} from '../../../Library/States/ServiceStates';
import InternalObjectStateBox from './OrderTableBox/InternalObjectStateBox';
import PartnerObjectStateBox from './OrderTableBox/PartnerObjectStateBox';

/**
 * InternalBoxes()
 * @returns {*|null}
 * @constructor
 */
export default function InternalBoxes(props) {
  const {
    reclamations, orders, onOrderClick, onReclamationClick, visible, storageConfig, onHideBox, onRestoreBoxes,
    ordersLoading, reclamationsLoading, client, services,
  } = props;
  const [hasHiddenOrderBoxes, setHasHiddenOrderBoxes] = React.useState(false);
  const [hasHiddenReklaBoxes, setHasHiddenReklaBoxes] = React.useState(false);

  React.useEffect(() => {
    setHasHiddenOrderBoxes(false);
    setHasHiddenReklaBoxes(false);

    if (storageConfig) {
      Object.keys(storageConfig).forEach((item) => {
        if (item.indexOf('orderIntern') > -1) {
          setHasHiddenOrderBoxes(true);
        }
        if (item.indexOf('reclamationIntern') > -1) {
          setHasHiddenReklaBoxes(true);
        }
      });
    }
  }, [storageConfig]);

  const renderOrderSettingsIcon = () => {
    if (ordersLoading) {
      return <BoxLoadingIcon />;
    }

    if (hasHiddenOrderBoxes) {
      return <BoxSettingsIcon onPress={() => onRestoreBoxes('orderIntern')} />;
    }
    return <div style={{ height: 40 }} />;
  };

  const renderReclamationSettingsIcon = () => {
    if (reclamationsLoading) {
      return <BoxLoadingIcon />;
    }

    if (hasHiddenReklaBoxes) {
      return <BoxSettingsIcon onPress={() => onRestoreBoxes('reclamationIntern')} />;
    }
    return <div style={{ height: 40 }} />;
  };

  if (visible) {
    return (
      <div className="row">
        <div className="col-lg-6">
          <Panel marginBottom={25} withPadding={false}>
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center flex-grow-1">
                <h5 style={{ marginBottom: 0 }}>
                  <i className="fas fa-truck" style={{ marginRight: 12 }} />
                  Interne Aufträge
                </h5>
              </div>
              <div>
                {renderOrderSettingsIcon()}
              </div>
            </div>
          </Panel>

          <PartnerObjectStateBox
            orders={orders}
            headline="Partnerauftrag wartet auf Bestätigung"
            subHeadline="Diese Aufträge warten auf Bestätigung zur Übernahme"
            rowCount={50}
            onClick={onOrderClick}
            state={ORDERSTATE_PARTNER_CREATED}
            identifier="orderInternMandant"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
            client={client}
          />

          <InternalObjectStateBox
            orders={orders}
            headline="Offene Aufträge"
            subHeadline="Die zuletzt erstellten internen Aufträge mit dem Status offen"
            rowCount={8}
            onClick={onOrderClick}
            state={OBJECT_DATESTATE_OPEN}
            identifier="orderInternOpen"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
            client={client}
          />

          <InternalObjectStateBox
            orders={orders}
            headline="Terminierte Aufträge"
            subHeadline="Die als nächstes anstehenden interne terminierten Aufträge"
            rowCount={8}
            onClick={onOrderClick}
            state={OBJECT_DATESTATE_SCHEDULED}
            identifier="orderInternScheduled"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
            client={client}
          />

          <InternalObjectStateBox
            orders={orders}
            headline="Zugewiesene Aufträge"
            subHeadline="Die als nächstes anstehenden internen zugewiesenen Aufträge"
            rowCount={8}
            onClick={onOrderClick}
            state={OBJECT_DATESTATE_ASSIGNED}
            identifier="orderInternAssigned"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
            client={client}
          />
        </div>
        <div className="col-lg-6">
          <Panel marginBottom={25} withPadding={false}>
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center flex-grow-1">
                <h5 style={{ marginBottom: 0 }}>
                  <i className="fas fa-sync" style={{ marginRight: 12 }} />
                  Interne Serviceaufträge
                </h5>
              </div>
              <div>
                {renderReclamationSettingsIcon()}
              </div>
            </div>
          </Panel>

          <ReclamationTableView
            reclamations={reclamations}
            reclamationState={RECLAMATIONSTATE_OPEN}
            services={services}
            serviceState={SERVICESTATE_OPEN}
            headline="Offene Serviceaufträge"
            subHeadline="Die zuletzt erstellten internen Reklamationen mit dem Status offen"
            rowCount={5}
            onClick={onReclamationClick}
            identifier="reclamationInternOpen"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ReclamationTableView
            reclamations={reclamations}
            reclamationState={RECLAMATIONSTATE_SCHEDULED}
            services={services}
            serviceState={SERVICESTATE_SCHEDULED}
            headline="Terminierte Serviceaufträge"
            subHeadline="Die als nächstes anstehenden interne terminierten Reklamationen"
            rowCount={8}
            onClick={onReclamationClick}
            identifier="reclamationInternScheduled"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ReclamationTableView
            reclamations={reclamations}
            reclamationState={RECLAMATIONSTATE_ASSIGNED}
            services={services}
            serviceState={SERVICESTATE_ASSIGNED}
            headline="Zugewiesene Serviceaufträge"
            subHeadline="Die als nächstes anstehenden internen zugewiesenen Reklamationen"
            rowCount={8}
            onClick={onReclamationClick}
            identifier="reclamationInternAssigned"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />
        </div>
      </div>
    );
  }
  return null;
}

InternalBoxes.propTypes = {
  orders: PropTypes.instanceOf(Array),
  reclamations: PropTypes.instanceOf(Array),
  services: PropTypes.instanceOf(Array),
  onReclamationClick: PropTypes.func.isRequired,
  onOrderClick: PropTypes.func.isRequired,
  onRestoreBoxes: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  storageConfig: PropTypes.instanceOf(Object),
  onHideBox: PropTypes.func.isRequired,
  ordersLoading: PropTypes.bool,
  reclamationsLoading: PropTypes.bool,
  client: PropTypes.instanceOf(Object).isRequired,
};

InternalBoxes.defaultProps = {
  orders: null,
  reclamations: null,
  services: null,
  visible: false,
  storageConfig: null,
  ordersLoading: false,
  reclamationsLoading: false,
};
