import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomReactSelect from '../../CustomReactSelect';
import Input from '../../Input';
import {
  floorOption, ORDERTYPE_SINGLE, parkingOption, salutationOption,
} from '../../../Library/Types';
import Config from '../../../Assets/Config';
import Checkbox from '../../Checkbox';
import ShowHideContainer from '../../ShowHideContainer';

function initReducer(data) {
  if (Config.env === 'dev') {
    return {
      firstname: 'Thorsten',
      lastname: 'Rutte',
      zip: '64560',
      city: 'Riedstadt',
      street: 'Heinrich-Heine-Str.',
      streetNo: '9d',
      phone: '01712233471',
      mobile: '',
      mail: 'thorsten@corodo.de',
      floor: 1,
      parking: 0,

      hasInvoiceAddress: 0,
      invoiceFirstname: null,
      invoiceLastname: null,
      invoiceZip: null,
      invoiceCity: null,
      invoiceStreet: null,
      invoiceStreetNo: null,
    };
  }

  return { ...data };
}

function reducer(state, action) {
  switch (action.type) {
    case 'salutation':
      return { ...state, salutation: action.payload };
    case 'firstname':
      return { ...state, firstname: action.payload };
    case 'lastname':
      return { ...state, lastname: action.payload };
    case 'zip':
      return { ...state, zip: action.payload.trim() };
    case 'city':
      return { ...state, city: action.payload };
    case 'street':
      return { ...state, street: action.payload };
    case 'streetNo':
      return { ...state, streetNo: action.payload };
    case 'phone':
      return { ...state, phone: action.payload };
    case 'mobile':
      return { ...state, mobile: action.payload };
    case 'mail':
      return { ...state, mail: action.payload.toLowerCase() };
    case 'floor':
      return { ...state, floor: parseInt(action.payload, 0) };
    case 'parking':
      return { ...state, parking: parseInt(action.payload, 0) };
    case 'comment':
      return { ...state, comment: action.payload };

    case 'hasInvoiceAddress':
      if (!action.payload) {
        return {
          ...state,
          hasInvoiceAddress: action.payload,
          invoiceFirstname: null,
          invoiceLastname: null,
          invoiceZip: null,
          invoiceCity: null,
          invoiceStreet: null,
          invoiceStreetNo: null,
        };
      }
      return { ...state, hasInvoiceAddress: action.payload };
    case 'invoiceFirstname':
      return { ...state, invoiceFirstname: action.payload };
    case 'invoiceLastname':
      return { ...state, invoiceLastname: action.payload };
    case 'invoiceZip':
      return { ...state, invoiceZip: action.payload.trim() };
    case 'invoiceCity':
      return { ...state, invoiceCity: action.payload };
    case 'invoiceStreet':
      return { ...state, invoiceStreet: action.payload };
    case 'invoiceStreetNo':
      return { ...state, invoiceStreetNo: action.payload };

    case 'deleteInvoice':
      return {
        ...state,
        invoiceFirstname: null,
        invoiceLastname: null,
        invoiceZip: null,
        invoiceCity: null,
        invoiceStreet: null,
        invoiceStreetNo: null,
      };

    case 'initEdit': {
      const {
        firstname, lastname, zip, city, street, streetNo, phone, mail, floor, comment, parking, invoiceFirstname,
        invoiceLastname, invoiceZip, invoiceCity, invoiceStreet, invoiceStreetNo, hasInvoiceAddress, mobile,
        salutation,
      } = action.payload;

      return {
        salutation,
        firstname,
        lastname,
        zip,
        city,
        street,
        streetNo,
        phone,
        mobile,
        mail,
        floor: (!floor) ? 0 : floor,
        comment,
        parking: (!parking) ? 0 : parking,
        invoiceFirstname,
        invoiceLastname,
        invoiceZip,
        invoiceCity,
        invoiceStreet,
        invoiceStreetNo,
        hasInvoiceAddress,
        init: true,
      };
    }

    default:
      throw new Error();
  }
}

/**
 * CustomerAddressInput()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function CustomerAddressInput(props) {
  const {
    onChange, editableData, errors, headline, orderType, isInternal,
  } = props;
  const [state, dispatch] = useReducer(reducer, editableData, initReducer);
  const [prevState, setPrevState] = useState();

  useEffect(() => {
    if (JSON.stringify(state) !== prevState) {
      if (onChange) {
        onChange(state);
        setPrevState(JSON.stringify(state));
      }
    }
  }, [onChange, state, prevState]);

  React.useEffect(() => {
    if (editableData && !state.init) {
      dispatch({ type: 'initEdit', payload: editableData });
    }
  }, [editableData, state.init]);

  const getHeadline = () => {
    if (headline) {
      return headline;
    }

    if (orderType === ORDERTYPE_SINGLE) {
      return '4. Lieferadresse';
    }
    return '4.1 Lieferadresse';
  };

  const renderInvoiceAddress = () => {
    if (!state.hasInvoiceAddress) {
      return null;
    }

    return (
      <>
        <div className="col-lg-12" style={{ marginTop: 30 }}>
          <h6>Rechnungsadresse</h6>
          <p>
            Bitte geben Sie hier die abweichende Rechnungsadresse ein.
          </p>

          <div className="row">
            <div className="col-lg-6">
              <Input
                onChange={(value) => dispatch({ type: 'invoiceFirstname', payload: value })}
                value={state.invoiceFirstname}
                placeholder="Vorname"
                hasError={!!errors.invoiceFirstname}
              />
            </div>
            <div className="col-lg-6">
              <Input
                onChange={(value) => dispatch({ type: 'invoiceLastname', payload: value })}
                value={state.invoiceLastname}
                placeholder="Nachname"
                hasError={!!errors.invoiceLastname}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <Input
                onChange={(value) => dispatch({ type: 'invoiceStreet', payload: value })}
                value={state.invoiceStreet}
                placeholder="Strasse"
                hasError={!!errors.invoiceStreet}
              />
            </div>
            <div className="col-lg-4">
              <Input
                onChange={(value) => dispatch({ type: 'invoiceStreetNo', payload: value })}
                value={state.invoiceStreetNo}
                placeholder="Hausnummer"
                hasError={!!errors.invoiceStreetNo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <Input
                onChange={(value) => dispatch({ type: 'invoiceZip', payload: value })}
                value={state.invoiceZip}
                placeholder="Postleitzahl"
                hasError={!!errors.invoiceZip}
                errorMessage={errors.invoiceZipMessage}
              />
            </div>
            <div className="col-lg-8">
              <Input
                onChange={(value) => dispatch({ type: 'invoiceCity', payload: value })}
                value={state.invoiceCity}
                placeholder="Ort"
                hasError={!!errors.invoiceCity}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <form>
      <div className="row">
        <div className="col-lg-12">
          <h5>{getHeadline()}</h5>
          <p className="text-muted">
            Bitte geben Sie die Adresse an, wo die Küche montiert werden soll.
          </p>

          <div className="row">
            <CustomReactSelect
              onChange={(value) => dispatch({ type: 'salutation', payload: value })}
              value={state.salutation}
              title="Parkplatzsituation"
              rowClass="col-lg-2"
              options={salutationOption}
              hasError={!!errors.salutation}
            />
            <div className="col-lg-5">
              <Input
                onChange={(value) => dispatch({ type: 'firstname', payload: value })}
                value={state.firstname}
                placeholder="Vorname"
                hasError={!!errors.firstname}
              />
            </div>
            <div className="col-lg-5">
              <Input
                onChange={(value) => dispatch({ type: 'lastname', payload: value })}
                value={state.lastname}
                placeholder="Nachname"
                hasError={!!errors.lastname}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <Input
                onChange={(value) => dispatch({ type: 'street', payload: value })}
                value={state.street}
                placeholder="Strasse"
                hasError={!!errors.street}
              />
            </div>
            <div className="col-lg-4">
              <Input
                onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
                value={state.streetNo}
                placeholder="Hausnummer"
                hasError={!!errors.streetNo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <Input
                onChange={(value) => dispatch({ type: 'zip', payload: value })}
                value={state.zip}
                placeholder="Postleitzahl"
                hasError={!!errors.zip}
                errorMessage={errors.zipMessage}
              />
            </div>
            <div className="col-lg-8">
              <Input
                onChange={(value) => dispatch({ type: 'city', payload: value })}
                value={state.city}
                placeholder="Ort"
                hasError={!!errors.city}
              />
            </div>
          </div>

          <div className="row">
            <Input
              onChange={(value) => dispatch({ type: 'mail', payload: value })}
              value={state.mail}
              rowClass="col-lg-12"
              placeholder="E-Mail Adresse"
              hasError={!!errors.mail}
              errorMessage={errors.mailMessage}
            />
          </div>

          <div className="row">
            <Input
              onChange={(value) => dispatch({ type: 'phone', payload: value })}
              value={state.phone}
              rowClass="col-lg-6"
              placeholder="Telefon (Festnetz)"
              hasError={!!errors.phone}
              errorMessage={errors.phoneMessage}
            />
            <Input
              onChange={(value) => dispatch({ type: 'mobile', payload: value })}
              value={state.mobile}
              rowClass="col-lg-6"
              placeholder="Telefon (Mobil)"
              hasError={!!errors.mobile}
              errorMessage={errors.mobileMessage}
            />
          </div>

          <div className="row">
            <CustomReactSelect
              onChange={(value) => dispatch({ type: 'floor', payload: value })}
              value={state.floor}
              title="Etage wählen..."
              rowClass="col-lg-4"
              options={floorOption}
              hasError={!!errors.floor}
            />
            <CustomReactSelect
              onChange={(value) => dispatch({ type: 'parking', payload: value })}
              value={state.parking}
              title="Parkplatzsituation"
              rowClass="col-lg-8"
              options={parkingOption}
              hasError={!!errors.parking}
            />
            <Input
              onChange={(value) => dispatch({ type: 'comment', payload: value })}
              value={state.comment}
              rowClass="col-lg-12"
              placeholder="Platz für Notizen"
            />
          </div>
        </div>

        <ShowHideContainer visible={isInternal}>
          <div className="col-lg-12">
            <Checkbox
              onChange={(value) => dispatch({ type: 'hasInvoiceAddress', payload: value })}
              value={state.hasInvoiceAddress}
            >
              Abweichende Rechnungsadresse angeben
            </Checkbox>
          </div>
        </ShowHideContainer>

        {renderInvoiceAddress()}
      </div>
    </form>
  );
}

CustomerAddressInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  editableData: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  headline: PropTypes.string,
  orderType: PropTypes.string,
  isInternal: PropTypes.bool,
};

CustomerAddressInput.defaultProps = {
  editableData: null,
  errors: {},
  headline: null,
  orderType: 'SINGLE',
  isInternal: true,
};
