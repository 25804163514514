import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../ModalDialog';
import { workOptions } from '../../../Library/Types';
import SelectPanel from '../../SelectPanels';

/**
 * UpdateWorkOptions()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UpdateWorkOptions(props) {
  const {
    object, visible, onClose, onSave,
  } = props;
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    const dataArray = [];
    if (object.workOptionElectric) dataArray.push('ELECTRIC');
    if (object.workOptionWater) dataArray.push('WATER');
    if (object.workOptionAir) dataArray.push('AIR');
    if (object.workOptionLight) dataArray.push('LIGHT');
    if (object.workOptionQuooker) dataArray.push('QUOOKER');
    setSelected(dataArray);
  }, [object]);

  const handleSave = () => {
    const mergedData = {
      workOptionElectric: (selected.indexOf('ELECTRIC') > -1),
      workOptionWater: (selected.indexOf('WATER') > -1),
      workOptionAir: (selected.indexOf('AIR') > -1),
      workOptionLight: (selected.indexOf('LIGHT') > -1),
      workOptionQuooker: (selected.indexOf('QUOOKER') > -1),
    };
    onSave(mergedData);
  };

  return (
    <ModalDialog onClose={onClose} visible={visible} onConfirm={handleSave}>
      <h5>Anschlussarbeiten ändern</h5>
      <hr />
      <br />

      <SelectPanel
        onChange={(value) => setSelected(value)}
        selected={selected}
        data={workOptions}
        isMultiSelect
      />
    </ModalDialog>
  );
}

UpdateWorkOptions.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

UpdateWorkOptions.defaultProps = {
  visible: false,
};
