import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Panel from '../../Panel';
import {
  formatCurrency,
  formatDate,
  isExternalObjectAssignee,
  isExternalOrderOwner,
} from '../../../Library/Functions';
import InfoBox from '../../InfoBox';
import { getList } from '../../../Redux/Action/TeamsAction';
import { getAll } from '../../../Redux/Action/UserAction';
import OrderOptionIcons from '../../OrderComponents/OrderOptionIcons';
import { CLIENTTYPE_MONTEUR } from '../../../Library/Types';
import { ORDERSTATE_WAITING_CALCULATION } from '../../../Library/StateTypes';
import { useGetWorkTimeString } from '../../../Library/HelperHooks';

/**
 * ObjectInformationPanel()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function ObjectInformationPanel(props) {
  const {
    object, order, storeTeams, storeUsers, showEstimation, dispatchGetTeams, dispatchGetUsers, client,
    isMonteurOnly, isObjectServiceView,
  } = props;
  const getWorgTimeString = useGetWorkTimeString();

  useEffect(() => {
    dispatchGetTeams();
    dispatchGetUsers();
  }, [dispatchGetTeams, dispatchGetUsers]);

  const getOptions = () => {
    const resultArray = [];
    if (object.workOptionElectric) resultArray.push('Elektro');
    if (object.workOptionWater) resultArray.push('Wasser');
    if (object.workOptionAir) resultArray.push('Abluft');
    if (object.workOptionLight) resultArray.push('Beleuchtung');
    if (object.workOptionQuooker) resultArray.push('Quooker');

    if (resultArray.length > 0) {
      return resultArray.join(', ');
    }
    return <i>Keine Optionen gewählt</i>;
  };

  const getUsersAndTeam = () => {
    if (storeUsers && object.montageDate) {
      let result = [];

      if (object.montageDate.teamId) {
        if (storeTeams) {
          const team = storeTeams.filter((item) => item.teamId === object.montageDate.teamId)[0];
          if (team) {
            result.push(team.name);
          }
        }
      } else {
        result = object.montageDate.users.map((item) => (`${item.firstname} ${item.lastname}`));
      }

      return result.reverse().join(', ');
    }
    return '';
  };

  const renderMonteurBox = () => {
    if ((order.internal || !isExternalOrderOwner(client.clientId, order))
      && order.state !== ORDERSTATE_WAITING_CALCULATION && !object.splitted
    ) {
      let captionString = getUsersAndTeam();

      if (order.mandantId && order.mandantId === client.clientId) {
        if (captionString && captionString.length > 0) {
          captionString = 'Zugewiesen';
        }
      } else if (!captionString || captionString.length === 0) {
        captionString = (<i>Nicht zugewiesen</i>);
      }
      return <InfoBox icon="fas fa-users" title="Monteur / Team" text={captionString} />;
    }
    return null;
  };

  const renderEstimation = () => {
    if (order && order.internal && order.type === 'SINGLE' && order.assemblyTime) {
      if (showEstimation) {
        const timeString = getWorgTimeString(order.internal, object.montageDate.executionTime);
        return <InfoBox icon="far fa-clock" title="Geschätzte Montagezeit" text={timeString} />;
      }
    }
    return null;
  };

  const renderPrice = () => {
    if (!isMonteurOnly) {
      if (order && !order.internal && order.type === 'SINGLE' && order.state !== ORDERSTATE_WAITING_CALCULATION) {
        let price = formatCurrency(order.dealerPrice);
        if (client.type === CLIENTTYPE_MONTEUR) {
          price = formatCurrency(order.mechanicPrice);
        }
        return <InfoBox icon="fas fa-euro-sign" title="Preis" text={price} />;
      }
    }
    return null;
  };

  const getCustomerAddress = () => {
    if (order && !order.internal) {
      if (!isExternalOrderOwner(client.clientId, order) && !isExternalObjectAssignee(client.clientId, order, object)) {
        return `${object.customer.zip} ${object.customer.city}`;
      }
    }
    return `${object.customer.street} ${object.customer.streetNo}, ${object.customer.city}`;
  };

  const renderMontageDate = () => {
    let date = (<i>Nicht terminiert</i>);
    if (object.montageDate && object.montageDate.date) {
      date = formatDate(object.montageDate.date, 'dd. DD.MM.YYYY');
    }

    return (
      <InfoBox
        icon="far fa-calendar"
        title="Montagedatum"
        text={date}
      />
    );
  };

  const renderCustomer = () => {
    if (!isObjectServiceView || !object || !object.customer) {
      return null;
    }

    const customerString = `${object.customer.firstname} ${object.customer.lastname}`;
    return (
      <InfoBox icon="fas fa-user" title="Kunde" text={customerString} />
    );
  };

  const renderWorkOptions = () => {
    if (isObjectServiceView) {
      return null;
    }
    return (
      <InfoBox icon="fas fa-tools" title="Anschlussarbeiten" text={getOptions()} />
    );
  };

  if (order) {
    return (
      <Panel marginBottom={20} className="ProjectInformation">
        <h6>Auftragsinformationen</h6>
        <p>
          Hier sehen Sie die Informationen zum Ursprungsauftrag / Erstmontage
        </p>

        <div className="InfoBoxContainer">
          <InfoBox
            icon="fas fa-check-circle"
            title="Auftragsoptionen"
            text={<OrderOptionIcons order={order} withMarginRight />}
          />

          {renderWorkOptions()}
          {renderCustomer()}
          <InfoBox
            icon="fas fa-bullseye"
            title="Kundenadresse"
            text={getCustomerAddress()}
          />
          {renderMontageDate()}
          {renderMonteurBox()}
          {renderEstimation()}
          {renderPrice()}
        </div>
      </Panel>
    );
  }
  return null;
}

ObjectInformationPanel.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  storeTeams: PropTypes.instanceOf(Array),
  storeUsers: PropTypes.instanceOf(Array),
  showEstimation: PropTypes.bool,
  dispatchGetTeams: PropTypes.func.isRequired,
  dispatchGetUsers: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  isMonteurOnly: PropTypes.bool.isRequired,
  isObjectServiceView: PropTypes.bool,
};

ObjectInformationPanel.defaultProps = {
  storeTeams: null,
  storeUsers: null,
  showEstimation: false,
  isObjectServiceView: false,
};

function mapStoreToProps(store) {
  return {
    client: store.auth.client,
    storeTeams: store.teams.teams,
    storeUsers: store.user.users,
    isMonteurOnly: store.auth.isMonteurOnly,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetTeams: () => dispatch(getList()),
    dispatchGetUsers: () => dispatch(getAll()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ObjectInformationPanel));
