import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { deleteMaterial, getList } from '../../../Redux/Action/Client/MaterialAction';
import Panel from '../../../Components/Panel';
import CustomSwitch from '../../../Components/CustomSwitch';
import Button from '../../../Components/Button';
import { useDispatchUpdateSettings } from '../../../Redux/Action/ClientSettingsAction';
import Add from './Add';
import MaterialList from './MaterialList';
import ModalDialog from '../../../Components/ModalDialog';

/**
 * Overview()
 * @param props
 * @returns {*}
 * @constructor
 */
function Overview(props) {
  const {
    material, dispatchGetList, clientSettings, dispatchDelete,
  } = props;
  const dispatchUpdateSettings = useDispatchUpdateSettings();

  const [showAdd, setShowAdd] = React.useState(false);
  const [selectedMaterial, setSelectedMaterial] = React.useState(null);
  const [showHint, setShowHint] = React.useState(null);

  React.useEffect(() => {
    dispatchGetList();
  }, [dispatchGetList]);

  const handleDeleteMaterial = (data) => {
    if (material.length === 1) {
      dispatchUpdateSettings({ ...clientSettings, internalUseMaterial: false });
    }
    dispatchDelete(data);
  };

  const handleCloseAdd = () => {
    setSelectedMaterial(null);
    setShowAdd(false);
  };

  const startEdit = (item) => {
    setSelectedMaterial(item);
    setShowAdd(true);
  };

  const handleUpdateSettings = (value) => {
    if (material && material.length > 0) {
      dispatchUpdateSettings({ ...clientSettings, internalUseMaterial: value });
    } else {
      setShowHint(true);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <Panel marginBottom={20}>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h4>Materialverwaltung</h4>
              <p style={{ marginBottom: 0 }}>
                Hier können Sie das Material verwalten, welches Ihre Monteure während der Berichterstellung verwenden
                können. Die Monteure haben innerhalb der Berichterstellung die Möglichkeit, zusätzlich verwendetes
                Material anzugeben. Dieses Material wird Ihnen auf dem internen Bericht angezeigt. Um diese
                Funktionalität verwenden zu können, müssen Sie Material angelegt haben und die Materialverwaltung
                für die App aktivieren!
              </p>
            </div>
          </div>
        </Panel>
        <Panel marginBottom={20}>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <CustomSwitch
                checked={clientSettings.internalUseMaterial}
                onChange={handleUpdateSettings}
                caption="Materialverwaltung für Berichte aktivieren"
              />
            </div>
            <div>
              <Button onClick={() => setShowAdd(true)}>Material hinzufügen</Button>
            </div>
          </div>
        </Panel>

        <Panel>
          <MaterialList
            material={material}
            onDelete={handleDeleteMaterial}
            onEdit={startEdit}
          />
        </Panel>
      </div>

      <Add
        onClose={handleCloseAdd}
        visible={showAdd}
        editData={selectedMaterial}
      />

      <ModalDialog onClose={() => setShowHint(false)} hideConfirmButton visible={showHint} centered>
        <h5>Noch keine Material vorhanden!</h5>
        <p>
          Um die Funktionalität verwenden zu können, müssen Sie zuerst Material anlegen.
        </p>
      </ModalDialog>
    </div>
  );
}

Overview.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchDelete: PropTypes.func.isRequired,
  material: PropTypes.instanceOf(Object),
  clientSettings: PropTypes.instanceOf(Object),
};

Overview.defaultProps = {
  material: null,
  clientSettings: null,
};

function mapStoreToProps(store) {
  return {
    material: store.client.material,
    client: store.client.client,
    clientSettings: store.auth.client.settings,
    users: store.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: () => dispatch(getList()),
    dispatchDelete: (material) => dispatch(deleteMaterial(material)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Overview));
