import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import SchedulerTile from '../../Components/Scheduler/SchedulerTile';
import HolidayAndOrderBadge from '../../Components/Scheduler/Components/HolidayAndOrderBadge';
import Panel from '../../Components/Panel';
import { getCalendarData } from '../../Redux/Action/UserHolidayAction';
import { getAll as getUserList, getOrders, getReclamations } from '../../Redux/Action/UserAction';
import AddButtonMonthSelector from '../../Components/Scheduler/Components/AddButtonMonthSelector';
import RequestHolidayModal from './Component/RequestHolidayModal';
import Alert from '../../Components/Alert';

/**
 * Holiday()
 * @param props
 * @returns {*}
 * @constructor
 */
function Holiday(props) {
  const {
    user, dispatchGetCalendarData, dispatchGetUsers, users, dispatchGetOrders, dispatchGetReclamations,
    history,
  } = props;
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [calendarData, setCalendarData] = useState(null);
  const [orders, setOrders] = useState(null);
  const [reclamations, setReclamations] = useState(null);
  const [allDates, setAllDates] = useState([]);
  const [mergedCalendarData, setMergedCalendarData] = useState([]);
  const [showRequest, setShowRequest] = useState(false);
  const [showRequestSuccess, setShowRequestSuccess] = useState(false);

  const callDispatchCalendarData = useCallback(() => {
    const startDate = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
    const endtDate = moment(currentMonth).endOf('month').format('YYYY-MM-DD');

    dispatchGetCalendarData(user.userId, startDate, endtDate).then((response) => setCalendarData(response.tileData));
  }, [dispatchGetCalendarData, currentMonth, user.userId]);

  React.useEffect(() => {
    dispatchGetUsers();
    dispatchGetOrders().then((response) => setOrders((response) || []));
    dispatchGetReclamations().then((response) => setReclamations((response) || []));
  }, [dispatchGetUsers, dispatchGetOrders, dispatchGetReclamations]);

  React.useEffect(() => {
    callDispatchCalendarData();
  }, [callDispatchCalendarData]);

  React.useEffect(() => {
    let dates = [];
    if (calendarData && orders && reclamations) {
      const orderDates = orders.map((item) => item.deliveryDateStart);
      const reclamationDates = reclamations.map((item) => item.executionDate);
      const holidayDates = Object.keys(calendarData);

      dates = [...orderDates, ...reclamationDates, ...holidayDates];
      dates = dates.filter((item, index) => dates.indexOf(item) === index);
      setAllDates(dates);
    }
  }, [calendarData, orders, reclamations]);

  React.useEffect(() => {
    if (allDates) {
      const mergedData = {};
      allDates.map((item) => {
        const ordersMapped = orders.filter((orderItem) => orderItem.deliveryDateStart === item);
        const reclamationsMapped = reclamations.filter((reclamationItem) => reclamationItem.executionDate === item);

        mergedData[item] = [
          ...ordersMapped, ...reclamationsMapped, ...((calendarData[item]) ? calendarData[item] : []),
        ];
        return null;
      });

      setMergedCalendarData(mergedData);
    }
  }, [calendarData, orders, reclamations, allDates]);

  const handleSelectDay = (param) => {
    if (param.reclamationId) {
      const uri = `/order/${param.orderId}/reclamation/${param.reclamationId}`;
      history.push(uri);
    } else if (param.orderId) {
      const uri = `/order/${param.orderId}/handle`;
      history.push(uri);
    }

    return null;
  };

  const handleOnSavedRequest = () => {
    setShowRequest(false);
    setShowRequestSuccess(true);
    callDispatchCalendarData();
  };

  return (
    <Panel>
      <SchedulerTile
        calendarData={(mergedCalendarData) || []}
        onSelectDay={handleSelectDay}
        currentMonth={currentMonth}
        onMonthChange={setCurrentMonth}
        BadgeComponent={HolidayAndOrderBadge}
        users={(users) || []}
        monthSelectorRightActions={(
          <AddButtonMonthSelector
            onClick={() => setShowRequest(true)}
            caption="Urlaub beantragen"
            type="btn-light"
          />
        )}
      />

      <RequestHolidayModal
        visible={showRequest}
        onClose={() => setShowRequest(false)}
        onSave={handleOnSavedRequest}
        user={user}
        initialData={{ userId: user.userId }}
      />

      <Alert onClose={() => setShowRequestSuccess(false)} visible={showRequestSuccess} type="success">
        <h5>Urlaubsantrag gespeichert</h5>
        <p>
          Ihr Antrag wurde erfolgreich gespeichert und liegt jetzt zur Freigabe bei der verantwortlichen Person!
        </p>
      </Alert>
    </Panel>
  );
}

Holiday.propTypes = {
  dispatchGetCalendarData: PropTypes.func.isRequired,
  dispatchGetUsers: PropTypes.func.isRequired,
  dispatchGetOrders: PropTypes.func.isRequired,
  dispatchGetReclamations: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
};

Holiday.defaultProps = {
  user: null,
  users: null,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
    users: store.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetCalendarData: (userId, startDate, endDate) => dispatch(getCalendarData(userId, startDate, endDate)),
    dispatchGetUsers: () => dispatch(getUserList()),
    dispatchGetOrders: () => dispatch(getOrders()),
    dispatchGetReclamations: () => dispatch(getReclamations()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Holiday));
