import React, { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Panel from '../../../Components/Panel';
import { CLIENTTYPE_DEALER, legalFormOptions } from '../../../Library/Types';
import { get, update } from '../../../Redux/Action/ClientAction';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import { isObjectIdentical } from '../../../Library/Functions';
import getBankInformation from '../../../Redux/Action/MetaDataAction';
import HelpDialog from '../../../Components/HelpDialog';

function reducer(state, action) {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload };
    case 'legalForm':
      return { ...state, legalForm: action.payload };
    case 'mail':
      return { ...state, mail: action.payload };
    case 'web':
      return { ...state, web: action.payload };
    case 'zip':
      return { ...state, zip: action.payload.trim() };
    case 'city':
      return { ...state, city: action.payload };
    case 'street':
      return { ...state, street: action.payload };
    case 'streetNo':
      return { ...state, streetNo: action.payload };
    case 'phone':
      return { ...state, phone: action.payload };
    case 'fax':
      return { ...state, fax: action.payload };
    case 'vat':
      return { ...state, vat: action.payload };
    case 'bankOwner':
      return { ...state, bankOwner: action.payload };
    case 'bankName':
      return { ...state, bankName: action.payload };
    case 'bankIban':
      return { ...state, bankIban: action.payload };
    case 'bankBic':
      return { ...state, bankBic: action.payload };
    case 'devoutIdentificationNumber':
      return { ...state, devoutIdentificationNumber: action.payload };
    case 'updateBank': {
      const { bankName, bankBic, bankIban } = action.payload;
      return {
        ...state, bankName, bankBic, bankIban,
      };
    }
    default:
      throw new Error();
  }
}

/**
 * Update()
 * @param props
 * @returns {*}
 * @constructor
 */
function Update(props) {
  const {
    currentClient, dispatchGetBankInformation, dispatchUpdate, dispatchGetClient,
  } = props;
  const [currentTabIndex, setCurrentTabIndex] = React.useState(null);
  const copiedClient = {
    ...currentClient,
    bankOwner: (!currentClient.bankOwner) ? currentClient.name : currentClient.bankOwner,
  };

  const [state, dispatch] = useReducer(reducer, copiedClient);
  const [lastState, setLastState] = useState(copiedClient);
  const [legalFormString, setLegalFormString] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [helpContext, setHelpContext] = useState(null);

  React.useEffect(() => {
    dispatchGetClient(currentClient.clientId);
  }, [dispatchGetClient, currentClient.clientId]);

  useEffect(() => {
    if (!isObjectIdentical(state, lastState)) {
      dispatchUpdate(state).then(() => {
        setLastState(state);
      });
    }
  }, [state, dispatchUpdate, lastState]);

  const openHelp = (context) => {
    setHelpContext(context);
    setShowHelp(true);
  };

  const handleOnSaveIban = (iban) => {
    if (iban && iban.length === 22) {
      if ((!state.bankName || state.bankName.length === 0) && (!state.bankBic || state.bankBic.length === 0)) {
        dispatchGetBankInformation(iban).then((data) => {
          const payload = { bankName: data.name, bankBic: data.bic, bankIban: iban };
          dispatch({ type: 'updateBank', payload });
        }).catch(() => {});
      } else {
        dispatch({ type: 'bankIban', payload: iban });
      }
    } else {
      dispatch({ type: 'bankIban', payload: iban });
    }
  };

  useEffect(() => {
    if (state.legalForm) {
      const legal = legalFormOptions.filter((item) => item.value === parseInt(state.legalForm, 0))[0];
      setLegalFormString(legal.label);
    }
  }, [state.legalForm]);

  const isBankFieldMandatoryForOrder = () => {
    if (currentClient.type === CLIENTTYPE_DEALER) {
      return !!currentClient.devoutIdentificationNumber;
    }
    return true;
  };

  return (
    <div className="row">
      <div className="col-sm-12" role="tablist">
        <Panel marginBottom={25}>
          <div>
            <h4>
              Firmendaten
            </h4>
            <p style={{ marginBottom: 40 }}>
              Alle wichtigen Daten Ihres Unternehmens!
            </p>

            <KeyValueEditRow
              title="Firmenname"
              type="text"
              initialValue={state.name}
              displayText={state.name}
              onChange={(value) => dispatch({ type: 'name', payload: value })}
              containerTabIndex={1}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
            />
            <KeyValueEditRow
              title="Rechtsform"
              type="select"
              initialValue={state.legalForm}
              displayText={legalFormString}
              onChange={(value) => dispatch({ type: 'legalForm', payload: value })}
              selectData={legalFormOptions}
              containerTabIndex={2}
              startEditMode={(currentTabIndex === 2)}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
            />

            <div className="row">
              <div className="col-lg-9">
                <KeyValueEditRow
                  title="Strasse"
                  type="text"
                  initialValue={state.street}
                  displayText={state.street}
                  onChange={(value) => dispatch({ type: 'street', payload: value })}
                  mandatoryForOrder
                  containerTabIndex={3}
                  startEditMode={(currentTabIndex === 3)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-3">
                <KeyValueEditRow
                  title="Hausnummer"
                  type="text"
                  initialValue={state.streetNo}
                  displayText={state.streetNo}
                  onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
                  mandatoryForOrder
                  containerTabIndex={4}
                  startEditMode={(currentTabIndex === 4)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <KeyValueEditRow
                  title="Postleitzahl"
                  type="text"
                  initialValue={state.zip}
                  displayText={state.zip}
                  onChange={(value) => dispatch({ type: 'zip', payload: value })}
                  mandatoryForOrder
                  containerTabIndex={5}
                  startEditMode={(currentTabIndex === 5)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-9">
                <KeyValueEditRow
                  title="Ort"
                  type="text"
                  initialValue={state.city}
                  displayText={state.city}
                  onChange={(value) => dispatch({ type: 'city', payload: value })}
                  mandatoryForOrder
                  containerTabIndex={6}
                  startEditMode={(currentTabIndex === 6)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
            </div>

            <KeyValueEditRow
              title="Telefon"
              type="text"
              initialValue={state.phone}
              displayText={state.phone}
              onChange={(value) => dispatch({ type: 'phone', payload: value })}
              mandatoryForOrder
              containerTabIndex={7}
              startEditMode={(currentTabIndex === 7)}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
            />
            <KeyValueEditRow
              title="Fax"
              type="text"
              initialValue={state.fax}
              displayText={state.fax}
              onChange={(value) => dispatch({ type: 'fax', payload: value })}
              containerTabIndex={8}
              startEditMode={(currentTabIndex === 8)}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
            />
            <KeyValueEditRow
              title="E-Mail Adresse"
              type="text"
              initialValue={state.mail}
              displayText={state.mail}
              onChange={(value) => dispatch({ type: 'mail', payload: value })}
              mandatoryForOrder
              containerTabIndex={9}
              startEditMode={(currentTabIndex === 9)}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
            />
            <KeyValueEditRow
              title="Webseite"
              type="text"
              initialValue={state.web}
              displayText={state.web}
              onChange={(value) => dispatch({ type: 'web', payload: value })}
              mandatoryForOrder
              containerTabIndex={12}
              startEditMode={(currentTabIndex === 12)}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
            />
            <KeyValueEditRow
              title="Umsatzsteuer-Identifikationsnummer"
              type="text"
              initialValue={state.vat}
              displayText={state.vat}
              onChange={(value) => dispatch({ type: 'vat', payload: value })}
              mandatoryForOrder
              containerTabIndex={13}
              startEditMode={(currentTabIndex === 13)}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
            />
            <KeyValueEditRow
              title="Gläubiger-Identifikationsnummer"
              type="text"
              initialValue={state.devoutIdentificationNumber}
              displayText={state.devoutIdentificationNumber}
              onChange={(value) => dispatch({ type: 'devoutIdentificationNumber', payload: value })}
              onHelpAction={() => openHelp('devoutIdentificationNumber')}
              containerTabIndex={14}
              startEditMode={(currentTabIndex === 14)}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
            />
          </div>
        </Panel>

        <Panel marginBottom={25}>
          <h4>
            Bankverbindung
          </h4>
          <p style={{ marginBottom: 40 }}>
            Bitte tragen Sie hier Ihre Bankverbindung ein. Bankname und BIC werden, falls möglich, automatisch
            ausgefüllt, wenn Sie Ihre IBAN korrekt eingetragen haben.
          </p>

          <div className="row">
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Kontoinhaber"
                type="text"
                initialValue={state.bankOwner}
                displayText={state.bankOwner}
                onChange={(value) => dispatch({ type: 'bankOwner', payload: value })}
                mandatoryForOrder={isBankFieldMandatoryForOrder()}
                containerTabIndex={15}
                startEditMode={(currentTabIndex === 15)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="IBAN"
                type="text"
                initialValue={state.bankIban}
                displayText={state.bankIban}
                onChange={handleOnSaveIban}
                mandatoryForOrder={isBankFieldMandatoryForOrder()}
                containerTabIndex={16}
                startEditMode={(currentTabIndex === 16)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Bankname"
                type="text"
                initialValue={state.bankName}
                displayText={state.bankName}
                onChange={(value) => dispatch({ type: 'bankName', payload: value })}
                mandatoryForOrder={isBankFieldMandatoryForOrder()}
                containerTabIndex={17}
                startEditMode={(currentTabIndex === 17)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="BIC"
                type="text"
                initialValue={state.bankBic}
                displayText={state.bankBic}
                onChange={(value) => dispatch({ type: 'bankBic', payload: value })}
                mandatoryForOrder={isBankFieldMandatoryForOrder()}
                containerTabIndex={18}
                startEditMode={(currentTabIndex === 18)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
              />
            </div>
          </div>
        </Panel>
      </div>

      <HelpDialog context={helpContext} visible={showHelp} onClose={() => setShowHelp(false)} />
    </div>
  );
}

Update.propTypes = {
  currentClient: PropTypes.instanceOf(Object).isRequired,
  dispatchGetClient: PropTypes.func.isRequired,
  dispatchGetBankInformation: PropTypes.func.isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
};

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
    currentClient: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdate: (state) => dispatch(update(state)),
    dispatchGetClient: (clientId) => dispatch(get(clientId)),
    dispatchGetBankInformation: (iban) => dispatch(getBankInformation(iban)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Update));
