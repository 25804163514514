import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Panel from '../../Panel';
import { formatCurrency, formatDate } from '../../../Library/Functions';
import { get } from '../../../Redux/Action/ClientVoucherAction';

/**
 * ClientVoucherInformation()
 * @param props
 * @returns {*}
 * @constructor
 */
function ClientVoucherInformation(props) {
  const {
    client, dispatchGet,
  } = props;
  const [voucher, setVoucher] = React.useState(null);

  React.useEffect(() => {
    if (client) {
      dispatchGet(client.clientId).then((response) => {
        setVoucher(response);
      });
    }
  }, [dispatchGet, client]);

  const getDiscount = () => (
    (voucher.discountPrice) ? formatCurrency(voucher.discountPrice) : `${voucher.discountPercent}%`
  );

  const renderContent = () => {
    if (client && voucher) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h6 className="text-success">
            {`*** ${getDiscount()} Rabatt auf alle Angebote ***`}
          </h6>
          <div><strong>{`Gültig bis einschließlich: ${formatDate(voucher.endDate)}`}</strong></div>
          <div>{`Verwendeter Gutscheincode: ${voucher.formattedCode}`}</div>
          <div>{`Eingelöst am: ${formatDate(voucher.startDate)}`}</div>
        </div>
      );
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: 20 }}>Aktuell kein Gutschein hinterlegt!</div>
      </div>
    );
  };

  return (
    <Panel>
      <h5 className="hk-text-primary">Gutschein</h5>
      <p>Hier sehen Sie ob und welchen Gutschein der Kunde aktuell aktiviert hat</p>
      <div>
        <hr />
      </div>

      <div className="d-flex flex-column align-items-center" style={{ marginTop: 20 }}>
        {renderContent()}
      </div>
    </Panel>
  );
}

ClientVoucherInformation.propTypes = {
  dispatchGet: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object),
};

ClientVoucherInformation.defaultProps = {
  client: null,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: (clientId) => dispatch(get(clientId)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(ClientVoucherInformation);
