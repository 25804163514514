import React from 'react';
import {
  NavLink, useRouteMatch,
} from 'react-router-dom';
import Panel from '../../Panel';

/**
 * SystemStatisticNavigation()
 * @returns {*}
 * @constructor
 */
export default function SystemStatisticNavigation() {
  const { url } = useRouteMatch();

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            Statistiken
          </h6>
        </div>

        <hr />

        <ul>
          <li>
            <NavLink className="nav-link" to={`${url}/month`}>
              <i className="fas fa-folder-open" />
              Monatsübersicht (Extern)
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/transactions`}>
              <i className="fas fa-euro-sign" />
              Transaktionsübersicht
            </NavLink>
          </li>
          <hr />
          <li>
            <NavLink className="nav-link" to="/system" activeClassName={null}>
              <i className="fas fa-angle-double-left" />
              Zurück
            </NavLink>
          </li>
        </ul>
      </div>
    </Panel>
  );
}
