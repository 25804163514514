import React, { useEffect } from 'react';
import Footer from './Components/Footer';
import { HeadlineBox } from './StyledComponents';

/**
 * Imprint()
 * @returns {*}
 * @constructor
 */
export default function Imprint() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-grow-1 flex-column" style={{ margin: 0, paddingTop: 80, backgroundColor: '#F1F1F2' }}>
      <div className="FullPageBox Light">
        <div className="container">
          <HeadlineBox className="Red">Impressum</HeadlineBox>

          <div className="row">
            <div className="col-lg-6">
              <h6 style={{ margin: '10px 0' }}>Angaben gemäß § 5 TMG</h6>

              <address style={{ margin: 0 }}>hey.kitchen GmbH</address>
              <address style={{ margin: 0 }}>Rennweg 60</address>
              <address style={{ margin: 0 }}>56626 Rennwegr</address>
            </div>

            <div className="col-lg-6">
              <h6 style={{ margin: '10px 0' }}>Kontakt</h6>

              <address style={{ margin: 0 }}>+49 2266 478 9296</address>
              <address style={{ margin: 0 }}>kontakt@hey.kitchen</address>
              <address style={{ margin: 0 }}>www.hey.kitchen</address>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <h6 style={{ margin: '50px 0 10px' }}>Umsatzsteuer-Identifikationsnummer</h6>
              <div>DE326632866</div>

              <h6 style={{ margin: '50px 0 10px' }}>
                Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
              </h6>

              <address style={{ margin: 0 }}>Oliver Brandt</address>
              <address style={{ margin: 0 }}>hey.kitchen GmbH</address>
              <address style={{ margin: 0 }}>Rennweg 60</address>
              <address style={{ margin: 0 }}>56626 Rennweg</address>
            </div>

            <div className="col-lg-6">
              <h6 style={{ margin: '50px 0 10px' }}>Vertreten durch:</h6>

              <address style={{ margin: 0 }}>Geschäftsführer: Oliver Brandt, Christian Lenzhölzer</address>
              <address style={{ margin: 0 }}>Handelsregister: AG Köln HRB 99605</address>
            </div>
          </div>

          <HeadlineBox style={{ margin: '50px 0 10px' }}>Streitschlichtung</HeadlineBox>
          <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
            https://ec.europa.eu/consumers/odr.
            <br />
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
            <br />
            <br />
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
          </p>

          <HeadlineBox style={{ margin: '50px 0 20px' }}>Haftung für Inhalte</HeadlineBox>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
            forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            <br />
            <br />
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
            einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
            wir diese Inhalte umgehend entfernen.
          </p>

          <HeadlineBox style={{ margin: '50px 0 20px' }}>Haftung für Links</HeadlineBox>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
            haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
            der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
            Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
            <br />
            <br />
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
            einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
            Links umgehend entfernen.
          </p>

          <HeadlineBox style={{ margin: '50px 0 20px' }}>Urheberrecht</HeadlineBox>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            <br />
            <br />
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
            auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
            Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
        </div>
      </div>

      <Footer type="Dark" />
    </div>
  );
}
