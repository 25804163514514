import React from 'react';
import * as Redux from 'react-redux';
import Axios from '../../../../Library/Axios';

export const defaultUri = '/order/{orderId}/object/{objectId}/note';
export const defaultUriId = `${defaultUri}/{noteId}`;

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.orderId {number}
 * @param parameter.objectId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUri(parameter, uri = defaultUri) {
  const { orderId, objectId } = parameter;

  return uri
    .replace('{orderId}', orderId)
    .replace('{objectId}', objectId);
}

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.orderId {number}
 * @param parameter.objectId {number}
 * @param parameter.noteId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUriId(parameter, uri = defaultUriId) {
  const { dateId } = parameter;
  return prepareUri(parameter, uri).replace('{noteId}', dateId);
}

export const OBJECT_NOTE_GET_LIST_START = 'OBJECT_NOTE_GET_LIST_START';
export const OBJECT_NOTE_GET_LIST_SUCCESS = 'OBJECT_NOTE_GET_LIST_SUCCESS';
export const OBJECT_NOTE_GET_LIST_ERROR = 'OBJECT_NOTE_GET_LIST_ERROR';

/**
 * useDispatchGetObjectNotes
 * @param orderId
 * @param objectId
 * @returns {function(): *}
 */
export const useDispatchGetObjectNotes = (orderId, objectId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUri({ orderId, objectId });

  return React.useCallback(() => {
    dispatch({ type: OBJECT_NOTE_GET_LIST_START, payload: uri });
    return Axios().get(uri).then((response) => {
      dispatch({ type: OBJECT_NOTE_GET_LIST_SUCCESS, payload: response.data.data });
      return Promise.resolve(response.data.data);
    }).catch((error) => {
      dispatch({ type: OBJECT_NOTE_GET_LIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};
