import React from 'react';
import { isMobileOnly, isIOS } from 'react-device-detect';

const apple = require('../../Assets/Images/apple-download-dark.svg');
const android = require('../../Assets/Images/android-download.png');

/**
 * MobileDeactivatedHint()
 * @returns {null|*}
 * @constructor
 */
export default function MobileDeactivatedHint() {
  const renderIosBanner = () => {
    if (isIOS) {
      return (
        <a
          href="https://apps.apple.com/de/app/hey-kitchen/id1488213749?mt=8"
          target="_blank"
          rel="noopener noreferrer"
          className="IOS"
        >
          <img src={apple} alt="Apple Store" style={{ marginBottom: 20 }} />
        </a>
      );
    }
    return null;
  };

  const renderAndroidBanner = () => {
    if (!isIOS) {
      return (
        <a
          href="https://play.google.com/store/apps/details?id=de.hey.kitchen"
          target="_blank"
          rel="noreferrer noopener"
          className="Android"
        >
          <img src={android} alt="Google Play Store" />
        </a>
      );
    }
    return null;
  };

  if (isMobileOnly) {
    return (
      <div style={{ marginTop: 50, marginLeft: -20, marginRight: -20 }}>
        <div className="alert alert-hk-primary" style={{ textAlign: 'center' }}>
          <i className="fas fa-info-circle" style={{ fontSize: 30 }} />
          <br />
          <br />
          Diese Seite ist nicht für die Nutzung auf Smartphones optimiert.
          Bitte verwenden Sie unsere hey.kitchen App oder wechseln Sie auf ein Tablet oder Desktop-Computer.
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
          {renderIosBanner()}
          {renderAndroidBanner()}
        </div>
      </div>
    );
  }
  return null;
}
