import { isValidateEmail, isValidZip } from '../../../../Library/Functions';

export const validationFields = {
  firstname: false,
  lastname: false,
  zip: false,
  city: false,
  street: false,
  streetNo: false,
  phone: false,
  mail: false,
  floor: false,
  note: false,
};

/**
 * Check if customer input is valid
 * @param data
 * @param isInternal
 * @returns {{zip, note, firstname, mail, city, streetNo, phone, street, floor, lastname}|boolean}
 */
export default function customerFormHasError(data, isInternal) {
  let errorCodes = { ...validationFields };
  let hasError = false;

  if (!data.firstname) {
    errorCodes = { ...errorCodes, firstname: true };
    hasError = true;
  }
  if (!data.lastname) {
    errorCodes = { ...errorCodes, lastname: true };
    hasError = true;
  }

  if (!data.zip) {
    errorCodes = { ...errorCodes, zip: true };
    hasError = true;
  } else if (!isValidZip(data.zip) && !isInternal) {
    errorCodes = {
      ...errorCodes,
      zip: true,
      zipMessage: 'Bitte geben Sie eine gültige deutsche Postleitzahl ein',
    };
    hasError = true;
  }

  if (!data.city) {
    errorCodes = { ...errorCodes, city: true };
    hasError = true;
  }
  if (!data.street) {
    errorCodes = { ...errorCodes, street: true };
    hasError = true;
  }
  if (!data.streetNo) {
    errorCodes = { ...errorCodes, streetNo: true };
    hasError = true;
  }

  if (!data.phone && !data.mobile) {
    errorCodes = {
      ...errorCodes,
      phone: true,
      mobile: true,
      phoneMessage: 'Bitte geben Sie mindestens eine Telefonnummer an',
    };
    hasError = true;
  }

  if (data.mail && !isValidateEmail(data.mail)) {
    errorCodes = {
      ...errorCodes,
      mail: true,
      mailMessage: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
    };
    hasError = true;
  }

  if (data.floor === null || data.floor === undefined) {
    errorCodes = { ...errorCodes, floor: true };
    hasError = true;
  }

  if (data.hasInvoiceAddress) {
    if (!data.invoiceFirstname) {
      errorCodes = { ...errorCodes, invoiceFirstname: true };
      hasError = true;
    }
    if (!data.invoiceLastname) {
      errorCodes = { ...errorCodes, invoiceLastname: true };
      hasError = true;
    }

    if (!data.invoiceZip) {
      errorCodes = { ...errorCodes, invoiceZip: true };
      hasError = true;
    } else if (!isValidZip(data.invoiceZip)) {
      errorCodes = {
        ...errorCodes,
        invoiceZip: true,
        invoiceZipMessage: 'Bitte geben Sie eine gültige Postleitzahl ein',
      };
      hasError = true;
    }

    if (!data.invoiceCity) {
      errorCodes = { ...errorCodes, invoiceCity: true };
      hasError = true;
    }
    if (!data.invoiceStreet) {
      errorCodes = { ...errorCodes, invoiceStreet: true };
      hasError = true;
    }
    if (!data.invoiceStreetNo) {
      errorCodes = { ...errorCodes, invoiceStreetNo: true };
      hasError = true;
    }
  }

  if (hasError) {
    return errorCodes;
  }
  return false;
}
