import React from 'react';

import './style.css';

const screenshot01 = require('../../../../Assets/Images/App/iphone_screen1.png');
const screenshot02 = require('../../../../Assets/Images/App/iphone_screen3.png');
const screenshot03 = require('../../../../Assets/Images/App/iphone_screen4.png');
const screenshot04 = require('../../../../Assets/Images/App/iphone_screen9.png');

/**
 * MobilePresentation()
 * @returns {*}
 * @constructor
 */
export default function MobilePresentation() {
  return (
    <div className="MobilePresentation FullPageBox Light Center">
      <div style={{ marginTop: '-120px' }} id="pricing" />
      <div className="container d-flex flex-column">
        <h2 style={{ marginBottom: 0 }}>wir stellen vor: hey.kitchen App</h2>
        <p style={{ marginTop: 0 }} className="hk-text-primary">
          DIGITALE KÜCHENMONTAGE PLATTFORM
        </p>
        <p>
          Beim Kunden vor Ort führt die hey.kitchen APP Ihren Monteur dann durch den gesamten Montageprozess.
          Das Fotoprotokoll wird direkt mit dem Tablet oder dem Smartphone erstellt. Reklamationen werden
          automatisch abgewickelt und dokumentiert. Sobald Ihr Kunde unterschreibt, wird automatisch Ihr
          Montagebericht an den Endkunden geschickt.
        </p>

        <div className="ScreenshotContainer" style={{ marginTop: 80 }}>
          <div className="First">
            <div>
              <img src={screenshot01} alt="Screen1" />
            </div>
            <div>
              <img src={screenshot02} alt="Screen2" />
            </div>
          </div>
          <div className="Second">
            <div>
              <img src={screenshot03} alt="Screen3" />
            </div>
            <div>
              <img src={screenshot04} alt="Screen4" />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
