/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Panel from '../Panel';
import ImagePreviewOverlay from '../DeviceList/ImagePreviewOverlay';

const Container = styled.div`
  .Img {
    min-width: 140px;
    min-height: 140px;
    max-width: 140px;
    max-height: 140px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: ${(props) => props.theme.border.radius};
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
`;

/**
 * InventoryImages()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function InventoryImages(props) {
  const { images } = props;
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClick = (index) => {
    setSelectedIndex(index);
    setShowImagePreview(true);
  };

  if (images && images.length > 0) {
    return (
      <Container>
        <Panel marginBottom={20}>
          <h6>Bilder der Bestandsaufnahme</h6>
          <p style={{ marginBottom: 30 }}>
            Hier sehen Sie alle Bilder, die während der Bestandsaufnahme gemacht wurden
          </p>
          <div>
            {images.map((item, index) => (
              <img
                className="Img"
                key={item.documentId}
                src={item.absolutePath}
                alt="Küchenbild"
                onClick={() => handleClick(index)}
                tabIndex={-1}
                onKeyPress={() => {}}
              />
            ))}
          </div>
        </Panel>

        <ImagePreviewOverlay
          visible={showImagePreview}
          onClose={() => setShowImagePreview(false)}
          documents={images}
          selectedIndex={selectedIndex}
        />
      </Container>
    );
  }
  return null;
}

InventoryImages.propTypes = {
  images: PropTypes.instanceOf(Array).isRequired,
};
