import {
  RECLAMATION_GETLIST_SUCCESS, RECLAMATION_GETLIST_ERROR, RECLAMATION_GETLIST_START, RECLAMATION_GET_START,
  RECLAMATION_GET_ERROR, RECLAMATION_GET_SUCCESS, RECLAMATION_UPDATE_START, RECLAMATION_UPDATE_ERROR,
  RECLAMATION_UPDATE_SUCCESS, RECLAMATION_APPOINTMENTAPPROVE_SUCCESS, RECLAMATION_ADDDEVICE_SUCCESS,
  RECLAMATION_DELETEDEVICE_SUCCESS,
} from '../Action/ReclamationAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  reclamations: null,
  selectedReclamation: null,
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /**
     * GET LIST
     */
    case RECLAMATION_GETLIST_START: {
      return { ...state, isLoading: true };
    }
    case RECLAMATION_GETLIST_SUCCESS: {
      return { ...state, isLoading: false, reclamations: action.payload };
    }
    case RECLAMATION_GETLIST_ERROR: {
      return { ...state, reclamations: [], isLoading: false };
    }

    /**
     * GET
     */
    case RECLAMATION_GET_START: {
      return { ...state, isLoading: true };
    }
    case RECLAMATION_GET_SUCCESS: {
      return { ...state, isLoading: false, selectedReclamation: action.payload };
    }
    case RECLAMATION_GET_ERROR: {
      return { ...state, selectedReclamation: null, isLoading: false };
    }

    /**
     * UPDATE
     */
    case RECLAMATION_UPDATE_START: {
      return { ...state, isLoading: true };
    }
    case RECLAMATION_UPDATE_SUCCESS: {
      return { ...state, isLoading: false, selectedReclamation: action.payload };
    }
    case RECLAMATION_UPDATE_ERROR: {
      return { ...state, selectedReclamation: null, isLoading: false };
    }

    /**
     * APPOINTMENT APPROVE
     */
    case RECLAMATION_APPOINTMENTAPPROVE_SUCCESS: {
      return { ...state, isLoading: false, selectedReclamation: action.payload };
    }

    case RECLAMATION_DELETEDEVICE_SUCCESS:
    case RECLAMATION_ADDDEVICE_SUCCESS: {
      return { ...state, isLoading: false, selectedReclamation: action.payload };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
