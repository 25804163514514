import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

/**
 * Panel()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Panel(props) {
  const {
    children, width, shadow, marginBottom, containerStyle, withPadding, containerRef, actionIcon, onAction, boxPadding,
    className,
  } = props;

  const getWidth = () => {
    if (width) {
      return width;
    }
    return '100%';
  };

  const mergeStyles = () => ({
    ...containerStyle, width: getWidth(), marginBottom, padding: boxPadding,
  });

  const renderContent = () => {
    if (withPadding) {
      return (
        <div className="card-body d-flex flex-column">
          {children}
        </div>
      );
    }
    return children;
  };

  return (
    <div
      className={`Panel card ${(shadow) ? 'Shadow' : null} ${className}`}
      style={mergeStyles()}
      ref={containerRef}
    >
      <Headline {...props} icon={actionIcon} onAction={onAction} />
      {renderContent()}
    </div>
  );
}

Panel.propTypes = {
  children: PropTypes.node,
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
  width: PropTypes.string,
  shadow: PropTypes.bool,
  marginBottom: PropTypes.number,
  containerStyle: PropTypes.instanceOf(Object),
  withPadding: PropTypes.bool,
  containerRef: PropTypes.instanceOf(Object),
  actionIcon: PropTypes.string,
  onAction: PropTypes.func,
  boxPadding: PropTypes.number,
  className: PropTypes.string,
};

Panel.defaultProps = {
  headline: '',
  subHeadline: '',
  width: '100%',
  shadow: true,
  marginBottom: 0,
  containerStyle: {},
  withPadding: true,
  containerRef: null,
  actionIcon: null,
  onAction: () => {},
  boxPadding: null,
  className: '',
  children: null,
};

/**
 * RenderHeader()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Headline(props) {
  const {
    headline, subHeadline, icon, onAction,
  } = props;

  const renderActionButton = () => {
    if (icon) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-light"
          onClick={onAction}
          tabIndex={-1}
        >
          <i className={icon} />
        </button>
      );
    }
    return null;
  };

  if (headline && !subHeadline) {
    return (
      <div className="card-header">
        <div className="d-flex justify-content-center">
          <div className="d-flex align-items-center flex-grow-1">
            {headline}
          </div>
          <div className="justify-content-center">
            {renderActionButton()}
          </div>
        </div>
      </div>
    );
  }
  if (headline && subHeadline) {
    return (
      <div className="card-header">
        <div className="d-flex justify-content-center">
          <div className="d-flex align-items-center flex-grow-1">
            {headline}
            <div className="card-subheadline">
              {subHeadline}
            </div>
          </div>
          <div className="justify-content-center">
            {renderActionButton()}
          </div>
        </div>
      </div>
    );
  }
  return null;
}

Headline.propTypes = {
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
  icon: PropTypes.string,
  onAction: PropTypes.func,
};

Headline.defaultProps = {
  headline: '',
  subHeadline: '',
  icon: null,
  onAction: () => {},
};
