/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { get, trackView } from '../../../Redux/Action/OrderAction';
import { hasOrderAccess } from '../../../Library/Functions';
import { ORDERTYPE_SINGLE } from '../../../Library/Types';
import LoadingModal from '../../../Components/LoadingModal';

/**
 * DetailsHandler()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function DetailsHandler(props) {
  const {
    client, dispatchGetOrder, isLoggedIn,
  } = props;
  const { orderId } = useParams();
  const [order, setOrder] = React.useState(null);

  useEffect(() => {
    dispatchGetOrder(orderId).then((response) => {
      setOrder(response);
    });
  }, [dispatchGetOrder, orderId]);

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  if (order) {
    if (order.type === ORDERTYPE_SINGLE) {
      const object = order.objects[0];
      if (hasOrderAccess(client, order)) {
        return <Redirect to={`/order/${orderId}/object/${object.objectId}`} />;
      }
      return <Redirect to="/dashboard" />;
    }
    return <Redirect to={`/order/${orderId}`} />;
  }
  return <LoadingModal visible text="Auftrag wird geladen..." />;
}

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    isLoading: store.order.isLoading,
    isLoggedIn: store.auth.isLoggedin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
    dispatchTrackView: (orderId) => dispatch(trackView(orderId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(DetailsHandler));
