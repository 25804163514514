import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import { OBJECT_DATE_TYPE_DELIVERY, OBJECT_DATE_TYPE_REMOVAL } from '../../../../Library/Types';

/**
 * AskSendDateChangedNotificationToPartner()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AskSendDateChangedNotificationToPartner(props) {
  const {
    object, dateType, visible, onClose, onSend,
  } = props;

  const getDateName = () => {
    if (dateType) {
      switch (dateType) {
        case OBJECT_DATE_TYPE_DELIVERY: return 'Lieferdatum';
        case OBJECT_DATE_TYPE_REMOVAL: return 'Demontagedatum';
        default: return 'Montagedatum';
      }
    }
    return '';
  };

  return (
    <ModalDialog
      onClose={onClose}
      visible={visible}
      confirmCaption="Benachrichtigung senden"
      onConfirm={() => onSend(object, dateType)}
      centered
    >
      <h5>Partner benachrichtigen?</h5>
      <p>
        Sie haben das
        <span>{` ${getDateName()} `}</span>
        geändert. Möchten Sie Ihren Partner über diese Änderung informieren? Ihr Partner erhält
        umgehend eine Benachrichtigung im System über die Änderung.
      </p>
    </ModalDialog>
  );
}

AskSendDateChangedNotificationToPartner.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  dateType: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

AskSendDateChangedNotificationToPartner.defaultProps = {
  dateType: null,
};
