import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

/**
 * SelectPanel()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function SelectPanel(props) {
  const {
    data, onChange, isMultiSelect, hasError, selected, returnSingleKey,
  } = props;

  const handleOnPress = (key) => {
    if (returnSingleKey) {
      onChange(key);
    } else {
      let tempSelected = [...selected];

      if (isMultiSelect) {
        if (tempSelected && tempSelected.indexOf(key) > -1) {
          tempSelected = tempSelected.filter((item) => item !== key);
        } else {
          tempSelected.push(key);
        }

        if (onChange) {
          onChange(tempSelected);
        }
      } else if (onChange) {
        onChange(key);
      }
    }
  };

  if (data) {
    return (
      <div className={`SelectPanel d-flex flex-row ${(hasError) ? 'Error' : ''}`}>
        {
          data.map(
            (item) => (
              <Card
                key={item.key}
                item={item}
                selected={((selected) ? selected.indexOf(item.key) > -1 : false)}
                onPress={(key) => handleOnPress(key)}
                disabled={(item.disabled) ? item.disabled : false}
              />
            ),
          )
        }
      </div>
    );
  }
  return null;
}

SelectPanel.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool,
  hasError: PropTypes.bool,
  selected: PropTypes.instanceOf(Array),
  returnSingleKey: PropTypes.bool,
};

SelectPanel.defaultProps = {
  isMultiSelect: false,
  hasError: false,
  selected: [],
  returnSingleKey: false,
};

/**
 * Card()
 * @param props
 * @returns {*}
 * @constructor
 */
function Card(props) {
  const {
    item, onPress, selected, disabled,
  } = props;
  const {
    caption, icon, key, image,
  } = item;

  const renderChecked = () => {
    if (selected) {
      return (
        <i className="fas fa-check-circle text-success CheckIcon" />
      );
    }
    return null;
  };

  const renderIcon = () => {
    if (icon) {
      return <i className={icon} />;
    }
    return <img src={image} alt="CompanyLogo" height={56} style={{ maxHeight: 56, maxWidth: '100%' }} />;
  };

  return (
    <div
      className={`card flex-fill text-center ${(selected) ? 'Active' : ''} ${(disabled) ? 'Disabled' : ''}`}
      style={{ width: '100%' }}
      onClick={(!disabled) ? () => onPress(key) : () => {}}
      role="button"
      tabIndex={-1}
      onKeyPress={() => {}}
    >
      <div className="card-body">
        {renderChecked()}
        {renderIcon()}
        <div>{caption}</div>
      </div>
    </div>
  );
}

Card.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Card.defaultProps = {
  selected: false,
  disabled: false,
};
