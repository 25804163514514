import {
  SYSTEM_VOUCHER_GETLIST_ERROR, SYSTEM_VOUCHER_GETLIST_START, SYSTEM_VOUCHER_GETLIST_SUCCESS,
} from '../Action/VoucherAction';

const defaultValues = {
  vouchers: null,
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case SYSTEM_VOUCHER_GETLIST_START: {
      return { ...state, isLoading: true };
    }
    case SYSTEM_VOUCHER_GETLIST_SUCCESS: {
      return { ...state, vouchers: action.payload, isLoading: false };
    }
    case SYSTEM_VOUCHER_GETLIST_ERROR: {
      return { ...state, vouchers: null, isLoading: false };
    }

    default: return { ...state };
  }
}
