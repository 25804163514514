/* eslint-disable react/no-unescaped-entities, max-len */

import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';

import './style.css';
import MaterialTable from './MaterialTable';

/**
 * AgbDialogMonteur()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function AgbDialogMonteur(props) {
  const { visible, onClose, isPopup } = props;

  if (false) {
    return (
      <Container onClose={onClose} visible={visible} isPopup={isPopup}>
        <h1>
          Allgemeine Geschäftsbedingungen für die Beauftragung von Nachunternehmern bei Auslieferungs-, Montage-
          bzw. Installations- und/oder Lagerleistungen von Küchen / "Allgemeine Nachunternehmerbedingungen"
          der hey.kitchen GmbH, Stand November 2019

        </h1>
        <p>
          Wir, die hey.kitchen GmbH (im Folgenden auch "HK" genannt), betreiben eine Online-Plattform unter der
          Bezeichnung "hey.kitchen" (im Folgenden "Plattform" genannt). Zum einen können auf der Plattform – als
          Kunden – registrierte Hauptauftraggeber (insbesondere Möbel-, Einrichtungshäuser, Küchenstudios etc.;
          im Folgenden "Hauptauftraggeber" genannt) Anfragen zur Erbringung von Auslieferungs-, Montage- und
          Lagerleistungen von Küchen, die von Endkunden der Hauptauftraggeber bestellt worden sind, einstellen.
          Diese Anfragen werden von HK als Hauptauftragnehmer – nach Maßgabe von hierfür gesonderten Allgemeinen
          Geschäftsbedingungen – bearbeitet und ggf. verbindlich angenommen.
        </p>
        <p>
          Zum anderen können auf der Plattform – als Monteure – registrierte Nachauftragnehmer (insbesondere
          Möbel- und Küchenmontageunternehmen, Umzugsunternehmen etc.; im Folgenden "Nachunternehmer" genannt)
          die von HK – als Hauptauftragnehmer – angenommenen Aufträge zur Auslieferungs-, Montage-/Installations-
          und/oder Lagerleistungen von Küchen, die HK sodann als Angebot für die Nachunternehmer auf der
          Plattform einstellt, als Nachunternehmer von HK – nach Maßgabe dieser Allgemeinen Geschäftsbedingungen
          – annehmen oder diesbezüglich gegenüber HK ein neues Angebot abgeben.
        </p>

        <ol className="Main">
          <li>
            Geltung
            <ol className="Sub">
              <li>
                Alle Lieferungen, Leistungen und Angebote der Nachunternehmer, die diese für uns im Bereich
                Auslieferungs-, Montage- bzw. Installations- und/oder Lagerleistungen von Küchen, Küchenteilen,
                Küchenwaren erbringen und / oder abgeben, erfolgen ausschließlich aufgrund dieser Allgemeinen
                Geschäftsbedingungen für die Beauftragung von Nachunternehmern für Auslieferungs-, Montage- und
                Lagerleistungen von Küchen (im Folgenden kurz: "Allgemeine Nachunternehmerbedingungen " genannt).
                Diese sind Bestandteil aller Verträge, die wir mit den Nachunternehmern über die von ihnen
                angenommenen oder angebotenen Lieferungen oder Leistungen im Bereich Auslieferungs-, Montage-
                und Lagerleistungen von Küchen schließen. Sie gelten auch für alle zukünftigen Lieferungen,
                Leistungen oder Angebote durch oder an uns, selbst wenn sie nicht nochmals gesondert vereinbart
                werden.
              </li>
              <li>
                Sofern der Nachunternehmer uns gegenüber ausschließlich speditionsübliche Leistungen, insbesondere
                nur die Beförderung und/oder Lagerung von Waren erbringt, erfolgt dies unter Beachtung der
                einschlägigen Vorgaben der europäischen Union sowie auf Grundlage der jeweils aktuell geltenden
                Allgemeinen Deutschen Spediteurbedingungen (gegenwärtig: ADSp 2017) und zwingender im
                europäischen Transport/Speditionsbereich eingreifender Vorschriften (insbesondere CMR).
                Ergänzend zu den jeweils geltenden gesetzlichen Grundlagen und jeweils nationalen
                Spediteurbedingungen gelten diese Allgemeinen Nachunternehmerbedingungen.
              </li>
              <li>
                Geschäftsbedingungen unserer Nachunternehmer oder Dritter finden keine Anwendung, auch wenn wir
                ihrer Geltung im Einzelfall nicht gesondert widersprechen. Selbst wenn wir auf ein Schreiben
                Bezug nehmen, das Geschäftsbedingungen des Nachunternehmers oder eines Dritten enthält oder
                auf solche verweist, liegt darin kein Einverständnis mit der Geltung jener Geschäftsbedingungen.
              </li>
            </ol>
          </li>

          <li>
            Vertragsschluss
            <ol className="Sub">
              <li>
                Durch Einstellung eines Angebots eines Nachunternehmerauftrages auf der Plattform machen wir
                ein verbindliches Angebot.
              </li>
              <li>
                Der Nachunternehmer kann ein Angebot verbindlich annehmen oder uns vor dem im Angebot
                ausgewiesenen Leistungs-/Liefertermin (im Folgenden auch kurz: "Auftragsdatum" genannt) ein
                neues Angebot machen.
              </li>
              <li>
                Nimmt der Nachunternehmer ein Angebot an, kommt der Nachunternehmervertrag – nach Maßgabe
                dieser Allgemeinen Nachunternehmerbedingungen – zustande (im Folgenden "Vertrag" und/oder
                "Einzelauftrag" genannt). Wir werden dem Nachunternehmer unverzüglich nach Eingang seiner
                Annahme eine Bestätigung über den Erhalt der Annahme und den Vertragsschluss zum jeweiligen
                Einzelauftrag zusenden.
              </li>
              <li>
                Macht der Nachunternehmer uns gegenüber ein neues Angebot, werden wir dem Nachunternehmer
                unverzüglich nach Eingang des Angebots eine Bestätigung über den Erhalt des Angebots zusenden,
                die keine Annahme des Angebots darstellt. Das Angebot gilt erst als von uns angenommen, sobald
                wir gegenüber dem Nachunternehmer (per E-Mail) die Annahme erklären. Der Nachunternehmervertrag
                mit dem Nachunternehmer kommt erst mit unserer Annahme zustande.
              </li>
              <li>
                Durch den Abschluss eines Einzelauftrages und/oder mit diesen Allgemeinen
                Nachunternehmerbedingungen entsteht für keine der Vertragsparteien ein durchsetzbarer
                Anspruch auf die Erteilung von Einzelaufträgen und/oder die Annahme von angebotenen Einzelaufträgen.
              </li>
              <li>
                Wir sind berechtigt, gleich aus welchem Grund, Zeit und Ort der Leistungen sowie Art und Umfang
                der Leistung jederzeit durch schriftliche Mitteilung vor dem vereinbarten Leistungs-/Liefertermin
                (= Auftragsdatum) zu ändern, sofern dies zwingend erforderlich und nicht unzumutbar ist.
                Gleiches gilt für sämtliche (Teil-)Änderungen in Bezug auf die nach Maßgabe des jeweiligen
                Einzelauftrages auszuliefernden, zu montierenden bzw. anzuschließenden und/oder zu lagernden
                Gegenstände bzw. Küchen. Der Nachunternehmer ist verpflichtet, einem solchen Änderungsverlangen
                nachzukommen, soweit dieses nicht unzumutbar ist. Haben solche Änderungen Leistungs-/
                Lieferverzögerungen zur Folge, die sich nicht im normalen Produktions- und Geschäftsbetrieb des
                Nachunternehmers mit zumutbaren Anstrengungen vermeiden lassen, verschiebt sich der ursprünglich
                vereinbarte Leistungs-/Liefertermin entsprechend. Der Nachunternehmer wird uns die von ihm bei
                sorgfältiger Einschätzung zu erwartenden Mehr- oder Minderkosten oder Leistungs-/
                Lieferverzögerungen rechtzeitig vor dem Leistungs-/Liefertermin, mindestens jedoch innerhalb
                von 3 Kalendertagen nach Zugang unserer Mitteilung gemäß Satz 1 schriftlich anzeigen.
              </li>
              <li>
                Wir sind berechtigt, jederzeit durch schriftliche Erklärung unter Angabe des Grundes vom
                Vertrag/Einzelauftrag zurückzutreten, wenn wir die beauftragten Leistungen in unserem
                Geschäftsbetrieb aufgrund von nach Vertragsschluss eingetretenen, vom Nachunternehmer zu
                vertretenen Umständen (wie z.B. die fehlende Einhaltung von gesetzlichen und/oder vertraglichen
                Anforderungen) nicht mehr oder nur mit erheblichen Aufwendungen verwenden können oder sich
                die Vermögensverhältnisse des Nachunternehmers nach Vertragsschluss derart verschlechtern,
                dass mit einer vertragsgemäßen Lieferung nicht zu rechnen ist. In diesem Fall entstehen jeweils
                keinerlei Ansprüche des Nachunternehmers auf Ersatz- und/oder Stornokosten.
              </li>
              <li>
                Wir sind darüber hinaus berechtigt, mit einer Frist von 14 Kalendertagen vor dem Leistungs-/
                Liefertermin (= Auftragsdatum) durch schriftliche Erklärung ohne Angabe des Grundes vom Vertrag
                /Einzelauftrag zurückzutreten. In diesem Fall entstehen keinerlei Ansprüche des Nachunternehmers
                auf Ersatz- und/oder Stornokosten.
              </li>
              <li>
                Treten wir innerhalb von 14 Kalendertagen vor dem Leistungs-/Liefertermin (= Auftragsdatum)
                zurück, so verliert der Nachunternehmer seinen Anspruch auf die Vergütung. Stattdessen kann
                er eine angemessene Entschädigung von uns verlangen, es sei denn, dass der Rücktritt nicht von
                uns aus zu vertreten ist oder am Leistungs-/Lieferort oder in dessen Nähe unvermeidbare und/oder
                außergewöhnliche Umstände auftreten, die die Durchführung der vom Nachunternehmer zu erbringenden
                Leistungen erheblich beeinträchtigen. Umstände sind unvermeidbar und/oder außergewöhnlich, wenn
                sie nicht unserer Kontrolle unterliegen und sich ihre Folgen auch dann nicht hätten vermeiden
                lassen, wenn alle zumutbaren Vorkehrungen getroffen worden wären.
                <br />
                <br />
                Die Höhe der dem Nachunternehmer hiernach zustehenden Entschädigung ist in Form von Stornokosten
                wie folgt pauschaliert:

                <ul className="SubListing">
                  <li>
                    Erfolgt der Rücktritt durch uns innerhalb von 14 Kalendertagen aber mit einer Frist von 7
                    Kalendertagen vor dem Leistungs-/Liefertermin (= Auftragsdatum), so beträgt die von uns an
                    den Nachunternehmer zu leistende Entschädigung 25 % der vereinbarten Vergütung.
                  </li>
                  <li>
                    Erfolgt der Rücktritt durch uns innerhalb von 7 Kalendertagen aber mit einer Frist von 3
                    Kalendertagen vor dem Leistungs-/Liefertermin (= Auftragsdatum), so beträgt die von uns an
                    den Nachunternehmer zu leistende Entschädigung 50 % der vereinbarten Vergütung.
                  </li>
                  <li>
                    Erfolgt der Rücktritt durch uns innerhalb von 3 Kalendertagen vor dem Leistungs-/Liefertermin
                    (= Auftragsdatum), so beträgt die von uns an den Nachunternehmer zu leistende Entschädigung
                    100% der vereinbarten Vergütung.
                  </li>
                </ul>
                Es bleibt uns der Nachweis gestattet, die dem Nachunternehmer zustehende angemessene Entschädigung
                sei wesentlich niedriger als die ihm hiernach zustehenden Stornokosten.
              </li>
              <li>
                Im Übrigen haftet der Nachunternehmer bei schuldhafter Nichteinhaltung der Leistungs-/Liefertermine
                (= Auftragsdatum) für sämtliche Schäden und Nachteile, die uns und/oder Dritten entstehen.
              </li>
            </ol>
          </li>

          <li>
            Gegenstand der Leistungen des Nachunternehmers
            <ol className="Sub">
              <li>
                Der Nachunternehmer übernimmt – nach Maßgabe dieser Allgemeinen Nachunternehmerbedingungen – je
                nach Einzelauftrag für uns folgende Leistungen:
                <ul className="SubListing">
                  <li>
                    Auslieferung, Montage bzw. Installation von Küchen, Küchenteilen, Küchenwaren (inklusive
                    Installation sämtlicher Anschlüsse und Geräte) und/oder
                  </li>
                  <li>
                    Kundendienstleistungen (wie insbesondere die Bearbeitung von Reklamationen) und/oder
                  </li>
                  <li>
                    Einholung von Einziehungsermächtigung von Geldforderungen und/oder
                  </li>
                  <li>
                    Lagerung von Küchen, Küchenteilen, Küchenwaren, Geräten und Installationsprodukten.
                  </li>
                </ul>
              </li>
              <li>
                Der Nachunternehmer hat die Leistung, soweit nicht ausdrücklich Abweichendes vereinbart worden ist,
                höchstpersönlich zu erbringen. Eine Übertragung von Leistungen und/oder Teilleistungen auf
                Subunternehmer des Nachunternehmers ist nur nach vorheriger schriftlicher Zustimmung von uns
                zulässig. Der Nachunternehmer steht für seine Subunternehmer ein wie für eigenes Handeln.
              </li>
              <li>
                Der Nachunternehmer ist – unter Beachtung der vertraglich vereinbarten Bedingungen – frei in der
                Erbringung seiner Leistungen. Er ist stets und ausschließlich selbständig im eigenen Namen und
                für eigene Rechnung tätig; er ist nicht berechtigt, uns rechtsgeschäftlich oder anderweitig zu
                vertreten.
              </li>
            </ol>
          </li>

          <li>
            Auslieferung, Montage, Installation und/oder Lagerung von Küchen und Elektrogeräten
            <ol className="Sub">
              <li>
                Der Nachunternehmer ist uns gegenüber zur vertragsgemäßen, vollständigen, termingerechten sowie
                sach- und fachgerechten Ausführung seiner Leistungen verpflichtet, und zwar – je nach Gegenstand
                des Vertrag/Einzelauftrages – insbesondere

                <ul className="SubListing">
                  <li>
                    zur termin-, sach- und fachgerechten Auslieferung und Montage von Küchen bzw. Küchenteilen
                    an/bei den Endkunden des Hauptauftraggebers und/oder
                  </li>
                  <li>
                    zum termin-, sach- und fachgerechten sowie funktionsfähigen Anschluss der Küche bzw.
                    Küchenteilen, hierbei insbesondere zum sach- und fachgerechten sowie funktionsfähigen
                    Anschluss von Einbau- und/oder Elektrogeräten, Wasser- und/oder Abwasseranschlüssen bei den
                    Endkunden des Hauptauftraggebers und/oder
                  </li>
                  <li>
                    zur sach- und fachgerechten Lagerung von Küchen bzw. Küchenteilen für den Endkunden des
                    Hauptauftraggebers.
                  </li>
                </ul>
              </li>
              <li>
                Der Nachunternehmer ist hierbei verpflichtet, seine Leistungen auf eigene Kosten und nur durch
                einwandfreies Material (insbesondere Fahrzeuge, Werkzeug) sowie geschultes, zuverlässiges
                Fachpersonal nach den anerkannten Regeln der Technik und des Handwerks zu erbringen.
              </li>
              <li>
                Der Nachunternehmer übernimmt die – nach Maßgabe des jeweiligen Einzelauftrages – auszuliefernde
                bzw. zu montierende/zu installierende Ware an dem von uns vorgegebenen Zeitpunkt an dem von im
                Einzelauftrag angegebenen Abholort. Mit der zur Übernahme für den Nachunternehmer bereitgestellten
                Ware werden dem Nachunternehmer von dem Hauptauftraggeber die entsprechenden Lieferpapiere
                übergeben. Der Nachunternehmer ist im Falle der durch ihn erfolgten Lagerung verpflichtet,
                den Bereitstellungsschein/Lieferschein innerhalb von 24 Stunden in der von uns zur Verfügung
                gestellten Software/mobilen Applikation hochzuladen. Der Nachunternehmer ist verpflichtet, vor
                Übernahme der Ware diese auf sichtbare Unversehrtheit und Vollständigkeit zu prüfen; ist die Ware
                sichtbar unversehrt und vollständig, hat der Nachunternehmer dies auf dem Bereitstellungsschein
                /Lieferschein entsprechend zu bestätigen. Gefahr und Haftung für die Ware gehen nach der
                Bestätigung der Unversehrtheit und Vollständigkeit durch den Nachunternehmer vollständig auf
                diesen über; der Nachauftragnehmer haftet ab diesem Zeitpunkt für Verlust an der Ware und
                Schäden, sofern er diese grob fahrlässig oder vorsätzlich verursacht hat.
              </li>
              <li>
                Der Nachunternehmer ist verpflichtet, Schäden und Verlust an der Ware, die ab Unterzeichnung
                des Bereitstellungsscheins/Lieferscheins gemäß vorstehender Ziffer 4.3 an der Ware eintreten,
                unverzüglich zu ersetzen und zu regulieren, sofern er diese grob fahrlässig oder vorsätzlich
                verursacht hat. Der Nachunternehmer ist verpflichtet, einen Nachweis einer entsprechenden
                Versicherung bei uns zu hinterlegen.
              </li>
              <li>
                Der Nachunternehmer ist verpflichtet, die von uns zur Verfügung gestellte Software/mobile
                Applikation (im Folgenden kurz: "hk-App" genannt) zu nutzen, über welche während der
                Auftragsabwicklung durch den Nachunternehmer Daten und Formulare übertragen und erzeugt werden
                können, auf welche dann zentral zugegriffen werden kann.
              </li>
              <li>
                Der Nachunternehmer ist verpflichtet, das von uns im Rahmen der hk-App bereit gestellte
                Abnahmeprotokoll über die vom Nachunternehmer erbrachten Leistungen (insbesondere Auslieferung
                und Montage der Küche und der Geräte) vollständig auszufüllen und dieses von dem Endkunden des
                Hauptauftraggebers und dem Nachunternehmer (bzw. einem beteiligten Monteur) unterzeichnen zu
                lassen. Der Nachunternehmer ist zudem verpflichtet, sich von dem Endkunden zu den noch offenen
                und auf dem Lieferschein/Rechnungsformular des Hauptauftraggebers ausgewiesen Restbeträgen –
                nach Maßgabe von nachstehender Ziffer 4. dieser Allgemeinen Nachunternehmerbedingungen – die
                im Rahmen der hk-App  für den Hauptauftraggeber zur Verfügung gestellte Einzugsermächtigung
                ausfüllen und unterzeichnen zu lassen.
              </li>
              <li>
                Für den Fall, dass der Nachunternehmer einen Endkunden an der Lieferanschrift zum angegebenen
                Lieferzeitpunkt nicht antrifft, ist der Nachunternehmer verpflichtet, mit uns unverzüglich
                telefonisch Kontakt aufzunehmen und die weitere Vorgehensweise mit uns abzustimmen.
              </li>
              <li>
                Der Nachunternehmer ist verpflichtet, uns sämtliche Reklamationen, Reklamationsprotokolle und/
                oder Abnahmeprotokolle und digitale Fotoaufnahmen (Fotoaufnahme sind dabei auf durch den
                Nachunternehmer im Rahmen der hk-App zur Verfügung zu stellen) unmittelbar zur Verfügung zu stellen.
              </li>
            </ol>
          </li>

          <li>
            Einholung einer Einziehungsermächtigung
            <ol className="Sub">
              <li>
                Ein etwaiger (Rest-)Kaufpreis für die vom Nachunternehmer für den Hauptauftraggeber ausgelieferten
                bzw. montierten Küchen wird von dem jeweiligen Endkunden mittels einer im Rahmen der hk-App zur
                Verfügung gestellten Einzugsermächtigung zugunsten des Hauptauftraggebers eingezogen. Der
                Nachunternehmer wird dem Endkunden die so zur Verfügung gestellte Einzugsermächtigung am
                Erstmontagetermin vor Beginn der Auslieferungs- und/oder Montage-/Installationsleistungen
                vorlegen und von diesem ausfüllen und unterzeichnen lassen.
              </li>
              <li>
                Der Nachunternehmer erhält von uns oder dem Hauptauftraggeber ausdrücklich keine Inkassovollmacht.
                Er ist nicht berechtigt und ihm ist ausdrücklich nicht gestattet, etwaige Zahlungsmittel vom
                Endkunden – auch nicht treuhänderisch – in Empfang zu nehmen.
              </li>
            </ol>
          </li>

          <li>
            Kundendienst / Bearbeitung von Reklamationen
            <ol className="Sub">
              <li>
                Der Nachunternehmer ist zur sach- und fachgerechten Erbringung von Kundendienstleistungen an
                Küchen, wie insbesondere auch zur Bearbeitung und Behebung von Reklamationen, beim Endkunden
                des Hauptauftraggebers verpflichtet.
              </li>
              <li>
                Im Falle von Reklamationen ist der Nachunternehmer verpflichtet, diese Reklamationen unverzüglich
                beim Endkunden nach Maßgabe der nachfolgenden Bestimmungen zu bearbeiten:

                <ul className="SubListing">
                  <li>
                    Die erste Stunde Bearbeitung und Behebung der ersten Reklamation des Endkunden durch uns ist mit
                    der in dem schriftlich bestätigten Vertrag/Einzelauftrag ausgewiesenen Vergütung gemäß
                    nachstehender Ziffer 9.1 abgedeckt.; Sämtliche darüber hinausgehenden Reklamationsleistungen
                    durch uns werden – gegen entsprechenden Nachweis durch uns – mit - pauschal € 35,00 netto pro
                    Arbeitsstunde und pro Monteur vergütet.
                  </li>
                  <li>
                    Die Termine zur Bearbeitung und Behebung von Reklamationen durch den Nachunternehmer werden
                    vom Hauptauftraggeber – nach Abstimmung mit dem Endkunden – durch Mitteilung einer in
                    Betracht kommenden Kalenderwoche vorgegeben. Der Nachunternehmer ist verpflichtet, uns
                    gegenüber drei Terminvorschläge (mit entsprechendem Datum und Uhrzeit) für die vom
                    Hauptauftraggeber vorgegebene Kalenderwoche zu nennen; nach Abstimmung mit dem
                    Hauptauftraggeber werden wir einen dieser vom Nachunternehmer genannten Termine bestätigen,
                    sofern dies tatsächlich möglich ist. Andernfalls muss vom Hauptauftraggeber solange eine
                    andere Kalenderwoche vorgegeben werden, bis einer der diesbezüglich vom Nachunternehmer
                    genannten Termine tatsächlich bestätigt werden kann.
                  </li>
                </ul>
              </li>
              <li>
                Die Vorschriften zur Gewährleistung gemäß nachstehender Ziffer 12. bleiben hiervon unberührt.
              </li>
            </ol>
          </li>

          <li>
            Sonstige Pflichten des Nachunternehmers
            <ol className="Sub">
              <li>
                Der Nachunternehmer ist verpflichtet, das Verpackungsmaterial der an den Endkunden ausgelieferten
                Ware sowie den im Rahmen der vertraglichen Arbeiten anfallenden Abfall sach- und fachgerecht zu
                entsorgen.
              </li>
              <li>
                Für zusätzliche Materialien, die der Nachunternehmer für die Erbringung seiner Leistungen,
                insbesondere für den Elektro- und/oder Sanitäranschluss, benötigt, gilt unsere jeweils gültige
                Preisliste

                <div style={{ padding: 30 }}>
                  <MaterialTable type="MONTEUR" />
                </div>
              </li>
              <li>
                Sofern gesetzlich vorgeschrieben (insbesondere aufgrund des ElektroG), ist der Nachunternehmer
                verpflichtet, für den Hauptauftraggeber Altgeräte vom Endkunden zurückzunehmen und an den
                Hauptauftraggeber zu übergeben.
              </li>
            </ol>
          </li>

          <li>
            Versicherungen
            <ol className="Sub">
              <li>
                Der Nachunternehmer ist verpflichtet, die üblichen Risiken seiner Tätigkeiten, insbesondere die
                mit der Lagerung, der Auslieferung, der Installation und Montage der Ware verbundenen Risiken mit
                den hierfür üblichen und angemessenen Deckungssummen auf eigene Kosten zu versichern (z.B.
                Lagerversicherung, Transportversicherung, Haftpflichtversicherung etc.); diese Versicherungen
                sind während der Dauer des Vertrages und der Gewährleistungsfrist bzw. Garantiefrist zu unterhalten
                und uns gegenüber auf Verlangen schriftlich nachzuweisen.
              </li>
            </ol>
          </li>

          <li>
            Vergütung, Zahlungsbedingungen, Rechnungsangaben
            <ol className="Sub">
              <li>
                Die in dem von uns gegenüber dem Nachunternehmer schriftlich bestätigten Vertrag/Einzelauftrag
                ausgewiesene Vergütung ist bindend. Die Vertragspreise sind Festpreise.
              </li>
              <li>
                In den Preisen ist alles enthalten, was zur ordnungsgemäßen, vollständigen und termingerechten
                Ausführung der Leistungen durch den Nachunternehmer erforderlich ist, sowie sämtliche Kosten, die
                zur Erfüllung der vertraglichen Verpflichtungen des Nachunternehmers anfallen.
              </li>
              <li>
                Sofern nicht etwas anderes vereinbart ist, zahlen wir ab ordnungsgemäßer, vollständiger und
                termingerechter Erbringung der Leistung (z.B. nach ordnungsgemäßer, vollständiger und
                termingerechter Erstmontage der Küche) und Rechnungserhalt die vereinbarte Vergütung innerhalb
                von 14 Kalendertagen. Für die Rechtzeitigkeit der von uns geschuldeten Zahlungen genügt der
                Eingang unseres Überweisungsauftrages bei unserer Bank.
              </li>
              <li>
                In sämtlichen Auftragsbestätigungen, Lieferpapieren und Rechnungen sind unsere Auftragsnummer,
                die Artikel-Nr., Leistungs-/Liefermenge und Endkundenanschrift anzugeben. Sollten eine oder
                mehrere dieser Angaben fehlen und sich dadurch im Rahmen unseres normalen Geschäftsverkehrs die
                Bearbeitung durch uns verzögern, verlängern sich die in Ziffer 9.3 genannten Zahlungsfristen
                um den Zeitraum der Verzögerung.
              </li>
              <li>
                Eine Aufrechnung durch uns ist jederzeit und uneingeschränkt zulässig.
              </li>
              <li>
                Bei Zahlungsverzug schulden wir Verzugszinsen in Höhe von 5 Prozentpunkten über dem Basiszinssatz
                p.a. gem. § 288 Abs. 1 BGB.
              </li>
            </ol>
          </li>

          <li>
            Lieferzeit und Lieferung, Gefahrübergang
            <ol className="Sub">
              <li>
                Die im jeweiligen verbindlich zustande gekommenen Vertrag/Einzelauftrag angegebene oder sonst
                nach diesen Allgemeinen Nachunternehmerbedingungen maßgebliche Leistungs-/Lieferzeit (Leistungs-
                /Liefertermin oder -frist) ist bindend. Vorzeitige Leistungen-/Lieferungen sind nicht zulässig.
              </li>
              <li>
                Der Nachunternehmer ist verpflichtet, uns unverzüglich schriftlich zu informieren, wenn Umstände
                eintreten oder erkennbar werden, wonach die Leistungs-/Lieferzeit nicht eingehalten werden kann.
              </li>
              <li>
                Lässt sich der Tag, an dem die Leistung-/Lieferung spätestens zu erfolgen hat, aufgrund des
                Vertrages bestimmen, so kommt der Nachunternehmer mit Ablauf dieses Tages in Verzug, ohne dass es
                hierfür einer Mahnung unsererseits bedarf.
              </li>
              <li>
                Im Falle des Leistungs-/Lieferverzugs stehen uns uneingeschränkt die gesetzlichen Ansprüche zu,
                wobei wir erst nach fruchtlosem Ablauf einer angemessenen Nachfrist ein Rücktrittsrecht ausüben
                oder Ansprüche auf Schadensersatz statt der Leistung geltend machen können.
              </li>
              <li>
                Der Nachunternehmer ist ohne unsere vorherige schriftliche Zustimmung zu Teillieferungen
                nicht berechtigt.
              </li>
            </ol>
          </li>

          <li>
            Eigentumssicherung
            <ol className="Sub">
              <li>
                An von uns abgegebenen Bestellungen, Aufträgen sowie dem Nachunternehmer zur Verfügung gestellten
                Zeichnungen, Abbildungen, Berechnungen, Beschreibungen und anderen Unterlagen behalten wir uns –
                soweit diese nicht ohnehin Dritten zustehen – das Eigentum oder Urheberrecht vor. Der
                Nachunternehmer darf sie ohne unsere ausdrückliche Zustimmung weder Dritten zugänglich machen
                noch selbst oder durch Dritte nutzen oder vervielfältigen. Er hat diese Unterlagen auf unser
                Verlangen vollständig an uns zurückzugeben, wenn sie von ihm im ordnungsgemäßen Geschäftsgang
                nicht mehr benötigt werden oder wenn Verhandlungen nicht zum Abschluss eines Vertrages führen.
                Vom Nachunternehmer hiervon angefertigte Kopien sind in diesem Fall zu vernichten; ausgenommen
                hiervon sind nur die Aufbewahrung im Rahmen gesetzlicher Aufbewahrungspflichten sowie die
                Speicherung von Daten zu Sicherungszwecken im Rahmen der üblichen Datensicherung.
              </li>
              <li>
                Eigentumsvorbehalte des Nachunternehmers gelten nur, soweit sie sich auf unsere
                Zahlungsverpflichtung für solche Produkte beziehen, an denen der Nachunternehmer sich das
                Eigentum vorbehält. Insbes. sind erweiterte oder verlängerte Eigentumsvorbehalte unzulässig.
              </li>
            </ol>
          </li>

          <li>
            Gewährleistungsansprüche
            <ol className="Sub">
              <li>
                Bei Mängeln stehen uns uneingeschränkt die gesetzlichen Ansprüche zu. Die Gewährleistungsfrist
                beträgt jedoch abweichend hiervon 30 Monate.
              </li>
              <li>
                Qualitäts- und Quantitätsabweichungen sind jedenfalls rechtzeitig gerügt, wenn wir sie dem
                Nachunternehmer innerhalb von 14 Kalendertagen seit Eingang einer Reklamation bei uns mitteilen.
                Versteckte Sachmängel sind jedenfalls rechtzeitig gerügt, wenn die Mitteilung innerhalb von 30
                Kalendertagen nach Entdeckung an den Nachunternehmer erfolgt.
              </li>
              <li>
                Mit dem Zugang unserer schriftlichen Mängelanzeige beim Nachunternehmer ist die Verjährung von
                Gewährleistungsansprüchen gehemmt, bis der Nachunternehmer unsere Ansprüche ablehnt oder den
                Mangel für beseitigt erklärt oder sonst die Fortsetzung von Verhandlungen über unsere Ansprüche
                verweigert. Bei Ersatzlieferung und Mängelbeseitigung beginnt die Gewährleistungsfrist für
                ersetzte und nachgebesserte Teile erneut, es sei denn, wir mussten nach dem Verhalten des
                Nachunternehmers davon ausgehen, dass dieser sich nicht zu der Maßnahme verpflichtet sah,
                sondern die Ersatzlieferung oder Mängelbeseitigung nur aus Kulanzgründen oder ähnlichen Gründen
                vornahm.
              </li>
            </ol>
          </li>

          <li>
            Haftung
            <ol className="Sub">
              <li>
                Der Nachunternehmer haftet nach gesetzlichen Maßstäben.
              </li>
              <li>
                Unsere Haftung, gleich aus welchem Rechtsgrund, ist auf Vorsatz und grobe Fahrlässigkeit
                beschränkt.
              </li>
              <li>
                Daneben haften wir auch für eine einfache fahrlässige Verletzung von für die Vertragserfüllung
                wesentlichen und die Erreichung des Vertragszwecks sichernden Pflichten (sog. Kardinalpflichten);
                diesbezüglich ist in die Haftung auf den vorhersehbaren, vertragstypischen Schaden beschränkt.
              </li>
              <li>
                Die Haftungsbeschränkungen gemäß Ziffern 13.2 ff. finden auch auf die Haftung für unsere
                Erfüllungs- und Verrichtungsgehilfen entsprechende Anwendung.
              </li>
              <li>
                Die Haftungsbeschränkungen gemäß Ziffern 13.2 ff. gelten nicht bei Verletzung von Leben,
                Körper und/oder Gesundheit, bei Übernahme einer Garantie oder des Beschaffungsrisikos, sowie
                bei dem Vorliegen von Arglist.
              </li>
              <li>
                Die Haftungsbeschränkungen gemäß Ziffern 13.2 ff. gelten zudem nicht in Fällen, in denen
                eine Haftung gesetzlich vorgeschrieben ist (z. B. ProdHaftG), sowie in sonstigen Fällen,
                in denen eine Beschränkung der Haftung durch Rechtsgeschäft nicht möglich ist.
              </li>
            </ol>
          </li>

          <li>
            Schutzrechte
            <ol className="Sub">
              <li>
                Der Nachunternehmer steht nach Maßgabe der nachstehenden Ziffer 14.2 dafür ein, dass durch ein –
                etwaig – von ihm geliefertes (fehlerhaftes) Produkt und/oder die von ihm erbrachten Leistungen
                keine Schutzrechte Dritter in Ländern der Europäischen Union oder anderen Ländern verletzt werden.
              </li>
              <li>
                Der Nachunternehmer ist verpflichtet, uns von allen Ansprüchen freizustellen, die Dritte gegen
                uns wegen der in Ziffer 14.1 genannten Verletzung von gewerblichen Schutzrechten erheben, und
                uns alle notwendigen Aufwendungen im Zusammenhang mit dieser Inanspruchnahme zu erstatten. Dies
                gilt nicht, soweit der Nachunternehmer nachweist, dass er die Schutzrechtsverletzung weder zu
                vertreten hat noch bei Anwendung kaufmännischer Sorgfalt zum Zeitpunkt der Lieferung hätte
                kennen müssen.
              </li>
              <li>
                Unsere weitergehenden gesetzlichen Ansprüche wegen Rechtsmängeln der uns gegenüber erbrachten
                Leistungen bleiben unberührt.
              </li>
            </ol>
          </li>

          <li>
            Geheimhaltung
            <ol className="Sub">
              <li>
                Der Nachunternehmer ist verpflichtet, die Bedingungen des jeweiligen Einzelauftrages sowie
                sämtliche ihm für diesen Zweck zur Verfügung gestellten Informationen und Unterlagen (mit
                Ausnahme von öffentlich zugänglichen Informationen) für einen Zeitraum von 5 Jahren nach
                Vertragsschluss geheim zu halten und nur zur Ausführung des Einzelauftrages zu verwenden. Er
                wird sie nach Erledigung von Anfragen oder nach Abwicklung des Einzelauftrages auf Verlangen
                umgehend an uns zurückgeben.
              </li>
              <li>
                Ohne unsere vorherige schriftliche Zustimmung darf der Nachunternehmer in Werbematerial,
                Broschüren etc. nicht auf die Geschäftsverbindung hinweisen und für uns gefertigte
                Liefergegenstände nicht ausstellen.
              </li>
              <li>
                Der Nachunternehmer wird seine Subunternehmer – sofern solche nach Maßgabe dieser Allgemeinen
                Nachunternehmerbedingungen eingesetzt werden dürfen – entsprechend dieser Ziffer 15. verpflichten.
              </li>
            </ol>
          </li>

          <li>
            Abtretung
            <ol className="Sub">
              <li>
                Der Nachunternehmer ist nicht berechtigt, seine Forderungen aus dem Vertragsverhältnis an
                Dritte abzutreten. Dies gilt nicht, soweit es sich um Geldforderungen handelt.
              </li>
            </ol>
          </li>

          <li>
            Einhaltung von Gesetzen
            <ol className="Sub">
              <li>
                Der Nachunternehmer ist verpflichtet, im Zusammenhang mit dem Vertragsverhältnis die jeweils
                für ihn maßgeblichen gesetzlichen Bestimmungen einzuhalten. Dies betrifft insbesondere
                Antikorruptions- und Geldwäschegesetze sowie kartellrechtliche, arbeits- und umweltschutzrechtliche
                Vorschriften. Auf unsere Verlangen hat der Nachunternehmer uns gegenüber entsprechende
                schriftliche Nachweise hierüber vorzulegen. Der Nachunternehmer haftet vollumfänglich für
                etwaige uns oder Dritten durch die Nichteinhaltung von für ihn maßgeblichen gesetzlichen
                Bestimmungen entstehende Schäden und hat uns diesbezüglich vollumfänglich freizustellen.
              </li>
              <li>
                Der Nachunternehmer wird sicherstellen, dass die von ihm erbrachten Leistungen allen maßgeblichen
                Anforderungen an das Inverkehrbringen in der Europäischen Union und im Europäischen
                Wirtschaftsraum genügen. Er hat uns die Konformität auf Verlangen durch Vorlage geeigneter
                Dokumente nachzuweisen.
              </li>
              <li>
                Der Nachunternehmer wird zumutbare Anstrengungen unternehmen, um die Einhaltung der in dieser
                Ziffer 17. enthaltenen, den Nachunternehmer treffenden Verpflichtungen durch seine Subunternehmer
                – sofern solche nach Maßgabe dieser Allgemeinen Nachunternehmerbedingungen eingesetzt werden
                dürfen – sicherzustellen.
              </li>
            </ol>
          </li>

          <li>
            Gerichtsstand, anwendbares Recht
            <ol className="Sub">
              <li>
                Ausschließlicher Gerichtsstand für alle Streitigkeiten aus dem Vertragsverhältnis ist Köln.
              </li>
              <li>
                Die zwischen uns und dem Nachunternehmer geschlossenen Verträge unterliegen dem Recht der
                Bundesrepublik Deutschland unter Ausschluss des Übereinkommens über den internationalen Warenkauf
                (UN-Kaufrechtsübereinkommen/CISG).
              </li>
            </ol>
          </li>

        </ol>
      </Container>
    );
  }
  return null;
}

AgbDialogMonteur.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  isPopup: PropTypes.bool,
};

AgbDialogMonteur.defaultProps = {
  visible: false,
  isPopup: true,
  onClose: () => {},
};
