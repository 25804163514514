import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Toolipp from 'react-tooltip-lite';
import { CLIENTTYPE_MONTEUR } from '../../../../Library/Types';

/**
 * MapMarker()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function MapMarker(props) {
  const {
    // eslint-disable-next-line no-unused-vars
    address, clients, lat, lng,
  } = props;
  const [client, setClient] = React.useState(null);

  React.useEffect(() => {
    if (address && clients) {
      const clientFiltered = clients.filter((item) => item.clientId === address.clientId)[0];
      setClient(clientFiltered);
    }
  }, [address, clients]);

  if (client) {
    if (client.type === CLIENTTYPE_MONTEUR) {
      return (
        <Toolipp content={client.name}>
          <i className="fas fa-tools" style={{ fontSize: '18px', color: '#e7184e' }} />
        </Toolipp>
      );
    }
    return (
      <Toolipp content={client.name}>
        <i className="fas fa-building" style={{ fontSize: '18px', color: '#e7184e' }} />
      </Toolipp>
    );
  }
  return null;
}

MapMarker.propTypes = {
  address: PropTypes.instanceOf(Object).isRequired,
  clients: PropTypes.instanceOf(Array).isRequired,
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
};
