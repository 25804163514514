const messageMapping = {
  CLIENT_MAIL_ALREADY_EXISTING: 'Diese E-Mail Adresse existiert bereits',
  CLIENT_NAME_ALREADY_EXISTING: 'Dieser Firmenname wird bereits verwendet',
  EMAILADDRESSINVALIDHOSTNAME: 'Ungültige E-Mail Adresse',
  HOSTNAMEUNKNOWNTLD: 'Ungültige E-Mail Adresse',
  HOSTNAMELOCALNAMENOTALLOWED: 'Ungültige E-Mail Adresse',
  FILE_EXTENSION_NOT_ALLOWED: 'Ungültiges Dateiformat. Bitte verwenden Sie das angegebene Dateiformat!',
  FILE_IS_NOT_UTF8: 'Falsche Codierung! Die Datei muss mit der Codierung UTF-8 hochgeladen werden.',
  CSV_IMPORT_ERROR_CUSTOMER_FIRSTNAME: 'Der Vorname des Kunden fehlt oder ist leer! (customerFirstName)',
  CSV_IMPORT_ERROR_CUSTOMER_LASTNAME: 'Der Nachname des Kunden fehlt oder ist leer! (customerLastName)',
  CSV_IMPORT_ERROR_CUSTOMER_ZIP: 'Die Postleitzahl des Kunden fehlt oder ist leer! (customerZip)',
  CSV_IMPORT_ERROR_CUSTOMER_CITY: 'Der Wohnort des Kunden fehlt oder ist leer! (customerCity)',
  CSV_IMPORT_ERROR_CUSTOMER_STREET: 'Die Strasse des Kunden fehlt oder ist leer! (customerStreet)',
  CSV_IMPORT_ERROR_OBJECT_EXTERNALID: 'Die Kommissionsnummer fehlt oder ist leer! (externalId)',
  CSV_IMPORT_ERROR_OBJECT_PRICE: 'Der Bruttopreis fehlt oder ist leer! (price)',
  CSV_IMPORT_ERROR_OBJECT_ALREADY_FINISHED: 'Der Auftragsstatus lässt keine weiter Bearbeitung durch einen Import zu!',
  CSV_IMPORT_ERROR_LOADING_ADDRESS: 'Die Beladestelle oder Lageradresse fehlt!',
  CSV_IMPORT_ERROR_ORDER_CREATED_ID: 'Die Angabe über den "Ersteller" fehlt (createdUserId || createUserReferenceId)',
};

export default function getErrorMessage(errorCode) {
  let data = errorCode;
  if (Array.isArray(errorCode)) {
    // eslint-disable-next-line prefer-destructuring
    data = errorCode[0];
  }

  if (messageMapping[data]) {
    return messageMapping[data];
  }
  return errorCode;
}
