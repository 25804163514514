import React, { forwardRef } from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';

registerLocale('de', de);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  height: ${(props) => props.theme.cellHeadlineHeight}px;
  border: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  border-top-left-radius: ${(props) => props.theme.border.radius};
  border-top-right-radius: ${(props) => props.theme.border.radius};
  padding: 6px 15px;
  background-color: ${(props) => props.theme.color.backgroundGrayLight};
  font-family: "DIN Next LT W01 Bold", sans-serif;
  font-size: 20px;

  .Center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .Right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  .Left {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
  }
`;

const IconContainer = styled.div`
  padding: 4px 12px;
  cursor: pointer;
  border-radius: ${(props) => props.theme.border.radius};
  transition: background-color 300ms, color 300ms;
  
  &:hover {
    background-color: gray;
    color: #ffffff;
  }
`;

const CustomDateContainer = styled.div`
  cursor: pointer;

  i {
    margin-right: 10px;

    &:last-child {
      margin-left: 10px;
    }
  }
`;

/**
 * WeekViewHeadline()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function WeekViewHeadline(props) {
  const { onChange, startDateProp, endDateProp } = props;
  const [dateRange, setDateRange] = React.useState([startDateProp, endDateProp]);

  // eslint-disable-next-line react/prop-types
  const CustomDateInput = forwardRef(({ value, onClick }, ref) => {
    // eslint-disable-next-line react/prop-types
    const dates = value.split(' - ');

    const renderFrom = () => {
      if (dates[0]) {
        return moment(dates[0]).format('dd, DD.MM.YYYY');
      }
      return null;
    };

    const renderTo = () => {
      if (dates[1]) {
        return moment(dates[1]).format('dd, DD.MM.YYYY');
      }
      return null;
    };

    return (
      <CustomDateContainer onClick={onClick} ref={ref}>
        {`${renderFrom()} - ${renderTo()}`}
        <i className="fas fa-caret-down" />
      </CustomDateContainer>
    );
  });

  const handleUpdate = (data) => {
    const start = (data[0]) ? moment(data[0]).format('YYYY-MM-DD') : null;
    const end = (data[1]) ? moment(data[1]).format('YYYY-MM-DD') : null;
    setDateRange([start, end]);

    if (start && end) {
      onChange([start, end]);
    }
  };

  const handleOnNext = () => {
    const daysBetween = Math.abs(moment(dateRange[0]).diff(moment(dateRange[1]), 'day'));
    const start = moment(dateRange[1]).add(1, 'day').format('YYYY-MM-DD');
    const end = moment(dateRange[1]).add(daysBetween + 1, 'day').format('YYYY-MM-DD');
    setDateRange([start, end]);
    onChange([start, end]);
  };

  const handleOnPrev = () => {
    const daysBetween = Math.abs(moment(dateRange[0]).diff(moment(dateRange[1]), 'day'));
    const end = moment(dateRange[0]).subtract(1, 'day').format('YYYY-MM-DD');
    const start = moment(dateRange[0]).subtract(daysBetween + 1, 'day').format('YYYY-MM-DD');
    setDateRange([start, end]);
    onChange([start, end]);
  };

  return (
    <Container>
      <div className="Left">
        <IconContainer onClick={handleOnPrev}>
          <i className="fas fa-arrow-left" />
        </IconContainer>
      </div>
      <div className="Center">
        <DatePicker
          locale="de"
          selectsRange
          startDate={moment(dateRange[0]).toDate()}
          endDate={(dateRange[1]) ? moment(dateRange[1]).toDate() : null}
          onChange={handleUpdate}
          onChangeRaw={(e) => e.preventDefault()}
          customInput={<CustomDateInput />}
          maxDate={(dateRange[0] && !dateRange[1]) ? moment(dateRange[0]).add(10, 'days').toDate() : null}
        />
      </div>
      <div className="Right">
        <IconContainer onClick={handleOnNext}>
          <i className="fas fa-arrow-right" />
        </IconContainer>
      </div>
    </Container>
  );
}

WeekViewHeadline.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDateProp: PropTypes.string.isRequired,
  endDateProp: PropTypes.string.isRequired,
};
