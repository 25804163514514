import axios from '../../Library/Axios';

export const defaultUri = '/order/{orderId}/object/{objectId}/message';

export const OBJECTMESSAGE_GETLIST_START = 'OBJECTMESSAGE_GETLIST_START';
export const OBJECTMESSAGE_GETLIST_SUCCESS = 'OBJECTMESSAGE_GETLIST_SUCCESS';
export const OBJECTMESSAGE_GETLIST_ERROR = 'OBJECTMESSAGE_GETLIST_ERROR';

/**
 * getList()
 * @param object
 * @returns {function(*): Promise<any | never>}
 */
export function getList(object) {
  return (dispatch) => {
    dispatch({ type: OBJECTMESSAGE_GETLIST_START, payload: object.objectId });

    const uri = defaultUri.replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECTMESSAGE_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECTMESSAGE_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const OBJECTMESSAGE_CREATE_START = 'OBJECTMESSAGE_CREATE_START';
export const OBJECTMESSAGE_CREATE_SUCCESS = 'OBJECTMESSAGE_CREATE_SUCCESS';
export const OBJECTMESSAGE_CREATE_ERROR = 'OBJECTMESSAGE_CREATE_ERROR';

/**
 * create()
 * @param object
 * @param message
 * @returns {function(*): Promise<unknown>}
 */
export function create(object, message) {
  return (dispatch) => {
    dispatch({ type: OBJECTMESSAGE_CREATE_START });

    const uri = defaultUri.replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);

    return axios().post(uri, { message }).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECTMESSAGE_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECTMESSAGE_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const OBJECTMESSAGE_DELETE_START = 'OBJECTMESSAGE_DELETE_START';
export const OBJECTMESSAGE_DELETE_SUCCESS = 'OBJECTMESSAGE_DELETE_SUCCESS';
export const OBJECTMESSAGE_DELETE_ERROR = 'OBJECTMESSAGE_DELETE_ERROR';

/**
 * deleteMessage()
 * @param message
 * @returns {function(*): Promise<unknown>}
 */
export function deleteMessage(message) {
  return (dispatch) => {
    dispatch({ type: OBJECTMESSAGE_DELETE_START });

    const preparedUri = defaultUri.replace('{orderId}', message.orderId)
      .replace('{objectId}', message.objectId);
    const uri = `${preparedUri}/${message.messageId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECTMESSAGE_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECTMESSAGE_DELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const OBJECTMESSAGE_APPROVE_START = 'OBJECTMESSAGE_APPROVE_START';
export const OBJECTMESSAGE_APPROVE_SUCCESS = 'OBJECTMESSAGE_APPROVE_SUCCESS';
export const OBJECTMESSAGE_APPROVE_ERROR = 'OBJECTMESSAGE_APPROVE_ERROR';

/**
 * approve()
 * @param message
 * @returns {function(*): Promise<unknown>}
 */
export function approve(message) {
  return (dispatch) => {
    dispatch({ type: OBJECTMESSAGE_APPROVE_START });

    const preparedUri = defaultUri.replace('{orderId}', message.orderId)
      .replace('{objectId}', message.objectId);
    const uri = `${preparedUri}/${message.messageId}`;

    const config = {
      params: { action: 'approve' },
    };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECTMESSAGE_APPROVE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECTMESSAGE_APPROVE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
