import React from 'react';

import './style.css';

const banner = require('../../../../Assets/Images/red_background.jpg');
const apple = require('../../../../Assets/Images/apple-download.png');
const android = require('../../../../Assets/Images/android-download.png');
const tablet = require('../../../../Assets/Images/App/tablet_screen11.png');

/**
 * AppStoreBanner()
 * @returns {*}
 * @constructor
 */
export default function AppStoreBanner() {
  return (
    <div className="FullPageBox Dark AppStoreBanner" style={{ backgroundImage: `url(${banner})` }}>
      <div className="container d-flex flex-row">
        <div className="d-flex flex-column">
          <h2>Verfügbar für iOS und Android</h2>
          <p>
            Nutzbar auf jedem Smartphone und Tablet.
          </p>
          <div className="ImageContainer">
            <a
              href="https://apps.apple.com/de/app/hey-kitchen/id1488213749"
              target="_blank"
              rel="noopener noreferrer"
              className="IOS"
            >
              <img src={apple} alt="Apple Store" style={{ marginRight: 20 }} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=de.hey.kitchen"
              target="_blank"
              rel="noreferrer noopener"
              className="Android"
            >
              <img src={android} alt="Google Play Store" />
            </a>
          </div>
        </div>
        <div>
          <img src={tablet} alt="Tablet" className="Tablet" />
        </div>
      </div>
    </div>
  );
}
