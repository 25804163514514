import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../../../Components/Panel';
import { RECLAMATIONSTATE_CLOSED } from '../../../../Library/StateTypes';

/**
 * ClosedHint()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ClosedHint(props) {
  const { reclamation, client, order } = props;

  const renderComment = () => {
    if (reclamation.closedComment) {
      return <i>{reclamation.closedComment}</i>;
    }
    return <i>Keine Bemerkung hinterlegt!</i>;
  };

  if (reclamation.state === RECLAMATIONSTATE_CLOSED && client.clientId === order.clientId) {
    return (
      <Panel marginBottom={25}>
        <div className="text-center">
          <div>
            <strong>
              Diese Reklamation wurde manuell auf "Erledigt" gestellt. Es wurde kein Reklamationsbericht erstellt!
            </strong>
          </div>
          <div>Es wurde folgende Bemerkung zur manuellen Erledigung hinterlegt:</div>
          <div style={{ color: '#000' }}>
            {renderComment()}
          </div>
        </div>
      </Panel>
    );
  }
  return null;
}

ClosedHint.propTypes = {
  reclamation: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
};
