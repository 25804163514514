import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import {
  assemblyTimeMinuteOptions, OBJECT_SERVICE_TYPE_CHECK,
  OBJECT_SERVICE_TYPE_DELIVERY, OBJECT_SERVICE_TYPE_MONTAGE, OBJECT_SERVICE_TYPE_RECLAMATION,
} from '../../../../Library/Types';
import Panel from '../../../../Components/Panel';
import Input from '../../../../Components/Input';
import Textarea from '../../../../Components/Textarea';
import CustomReactSelect from '../../../../Components/CustomReactSelect';
import AddItemImages from './AddItemImages';
import ShowHideContainer from '../../../../Components/ShowHideContainer';
import WeekPickerInput from '../../../../Components/WeekPickerInput';

export const YesNoOption = [
  { label: 'Ja', value: true },
  { label: 'Nein', value: false },
];

export const storageDateOption = [
  { label: '‌‌ ', value: null },
  { label: 'Ja', value: 'true' },
  { label: 'Nein', value: 'false' },
];

const initialState = {
  serviceId: null,
  type: null,
  title: '',
  description: '',
  number: '',
  assemblyTime: 0,
  workOptionWater: false,
  workOptionElectric: false,
  documents: [],
  storageDate: null,
  deliveryWeek: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'type':
      return { ...state, type: action.payload };
    case 'title':
      return { ...state, title: action.payload };
    case 'description':
      return { ...state, description: action.payload };
    case 'number':
      return { ...state, number: action.payload };
    case 'assemblyTime':
      return { ...state, assemblyTime: action.payload };
    case 'workOptionWater':
      return { ...state, workOptionWater: action.payload };
    case 'workOptionElectric':
      return { ...state, workOptionElectric: action.payload };
    case 'documents':
      return { ...state, documents: action.payload };
    case 'storageDate':
      return { ...state, storageDate: action.payload };
    case 'deliveryWeek':
      return { ...state, deliveryWeek: action.payload };
    case 'reset':
      return { ...initialState };
    case 'edit':
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

/**
 * AddItemModal()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ItemModal(props) {
  const {
    visible, onClose, onSave, service, editItem, onEdit, isInternal,
  } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [devicesSelected, setDevicesSelected] = useState(false);
  const [errors, setErrors] = useState({});
  const [headline, setHeadline] = React.useState(null);
  const [description, setDescription] = React.useState(null);

  React.useEffect(() => {
    if (service) {
      switch (service.type) {
        case OBJECT_SERVICE_TYPE_RECLAMATION: {
          setHeadline('Beanstandung hinzufügen');
          setDescription(
            'Fügen sie eine manuelle Beanstandung hinzu oder wählen Sie einer vorhandene Beanstandung '
            + 'aus den vorangegangenen Berichten',
          );
          break;
        }
        case OBJECT_SERVICE_TYPE_MONTAGE: {
          setHeadline('Nachmontage hinzufügen');
          setDescription(
            'Bitte füllen Sie alle notwendigen Felder aus um ein Nachmontage dem Serviceauftrag hinzuzufügen',
          );
          break;
        }
        case OBJECT_SERVICE_TYPE_DELIVERY: {
          setHeadline('Nachlieferung hinzufügen');
          setDescription(
            'Bitte füllen Sie alle notwendigen Felder aus um ein Nachlieferung dem Serviceauftrag hinzuzufügen',
          );
          break;
        }
        case OBJECT_SERVICE_TYPE_CHECK: {
          setHeadline('Prüfpunkt hinzufügen');
          setDescription(
            'Bitte füllen Sie alle notwendigen Felder aus um einen Prüfpunkt dem Serviceauftrag hinzuzufügen',
          );
          break;
        }
        default: {
          setHeadline('Item hinzufügen');
          setDescription(
            'Fügen Sie hier so viele manuelle Items hinzu, die alle zum gleichen Termin behoben werden sollen.',
          );
        }
      }
    }
  }, [service]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'reset' });
    }
  }, [visible]);

  React.useEffect(() => {
    if (editItem) {
      dispatch({ type: 'edit', payload: editItem });
    }
  }, [editItem]);

  const getTitleCaption = () => {
    switch (service.type) {
      case OBJECT_SERVICE_TYPE_CHECK:
        return 'Was muss geprüft werden?';
      default:
        return 'Gerätebezeichnung / Teilebezeichnung';
    }
  };

  const handleSaveItem = () => {
    setErrors({});

    if (state.title) {
      if (editItem) {
        onEdit(state);
      } else if (onSave) {
        onSave(state);
      }
    } else {
      setErrors({ title: true });
    }
  };

  const getStorageDateValue = () => {
    if (!state.storageDate) {
      return null;
    } if (state.storageDate !== 'false') {
      return 'true';
    }
    return 'false';
  };

  const renderManual = () => {
    if (!devicesSelected) {
      return (
        <>
          <Panel boxPadding={10} marginBottom={20}>
            <div className="row">
              <Input
                onChange={(value) => dispatch({ type: 'title', payload: value })}
                value={state.title}
                label={getTitleCaption()}
                hasError={!!(errors.title)}
                placeholder="z.B. Backofen / Kühlschrank / Arbeitsplatte etc..."
                rowClass="col-lg-12"
                required
                autoFocus
              />
              <Textarea
                onChange={(value) => dispatch({ type: 'description', payload: value })}
                value={state.description}
                rows={4}
                label="Zusätzliche Informationen oder Beschreibungen"
                placeholder="Weiter wichtige Informationen die für den ausführenden Mitarbeiter relevant sind"
                rowClass="col-lg-12"
              />

              <Input
                onChange={(value) => dispatch({ type: 'number', payload: value })}
                value={state.number}
                label="Gerätenummer / Teilenummer"
                hasError={!!(errors.number)}
                placeholder=""
                rowClass="col-lg-6"
              />
              <CustomReactSelect
                onChange={(value) => dispatch({ type: 'assemblyTime', payload: value })}
                value={state.assemblyTime}
                options={assemblyTimeMinuteOptions}
                label="Geschätzte Arbeitsdauer"
                rowClass="col-lg-6"
              />

              <ShowHideContainer visible={service.type !== OBJECT_SERVICE_TYPE_DELIVERY}>
                <CustomReactSelect
                  onChange={(value) => dispatch({ type: 'workOptionWater', payload: value })}
                  value={state.workOptionWater}
                  options={YesNoOption}
                  label="Benötigt erneute Prüfung vom Wasseranschluss"
                  rowClass="col-lg-6"
                />
              </ShowHideContainer>
              <ShowHideContainer visible={service.type !== OBJECT_SERVICE_TYPE_DELIVERY}>
                <CustomReactSelect
                  onChange={(value) => dispatch({ type: 'workOptionElectric', payload: value })}
                  value={state.workOptionElectric}
                  options={YesNoOption}
                  label="Benötigt erneute Prüfung vom Elektroanschluss"
                  rowClass="col-lg-6"
                />
              </ShowHideContainer>
              <ShowHideContainer visible={isInternal}>
                <div className="col-lg-6">
                  <label style={{ fontSize: '14px' }}>
                    Lieferwoche
                  </label>
                  <WeekPickerInput
                    onChange={(value) => dispatch({ type: 'deliveryWeek', payload: value })}
                    value={state.deliveryWeek}
                  />
                </div>
                <CustomReactSelect
                  onChange={(value) => dispatch({ type: 'storageDate', payload: value })}
                  value={getStorageDateValue()}
                  options={storageDateOption}
                  label="Ist im Lager"
                  rowClass="col-lg-6"
                />
              </ShowHideContainer>
            </div>
          </Panel>

          <Panel boxPadding={0}>
            <AddItemImages onChange={(files) => dispatch({ type: 'documents', payload: files })} />
          </Panel>
        </>
      );
    }
    return null;
  };

  const renderTabBar = () => {
    if (editItem) {
      return null;
    }
    if (service && service.type === OBJECT_SERVICE_TYPE_RECLAMATION) {
      return (
        <div className="btn-group btn-block" style={{ marginBottom: 20 }}>
          <button
            type="button"
            className={`btn ${(!devicesSelected) ? 'btn-hk-primary' : 'btn-light'}`}
            onClick={() => setDevicesSelected(false)}
          >
            Manuelle Beanstandung
          </button>
          <button
            type="button"
            className={`btn ${(devicesSelected) ? 'btn-hk-primary' : 'btn-light'}`}
            onClick={() => setDevicesSelected(true)}
          >
            Beanstandung aus Berichten
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <ModalDialog visible={visible} onClose={onClose} onConfirm={handleSaveItem} width={900}>
      <h5>{headline}</h5>
      <p style={{ marginBottom: 30 }}>
        {description}
      </p>

      {renderTabBar()}
      {renderManual()}
    </ModalDialog>
  );
}

ItemModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  service: PropTypes.instanceOf(Object).isRequired,
  editItem: PropTypes.instanceOf(Object),
  onEdit: PropTypes.func.isRequired,
  isInternal: PropTypes.bool.isRequired,
};

ItemModal.defaultProps = {
  visible: false,
  editItem: null,
};
