import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { create, getAll } from '../../../Redux/Action/ClientSubcontractorAction';
import ModalDialog from '../../../Components/ModalDialog';
import Input from '../../../Components/Input';
import CustomSwitch from '../../../Components/CustomSwitch';
import CustomReactSelect from '../../../Components/CustomReactSelect';
import { legalFormOptions } from '../../../Library/Types';
import Panel from '../../../Components/Panel';

const initialState = {
  name: '',
  legalForm: null,
  zip: '',
  city: '',
  street: '',
  streetNo: '',
  phone: '',
  mobile: '',
  fax: '',
  mail: '',
  web: '',
  contactPerson: ' ',
  active: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload, changed: true };
    case 'legalForm':
      return { ...state, legalForm: action.payload, changed: true };
    case 'zip':
      return { ...state, zip: action.payload, changed: true };
    case 'city':
      return { ...state, city: action.payload, changed: true };
    case 'street':
      return { ...state, street: action.payload, changed: true };
    case 'streetNo':
      return { ...state, streetNo: action.payload, changed: true };
    case 'phone':
      return { ...state, phone: action.payload, changed: true };
    case 'mobile':
      return { ...state, mobile: action.payload, changed: true };
    case 'fax':
      return { ...state, fax: action.payload, changed: true };
    case 'mail':
      return { ...state, mail: action.payload, changed: true };
    case 'web':
      return { ...state, web: action.payload, changed: true };
    case 'contactPerson':
      return { ...state, contactPerson: action.payload, changed: true };
    case 'active':
      return { ...state, active: action.payload, changed: true };
    case 'reset':
      return { ...initialState, changed: false };

    default:
      throw new Error();
  }
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Add(props) {
  const {
    dispatchCreate, onClose, visible, dispatchGetList, client,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errors, setError] = useState({});

  const handleOnSave = () => {
    dispatchCreate(client.clientId, state).then(() => {
      dispatchGetList(client.clientId).then(() => {
        dispatch({ type: 'reset' });
        onClose();
      });
    }).catch((error) => {
      if (error.data && error.data.errorMessage) {
        let errorData = {};
        Object.keys(error.data.errorMessage).forEach((key) => {
          errorData = { ...errorData, [key]: true };
        });
        setError(errorData);
      }
    });
  };

  const onCancel = () => {
    dispatch({ type: 'reset' });
    setError({});
    onClose();
  };

  return (
    <ModalDialog
      width={900}
      onClose={onCancel}
      visible={visible}
      confirmCaption="Speichern"
      onConfirm={handleOnSave}
    >
      <h5>Subunternehmer hinzufügen</h5>
      <hr />

      <Panel boxPadding={10} marginBottom={20}>
        <div className="row">
          <Input
            label="Name"
            placeholder="Name"
            onChange={(value) => dispatch({ type: 'name', payload: value })}
            value={state.name}
            rowClass="col-lg-12"
            hasError={!!(errors.name)}
            required
            autoFocus
          />
          <CustomReactSelect
            style={{ marginBottom: 20 }}
            onChange={(value) => dispatch({ type: 'legalForm', payload: value })}
            value={state.legalForm}
            options={legalFormOptions}
            label="Rechtsform"
            rowClass="col-lg-12"
            hasError={!!(errors.legalForm)}
            required
          />
        </div>
      </Panel>

      <Panel boxPadding={10} marginBottom={20}>
        <div className="row">
          <Input
            label="Postleitzahl"
            placeholder="Postleitzahl"
            onChange={(value) => dispatch({ type: 'zip', payload: value })}
            value={state.zip}
            rowClass="col-lg-4"
          />
          <Input
            label="Stadt"
            placeholder="Stadt"
            onChange={(value) => dispatch({ type: 'city', payload: value })}
            value={state.city}
            rowClass="col-lg-8"
          />
          <Input
            label="Straße"
            placeholder="Straße"
            onChange={(value) => dispatch({ type: 'street', payload: value })}
            value={state.street}
            rowClass="col-lg-8"
          />
          <Input
            label="Straßennummer"
            placeholder="Straßennnummer"
            onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
            value={state.streetNo}
            rowClass="col-lg-4"
          />
        </div>
      </Panel>

      <Panel boxPadding={10}>
        <div className="row">
          <Input
            label="E-Mail"
            placeholder="E-Mail"
            onChange={(value) => dispatch({ type: 'mail', payload: value })}
            value={state.mail}
            rowClass="col-lg-6"
            hasError={!!(errors.mail)}
            required
          />
          <Input
            label="Telefon"
            placeholder="Telefonnummer"
            onChange={(value) => dispatch({ type: 'phone', payload: value })}
            value={state.phone}
            rowClass="col-lg-6"
          />
          <Input
            label="Mobilfunknummer"
            placeholder="Mobilfunknummer"
            onChange={(value) => dispatch({ type: 'mobile', payload: value })}
            value={state.mobile}
            rowClass="col-lg-6"
          />
          <Input
            label="Fax"
            placeholder="Fax"
            onChange={(value) => dispatch({ type: 'fax', payload: value })}
            value={state.fax}
            rowClass="col-lg-6"
          />
          <Input
            label="Webseite"
            placeholder="Web"
            onChange={(value) => dispatch({ type: 'web', payload: value })}
            value={state.web}
            rowClass="col-lg-6"
          />
          <Input
            label="Kontaktperson"
            placeholder="Kontaktperson"
            onChange={(value) => dispatch({ type: 'contactPerson', payload: value })}
            value={state.contactPerson}
            rowClass="col-lg-6"
          />
        </div>
        <div style={{ marginLeft: 5, marginTop: 15 }} className="row">
          <CustomSwitch
            onChange={(value) => dispatch({ type: 'active', payload: value })}
            checked={state.active}
            caption={`Benutzerkonto: ${state.active
              ? 'Aktiv, Anmeldung für zugewiesene Benutzer möglich'
              : 'Inaktiv, keine Anmeldung für zugewiesene Benutzer möglich'}`}
          />
        </div>
      </Panel>
    </ModalDialog>
  );
}

Add.propTypes = {
  dispatchCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  dispatchGetList: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreate: (clientId, subcontractorData) => dispatch(create(clientId, subcontractorData)),
    dispatchGetList: (clientId) => dispatch(getAll(clientId, true)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(withRouter(Add));
