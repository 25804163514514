import * as Redux from 'react-redux';
import React from 'react';
import axios from '../../Library/Axios';

export const defaultUri = '/order';
export const defaultUriId = `${defaultUri}/{orderId}`;

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.orderId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUriId(parameter, uri = defaultUriId) {
  const { orderId } = parameter;
  return uri.replace('{orderId}', orderId);
}

export const ORDER_CREATE_UPLOADFILES_START = 'ORDER_CREATE_UPLOADFILES_START';
export const ORDER_CREATE_UPLOADFILES_SUCCESS = 'ORDER_CREATE_UPLOADFILES_SUCCESS';
export const ORDER_CREATE_UPLOADFILES_ERROR = 'ORDER_CREATE_UPLOADFILES_ERROR';
export const ORDER_CREATE_UPLOADFILES_PROGRESS = 'ORDER_CREATE_UPLOADFILES_PROGRESS';

export const useDispatchUploadFiles = () => {
  const dispatch = Redux.useDispatch();
  const config = {
    timeout: 0,
    onUploadProgress: (progressEvent) => {
      const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch({ type: ORDER_CREATE_UPLOADFILES_PROGRESS, percent });
    },
  };

  return React.useCallback((orderId, orderData) => {
    const uri = `${defaultUri}/${orderId}/file`;

    dispatch({
      type: ORDER_CREATE_UPLOADFILES_START,
      payload: {
        uri, config, orderId, orderData,
      },
    });

    const formData = new FormData();
    if (orderData.type === 'SINGLE') {
      formData.append('file[installationPlan]', orderData.objects.documents.installationPlan);
      formData.append('file[orderConfirmation]', orderData.objects.documents.orderConfirmation);
      if (orderData.objects.documents.fileOther) {
        orderData.objects.documents.fileOther.forEach((doc) => {
          formData.append('file[fileOther][]', doc);
        });
      }
    } else {
      orderData.objects.forEach((item) => {
        formData.append(`file[${item.id}][installationPlan]`, item.documents.installationPlan);
        formData.append(`file[${item.id}][orderConfirmation]`, item.documents.orderConfirmation);
        if (item.documents.fileOther) {
          item.documents.fileOther.forEach((doc) => {
            formData.append(`file[${item.id}][fileOther][]`, doc);
          });
        }
      });
    }

    return axios().post(uri, formData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_CREATE_UPLOADFILES_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_CREATE_UPLOADFILES_ERROR, payload: { error } });
      return Promise.reject(error);
    });
  }, [dispatch, config]);
};

/**
 * uploadFiles()
 * @param orderId
 * @param orderData
 * @returns {function(*): Promise<any | never>}
 */
function uploadFiles(orderId, orderData) {
  return (dispatch) => {
    dispatch({ type: ORDER_CREATE_UPLOADFILES_START });

    const config = {
      timeout: 0,
      onUploadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch({ type: ORDER_CREATE_UPLOADFILES_PROGRESS, percent });
      },
    };

    const uri = `${defaultUri}/${orderId}/file`;

    const formData = new FormData();
    if (orderData.type === 'SINGLE') {
      formData.append('file[installationPlan]', orderData.objects.documents.installationPlan);
      formData.append('file[orderConfirmation]', orderData.objects.documents.orderConfirmation);
      if (orderData.objects.documents.fileOther) {
        orderData.objects.documents.fileOther.forEach((doc) => {
          formData.append('file[fileOther][]', doc);
        });
      }
    } else {
      orderData.objects.forEach((item) => {
        formData.append(`file[${item.id}][installationPlan]`, item.documents.installationPlan);
        formData.append(`file[${item.id}][orderConfirmation]`, item.documents.orderConfirmation);
        if (item.documents.fileOther) {
          item.documents.fileOther.forEach((doc) => {
            formData.append(`file[${item.id}][fileOther][]`, doc);
          });
        }
      });
    }

    return axios().post(uri, formData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_CREATE_UPLOADFILES_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_CREATE_UPLOADFILES_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_UPDATE_UPLOADFILES_START = 'ORDER_UPDATE_UPLOADFILES_START';
export const ORDER_UPDATE_UPLOADFILES_SUCCESS = 'ORDER_UPDATE_UPLOADFILES_SUCCESS';
export const ORDER_UPDATE_UPLOADFILES_ERROR = 'ORDER_UPDATE_UPLOADFILES_ERROR';
export const ORDER_UPDATE_UPLOADFILES_PROGRESS = 'ORDER_UPDATE_UPLOADFILES_PROGRESS';

/**
 * uploadFiles()
 * @param orderData
 * @returns {function(*): Promise<any | never>}
 */
function updateFiles(orderData) {
  return (dispatch) => {
    let needUpload = false;
    dispatch({ type: ORDER_UPDATE_UPLOADFILES_START, orderData });

    const config = {
      onUploadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch({ type: ORDER_UPDATE_UPLOADFILES_PROGRESS, percent });
      },
    };

    const uri = `${defaultUri}/${orderData.orderId}/file`;

    const formData = new FormData();
    if (orderData.type === 'SINGLE') {
      const { documents } = orderData.objects;

      if (documents.installationPlan && !documents.installationPlan.documentId) {
        formData.append('file[installationPlan]', documents.installationPlan);
        needUpload = true;
      }
      if (documents.orderConfirmation && !documents.orderConfirmation.documentId) {
        formData.append('file[orderConfirmation]', documents.orderConfirmation);
        needUpload = true;
      }
      if (documents.fileOther && documents.fileOther.length > 0) {
        documents.fileOther.forEach((doc) => {
          formData.append('file[fileOther][]', doc);
        });
        needUpload = true;
      }
    } else {
      orderData.objects.forEach((item) => {
        const { documents } = item;

        if (documents.installationPlan && !documents.installationPlan.documentId) {
          formData.append(`file[${item.id}][installationPlan]`, documents.installationPlan);
          needUpload = true;
        }
        if (documents.orderConfirmation && !documents.orderConfirmation.documentId) {
          formData.append(`file[${item.id}][orderConfirmation]`, documents.orderConfirmation);
          needUpload = true;
        }
        if (documents.fileOther && documents.fileOther.length > 0) {
          documents.fileOther.forEach((doc) => {
            formData.append(`file[${item.id}][fileOther][]`, doc);
          });
          needUpload = true;
        }
      });
    }

    if (needUpload) {
      return axios().post(uri, formData, config).then((response) => {
        const { data } = response.data;
        dispatch({ type: ORDER_UPDATE_UPLOADFILES_SUCCESS, payload: data });
        return Promise.resolve(data);
      }).catch((error) => {
        dispatch({ type: ORDER_UPDATE_UPLOADFILES_ERROR, payload: error.response });
        return Promise.reject(error);
      });
    }
    return Promise.resolve();
  };
}

export const ORDER_CREATE_START = 'ORDER_CREATE_START';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_ERROR = 'ORDER_CREATE_ERROR';

export const useDispatchCreateOrder = () => {
  const dispatch = Redux.useDispatch();
  const dispatchUploadFiles = useDispatchUploadFiles();
  const uri = defaultUri;

  return React.useCallback((orderData) => {
    dispatch({ type: ORDER_CREATE_START, payload: { uri, orderData } });

    return axios().post(uri, orderData).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });
      return dispatchUploadFiles(data.orderId, orderData).then(() => Promise.resolve(data));
    }).catch((error) => {
      dispatch({ type: ORDER_CREATE_ERROR, payload: { error } });
      return Promise.reject(error);
    });
  }, [dispatch, dispatchUploadFiles, uri]);
};

/**
 * create()
 * @param orderData
 * @returns {function(*): Promise<any | never>}
 */
export function create(orderData) {
  return (dispatch) => {
    dispatch({ type: ORDER_CREATE_START, orderData });

    return axios().post(defaultUri, orderData).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });
      return dispatch(uploadFiles(data.orderId, orderData)).then(() => Promise.resolve(data));
    }).catch((error) => {
      dispatch({ type: ORDER_CREATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_GET_PREFILL_START = 'ORDER_GET_PREFILL_START';
export const ORDER_GET_PREFILL_SUCCESS = 'ORDER_GET_PREFILL_SUCCESS';
export const ORDER_GET_PREFILL_ERROR = 'ORDER_GET_PREFILL_ERROR';

export const useDispatchGetOrderPrefill = () => {
  const dispatch = Redux.useDispatch();

  return React.useCallback((guid) => {
    const uri = defaultUri;

    const config = {
      params: {
        action: 'prefilled',
        hash: guid,
      },
    };

    dispatch({ type: ORDER_GET_PREFILL_START, payload: { uri, config } });

    return axios().get(uri, config).then((res) => {
      const { data } = res.data;
      dispatch({ type: ORDER_GET_PREFILL_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((err) => {
      dispatch({ type: ORDER_GET_PREFILL_ERROR, payload: err });
      return Promise.reject(err);
    });
  }, [dispatch]);
};

export const ORDER_GET_START = 'ORDER_GET_START';
export const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS';
export const ORDER_GET_ERROR = 'ORDER_GET_ERROR';

export const useDispatchGetOrder = () => {
  const dispatch = Redux.useDispatch();

  return React.useCallback((orderId) => {
    const uri = prepareUriId({ orderId });

    dispatch({ type: ORDER_GET_START, payload: { uri } });

    return axios().get(uri).then((res) => {
      const { data } = res.data;
      dispatch({ type: ORDER_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((err) => {
      dispatch({ type: ORDER_GET_ERROR, payload: err });
      return Promise.reject(err);
    });
  }, [dispatch]);
};

/**
 * get()
 * @param orderId
 * @param forceReload
 * @returns {function(*): Promise<any | never>}
 */
export function get(orderId, forceReload = false) {
  return (dispatch, getStore) => {
    const { order } = getStore().order;
    if (!forceReload) {
      if (order && order.orderId === parseInt(orderId, 0)) {
        return Promise.resolve(order);
      }
    }

    dispatch({ type: ORDER_GET_START, payload: orderId });
    const uri = `${defaultUri}/${orderId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const ORDER_GETOPENEXTERNAL_START = 'ORDER_GETOPENEXTERNAL_START';
export const ORDER_GETOPENEXTERNAL_SUCCESS = 'ORDER_GETOPENEXTERNAL_SUCCESS';
export const ORDER_GETOPENEXTERNAL_ERROR = 'ORDER_GETOPENEXTERNAL_ERROR';

/**
 * Get all ready to be picked by a monteur
 * getOpenExternal()
 * @returns {Function}
 */
export function getOpenExternal() {
  return (dispatch) => {
    dispatch({ type: ORDER_GETOPENEXTERNAL_START });

    const config = { params: { action: 'openExternal' } };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_GETOPENEXTERNAL_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_GETOPENEXTERNAL_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}

export const ORDER_GETWAITINGEXTERNAL_START = 'ORDER_GETWAITINGEXTERNAL_START';
export const ORDER_GETWAITINGEXTERNAL_SUCCESS = 'ORDER_GETWAITINGEXTERNAL_SUCCESS';
export const ORDER_GETWAITINGEXTERNAL_ERROR = 'ORDER_GETWAITINGEXTERNAL_ERROR';

/**
 * Get all orders waiting for calculation
 * getWaitingExternal()
 * @returns {Function}
 */
export function getWaitingExternal() {
  return (dispatch) => {
    dispatch({ type: ORDER_GETWAITINGEXTERNAL_START });

    const config = { params: { action: 'waitingExternal' } };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_GETWAITINGEXTERNAL_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_GETWAITINGEXTERNAL_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}

export const ORDER_APPROVE_START = 'ORDER_APPROVE_START';
export const ORDER_APPROVE_SUCCESS = 'ORDER_APPROVE_SUCCESS';
export const ORDER_APPROVE_ERROR = 'ORDER_APPROVE_ERROR';

export const useDispatchApproveOrder = () => {
  const dispatch = Redux.useDispatch();

  return React.useCallback((orderId) => {
    const uri = prepareUriId({ orderId });

    const config = { params: { action: 'approve' } };

    dispatch({ type: ORDER_APPROVE_START, payload: { uri, config } });

    return axios().put(uri, {}, config).then((res) => {
      const { data } = res.data;
      dispatch({ type: ORDER_APPROVE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((err) => {
      dispatch({ type: ORDER_APPROVE_ERROR, payload: err });
      return Promise.reject(err);
    });
  }, [dispatch]);
};

/**
 * approve()
 * @param orderId
 * @returns {function(*): Promise<any | never>}
 */
export function approve(orderId) {
  return (dispatch) => {
    dispatch({ type: ORDER_APPROVE_START });
    const uri = `${defaultUri}/${orderId}`;

    const config = { params: { action: 'approve' } };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_APPROVE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_APPROVE_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}

export const ORDER_UPDATE_START = 'ORDER_UPDATE_START';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_ERROR = 'ORDER_UPDATE_ERROR';

/**
 * create()
 * @param orderData
 * @param ignoreFiles
 * @returns {function(*): Promise<any | never>}
 */
export function update(orderData, ignoreFiles = false) {
  return (dispatch) => {
    dispatch({ type: ORDER_UPDATE_START, payload: orderData });

    const uri = `${defaultUri}/${orderData.orderId}`;

    return axios().put(uri, orderData).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_UPDATE_SUCCESS, payload: data });
      if (!ignoreFiles) {
        return dispatch(updateFiles(orderData)).then(() => Promise.resolve(data));
      }
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_SET_EDIT_ORDER = 'ORDER_SET_EDIT_ORDER';

/**
 * setEditOrder()
 * @param order
 * @returns {Function}
 */
export function setEditOrder(order) {
  return (dispatch) => {
    dispatch({ type: ORDER_SET_EDIT_ORDER, payload: order });
  };
}

export const ORDER_DELETE_START = 'ORDER_DELETE_START';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';
export const ORDER_DELETE_ERROR = 'ORDER_DELETE_ERROR';

/**
 * deleteOrder()
 * @param orderData
 * @returns {function(*): Promise<void>}
 */
export function deleteOrder(orderData) {
  return (dispatch) => {
    dispatch({ type: ORDER_DELETE_START });

    const uri = `${defaultUri}/${orderData.orderId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_DELETE_SUCCESS, payload: data });
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: ORDER_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_SINGLEUDPATE_START = 'ORDER_SINGLEUDPATE_START';
export const ORDER_SINGLEUDPATE_SUCCESS = 'ORDER_SINGLEUDPATE_SUCCESS';
export const ORDER_SINGLEUDPATE_ERROR = 'ORDER_SINGLEUDPATE_ERROR';

/**
 * singleUpdate()
 * @param orderId
 * @param field
 * @param value
 * @returns {function(*): Promise<unknown>}
 */
export function singleUpdate(orderId, field, value) {
  return (dispatch) => {
    dispatch({ type: ORDER_SINGLEUDPATE_START, payload: { orderId, field, value } });

    const uri = `${defaultUri}/${orderId}`;

    const config = {
      params: {
        action: 'singleUpdate',
      },
    };

    return axios().put(uri, { field, value }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_SINGLEUDPATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_SINGLEUDPATE_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}

export const ORDER_UPDATELOADINGADDRESS_START = 'ORDER_UPDATELOADINGADDRESS_START';
export const ORDER_UPDATELOADINGADDRESS_SUCCESS = 'ORDER_UPDATELOADINGADDRESS_SUCCESS';
export const ORDER_UPDATELOADINGADDRESS_ERROR = 'ORDER_UPDATELOADINGADDRESS_ERROR';

/**
 * updateLoadingAddress()
 * @param orderId
 * @param loadingAddress
 * @returns {function(*): Promise<unknown>}
 */
export function updateLoadingAddress(orderId, loadingAddress) {
  return (dispatch) => {
    dispatch({ type: ORDER_UPDATELOADINGADDRESS_START, payload: { orderId, loadingAddress } });

    const uri = `${defaultUri}/${orderId}`;

    const config = {
      params: {
        action: 'updateLoadingaddress',
      },
    };

    return axios().put(uri, loadingAddress, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_UPDATELOADINGADDRESS_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_UPDATELOADINGADDRESS_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_CALCULATE_START = 'ORDER_CALCULATE_START';
export const ORDER_CALCULATE_SUCCESS = 'ORDER_CALCULATE_SUCCESS';
export const ORDER_CALCULATE_ERROR = 'ORDER_CALCULATE_ERROR';

export const useDispatchCalculateOrder = () => {
  const dispatch = Redux.useDispatch();
  const uri = defaultUri;
  const config = {
    params: {
      action: 'calculate',
    },
  };

  return React.useCallback((orderData) => {
    dispatch({ type: ORDER_CALCULATE_START, payload: { uri, orderData } });

    return axios().post(uri, orderData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_CALCULATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_CALCULATE_ERROR, payload: { error } });
      return Promise.reject(error);
    });
  }, [dispatch, uri, config]);
};

/**
 * Calculate the montage data without the order available in the database
 * calculate()
 * @param orderData
 * @returns {function(*): Promise<unknown>}
 */
export function calculate(orderData) {
  return (dispatch) => {
    dispatch({ type: ORDER_CALCULATE_START, orderData });

    const config = {
      params: {
        action: 'calculate',
      },
    };

    return axios().post(defaultUri, orderData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_CALCULATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_CALCULATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_CALCULATEWITHORDERID_START = 'ORDER_CALCULATEWITHORDERID_START';
export const ORDER_CALCULATEWITHORDERID_SUCCESS = 'ORDER_CALCULATEWITHORDERID_SUCCESS';
export const ORDER_CALCULATEWITHORDERID_ERROR = 'ORDER_CALCULATEWITHORDERID_ERROR';

/**
 * Calculate the order with the given orderId. The order must be existing!!!
 * calculateWithOrderId()
 * @param orderId
 * @returns {function(*): Promise<unknown>}
 * @constructor
 */
export function calculateWithOrderId(orderId) {
  return (dispatch) => {
    dispatch({ type: ORDER_CALCULATEWITHORDERID_START, orderId });

    const config = {
      params: {
        action: 'calculate',
      },
    };

    const uri = `${defaultUri}/${orderId}`;

    return axios().get(uri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_CALCULATEWITHORDERID_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_CALCULATEWITHORDERID_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_CREATEOFFER_START = 'ORDER_CREATEOFFER_START';
export const ORDER_CREATEOFFER_SUCCESS = 'ORDER_CREATEOFFER_SUCCESS';
export const ORDER_CREATEOFFER_ERROR = 'ORDER_CREATEOFFER_ERROR';

/**
 * Create an offer for the given user. Create the offer always for the current authed clientId!
 * createOffer()
 * @param order
 * @param price
 * @param addressId
 * @param dates
 * @returns {function(*): Promise<unknown>}
 */
export function createOffer(order, price, addressId, dates) {
  return (dispatch) => {
    dispatch({ type: ORDER_CREATEOFFER_START, payload: { order, price, addressId } });

    const uri = `${defaultUri}/${order.orderId}/offer`;

    return axios().post(uri, { price, addressId, dates }).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_CREATEOFFER_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_CREATEOFFER_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_DELETEOFFER_START = 'ORDER_DELETEOFFER_START';
export const ORDER_DELETEOFFER_SUCCESS = 'ORDER_DELETEOFFER_SUCCESS';
export const ORDER_DELETEOFFER_ERROR = 'ORDER_DELETEOFFER_ERROR';

/**
 * Delete all offers from the client for the given order
 * deleteOffer()
 * @param order
 * @returns {function(*): Promise<unknown>}
 */
export function deleteOffer(order) {
  return (dispatch) => {
    dispatch({ type: ORDER_DELETEOFFER_START, payload: { order } });

    const uri = `${defaultUri}/${order.orderId}/offer`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_DELETEOFFER_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_DELETEOFFER_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_TRACKVIEW_START = 'ORDER_TRACKVIEW_START';
export const ORDER_TRACKVIEW_SUCCESS = 'ORDER_TRACKVIEW_SUCCESS';
export const ORDER_TRACKVIEW_ERROR = 'ORDER_TRACKVIEW_ERROR';

/**
 * trackView()
 * @param orderId
 * @returns {function(*): Promise<unknown>}
 */
export function trackView(orderId) {
  return (dispatch) => {
    dispatch({ type: ORDER_TRACKVIEW_START, payload: { orderId } });

    const uri = `${defaultUri}/${orderId}/view`;

    return axios().post(uri, {}).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_TRACKVIEW_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_TRACKVIEW_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_ASSIGN_START = 'ORDER_ASSIGN_START';
export const ORDER_ASSIGN_SUCCESS = 'ORDER_ASSIGN_SUCCESS';
export const ORDER_ASSIGN_ERROR = 'ORDER_ASSIGN_ERROR';

/**
 * assign()
 * ATTENTION: Status of order will be updated in order reducer after success!
 * @param order
 * @param addressId
 * @returns {function(*): Promise<unknown>}
 */
export function assign(order, addressId) {
  return (dispatch) => {
    dispatch({ type: ORDER_ASSIGN_START });

    const uri = `${defaultUri}/${order.orderId}`;
    const config = { params: { action: 'assign', addressId } };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_ASSIGN_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_ASSIGN_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_APPROVEMANDANT_START = 'ORDER_APPROVEMANDANT_START';
export const ORDER_APPROVEMANDANT_SUCCESS = 'ORDER_APPROVEMANDANT_SUCCESS';
export const ORDER_APPROVEMANDANT_ERROR = 'ORDER_APPROVEMANDANT_ERROR';

/**
 * approveMandant()
 * Approve an order created by a mandant
 * @param order
 * @returns {function(*): Promise<unknown>}
 */
export function approveMandant(order) {
  return (dispatch) => {
    dispatch({ type: ORDER_APPROVEMANDANT_START });

    const uri = `${defaultUri}/${order.orderId}`;
    const config = { params: { action: 'approveMandant' } };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_APPROVEMANDANT_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_APPROVEMANDANT_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const ORDER_SEARCH_BY_CLIENT_START = 'ORDER_SEARCH_BY_CLIENT_START';
export const ORDER_SEARCH_BY_CLIENT_SUCCESS = 'ORDER_SEARCH_BY_CLIENT_SUCCESS';
export const ORDER_SEARCH_BY_CLIENT_ERROR = 'ORDER_SEARCH_BY_CLIENT_ERROR';

/**
 * useDispatchSearchByClient()
 * @returns {function(*): *}
 */
export function useDispatchSearchByClient() {
  const dispatch = Redux.useDispatch();

  return React.useCallback((searchValue) => {
    const config = {
      params: {
        action: 'search',
        searchValue,
      },
    };

    dispatch({ type: ORDER_SEARCH_BY_CLIENT_START, payload: { defaultUri, config } });

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_SEARCH_BY_CLIENT_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_SEARCH_BY_CLIENT_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const ORDER_SEARCH_BY_USER_START = 'ORDER_SEARCH_BY_USER_START';
export const ORDER_SEARCH_BY_USER_SUCCESS = 'ORDER_SEARCH_BY_USER_SUCCESS';
export const ORDER_SEARCH_BY_USER_ERROR = 'ORDER_SEARCH_BY_USER_ERROR';

/**
 * useDispatchSearchByUser()
 * @returns {function(*): *}
 */
export function useDispatchSearchByUser() {
  const dispatch = Redux.useDispatch();

  return React.useCallback((searchValue) => {
    const config = {
      params: {
        action: 'userSearch',
        searchValue,
      },
    };

    dispatch({ type: ORDER_SEARCH_BY_USER_START, payload: { defaultUri, config } });

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: ORDER_SEARCH_BY_USER_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: ORDER_SEARCH_BY_USER_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
