import React from 'react';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.theme.timelineWidth}px;

  .HourCell {
    display: flex;
    min-width: ${(props) => props.theme.cellWidth}px;
    max-width: ${(props) => props.theme.cellWidth}px;
    height: ${(props) => props.theme.cellHeadlineHeight}px;
    border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
    border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    
    &.NoWorkHour {
      ${(props) => props.theme.linearGradient.noWorkHour}
    }
    
    span {
      padding-left: 15px;
    }

    &:last-child {
      border-right: none;
    }
  }
`;

/**
 * Timeline()
 * @returns {*}
 * @constructor
 */
export default function Timeline() {
  const theme = useTheme();

  const renderTimes = () => {
    const {
      startHour, endHour, workDayStart, workDayEnd,
    } = theme;
    const startHourInt = parseInt(moment(workDayStart, 'HH:mm:ss').format('H'), 0);
    const endHourInt = parseInt(moment(workDayEnd, 'HH:mm:ss').format('H'), 0);
    const result = [];

    for (let i = startHour; i <= endHour; i += 1) {
      result.push((
        <div key={i} className={`HourCell ${(i < startHourInt || i >= endHourInt) ? 'NoWorkHour' : null}`}>
          <span>
            {`${i}:00`}
          </span>
        </div>
      ));
    }

    return result;
  };

  return (
    <Container>
      {renderTimes()}
    </Container>
  );
}
