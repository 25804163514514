import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatDate, minutesToTimeString } from '../../../Library/Functions';
import Panel from '../../../Components/Panel';
import BoxHideIcon from './BoxHideIcon';
import ReclamationStatusBadge from '../../../Components/ReclamationComponents/ReclamationStatusBadge';
import { RECLAMATIONSTATE_OPEN } from '../../../Library/StateTypes';
import { SERVICESTATE_OPEN } from '../../../Library/States/ServiceStates';
import ServiceStatusBadge from '../../../Components/ServiceComponents/ServiceStatusBadge';

/**
 * ReclamationTableView()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ReclamationTableView(props) {
  const {
    reclamations, headline, rowCount, onClick, subHeadline, reclamationState, identifier, services, serviceState,
    storageDashboardSettings, onHideBox,
  } = props;
  const [filtered, setFiltered] = React.useState(null);
  const [filteredReclamations, setFilteredReclamations] = React.useState([]);
  const [filteredServices, setFilteredServices] = React.useState([]);
  const [visibleByConfig, setVisibleByConfig] = React.useState(false);

  React.useEffect(() => {
    setVisibleByConfig((
      !storageDashboardSettings
      || storageDashboardSettings[identifier] === undefined
      || storageDashboardSettings[identifier] === true
    ));
  }, [identifier, storageDashboardSettings]);

  React.useEffect(() => {
    if (reclamations) {
      const filterData = reclamations.filter((item) => item.state === reclamationState);

      if (reclamationState === RECLAMATIONSTATE_OPEN) {
        const sorted = filterData.sort((a, b) => ((a.reclamationId > b.reclamationId) ? -1 : 1));
        setFilteredReclamations(sorted);
      } else {
        setFilteredReclamations(filterData);
      }
    }
  }, [reclamations, reclamationState]);

  React.useEffect(() => {
    if (services && serviceState) {
      const filterData = services.filter((item) => item.state === serviceState);

      if (serviceState === SERVICESTATE_OPEN) {
        const sorted = filterData.sort((a, b) => ((a.serviceId > b.serviceId) ? -1 : 1));
        setFilteredServices(sorted);
      } else {
        setFilteredServices(filterData);
      }
    }
  }, [services, serviceState]);

  React.useEffect(() => {
    const merged = [...filteredReclamations, ...filteredServices];
    const sorted = merged.sort((a, b) => ((moment(a.executionDate).isSameOrAfter(moment(b.executionDate))) ? 1 : -1));
    setFiltered(sorted);
  }, [filteredReclamations, filteredServices]);

  const renderReclamations = () => {
    const result = [];
    let currentCount = 1;

    if (filtered) {
      filtered.forEach((item) => {
        if (currentCount <= rowCount) {
          result.push((
            <Row
              key={(item.reclamationId) ? `reclamation${item.reclamationId}` : `service${item.serviceId}`}
              reclamation={item}
              onClick={onClick}
              state={reclamationState}
            />
          ));
          currentCount += 1;
        }
      });

      return result;
    }
    return null;
  };

  const renderSubHeadline = () => {
    if (subHeadline) {
      return (
        <div style={{ fontSize: 16, color: '#9f9f9f' }}>{subHeadline}</div>
      );
    }
    return null;
  };

  const renderStatusBadge = () => {
    if (serviceState) {
      return (<ServiceStatusBadge serviceState={serviceState} />);
    }
    return (<ReclamationStatusBadge reclamation={{ state: reclamationState }} />);
  };

  const renderHeadline = () => {
    if (React.isValidElement(headline)) {
      return headline;
    }

    return (
      <div className="d-flex flex-row">
        <div className="flex-grow-1">
          <h6 className="SecondaryHeadline" style={{ margin: 0 }}>
            {headline}
          </h6>
          {renderSubHeadline()}
        </div>
        <div>
          {renderStatusBadge()}
        </div>
      </div>
    );
  };

  const renderDeliveryDateHeadline = () => {
    if (reclamationState > 3) {
      return (<th>Ausführungsdatum</th>);
    }
    return null;
  };

  if (filtered && filtered.length > 0 && visibleByConfig) {
    return (
      <div style={{ display: 'flex' }}>
        <Panel containerStyle={{ flex: 1 }} marginBottom={25}>
          <BoxHideIcon onPress={() => onHideBox(identifier)} />

          {renderHeadline()}

          <table className="table table-hover linked" style={{ marginTop: 25 }}>
            <thead>
              <tr>
                <th>Typ</th>
                {renderDeliveryDateHeadline()}
                <th>Kommissions-Nr.</th>
                <th>Plz & Ort</th>
                <th className="text-right">Aufwand (ca.)</th>
              </tr>
            </thead>
            <tbody>
              {renderReclamations()}
            </tbody>
          </table>
        </Panel>
      </div>
    );
  }
  return null;
}

ReclamationTableView.propTypes = {
  reclamations: PropTypes.instanceOf(Array),
  reclamationState: PropTypes.number,
  services: PropTypes.instanceOf(Array),
  serviceState: PropTypes.number,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rowCount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  subHeadline: PropTypes.string,
  identifier: PropTypes.string.isRequired,
  storageDashboardSettings: PropTypes.instanceOf(Object),
  onHideBox: PropTypes.func.isRequired,
};

ReclamationTableView.defaultProps = {
  reclamations: [],
  reclamationState: undefined,
  services: [],
  serviceState: undefined,
  headline: '',
  rowCount: 100,
  subHeadline: null,
  storageDashboardSettings: null,
};

/**
 * Row()
 * @param props
 * @returns {*}
 * @constructor
 */
function Row(props) {
  const { reclamation, onClick, state } = props;

  const calcDeliveryDays = () => {
    const deliverDate = moment(reclamation.executionDate, 'YYYY-MM-DD').endOf('day');
    const days = deliverDate.diff(moment().endOf('day'), 'days');
    if (days === 0) {
      return 'Heute';
    }
    return `${days} Tage`;
  };

  const getDateString = () => {
    if (reclamation.executionDate) {
      return (
        `${formatDate(reclamation.executionDate, 'DD.MM.YYYY')} (${calcDeliveryDays()})`
      );
    }
    return '';
  };

  const renderDeliveryDate = () => {
    if (state > 3) {
      return <td>{getDateString()}</td>;
    }
    return null;
  };

  const renderAddress = () => {
    if (reclamation.zip && reclamation.city) {
      const address = `${reclamation.zip} ${reclamation.city}`;
      return (<td>{address}</td>);
    }
    return <td />;
  };

  return (
    <tr onClick={() => onClick(reclamation)}>
      <td>
        <i className="fas fa-sync" />
      </td>
      {renderDeliveryDate()}
      <td>{reclamation.objectExternalId}</td>
      {renderAddress()}
      <td className="text-right">
        {minutesToTimeString(reclamation.assemblyTime)}
      </td>
    </tr>
  );
}

Row.propTypes = {
  reclamation: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  state: PropTypes.number.isRequired,
};
