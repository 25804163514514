import React, { useEffect } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getList } from './Redux/Action/ClientAction';
import ClientList from './Component/ClientList';
import Panel from '../../Components/Panel';
import ClientMap from './Component/ClientMap';
import Button from '../../Components/Button';

/**
 * Clients()
 * @param props
 * @returns {*}
 * @constructor
 */
function Clients(props) {
  const {
    dispatchGetList, clients, history,
  } = props;
  const [showMap, setShowMap] = React.useState(false);

  useEffect(() => {
    dispatchGetList();
  }, [dispatchGetList]);

  const handleOnPress = (client) => {
    const uri = `/system/client/${client.clientId}`;
    history.push(uri);
  };

  const renderContent = () => {
    if (!showMap) {
      return (
        <ClientList clients={clients} onPress={handleOnPress} />
      );
    }
    return (
      <ClientMap />
    );
  };

  return (
    <div>
      <Panel marginBottom={25} boxPadding={10}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1 align-items-center d-flex">
            <h5 style={{ margin: 0 }}>Kundenübersicht</h5>
          </div>
          <div>
            <Button
              onClick={() => setShowMap(false)}
              style={{ marginRight: 10 }}
              type={(!showMap) ? 'btn-primary' : 'btn-light'}
            >
              <i className="fas fa-list" style={{ margin: 0 }} />
            </Button>
            <Button onClick={() => setShowMap(true)} type={(showMap) ? 'btn-primary' : 'btn-light'}>
              <i className="fas fa-map-marked" style={{ margin: 0 }} />
            </Button>
          </div>
        </div>
      </Panel>

      {renderContent()}
    </div>
  );
}

Clients.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
};

Clients.defaultProps = {
  clients: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    clients: store.systemClient.clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: () => dispatch(getList()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Clients));
