import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import Panel from '../../../Components/Panel';
import { formatCurrency, formatDate, formatDateTime } from '../../../Library/Functions';
import Button from '../../../Components/Button';
import ApproveOfferDialog from '../Component/ApproveOfferDialog';
import { approveOffer, deleteOffer } from '../Redux/Action/OrderAction';
import { getList } from '../Redux/Action/ClientAction';
import { get } from '../../../Redux/Action/OrderAction';
import Alert from '../../../Components/Alert';
import ModalDialog from '../../../Components/ModalDialog';
import { ORDERSTATE_WAITING_CALCULATION } from '../../../Library/StateTypes';

/**
 * OfferRow()
 * @param props
 * @returns {*}
 * @constructor
 */
function OfferRow(props) {
  const {
    clients, offer, onClick, onDelete, order,
  } = props;
  const [showAskDelete, setShowAskDelete] = React.useState(false);

  const getClient = () => {
    if (clients) {
      const filtered = clients.filter((item) => item.clientId === offer.clientId);
      if (filtered && filtered.length > 0) {
        return filtered[0].name;
      }
    }
    return null;
  };

  const handleOnDelete = () => {
    setShowAskDelete(false);
    onDelete(offer);
  };

  const renderAlternativeDate = () => {
    if (offer.alternativeDate) {
      const dayDiff = moment(offer.alternativeDate).diff(moment(order.deliveryDateStart), 'day');

      return (
        <td>
          {`${formatDate(offer.alternativeDate)} (${dayDiff} Tage)`}
        </td>
      );
    }
    return <td />;
  };

  const renderActionButtons = () => {
    if (order.state === ORDERSTATE_WAITING_CALCULATION) {
      return (
        <>
          <Button
            onClick={() => onClick(offer)}
            className="btn-sm"
            type="btn-success"
          >
            <i className="fas fa-check-circle" />
          </Button>
          <Button
            onClick={() => setShowAskDelete(true)}
            className="btn-sm"
            type="btn-primary"
            style={{ marginLeft: 10 }}
          >
            <i className="fas fa-trash" />
          </Button>
        </>
      );
    }
    return null;
  };

  return (
    <tr className={(offer.approved) ? 'table-success' : ''}>
      <td>
        {getClient()}

        <ModalDialog
          onClose={() => setShowAskDelete(false)}
          visible={showAskDelete}
          confirmCaption="Löschen"
          onConfirm={handleOnDelete}
          centered
        >
          <h5>Angebot endgültig löschen</h5>
          <p>
            Bitte bestätigen Sie das Sie das Angebot endgültig löschen möchten. Der Benutzer kann dann, falls notwendig,
            ein neues Angebot für diesen Auftrag abgeben.
          </p>
        </ModalDialog>
      </td>
      <td>{formatDateTime(offer.createdDate)}</td>
      {renderAlternativeDate()}
      <td align="right">{formatCurrency(offer.price)}</td>
      <td align="right">
        {renderActionButtons()}
      </td>
    </tr>
  );
}

OfferRow.propTypes = {
  clients: PropTypes.instanceOf(Array),
  offer: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
};

OfferRow.defaultProps = {
  clients: null,
};

/**
 * Offers()
 * @param props
 * @returns {*}
 * @constructor
 */
function Offers(props) {
  const {
    order, clients, dispatchApprove, history, dispatchGetDetails, dispatchDeleteOffer, dispatchGetClients,
  } = props;
  const [offers, setOffers] = React.useState(null);
  const [selectedOffer, setSelectedOffer] = React.useState(null);
  const [showSuccess, setShowSuccess] = React.useState(false);

  React.useEffect(() => {
    if (order) {
      setOffers(order.offers);
    }
  }, [order]);

  React.useEffect(() => {
    if (!clients || clients.length === 0) {
      dispatchGetClients();
    }
  }, [clients, dispatchGetClients]);

  const handleApprove = (data) => {
    const { offer, dealerPrice } = data;
    dispatchApprove(order.orderId, offer.offerId, dealerPrice).then(() => {
      dispatchGetDetails(order.orderId).then(() => {
        setSelectedOffer(null);
        setShowSuccess(true);
      });
    });
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
    history.push(`/system/order/${order.orderId}/details`);
  };

  const handleOnClickOffer = (data) => {
    setSelectedOffer(data);
  };

  const handleDelete = (data) => {
    dispatchDeleteOffer(order.orderId, data.offerId).then(() => {
      dispatchGetDetails(order.orderId).then((response) => {
        if (!response.offers || response.offers.length === 0) {
          history.push(`/system/order/${order.orderId}/details`);
        }
      });
    });
  };

  const renderTable = () => {
    if (offers) {
      return (
        <Panel>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Monteur</th>
                <th>Abgegeben am</th>
                <th>Alternativedatum</th>
                <th style={{ textAlign: 'right' }}>Angebot</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {offers.map((item) => (
                <OfferRow
                  clients={clients}
                  offer={item}
                  onClick={handleOnClickOffer}
                  key={item.offerId}
                  onDelete={handleDelete}
                  order={order}
                />
              ))}
            </tbody>
          </table>
        </Panel>
      );
    }
    return null;
  };

  return (
    <div>
      <Panel marginBottom={25}>
        <h4 style={{ margin: 0 }}>
          Individuelle Angebote
        </h4>
      </Panel>
      {renderTable()}

      <ApproveOfferDialog
        onSave={handleApprove}
        offer={selectedOffer}
        onClose={() => setSelectedOffer(null)}
        visible={(selectedOffer !== null)}
      />

      <Alert onClose={handleCloseSuccess} visible={showSuccess} type="success">
        Das Angebot wurde erfolgeich für diesen Auftrag übernommen und der Verkäufer wurde per E-Mail über das
        Angebot informiert.
      </Alert>
    </div>
  );
}

Offers.propTypes = {
  dispatchGetClients: PropTypes.func.isRequired,
  dispatchApprove: PropTypes.func.isRequired,
  dispatchGetDetails: PropTypes.func.isRequired,
  dispatchDeleteOffer: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  clients: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
};

Offers.defaultProps = {
  clients: null,
  order: null,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
    clients: store.systemClient.clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetDetails: (orderId) => dispatch(get(orderId, true)),
    dispatchDeleteOffer: (orderId, offerId) => dispatch(deleteOffer(orderId, offerId)),
    dispatchApprove: (orderId, offerId, dealerPrice) => dispatch(approveOffer(orderId, offerId, dealerPrice)),
    dispatchGetClients: () => dispatch(getList()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Offers));
