import React from 'react';
import PropTypes from 'prop-types';
import ObjectArrayFilter from '../../Filter/ObjectArrayFilter';
import { combinedStateCaption, useCombinedState } from '../../../../../Library/StateTypes';

/**
 * ObjectStateFilter(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectStateFilter(props) {
  const {
    objects, onFilterChange,
  } = props;

  const combineStates = useCombinedState();

  const filter = 'orderState';
  const useSystemView = true;
  const getFilterParameter = (object) => combineStates(object.orderState, object.state, object.internal, useSystemView);
  const getCaption = (option, count) => `${combinedStateCaption[Number(option)]} (${count})`;

  return (
    <ObjectArrayFilter
      objectsArray={objects}
      getFilterParameter={getFilterParameter}
      getCaption={getCaption}
      filter={filter}
      onFilterChange={onFilterChange}
    />
  );
}

ObjectStateFilter.propTypes = {
  objects: PropTypes.instanceOf(Array).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

ObjectStateFilter.defaultProps = {};
