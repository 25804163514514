import PropTypes from 'prop-types/prop-types';
import React from 'react';
import * as Redux from 'react-redux';
import { formatDate } from '../../../../../Library/Functions';
import ServiceStatusBadge from '../../../../../Components/ServiceComponents/ServiceStatusBadge';
import { objectServiceTypeCaptions } from '../../../../../Library/Types';

/**
 * ServiceRow(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ServiceRow(props) {
  const { serviceViewRow, onClick, isInternal } = props;
  const { clients } = Redux.useSelector((state) => state.systemClient);

  const getClient = () => {
    if (serviceViewRow.ownerName) {
      if (serviceViewRow.ownerName.length > 40) {
        return `${serviceViewRow.ownerName.substring(0, 40)}...`;
      }
      return serviceViewRow.ownerName;
    }
    if (clients && clients.length > 0 && serviceViewRow.clientId) {
      const foundClient = clients.find((client) => client.clientId === serviceViewRow.clientId);
      if (foundClient && foundClient.name) {
        return foundClient.name;
      }
      return serviceViewRow.clientId;
    }
    return null;
  };

  const getExternalId = () => {
    if (serviceViewRow.objectExternalId && serviceViewRow.objectExternalId.length > 20) {
      return `${serviceViewRow.objectExternalId.substring(0, 20)}...`;
    }
    return serviceViewRow.objectExternalId;
  };

  const renderAssignee = () => {
    if (!isInternal) {
      if (serviceViewRow.assigneeName) {
        return (
          <td>{serviceViewRow.assigneeName}</td>
        );
      }
      if (clients && clients.length > 0 && serviceViewRow.assignedClientId) {
        const foundClient = clients.find((client) => client.clientId === serviceViewRow.assignedClientId);
        if (foundClient && foundClient.name) {
          return (
            <td>{foundClient.name}</td>
          );
        }
        return (
          <td>{serviceViewRow.assignedClientId}</td>
        );
      }
      return (
        <td />
      );
    }
    return null;
  };

  const getOption = () => {
    if (serviceViewRow.serviceType) {
      return objectServiceTypeCaptions[serviceViewRow.serviceType];
    }
    return null;
  };

  return (
    <tr onClick={() => onClick(serviceViewRow)}>
      <td>{serviceViewRow.serviceId}</td>
      <td>{getExternalId()}</td>
      <td>{getOption()}</td>
      <td>{getClient()}</td>
      <td style={{ whiteSpace: 'nowrap' }}>
        {(serviceViewRow.executionDate) ? formatDate(serviceViewRow.executionDate) : ''}
      </td>
      {renderAssignee()}
      <td>
        <ServiceStatusBadge serviceState={serviceViewRow.state} alignContainer="flex-end" useMediaQuery />
      </td>
    </tr>
  );
}

ServiceRow.defaultProps = {
  onClick: () => {},
};

ServiceRow.propTypes = {
  serviceViewRow: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  isInternal: PropTypes.bool.isRequired,
};
