import {
  CLIENTVOUCHER_GET_START, CLIENTVOUCHER_GET_ERROR, CLIENTVOUCHER_GET_SUCCESS,
} from '../Action/ClientVoucherAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  voucher: null,
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /**
     * GETLIST
     */
    case CLIENTVOUCHER_GET_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTVOUCHER_GET_SUCCESS: {
      return { ...state, voucher: action.payload, isLoading: false };
    }
    case CLIENTVOUCHER_GET_ERROR: {
      return { ...state, voucher: null, isLoading: false };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
