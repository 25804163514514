import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../../../../Components/Panel';
import {
  DEVICETYPE_DAMAGE,
  DEVICETYPE_PREDAMAGE,
  DEVICETYPE_RECLAMATION,
  DEVICETYPE_RECLAMATIONDEVICE,
} from '../../../../Library/Types';
import CheckBoxRow from '../../../../Components/ReportComponents/CheckBoxRow';

/**
 * Overview()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function ReportOverview(props) {
  const { report } = props;
  const [reclamations, setReclamations] = useState([]);
  const [damages, setDamages] = useState([]);
  const [preDamage, setPreDamage] = useState([]);
  const [notRepaired, setNotRepaired] = useState([]);
  const [checkWater, setCheckWater] = useState(false);
  const [checkElectric, setCheckElectric] = useState(false);

  useEffect(() => {
    if (report) {
      setReclamations(report.devices.filter((item) => item.type === DEVICETYPE_RECLAMATION));
      setDamages(report.devices.filter((item) => item.type === DEVICETYPE_DAMAGE));
      setPreDamage(report.devices.filter((item) => item.type === DEVICETYPE_PREDAMAGE));
      setNotRepaired(report.devices.filter((item) => (item.type === DEVICETYPE_RECLAMATIONDEVICE && !item.repaired)));
    }
  }, [report]);

  React.useEffect(() => {
    if (report) {
      const repairedReclamations = report.devices.filter((item) => (
        item.type === DEVICETYPE_RECLAMATIONDEVICE && item.repaired
      ));

      const hasWater = repairedReclamations.filter((item) => item.workOptionWater);
      const hasElectric = repairedReclamations.filter((item) => item.workOptionElectric);
      setCheckWater((hasWater && hasWater.length > 0));
      setCheckElectric((hasElectric && hasElectric.length > 0));
    }
  }, [report]);

  if (report) {
    return (
      <Panel marginBottom={25}>
        <h6>Übersicht</h6>
        <p style={{ marginBottom: 30 }}>
          Hier sehen Sie eine Zusammenfassung des Reklmationsberichts!
        </p>
        <table className="table-borderless">
          <tbody>
            <CheckBoxRow visible={(!checkElectric && checkWater)}>
              Alle Wasseranschlüsse wurden im Beisein des Kunden auf Dichtigkeit überprüft.
            </CheckBoxRow>
            <CheckBoxRow visible={(checkElectric && !checkWater)}>
              Alle Elektroanschlüsse wurden im Beisein des Kunden überprüft.
            </CheckBoxRow>
            <CheckBoxRow visible={(checkElectric && checkWater)}>
              Alle Anschlüsse wurden im Beisein des Kunden überprüft.
            </CheckBoxRow>
            <CheckBoxRow visible={preDamage.length === 0}>
              Es wurden keine Vorschäden festgestellt
            </CheckBoxRow>
            <CheckBoxRow visible={preDamage.length > 0} type="danger">
              <strong>Es wurden Vorschäden festgestellt</strong>
            </CheckBoxRow>
            <CheckBoxRow visible={reclamations.length === 0}>
              Es sind keine Reklamation vorhanden
            </CheckBoxRow>
            <CheckBoxRow visible={reclamations.length > 0} type="danger">
              <strong>Es sind Reklamationen vorhanden</strong>
            </CheckBoxRow>
            <CheckBoxRow visible={damages.length === 0}>
              Es wurde kein Kundeneigentum beschädigt
            </CheckBoxRow>
            <CheckBoxRow visible={damages.length > 0} type="danger">
              <strong>Es wurde Kundeneigentum beschädigt</strong>
            </CheckBoxRow>
            <CheckBoxRow visible={notRepaired.length === 0} type="success">
              Alle Reklamationen wurden ordnungsgemäß durchgeführt
            </CheckBoxRow>
            <CheckBoxRow visible={notRepaired.length > 0} type="danger">
              <strong>Es wurden nicht alle Reklamationen behoben</strong>
            </CheckBoxRow>

            <CheckBoxRow visible>
              Die einwandfreie Ausführung der Leistung wird hiermit bescheinigt.
            </CheckBoxRow>
          </tbody>
        </table>
      </Panel>
    );
  }
  return null;
}

ReportOverview.propTypes = {
  report: PropTypes.instanceOf(Object),
};

ReportOverview.defaultProps = {
  report: null,
};
