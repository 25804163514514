import React from 'react';
import PropTypes from 'prop-types';
import StatusBadge from '../StatusBadge';
import {
  orderStateCaption,
  orderStateIcon,
  orderStateType,
} from '../../Library/StateTypes';

/**
 * OrderStatusBadge()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function OrderStatusBadge(props) {
  const {
    orderState, containerStyle, visible, alignContainer, iconOnly, useMediaQuery,
  } = props;

  const getIcon = () => orderStateIcon[orderState];
  const getCaption = () => ((!iconOnly) ? orderStateCaption[orderState] : null);
  const getType = () => orderStateType[orderState];

  return (
    <StatusBadge
      type={getType()}
      icon={getIcon()}
      visible={visible}
      useMediaQuery={useMediaQuery}
      containerStyle={containerStyle}
      iconOnly={iconOnly}
      alignContainer={alignContainer}
    >
      {getCaption()}
    </StatusBadge>
  );
}

OrderStatusBadge.propTypes = {
  orderState: PropTypes.number,
  containerStyle: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  alignContainer: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  iconOnly: PropTypes.bool,
  useMediaQuery: PropTypes.bool,
};

OrderStatusBadge.defaultProps = {
  orderState: null,
  containerStyle: {},
  visible: true,
  alignContainer: 'flex-start',
  iconOnly: false,
  useMediaQuery: false,
};
