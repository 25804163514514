/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { getAll, remove } from '../../../Redux/Action/UserAction';
import UserTable from '../../../Components/UserTable';
import Button from '../../../Components/Button';
import Panel from '../../../Components/Panel';
import { NAVIGATION_CLIENT_ADMIN_USER_CREATE, NAVIGATION_CLIENT_ADMIN_USER_DETAILS } from '../../../Library/Types';
import { useDispatchFacilityLoginClient } from '../../../Redux/Action/FacilityAction';

/**
 * List()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function List(props) {
  const {
    users, getAllUsers, history, userGroups, client,
  } = props;

  const dispatchFacilityLoginClient = useDispatchFacilityLoginClient();

  const facilities = useSelector((state) => state.facility.facilities);
  const mainFacility = facilities.find((item) => item.isMainClient);
  const facility = facilities.find((item) => item.clientId === client.clientId);

  useEffect(() => {
    if (userGroups.indexOf('ADM') < 0) {
      history.push('/dashboard');
    }
  }, [userGroups, history]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  const onDelete = (deletedItem) => {
    props.remove(deletedItem).then(() => {
      getAllUsers();
    });
  };

  const navigateUserDetails = (data) => {
    const uri = NAVIGATION_CLIENT_ADMIN_USER_DETAILS.replace('{userId}', data.userId);
    history.push(uri);
  };

  if (facility && !facility.isMainClient) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Panel marginBottom={25}>
            <h4>Benutzerverwaltung</h4>
            Die Verwaltung von Benutzer ist nur über den Hauptbetriebsstätte möglich. (TODO Text)
            <div className="d-flex align-items-center" style={{ marginTop: 20 }}>
              <Button onClick={() => dispatchFacilityLoginClient(mainFacility.clientId)}>
                Betriebsstätte wechseln
              </Button>
            </div>
          </Panel>
        </div>
      </div>
    );
  }

  if (users) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Panel marginBottom={25}>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                <h4>Benutzerverwaltung</h4>
                <p style={{ marginBottom: 0 }}>
                  Verwalten Sie alle Ihre Benutzer und Berechtigungen!
                </p>
              </div>
              <div className="d-flex align-items-center">
                <Button onClick={() => history.push(NAVIGATION_CLIENT_ADMIN_USER_CREATE)}>
                  <i className="fas fa-plus" />
                  Erstellen
                </Button>
              </div>
            </div>
          </Panel>
        </div>

        <div className="col-sm-12">
          <UserTable
            users={users}
            onDelete={(item) => onDelete(item)}
            onUpdate={navigateUserDetails}
            showIds
          />
        </div>

        <div style={{ marginTop: 0, paddingRight: 15 }} className="d-flex justify-content-end flex-grow-1">
          {(client.settings.allowSubcontractor) ? '* Benutzer ist einem Subunternehmen zugewiesen.' : ''}
        </div>
      </div>
    );
  }
  return null;
}

function mapStoreToProps(store) {
  return {
    isLoading: store.user.isLoggedin,
    users: store.user.users,
    client: store.client.client,
    userGroups: store.auth.groups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllUsers: () => dispatch(getAll(true)),
    remove: (userData) => dispatch(remove(userData)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(List));
