import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Panel from '../Panel';

/**
 * CustomerInvoiceAddress
 * @param props
 * @returns {*}
 * @constructor
 */
function CustomerInvoiceAddress(props) {
  const {
    customer, client, order, object,
  } = props;

  if (client.type === 'DEALER'
    || order.internal
    || (object.montageDate && object.montageDate.clientId === client.clientId)
    || client.clientId === order.clientId
  ) {
    return (
      <Panel marginBottom={20} className="CustomerAddress">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6>Abweichende Rechnungsadresse</h6>
          </div>
        </div>

        <address style={{ marginTop: 20 }}>
          <div>
            <strong>
              {`${customer.invoiceFirstname} ${customer.invoiceLastname}`}
            </strong>
          </div>
          <div>{`${customer.invoiceStreet} ${customer.invoiceStreetNo}`}</div>
          <div>{`${customer.invoiceZip} ${customer.invoiceCity}`}</div>
        </address>
      </Panel>
    );
  }
}

CustomerInvoiceAddress.propTypes = {
  customer: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
};

function mapStoreToProps(store) {
  return {
    clientId: store.auth.client.clientId,
  };
}

export default connect(mapStoreToProps, () => ({}))(CustomerInvoiceAddress);
