import React from 'react';
import {
  Route, Redirect, Switch, useRouteMatch,
} from 'react-router-dom';
import RightNavigationLayout from '../Components/Layout/RightNavigationLayout';
import ObjectDetailsRightNavigation from '../Components/Navigation/ObjectDetailsRightNavigation';
import Messages from '../Components/ObjectComponents/Messages';
import ObjectDetails from '../Pages/Order/Details/ObjectDetails';
import TaskOverview from '../Pages/Order/Details/TaskOverview';
import ServiceReport from '../Pages/Order/ServiceReport/ServiceReport';
import DemontageReport from '../Pages/Order/DemontageReport/DemontageReport';
import DeliveryReport from '../Pages/Order/DeliveryReport/DeliveryReport';
import OrderDetailsReclamation from '../Pages/Order/Reclamation/Details';
import Report from '../Pages/Order/Reclamation/Report';
import ServiceCreate from '../Pages/Order/Service/Create';
import ServiceDetails from '../Pages/Order/Service/Details';
import ObjectNotes from '../Pages/Order/Object/Notes';
import ObjectServiceReport from '../Pages/Order/Service/Report';

export default function OrderObjectDetailsRoute() {
  const { path } = useRouteMatch();

  return (
    <RightNavigationLayout navigation={<ObjectDetailsRightNavigation />}>
      <Switch>
        <Route exact path={`${path}/details`}><ObjectDetails /></Route>
        <Route exact path={`${path}/messages`}><Messages /></Route>
        <Route exact path={`${path}/task`}><TaskOverview /></Route>
        <Route exact path={`${path}/servicereport/:reportId`}><ServiceReport /></Route>
        <Route exact path={`${path}/demontagereport/:reportId`}><DemontageReport /></Route>
        <Route exact path={`${path}/deliveryreport/:reportId`}><DeliveryReport /></Route>
        <Route exact path={`${path}/reclamation/:reclamationId`}><OrderDetailsReclamation /></Route>
        <Route exact path={`${path}/reclamation/:reclamationId/report`}><Report /></Route>
        <Route exact path={`${path}/service/create`}><ServiceCreate /></Route>
        <Route exact path={`${path}/service/:serviceId`}><ServiceDetails /></Route>
        <Route exact path={`${path}/service/:serviceId/report`}><ObjectServiceReport /></Route>
        <Route exact path={`${path}/notes`}><ObjectNotes /></Route>
        <Redirect to={`${path}/details`} />
      </Switch>
    </RightNavigationLayout>
  );
}
