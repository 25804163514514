import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './MonthSelector.css';

/**
 * DayBadge()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function MonthSelector(props) {
  const {
    currentMonth, onNext, onPrev, leftContainer, rightContainer,
  } = props;

  const renderLeftContainer = () => {
    if (leftContainer) {
      return leftContainer;
    }
    return null;
  };

  const renderRightContainer = () => {
    if (rightContainer) {
      return rightContainer;
    }
    return null;
  };

  if (currentMonth) {
    return (
      <div className="MonthSelector">
        <div style={{ width: 200 }}>
          {renderLeftContainer()}
        </div>
        <div className="Container">
          <div
            className="Left Arrow"
            onClick={() => onPrev(moment(currentMonth).subtract(1, 'months'))}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
          >
            <i className="fas fa-angle-left" />
          </div>
          <div className="Caption">
            {moment(currentMonth).format('MMMM YYYY')}
          </div>
          <div
            className="Right Arrow"
            onClick={() => onNext(moment(currentMonth).add(1, 'months'))}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
          >
            <i className="fas fa-angle-right" />
          </div>
        </div>
        <div style={{ width: 200, textAlign: 'right' }}>
          {renderRightContainer()}
        </div>
      </div>
    );
  }
  return null;
}

MonthSelector.propTypes = {
  currentMonth: PropTypes.instanceOf(Object).isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  leftContainer: PropTypes.instanceOf(Object),
  rightContainer: PropTypes.instanceOf(Object),
};

MonthSelector.defaultProps = {
  leftContainer: null,
  rightContainer: null,
};
