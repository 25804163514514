import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PanelContainer = styled.div`
  margin-bottom: 10px;

  &.Error {
    .card {
      border-color: ${(props) => props.theme.color.textDanger};
      color: ${(props) => props.theme.color.textDanger};
    }
  }

  .card {
    height: 150px;
    border-width: 1px;
    border-radius: ${(props) => props.theme.border.radius};

    &.link {
      cursor: pointer;
      color: #ededed;

      i {
        font-size: 50px;
      }
    }

    &:hover {
      &.link {
        color: #777777;
      }
    }
  }

  .ActionIcons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: 6px;
    position: absolute;
    top: 0;

    i {
      color: #ededed;
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        color: #000;
      }
    }
  }

  .PlugableWork {
    i {
      margin-right: 14px;
      color: #ccc;

      &.Active {
        color: #1a1a1a;
      }
    }
  }

  address {
    font-size: 12px;
    font-weight: lighter;
    color: #777777;
  }
`;

/**
 * ObjectPanel()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectPanel(props) {
  const {
    objects, onAdd, onDeleteObject, onEditObject, onCopyObject, hasError, showAdd,
  } = props;

  const renderAdd = () => {
    if (showAdd) {
      return <AddObject onAddClick={() => onAdd()} hasError={hasError} />;
    }
    return null;
  };

  return (
    <div className="row">
      {objects.map(
        (object) => (
          <ObjectContainer
            key={Math.random()}
            object={object}
            onDelete={(id) => onDeleteObject(id)}
            onEdit={(id) => onEditObject(id)}
            onCopy={(data) => onCopyObject(data)}
          />
        ),
      )}
      {renderAdd()}
    </div>
  );
}

ObjectPanel.propTypes = {
  objects: PropTypes.instanceOf(Array).isRequired,
  onAdd: PropTypes.func.isRequired,
  onDeleteObject: PropTypes.func.isRequired,
  onEditObject: PropTypes.func.isRequired,
  onCopyObject: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  showAdd: PropTypes.bool,
};

ObjectPanel.defaultProps = {
  hasError: false,
  showAdd: true,
};

/**
 * AddObject()
 * @param props
 * @returns {*}
 * @constructor
 */
function AddObject(props) {
  const { onAddClick, hasError } = props;

  return (
    <PanelContainer className={`col-lg-6 ${(hasError) ? 'Error' : ''}`}>
      <div
        className="card d-flex flex-column justify-content-center align-items-center link"
        onClick={() => onAddClick()}
        tabIndex={-1}
        onKeyPress={() => {}}
        role="button"
      >
        <div>
          <i className="fas fa-plus" />
        </div>
        <div>
          Hinzufügen
        </div>
      </div>
    </PanelContainer>
  );
}

AddObject.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

/**
 * Object()
 * @param props
 * @returns {*}
 * @constructor
 */
function ObjectContainer(props) {
  const {
    object, onDelete, onEdit, onCopy,
  } = props;
  const { customer } = object;

  return (
    <PanelContainer className="col-lg-6">
      <div className="card d-flex flex-column">
        <div className="ActionIcons">
          <i
            className="fas fa-edit"
            onClick={() => onEdit(object.id)}
            role="button"
            onKeyPress={() => {}}
            tabIndex={-1}
          />
          <i
            className="far fa-copy"
            onClick={() => onCopy(object)}
            role="button"
            onKeyPress={() => {}}
            tabIndex={-1}
          />
          <i
            className="fas fa-times-circle"
            onClick={() => onDelete(object.id)}
            role="button"
            onKeyPress={() => {}}
            tabIndex={-1}
          />
        </div>
        <div className="card-body">
          <h6>{object.externalId}</h6>
          <address>
            <div>{`${customer.firstname} ${customer.lastname}`}</div>
            <div>{`${customer.street} ${customer.streetNo}`}</div>
            <div>{`${customer.zip} ${customer.city}`}</div>
          </address>
          <div className="PlugableWork">
            <i className={`fas fa-plug ${(object.workOptionElectric) ? 'Active' : ''}`} />
            <i className={`fas fa-shower ${(object.workOptionWater) ? 'Active' : ''}`} />
            <i className={`fas fa-wind ${(object.workOptionAir) ? 'Active' : ''}`} />
            <i className={`fas fa-lightbulb ${(object.workOptionLight) ? 'Active' : ''}`} />
          </div>
        </div>
      </div>
    </PanelContainer>
  );
}

ObjectContainer.propTypes = {
  object: PropTypes.instanceOf(Object),
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
};

ObjectContainer.defaultProps = {
  object: {},
};
