import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { getList } from '../../Redux/Action/MaterialAction';
import { formatCurrency } from '../../Library/Functions';

/**
 * MaterialItem()
 * @param props
 * @returns {*}
 * @constructor
 */
function MaterialItem(props) {
  const { item, type, isWithDescription } = props;

  const renderPrice = () => {
    if (type === 'DEALER') {
      return formatCurrency(item.priceDealer);
    }
    return formatCurrency(item.priceMonteur);
  };

  const renderDescription = () => {
    if (isWithDescription) {
      return (
        <td>{item.description}</td>
      );
    }
    return null;
  };

  return (
    <tr key={item.materialId}>
      <td>{item.caption}</td>
      {renderDescription()}
      <td width={150} align="right">{renderPrice()}</td>
    </tr>
  );
}

MaterialItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.oneOf(['DEALER', 'MONTEUR']).isRequired,
  isWithDescription: PropTypes.bool.isRequired,
};

/**
 * MaterialTable()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function MaterialTable(props) {
  const {
    type, dispatchGetList, materials, isWithDescription,
  } = props;

  React.useEffect(() => {
    dispatchGetList();
  }, [dispatchGetList]);

  const renderDescription = () => {
    if (isWithDescription) {
      return (
        <th> Beschreibung </th>
      );
    }
    return null;
  };

  if (materials && materials.length > 0) {
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th>Bezeichnung / Material</th>
              {renderDescription()}
              <th>Preis (netto)</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((item) => (
              <MaterialItem
                item={item}
                type={type}
                key={item.materialId}
                isWithDescription={isWithDescription}
              />
            ))}
          </tbody>
        </table>
      </>
    );
  }
  return null;
}

MaterialTable.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  materials: PropTypes.instanceOf(Array),
  type: PropTypes.oneOf(['DEALER', 'MONTEUR']).isRequired,
  isWithDescription: PropTypes.bool,
};

MaterialTable.defaultProps = {
  materials: null,
  isWithDescription: false,
};

function mapStoreToProps(store) {
  return {
    materials: store.material.materials,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: () => dispatch(getList()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(MaterialTable);
