import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import Panel from '../../../Components/Panel';
import { getInvoices } from '../Redux/Action/ClientAction';
import InvoiceTable from '../../../Components/InvoiceTable';
import DetailsModal from '../Invoice/DetailsModal';
import { get } from '../Redux/Action/InvoiceAction';
import LoadingModal from '../../../Components/LoadingModal';

/**
 * Invoices()
 * @param props
 * @returns {*}
 * @constructor
 */
function Invoices(props) {
  const { dispatchGetList, dispatchGet } = props;
  const { clientId } = useParams();
  const [invoices, setInvoices] = React.useState(null);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const updateList = React.useCallback(() => {
    dispatchGetList(clientId).then((response) => {
      setInvoices(response);
    });
  }, [dispatchGetList, clientId]);

  React.useEffect(() => {
    setLoading(true);
    dispatchGetList(clientId).then((response) => {
      setInvoices(response);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [dispatchGetList, clientId]);

  const handleOnAction = (data) => {
    dispatchGet(data.transactionId).then((response) => {
      setSelectedInvoice(response);
    });
  };

  const handleCloseDetails = () => {
    setSelectedInvoice(null);
  };

  return (
    <>
      <Panel marginBottom={25}>
        <h5 style={{ margin: 0 }}>
          Rechnungsübersicht
        </h5>
      </Panel>

      <Panel>
        <InvoiceTable
          transactions={invoices}
          onAction={handleOnAction}
          actionIcon="fas fa-info-circle"
          onDownload={() => {}}
        />
      </Panel>

      <LoadingModal visible={loading} text="Rechnungen werden geladen..." />
      <DetailsModal
        transactionId={(selectedInvoice) ? selectedInvoice.transactionId : null}
        onClose={handleCloseDetails}
        onChange={() => updateList()}
      />
    </>
  );
}

Invoices.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGet: PropTypes.func.isRequired,
};

Invoices.defaultProps = {

};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: (clientId) => dispatch(getInvoices(clientId)),
    dispatchGet: (id) => dispatch(get(id)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(Invoices);
