import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatchUpdateSettings } from '../../../Redux/Action/ClientSettingsAction';
import Panel from '../../../Components/Panel';
import CustomSwitch from '../../../Components/CustomSwitch';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import {
  eventTypes,
} from '../../../Library/Types';
import EventList from './Components/EventList';

const activeInactiveOption = [
  { label: 'Aktiv, Statusupdates werden gesendet', value: true },
  { label: 'Inaktiv, es werden keine Statusupdates gesendet', value: false },
];

const YesNoOption = [
  { label: 'Aktiviert, E-Mails werden von hey.kitchen versendet', value: true },
  { label: 'Deaktiviert, es werden keine Kundenmails zu internen Aufträgen versendet', value: false },
];

/**
 * Events()
 * @returns {*}
 * @constructor
 */
export default function Events() {
  const [settings, setSettings] = React.useState(null);
  const [events, setEvents] = React.useState(null);
  const { client } = useSelector((state) => state.auth);
  const dispatchUpdateSettings = useDispatchUpdateSettings();

  React.useEffect(() => {
    setSettings(client.settings);
    setEvents((client.settings.eventsRegistered) ? client.settings.eventsRegistered.split(',') : []);
  }, [client]);

  const toggleEvent = (type) => {
    if (events.indexOf(type) >= 0) {
      const newArray = [...events];
      newArray.splice(events.indexOf(type), 1);
      dispatchUpdateSettings({ ...settings, eventsRegistered: newArray.join(',') });
    } else {
      const newArray = [...events];
      newArray.push(type);
      dispatchUpdateSettings({ ...settings, eventsRegistered: newArray.join(',') });
    }
  };

  const renderDeactivated = () => {
    if (!settings.allowUseEvents) {
      return (
        <Panel withPadding={false}>
          <div className="alert alert-info text-center" style={{ padding: 20, marginBottom: 0 }}>
            <div>
              <i className="fas fa-info-circle" style={{ marginRight: 6 }} />
            </div>
            Die Verwendung der key.kitchen Event API ist kostenpflichtig und muss für Ihren Account freigeschaltet
            werden. Für weitere Informationen oder die Freischaltung wenden Sie sich an den hey.kitchen Support!
          </div>
        </Panel>
      );
    }
    return null;
  };

  const renderEventTypes = () => (
    <Panel marginBottom={20}>
      <h5>Verfügbare Events</h5>
      <p style={{ marginBottom: 40 }}>
        Bitte wählen Sie über welche Events Sie informiert werden möchten. Beachten Sie das die Events
        mit einer Verzögerung von ca. 2 Minuten nach der letzten Aktion gesendet werden. Sollte innerhalb dieser Zeit
        mehrere Aktionen für ein Event ausgeführt worden sein, so wird immer nur die letzte Aktion gesendet.
        Beispiel: Sie fügen einem Objekt einen Monteur hinzu. Nach ca. einer Minute fügen Sie einen zweiten Monteur
        hinzu. Daraus resultiert ein Event welches nach 2 Minuten versendet wird mit den aktualisierten Objektdaten
        in welchen beide Monteure bereits hinzugefügt wurden.
      </p>

      <table className="table table-hover">
        <tbody>
          {
            eventTypes.map((item) => (
              <tr key={item.value}>
                <td>
                  <CustomSwitch
                    key={item.value}
                    caption={item.caption}
                    checked={events.indexOf(item.value) >= 0}
                    onChange={() => toggleEvent(item.value)}
                  />
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Panel>
  );

  const renderDeactivateMail = () => {
    if (settings.allowDisableInternalMails) {
      return (
        <>
          <h6 style={{ marginTop: 30 }}>
            Kunden-Mails bei internen Aufträge deaktivieren
          </h6>
          <p>
            Mit dieser Option können Sie die von hey.kitchen automatisch verendeten E-Mails an Ihre Kunden zu
            internen Aufträgen deaktivieren. Bitte beachten Sie das Sie dann selber für den Informationsfluss
            gegenüber Ihren Kunden verantwortlich sind! Zusammen mit der hey.kitchen Event API haben Sie alle
            Möglichkeiten selber E-Mails zu gestalten und zu versenden. Sollten Sie weitere Informationen oder
            Hilfe benötigen wenden Sie sich bitte an den hey.kitchen Support.
          </p>

          <KeyValueEditRow
            key="mail"
            title="Automatischer E-Mail-Versand"
            type="select"
            selectData={YesNoOption}
            initialValue={settings.internalMailsDisabled}
            displayText={
              (settings.internalMailsDisabled)
                ? 'Deaktiviert, es werden keine Kundenmails zu internen Aufträgen versendet'
                : 'Aktiviert, E-Mails werden von hey.kitchen versendet'
            }
            onChange={(value) => dispatchUpdateSettings({ ...settings, internalMailsDisabled: value })}
            required
          />

          <ShowHideContainer visible={settings.internalMailsDisabled}>
            <div className="alert alert-danger">
              <i className="fas fa-info-circle" style={{ marginRight: 6 }} />
              Achtung, der E-Mail Versand ist deaktiviert! Ihr Kunden erhalten
              <strong> keine </strong>
              automatischen E-Mails mehr zu Ihren internen Aufträgen von hey.kitchen!
            </div>
          </ShowHideContainer>
        </>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (settings.allowUseEvents) {
      return (
        <>
          <Panel marginBottom={20}>
            <h5 style={{ marginBottom: 20 }}>Einstellungen</h5>
            <KeyValueEditRow
              key="active"
              title="Event API Status"
              type="select"
              selectData={activeInactiveOption}
              initialValue={settings.eventsActive}
              displayText={
                (settings.eventsActive)
                  ? 'Aktiv, Statusupdates werden gesendet'
                  : 'Inaktiv, es werden keine Statusupdates gesendet'
              }
              onChange={(value) => dispatchUpdateSettings({ ...settings, eventsActive: value })}
              required
            />

            <KeyValueEditRow
              key="url"
              title="Event URL"
              type="text"
              initialValue={settings.eventUrl}
              displayText={settings.eventUrl}
              onChange={(value) => dispatchUpdateSettings({ ...settings, eventUrl: value.trim() })}
              required
            />

            <KeyValueEditRow
              key="header"
              title="Custom Authorization-Header"
              type="text"
              initialValue={settings.eventCustomAuthHeader}
              displayText={settings.eventCustomAuthHeader}
              onChange={(value) => dispatchUpdateSettings({ ...settings, eventCustomAuthHeader: value })}
            />

            {renderDeactivateMail()}
          </Panel>

          {renderEventTypes()}
        </>
      );
    }
    return null;
  };

  if (settings) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Panel marginBottom={20}>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                <h4>Event API</h4>
                <p style={{ marginBottom: 0 }}>
                  Mit der hey.kitchen Events API können Sie Statusupdates zu Aufträgen an ein externes System senden.
                  Dazu
                  benötigen Sie eine Schnittstelle die diese Events entgegennehmen kann und entsprechend verarbeitet.
                  Für weiteren technischen Support oder Hilfe wenden Sie sich bitte direkt an den hey.kitchen Support.
                </p>
              </div>
            </div>
          </Panel>

          {renderDeactivated()}
          {renderContent()}

          <EventList />
        </div>
      </div>
    );
  }
  return null;
}
