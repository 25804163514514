import axios from '../../Library/Axios';

const defaultUri = '/client/{clientId}/voucher';

export const CLIENTVOUCHER_GET_START = 'CLIENTVOUCHER_GET_START';
export const CLIENTVOUCHER_GET_SUCCESS = 'CLIENTVOUCHER_GET_SUCCESS';
export const CLIENTVOUCHER_GET_ERROR = 'CLIENTVOUCHER_GET_ERROR';

/**
 * get()
 * @param clientId
 * @returns {function(*): Promise<unknown>}
 */
export function get(clientId) {
  return (dispatch) => {
    dispatch({ type: CLIENTVOUCHER_GET_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      if (response.status === 200) {
        dispatch({ type: CLIENTVOUCHER_GET_SUCCESS, payload: data });
        return Promise.resolve(data);
      }
      dispatch({ type: CLIENTVOUCHER_GET_SUCCESS, payload: null });
      return Promise.resolve(null);
    }).catch((error) => {
      dispatch({ type: CLIENTVOUCHER_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CLIENTVOUCHER_REDEEM_START = 'CLIENTVOUCHER_REDEEM_START';
export const CLIENTVOUCHER_REDEEM_SUCCESS = 'CLIENTVOUCHER_REDEEM_SUCCESS';
export const CLIENTVOUCHER_REDEEM_ERROR = 'CLIENTVOUCHER_REDEEM_ERROR';

/**
 * redeem()
 * @param clientId
 * @param code
 * @returns {function(*): Promise<unknown>}
 */
export function redeem(clientId, code) {
  return (dispatch) => {
    dispatch({ type: CLIENTVOUCHER_REDEEM_START });

    const config = {
      params: { action: 'redeem' },
    };

    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().post(uri, { code }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTVOUCHER_REDEEM_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTVOUCHER_REDEEM_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CLIENTVOUCHER_ISVALID_START = 'CLIENTVOUCHER_ISVALID_START';
export const CLIENTVOUCHER_ISVALID_SUCCESS = 'CLIENTVOUCHER_ISVALID_SUCCESS';
export const CLIENTVOUCHER_ISVALID_ERROR = 'CLIENTVOUCHER_ISVALID_ERROR';

/**
 * isValid()
 * @param clientId
 * @param code
 * @param voucherType
 * @returns {function(*): Promise<unknown>}
 */
export function isValid(clientId, code, voucherType) {
  return (dispatch) => {
    dispatch({ type: CLIENTVOUCHER_ISVALID_START });

    const config = {
      params: {
        action: 'isValid',
        voucherType,
      },
    };

    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().post(uri, { code }, config).then((response) => {
      const { data } = response;
      dispatch({ type: CLIENTVOUCHER_ISVALID_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTVOUCHER_ISVALID_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENTVOUCHER_REMOVE_START = 'CLIENTVOUCHER_REMOVE_START';
export const CLIENTVOUCHER_REMOVE_SUCCESS = 'CLIENTVOUCHER_REMOVE_SUCCESS';
export const CLIENTVOUCHER_REMOVE_ERROR = 'CLIENTVOUCHER_REMOVE_ERROR';

/**
 * remove()
 * @param clientId
 * @param voucherId
 * @returns {function(*): Promise<unknown>}
 */
export function remove(clientId, voucherId) {
  return (dispatch) => {
    dispatch({ type: CLIENTVOUCHER_REMOVE_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}/${voucherId}`;

    return axios().delete(uri).then((response) => {
      const { result } = response.data;
      dispatch({ type: CLIENTVOUCHER_REMOVE_SUCCESS, payload: response });
      return Promise.resolve(result);
    }).catch((error) => {
      dispatch({ type: CLIENTVOUCHER_REMOVE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
