import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import styled from 'styled-components';
import Panel from '../../../../Components/Panel';
import ModalDialog from '../../../../Components/ModalDialog';
import Measurement from './Measurement';
import DefaultTheme from '../../../../Assets/DefaultTheme';

const Container = styled.div`
      margin-bottom: 25px; 
      &:hover {
        cursor: pointer;
    }
`;

/**
 * Overview()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function MeasurementList(props) {
  const {
    measurement,
  } = props;

  const [sketchDocument, setSketchDocument] = useState(null);
  const [showMeasurementModal, setShowMeasurementModal] = useState(false);

  React.useEffect(() => {
    if (!measurement || !measurement.documents || measurement.documents.length < 1) {
      return;
    }
    const filteredDocuments = measurement.documents.filter((document) => document.type === 'SKETCH');
    if (!filteredDocuments) {
      return;
    }
    setSketchDocument(filteredDocuments[0]);
  }, [measurement]);

  if (!measurement) {
    return null;
  }

  const renderInformation = () => {
    if (!measurement.height || !measurement.width) {
      return '';
    }

    let resultString = `${measurement.width} mm x ${measurement.height} mm `;
    if (measurement.cutouts && measurement.cutouts.length > 0) {
      resultString += ` Ausschnitte: ${measurement.cutouts.length} `;
    }
    return resultString;
  };

  return (
    <>
      <Container onClick={() => setShowMeasurementModal(true)}>
        <Panel boxPadding={10} containerStyle={{ backgroundColor: DefaultTheme.color.textGrayLight }}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 50 }}>
              <img src={(sketchDocument) ? sketchDocument.absolutePath : null} alt="Aufmaß" width={200} />
            </div>
            <div style={{ }}>
              <h6>{(measurement.name) ? measurement.name : ''}</h6>
              <p style={{ marginBottom: 30 }}>
                {renderInformation()}
              </p>
            </div>
          </div>
        </Panel>
      </Container>
      <ModalDialog
        onClose={() => setShowMeasurementModal(false)}
        visible={showMeasurementModal}
        hideConfirmButton
        closeCaption="Schließen"
      >
        <Measurement measurement={measurement} />
      </ModalDialog>
    </>

  );
}

MeasurementList.propTypes = {
  measurement: PropTypes.instanceOf(Object).isRequired,
};
