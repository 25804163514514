import axios from '../../../../Library/Axios';

const defaultUri = '/system/settings';

export const SYSTEM_SETTINGS_GET_START = 'SYSTEM_SETTINGS_GET_START';
export const SYSTEM_SETTINGS_GET_SUCCESS = 'SYSTEM_SETTINGS_GET_SUCCESS';
export const SYSTEM_SETTINGS_GET_ERROR = 'SYSTEM_SETTINGS_GET_ERROR';

/**
 * get()
 * @returns {function(*): Promise<any | never>}
 */
export function get() {
  return (dispatch) => {
    dispatch({ type: SYSTEM_SETTINGS_GET_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_SETTINGS_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_SETTINGS_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_SETTINGS_UPDATE_START = 'SYSTEM_SETTINGS_UPDATE_START';
export const SYSTEM_SETTINGS_UPDATE_SUCCESS = 'SYSTEM_SETTINGS_UPDATE_SUCCESS';
export const SYSTEM_SETTINGS_UPDATE_ERROR = 'SYSTEM_SETTINGS_UPDATE_ERROR';

/**
 * update()
 * @param field
 * @param value
 * @returns {function(*): *}
 */
export function update(field, value) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_SETTINGS_UPDATE_START });

    return axios().put(defaultUri, { field, value }).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_SETTINGS_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_SETTINGS_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
