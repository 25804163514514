import axios from '../../../../Library/Axios';

const defaultUri = '/system/voucher';

export const SYSTEM_VOUCHER_GETLIST_START = 'SYSTEM_VOUCHER_GETLIST_START';
export const SYSTEM_VOUCHER_GETLIST_SUCCESS = 'SYSTEM_VOUCHER_GETLIST_SUCCESS';
export const SYSTEM_VOUCHER_GETLIST_ERROR = 'SYSTEM_VOUCHER_GETLIST_ERROR';

/**
 * getList()
 * @returns {function(*): Promise<any | never>}
 */
export function getList() {
  return (dispatch) => {
    dispatch({ type: SYSTEM_VOUCHER_GETLIST_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_VOUCHER_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_VOUCHER_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_VOUCHER_CREATE_START = 'SYSTEM_VOUCHER_CREATE_START';
export const SYSTEM_VOUCHER_CREATE_SUCCESS = 'SYSTEM_VOUCHER_CREATE_SUCCESS';
export const SYSTEM_VOUCHER_CREATE_ERROR = 'SYSTEM_VOUCHER_CREATE_ERROR';

/**
 * create()
 * @param voucherData
 * @returns {function(*): Promise<unknown>}
 */
export function create(voucherData) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_VOUCHER_CREATE_START });

    return axios().post(defaultUri, voucherData).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_VOUCHER_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_VOUCHER_CREATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_VOUCHER_GET_START = 'SYSTEM_VOUCHER_GET_START';
export const SYSTEM_VOUCHER_GET_SUCCESS = 'SYSTEM_VOUCHER_GET_SUCCESS';
export const SYSTEM_VOUCHER_GET_ERROR = 'SYSTEM_VOUCHER_GET_ERROR';

/**
 * get()
 * @returns {function(*): Promise<any | never>}
 */
export function get(voucherId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_VOUCHER_GET_START });

    const uri = `${defaultUri}/${voucherId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_VOUCHER_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_VOUCHER_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_VOUCHER_DELETE_START = 'SYSTEM_VOUCHER_DELETE_START';
export const SYSTEM_VOUCHER_DELETE_SUCCESS = 'SYSTEM_VOUCHER_DELETE_SUCCESS';
export const SYSTEM_VOUCHER_DELETE_ERROR = 'SYSTEM_VOUCHER_DELETE_ERROR';

/**
 * deleteVoucher()
 * @returns {function(*): Promise<any | never>}
 */
export function deleteVoucher(voucherId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_VOUCHER_DELETE_START });

    const uri = `${defaultUri}/${voucherId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_VOUCHER_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_VOUCHER_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_VOUCHER_UPDATE_START = 'SYSTEM_VOUCHER_UPDATE_START';
export const SYSTEM_VOUCHER_UPDATE_SUCCESS = 'SYSTEM_VOUCHER_UPDATE_SUCCESS';
export const SYSTEM_VOUCHER_UPDATE_ERROR = 'SYSTEM_VOUCHER_UPDATE_ERROR';

/**
 * update()
 * @returns {function(*): Promise<any | never>}
 */
export function update(voucher) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_VOUCHER_UPDATE_START });

    const uri = `${defaultUri}/${voucher.voucherId}`;

    return axios().put(uri, voucher).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_VOUCHER_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_VOUCHER_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
