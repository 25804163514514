import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import './style.css';

/**
 * Footer()
 * @returns {*}
 * @constructor
 */
function Footer(props) {
  const { type } = props;

  return (
    <div className={`Footer FullPageBox ${type}`}>
      <div className="Box container">
        <div>
          &copy; hey.kitchen Copyright 2019. All Rights Reserved.
        </div>
        <div className="LinkContainer">
          <NavLink to="/kontakt" style={{ marginRight: 25 }}>Kontakt</NavLink>
          <NavLink to="/impressum" style={{ marginRight: 25 }}>Impressum</NavLink>
          <NavLink to="/datenschutz">Datenschutz</NavLink>
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {
  type: PropTypes.oneOf(['Dark', 'Light']),
};

Footer.defaultProps = {
  type: 'Light',
};

export default withRouter(Footer);
