import {
  SYSTEM_RECLAMATION_GET_LIST_ERROR,
  SYSTEM_RECLAMATION_GET_LIST_START,
  SYSTEM_RECLAMATION_GET_LIST_SUCCESS,
  SYSTEM_RECLAMATIONS_APPLY_FILTERS_FINISHED,
  SYSTEM_RECLAMATIONS_APPLY_FILTERS_START,
  SYSTEM_RECLAMATIONS_APPLY_FILTERS_SUCCESS,
  SYSTEM_RECLAMATIONS_UPDATE_FILTERS,
} from '../Action/ReclamationAction';
import {
  SYSTEM_RECLAMATIONREPORT_GET_ERROR,
  SYSTEM_RECLAMATIONREPORT_GET_SUCCESS,
} from '../Action/ReclamationReportAction';

const defaultValues = {
  reclamations: null,
  filteredReclamations: null,
  filters: null,
  lastUpdated: null,
  isLoading: false,
  isWorking: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case SYSTEM_RECLAMATION_GET_LIST_START: {
      const isLoading = true;
      return { ...state, isLoading };
    }

    case SYSTEM_RECLAMATION_GET_LIST_SUCCESS: {
      const { reclamations, lastUpdated } = action.payload;
      const isLoading = false;
      return {
        ...state, isLoading, reclamations, lastUpdated,
      };
    }

    case SYSTEM_RECLAMATION_GET_LIST_ERROR: {
      const isLoading = false;
      return { ...state, isLoading };
    }

    case SYSTEM_RECLAMATIONS_UPDATE_FILTERS: {
      const { newFilter } = action.payload;
      const filters = { ...state.filters, ...newFilter };
      return { ...state, filters };
    }

    case SYSTEM_RECLAMATIONS_APPLY_FILTERS_START: {
      const isWorking = true;
      return { ...state, isWorking };
    }

    case SYSTEM_RECLAMATIONS_APPLY_FILTERS_SUCCESS: {
      const { filteredReclamations } = action.payload;
      const isWorking = false;
      return { ...state, filteredReclamations, ...isWorking };
    }

    case SYSTEM_RECLAMATIONS_APPLY_FILTERS_FINISHED: {
      const isWorking = false;
      return { ...state, isWorking };
    }
    default:
      return { ...state };
    case SYSTEM_RECLAMATIONREPORT_GET_SUCCESS: {
      const { reclamationId, data } = action.payload;
      const merged = state.reclamations.map((item) => {
        if (item.reclamationId === reclamationId) {
          return { ...item, report: data };
        }
        return { ...item };
      });
      return { ...state, reclamations: merged };
    }
    case SYSTEM_RECLAMATIONREPORT_GET_ERROR: {
      const { reclamationId } = action.payload;
      const merged = state.reclamations.map((item) => {
        if (item.reclamationId === reclamationId) {
          return { ...item, report: [] };
        }
        return { ...item };
      });
      return { ...state, reclamations: merged };
    }
  }
}
