import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ModalDialog from '../../../../Components/ModalDialog';
import OrderObjectPanel, { initialObject } from './OrderObjectPanel';
import { validateObjectContainer } from '../../../../Library/Validator/FieldValidator';
import { ORDER_TYPE_OBJECT } from '../../../../Library/Types/OrderTypes';

const PanelContainer = styled.div`
  margin-bottom: 10px;

  &.Error {
    .card {
      border-color: ${(props) => props.theme.color.textDanger};
      color: ${(props) => props.theme.color.textDanger};
    }
  }

  .card {
    height: 150px;
    border-width: 1px;
    border-radius: ${(props) => props.theme.border.radius};

    &.link {
      cursor: pointer;
      color: #ededed;

      i {
        font-size: 50px;
      }
    }
    
    &.error {
      border-color: ${(props) => props.theme.color.textDanger};
    }

    &:hover {
      &.link {
        color: #777777;
      }
      
      &.error {
        color: ${(props) => props.theme.color.textDanger};
      }
    }
  }

  .ActionIcons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: 6px;
    position: absolute;
    top: 0;

    i {
      color: #ededed;
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        color: #000;
      }
    }
  }

  .PlugableWork {
    i {
      margin-right: 14px;
      color: #ccc;

      &.Active {
        color: #1a1a1a;
      }
    }
  }

  address {
    font-size: 12px;
    font-weight: lighter;
    color: #777777;
  }
`;

export default function OrderObjectContainer(props) {
  const {
    initialState, objects, isInternal, commentMandatory, headlineCounter, onConfirm, onEdit, onDelete, hasError,
  } = props;

  const [object, setObject] = React.useState(initialState || initialObject);
  const [editMode, setEditMode] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);

  const [checkErrors, setCheckErrors] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const checkObject = React.useCallback((value, values) => {
    const tmpErrors = validateObjectContainer(value, values, ORDER_TYPE_OBJECT, isInternal, commentMandatory);
    setErrors(tmpErrors);
    return Object.keys(tmpErrors).length === 0;
  }, [setErrors, isInternal, commentMandatory]);

  React.useEffect(() => {
    if (checkErrors) {
      const delayed = setTimeout(() => {
        checkObject(object, objects);
      }, 1000);

      return () => (delayed ? clearTimeout(delayed) : undefined);
    }
    return () => {};
  }, [object, objects, checkObject, checkErrors]);

  const handleOnConfirm = () => {
    if (checkObject(object, objects)) {
      if (onConfirm) {
        onConfirm(object);
      }
      setCheckErrors(false);
      setShowEditModal(false);
      setObject(initialState || initialObject);
    } else if (!checkErrors) {
      setCheckErrors(true);
    }
  };

  const handleOnEdit = () => {
    if (checkObject(object, objects)) {
      if (onEdit) {
        onEdit(object);
      }
      setShowEditModal(false);
    } else if (!checkErrors) {
      setCheckErrors(true);
    }
  };

  const handleOnDelete = () => {
    if (onDelete) {
      onDelete(object);
    }
  };

  const handleOnClose = () => {
    setShowEditModal(false);
    setObject(initialState || initialObject);
  };

  const renderAddObjectModal = () => (showEditModal ? (
    <ModalDialog
      onConfirm={editMode ? handleOnEdit : handleOnConfirm}
      onClose={handleOnClose}
      visible
    >
      <OrderObjectPanel
        initialState={editMode ? object : { ...object, id: null }}
        onChange={setObject}
        initialCounter={headlineCounter}
        errors={errors}
      />
    </ModalDialog>
  ) : null);

  const renderObject = () => (
    <div className="card d-flex flex-column">
      <div className="ActionIcons">
        <i
          className="fas fa-edit"
          onClick={() => {
            setEditMode(true);
            setShowEditModal(true);
          }}
          role="button"
          onKeyPress={() => {}}
          tabIndex={-1}
        />
        <i
          className="far fa-copy"
          onClick={() => {
            setEditMode(false);
            setShowEditModal(true);
          }}
          role="button"
          onKeyPress={() => {}}
          tabIndex={-1}
        />
        <i
          className="fas fa-times-circle"
          onClick={handleOnDelete}
          role="button"
          onKeyPress={() => {}}
          tabIndex={-1}
        />
      </div>
      <div className="card-body">
        <h6>{object.externalId}</h6>
        <address>
          <div>{`${object.customer.firstname} ${object.customer.lastname}`}</div>
          <div>{`${object.customer.street} ${object.customer.streetNo}`}</div>
          <div>{`${object.customer.zip} ${object.customer.city}`}</div>
        </address>
        <div className="PlugableWork">
          <i className={`fas fa-plug ${(object.workOptionElectric) ? 'Active' : ''}`} />
          <i className={`fas fa-shower ${(object.workOptionWater) ? 'Active' : ''}`} />
          <i className={`fas fa-wind ${(object.workOptionAir) ? 'Active' : ''}`} />
          <i className={`fas fa-lightbulb ${(object.workOptionLight) ? 'Active' : ''}`} />
        </div>
      </div>
    </div>
  );

  const renderAdd = () => (
    <div
      className={`card d-flex flex-column justify-content-center align-items-center link ${hasError ? 'error' : ''}`}
      onClick={() => {
        setEditMode(false);
        setShowEditModal(true);
      }}
      tabIndex={-1}
      onKeyPress={() => {}}
      role="button"
    >
      <div>
        <i className="fas fa-plus" />
      </div>
      <div>
        Hinzufügen
      </div>
    </div>
  );

  const renderContent = () => (initialState ? renderObject() : renderAdd());

  return (
    <div className="col-lg-6">
      <PanelContainer>
        {renderContent()}
      </PanelContainer>
      {renderAddObjectModal()}
    </div>
  );
}

OrderObjectContainer.propTypes = {
  initialState: PropTypes.instanceOf(Object),
  objects: PropTypes.instanceOf(Array),
  isInternal: PropTypes.bool,
  commentMandatory: PropTypes.bool,
  headlineCounter: PropTypes.number,
  onConfirm: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  hasError: PropTypes.bool,
};

OrderObjectContainer.defaultProps = {
  initialState: undefined,
  objects: [],
  isInternal: false,
  commentMandatory: false,
  headlineCounter: 1,
  onConfirm: undefined,
  onEdit: undefined,
  onDelete: undefined,
  hasError: false,
};
