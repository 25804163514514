import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalDialog from '../../../Components/ModalDialog';
import { getList, update } from '../../../Redux/Action/TeamsAction';
import { isObjectEmpty } from '../../../Library/Functions';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import { getAll } from '../../../Redux/Action/UserAction';

const initialState = {
  name: '',
  userIds: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload };
    case 'users': {
      const users = action.payload.monteure.filter((item) => action.payload.value.indexOf(item.userId) > -1);

      return {
        ...state,
        users: action.payload.value,
        userArray: users,
        usersDisplay: users.map((item) => (`${item.firstname} ${item.lastname}`)).join(' / '),
      };
    }
    case 'reset':
      return { ...initialState };
    case 'init': {
      const users = action.payload.monteure.filter((item) => action.payload.users.indexOf(item.userId) > -1);

      return {
        name: action.payload.name,
        users: action.payload.users,
        userArray: users,
        usersDisplay: users.map((item) => (`${item.firstname} ${item.lastname}`)).join(' / '),
      };
    }

    default:
      throw new Error();
  }
}

/**
 * Update()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Update(props) {
  const {
    visible, onClose, onSaved, updateTeam, updateData, dispatchGetAll, monteureOnly, dispatchGetAllUsers,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errors, setErrors] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    dispatchGetAllUsers();
  }, [dispatchGetAllUsers]);

  useEffect(() => {
    if (updateData) {
      dispatch({
        type: 'init',
        payload: {
          name: updateData.name,
          users: updateData.users.map((item) => item.userId),
          monteure: monteureOnly,
        },
      });
    }
  }, [updateData, monteureOnly]);

  React.useEffect(() => {
    if (monteureOnly && monteureOnly.length > 0) {
      const options = monteureOnly.map((item) => ({
        value: item.userId,
        label: `${item.firstname} ${item.lastname}`,
      }));
      setSelectOptions(options);
    }
  }, [monteureOnly]);

  const save = () => {
    setErrors({});
    let mergedErrors = {};
    if (!state.users || state.users.length === 0) {
      mergedErrors = { ...mergedErrors, users: true };
    }
    if (state.name === '') {
      mergedErrors = { ...mergedErrors, name: true };
    }

    if (isObjectEmpty(mergedErrors)) {
      updateTeam(updateData.teamId, state.name, state.userArray).then(() => {
        dispatch({ type: 'reset' });
        setErrors({});
        dispatchGetAll();
        onSaved();
      });
    } else {
      setErrors(mergedErrors);
    }
  };

  const onCancel = () => {
    dispatch({ type: 'reset' });
    setErrors({});
    onClose();
  };

  return (
    <ModalDialog onClose={() => onCancel()} onConfirm={() => save()} visible={visible}>
      <div className="row">
        <div className="col-sm-12">
          <h5 className="hk-text-primary">Montageteam bearbeiten</h5>

          <hr />

          <KeyValueEditRow
            title="Teamname"
            type="text"
            initialValue={state.name}
            displayText={state.name}
            onChange={(value) => dispatch({ type: 'name', payload: value })}
            containerTabIndex={1}
            required
            hasError={!!(errors.name)}
          />

          <KeyValueEditRow
            title="Monteure im Team"
            type="select"
            selectData={selectOptions}
            isMultiSelect
            initialValue={state.users}
            displayText={state.usersDisplay}
            onChange={(value) => dispatch({ type: 'users', payload: { value, monteure: monteureOnly } })}
            containerTabIndex={2}
            required
            hasError={!!(errors.users)}
          />
        </div>
      </div>
    </ModalDialog>
  );
}

function mapStoreToProps(store) {
  return {
    isLoading: store.user.isLoggedin,
    monteureOnly: store.user.monteureOnly,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateTeam: (id, name, userIds) => dispatch(update(id, name, userIds)),
    dispatchGetAll: () => dispatch(getList()),
    dispatchGetAllUsers: () => dispatch(getAll()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(Update);

Update.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
  dispatchGetAll: PropTypes.func.isRequired,
  updateData: PropTypes.instanceOf(Object),
  monteureOnly: PropTypes.instanceOf(Array),
  dispatchGetAllUsers: PropTypes.func.isRequired,
};

Update.defaultProps = {
  monteureOnly: null,
  updateData: null,
};
