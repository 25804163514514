import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getTransactionsByMonth } from '../Redux/Action/StatisticAction';
import Panel from '../../../Components/Panel';
import { formatCurrency } from '../../../Library/Functions';

/**
 * TransactionsByMonth()
 * @param props
 * @returns {*}
 * @constructor
 */
function TransactionsByMonth(props) {
  const { dispatchGet, data } = props;

  React.useEffect(() => {
    dispatchGet();
  }, [dispatchGet]);

  const getProjectedTurnOver = (item) => (
    (parseFloat((item.debit) ? item.debit : 0) + parseFloat((item.debitOpen) ? item.debitOpen : 0))
    - (parseFloat((item.voucher) ? item.voucher : 0) + parseFloat((item.voucherOpen) ? item.voucherOpen : 0))
  );

  const renderRow = (item) => (
    <tr>
      <td>
        {`${moment(`${item.year}-${item.month}-01`).format('MMMM')} ${item.year}`}
      </td>
      <td className="text-center text-success">
        {formatCurrency(item.voucher)}
      </td>
      <td className="text-center text-danger">
        {formatCurrency(item.voucherOpen)}
      </td>
      <td className="text-center text-success">
        {formatCurrency(item.debit)}
      </td>
      <td className="text-center text-danger">
        {formatCurrency(item.debitOpen)}
      </td>
      <td className="text-center text-muted">
        {formatCurrency(item.subscription)}
      </td>
      <td className="text-center">
        <strong>{formatCurrency(item.overall)}</strong>
      </td>
      <td className="text-muted text-center">
        {formatCurrency(getProjectedTurnOver(item))}
      </td>
      <td className={`text-center ${(item.turnover > 0) ? 'text-success' : 'text-danger'}`}>
        <strong>{formatCurrency(item.turnover)}</strong>
      </td>
    </tr>
  );

  if (data) {
    return (
      <>
        <Panel marginBottom={20}>
          <h5 className="hk-text-primary">
            Transaktionen nach Monat
          </h5>
          <p style={{ marginBottom: 0 }}>
            Diese Statistik zeigt alle über das System generierten Transaktionen (Rechnungen). Die Felder Gutschriften
            und Lastschriften sind die bereits erledigten Transaktionen. Die jeweils offenen beziehen sich auf die
            noch nicht erledigten/bezahlten Einträge. Der Umsatz bezieht sich NUR auf die bereits erledigten
            Transaktionen. Die Spalte "Anteil Abo" ist nur eine reine Infospalte um zu sehen wie hoch der Anteil der
            Abo's ist.
          </p>
        </Panel>
        <Panel boxPadding={10}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Monat</th>
                <th className="text-center">Gutschriften</th>
                <th className="text-center">Offene Gutschriften</th>
                <th className="text-center">Rechnungen</th>
                <th className="text-center">Offene Rechnungen</th>
                <th className="text-center text-muted">Anteil Abo</th>
                <th className="text-center">Gesamt</th>
                <th className="text-center text-muted">geplanter Umsatz</th>
                <th className="text-center">Akt. Umsatz</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => renderRow(item))}
            </tbody>
          </table>
        </Panel>
      </>
    );
  }
  return null;
}

TransactionsByMonth.propTypes = {
  dispatchGet: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array),
};

TransactionsByMonth.defaultProps = {
  data: null,
};

function mapStoreToProps(store) {
  return {
    data: store.systemStatistic.transactionsByMonth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: () => dispatch(getTransactionsByMonth()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(TransactionsByMonth);
