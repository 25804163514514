import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import de from 'date-fns/locale/de';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const DatePickerDiv = styled.div`
  .form-control {
    &.Disabled {
      background-color: hsl(0,0%,95%);
    }
  }

  .react-datepicker-wrapper {
    display: flex;

    .react-datepicker__input-container {
      display: flex;
      flex: 1;

      input {
        display: flex;
        flex: 1;
        border-radius: .3rem;
        border: none;

        &:disabled {
          background-color: hsl(0,0%,95%) !important;
        }
      }
    }
  }  
`;

export default function DatePickerInput(props) {
  const {
    value, label, placeholder, minDate, maxDate, disabled, showTimeSelect, onChange, onBlur,
    rightIcon, rightText, onRightAction, hasError, errorMessage,
  } = props;

  const getInputClass = () => {
    const classes = ['form-control'];

    if (hasError) {
      classes.push('is-invalid');
    }
    if (disabled) {
      classes.push('Disabled');
    }
    return classes.join(' ');
  };

  const handleOnChange = (date) => {
    if (onChange) {
      if (date) {
        onChange(moment(date).format('YYYY-MM-DD'));
      } else {
        onChange(null);
      }
    }
  };

  const renderLabel = () => (label ? <label>{label}</label> : null);

  const renderErrorMessage = () => (errorMessage ? (
    <div className="invalid-tooltip">
      {errorMessage}
    </div>
  ) : null);

  const renderRightIconOrText = () => ((rightIcon) ? <i className={rightIcon} /> : rightText);
  const renderButton = () => (onRightAction ? (
    <button className="btn btn-danger" type="button" id="button-addon2" onClick={onRightAction}>
      {renderRightIconOrText()}
    </button>
  ) : (
    <span className="input-group-text">
      {renderRightIconOrText()}
    </span>
  ));
  const renderRightAddon = () => ((rightIcon || rightText) ? (
    <div className="input-group-append">
      {renderButton()}
    </div>
  ) : null);

  return (
    <DatePickerDiv>
      <div className="form-group">
        {renderLabel()}
        <div className="input-group">
          <div className={getInputClass()}>
            <DatePicker
              selected={value ? moment(value).toDate() : null}
              onChange={handleOnChange}
              onBlur={onBlur}
              onChangeRaw={(e) => e.preventDefault()}
              minDate={minDate}
              maxDate={maxDate}
              disabled={disabled}
              placeholderText={placeholder}
              dateFormat={(!showTimeSelect) ? 'iiii, dd.MM.yyyy' : 'iiii, dd.MM.yyyy HH:ii'}
              showTimeSelect={showTimeSelect}
              locale={de}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              showWeekNumbers
            />
          </div>
          {renderErrorMessage()}
          {renderRightAddon()}
        </div>

      </div>
    </DatePickerDiv>
  );
}

DatePickerInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.instanceOf(Object),
  maxDate: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  rightIcon: PropTypes.string,
  rightText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onRightAction: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

DatePickerInput.defaultProps = {
  label: null,
  value: null,
  placeholder: null,
  minDate: null,
  maxDate: null,
  disabled: false,
  showTimeSelect: false,
  rightIcon: null,
  rightText: null,
  onChange: undefined,
  onBlur: () => {},
  onRightAction: undefined,
  hasError: false,
  errorMessage: null,
};
