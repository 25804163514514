import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ModalDialog from '../../../Components/ModalDialog';
import Input from '../../../Components/Input';
import { changePassword } from '../../../Redux/Action/UserAction';

/**
 * ChangePasswordDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
function ChangePasswordDialog(props) {
  const {
    user, onClose, visible, dispatchChangePassword, isLoading, onSuccess,
  } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeat, setRepeat] = useState('');
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    setErrors({});
    if (newPassword !== repeat) {
      setErrors({ repeat: 'Passwörter stimmen nicht überein!' });
      return;
    }
    if (!oldPassword || oldPassword.length === 0) {
      setErrors({ oldPassword: 'Bitte geben Sie Ihr aktuelles Passwort ein!' });
      return;
    }
    if (!newPassword || newPassword.length === 0) {
      setErrors({ newPassword: 'Bitte geben Sie Ihr neues Passwort ein!' });
      return;
    }
    if (newPassword.length < 8) {
      setErrors({ newPassword: 'Das neue Passwort muss mindestens 8 Zeichen lang sein!' });
      return;
    }

    dispatchChangePassword(user.userId, oldPassword, newPassword).then(() => {
      setErrors({});
      setRepeat('');
      setNewPassword('');
      setOldPassword('');
      onClose();
      onSuccess();
    }).catch(() => {
      setErrors({ oldPassword: 'Ihr aktuelles Passwort ist nicht korrekt!' });
    });
  };

  const handleClose = () => {
    setErrors({});
    setRepeat('');
    setNewPassword('');
    setOldPassword('');
    onClose();
  };

  return (
    <ModalDialog
      onClose={handleClose}
      visible={visible}
      confirmCaption="Passwort ändern"
      onConfirm={handleSave}
      disableButtons={isLoading}
    >
      <h5>Passwort ändern</h5>
      <p>
        Bitte geben Sie zuerst Ihr aktuelles Passwort ein und dann zwei mal Ihr neues Passwort! Nach erfolgreicher
        Änderung ist Ihr neues Passwort sofort gültig und Sie müssen dieses bei der nächsten Anmeldung auf der Webseite
        oder in der App verwenden.
      </p>
      <hr />

      <Input
        onChange={(value) => setOldPassword(value)}
        value={oldPassword}
        placeholder="Aktuelles Passwort"
        type="Password"
        required
        hasError={!!(errors.oldPassword)}
        errorMessage={(errors.oldPassword) ? errors.oldPassword : null}
        onFocus={() => setErrors({})}
        label="Ihr aktuelle Passwort"
      />

      <Input
        onChange={(value) => setNewPassword(value)}
        value={newPassword}
        placeholder="Neues Passwort"
        type="Password"
        hasError={!!(errors.newPassword)}
        errorMessage={(errors.newPassword) ? errors.newPassword : null}
        required
        onFocus={() => setErrors({})}
        label="Neues Passwort"
        style={{ marginTop: 30 }}
      />
      <Input
        onChange={(value) => setRepeat(value)}
        value={repeat}
        placeholder="Neues Passwort wiederholen"
        type="Password"
        hasError={!!(errors.repeat)}
        errorMessage={(errors.repeat) ? errors.repeat : null}
        required
        onFocus={() => setErrors({})}
      />
    </ModalDialog>
  );
}

ChangePasswordDialog.propTypes = {
  user: PropTypes.instanceOf(Object),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dispatchChangePassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
};

ChangePasswordDialog.defaultProps = {
  user: null,
  onSuccess: () => {},
};

function mapStoreToProps(store) {
  return {
    isLoading: store.user.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchChangePassword: (userId, oldPassword, newPassword) => dispatch(
      changePassword(userId, oldPassword, newPassword),
    ),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ChangePasswordDialog));
