import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { getOrderOffers } from '../../../../../Redux/Action/ClientAction';
import Panel from '../../../../../Components/Panel';
import { CLIENTTYPE_MONTEUR } from '../../../../../Library/Types';
import Button from '../../../../../Components/Button';
import { formatCurrency } from '../../../../../Library/Functions';
import AddOfferDialog from './AddOfferDialog';
import { createOffer, deleteOffer } from '../../../../../Redux/Action/OrderAction';
import Alert from '../../../../../Components/Alert';
import ModalDialog from '../../../../../Components/ModalDialog';
import { ORDERSTATE_WAITING_CALCULATION } from '../../../../../Library/StateTypes';

/**
 * MakeOfferHandler()
 * @param props
 * @constructor
 */
function MakeOfferHandler(props) {
  const {
    order, client, dispatchGetOrderOffers, orderOffers, dispatchCreateOffer, dispatchDeleteOffer, visible,
  } = props;
  const [existingOffer, setExistingOffer] = React.useState(null);
  const [showAdd, setShowAdd] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [askDelete, setAskDelete] = React.useState(false);

  React.useEffect(() => {
    if (order.state === ORDERSTATE_WAITING_CALCULATION && client.type === CLIENTTYPE_MONTEUR) {
      dispatchGetOrderOffers();
    }
  }, [dispatchGetOrderOffers, order, client]);

  React.useEffect(() => {
    if (orderOffers && order && orderOffers.length > 0) {
      const filtered = orderOffers.filter((item) => item.orderId === order.orderId);
      setExistingOffer((filtered.length > 0) ? filtered[0] : null);
    } else {
      setExistingOffer(null);
    }
  }, [orderOffers, order]);

  const handleCreateOffer = (data) => {
    const { price, addressId, dates } = data;
    dispatchCreateOffer(order, price, addressId, dates).then(() => {
      dispatchGetOrderOffers().then(() => {
        setShowSuccess(true);
        setShowAdd(false);
      });
    });
  };

  const handleDeleteOffer = () => {
    dispatchDeleteOffer(order).then(() => {
      setAskDelete(false);
      dispatchGetOrderOffers();
    });
  };

  if (order.state === ORDERSTATE_WAITING_CALCULATION && client.type === CLIENTTYPE_MONTEUR && visible) {
    if (!existingOffer) {
      return (
        <Panel marginBottom={20}>
          <div className="text-center">
            <h4 className="hk-text-primary">
              Individuelles Angebot / Termin abgeben!
            </h4>
            <p>
              <strong>
                Sie können für diesen Auftrag ein individuelles Angebot / Termin abgeben.
              </strong>
            </p>
            <p>
              Sollte Ihr Angebot passen, werden Sie von uns automatisch per E-Mail informiert und der Auftrag wird Ihnen
              zugewiesen. Sie können maximal ein Angebot pro Auftrag abgeben!
              <br />
              Das Angebot kann nach dem versenden nicht erneut angepasst werden. Bitte prüfen Sie vor der
              Abgabe Ihres Angebot alle Auftragsdetails!
              <br />
              Mit der Abgabe Ihres Angebots verpflichten Sie sich den Auftrag
              zu übernehmen, sollte Ihr Angebot angenommen werden.
            </p>

            <Button onClick={() => setShowAdd(true)} style={{ marginTop: 20 }}>
              Jetzt individuelles Angebot / Termin abgeben
            </Button>
          </div>

          <AddOfferDialog
            onClose={() => setShowAdd(false)}
            onSave={handleCreateOffer}
            order={order}
            visible={showAdd}
          />
        </Panel>
      );
    }
    if (existingOffer) {
      return (
        <Panel marginBottom={20}>
          <div className="text-center">
            <h4 className="hk-text-primary">
              Ihr individuelles Angebot für diesen Auftrag!
            </h4>
            <p>
              <strong>
                Sie haben für diesen Auftrag bereits ein individuelles Angebot abgegeben!
              </strong>
            </p>
            <p>
              Ihr Angebotspreis:
            </p>
            <h2 className="text-success" style={{ marginTop: 30 }}>
              <strong>
                {formatCurrency(existingOffer.price)}
              </strong>
            </h2>

            <div style={{ marginTop: 30 }}>
              <Button type="btn btn-sm btn-primary" onClick={() => setAskDelete(true)}>
                Angebot zurücknehmen
              </Button>
            </div>
          </div>

          <ModalDialog
            onClose={() => setAskDelete(false)}
            visible={askDelete}
            centered
            confirmCaption="Angebot zurückziehen"
            onConfirm={handleDeleteOffer}
          >
            <h5>Angebot zurückziehen?</h5>
            <p>
              Bitte bestätigen Sie das Sie Ihr Angebot zurückziehen möchten.
              <br />
              Sie können jederzeit ein neues Angebot für den Auftrag abgeben!
            </p>
          </ModalDialog>

          <Alert onClose={() => setShowSuccess(false)} visible={showSuccess} type="success">
            Ihr Angebot wurde gespeichert. Sie werden automatisch informiert, sollte Ihr Angebot angenommen werden!
          </Alert>
        </Panel>
      );
    }
  }
  return null;
}

MakeOfferHandler.propTypes = {
  dispatchGetOrderOffers: PropTypes.func.isRequired,
  dispatchCreateOffer: PropTypes.func.isRequired,
  dispatchDeleteOffer: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  orderOffers: PropTypes.instanceOf(Array),
  visible: PropTypes.bool,
};

MakeOfferHandler.defaultProps = {
  orderOffers: null,
  visible: false,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    orderOffers: store.client.offers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetOrderOffers: () => dispatch(getOrderOffers()),
    dispatchCreateOffer: (order, price, addressId, dates) => dispatch(createOffer(order, price, addressId, dates)),
    dispatchDeleteOffer: (order) => dispatch(deleteOffer(order)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(MakeOfferHandler);
