import React from 'react';
import {
  Route, withRouter, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import RightNavigationLayout from '../../Components/Layout/RightNavigationLayout';
import SystemClientDetailsNavigation from '../../Components/Navigation/SystemClientDetailsNavigation';
import SystemClientDetails from '../../Pages/System/Client/Details';
import SystemClientOrders from '../../Pages/System/Client/Orders';
import SystemClientInvoices from '../../Pages/System/Client/Invoices';
import SystemClientNotes from '../../Pages/System/Client/Notes';

/**
 * SystemClientDetailsRoutes()
 * @returns {*}
 * @constructor
 */
function SystemClientDetailsRoutes() {
  const { path, url } = useRouteMatch();

  return (
    <RightNavigationLayout navigation={<SystemClientDetailsNavigation />}>
      <Switch>
        <Route exact path={`${path}/details`}><SystemClientDetails /></Route>
        <Route exact path={`${path}/orders`}><SystemClientOrders /></Route>
        <Route exact path={`${path}/invoices`}><SystemClientInvoices /></Route>
        <Route exact path={`${path}/notes`}><SystemClientNotes /></Route>
        <Route><Redirect to={`${url}/details`} /></Route>
      </Switch>
    </RightNavigationLayout>
  );
}

function mapStoreToProps() {
  return {

  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(SystemClientDetailsRoutes));
