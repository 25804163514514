import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Panel from '../../Panel';
import { getList } from '../../../Pages/System/Redux/Action/ClientAction';
import Button from '../../Button';
import ModalDialog from '../../ModalDialog';
import ClientList from '../../../Pages/System/Component/ClientList';
import ConfirmDeleteDialog from '../../Dialogs/ConfirmDeleteDialog';
import { useDispatchUpdatedAssignedClient } from '../../../Pages/System/Redux/Action/ObjectAction';

const ClientName = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

/**
 * AssignedClientAddress()
 * @param props
 * @returns {*}
 * @constructor
 */
function AssignedClientAddress(props) {
  const {
    clients, object, dispatchGetClients, onClientClick,
  } = props;
  const [client, setClient] = React.useState(null);
  const [showEditAssignedClient, setShowEditAssignedClient] = React.useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(false);

  const dispatchUpdateAssignedClient = useDispatchUpdatedAssignedClient(object.orderId, object.objectId);

  React.useEffect(() => {
    if (!clients) {
      dispatchGetClients();
    }
  }, [dispatchGetClients, clients]);

  React.useEffect(() => {
    if (object && object.montageDate && object.montageDate.clientId && clients) {
      const filtered = clients.filter((item) => item.clientId === object.montageDate.clientId)[0];
      if (filtered) {
        setClient(filtered);
      }
    }
  }, [object, clients]);

  const renderEditButton = () => (
    <Button onClick={() => setShowEditAssignedClient(true)} type="btn-light">
      <i
        className="fas fa-pencil-alt"
        onClick={() => setShowEditAssignedClient(true)}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
        style={{ margin: 0 }}
      />
    </Button>
  );

  const renderRemoveButton = () => (
    <Button onClick={() => setShowDeleteConfirmModal(true)} type="btn-light">
      <i
        className="fas fa-trash"
        onClick={() => setShowDeleteConfirmModal(true)}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
        style={{ margin: 0 }}
      />
    </Button>
  );

  const handleOnUpdateClient = (selectedClient) => {
    dispatchUpdateAssignedClient(selectedClient.clientId).then(() => {
      setClient(selectedClient);
    });
    setShowEditAssignedClient(false);
  };

  const handleOnRemoveClient = () => {
    dispatchUpdateAssignedClient(null).then(() => {
      setClient(null);
    });
    setShowDeleteConfirmModal(false);
  };

  const renderMonteurDetails = () => {
    if (client) {
      return (
        <address style={{ marginBottom: 0 }}>
          <ClientName onClick={() => onClientClick(client)}>
            <strong>
              {client.name}
            </strong>
          </ClientName>
          <div>{`${client.street} ${client.streetNo}`}</div>
          <div>{`${client.zip} ${client.city}`}</div>
          <div>{`Telefon: ${client.phone}`}</div>
        </address>
      );
    }
    return (
      <div>
        <i>Noch nicht zugewiesen</i>
      </div>
    );
  };

  const infoString = 'Hinweis: Bei durchgeführten Montagen werden die zugewiesenen Monteure / Teams nicht entfernt.';

  return (
    <>
      <Panel marginBottom={20} className="CustomerAddress">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6>Zugewiesener Monteur</h6>
          </div>
          <div>
            {renderEditButton()}
          </div>
          <div>
            {renderRemoveButton()}
          </div>
        </div>
        {renderMonteurDetails()}
      </Panel>
      <ModalDialog visible={showEditAssignedClient} onClose={() => setShowEditAssignedClient(false)}>
        {infoString}
        <div style={{ overflow: 'hidden', overflowY: 'scroll', height: '700px' }}>
          <ClientList clients={clients} onPress={handleOnUpdateClient} />
        </div>
      </ModalDialog>
      <ConfirmDeleteDialog
        headline="Bestätigung erforderlich"
        onClose={() => setShowDeleteConfirmModal(false)}
        onConfirm={handleOnRemoveClient}
        visible={showDeleteConfirmModal}
      >
        {infoString}
      </ConfirmDeleteDialog>
    </>
  );
}

AssignedClientAddress.propTypes = {
  clients: PropTypes.instanceOf(Array).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  dispatchGetClients: PropTypes.func.isRequired,
  onClientClick: PropTypes.func.isRequired,
};

AssignedClientAddress.defaultProps = {

};

function mapStoreToProps(store) {
  return {
    clients: store.systemClient.clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetClients: () => dispatch(getList()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(AssignedClientAddress);
