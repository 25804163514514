import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as Redux from 'react-redux';
import {
  ORDERSTATE_EXPIRED,
  ORDERSTATE_FINISHED,
  ORDERSTATE_PARTNER_CREATED,
} from './StateTypes';
import {
  SERVICESTATE_CLOSED,
  SERVICESTATE_REPORT_FINISHED,
} from './States/ServiceStates';

/**
 * Check if an user is allowed to edit an object in general by the group, the current state of the object or the
 * option with partners
 * @param object
 * @returns {boolean}
 */
export function useAccessIsObjectEditable(object) {
  const { client, groups } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.order);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (order && groups && object) {
      if (groups.indexOf('ADM') < 0 && groups.indexOf('VEK') < 0
        && groups.indexOf('DIS') < 0 && groups.indexOf('VER') < 0
      ) {
        setIsAllowed(false);
      } else if (order.state) {
        if (order.internal) {
          if (order.mandantId) {
            if ((order.mandantId === client.clientId && order.state === ORDERSTATE_PARTNER_CREATED)
              || (order.clientId === client.clientId && order.state !== ORDERSTATE_PARTNER_CREATED)
            ) {
              setIsAllowed(true);
            }
          } else {
            setIsAllowed(order.clientId === client.clientId);
          }
        }
      }
    }
  }, [object, order, groups, client.clientId]);

  return isAllowed;
}

/**
 * Check if a service is editable in general by group and service state
 * @param service
 * @returns {boolean}
 */
export function useAccessIsServiceEditable(service) {
  const { client, groups } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.order);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (order && groups && service) {
      if (groups.indexOf('ADM') < 0 && groups.indexOf('VEK') < 0
        && groups.indexOf('DIS') < 0 && groups.indexOf('VER') < 0
      ) {
        setIsAllowed(false);
      } else if (service.state) {
        if (service.state === SERVICESTATE_CLOSED || service.state === SERVICESTATE_REPORT_FINISHED
          || (order.clientId !== client.clientId)
        ) {
          setIsAllowed(false);
        } else {
          setIsAllowed(true);
        }
      }
    }
  }, [service, order, groups, client.clientId]);

  return isAllowed;
}

/**
 * Check if a serviceItem is deletable in general by group and service state
 * @param service
 * @returns {boolean}
 */
export function useAccessIsServiceItemDeletable(service) {
  const { client, groups } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.order);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (order && groups && service) {
      if (groups.indexOf('ADM') < 0 && groups.indexOf('VEK') < 0
        && groups.indexOf('DIS') < 0 && groups.indexOf('VER') < 0
      ) {
        setIsAllowed(false);
      } else if (service.state) {
        if (service.state === SERVICESTATE_CLOSED || service.state === SERVICESTATE_REPORT_FINISHED
          || (order.clientId !== client.clientId && order.mandantId !== client.clientId)
        ) {
          setIsAllowed(false);
        } else {
          setIsAllowed(true);
        }
      }
    }
  }, [service, order, groups, client.clientId]);

  return isAllowed;
}

/**
 * Check if user is allowed to load all client orders or only his own assigned ones
 * @returns {boolean}
 */
export function useAccessCanSeeAllClientOrders() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('VEK') >= 0
    || groups.indexOf('DIS') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * check if user is allowed to create services
 * @returns {boolean}
 */
export function useAccessCanCreateServices() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('VEK') >= 0
    || groups.indexOf('DIS') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * Check if user is allowed to assign objects to monteur or partners
 * @returns {boolean}
 */
export function useAccessCanAssignObjects() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('DIS') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * Check if user is allowed to schedule objects
 * @returns {boolean}
 */
export function useAccessCanScheduleObjects() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('DIS') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * Check if user is allowed to schedule objects
 * @returns {boolean}
 */
export function useAccessCanUseDisposition() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('VEK') >= 0
    || groups.indexOf('DIS') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * Check if user is allowed to schedule objects
 * @returns {boolean}
 */
export function useAccessCanUseCalendar() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('VEK') >= 0
    || groups.indexOf('DIS') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * Check if the user is allowed to create internal AND external orders
 * @returns {boolean}
 */
export function useAccessCanCreateOrders() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('VEK') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * Check if user is allowed to assign services to monteur or partners
 * @returns {boolean}
 */
export function useAccessCanAssignServices() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('DIS') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * Check if user is allowed to schedule services
 * @returns {boolean}
 */
export function useAccessCanScheduleServices() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('DIS') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * Check if user is allowed to see and create internal notes
 * @returns {boolean}
 */
export function useAccessCanUseInternalNotes() {
  const { groups } = useSelector((state) => state.auth);
  return (
    groups.indexOf('ADM') >= 0
    || groups.indexOf('VEK') >= 0
    || groups.indexOf('DIS') >= 0
    || groups.indexOf('VER') >= 0
  );
}

/**
 * Checks if the service is assignable
 * @param service
 * @returns {boolean}
 */
export function useAccessServiceIsAssignable(service) {
  if (service && service.state) {
    return (service.state !== SERVICESTATE_CLOSED && service.state !== SERVICESTATE_REPORT_FINISHED);
  }
  return false;
}

export function useAccessDateIsAssignable(date) {
  if (date && date.state) {
    return (date.state !== ORDERSTATE_FINISHED && date.state !== ORDERSTATE_EXPIRED);
  }
  return false;
}

/**
 * Check if user is allowed to assign services to monteur or partners
 * @returns {boolean}
 */
export function useAccessClientCanAssignService(service) {
  const { client } = useSelector((state) => state.auth);
  const { order } = Redux.useSelector((state) => state.order);
  const accessCanAssignServices = useAccessCanAssignServices();
  const accessServiceIsAssignable = useAccessServiceIsAssignable(service);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (accessCanAssignServices && accessServiceIsAssignable && order) {
      if (order.internal) {
        setIsAllowed(true);
      } else if (client.clientId === service.assignedClientId) {
        setIsAllowed(true);
      } else {
        setIsAllowed(false);
      }
    } else {
      setIsAllowed(false);
    }
  }, [accessCanAssignServices, accessServiceIsAssignable, order, client.clientId, service.assignedClientId]);

  return isAllowed;
}

/**
 * useAccessClientCanAssignObject
 * @param object
 * @returns {boolean}
 */
export function useAccessClientCanAssignObject(object) {
  const { client } = useSelector((state) => state.auth);
  const { order } = Redux.useSelector((state) => state.order);
  const { montageDate } = object;
  const accessCanAssignObjects = useAccessCanAssignObjects();
  const accessDateIsAssignable = useAccessDateIsAssignable(montageDate);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (accessCanAssignObjects && accessDateIsAssignable && order) {
      if (order.internal) {
        setIsAllowed(true);
      } else if (client.clientId === montageDate.clientId) {
        setIsAllowed(true);
      } else {
        setIsAllowed(false);
      }
    } else {
      setIsAllowed(false);
    }
  }, [accessCanAssignObjects, accessDateIsAssignable, order, client.clientId, montageDate.clientId]);

  return isAllowed;
}

export function useAccessClientIsOwner(order) {
  const { client } = Redux.useSelector((state) => state.auth);
  return !!(client && order && (client.clientId === order.clientId || client.clientId === order.mandantId));
}

export function useAccessClientIsAssigned(order, service) {
  const { client } = Redux.useSelector((state) => state.auth);
  return !!(client && service && client.clientId === service.assignedClientId);
}

export function useAccessClientIsOwnerOrAssigned(order, service) {
  const isOwner = useAccessClientIsOwner(order);
  const isAssigned = useAccessClientIsAssigned(order, service);

  return isOwner || isAssigned;
}
