import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { lighten } from 'polished';
import { useDispatchDispositionCalculateWorkload } from '../../../../Redux/Action/DispositionAction';

const Container = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => props.theme.cellWeekDayOverviewWidth}px;
  
  .HourCell {
    display: flex;
    flex-direction: column;
    min-width: ${(props) => props.theme.cellWeekDayOverviewWidth}px;
    max-width: ${(props) => props.theme.cellWeekDayOverviewWidth}px;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-grow: 1;
    max-height: ${(props) => props.theme.height}px;
    min-height: ${(props) => props.theme.height}px;
    border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
    border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
    background-color: ${(props) => props.theme.bgColor};
    color: ${(props) => props.theme.fontColor};
    
    .Percent {
      font-size: 17px;
      font-family: "DIN Next LT W02 Bold", sans-serif;
    }
    
    .Remaining {
      font-size: 13px;
    }

    &:hover {
      opacity: 0.5 !important;
    }
  }
`;

/**
 * UserTimeLineRow()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UserTimeLineRow(props) {
  const {
    data, selectedDay, rowHeight, onClickCell,
  } = props;
  const dispatchCalculateWorkLoad = useDispatchDispositionCalculateWorkload();
  const [workLoad, setWorkLoad] = React.useState(null);
  const theme = useTheme();

  React.useEffect(() => {
    const type = (data.isUser) ? 'user' : 'team';
    dispatchCalculateWorkLoad(type, data.id, selectedDay, selectedDay).then((response) => {
      setWorkLoad(response);
    });
  }, [dispatchCalculateWorkLoad, data.id, data.isUser, selectedDay]);

  const calculateCellColors = () => {
    if (workLoad) {
      if (workLoad.workLoadPercent <= 50) {
        return {
          bgColor: lighten(0.2, '#0B6121'),
          fontColor: '#ffffff',
        };
      }
      if (workLoad.workLoadPercent <= 80) {
        return {
          bgColor: lighten(0.2, theme.color.primaryOrangeColor),
          fontColor: '#ffffff',
        };
      }
      if (workLoad.workLoadPercent <= 100) {
        return {
          bgColor: lighten(0.2, 'red'),
          fontColor: '#ffffff',
        };
      }
      return {
        bgColor: theme.color.primaryRedColor,
        fontColor: '#ffffff',
      };
    }
    return {};
  };

  const renderContent = () => {
    if (workLoad) {
      return (
        <>
          <div className="Percent">
            {`${workLoad.workLoadPercent}%`}
          </div>
          <div className="Remaining">
            {`${workLoad.workLoadRemaining} Minuten`}
          </div>
        </>
      );
    }
    return null;
  };

  const renderTimes = () => (
    <div
      className="HourCell"
      onClick={() => onClickCell(selectedDay)}
      key={`${selectedDay}`}
      tabIndex={-1}
      role="button"
      onKeyPress={() => {}}
    >
      {renderContent()}
    </div>
  );

  return (
    <Container theme={{ ...theme, height: rowHeight, ...calculateCellColors() }}>
      {renderTimes()}
    </Container>
  );
}

UserTimeLineRow.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  selectedDay: PropTypes.string.isRequired,
  rowHeight: PropTypes.number.isRequired,
  onClickCell: PropTypes.func.isRequired,
};
