import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Checkbox from '../Checkbox';
import Button from '../Button';
import { useDispatchGetUser } from '../../Redux/Action/UserAction';
import { useDispatchUpdateClient } from '../../Redux/Action/ClientAction';
import { CLIENTTYPE_DEALER } from '../../Library/Types';

export default function ApproveAgb() {
  const user = useSelector((store) => store.auth.user);
  const client = useSelector((store) => store.auth.client);
  const clientId = useSelector((store) => store.auth.clientId);

  const [agb, setAgb] = React.useState(false);
  const [adminUser, setAdminUser] = React.useState(null);

  const dispatchGetUser = useDispatchGetUser();
  const dispatchUpdateClient = useDispatchUpdateClient(clientId);

  React.useEffect(() => {
    if (client) {
      dispatchGetUser(client.adminUserId).then((response) => setAdminUser((response)));
    }
  }, [client, dispatchGetUser]);

  const renderAdmin = () => (adminUser ? (
    <>
      <br />
      <br />
      Folgender Kontakt ist bei Ihnen Administrator:
      <strong>{` ${adminUser.firstname} ${adminUser.lastname}`}</strong>
    </>
  ) : null);

  const approveAgb = () => {
    if (agb) {
      const merged = {
        ...client,
        externalAgbApproved: true,
        externalAgbApprovedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        externalAgbApprovedUserId: user.userId,
      };

      dispatchUpdateClient(merged);
    }
  };

  const renderDealer = () => (client.adminUserId === user.userId ? (
    <div>
      <p style={{ margin: 0 }}>
        Damit Sie und Ihre Kollegen weiterhin interne Aufträge einstellen können, müssen Sie unsere
        Allgemeinen Geschäftsbedingungen bestätigen.
      </p>
      <p>
        <Checkbox onChange={(data) => setAgb(data)} value={agb}>
          Ich habe die hey.kitchen AGB gelesen und stimme zu
        </Checkbox>
      </p>

      <div style={{ marginTop: 30 }} className="text-center">
        <Button onClick={approveAgb} disabled={!agb}>AGB jetzt bestätigen</Button>
      </div>
    </div>
  ) : (
    <div>
      <p style={{ margin: 0 }}>
        Bitte wenden Sie sich an den  Administrator Ihres hey.kitchen Accounts. Administrator ist derjenige,
        der sich bei uns registriert hat. Dieser muss unsere Allgemeinen Geschäftsbedingungen bestätigen, damit
        Sie weiterhin Aufträge eingeben können. Die Allgemeine Geschäftsbedingungen sind im Account des
        Administrators unter interne Aufräge zu finden und zu akzeptieren.
        {renderAdmin()}
      </p>
    </div>
  ));

  const renderMonteur = () => (client.adminUserId === user.userId ? (
    <div>
      <p style={{ margin: 0 }}>
        Damit Sie und Ihre Kollegen weiterhin Aufträge von hey.kitchen übernehmen können, müssen Sie unsere
        Allgemeinen Geschäftsbedingungen bestätigen.
      </p>
      <ul style={{ marginTop: 20 }} />
      <p>
        <Checkbox onChange={(data) => setAgb(data)} value={agb}>
          Ich habe die hey.kitchen AGB gelesen und stimme zu
        </Checkbox>
      </p>

      <div style={{ marginTop: 30 }} className="text-center">
        <Button onClick={approveAgb} disabled={!agb}>AGB jetzt bestätigen</Button>
      </div>
    </div>
  ) : (
    <div>
      <p style={{ margin: 0 }}>
        Bitte wenden Sie sich an den  Administrator Ihres hey.kitchen Accounts.
        Dieser muss unsere Allgemeinen Geschäftsbedingungen bestätigen, damit sie neue Aufträge von hey.kitchen
        übernhemen können.
        {renderAdmin()}
      </p>
    </div>
  ));

  const renderContent = () => (client.type === CLIENTTYPE_DEALER ? renderDealer() : renderMonteur());

  return (
    <>
      <h2 style={{ marginBottom: 30 }} className="hk-text-primary">
        <i className="fas fa-check-circle" style={{ marginRight: 20 }} />
        Allgemeine Geschäftsbedingungen bestätigen
      </h2>
      {renderContent()}
    </>
  );
}

ApproveAgb.propTypes = {};

ApproveAgb.defaultProps = {};
