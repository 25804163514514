import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Panel from '../../Components/Panel';
import KeyValueEditRow from '../../Components/KeyValueEditRow';
import { update } from '../../Redux/Action/UserAction';
import { updateUserData } from '../../Redux/Action/AuthAction';
import TextLink from '../../Components/TextLink';
import Alert from '../../Components/Alert';
import Avatar from '../../Components/Avatar';

/**
 * Profile()
 * @param props
 * @returns {*}
 * @constructor
 */
function Profile(props) {
  const { user, dispatchUpdate, dispatchUpdateAuthUser } = props;
  const [showNotImplemented, setShowNotImplemented] = useState(false);

  const handleUpdate = (key, value) => {
    const userData = { ...user, [key]: value, groups: null };
    dispatchUpdate(user.userId, userData).then((item) => {
      dispatchUpdateAuthUser(item);
    });
  };

  return (
    <form autoComplete="off">
      <div className="row">
        <div className="col-lg-4" style={{ display: 'flex' }}>
          <Panel containerStyle={{ flex: 1 }} marginBottom={25}>
            <h6 className="PrimaryHeadline">
              Profilbild
            </h6>

            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <Avatar size={200} />
              </div>
              <div className="d-flex justify-content-center" style={{ marginTop: 15 }}>
                <TextLink
                  caption="Profilbild ändern"
                  onPress={() => setShowNotImplemented(true)}
                  icon="fas fa-pencil-alt"
                />
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-lg-8 d-flex">
          <Panel marginBottom={25}>
            <h6 className="PrimaryHeadline">
              Persönliche Daten
            </h6>

            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Vorname"
                  type="text"
                  initialValue={user.firstname}
                  displayText={user.firstname}
                  onChange={(value) => handleUpdate('firstname', value)}
                  actionIcon="fas fa-pencil-alt"
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Nachname"
                  type="text"
                  initialValue={user.lastname}
                  displayText={user.lastname}
                  onChange={(value) => handleUpdate('lastname', value)}
                  actionIcon="fas fa-pencil-alt"
                />
              </div>
            </div>

            <KeyValueEditRow
              title="E-Mail Adresse"
              type="text"
              initialValue={user.mail}
              displayText={user.mail}
              onChange={(value) => handleUpdate('mail', value)}
              actionIcon="fas fa-pencil-alt"
            />
            <KeyValueEditRow
              title="Telefonnummer"
              type="text"
              initialValue={user.phone}
              displayText={(user.phone) ? user.phone : <i className="text-muted">Keine Telefonnummer hinterlegt</i>}
              onChange={(value) => handleUpdate('phone', value)}
              actionIcon="fas fa-pencil-alt"
            />
          </Panel>
        </div>
      </div>

      <Alert
        onClose={() => setShowNotImplemented(false)}
        type="exclamation"
        visible={showNotImplemented}
      >
        Diese Funktion ist auf der Webseite noch nicht verfügbar!
        <br />
        Bitte nutzen Sie die
        <strong> Hey.Kitchen App </strong>
        um Ihr Profilbild hoch zu laden.
      </Alert>
    </form>
  );
}

Profile.propTypes = {
  user: PropTypes.instanceOf(Object),
  dispatchUpdate: PropTypes.func.isRequired,
  dispatchUpdateAuthUser: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  user: null,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdate: (userId, userData) => dispatch(update(userId, userData)),
    dispatchUpdateAuthUser: (data) => dispatch(updateUserData(data)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Profile));
