import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Input from '../../../../Components/Input';
import {
  countertopDeliveryOptions, countertopOptions, ORDERTYPE_OBJECT, ORDERTYPE_SINGLE, workOptions, YesNoOption,
} from '../../../../Library/Types';
import SelectPanel from '../../../../Components/SelectPanels';
import HelpDialog from '../../../../Components/HelpDialog';
import Textarea from '../../../../Components/Textarea';
import { checkAndFormatPrice } from '../../../../Library/Functions';
import CustomReactSelect from '../../../../Components/CustomReactSelect';

function initReducer(data) {
  if (data) {
    let newData = data;
    if (data.withoutGrip === undefined) {
      newData = { ...newData, withoutGrip: false };
    }
    if (data.nicheTrim === undefined) {
      newData = { ...newData, nicheTrim: true };
    }
    if (data.exhaustHood === undefined) {
      newData = { ...newData, exhaustHood: false };
    }
    if (data.wallUnit === undefined) {
      newData = { ...newData, wallUnit: true };
    }
    if (!data.nicheMeasuring) {
      newData = { ...newData, nicheMeasuring: false };
    }

    const dataArray = [];
    if (data.workOptionElectric) dataArray.push('ELECTRIC');
    if (data.workOptionWater) dataArray.push('WATER');
    if (data.workOptionAir) dataArray.push('AIR');
    if (data.workOptionLight) dataArray.push('LIGHT');
    if (data.workOptionQuooker) dataArray.push('QUOOKER');

    return {
      ...newData,
      selectedWorkOptionsArray: dataArray,
      workOptionElectric: (data.workOptionElectric !== undefined) ? data.workOptionElectric : false,
      workOptionWater: (data.workOptionWater !== undefined) ? data.workOptionWater : false,
      workOptionAir: (data.workOptionAir !== undefined) ? data.workOptionAir : false,
      workOptionLight: (data.workOptionLight !== undefined) ? data.workOptionLight : false,
      workOptionQuooker: (data.workOptionQuooker !== undefined) ? data.workOptionQuooker : false,
    };
  }

  return {};
}

function reducer(state, action) {
  switch (action.type) {
    case 'price':
      return { ...state, price: checkAndFormatPrice(action.payload) };
    case 'balance':
      return { ...state, balance: checkAndFormatPrice(action.payload) };
    case 'externalId':
      return { ...state, externalId: action.payload };
    case 'withoutGrip':
      return { ...state, withoutGrip: action.payload };
    case 'nicheTrim':
      return { ...state, nicheTrim: action.payload };
    case 'nicheMeasuring':
      return { ...state, nicheMeasuring: action.payload };
    case 'wallUnit':
      return { ...state, wallUnit: action.payload };
    case 'runningMeterBottom':
      return { ...state, runningMeterBottom: action.payload };
    case 'workOptions': {
      return {
        ...state,
        selectedWorkOptionsArray: action.payload,
        workOptionElectric: (action.payload.indexOf('ELECTRIC') > -1),
        workOptionWater: (action.payload.indexOf('WATER') > -1),
        workOptionAir: (action.payload.indexOf('AIR') > -1),
        workOptionLight: (action.payload.indexOf('LIGHT') > -1),
        workOptionQuooker: (action.payload.indexOf('QUOOKER') > -1),
      };
    }
    case 'countertopType':
      return { ...state, countertopType: action.payload };
    case 'countertopOption':
      return { ...state, countertopOption: action.payload };
    case 'exhaustHood':
      return { ...state, exhaustHood: action.payload };
    case 'comment':
      return { ...state, comment: action.payload };

    default:
      throw new Error();
  }
}

function KitchenInformation(props) {
  const {
    onChange, orderType, editableData, errors, client, isInternal,
  } = props;
  const [state, dispatch] = useReducer(reducer, editableData, initReducer);
  const [prevState, setPrevState] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [helpContext, setHelpContext] = useState(null);

  useEffect(() => {
    if (!client.devoutIdentificationNumber && !isInternal) {
      dispatch({ type: 'balance', payload: '0.00' });
    }
  }, [client, isInternal]);

  useEffect(() => {
    if (JSON.stringify(state) !== prevState) {
      if (onChange) {
        const data = { ...state };
        // Set some values to null which are not needed for object orders
        if (orderType === ORDERTYPE_OBJECT) {
          delete data.withoutGrip;
          delete data.nicheTrim;
          delete data.wallUnit;
          delete data.runningMeterBottom;
          delete data.exhaustHood;
          delete data.nicheMeasuring;
        }

        onChange(data);
        setPrevState(JSON.stringify(state));
      }
    }
  }, [onChange, state, prevState, orderType]);

  const getHeadline = () => {
    if (orderType === ORDERTYPE_SINGLE) {
      return '5. KÜCHENINFORMATIONEN';
    }
    return '4.2 KÜCHENINFORMATIONEN';
  };

  const renderCountertopOption = () => {
    if (state.countertopType === 'STONE') {
      return (
        <div className="row" style={{ marginTop: 10 }}>
          <br />
          <CustomReactSelect
            onChange={(value) => dispatch({ type: 'countertopOption', payload: value })}
            label="Haben Sie externe Dienstleister für die Arbeitsplatte?"
            options={countertopDeliveryOptions}
            value={state.countertopOption}
          />
        </div>
      );
    }
    return null;
  };

  const renderDevoutIdentificationNumberHint = () => {
    if (!client.devoutIdentificationNumber && !isInternal) {
      return (
        <div className="col-lg-12" style={{ marginTop: 20, marginBottom: 20 }}>
          <div className="alert alert-info">
            <i className="fas fa-info-circle" style={{ marginRight: 6 }} />
            Der Restbetrags kann erst verwendet werden wenn Sie eine Gläubiger-Identifikationsnummer
            in den Firmendaten hinterlegt haben. Mit Ihrer Gläubiger-Identifikationsnummer wird automatisch ein
            SEPA-Lastschriftmandat generiert welches Sie berechtigt den Restbetrag des Kunden von seinem angegebenen
            Bankkonto ein zu ziehen.
            <NavLink to="/admin/client" className="alert-link" style={{ marginTop: 10, display: 'block' }}>
              <i className="fas fa-cogs" style={{ marginRight: 10 }} />
              Eintellungen öffnen
            </NavLink>
          </div>
        </div>
      );
    }
    return null;
  };

  const openHelp = (context) => {
    setHelpContext(context);
    setShowHelp(true);
  };

  const render51Fields = () => {
    if (orderType === 'SINGLE') {
      return (
        <>
          <h6 style={{ marginTop: 30 }}>5.1 Zahlung / Preis</h6>
          <div className="row">
            {renderDevoutIdentificationNumberHint()}
            <Input
              onChange={(value) => dispatch({ type: 'price', payload: value })}
              value={state.price}
              rowClass="col-lg-6"
              label="Küchenpreis (brutto)"
              rightIcon="fas fa-euro-sign"
              placeholder="Beispiel: 1590.00"
              hasError={!!errors.price}
              errorMessage={errors.priceMessage}
            />
            <Input
              onChange={(value) => dispatch({ type: 'balance', payload: value })}
              value={state.balance}
              label="Offener Rechnungsbetrag"
              rightIcon="fas fa-euro-sign"
              placeholder="Beispiel: 1590.00"
              rowClass="col-lg-6"
              hasError={!!errors.balance}
              errorMessage={errors.balanceMessage}
              disabled={!client.devoutIdentificationNumber && !isInternal}
            />
          </div>
          <Input
            onChange={(value) => dispatch({ type: 'externalId', payload: value })}
            value={state.externalId}
            label="Kommissionsnummer"
            hasError={!!errors.externalId}
            errorMessage={errors.externalIdMessage}
          />
        </>
      );
    }
    return (
      <>
        <h6 style={{ marginTop: 30 }}>4.2.1 Kommissionsnummer</h6>
        <Input
          onChange={(value) => dispatch({ type: 'externalId', payload: value })}
          value={state.externalId}
          label="Kommissionsnummer"
          hasError={!!errors.externalId}
          errorMessage={errors.externalIdMessage}
        />
      </>
    );
  };

  const render52Fields = () => {
    if (orderType === ORDERTYPE_SINGLE) {
      return (
        <>
          <h6 style={{ marginTop: 30 }}>5.2 Ausstattung</h6>
          <div className="row">
            <CustomReactSelect
              onChange={(value) => dispatch({ type: 'withoutGrip', payload: value })}
              value={state.withoutGrip}
              label="Grifflos"
              options={YesNoOption}
              rowClass="col-lg-6"
            />
            <CustomReactSelect
              onChange={(value) => dispatch({ type: 'nicheTrim', payload: value })}
              value={state.nicheTrim}
              label="Nischenverkleidungen"
              options={YesNoOption}
              rowClass="col-lg-6"
            />
            <CustomReactSelect
              value={state.nicheMeasuring}
              onChange={(value) => dispatch({ type: 'nicheMeasuring', payload: value })}
              label="Nischenaufmaß notwendig?"
              options={YesNoOption}
              rowClass="col-lg-6"
            />
          </div>
          <div className="row">
            <CustomReactSelect
              onChange={(value) => dispatch({ type: 'wallUnit', payload: value })}
              value={state.wallUnit}
              label="Oberschränke vorhanden"
              options={YesNoOption}
              rowClass="col-lg-6"
            />
            <Input
              onChange={(value) => dispatch({ type: 'runningMeterBottom', payload: value })}
              value={state.runningMeterBottom}
              label="Laufende Meter (Hinterkante)"
              rightText="m"
              rowClass="col-lg-6"
              hasError={!!errors.runningMeterBottom}
              errorMessage={errors.runningMeterBottomMessage}
              showHelpButton
              onHelpPress={() => openHelp('calculateMeterHint')}
            />
          </div>
        </>
      );
    }
    return null;
  };

  const render53Fields = () => {
    if (orderType === ORDERTYPE_SINGLE) {
      return (
        <>
          <h6 style={{ marginTop: 30 }}>5.3 Spezielle Dunstabzugshaube</h6>
          <p className="text-muted">
            Handelt es sich bei der Dunstabzugshaube um eine Inselhaube, flächenbündige Deckenhaube, eine Haube
            integriert im Kochfeld oder eine Arbeitsplattenhaube?
          </p>
          <div className="row">
            <CustomReactSelect
              onChange={(value) => dispatch({ type: 'exhaustHood', payload: value })}
              value={state.exhaustHood}
              options={YesNoOption}
            />
          </div>
        </>
      );
    }
    return null;
  };

  const render54Fields = () => {
    const headline = (orderType === ORDERTYPE_SINGLE) ? '5.4 Anschlussarbeiten' : '4.2.2 Anschlussarbeiten';

    return (
      <>
        <h6 style={{ marginTop: 30 }}>
          {headline}
        </h6>
        <SelectPanel
          onChange={(value) => dispatch({ type: 'workOptions', payload: value })}
          selected={state.selectedWorkOptionsArray}
          data={workOptions}
          isMultiSelect
          hasError={!!errors.workOptions}
        />
      </>
    );
  };

  const render55Fields = () => {
    const headline = (orderType === ORDERTYPE_SINGLE)
      ? '5.5 Material der Arbeitsplatte' : '4.2.3 Material der Arbeitsplatte';

    return (
      <>
        <h6 style={{ marginTop: 30 }}>
          {headline}
        </h6>
        <SelectPanel
          onChange={(value) => dispatch({ type: 'countertopType', payload: value })}
          selected={[state.countertopType]}
          data={countertopOptions}
          hasError={!!errors.countertopType}
        />
        {renderCountertopOption()}
      </>
    );
  };

  const render56Fields = () => {
    const headline = (orderType === ORDERTYPE_SINGLE)
      ? '5.6 Montagehinweise' : '4.2.4 Montagehinweise';

    return (
      <>
        <h6 style={{ marginTop: 30 }}>
          {headline}
        </h6>
        <Textarea
          onChange={(value) => dispatch({ type: 'comment', payload: value })}
          rows={4}
          value={state.comment}
          hasError={!!errors.comment}
        />
      </>
    );
  };

  return (
    <div>
      <h5>{getHeadline()}</h5>
      <p className="text-muted">
        Wir benötigen noch ein paar Informationen zur Küche
      </p>

      {render51Fields()}
      {render52Fields()}
      {render53Fields()}
      {render54Fields()}
      {render55Fields()}
      {render56Fields()}

      <HelpDialog context={helpContext} visible={showHelp} onClose={() => setShowHelp(false)} />
    </div>
  );
}

KitchenInformation.propTypes = {
  onChange: PropTypes.func.isRequired,
  orderType: PropTypes.oneOf(['SINGLE', 'OBJECT']),
  editableData: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object).isRequired,
  isInternal: PropTypes.bool.isRequired,
};

KitchenInformation.defaultProps = {
  orderType: 'SINGLE',
  editableData: {},
  errors: {},
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
  };
}

export default connect(mapStoreToProps, () => ({}))(KitchenInformation);
