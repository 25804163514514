import axios from '../../Library/Axios';

const defaultUri = '/client/{clientId}/document';

export const CLIENTDOCUMENT_GETLIST_START = 'CLIENTDOCUMENT_GETLIST_START';
export const CLIENTDOCUMENT_GETLIST_SUCCESS = 'CLIENTDOCUMENT_GETLIST_SUCCESS';
export const CLIENTDOCUMENT_GETLIST_ERROR = 'CLIENTDOCUMENT_GETLIST_ERROR';

/**
 * getList()
 * @returns {function(*, *): Promise<any | never>}
 */
export function getList(clientId, forceReload = false) {
  return (dispatch, getState) => {
    const { documents } = getState().clientDocument.documents;

    if (documents && !forceReload) {
      return Promise.resolve(documents);
    }

    dispatch({ type: CLIENTDOCUMENT_GETLIST_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;

      if (response.status === 200) {
        dispatch({ type: CLIENTDOCUMENT_GETLIST_SUCCESS, payload: data });
        return Promise.resolve(data);
      }
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: CLIENTDOCUMENT_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CLIENTDOCUMENT_UPLOAD_START = 'CLIENTDOCUMENT_UPLOAD_START';
export const CLIENTDOCUMENT_UPLOAD_SUCCESS = 'CLIENTDOCUMENT_UPLOAD_SUCCESS';
export const CLIENTDOCUMENT_UPLOAD_ERROR = 'CLIENTDOCUMENT_UPLOAD_ERROR';

/**
 * upload()
 * @param file
 * @param type
 * @param clientId
 * @returns {function(*): Promise<unknown>}
 */
export function upload(file, type, clientId) {
  return (dispatch) => {
    dispatch({ type: CLIENTDOCUMENT_UPLOAD_START });

    const formData = new FormData();
    formData.append('document', file);

    const config = { params: { type } };
    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().post(uri, formData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTDOCUMENT_UPLOAD_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTDOCUMENT_UPLOAD_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CLIENTDOCUMENT_DELETE_START = 'CLIENTDOCUMENT_DELETE_START';
export const CLIENTDOCUMENT_DELETE_SUCCESS = 'CLIENTDOCUMENT_DELETE_SUCCESS';
export const CLIENTDOCUMENT_DELETE_ERROR = 'CLIENTDOCUMENT_DELETE_ERROR';

/**
 * remove()
 * @returns {function(*, *): Promise<any | never>}
 */
export function remove(documentId, clientId) {
  return (dispatch) => {
    dispatch({ type: CLIENTDOCUMENT_DELETE_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}/${documentId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTDOCUMENT_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTDOCUMENT_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
