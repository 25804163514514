import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import { formatDateTime } from '../../../../Library/Functions';

/**
 * EventDetailsModal()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function EventDetailsModal(props) {
  const { item, visible, onClose } = props;
  const ref = React.useRef();

  const copyText = () => {
    ref.current.select();
    document.execCommand('copy');
  };

  if (item) {
    return (
      <ModalDialog visible={visible} onClose={onClose} hideConfirmButton closeCaption="Schließen">
        <h5 style={{ marginBottom: 40 }}>
          Eventdetails
        </h5>

        <textarea
          ref={ref}
          style={{
            position: 'absolute', width: 0, height: 0, top: -100,
          }}
        >
          {item.payload}
        </textarea>

        <KeyValueEditRow
          title="Gesendet an"
          type="text"
          displayText={item.url}
        />
        <KeyValueEditRow
          title="Gesendet am"
          type="text"
          displayText={formatDateTime(item.sendDateTime)}
        />
        <KeyValueEditRow
          title="Eventtyp"
          type="text"
          displayText={item.eventType}
        />

        <KeyValueEditRow
          title="Gesendete Daten von hey.kitchen"
          type="text"
          displayText={`${item.payload.length} Bytes gesendet`}
          actionIcon="fas fa-copy"
          onCustomAction={copyText}
          useCustomAction
        />

        <KeyValueEditRow
          title="Response Status Code"
          type="text"
          displayText={item.responseCode}
        />
        <KeyValueEditRow
          title="Response Content (Ihre Antwort)"
          type="text"
          displayText={item.response}
        />
      </ModalDialog>
    );
  }
  return null;
}

EventDetailsModal.propTypes = {
  item: PropTypes.instanceOf(Object),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

EventDetailsModal.defaultProps = {
  item: null,
};
