const booleanCaption = 'Boolean (true / false)';
const floatCaption = 'Float (Beispiel: 2500.50)';

export default [
  {
    field: 'partnerId',
    mandatory: false,
    type: 'Integer',
    default: 'null',
    description: 'Eindeutige ID des Partners für den der Auftrag erstellt werden soll. Die jeweils passende ID finden '
      + 'Sie unter dem Menüpunkt "Partnerschaften" bei den dort angezeigten Partner im Feld "PartnerID". Wenn die '
      + 'partnerId in Verbindung mit einer Lagerung gewählt wird muss die partnerStorageId angegeben werden!',
    category: 'order',
  },
  {
    field: 'createdUserId',
    mandatory: true,
    type: 'Integer',
    description: 'Hiermit geben Sie an welcher Benutzer für diesen Auftrag verantwortlich ist (den Auftrag intern '
      + 'verwaltet oder verkauft hat). Die passende Nummer / ID finden Sie in der Benutzerverwaltung vor dem Name. '
      + 'Wenn Sie eine "Interne Mitarbeiternummer" zu einem Benutzer hinterlegt haben, können Sie auch '
      + 'diese über das Feld "createdUserReferenceId" verwenden. Siehe Feldbeschreibung. Dieses Feld ist nur '
      + 'Pflicht wenn das andere Feld nicht verwendet wurde.',
    category: 'order',
  },
  {
    field: 'createdUserReferenceId',
    mandatory: false,
    type: 'Integer',
    description: 'Siehe Beschreibung von "createdUserId". Wenn Sie aus Ihrem System eine interne Benutzernummer '
      + 'verwenden möchten, müssen Sie diese dem passenden Benutzer in hey.kitchen als "Interne Mitarbeiternummer" '
      + ' zuweisen. Wenn Sie diese zugewiesen haben können Sie anstelle der internen hey.kitchen ID auch Ihre '
      + 'Mitarbeiternummer beim Import in diesem Feld verwenden.',
    category: 'order',
  },
  {
    field: 'deliveryDateStart',
    mandatory: false,
    type: 'Date (YYYY-MM-DD)',
    description: 'Montagedatum',
    category: 'object',
  },
  {
    field: 'deliveryWeekStart',
    mandatory: false,
    type: 'Date (YYYY-MM-DD)',
    description: 'Der Montag der Wunschkalenderwoche',
    category: 'object',
  },
  {
    field: 'optionDelivery',
    mandatory: false,
    type: booleanCaption,
    default: 'true',
    description: 'Diese Auftragsoption legt fest ob der Auftrag geliefert werden muss. Wenn Lieferung '
      + 'ausgewählt ist muss eine Beladestelle entweder über die loadingAddressId oder manuell über die einzelnen '
      + 'Felder angegeben werden.',
    category: 'order',
  },
  {
    field: 'optionMontage',
    mandatory: false,
    type: booleanCaption,
    default: 'true',
    description: 'Diese Auftragsoption legt fest ob der Auftrag eine Montage benötigt',
    category: 'order',
  },
  {
    field: 'optionRemoval',
    mandatory: false,
    type: booleanCaption,
    default: 'false',
    description: 'Diese Auftragsoption legt fest ob vor der Montage eine Demontage der alten Küche durchgeführt '
      + 'werden soll.',
    category: 'order',
  },

  {
    field: 'customerFirstName',
    mandatory: true,
    type: 'String',
    description: 'Vorname des Kunden',
    category: 'customer',
  },
  {
    field: 'customerLastName',
    mandatory: true,
    type: 'String',
    description: 'Nachname des Kunden',
    category: 'customer',
  },
  {
    field: 'customerZip',
    mandatory: true,
    type: 'Integer (max. 5)',
    description: 'Postleitzahl des Kunden',
    category: 'customer',
  },
  {
    field: 'customerCity',
    mandatory: true,
    type: 'String',
    description: 'Wohnort des Kunden',
    category: 'customer',
  },
  {
    field: 'customerStreet',
    mandatory: true,
    type: 'String',
    description: 'Straßenname des Kunden',
    category: 'customer',
  },
  {
    field: 'customerStreetNo',
    mandatory: false,
    type: 'String',
    description: 'Hausnummer des Kunden',
    category: 'customer',
  },
  {
    field: 'customerPhone',
    mandatory: true,
    type: 'String',
    description: 'Telefon des Kunden',
    category: 'customer',
  },
  {
    field: 'customerMail',
    mandatory: false,
    type: 'String',
    description: 'E-Mail Adresse des Kunden',
    category: 'customer',
  },
  {
    field: 'customerComment',
    mandatory: false,
    type: 'String',
    description: 'Frei wählbarer Kommentar oder Freitext oder Zusatzinformationen zum Kunden',
    category: 'customer',
  },
  {
    field: 'floor',
    type: 'Integer (0-6)',
    default: 0,
    description: 'Stockwerk des Kunden',
    category: 'customer',
  },

  {
    field: 'montageComment',
    mandatory: false,
    type: 'String',
    default: 'null',
    description: 'Montagehinweis',
    category: 'object',
  },
  {
    field: 'balance',
    mandatory: false,
    type: floatCaption,
    default: 0,
    description: 'Der zu bezahlende Restbetrag vom Kunden ohne Währungszeichen. Als Dezimaltrenner muss ein '
      + 'Punkt (kein Komma) verwendet werden. Bei glatten beträgen können die Dezimalstellen weggelassen werden.',
    category: 'object',
  },
  {
    field: 'externalId',
    mandatory: true,
    type: 'String',
    description: 'Kommissionsnummer. Bitte beachten Sie das die Kommissionsnummern für Ihre Aufträge immer '
      + 'eindeutig sein müssen. Die Aufträge werden beim Import anhand der Kommissionsnummer geprüft. Sollte eine '
      + 'Kommissionsnummer bereits vorhanden sein wird der gefundene Auftrag mit den Daten aus dem Import '
      + 'aktualisiert. Es werden nur Felder aktualisiert die sich geändert haben und welche geändert werden dürfen.',
    category: 'object',
  },
  {
    field: 'priceBrutto',
    mandatory: true,
    type: floatCaption,
    description: 'Brutto Küchenpreis ohne Währungszeichen. Als Dezimaltrenner muss ein Punkt (kein Komma) '
      + 'verwendet werden. Bei glatten beträgen können die Dezimalstellen weggelassen werden.',
    category: 'object',
  },
  {
    field: 'objectLength',
    mandatory: false,
    type: 'Integer',
    default: 0,
    description: 'Laufende Meter der Küche, gemessen an den Hinterkanten',
    category: 'object',
  },
  {
    field: 'withoutGrip',
    mandatory: false,
    type: booleanCaption,
    default: 'false',
    description: 'Die Küche ist Grifflos ja oder nein',
    category: 'object',
  },
  {
    field: 'wallUnit',
    mandatory: false,
    type: booleanCaption,
    default: 'true',
    description: 'Es sind Oberschränke vorhanden',
    category: 'object',
  },
  {
    field: 'nicheTrim',
    mandatory: false,
    type: booleanCaption,
    default: 'true',
    description: 'Nischenverkleidung vorhanden',
    category: 'object',
  },
  {
    field: 'exhaustHood',
    mandatory: false,
    type: booleanCaption,
    default: 'false',
    description: 'Spezielle Dunstabzugshaube vorhanden',
    category: 'object',
  },
  {
    field: 'objectOptionAir',
    mandatory: false,
    type: booleanCaption,
    default: 'false',
    description: 'Die Option legt fest ob bei der Küche Anschlussarbeiten für Abluft notwendig sind.',
    category: 'object',
  },
  {
    field: 'objectOptionElectric',
    mandatory: false,
    type: booleanCaption,
    default: 'true',
    description: 'Die Option legt fest ob bei der Küche Anschlussarbeiten für Elektro notwendig sind.',
    category: 'object',
  },
  {
    field: 'objectOptionLight',
    mandatory: false,
    type: booleanCaption,
    default: 'false',
    description: 'Die Option legt fest ob bei der Küche Anschlussarbeiten für Beleuchtung / Licht notwendig sind.',
    category: 'object',
  },
  {
    field: 'objectOptionQuooker',
    mandatory: false,
    type: booleanCaption,
    default: 'false',
    description: 'Die Option legt fest ob bei der Küche Anschlussarbeiten für Quooker o.Ä. notwendig sind.',
    category: 'object',
  },
  {
    field: 'objectOptionWater',
    mandatory: false,
    type: booleanCaption,
    default: 'true',
    description: 'Die Option legt fest ob bei der Küche Anschlussarbeiten für Wasserinstallationen notwendig sind.',
    category: 'object',
  },
  {
    field: 'countertopType',
    mandatory: false,
    type: 'String (WOOD, STONE, STEEL, CERAMIC)',
    default: 'WOOD',
    description: 'Arbeitsplattentyp. Es können nur die im Typ definierten Werte verwendet werden. Wird ein anderer '
      + 'Wert als einer der angegebenen Verwendet wird immer der Standardwert benutzt.',
    category: 'object',
  },

  {
    field: 'partnerStorageId',
    mandatory: false,
    type: 'Integer',
    default: 'null',
    description: 'Wenn Sie eine partnerId und die Lageroption gewählt haben, müssen Sie hier die LagerId Ihres '
      + 'Partners angeben. Diese kann Ihnen nur der Partner selber mitteilen. Bitte erkundigen Sie sich bei Ihrem '
      + 'jeweiligen Partner nach der richtigen Standort-ID',
    category: 'storage',
  },
  {
    field: 'loadingAddressId',
    mandatory: false,
    type: 'Integer',
    default: 'null',
    description: 'Wenn Sie einen Auftrag importieren und die Option Lieferung aktiviert haben, '
      + 'können Sie hier die ID des Standortes angeben an welchem die Küche beladen wird. Die passende '
      + 'Standort-ID finden Sie in den Einstellungen unter "Lagerorte / Standorte". Wenn Sie eine ID angeben '
      + 'müssen Sie keine weiteren Daten angeben. Achtung: Bie Partneraufträgen mit Lieferung und Lagerung muss '
      + 'die partnerStorageId angegeben werden!',
    category: 'storage',
  },
  {
    field: 'loadingAddressName',
    mandatory: false,
    type: 'String',
    default: 'null',
    description: 'Name des Lagerorts oder der Beladestelle',
    category: 'storage',
  },
  {
    field: 'loadingAddressZip',
    mandatory: false,
    type: 'String',
    default: 'null',
    description: 'Postleitzahl des Lagerorts oder der Beladestelle',
    category: 'storage',
  },
  {
    field: 'loadingAddressCity',
    mandatory: false,
    type: 'String',
    default: 'null',
    description: 'Ort des Lagerorts oder der Beladestelle',
    category: 'storage',
  },
  {
    field: 'loadingAddressStreet',
    mandatory: false,
    type: 'String',
    default: 'null',
    description: 'Strasse des Lagerorts oder der Beladestelle',
    category: 'storage',
  },
  {
    field: 'loadingAddressStreetNo',
    mandatory: false,
    type: 'String',
    default: 'null',
    description: 'Hausnummer des Lagerorts oder der Beladestelle',
    category: 'storage',
  },
  {
    field: 'loadingAddressContactName',
    mandatory: false,
    type: 'String',
    default: 'null',
    description: 'Ansprechpartner / Vor-Ort Mitarbeiter bei Rückfragen zum Lagerorts oder der Beladestelle',
    category: 'storage',
  },
  {
    field: 'loadingAddressContactPhone',
    mandatory: false,
    type: 'String',
    default: 'null',
    description: 'Telefonnummer des Ansprechpartners',
    category: 'storage',
  },

];
