import React from 'react';
import PropTypes from 'prop-types';
import ReclamationStatusBadge from './ReclamationStatusBadge';
import ClickableTableRow from '../ClickableTableRow';
import { formatDate } from '../../Library/Functions';
import ServiceStatusBadge from '../ServiceComponents/ServiceStatusBadge';
import { objectServiceTypeCaptions } from '../../Library/Types';

/**
 * ReclamationRow()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function ReclamationRow(props) {
  const {
    reclamationOrService, onClick, showDate,
  } = props;

  const getCount = () => {
    if (reclamationOrService.itemCount <= 1) {
      return '1 Service';
    }
    if (reclamationOrService.itemCount > 1) {
      return `${reclamationOrService.itemCount} Services`;
    }
    if (reclamationOrService.reclamationCount === 1) {
      return '1 Reklamation';
    }
    return `${reclamationOrService.reclamationCount} Reklamationen`;
  };

  const getAddress = () => `${reclamationOrService.zip} ${reclamationOrService.city}`;

  const renderIcon = () => <i className="fas fa-angle-right hk-text-primary" />;

  const renderDate = () => {
    if (showDate) {
      if (reclamationOrService.executionDate) {
        return (
          <ClickableTableRow onClick={() => onClick(reclamationOrService)}>
            {formatDate(reclamationOrService.executionDate)}
          </ClickableTableRow>
        );
      }
      return <ClickableTableRow onClick={() => onClick(reclamationOrService)} />;
    }
    return null;
  };

  const renderassemblyTime = () => {
    if (reclamationOrService.assemblyTime) {
      return `${reclamationOrService.assemblyTime} min.`;
    }
    return '0 min.';
  };

  const renderStatusBadge = () => {
    if (reclamationOrService.reclamationId) {
      return (
        <ReclamationStatusBadge reclamation={reclamationOrService} />
      );
    }
    return (
      <ServiceStatusBadge serviceState={reclamationOrService.state} />
    );
  };

  const renderCaption = () => {
    if (reclamationOrService.serviceId) {
      const typeString = objectServiceTypeCaptions[reclamationOrService.serviceType];
      return `${typeString} zu Auftrag "${reclamationOrService.objectExternalId}"`;
    }
    return `Reklamation zu Auftrag "${reclamationOrService.objectExternalId}"`;
  };

  return (
    <>
      <tr>
        <ClickableTableRow onClick={() => onClick(reclamationOrService)}>
          {renderIcon()}
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(reclamationOrService)}>
          <i className="fas fa-sync" />
        </ClickableTableRow>
        {renderDate()}
        <ClickableTableRow onClick={() => onClick(reclamationOrService)}>
          {renderCaption()}
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(reclamationOrService)}>
          {renderassemblyTime()}
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(reclamationOrService)}>
          {getCount()}
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(reclamationOrService)}>
          {getAddress()}
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(reclamationOrService)}>
          {renderStatusBadge()}
        </ClickableTableRow>
      </tr>
    </>
  );
}

ReclamationRow.propTypes = {
  reclamationOrService: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  showDate: PropTypes.bool.isRequired,
};

/**
 * ReclamationTable()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ReclamationTable(props) {
  const {
    reclamations, onClick, showDate,
  } = props;

  const renderDateHeader = () => {
    if (showDate) {
      return (<th>Datum</th>);
    }
    return null;
  };

  if (reclamations && reclamations.length > 0) {
    return (
      <table className="table table-linked table-hover">
        <thead>
          <tr>
            <th />
            <th>Typ</th>
            {renderDateHeader()}
            <th>Kommission</th>
            <th>Montagedauer</th>
            <th>Anzahl</th>
            <th>Adresse</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {reclamations.map((item) => (
            <ReclamationRow
              reclamationOrService={item}
              key={item.serviceId || item.reclamationId}
              onClick={onClick}
              showDate={showDate}
            />
          ))}
        </tbody>
      </table>
    );
  }
  return null;
}

ReclamationTable.propTypes = {
  reclamations: PropTypes.instanceOf(Array),
  onClick: PropTypes.func.isRequired,
  showDate: PropTypes.bool,
};

ReclamationTable.defaultProps = {
  reclamations: null,
  showDate: false,
};
