import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import KeyValueEditRow from '../../KeyValueEditRow';
import { timeOptions } from '../../../Library/Types';
import { useDispatchUpdateDate } from '../../../Redux/Action/ObjectAction';

/**
 * DispoInformation()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function DispoInformation(props) {
  const {
    order, object, date, onChange, editable,
  } = props;
  const { client } = useSelector((state) => state.client);
  const dispatchUpdateDate = useDispatchUpdateDate();

  const formatTime = (time) => {
    if (time) {
      return `${moment(time, 'HH:mm:ss').format('HH:mm')} Uhr`;
    }
    return null;
  };

  if ((date.clientId && client.clientId === date.clientId) || (order.clientId === client.clientId)) {
    return (
      <div className="row">
        <div className="col-lg-6">
          <KeyValueEditRow
            title="Ankunft Monteur beim Kunden"
            type="select"
            initialValue={date.startTime}
            displayText={formatTime(date.startTime)}
            onChange={(startTime) => onChange('startTime', startTime)}
            onClear={() => dispatchUpdateDate(object, { ...date, startTime: null })}
            selectData={timeOptions}
            editable={editable}
          />
        </div>
        <div className="col-lg-6">
          <KeyValueEditRow
            title="Geschätzte Arbeitszeit in Minuten"
            type="text"
            initialValue={date.executionTime}
            displayText={`${date.executionTime} Minuten`}
            placeholder="Noch nicht festgelegt"
            onChange={(executionTime) => onChange('executionTime', executionTime)}
            onClear={() => dispatchUpdateDate(object, { ...date, executionTime: 0 })}
            editable={editable}
          />
        </div>
      </div>
    );
  }
  return null;
}

DispoInformation.propTypes = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  date: PropTypes.instanceOf(Object).isRequired,
  editable: PropTypes.bool.isRequired,
};
