import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { getMinuteDiff } from '../../../../Library/Functions';

/**
 * DispositionInformation()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function DispositionInformation(props) {
  const { appointment, height } = props;

  const renderAdditionalInformation = () => {
    if (height > 40) {
      return (
        <>
          <div className="SubCaption">
            {appointment.description}
          </div>
          <div className="Time">
            {`Dauer: ${getMinuteDiff(appointment.start, appointment.end)}`}
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div className="Caption">
        <div>
          <i
            className="fas fa-calendar-check"
            style={{ marginRight: 10 }}
          />
          <strong>
            {appointment.title}
          </strong>
        </div>
        {renderAdditionalInformation()}
      </div>
    </>
  );
}

DispositionInformation.propTypes = {
  appointment: PropTypes.instanceOf(Object).isRequired,
  height: PropTypes.number.isRequired,
};
