import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Panel from '../../../../Components/Panel';
import {
  approveAppointment, getAppointments,
} from '../../../../Redux/Action/ReclamationAction';
import AppointmentListView from './AppointmentListView';
import ModalDialog from '../../../../Components/ModalDialog';
import { formatDate, formatTime } from '../../../../Library/Functions';
import {
  RECLAMATIONSTATE_WAITING_APPOINTMENT,
  RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT,
} from '../../../../Library/StateTypes';

/**
 * AppointmentsMonteur()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function AppointmentsMonteur(props) {
  const {
    order, object, reclamation, client, dispatchApproveAppointment, dispatchGetAppointments,
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [appointments, setAppointments] = useState(null);

  useEffect(() => {
    dispatchGetAppointments(reclamation).then((data) => {
      setAppointments(data);
    });
  }, [dispatchGetAppointments, reclamation]);

  const handleAskApprove = (item) => {
    setSelectedItem(item);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedItem(null);
    setShowDialog(false);
  };

  const handleApprove = () => {
    dispatchApproveAppointment(reclamation, selectedItem.appointmentId).then(() => {
      setSelectedItem(null);
      setShowDialog(false);
    });
  };

  const renderDialogText = () => {
    if (selectedItem) {
      return (
        <p>
          Hiermit bestätigen Sie, dass Sie den Termin am
          {` ${(selectedItem) ? formatDate(selectedItem.date) : ''} `}
          {`zwischen ${(selectedItem) ? formatTime(selectedItem.executionFromTime) : ''} 
          und ${(selectedItem) ? formatTime(selectedItem.executionToTime) : ''} Uhr `}
          verbindlich annehmen!
        </p>
      );
    }
    return null;
  };

  const renderAppointmentList = () => {
    if (appointments && appointments.length >= 3) {
      return (
        <AppointmentListView
          appointments={appointments}
          onApprove={handleAskApprove}
        />
      );
    }
    return (
      <div className="alert alert-info">
        <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
        Es sind noch keine Terminvorschläge vorhanden!
      </div>
    );
  };

  if (reclamation.state === RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT
    || reclamation.state === RECLAMATIONSTATE_WAITING_APPOINTMENT
  ) {
    if (!order.internal && object.montageDate && object.montageDate.clientId === client.clientId) {
      return (
        <Panel marginBottom={20}>
          <h6>Terminplanung</h6>
          <p>
            Bitte wählen Sie einen für Sie passenden Terminvorschlag für die Durchführung der Reklamation.
            Klicken Sie dafür rechts auf den entsprechenden Pfeil neben dem Termin.
          </p>

          {renderAppointmentList()}

          <ModalDialog
            onClose={() => handleCloseDialog()}
            onConfirm={() => handleApprove()}
            visible={showDialog}
            confirmCaption="Termin bestätigen"
            width={600}
          >
            <h6>Termin bestätigen</h6>
            {renderDialogText()}
          </ModalDialog>
        </Panel>
      );
    }
  }
  return null;
}

AppointmentsMonteur.propTypes = {
  dispatchApproveAppointment: PropTypes.func.isRequired,
  dispatchGetAppointments: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  reclamation: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchApproveAppointment: (reclamation, data) => dispatch(approveAppointment(reclamation, data)),
    dispatchGetAppointments: (reclamation) => dispatch(getAppointments(reclamation)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(AppointmentsMonteur);
