/**
 * CreateAddressValidator
 */
export default class CreateAddressValidator {
  constructor() {
    this.data = {
      type: false,
      name: false,
      zip: false,
      city: false,
      street: false,
      streetNo: false,
      contactName: false,
      contactPhone: false,
      comment: false,
    };
  }

  hasError() {
    let hasError = false;

    Object.keys(this.data).forEach((value) => {
      if (this.data[value]) {
        hasError = true;
      }
    });

    return hasError;
  }

  isValid(data) {
    if (!data.name) {
      this.data = { ...this.data, name: true };
    }
    if (!data.zip) {
      this.data = { ...this.data, zip: true };
    }
    if (!data.city) {
      this.data = { ...this.data, city: true };
    }
    if (!data.street) {
      this.data = { ...this.data, street: true };
    }
    if (!data.streetNo) {
      this.data = { ...this.data, streetNo: true };
    }
    if (!data.contactName) {
      this.data = { ...this.data, contactName: true };
    }
    if (!data.contactPhone) {
      this.data = { ...this.data, contactPhone: true };
    }

    return !this.hasError();
  }

  getMessages() {
    return this.data;
  }
}
