import React from 'react';
import PropTypes from 'prop-types';
import {
  Route, withRouter, Redirect, Switch, useRouteMatch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import RightNavigationLayout from '../Components/Layout/RightNavigationLayout';
import ClientAdminRightNavigation from '../Components/Navigation/ClientAdminRightNavigation';
import TeamList from '../Pages/Administration/Teams/List';
import EditClient from '../Pages/Administration/Client/Update';
import ClientDocuments from '../Pages/Administration/Documents';
import UserList from '../Pages/Administration/User/List';
import UpdateUser from '../Pages/Administration/User/Update';
import AddUser from '../Pages/Administration/User/Add';
import SubcontractorList from '../Pages/Administration/Subcontractor/List';
import UpdateSubcontractor from '../Pages/Administration/Subcontractor/Update';
import AddressList from '../Pages/Administration/Address/List';
import Invoices from '../Pages/Administration/Invoices';
import HolidayOverview from '../Pages/Administration/Holiday/Overview';
import Voucher from '../Pages/Administration/Voucher';
import PartnerOverview from '../Pages/Administration/Mandant/PartnerOverview';
import CsvImport from '../Pages/Administration/Import/CsvImport';
import CsvDocumentation from '../Pages/Administration/Import/CsvDocumentation';
import MandantSettings from '../Pages/Administration/Mandant/MandantSettings';
import Events from '../Pages/Administration/Settings/Events';
import Settings from '../Pages/Administration/Settings/Settings';
import MaterialOverview from '../Pages/Administration/Material/Overview';
import Facility from '../Pages/Administration/Facility/Facility';

/**
 * ClientAdminRoute()
 * @param props
 * @returns {*}
 * @constructor
 */
function ClientAdminRoute(props) {
  const { isLoggedin, userGroups } = props;
  const { path } = useRouteMatch();
  const [isAllowed] = React.useState(
    (userGroups.indexOf('ADM') > -1) || (userGroups.indexOf('VER') > -1) || (userGroups.indexOf('REG') > -1),
  );

  if (isLoggedin) {
    if (!isAllowed) {
      return <Route><Redirect to="/accessdenied" /></Route>;
    }
    return (
      <RightNavigationLayout navigation={<ClientAdminRightNavigation />}>
        <Switch>
          <Route exact path={`${path}/teams`}><TeamList /></Route>
          <Route exact path={`${path}/settings`}><Settings /></Route>
          <Route exact path={`${path}/client`}><EditClient /></Route>
          <Route exact path={`${path}/facility`}><Facility /></Route>
          <Route exact path={`${path}/documents`}><ClientDocuments /></Route>
          <Route exact path={`${path}/users`}><UserList /></Route>
          <Route exact path={`${path}/users/create`}><AddUser /></Route>
          <Route exact path={`${path}/users/:userId`}><UpdateUser /></Route>
          <Route exact path={`${path}/subcontractor`}><SubcontractorList /></Route>
          <Route exact path={`${path}/subcontractor/:subcontractorId`}><UpdateSubcontractor /></Route>
          <Route exact path={`${path}/address`}><AddressList /></Route>
          <Route exact path={`${path}/invoices`}><Invoices /></Route>
          <Route exact path={`${path}/holidays`}><HolidayOverview /></Route>
          <Route exact path={`${path}/voucher`}><Voucher /></Route>
          <Route exact path={`${path}/mandant`}><PartnerOverview /></Route>
          <Route exact path={`${path}/events`}><Events /></Route>
          <Route exact path={`${path}/material`}><MaterialOverview /></Route>
          <Route exact path={`${path}/mandant/:mandantId`}><MandantSettings /></Route>
          <Route exact path={`${path}/import/csv`}><CsvImport /></Route>
          <Route exact path={`${path}/import/csv/docu`}><CsvDocumentation /></Route>
          <Redirect to={`${path}/client`} />
        </Switch>
      </RightNavigationLayout>
    );
  }
  return <Redirect to="/login" />;
}

ClientAdminRoute.propTypes = {
  isLoggedin: PropTypes.bool,
  userGroups: PropTypes.instanceOf(Array).isRequired,
};

ClientAdminRoute.defaultProps = {
  isLoggedin: null,
};

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
    userGroups: store.auth.groups,
    client: store.client.client,
  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(ClientAdminRoute));
