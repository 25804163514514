import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Panel from '../../Panel';
import UpdateCustomerAddress from '../../OrderComponents/UpdateCustomerAddress';
import Button from '../../Button';
import { get } from '../../../Redux/Action/OrderAction';
import { useDispatchCustomerUpdate } from '../../../Redux/Action/Order/Object/CustomerAction';

/**
 * CustomerAddress
 * @param props
 * @returns {*}
 * @constructor
 */
function CustomerAddress(props) {
  const {
    customer, object, dispatchGetOrder, editable,
  } = props;
  const dispatchUpdateCustomer = useDispatchCustomerUpdate();
  const [showEdit, setShowEdit] = React.useState(false);

  const renderPhone = () => {
    if (customer.phone && customer.phone.length > 0) {
      return <div>{`Telefon: ${customer.phone}`}</div>;
    }
    return null;
  };

  const renderMobile = () => {
    if (customer.mobile && customer.mobile.length > 0) {
      return <div>{`Mobil: ${customer.mobile}`}</div>;
    }
    return null;
  };

  const renderEditButton = () => {
    if (editable) {
      return (
        <Button onClick={() => setShowEdit(true)} type="btn-light">
          <i
            className="fas fa-pencil-alt"
            onClick={() => setShowEdit(true)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
            style={{ margin: 0 }}
          />
        </Button>
      );
    }
    return null;
  };

  const renderFloor = () => {
    switch (customer.floor) {
      case 0: return (<div style={{ marginBottom: 6 }}>Erdgeschoss</div>);
      default: return (<div style={{ marginBottom: 6 }}>{`${customer.floor}. Etage`}</div>);
    }
  };

  const handleUpdateCustomer = (customerData) => {
    dispatchUpdateCustomer(object, customerData).then(() => {
      dispatchGetOrder(object.orderId);
      setShowEdit(false);
    });
  };

  return (
    <Panel marginBottom={20} className="CustomerAddress">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h6>Lieferadresse</h6>
        </div>
        <div>
          {renderEditButton()}
        </div>
      </div>

      <address style={{ marginBottom: 0 }}>
        <div>
          <strong>
            {`${customer.firstname} ${customer.lastname}`}
          </strong>
        </div>
        <div>{`${customer.street} ${customer.streetNo}`}</div>
        <div>{`${customer.zip} ${customer.city}`}</div>
        {renderFloor()}
        {renderPhone()}
        {renderMobile()}
        <div>{customer.mail}</div>
        <small>{customer.comment}</small>
      </address>

      <UpdateCustomerAddress
        onClose={() => setShowEdit(false)}
        object={object}
        onSave={handleUpdateCustomer}
        visible={showEdit}
      />
    </Panel>
  );
}

CustomerAddress.propTypes = {
  dispatchGetOrder: PropTypes.func.isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  editable: PropTypes.bool,
};

CustomerAddress.defaultProps = {
  editable: false,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(CustomerAddress);
