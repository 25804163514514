import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import leftImage from './Monteur_mit_heykitchen_app.webp';
import rightImage from './landingRight-2.webp';
import Footer from '../Components/Footer';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: ${(props) => props.theme.height}px;
  max-height: ${(props) => props.theme.height}px;

  @media (max-width: 575.98px) {
    flex-direction: column;
  }
  
  >.Item {
    display: flex;
    flex: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    filter: grayscale(30%);
    transition: filter 500ms;
    
    &.Left {
      background-position-x: -100px;
      background-image: url(${leftImage});

      &:hover {
        filter: grayscale(0%);

        .Overlay {
          opacity: 0.1;
        }

        .Button {
          display: block;
        }
      }
    }

    &.Right {
      background-image: url(${rightImage});
      
      &:hover {
        filter: grayscale(0%);
        
        .Overlay {
          opacity: 0.1;
        }
        
        .Button {
          display: block;
        }
      }
    }

    .Button {
      position: absolute;
      bottom: 80px;
      left: 50%;
      margin-left: -80px;
      background-color: ${(props) => props.theme.color.primaryRedColor};
      padding: 10px 20px;
      color: #ffffff;
      border-radius: 6px;
      font-size: 22px;
      display: none;
      transition: display 500ms;
    }
  }
  
  .Overlay {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #000000;
    transition: opacity 500ms;
  }
`;

const TextContainer = styled.div`
  z-index: 5000;
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 30vh;
  margin-left: 15vh;

  @media (max-width: 575.98px) {
    display: none;
  }
  
  .Item {
    position: relative;
    width: fit-content;
    background-color: ${(props) => props.theme.color.primaryRedColor};
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 6px;
    ${(props) => props.theme.boxShadowDarkHover};

    &.Headline {
      padding: 15px 40px;
      font-size: 5vh;
      margin-bottom: 50px;
      font-family: "DIN Next LT W01 Bold", sans-serif;

      i {
        font-size: 60px;
      }
    }

    &.TextRed {
      padding: 8px 20px;
      font-size: 3vh;
      margin-bottom: 10px;
      font-family: "DIN Next LT W02 Bold", sans-serif;
    }

    &.TextGray {
      padding: 8px 20px;
      font-size: 3vh;
      margin-bottom: 10px;
      font-family: "DIN Next LT W02 Bold", sans-serif;
      background-color: #B4B3B3;
    }
  }
`;

export default function LandingPage() {
  const { isLoggedin } = useSelector((state) => state.auth);

  const theme = useTheme();
  const [widthLeft, setWidthLeft] = React.useState(0);
  const [heightLeft, setHeightLeft] = React.useState(0);
  const refLeft = React.useRef();
  const [widthRight, setWidthRight] = React.useState(0);
  const [heightRight, setHeightRight] = React.useState(0);
  const refRight = React.useRef();
  const [height, setHeight] = React.useState(0);

  const handleResize = React.useCallback(() => {
    setHeight(window.innerHeight);
  }, []);

  React.useEffect(() => {
    handleResize();
  }, [handleResize]);

  React.useEffect(() => {
    if (refLeft.current) {
      setWidthLeft(refLeft.current.offsetWidth / 2);
      setHeightLeft(refLeft.current.offsetHeight / 2);
    }
  }, [refLeft]);

  React.useEffect(() => {
    if (refRight.current) {
      setWidthRight(refRight.current.offsetWidth / 2);
      setHeightRight(refRight.current.offsetHeight / 2);
    }
  }, [refRight]);

  const onNavigate = (url) => {
    window.location = url;
  };

  if (isLoggedin) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Container theme={{ ...theme, height }}>
        <div
          className="Item Left"
          onClick={() => onNavigate('https://software.hey.kitchen')}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
        >
          <div className="Overlay" />
          <TextContainer ref={refLeft} theme={{ ...theme, marginLeft: widthLeft, marginTop: heightLeft }}>
            <div className="Item Headline WithIcon">
              hey.kitchen
              <i className="fas fa-plus" style={{ marginLeft: 20 }} />
            </div>
            <div className="Item TextRed">Die geniale</div>
            <div className="Item TextRed">Software + App</div>
            <div className="Item TextRed">Für Ihre digitale</div>
            <div className="Item TextGray">Küchen– und</div>
            <div className="Item TextGray">Möbelmontage.</div>
          </TextContainer>

          <div className="Button">Mehr erfahren</div>
        </div>
        <div style={{ width: 20 }} />
        <div
          className="Item Right"
          onClick={() => onNavigate('https://marktplatz.hey.kitchen')}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
        >
          <div className="Overlay" />
          <TextContainer ref={refRight} theme={{ ...theme, marginLeft: widthRight, marginTop: heightRight }}>
            <div className="Item Headline">
              hey.kitchen
            </div>
            <div className="Item TextRed">Wir vernetzen</div>
            <div className="Item TextRed">Händler und</div>
            <div className="Item TextRed">Monteure.</div>
            <div className="Item TextGray">Bundesweit.</div>
            <div className="Item TextGray">Einfach. Digital.</div>
          </TextContainer>

          <div className="Button">Mehr erfahren</div>
        </div>
      </Container>

      <Footer />
    </>
  );
}
