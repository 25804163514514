import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import Timeline from './Timeline';
import UserTimeLineRow from './UserTimeLineRow';
import LeftCaptionContainer from '../LeftCaptionContainer';
import GroupSeparator from '../GroupSeparator';
import WorkLoadContainer from '../WorkLoadContainer';

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidth}px;
  overflow-x: scroll;
  border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.backgroundGrayLight};
`;

/**
 * UserContent()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UserContent(props) {
  const {
    data, selectedDate, onDrop, onClickAppointment, onClickHour,
  } = props;
  const theme = useTheme();

  const renderTimeLine = () => {
    const timeline = (<Timeline key="timeline" />);

    const mappedData = data.map((separator) => {
      const sep = <GroupSeparator key={separator.name} />;

      const dataParam = separator.data.map((item) => (
        <UserTimeLineRow
          data={item}
          key={item.id}
          selectedDate={selectedDate}
          rowHeight={separator.rowHeight || theme.cellHeight}
          onDrop={onDrop}
          onClickAppointment={onClickAppointment}
          onClickHour={onClickHour}
        />
      ));

      return [sep, ...dataParam];
    });

    return [timeline, ...mappedData];
  };

  return (
    <>
      <LeftCaptionContainer data={data} />
      <WorkLoadContainer data={data} startDate={selectedDate} />
      <ScrollContainer>
        {renderTimeLine()}
      </ScrollContainer>
    </>
  );
}

UserContent.propTypes = {
  onDrop: PropTypes.func,
  data: PropTypes.instanceOf(Array).isRequired,
  selectedDate: PropTypes.string.isRequired,
  onClickAppointment: PropTypes.func.isRequired,
  onClickHour: PropTypes.func.isRequired,
};

UserContent.defaultProps = {
  onDrop: null,
};
