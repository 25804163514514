import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../../../Components/Panel';
import { formatCurrency, formatDate } from '../../../Library/Functions';

/**
 * Row()
 * @param props
 * @returns {*}
 * @constructor
 */
function Row(props) {
  const {
    item, onClick, clients, recentlyCreated,
  } = props;

  const renderDiscount = () => {
    if (item.discountPrice) {
      return formatCurrency(item.discountPrice);
    }
    return `${item.discountPercent}%`;
  };

  const getClient = () => {
    if (clients) {
      const client = clients.filter((data) => data.clientId === item.clientId)[0];
      return client.name;
    }
    return '';
  };

  const renderInUse = () => {
    if (item.clientId && item.clientId > 0) {
      return (
        <>
          <i className="fas fa-check-circle text-success" style={{ marginRight: 6 }} />
          {getClient()}
        </>
      );
    }
    return <i className="fas fa-times-circle text-danger" />;
  };

  const getClass = () => {
    const classes = [];
    if (recentlyCreated && recentlyCreated.length > 0) {
      const found = recentlyCreated.filter((data) => data.voucherId === item.voucherId);
      if (found.length > 0) {
        classes.push('table-info');
      }
    }
    if (item.expired) {
      classes.push('table-danger');
    }
    return classes.join(' ');
  };

  return (
    <tr onClick={() => onClick(item)} className={getClass()}>
      <td>{item.voucherId}</td>
      <td>{item.formattedCode}</td>
      <td>{item.type}</td>
      <td>{item.clientType}</td>
      <td>{formatDate(item.validFrom)}</td>
      <td>{formatDate(item.validUntil)}</td>
      <td>{renderDiscount()}</td>
      <td>{renderInUse()}</td>
    </tr>
  );
}

Row.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Array),
  recentlyCreated: PropTypes.instanceOf(Array),
};

Row.defaultProps = {
  clients: null,
  recentlyCreated: null,
};

/**
 * VoucherList()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function VoucherList(props) {
  const {
    vouchers, onClick, clients, recentlyCreated,
  } = props;

  const renderRows = () => vouchers.map((item) => (
    <Row item={item} key={item.voucherId} onClick={onClick} clients={clients} recentlyCreated={recentlyCreated} />
  ));

  const renderHeader = () => (
    <thead>
      <tr>
        <th>ID</th>
        <th>Code</th>
        <th>Type</th>
        <th>Für Kunde</th>
        <th>Einlösbar ab</th>
        <th>Einlösbar bis</th>
        <th>Rabatt</th>
        <th>Verwendet</th>
      </tr>
    </thead>
  );

  if (vouchers && vouchers.length > 0) {
    return (
      <>
        <Panel>
          <table className="table table-hover">
            {renderHeader()}
            <tbody>
              {renderRows()}
            </tbody>
          </table>
        </Panel>
      </>
    );
  }
  return null;
}

VoucherList.propTypes = {
  vouchers: PropTypes.instanceOf(Array),
  onClick: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Array),
  recentlyCreated: PropTypes.instanceOf(Array),
};

VoucherList.defaultProps = {
  vouchers: null,
  clients: null,
  recentlyCreated: null,
};
