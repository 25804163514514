import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import ObjectContainer from './ObjectContainer';
import ObjectPanel from './ObjectPanel';
import { guid } from '../../../../Library/Functions';
import validateObjectContainerHasErrors from '../Validator/ValidateObjectContainer';
import Panel from '../../../../Components/Panel';

/**
 * OrderObjectHandler()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function OrderObjectHandler(props) {
  const {
    onChange, hasError, editableData, isInternal, client,
  } = props;
  const [object, setObject] = useState({});
  const [objects, setObjects] = useState((editableData) || []);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [editingObjectId, setEditingObjectId] = useState(null);
  const [errors, setErrors] = useState(false);

  React.useEffect(() => () => {
    setObjects([]);
  }, []);

  const validateForms = () => {
    const objectContainerErrors = validateObjectContainerHasErrors(
      object, 'OBJECT', objects, client.settings, isInternal, (editingObjectId !== null),
    );

    if (objectContainerErrors === false) {
      setErrors(false);
      return true;
    }

    setErrors(objectContainerErrors);
    return false;
  };

  const handleSaveObject = () => {
    if (!validateForms()) {
      window.scrollTo(0, 0);
      return;
    }

    let mergedObj = null;

    if (!editingObjectId) {
      mergedObj = objects;
      mergedObj.push({ ...object, id: guid() });
      setObjects(mergedObj);
    } else {
      mergedObj = objects.map((item) => {
        if (item.id === editingObjectId) {
          return object;
        }
        return item;
      });
      setEditingObjectId(null);
    }

    setObjects(mergedObj);
    if (onChange) {
      onChange(mergedObj);
    }

    setEditData({});
    setShowModal(false);
    setErrors(false);
  };

  const handleCloseModal = () => {
    setObject({});
    setEditData({});
    setErrors(false);
    setShowModal(false);
  };

  const handleObjectDelete = (objectId) => {
    const newObject = objects.filter((item) => item.id !== objectId);
    setObjects(newObject);
    if (onChange) {
      onChange(newObject);
    }
  };

  const handleObjectEdit = (editId) => {
    const editObject = objects.filter((item) => item.id === editId)[0];
    setEditData(editObject);
    setShowModal(true);
    setEditingObjectId(editId);
  };

  const handleObjectCopy = (copyObject) => {
    const modified = { ...copyObject, externalId: '', id: guid() };
    delete modified.objectId;
    setEditData(modified);
    setShowModal(true);
  };

  return (
    <Panel marginBottom={30}>
      <h5>4. Küchen</h5>
      <p className="text-muted">
        Bitte fügen Sie hier alle Küchen hinzu die zu diesem Objektauftrag gehören
      </p>
      <ObjectPanel
        objects={objects}
        onAdd={() => setShowModal(true)}
        onDeleteObject={(id) => handleObjectDelete(id)}
        onEditObject={(id) => handleObjectEdit(id)}
        onCopyObject={(data) => handleObjectCopy(data)}
        hasError={hasError}
      />

      <ModalDialog
        onClose={() => handleCloseModal()}
        onConfirm={() => handleSaveObject()}
        confirmCaption={(Object.keys(editData).length > 0) ? 'Speichern' : 'Hinzufügen'}
        visible={showModal}
      >
        <ObjectContainer
          errors={errors}
          onChange={(value) => setObject(value)}
          orderType="OBJECT"
          editableData={editData}
          isInternal={isInternal}
          isEditMode={(editingObjectId !== null)}
        />
      </ModalDialog>
    </Panel>
  );
}

OrderObjectHandler.propTypes = {
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  editableData: PropTypes.instanceOf(Array),
  isInternal: PropTypes.bool.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

OrderObjectHandler.defaultProps = {
  hasError: false,
  editableData: [],
};
