/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, update } from '../Redux/Action/ClientAction';
import Panel from '../Components/Panel';
import Button from '../Components/Button';

/**
 * ApproveRegistration()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function ApproveRegistration(props) {
  const { history, dispatchGet, dispatchUpdate } = props;
  const { clientId } = useParams();
  const [client, setClient] = useState();
  const [alreadyApproved, setAlreadyApproved] = useState(false);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    if (clientId && !client) {
      dispatchGet(clientId).then((data) => {
        setClient(data);

        if (data.approvedRegistration) {
          setAlreadyApproved(true);
        } else {
          dispatchUpdate({ ...data, approvedRegistration: true }).then(() => {
            setApproved(true);
          });
        }
      });
    }
  }, [clientId, client, dispatchUpdate, dispatchGet]);

  if (client) {
    if (approved) {
      return (
        <div className="container-fluid">
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <Panel width={500}>
              <h6 className="text-success">
                <i className="far fa-check-circle" style={{ marginRight: 10 }} />
                Bestätigung erfolgreich abgeschlosen!
              </h6>
              Vielen Dank für die Bestätigung Ihrer Registrierung auf hey.kitchen. Sie können hey.kitchen jetzt
              uneingeschränkt nutzen!

              <div style={{ marginTop: 30, textAlign: 'center' }}>
                <Button onClick={() => history.push('/login')}>Zum login</Button>
              </div>
            </Panel>
          </div>
        </div>
      );
    } if (alreadyApproved) {
      return <Redirect to="/login" />;
    }
  }
  return null;
}

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: (clientId) => dispatch(get(clientId)),
    dispatchUpdate: (clientData) => dispatch(update(clientData)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ApproveRegistration));
