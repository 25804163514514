import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Panel from '../../../Components/Panel';
import Button from '../../../Components/Button';
import RequestModal from './RequestModal';
import ClientMandantContainer from './Components/ClientMandantContainer';
import {
  update, remove, getList, approve,
} from '../../../Redux/Action/MandantAction';
import Alert from '../../../Components/Alert';
import { NAVIGATION_CLIENT_ADMIN_MANDANT_DETAILS } from '../../../Library/Types';

/**
 * PartnerOverview()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function PartnerOverview(props) {
  const {
    partners, dispatchDelete, dispatchUpdate, client, dispatchGetList, dispatchApprove, history,
  } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [showInactiveHint, setShowInactiveHint] = React.useState(false);
  const [showRequestedHint, setShowRequestedHint] = React.useState(false);
  const [showApprovedHint, setShowApprovedHint] = React.useState(false);

  React.useEffect(() => {
    dispatchGetList(client);
  }, [client, dispatchGetList]);

  const handleDeleteMandant = (id) => {
    dispatchDelete(client, id);
  };

  const handleToggleActive = (id) => {
    const mandant = partners.filter((item) => item.id === id)[0];
    const oldState = mandant.active;
    mandant.active = !mandant.active;
    dispatchUpdate(client, mandant).then(() => {
      if (oldState) {
        setShowInactiveHint(true);
      }
    });
  };

  const handleApprove = (mandantId) => {
    dispatchApprove(client, mandantId).then(() => setShowApprovedHint(true));
  };

  const navigateDetails = (data) => {
    const uri = NAVIGATION_CLIENT_ADMIN_MANDANT_DETAILS.replace('{mandantId}', data.id);
    history.push(uri);
  };

  const renderContent = () => {
    if (partners && partners.length > 0) {
      return partners.map((item) => (
        <ClientMandantContainer
          client={client}
          item={item}
          key={item.partner.clientId}
          onToggleActive={handleToggleActive}
          onDelete={handleDeleteMandant}
          onApprove={handleApprove}
          onSettings={navigateDetails}
        />
      ));
    }
    return null;
  };

  const handleOnRequested = () => {
    setShowModal(false);
    setShowRequestedHint(true);
  };

  const renderRequestButton = () => {
    if (client.canHaveMandant) {
      return (
        <div className="d-flex align-items-start" style={{ marginLeft: 40 }}>
          <Button onClick={() => setShowModal(true)}>
            Partnerschaftsanfrage senden
          </Button>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <Panel marginBottom={25}>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              <h4>Partnerschaften</h4>
              <p style={{ margin: 0, padding: 0 }}>
                Die Partnerschaften helfen Ihnen bei internen Aufträgen. Sie können Partnerschaften mit ihren
                Verkäufern oder externen Monteuren hier festlegen. Verkäufer können dann direkt in hey.kitchen Küchen
                in Ihrem Namen anlegen, so das Sie diese nicht selber manuell einpflegen müssen. Der Verkäufer kann
                dann den Status seines für Sie erstellen Auftrags hier im System verfolgen und erhält sofort alle
                Berichte und Dokumente online.
              </p>
            </div>
            <div className="d-flex align-items-center">
              {renderRequestButton()}
            </div>
          </div>
        </Panel>

        {renderContent()}
      </div>

      <RequestModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        onRequested={handleOnRequested}
      />

      <Alert visible={showInactiveHint} type="exclamation" onClose={() => setShowInactiveHint(false)}>
        <strong>Die Partnerschaft wurde pausiert!</strong>
        <br />
        Der Partner kann jetzt keine Aufträge oder Arbeiten in Ihrem Namen erstellen oder durchführen. Sie können
        die Partnerschaft jederzeit wieder aktivieren und fortführen!
      </Alert>

      <Alert visible={showRequestedHint} type="success" onClose={() => setShowRequestedHint(false)}>
        <strong>Die Partnerschaftsanfrage wurde erfolgreich versendet!</strong>
        <br />
        Ihr Partner wird per E-Mail über Ihre Anfrage informiert.
        Sobald die Anfrage bestätigt wurde, ist die Partnerschaft aktiv.
      </Alert>

      <Alert visible={showApprovedHint} type="success" onClose={() => setShowApprovedHint(false)}>
        <strong>Die Partnerschaft wurde erfolgreich bestätigt!</strong>
        <br />
        Sie können jetzt interne Aufträge direkt für Ihren Partner erstellen und den Status der Bearbeitung verfolgen
      </Alert>

    </div>
  );
}

PartnerOverview.propTypes = {
  dispatchDelete: PropTypes.func.isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
  dispatchGetList: PropTypes.func.isRequired,
  dispatchApprove: PropTypes.func.isRequired,
  partners: PropTypes.instanceOf(Array),
  client: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

PartnerOverview.defaultProps = {
  partners: null,
};

function mapStoreToProps(store) {
  return {
    partners: store.client.partners,
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDelete: (clientId, mandantId) => dispatch(remove(clientId, mandantId)),
    dispatchUpdate: (clientId, mandant) => dispatch(update(clientId, mandant)),
    dispatchGetList: (client) => dispatch(getList(client)),
    dispatchApprove: (client, mandantId) => dispatch(approve(client, mandantId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(PartnerOverview));
