import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import ModalDialog from '../ModalDialog';
import { unsplit } from '../../Redux/Action/ObjectAction';

/**
 * ConfirmObjectUnsplitDialog()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ConfirmObjectUnsplitDialog(props) {
  const {
    object, visible, onClose, dispatchUnsplitObject,
  } = props;

  const handleConfirm = () => {
    dispatchUnsplitObject(object).then(() => {
      onClose();
    });
  };

  return (
    <ModalDialog
      visible={visible}
      onClose={onClose}
      centered
      confirmCaption="Auftragsteilung beenden"
      onConfirm={handleConfirm}
    >
      <h5>
        <i className="fas fa-box" style={{ marginRight: 10 }} />
        Auftragsteilung beenden
      </h5>
      <p>
        Bitte bestätigen Sie das Sie die Aufteilung der Auftragsoptionen beenden möchten.
        <br />
        <br />
        <strong>ACHTUNG! </strong>
        Mit dem beenden der Auftragteilung gehen alle bereits zugewiesenen Termine und Monteure verloren. Sollten Sie
        ein Montagedatum festgelegt haben wird dieses als Datum der Gesamtausführung übernommen. Die Zuweisung der
        Monteure oder Partner wird komplett entfernt und muss von Ihnen neu zugewiesen werden!
      </p>
    </ModalDialog>
  );
}

ConfirmObjectUnsplitDialog.propTypes = {
  dispatchUnsplitObject: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  object: PropTypes.instanceOf(Object),
};

ConfirmObjectUnsplitDialog.defaultProps = {
  visible: false,
  object: null,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchUnsplitObject: (object) => dispatch(unsplit(object)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(ConfirmObjectUnsplitDialog);
