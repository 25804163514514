import axios from '../../Library/Axios';

const defaultUri = '/order/{orderId}/object/{objectId}/demontage';

/**
 * prepareUri()
 * @param orderId
 * @param objectId
 * @returns {string}
 */
function prepareUri(orderId, objectId) {
  return defaultUri.replace('{orderId}', orderId).replace('{objectId}', objectId);
}

export const DEMONTAGEREPORT_GETPDF_START = 'DEMONTAGEREPORT_GETPDF_START';
export const DEMONTAGEREPORT_GETPDF_SUCCESS = 'DEMONTAGEREPORT_GETPDF_SUCCESS';
export const DEMONTAGEREPORT_GETPDF_ERROR = 'DEMONTAGEREPORT_GETPDF_ERROR';

/**
 * getPdf()
 * @param orderId
 * @param objectId
 * @param demontageReportId
 * @param withImages
 * @returns {function(*): *}
 */
export function getPdf(orderId, objectId, demontageReportId, withImages = false) {
  return (dispatch) => {
    dispatch({ type: DEMONTAGEREPORT_GETPDF_START });

    const config = {
      params: { action: 'pdf', withImages },
      responseType: 'arraybuffer',
    };
    const preparedUri = prepareUri(orderId, objectId);
    const uri = `${preparedUri}/${demontageReportId}`;

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/pdf' },
      );
      dispatch({ type: DEMONTAGEREPORT_GETPDF_SUCCESS, payload: data });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: DEMONTAGEREPORT_GETPDF_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const DEMONTAGEREPORT_DOWNLOADIMAGES_START = 'DEMONTAGEREPORT_DOWNLOADIMAGES_START';
export const DEMONTAGEREPORT_DOWNLOADIMAGES_SUCCESS = 'DEMONTAGEREPORT_DOWNLOADIMAGES_SUCCESS';
export const DEMONTAGEREPORT_DOWNLOADIMAGES_ERROR = 'DEMONTAGEREPORT_DOWNLOADIMAGES_ERROR';

/**
 * downloadImages()
 * @param orderId
 * @param objectId
 * @param demontageReportId
 * @returns {function(*): Promise<Blob | never>}
 */
export function downloadImages(orderId, objectId, demontageReportId) {
  return (dispatch) => {
    dispatch({ type: DEMONTAGEREPORT_DOWNLOADIMAGES_START });

    const config = {
      params: { action: 'downloadImages' },
      responseType: 'arraybuffer',
    };
    const preparedUri = prepareUri(orderId, objectId);
    const uri = `${preparedUri}/${demontageReportId}`;

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/zip' },
      );
      dispatch({ type: DEMONTAGEREPORT_DOWNLOADIMAGES_SUCCESS, payload: data });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: DEMONTAGEREPORT_DOWNLOADIMAGES_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const DEMONTAGEREPORT_GETLIST_START = 'DEMONTAGEREPORT_GETLIST_START';
export const DEMONTAGEREPORT_GETLIST_SUCCESS = 'DEMONTAGEREPORT_GETLIST_SUCCESS';
export const DEMONTAGEREPORT_GETLIST_ERROR = 'DEMONTAGEREPORT_GETLIST_ERROR';

/**
 * getList()
 * @param orderId
 * @param objectId
 * @returns {function(*): Promise<Blob | never>}
 */
export function getList(orderId, objectId) {
  return (dispatch) => {
    dispatch({ type: DEMONTAGEREPORT_GETLIST_START });

    const uri = prepareUri(orderId, objectId);

    return axios().get(uri).then((response) => {
      const { status } = response;
      const { data } = response.data;

      if (status === 200) {
        dispatch({ type: DEMONTAGEREPORT_GETLIST_SUCCESS, payload: data });
        return Promise.resolve(data);
      }
      dispatch({ type: DEMONTAGEREPORT_GETLIST_ERROR });
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: DEMONTAGEREPORT_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const DEMONTAGEREPORT_GET_START = 'DEMONTAGEREPORT_GET_START';
export const DEMONTAGEREPORT_GET_SUCCESS = 'DEMONTAGEREPORT_GET_SUCCESS';
export const DEMONTAGEREPORT_GET_ERROR = 'DEMONTAGEREPORT_GET_ERROR';

/**
 * getList()
 * @param demontageReportId
 * @returns {function(*): Promise<Blob | never>}
 */
export function get(demontageReportId) {
  return (dispatch) => {
    dispatch({ type: DEMONTAGEREPORT_GET_START });

    const uri = `${prepareUri(0, 0)}/${demontageReportId}`;

    return axios().get(uri).then((response) => {
      const { status } = response;
      const { data } = response.data;

      if (status === 200) {
        dispatch({ type: DEMONTAGEREPORT_GET_SUCCESS, payload: data });
        return Promise.resolve(data);
      }
      dispatch({ type: DEMONTAGEREPORT_GET_ERROR });
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: DEMONTAGEREPORT_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
