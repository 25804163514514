import * as Redux from 'react-redux';
import React from 'react';
import axios from '../../../../../Library/Axios';
import { defaultUri as serviceDefaultUri, get as getService } from '../ServiceAction';

/**
 * Prepare default uri and replace all placeholders
 * @param service
 * @returns {string}
 */
function prepareUrl(service) {
  const defaultUri = `${serviceDefaultUri}/{serviceId}/document`;
  return defaultUri.replace('{orderId}', service.orderId)
    .replace('{objectId}', service.objectId)
    .replace('{serviceId}', service.serviceId);
}

export const OBJECT_SERVICE_DOCUMENT_ADD_ITEM_START = 'OBJECT_SERVICE_DOCUMENT_ADD_ITEM_START';
export const OBJECT_SERVICE_DOCUMENT_ADD_ITEM_SUCCESS = 'OBJECT_SERVICE_DOCUMENT_ADD_ITEM_SUCCESS';
export const OBJECT_SERVICE_DOCUMENT_ADD_ITEM_ERROR = 'OBJECT_SERVICE_DOCUMENT_ADD_ITEM_ERROR';

/**
 * addItemDocument()
 * @param service
 * @param item
 * @param file
 * @returns {function(*): *}
 */
export function addItemDocument(service, item, file) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_DOCUMENT_ADD_ITEM_START, payload: service });

    const config = {
      params: { itemId: item.itemId },
    };

    const formData = new FormData();
    formData.append('file', file);

    return axios().post(prepareUrl(service), formData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_DOCUMENT_ADD_ITEM_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_DOCUMENT_ADD_ITEM_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

export const OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_START = 'OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_START';
export const OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_SUCCESS = 'OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_SUCCESS';
export const OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_ERROR = 'OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_ERROR';

/**
 * addServiceDocument()
 * @param service
 * @param file
 * @param type
 * @returns {function(*): *}
 */
export function addServiceDocument(service, file, type) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_START, payload: service });

    const config = {
      params: { type },
    };

    const formData = new FormData();
    formData.append('file', file);

    return axios().post(prepareUrl(service), formData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_DOCUMENT_ADD_OBJECT_SERVICE_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

export const OBJECT_SERVICE_DOCUMENT_DELETE_START = 'OBJECT_SERVICE_DOCUMENT_DELETE_START';
export const OBJECT_SERVICE_DOCUMENT_DELETE_SUCCESS = 'OBJECT_SERVICE_DOCUMENT_DELETE_SUCCESS';
export const OBJECT_SERVICE_DOCUMENT_DELETE_ERROR = 'OBJECT_SERVICE_DOCUMENT_DELETE_ERROR';

/**
 * deleteDocument()
 * @param service
 * @param documentId
 * @returns {function(*): *}
 */
export function deleteDocument(service, documentId) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_DOCUMENT_DELETE_START, payload: documentId });

    const uri = `${prepareUrl(service)}/${documentId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_DOCUMENT_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_DOCUMENT_DELETE_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

export const OBJECT_SERVICE_DOCUMENT_ATTACH_TO_MAIL_START = 'OBJECT_SERVICE_DOCUMENT_ATTACH_TO_MAIL_START';
export const OBJECT_SERVICE_DOCUMENT_ATTACH_TO_MAIL_SUCCESS = 'OBJECT_SERVICE_DOCUMENT_ATTACH_TO_MAIL_SUCCESS';
export const OBJECT_SERVICE_DOCUMENT_ATTACH_TO_MAIL_ERROR = 'OBJECT_SERVICE_DOCUMENT_ATTACH_TO_MAIL_ERROR';
/**
 * useDispatchToggleAttachToMail()
 * @returns {function(*, *, *): *}
 */
export function useDispatchToggleAttachToMail() {
  const dispatch = Redux.useDispatch();

  return React.useCallback((service, object, document) => {
    dispatch({ type: OBJECT_SERVICE_DOCUMENT_ATTACH_TO_MAIL_START, payload: { object, document } });

    const uri = `${prepareUrl(service)}/${document.documentId}`;

    const changedDoc = { ...document, sendToCustomer: (document.sendToCustomer !== true) };

    return axios().put(uri, changedDoc).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_DOCUMENT_ATTACH_TO_MAIL_SUCCESS, payload: data });
      return dispatch(getService(service.serviceId)).then(() => Promise.resolve(data));
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_DOCUMENT_ATTACH_TO_MAIL_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
