import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FileSaver from 'file-saver';
import moment from 'moment';
import Panel from '../../../Components/Panel';
import { generateDebits, get, getList } from '../Redux/Action/InvoiceAction';
import InvoiceTable from '../../../Components/InvoiceTable';
import ModalDialog from '../../../Components/ModalDialog';
import Button from '../../../Components/Button';
import LoadingModal from '../../../Components/LoadingModal';
import { formatCurrency } from '../../../Library/Functions';
import DetailsModal from './DetailsModal';

/**
 * GenerateDebits()
 * @param props
 * @returns {*}
 * @constructor
 */
function GenerateDebits(props) {
  const {
    dispatchGetList, invoices, dispatchGenerate, dispatchGet,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [askGenerate, setAskGenerate] = React.useState(false);
  const [overallPrice, setOverallPrice] = React.useState(null);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [intInvoices, setIntInvoices] = React.useState(null);

  // Set invoices to local var to modify the selection state internally!
  React.useEffect(() => {
    if (invoices) {
      const mapped = invoices.map((item) => ({ ...item, selected: true }));
      setIntInvoices(mapped);
    } else {
      setIntInvoices(null);
    }
  }, [invoices]);

  React.useEffect(() => {
    setLoading(true);
    dispatchGetList().then(() => setLoading(false)).catch(() => setLoading(false));
  }, [dispatchGetList]);

  React.useEffect(() => {
    if (intInvoices) {
      let price = 0;
      intInvoices.filter((item) => {
        price += parseFloat(item.priceBrutto);
        return true;
      });
      setOverallPrice(price.toFixed(2));
    } else {
      setOverallPrice(null);
    }
  }, [intInvoices]);

  const handleItemCheck = (item) => {
    const mapped = intInvoices.map((data) => {
      if (data.transactionId === item.transactionId) {
        return { ...data, selected: !item.selected };
      }
      return data;
    });
    setIntInvoices(mapped);
  };

  const handleOnAction = (data) => {
    dispatchGet(data.transactionId).then((response) => {
      setSelectedInvoice(response);
    });
  };

  const handleCloseDetails = () => {
    setSelectedInvoice(null);
  };

  const handleGenerate = () => {
    const transactionIds = intInvoices.filter((item) => item.selected).map((item) => item.transactionId);

    if (transactionIds && transactionIds.length > 0) {
      setAskGenerate(false);
      setLoading(true);
      dispatchGenerate(transactionIds).then((data) => {
        dispatchGetList();
        setLoading(false);
        FileSaver.saveAs(data, `SEPA_DEBITS_${moment().format('YYYYMMDDHHmmss')}.xml`);
      }).catch(() => setLoading(false));
    }
  };

  const renderContent = () => {
    if (!loading) {
      if (!intInvoices || intInvoices.length === 0) {
        return (
          <div className="alert alert-info" style={{ margin: 0 }}>
            <i className="fas fa-info-circle" style={{ marginRight: 8 }} />
            Keine offenen Rechnungen vorhanden!
          </div>
        );
      }
      return (
        <InvoiceTable
          selectable
          onCheckItem={handleItemCheck}
          transactions={intInvoices}
          onAction={handleOnAction}
          actionIcon="fas fa-info-circle"
        />
      );
    }
    return null;
  };

  const renderOverallPrice = () => {
    if (overallPrice) {
      return (
        <Panel marginBottom={25} boxPadding={10}>
          <div style={{ fontSize: 20 }} className="d-flex">
            <div className="flex-grow-1">
              Gesamtsumme:
            </div>
            <div>
              <strong style={{ marginLeft: 10 }}>
                {formatCurrency(overallPrice)}
              </strong>
            </div>
          </div>
        </Panel>
      );
    }
    return null;
  };

  return (
    <>
      <Panel marginBottom={25}>
        <h5>
          Lastschriften herunterladen
        </h5>
        <p style={{ marginBottom: 0 }}>
          Bitte prüfen Sie die Liste der zu erstellenden Lastschriften. Nach dem Herunterladen der
          SEPA-Lastschriftdatei werden die Einträge als bearbeitet und "bezahlt" markiert und können nicht
          erneut exportiert werden. Die erstelle SEPA-Lastschriftdatei kann jedoch jederzeit erneut heruntergeladen
          werden! Es werden alle Lastschriften exportiert mit einem Fälligkeitsdatum von heute oder kleiner!
        </p>
      </Panel>

      {renderOverallPrice()}

      <Panel marginBottom={25}>
        {renderContent()}
      </Panel>

      <Panel boxPadding={10}>
        <div className="justify-content-end d-flex">
          <Button onClick={() => setAskGenerate(true)} disabled={(!invoices || invoices.length === 0)}>
            Jetzt generieren
          </Button>
        </div>
      </Panel>

      <ModalDialog
        onClose={() => setAskGenerate(false)}
        visible={askGenerate}
        confirmCaption="Jetzt generieren"
        onConfirm={handleGenerate}
        centered
      >
        <h5>SEPA-Lastschriftdatei jetzt generieren?</h5>
        <p>
          Bitte bestätigen Sie das Sie die SEPA-Lastschriftdatei jetzt generieren und die
          Einträge als "bezahlt" markieren möchten. Dieser Vorgang kann nicht rückgängig gemacht werden!
        </p>
      </ModalDialog>

      <LoadingModal visible={loading} text="Offene Lastschriften werden geladen..." />
      <DetailsModal
        transactionId={(selectedInvoice) ? selectedInvoice.transactionId : null}
        onClose={handleCloseDetails}
        onChange={() => {}}
      />
    </>
  );
}

GenerateDebits.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGenerate: PropTypes.func.isRequired,
  dispatchGet: PropTypes.func.isRequired,
  invoices: PropTypes.instanceOf(Array),
};

GenerateDebits.defaultProps = {
  invoices: null,
};

function mapStoreToProps(store) {
  return {
    invoices: store.systemInvoice.openDebits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: () => dispatch(getList('openDebits')),
    dispatchGenerate: (transactionIds) => dispatch(generateDebits(transactionIds)),
    dispatchGet: (id) => dispatch(get(id)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(GenerateDebits));
