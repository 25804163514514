import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../Library/Axios';
import {
  filterMaxTenDayInThePastOrders,
  filterMaxTenDayInThePastService,
  filterRemoveOwnStorageOrders,
} from '../../Library/Functions';

export const defaultUri = '/user';

/**
 * updateUserGroups()
 * @param userId
 * @param groups
 * @returns {function(): Promise<any | never>}
 */
export function updateUserGroups(userId, groups) {
  return () => {
    const uri = `${defaultUri}/${userId}/group`;

    return axios().post(uri, groups).then((response) => {
      const { data } = response.data;
      return Promise.resolve(data);
    }).catch(() => Promise.reject());
  };
}

export const USER_GET_START = 'USER_GET_START';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_ERROR = 'USER_GET_ERROR';

export function useDispatchGetUser() {
  const dispatch = useDispatch();

  return React.useCallback((userId) => {
    const uri = `${defaultUri}/${userId}`;

    dispatch({ type: USER_GET_START });
    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

/**
 * get()
 * @param userId
 * @returns {function(*, *): *}
 */
export function get(userId) {
  return (dispatch) => {
    dispatch({ type: USER_GET_START });

    const uri = `${defaultUri}/${userId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const USER_GETALL_START = 'USER_GETALL_START';
export const USER_GETALL_SUCCESS = 'USER_GETALL_SUCCESS';
export const USER_GETALL_ERROR = 'USER_GETALL_ERROR';

/**
 * getAll()
 * @param forceReload
 * @returns {Function}
 */
export function getAll(forceReload = false) {
  return (dispatch, getState) => {
    const { users } = getState().user;
    const { settings } = getState().client.client;

    const assemblyTimePerDay = Math.abs(moment(settings.globalWorkDayStart, 'HH:mm:ss')
      .diff(moment(settings.globalWorkDayEnd, 'HH:mm:ss'), 'minutes'));

    if (!forceReload) {
      if (users && users.length > 0) {
        return Promise.resolve(users);
      }
    }

    dispatch({ type: USER_GETALL_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_GETALL_SUCCESS, payload: { users: data, assemblyTimePerDay } });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_GETALL_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

/**
 * useDispatchUserGetAll()
 * @returns (function(forceReload = false): (Promise<*>))
 */
export function useDispatchUserGetAll() {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const { settings } = useSelector((state) => state.client.client);

  return React.useCallback((forceReload = false) => {
    const assemblyTimePerDay = Math.abs(moment(settings.globalWorkDayStart, 'HH:mm:ss')
      .diff(moment(settings.globalWorkDayEnd, 'HH:mm:ss'), 'minutes'));

    if (!forceReload) {
      if (users && users.length > 0) {
        return Promise.resolve(users);
      }
    }

    dispatch({ type: USER_GETALL_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_GETALL_SUCCESS, payload: { users: data, assemblyTimePerDay } });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_GETALL_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch, users, settings]);
}

export const USER_CREATE_START = 'USER_CREATE_START';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';

/**
 * Create a new user for the current client
 * create()
 * @param userData
 * @returns {function(*): Promise<any | never>}
 */
export function create(userData) {
  return (dispatch) => {
    dispatch({ type: USER_CREATE_START, payload: userData });

    return axios().post(defaultUri, userData).then((response) => {
      const { data } = response.data;

      return dispatch(updateUserGroups(data.userId, userData.groups)).then((user) => {
        dispatch({ type: USER_CREATE_SUCCESS, payload: user });
        return Promise.resolve(data);
      });
    }).catch((error) => {
      dispatch({ type: USER_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const USER_UPDATE_START = 'USER_UPDATE_START';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

/**
 * Create a new user for the current client
 * update()
 * @param userId
 * @param userData
 * @returns {function(*): Promise<any | never>}
 */
export function update(userId, userData) {
  return (dispatch) => {
    dispatch({ type: USER_UPDATE_START, userData });

    const uri = `${defaultUri}/${userId}`;

    return axios().put(uri, userData).then((response) => {
      const { data } = response.data;

      if (userData.groups) {
        return dispatch(updateUserGroups(data.userId, userData.groups)).then((user) => {
          dispatch({ type: USER_UPDATE_SUCCESS, payload: user });
          return Promise.resolve(data);
        });
      }
      dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const USER_DELETE_START = 'USER_DELETE_START';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

/**
 * remove()
 * @param userData
 * @returns {function(*): Promise<any | never>}
 */
export function remove(userData) {
  return (dispatch) => {
    dispatch({ type: USER_DELETE_START });

    const uri = `${defaultUri}/${userData.userId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const USER_CHANGEPASSWORD_START = 'USER_CHANGEPASSWORD_START';
export const USER_CHANGEPASSWORD_SUCCESS = 'USER_CHANGEPASSWORD_SUCCESS';
export const USER_CHANGEPASSWORD_ERROR = 'USER_CHANGEPASSWORD_ERROR';

/**
 * changePassword()
 * @param userId
 * @param oldPassword
 * @param newPassword
 * @returns {function(*): Promise<unknown>}
 */
export function changePassword(userId, oldPassword, newPassword) {
  return (dispatch) => {
    dispatch({ type: USER_CHANGEPASSWORD_START });

    const uri = `${defaultUri}/${userId}`;

    const config = {
      params: {
        action: 'changePassword',
      },
    };

    return axios().put(uri, { oldPassword, newPassword }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_CHANGEPASSWORD_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_CHANGEPASSWORD_ERROR, payload: error.response });
      return Promise.reject(error.repsonse);
    });
  };
}

export const USER_RESETPASSWORD_START = 'USER_RESETPASSWORD_START';
export const USER_RESETPASSWORD_SUCCESS = 'USER_RESETPASSWORD_SUCCESS';
export const USER_RESETPASSWORD_ERROR = 'USER_RESETPASSWORD_ERROR';

/**
 * resetPassword()
 * @param mail
 * @returns {function(*): Promise<unknown>}
 */
export function resetPassword(mail) {
  return (dispatch) => {
    dispatch({ type: USER_RESETPASSWORD_START });

    const config = {
      params: {
        action: 'resetPassword',
      },
    };

    return axios().post(defaultUri, { mail }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: USER_RESETPASSWORD_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_RESETPASSWORD_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}

export const USER_GETORDERS_START = 'USER_GETORDERS_START';
export const USER_GETORDERS_SUCCESS = 'USER_GETORDERS_SUCCESS';
export const USER_GETORDERS_ERROR = 'USER_GETORDERS_ERROR';

/**
 * getOrders()
 * @param startDate
 * @param endDate
 * @returns {function(*, *): *}
 */
export function getOrders(startDate = null, endDate = null) {
  return (dispatch, getState) => {
    const { client } = getState().auth;
    dispatch({ type: USER_GETORDERS_START, payload: startDate, endDate });

    const config = {
      params: {
        action: 'orders',
        startDate,
        endDate,
      },
    };

    return axios().get(defaultUri, config).then((response) => {
      let { data } = response.data;
      data = data.reverse();
      let filteredOrders = filterRemoveOwnStorageOrders(data, client);
      filteredOrders = filterMaxTenDayInThePastOrders(filteredOrders);
      const internalOrders = filteredOrders.filter((item) => item.internal);
      const externalOrders = filteredOrders.filter((item) => !item.internal);
      const merged = {
        orders: data, internalOrders, externalOrders,
      };

      dispatch({ type: USER_GETORDERS_SUCCESS, payload: (merged) || [] });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_GETORDERS_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}

export const USER_GETRECLAMATIONS_START = 'USER_GETRECLAMATIONS_START';
export const USER_GETRECLAMATIONS_SUCCESS = 'USER_GETRECLAMATIONS_SUCCESS';
export const USER_GETRECLAMATIONS_ERROR = 'USER_GETRECLAMATIONS_ERROR';

/**
 * getReclamations()
 * @param startDate
 * @param endDate
 * @returns {function(*): Promise<unknown>}
 */
export function getReclamations(startDate = null, endDate = null) {
  return (dispatch) => {
    dispatch({ type: USER_GETRECLAMATIONS_START, payload: startDate, endDate });

    const config = {
      params: {
        action: 'reclamations',
        startDate,
        endDate,
      },
    };

    return axios().get(defaultUri, config).then((response) => {
      let { data } = response.data;
      data = data.reverse();
      const filteredReclamations = filterMaxTenDayInThePastService(data);
      const internalReclamations = filteredReclamations.filter((item) => item.internal);
      const externalReclamations = filteredReclamations.filter((item) => !item.internal);
      const merged = {
        reclamations: data, internalReclamations, externalReclamations,
      };

      dispatch({ type: USER_GETRECLAMATIONS_SUCCESS, payload: (merged) || [] });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_GETRECLAMATIONS_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}

export const USER_GETOBJECTSERVICES_START = 'USER_GETOBJECTSERVICES_START';
export const USER_GETOBJECTSERVICES_SUCCESS = 'USER_GETOBJECTSERVICES_SUCCESS';
export const USER_GETOBJECTSERVICES_ERROR = 'USER_GETOBJECTSERVICES_ERROR';

/**
 * Get all assigned object services
 * getServices()
 * @param startDate
 * @param endDate
 * @returns {function(*): Promise<any | never>}
 */
export function getServices(startDate = null, endDate = null) {
  return (dispatch) => {
    dispatch({ type: USER_GETOBJECTSERVICES_START, payload: startDate, endDate });

    const config = {
      params: {
        action: 'objectServices',
        startDate: (startDate) || moment().subtract(3, 'days').format('YYYY-MM-DD'),
        endDate,
      },
    };

    return axios().get(defaultUri, config).then((response) => {
      let { data } = response.data;
      data = data.reverse();
      const filteredServices = filterMaxTenDayInThePastService(data);
      const internalServices = filteredServices.filter((item) => item.internal).reverse();
      const externalServices = filteredServices.filter((item) => !item.internal).reverse();
      const merged = {
        services: data, internalServices, externalServices,
      };
      dispatch({ type: USER_GETOBJECTSERVICES_SUCCESS, payload: (merged) || [] });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: USER_GETOBJECTSERVICES_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

/**
 * checkUsernameAvailable()
 * @param username
 * @returns {function(*): Promise<unknown>}
 */
export function checkUsernameAvailable(username) {
  return () => {
    const config = {
      params: {
        username,
        action: 'checkUsername',
      },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      if (data.result) {
        return Promise.resolve();
      }
      return Promise.reject();
    }).catch(() => Promise.reject());
  };
}
