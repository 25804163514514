import React from 'react';
import PropTypes from 'prop-types';
import StatusBadge from '../../StatusBadge';
import {
  useGetAppointmentCaption,
  useGetAppointmentIcon,
  useGetAppointmentType,
} from '../../../Library/States/AppointmentStates';

/**
 * AppointmentStatusBadge()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function AppointmentStatusBadge(props) {
  const {
    status, alignContainer, containerStyle, iconOnly, useMediaQuery, visible,
  } = props;

  const getCaption = useGetAppointmentCaption();
  const getIcon = useGetAppointmentIcon();
  const getType = useGetAppointmentType();

  return (
    <StatusBadge
      caption={getCaption(status)}
      icon={getIcon(status)}
      type={getType(status)}
      visible={visible}
      useMediaQuery={useMediaQuery}
      containerStyle={containerStyle}
      iconOnly={iconOnly}
      alignContainer={alignContainer}
    />
  );
}

AppointmentStatusBadge.propTypes = {
  status: PropTypes.number.isRequired,
  alignContainer: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  containerStyle: PropTypes.instanceOf(Object),
  iconOnly: PropTypes.bool,
  useMediaQuery: PropTypes.bool,
  visible: PropTypes.bool,
};

AppointmentStatusBadge.defaultProps = {
  alignContainer: 'flex-start',
  containerStyle: {},
  iconOnly: false,
  useMediaQuery: false,
  visible: true,
};
