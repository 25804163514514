import {
  SYSTEM_INVOICE_GETLIST_ERROR, SYSTEM_INVOICE_GETLIST_START, SYSTEM_INVOICE_GETLIST_SUCCESS,
} from '../Action/InvoiceAction';

const defaultValues = {
  invoices: null,
  openDebits: null,
  openCredits: null,
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case SYSTEM_INVOICE_GETLIST_START: {
      return {
        ...state, openCredits: null, openDebits: null, isLoading: true,
      };
    }
    case SYSTEM_INVOICE_GETLIST_SUCCESS: {
      const { type, data } = action.payload;
      if (type === 'openDebits') {
        return { ...state, openDebits: data, isLoading: false };
      } if (type === 'openCredits') {
        return { ...state, openCredits: data, isLoading: false };
      }
      return { ...state, invoices: data, isLoading: false };
    }
    case SYSTEM_INVOICE_GETLIST_ERROR: {
      return {
        ...state,
        invoices: null,
        openCredits: null,
        openDebits: null,
        isLoading: false,
      };
    }

    default: return { ...state };
  }
}
