import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../ModalDialog';
import CustomerAddressInput from '../CustomerAddressInput';

/**
 * UpdateCustomerAddress()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UpdateCustomerAddress(props) {
  const {
    object, visible, onClose, onSave,
  } = props;
  const [data, setData] = React.useState({});

  const handleConfirm = () => {
    const merged = { ...object.customer, ...data };
    onSave(merged);
  };

  return (
    <ModalDialog onClose={onClose} visible={visible} onConfirm={handleConfirm}>
      <CustomerAddressInput
        onChange={(value) => setData(value)}
        editableData={object.customer}
        headline="Lieferadresse"
      />
    </ModalDialog>
  );
}

UpdateCustomerAddress.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

UpdateCustomerAddress.defaultProps = {
  visible: false,
};
