import React, { useEffect } from 'react';
import AppStoreBanner from '../Components/AppStoreBanner';
import FaqBox from '../Components/MonteurFaqBox';
import Button from '../../../Components/Button';
import WebdemoContact from '../Components/WebdemoContact';
import Footer from '../Components/Footer';
import StartpageHeader from '../Components/MonteurHeader';
import MonteurAdvantages from '../Components/MonteurAdvantages';
import MonteurBenefits from '../Components/MonteurBenefits';
import ProcessDescription from '../Components/MonteurProcessDescription';
import RegisterRow from '../Components/MonteurRegisterRow';

/**
 * Startpage()
 * @returns {*}
 * @constructor
 */
export default function Startpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-grow-1 flex-column" style={{ margin: -40 }}>
      <StartpageHeader />

      <RegisterRow />

      <ProcessDescription />

      <div className="FullPageBox Gray" style={{ padding: '30px 0' }} />

      <MonteurAdvantages />
      <RegisterRow />
      <MonteurBenefits />
      <AppStoreBanner />

      <div className="FullPageBox Light Center" style={{ marginTop: 100 }}>
        <div className="container d-flex flex-column">
          <h2>FAQ</h2>
          <p style={{ marginBottom: 60 }}>
            Wir haben die wichtigsten Fragen für Sie schon beantwortet.
          </p>

          <FaqBox />

          <h2 style={{ marginTop: 60 }}>Ihre Frage wurde nicht beantwortet?</h2>
          <p style={{ marginBottom: 60 }}>
            Schreiben Sie uns eine E-Mail. Gerne beantworten wir Ihre Fragen.
          </p>

          <a href="mailto:info@hey.kitchen">
            <Button onClick={() => {}}>Kontakt</Button>
          </a>
        </div>
      </div>

      <WebdemoContact />
      <Footer />
    </div>
  );
}
