/* eslint-disable react/prop-types */

import React, { useReducer, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Panel from '../../../Components/Panel';
import SelectPanel from '../../../Components/SelectPanels';
import {
  orderType,
} from '../../../Library/Types';
import Button from '../../../Components/Button';
import LoadingModal from '../../../Components/LoadingModal';
import ObjectContainer from './Components/ObjectContainer';
import OrderObjectHandler from './Components/OrderObjectHandler';
import LoadingAddress from './Components/LoadingAddress';
import validateObjectContainerHasErrors from './Validator/ValidateObjectContainer';
import loadingAddressFormHasErrors from './Validator/LoadingAddressValidator';
import deliveryDateFormHasErrors from './Validator/DeliveryDateValidator';
import orderFormHasErrors from './Validator/OrderValidator';
import { isObjectEmpty } from '../../../Library/Functions';
import { get, update } from '../../../Redux/Action/OrderAction';
import DeliveryDate from './Components/DeliveryDate';
import ModalDialog from '../../../Components/ModalDialog';
import SelectOrderOptions from '../../../Components/OrderComponents/SelectOrderOptions';
import { ORDERSTATE_INCOMPLETE, ORDERSTATE_OPEN } from '../../../Library/StateTypes';

const initialState = {
  internal: false,
  type: 'SINGLE',
  optionDelivery: false,
  optionMontage: false,
  optionStorage: false,
  optionRemoval: false,
  deliveryDateStart: null,
  deliveryDateEnd: null,
  loadingAddress: {},
  objects: [],
  deliveryDate: {},
  removalMeter: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'initialData':
      return { ...action.payload };
    case 'internal':
      return { ...state, internal: action.payload };
    case 'orderType':
      return { ...state, type: action.payload, objects: [] };
    case 'optionDelivery':
      return { ...state, optionDelivery: !state.optionDelivery };
    case 'optionRemoval':
      return { ...state, optionRemoval: !state.optionRemoval };
    case 'optionStorage':
      return { ...state, optionStorage: !state.optionStorage };
    case 'removalMeter':
      return { ...state, removalMeter: (action.payload) ? action.payload.replace(',', '.') : null };
    case 'orderLoadingAddress':
      return { ...state, loadingAddress: action.payload };
    case 'addObject':
      return { ...state, objects: action.payload };
    case 'deliveryDate':
      return {
        ...state,
        deliveryDate: action.payload,
        deliveryDateStart: action.payload.deliveryDateStart,
        deliveryDateEnd: action.payload.deliveryDateEnd,
        deliveryWeekStart: action.payload.deliveryWeekStart,
      };

    default:
      throw new Error();
  }
}

/**
 * Edit()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Edit(props) {
  const {
    match, getOrder, history, client, dispatchUpdate,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errors, setErrors] = useState(false);
  const [orderId] = useState(match.params.orderId);
  const [order, setOrder] = useState(null);
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * On function did mount, load order data and initialize reducer state
   */
  useEffect(() => {
    setIsLoading(true);
    getOrder(orderId).then((data) => {
      setOrder(data);
      const tmpData = [];
      tmpData.push((data.optionMontage) ? 'MONTAGE' : null);
      tmpData.push((data.optionDelivery) ? 'DELIVERY' : null);
      tmpData.push((data.optionStorage) ? 'STORAGE' : null);
      tmpData.push((data.optionRemoval) ? 'REMOVAL' : null);

      let initialData = {
        orderId: data.orderId,
        internal: data.internal,
        type: data.type,
        optionDelivery: data.optionDelivery,
        optionMontage: data.optionMontage,
        optionStorage: data.optionStorage,
        optionRemoval: data.optionRemoval,
        workOptionArray: tmpData,
        loadingAddress: data.loadingAddress,
        removalMeter: data.removalMeter,
        state: data.state,
        deliveryDate: {
          deliveryDateStart: data.deliveryDateStart,
          deliveryDateEnd: data.deliveryDateEnd,
          deliveryWeekStart: data.deliveryWeekStart,
        },
        objects: {
          ...data.objects[0],
        },
      };

      if (data.type === 'SINGLE') {
        initialData = {
          ...initialData,
          objects: {
            ...data.objects[0],
          },
        };
      } else {
        const objects = [];
        data.objects.forEach((item) => {
          objects.push({
            ...item,
            id: item.objectId,
          });
        });
        initialData = { ...initialData, objects };
      }

      dispatch({ type: 'initialData', payload: initialData });
      setOrderLoaded(true);
      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  }, [getOrder, orderId]);

  useEffect(() => {
    if (orderLoaded && order) {
      if (order.internal) {
        if (order.state !== ORDERSTATE_OPEN && order.state !== ORDERSTATE_INCOMPLETE) {
          const detailsUri = `/order/${order.orderId}/handle`;
          history.push(detailsUri);
        }
      } else if (order.approved) {
        history.push('/dashboard');
      }
    }
  }, [orderLoaded, state, order, history]);

  /**
   * validateForms()
   * @returns {boolean}
   */
  const validateForms = () => {
    let errorObject = {};

    const orderErrors = orderFormHasErrors(state, state.type);
    if (orderErrors !== false) {
      errorObject = { ...errorObject, orderErrors };
    }

    if (state.optionDelivery && !state.optionStorage) {
      const loadingAddressErrors = loadingAddressFormHasErrors(state);
      if (loadingAddressErrors !== false) {
        errorObject = { ...errorObject, loadingAddressErrors };
      }
    }

    if (state.type === 'SINGLE') {
      const objectContainerErrors = validateObjectContainerHasErrors(
        state.objects, 'SINGLE', null, client.settings.internalMontageInfoMandatory, state.internal, true,
      );

      if (objectContainerErrors !== false) {
        errorObject = { ...errorObject, objectContainerErrors };
      }
    }

    const deliveryDateErrors = deliveryDateFormHasErrors(state.deliveryDate, state.type, state.internal);

    if (deliveryDateErrors !== false) {
      errorObject = { ...errorObject, deliveryDateErrors };
    }

    if (isObjectEmpty(errorObject)) {
      setErrors(false);
      return true;
    }
    setErrors(errorObject);
    return false;
  };

  /**
   * onSave()
   * @returns {boolean}
   */
  const onSave = () => {
    if (!validateForms()) {
      window.scrollTo(0, 0);
      setShowErrorDialog(true);
      return false;
    }

    setIsSaving(true);
    dispatchUpdate(state).then((data) => {
      setIsSaving(false);
      if (!data.internal) {
        if (data.processed) {
          history.push(`/create/${data.orderId}/approve`);
          return true;
        }
        history.push(`/create/${data.orderId}/processing`);
        return true;
      }

      const detailsUri = `/order/${data.orderId}/handle`;
      history.push(detailsUri);
      return true;
    }).catch(() => setIsSaving(false));

    return true;
  };

  /**
   * renderContent()
   * @returns {*}
   */
  const renderContent = () => {
    if (state.type === 'SINGLE') {
      return (
        <ObjectContainer
          optionStorage={state.optionStorage}
          orderType={state.type}
          onChange={(value) => dispatch({ type: 'addObject', payload: value })}
          errors={errors.objectContainerErrors}
          editableData={state.objects}
          isEditMode
          isInternal={order.internal}
        />
      );
    }
    return (
      <OrderObjectHandler
        hasError={(errors.orderErrors && errors.orderErrors.objects)}
        onChange={(value) => dispatch({ type: 'addObject', payload: value })}
        editableData={state.objects}
        client={client}
        isInternal={order.internal}
      />
    );
  };

  if (orderLoaded) {
    return (
      <div className="row">
        <LoadingModal
          visible={isSaving}
          text="Änderungen werden gespeichert..."
        />
        <LoadingModal
          visible={isLoading}
          text="Auftrag wird geladen..."
        />

        <div className="col-xl-3" />
        <div className="col-xl-6">
          <Panel marginBottom={25}>
            <h5>1. Auftragsart</h5>
            <p className="text-muted">
              Wählen Sie die Art Ihres Auftrags
            </p>
            <SelectPanel
              data={orderType}
              onChange={(value) => dispatch({ type: 'orderType', payload: value })}
              selected={[state.type]}
              hasError={errors.orderErrors && !!errors.orderErrors.type}
            />
          </Panel>

          <Panel marginBottom={25}>
            <SelectOrderOptions
              onChange={(value) => dispatch({ type: 'orderOptions', payload: value })}
              onChangeRemovalMeter={(value) => dispatch({ type: 'removalMeter', payload: value })}
              removalMeter={state.removalMeter}
              internal={false}
              errors={errors}
              onMontage={() => {}}
              onDelivery={() => dispatch({ type: 'optionDelivery' })}
              onRemoval={() => dispatch({ type: 'optionRemoval' })}
              onStorage={() => dispatch({ type: 'optionStorage' })}
              isStorage={state.optionStorage}
              isMontage={state.optionMontage}
              isDelivery={state.optionDelivery}
              isRemoval={state.optionRemoval}
            />
          </Panel>

          <Panel marginBottom={25}>
            <LoadingAddress
              storageSelected={state.optionStorage}
              deliverySelected={state.optionDelivery}
              onChange={(value) => dispatch({ type: 'orderLoadingAddress', payload: value })}
              errors={errors.loadingAddressErrors}
              editableData={state.loadingAddress}
              mandantId={0}
              internal={state.internal}
            />
          </Panel>

          {renderContent()}

          <Panel marginBottom={25}>
            <DeliveryDate
              orderType={state.type}
              onChange={(value) => dispatch({ type: 'deliveryDate', payload: value })}
              errors={errors.deliveryDateErrors}
              editableData={state.deliveryDate}
              isInternal={state.internal}
            />
          </Panel>

          <Panel>
            <div className="row">
              <div className="col-lg-12">
                <div className="float-right">
                  <div className="ButtonContainer">
                    <Button onClick={() => history.goBack()} type="btn-light">
                      Abbrechen
                    </Button>
                    <Button onClick={() => onSave()}>
                      Änderung speichern
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-xl-3" />

        <ModalDialog
          onClose={() => setShowErrorDialog(false)}
          visible={showErrorDialog}
          hideConfirmButton
          closeCaption="Schließen"
        >
          <h5 className="hk-text-primary">
            <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
            Informationen fehlen!
          </h5>
          Sie haben nicht alle benötigten Informationen ausgefüllt.
          <br />
          Bitte prüfen Sie das Formular und korrigieren Sie die "rot" markierten Felder!
        </ModalDialog>
      </div>
    );
  }
  return null;
}

function mapStoreToProps(store) {
  return {
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrder: (orderId) => dispatch(get(orderId, true)),
    dispatchUpdate: (order) => dispatch(update(order)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Edit));
