import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';

import './style.css';

/**
 * Document()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Document(props) {
  const { document } = props;
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (document && document.file) {
      const reader = new FileReader();
      reader.readAsDataURL(document.file);

      reader.onloadend = () => {
        setImageSrc([reader.result]);
      };
    }
  }, [document]);

  if (document && document.absolutePath) {
    return (
      <div className="Image">
        <img src={document.absolutePath} alt="Gerätebild" />
      </div>
    );
  }
  if (imageSrc) {
    return (
      <div className="Image">
        <img src={imageSrc} alt="Gerätebild" />
      </div>
    );
  }
  return <div className="Image" />;
}

Document.propTypes = {
  document: PropTypes.instanceOf(Object),
};

Document.defaultProps = {
  document: null,
};

/**
 * ImageContainer()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ImageContainer(props) {
  const { documents, onClick } = props;
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (documents && documents.length === 1 && documents[0].file) {
      const reader = new FileReader();
      reader.readAsDataURL(documents[0].file);

      reader.onloadend = () => {
        setImageSrc([reader.result]);
      };
    }
  }, [documents]);

  const renderDocument = (index) => (
    <Document document={documents[index]} />
  );

  const renderNumber = () => (
    <div className="Image Number">
      {`+${documents.length - 4}`}
    </div>
  );

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (documents) {
    if (documents.length === 1) {
      return (
        <div
          className="ImageContainer"
          onClick={handleOnClick}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
        >
          <div className="SingleImage">
            <img src={(imageSrc) || documents[0].absolutePath} alt="Gerätebild" />
          </div>
        </div>
      );
    }
    if (documents.length > 1 && documents.length <= 4) {
      return (
        <div
          className="ImageContainer"
          onClick={handleOnClick}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
        >
          <div className="Column Top">
            {renderDocument(0)}
            {renderDocument(1)}
          </div>
          <div className="Column Bottom">
            {renderDocument(2)}
            {renderDocument(3)}
          </div>
        </div>
      );
    }
    if (documents.length > 4) {
      return (
        <div
          className="ImageContainer"
          onClick={handleOnClick}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
        >
          <div className="Column Top">
            {renderDocument(0)}
            {renderDocument(1)}
          </div>
          <div className="Column Bottom">
            {renderDocument(2)}
            {renderNumber()}
          </div>
        </div>
      );
    }
  }
  return null;
}

ImageContainer.propTypes = {
  documents: PropTypes.instanceOf(Array).isRequired,
  onClick: PropTypes.func,
};

ImageContainer.defaultProps = {
  onClick: null,
};
