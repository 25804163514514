import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DayView from './Components/DayView';
import WeekView from './Components/WeekView';
import CalendarTheme from './CalendarTheme';
import DefaultPageTheme from '../../Assets/DefaultTheme';
import TopPanel from './Components/TopPanel';
import WeekOverView from './Components/WeekOverView';

export const VIEW_DAY = 'day';
export const VIEW_WEEK = 'week';
export const VIEW_WEEK_OVERVIEW = 'weekOverview';

const CalendarContainer = styled.div`
  display: flex;

  *,
  *::before,
  *::after {
    box-sizing: unset !important;
  }
`;

const CalendarRightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: #fff;
  border-radius: ${(props) => props.theme.border.radius};
  ${(props) => props.theme.boxShadowDark};
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 60px;
  font-size: 80px;
`;

const STORAGENAME = 'HKDISPO';

/**
 * Scheduler()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Scheduler(props) {
  const {
    themeOverwrite, calendarData, onDrop, onClickAppointment, onClickCreate, onClickHour, initialDate, onDateChange,
    isReloading, onRefresh,
  } = props;
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedRange, setSelectedRange] = React.useState(null);
  const [currentView, setCurrentView] = React.useState(VIEW_DAY);
  const [mergedTheme, setMergedTheme] = React.useState(CalendarTheme);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);

      const storage = JSON.parse(window.localStorage.getItem(STORAGENAME));
      const defaultRange = [
        moment().format('YYYY-MM-DD'),
        moment().add(6, 'days').format('YYYY-MM-DD'),
      ];

      if (initialDate) {
        setSelectedRange(defaultRange);
        setSelectedDate(initialDate);
        setCurrentView(VIEW_DAY);
        onDateChange({ startDate: initialDate, endDate: initialDate });
      } else if (storage && Object.keys(storage).length > 0) {
        setSelectedRange(storage.lastSelectedRange || defaultRange);
        setSelectedDate(storage.lastSelectedDate || moment().format('YYYY-MM-DD'));
        setCurrentView(storage.lastSelectedView);
        const dateRange = storage.lastSelectedRange || defaultRange;
        onDateChange({ startDate: dateRange[0], endDate: dateRange[1] });
      } else {
        const today = moment().format('YYYY-MM-DD');
        setSelectedRange(defaultRange);
        setSelectedDate(today);
        setCurrentView(VIEW_DAY);
        onDateChange({ startDate: today, endDate: today });
      }
    }
  }, [initialDate, onDateChange, initialized]);

  React.useEffect(() => {
    if (selectedDate && selectedRange && currentView) {
      const storage = JSON.parse(window.localStorage.getItem(STORAGENAME));
      const storageMerged = {
        ...storage,
        lastSelectedDate: selectedDate,
        lastSelectedRange: selectedRange,
        lastSelectedView: currentView,
      };
      window.localStorage.setItem(STORAGENAME, JSON.stringify(storageMerged));
    }
  }, [currentView, selectedRange, selectedDate]);

  React.useEffect(() => {
    const merged = _.merge({ ...DefaultPageTheme }, { ...CalendarTheme }, { ...themeOverwrite });
    setMergedTheme(merged);
  }, [themeOverwrite]);

  const handleOnSetDate = (date) => {
    setSelectedDate(date);
    setCurrentView(VIEW_DAY);
    onDateChange({ startDate: date, endDate: date });
  };

  const handleOnSetRange = (data) => {
    setSelectedRange(data);
    onDateChange({ startDate: data[0], endDate: data[1] });
  };

  const renderContent = () => {
    if (selectedDate && selectedRange && calendarData) {
      if (currentView === VIEW_DAY) {
        return (
          <DayView
            data={calendarData}
            selectedDate={selectedDate}
            onDrop={onDrop}
            onClickAppointment={onClickAppointment}
            onChangeDate={handleOnSetDate}
            onClickHour={onClickHour}
          />
        );
      }
      if (currentView === VIEW_WEEK) {
        return (
          <WeekView
            data={calendarData}
            selectedRange={selectedRange}
            onClickAppointment={onClickAppointment}
            onRangeChange={handleOnSetRange}
            onClickHour={onClickHour}
            onClickDate={handleOnSetDate}
          />
        );
      }
      return (
        <WeekOverView
          data={calendarData}
          selectedRange={selectedRange}
          onRangeChange={handleOnSetRange}
          onClickDate={handleOnSetDate}
        />
      );
    }
    return (
      <LoadingContainer>
        <i className="fas fa-spin fa-sync" />
      </LoadingContainer>
    );
  };

  return (
    <ThemeProvider theme={{ ...mergedTheme }}>
      <DndProvider backend={HTML5Backend}>
        <TopPanel
          dayView={currentView === VIEW_DAY}
          weekView={currentView === VIEW_WEEK}
          weekOverview={currentView === VIEW_WEEK_OVERVIEW}
          onActivateDayView={() => setCurrentView(VIEW_DAY)}
          onActivateWeekView={() => setCurrentView(VIEW_WEEK)}
          onActivateWeekOverview={() => setCurrentView(VIEW_WEEK_OVERVIEW)}
          onClickCreate={onClickCreate}
          onReload={onRefresh}
          isReloading={isReloading}
        />
        <CalendarContainer>
          <CalendarRightContainer>
            {renderContent()}
          </CalendarRightContainer>
        </CalendarContainer>
      </DndProvider>
    </ThemeProvider>
  );
}

Scheduler.propTypes = {
  onDrop: PropTypes.func,
  calendarData: PropTypes.instanceOf(Array).isRequired,
  themeOverwrite: PropTypes.instanceOf(Object),
  onClickAppointment: PropTypes.func.isRequired,
  onClickCreate: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onClickHour: PropTypes.func.isRequired,
  initialDate: PropTypes.string,
  isReloading: PropTypes.bool,
  onRefresh: PropTypes.func.isRequired,
};

Scheduler.defaultProps = {
  onDrop: null,
  themeOverwrite: {},
  initialDate: null,
  isReloading: false,
};
