import { combineReducers } from 'redux';

/**
 * Search for all reducer files in the directory "./redux/reducers" and import as reducers.
 * The name of the reducer ist the filename without "Reducer" and the first
 * char of the filename to lower case
 */

const reducers = [];

/** Add global redux reducers */
const req = require.context('./Reducer', true, /\.js$/);
req.keys().forEach((key) => {
  let storeName = key.replace('./', '').replace('Reducer.js', '');
  storeName = storeName.charAt(0).toLowerCase() + storeName.slice(1);
  reducers[storeName] = req(key).default;
});

/** Add system redux reducers */
const systemReq = require.context('../Pages/System/Redux/Reducer', true, /\.js$/);
systemReq.keys().forEach((key) => {
  const storeName = key.replace('./', 'system').replace('Reducer.js', '');
  reducers[storeName] = systemReq(key).default;
});

export default combineReducers(reducers);
