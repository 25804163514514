import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

/**
 * LoadingModal
 * @param props
 * @returns {null|*}
 * @constructor
 */
function LoadingModal(props) {
  const {
    visible, headline, text, progress,
  } = props;

  const renderHeadline = () => {
    if (headline) {
      return (
        <h4>{headline}</h4>
      );
    }
    return null;
  };

  const renderProgress = () => {
    if (progress > 0) {
      return (
        <div className="Progress">{`${progress}%`}</div>
      );
    }
    return null;
  };

  const renderText = () => {
    if (text) {
      return (
        // eslint-disable-next-line react/no-danger
        <div className="Text" dangerouslySetInnerHTML={{ __html: text }} />
      );
    }
    return null;
  };

  if (visible) {
    return (
      <div className="LoadingModal">
        <div className="Container">
          {renderHeadline()}
          {renderText()}
          <i className="fas fa-spin fa-circle-notch" />
          {renderProgress()}
        </div>
      </div>
    );
  }
  return null;
}

LoadingModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  headline: PropTypes.string,
  text: PropTypes.string,
  progress: PropTypes.number,
};

LoadingModal.defaultProps = {
  headline: null,
  text: null,
  progress: 0,
};

export default LoadingModal;
