import React from 'react';
import PropTypes from 'prop-types';
import StatusBadge from '../StatusBadge';
import {
  objectDateStateCaption,
  objectDateStateIcon,
  objectDateStateType,
} from '../../Library/StateTypes';

/**
 * ObjectDateStatusBadge()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ObjectDateStatusBadge(props) {
  const {
    objectDateState, containerStyle, visible, alignContainer, iconOnly, useMediaQuery,
  } = props;

  const getIcon = () => objectDateStateIcon[objectDateState];
  const getCaption = () => ((!iconOnly) ? objectDateStateCaption[objectDateState] : null);
  const getType = () => objectDateStateType[objectDateState];

  return (
    <StatusBadge
      type={getType()}
      icon={getIcon()}
      visible={visible}
      useMediaQuery={useMediaQuery}
      containerStyle={containerStyle}
      iconOnly={iconOnly}
      alignContainer={alignContainer}
    >
      {getCaption()}
    </StatusBadge>
  );
}

ObjectDateStatusBadge.propTypes = {
  objectDateState: PropTypes.number,
  containerStyle: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  alignContainer: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  iconOnly: PropTypes.bool,
  useMediaQuery: PropTypes.bool,
};

ObjectDateStatusBadge.defaultProps = {
  objectDateState: null,
  containerStyle: {},
  visible: true,
  alignContainer: 'flex-start',
  iconOnly: false,
  useMediaQuery: false,
};
