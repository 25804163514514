import React from 'react';
import {
  Route, withRouter, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import RightNavigationLayout from '../../Components/Layout/RightNavigationLayout';
import OrderDetailsNavigation from '../../Components/Navigation/System/OrderDetailsNavigation';
import Details from '../../Pages/System/Order/Details';
import Offers from '../../Pages/System/Order/Offers';

/**
 * SystemOrderDetailsRoutes()
 * @returns {*}
 * @constructor
 */
function SystemOrderDetailsRoutes() {
  const { path, url } = useRouteMatch();

  return (
    <RightNavigationLayout navigation={<OrderDetailsNavigation />}>
      <Switch>
        <Route exact path={path}><Details /></Route>

        <Route exact path={`${path}/offers`}><Offers /></Route>
        <Route><Redirect to={url} /></Route>
      </Switch>
    </RightNavigationLayout>
  );
}

export default connect(() => ({}), () => ({}))(withRouter(SystemOrderDetailsRoutes));
