import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import { formatDateTime } from '../../../../Library/Functions';
import Panel from '../../../../Components/Panel';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';

/**
 * Overview()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function Information(props) {
  const { report } = props;

  const renderDuration = () => {
    if (report.finished) {
      const start = moment(report.startDateTime);
      const end = moment(report.endDateTime);
      const duration = moment.duration(end.diff(start));
      const minutes = duration.asMinutes().toFixed(0);
      return (
        <KeyValueEditRow title="Demontagedauer" type="text" displayText={`${minutes} Minuten`} />
      );
    }
    return (
      <KeyValueEditRow title="Demontagedauer" type="text" displayText="Bericht in Bearbeitung..." />
    );
  };

  const renderMontageEnd = () => {
    if (report.finished) {
      return (
        <KeyValueEditRow title="Demontageende" type="text" displayText={formatDateTime(report.endDateTime)} />
      );
    }
    return (
      <KeyValueEditRow title="Demontageende" type="text" displayText="Bericht in Bearbeitung..." />
    );
  };

  if (report) {
    return (
      <Panel marginBottom={25}>
        <h6>Demontageinformationen</h6>
        <p>
          Hier sehen Sie wie lange die Demontage gedauert hat und ob weiter Informationen hinterlegt wurden.
        </p>

        <KeyValueEditRow title="Demontagebeginn" type="text" displayText={formatDateTime(report.startDateTime)} />
        {renderMontageEnd()}
        {renderDuration()}
        <KeyValueEditRow
          title="Weitere Informationen"
          type="text"
          displayText={report.comment}
          visible={(report.comment && report.comment.length > 0)}
        />
        <KeyValueEditRow
          title="Gemessene Länge der Küche bei Demontage"
          type="text"
          displayText={`${report.runningMeter}m`}
          visible={(report.runningMeter)}
        />
        <KeyValueEditRow
          title="Anzahl ausgebauter Elektrogeräte"
          type="text"
          displayText={report.electricDeviceCount}
          visible={(report.electricDeviceCount)}
        />
      </Panel>
    );
  }
  return null;
}

Information.propTypes = {
  report: PropTypes.instanceOf(Object),
};

Information.defaultProps = {
  report: null,
};
