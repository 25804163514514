import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../ModalDialog';

/**
 * ConfirmDeleteDialog()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ConfirmDeleteDialog(props) {
  const {
    visible, onClose, children, onConfirm, item, headline,
  } = props;

  return (
    <ModalDialog
      visible={visible}
      onClose={onClose}
      centered
      confirmCaption="Löschen"
      onConfirm={() => onConfirm(item)}
    >
      <h5>{headline}</h5>
      <p style={{ marginTop: 20 }}>{children}</p>
    </ModalDialog>
  );
}

ConfirmDeleteDialog.propTypes = {
  children: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  headline: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  item: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

ConfirmDeleteDialog.defaultProps = {
  visible: false,
  item: null,
};
