import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../../../../Components/Panel';
import {
  DEVICETYPE_DAMAGE, DEVICETYPE_PREDAMAGE,
} from '../../../../Library/Types';
import CheckBoxRow from '../../../../Components/ReportComponents/CheckBoxRow';

/**
 * Overview()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function Overview(props) {
  const { report } = props;
  const [preDamages, setPreDamages] = useState([]);
  const [damages, setDamages] = useState([]);

  useEffect(() => {
    if (report) {
      setPreDamages(report.devices.filter((item) => item.type === DEVICETYPE_PREDAMAGE));
      setDamages(report.devices.filter((item) => item.type === DEVICETYPE_DAMAGE));
    }
  }, [report]);

  if (report && report.finished) {
    return (
      <Panel marginBottom={25}>
        <h6>Übersicht</h6>
        <p style={{ marginBottom: 30 }}>
          Hier sehen Sie eine Zusammenfassung des Lieferberichts!
        </p>
        <table className="table-borderless">
          <tbody>
            <CheckBoxRow visible={preDamages.length > 0} type="warning">
              <strong>Es wurden Vorschäden dokumentiert</strong>
            </CheckBoxRow>
            <CheckBoxRow visible={damages.length === 0}>
              Es wurde kein Kundeneigentum beschädigt
            </CheckBoxRow>
            <CheckBoxRow visible={damages.length > 0} type="danger">
              <strong>Es wurde Kundeneigentum beschädigt</strong>
            </CheckBoxRow>

            <CheckBoxRow visible>
              Die einwandfreie Ausführung der Leistung wird hiermit bescheinigt.
            </CheckBoxRow>
          </tbody>
        </table>
      </Panel>
    );
  }
  return null;
}

Overview.propTypes = {
  report: PropTypes.instanceOf(Object),
};

Overview.defaultProps = {
  report: null,
};
