import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Button from '../../../../Components/Button';

import './style.css';

/**
 * MonteurRegisterRow()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function MonteurRegisterRow(props) {
  const { style } = props;

  return (
    <div className="MonteurRegisterRow FullPageBox Dark" style={{ padding: '40px 0', ...style }}>
      <div className="container d-flex">
        <div className="flex-grow-1">
          <div className="Bold">Jetzt Kostenlos registrieren</div>
          <div>Erhalten Sie kostenlos Küchenmontageaufträge in Ihrer Nähe</div>
        </div>
        <div className="Button d-flex justify-content-center align-items-center align-content-center ">
          <NavLink to="/register">
            <Button onClick={() => {}}>
              Kostenlos registrieren
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

MonteurRegisterRow.propTypes = {
  style: PropTypes.instanceOf(Object),
};

MonteurRegisterRow.defaultProps = {
  style: {},
};
