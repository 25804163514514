import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../Input';
import CustomReactSelect from '../../CustomReactSelect';
import { floorOption, parkingOption, salutationOption } from '../../../Library/Options/AdressOptions';
import Checkbox from '../../Checkbox';

export const initialCustomer = {
  salutation: null,
  firstname: null,
  lastname: null,
  street: null,
  streetNo: null,
  zip: null,
  city: null,
  mail: null,
  phone: null,
  mobile: null,
  floor: null,
  parking: 0,
  comment: null,

  hasInvoiceAddress: 0,
  invoiceFirstname: null,
  invoiceLastname: null,
  invoiceZip: null,
  invoiceCity: null,
  invoiceStreet: null,
  invoiceStreetNo: null,
};

export default function EditCustomer(props) {
  const {
    state, onChange, headline, errors,
  } = props;

  const updateValue = (update) => {
    if (onChange) {
      onChange({
        ...state,
        ...update,
      });
    }
  };

  const renderInvoiceAddress = () => ((!state.hasInvoiceAddress) ? null : (
    <>
      <div className="col-lg-12" style={{ marginTop: 30 }}>
        <h6>Rechnungsadresse</h6>
        <p>
          Bitte geben Sie hier die abweichende Rechnungsadresse ein.
        </p>

        <div className="row">
          <div className="col-lg-6">
            <Input
              value={state.invoiceFirstname}
              onChange={(invoiceFirstname) => updateValue({ invoiceFirstname })}
              placeholder="Vorname"
              hasError={errors.invoiceFirstname}
            />
          </div>
          <div className="col-lg-6">
            <Input
              value={state.invoiceLastname}
              onChange={(invoiceLastname) => updateValue({ invoiceLastname })}
              placeholder="Nachname"
              hasError={errors.invoiceLastname}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <Input
              value={state.invoiceStreet}
              onChange={(invoiceStreet) => updateValue({ invoiceStreet })}
              placeholder="Strasse"
              hasError={errors.invoiceStreet}
            />
          </div>
          <div className="col-lg-4">
            <Input
              value={state.invoiceStreetNo}
              onChange={(invoiceStreetNo) => updateValue({ invoiceStreetNo })}
              placeholder="Hausnummer"
              hasError={errors.invoiceStreetNo}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <Input
              value={state.invoiceZip}
              onChange={(invoiceZip) => updateValue({ invoiceZip })}
              placeholder="Postleitzahl"
              hasError={errors.invoiceZip}
              errorMessage={errors.invoiceZipMessage}
            />
          </div>
          <div className="col-lg-8">
            <Input
              value={state.invoiceCity}
              onChange={(invoiceCity) => updateValue({ invoiceCity })}
              placeholder="Ort"
              hasError={errors.invoiceCity}
            />
          </div>
        </div>
      </div>
    </>
  ));

  return (
    <form>
      <div className="row">
        <div className="col-lg-12">
          <h5>{headline}</h5>
          <p className="text-muted">
            Bitte geben Sie die Adresse an, wo die Küche montiert werden soll.
          </p>

          <div className="row">
            <CustomReactSelect
              options={salutationOption}
              value={state.salutation}
              onChange={(salutation) => updateValue({ salutation })}
              rowClass="col-lg-2"
              hasError={errors.salutation}
            />
            <div className="col-lg-5">
              <Input
                value={state.firstname}
                onChange={(firstname) => updateValue({ firstname })}
                placeholder="Vorname"
                hasError={errors.firstname}
              />
            </div>
            <div className="col-lg-5">
              <Input
                value={state.lastname}
                onChange={(lastname) => updateValue({ lastname })}
                placeholder="Nachname"
                hasError={errors.lastname}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <Input
                value={state.street}
                onChange={(street) => updateValue({ street })}
                placeholder="Strasse"
                hasError={errors.street}
              />
            </div>
            <div className="col-lg-4">
              <Input
                value={state.streetNo}
                onChange={(streetNo) => updateValue({ streetNo })}
                placeholder="Hausnummer"
                hasError={errors.streetNo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <Input
                value={state.zip}
                onChange={(zip) => updateValue({ zip })}
                placeholder="Postleitzahl"
                hasError={errors.zip}
                errorMessage={errors.zipMessage}
              />
            </div>
            <div className="col-lg-8">
              <Input
                value={state.city}
                onChange={(city) => updateValue({ city })}
                placeholder="Ort"
                hasError={errors.city}
              />
            </div>
          </div>

          <div className="row">
            <Input
              value={state.mail}
              onChange={(mail) => updateValue({ mail })}
              rowClass="col-lg-12"
              placeholder="E-Mail Adresse"
              hasError={errors.mail}
              errorMessage={errors.mailMessage}
            />
          </div>

          <div className="row">
            <Input
              value={state.phone}
              onChange={(phone) => updateValue({ phone })}
              rowClass="col-lg-6"
              placeholder="Telefon (Festnetz)"
              hasError={errors.phone}
              errorMessage={errors.phoneMessage}
            />
            <Input
              value={state.mobile}
              onChange={(mobile) => updateValue({ mobile })}
              rowClass="col-lg-6"
              placeholder="Telefon (Mobil)"
              hasError={errors.mobile}
              errorMessage={errors.mobileMessage}
            />
          </div>

          <div className="row">
            <CustomReactSelect
              options={floorOption}
              value={state.floor}
              onChange={(floor) => updateValue({ floor })}
              rowClass="col-lg-4"
              hasError={errors.floor}
            />
            <CustomReactSelect
              options={parkingOption}
              value={state.parking}
              onChange={(parking) => updateValue({ parking })}
              rowClass="col-lg-8"
            />
            <Input
              value={state.comment}
              onChange={(comment) => updateValue({ comment })}
              rowClass="col-lg-12"
              placeholder="Platz für Notizen"
            />
          </div>
        </div>

        <div className="col-lg-12">
          <Checkbox
            value={state.hasInvoiceAddress}
            onChange={(hasInvoiceAddress) => updateValue({ hasInvoiceAddress })}
            label="Abweichende Rechnungsadresse angeben"
          />
        </div>

        {renderInvoiceAddress()}
      </div>
    </form>
  );
}

EditCustomer.propTypes = {
  state: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  headline: PropTypes.string,
  errors: PropTypes.instanceOf(Object),
};

EditCustomer.defaultProps = {
  state: initialCustomer,
  onChange: undefined,
  headline: 'Kundenadresse',
  errors: {},
};
