import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../../Components/ModalDialog';

/**
 * ConfirmDeleteDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ConfirmDeleteDialog(props) {
  const { onClose, onConfirm, visible } = props;

  return (
    <ModalDialog
      onClose={onClose}
      visible={visible}
      onConfirm={onConfirm}
      confirmClass="btn-primary"
      confirmCaption="Auftrag unwiderruflich löschen"
    >
      <h5>Auftrag unwiderruflich löschen?</h5>
      <p>
        Bitte bestätigen Sie das Sie den Auftrag endgültig löschen möchten. Alle erfassten Daten werden
        unwiderruflich gelöscht und können nicht wiederhergestellt werden!
        <br />
        <br />
        <strong>
          Dieser Vorgang kann nicht rückgängig gemacht werden!
        </strong>
      </p>
    </ModalDialog>
  );
}

ConfirmDeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

ConfirmDeleteDialog.defaultProps = {
  visible: false,
};
