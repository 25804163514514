import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware';
import throttle from 'lodash/throttle';
import { createLogger } from 'redux-logger';
import { loadState, saveState } from './LocalStorage';
import reducers from './StoreCombinedReducers';
import Config from '../Assets/Config';

let middleware = null;

/**
 * Add logger only on dev or staging environment.
 * On production environment no console output should be generated
 */
if (Config.env !== 'production') {
  const logger = createLogger({});
  middleware = applyMiddleware(createPromise(), thunk, logger);
} else {
  middleware = applyMiddleware(createPromise(), thunk);
}

const persistState = loadState();
const store = createStore(reducers, persistState, middleware);

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));

export default store;
