import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

/**
 * InfoBox()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function InfoBox(props) {
  const {
    children, icon, headline, style, iconColor, iconBgColor, withBox,
  } = props;

  const renderIcon = () => {
    if (icon) {
      return (
        <div className="Icon" style={{ backgroundColor: iconBgColor }}>
          <i className={icon} style={{ color: iconColor }} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={`InfoBox ${(withBox) ? 'Withbox' : ''}`} style={style}>
      {renderIcon()}
      <h2>{headline}</h2>
      <p>{children}</p>
    </div>
  );
}

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  headline: PropTypes.string.isRequired,
  style: PropTypes.instanceOf(Object),
  iconColor: PropTypes.string,
  iconBgColor: PropTypes.string,
  withBox: PropTypes.bool,
};

InfoBox.defaultProps = {
  style: {},
  iconColor: '#000',
  icon: null,
  iconBgColor: null,
  withBox: false,
};
