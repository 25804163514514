/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, no-script-url */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  NavLink, useParams, useRouteMatch, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import { getList as getServiceReport } from '../../../Redux/Action/ServiceReportAction';
import { getList as getDemontageReport } from '../../../Redux/Action/DemontageReportAction';
import { getList as getDeliveryReport } from '../../../Redux/Action/DeliveryReportAction';
import { getList as getMessages } from '../../../Redux/Action/ObjectMessagesAction';
import { getList as getServices } from '../../../Redux/Action/Order/Object/ServiceAction';
import { getList as getTasks } from '../../../Redux/Action/User/TaskAction';
import { get as getOrder } from '../../../Redux/Action/OrderAction';
import { getList as getReclamations } from '../../../Redux/Action/ReclamationAction';
import {
  addDocument,
  getAllDocuments,
  getSepaMandate,
  useDispatchDebitPaidObjectServicesCount,
} from '../../../Redux/Action/ObjectAction';
import Panel from '../../Panel';
import StatusBadge from '../../StatusBadge';
import LoadingModal from '../../LoadingModal';
import CombinedOrderBadge from '../../OrderComponents/CombinedOrderBadge';
import ConfirmObjectSplitDialog from '../../Dialogs/ConfirmObjectSplitDialog';
import ConfirmObjectUnsplitDialog from '../../Dialogs/ConfirmObjectUnsplitDialog';
import {
  DOCUMENTTYPE_DELIVERYNOTE,
  NAVIGATION_ORDER_DETAILS,
  ORDERTYPE_OBJECT,
  PAYMENTTYPE_DIRECT_DEBIT,
} from '../../../Library/Types';

import './index.css';
import ServiceMenu from './ServiceMenu';
import ReclamationMenu from './ReclamationMenu';
import { getList as getPartners } from '../../../Redux/Action/MandantAction';
import MenuEntryWithPermission from '../MenuEntryWithPermission';
import { OBJECT_DATESTATE_FINISHED, OBJECT_DATESTATE_CLOSED } from '../../../Library/StateTypes';
import { canObjectBeSplitted, canObjectBeUnsplitted, hasObjectAccess } from '../../../Library/Functions';

/**
 * ObjectDetailsRightNavigation()
 * @returns {*|null}
 * @constructor
 */
function ObjectDetailsRightNavigation(props) {
  const {
    getReports, dispatchGetReclamations, reclamations, dispatchDownloadSepaMandate, history, user,
    dispatchGetOrder, order, client, dispatchUploadDeliveryNote, dispatchGetMessages, messages, tasks,
    dispatchGetDemontageReport, dispatchGetDeliveryReport, serviceReport, demontageReport, deliveryReport,
    dispatchGetAllDocuments, services, dispatchGetServices, dispatchGetPartners, dispatchGetTasks,
  } = props;

  const { orderId, objectId } = useParams();
  const [object, setObject] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingSepa, setIsLoadingSepa] = useState(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showSplitDialog, setShowSplitDialog] = React.useState(false);
  const [showUnsplitDialog, setShowUnsSplitDialog] = React.useState(false);
  const [hasDebitPaidServices, setHasDebitPaidServices] = React.useState(false);

  const fileInputRef = useRef();
  const { url } = useRouteMatch();

  const dispatchDebitPaidObjectServicesCount = useDispatchDebitPaidObjectServicesCount();

  React.useEffect(() => {
    setLoading(true);
    if (orderId) {
      dispatchGetOrder(orderId).then(() => setLoading(false)).catch(() => history.push('/dashboard'));
      getReports(orderId, objectId).catch(() => {});
      dispatchGetReclamations(orderId, objectId).catch(() => {});
      dispatchGetDemontageReport(orderId, objectId).catch(() => {});
      dispatchGetDeliveryReport(orderId, objectId).catch(() => {});
      dispatchGetTasks(user).catch(() => {});
    }
  }, [
    getReports,
    orderId,
    dispatchGetReclamations,
    dispatchGetOrder,
    dispatchGetMessages,
    objectId,
    dispatchGetDemontageReport,
    dispatchGetDeliveryReport,
    dispatchGetTasks,
    user,
    history,
  ]);

  React.useEffect(() => {
    dispatchGetPartners(client);
  }, [client, dispatchGetPartners]);

  React.useEffect(() => {
    if (object) {
      dispatchGetMessages(object).catch(() => {});
      dispatchGetServices(object).catch(() => {});
    }
  }, [object, dispatchGetMessages, dispatchGetServices]);

  React.useEffect(() => {
    if (order) {
      const filtered = order.objects.filter((item) => item.objectId === parseInt(objectId, 0))[0];
      setObject(filtered);
    }
  }, [order, objectId, deliveryReport, demontageReport, serviceReport]);

  React.useEffect(() => {
    if (object && object.objectId) {
      dispatchDebitPaidObjectServicesCount(object).then((response) => {
        setHasDebitPaidServices((response > 0));
      });
    }
  }, [object, dispatchDebitPaidObjectServicesCount]);

  const renderServiceReport = () => {
    if (serviceReport) {
      return (
        <li key="serviceReport">
          <NavLink
            className="nav-link"
            to={`${url}/servicereport/${serviceReport.serviceReportId}`}
          >
            <i className="fas fa-file-pdf" />
            Montagebericht
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderDeliveryReport = () => {
    if (deliveryReport) {
      return (
        <li key="deliveryReport">
          <NavLink
            className="nav-link"
            to={`${url}/deliveryreport/${deliveryReport.deliveryReportId}`}
          >
            <i className="fas fa-file-pdf" />
            Lieferbericht
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderDemontageReport = () => {
    if (demontageReport) {
      return (
        <li key="demontageReport">
          <NavLink
            className="nav-link"
            to={`${url}/demontagereport/${demontageReport.demontageReportId}`}
          >
            <i className="fas fa-file-pdf" />
            Demontagebericht
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const downloadSepaMandate = () => {
    setIsLoadingSepa(true);
    dispatchDownloadSepaMandate(object).then((data) => {
      FileSaver.saveAs(data, `SEPA-Lastschriftmandat_${object.externalId}.pdf`);
      setIsLoadingSepa(false);
    }).catch(() => {
      setIsLoadingSepa(false);
    });
  };

  const renderSepaMandate = () => {
    if (object && order
      && (
        (!order.mandantId && order.clientId === client.clientId)
        || (order.mandantId && order.mandantId === client.clientId)
      )
      && (
        (serviceReport && serviceReport.finished && parseFloat(serviceReport.customerPaidValue) > 0
          && serviceReport.paymentType === PAYMENTTYPE_DIRECT_DEBIT)
        || (deliveryReport && deliveryReport.finished && deliveryReport.paymentType === PAYMENTTYPE_DIRECT_DEBIT
          && parseFloat(deliveryReport.customerPaidValue) > 0)
        || hasDebitPaidServices
      )
    ) {
      return (
        <li key="sepaMandate">
          <a
            className="nav-link"
            href="javascript://"
            onClick={() => downloadSepaMandate()}
          >
            <i className="fas fa-file-download" />
            SEPA-Lastschriftmandat
          </a>
        </li>
      );
    }
    return null;
  };

  const downloadAllDocuments = () => {
    setIsLoadingDocuments(true);
    dispatchGetAllDocuments(object).then((data) => {
      FileSaver.saveAs(data, `AlleDokumenteUndBerichte_${object.externalId}.pdf`);
      setIsLoadingDocuments(false);
    }).catch(() => {
      setIsLoadingDocuments(false);
    });
  };

  const renderGetAllDocuments = () => {
    if (object && order && object.montageDate
      && (object.montageDate.state === OBJECT_DATESTATE_FINISHED
        || object.montageDate.state === OBJECT_DATESTATE_CLOSED)) {
      return (
        <li key="allDocuments">
          <a
            className="nav-link"
            href="javascript://"
            onClick={() => downloadAllDocuments()}
          >
            <i className="fas fa-cloud-download-alt" />
            Alle Dokumente als PDF
          </a>
        </li>
      );
    }
    return null;
  };

  const renderMessages = () => {
    if (order && order.orderId && object && object.objectId && client && client.clientId) {
      if (!hasObjectAccess(order, object, client)) {
        return null;
      }
      let name = 'Mitteilungen';
      if (messages && messages.length > 0) {
        if (messages.length === 1) {
          name = '1 Mitteilung';
        } else {
          name = `${messages.length} Mitteilungen`;
        }
      }
      return (
        <li>
          <NavLink className="nav-link" to={`${url}/messages`}>
            <i className="fas fa-comments" />
            {name}
          </NavLink>
        </li>
      );
    } return null;
  };

  const renderNotes = () => {
    let name = 'Interne Notizen';
    if (object && object.notes && object.notes.length > 0) {
      if (object.notes.length === 1) {
        name = '1 interne Notiz';
      } else {
        name = `${object.notes.length} interne Notizen`;
      }
    }

    return (
      <MenuEntryWithPermission
        title={name}
        icon="fas fa-clipboard"
        to={`${url}/notes`}
        allowedGroups={['ADM', 'VER', 'REG', 'DIS', 'VEK']}
      />
    );
  };

  const handleOnUpload = (e) => {
    setIsUploading(true);
    dispatchUploadDeliveryNote(object, e.target.files[0]).then(() => {
      setTimeout(() => {
        setIsUploading(false);
      }, 1000);
    }).catch(() => setIsUploading(false));
  };

  const renderUploadDeliveryNote = () => {
    if (
      order && order.optionStorage
      && object
      && (
        (object.montageDate && object.montageDate.clientId === client.clientId)
        || (object.storageDate && object.storageDate.clientId === client.clientId)
      )
    ) {
      return (
        <li>
          <a
            style={{ cursor: 'pointer' }}
            className="nav-link"
            onClick={() => fileInputRef.current.click()}
            tabIndex={-1}
            onKeyPress={() => {}}
          >
            <i className="fas fa-truck-loading" />
            Lieferschein hinzufügen
          </a>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={(e) => handleOnUpload(e)}
          />
        </li>
      );
    }
    return null;
  };

  const renderStatusIcon = () => {
    if (order.mandantId) {
      return (
        <StatusBadge
          caption="Partnermontage"
          type="Primary"
          containerStyle={{ marginBottom: 10 }}
          icon="fas fa-user-check"
        />
      );
    }

    if (order.internal) {
      return (
        <StatusBadge
          caption="Montage: Intern"
          type="Default"
          containerStyle={{ marginBottom: 10 }}
          icon="fas fa-home"
        />
      );
    }
    return (
      <StatusBadge
        caption="Montage: Extern"
        type="Default"
        containerStyle={{ marginBottom: 10 }}
        icon="fas fa-sign-out-alt"
      />
    );
  };

  const renderSplitButton = () => {
    if (canObjectBeSplitted(order, object, client.clientId)) {
      return (
        <MenuEntryWithPermission
          title="Auftragsoptionen teilen"
          icon="fas fa-boxes"
          allowedGroups={['ADM', 'DIS']}
          onClick={() => setShowSplitDialog(true)}
        />
      );
    }

    if (canObjectBeUnsplitted(order, object, client.clientId)) {
      return (
        <MenuEntryWithPermission
          title="Auftragsteilung beenden"
          icon="fas fa-box"
          allowedGroups={['ADM', 'DIS']}
          onClick={() => setShowUnsSplitDialog(true)}
        />
      );
    }

    return null;
  };

  const renderBackButton = () => {
    if (order.type === ORDERTYPE_OBJECT) {
      const urlParam = NAVIGATION_ORDER_DETAILS.replace('{orderId}', orderId);

      return (
        <>
          <hr />
          <li>
            <a
              style={{ cursor: 'pointer' }}
              className="nav-link"
              onClick={() => history.push(urlParam)}
              tabIndex={-1}
              onKeyPress={() => {}}
            >
              <i className="fas fa-angle-double-left" />
              Zurück zur Übersicht
            </a>
          </li>
        </>
      );
    }
    return null;
  };

  const renderReportNavigation = () => {
    if (demontageReport || deliveryReport || serviceReport || hasDebitPaidServices) {
      return (
        <>
          <hr />
          {renderDemontageReport()}
          {renderDeliveryReport()}
          {renderServiceReport()}
          {renderSepaMandate()}
          {renderGetAllDocuments()}
        </>
      );
    }
    return null;
  };

  const renderTasks = () => {
    let caption = 'Aufgaben';
    if (tasks && tasks.length > 0) {
      const count = tasks.filter((item) => (item.objectId === parseInt(objectId, 0) && !item.finished));
      if (count.length === 1) {
        caption = '1 Aufgabe';
      } else if (count.length > 1) {
        caption = `${count.length} Aufgaben`;
      }
    }

    return (
      <li>
        <NavLink className="nav-link" to={`${url}/task`}>
          <i className="fas fa-tasks" />
          {caption}
        </NavLink>
      </li>
    );
  };

  const renderObjectStatusBadge = () => {
    if (object && order) {
      if (object.splitted) {
        return <StatusBadge caption="Auftragsoptionen geteilt" icon="fas fa-boxes" type="Danger" />;
      }
      return (
        <CombinedOrderBadge
          orderState={order.state}
          objectDateState={object.montageDate.state}
          isInternal={order.internal}
        />
      );
    }
    return null;
  };

  if (order) {
    return (
      <>
        <Panel boxPadding={10} marginBottom={20}>
          <div className="Navigation">
            <div className="Header">
              <h6 className="Headline">
                Auftragsdetails
              </h6>

              {renderStatusIcon()}
              {renderObjectStatusBadge()}
            </div>

            <hr />

            <div className="NavContainer">
              <ul>
                <li>
                  <NavLink className="nav-link" to={`${url}/details`}>
                    <i className="fas fa-info-circle" />
                    Details
                  </NavLink>
                </li>
                {renderTasks()}

                {renderUploadDeliveryNote()}
                {renderNotes()}
                {renderMessages()}
                {renderSplitButton()}
                {renderReportNavigation()}

                <ReclamationMenu
                  order={order}
                  reclamations={reclamations}
                />
                <ServiceMenu
                  client={client}
                  order={order}
                  object={object}
                  services={services}
                />

                {renderBackButton()}
              </ul>
            </div>
          </div>

          <LoadingModal visible={isUploading} text="Lieferschein wird hochgeladen..." />
          <LoadingModal
            visible={isLoadingSepa}
            text="Der Download startet automatisch..."
            headline="SEPA-Lastschriftmandat wird erstellt"
          />
          <LoadingModal
            visible={isLoadingDocuments}
            text="Dieser Vorgang kann einige Minuten dauern. Der Download startet automatisch..."
            headline="Dokumente & Berichte werden erstellt und zusammengeführt!"
          />
          <LoadingModal visible={loading} text="Auftrag wird geladen..." />

          <ConfirmObjectSplitDialog
            object={object}
            visible={showSplitDialog}
            onClose={() => setShowSplitDialog(false)}
          />
          <ConfirmObjectUnsplitDialog
            object={object}
            visible={showUnsplitDialog}
            onClose={() => setShowUnsSplitDialog(false)}
          />

        </Panel>
      </>
    );
  }
  return null;
}

ObjectDetailsRightNavigation.propTypes = {
  serviceReport: PropTypes.instanceOf(Object),
  demontageReport: PropTypes.instanceOf(Object),
  deliveryReport: PropTypes.instanceOf(Object),
  getReports: PropTypes.func,
  dispatchGetMessages: PropTypes.func.isRequired,
  dispatchGetDeliveryReport: PropTypes.func.isRequired,
  dispatchGetDemontageReport: PropTypes.func.isRequired,
  dispatchGetReclamations: PropTypes.func.isRequired,
  dispatchDownloadSepaMandate: PropTypes.func.isRequired,
  dispatchGetAllDocuments: PropTypes.func.isRequired,
  dispatchGetServices: PropTypes.func.isRequired,
  dispatchGetPartners: PropTypes.func.isRequired,
  dispatchGetTasks: PropTypes.func.isRequired,
  reclamations: PropTypes.instanceOf(Array),
  services: PropTypes.instanceOf(Array),
  dispatchGetOrder: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object),
  dispatchUploadDeliveryNote: PropTypes.func.isRequired,
  messages: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  tasks: PropTypes.instanceOf(Array),
};

ObjectDetailsRightNavigation.defaultProps = {
  serviceReport: null,
  demontageReport: null,
  deliveryReport: null,
  getReports: () => {},
  reclamations: null,
  services: null,
  order: null,
  client: null,
  messages: null,
  tasks: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    serviceReport: store.reports.serviceReport,
    demontageReport: store.reports.demontageReport,
    deliveryReport: store.reports.deliveryReport,
    reclamations: store.reclamation.reclamations,
    services: store.objectService.services,
    order: store.order.order,
    user: store.auth.user,
    tasks: store.auth.tasks,
    messages: store.objectMessage.messages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReports: (orderId, objectId) => dispatch(getServiceReport(orderId, objectId)),
    dispatchGetDemontageReport: (orderId, objectId) => dispatch(getDemontageReport(orderId, objectId)),
    dispatchGetDeliveryReport: (orderId, objectId) => dispatch(getDeliveryReport(orderId, objectId)),
    dispatchGetReclamations: (orderId, objectId) => dispatch(getReclamations(orderId, objectId)),
    dispatchGetOrder: (orderId) => dispatch(getOrder(orderId, true)),
    dispatchUploadDeliveryNote: (object, file) => dispatch(addDocument(object, file, DOCUMENTTYPE_DELIVERYNOTE)),
    dispatchDownloadSepaMandate: (object) => dispatch(getSepaMandate(object)),
    dispatchGetMessages: (orderId) => dispatch(getMessages(orderId)),
    dispatchGetAllDocuments: (object) => dispatch(getAllDocuments(object)),
    dispatchGetServices: (object) => dispatch(getServices(object)),
    dispatchGetPartners: (client) => dispatch(getPartners(client)),
    dispatchGetTasks: (user) => dispatch(getTasks(user)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ObjectDetailsRightNavigation));
