import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Button from '../../../Components/Button';
import ConfirmDeleteDialog from '../../../Components/Dialogs/ConfirmDeleteDialog';

/**
 * MaterialList()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MaterialList(props) {
  const { material, onDelete, onEdit } = props;
  const [showAskDelete, setShowAskDelete] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const askDelete = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    setShowAskDelete(true);
  };

  const renderActive = (item) => {
    if (item.active) {
      return <i className="fas fa-check-circle text-success" />;
    }
    return <i className="fas fa-times-circle text-danger" />;
  };

  const renderRow = (item) => (
    <tr key={item.materialId} onClick={() => onEdit(item)}>
      <td>
        <div>{item.caption}</div>
        <div className="text-muted">
          <small>{item.description}</small>
        </div>
      </td>
      <td align="center">{renderActive(item)}</td>
      <td align="right">
        <Button onClick={(e) => askDelete(e, item)} className="btn btn-sm" type="btn-outline-danger">
          <i className="fas fa-trash" />
        </Button>
      </td>
    </tr>
  );

  const confirmDelete = (item) => {
    onDelete(item);
    setShowAskDelete(false);
  };

  return (
    <>
      <table className="table table-hover table-striped">
        <thead>
          <tr>
            <th>Bezeichnung</th>
            <th width={150} style={{ textAlign: 'center' }}>Aktiv</th>
            <th width={100} />
          </tr>
        </thead>
        <tbody>
          {material.map(renderRow)}
        </tbody>
      </table>

      <ConfirmDeleteDialog
        headline="Material löschen"
        onClose={() => setShowAskDelete(false)}
        onConfirm={confirmDelete}
        item={selectedItem}
        visible={showAskDelete}
      >
        Bitte bestätigen Sie das Sie das Material endgültig löschen möchten. Eine Wiederherstellung ist nicht
        mehr möglich. Das Material kann dann nicht mehr für Berichte verwendet werden.
      </ConfirmDeleteDialog>
    </>
  );
}

MaterialList.propTypes = {
  material: PropTypes.instanceOf(Array),
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

MaterialList.defaultProps = {
  material: null,
};
