import React from 'react';
import PropTypes from 'prop-types';
import * as Redux from 'react-redux';
import CustomReactSelect from '../../CustomReactSelect';
import Input from '../../Input';
import { useDispatchGetListClientAddress } from '../../../Redux/Action/ClientAddressAction';

export const initialLoadingAddress = {
  addressId: null,
  name: null,
  zip: null,
  city: null,
  street: null,
  streetNo: null,
  contactPhone: null,
  contactName: null,
  comment: null,
};

export default function EditLoadingAddress(props) {
  const {
    loadingAddress, onChange, headline, errors,
  } = props;

  const clientId = Redux.useSelector((store) => store.auth.client.clientId);
  const clientAddresses = Redux.useSelector((store) => store.clientAddress.addresses);
  const dispatchGetListClientAddress = useDispatchGetListClientAddress(clientId);

  React.useEffect(() => {
    dispatchGetListClientAddress();
  }, [dispatchGetListClientAddress]);

  const addressOptions = React.useMemo(
    () => (clientAddresses ? [
      { label: 'Neue Adresse eingeben', value: null },
      ...clientAddresses.map((item) => ({ label: item.name, value: item.addressId })),
    ] : [
      { label: 'Neue Adresse eingeben', value: null },
    ]),
    [clientAddresses],
  );

  const updateValue = (update) => {
    if (onChange) {
      onChange({
        ...loadingAddress,
        ...update,
      });
    }
  };

  const handleAddressChange = (addressId) => {
    const address = clientAddresses.find((item) => item.addressId === addressId);
    if (address) {
      onChange(address);
    } else {
      onChange(initialLoadingAddress);
    }
  };

  const renderAddressSelector = () => (
    (addressOptions.length > 1) ? (
      <CustomReactSelect
        options={addressOptions}
        onChange={(addressId) => handleAddressChange(addressId)}
        rowClass="col-lg-12"
        value={loadingAddress.addressId}
      />
    ) : null
  );

  return (
    <form>
      <div className="row">
        <div className="col-lg-12">
          <h5>{headline}</h5>
          <p className="text-muted">
            Bitte geben Sie an wo die Küche abgeholt werden muss
          </p>
        </div>

        {renderAddressSelector()}

        <div className="col-lg-12">
          <Input
            value={loadingAddress.name}
            onChange={(name) => updateValue({ name })}
            placeholder="Firma / Name"
            disabled={!!loadingAddress.addressId}
            hasError={!!errors.name}
            /* visible={(!selected)} */
          />
          <div className="row">
            <div className="col-lg-8">
              <Input
                value={loadingAddress.street}
                onChange={(street) => updateValue({ street })}
                placeholder="Strasse"
                disabled={!!loadingAddress.addressId}
                hasError={!!errors.street}
              />
            </div>
            <div className="col-lg-4">
              <Input
                value={loadingAddress.streetNo}
                onChange={(streetNo) => updateValue({ streetNo })}
                placeholder="Hausnummer"
                disabled={!!loadingAddress.addressId}
                hasError={!!errors.streetNo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <Input
                value={loadingAddress.zip}
                onChange={(zip) => updateValue({ zip: zip.trim() })}
                placeholder="Postleitzahl"
                disabled={!!loadingAddress.addressId}
                hasError={!!errors.zip}
                errorMessage={errors.zipMessage}
              />
            </div>
            <div className="col-lg-8">
              <Input
                value={loadingAddress.city}
                onChange={(city) => updateValue({ city })}
                placeholder="Ort"
                disabled={!!loadingAddress.addressId}
                hasError={!!errors.city}
              />
            </div>
          </div>

          <Input
            value={loadingAddress.contactName}
            onChange={(contactName) => updateValue({ contactName })}
            placeholder="Ansprechpartner"
            disabled={!!loadingAddress.addressId}
            hasError={!!errors.contactName}
          />
          <Input
            value={loadingAddress.contactPhone}
            onChange={(contactPhone) => updateValue({ contactPhone })}
            placeholder="Kontakt Telefon"
            disabled={!!loadingAddress.addressId}
            hasError={!!errors.contactPhone}
            errorMessage={errors.contactPhoneMessage}
          />
          <Input
            value={loadingAddress.comment}
            onChange={(comment) => updateValue({ comment })}
            placeholder="Platz für Notizen"
          />
        </div>
      </div>
    </form>
  );
}

EditLoadingAddress.propTypes = {
  loadingAddress: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  headline: PropTypes.string,
  errors: PropTypes.instanceOf(Object),
};

EditLoadingAddress.defaultProps = {
  loadingAddress: initialLoadingAddress,
  onChange: undefined,
  headline: 'Beladestelle',
  errors: {},
};
