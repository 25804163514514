import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../../../Components/ModalDialog';

/**
 * AskAdaptAssemblyTime()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AskAdaptAssemblyTime(props) {
  const {
    service, assemblyTime, visible, onClose, onConfirm,
  } = props;

  if (service && (service.assemblyTime <= 0 && assemblyTime < 0)) {
    return null;
  }

  const renderTimeInfo = () => {
    let newTime = service.assemblyTime + assemblyTime;
    newTime = (newTime > 0) ? newTime : 0;

    return (
      <div style={{ marginTop: 20 }}>
        Die geschätzte Arbeitszeit wird von
        <strong>{` ${service.assemblyTime} Minuten `}</strong>
        auf
        <strong>{` ${newTime} Minuten `}</strong>
        geändert!
      </div>
    );
  };

  return (
    <ModalDialog
      visible={visible}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmCaption="Zeit autom. anpassen"
      confirmClass="btn-success"
    >
      <h5>Geschätzte Arbeitszeit automatisch anpassen?</h5>
      <p>
        Sie haben eine Eintrag zum Serviceauftrag hinzugefügt und dort eine geschätzte Arbeitszeit angegeben.
        Soll die gesamte geschätzte Arbeitszeit des Serviceauftrags angepasst werden?
        {renderTimeInfo()}
      </p>
    </ModalDialog>
  );
}

AskAdaptAssemblyTime.propTypes = {
  service: PropTypes.instanceOf(Object),
  assemblyTime: PropTypes.number,
  visible: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

AskAdaptAssemblyTime.defaultProps = {
  service: null,
  assemblyTime: null,
  visible: false,
};
