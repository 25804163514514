import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getDeviceType } from '../../../../Library/Functions';
import Panel from '../../../../Components/Panel';
import AddDevice from '../AddDevice';
import TextLink from '../../../../Components/TextLink';
import ImageContainer from '../../../../Components/DeviceList/ImageContainer';
import { DEVICETYPEDEVICE_OTHERDEVICE, DEVICETYPEDEVICE_OTHERS } from '../../../../Library/Types';
import ImagePreviewOverlay from '../../../../Components/DeviceList/ImagePreviewOverlay';

import './style.css';

/**
 * Row()
 * @param props
 * @returns {*}
 * @constructor
 */
function Row(props) {
  const { item, onDelete, disabled } = props;
  const [showImagePreview, setShowImagePreview] = useState(false);

  const renderDelete = () => {
    if (!disabled) {
      return (
        <div className="Delete" onClick={() => onDelete(item)} tabIndex={-1} role="button" onKeyPress={() => {}}>
          <i className="far fa-trash-alt" style={{ marginRight: 10 }} />
          Löschen
        </div>
      );
    }
    return null;
  };

  const renderImageOverlay = () => (
    <ImagePreviewOverlay
      onClose={() => setShowImagePreview(false)}
      visible={showImagePreview}
      documents={item.documents}
    />
  );

  if (item.device === DEVICETYPEDEVICE_OTHERS || item.device === DEVICETYPEDEVICE_OTHERDEVICE) {
    return (
      <>
        <div className="Row">
          <ImageContainer documents={item.documents} onClick={() => setShowImagePreview(true)} />
          <div className="Left">
            <div className="Headline">
              {`${item.deviceDescription} ${(item.deviceNumber) ? `(${item.deviceNumber})` : ''}`}
            </div>
            <div>{item.comment}</div>
          </div>
          <div className="Right">
            {renderDelete()}
          </div>
        </div>
        {renderImageOverlay()}
      </>
    );
  }
  return (
    <>
      <div className="Row">
        <ImageContainer documents={item.documents} onClick={() => setShowImagePreview(true)} />
        <div className="Left">
          <div className="Headline">{`${getDeviceType(item.device).label} (${item.deviceNumber})`}</div>
          <div>{(item.comment) ? item.comment : <i>Keine weiteren Details vorhanden</i>}</div>
        </div>
        <div className="Right">
          {renderDelete()}
        </div>
      </div>
      {renderImageOverlay()}
    </>
  );
}

Row.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

/**
 * DeviceList()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function DeviceList(props) {
  const {
    devices, serviceReport, onDelete, onAddDevice, disabled,
  } = props;
  const [showAddDevice, setShowAddDevice] = useState(false);

  const renderDevice = (item) => (
    <Row item={item} onDelete={onDelete} key={item.internalId} disabled={disabled || devices.length === 1} />
  );

  const renderManual = (item) => (
    <Row
      item={item}
      onDelete={onDelete}
      key={item.internalId}
      disabled={disabled || devices.length === 1}
    />
  );

  const handleDeviceAdd = (device) => {
    onAddDevice(device);
    setShowAddDevice(false);
  };

  const renderAddLink = () => {
    if (!disabled) {
      return (
        <TextLink
          caption="Reklamation hinzufügen"
          onPress={() => setShowAddDevice(true)}
          icon="fas fa-plus-square"
          style={{ marginTop: 20 }}
        />
      );
    }
    return null;
  };

  return (
    <Panel marginBottom={20} className="DeviceList" shadow>
      <h6>Reklamationen</h6>
      <p>
        Fügen Sie hier so viele manuelle nachgemeldete Reklamationen hinzu, die alle zum gleichen Termin behoben
        werden sollen.
      </p>

      <div className="RowContainer">
        {devices.map((item) => ((item.serviceReportDeviceId) ? renderDevice(item) : renderManual(item)))}
      </div>

      {renderAddLink()}
      <AddDevice
        visible={showAddDevice}
        onClose={() => setShowAddDevice(false)}
        onSave={handleDeviceAdd}
        serviceReport={serviceReport}
      />
    </Panel>
  );
}

DeviceList.propTypes = {
  devices: PropTypes.instanceOf(Array).isRequired,
  serviceReport: PropTypes.instanceOf(Object),
  onDelete: PropTypes.func.isRequired,
  onAddDevice: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DeviceList.defaultProps = {
  serviceReport: null,
  disabled: false,
};
