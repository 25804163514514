import React from 'react';
import { useParams } from 'react-router';
import Lodash from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatchGetOrderPrefill } from '../../../Redux/Action/OrderAction';
import { initialOrderState } from './CreateNew';
import { initialObject } from './Components/OrderObjectPanel';
import Panel from '../../../Components/Panel';

export default function Prefilled() {
  const { guid } = useParams();

  const history = useHistory();
  const dispatchGetOrderPrefill = useDispatchGetOrderPrefill();

  React.useEffect(() => {
    dispatchGetOrderPrefill(guid).then((data) => {
      const { object, customer } = data;

      const initialOrder = {
        ...initialOrderState,
        ...Lodash.omit(data, ['customer', 'object']),
        objects: {
          ...initialObject,
          ...Lodash.omit(object, ['documents']),
          customer,
        },
      };

      history.push(
        {
          pathname: '/create',
          state: { initialOrder },
        },
      );
    });
  }, [dispatchGetOrderPrefill, history, guid]);

  return (
    <div className="row">
      <div className="col-xl-3 d-xl-block" />
      <div className="col-xl-6">
        <Panel marginBottom={25}>
          <h2>
            <i className="fas fa-spinner fa-spin hk-text-primary" style={{ marginRight: 20 }} />
            Auftrag wird geladen
          </h2>
        </Panel>
      </div>
      <div className="col-xl-3 d-xl-block" />
    </div>
  );
}

Prefilled.propTypes = {};

Prefilled.defaultProps = {};
