import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import Scheduler from '../../Components/DispoScheduler';
import { useDispatchDispositionGetList } from '../../Redux/Action/DispositionAction';
import ManualDispositionModal from '../../Components/DispoScheduler/Components/ManualDispositionModal';
import {
  DISPOSITION_TYPE_MANUAL,
  DISPOSITION_TYPE_OBJECT_DATE,
  DISPOSITION_TYPE_SERVICE, NAVIGATION_OBJECT_DETAILS,
  NAVIGATION_OBJECT_SERVICE_DETAILS,
} from '../../Library/Types';

/**
 * Disposition()
 * @returns {*|null}
 * @constructor
 */
export default function Disposition() {
  const { calendarData } = useSelector((state) => state.disposition);
  const { settings } = useSelector((state) => state.client.client);
  const dispatchGetDispositions = useDispatchDispositionGetList();
  const history = useHistory();
  const [editData, setEditData] = React.useState(null);
  const [showCreate, setShowCreate] = React.useState(false);
  const [isReloading, setIsReloading] = React.useState(false);
  const [lastLoadedStart, setLastLoadedStart] = React.useState(null);
  const [lastLoadedEnd, setLastLoadedEnd] = React.useState(null);
  const params = useParams();

  const loadData = React.useCallback((start, end) => {
    setIsReloading(true);
    dispatchGetDispositions(start, end).then(() => setIsReloading(false));
  }, [dispatchGetDispositions]);

  React.useEffect(() => {
    if (!settings || !settings.allowUseDisposition) {
      history.push('/calendar');
    }
  }, [settings, history]);

  const handleDateChange = ({ startDate, endDate }) => {
    const start = moment(startDate).startOf('month').format('YYYY-MM-DD');
    const end = moment(endDate).endOf('month').format('YYYY-MM-DD');

    if (start !== lastLoadedStart || end !== lastLoadedEnd) {
      setLastLoadedStart(start);
      setLastLoadedEnd(end);
      loadData(start, end);
    }
  };

  const handleOnDispositionCloseModal = () => {
    loadData(lastLoadedStart, lastLoadedEnd);
    setEditData(null);
    setShowCreate(false);
  };

  const handleStartEdit = (data) => {
    if (data.type === DISPOSITION_TYPE_MANUAL) {
      setEditData(data.disposition);
      setShowCreate(true);
    } else if (data.type === DISPOSITION_TYPE_SERVICE) {
      const uri = NAVIGATION_OBJECT_SERVICE_DETAILS.replace('{orderId}', data.source.orderId)
        .replace('{objectId}', data.source.objectId)
        .replace('{serviceId}', data.source.serviceId);
      history.push(uri);
    } else if (data.type === DISPOSITION_TYPE_OBJECT_DATE) {
      const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', data.source.orderId)
        .replace('{objectId}', data.source.objectId);
      history.push(uri);
    }
  };

  const getOverwriteTheme = () => ({
    workDayStart: settings.globalWorkDayStart,
    workDayEnd: settings.globalWorkDayEnd,
  });

  const handleHourClick = ({ hour, date, data }) => {
    const initialData = {
      date,
      startTime: hour,
      endTime: moment(hour, 'HH:mm').add(1, 'hour').format('HH:mm'),
      userId: (data.isUser) ? data.id : null,
      teamId: (data.isTeam) ? data.id : null,
    };
    setEditData(initialData);
    setShowCreate(true);
  };

  const handleCloseManualModal = () => {
    setEditData(null);
    setShowCreate(false);
  };

  return (
    <>
      <Scheduler
        calendarData={calendarData}
        themeOverwrite={getOverwriteTheme()}
        onClickAppointment={handleStartEdit}
        onClickCreate={() => setShowCreate(true)}
        onClickHour={handleHourClick}
        initialDate={params.date || null}
        onDateChange={handleDateChange}
        isReloading={isReloading}
        onRefresh={() => loadData()}
      />

      <ManualDispositionModal
        onClose={handleCloseManualModal}
        onCreated={handleOnDispositionCloseModal}
        onDeleted={handleOnDispositionCloseModal}
        visible={showCreate}
        initialData={editData}
      />
    </>
  );
}
