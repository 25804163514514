import { isValidFloatNumberFormat } from '../../../../Library/Functions';

export const validationFields = {
  type: false,
  option: false,
  objects: false,
};

/**
 * orderFormHasError()
 * @param data
 * @param orderType
 * @returns {{balance, countertopType, price, externalId, workOption, runningMeter}|boolean}
 */
export default function orderFormHasError(data, orderType) {
  let errorCodes = { ...validationFields };
  let hasError = false;

  if (!data.optionDelivery && !data.optionMontage && !data.optionStorage && !data.optionRemoval) {
    errorCodes = { ...errorCodes, option: true };
    hasError = true;
  }

  if (data.optionRemoval) {
    if (!data.removalMeter || !isValidFloatNumberFormat(data.removalMeter)) {
      errorCodes = {
        ...errorCodes,
        removalMeter: true,
        removalMeterMessage: 'Bitte geben Sie eine gültige Länge ein!',
      };
      hasError = true;
    }
  }
  if (!data.type) {
    errorCodes = { ...errorCodes, type: true };
    hasError = true;
  }
  if (orderType === 'OBJECT') {
    if (data.objects.length <= 0) {
      errorCodes = { ...errorCodes, objects: true };
      hasError = true;
    }
  }

  if (hasError) {
    return errorCodes;
  }
  return false;
}
