/**
 * This class is to store some reducer states to localStorage and to restore reducer state from localStorage
 * @author TRutte <thorsten.rutte@corodo.de>
 * @version 1.0
 */
import { clientDefaultValues } from './Reducer/ClientReducer';

export const localStorageStoreName = '@savedStoreData';

/**
 * Save auth state to localStorage
 * @param state
 */
export function saveState(state) {
  try {
    const serialized = JSON.stringify({
      auth: state.auth,
      client: {
        ...clientDefaultValues,
        client: state.client.client,
        partners: state.client.partners,
      },
    });
    localStorage.setItem(localStorageStoreName, serialized);
  } catch (e) {
    // Ignore write errors
  }
}

/**
 * Load auth state from local storage
 * @returns {*}
 */
export function loadState() {
  try {
    const serializedState = localStorage.getItem(localStorageStoreName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}
