import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../../../Panel';
import Button from '../../../Button';

/**
 * TopPanel()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TopPanel(props) {
  const {
    dayView, weekView, weekOverview, onActivateDayView, onActivateWeekView, onActivateWeekOverview, onClickCreate,
    onReload, isReloading,
  } = props;

  const renderHeadline = () => {
    let sub = 'Tagesansicht';
    if (weekView) {
      sub = 'Mehrtagesansicht';
    }
    if (weekOverview) {
      sub = 'Auslastungsübersicht';
    }

    return `Disposition - ${sub}`;
  };

  const getLoadingIcon = () => {
    if (isReloading) {
      return 'fas fa-spin fa-sync';
    }
    return 'fas fa-sync';
  };

  return (
    <Panel marginBottom={20} withPadding={false}>
      <div className="d-flex flex-row">
        <div className="flex-grow-1 d-flex align-items-center">
          <h4 className="hk-text-primary" style={{ margin: 0 }}>
            {renderHeadline()}
          </h4>
        </div>
        <div className="ButtonContainer">
          <Button onClick={onClickCreate} type="btn btn-outline-danger" style={{ marginRight: 30 }}>
            <i className="fas fa-plus" style={{ margin: 0 }} />
          </Button>

          <Button onClick={onActivateWeekOverview} type={`btn ${(weekOverview) ? 'btn-primary' : 'btn-outline-dark'}`}>
            <i className="fas fa-calendar-alt" style={{ margin: 0 }} />
          </Button>
          <Button onClick={onActivateWeekView} type={`btn ${(weekView) ? 'btn-primary' : 'btn-outline-dark'}`}>
            <i className="fas fa-calendar-week" style={{ margin: 0 }} />
          </Button>
          <Button onClick={onActivateDayView} type={`btn ${(dayView) ? 'btn-primary' : 'btn-outline-dark'}`}>
            <i className="fas fa-calendar-day" style={{ margin: 0 }} />
          </Button>

          <Button onClick={onReload} type="btn btn-outline-dark" style={{ marginLeft: 20 }}>
            <i className={getLoadingIcon()} style={{ margin: 0 }} />
          </Button>
        </div>
      </div>
    </Panel>
  );
}

TopPanel.propTypes = {
  dayView: PropTypes.bool,
  weekView: PropTypes.bool,
  weekOverview: PropTypes.bool,
  onActivateDayView: PropTypes.func.isRequired,
  onActivateWeekView: PropTypes.func.isRequired,
  onActivateWeekOverview: PropTypes.func.isRequired,
  onClickCreate: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  isReloading: PropTypes.bool,
};

TopPanel.defaultProps = {
  dayView: true,
  weekView: false,
  weekOverview: false,
  isReloading: false,
};
