import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { get, update } from '../Redux/Action/ClientAction';
import Panel from '../../../Components/Panel';
import { formatDateTime } from '../../../Library/Functions';
import Document from '../../../Components/ClientComponents/Document';
import {
  CLIENT_DOCUMENTSTYPE_EXEMPTIONCERTIFICATE,
  CLIENTTYPE_DEALER,
  CLIENTTYPE_MONTEUR, settlementMethodeOptions,
  YesNoOption,
} from '../../../Library/Types';
import LoadingModal from '../../../Components/LoadingModal';
import ClientVoucherInformation from '../../../Components/SystemComponents/ClientVoucherInformation';
import { getList } from '../Redux/Action/UserAction';
import { useDispatchUpdateSettings } from '../../../Redux/Action/ClientSettingsAction';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import ShowHideContainer from '../../../Components/ShowHideContainer';

/**
 * Details()
 * @param props
 * @constructor
 */
function Details(props) {
  const {
    dispatchGetDetails, client, users, dispatchGetUsers, dispatchUpdateClient,
  } = props;
  const dispatchUpdateSettings = useDispatchUpdateSettings();

  const { clientId } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    if (clientId) {
      dispatchGetUsers();
      dispatchGetDetails(clientId).then(() => setIsLoading(false)).catch(() => setIsLoading(false));
    }
  }, [clientId, dispatchGetDetails, dispatchGetUsers]);

  const handleOnChange = () => {
    dispatchGetDetails(clientId);
  };

  const getUserName = () => {
    if (users) {
      const user = users.filter((item) => item.userId === client.adminUserId)[0];
      if (user) {
        return (
          <div>
            {`${user.firstname} ${user.lastname}`}
          </div>
        );
      }
    }
    return null;
  };

  const handleUpdateSettings = (key, value) => {
    dispatchUpdateSettings({ ...client.settings, [key]: value }).then(() => {
      dispatchGetDetails(clientId);
    });
  };

  const renderBank = () => {
    if (client.bankIban) {
      return (
        <>
          <div><strong>{client.bankOwner}</strong></div>
          <div>{client.bankIban}</div>
          <div>{client.bankBic}</div>
          <div>{client.bankName}</div>
        </>
      );
    }
    return (
      <div className="alert alert-danger">
        Der Kunde hat noch keine Bankdaten hinterlegt!
      </div>
    );
  };

  const renderDocuments = () => (
    <Panel containerStyle={{ marginTop: 25 }} marginBottom={25}>
      <h5 className="hk-text-primary">Dokumente</h5>
      <p>Alle vom Kunden bereitgestellten Dokumente</p>

      <div className="row">
        <Document
          caption="Firmenlogo"
          type="logo"
          mandatory
          client={client}
          onChange={handleOnChange}
        />
        <Document
          caption="Versicherungsnachweis"
          type="insurance"
          mandatory={(client.type === CLIENTTYPE_MONTEUR)}
          client={client}
          onChange={handleOnChange}
        />
        <Document
          caption="Zertifikat Elektroinstalltion"
          type="electriccertificate"
          monteurImportant={(client.type === CLIENTTYPE_MONTEUR)}
          client={client}
          onChange={handleOnChange}
        />
        <Document
          caption="Zertifikat Wasserinstallation"
          type="watercertificate"
          monteurImportant={(client.type === CLIENTTYPE_MONTEUR)}
          client={client}
          onChange={handleOnChange}
        />
        <Document
          caption="Handelsregisterauszug"
          type="registrationcertificate"
          mandatory={(client.legalForm > 1)}
          client={client}
          onChange={handleOnChange}
        />
        <Document
          caption="Gewerbeschein"
          type="businessregistration"
          mandatory={(client.legalForm === 1)}
          client={client}
          onChange={handleOnChange}
        />
        <Document
          caption="Freistellungsbescheinigung"
          type={CLIENT_DOCUMENTSTYPE_EXEMPTIONCERTIFICATE}
          client={client}
          onChange={handleOnChange}
          visible={(client.type === CLIENTTYPE_DEALER)}
        />
      </div>
    </Panel>
  );

  const getSettlementMethodeDisplayString = () => {
    let { settlementMethode } = client;
    if (!settlementMethode) {
      settlementMethode = 0;
    }
    const findResult = settlementMethodeOptions.find((option) => option.value === settlementMethode);
    if (findResult === undefined) {
      return 'Fehler, unbekannte Abrechnungsmethode ist gesetzt.';
    }
    return findResult.label;
  };

  if (client) {
    return (
      <>
        <LoadingModal visible={isLoading} text="Daten werden geladen..." />

        <div className="row">
          <div className="col-lg-6 d-flex">
            <Panel>
              <h5 className="hk-text-primary">Details</h5>
              <p>Alle wichtigen Details zum Kunden</p>
              <div>
                <hr />
              </div>

              <address>
                <div><strong>{client.name}</strong></div>
                {getUserName()}
                <div>{`${(client.zip) ? client.zip : ''} ${(client.city) ? client.city : ''}`}</div>
                <div>{`${(client.street) ? client.street : ''} ${(client.streetNo) ? client.streetNo : ''}`}</div>
                <div style={{ marginTop: 10 }}>{`Telefon: ${(client.phone) ? client.phone : ''}`}</div>
                <div>{`E-Mail: ${(client.mail) ? client.mail : ''}`}</div>
                <div>{`Webseite: ${(client.web) ? client.web : ''}`}</div>
                <div style={{ marginTop: 10 }}>{`Ust-Identnummer: ${(client.vat) ? client.vat : ''}`}</div>
                <div>
                  {`Gläubiger-ID: ${(client.devoutIdentificationNumber) ? client.devoutIdentificationNumber : ''}`}
                </div>

                <div style={{ marginTop: 10 }}>
                  {`Registriert am: ${formatDateTime(client.createdDate)} Uhr`}
                </div>
                <div>
                  {`Registrierung bestätigt: ${(client.registrationApproved) ? 'Ja' : 'Nein'}`}
                </div>
              </address>
            </Panel>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12">
                <Panel marginBottom={25}>
                  <h5 className="hk-text-primary">Bankdaten</h5>
                  <p>Bankverbindung des Kunden</p>
                  <div>
                    <hr />
                  </div>
                  {renderBank()}
                </Panel>
              </div>
              <div className="col-lg-12">
                <ClientVoucherInformation client={client} />
              </div>
            </div>
          </div>
        </div>
        {renderDocuments()}

        <div className="row">
          <div className="col-lg-12 d-flex">
            <Panel>
              <h5 className="hk-text-primary">Interne Kundeneinstellungen</h5>
              <p>Systeminterne Einstellungen zum Kunden</p>
              <div>
                <hr />
              </div>

              <ShowHideContainer visible={client.type === CLIENTTYPE_DEALER}>
                <p style={{ marginBottom: 4 }}>
                  Achtung, wenn der Kunde/Verkäufer eine Freistellungsbescheinigung hat, wird für diesen Kunden keine
                  Mehrwertsteuer auf den Rechnungen ausgewiesen.
                </p>
                <KeyValueEditRow
                  type="select"
                  title="Kunde hat Freistellungsbescheinigung"
                  selectData={YesNoOption}
                  initialValue={client.isTaxFree}
                  displayText={
                    (client.isTaxFree) ? 'Ja, Kunde hat eine gültige Bescheinigung' : 'Nein'
                  }
                  onChange={(value) => dispatchUpdateClient(clientId, { isTaxFree: value })}
                />
              </ShowHideContainer>

              <ShowHideContainer visible={client.type === CLIENTTYPE_MONTEUR}>
                <KeyValueEditRow
                  type="select"
                  title="Kunde ist Kleinunternehmer"
                  selectData={YesNoOption}
                  initialValue={client.smallBusiness}
                  displayText={
                    (client.smallBusiness) ? 'Ja, Kunde ist Kleinunternehmer und stell selber eine Rechnung' : 'Nein'
                  }
                  onChange={(value) => dispatchUpdateClient(clientId, { smallBusiness: value })}
                />
              </ShowHideContainer>

              <KeyValueEditRow
                type="select"
                title="Kunde darf Partnerschaften verwalten"
                selectData={YesNoOption}
                initialValue={client.canHaveMandant}
                displayText={
                  (client.canHaveMandant) ? 'Ja, Kunde darf Partnerschaften verwalten' : 'Nein'
                }
                onChange={(value) => dispatchUpdateClient(clientId, { canHaveMandant: value })}
              />

              <KeyValueEditRow
                type="select"
                title="Kunde darf Subunternehmen erstellen und verwalten"
                selectData={YesNoOption}
                initialValue={client.settings.allowSubcontractor}
                displayText={
                  (client.settings.allowSubcontractor) ? 'Ja' : 'Nein'
                }
                onChange={(value) => handleUpdateSettings('allowSubcontractor', value)}
              />
              <KeyValueEditRow
                type="select"
                title="Abrechnungs-Methode"
                selectData={settlementMethodeOptions}
                initialValue={client.settlementMethode}
                displayText={getSettlementMethodeDisplayString()}
                onChange={(value) => dispatchUpdateClient(clientId, { settlementMethode: value })}
              />

              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-lg-12">
                  <h6>Disposition</h6>
                  <p>
                    Achtung, die Nutzung der Disposition ist für den Kunden ggf. kostenpflichtig.
                  </p>
                </div>
                <div className="col-lg-12">
                  <KeyValueEditRow
                    type="select"
                    title="Kunde darf Disposition nutzen"
                    selectData={YesNoOption}
                    initialValue={client.settings.allowUseDisposition}
                    displayText={
                      (client.settings.allowUseDisposition) ? 'Ja' : 'Nein'
                    }
                    onChange={(value) => handleUpdateSettings('allowUseDisposition', value)}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-lg-12">
                  <h6>hey.kitchen Event API</h6>
                  <p>
                    Achtung, die Nutzung der Event API ist für den Kunden kostenpflichtig und wird pro Auftrag
                    abgerechnet. Der Kunde sieht den Menüpunkt in seinen Einstellungen erst wenn die Option
                    aktiviert ist!
                  </p>
                </div>
                <div className="col-lg-6">
                  <KeyValueEditRow
                    type="select"
                    title="Kunde darf Event API verwenden"
                    selectData={YesNoOption}
                    initialValue={client.settings.allowUseEvents}
                    displayText={
                      (client.settings.allowUseEvents) ? 'Ja' : 'Nein'
                    }
                    onChange={(value) => handleUpdateSettings('allowUseEvents', value)}
                  />
                </div>
                <div className="col-lg-6">
                  <KeyValueEditRow
                    type="select"
                    title="Kunde darf interne Kundenmails deaktivieren"
                    selectData={YesNoOption}
                    initialValue={client.settings.allowDisableInternalMails}
                    displayText={
                      (client.settings.allowDisableInternalMails) ? 'Ja' : 'Nein'
                    }
                    onChange={(value) => handleUpdateSettings('allowDisableInternalMails', value)}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-lg-12">
                  <h6>hey.kitchen Externe API</h6>
                  <p>
                    Achtung, die Nutzung der externen API ist für den Kunden ggf. kostenpflichtig.
                    Der API Token wird beim deaktivieren gelöscht und beim aktivieren wird IMMER neu
                    generiert. Eine vorübergehende Deaktivierung führt dazu, das der Kunde den neuen Token bei sich
                    einbinden/ändern muss!
                  </p>
                </div>
                <div className="col-lg-12">
                  <KeyValueEditRow
                    type="select"
                    title="Kunde darf externe API nutzen"
                    selectData={YesNoOption}
                    initialValue={client.settings.allowUseExternalApi}
                    displayText={
                      (client.settings.allowUseExternalApi) ? 'Ja' : 'Nein'
                    }
                    onChange={(value) => handleUpdateSettings('allowUseExternalApi', value)}
                  />
                </div>
                <ShowHideContainer visible={client.settings.allowUseExternalApi}>
                  <div className="col-lg-12">
                    <KeyValueEditRow
                      type="textarea"
                      title="API Token"
                      displayText={client.settings.externalApiToken}
                    />
                  </div>
                </ShowHideContainer>
              </div>
            </Panel>
          </div>
        </div>
      </>
    );
  }
  return null;
}

Details.propTypes = {
  dispatchUpdateClient: PropTypes.func.isRequired,
  dispatchGetDetails: PropTypes.func.isRequired,
  dispatchGetUsers: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Array),
};

Details.defaultProps = {
  client: null,
  users: null,
};

function mapStoreToProps(store) {
  return {
    client: store.systemClient.selectedClient,
    users: store.systemUser.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetDetails: (clientId) => dispatch(get(clientId)),
    dispatchGetUsers: () => dispatch(getList()),
    dispatchUpdateClient: (clientId, data) => dispatch(update(clientId, data)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Details));
