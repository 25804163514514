/** OBJECT SERVICE STATES */
import React from 'react';
import {
  APPOINTMENT_WAITING_FOR_APPROVAL,
  APPOINTMENT_WAITING_FOR_CONFIRMATION,
  APPOINTMENT_WAITING_FOR_PROPOSED_DATES,
  useGetAppointmentCaption,
  useGetAppointmentIcon,
  useGetAppointmentType,
} from './AppointmentStates';

export const SERVICESTATE_OPEN = 1;
export const SERVICESTATE_WAITING_APPOINTMENT = 2;
export const SERVICESTATE_WAITING_APPOINTMENT_APPROVEMENT = 3;
export const SERVICESTATE_APPROVED = 4;
export const SERVICESTATE_SCHEDULED = 5;
export const SERVICESTATE_ASSIGNED = 6;
export const SERVICESTATE_IN_PROGRESS = 7;
export const SERVICESTATE_REPORT_FINISHED = 8;
export const SERVICESTATE_WAITING = 9;
export const SERVICESTATE_CLOSED = 10;

export const SERVICE_APPOINTMENT_WAITING_FOR_APPROVAL = APPOINTMENT_WAITING_FOR_APPROVAL;
export const SERVICE_APPOINTMENT_WAITING_FOR_PROPOSED_DATES = APPOINTMENT_WAITING_FOR_PROPOSED_DATES;
export const SERVICE_APPOINTMENT_WAITING_FOR_CONFIRMATION = APPOINTMENT_WAITING_FOR_CONFIRMATION;

const serviceStateCaption = {
  [SERVICESTATE_OPEN]: 'Offen',
  [SERVICESTATE_WAITING_APPOINTMENT]: 'Wartet auf Terminvorschläge',
  [SERVICESTATE_WAITING_APPOINTMENT_APPROVEMENT]: 'Wartet auf Terminbestätigung',
  [SERVICESTATE_SCHEDULED]: 'Terminiert',
  [SERVICESTATE_ASSIGNED]: 'Zugewiesen',
  [SERVICESTATE_REPORT_FINISHED]: 'Abgeschlossen',
  [SERVICESTATE_CLOSED]: 'Erledigt',
};

const serviceStateIcon = {
  [SERVICESTATE_OPEN]: 'fas fa-folder-open',
  [SERVICESTATE_WAITING_APPOINTMENT]: 'far fa-calendar-alt',
  [SERVICESTATE_WAITING_APPOINTMENT_APPROVEMENT]: 'far fa-calendar-check',
  [SERVICESTATE_SCHEDULED]: 'fas fa-calendar',
  [SERVICESTATE_ASSIGNED]: 'fas fa-user-check',
  [SERVICESTATE_REPORT_FINISHED]: 'fas fa-check-circle',
  [SERVICESTATE_CLOSED]: 'fas fa-check-circle',
};

const serviceStateType = {
  [SERVICESTATE_OPEN]: 'Danger',
  [SERVICESTATE_WAITING_APPOINTMENT]: 'Warning',
  [SERVICESTATE_WAITING_APPOINTMENT_APPROVEMENT]: 'Primary',
  [SERVICESTATE_SCHEDULED]: 'Warning',
  [SERVICESTATE_ASSIGNED]: 'Primary',
  [SERVICESTATE_REPORT_FINISHED]: 'Success',
  [SERVICESTATE_CLOSED]: 'Info',
};

export function useGetServiceCaption() {
  const getAppointmentCaption = useGetAppointmentCaption();

  return React.useCallback(
    (state) => serviceStateCaption[state] || getAppointmentCaption(state),
    [getAppointmentCaption],
  );
}

export function useGetServiceIcon() {
  const getAppointmentIcon = useGetAppointmentIcon();

  return React.useCallback(
    (state) => serviceStateIcon[state] || getAppointmentIcon(state),
    [getAppointmentIcon],
  );
}

export function useGetServiceType() {
  const getAppointmentType = useGetAppointmentType();

  return React.useCallback(
    (state) => serviceStateType[state] || getAppointmentType(state),
    [getAppointmentType],
  );
}
