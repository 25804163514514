import React from 'react';
import {
  Route, withRouter, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import RightNavigationLayout from '../../Components/Layout/RightNavigationLayout';
import SystemInvoiceNavigation from '../../Components/Navigation/SystemInvoiceNavigation';
import Invoices from '../../Pages/System/Invoice/Overview';
import ClearingOverview from '../../Pages/System/Invoice/ClearingOverview';
import GenerateDebits from '../../Pages/System/Invoice/GenerateDebits';
import GenerateCredits from '../../Pages/System/Invoice/GenerateCredits';
import Create from '../../Pages/System/Invoice/Create';

/**
 * SystemInvoiceRoutes()
 * @returns {*}
 * @constructor
 */
function SystemInvoiceRoutes() {
  const { path, url } = useRouteMatch();

  return (
    <RightNavigationLayout navigation={<SystemInvoiceNavigation />}>
      <Switch>
        <Route exact path={`${path}/open`}><Invoices /></Route>
        <Route exact path={`${path}/generatedebits`}><GenerateDebits /></Route>
        <Route exact path={`${path}/generatecredits`}><GenerateCredits /></Route>
        <Route exact path={`${path}/create`}><Create /></Route>
        <Route exact path={`${path}/clearing`}><ClearingOverview /></Route>
        <Route><Redirect to={`${url}/open`} /></Route>
      </Switch>
    </RightNavigationLayout>
  );
}

SystemInvoiceRoutes.propTypes = {

};

SystemInvoiceRoutes.defaultProps = {

};

function mapStoreToProps() {
  return {

  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(SystemInvoiceRoutes));
