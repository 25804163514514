import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import * as Redux from 'react-redux';
import { getAll } from '../../Redux/Action/UserAction';
import { useDispatchCalendarGetList } from '../../Redux/Action/CalendarAction';
import { getList as getTeams } from '../../Redux/Action/TeamsAction';
import { useDispatchHolidayGetList } from '../../Redux/Action/HolidayAction';
import Scheduler from '../../Components/Scheduler/SchedulerTile';
import DayBadge from '../../Components/Scheduler/Components/DayBadge';

/**
 * Calendar()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function Calendar(props) {
  const {
    monteureOnly, getAllUsers, dispatchGetTeams, teams, history, overallAssemblyTime,
  } = props;
  const dispatchGetHolidays = useDispatchHolidayGetList();
  const dispatchGetList = useDispatchCalendarGetList();
  const [currentMonth, setCurrentMonth] = useState(moment());

  const calendarData = Redux.useSelector((store) => (store.calendar.data));

  useEffect(() => {
    getAllUsers();
    dispatchGetTeams();
    const startDate = moment().startOf('month').subtract(7, 'days').format('YYYY-MM-DD');
    const endDate = moment().endOf('month').add(7, 'days').format('YYYY-MM-DD');
    dispatchGetHolidays(startDate, endDate);
    dispatchGetList(startDate, endDate).then(() => {
    });
    dispatchGetHolidays(startDate, endDate);
  }, [getAllUsers, dispatchGetTeams, dispatchGetList, dispatchGetHolidays]);

  const handleOnMonthChange = (newMonth) => {
    setCurrentMonth(newMonth);
    const startDate = moment(newMonth).startOf('month').subtract(7, 'days').format('YYYY-MM-DD');
    const endDate = moment(newMonth).endOf('month').add(7, 'days').format('YYYY-MM-DD');
    dispatchGetList(startDate, endDate).then(() => {
    });
  };

  const handleOpenDetails = (date) => {
    history.push(`/calendar/${date}`);
  };

  if (monteureOnly && teams) {
    return (
      <Scheduler
        calendarData={calendarData}
        currentMonth={currentMonth}
        userCount={monteureOnly.length}
        teamCount={teams.length}
        onMonthChange={handleOnMonthChange}
        onSelectDay={handleOpenDetails}
        showSunday
        overallAssemblyTime={overallAssemblyTime}
        BadgeComponent={DayBadge}
      />
    );
  }
  return null;
}

Calendar.propTypes = {
  monteureOnly: PropTypes.instanceOf(Array),
  teams: PropTypes.instanceOf(Array),
  getAllUsers: PropTypes.func.isRequired,
  dispatchGetTeams: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  overallAssemblyTime: PropTypes.number,
};

Calendar.defaultProps = {
  monteureOnly: [],
  teams: [],
  overallAssemblyTime: 0,
};

function mapStoreToProps(store) {
  return {
    monteureOnly: store.user.monteureOnly,
    teams: store.teams.teams,
    overallAssemblyTime: store.user.overallAssemblyTime,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllUsers: () => dispatch(getAll()),
    dispatchGetTeams: () => dispatch(getTeams()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Calendar));
