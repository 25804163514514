import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ServiceRow from './ServiceList/ServiceRow';

/**
 * ServiceList(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ServiceList(props) {
  const {
    services, variant, onClick,
  } = props;

  const renderHeader = () => {
    if (variant === 'internal') {
      return (
        <thead>
          <tr>
            <th>ServiceId</th>
            <th>Kommissions-Nr.</th>
            <th>Option</th>
            <th>Firma</th>
            <th>Ausführungsdatum</th>
            <th>Status</th>
          </tr>
        </thead>
      );
    }
    return (
      <thead>
        <tr>
          <th>ServiceId</th>
          <th>Kommissions-Nr.</th>
          <th>Option</th>
          <th>Firma</th>
          <th>Ausführungsdatum</th>
          <th>Zugewiesen an</th>
          <th>Status</th>
        </tr>
      </thead>
    );
  };

  const renderRows = () => {
    if (services && services.length > 0) {
      return services.map((service) => (
        <ServiceRow
          serviceViewRow={service}
          isInternal={variant === 'internal'}
          key={service.serviceId}
          onClick={onClick}
        />
      ));
    }
    return null;
  };

  const renderBody = () => {
    if (services && services.length > 0) {
      return (
        <tbody>
          {renderRows()}
        </tbody>
      );
    }
    return null;
  };

  return (
    <table className="table table-hover">
      {renderHeader()}
      {renderBody()}
    </table>
  );
}

ServiceList.propTypes = {
  services: PropTypes.instanceOf(Array).isRequired,
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ServiceList.defaultProps = { };
