import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../Panel';

/**
 * SignaturesBox()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function SignaturesBox(props) {
  const {
    customerSignaturePath, monteurSignaturePath, customer, user, containerStyle,
  } = props;

  if (customerSignaturePath && customerSignaturePath.length !== 0
    && monteurSignaturePath && monteurSignaturePath.length !== 0
  ) {
    return (
      <div className="row" style={containerStyle}>
        <div className="col-lg-6">
          <Panel>
            <h6>Unterschrift Mitarbeiter</h6>
            <img
              alt="Monteurunterschrift"
              src={monteurSignaturePath}
              style={{ maxWidth: '100%', maxHeight: 250 }}
            />
            <div style={{ borderTop: '1px solid #000' }}>
              {`${(user) ? `${user.lastname}, ${user.firstname}` : 'Monteur'}`}
            </div>
          </Panel>
        </div>
        <div className="col-lg-6">
          <Panel>
            <h6>Unterschrift Kunde</h6>
            <img
              alt="Kundenunterschrift"
              src={customerSignaturePath}
              style={{ maxWidth: '100%', maxHeight: 250 }}
            />
            <div style={{ borderTop: '1px solid #000' }}>
              {`${customer.lastname}, ${customer.firstname}`}
            </div>
          </Panel>
        </div>
      </div>
    );
  }
  return null;
}

SignaturesBox.propTypes = {
  monteurSignaturePath: PropTypes.string,
  customerSignaturePath: PropTypes.string,
  customer: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object),
  containerStyle: PropTypes.instanceOf(Object),
};

SignaturesBox.defaultProps = {
  monteurSignaturePath: null,
  customerSignaturePath: null,
  user: null,
  containerStyle: {},
};
