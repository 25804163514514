import React from 'react';
import PropTypes from 'prop-types';
import {
  formatCurrency,
  formatDate,
  getOrderIcon,
} from '../../Library/Functions';
import ClickableTableRow from '../ClickableTableRow';
import ShowHideContainer from '../ShowHideContainer';
import CombinedOrderBadge from '../OrderComponents/CombinedOrderBadge';
import { ORDERTYPE_OBJECT } from '../../Library/Types';
import OptionIcons from '../ObjectComponents/OptionIcons';
import { useGetWorkTimeString } from '../../Library/HelperHooks';

/**
 * OrderRow()
 * @param props
 * @returns {*}
 * @constructor
 */
function OrderRow(props) {
  const {
    order, onClick, showDeliveryDate, showAddress, showAssemblyTime, showMonteurPrice, showStatus,
    showShortDeliveryAddress, client,
  } = props;
  const getWorkTimeString = useGetWorkTimeString();
  const getAddress = () => `${order.zip} ${order.city}`;

  const renderIcon = () => <i className="fas fa-angle-right hk-text-primary" />;

  const renderDeliveryDate = () => {
    if (showDeliveryDate) {
      if (order.date) {
        return (
          <ClickableTableRow onClick={() => onClick(order)}>
            {formatDate(order.date)}
          </ClickableTableRow>
        );
      }
      return <ClickableTableRow onClick={() => onClick(order)} />;
    }
    return null;
  };

  const renderAddress = () => {
    if (showAddress || showShortDeliveryAddress) {
      return (
        <ClickableTableRow onClick={() => onClick(order)}>
          {getAddress()}
        </ClickableTableRow>
      );
    }
    return false;
  };

  const renderExternalId = () => {
    if (order.type === ORDERTYPE_OBJECT) {
      if (order.orderExternalId) {
        return `${order.orderExternalId} (${order.objectExternalId})`;
      }
      return `${order.orderId} (${order.objectExternalId})`;
    }
    return order.objectExternalId;
  };

  return (
    <>
      <tr>
        <ClickableTableRow onClick={() => onClick(order)}>
          {renderIcon()}
        </ClickableTableRow>

        <ClickableTableRow onClick={() => onClick(order)}>
          {getOrderIcon(order, client)}
        </ClickableTableRow>

        <ClickableTableRow onClick={() => onClick(order)}>
          {renderExternalId()}
        </ClickableTableRow>

        <ClickableTableRow onClick={() => onClick(order)}>
          <OptionIcons order={order} />
        </ClickableTableRow>

        {renderDeliveryDate()}

        <ShowHideContainer visible={showMonteurPrice}>
          <ClickableTableRow onClick={() => onClick(order)}>
            {formatCurrency(order.mechanicPrice)}
          </ClickableTableRow>
        </ShowHideContainer>

        <ShowHideContainer visible={showAssemblyTime}>
          <ClickableTableRow onClick={() => onClick(order)}>
            {getWorkTimeString(order.internal, order.executionTime)}
          </ClickableTableRow>
        </ShowHideContainer>
        {renderAddress()}

        <ShowHideContainer visible={showStatus}>
          <ClickableTableRow onClick={() => onClick(order)} style={{ textAlign: 'right' }}>
            <div className="d-flex justify-content-center">
              <CombinedOrderBadge
                orderState={order.orderState}
                objectDateState={order.state}
                isInternal={order.internal}
                useMediaQuery
              />
            </div>
          </ClickableTableRow>
        </ShowHideContainer>
      </tr>
    </>
  );
}

OrderRow.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  showDeliveryDate: PropTypes.bool,
  showAddress: PropTypes.bool,
  showAssemblyTime: PropTypes.bool,
  showMonteurPrice: PropTypes.bool,
  showStatus: PropTypes.bool,
  showShortDeliveryAddress: PropTypes.bool.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

OrderRow.defaultProps = {
  showDeliveryDate: false,
  showAddress: false,
  showAssemblyTime: true,
  showMonteurPrice: false,
  showStatus: true,
};

/**
 * OrderTable()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function OrderTable(props) {
  const {
    orders, onClick, showDeliveryDate, showAddress, showAssemblyTime, showMonteurPrice, showStatus,
    showShortDeliveryAddress, client,
  } = props;

  const renderDeliveryDate = () => {
    if (showDeliveryDate) {
      return (<th>Montagedatum</th>);
    }
    return null;
  };

  const renderAddress = () => {
    if (showAddress || showShortDeliveryAddress) {
      return (<th>Adresse</th>);
    }
    return null;
  };

  if (orders && orders.length > 0) {
    return (
      <div className="OrderListView">
        <table className="table table-linked table-hover">
          <thead>
            <tr>
              <th />
              <th>Typ</th>
              <th>Kommission</th>
              <th>Optionen</th>
              {renderDeliveryDate()}
              {(showMonteurPrice) ? <th>Angebotspreis</th> : null}
              {(showAssemblyTime) ? <th>Montagedauer</th> : null}
              {renderAddress()}
              {(showStatus) ? <th style={{ textAlign: 'center' }}>Status</th> : null}
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <OrderRow
                order={order}
                key={order.dateId}
                onClick={onClick}
                showDeliveryDate={showDeliveryDate}
                showAddress={showAddress}
                showAssemblyTime={showAssemblyTime}
                showMonteurPrice={showMonteurPrice}
                showStatus={showStatus}
                showShortDeliveryAddress={showShortDeliveryAddress}
                client={client}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  return null;
}

OrderTable.propTypes = {
  orders: PropTypes.instanceOf(Array),
  onClick: PropTypes.func.isRequired,
  showDeliveryDate: PropTypes.bool,
  showAddress: PropTypes.bool,
  showAssemblyTime: PropTypes.bool,
  showMonteurPrice: PropTypes.bool,
  showShortDeliveryAddress: PropTypes.bool,
  showStatus: PropTypes.bool,
  client: PropTypes.instanceOf(Object).isRequired,
};

OrderTable.defaultProps = {
  orders: null,
  showDeliveryDate: false,
  showAddress: true,
  showAssemblyTime: true,
  showMonteurPrice: false,
  showStatus: true,
  showShortDeliveryAddress: false,
};
