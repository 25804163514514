import {
  USER_GETALL_ERROR, USER_GETALL_START, USER_GETALL_SUCCESS, USER_UPDATE_SUCCESS, USER_CHANGEPASSWORD_START,
  USER_CHANGEPASSWORD_SUCCESS, USER_CHANGEPASSWORD_ERROR, USER_GETRECLAMATIONS_SUCCESS, USER_GETORDERS_SUCCESS,
  USER_GETOBJECTSERVICES_SUCCESS,
} from '../Action/UserAction';
import { CALENDAR_GETTODAY_SUCCESS, CALENDAR_GETTODAY_ERROR } from '../Action/CalendarAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  users: null,
  isLoading: false,
  statusCode: null,
  errorCode: null,
  monteureOnly: null,
  overallAssemblyTime: 0,
  todayAvailableMonteurCount: 0,
  orders: [],
  internalOrders: [],
  externalOrders: [],
  reclamations: [],
  internalReclamations: [],
  externalReclamations: [],
  services: [],
  internalServices: [],
  externalServices: [],
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /** GET ALL */
    case USER_GETALL_START: {
      return { ...state, isLoading: true };
    }
    case USER_GETALL_SUCCESS: {
      const { users, assemblyTimePerDay } = action.payload;

      const monteureOnly = users.filter((user) => {
        let found = false;
        user.groups.forEach((group) => {
          if (group.groupInitials === 'MON' || group.groupInitials === 'MMON') {
            found = true;
          }
        });
        return found;
      });

      return {
        ...state,
        isLoading: false,
        users,
        monteureOnly,
        overallAssemblyTime: (monteureOnly && monteureOnly.length > 0)
          ? (monteureOnly.length * assemblyTimePerDay)
          : null,
      };
    }
    case USER_GETALL_ERROR: {
      if (action.payload) {
        const { status, data } = action.payload;
        return {
          ...state, isLoading: false, statusCode: status, errorCode: data.errorCode,
        };
      }
      return { ...state, isLoading: false, statusCode: 500 };
    }

    /** GET USER ORDERS */
    case USER_GETORDERS_SUCCESS: {
      if (action.payload) {
        const {
          orders, internalOrders, externalOrders,
        } = action.payload;

        return {
          ...state, orders, internalOrders, externalOrders,
        };
      }
      return {
        ...state, internalOrders: [], externalOrders: [], orders: [],
      };
    }

    /** GET USER RECLAMATIONS */
    case USER_GETRECLAMATIONS_SUCCESS: {
      if (action.payload) {
        const {
          reclamations, internalReclamations, externalReclamations,
        } = action.payload;
        return {
          ...state, reclamations, internalReclamations, externalReclamations,
        };
      }
      return {
        ...state, internalReclamations: [], externalReclamations: [], reclamations: [],
      };
    }

    /** GET USER OBJECT SERVICES */
    case USER_GETOBJECTSERVICES_SUCCESS: {
      if (action.payload) {
        const {
          services, internalServices, externalServices,
        } = action.payload;
        return {
          ...state, services, internalServices, externalServices,
        };
      }
      return {
        ...state, internalServices: [], externalServices: [], services: [],
      };
    }

    /** USER UDPATE */
    case USER_UPDATE_SUCCESS: {
      const { payload } = action;

      if (state.users) {
        const mergedUsers = state.users.map((item) => {
          if (item.userId === payload.userId) {
            return payload;
          }
          return item;
        });

        return { ...state, users: mergedUsers };
      }
      return { ...state };
    }

    /** CHANGE PASSWORD */
    case USER_CHANGEPASSWORD_START: {
      return { ...state, isLoading: true };
    }

    case USER_CHANGEPASSWORD_ERROR:
    case USER_CHANGEPASSWORD_SUCCESS: {
      return { ...state, isLoading: false };
    }

    /** GET TODAY (Calendar) */
    case CALENDAR_GETTODAY_SUCCESS: {
      const { payload } = action;

      if (payload && state.monteureOnly) {
        const available = state.monteureOnly.filter((user) => payload.users.indexOf(user.userId) === -1);
        return { ...state, todayAvailableMonteurCount: available.length };
      }
      if (state.monteureOnly) {
        return { ...state, todayAvailableMonteurCount: state.monteureOnly.length };
      }
      return { ...state };
    }

    case CALENDAR_GETTODAY_ERROR: {
      if (state.monteureOnly) {
        return { ...state, todayAvailableMonteurCount: state.monteureOnly.length };
      }
      return { ...state };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
