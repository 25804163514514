import React, { useReducer } from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import Input from '../../../../Components/Input';
import Textarea from '../../../../Components/Textarea';
import { isValidFloatNumberFormat } from '../../../../Library/Functions';
import { globalTaxRate } from '../../../../Library/Types';

const initialState = {
  caption: null,
  description: '',
  priceNetto: '',
  priceBrutto: '0.00',
  titleError: false,
  priceNettoError: false,
  taxRate: 1.19,
};

function reducer(state, action) {
  switch (action.type) {
    case 'caption': return { ...state, caption: action.payload, captionError: false };
    case 'description': return { ...state, description: action.payload };
    case 'priceNetto': {
      if (action.payload) {
        if (isValidFloatNumberFormat(action.payload)) {
          return {
            ...state,
            priceNetto: action.payload.replace(',', '.'),
            priceBrutto: (parseFloat(action.payload.replace(',', '.')) * state.taxRate).toFixed(2),
            priceNettoError: false,
          };
        }
        return { ...state };
      }
      return { ...state, priceNetto: action.payload, priceBrutto: '' };
    }

    case 'captionError': return { ...state, titleError: true };
    case 'priceNettoError': return { ...state, priceNettoError: true };
    case 'taxRate': return { ...state, taxRate: action.payload };
    case 'reset': return { ...initialState };

    default:
      throw new Error();
  }
}

/**
 * AddPositionModal()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AddPositionModal(props) {
  const {
    visible, onAdd, onClose, performanceDate,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'reset' });
    }
  }, [visible]);

  React.useEffect(() => {
    if (performanceDate) {
      dispatch({ type: 'taxRate', payload: globalTaxRate(performanceDate) });
    }
  }, [performanceDate]);

  const handleSave = () => {
    let hasError = false;
    if (!state.caption || state.caption.length <= 3) {
      dispatch({ type: 'captionError' });
      hasError = true;
    }
    if (!state.priceNetto || state.priceNetto < 0) {
      dispatch({ type: 'priceNettoError' });
      hasError = true;
    }

    if (!hasError) {
      onAdd({
        caption: state.caption,
        description: state.description,
        priceNetto: parseFloat(state.priceNetto).toFixed(2),
        priceBrutto: parseFloat(state.priceBrutto).toFixed(2),
      });
    }
  };

  return (
    <ModalDialog
      visible={visible}
      marginBottom={25}
      onClose={onClose}
      confirmCaption="Hinzufügen"
      onConfirm={handleSave}
    >
      <h6>Position hinzufügen</h6>
      <hr />

      <div className="row">
        <Input
          onChange={(value) => dispatch({ type: 'caption', payload: value })}
          label="Positionstitel"
          rowClass="col-lg-12"
          value={state.caption}
          hasError={state.captionError}
          autoFocus
          required
        />
        <Textarea
          onChange={(value) => dispatch({ type: 'description', payload: value })}
          label="Beschreibung"
          rowClass="col-lg-12"
          rows={3}
          value={state.description}
        />
        <Input
          onChange={(value) => dispatch({ type: 'priceNetto', payload: value })}
          label="Nettopreis"
          rowClass="col-lg-6"
          rightIcon="fas fa-euro-sign"
          value={state.priceNetto}
          hasError={state.priceNettoError}
          required
        />
        <Input
          onChange={(value) => dispatch({ type: 'priceBrutto', payload: value })}
          label="Bruttopreis"
          rowClass="col-lg-6"
          rightIcon="fas fa-euro-sign"
          value={state.priceBrutto}
          disabled
          required
        />
      </div>
    </ModalDialog>
  );
}

AddPositionModal.propTypes = {
  visible: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  performanceDate: PropTypes.string.isRequired,
};

AddPositionModal.defaultProps = {
  visible: false,
};
