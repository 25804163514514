import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import Panel from '../../../Components/Panel';
import { execute, getList } from '../Redux/Action/ClearingAction';
import { getList as getClients } from '../Redux/Action/ClientAction';
import ClearingList from '../Component/ClearingList';
import ModalDialog from '../../../Components/ModalDialog';
import Alert from '../../../Components/Alert';

/**
 * ClearingOverview()
 * @param props
 * @returns {*}
 * @constructor
 */
function ClearingOverview(props) {
  const {
    dispatchGetList, dispatchGetClients, clients, dispatchExecute,
  } = props;
  const [lastMonth, setLastMonth] = React.useState([]);
  const [currentMonth, setCurrentMonth] = React.useState([]);
  const [lastMonthDate] = React.useState(moment().subtract(1, 'month'));
  const [executeItem, setExecuteItem] = React.useState(null);
  const [canExecute, setCanExecute] = React.useState(false);
  const [showExecuteError, setShowExecuteError] = React.useState(false);

  const loadClearings = React.useCallback(() => {
    dispatchGetList(lastMonthDate.format('M'), lastMonthDate.format('YYYY')).then((data) => {
      setLastMonth(data);
    });
    dispatchGetList(moment().format('M'), moment().format('YYYY')).then((data) => {
      setCurrentMonth(data);
    });
  }, [dispatchGetList, lastMonthDate]);

  const handleOnExecute = (item) => {
    dispatchExecute(item).then(() => {
      setExecuteItem(null);
      dispatchGetList(lastMonthDate.format('M'), lastMonthDate.format('YYYY')).then((data) => {
        setLastMonth(data);
      });
    }).catch((error) => {
      setShowExecuteError(error.errorCode);
    });
  };

  React.useEffect(() => {
    if (moment().format('D') >= 5) {
      setCanExecute(true);
    }
    dispatchGetClients();
    loadClearings();
  }, [loadClearings, dispatchGetClients]);

  const renderLastMonth = () => {
    let content;
    if (lastMonth && lastMonth.length > 0) {
      content = (
        <ClearingList items={lastMonth} clients={clients} onClick={(canExecute) ? setExecuteItem : null} />
      );
    } else {
      content = (
        <div className="alert alert-info" style={{ marginBottom: 0 }}>
          <i className="fas fa-info-circle" style={{ marginRight: 6 }} />
          Keine offenen Abrechnungen vom Vormonat vorhanden!
        </div>
      );
    }

    return (
      <Panel marginBottom={20}>
        <h6>{`Letzter Monat ${lastMonthDate.format('MM')}/${lastMonthDate.format('YYYY')}`}</h6>
        <p>Alle offenen Abrechnungen des Vormonats. Diese können ab dem 5ten des Folgemonats abgerechnet werden!</p>
        {content}
      </Panel>
    );
  };

  const renderCurrentMonth = () => {
    let content;
    if (currentMonth && currentMonth.length > 0) {
      content = (
        <ClearingList items={currentMonth} clients={clients} />
      );
    } else {
      content = (
        <div className="alert alert-info" style={{ marginBottom: 0 }}>
          <i className="fas fa-info-circle" style={{ marginRight: 6 }} />
          Noch keine Daten vom aktuellen monat vorhanden.
        </div>
      );
    }

    return (
      <Panel marginBottom={20}>
        <h6>{`Aktueller Monat ${moment().format('MM')}/${moment().format('YYYY')}`}</h6>
        <p>Alle offenen Abrechnungen des aktuellen Monats.</p>
        {content}
      </Panel>
    );
  };

  return (
    <>
      <Panel marginBottom={20}>
        <h5 style={{ margin: 0 }}>
          Monatliche Abrechnungen
        </h5>
      </Panel>

      {renderLastMonth()}
      {renderCurrentMonth()}

      <ModalDialog
        visible={(executeItem !== null)}
        onClose={() => setExecuteItem(null)}
        confirmCaption="Abrechnung ausführen"
        onConfirm={() => handleOnExecute(executeItem)}
      >
        <h5>Abrechnung ausführen?</h5>
        <p>
          Bitte bestätigen Sie das Sie die monatliche Abrechnung für den gewählten Kunden durchführen möchten.
          Dieser Vorgang kann nicht rückgängig gemacht werden!
        </p>
      </ModalDialog>

      <Alert type="danger" visible={showExecuteError} onClose={() => setShowExecuteError(false)}>
        {`Die Abrechnung konnte nicht ausgeführt werden. Fehlermeldung: ${showExecuteError}`}
      </Alert>
    </>
  );
}

ClearingOverview.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGetClients: PropTypes.func.isRequired,
  dispatchExecute: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Array),
};

ClearingOverview.defaultProps = {
  clients: null,
};

function mapStoreToProps(store) {
  return {
    clients: store.systemClient.clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: (month, year) => dispatch(getList(month, year)),
    dispatchExecute: (item) => dispatch(execute(item)),
    dispatchGetClients: () => dispatch(getClients()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ClearingOverview));
