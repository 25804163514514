import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { get, update } from '../../../Redux/Action/UserAction';
import GroupSelect from '../Component/GroupSelect';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import Panel from '../../../Components/Panel';
import Button from '../../../Components/Button';
import LoadingModal from '../../../Components/LoadingModal';
import HelpDialog from '../../../Components/HelpDialog';
import { YesNoOption } from '../../../Library/Types';
import { getAll } from '../../../Redux/Action/ClientSubcontractorAction';

function reducer(state, action) {
  switch (action.type) {
    case 'firstname':
      return { ...state, firstname: action.payload, changed: true };
    case 'lastname':
      return { ...state, lastname: action.payload, changed: true };
    case 'referenceId':
      return { ...state, referenceId: action.payload, changed: true };
    case 'subcontractorId':
      return { ...state, subcontractorId: action.payload, changed: true };
    case 'mail':
      return { ...state, mail: action.payload, changed: true };
    case 'password':
      return { ...state, password: action.payload, changed: true };
    case 'active':
      return { ...state, active: action.payload, changed: true };
    case 'groups':
      return { ...state, groups: action.payload, changed: true };
    case 'phone':
      return { ...state, phone: action.payload, changed: true };
    case 'initials':
      return { ...state, initials: action.payload, changed: true };
    case 'init': {
      return { ...action.payload, changed: false };
    }

    default:
      throw new Error();
  }
}

/**
 * Update()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Update(props) {
  const {
    dispatchUpdateUser, getAllSubcontractors, subcontractors, dispatchGet, history, client,
  } = props;
  const { userId } = useParams();
  const [state, dispatch] = useReducer(reducer, {});
  const [currentTabIndex, setCurrentTabIndex] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [helpContext] = useState('userInitials');
  const [subcontractorName, setSubcontractorName] = useState(null);

  useEffect(() => {
    if (userId) {
      getAllSubcontractors(client.clientId).then((getAllSubcontractorResponse) => {
        dispatchGet(userId).then((getResponse) => {
          if (getResponse.clientId === client.clientId) {
            setUser(getResponse);
            dispatch({ type: 'init', payload: getResponse });
            if (getAllSubcontractorResponse.find((x) => x.subcontractorId === getResponse.subcontractorId)) {
              setSubcontractorName(getAllSubcontractorResponse.find(
                (x) => x.subcontractorId === getResponse.subcontractorId,
              ).name);
            } else {
              setSubcontractorName('');
            }
          } else {
            history.goBack();
          }
        });
      });
    }
  }, [getAllSubcontractors, dispatchGet, userId, client.clientId, history]);

  const saveData = () => {
    setSaving(true);
    dispatchUpdateUser(userId, state).then(() => {
      setSaving(false);
      history.push('/admin/users');
    });
  };

  function getSelectDataSubcontractor() {
    return ((subcontractors)
      ? [{
        label: 'keine Zuweisung',
        value: 0,
      },
      ...subcontractors.map(
        (item) => ({
          label: item.name,
          value: item.subcontractorId,
        }),
      )]
      : {
        label: '',
        subcontractorId: 0,
      }
    );
  }

  function assignSubcontractor(value) {
    dispatch({ type: 'subcontractorId', payload: value });
    if (value > 0) {
      setSubcontractorName(subcontractors.find((subcontractor) => subcontractor.subcontractorId === value).name);
      dispatch({
        type: 'active',
        payload: subcontractors.find((subcontractor) => subcontractor.subcontractorId === value).active,
      });
    } else {
      setSubcontractorName('keine Zuweisung');
    }
  }

  function renderSubcontractor() {
    if (client.settings.allowSubcontractor) {
      return (
        <div className="col-lg-6">
          <KeyValueEditRow
            type="select"
            title="Subunternehmen zuweisen"
            selectData={getSelectDataSubcontractor()}
            initialValue=""
            displayText={subcontractorName}
            placeholder="keine Zuweisung"
            onChange={(value) => assignSubcontractor(value)}
            containerTabIndex={8}
            startEditMode={(currentTabIndex === 8)}
            onPressEnterGetNextTabIndex={setCurrentTabIndex}
          />
        </div>
      );
    }
    return null;
  }

  if (user) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Panel marginBottom={25}>
            <h4 style={{ margin: 0 }}>Benutzerdaten bearbeiten</h4>
          </Panel>
        </div>

        <div className="col-lg-12">
          <Panel marginBottom={25}>
            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Vorname"
                  initialValue={state.firstname}
                  displayText={state.firstname}
                  onChange={(value) => dispatch({ type: 'firstname', payload: value })}
                  containerTabIndex={2}
                  startEditMode={(currentTabIndex === 2)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Nachname"
                  initialValue={state.lastname}
                  displayText={state.lastname}
                  onChange={(value) => dispatch({ type: 'lastname', payload: value })}
                  containerTabIndex={3}
                  startEditMode={(currentTabIndex === 3)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Initialen"
                  initialValue={state.initials}
                  displayText={state.initials}
                  onChange={(value) => dispatch({ type: 'initials', payload: value.toString().toUpperCase() })}
                  containerTabIndex={5}
                  startEditMode={(currentTabIndex === 5)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  maxLength={3}
                  onHelpAction={() => setShowHelp(true)}
                  placeholder="Maximal 3 Zeichen als Kürzel für den Benutzer"
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="E-Mail Adresse"
                  initialValue={state.mail}
                  displayText={state.mail}
                  onChange={(value) => dispatch({ type: 'mail', payload: value })}
                  containerTabIndex={4}
                  startEditMode={(currentTabIndex === 4)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Interne Mitarbeiternummer"
                  initialValue={state.referenceId}
                  displayText={state.referenceId}
                  onChange={(value) => dispatch({ type: 'referenceId', payload: value })}
                  containerTabIndex={6}
                  startEditMode={(currentTabIndex === 6)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Telefonnummer"
                  initialValue={state.phone}
                  displayText={state.phone}
                  onChange={(value) => dispatch({ type: 'phone', payload: value })}
                  containerTabIndex={7}
                  startEditMode={(currentTabIndex === 7)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  type="select"
                  title="Status Benutzerkonto"
                  selectData={YesNoOption}
                  initialValue={state.active}
                  displayText={
                    (state.active)
                      ? 'Benutzer aktiv, anmeldung möglich'
                      : 'Benutzer inaktiv, keine Anmeldung im Web oder in der App möglich'
                  }
                  onChange={(value) => (dispatch({ type: 'active', payload: value }))}
                  containerTabIndex={7}
                  startEditMode={(currentTabIndex === 7)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                />
              </div>
              {renderSubcontractor()}
            </div>
          </Panel>
        </div>

        <div className="col-lg-12">
          <Panel marginBottom={25}>
            <GroupSelect
              onChange={(value) => dispatch({ type: 'groups', payload: value })}
              initialSelected={user.groups}
            />
          </Panel>
        </div>

        <div className="col-lg-12">
          <Panel withPadding={false}>
            <div className="ButtonContainer d-flex justify-content-end">
              <Button type="btn-light" onClick={() => history.goBack()}>Abbrechen</Button>
              <Button disabled={!state.changed} onClick={saveData}>
                Änderungen speichern
              </Button>
            </div>
          </Panel>
        </div>

        <LoadingModal visible={saving} text="Benutzerdaten werden gespeichert..." />
        <HelpDialog context={helpContext} visible={showHelp} onClose={() => setShowHelp(false)} />
      </div>
    );
  }
  return null;
}

function mapStoreToProps(store) {
  return {
    isLoading: store.user.isLoggedin,
    monteure: store.user.monteureOnly,
    subcontractors: store.subcontractor.subcontractors,
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: (userId) => dispatch(get(userId)),
    dispatchUpdateUser: (userId, userData) => dispatch(update(userId, userData)),
    getAllSubcontractors: (clientId) => dispatch(getAll(clientId, false)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Update));

Update.propTypes = {
  dispatchGet: PropTypes.func.isRequired,
  getAllSubcontractors: PropTypes.func.isRequired,
  dispatchUpdateUser: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  subcontractors: PropTypes.instanceOf(Object).isRequired,

};
