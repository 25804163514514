import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import Panel from '../Panel';
import {
  DOCUMENTTYPE_CARGOLIST,
  DOCUMENTTYPE_DELIVERYNOTE,
  DOCUMENTTYPE_INSTALL, DOCUMENTTYPE_INVOICE,
  DOCUMENTTYPE_ORDER,
  DOCUMENTTYPE_OTHER,
} from '../../Library/Types';
import Button from '../Button';
import DocumentItem from './DocumentItem';
import SelectDocumentDialog from './SelectDocumentDialog';
import {
  ORDERSTATE_WAITING_APPROVEMENT,
  ORDERSTATE_WAITING_CALCULATION,
} from '../../Library/StateTypes';
import WarningAttachmentSizeDialog from './WarningAttachmentSizeDialog';

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 15px;

  .Document {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #000000;
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 6px;
    font-size: 16px;
    background-color: ${(props) => props.theme.color.grayBackgroundColor};

    &:hover {
      background-color: #e5e5e5;
      border: 1px solid #c0c0c0;
      padding: 9px 14px;
      text-decoration: none;

      .fas {
        color: ${(props) => props.theme.color.textGray};
        
        &.fa-trash {
          &:hover {
            color: ${(props) => props.theme.color.primaryRedColor};
          }
        }
        
        &.fa-user {
          &:hover {
            color: ${(props) => props.theme.color.primaryGreenColor};
          }  
        }
      }
    }

    .fas {
      color: ${(props) => props.theme.color.textGrayLight};
      font-size: 14px !important;
      width: 14px !important;
      padding: 10px;
      margin-right: 10px;
      
      &.fa-user-check {
        color: ${(props) => props.theme.color.primaryGreenColor};
      }
    }

    .Filename {
      flex-grow: 1;
      white-space: nowrap;
      font-size: 15px;
      margin-left: 10px;
    }

    i {
      font-size: 18px;
    }
  }
`;

/**
 * DocumentListPanel()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function DocumentListPanel(props) {
  const {
    documents, order, isSystemContext, onAdd, onDelete, additionalButton, headline, children,
    onAttachToMail, type,
  } = props;
  const { user } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.client);

  const [showAdd, setShowAdd] = React.useState(false);
  const [documentAttachSize, setDocumentAttachSize] = React.useState(0);
  const [documentSizeWarningModal, setDocumentSizeWarningModal] = React.useState(false);

  const isDocumentsEditable = (isSystemContext || (order.internal)
                                || (order.state === ORDERSTATE_WAITING_CALCULATION)
                                || (order.state === ORDERSTATE_WAITING_APPROVEMENT));

  React.useEffect(() => {
    const attachedDocuments = documents.filter((document) => document.sendToCustomer === true);
    let size = 0;
    attachedDocuments.forEach((document) => {
      size += document.size;
    });
    setDocumentAttachSize(size);
  }, [documents]);

  const handleAttachToMail = (doc) => {
    if (doc.sendToCustomer === false) {
      if (documentAttachSize + doc.size >= 8000000) {
        setTimeout(() => {
          setDocumentSizeWarningModal(true);
        }, 500);
      }
      setDocumentAttachSize(documentAttachSize + doc.size);
    } else {
      setDocumentAttachSize(documentAttachSize - doc.size);
    }
    onAttachToMail(doc);
  };

  const renderOther = () => {
    const fileOther = documents.filter((item) => (
      item.type === DOCUMENTTYPE_OTHER || item.type === DOCUMENTTYPE_INVOICE || item.type === DOCUMENTTYPE_CARGOLIST
    ));

    if (fileOther && fileOther.length > 0) {
      return fileOther.map((item) => (
        <DocumentItem
          key={item.filename}
          item={item}
          order={order}
          deletable={(order.clientId === client.clientId) || (user.userId === item.createdId) || isSystemContext}
          onDelete={(doc) => onDelete(doc)}
          onAttachToMail={handleAttachToMail}
          type={type}
        />
      ));
    }
    return null;
  };

  const renderInstallation = () => {
    const installationPlan = documents.filter((item) => item.type === DOCUMENTTYPE_INSTALL)[0];

    if (installationPlan) {
      return (
        <DocumentItem
          item={installationPlan}
          order={order}
          deletable={isSystemContext}
          onDelete={(doc) => onDelete(doc)}
          onAttachToMail={handleAttachToMail}
          type={type}
        />
      );
    }
    return null;
  };

  const renderConfirmation = () => {
    const orderConfirmation = documents.filter((item) => item.type === DOCUMENTTYPE_ORDER)[0];

    if (orderConfirmation) {
      return (
        <DocumentItem
          item={orderConfirmation}
          order={order}
          deletable={isSystemContext}
          onDelete={(doc) => onDelete(doc)}
          onAttachToMail={handleAttachToMail}
          type={type}
        />
      );
    }
    return null;
  };

  const renderDeliveryNote = () => {
    const deliveryNotes = documents.filter((item) => item.type === DOCUMENTTYPE_DELIVERYNOTE);

    if (deliveryNotes && deliveryNotes.length > 0) {
      return deliveryNotes.map((item) => (
        <DocumentItem
          item={item}
          deletable={(user.userId === item.createdId) || isSystemContext}
          order={order}
          caption={`Lieferschein vom ${moment(item.createdDate).format('DD.MM.YYYY - HH:mm')} Uhr`}
          onDelete={(doc) => onDelete(doc)}
          onAttachToMail={handleAttachToMail}
          type={type}
        />
      ));
    }
    return null;
  };

  const renderEditButton = () => {
    if (client.clientId === order.clientId || order.mandantId === client.clientId || isSystemContext) {
      return (
        <Button onClick={() => setShowAdd(true)} type="btn-outline-dark">
          <i className="fas fa-plus" style={{ margin: 0 }} />
        </Button>
      );
    }
    return null;
  };

  const handleAddDocument = (typeParam, file) => {
    setShowAdd(false);
    onAdd(typeParam, file);
  };

  const getHeadline = () => {
    if (headline) {
      return headline;
    }
    return `Dokumente (${Object.keys(documents).length})`;
  };

  if (documents) {
    return (
      <Panel marginBottom={20} containerStyle={{ display: 'flex' }}>
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6>{getHeadline()}</h6>
            {(children) ? (<p>{children}</p>) : null}
          </div>
          <div>
            {additionalButton || null}
            {renderEditButton()}
          </div>
        </div>

        <DocumentContainer>
          {renderInstallation()}
          {renderConfirmation()}
          {renderOther()}
          {renderDeliveryNote()}
        </DocumentContainer>

        <SelectDocumentDialog
          onClose={() => setShowAdd(false)}
          onSave={handleAddDocument}
          visible={showAdd}
          editable={isDocumentsEditable}
        />
        <WarningAttachmentSizeDialog
          onClose={() => setDocumentSizeWarningModal(false)}
          visible={documentSizeWarningModal}
        />
      </Panel>
    );
  }
}

DocumentListPanel.propTypes = {
  documents: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  isSystemContext: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAttachToMail: PropTypes.func.isRequired,
  additionalButton: PropTypes.node,
  headline: PropTypes.string,
  children: PropTypes.string,
  type: PropTypes.oneOf(['object', 'service']).isRequired,
};

DocumentListPanel.defaultProps = {
  isSystemContext: false,
  additionalButton: null,
  headline: null,
  children: null,
};
