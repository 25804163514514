import axios from '../../Library/Axios';

const defaultUri = '/public';

export const PUBLIC_DOWNLOADREPORT_START = 'PUBLIC_DOWNLOADREPORT_START';
export const PUBLIC_DOWNLOADREPORT_SUCCESS = 'PUBLIC_DOWNLOADREPORT_SUCCESS';
export const PUBLIC_DOWNLOADREPORT_ERROR = 'PUBLIC_DOWNLOADREPORT_ERROR';

/**
 * downloadServiceReport()
 * @param serviceReportId
 * @returns {function(*): Promise<unknown>}
 */
export function downloadServiceReport(serviceReportId) {
  return (dispatch) => {
    dispatch({ type: PUBLIC_DOWNLOADREPORT_START });

    const uri = `${defaultUri}/downloadreport/${serviceReportId}`;
    const config = {
      params: { action: 'downloadReport' },
      responseType: 'arraybuffer',
    };

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/pdf' },
      );
      dispatch({ type: PUBLIC_DOWNLOADREPORT_SUCCESS, payload: data });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: PUBLIC_DOWNLOADREPORT_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
