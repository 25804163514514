import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import {
  NavLink, useParams, useRouteMatch, withRouter,
} from 'react-router-dom';
import Panel from '../../Panel';
import ModalDialog from '../../ModalDialog';
import { remove } from '../../../Pages/System/Redux/Action/OrderAction';
import { get } from '../../../Redux/Action/OrderAction';
import StatusBadge from '../../StatusBadge';
import { formatDate } from '../../../Library/Functions';
import {
  ORDERSTATE_EXPIRED,
  ORDERSTATE_OPEN,
  ORDERSTATE_WAITING_CALCULATION,
  ORDERSTATE_WAITING_APPROVEMENT,
} from '../../../Library/StateTypes';

/**
 * OrderDetailsNavigation()
 * @returns {*}
 * @constructor
 */
function OrderDetailsNavigation(props) {
  const {
    order, dispatchDelete, history, dispatchGetOrder,
  } = props;
  const { orderId } = useParams();
  const { url } = useRouteMatch();
  const [askDelete, setAskDelete] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (!order) {
        dispatchGetOrder(orderId);
      }
    }, 600);
  }, [dispatchGetOrder, order, orderId]);

  const renderDelete = () => {
    if (order
      && (
        order.state === ORDERSTATE_WAITING_CALCULATION
        || order.state === ORDERSTATE_WAITING_APPROVEMENT
        || order.state === ORDERSTATE_OPEN
        || order.state === ORDERSTATE_EXPIRED
      )
    ) {
      return (
        <li>
          {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
          <a className="nav-link" href={null} onClick={() => setAskDelete(true)} style={{ cursor: 'pointer' }}>
            <i className="fas fa-trash-alt" />
            Löschen
          </a>
        </li>
      );
    }
    return null;
  };

  const renderTypeBadge = () => {
    if (order) {
      return (
        <StatusBadge type="Primary" caption={(order.internal) ? 'Interner Auftrag' : 'Externer Auftrag'} />
      );
    }
    return null;
  };

  const renderDateBadge = () => {
    if (order && order.deliveryDateStart) {
      return (
        <StatusBadge type="Primary" caption={formatDate(order.deliveryDateStart)} containerStyle={{ marginTop: 10 }} />
      );
    }
    return null;
  };

  const handleDelete = () => {
    dispatchDelete(order.orderId);
    history.push('/system');
  };

  const renderOffer = () => {
    if (order && !order.internal && order.offers && order.offers.length > 0) {
      return (
        <li>
          <NavLink className="nav-link" to={`${url}/offers`}>
            <i className="fas fa-gavel" />
            {`Individuelle Angebote (${order.offers.length})`}
          </NavLink>
        </li>
      );
    }
    return null;
  };

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            Auftragsdetails
          </h6>

          {renderTypeBadge()}
          {renderDateBadge()}
        </div>

        <hr />

        <ul>
          <li>
            <NavLink className="nav-link" exact to={`${url}`}>
              <i className="fas fa-info-circle" />
              Übersicht
            </NavLink>
          </li>
          {renderOffer()}
          {renderDelete()}
          <hr />
          <li>
            <NavLink
              className="nav-link"
              to="/system/order"
              activeClassName={null}
            >
              <i className="fas fa-angle-double-left" />
              Zurück
            </NavLink>
          </li>
        </ul>
      </div>

      <ModalDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        centered
        confirmCaption="Auftrag endgültig löschen"
        onConfirm={handleDelete}
      >
        <h5 className="hk-text-primary">
          <i className="fas fa-trash-alt" style={{ marginRight: 10 }} />
          Auftrag endgültig löschen?
        </h5>
        <p>
          Bitte bestätigen Sie das Sie diesen Auftrag endgültig löschen möchten. Eine Wiederherstellung des Auftrags
          ist nicht möglich!
        </p>
      </ModalDialog>
    </Panel>
  );
}

OrderDetailsNavigation.propTypes = {
  dispatchDelete: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
};

OrderDetailsNavigation.defaultProps = {
  order: null,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDelete: (orderId) => dispatch(remove(orderId)),
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(OrderDetailsNavigation));
