import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {
  useDispatchDeleteProposedDate,
  useDispatchSelectProposedDate,
} from '../../../Redux/Action/Order/Object/Service/Appointment/ProposedDateAction';
import ProposedDateView from './ProposedDateView';
import EditProposedDateModal from '../ProposedDates/EditProposedDateModal';
import ModalDialog from '../../ModalDialog';
import { formatDate, formatTime } from '../../../Library/Functions';
import { useDispatchSendCustomerMail } from '../../../Redux/Action/Order/Object/ServiceAction';

/**
 * ProposedDate()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProposedDate(props) {
  const {
    orderId, objectId, serviceId, appointmentId, dateId, date, startTime, comment, selected, deletable, editable,
    selectable, onChange,
  } = props;

  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showSelect, setShowSelect] = React.useState(false);

  const dispatchDeleteProposedDate = useDispatchDeleteProposedDate(
    orderId,
    objectId,
    serviceId,
    appointmentId,
    dateId,
  );

  const dispatchSelectProposedDate = useDispatchSelectProposedDate(
    orderId,
    objectId,
    serviceId,
    appointmentId,
    dateId,
  );

  const dispatchSendCustomerMail = useDispatchSendCustomerMail();

  const showDeleteModal = deletable ? () => { setShowDelete(true); } : undefined;
  const showEditModal = editable ? () => { setShowEdit(true); } : undefined;
  const showSelectModal = selectable ? () => { setShowSelect(true); } : undefined;

  const handleDelete = deletable ? () => {
    dispatchDeleteProposedDate().then(() => {
      if (onChange) {
        onChange();
      }
    });
  } : undefined;

  const handleSelect = selectable ? () => {
    dispatchSelectProposedDate(true).then(() => {
      if (onChange) {
        onChange();
      }
      dispatchSendCustomerMail(serviceId);
    });
  } : undefined;

  const displayDate = date ? formatDate(date) : null;
  const displayStartTime = startTime ? formatTime(startTime) : null;

  const renderSelectModal = () => ((showSelect) ? (
    <ModalDialog
      visible
      onConfirm={handleSelect}
      onClose={() => { setShowSelect(false); }}
      confirmCaption="Termin jetzt bestätigen"
      centered
    >
      <h5>Termin verbindlich bestätigen!</h5>
      <p>
        Hiermit bestätige ich den Termin am
        <strong>{` ${displayDate}${startTime ? ` ab ${displayStartTime} Uhr` : ''} `}</strong>
        verbindlich. Nach der Bestätigung wird der Termin verbindlich reserviert und der Monteur wird informiert.
        Eine nachträgliche Änderung des Termins ist dann nur noch über den hey.kitchen Support möglich!
      </p>
    </ModalDialog>
  ) : null);

  const renderDeleteModal = () => ((showDelete) ? (
    <ModalDialog
      visible
      onConfirm={handleDelete}
      onClose={() => { setShowDelete(false); }}
      confirmCaption="Termin löschen"
      centered
    >
      <h5>Vorgeschlagenen Termin löschen?</h5>
      <p>
        Bitte bestätigen Sie, das Sie den Termin
        <strong>
          {` ${moment(date).format('DD.MM.YYYY')} um ${moment(startTime, 'HH:mm:ss').format('HH:mm')} Uhr `}
        </strong>
        löschen möchten!
      </p>
    </ModalDialog>
  ) : null);

  const renderEditModal = () => ((showEdit) ? (
    <EditProposedDateModal
      orderId={orderId}
      objectId={objectId}
      serviceId={serviceId}
      appointmentId={appointmentId}
      dateId={dateId}
      initialDate={date}
      initialStartTime={startTime}
      initialComment={comment}
      onSuccess={onChange}
      onClose={() => { setShowEdit(false); }}
    />
  ) : null);

  return (
    <>
      <ProposedDateView
        date={date}
        startTime={startTime}
        comment={comment}
        selected={selected}
        onDelete={showDeleteModal}
        onEdit={showEditModal}
        onSelect={showSelectModal}
      />
      {renderDeleteModal()}
      {renderEditModal()}
      {renderSelectModal()}
    </>
  );
}

ProposedDate.propTypes = {
  orderId: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  serviceId: PropTypes.number.isRequired,
  appointmentId: PropTypes.number.isRequired,
  dateId: PropTypes.number.isRequired,
  date: PropTypes.string,
  startTime: PropTypes.string,
  comment: PropTypes.string,
  selected: PropTypes.bool,
  deletable: PropTypes.bool,
  editable: PropTypes.bool,
  selectable: PropTypes.bool,
  onChange: PropTypes.func,
};

ProposedDate.defaultProps = {
  date: undefined,
  startTime: null,
  comment: '',
  selected: false,
  deletable: false,
  editable: false,
  selectable: false,
  onChange: undefined,
};
