/* eslint-disable no-param-reassign */
import moment from 'moment';

/**
 * Checks if appointments are overlapping and prepares the appointments for displaying in the dispo.
 * @param appointments
 * @param appointmentHeight
 * @returns {*}
 */
export default function prepareOverlappingAppointments(appointments, appointmentHeight) {
  if (appointments && appointments.length > 1) {
    const sorted = appointments.sort((a, b) => (
      moment(a.start, 'HH:mm').isAfter(moment(b.start, 'HH:mm')) ? -1 : 1
    ));

    sorted.forEach((item) => {
      sorted.forEach((checkItem) => {
        if (moment(item.date).isSame(moment(checkItem.date), 'day')) {
          if (item.disposition.dispositionId !== checkItem.disposition.dispositionId) {
            if (moment(item.end, 'HH:mm').isAfter(moment(checkItem.start, 'HH:mm'), 'minute')
              && moment(item.start, 'HH:mm').isBefore(moment(checkItem.start, 'HH:mm'), 'minute')
            ) {
              item.top = 0;
              item.height = appointmentHeight / 2;

              checkItem.top = appointmentHeight / 2 + 1;
              checkItem.height = appointmentHeight / 2 - 1;
            }
          }
        }
      });
    });

    return sorted;
  }

  return appointments;
}
