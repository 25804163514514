import axios from '../../Library/Axios';

const defaultUri = '/material';

export const MATERIAL_GETLIST_START = 'MATERIAL_GETLIST_START';
export const MATERIAL_GETLIST_SUCCESS = 'MATERIAL_GETLIST_SUCCESS';
export const MATERIAL_GETLIST_ERROR = 'MATERIAL_GETLIST_ERROR';

/**
 * This call is possible without authorization!
 * getList()
 * @returns {function(*): Promise<any | never>}
 */
export function getList() {
  return (dispatch) => {
    dispatch({ type: MATERIAL_GETLIST_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: MATERIAL_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: MATERIAL_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
