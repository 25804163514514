import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ModalDialog from '../../../../Components/ModalDialog';
import DatePickerInput from '../../../../Components/DatePickerInput';
import { timeOptions } from '../../../../Library/Types';
import CustomReactSelect from '../../../../Components/CustomReactSelect';
import Textarea from '../../../../Components/Textarea';

/**
 * AddAppointmentDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AddAppointmentDialog(props) {
  const {
    visible, onClose, onSave,
  } = props;
  const [date, setDate] = useState('');
  const [executionFromTime, setExecutionFromTime] = useState('');
  const [executionToTime, setExecutionToTime] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    setDate('');
    setExecutionFromTime('');
    setExecutionToTime('');
    setComment('');
  }, [visible]);

  const handleOnSave = () => {
    onSave({
      date, executionFromTime, executionToTime, comment,
    });
  };

  return (
    <ModalDialog visible={visible} onClose={onClose} onConfirm={handleOnSave} width={600} confirmCaption="Hinzufügen">
      <h6>Termin hinzufügen</h6>
      <br />

      <DatePickerInput
        onChange={(data) => setDate(data)}
        minDate={moment().add(1, 'days').toDate()}
        label="Datum"
      />

      <div className="row">
        <CustomReactSelect
          options={timeOptions}
          onChange={(data) => setExecutionFromTime(data)}
          value={executionFromTime}
          rowClass="col-lg-6"
          label="Monteur Ankunft von"
        />
        <CustomReactSelect
          options={timeOptions}
          onChange={(data) => setExecutionToTime(data)}
          value={executionToTime}
          rowClass="col-lg-6"
          label="Monteur Ankunft bis"
        />
      </div>

      <Textarea
        onChange={(data) => setComment(data)}
        value={comment}
        label="Kommentar (optional)"
      />

    </ModalDialog>
  );
}

AddAppointmentDialog.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

AddAppointmentDialog.defaultProps = {
  visible: false,
};
