import React from 'react';

import './style.css';

const screenshot03 = require('../../../../Assets/Images/App/iphone_screen8.png');
const screenshot04 = require('../../../../Assets/Images/App/iphone_screen10.png');
const screenshot05 = require('../../../../Assets/Images/App/iphone_screen2.png');
const softwareArrow = require('../../../../Assets/Images/pfeil_gelb.png');

/**
 * MobileFlow()
 * @returns {*}
 * @constructor
 */
export default function MobileFlow() {
  return (
    <div
      className="MobileFlow FullPageBox Light Center"
      style={{ backgroundImage: `url(${softwareArrow})` }}
    >
      <div className="container d-flex flex-column">
        <h2>unsere hey.kitchen Software</h2>
        <p>
          Sie sind Experte beim Verkauf, der Lieferung und Montage von Küchen? Hervorragend! Genau für Sie
          haben wir hey.kitchen entwickelt. Die Software erleichtert Ihnen nicht nur die Montage sondern spart
          Ihnen auch viel Geld und Zeit. Unsere Webanwendung in Kombination mit der Montage App bildet den gesamten
          Küchenprozess digital ab.
        </p>

        <div>
          <div className="Box">
            <div className="Text">
              <h2>Tägliche Übersicht</h2>
              <ul>
                <li>
                  Jeder Monteur sieht die für ihn zugeordneten Aufträge übersichtlich in der App im
                  Startbildschirm dargestellt.
                </li>
                <li>
                  Direkte Kommunikation zwischen Disposition und Monteur
                </li>
                <li>
                  Interner Bereich für Ablage wichtiger Unterlagen für die Monteure.
                </li>
              </ul>
            </div>
            <div className="Image">
              <img src={screenshot05} alt="Screen1" style={{ width: '70%' }} />
            </div>
          </div>

          <div className="Box">
            <div className="Image">
              <img src={screenshot04} alt="Screen1" style={{ width: '70%' }} />
            </div>
            <div className="Text">
              <h2>Montageabwicklung</h2>
              <ul>
                <li>
                  Mitarbeiter beginnen die Montage ganz einfach per Knopfdruck.
                </li>
                <li>
                  Angeschlossene und nicht angeschlossene Geräte inkl. Gerätenummer hinzufügen.
                </li>
                <li>
                  Eventuell anfallende Reklamationen inkl. Bilder dokumentieren.
                </li>
              </ul>
            </div>
          </div>

          <div className="Box">
            <div className="Text">
              <h2>Montagebericht</h2>
              <ul>
                <li>
                  Ganz einfach Aufträge beenden und Montagebericht erstellen.
                </li>
                <li>
                  Berücksichtigt Reklamationen, Kundendienste und Versicherungsfälle.
                </li>
                <li>
                  Notwendige Bilder pro Auftrag einfach in der App machen.
                </li>
              </ul>
            </div>
            <div className="Image">
              <img src={screenshot03} alt="Screen1" style={{ width: '70%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
