import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Select from 'react-select';

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: (state.selectProps.hasError) ? '#ff717a' : provided.borderColor,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: (state.selectProps.isLarge) ? 7 : null,
    paddingLeft: (state.selectProps.isLarge) ? 15 : 10,
  }),
};

/**
 * CustomReactSelect()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CustomReactSelect(props) {
  const {
    // eslint-disable-next-line no-unused-vars
    options, onChange, value, rowClass, label, placeholder, noOptionsMessage, hasError, disabled, style, isLarge,
    isMulti, onBlur, visible,
  } = props;

  const handleOnChange = (data) => {
    if (!isMulti) {
      onChange(data.value);
    } else if (data && data.length > 0) {
      const mapped = data.map((item) => item.value);
      onChange(mapped);
    } else {
      onChange(null);
    }
  };

  const handleSetValue = () => {
    if (!isMulti) {
      return (options && options.length > 0) ? options.filter((data) => data.value === value)[0] : null;
    }
    if (options && options.length > 0) {
      if (value && Array.isArray(value)) {
        return options.filter((item) => (value.indexOf(item.value) > -1));
      }
    }
    return null;
  };

  if (visible) {
    return (
      <div className={rowClass}>
        <div className="form-group" style={style}>
          <RenderLabel {...props} />
          <div className="input-group">
            <Select
              onChange={handleOnChange}
              options={options}
              styles={customStyles}
              placeholder={placeholder}
              onBlur={onBlur}
              value={handleSetValue()}
              noOptionsMessage={noOptionsMessage}
              hasError={hasError}
              isDisabled={disabled}
              isLarge={isLarge}
              isMulti={isMulti}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
}

CustomReactSelect.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string, PropTypes.instanceOf(Array)]),
  rowClass: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  isLarge: PropTypes.bool,
  isMulti: PropTypes.bool,
  visible: PropTypes.bool,
};

CustomReactSelect.defaultProps = {
  value: null,
  rowClass: 'col-lg-12',
  label: null,
  placeholder: null,
  onBlur: () => {},
  noOptionsMessage: () => {},
  hasError: false,
  disabled: false,
  style: {},
  isLarge: false,
  isMulti: false,
  visible: true,
};

/**
 * RenderLabel()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderLabel(props) {
  // eslint-disable-next-line react/prop-types
  const { label, tooltip, required } = props;

  const renderTooltip = () => {
    if (tooltip && tooltip !== '') {
      return (
        <i
          className="fas fa-info-circle"
          data-toggle="tooltip"
          data-placement="right"
          title={tooltip}
        />
      );
    }
    return null;
  };

  if (label) {
    return (
      <label>
        {(required) ? `${label}*` : label}
        {' '}
        {renderTooltip()}
      </label>
    );
  }
  return null;
}
