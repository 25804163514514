/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types/prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Button from '../../Button';

import '../style.css';

const hkLogo = require('../../../Assets/Images/hk_logo_full.jpg');

/**
 * PublicNavigation()
 * @returns {*}
 * @constructor
 */
function PublicNavigation(props) {
  const { history } = props;
  // eslint-disable-next-line no-shadow,react/prop-types
  const [scrolled, setScrolled] = useState(false);
  const collapse = useRef();

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset < 40) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }
    };

    return () => {
      window.onscroll = null;
    };
  });

  const hideCollapse = () => {
    collapse.current.classList.remove('show');
  };

  return (
    <>
      <nav className={`navbar navbar-expand-lg fixed-top navbar-light bg-light ${(scrolled) ? 'Shaddow' : null}`}>
        <div className="container">
          <div className="navbar-brand">
            <HashLink to="/">
              <img src={hkLogo} alt="HK Logo" style={{ height: 40 }} />
            </HashLink>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={collapse}>
            <ul className="navbar-nav">
              <li className="nav-item" onClick={() => hideCollapse()} tabIndex={-1} onKeyPress={() => {}}>
                <a className="nav-link" href="https://montage.hey.kitchen">
                  <div><i className="fas fa-tools" /></div>
                  <div>Küchenmontage</div>
                </a>
              </li>
              <li className="nav-item" onClick={() => hideCollapse()} tabIndex={-1} onKeyPress={() => {}}>
                <a className="nav-link" href="https://software.hey.kitchen">
                  <div><i className="fas fa-tablet-alt" /></div>
                  <div>Software + App</div>
                </a>
              </li>
              <li className="nav-item" onClick={() => hideCollapse()} tabIndex={-1} onKeyPress={() => {}}>
                <HashLink className="nav-link" to="/kontakt">
                  <div><i className="fas fa-id-card" /></div>
                  <div>Kontakt</div>
                </HashLink>
              </li>
              <li className="nav-item" onClick={() => hideCollapse()} tabIndex={-1} onKeyPress={() => {}}>
                <NavLink className="nav-link" to="/login">
                  <div><i className="fas fa-sign-in-alt" /></div>
                  <div>Login</div>
                </NavLink>
              </li>
              <li
                className="nav-item d-flex align-content-center"
                onClick={() => hideCollapse()}
                tabIndex={-1}
                onKeyPress={() => {}}
                style={{ paddingLeft: 20 }}
              >
                <div className="d-flex justify-content-center align-items-center align-items-center">
                  <Button onClick={() => history.push('/register')}>
                    Kostenlos registrieren
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

PublicNavigation.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(PublicNavigation);
