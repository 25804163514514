import React, { useEffect } from 'react';
import PropTypes from 'prop-types/prop-types';
import { withRouter } from 'react-router';
import Footer from './Components/Footer';
import Button from '../../Components/Button';

/**
 * Aktion2020()
 * @returns {*}
 * @constructor
 */
function Aktion2020(props) {
  const { history } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-grow-1 flex-column" style={{ margin: -40, backgroundColor: '#F1F1F2' }}>
      <div className="FullPageBox Light">
        <div className="container">
          <h2 className="hk-text-primary">
            Jetzt Küchenmontage buchen und kräftig sparen!
          </h2>

          <p style={{ marginBottom: 40 }}>
            Sichern Sie sich jetzt tolle Angebote zum kennenlernen: Mit unserer Earlybird-Kennenlern-Aktion sparen
            Sie bei jeder Buchung für den Zeitraum bis zum 31. Juni 2020 satte 10% auf Ihre Küchenmontage.
          </p>

          <p style={{ marginTop: 50 }}>
            Worauf warten Sie noch?
          </p>

          <ul>
            <li>
              Registrieren Sie sich kostenlos und Sie erhalten kostenlos 10% Nachlass bei Ihrer Küchenmontage Buchung.
            </li>
            <li>
              Der Rabatt ist gültig bis einschließlich 31.06.2020.
            </li>
            <li>
              Ausschlaggebend ist der Tag der Buchung. Nicht der Tag der Montage.
            </li>
            <li>
              Rabattiert sind alle Montagen inkl. Lieferung und oder Lagerung
            </li>
          </ul>

          <p>
            Wir garantieren Ihnen eine professionelle durchgeführte Montage mit unserem Fachpersonal.
          </p>
          <p style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
            <Button onClick={() => history.push('/register')}>
              Jetzt buchen
            </Button>
          </p>

          <small>
            Anwendbar nur auf Online-Buchungen, die über unsere Webseite www.hey.kitchen erfolgen. Sie erhalten
            den Rabatt auf den Nettopreis. Es gelten die Allgemeinen Vertragsdingungen der hey.kitchen GmbH,
            Lindlar. Die Einlösung erfolgt im Laufe des Buchungsprozesses und gilt nur für gewerbliche Kunden.
            Jeder gewerbliche und kommerzielle Weiterverkauf ist untersagt.

            <br />
            <br />

            <div style={{ margin: 0 }}><strong>hey.kitchen GmbH</strong></div>
            <div style={{ margin: 0 }}>Rennweg 60</div>
            <div style={{ margin: 0 }}>56626 Rennweg</div>

            <div style={{ margin: 0 }}>+49 2266 478 9296</div>
            <div style={{ margin: 0 }}>kontakt@hey.kitchen</div>
            <div style={{ margin: 0 }}>www.hey.kitchen</div>
          </small>
        </div>
      </div>

      <Footer type="Dark" />
    </div>
  );
}

Aktion2020.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Aktion2020);
