import PropTypes from 'prop-types/prop-types';
import React from 'react';
import { ORDERTYPE_SINGLE } from '../../../../../Library/Types';
import { formatCurrency, formatDate } from '../../../../../Library/Functions';
import { ORDERSTATE_WAITING_CALCULATION } from '../../../../../Library/StateTypes';
import CombinedOrderBadge from '../../../../../Components/OrderComponents/CombinedOrderBadge';

/**
 * ObjectRow(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectRow(props) {
  const { objectView, onClick, isInternal } = props;

  const getClient = () => {
    if (objectView.ownerName) {
      if (objectView.ownerName.length > 40) {
        return `${objectView.ownerName.substring(0, 40)}...`;
      }
      return objectView.ownerName;
    }
    return null;
  };

  const getExternalId = () => {
    if (objectView.orderType === ORDERTYPE_SINGLE) {
      if (objectView.objectExternalId.length > 20) {
        return `${objectView.objectExternalId.substring(0, 20)}...`;
      }
      return objectView.objectExternalId;
    }
    const externalIdOrderTypeObject = objectView.objectExternalId || objectView.orderExternalId || objectView.orderId;
    if (externalIdOrderTypeObject.length > 20) {
      return `${externalIdOrderTypeObject.substring(0, 20)}...`;
    }
    return externalIdOrderTypeObject;
  };

  const getDealerPrice = () => {
    if (!objectView.internal) {
      return formatCurrency(objectView.dealerPrice);
    }
    return null;
  };

  const getMonteurPrice = () => {
    if (!objectView.internal) {
      return formatCurrency(objectView.mechanicPrice);
    }
    return null;
  };

  const getRowClass = () => {
    if (objectView.state === ORDERSTATE_WAITING_CALCULATION) {
      return 'table-danger';
    }
    return '';
  };

  const renderPrice = () => {
    if (!isInternal) {
      return (
        <>
          <td>{getDealerPrice()}</td>
          <td>{getMonteurPrice()}</td>
        </>
      );
    }
    return null;
  };

  const renderAssignee = () => {
    if (!isInternal) {
      if (objectView.assigneeName) {
        return (
          <td>{objectView.assigneeName}</td>
        );
      }
      return <td />;
    }
    return null;
  };

  return (
    <tr onClick={() => onClick(objectView)} className={getRowClass()}>
      <td>{objectView.objectId}</td>
      <td>{getExternalId()}</td>
      <td>{getClient()}</td>
      <td style={{ whiteSpace: 'nowrap' }}>
        {(objectView.date) ? formatDate(objectView.date) : ''}
      </td>
      {renderPrice()}
      {renderAssignee()}
      <td>
        <CombinedOrderBadge
          orderState={objectView.orderState}
          objectDateState={objectView.state}
          isInternal={objectView.internal}
          systemView
        />
      </td>
    </tr>
  );
}

ObjectRow.defaultProps = {
  onClick: () => {},
};

ObjectRow.propTypes = {
  objectView: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  isInternal: PropTypes.bool.isRequired,
};
