import React from 'react';
import PropTypes from 'prop-types/prop-types';
import styled from 'styled-components';
import Panel from '../Panel';
import { formatDateTime } from '../../Library/Functions';
import ModalDialog from '../ModalDialog';
import Button from '../Button';
import Textarea from '../Textarea';

const ViewContainer = styled.div`
  justify-content: flex-end;
  flex-direction: column; 
`;

const MessageContainer = styled.div`
  background-color: #fff;
  ${(props) => props.theme.boxShadowDark};
  border-radius: 6px;
  padding: 10px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  
  .UnapprovedHint {
    border-top: 1px dashed #a7a7a7;
    padding-top: 10px;
    margin-top: 10px;
    font-size: 14px;
    color: #e7184e;
  }
    
  .Headline {
    border-bottom: 1px dashed #a7a7a7;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    
    .Icon {
      display: flex;
      font-size: 12px;
      align-content: center;
      align-items: center;
      justify-content: center;
      
      &.Left {
        margin-right: 10px;
      }
      &.Right {
        margin-left: 10px;
        display: none;
      }
    }
    
    .Actions {
      display: flex;
      font-size: 18px;
      align-content: center;
      align-items: center;
      justify-content: center;
      color: #cdcdcd;
      cursor: pointer;
      
      i {
        margin-right: 10px;
        
        &:last-child {
          margin-right: 0;
        }

        &.fa-tasks {
          &:hover {
            color: #e7184e;
          }
        }
        
        &.fa-trash {
          &:hover {
            color: #e7184e;
          }
        }
        
        &.fa-check-circle {
          &:hover {
            color: green;
          }
        }
      }
     
      
      &.Left {
        margin-right: 10px;
        display: none;
      }
      &.Right {
        margin-left: 10px;
      }
    }
    
    .Text {
      flex-grow: 1;
      
      .Name {
        font-family: "DIN Next LT W01 Bold", sans-serif;
      }
      .Date {
        font-size: 14px;
      }
    }
  
  }
  
  &.Right {  
    background-color: #f8f8f8;
  
    .Headline {
      .Icon {
        &.Left {
          display: none;
        }
        &.Right {
          display: flex;
        }
      }
      
      .Actions {
        &.Left {
          display: flex;
        }
        &.Right {
          display: none;
        }
      }
    
      .Text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: flex-end;
        align-items: flex-end;
      }
    }
  }
  
  &.Left {
    
  }
`;

/**
 * Note()
 * @param props
 * @returns {*}
 * @constructor
 */
function Note(props) {
  const { note, onDelete, user } = props;
  const [askDelete, setAskDelete] = React.useState(false);

  const handleDelete = () => {
    setAskDelete(false);
    onDelete(note);
  };

  const getName = () => `${note.createdUser.firstname} ${note.createdUser.lastname}`;

  const getIcon = () => (
    <span className="fa-stack fa-2x">
      <i className="fas fa-circle fa-stack-2x" />
      <i className="fas fa-user fa-stack-1x fa-inverse" />
    </span>
  );

  const renderActions = () => {
    if (user.userId === note.createdId) {
      return (
        <i
          className="fas fa-trash"
          onClick={() => setAskDelete(true)}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
          key={`${note.noteId}trash`}
        />
      );
    }
    return null;
  };

  const getClassForContainer = () => {
    const classes = ['Left'];
    return classes.join(' ');
  };

  return (
    <MessageContainer className={getClassForContainer()}>
      <div className="Headline">
        <div className="Icon Left">
          {getIcon()}
        </div>
        <div className="Text">
          <div className="Name">{`${getName()}`}</div>
          <div className="Date">{formatDateTime(note.createdDate)}</div>
        </div>

        <div className="Actions Right">
          {renderActions()}
        </div>
      </div>
      <div className="Message">
        {note.note}
      </div>

      <ModalDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        centered
        confirmCaption="Notiz löschen"
        onConfirm={handleDelete}
      >
        <h5>Interne Notiz löschen?</h5>
        <p>
          Bitte bestätigen Sie, das Sie Ihre interne Notiz endgültig löschen möchten.
        </p>
      </ModalDialog>
    </MessageContainer>
  );
}

Note.propTypes = {
  note: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
};

/**
 * NoteView()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function NoteView(props) {
  const {
    notes, onDelete, onAdd, user,
  } = props;
  const [note, setNote] = React.useState('');
  const [showAdd, setShowAdd] = React.useState(false);

  const handleCloseAdd = () => {
    setNote('');
    setShowAdd(false);
  };

  const handleOnAdd = () => {
    onAdd(note);
    setNote('');
    setShowAdd(false);
  };

  return (
    <>
      <ViewContainer>
        <Panel marginBottom={20} withPadding={false}>
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShowAdd(true)}>
              <i className="fas fa-comment-medical" style={{ marginRight: 8 }} />
              Neue Notiz
            </Button>
          </div>
        </Panel>

        {
          (notes)
            ? notes.map((item) => (
              <Note
                note={item}
                key={item.noteId}
                onDelete={onDelete}
                user={user}
              />
            ))
            : null
        }
      </ViewContainer>

      <ModalDialog visible={showAdd} onClose={handleCloseAdd} onConfirm={handleOnAdd}>
        <h5>
          <i className="fas fa-comment-medical" style={{ marginRight: 8 }} />
          Neue interne Notiz
        </h5>
        <hr />
        <Textarea rows={10} onChange={setNote} value={note} autoFocus />
      </ModalDialog>
    </>
  );
}

NoteView.propTypes = {
  notes: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func.isRequired,
};

NoteView.defaultProps = {
  notes: null,
  onDelete: () => {},
};
