import React from 'react';
import * as Redux from 'react-redux';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import moment from 'moment';
import {
  useDispatchCreateAppointment,
  useDispatchGetAppointment,
  useDispatchUpdateAppointment,
} from '../../Redux/Action/Order/Object/Service/AppointmentAction';
import AppointmentView from './AppointmentPanel/AppointmentView';
import Panel from '../Panel';
import ProposedDates from './ProposedDates/ProposedDates';
import CreateProposedDateModal from './ProposedDates/CreateProposedDateModal';
import { useDispatchGetService } from '../../Redux/Action/Order/Object/ServiceAction';
import AppointmentStatusBadge from './AppointmentPanel/AppointmentStatusBadge';
import ModalDialog from '../ModalDialog';
import TextLink from '../TextLink';
import { APPOINTMENT_WAITING_FOR_APPROVAL } from '../../Library/States/AppointmentStates';

/**
 * AppointmentPanel()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function AppointmentPanel(props) {
  const {
    orderId, objectId, serviceId,
  } = props;

  const appointment = Redux.useSelector((state) => state.appointment.current);
  const appointmentId = Redux.useSelector((state) => state.appointment.current.id);
  const isLoading = Redux.useSelector((state) => state.appointment.isLoading);

  const clientId = Redux.useSelector((state) => state.client.client.clientId);
  const dealerClientId = Redux.useSelector((state) => state.order.order.clientId);
  const monteurClientId = Redux.useSelector((state) => state.objectService.service.assignedClientId);

  const dispatchGetAppointment = useDispatchGetAppointment(orderId, objectId, serviceId);
  const dispatchCreateAppointment = useDispatchCreateAppointment(orderId, objectId, serviceId);
  const dispatchUpdateAppointment = useDispatchUpdateAppointment(orderId, objectId, serviceId, appointmentId);
  const dispatchGetService = useDispatchGetService(orderId, objectId, serviceId);

  const [showCreateAppointment, setShowCreateAppointment] = React.useState(false);
  const [showCreateProposedDate, setShowCreateProposedDate] = React.useState(false);

  const [date, setDate] = React.useState(null);
  const [comment, setComment] = React.useState('');

  React.useEffect(() => {
    if (orderId && isNumber(orderId)
      && objectId && isNumber(objectId)
      && serviceId && isNumber(serviceId)
    ) {
      dispatchGetAppointment(true);
    }
  }, [dispatchGetAppointment, orderId, objectId, serviceId]);

  const isDealer = () => clientId === dealerClientId;
  const isMonteur = () => clientId === monteurClientId;

  const renderHeader = () => (
    <div className="d-flex">
      <div className="flex-grow-1" style={{ marginBottom: 10 }}>
        <h6>Terminabstimmung</h6>
      </div>
      <div style={{ marginTop: -15 }}>
        <AppointmentStatusBadge
          status={(appointment && appointment.state) ? appointment.state : APPOINTMENT_WAITING_FOR_APPROVAL}
        />
      </div>
    </div>
  );

  const renderDescription = () => (
    <div className="flex-grow-1" style={{ marginBottom: 10 }}>
      {isDealer() ? (
        <p>
          Um einen passenden Termin mit dem Monteur und Ihrem Kunden zu vereinbaren, geben Sie bitte hier an, zu
          welchem frühst möglichen Datum ein Termin möglich wäre. Der Monteur wird dann mindestens 2
          Terminvorschläge hinzufügen. Wir benachrichtigen Sie, sobald der Monteur die Termine eingetragen hat.
          Anschließend können Sie in Absprache mit dem Kunden einen passenden Termin auswählen / bestätigen.
        </p>
      ) : (
        <p>
          Zur Vereinfachung der Terminvereinbarung hinterlegt der Verkäufer ein Datum ab wann Termine möglich sind.
          Sobald der Verkäufer dieses Datum eingetragen hat, können Sie als Monteur mindestens 2 passende
          Terminvorschläge abgeben, aus welchen der Verkäufer einen in Abstimmung mit dem Kunden auswählen kann.
          Die von Ihnen vorgeschlagenen Termine sollten Sie sich, bis der Verkäufer einen Termin bestätigt hat,
          frei halten.
        </p>
      )}
    </div>
  );

  const handleOnStart = () => (
    !appointmentId ? (newDate) => {
      setDate(newDate);
      setShowCreateAppointment(true);
    } : undefined
  );

  const handleOnCommentChange = () => (appointmentId ? dispatchUpdateAppointment : setComment);

  const renderDetails = () => (
    <AppointmentView
      earliestStartDate={appointmentId ? appointment.earliestStartDate : date}
      earliestStartComment={appointmentId ? appointment.earliestStartComment : comment}
      onDateChange={isDealer() ? handleOnStart() : null}
      onCommentChange={isDealer() ? handleOnCommentChange() : null}
    />
  );

  const renderProposedDatesHeadline = () => {
    const text = isDealer() ? 'Terminvorschläge des Monteurs' : 'Ihre Terminvorschläge';
    return (
      <h6 style={{ marginBottom: 20 }}>{text}</h6>
    );
  };

  const renderCreateButton = () => (
    isMonteur() ? (
      <TextLink
        icon="fas fa-plus-square"
        caption="Terminvorschlag hinzufügen"
        onPress={() => { setShowCreateProposedDate(true); }}
      />
    ) : null
  );

  const handleOnChange = () => {
    dispatchGetAppointment();
    dispatchGetService();
  };

  const renderProposedDates = () => (
    appointmentId ? (
      <div style={{ marginTop: 15 }}>
        {renderProposedDatesHeadline()}
        <ProposedDates
          orderId={orderId}
          objectId={objectId}
          serviceId={serviceId}
          appointmentId={appointmentId}
          onChange={handleOnChange}
        />
        {renderCreateButton()}
      </div>
    ) : null
  );

  const renderContent = () => {
    if (isLoading) {
      return (<div>Loading</div>);
    }
    if (appointmentId || clientId === dealerClientId) {
      return (
        <>
          {renderDetails()}
          {renderProposedDates()}
        </>
      );
    }
    return null;
  };

  const renderCreateAppointmentModal = () => (
    !appointmentId && showCreateAppointment ? (
      <ModalDialog
        visible
        onConfirm={() => dispatchCreateAppointment(date, comment)}
        onClose={() => {
          setDate(null);
          setShowCreateAppointment(false);
        }}
        centered
        confirmClass="btn-success"
        confirmCaption="Starttermin jetzt festlegen"
      >
        <div>
          <h5 className="hk-text-primary">Frühestes Startdatum jetzt setzen?</h5>
          <p>
            Bitte bestätigen Sie, das Sie das Datum
            <span>{` ${moment(date).format('DD.MM.YYYY')} `}</span>
            jetzt als frühst mögliches Startdatum für den
            Service eintragen möchten. Wir informieren den Monteur umgehend, damit dieser möglich schnell mindestens
            2 Terminvorschläge hinterlegen kann! Das Datum kann nach dem bestätigen nicht mehr eigenständig geändert
            werden. Bitte beachten Sie weiterhin, das Sie nach dem Start der Terminfindung keine weiteren Arbeiten
            mehr zu dem Service hinzufügen können!
          </p>
        </div>
      </ModalDialog>
    ) : null);

  const renderCreateProposedDateModal = () => (
    showCreateProposedDate ? (
      <CreateProposedDateModal
        orderId={orderId}
        objectId={objectId}
        serviceId={serviceId}
        appointmentId={appointmentId}
        onSuccess={() => { dispatchGetAppointment(); }}
        onClose={() => { setShowCreateProposedDate(false); }}
      />
    ) : null);

  return (
    <Panel marginBottom={20} className="ProjectInformation">
      {renderHeader()}
      {renderDescription()}
      {renderContent()}
      {renderCreateAppointmentModal()}
      {renderCreateProposedDateModal()}
    </Panel>
  );
}

AppointmentPanel.propTypes = {
  orderId: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  serviceId: PropTypes.number.isRequired,
};

AppointmentPanel.defaultProps = {};
