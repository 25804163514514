export default {
  fontSize: '16px',

  color: {
    primaryColor: '#007bff',
    backgroundColor: '#fff',
    grayBackgroundColor: '#f8f8f8',
    textGray: '#808697',
    textGrayLight: '#E8E8E8',
    textDanger: '#CE143D',
    primaryRedColor: '#e7184e',
    primaryBlueColor: '#00508c',
    primaryGreenColor: '#008C1C',
    primaryYellowColor: '#cccf00',
    primaryOrangeColor: '#f9b30e',
    primaryColorBlueGray: '#44546a',
    borderColorGray: '#cac9c9',
    headlineFontColor: '#000000',
    primaryTextColor: '#6c6c6c',
    darkTextColor: '#000000',
  },

  boxShadowDark: '-webkit-box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);'
    + '-moz-box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);'
    + 'box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);',

  boxShadowDarkHover: '-webkit-box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);'
    + '-moz-box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);'
    + 'box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);',

  border: {
    radius: '6px',
  },
};
