import React from 'react';
import PropTypes from 'prop-types';
import OrderObjectContainer from './OrderObjectContainer';
import { guid } from '../../../../Library/Functions';

export default function OrderObjectsPanel(props) {
  const {
    headline, headlineCounter, onChange, errors,
  } = props;

  const [objects, setObjects] = React.useState([]);

  React.useEffect(() => {
    if (onChange) {
      onChange(objects);
    }
  },
  [objects, onChange]);

  const handleCreateObject = React.useCallback((object) => {
    const newObject = { ...object, id: guid() };
    setObjects([...objects, newObject]);
  }, [setObjects, objects]);

  const handleEditObject = React.useCallback((object) => {
    setObjects([...objects.map((item) => (item.id === object.id ? object : item))]);
  }, [setObjects, objects]);

  const handleDeleteObject = React.useCallback((object) => {
    setObjects([...objects.filter((item) => item.id !== object.id)]);
  }, [setObjects, objects]);

  const renderAddObject = () => (
    <OrderObjectContainer
      key={-1}
      objects={objects}
      headlineCounter={headlineCounter}
      onConfirm={handleCreateObject}
      hasError={errors.objects}
    />
  );

  const renderObjects = () => objects.map(
    (object) => (
      <OrderObjectContainer
        key={object.id}
        initialState={object}
        objects={objects}
        onConfirm={handleCreateObject}
        onEdit={handleEditObject}
        onDelete={handleDeleteObject}
      />
    ),
  );

  return (
    <div className="row">
      <div className="col-lg-12">
        <h5>{headline}</h5>
        <p>
          Bitte fügen Sie hier alle Küchen hinzu die zu diesem Objektauftrag gehören
        </p>
      </div>
      {renderObjects()}
      {renderAddObject()}
    </div>

  );
}

OrderObjectsPanel.propTypes = {
  headline: PropTypes.string,
  headlineCounter: PropTypes.number,
  onChange: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
};

OrderObjectsPanel.defaultProps = {
  headline: 'Küchen',
  headlineCounter: 1,
  onChange: undefined,
  errors: {},
};
