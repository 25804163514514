import axios from '../../../../Library/Axios';

const defaultUri = '/system/user';

export const SYSTEM_USER_GETLIST_START = 'SYSTEM_USER_GETLIST_START';
export const SYSTEM_USER_GETLIST_SUCCESS = 'SYSTEM_USER_GETLIST_SUCCESS';
export const SYSTEM_USER_GETLIST_ERROR = 'SYSTEM_USER_GETLIST_ERROR';

/**
 * getList()
 * @returns {function(*): Promise<any | never>}
 */
export function getList(forceReload = false) {
  return (dispatch, getState) => {
    const { users } = getState().systemUser;

    if (!forceReload && users && users.length <= 0) {
      return Promise.resolve(users);
    }

    dispatch({ type: SYSTEM_USER_GETLIST_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_USER_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_USER_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
