import axios from '../../../Library/Axios';
import { defaultUri } from '../ClientAction';

/**
 * prepareUri()
 * @param clientId
 * @returns {string}
 */
function prepareUri(clientId) {
  return `${defaultUri}/${clientId}/material`;
}

export const CLIENT_MATERIAL_GETLIST_START = 'CLIENT_MATERIAL_GETLIST_START';
export const CLIENT_MATERIAL_GETLIST_SUCCESS = 'CLIENT_MATERIAL_GETLIST_SUCCESS';
export const CLIENT_MATERIAL_GETLIST_ERROR = 'CLIENT_MATERIAL_GETLIST_ERROR';

/**
 * getList()
 * @returns {function(*, *): Promise<unknown>}
 */
export function getList() {
  return (dispatch, getState) => {
    const { clientId } = getState().auth.client;
    dispatch({ type: CLIENT_MATERIAL_GETLIST_START });

    return axios().get(prepareUri(clientId)).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_MATERIAL_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENT_MATERIAL_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_MATERIAL_CREATE_START = 'CLIENT_MATERIAL_CREATE_START';
export const CLIENT_MATERIAL_CREATE_SUCCESS = 'CLIENT_MATERIAL_CREATE_SUCCESS';
export const CLIENT_MATERIAL_CREATE_ERROR = 'CLIENT_MATERIAL_CREATE_ERROR';

/**
 * @param material
 * @returns {function(*, *): *}
 */
export function create(material) {
  return (dispatch, getState) => {
    const { clientId } = getState().auth.client;
    dispatch({ type: CLIENT_MATERIAL_CREATE_START, material });

    return axios().post(prepareUri(clientId), material).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_MATERIAL_CREATE_SUCCESS, payload: data });
      return dispatch(getList()).then(() => Promise.resolve(data));
    }).catch((error) => {
      dispatch({ type: CLIENT_MATERIAL_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_MATERIAL_UPDATE_START = 'CLIENT_MATERIAL_UPDATE_START';
export const CLIENT_MATERIAL_UPDATE_SUCCESS = 'CLIENT_MATERIAL_UPDATE_SUCCESS';
export const CLIENT_MATERIAL_UPDATE_ERROR = 'CLIENT_MATERIAL_UPDATE_ERROR';

/**
 * @param material
 * @returns {function(*, *): *}
 */
export function update(material) {
  return (dispatch, getState) => {
    const { clientId } = getState().auth.client;
    dispatch({ type: CLIENT_MATERIAL_UPDATE_START, material });

    const uri = `${prepareUri(clientId)}/${material.materialId}`;

    return axios().put(uri, material).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_MATERIAL_UPDATE_SUCCESS, payload: data });
      return dispatch(getList()).then(() => Promise.resolve(data));
    }).catch((error) => {
      dispatch({ type: CLIENT_MATERIAL_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_MATERIAL_DELETE_START = 'CLIENT_MATERIAL_DELETE_START';
export const CLIENT_MATERIAL_DELETE_SUCCESS = 'CLIENT_MATERIAL_DELETE_SUCCESS';
export const CLIENT_MATERIAL_DELETE_ERROR = 'CLIENT_MATERIAL_DELETE_ERROR';

/**
 * @param material
 * @returns {function(*, *): *}
 */
export function deleteMaterial(material) {
  return (dispatch, getState) => {
    const { clientId } = getState().auth.client;
    dispatch({ type: CLIENT_MATERIAL_DELETE_START, material });

    const uri = `${prepareUri(clientId)}/${material.materialId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_MATERIAL_DELETE_SUCCESS, payload: data });
      return dispatch(getList()).then(() => Promise.resolve(data));
    }).catch((error) => {
      dispatch({ type: CLIENT_MATERIAL_DELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
