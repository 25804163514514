import {
  APPOINTMENT_CREATE_ERROR, APPOINTMENT_CREATE_START,
  APPOINTMENT_CREATE_SUCCESS,
  APPOINTMENT_GET_ERROR, APPOINTMENT_GET_START, APPOINTMENT_GET_SUCCESS, APPOINTMENT_UPDATE_SUCCESS,
} from '../Action/Order/Object/Service/AppointmentAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';
import {
  PROPOSED_DATE_GET_LIST_SUCCESS,
} from '../Action/Order/Object/Service/Appointment/ProposedDateAction';

const defaultValues = {
  current: { id: null },
  error: null,
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case APPOINTMENT_CREATE_START:
    case APPOINTMENT_GET_START: {
      if (action.payload) {
        const { clear } = action.payload;
        if (clear) {
          return { ...defaultValues, isLoading: true };
        }
      }
      return { ...state, isLoading: true };
    }

    case APPOINTMENT_CREATE_SUCCESS:
    case APPOINTMENT_GET_SUCCESS:
    case APPOINTMENT_UPDATE_SUCCESS: {
      const { payload } = action;
      return { ...state, isLoading: false, current: payload };
    }

    case APPOINTMENT_CREATE_ERROR:
    case APPOINTMENT_GET_ERROR: {
      const { payload } = action;
      return { ...state, isLoading: false, error: payload };
    }

    case PROPOSED_DATE_GET_LIST_SUCCESS: {
      const { current } = state;
      const { payload } = action;
      return { ...state, current: { ...current, proposedDates: payload } };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
