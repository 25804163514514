import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Panel from '../../../Components/Panel';
import MapMarker from '../../../Components/MapMarker';
import ModalDialog from '../../../Components/ModalDialog';
import { CLIENTTYPE_MONTEUR } from '../../../Library/Types';

import './style.css';

/**
 * Address()
 * @param props
 * @returns {*}
 * @constructor
 */
function Address(props) {
  const {
    item, onDelete, onEdit, client,
  } = props;
  const [deleteVisible, setDeleteVisible] = useState(false);

  const getAddressPosition = () => {
    if (item.latitude && item.longitude) {
      return { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) };
    }
    return null;
  };

  const renderRadius = () => {
    if (client.type === CLIENTTYPE_MONTEUR) {
      if (item.radius && item.radius > 0) {
        return (
          <div>{`Umkreissuche / Radius: ${item.radius}km`}</div>
        );
      }
      return (
        <div>Umkreissuche: Deaktiviert</div>
      );
    }
    return null;
  };

  return (
    <Panel marginBottom={25}>
      <div className="AddressContainer">
        <div className="MapContainer">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBgsVfRCdiXwfSxtNcKFgj_jdBT5l_7Q8Y' }}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={getAddressPosition()}
            defaultZoom={15}
            defaultOptions={{ fullscreenControl: false }}
          >
            <MapMarker {...getAddressPosition()} />
          </GoogleMapReact>
        </div>
        <div className="Address">
          <div className="AddressName">
            <strong>
              {item.name}
            </strong>
          </div>
          <div style={{ fontSize: 14, marginBottom: 10 }}>
            {`Standort-ID: ${item.addressId}`}
          </div>
          <div>{`${item.zip} ${item.city}`}</div>
          <div>{`${item.street} ${item.streetNo}`}</div>
          <div>{`${item.contactName} (${item.contactPhone})`}</div>
          {renderRadius()}
          <div>{item.comment}</div>
        </div>
        <div className="ActionButtons">
          <i
            className="fas fa-pencil-alt primary"
            onClick={() => onEdit(item)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
            style={{ marginRight: 20 }}
          />
          <i
            className="fas fa-trash danger"
            onClick={() => setDeleteVisible(true)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
          />
        </div>
      </div>

      <ModalDialog
        onClose={() => setDeleteVisible(false)}
        onConfirm={() => onDelete(item)}
        confirmCaption="Löschen"
        visible={deleteVisible}
        centered
      >
        <h5>Lageradresse löschen?</h5>
        <p>
          Wollen Sie die ausgewählte Adresse wirklich löschen?
        </p>
      </ModalDialog>
    </Panel>
  );
}

Address.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

/**
 * UserTable()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function AddressPanel(props) {
  const {
    addresses, onDelete, onEdit, client,
  } = props;

  if (addresses) {
    return addresses.map((item) => (
      <Address
        key={item.addressId}
        item={item}
        onDelete={onDelete}
        onEdit={onEdit}
        client={client}
      />
    ));
  }
  return null;
}

AddressPanel.propTypes = {
  addresses: PropTypes.instanceOf(Array),
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

AddressPanel.defaultProps = {
  addresses: null,
};
