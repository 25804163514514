import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '../../Panel';

import './style.css';
import { getDistanceFromGeoPoints } from '../../../Library/Functions';

/**
 * FilterCard()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function FilterCard(props) {
  const {
    caption, selected, onClick, visible, title,
  } = props;

  if (visible) {
    return (
      <div
        className={`FilterCard ${(selected) ? 'Selected' : null}`}
        onClick={onClick}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
        title={title}
      >
        {caption}
      </div>
    );
  }
  return null;
}

FilterCard.propTypes = {
  caption: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  title: PropTypes.string,
};

FilterCard.defaultProps = {
  selected: false,
  visible: true,
  title: '',
};

const defaultState = {
  montage: true,
  removal: true,
  delivery: true,
  storage: true,
  filtered: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'reset': {
      return { ...defaultState };
    }
    case 'init': return { ...action.payload };
    case 'removal': return { ...state, removal: !state.removal };
    case 'delivery': return { ...state, delivery: !state.delivery };
    case 'storage': return { ...state, storage: !state.storage };
    case 'filtered': return { ...state, filtered: action.payload };

    default:
      throw new Error();
  }
}

/**
 * Filter()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Filter(props) {
  const {
    orders, onChange, clientAddresses,
  } = props;
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [prevState, setPrevState] = useState(JSON.stringify(state));
  const [init, setInit] = useState(false);

  const filterOrders = React.useCallback(() => {
    if (clientAddresses && orders) {
      if (state.filtered) {
        const filteredOrderIds = [];
        let filteredOrders = [];

        clientAddresses.forEach((addressItem) => {
          let filtered = orders.filter((orderItem) => {
            const result = getDistanceFromGeoPoints(
              parseFloat(addressItem.latitude),
              parseFloat(addressItem.longitude),
              parseFloat(orderItem.customerLatitude),
              parseFloat(orderItem.customerLongitude),
            );
            if (parseFloat(result) <= parseFloat(addressItem.radius)) {
              if (filteredOrderIds.indexOf(orderItem.orderId) < 0) {
                filteredOrderIds.push(orderItem.orderId);
                return true;
              }
            }
            return false;
          });

          // Set the found address to the order to show a hint where the order is near by in find external order list!
          // Do this only if the client has more than one address saved to show him to which of the assigned address
          // the order is near by
          if (clientAddresses.length > 1) {
            filtered = filtered.map((item) => ({ ...item, nearByAddress: addressItem }));
          }

          filteredOrders = [...filteredOrders, ...filtered];
        });

        filteredOrders = [...new Set(filteredOrders)];

        const sorted = filteredOrders.sort((a, b) => {
          if (a.deliveryDateStart > b.deliveryDateStart) return -1;
          if (b.deliveryDateStart > a.deliveryDateStart) return 1;
          return 0;
        });

        return sorted.reverse();
      }
    }

    return orders;
  }, [orders, state.filtered, clientAddresses]);

  useEffect(() => {
    setInit(false);
  }, [orders]);

  useEffect(() => {
    if (JSON.stringify(state) !== prevState || !init) {
      let filterArray = null;

      if (orders) {
        filterArray = filterOrders();

        filterArray = (!state.delivery) ? filterArray.filter((item) => !item.optionDelivery) : filterArray;
        filterArray = (!state.storage) ? filterArray.filter((item) => !item.optionStorage) : filterArray;
        filterArray = (!state.removal) ? filterArray.filter((item) => !item.optionRemoval) : filterArray;
      }

      setInit(true);
      setPrevState(JSON.stringify(state));
      if (onChange) {
        onChange(filterArray, state.orders, state.reclamations);
      }
    }
  }, [state, onChange, orders, prevState, init, filterOrders]);

  const renderInRangeFilterOptions = () => {
    if (clientAddresses && clientAddresses.length > 0) {
      return (
        <>
          <div className="Spacer" />

          <div className="d-flex flex-wrap">
            <FilterCard
              caption="Nur Aufträge in der Nähe anzeigen"
              selected={state.filtered}
              onClick={() => dispatch({ type: 'filtered', payload: true })}
              title="Nur Aufträge im vorgegebenen Radius meiner Standorte"
            />
            <FilterCard
              caption="Alle Aufträge anzeigen"
              selected={!state.filtered}
              onClick={() => dispatch({ type: 'filtered', payload: false })}
              title="Alle Aufträge anzeigen"
            />
          </div>
        </>
      );
    }
    return null;
  };

  const renderOrderFilters = () => (
    <>
      <div className="Spacer" />

      <div className="d-flex flex-wrap">
        <FilterCard
          caption="Demontage"
          selected={state.removal}
          onClick={() => dispatch({ type: 'removal' })}
          title="Aufträge ausblenden mit der Option Demontage"
        />
        <FilterCard
          caption="Lagerung"
          selected={state.storage}
          onClick={() => dispatch({ type: 'storage' })}
          title="Aufträge ausblenden mit der Option Lagerung"
        />
        <FilterCard
          caption="Lieferung"
          selected={state.delivery}
          onClick={() => dispatch({ type: 'delivery' })}
          title="Aufträge ausblenden mit der Option Lieferung"
        />
        {renderInRangeFilterOptions()}
      </div>
    </>
  );

  return (
    <Panel marginBottom={20}>
      <div className="FilterCardContainer">
        <div className="Caption">
          Filter
        </div>

        {renderOrderFilters()}
      </div>
    </Panel>
  );
}

Filter.propTypes = {
  orders: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
  clientAddresses: PropTypes.instanceOf(Array),
};

Filter.defaultProps = {
  clientAddresses: null,
};
