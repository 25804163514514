import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Panel from '../../../../Components/Panel';
import { isExternalOrderOwner } from '../../../../Library/Functions';
import Button from '../../../../Components/Button';
import { deleteOrder } from '../../../../Redux/Action/OrderAction';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import { ORDERSTATE_OPEN } from '../../../../Library/StateTypes';

/**
 * OrderHeadline()
 * @param props
 * @returns {*}
 * @constructor
 */
function OrderHeadline(props) {
  const {
    order, client, users, dispatchDeleteOrder, history,
  } = props;
  const [showAskDelete, setShowAskDelete] = useState(false);

  const handleDelete = () => {
    dispatchDeleteOrder(order).then(() => {
      history.push('/dashboard');
    });
  };

  const renderCreator = () => {
    if (order.internal || isExternalOrderOwner(client.clientId, order)) {
      if (order.createdId && users) {
        const user = users.filter((item) => item.userId === order.createdId)[0];
        if (user) {
          return (
            <div>{`Auftrag erstellt von ${user.firstname} ${user.lastname}`}</div>
          );
        }
      }
    }
    return null;
  };

  const renderDeleteIcon = () => {
    if (order && order.internal && (order.state <= ORDERSTATE_OPEN)) {
      return (
        <Button
          onClick={() => setShowAskDelete(true)}
          type="btn-danger"
          style={{ marginLeft: 10 }}
          title="Auftrag löschen"
        >
          <i className="fas fa-trash" style={{ margin: 0 }} />
        </Button>
      );
    }
    return null;
  };

  if (order) {
    return (
      <Panel marginBottom={20}>
        <div className="d-flex">
          <div className="flex-grow-1 d-flex flex-column">
            <h4
              className="PrimaryHeadline d-flex align-items-center"
              style={{ marginBottom: 0 }}
            >
              {`Objektauftrag - ${(order.externalId) || order.orderId}`}
            </h4>
            {renderCreator()}
          </div>
          <div>
            {renderDeleteIcon()}
          </div>
        </div>

        <ConfirmDeleteDialog
          onConfirm={handleDelete}
          onClose={() => setShowAskDelete(false)}
          visible={showAskDelete}
        />
      </Panel>
    );
  }
  return null;
}

OrderHeadline.propTypes = {
  dispatchDeleteOrder: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
};

OrderHeadline.defaultProps = {
  client: null,
  users: null,
  order: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    users: store.user.users,
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDeleteOrder: (order) => dispatch(deleteOrder(order)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(OrderHeadline));
