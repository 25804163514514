import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '../../../../Components/Panel';
import {
  CLIENTTYPE_DEALER,
  CLIENTTYPE_MONTEUR,
} from '../../../../Library/Types';
import Input from '../../../../Components/Input';
import { getListFreeWithOrders } from '../../Redux/Action/ClientAction';

import './style.css';

const defaultState = {
  dealer: false,
  freeOfCharge: false,
  monteur: false,
  searchValue: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'reset': {
      return { ...defaultState };
    }
    case 'init':
      return {
        ...action.payload,
      };

    case 'dealer':
      return {
        ...state, monteur: false, freeOfCharge: false, dealer: !state.dealer,
      };
    case 'freeOfCharge':
      return {
        ...state, dealer: false, monteur: false, freeOfCharge: !state.freeOfCharge,
      };
    case 'monteur':
      return {
        ...state, monteur: !state.monteur, dealer: false, freeOfCharge: false,
      };
    case 'searchValue':
      return {
        ...state, searchValue: action.payload,
      };

    default:
      throw new Error();
  }
}

const storageSaveKey = '@systemClientFilter';

/**
 * ClientFilter()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ClientFilter(props) {
  const {
    clients, onChange,
  } = props;
  const [clientsFreeOfCharge, setClientsFreeOfCharge] = useState(null);
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [prevState, setPrevState] = useState(JSON.stringify(state));
  const [init, setInit] = useState(false);

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem(storageSaveKey));
    if (savedState) {
      dispatch({ type: 'init', payload: { ...savedState, searchValue: '' } });
    }
  }, []);

  useEffect(() => {
    setInit(false);
  }, [clients]);

  useEffect(() => {
    if (JSON.stringify(state) !== prevState || !init) {
      let filterArray = null;

      if (clients) {
        filterArray = clients;

        filterArray = (state.monteur) ? filterArray.filter((item) => item.type === CLIENTTYPE_MONTEUR) : filterArray;
        filterArray = (state.dealer) ? filterArray.filter((item) => item.type === CLIENTTYPE_DEALER) : filterArray;
        filterArray = (state.freeOfCharge) ? clientsFreeOfCharge : filterArray;

        if (state.searchValue && state.searchValue.length > 0) {
          filterArray = filterArray.filter((item) => (
            item.name.toLowerCase().indexOf(state.searchValue.toLowerCase()) > -1
            || item.clientId.toString().indexOf(state.searchValue) > -1
          ));
        }
      }

      setInit(true);
      setPrevState(JSON.stringify(state));
      localStorage.setItem(storageSaveKey, JSON.stringify(state));
      if (onChange) {
        onChange(filterArray, state.orders, state.reclamations);
      }
    }
  }, [state, onChange, clients, prevState, init, clientsFreeOfCharge]);

  useEffect(() => {
    if (state.freeOfCharge && !clientsFreeOfCharge) {
      getListFreeWithOrders().then((response) => {
        setInit(false);
        setClientsFreeOfCharge(response);
      });
    }
  }, [state.freeOfCharge, clientsFreeOfCharge]);

  const renderOrderFilters = () => (
    <>
      <div className="Spacer" />

      <div className="d-flex flex-wrap">
        <FilterCard
          caption="Verkäufer"
          selected={state.dealer}
          onClick={() => dispatch({ type: 'dealer' })}
        />
        <FilterCard
          caption="Monteure"
          selected={state.monteur}
          onClick={() => dispatch({ type: 'monteur' })}
        />
        <FilterCard
          caption="Neukunden mit min. einem Auftrag"
          selected={state.freeOfCharge}
          onClick={() => dispatch({ type: 'freeOfCharge' })}
        />

      </div>
    </>
  );

  return (
    <Panel marginBottom={20}>
      <div className="FilterCardContainer">
        <div className="Caption">
          Filter
        </div>

        <div className="flex-grow-1 align-content-center d-flex">
          {renderOrderFilters()}
        </div>

        <div className="align-items-center align-content-center d-flex flex-row" style={{ width: 200 }}>
          <Input
            onChange={(value) => dispatch({ type: 'searchValue', payload: value })}
            value={state.searchValue}
            style={{ marginBottom: 0 }}
            placeholder="Suchen..."
            rightIcon="fas fa-search"
          />
        </div>
      </div>
    </Panel>
  );
}

ClientFilter.propTypes = {
  clients: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
};

ClientFilter.defaultProps = {

};

/**
 * FilterCard()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function FilterCard(props) {
  const {
    caption, selected, onClick, visible,
  } = props;

  if (visible) {
    return (
      <div
        className={`FilterCard ${(selected) ? 'Selected' : null}`}
        onClick={onClick}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
      >
        {caption}
      </div>
    );
  }
  return null;
}

FilterCard.propTypes = {
  caption: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

FilterCard.defaultProps = {
  selected: false,
  visible: true,
};
