import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { formatCurrency } from '../../../../Library/Functions';
import Panel from '../../../../Components/Panel';
import {
  DEVICETYPE_DAMAGE,
  DEVICETYPE_RECLAMATION, DEVICETYPE_SERVICE, OBJECT_SERVICE_TYPE_RENTAL_KITCHEN,
} from '../../../../Library/Types';
import CheckBoxRow from '../../../../Components/ReportComponents/CheckBoxRow';

/**
 * ReportOverview()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function ReportOverview(props) {
  const { report, object, service } = props;
  const [reclamations, setReclamations] = useState([]);
  const [damages, setDamages] = useState([]);
  const [checkWater, setCheckWater] = useState(false);
  const [checkElectro, setCheckElectro] = useState(false);

  useEffect(() => {
    if (report) {
      setReclamations(report.items.filter((item) => item.type === DEVICETYPE_RECLAMATION));
      setDamages(report.items.filter((item) => item.type === DEVICETYPE_DAMAGE));

      report.items.forEach((item) => {
        if (item.type === DEVICETYPE_SERVICE) {
          if (item.workOptionElectric) {
            setCheckElectro(true);
          }
          if (item.workOptionWater) {
            setCheckWater(true);
          }
        }
      });
    }
  }, [report]);

  const renderPlugInformation = () => {
    if (service.type === OBJECT_SERVICE_TYPE_RENTAL_KITCHEN) {
      return (
        <CheckBoxRow visible>
          Alle Anschlüsse wurden im Beisein des Kunden überprüft
        </CheckBoxRow>
      );
    }
    return null;
  };

  const renderWaterAndElectricHint = () => {
    if (service.type === OBJECT_SERVICE_TYPE_RENTAL_KITCHEN) {
      return null;
    }

    let caption = null;
    if (checkElectro && !checkWater) {
      caption = 'Alle Elektroanschlüsse wurden im Beisein des Kunden überprüft';
    } else if (!checkElectro && checkWater) {
      caption = 'Alle Wasseranschlüsse wurden im Beisein des Kunden überprüft';
    } else if (checkElectro && checkWater) {
      caption = 'Alle Anschlüsse wurden im Beisein des Kunden überprüft';
    }

    if (caption) {
      return (
        <CheckBoxRow visible>
          {caption}
        </CheckBoxRow>
      );
    }
    return null;
  };

  if (report && report.finished && service) {
    return (
      <Panel marginBottom={25}>
        <h6>Übersicht</h6>
        <p style={{ marginBottom: 30 }}>
          Hier sehen Sie eine Zusammenfassung des Serviceberichts
        </p>
        <table className="table-borderless">
          <tbody>
            {renderPlugInformation()}
            {renderWaterAndElectricHint()}
            <CheckBoxRow visible={reclamations.length === 0}>
              Es sind keine neuen Beanstandungen vorhanden
            </CheckBoxRow>
            <CheckBoxRow visible={reclamations.length > 0} type="danger">
              <strong>Es sind neue Beanstandungen vorhanden</strong>
            </CheckBoxRow>
            <CheckBoxRow visible={damages.length === 0}>
              Es wurde kein Kundeneigentum beschädigt
            </CheckBoxRow>
            <CheckBoxRow visible={damages.length > 0} type="danger">
              <strong>Es wurde Kundeneigentum beschädigt</strong>
            </CheckBoxRow>

            <CheckBoxRow visible={(report.customerRestValue === 0 && object.balance > 0)}>
              Der offene Betrag wurde komplett beglichen
            </CheckBoxRow>
            <CheckBoxRow visible={(report.customerRestValue > 0 && object.balance > 0)} type="danger">
              <strong>
                {'Es bleibt eine zu zahlender Restbetrag in Höhe '}
                {`von ${formatCurrency(report.customerRestValue)} offen`}
              </strong>
            </CheckBoxRow>
            <CheckBoxRow visible>
              Die einwandfreie Ausführung der Leistung wird hiermit bescheinigt.
            </CheckBoxRow>
          </tbody>
        </table>
      </Panel>
    );
  }
  return null;
}

ReportOverview.propTypes = {
  report: PropTypes.instanceOf(Object),
  object: PropTypes.instanceOf(Object),
  service: PropTypes.instanceOf(Object),
};

ReportOverview.defaultProps = {
  report: null,
  object: null,
  service: null,
};
