import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthedNavigation from '../../Navigation/AuthedNavigation';

import './index.css';

/**
 * RightNavigationLayout
 * @param props
 * @returns {*}
 * @constructor
 */
function RightNavigationLayout(props) {
  const { navigation, children, isLoggedin } = props;

  if (isLoggedin) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <AuthedNavigation />
        <div className="container-fluid container-fluid-extended">
          <div className="RightNavigationContainer">
            <div className="Menu">
              {navigation}
            </div>
            <div className="Content">
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <Redirect to="/login" />;
}

RightNavigationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navigation: PropTypes.node.isRequired,
  isLoggedin: PropTypes.bool.isRequired,
};

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
  };
}

export default connect(mapStoreToProps, () => ({}))(RightNavigationLayout);
