import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Toolipp from 'react-tooltip-lite';
import Panel from '../Panel';
import OrderOptionIcons from '../OrderComponents/OrderOptionIcons';
import StatusBadge from '../StatusBadge';
import { formatCurrency, formatDate } from '../../Library/Functions';
import ShowHideContainer from '../ShowHideContainer';
import { ORDERSTATE_WAITING_CALCULATION } from '../../Library/StateTypes';

/**
 * FindExternalOrderMap()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function FindExternalOrderMap(props) {
  const {
    client, orders, clientAddresses, onPressOrder,
  } = props;
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    window.addEventListener('resize', () => setWindowHeight(window.innerHeight));
  }, []);

  const renderToolTip = (order, customer) => (
    <div className="d-flex flex-column justify-content-center align-items-center align-content-center">
      <div style={{ fontSize: 16 }}>
        <strong>
          {`${customer.zip} ${customer.city}`}
        </strong>
      </div>
      <div>
        {formatDate(order.deliveryDateStart)}
      </div>
      <div style={{ marginTop: 10 }}>
        <StatusBadge caption={<OrderOptionIcons order={order} withMarginRight />} />
      </div>
      <ShowHideContainer visible={order.state !== ORDERSTATE_WAITING_CALCULATION}>
        <div style={{ marginTop: 10 }}>
          <StatusBadge caption={formatCurrency(order.mechanicPrice)} type="Success" />
        </div>
      </ShowHideContainer>
      <ShowHideContainer visible={order.state === ORDERSTATE_WAITING_CALCULATION}>
        <div style={{ marginTop: 10 }}>
          <StatusBadge caption="Angebot abgeben" type="Primary" />
        </div>
      </ShowHideContainer>
    </div>
  );

  const renderOrderAddressMarkers = () => {
    if (orders) {
      return orders.map((order) => {
        const {
          customerLatitude, customerLongitude, city, zip,
        } = order;

        return (
          <Toolipp
            content={renderToolTip(order, { city, zip })}
            lat={customerLatitude}
            lng={customerLongitude}
            key={order.orderId}
          >
            <i
              className="fas fa-tools"
              style={{ fontSize: '18px', color: '#e7184e' }}
              onClick={() => onPressOrder(order)}
              tabIndex={-1}
              role="button"
              onKeyPress={() => {}}
            />
          </Toolipp>
        );
      });
    }
    return null;
  };

  const renderLocationTooltip = (address) => (
    <div className="d-flex flex-column justify-content-center align-items-center align-content-center">
      <div style={{ marginBottom: 8 }}>Ihr Standort</div>
      <div style={{ fontSize: 16 }}>
        <strong>{address.name}</strong>
      </div>
      <div>{`${address.street} ${address.streetNo}`}</div>
      <div>{`${address.zip} ${address.city}`}</div>
    </div>
  );

  const renderClientLocations = () => {
    if (clientAddresses && clientAddresses.length > 0) {
      return clientAddresses.map((item) => (
        <Toolipp
          content={renderLocationTooltip(item)}
          lat={item.latitude}
          lng={item.longitude}
          key={item.addressId}
        >
          <i
            className="fas fa-warehouse"
            style={{ fontSize: '18px', color: '#e7184e' }}
          />
        </Toolipp>
      ));
    }
    return null;
  };

  const getDefaultCenter = () => {
    if (client && client.latitude && client.longitude) {
      return { lat: parseFloat(client.latitude), lng: parseFloat(client.longitude) };
    }
    return { lat: 51, lng: 10 };
  };

  return (
    <Panel
      marginBottom={20}
      boxPadding={0}
      containerStyle={{ display: 'flex', height: windowHeight - 490, minHeight: 500 }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBgsVfRCdiXwfSxtNcKFgj_jdBT5l_7Q8Y' }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={getDefaultCenter()}
          defaultZoom={11}
          zoom={11}
          options={{ maxZoom: 11, minZoom: 6 }}
          zoomControl={false}
        >
          {renderOrderAddressMarkers()}
          {renderClientLocations()}
        </GoogleMapReact>
      </div>
    </Panel>
  );
}

FindExternalOrderMap.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  orders: PropTypes.instanceOf(Array).isRequired,
  onPressOrder: PropTypes.func.isRequired,
  clientAddresses: PropTypes.instanceOf(Array),
};

FindExternalOrderMap.defaultProps = {
  clientAddresses: null,
};
