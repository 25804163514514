import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import ModalDialog from '../ModalDialog';
import Input from '../Input';
import Textarea from '../Textarea';
import DatePickerInput from '../DatePickerInput';
import CustomReactSelect from '../CustomReactSelect';
import { taskPrioOptions, timeOptions } from '../../Library/Types';
import { getList, create } from '../../Redux/Action/User/TaskAction';
import { getAll } from '../../Redux/Action/UserAction';

const defaultState = {
  assignedUserId: null,
  orderId: null,
  objectId: null,
  title: '',
  description: '',
  dueDate: null,
  dueTime: null,
  priority: 4,
};

function reducer(state, action) {
  switch (action.type) {
    case 'orderId': return { ...state, orderId: action.payload };
    case 'objectId': return { ...state, objectId: action.payload.objectId, orderId: action.payload.orderId };
    case 'title': return { ...state, title: action.payload };
    case 'description': return { ...state, description: action.payload };
    case 'dueDate': return { ...state, dueDate: action.payload };
    case 'dueTime': return { ...state, dueTime: action.payload };
    case 'priority': return { ...state, priority: action.payload };
    case 'assignedUserId': return { ...state, assignedUserId: action.payload };

    case 'clear': return { ...defaultState };

    default:
      throw new Error();
  }
}

/**
 * CreateTaskModal()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function CreateTaskModal(props) {
  const {
    visible, onClose, dispatchCreate, user, dispatchGetList, dispatchGetAllUsers, users, object, initialTitle,
    initialDescription,
  } = props;
  const [state, dispatch] = React.useReducer(reducer, defaultState, () => (defaultState));
  const [timeOptionsModified, setTimeOptionsModified] = React.useState(timeOptions);
  const [loading, setLoading] = React.useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [errors, setErrors] = React.useState({});

  const isValid = React.useCallback(() => {
    let error = {};
    if (!state.title || state.title.length === 0) {
      error = { ...error, title: true };
    }
    if (!state.dueDate || state.title.dueDate === 0) {
      error = { ...error, dueDate: true };
    }

    setErrors(error);
    return (Object.keys(error).length === 0);
  }, [state]);

  React.useEffect(() => {
    if (initialTitle) {
      dispatch({ type: 'title', payload: initialTitle });
    }
    if (initialDescription) {
      dispatch({ type: 'description', payload: initialDescription });
    }
  }, [initialTitle, initialDescription]);

  React.useEffect(() => {
    dispatchGetAllUsers();
  }, [dispatchGetAllUsers]);

  React.useEffect(() => {
    if (object) {
      dispatch({ type: 'objectId', payload: object });
    }
  }, [object]);

  React.useEffect(() => {
    if (users && users.length > 0) {
      const options = users.map((item) => ({
        value: item.userId,
        label: `${item.lastname}, ${item.firstname} (${item.initials})`,
      }));
      setSelectOptions(options);
    }
  }, [users]);

  React.useEffect(() => {
    const newTime = [...timeOptions];
    newTime.splice(0, 1);
    setTimeOptionsModified(newTime);
  }, []);

  const handleClose = () => {
    setErrors({});
    dispatch({ type: 'clear' });
    onClose();
  };

  const handleCreate = () => {
    if (isValid()) {
      setLoading(true);
      const mapped = { ...state, createdUserId: user.userId };

      dispatchCreate(user, mapped).then(() => dispatchGetList(user).then(() => {
        handleClose();
        setLoading(false);
      })).catch(() => setLoading(false));
    }
  };

  const renderObjectHeader = () => {
    if (object) {
      return (
        <div className="alert alert-dark">
          Diese Aufgabe bezieht sich auf Kommission
          <strong>{` "${object.externalId}" `}</strong>
        </div>
      );
    }
    return null;
  };

  const renderNotificationHint = () => {
    if (state.assignedUserId) {
      return (
        <div className="alert alert-dark">
          Wenn Sie eine Aufgabe einem anderen Mitarbeiter zuweisen, erhält dieser eine Mitteilung über die Erstellung
          der neue Aufgabe. Sie werden per Mitteilung informiert, sobald die Aufgabe abgeschlossen wurde!
        </div>
      );
    }
    return null;
  };

  return (
    <ModalDialog
      onClose={handleClose}
      visible={visible}
      confirmCaption="Aufgabe erstellen"
      confirmClass="btn-success"
      onConfirm={handleCreate}
      disableConfirm={loading}
    >
      <h5>Aufgabe erstellen</h5>

      <hr />

      {renderObjectHeader()}
      <div className="row">
        <Input
          onChange={(data) => dispatch({ type: 'title', payload: data })}
          value={state.title}
          label="Aufgabentitel"
          autoFocus
          placeholder="Kurzer Titel der Aufgabe"
          rowClass="col-lg-12"
          hasError={!!errors.title}
        />
        <Textarea
          onChange={(data) => dispatch({ type: 'description', payload: data })}
          value={state.description}
          rows={4}
          placeholder="Weitere Informationen oder Beschreibungen zur Aufgabe"
          rowClass="col-lg-12"
        />
        <DatePickerInput
          onChange={(data) => dispatch({ type: 'dueDate', payload: data })}
          value={state.dueDate}
          rowClass="col-lg-6"
          placeholder="Aufgabe fällig am..."
          rightIcon="fas fa-calendar-alt"
          hasError={!!errors.dueDate}
          minDate={moment().toDate()}
        />
        <CustomReactSelect
          onChange={(data) => dispatch({ type: 'dueTime', payload: data })}
          value={state.dueTime}
          options={timeOptionsModified}
          placeholder="Aufgabe fällig um..."
          rowClass="col-lg-6"
        />
        <CustomReactSelect
          options={taskPrioOptions}
          value={state.priority}
          onChange={(data) => dispatch({ type: 'priority', payload: data })}
          placeholder="Priorität wählen..."
          rowClass="col-lg-6"
        />
        <CustomReactSelect
          options={selectOptions}
          value={state.assignedUserId}
          onChange={(data) => dispatch({ type: 'assignedUserId', payload: data })}
          placeholder="Benutzer zuweisen..."
          rowClass="col-lg-6"
        />
      </div>
      {renderNotificationHint()}
    </ModalDialog>
  );
}

CreateTaskModal.propTypes = {
  dispatchCreate: PropTypes.func.isRequired,
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGetAllUsers: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  users: PropTypes.instanceOf(Array),
  object: PropTypes.instanceOf(Object),
  initialTitle: PropTypes.string,
  initialDescription: PropTypes.string,
};

CreateTaskModal.defaultProps = {
  visible: false,
  users: null,
  object: null,
  initialTitle: null,
  initialDescription: null,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
    users: store.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreate: (user, task) => dispatch(create(user, task)),
    dispatchGetList: (user) => dispatch(getList(user)),
    dispatchGetAllUsers: () => dispatch(getAll()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(CreateTaskModal));
