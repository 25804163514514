import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../Panel';

/**
 * StatusInProgress()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function StatusInProgress(props) {
  const { report } = props;

  if (report && !report.finsihed && report.inProgress) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Panel marginBottom={25} withPadding={false}>
            <div className="alert alert-warning d-flex flex-row" style={{ margin: 0 }}>
              <div style={{ marginRight: 15 }}>
                <i className="fa fa-spin fa-spinner" />
              </div>
              <div>
                Der Bericht wird gerade bearbeitet und ist noch nicht abgeschlossen! Sie können hier in Echtzeit
                alle aktuellen Änderungen verfolgen.
              </div>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
  return null;
}

StatusInProgress.propTypes = {
  report: PropTypes.instanceOf(Object),
};

StatusInProgress.defaultProps = {
  report: null,
};
