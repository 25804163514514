import React from 'react';
import { useSelector } from 'react-redux';
import Panel from '../../../../Components/Panel';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';

/**
 * ExternalApiSettings()
 * @returns {JSX.Element}
 * @constructor
 */
export default function ExternalApiSettings() {
  const { settings } = useSelector((state) => state.client.client);

  if (settings.allowUseExternalApi) {
    return (
      <div className="row">
        <div className="col-sm-12">
          <Panel marginBottom={25}>
            <h6>
              Externe hey.kitchen Schnittstelle
            </h6>
            <p style={{ marginBottom: 40 }}>
              Für die Verwendung der externen Schnittstelle von hey.kitchen benötigen Sie einen Zugriffsschlüssel.
              Dieser Schlüssel wir automatisch generiert. Bei jeder Anfrage, die Sie an die externe Schnittstelle
              von hey.kitchen sende, muss dieser Schlüssel im Authorization-Header mit gesendet werden.
            </p>

            <KeyValueEditRow
              title="Zugriffsschlüssel"
              type="textarea"
              displayText={settings.externalApiToken}
            />
          </Panel>
        </div>
      </div>
    );
  }
  return null;
}
