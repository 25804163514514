import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import SelectPanel from '../../SelectPanels';
import Input from '../../Input';

export const orderOptions = [
  {
    caption: 'Montage',
    icon: 'fas fa-tools',
    key: 'MONTAGE',
  },
  {
    caption: 'Lieferung',
    icon: 'fas fa-truck-moving',
    key: 'DELIVERY',
  },
  {
    caption: 'Lagerung',
    icon: 'fas fa-cubes',
    key: 'STORAGE',
  },
  {
    caption: 'Demontage/Entsorgung',
    icon: 'fas fa-trash-alt',
    key: 'REMOVAL',
  },
];

/**
 * SelectOrderOptions()
 * @param props
 * @returns {*}
 * @constructor
 */
function SelectOrderOptions(props) {
  const {
    internal, errors, headline, onChangeRemovalMeter, removalMeter, mandantId, partners,
    isStorage, isDelivery, isRemoval, isMontage, onMontage, onDelivery, onRemoval, onStorage,
  } = props;
  const [options, setOptions] = useState(null);
  const [showStorage, setShowStorage] = React.useState(false);
  const [storageCaption, setStorageCaption] = React.useState('Lagerung');
  const [selected, setSelected] = React.useState(['MONTAGE']);

  React.useEffect(() => {
    setStorageCaption('Lagerung');

    if (internal) {
      if (mandantId > 0) {
        const filteredPartner = partners.filter((item) => item.partner.clientId === mandantId)[0];
        if (filteredPartner) {
          if (filteredPartner.partnerSettings.allowStorage) {
            setShowStorage(true);
          } else {
            setShowStorage(false);
          }
        }
      } else {
        const filteredPartners = partners.filter((item) => item.partnerSettings.allowStorage);
        if (filteredPartners && filteredPartners.length > 0) {
          setShowStorage(true);
          setStorageCaption('Externe Lagerung');
        } else {
          setShowStorage(false);
        }
      }
    } else {
      setShowStorage(true);
    }
  }, [internal, partners, mandantId]);

  useEffect(() => {
    if (!showStorage) {
      const filteredOptions = orderOptions.filter((item) => item.key !== 'STORAGE');
      setOptions(filteredOptions);
    } else {
      const mappedOptions = orderOptions.map((item) => {
        if (item.key === 'STORAGE') {
          return { ...item, caption: storageCaption };
        }
        return { ...item };
      });
      setOptions(mappedOptions);
    }
  }, [internal, showStorage, storageCaption]);

  useEffect(() => {
    const optionsParam = [];
    if (isRemoval) {
      optionsParam.push('REMOVAL');
    }
    if (isDelivery) {
      optionsParam.push('DELIVERY');
    }
    if (isMontage) {
      optionsParam.push('MONTAGE');
    }
    if (isStorage) {
      optionsParam.push('STORAGE');
    }
    setSelected(optionsParam);
  }, [isDelivery, isMontage, isRemoval, isStorage]);

  const handleChangeValue = (data) => {
    switch (data) {
      case 'MONTAGE': {
        onMontage();
        break;
      }
      case 'DELIVERY': {
        onDelivery();
        break;
      }
      case 'REMOVAL': {
        onRemoval();
        break;
      }
      case 'STORAGE': {
        onStorage();
        break;
      }
      default:
        Error('Order options key not found');
    }
  };

  const renderRemovalOption = () => {
    if (selected && selected.indexOf('REMOVAL') > -1) {
      return (
        <div style={{ marginTop: 15 }}>
          <Input
            onChange={(value) => onChangeRemovalMeter(value)}
            label="Länge der zu demontierenden Küche (Hinterkante Arbeitsplatte)"
            rightText="m"
            value={removalMeter}
            autoFocus
            hasError={(errors.orderErrors && !!errors.orderErrors.removalMeter)}
            errorMessage={
              (errors.orderErrors && errors.orderErrors.removalMeterMessage)
                ? errors.orderErrors.removalMeterMessage
                : null
            }
          />
        </div>
      );
    }
    return null;
  };

  if (options) {
    return (
      <>
        <h5>{headline}</h5>
        <p className="text-muted">
          Wählen Sie mindestens eine oder mehrere Optionen für Ihren Auftrag
        </p>
        <SelectPanel
          data={options}
          isMultiSelect
          selected={selected}
          onChange={handleChangeValue}
          hasError={errors.orderErrors && !!errors.orderErrors.option}
          returnSingleKey
        />
        {renderRemovalOption()}
      </>
    );
  }
  return null;
}

SelectOrderOptions.propTypes = {
  onMontage: PropTypes.func.isRequired,
  onDelivery: PropTypes.func.isRequired,
  onRemoval: PropTypes.func.isRequired,
  onStorage: PropTypes.func.isRequired,
  onChangeRemovalMeter: PropTypes.func.isRequired,
  internal: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  headline: PropTypes.string,
  removalMeter: PropTypes.string,
  mandantId: PropTypes.number,
  partners: PropTypes.instanceOf(Array),
  isStorage: PropTypes.bool.isRequired,
  isDelivery: PropTypes.bool.isRequired,
  isRemoval: PropTypes.bool.isRequired,
  isMontage: PropTypes.bool.isRequired,
};

SelectOrderOptions.defaultProps = {
  internal: true,
  errors: {},
  headline: '2. Auftragsoptionen',
  removalMeter: null,
  mandantId: 0,
  partners: null,
};

function mapStoreToProps(store) {
  return {
    partners: store.client.partners,
  };
}

export default connect(mapStoreToProps, () => ({}))(SelectOrderOptions);
