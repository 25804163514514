import React from 'react';
import PropTypes from 'prop-types';
import * as Redux from 'react-redux';
import { useDispatchGetClientAddress } from '../../../Redux/Action/ClientAddressAction';

export const initialLoadingAddress = {
  addressId: null,
  name: null,
  zip: null,
  city: null,
  street: null,
  streetNo: null,
  contactPhone: null,
  contactName: null,
  comment: null,
};

export default function ShowStorageAddress(props) {
  const {
    mandantId, headline, onChange,
  } = props;

  const [address, setAddress] = React.useState(undefined);

  const partners = Redux.useSelector((store) => store.client.partners);
  const partner = partners.find((item) => item.partner.clientId === mandantId);

  const dispatchGetClientAddress = useDispatchGetClientAddress();

  React.useEffect(() => {
    if (partner) {
      const { defaultClientAddressId } = partner.partnerSettings;
      if (address === undefined
        || (address && address.addressId && defaultClientAddressId && defaultClientAddressId !== address.addressId)) {
        setAddress(null);
        dispatchGetClientAddress(partner.partner.clientId, defaultClientAddressId).then((response) => {
          setAddress(response);
          onChange({ loadingAddress: response });
        })
          .catch(() => setAddress({ addressId: defaultClientAddressId }));
      }
    } else {
      onChange({ loadingAddress: initialLoadingAddress });
    }
  }, [partner, dispatchGetClientAddress, setAddress, onChange, address]);

  if (partner && address) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <h5>{headline}</h5>
          <p className="text-muted">
            Ihr Partner "
            {partner.partner.name}
            " hat Ihnen folgende Lageradresse zugewiesen. Bitte liefern Sie Ihre Ware an folgende Lageradresse.
            Bitte geben Sie an wo die Küche abgeholt werden muss
          </p>

          <div className="text-center" style={{ marginTop: 40 }}>
            <div><strong>{address.name}</strong></div>
            <div>{`${address.street} ${address.streetNo} `}</div>
            <div>{`${address.zip} ${address.city} `}</div>
            <div style={{ marginTop: 8 }}>
              <div>Ansprechpartner:</div>
              <div>{`${address.contactName} (${address.contactPhone})`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

ShowStorageAddress.propTypes = {
  mandantId: PropTypes.number.isRequired,
  headline: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ShowStorageAddress.defaultProps = {
  headline: 'Lageradresse',
};
