import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import {
  deleteVoucher, get, getList, update,
} from '../Redux/Action/VoucherAction';
import { getList as getClients } from '../Redux/Action/ClientAction';
import VoucherList from '../Component/VoucherList';
import CreateDialog from './CreateDialog';
import Button from '../../../Components/Button';
import Panel from '../../../Components/Panel';
import DetailsDialog from './DetailsDialog';
import Alert from '../../../Components/Alert';

/**
 * Vouchers()
 * @param props
 * @returns {*}
 * @constructor
 */
function Vouchers(props) {
  const {
    vouchers, dispatchGetList, dispatchGet, dispatchDelete, clients, dispatchGetClients, history, dispatchUpdate,
    user,
  } = props;
  const [showCreate, setShowCreate] = React.useState(false);
  const [selectedVoucher, setSelectedVoucher] = React.useState(null);
  const [showDeleteSuccess, setShowDeleteSuccess] = React.useState(false);
  const [recentlyCreated, setRecentlyCreated] = React.useState(null);

  React.useEffect(() => {
    if (!clients || clients.length === 0) {
      dispatchGetClients();
    }
  }, [dispatchGetClients, clients]);

  React.useEffect(() => {
    dispatchGetList();
  }, [dispatchGetList]);

  const handleOnClick = (data) => {
    dispatchGet(data.voucherId).then((response) => {
      setSelectedVoucher(response);
    });
  };

  const handleOnDeactivate = (data) => {
    const merged = {
      ...data,
      expired: true,
      manualExpireDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      expiredBy: user.userId,
    };

    dispatchUpdate(merged).then(() => {
      setSelectedVoucher(null);
      dispatchGetList();
    });
  };

  const handleOnDelete = (data) => {
    dispatchDelete(data.voucherId).then(() => {
      setSelectedVoucher(null);
      dispatchGetList();
      setShowDeleteSuccess(true);
    });
  };

  const navigateCustomer = (clientId) => {
    const uri = `/system/client/${clientId}/details`;
    history.push(uri);
  };

  const handleOnCreated = (data) => {
    setRecentlyCreated(data);
    setShowCreate(false);
  };

  return (
    <>
      <Panel boxPadding={10} marginBottom={25}>
        <div className="d-flex flex-row">
          <div className="d-flex flex-grow-1 align-items-center">
            <h5 style={{ margin: 0 }}>Gutscheine</h5>
          </div>
          <div>
            <Button onClick={() => setShowCreate(true)}>
              <i className="fas fa-plus" style={{ marginRight: 0 }} />
            </Button>
          </div>
        </div>
      </Panel>
      <VoucherList
        onClick={handleOnClick}
        vouchers={vouchers}
        clients={clients}
        recentlyCreated={recentlyCreated}
      />

      <CreateDialog
        onClose={() => setShowCreate(false)}
        visible={showCreate}
        onCreated={handleOnCreated}
      />
      <DetailsDialog
        onClose={() => setSelectedVoucher(null)}
        voucher={selectedVoucher}
        onDelete={handleOnDelete}
        onDeactivate={handleOnDeactivate}
        clients={clients}
        onCustomer={navigateCustomer}
      />

      <Alert onClose={() => setShowDeleteSuccess(false)} visible={showDeleteSuccess} type="info">
        Der Gutschein wurde gelöscht und kann ab sofort nicht mehr verwendet werden!
      </Alert>
    </>
  );
}

Vouchers.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGet: PropTypes.func.isRequired,
  dispatchDelete: PropTypes.func.isRequired,
  dispatchGetClients: PropTypes.func.isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
  vouchers: PropTypes.instanceOf(Array),
  clients: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

Vouchers.defaultProps = {
  vouchers: [],
  clients: null,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
    vouchers: store.systemVoucher.vouchers,
    clients: store.systemClient.clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: () => dispatch(getList()),
    dispatchGet: (id) => dispatch(get(id)),
    dispatchDelete: (id) => dispatch(deleteVoucher(id)),
    dispatchUpdate: (voucher) => dispatch(update(voucher)),
    dispatchGetClients: () => dispatch(getClients()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Vouchers));
