import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import Alert from '../../../../Components/Alert';

/**
 * AskSendCustomerMailDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AskSendCustomerMailDialog(props) {
  const {
    onClose, onConfirm, visible, validMail,
  } = props;

  if (!validMail) {
    return (
      <Alert onClose={onClose} visible={visible} type="info">
        <div>
          Um eine Kundenbenachrichtigung versenden zu können muss der Kunde eine gültige E-Mail Adresse hinterlegt
          haben.
        </div>
        <div>
          Bitte hinterlegen Sie eine gültige E-Mail Adresse und versuchen Sie erneut die Kundenbenachrichtigung zu
          versenden.
        </div>
      </Alert>
    );
  }

  return (
    <ModalDialog
      onClose={onClose}
      visible={visible}
      onConfirm={onConfirm}
      confirmClass="btn-primary"
      confirmCaption="Jetzt senden"
    >
      <h5>Kundenbenachrichtigung versenden?</h5>
      <p>
        Möchten Sie den Kunden über die Änderungen an diesem Serviceauftrag per E-Mail informieren?
        Der Kunde erhält eine E-Mail mit allen relevanten Informationen zu seinem Serviceauftrag.
      </p>
    </ModalDialog>
  );
}

AskSendCustomerMailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  validMail: PropTypes.bool,
};

AskSendCustomerMailDialog.defaultProps = {
  visible: false,
  validMail: false,
};
