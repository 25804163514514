import React from 'react';
import PropTypes from 'prop-types';
import { useGetServiceCaption } from '../../../../../Library/States/ServiceStates';
import ObjectArrayFilter from '../../Filter/ObjectArrayFilter';

/**
 * ServiceStateFilter(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ServiceStateFilter(props) {
  const {
    services, onFilterChange,
  } = props;

  const filter = 'serviceState';
  const getFilterParameter = (service) => service.state;
  const getCaption = useGetServiceCaption();
  const renderCaption = (option, count) => `${getCaption(Number(option))} (${count})`;

  if (services && services.length > 0) {
    return (
      <ObjectArrayFilter
        objectsArray={services}
        getFilterParameter={getFilterParameter}
        getCaption={renderCaption}
        filter={filter}
        onFilterChange={onFilterChange}
      />
    );
  }
  return null;
}

ServiceStateFilter.propTypes = {
  services: PropTypes.instanceOf(Array).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

ServiceStateFilter.defaultProps = {};
