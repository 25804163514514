import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import de from 'date-fns/locale/de';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import './style.css';

/**
 * DatePickerInput()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function DatePickerInput(props) {
  const {
    onChange, initialValue, hasError, minDate, rowClass, style, visible, onBlur, disabled, placeholder, maxDate,
    clearValue,
    // eslint-disable-next-line no-unused-vars
    rightIcon, onAction, showTimeSelect,
  } = props;
  const [value, setValue] = useState((initialValue) ? moment(initialValue).toDate() : null);

  React.useEffect(() => {
    if (initialValue) {
      setValue(moment(initialValue).toDate());
    }
  }, [initialValue]);

  React.useEffect(() => {
    if (clearValue === true || clearValue === false) {
      setValue('');
    }
  }, [clearValue]);

  const getInputClass = () => {
    const classes = ['form-control'];

    if (hasError) {
      classes.push('is-invalid');
    }
    if (disabled) {
      classes.push('Disabled');
    }
    return classes.join(' ');
  };

  const handleSetValue = (param) => {
    if (onChange && param) {
      setValue(param);
      const date = moment(param).format('YYYY-MM-DD');
      onChange(date);
    }
  };

  if (visible) {
    return (
      <div className={`DatePicker ${rowClass}`}>
        <div className="form-group" style={style}>
          <RenderLabel {...props} />
          <div className="input-group">
            <div className={getInputClass()}>
              <DatePicker
                onChange={handleSetValue}
                locale={de}
                selected={value}
                dateFormat={(!showTimeSelect) ? 'iiii, dd.MM.yyyy' : 'iiii, dd.MM.yyyy HH:ii'}
                onChangeRaw={(e) => e.preventDefault()}
                minDate={minDate}
                maxDate={maxDate}
                showWeekNumbers
                onBlur={onBlur}
                disabled={disabled}
                placeholderText={placeholder}
                showTimeSelect={showTimeSelect}
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
              />
            </div>
            <RenderErrorMessage {...props} />
            <RenderRightAddon {...props} />
          </div>
        </div>
      </div>
    );
  }
  return null;
}

DatePickerInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hasError: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.object]),
  maxDate: PropTypes.instanceOf(Object),
  rowClass: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  rightIcon: PropTypes.string,
  onAction: PropTypes.func,
  showTimeSelect: PropTypes.bool,
  clearValue: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  disabled: false,
  initialValue: null,
  hasError: false,
  minDate: null,
  maxDate: null,
  rowClass: '',
  style: {},
  visible: true,
  onBlur: () => {},
  placeholder: '',
  rightIcon: null,
  onAction: null,
  showTimeSelect: false,
  clearValue: null,
};

/**
* RenderLabel()
* @param props
* @returns {null|*}
* @constructor
*/
function RenderLabel(props) {
  // eslint-disable-next-line react/prop-types
  const { label } = props;

  if (label) {
    return (
      <label>{label}</label>
    );
  }
  return null;
}

/**
 * RenderErrorMessage()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderErrorMessage(props) {
  // eslint-disable-next-line react/prop-types
  const { errorMessage } = props;

  if (errorMessage) {
    return (
      <div className="invalid-tooltip">
        {errorMessage}
      </div>
    );
  }
  return null;
}

/**
 * RenderRightAddon()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderRightAddon(props) {
  // eslint-disable-next-line react/prop-types
  const { rightIcon, rightText, onAction } = props;

  if (onAction && (rightIcon || rightText)) {
    return (
      <div className="input-group-append">
        <button className="btn btn-danger" type="button" id="button-addon2" onClick={onAction}>
          {(rightIcon !== null) ? (<i className={rightIcon} />) : rightText}
        </button>
      </div>
    );
  }

  if (rightIcon || rightText) {
    return (
      <div className="input-group-append">
        <span className="input-group-text">
          {(rightIcon) ? <i className={rightIcon} /> : rightText}
        </span>
      </div>
    );
  }

  return null;
}
