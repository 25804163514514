import React from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../Library/Axios';
import { get as getClient } from './ClientAction';

export const defaultUri = '/client/{clientId}/settings';

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.clientId {number}
 * @param uri
 * @returns {string}
 */
export const prepareUri = (parameter, uri = defaultUri) => {
  const { clientId } = parameter;
  return uri.replace('{clientId}', clientId);
};

export const CLIENTSETTINGS_GET_START = 'CLIENTSETTINGS_GET_START';
export const CLIENTSETTINGS_GET_SUCCESS = 'CLIENTSETTINGS_GET_SUCCESS';
export const CLIENTSETTINGS_GET_ERROR = 'CLIENTSETTINGS_GET_ERROR';

export const useDispatchGetClientSettings = () => {
  const dispatch = useDispatch();

  return React.useCallback((clientId) => {
    const uri = prepareUri({ clientId });
    dispatch({ type: CLIENTSETTINGS_GET_START, payload: { clientId, uri } });

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTSETTINGS_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTSETTINGS_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
};

/**
 * get()
 * @param clientId
 * @returns {function(*): Promise<unknown>}
 */
export function get(clientId) {
  return (dispatch) => {
    dispatch({ type: CLIENTSETTINGS_GET_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTSETTINGS_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTSETTINGS_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CLIENTSETTINGS_UPDATE_START = 'CLIENTSETTINGS_UPDATE_START';
export const CLIENTSETTINGS_UPDATE_SUCCESS = 'CLIENTSETTINGS_UPDATE_SUCCESS';
export const CLIENTSETTINGS_UPDATE_ERROR = 'CLIENTSETTINGS_UPDATE_ERROR';

/**
 * useDispatchUpdateSettings()
 * @returns {(function(*))|*|(function(*=, *=): *)}
 */
export function useDispatchUpdateSettings() {
  const dispatch = useDispatch();

  return React.useCallback((settings, reloadClient = true) => {
    dispatch({ type: CLIENTSETTINGS_UPDATE_START, settings });

    const uri = `${defaultUri.replace('{clientId}', settings.clientId)}/0`;

    return axios().put(uri, settings).then((response) => {
      const { data } = response.data;
      if (reloadClient) {
        return dispatch(getClient(settings.clientId)).then(() => {
          dispatch({ type: CLIENTSETTINGS_UPDATE_SUCCESS, payload: data });
          return Promise.resolve(data);
        });
      }
      dispatch({ type: CLIENTSETTINGS_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTSETTINGS_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
