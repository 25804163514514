import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { Redirect, useParams, withRouter } from 'react-router';
import { get } from '../../../Redux/Action/OrderAction';
import OrderHeadline from './Components/OrderHeadline';
import ObjectPreviewContainer from '../../../Components/ObjectComponents/ObjectPreviewContainer';
import { NAVIGATION_OBJECT_DETAILS, ORDERTYPE_OBJECT, ORDERTYPE_SINGLE } from '../../../Library/Types';
import OrderInformationPanel from '../../../Components/OrderComponents/OrderInformationPanel';
import Panel from '../../../Components/Panel';
import MakeOfferHandler from './Components/MakeOfferHandler';
import PickOrder from './Components/PickOrder';
import LoadingModal from '../../../Components/LoadingModal';
import { hasOrderAccess } from '../../../Library/Functions';
import PartnerHint from './Components/PartnerHint';

/**
 * OrderDetails()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function OrderDetails(props) {
  const {
    dispatchGetOrder, order, history, client,
  } = props;
  const [loaded, setLoaded] = React.useState(false);
  const { orderId } = useParams();

  React.useEffect(() => {
    if (order && client) {
      if (!hasOrderAccess(client, order)) {
        history.push('/dashboard');
      }
    }
  }, [client, order, history]);

  const navigateObject = (object) => {
    const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);
    history.push(uri);
  };

  React.useEffect(() => {
    dispatchGetOrder(orderId).then(() => setLoaded(true));
  }, [orderId, dispatchGetOrder]);

  if (order && loaded) {
    if (order.type === ORDERTYPE_SINGLE) {
      const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', orderId)
        .replace('{objectId}', order.objects[0].objectId);
      return (
        <Redirect to={uri} />
      );
    }

    return (
      <div>
        <OrderHeadline />

        <PartnerHint order={order} client={client} />
        <MakeOfferHandler order={order} visible={(order.type === ORDERTYPE_OBJECT)} />
        <PickOrder order={order} client={client} visible={(order.type === ORDERTYPE_OBJECT)} />

        <OrderInformationPanel order={order} client={client} />
        <Panel marginBottom={20}>
          <h5 style={{ marginBottom: 40 }}>
            {`${order.objects.length} Objekte im Auftrag`}
          </h5>

          {order.objects.map((item, index) => (
            <ObjectPreviewContainer
              object={item}
              order={order}
              key={item.objectId}
              onClick={navigateObject}
              index={index}
            />
          ))}
        </Panel>
      </div>
    );
  }
  return <LoadingModal visible text="Wird geladen..." />;
}

OrderDetails.propTypes = {
  dispatchGetOrder: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

OrderDetails.defaultProps = {
  order: null,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(OrderDetails));
