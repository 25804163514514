import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import FileSaver from 'file-saver';
import moment from 'moment';
import { get, getReclamationReport, getReportPdf } from '../../../Redux/Action/ReclamationAction';
import Panel from '../../../Components/Panel';
import Button from '../../../Components/Button';
import ReportOverview from './Component/ReportOverview';
import DeviceList from '../../../Components/DeviceList';
import LoadingModal from '../../../Components/LoadingModal';
import {
  DEVICETYPE_DAMAGE,
  DEVICETYPE_PREDAMAGE,
  DEVICETYPE_RECLAMATION,
  DEVICETYPE_RECLAMATIONDEVICE,
} from '../../../Library/Types';
import UsedMaterial from '../../../Components/UsedMaterial';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import { formatDateTime } from '../../../Library/Functions';
import SignaturesBox from '../../../Components/ReportComponents/SignaturesBox';

/**
 * ReclamationReport()
 * @param props
 * @constructor
 */
function ReclamationReport(props) {
  const {
    reclamation, dispatchGetReport, dispatchGetReclamation, dispatchDownloadReport, order,
  } = props;
  const [report, setReport] = useState();
  const [reclamations, setReclamations] = useState([]);
  const [damages, setDamages] = useState([]);
  const [preDamages, setPreDamages] = useState([]);
  const [reclamationDevices, setReclamationDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const { orderId, reclamationId, objectId } = useParams();

  useEffect(() => {
    dispatchGetReclamation(orderId, objectId, reclamationId).then((reclamationData) => {
      dispatchGetReport(reclamationData).then((data) => {
        setReport(data);

        setReclamations(data.devices.filter((item) => item.type === DEVICETYPE_RECLAMATION));
        setDamages(data.devices.filter((item) => item.type === DEVICETYPE_DAMAGE));
        setPreDamages(data.devices.filter((item) => item.type === DEVICETYPE_PREDAMAGE));
        setReclamationDevices(data.devices.filter((item) => item.type === DEVICETYPE_RECLAMATIONDEVICE));
      });
    });
  }, [dispatchGetReport, dispatchGetReclamation, objectId, orderId, reclamationId]);

  useEffect(() => {
    if (order) {
      const object = order.objects.filter((item) => item.objectId === parseInt(objectId, 0))[0];
      setCustomer(object.customer);
    }
  }, [order, objectId]);

  const handleDownload = () => {
    setIsLoading(true);
    dispatchDownloadReport(reclamation).then((data) => {
      FileSaver.saveAs(data, `Reklamationsbericht_${reclamationId}.pdf`);
      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  };

  const getDuration = () => {
    if (report && report.startDateTime && report.endDateTime) {
      return moment(report.endDateTime).diff(moment(report.startDateTime), 'minute');
    }
    return null;
  };

  const renderInformation = () => {
    if (report) {
      return (
        <div className="col-lg-4 d-flex">
          <Panel marginBottom={25}>
            <h6 style={{ marginBottom: 30 }}>Weitere Informationen</h6>
            <KeyValueEditRow type="text" title="Reklamation-Start" displayText={formatDateTime(report.startDateTime)} />
            <KeyValueEditRow type="text" title="Reklamation-Ende" displayText={formatDateTime(report.endDateTime)} />
            <KeyValueEditRow type="text" title="Reklamation-Dauer" displayText={`${getDuration()} Minuten`} />
            <KeyValueEditRow type="text" title="Weitere Informationen" displayText={report.comment} />
          </Panel>
        </div>
      );
    }
    return null;
  };

  if (report && reclamation) {
    return (
      <div>
        <LoadingModal visible={isLoading} text="Reklamationsbericht wird erstellt..." />

        <Panel marginBottom={25}>
          <div className="row">
            <div className="col-lg-8 d-flex align-items-center align-content-center">
              <h4 className="PrimaryHeadline" style={{ marginBottom: 0 }}>
                Reklamationsbericht
              </h4>
            </div>
            <div className="col-lg-4" style={{ textAlign: 'right' }}>
              <Button onClick={() => handleDownload()}>
                Als PDF-Datei herunterladen
              </Button>
            </div>
          </div>
        </Panel>

        <ReportOverview report={report} />

        <div className="row">
          <DeviceList
            showPreview
            colSize="col-lg-8"
            devices={reclamationDevices}
            caption="Bearbeitete Reklamationen"
          />
          {renderInformation()}

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={preDamages}
            caption="Vorschäden / Beschädigungen"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={reclamations}
            caption="Neue Reklamationen"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={damages}
            caption="Beschädigungen / Versicherungsfall"
          />

          <div className="col-lg-12">
            <UsedMaterial materials={(report) ? report.materials : []} />
          </div>
        </div>

        <SignaturesBox
          customer={customer}
          user={(reclamation.users) ? reclamation.users[0] : null}
          customerSignaturePath={(report.signatureCustomer.absolutePath) || null}
          monteurSignaturePath={(report.signatureMonteur.absolutePath) || null}
        />
      </div>
    );
  }
  return null;
}

ReclamationReport.propTypes = {
  dispatchGetReport: PropTypes.func.isRequired,
  dispatchGetReclamation: PropTypes.func.isRequired,
  dispatchDownloadReport: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  reclamation: PropTypes.instanceOf(Object),
};

ReclamationReport.defaultProps = {
  reclamation: null,
};

function mapStoreToProps(store) {
  return {
    reclamation: store.reclamation.selectedReclamation,
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetReport: (reclamation) => dispatch(getReclamationReport(reclamation)),
    dispatchGetReclamation: (orderId, objectId, reclamationId) => dispatch(get(orderId, objectId, reclamationId)),
    dispatchDownloadReport: (reclamation) => dispatch(getReportPdf(reclamation)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ReclamationReport));
