import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import Panel from '../../../Components/Panel';
import SchedulerList from '../../../Components/Scheduler/SchedulerList';
import SchedulerTile from '../../../Components/Scheduler/SchedulerTile';
import HolidayBadge from '../../../Components/Scheduler/Components/HolidayBadge';
import HolidayRow from '../../../Components/Scheduler/Components/HolidayRow';
import { useDispatchHolidayGetList } from '../../../Redux/Action/HolidayAction';
import { getAll as getUserList } from '../../../Redux/Action/UserAction';
import TileListViewSwitcher from '../../../Components/Scheduler/Components/TileListViewSwitcher';
import AddButtonMonthSelector from '../../../Components/Scheduler/Components/AddButtonMonthSelector';
import AddModal from './AddModal';
import UpdateModal from './UpdateModal';
import { LOCALSTORAGE_SETINGSHOLIDAYCALENDARVIEW } from '../../../Library/Types';

/**
 * Overview()
 * @param props
 * @returns {*}
 * @constructor
 */
function Overview(props) {
  const { dispatchGetUsers } = props;

  const dispatchGetList = useDispatchHolidayGetList();
  const { users } = useSelector((state) => state.user);

  const [currentMonth, setCurrentMonth] = useState(moment());
  const [calendarData, setCalendarData] = useState(null);
  const [currentView, setCurrentView] = useState('tile');
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(false);
  const [initialAddData, setInitialAddData] = useState({});

  useEffect(() => {
    const item = localStorage.getItem(LOCALSTORAGE_SETINGSHOLIDAYCALENDARVIEW);
    if (item) {
      setCurrentView(item);
    }
  }, []);

  useEffect(() => {
    dispatchGetUsers();
  }, [dispatchGetUsers]);

  useEffect(() => {
    localStorage.setItem(LOCALSTORAGE_SETINGSHOLIDAYCALENDARVIEW, currentView);
  }, [currentView]);

  useEffect(() => {
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endtDate = moment().endOf('month').format('YYYY-MM-DD');
    dispatchGetList(startDate, endtDate).then((data) => {
      setCalendarData(data);
    });
  }, [dispatchGetList]);

  const handleOnMonthChange = (newMonth) => {
    setCurrentMonth(newMonth);
    const startDate = moment(newMonth).startOf('month').format('YYYY-MM-DD');
    const endtDate = moment(newMonth).endOf('month').format('YYYY-MM-DD');
    dispatchGetList(startDate, endtDate).then((data) => {
      setCalendarData(data);
    });
  };

  const handleOnSaveOrUpdateAdd = () => {
    const startDate = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
    const endtDate = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
    dispatchGetList(startDate, endtDate).then((data) => {
      setCalendarData(data);
      setShowAdd(false);
      setShowUpdate(false);
    });
  };

  const handleStartUpdate = (holidayEntry) => {
    if (typeof holidayEntry === 'object' && (holidayEntry.id !== undefined)) {
      setSelectedEntry(holidayEntry);
      setShowUpdate(true);
    } else {
      if (typeof holidayEntry === 'object') {
        setInitialAddData({
          startDate: holidayEntry.startDate,
          userId: holidayEntry.userId,
        });
      } else {
        setInitialAddData({
          startDate: holidayEntry,
        });
      }
      setShowAdd(true);
    }
  };

  const renderView = () => {
    if (currentView === 'tile') {
      return (
        <SchedulerTile
          currentMonth={currentMonth}
          onMonthChange={handleOnMonthChange}
          BadgeComponent={HolidayBadge}
          RowComponent={HolidayRow}
          onSelectDay={handleStartUpdate}
          calendarData={(calendarData) ? calendarData.tileData : []}
          users={(users) || []}
          monthSelectorLeftActions={<TileListViewSwitcher selected={currentView} onChange={setCurrentView} />}
          monthSelectorRightActions={<AddButtonMonthSelector onClick={() => setShowAdd(true)} />}
        />
      );
    }
    return (
      <SchedulerList
        currentMonth={currentMonth}
        onMonthChange={handleOnMonthChange}
        BadgeComponent={HolidayBadge}
        RowComponent={HolidayRow}
        onSelectDay={handleStartUpdate}
        calendarData={(calendarData) ? calendarData.listData : []}
        users={(users) || []}
        monthSelectorLeftActions={<TileListViewSwitcher selected={currentView} onChange={setCurrentView} />}
        monthSelectorRightActions={<AddButtonMonthSelector onClick={() => setShowAdd(true)} />}
      />
    );
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <Panel marginBottom={25}>
          <h4>
            Urlaubs- / Abwesenheitskalender
          </h4>
          <p style={{ marginBottom: 0 }}>
            Verwalten Sie Urlaube und Abwesenheiten einfach und bequem hier!
          </p>
        </Panel>
      </div>

      <div className="col-xl-12">
        <Panel marginBottom={25}>
          {renderView()}
        </Panel>
      </div>

      <AddModal
        visible={showAdd}
        onClose={() => setShowAdd(false)}
        users={users}
        onSave={handleOnSaveOrUpdateAdd}
        initialData={initialAddData}
      />
      <UpdateModal
        visible={showUpdate}
        onClose={() => setShowUpdate(false)}
        initialData={(selectedEntry) || {}}
        users={users}
        onSave={handleOnSaveOrUpdateAdd}
      />
    </div>
  );
}

Overview.propTypes = {
  dispatchGetUsers: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetUsers: () => dispatch(getUserList()),
  };
}

export default connect(() => ({}), mapDispatchToProps)(withRouter(Overview));
