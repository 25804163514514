import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  formatCurrency, formatDate,
} from '../../Library/Functions';
import StatusBadge from '../StatusBadge';
import ClickableTableRow from '../ClickableTableRow';
import Button from '../Button';
import { ORDERTYPE_SINGLE } from '../../Library/Types';
import { ORDERSTATE_OPEN, ORDERSTATE_WAITING_CALCULATION } from '../../Library/StateTypes';

/**
 * OrderRow()
 * @param props
 * @returns {*}
 * @constructor
 */
function OrderRow(props) {
  const {
    order, onClick, onAction, openOffers,
  } = props;
  const [offer, setOffer] = useState(null);

  React.useEffect(() => {
    if (order && openOffers && openOffers.length > 0) {
      const filtered = openOffers.filter((item) => item.orderId === order.orderId);
      if (filtered && filtered.length > 0) {
        setOffer(filtered[0]);
      }
    }
  }, [order, openOffers]);

  const getAddress = () => `${order.zip} ${order.city}`;

  const renderIcon = () => <i className="fas fa-angle-right hk-text-primary" />;

  const renderAction = () => {
    if (order.orderState === ORDERSTATE_OPEN) {
      return (
        <td style={{ textAlign: 'right' }}>
          <Button onClick={() => onAction(order)} className="btn btn-sm">
            Annehmen
          </Button>
        </td>
      );
    }

    if (!offer) {
      return (
        <td style={{ textAlign: 'right' }}>
          <Button onClick={() => onAction(order)} className="btn btn-sm" type="btn-info">
            {(order.orderType === ORDERTYPE_SINGLE) ? 'Angebot / Termin abgeben' : 'Angebot abgeben'}
          </Button>
        </td>
      );
    }

    return (
      <td style={{ textAlign: 'right' }}>
        <Button onClick={() => onAction(order)} className="btn btn-sm" type="btn-success">
          {`Ihr Angebot: ${formatCurrency(offer.price)}`}
        </Button>
      </td>
    );
  };

  const renderPrice = () => {
    if (order.orderState === ORDERSTATE_OPEN) {
      return formatCurrency(order.mechanicPrice);
    }
    return null;
  };

  const renderDate = () => {
    if (order.orderState === ORDERSTATE_WAITING_CALCULATION) {
      const startWeek = moment(order.deliveryDateStart).startOf('week').format('DD.MM');
      const endWeek = moment(order.deliveryDateStart).endOf('week').format('DD.MM');
      return `KW${moment(order.deliveryDateStart).format('WW')} (${startWeek} - ${endWeek})`;
    }
    return formatDate(order.deliveryDateStart);
  };

  const renderOrderOptions = () => {
    const options = [];
    if (order.optionRemoval) {
      options.push(
        <StatusBadge
          caption={(
            <>
              <i className="fas fa-trash-alt" style={{ marginRight: 8 }} />
              Demontage
            </>
          )}
          type="Default"
          containerStyle={{ marginRight: 6 }}
          key={1}
        />,
      );
    }
    if (order.optionStorage) {
      options.push(
        <StatusBadge
          caption={(
            <>
              <i className="fas fa-cubes" style={{ marginRight: 8 }} />
              Lagerung
            </>
          )}
          type="Default"
          containerStyle={{ marginRight: 6 }}
          key={2}
        />,
      );
    }
    if (order.optionDelivery) {
      options.push(
        <StatusBadge
          caption={(
            <>
              <i className="fas fa-truck" style={{ marginRight: 8 }} />
              Lieferung
            </>
          )}
          type="Default"
          containerStyle={{ marginRight: 6 }}
          key={3}
        />,
      );
    }
    if (order.optionMontage) {
      options.push(
        <StatusBadge
          caption={(
            <>
              <i className="fas fa-tools" style={{ marginRight: 8 }} />
              Montage
            </>
          )}
          type="Default"
          containerStyle={{ marginRight: 6 }}
          key={4}
        />,
      );
    }
    return (
      <div className="d-flex flex-row flex-wrap">
        {options}
      </div>
    );
  };

  return (
    <>
      <tr>
        <ClickableTableRow onClick={() => onClick(order)}>
          {renderIcon()}
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(order)}>
          <i
            className={`fas ${(order.orderType === ORDERTYPE_SINGLE) ? 'fa-truck' : 'fa-building'}`}
            title={(order.orderType === ORDERTYPE_SINGLE) ? 'Einzelauftrag' : 'Objektauftrag'}
          />
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(order)}>
          {renderOrderOptions()}
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(order)}>
          {getAddress()}
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(order)}>
          {renderDate()}
        </ClickableTableRow>
        <ClickableTableRow onClick={() => onClick(order)}>
          {renderPrice()}
        </ClickableTableRow>
        {renderAction()}
      </tr>
    </>
  );
}

OrderRow.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  openOffers: PropTypes.instanceOf(Array),
};

OrderRow.defaultProps = {
  openOffers: [],
};

/**
 * FindExternalOrderTable()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function FindExternalOrderTable(props) {
  const {
    orders, onClick, onAction, openOffers,
  } = props;

  if (orders && orders.length > 0) {
    return (
      <div className="OrderListView">
        <table className="table table-linked table-hover">
          <thead>
            <tr>
              <th />
              <th>Typ</th>
              <th>Auftragsoptionen</th>
              <th>Adresse</th>
              <th>Ausführungsdatum</th>
              <th>
                Angebotspreis
                <small style={{ fontFamily: 'Tahoma' }}> (netto)</small>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <OrderRow
                order={order}
                key={order.dateId}
                onClick={onClick}
                onAction={onAction}
                openOffers={openOffers}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  return null;
}

FindExternalOrderTable.propTypes = {
  orders: PropTypes.instanceOf(Array),
  onClick: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  openOffers: PropTypes.instanceOf(Array),
};

FindExternalOrderTable.defaultProps = {
  orders: null,
  onAction: () => {},
  openOffers: [],
};
