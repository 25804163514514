import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RuntimeVoucher from '../../../Components/ClientComponents/Voucher/RuntimeVoucher';
import Alert from '../../../Components/Alert';
import { get } from '../../../Redux/Action/ClientAction';

/**
 * Voucher()
 * @param props
 * @returns {*}
 * @constructor
 */
function Voucher(props) {
  const { dispatchGetClient, client } = props;
  const [showVoucherSuccess, setShowVoucherSuccess] = useState(false);
  const [showVoucherDeleted, setShowVoucherDeleted] = useState(false);

  React.useEffect(() => {
    if (!client) {
      dispatchGetClient();
    }
  }, [dispatchGetClient, client]);

  return (
    <div className="row">
      <div className="col-lg-12" id="voucher">
        <RuntimeVoucher
          onSuccess={() => setShowVoucherSuccess(true)}
          onDeleted={() => setShowVoucherDeleted(true)}
        />
      </div>

      <Alert onClose={() => setShowVoucherSuccess(false)} visible={showVoucherSuccess} type="success">
        Ihr Gutschein wurde erfolgreich aktiviert!
      </Alert>
      <Alert onClose={() => setShowVoucherDeleted(false)} visible={showVoucherDeleted} type="info">
        Ihr Gutschein wurde deaktiviert.
        <br />
        Der gewährte Rabatt ist ab sofort nicht mehr verfügbar!
      </Alert>
    </div>
  );
}

Voucher.propTypes = {
  client: PropTypes.instanceOf(Object),
  dispatchGetClient: PropTypes.func.isRequired,
};

Voucher.defaultProps = {
  client: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetClient: (clientId) => dispatch(get(clientId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Voucher));
