export const SUPPORT_CONTENT_SETUP = [
  {
    id: 'Kostenlos registrieren',
    header: 'Kostenlos registrieren/anmelden auf hey.kitchen und erste Schritte',
    videoId: '88RMOZxCAOg',
    // eslint-disable-next-line max-len
    content: 'In diesem Video zeigen wir die kostenlose Registrierung/ Anmeldung auf unserer Seite www.hey.kitchen und die ersten Schritte in den Einstellungen. Wir füllen die wichtigsten Informationen aus, die zur Verwendung notwendig sind.',
  },
  {
    id: 'Benutzerverwaltung',
    header: 'Benutzerverwaltung und die verschiedene Gruppen in hey.kitchen',
    videoId: 'fM61aUP6V-w',
    // eslint-disable-next-line max-len
    content: 'In diesem Video stellen wir die Benutzerverwaltung vor. Wir erstellen gemeinsam verschiedene Benutzer mit unterschiedlichen Gruppen beziehungsweise Rollen. Außerdem erklären wir, wofür die verschiedenen Benutzern verwendet werden können.',
  },
  {
    id: 'Benutzerpasswort ändern',
    header: 'Benutzerpasswort ändern auf hey.kitchen',
    videoId: '1IjOIggxIuc',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir, wie Benutzer das Passwort selbstständig anpassen bzw. ändern können. Wenn Sie Ihr Passwort vergessen haben, können Sie sich ein neues zuschicken lassen und dann in der Benutzerverwaltung wieder auf Ihr gewünschtes Passwort ändern.',
  },
  {
    id: 'Materialverwaltung',
    header: 'Materialverwaltung in hey.kitchen aktivieren und verwenden',
    videoId: 'pTf6dtxRY',
    // eslint-disable-next-line max-len
    content: 'In diesem Video wird erklärt, wie Sie die Materialverwaltung für Montage- und Serviceberichte aktivieren und verwenden können. Außerdem wird gezeigt, wie die Monteure die hinzugefügten und selbst gepflegten Materialien in der App auswählen können. Alle zusätzlich (nicht geplanten) verwendeten Materialen werden dann wie im Video dargestellt im Montagebericht angezeigt.',
  },
  {
    id: 'Standorte und Lageradressen',
    header: 'Standorte und Lageradressen in hey.kitchen hinterlegen',
    videoId: '_aTz9N1-QlI',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir, wie Sie eine oder mehrere Lageradressen hinterlegen können, wofür die Lageradressen sind und wo man Sie bei der Erfassung von Aufträgen hinterlegen kann. Mit Hilfe der Lageradressen müssen Sie die Beladestelle bei Abholung nicht manuell ausfüllen.',
  },
];

export const SUPPORT_CONTENT_CREATE_ORDER = [
  {
    id: 'Internen Auftrag erstellen',
    header: 'Internen Auftrag für die eigenen Monteure auf hey.kitchen erstellen',
    videoId: 'uBKKqsM3jA0',
    // eslint-disable-next-line max-len
    content: 'In diesem Video wird erklärt, wie Sie einen internen Auftrag für Ihre eigenen angestellten Monteure erstellen. Die Monteure erhalten dann alle wichtigen Unterlagen und den Auftrag selbst digital auf Ihr Smartphone oder Tablet. Die gesamte Abnahme und der Montagebericht erfolgt dann vollständig inkl. Unterschriften auf dem Tablet bzw. Smartphone.',
  },
  {
    id: 'Objektauftrag',
    header: 'Objektauftrag in hey.kitchen erstellen',
    videoId: '6KbNMVEzxjg',
    tags: ['passwort', 'benutzer', 'zurücksetzen'],
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir, wie Sie ganz einfach einen Objektauftrag erstellen können. Von einem Objektauftrag sprechen wir, wenn mind. 2 oder mehr Küchen an der gleichen Adresse montiert werden. Die Erfassung von mehreren Küchen im gleichen Objekt ist darüber deutlich vereinfacht. Sehen Sie selbst.',
  },
  {
    id: 'Angebote für Küchenmontagen',
    header: 'Angebote für Küchenmontagen in ganz Deutschland auf Knopfdruck',
    videoId: 'VMvQlVpfHpQ',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir Ihnen, wie Sie sich selbst zu jederzeit und von überall Angebote für Küchenmontagen inkl. Lieferung und Lagerung einholen können. Bei einem Vorlauf von 5 Wochen erhalten Sie auf Knopfdruck und sofort einen Preis für jede Küche in ganz Deutschland. Und das zu regionalen Preisen vor Ort. Verkaufen Sie mit hey.kitchen Küchen in ganz Deutschland zu fairen Preisen. Jetzt kostenlos anmelden auf www.hey.kitchen und Angebote einholen.',
  },
  {
    id: 'Vorab Anlieferungen oder Demontagen überwachen',
    header: 'Vorab Anlieferungen oder Demontagen von Küchen mit hey.kitchen überwachen',
    videoId: '4LtrzlncM04',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir, wie Sie unterschiedliche Auftragsoptionen wie Lieferungen, De- und Montagen unternehmensübergreifend und vollständig unabhängig voneinander terminieren und disponieren können. Somit lassen sich z.B. Vorab Anlieferungen am Vortag realisieren. Oder aber Sie übergeben die Anlieferung an Ihren externen Subunternehmer und machen nur die Montage selbst. Oder natürlich genau andersrum. Sie haben mit hey.kitchen volle Flexibilität und alle Möglichkeiten.',
  },
  {
    id: 'Serviceauftrag planen',
    header: 'Serviceauftrag für APL Nachmontage planen und terminieren mit hey.kitchen',
    videoId: 'VMAU-70NOcc',
    // eslint-disable-next-line max-len
    content: 'In diesem Video zeigen wir Ihnen, wie Sie ganz einfach eine Nachmontage für Steinarbeitsplatten planen und terminieren können. Somit haben Sie alle Aufträge im Blick und können diese schnell und einfach digital Ihren Monteuren zuordnen.',
  },
];

export const SUPPORT_CONTENT_EXECUTE_MONTAGE = [
  {
    id: 'Schritt-für-Schritt Küchenmontage-App Anleitung',
    header: 'Die Schritt-für-Schritt Küchenmontage-App Anleitung für Monteure',
    videoId: 'KgE0yh_fRFg',
    // eslint-disable-next-line max-len
    content: 'In diesem Video zeigen wir einmal ganz genau, wie die Montage mit unserer Montage-App vor Ort beim Kunden für die Monteure funktioniert. Es wird exemplarisch ein Montagebericht komplett durchgeführt und Hinweise und Anmerkungen dazu gegeben. Auch werden Tipps zur Durchführung von Offline-Berichten gezeigt.',
  },
  {
    id: 'Besonderheiten bei Offlineberichten',
    header: 'Besonderheiten bei Offlineberichten ohne Internet-Empfang beim Kunden',
    videoId: 'MYMpgtJ4vZY',
    tags: ['standort', 'lager', 'adresse'],
    // eslint-disable-next-line max-len
    content: 'In diesem Video erläutern wir, wie Sie auch Montageberichte mit hey.kitchen vollständig offline durchführen können. Wenn Sie oder Ihre Monteure vor Ort beim Kunden keinen Empfang oder nicht ausreichende Internetverbindung haben, kann die Küchenmontage auch offline dokumentiert werden. Zusätzlich dazu geben wir ein paar Anmerkungen und Hinweise.',
  },
  {
    id: 'Demontageberichte',
    header: 'Demontageberichte - Saubere und ordentliche Dokumentationen in Echtzeit',
    videoId: 'Zs4TrgSxp6c',
    // eslint-disable-next-line max-len
    content: 'Zu einer verkauften Küche, gehört hin und wieder auch die Demontage einer bereits noch bestehenden Küche. Wie Sie saubere und ordentliche digitale Demontageberichte erhalten, zeigen wir in diesem Video. Alle notwendigen Berichte und Informationen befinden sich übersichtlich im Auftrag.',
  },
  {
    id: 'Lieferberichte',
    header: 'Lieferberichte für Küchenmontagen in Echtzeit',
    videoId: 'ec2wOhTUhI4',
    // eslint-disable-next-line max-len
    content: 'Oftmals werden die zu montierenden Küchen ein oder mehrere Tage vorab beim Kunden angeliefert. Dies geschieht entweder mit den eigenen Fahrern oder durch externe Partner. Mit hey.kitchen können Sie die Anlieferungen Ihrer Küchen komplett unabhängig terminieren und unternehmensübergreifend disponieren. Wie Sie saubere und ordentliche digitale Liferberichte erhalten, zeigen wir in diesem Video. Alle notwendigen Berichte und Informationen befinden sich übersichtlich im Auftrag.',
  },
];

export const SUPPORT_CONTENT_SERVICE = [
  {
    id: 'Serviceauftrag terminieren',
    header: 'Serviceauftrag für APL Nachmontage planen und terminieren mit hey.kitchen',
    videoId: 'VMAU-70NOcc',
    // eslint-disable-next-line max-len
    content: 'In diesem Video zeigen wir Ihnen, wie Sie ganz einfach eine Nachmontage für Steinarbeitsplatten planen und terminieren können. Somit haben Sie alle Aufträge im Blick und können diese schnell und einfach digital Ihren Monteuren zuordnen.',
  },
  {
    id: 'Nachträglich Mängel berfassen',
    header: 'Nachträglich gemeldete Mängel vom Endkunden erfassen und digital abwickeln',
    videoId: 'nBsu9OZ4v-E',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir Ihnen, wie Sie nachträglich gemeldete Reklamationen / Beanstandungen in hey.kitchen erfassen und digital abwickeln können. Dabei war es uns enorm wichtig, dass unsere Montage-App für die Monteure so einfach und unkompliziert wie möglich ist. Gleichzeitig verbessern sich aber durch den definierten Prozess die Berichtsqualität automatisch. ',
  }, {
    id: 'Serviceaufträge auf erledigt setzen',
    header: 'Ordnung halten - Serviceaufträge auf erledigt setzen ohne Bericht',
    videoId: 'LU-v_OQ1q4I',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir, wie Sie nicht durchgeführte Reklamationen oder Serviceaufträge auf erledigt setzen können ohne dafür eine Servicebericht erstellen zu müssen. Häufig kann es vor kommen, dass Monteure eine Beanstandung erfassen während der Montage, für die es keinen weiteren Kundendiensteinsatz benötigt. Wenn Einlegeböden haben oder Antirutschmatten gefehlt haben z.B. Oder aber auch, wenn man sich später mit dem Kunden über einen Preisnachlass einigt und die Beanstandung dafür so bleiben kann. Es kann verschiedene Gründe haben. Hier zeigen wir, wie Sie Ordnung im System behalten und diese Serviceaufträge abschließen ohne Bericht.',
  }, {
    id: 'Termine an den Kunden schicken',
    header: 'Schnelle Kundenkommunikation - Termine auf Knopfdruck an den Kunden schicken',
    videoId: 'wukCTxVJFbk',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir Ihnen, wie Sie schnell und automatisch Ihre Küchenmontage Termine mit dem Kunden organisieren können. Schicken Sie auf Knopfdruck disponierte Küchenmontagen direkt an den Kunden. Damit sparen Sie viel Zeit und Arbeit bei der Dispo. In dem Video zeigen wir Ihnen, wie das geht und welche Mails verschickt werden. Wenn Sie eigene Mails schreiben möchten und diese auch über Ihren eigenen Mailserver laufen lassen möchten, melden Sies ich gerne bei kontakt@hey.kitchen. Wir helfen Ihnen beim Einrichten.',
  },
];

export const SUPPORT_CONTENT_FUNCTIONS = [
  {
    id: 'Externe Partner einladen',
    header: 'Externe Partner zur gemeinsamen Zusammenarbeit auf hey.kitchen einladen',
    videoId: '5X5uxInkaEw',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir Ihnen, wie Sie Ihrem externen Partner einen Einladungslink zur gemeinsamen Zusammenarbeit auf hey.kitchen schicken können. Dabei können Sie alle notwendigen Informationen für Ihren Partner bereits vor ausfüllen, sodass die Anmeldung nur noch bestätigt werden muss. Somit können Sie auch Partner einladen, die noch keinen Account bei hey.kitchen haben.',
  },
  {
    id: 'Interne Notizen und Anmerkungen',
    header: 'Interne Notizen und Anmerkungen auftragsbezogen in hey kitchen für Sachbearbeiter',
    videoId: 'HZfYrkkqKms',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir die internen Notizen bzw. Anmerkungen in hey.kitchen. Interne Notizen sind ausschließlich für die Sachbearbeiter bestimmt. Können also nur von den Rollen "Verwaltung" und "Administrator" gelesen und geschrieben werden. Weder die Monteure noch die festen Subunternehmer im Partnerauftrag sehen die eigenen Notizen. Jeder Account kann aber für sich selbst zum gemeinsamen Partnerauftrag Notizen erstellen. Bei einer internen Notiz werden keine Benachrichtigungen an andere Sachbearbeiter geschickt. Interne Notizen sind bzw. werden  dauerhaft zum Auftrag gespeichert.',
  },
  {
    id: 'Aufgaben und Erinnerungen',
    header: 'Aufgaben und Erinnerungen in hey kitchen sinnvoll nutzen',
    videoId: '5qhjGiAyHng',
    // eslint-disable-next-line max-len
    content: 'In diesem Video erklären wir die Funktionen für Aufgaben und Erinnerungen. An Beispielen wird erläutert, wie Aufgaben und Erinnerungen genutzt werden können. Aufgaben können nicht nur für einen selber sondern auch für Mitarbeiter oder Monteure erstellt werden. Aufgaben werden sofort an die App des Monteurs übermittelt. Aufgaben sehen nur der Ersteller und eventuell zugewiesene Personen. Aufgaben können sowohl zu Aufträgen verknüpft werden oder auch losgelöst von Aufträgen erstellt werden.',
  },
  {
    id: 'Partnerschaftseinladung verschicken',
    header: 'Partnerschaftseinladung verschicken - Zusammenarbeit mit festen Partnern',
    videoId: '7DuTD1VSz1c',
    // eslint-disable-next-line max-len
    content: 'Verbessern Sie Ihre Effektivität und senken Sie Ihre Kosten mit einer intuitiven und einfachen gemeinsamen Plattform zur Zusammenarbeit. Erhalten Sie von Ihren Partnern automatisch Montageberichte in bester Qualität und in Echtzeit. Keine Zettelwirtschaft und unübersichtlicher Mailverkehr mehr.',
  },
];
