import React from 'react';
import PropTypes from 'prop-types';

export default function CounterTopIcon(props) {
  const { object } = props;

  const renderOptions = () => {
    switch (object.countertopType) {
      case 'STONE': {
        if (object.countertopOption) {
          return 'Stein / Granit (Arbeitsplatte ist Teil der Lieferung)';
        }
        return 'Stein / Granit (Arbeitsplatte wird von extern geliefert)';
      }
      case 'WOOD': return 'Holz / Schichtstoff';
      case 'STEEL': return 'Edelstahl';
      case 'CERAMIC': return 'Keramik';
      default: return null;
    }
  };

  return (
    <div>
      {renderOptions()}
    </div>
  );
}

CounterTopIcon.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
};
