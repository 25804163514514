/* eslint react/prop-types: 0 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getList } from '../../../Redux/Action/TeamsAction';
import TeamsTable from '../../../Components/TeamsTable';
import Button from '../../../Components/Button';
import Add from './Add';
import Update from './Update';
import Panel from '../../../Components/Panel';

/**
 * List()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function List(props) {
  const { getAll, storeTeams } = props;
  const [addVisible, setAddVisible] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  useEffect(() => {
    getAll();
  }, [getAll]);

  const handeOnSave = () => {
    getAll();
    setAddVisible(false);
  };

  const handleOnUpdate = (data) => {
    setShowUpdate(true);
    setUpdateData(data);
  };

  const handleOnUpdateClose = () => {
    setShowUpdate(false);
    setUpdateData(null);
  };

  const renderContent = () => {
    if (storeTeams && storeTeams.length > 0) {
      return (
        <div className="col-sm-12">
          <TeamsTable teams={storeTeams} onUpdate={handleOnUpdate} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <Panel marginBottom={25}>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h4>Montageteams</h4>
              <p style={{ marginBottom: 0 }}>
                Verwalten Sie die Montageteams zur einfacheren Zuweisung!
              </p>
            </div>
            <div className="d-flex align-items-center">
              <Button onClick={() => setAddVisible(true)} className="pull-right">
                <i className="fas fa-plus" />
                Erstellen
              </Button>
            </div>
          </div>
        </Panel>
      </div>

      {renderContent()}

      <Add visible={addVisible} onClose={() => setAddVisible(false)} onSaved={() => handeOnSave()} />
      <Update
        visible={showUpdate}
        onClose={handleOnUpdateClose}
        onSaved={handleOnUpdateClose}
        updateData={updateData}
      />
    </div>
  );
}

function mapStoreToProps(store) {
  return {
    isLoading: store.teams.isLoggedin,
    storeTeams: store.teams.teams,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAll: () => dispatch(getList(true)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(List));
