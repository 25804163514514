import * as Redux from 'react-redux';
import React from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../../../Library/Axios';
import { applyFiltersToObjectArray } from '../../../../Library/Functions';

export const SYSTEM_SERVICES_GET_LIST_START = 'SYSTEM_SERVICES_GET_LIST_START';
export const SYSTEM_SERVICES_GET_LIST_SUCCESS = 'SYSTEM_SERVICES_GET_LIST_SUCCESS';
export const SYSTEM_SERVICES_GET_LIST_ERROR = 'SYSTEM_SERVICES_GET_LIST_ERROR';

/**
 * useDispatchGetServiceView()
 * @returns {function(): *}
 * @param target
 * @param startDate
 * @param endDate
 * @param searchValue
 */
export const useDispatchGetServiceView = (target, startDate, endDate, searchValue = null) => {
  const dispatch = Redux.useDispatch();
  const uri = 'system/view/service';
  const config = {
    params: {
      action: target,
      startDate,
      endDate,
      searchValue,
    },
  };

  return React.useCallback(() => {
    dispatch({
      type: SYSTEM_SERVICES_GET_LIST_START,
      payload: {
        target, startDate, endDate, searchValue,
      },
    });
    return axios().get(uri, config).then((response) => {
      const services = response.data.data;
      const lastUpdated = new Date().getTime();
      dispatch({ type: SYSTEM_SERVICES_GET_LIST_SUCCESS, payload: { target, services, lastUpdated } });
      return Promise.resolve(services);
    }).catch((error) => {
      dispatch({ type: SYSTEM_SERVICES_GET_LIST_ERROR, payload: { target, error } });
      return Promise.reject(error);
    });
  }, [uri, dispatch, config, target, startDate, endDate, searchValue]);
};

export const SYSTEM_SERVICES_UPDATE_FILTERS = 'SYSTEM_SERVICES_UPDATE_FILTERS';

/**
 * updateFilters
 * @param target
 * @param newFilter
 * @returns {(function(*): void)|*}
 */
export function updateFilters(target, newFilter) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_SERVICES_UPDATE_FILTERS, payload: { target, newFilter } });
  };
}

export const SYSTEM_SERVICES_APPLY_FILTERS_START = 'SYSTEM_SERVICES_APPLY_FILTERS_START';
export const SYSTEM_SERVICES_APPLY_FILTERS_FINISHED = 'SYSTEM_SERVICES_APPLY_FILTERS_FINISHED';
export const SYSTEM_SERVICES_APPLY_FILTERS_SUCCESS = 'SYSTEM_SERVICES_APPLY_FILTERS_SUCCESS';

/**
 * Apply all filters to service of target
 * @returns {(function(*): void)|*}
 */
export function useDispatchApplyServiceFilters() {
  const dispatch = useDispatch();
  return React.useCallback((target, objects, filters, prevFilteredObjects) => {
    dispatch({ type: SYSTEM_SERVICES_APPLY_FILTERS_START, payload: { target } });
    const filteredServices = applyFiltersToObjectArray(objects, filters);

    if (filteredServices
      && prevFilteredObjects
      && filteredServices.length === prevFilteredObjects.length
      && filteredServices.every(
        (object, index) => JSON.stringify(object) === JSON.stringify(prevFilteredObjects[index]),
      )) {
      dispatch({ type: SYSTEM_SERVICES_APPLY_FILTERS_FINISHED, payload: { target } });
    } else {
      dispatch({ type: SYSTEM_SERVICES_APPLY_FILTERS_SUCCESS, payload: { target, filteredServices } });
    }
  }, [dispatch]);
}
