import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import CustomReactSelect from '../CustomReactSelect';

export const getDisplayString = (week) => {
  if (week && week !== '0000-00-00') {
    const currentWeek = moment(week).format('ww');
    const currentYear = moment(week).format('y');
    const startOfWeek = moment(week).startOf('week');
    const endOfWeek = moment(week).endOf('week');
    return (
      `KW ${currentWeek}/${currentYear} (${startOfWeek.format('DD.MM.')} - ${endOfWeek.format('DD.MM.')})`
    );
  }
  return '';
};

/**
 * WeekPickerInput()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function WeekPickerInput(props) {
  const {
    visible, onChange, clearValue, rowClass, value,
  } = props;

  const [weeks, setWeeks] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState('');
  const [initialize, setInitialize] = useState(false);
  const createWeeks = [];

  const generateWeekObject = useCallback(() => {
    createWeeks.push({ label: '‌‌ ', value: null });

    for (let i = 0; i < 52; i += 1) {
      createWeeks.push({
        label: getDisplayString(moment().add(i, 'weeks')),
        value: moment().add(i, 'weeks').startOf('week').format('YYYY-MM-DD'),
      });
    }
    setWeeks(createWeeks);
  }, [createWeeks]);

  React.useEffect(() => {
    if (!initialize) {
      generateWeekObject();
      setInitialize(true);
    }
  }, [initialize, generateWeekObject]);

  React.useEffect(() => {
    setSelectedWeek(null);
  }, [clearValue]);

  const handleOnChangeCustom = (newValue) => {
    setSelectedWeek(newValue);
    onChange(newValue);
  };

  if (visible) {
    return (
      <div className={rowClass} style={{ marginTop: 0 }}>
        <CustomReactSelect
          onChange={handleOnChangeCustom}
          value={(value === '') ? selectedWeek : value}
          options={weeks}
        />
      </div>
    );
  }
  return null;
}

WeekPickerInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  clearValue: PropTypes.bool,
  rowClass: PropTypes.string,
  value: PropTypes.string,
};

WeekPickerInput.defaultProps = {
  visible: true,
  clearValue: null,
  rowClass: 'row',
  value: '',
};
