import moment from 'moment/moment';
import {
  EMPTY_STRING,
  ERROR_BALANCE_INVALID,
  ERROR_BALANCE_TO_BIG,
  ERROR_DELIVERY_DATE_END_BEFORE_START,
  ERROR_DELIVERY_DATE_INVALID,
  ERROR_EXTERNAL_ID_DUPLICATE,
  ERROR_FILE_EXTENSION_INVALID,
  ERROR_MAIL_INVALID,
  ERROR_PHONE_OR_MOBILE_MSSING,
  ERROR_PRICE_INVALID,
  ERROR_REMOVAL_METER_INVALID,
  ERROR_RUNNING_METER_BOTTOM_INVALID,
  ERROR_VALUE_MSSING,
  ERROR_ZIP_INVALID,
} from '../ErrorMessages';
import { isValidFloatNumberFormat } from '../Functions';
import { ORDER_TYPE_OBJECT, ORDER_TYPE_SINGLE } from '../Types/OrderTypes';

/**
 * Validator should return empty string if everything is okay and an error message otherwise
 */

export const validateNotEmpty = (value) => (value ? EMPTY_STRING : ERROR_VALUE_MSSING);

export const validateZip = (zip, isInternal = false) => {
  let regExp;
  if (isInternal) {
    regExp = /\d{4,5}/;
  } else {
    regExp = /\d{5}/;
  }
  return regExp.test(zip);
};

export const validateMail = (mail) => {
  const regExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  return regExp.test(mail);
};

export const validateFileExtension = (fileName) => {
  const allowedExtensions = ['pdf', 'doc', 'docx', 'jpeg', 'jpg', 'png', 'bmp'];
  const extension = fileName.split('.').pop();
  return (allowedExtensions.indexOf(extension.toLowerCase()) !== -1);
};

export const validateDocuments = (documents) => {
  let errors = {};

  const { orderConfirmation, installationPlan } = documents;

  if (!orderConfirmation) {
    errors = { ...errors, orderConfirmation: true };
  } else if (!validateFileExtension(orderConfirmation.name)) {
    errors = { ...errors, orderConfirmation: true, orderConfirmationMessage: ERROR_FILE_EXTENSION_INVALID };
  }

  if (!installationPlan) {
    errors = { ...errors, installationPlan: true };
  } else if (!validateFileExtension(installationPlan.name)) {
    errors = { ...errors, installationPlan: true, installationPlanMessage: ERROR_FILE_EXTENSION_INVALID };
  }

  return errors;
};

const validateSingleObject = (object) => {
  let errors = {};

  if (!object.price || object.price === '') {
    errors = { ...errors, price: true };
  } else if (!isValidFloatNumberFormat(object.price)) {
    errors = { ...errors, price: true, priceMessage: ERROR_PRICE_INVALID };
  }

  if (!object.balance || object.balance === '') {
    errors = { ...errors, balance: true };
  } else if (!isValidFloatNumberFormat(object.balance)) {
    errors = { ...errors, balance: true, balanceMessage: ERROR_BALANCE_INVALID };
  } else if (object.price && parseFloat(object.balance) > parseFloat(object.price)) {
    errors = { ...errors, balance: true, balanceMessage: ERROR_BALANCE_TO_BIG };
  }

  if (!object.runningMeterBottom || object.runningMeterBottom === '') {
    errors = { ...errors, runningMeterBottom: true };
  } else if (!isValidFloatNumberFormat(object.runningMeterBottom)) {
    errors = { ...errors, runningMeterBottom: true, runningMeterBottomMessage: ERROR_RUNNING_METER_BOTTOM_INVALID };
  }

  return errors;
};

const validateExternalIdUnique = (object, objects) => {
  const duplicate = objects.filter(
    (item) => item.id !== object.id,
  ).find(
    (item) => item.externalId === object.externalId,
  );

  if (duplicate) {
    return { externalId: true, externalIdMessage: ERROR_EXTERNAL_ID_DUPLICATE };
  }

  return {};
};

export const validateObject = (object, objects, orderType, commentMandatory = false) => {
  let errors = {};

  if (orderType === ORDER_TYPE_SINGLE) {
    errors = { ...errors, ...validateSingleObject(object) };
  }

  if (!object.externalId || object.externalId === '') {
    errors = { ...errors, externalId: true };
  } else if (objects) {
    errors = { ...errors, ...validateExternalIdUnique(object, objects) };
  }

  if (!object.countertopType) {
    errors = { ...errors, countertopType: true };
  }

  if (commentMandatory && (!object.comment || object.comment.length === 0)) {
    errors = { ...errors, comment: true };
  }

  return errors;
};

export const validateCustomer = (customer, isInternal) => {
  let errors = {};

  if (!customer.firstname) {
    errors = { ...errors, firstname: true };
  }

  if (!customer.lastname) {
    errors = { ...errors, lastname: true };
  }

  if (!customer.street) {
    errors = { ...errors, street: true };
  }

  if (!customer.streetNo) {
    errors = { ...errors, streetNo: true };
  }

  if (!customer.city) {
    errors = { ...errors, city: true };
  }

  if (!customer.zip) {
    errors = { ...errors, zip: true };
  } else if (!validateZip(customer.zip, isInternal)) {
    errors = { ...errors, zip: true, zipMessage: ERROR_ZIP_INVALID };
  }

  if (!customer.phone && !customer.mobile) {
    errors = { ...errors, phone: true, phoneMessage: ERROR_PHONE_OR_MOBILE_MSSING };
    errors = { ...errors, mobile: true, mobileMessage: ERROR_PHONE_OR_MOBILE_MSSING };
  }

  if (customer.mail && !validateMail(customer.mail)) {
    errors = { ...errors, mail: true, mailMessage: ERROR_MAIL_INVALID };
  }

  if (customer.floor === null || customer.floor === undefined) {
    errors = { ...errors, floor: true };
  }

  if (customer.hasInvoiceAddress) {
    if (!customer.invoiceFirstname) {
      errors = { ...errors, invoiceFirstname: true };
    }

    if (!customer.invoiceLastname) {
      errors = { ...errors, invoiceLastname: true };
    }

    if (!customer.invoiceStreet) {
      errors = { ...errors, invoiceStreet: true };
    }

    if (!customer.invoiceStreetNo) {
      errors = { ...errors, invoiceStreetNo: true };
    }

    if (!customer.invoiceCity) {
      errors = { ...errors, invoiceCity: true };
    }

    if (!customer.invoiceZip) {
      errors = { ...errors, invoiceZip: true };
    } else if (!validateZip(customer.invoiceZip, isInternal)) {
      errors = { ...errors, invoiceZip: true, invoiceZipMessage: ERROR_ZIP_INVALID };
    }
  }

  return errors;
};

export const validateObjectContainer = (object, objects, orderType, isInternal, commentMandatory = false) => ({
  ...validateObject(object, objects, orderType, isInternal && commentMandatory),
  ...validateCustomer(object.customer, isInternal),
  ...validateDocuments(object.documents),
});

export const validateOrder = (order) => {
  let errors = {};

  if (order.internal == null) {
    errors = { ...errors, internal: true };
  }

  if (!order.type) {
    errors = { ...errors, type: true };
  }

  if (!order.optionMontage && !order.optionStorage && !order.optionDelivery && !order.optionRemoval) {
    errors = { ...errors, option: true };
  }

  if (order.optionRemoval) {
    if (!order.removalMeter) {
      errors = { ...errors, removalMeter: true };
    } else if (!isValidFloatNumberFormat(order.removalMeter)) {
      errors = { ...errors, removalMeter: true, removalMeterMessage: ERROR_REMOVAL_METER_INVALID };
    }
  }

  if (order.type === ORDER_TYPE_OBJECT && (!order.objects || order.objects.length <= 0)) {
    errors = { ...errors, objects: true };
  }

  return errors;
};

export const validateDeliveryDate = (order) => {
  if (order.internal) {
    return {};
  }

  let errors = {};

  if (!order.deliveryDate || !order.deliveryDate.deliveryDateStart) {
    errors = {
      ...errors,
      deliveryDateStart: true,
      deliveryDateStartMessage: ERROR_DELIVERY_DATE_INVALID,
    };
  }

  if (order.type === ORDER_TYPE_OBJECT) {
    if (!order.deliveryDate || !order.deliveryDate.deliveryDateEnd) {
      errors = {
        ...errors,
        deliveryDateEnd: true,
        deliveryDateEndMessage: ERROR_DELIVERY_DATE_INVALID,
      };
    }

    if (order.deliveryDate && order.deliveryDate.deliveryDateStart && order.deliveryDate.deliveryDateEnd) {
      const start = moment(order.deliveryDateStart, 'YYYY-MM-DD');
      const end = moment(order.deliveryDateEnd, 'YYYY-MM-DD');

      if (end.diff(start, 'days') < 1) {
        errors = {
          ...errors,
          deliveryDateEnd: true,
          deliveryDateEndMessage: ERROR_DELIVERY_DATE_END_BEFORE_START,
        };
      }
    }
  }

  return errors;
};
