import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import FileInput from '../../../../Components/FileInput';
import { ORDERTYPE_SINGLE } from '../../../../Library/Types';

function initReducer(data) {
  if (data !== undefined && Object.keys(data).length > 0) {
    return { ...data, fileOther: data.fileOther };
  }
  return {
    orderConfirmation: null,
    installationPlan: null,
    fileOther: [],
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'orderConfirmation':
      return { ...state, orderConfirmation: action.payload };
    case 'deleteOrderConfirmation':
      return { ...state, orderConfirmation: null };
    case 'installationPlan':
      return { ...state, installationPlan: action.payload };
    case 'deleteInstallationPlan':
      return { ...state, installationPlan: null };
    case 'fileOther':
      if (state.fileOther) {
        return { ...state, fileOther: [...state.fileOther, action.payload] };
      }
      return { ...state, fileOther: [action.payload] };
    case 'deleteFileOther': {
      const { payload } = action;
      const merged = state.fileOther.filter((item) => item !== payload);
      return { ...state, fileOther: merged };
    }

    default:
      throw new Error();
  }
}

/**
 * ObjectFiles()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectFiles(props) {
  const {
    onChange, editableData, errors, isEditMode, orderType,
  } = props;
  const [state, dispatch] = useReducer(reducer, editableData, initReducer);
  const [prevState, setPrevState] = useState(null);

  useEffect(() => {
    if (JSON.stringify(state) !== prevState) {
      if (onChange) {
        onChange(state);
        setPrevState(JSON.stringify(state));
      }
    }
  }, [onChange, state, prevState]);

  const getHeadline = () => {
    if (orderType === ORDERTYPE_SINGLE) {
      return '6. Dokumente';
    }
    return '4.3 Dokumente';
  };

  const renderFileOther = () => {
    const returnArray = [];
    if (state.fileOther && state.fileOther.length > 0) {
      state.fileOther.map((item) => {
        returnArray.push(<FileInput
          onChange={(value) => dispatch({ type: 'fileOther', payload: value })}
          placeholder="Weitere Dokumente"
          value={item}
          onDelete={(value) => dispatch({ type: 'deleteFileOther', payload: value })}
          key={item.filename}
        />);

        return item;
      });
    }

    returnArray.push((
      <FileInput
        onChange={(value) => dispatch({ type: 'fileOther', payload: value })}
        placeholder="Weitere Dokumente"
        value={null}
        onDelete={() => {}}
        key={Math.random()}
      />
    ));
    return returnArray;
  };

  const getDescription = () => {
    if (isEditMode) {
      return (
        <p className="text-muted">
          Beim bearbeiten des Auftrags müssen Sie nicht zwingend die Dokumente erneut angeben. Die Dokumente wurden
          bereits zum Auftrag gespeichert. Wenn Sie einen neuen Installationsplan oder eine neue Auftragsbestätigung
          hinzufügen möchten, können Sie diese einfach jetzt hinzufügen. Das bereits vorhandene Dokument wird dabei
          überschrieben. Sie können beliebig viele "Weitere Dokumente" hinzufügen. Die Dokumente können auch später
          in den Auftragsdetails weiter bearbeitet oder gelöscht werden.
        </p>
      );
    }
    return (
      <p className="text-muted">
        Bitte laden Sie die entsprechenden Dokumente als Bild oder PDF-Datei hoch
      </p>
    );
  };

  return (
    <div>
      <h5>{getHeadline()}</h5>
      {getDescription()}
      <FileInput
        onChange={(value) => dispatch({ type: 'orderConfirmation', payload: value })}
        placeholder="Auftragsbestätigung / Angebot"
        value={state.orderConfirmation}
        hasError={!!errors.orderConfirmation}
        errorMessage={errors.orderConfirmationMessage}
        onDelete={() => dispatch({ type: 'deleteOrderConfirmation' })}
      />
      <FileInput
        onChange={(value) => dispatch({ type: 'installationPlan', payload: value })}
        placeholder="Küchenplan / Installationsplan"
        value={state.installationPlan}
        hasError={!!errors.installationPlan}
        errorMessage={errors.installationPlanMessage}
        onDelete={() => dispatch({ type: 'deleteInstallationPlan' })}
      />
      {renderFileOther()}
    </div>
  );
}

ObjectFiles.propTypes = {
  onChange: PropTypes.func.isRequired,
  editableData: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  isEditMode: PropTypes.bool,
  orderType: PropTypes.string,
};

ObjectFiles.defaultProps = {
  editableData: {},
  errors: {},
  isEditMode: false,
  orderType: 'SINGLE',
};
