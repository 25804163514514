import React from 'react';
import { useSelector } from 'react-redux';
import Panel from '../../../../Components/Panel';
import { useDispatchUpdateSettings } from '../../../../Redux/Action/ClientSettingsAction';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';

const YesNoMontageInfoOption = [
  { label: 'Nein, es muss nicht zwingend ein Montagehinweis eingegeben werden', value: false },
  { label: 'Ja, bei Erstellung muss ein Montagehinweis eingegeben werden', value: true },
];

/**
 * InternalOrderSettings()
 * @returns {*}
 * @constructor
 */
export default function InternalOrderSettings() {
  const { client } = useSelector((state) => state.client);
  const dispatchUpdateSettings = useDispatchUpdateSettings();

  return (
    <div className="row">
      <div className="col-sm-12">
        <Panel marginBottom={25}>
          <h6>
            Interne Aufträge
          </h6>
          <p style={{ marginBottom: 40 }}>
            Hier können Sie Einstellungen zu Ihren internen Aufträgen verwalten.
          </p>

          <KeyValueEditRow
            title="Montagehinweise ist ein Pflichtfeld"
            type="select"
            selectData={YesNoMontageInfoOption}
            initialValue={client.settings.internalMontageInfoMandatory}
            displayText={
              (client.settings.internalMontageInfoMandatory)
                ? 'Ja, bei Erstellung muss ein Montagehinweis eingegeben werden'
                : 'Nein, bei Erstellung muss nicht zwingend ein Montagehinweis eingegeben werden'
            }
            onChange={(value) => dispatchUpdateSettings({ ...client.settings, internalMontageInfoMandatory: value })}
          />
        </Panel>
      </div>
    </div>
  );
}
