import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Panel from '../../Panel';
import { assignePrice, calculate } from '../../../Pages/System/Redux/Action/OrderAction';
import Button from '../../Button';
import { formatCurrency } from '../../../Library/Functions';
import ModalDialog from '../../ModalDialog';
import Input from '../../Input';
import { get, singleUpdate } from '../../../Redux/Action/OrderAction';
import Checkbox from '../../Checkbox';
import { ORDERSTATE_WAITING_CALCULATION } from '../../../Library/StateTypes';

/**
 * PriceInformation()
 * @param props
 * @constructor
 */
function PriceInformation(props) {
  const {
    order, dispatchAssigneePrice, dispatchGetOrder, dispatchUpdateOrder, dispatchCalculate, editable,
    visible,
  } = props;
  const [showEdit, setShowEdit] = React.useState(false);
  const [dealerPrice, setDealerPrice] = React.useState(0);
  const [monteurPrice, setMonteurPrice] = React.useState('0');
  const [monteurManual, setMonteurManual] = React.useState(false);
  const [dealerPriceError, setDealerPriceError] = React.useState(false);
  const [monteurPriceError, setMonteurPriceError] = React.useState(false);

  React.useEffect(() => {
    if (order) {
      if (order.state !== ORDERSTATE_WAITING_CALCULATION) {
        setDealerPrice(order.dealerPrice);
        setMonteurPrice(order.mechanicPrice);
      } else {
        dispatchCalculate(order.orderId).then((response) => {
          setDealerPrice(response.dealerPrice);
          setMonteurPrice(response.monteurPrice);
        });
      }
    }
  }, [order, dispatchCalculate]);

  React.useEffect(() => {
    if (!monteurManual && dealerPrice) {
      const mergedDealerPrice = parseFloat(dealerPrice.replace(',', '.'));
      setMonteurPrice(
        parseFloat((mergedDealerPrice - (mergedDealerPrice * 0.25)).toFixed(2))
          .toFixed(2),
      );
    }
  }, [dealerPrice, monteurManual]);

  const handleSavePrice = () => {
    setDealerPriceError(false);
    setMonteurPriceError(false);
    const mergedDealerPrice = parseFloat(dealerPrice.toString().replace(',', '.'));
    const mergedMonteurPrice = parseFloat(monteurPrice.toString().replace(',', '.'));

    if (!mergedDealerPrice || mergedDealerPrice <= 0) {
      setDealerPriceError(true);
    }

    if (!mergedMonteurPrice || mergedMonteurPrice <= 0 || mergedMonteurPrice >= mergedDealerPrice) {
      setMonteurPriceError(true);
    }

    if (order.state === ORDERSTATE_WAITING_CALCULATION) {
      dispatchAssigneePrice(order, mergedDealerPrice, mergedMonteurPrice).then(() => {
        dispatchGetOrder(order.orderId);
        setShowEdit(false);
      });
    } else {
      dispatchUpdateOrder(order.orderId, 'dealerPrice', mergedDealerPrice).then(() => {
        dispatchUpdateOrder(order.orderId, 'mechanicPrice', mergedMonteurPrice).then(() => {
          setShowEdit(false);
        });
      });
    }
  };

  const getHeadline = () => {
    if (order.state === ORDERSTATE_WAITING_CALCULATION) {
      return 'Preis zuweisen';
    }
    return 'Preis ändern';
  };

  const getDescription = () => {
    if (order.state === ORDERSTATE_WAITING_CALCULATION) {
      return (
        <p>
          Nach der erfolgreichen Preiszuweisung wird der Kunde darüber informiert das für diesen Auftrag ein
          Preis berechnet wurde und er diesen jetzt annehmen kann!
        </p>
      );
    }
    return null;
  };

  const renderEditButton = () => {
    if (editable) {
      return (
        <Button onClick={() => setShowEdit(true)} type="btn-light">
          <i
            className="fas fa-pencil-alt"
            onClick={() => setShowEdit(true)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
            style={{ margin: 0 }}
          />
        </Button>
      );
    }
    return null;
  };

  const renderVoucherHint = () => {
    if (order.voucherId) {
      return (
        <>
          <tr>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td>Gutschein:</td>
            <td>
              <i className="fas fa-check-circle text-success" style={{ marginRight: 6 }} />
              {`Verwendet Gutscheinnummer ${order.voucherId}`}
            </td>
          </tr>
          <tr>
            <td>Rabatt:</td>
            <td>
              {formatCurrency(order.lastCalculationLog.discountPrice)}
            </td>
          </tr>
        </>
      );
    }
    return null;
  };

  const renderPrice = () => {
    if (order.state === ORDERSTATE_WAITING_CALCULATION) {
      return (
        <div className="hk-text-primary">
          Es ist noch kein Preis vorhanden. Sie müssen den Preis manuell hinzufügen!
        </div>
      );
    }

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td width={150}>Händlerpreis:</td>
              <td><strong>{formatCurrency(order.dealerPrice)}</strong></td>
            </tr>
            <tr>
              <td>Montagepreis:</td>
              <td><strong>{formatCurrency(order.mechanicPrice)}</strong></td>
            </tr>
            <tr>
              <td colSpan={2}>&nbsp;</td>
            </tr>
            <tr>
              <td>Marge:</td>
              <td><strong>{formatCurrency(order.dealerPrice - order.mechanicPrice)}</strong></td>
            </tr>
            {renderVoucherHint()}
          </tbody>
        </table>
      </div>
    );
  };

  if (!order.internal && visible) {
    return (
      <Panel marginBottom={20} className="CustomerAddress">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6>Preisinformationen</h6>
          </div>
          <div>
            {renderEditButton()}
          </div>
        </div>

        {renderPrice()}

        <ModalDialog onClose={() => setShowEdit(false)} visible={showEdit} onConfirm={handleSavePrice}>
          <h5 className="hk-text-primary">
            {getHeadline()}
          </h5>
          {getDescription()}
          <hr />
          <Input
            onChange={setDealerPrice}
            value={dealerPrice}
            label="Händlerpreis"
            rightIcon="fas fa-euro-sign"
            hasError={dealerPriceError}
          />
          <Input
            onChange={setMonteurPrice}
            value={monteurPrice}
            label="Montagepreis"
            rightIcon="fas fa-euro-sign"
            hasError={monteurPriceError}
            disabled={!monteurManual}
          />
          <Checkbox onChange={() => setMonteurManual(!monteurManual)} value={monteurManual}>
            Montagepreis manuell eingeben
          </Checkbox>
        </ModalDialog>
      </Panel>
    );
  }
  return null;
}

PriceInformation.propTypes = {
  dispatchAssigneePrice: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchUpdateOrder: PropTypes.func.isRequired,
  dispatchCalculate: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  editable: PropTypes.bool,
  visible: PropTypes.bool,
};

PriceInformation.defaultProps = {
  editable: true,
  visible: true,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchAssigneePrice: (order, dealer, monteur) => dispatch(assignePrice(order, dealer, monteur)),
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
    dispatchUpdateOrder: (orderId, field, value) => dispatch(singleUpdate(orderId, field, value)),
    dispatchCalculate: (orderId) => dispatch(calculate(orderId)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(PriceInformation);
