import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../Panel';
import TaskListRow from './TaskListRow';
import LoadingModal from '../LoadingModal';
import EditTaskModal from './EditTaskModal';

/**
 * TaskOverview()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TaskList(props) {
  const { tasks, emptyHint, showObjectExternalId } = props;
  const [selectedTask, setSelectedTask] = React.useState(null);

  if (tasks) {
    if (tasks.length === 0) {
      return (
        <Panel>
          <div className="alert alert-dark" style={{ margin: 0 }}>
            <i className="fas fa-info-circle" style={{ marginRight: 6 }} />
            {emptyHint}
          </div>
        </Panel>
      );
    }

    return (
      <Panel>
        <table className="table table-striped table-hover">
          <tbody>
            {tasks.map((item) => (
              <TaskListRow
                task={item}
                key={item.taskId}
                onPress={setSelectedTask}
                showObjectExternalId={showObjectExternalId}
              />
            ))}
          </tbody>
        </table>

        <EditTaskModal
          visible={(selectedTask !== null)}
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
        />
      </Panel>
    );
  }
  return <LoadingModal visible text="Aufgaben werden geladen..." />;
}

TaskList.propTypes = {
  tasks: PropTypes.instanceOf(Object),
  emptyHint: PropTypes.string,
  showObjectExternalId: PropTypes.bool,
};

TaskList.defaultProps = {
  tasks: null,
  emptyHint: 'Sie haben keine Aufgaben in Ihrer Liste',
  showObjectExternalId: true,
};
