import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { formatDate } from '../../../Library/Functions';

/**
 * ReclamationMenu()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ReclamationMenu(props) {
  const {
    reclamations, order,
  } = props;
  const { url } = useRouteMatch();

  const renderReclamation = () => {
    let returnResult = [];

    if (reclamations) {
      returnResult = reclamations.map((reclamation) => (
        <li key={reclamation.reclamationId}>
          <NavLink className="nav-link" to={`${url}/reclamation/${reclamation.reclamationId}`}>
            <div className="d-flex">
              <div>
                <i className="fas fa-sync" />
              </div>
              <div className="d-flex flex-column flex-grow-1">
                <div className="ReclamationTitle">{reclamation.title}</div>
                <div className="Date">
                  {
                    (reclamation.executionDate)
                      ? formatDate(reclamation.executionDate)
                      : <i className="text-muted">Kein Ausführungsdatum</i>
                  }
                </div>
              </div>
            </div>
          </NavLink>
        </li>
      ));
    }

    if (returnResult.length > 0) {
      return (
        <div className="ReclamationItems">
          <hr />
          <h6 className="Headline">
            Reklamationen
          </h6>
          {returnResult}
        </div>
      );
    }
    return null;
  };

  if (order.internal && (!reclamations || reclamations.length === 0)) {
    return null;
  }

  return (
    <>
      {renderReclamation()}
    </>
  );
}

ReclamationMenu.propTypes = {
  reclamations: PropTypes.instanceOf(Array),
  order: PropTypes.instanceOf(Object),
};

ReclamationMenu.defaultProps = {
  reclamations: null,
  order: null,
};
