/* eslint-disable import/prefer-default-export */

import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as Redux from 'react-redux';

/**
 * useGetWorkTimeString()
 * @returns {(function(*=, *): (string|string))|*}
 */
export function useGetWorkTimeString() {
  const { settings } = useSelector((state) => state.client.client);

  return React.useCallback((internal, executionTime) => {
    if (internal) {
      const minutesPerDay = Math.abs(
        moment(settings.globalWorkDayStart, 'HH:mm')
          .diff(moment(settings.globalWorkDayEnd, 'HH:mm'), 'minute'),
      );
      const workTimeOfDay = Math.round((executionTime / minutesPerDay) * 2) / 2;
      switch (workTimeOfDay) {
        case 0:
          return '';
        case 1:
          return '1 Tag';
        default:
          return `${workTimeOfDay} Tage`;
      }
    }

    if (executionTime <= 270) {
      return '0,5 Tage';
    } if (executionTime <= 540) {
      return '1 Tag';
    } if (executionTime <= 810) {
      return '1,5 Tage';
    } if (executionTime <= 1080) {
      return '2 Tage';
    } if (executionTime <= 1350) {
      return '2,5 Tage';
    } if (executionTime <= 1620) {
      return '3 Tage';
    } if (executionTime <= 1890) {
      return '3,5 Tage';
    } if (executionTime <= 2160) {
      return '4 Tage';
    } if (executionTime <= 2430) {
      return '4,5 Tage';
    } if (executionTime <= 2700) {
      return '5 Tage';
    }
    return '';
  }, [settings]);
}

/**
 * Checks if the current logged in client is dealer or assigned to the order/object
 * @param order
 * @param object
 */
export function useIsDealerOrAssignedClient(order, object) {
  const client = Redux.useSelector((state) => state.auth.client);

  return (order && object && (
    client.clientId === order.clientId
    || (object.montageDate && client.clientId === object.montageDate.clientId)
    || (object.deliveryDate && client.clientId === object.deliveryDate.clientId)
    || (object.removalDate && client.clientId === object.removalDate.clientId)
    || (object.storageDate && client.clientId === object.storageDate.clientId)
  ));
}
