/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import Panel from '../../../Components/Panel';
import { get, approve } from '../../../Redux/Action/OrderAction';
import Button from '../../../Components/Button';
import ModalDialog from '../../../Components/ModalDialog';
import { formatCurrency, formatDate } from '../../../Library/Functions';
import { checkIsAllowedToRequestOffer } from '../../../Redux/Action/ClientAction';
import Alert from '../../../Components/Alert';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import { ORDERTYPE_SINGLE } from '../../../Library/Types';
import NotAllowedHint from '../../../Components/OrderComponents/NotAllowedHint';
import { useGetWorkTimeString } from '../../../Library/HelperHooks';

/**
 * ApproveOrder()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function ApproveOrder(props) {
  const {
    order, getOrder, history, match, dispatchIsAllowedRequestOffer, client,
  } = props;
  const getWorkTimeString = useGetWorkTimeString();
  const [object, setObject] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessHint, setShowSuccessHint] = useState(false);
  const [isAllowedToRequest, setIsAllowedToRequest] = useState(false);
  const [orderId] = useState(match.params.orderId);

  useEffect(() => {
    getOrder(orderId).then((data) => {
      setObject(data.objects[0]);
    }).catch(() => {
      history.push('/dashboard');
    });

    dispatchIsAllowedRequestOffer()
      .then(() => setIsAllowedToRequest(true))
      .catch(() => setIsAllowedToRequest(false));
  }, [getOrder, history, orderId, dispatchIsAllowedRequestOffer]);

  useEffect(() => {
    if (order && order.clientId !== client.clientId) {
      history.push('/dashboard');
    }
  }, [order, client.clientId, history]);

  const approveOrder = () => {
    props.approve(order.orderId).then(() => {
      setShowModal(false);
      setShowSuccessHint(true);
    });
  };

  const onCloseSuccess = () => {
    setShowSuccessHint(false);
    history.push(`/order/${order.orderId}/handle`);
  };

  const renderEditCountHint = () => {
    if (order.editCount < 3) {
      return (
        <div>
          <small>
            {`Sie können Ihre Anfrage noch maximal ${3 - order.editCount} mal bearbeiten`}
          </small>
        </div>
      );
    }
    return (
      <div className="alert alert-hk-primary" style={{ marginTop: 20 }}>
        <strong>
          <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
          <br />
          Sie können den Aufrag nicht mehr bearbeiten.
        </strong>
        <br />
        Sollte zwingend eine weitere Bearbeitung notwendig sein, wenden
        Sie sich bitte an den hey.kitchen Support.
      </div>
    );
  };

  const getValidDate = () => {
    if (order.expirationDate) {
      return moment(order.expirationDate, 'YYYY-MM-DD HH:mm')
        .format('DD.MM.YYYY - HH:mm');
    }
    return moment(order.createdDate, 'YYYY-MM-DD HH:mm')
      .add(3, 'days')
      .format('DD.MM.YYYY - HH:mm');
  };

  const renderVoucherInfo = () => {
    if (order.voucherId && order.lastCalculationLog) {
      return (
        <div style={{ marginTop: 20 }}>
          <div>
            <small>{`Originalpreis: ${formatCurrency(order.lastCalculationLog.overall)}`}</small>
          </div>
          <div className="text-info">
            <strong>
              {`Durch Ihren Gutschein sparen Sie: ${formatCurrency(order.lastCalculationLog.discountPrice)}`}
            </strong>
          </div>
        </div>
      );
    }
    return null;
  };

  if (order && object) {
    if (order.deleted) {
      return (
        <div className="row">
          <div className="col-xl-3" />
          <div className="col-xl-6">
            <Panel marginBottom={25}>
              <div className="d-flex flex-column align-content-center align-items-center">
                <i className="fas fa-calendar-times hk-text-primary" style={{ fontSize: 80, marginBottom: 30 }} />
                <h3 className="hk-text-primary">
                  Das Angebot ist bereits abgelaufen!
                </h3>
                <p style={{ textAlign: 'center', marginTop: 30 }}>
                  Das Angebot für den Auftrag mit der Kommissionsnummer "
                  <strong>{object.externalId}</strong>
                  " ist bereits abgelaufen.
                  <br />
                  Sollten Sie dennoch weiterhin ein Angebot benötigen, wenden Sie sich bitte an den hey.kitchen Support.
                </p>
              </div>
            </Panel>
          </div>
          <div className="col-xl-3" />
        </div>
      );
    }

    return (
      <div className="row">
        <Alert onClose={onCloseSuccess} type="success" visible={showSuccessHint}>
          <h5>Vielen Dank für Ihren Auftrag!</h5>
          <p>
            <ShowHideContainer visible={order.type === ORDERTYPE_SINGLE}>
              <strong>Bitte informieren Sie Ihren Kunden, </strong>
              dass wir vorraussichtlich
              <strong>{` ${getWorkTimeString(order.internal, order.assemblyTime)} `}</strong>
              bei Ihm vor Ort sein werden, um die Montage durchzuführen.
            </ShowHideContainer>
            <ShowHideContainer visible={order.type !== ORDERTYPE_SINGLE}>
              Ihr Auftrag wurde erfolgreich angenommen und wird jetzt von uns bearbeitet.
            </ShowHideContainer>
          </p>
        </Alert>

        <ModalDialog
          visible={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={() => approveOrder()}
          confirmCaption="Angebot jetzt verbindlich annehmen"
        >
          <strong>Annahme (Vertragsschluss)</strong>
          <br />
          Hiermit bestätigen Sie, dass Sie den Vertrag zu den von der hey.kitchen GmbH angebotenen Konditionen
          annehmen (§ 145 BGB). Nach Annahme erhalten Sie den Vertragsinhalt per E-Mail. Eine nachträgliche
          Vertragsänderung ist ausschließlich in Übereinstimmung mit der hey.kitchen GmbH möglich.
          Den Status Ihres Auftrag können Sie jederzeit unter Ihren Aufträgen einsehen. Sie werden jeweils per
          E-Mail über den aktuellen Status Ihres Auftrages informiert.
        </ModalDialog>

        <div className="col-xl-3" />
        <div className="col-xl-6">
          <Panel marginBottom={25}>
            <div className="d-flex flex-column align-content-center align-items-center">
              <h3 className="hk-text-primary">
                Ihr persönliches Angebot!
              </h3>
              <p>
                Wir haben Ihr persönliches Angebot berechnet!
              </p>
            </div>

            <hr />

            <div className="d-flex flex-row justify-content-center">
              <div style={{ textAlign: 'center', width: '100%' }}>
                <h5>Ihre Anfrage</h5>
                <div>
                  {`Kommisionsnummer: ${object.externalId}`}
                </div>
                <div className="IconOptions" style={{ marginTop: '20px' }}>
                  <i className={`fas fa-truck-moving ${(order.optionDelivery) ? 'Active' : ''}`} />
                  <i className={`fas fa-tools ${(order.optionMontage) ? 'Active' : ''}`} />
                  <i className={`fas fa-layer-group ${(order.optionStorage) ? 'Active' : ''}`} />
                  <i className={`fas fa-trash-alt ${(order.optionRemoval) ? 'Active' : ''}`} />
                </div>
                <div className="IconOptions" style={{ marginTop: '30px' }}>
                  <i className={`fas fa-plug ${(object.workOptionElectric) ? 'Active' : ''}`} />
                  <i className={`fas fa-hand-holding-water ${(object.workOptionWater) ? 'Active' : ''}`} />
                  <i className={`fas fa-wind ${(object.workOptionAir) ? 'Active' : ''}`} />
                  <i className={`fas fa-lightbulb ${(object.workOptionLight) ? 'Active' : ''}`} />
                  <i className={`fas fa-shower ${(object.workOptionQuooker) ? 'Active' : ''}`} />
                </div>
                <address style={{ fontSize: '14px', fontWeight: 'lighter', marginTop: '30px' }}>
                  <div>{`${object.customer.firstname} ${object.customer.lastname}`}</div>
                  <div>{`${object.customer.street} ${object.customer.streetNo}`}</div>
                  <div>{`${object.customer.zip} ${object.customer.city}`}</div>
                  <div>{object.customer.mail}</div>
                  <div>{object.customer.phone}</div>
                </address>
                <div>
                  {`Montagetermin: ${formatDate(object.montageDate.date)}`}
                </div>

                <hr />

                <div>
                  <h5>Ihr verbindlicher Angebotspreis</h5>
                  <div className="d-flex flex-column justify-content-center">
                    <div style={{ fontSize: '40px' }} className="text-success">
                      <strong>
                        {formatCurrency(order.dealerPrice)}
                      </strong>
                    </div>
                    <small style={{ fontSize: '12px' }} className="text-muted">(zzgl. gesetzlicher MwSt.)</small>
                    {renderVoucherInfo()}
                  </div>
                </div>

                <hr />

                <div>
                  <div>
                    <small>
                      {`Dieses Angebot ist bis zum ${getValidDate()} Uhr gültig!`}
                    </small>
                  </div>
                  {renderEditCountHint()}
                </div>

              </div>
            </div>
          </Panel>

          <NotAllowedHint isExternal={!order.internal} isNotAllowed={!isAllowedToRequest} />

          <Panel>
            <div className="d-flex flex-row justify-content-center">
              <div className="ButtonContainer">
                <Button onClick={() => props.history.push('/dashboard')} type="btn-light">
                  Abbrechen
                </Button>
                <Button
                  type="btn-light"
                  onClick={() => props.history.push(`/create/${order.orderId}/edit`)}
                  disabled={(order.editCount >= 3)}
                >
                  Bearbeiten
                </Button>
                <Button onClick={() => setShowModal(true)} type="btn-primary" disabled={!isAllowedToRequest}>
                  Angebot verbindlich annehmen
                </Button>
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-xl-3" />
      </div>
    );
  }
  return null;
}

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    isLoading: store.order.isLoading,
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrder: (orderId) => dispatch(get(orderId)),
    approve: (orderId) => dispatch(approve(orderId)),
    dispatchIsAllowedRequestOffer: () => dispatch(checkIsAllowedToRequestOffer()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ApproveOrder));
