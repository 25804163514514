import { useDispatch } from 'react-redux';
import React from 'react';
import axios from '../../Library/Axios';

const defaultUri = '/facility';

export const FACILITY_GET_LIST_START = 'FACILITY_GET_LIST_START';
export const FACILITY_GET_LIST_SUCCESS = 'FACILITY_GET_LIST_SUCCESS';
export const FACILITY_GET_LIST_ERROR = 'FACILITY_GET_LIST_ERROR';

/**
 * useDispatchGetFacilityList()
 * @returns {function(*=, *=): *}
 */
export function useDispatchGetFacilityList() {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch({ type: FACILITY_GET_LIST_START, payload: { defaultUri } });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: FACILITY_GET_LIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: FACILITY_GET_LIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const FACILITY_GET_CLIENT_AUTH_START = 'FACILITY_GET_CLIENT_AUTH_START';
export const FACILITY_GET_CLIENT_AUTH_SUCCESS = 'FACILITY_GET_CLIENT_AUTH_SUCCESS';
export const FACILITY_GET_CLIENT_AUTH_ERROR = 'FACILITY_GET_CLIENT_AUTH_ERROR';

/**
 * useDispatchFacilityGetClientAuth()
 * @returns {function(*=, *=): *}
 */
export function useDispatchFacilityLoginClient() {
  const dispatch = useDispatch();

  return React.useCallback((client) => {
    dispatch({ type: FACILITY_GET_CLIENT_AUTH_START, payload: { defaultUri } });

    const config = {
      params: { action: 'getAuth' },
    };

    return axios().post(defaultUri, client, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: FACILITY_GET_CLIENT_AUTH_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: FACILITY_GET_CLIENT_AUTH_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const FACILITY_CREATE_START = 'FACILITY_CREATE_START';
export const FACILITY_CREATE_SUCCESS = 'FACILITY_CREATE_SUCCESS';
export const FACILITY_CREATE_ERROR = 'FACILITY_CREATE_ERROR';

/**
 * useDispatchCreateFacility()
 * @returns {function(*=, *=): *}
 */
export function useDispatchCreateFacility() {
  const dispatch = useDispatch();

  return React.useCallback((mail, password) => {
    dispatch({ type: FACILITY_CREATE_START, payload: { defaultUri, mail, password } });

    const config = {
      params: { action: 'create' },
    };

    return axios().post(defaultUri, { mail, password }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: FACILITY_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: FACILITY_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
