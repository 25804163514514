import React, { forwardRef } from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import de from 'date-fns/locale/de';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  height: ${(props) => props.theme.cellHeadlineHeight}px;
  border: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  border-top-left-radius: ${(props) => props.theme.border.radius};
  border-top-right-radius: ${(props) => props.theme.border.radius};
  padding: 6px 15px;
  background-color: ${(props) => props.theme.color.backgroundGrayLight};
  font-family: "DIN Next LT W01 Bold", sans-serif;
  font-size: 20px;
  
  .Center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  
  .Right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  .Left {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
  }
`;

const IconContainer = styled.div`
  padding: 4px 12px;
  cursor: pointer;
  border-radius: ${(props) => props.theme.border.radius};
  transition: background-color 300ms, color 300ms;
  
  &:hover {
    background-color: gray;
    color: #ffffff;
  }
`;

const CustomDateContainer = styled.div`
  cursor: pointer;
  
  i {
    margin-right: 10px;

    &:last-child {
      margin-left: 10px;
    }
  }
`;

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Headline(props) {
  const { onChange, selectedDate } = props;

  // eslint-disable-next-line react/prop-types
  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <CustomDateContainer onClick={onClick} ref={ref}>
      <i className="fas fa-calendar-alt" />
      {moment(value).format('dddd, DD.MM.YYYY')}
      <i className="fas fa-caret-down" />
    </CustomDateContainer>
  ));

  const onNext = () => {
    const next = moment(selectedDate).add(1, 'day').format('YYYY-MM-DD');
    onChange(next);
  };

  const onPrev = () => {
    const prev = moment(selectedDate).subtract(1, 'day').format('YYYY-MM-DD');
    onChange(prev);
  };

  return (
    <Container>
      <div className="Left">
        <IconContainer onClick={onPrev}>
          <i className="fas fa-arrow-left" />
        </IconContainer>
      </div>
      <div className="Center">
        <DatePicker
          selected={moment(selectedDate).toDate()}
          onChange={onChange}
          locale={de}
          showWeekNumbers
          onChangeRaw={(e) => e.preventDefault()}
          customInput={<CustomDateInput date={selectedDate} />}
        />
      </div>
      <div className="Right">
        <IconContainer onClick={onNext}>
          <i className="fas fa-arrow-right" />
        </IconContainer>
      </div>
    </Container>
  );
}

Headline.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
};
