import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { lighten } from 'polished';
import { useDispatchDispositionCalculateWorkload } from '../../../../Redux/Action/DispositionAction';

export const ContentRow = styled.div`
  border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: ${(props) => props.theme.height}px;
  
  i {
    color: ${(props) => lighten(0.2, props.theme.bgColor || props.theme.color.primary)};
  }
`;

const IconContainer = styled.i`
  font-size: 19px;
`;

/**
 * WorkLoadField()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function WorkLoadField(props) {
  const {
    item, startDate, endDate, height,
  } = props;
  const theme = useTheme();
  const dispatchCalculateWorkLoad = useDispatchDispositionCalculateWorkload();
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (startDate && item) {
      const type = (item.isUser) ? 'user' : 'team';
      dispatchCalculateWorkLoad(type, item.id, startDate, endDate || startDate).then((response) => {
        setData(response);
      });
    }
  }, [item, startDate, endDate, dispatchCalculateWorkLoad]);

  const getBackgroundColor = () => {
    if (data) {
      if (data.workLoadPercent <= 50) {
        return '#234F1E';
      }
      if (data.workLoadPercent <= 80) {
        return theme.color.primaryOrangeColor;
      }
      if (data.workLoadPercent <= 100) {
        return 'red';
      }
      return 'darkred';
    }
    return null;
  };

  const getIcon = () => {
    if (data) {
      if (data.workLoadPercent <= 50) {
        return 'fas fa-check-circle';
      }
      if (data.workLoadPercent <= 80) {
        return 'fas fa-arrow-circle-right';
      }
      if (data.workLoadPercent <= 100) {
        return 'fas fa-arrow-circle-up';
      }
      return 'fas fa-times-circle';
    }
    return null;
  };

  return (
    <ContentRow key={item.id} theme={{ ...theme, height, bgColor: getBackgroundColor() }}>
      <div style={{ textAlign: 'center' }}>
        <IconContainer className={getIcon()} />
        <div>{(data) ? `${data.workLoadPercent}%` : null}</div>
      </div>
    </ContentRow>
  );
}

WorkLoadField.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  height: PropTypes.number.isRequired,
};

WorkLoadField.defaultProps = {
  endDate: null,
};
