import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import { formatDateTime, getMonteureFromObject } from '../../../../Library/Functions';
import Panel from '../../../../Components/Panel';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';

/**
 * Overview()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function Information(props) {
  const {
    report, teams, object, order, client,
  } = props;

  const renderMonteurTeam = () => {
    if (teams && client && object && order
      && (order.internal || (object.montageDate && object.montageDate.clientId === client.clientId))
    ) {
      const monteurString = getMonteureFromObject(object, teams);
      return (
        <KeyValueEditRow title="Monteur" type="text" displayText={monteurString.join(', ')} />
      );
    }
    return null;
  };

  const renderDuration = () => {
    if (report.finished) {
      const start = moment(report.startDateTime);
      const end = moment(report.endDateTime);
      const duration = moment.duration(end.diff(start));
      const minutes = duration.asMinutes().toFixed(0);
      return (
        <KeyValueEditRow title="Montagedauer" type="text" displayText={`${minutes} Minuten`} />
      );
    }
    return (
      <KeyValueEditRow title="Montagedauer" type="text" displayText="Bericht in Bearbeitung..." />
    );
  };

  const renderMontageEnd = () => {
    if (report.finished) {
      return (
        <KeyValueEditRow title="Montageende" type="text" displayText={formatDateTime(report.endDateTime)} />
      );
    }
    return (
      <KeyValueEditRow title="Montageende" type="text" displayText="Bericht in Bearbeitung..." />
    );
  };

  function renderReclamationInformation() {
    if (report.reclamationCount && report.reclamationTime) {
      return (
        <p style={{ marginTop: 30 }}>
          <KeyValueEditRow
            title="Geschätzte Restarbeitszeit"
            type="text"
            displayText={`${report.reclamationTime} Minuten`}
          />
          <KeyValueEditRow
            title="Geschätzte Anzahl benötigter Monteure"
            type="text"
            displayText={`${report.reclamationCount} Monteure`}
          />
        </p>
      );
    }
    return null;
  }

  if (report) {
    return (
      <Panel marginBottom={25}>
        <h6>Montageinformationen</h6>
        <p>
          Hier sehen Sie wer die Montage durchgeführt hat und wie lange die Montage gedauert hat.
        </p>

        <KeyValueEditRow title="Montagebeginn" type="text" displayText={formatDateTime(report.startDateTime)} />
        {renderMontageEnd()}
        {renderDuration()}
        {renderMonteurTeam()}
        <KeyValueEditRow
          title="Weitere Informationen"
          type="text"
          displayText={report.additionalInfo}
          visible={(report.additionalInfo && report.additionalInfo.length > 0)}
        />
        {renderReclamationInformation()}
      </Panel>
    );
  }
  return null;
}

Information.propTypes = {
  report: PropTypes.instanceOf(Object),
  teams: PropTypes.instanceOf(Array),
  object: PropTypes.instanceOf(Object),
  order: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object),
};

Information.defaultProps = {
  report: null,
  teams: null,
  object: null,
  order: null,
  client: null,
};
