import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { clientInviteType, legalFormOptions } from '../../../../Library/Types';
import Panel from '../../../../Components/Panel';
import SelectPanel from '../../../../Components/SelectPanels';
import Input from '../../../../Components/Input';
import CustomReactSelect from '../../../../Components/CustomReactSelect';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CreateInvite(props) {
  const {
    dispatch, state, formError,
  } = props;

  return (
    <div>
      <Panel marginBottom={25}>
        <h4>
          Unternehmensdaten
        </h4>
        <p style={{ marginBottom: 40 }}>
          Bitte geben Sie hier alle Ihnen bekannten und notwendigen Daten zum Unternehmen ein, welches Sie zu
          hey.kitchen und einer Partnerschaft einladen möchten.
        </p>

        <div style={{ marginBottom: 20 }}>
          <SelectPanel
            onChange={(value) => dispatch({ type: 'type', payload: value })}
            data={clientInviteType}
            selected={[state.type]}
            hasError={!!(formError.type)}
          />
        </div>

        <div className="row">
          <Input
            onChange={(value) => dispatch({ type: 'name', payload: value })}
            value={state.name}
            label="Firmenname"
            placeholder="Firmenname"
            hasError={!!(formError.name)}
            errorMessage={state.errorName}
            required
            rowClass="col-lg-6"
          />
          <CustomReactSelect
            onChange={(value) => dispatch({ type: 'legalForm', payload: value })}
            value={state.legalForm}
            label="Rechtsform"
            options={legalFormOptions}
            rowClass="col-lg-6"
            hasError={!!(formError.legalForm)}
          />
        </div>

        <div className="row">
          <Input
            onChange={(value) => dispatch({ type: 'street', payload: value })}
            value={state.street}
            label="Straße"
            placeholder="Straße"
            rowClass="col-lg-9"
          />
          <Input
            onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
            value={state.streetNo}
            label="Hausnummer"
            placeholder="Hausnummer"
            rowClass="col-lg-3"
          />
        </div>
        <div className="row">
          <Input
            onChange={(value) => dispatch({ type: 'zip', payload: value })}
            value={state.zip}
            label="Postleitzahl"
            placeholder="Postleitzahl"
            rowClass="col-lg-3"
          />
          <Input
            onChange={(value) => dispatch({ type: 'city', payload: value })}
            value={state.city}
            label="Ort"
            placeholder="Ort"
            rowClass="col-lg-9"
          />
        </div>

        <div className="row">
          <Input
            onChange={(value) => dispatch({ type: 'phone', payload: value })}
            value={state.phone}
            label="Telefon"
            placeholder="Telefon"
            hasError={!!(formError.phone)}
            required
            rowClass="col-lg-6"
          />
          <Input
            onChange={(value) => dispatch({ type: 'fax', payload: value })}
            value={state.fax}
            label="Fax"
            placeholder="Fax"
            rowClass="col-lg-6"
          />
        </div>

        <div className="row">
          <Input
            onChange={(value) => dispatch({ type: 'web', payload: value })}
            value={state.web}
            label="Webseite"
            placeholder="Webseite"
            rowClass="col-lg-6"
          />
          <Input
            onChange={(value) => dispatch({ type: 'vat', payload: value })}
            value={state.vat}
            label="Umsatzsteuer-Identifikationsnummer"
            placeholder="Umsatzsteuer-Identifikationsnummer"
            rowClass="col-lg-6"
          />
        </div>
      </Panel>

      <Panel>
        <h4>
          Ansprechpartner
        </h4>
        <p style={{ marginBottom: 20 }}>
          Bitte geben Sie hier die Daten der Person ein, die die Information über Ihre Einladung im
          Unternehmen erhalten soll.
        </p>
        <div className="row">
          <Input
            onChange={(value) => dispatch({ type: 'firstname', payload: value })}
            value={state.firstname}
            label="Vorname"
            placeholder="Vorname des Ansprechpartners"
            autoFocus
            hasError={!!(formError.firstname)}
            required
            rowClass="col-lg-6"
          />
          <Input
            onChange={(value) => dispatch({ type: 'lastname', payload: value })}
            value={state.lastname}
            label="Nachname"
            placeholder="Nachname des Ansprechpartners"
            hasError={!!(formError.lastname)}
            required
            rowClass="col-lg-6"
          />
          <Input
            onChange={(value) => dispatch({ type: 'mail', payload: value })}
            value={state.mail}
            label="E-Mail Adresse"
            placeholder="E-Mail Adresse des Ansprechpartners oder Unternehmens"
            hasError={!!(formError.mail)}
            errorMessage={state.errorMail}
            required
            rowClass="col-lg-12"
          />
        </div>
      </Panel>
    </div>

  );
}

CreateInvite.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  formError: PropTypes.instanceOf(Object).isRequired,
};
