import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Panel from '../../Panel';
import { CLIENTTYPE_DEALER, CLIENTTYPE_MONTEUR } from '../../../Library/Types';
import MenuEntryWithPermission from '../MenuEntryWithPermission';
import {
  checkClientHasAllDocuments,
  checkClientHasAllInformation,
  checkClientHasLoadingAddress,
} from '../../../Redux/Action/ClientAction';

/**
 * ClientAdminRightNavigation()
 * @returns {*}
 * @constructor
 */
function ClientAdminRightNavigation(props) {
  const {
    client, dispatchClientHasAddress, dispatchClientHasAllInformation, dispatchClientHasAllDocuments, partners,
  } = props;
  const [hasLogo, setHasLogo] = React.useState(true);
  const [hasAddress, setHasAddress] = React.useState(true);
  const [hasInformation, setHasInformation] = React.useState(true);
  const { url } = useRouteMatch();

  React.useEffect(() => {
    dispatchClientHasAllInformation().then(() => setHasInformation(true)).catch(() => setHasInformation(false));
    dispatchClientHasAllDocuments().then(() => setHasLogo(true)).catch(() => setHasLogo(false));

    if (client.type === CLIENTTYPE_MONTEUR) {
      dispatchClientHasAddress().then(() => setHasAddress(true)).catch(() => setHasAddress(false));
    }
  }, [dispatchClientHasAllDocuments, dispatchClientHasAllInformation, dispatchClientHasAddress, client]);

  const renderVoucher = () => {
    if (client.type === CLIENTTYPE_DEALER) {
      return (
        <MenuEntryWithPermission
          title="Gutscheine"
          icon="fas fa-money-check-alt"
          to={`${url}/voucher`}
          allowedGroups={['ADM']}
        />
      );
    }
    return null;
  };

  const renderMandant = () => {
    if (client.canHaveMandant || (partners && partners.length > 0)) {
      return (
        <MenuEntryWithPermission
          title="Partnerschaften"
          icon="fas fa-users"
          to={`${url}/mandant`}
          allowedGroups={['ADM', 'VER']}
        />
      );
    }
    return null;
  };

  const renderEventApi = () => {
    if (client.settings.allowUseEvents) {
      return (
        <MenuEntryWithPermission
          title="Event API"
          icon="fas fa-bullhorn"
          to={`${url}/events`}
          allowedGroups={['ADM', 'VER']}
        />
      );
    }
    return null;
  };

  function renderSubcontractor() {
    if (client.settings.allowSubcontractor) {
      return (
        <MenuEntryWithPermission
          title="Subunternehmer"
          icon="fas fa-house-user"
          to={`${url}/subcontractor`}
          allowedGroups={['ADM']}
        />
      );
    }

    return null;
  }

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            Einstellungen
          </h6>
        </div>

        <hr />

        <ul>
          <MenuEntryWithPermission
            title="Firmendaten"
            icon="fas fa-building"
            to={`${url}/client`}
            allowedGroups={['ADM', 'REG']}
            rightIcon={(!hasInformation) ? 'fas fa-exclamation-circle' : null}
          />
          <MenuEntryWithPermission
            title="Einstellungen"
            icon="fas fa-cogs"
            to={`${url}/settings`}
            allowedGroups={['ADM']}
          />

          {renderVoucher()}

          <MenuEntryWithPermission
            title="Rechnungen"
            icon="fas fa-euro-sign"
            to={`${url}/invoices`}
            allowedGroups={['ADM', 'REG']}
          />
          <MenuEntryWithPermission
            title="Dokumente"
            icon="fas fa-folder"
            to={`${url}/documents`}
            allowedGroups={['ADM']}
            rightIcon={(!hasLogo) ? 'fas fa-exclamation-circle' : null}
          />
          <MenuEntryWithPermission
            title="Benutzerverwaltung"
            icon="fas fa-user-friends"
            to={`${url}/users`}
            allowedGroups={['ADM']}
          />
          {renderSubcontractor()}
          <MenuEntryWithPermission
            title="Montageteams"
            icon="fas fa-users"
            to={`${url}/teams`}
            allowedGroups={['ADM']}
          />
          <MenuEntryWithPermission
            title="Standorte / Lageradressen"
            icon="fas fa-address-card"
            to={`${url}/address`}
            allowedGroups={['ADM']}
            rightIcon={(!hasAddress) ? 'fas fa-exclamation-circle' : null}
          />
          <MenuEntryWithPermission
            title="Materialverwaltung"
            icon="fas fa-tools"
            to={`${url}/material`}
            allowedGroups={['ADM', 'VER']}
          />
          <MenuEntryWithPermission
            title="Urlaubskalender"
            icon="fas fa-calendar-alt"
            to={`${url}/holidays`}
            allowedGroups={['ADM', 'VER']}
          />
          {renderMandant()}
          <MenuEntryWithPermission
            title="Betriebsstätten"
            icon="fas fa-industry"
            to={`${url}/facility`}
            allowedGroups={['ADM']}
          />
          <MenuEntryWithPermission
            title="Auftragsimport"
            icon="fas fa-file-import"
            to={`${url}/import/csv`}
            allowedGroups={['ADM', 'VER']}
          />
          {renderEventApi()}
        </ul>
      </div>
    </Panel>
  );
}

ClientAdminRightNavigation.propTypes = {
  client: PropTypes.instanceOf(Object),
  dispatchClientHasAllInformation: PropTypes.func.isRequired,
  dispatchClientHasAllDocuments: PropTypes.func.isRequired,
  dispatchClientHasAddress: PropTypes.func.isRequired,
  partners: PropTypes.instanceOf(Array),
};

ClientAdminRightNavigation.defaultProps = {
  client: null,
  partners: null,
};

function mapStoreToProps(store) {
  return {
    partners: store.client.partners,
    client: store.client.client,
    documents: store.clientDocument.documents,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchClientHasAllInformation: () => dispatch(checkClientHasAllInformation()),
    dispatchClientHasAllDocuments: () => dispatch(checkClientHasAllDocuments()),
    dispatchClientHasAddress: () => dispatch(checkClientHasLoadingAddress()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(ClientAdminRightNavigation);
