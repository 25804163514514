/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import Panel from '../../../Components/Panel';
import { get } from '../../../Redux/Action/OrderAction';
import Button from '../../../Components/Button';

const calculatingImage = require('../../../Assets/Images/calculating.png');

/**
 * ApproveOrder()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function ProcessingOrder(props) {
  const {
    order, getOrder, match, history,
  } = props;
  const [object, setObject] = useState(null);

  useEffect(() => {
    getOrder(match.params.orderId).then((data) => {
      setObject(data.objects[0]);
    }).catch(() => {
      history.push('/dashboard');
    });
  }, [getOrder, history, match.params]);

  if (order && object) {
    if (!order.isProcessing) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="row">
        <div className="col-xl-3" />
        <div className="col-xl-6">
          <Panel>
            <div className="d-flex flex-column align-items-center">
              <h3>
                <i className="fas fa-user-check text-info" style={{ marginRight: '10px' }} />
                Ihr Angebot wird berechnet!
              </h3>
              <p>
                Vielen Dank für Ihre Anfrage. Aufgrund einiger Parameter müssen wir Ihre Anfrage manuell prüfen.
                Sobald Ihr Angebot berechnet wurde erhalten Sie eine Mitteilung und können das Angebot einsehen!
              </p>
            </div>

            <hr />

            <div className="d-flex flex-column align-items-center" style={{ margin: '40px 0' }}>
              <img src={calculatingImage} alt="Calculating" />
            </div>

            <hr />

            <div className="d-flex flex-column align-items-center">
              <Button onClick={() => props.history.push('/dashboard')}>
                Zur Startseite
              </Button>
            </div>

          </Panel>
        </div>
        <div className="col-xl-3" />
      </div>
    );
  }
  return null;
}

function mapStoreToProps(store) {
  return {
    isLoading: store.order.isLoading,
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrder: (orderId) => dispatch(get(orderId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ProcessingOrder));
