import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../../../../Components/Panel';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import { formatCurrency } from '../../../../Library/Functions';
import { PAYMENTTYPE_CASH } from '../../../../Library/Types';

/**
 * CustomerBalance()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function CustomerBalance(props) {
  const { report } = props;

  if (report.finished && report.customerPaidValue > 0) {
    return (
      <div className="col-lg-4 d-flex">
        <Panel marginBottom={25}>
          <h6>Restbetrag</h6>
          <p>
            Informationen zum Restbetrag der Küche
          </p>

          <KeyValueEditRow
            title="Zahlungsweise"
            type="text"
            displayText={(report.paymentType === PAYMENTTYPE_CASH) ? 'Bar' : 'Bankeinzug'}
          />
          <KeyValueEditRow
            title="Vom Kunden bezahlt"
            type="text"
            displayText={formatCurrency(report.customerPaidValue)}
          />
          <KeyValueEditRow
            title="Verbleibender Restbetrag"
            type="text"
            displayText={formatCurrency(report.customerRestValue)}
          />
          <KeyValueEditRow
            title="Kommentar"
            type="text"
            displayText={report.comment}
            visible={(report.comment && report.comment.length > 0)}
          />
          <KeyValueEditRow
            title="Kontaktperson der Freigabe"
            type="text"
            displayText={report.approvedBy}
            visible={(report.approvedBy && report.approvedBy.length > 0)}
          />
        </Panel>
      </div>
    );
  }
  return null;
}

CustomerBalance.propTypes = {
  report: PropTypes.instanceOf(Object).isRequired,
};

CustomerBalance.defaultProps = {

};
