import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import styled from 'styled-components';
import { allowedImageExtensions } from '../../../../Library/Types';
import Alert from '../../../../Components/Alert';
import { guid } from '../../../../Library/Functions';

const ManualContainer = styled.div`
  display: flex;
  flex-direction: row;

  >.Item {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 4px;
    margin-right: 15px;
    padding: 5px;
    ${(props) => props.theme.boxShadowDark}

    &:last-child {
      margin-right: 0;
    }

    >img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }

    &.Add {
      cursor: pointer;
      outline: 0;

      &:hover {
        background-color: ${(props) => props.theme.color.textGrayLight};
        color: #fff
      }
    }

    >i {
      font-size: 30px;
    }
  }`;

/**
 * AddDocument()
 * @param props
 * @returns {*}
 * @constructor
 */
function AddDocument(props) {
  const { onAdd } = props;
  const [showError, setShowError] = React.useState(false);
  const fileInputRef = useRef();

  const handleOnChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const extension = file.name.split('.')
        .pop()
        .toLowerCase();

      if (allowedImageExtensions.indexOf(extension) > -1) {
        onAdd(e.target.files[0]);
      } else {
        setTimeout(() => {
          setShowError(true);
        }, 300);
      }
    }
  };

  return (
    <>
      <div
        className="Item Add"
        onClick={() => fileInputRef.current.click()}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
      >
        <input
          type="file"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(e) => handleOnChange(e)}
        />

        <i className="fas fa-plus" />
      </div>

      <Alert onClose={() => setShowError(false)} visible={showError} type="danger">
        Der ausgewählte Dateityp kann nicht verwendet werden. Bitte verwenden Sie Bilder mit einem der
        unterstützten Dateitypen.
        <br />
        <br />
        Unterstützte Dateitypen:
        <span>
          {` ${allowedImageExtensions.join(', ')}`}
        </span>
      </Alert>
    </>
  );
}

AddDocument.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

/**
 * Document()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function Document(props) {
  const { image } = props;
  const [imageSrc, setImageSrc] = useState();

  useEffect(() => {
    if (image) {
      const fileData = image.file;
      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onloadend = () => {
        setImageSrc([reader.result]);
      };
    }
  }, [image]);

  if (imageSrc) {
    return (
      <div className="Item">
        <img src={imageSrc} alt="Gerätebild" />
      </div>
    );
  }
  return null;
}

Document.propTypes = {
  image: PropTypes.instanceOf(Object).isRequired,
};

/**
 * AddManualDeviceImages()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AddItemDocument(props) {
  const { onChange } = props;
  const [files, setFiles] = useState([]);

  const handleOnAdd = (file) => {
    const newFiles = [...files, { file, internalId: guid() }];
    setFiles(newFiles);
    onChange(newFiles);
  };

  return (
    <ManualContainer>
      {files.map((image) => <Document image={image} key={image.id} />)}
      <AddDocument onAdd={handleOnAdd} />
    </ManualContainer>
  );
}

AddItemDocument.propTypes = {
  onChange: PropTypes.func.isRequired,
};
