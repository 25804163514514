import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../Components/ModalDialog';
import DeviceList from '../../../Components/DeviceList';
import { guid, isObjectEmpty } from '../../../Library/Functions';
import {
  DEVICETYPE_DEVICE, DEVICETYPE_RECLAMATION, DEVICETYPEDEVICE_OTHERS,
} from '../../../Library/Types';
import Panel from '../../../Components/Panel';
import AddManualDeviceImages from './Component/AddDeviceImages';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';

export const YesNoOption = [
  { label: 'Ja', value: true },
  { label: 'Nein', value: false },
];

/**
 * AddDevice()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AddDevice(props) {
  const {
    visible, onClose, onSave, serviceReport,
  } = props;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState();
  const [devicesSelected, setDevicesSelected] = useState(false);
  const [workOptionWater, setWorkOptionWater] = useState(false);
  const [workOptionElectric, setWorkOptionElectric] = useState(false);
  const [assemblyTime, setAssemblyTime] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (visible) {
      setTitle('');
      setDescription('');
      setAssemblyTime(0);
      setImages(null);
    }
  }, [visible]);

  const getErrors = React.useCallback(() => {
    setErrors({});
    let errorData = {};
    const assemblyTimeMatch = assemblyTime.toString().match(/^[0-9]*$/gm);
    if (!assemblyTimeMatch) {
      errorData = { ...errorData, assemblyTime: true };
    }
    if (title.length === 0) {
      errorData = { ...errorData, title: true };
    }
    if (!description || description.length === 0) {
      errorData = { ...errorData, description: true };
    }
    setErrors(errorData);
    return errorData;
  }, [title, description, assemblyTime]);

  const onHandleManualSave = () => {
    const errorsData = getErrors();

    if (isObjectEmpty(errorsData)) {
      onSave({
        deviceDescription: title,
        comment: description,
        type: DEVICETYPE_DEVICE,
        device: DEVICETYPEDEVICE_OTHERS,
        assemblyTime,
        uploadId: guid(),
        images,
        workOptionWater,
        workOptionElectric,
      });
    }
  };

  const onHandleDeviceSave = (data) => {
    onSave({
      ...data,
      type: DEVICETYPE_DEVICE,
      serviceReportDeviceId: data.deviceId,
      assemblyTime: serviceReport.reclamationTime,
    });
  };

  const renderDevices = () => {
    if (devicesSelected) {
      const devicesData = serviceReport.devices.filter((item) => item.type === DEVICETYPE_RECLAMATION);

      return (
        <div className="row">
          <DeviceList devices={devicesData} onPress={onHandleDeviceSave} showImageOpenHint={false} />
        </div>
      );
    }
    return null;
  };

  const renderManual = () => {
    if (!devicesSelected) {
      return (
        <Panel boxPadding={10}>
          <KeyValueEditRow
            rowClass="col-lg-12"
            title="Was wird reklamiert?"
            displayText={title}
            onChange={setTitle}
            hasError={!!errors.title}
            required
          />
          <KeyValueEditRow
            rowClass="col-lg-12"
            title="Beschreibung"
            type="textarea"
            displayText={description}
            onChange={setDescription}
            hasError={!!errors.description}
            required
          />
          <KeyValueEditRow
            rowClass="col-lg-12"
            title="Geschätzte Arbeitsdauer der Reklamation in Minuten"
            isNumeric
            displayText={`${assemblyTime} Minuten`}
            onChange={(data) => ((data === '') ? setAssemblyTime(0) : setAssemblyTime(data))}
          />

          <div className="row">
            <div className="col-lg-6">
              <KeyValueEditRow
                type="select"
                selectData={YesNoOption}
                rowClass="col-lg-6"
                title="Benötigt Prüfung vom Wasseranschluss"
                onChange={setWorkOptionWater}
                displayText={(workOptionWater) ? 'Ja' : 'Nein'}
                initialValue={workOptionWater}
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                type="select"
                selectData={YesNoOption}
                rowClass="col-lg-6"
                title="Benötigt Prüfung vom Elektroanschluss"
                onChange={setWorkOptionElectric}
                displayText={(workOptionElectric) ? 'Ja' : 'Nein'}
                initialValue={workOptionElectric}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-6">
              <label style={{ marginBottom: 15 }}>
                Bilder hinzufügen
              </label>
              <AddManualDeviceImages onChange={(files) => setImages(files)} />
            </div>
          </div>
        </Panel>
      );
    }
    return null;
  };

  return (
    <ModalDialog visible={visible} onClose={onClose} onConfirm={onHandleManualSave} width={800}>
      <h5>Reklamation hinzufügen</h5>
      <p>
        Wählen Sie eine offene Reklamation aus dem Monatgebericht oder erstellen Sie eine manuell Reklamation!
      </p>
      <br />

      <div className="btn-group btn-block">
        <button
          type="button"
          className={`btn ${(!devicesSelected) ? 'btn-hk-primary' : 'btn-light'}`}
          onClick={() => setDevicesSelected(false)}
        >
          Manuelle Reklamation
        </button>
        <button
          type="button"
          className={`btn ${(devicesSelected) ? 'btn-hk-primary' : 'btn-light'}`}
          onClick={() => setDevicesSelected(true)}
        >
          Reklamationen aus Montagebericht
        </button>
      </div>

      <br />
      <br />

      {renderManual()}
      {renderDevices()}

    </ModalDialog>
  );
}

AddDevice.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  serviceReport: PropTypes.instanceOf(Object),
};

AddDevice.defaultProps = {
  visible: false,
  serviceReport: null,
};
