import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import moment from 'moment';
import DetailsMapView from '../../../Components/OrderComponents/DetailsMapView';
import ObjectInformation from '../../../Components/OrderComponents/ObjectInformation';
import ObjectInformationPanel from '../../../Components/ObjectComponents/ObjectInformationPanel';
import PickOrder from './Components/PickOrder';
import ObjectHeadline from './Components/ObjectHeadline';
import LoadingAddress from '../../../Components/OrderComponents/LoadingAddress';
import CustomerAddress from '../../../Components/OrderComponents/CustomerAddress';
import { useDispatchHolidayGetList } from '../../../Redux/Action/HolidayAction';
import ShowInternalNextMontageDates from '../../../Components/OrderComponents/ShowInternalNextMontageDates';
import MakeOfferHandler from './Components/MakeOfferHandler';
import AssignMontageContainer from '../../../Components/ObjectComponents/AssignContainers/AssignMontageContainer';
import AssignDeliveryContainer from '../../../Components/ObjectComponents/AssignContainers/AssignDeliveryContainer';
import AssignRemovalContainer from '../../../Components/ObjectComponents/AssignContainers/AssignRemovalContainer';
import AssignStorageContainer from '../../../Components/ObjectComponents/AssignContainers/AssignStorageContainer';
import AssignContainer from '../../../Components/ObjectComponents/AssignContainers/AssignContainer';
import MonteurSplittedInfo from './Components/MonteurSplittedInfo';
import {
  ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE,
  ORDERTYPE_SINGLE,
} from '../../../Library/Types';
import ApproveMandant from './Components/ApproveMandant';
import PartnerHint from './Components/PartnerHint';
import CustomerInvoiceAddress from '../../../Components/ObjectComponents/CustomerInvoiceAddress';
import DocumentListPanel from '../../../Components/Documents/DocumentListPanel';
import LoadingModal from '../../../Components/LoadingModal';
import {
  addDocument,
  deleteDocument,
  useDispatchDateAddUser,
  useDispatchToggleAttachToMail,
  useDispatchUpdateDate,
} from '../../../Redux/Action/ObjectAction';
import ObjectDocumentsFinishedButton from '../../../Components/Documents/ObjectDocumentsFinishedButton';
import { notifyPartnerOrderDateChanged } from '../../../Redux/Action/NotificationAction';
import AskSendDateChangedNotificationToPartner from './Components/AskSendDateChangedNotificationToPartner';
import {
  ORDERSTATE_INCOMPLETE,
  ORDERSTATE_PARTNER_CREATED,
  ORDERSTATE_WAITING_APPROVEMENT,
} from '../../../Library/StateTypes';
import DispositionMonteurError from '../../../Components/Dialogs/DispositionMonteurError';
import { useAccessIsObjectEditable } from '../../../Library/AccessChecks';

const LAST_ACTION_TYPE_OBJECT_USER_ADD = 'OBJECT_USER_ADD';
const LAST_ACTION_TYPE_OBJECT_TEAM_ADD = 'OBJECT_TEAM_ADD';
const LAST_ACTION_TYPE_OBJECT_DATE_UPDATE = 'OBJECT_DATE_UPDATE';

/**
 * ObjectDetails()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function ObjectDetails(props) {
  const {
    client, storeTeams, storeUsers, history, order, dispatchAddDocument, dispatchDeleteDocument,
    dispatchSendDateChanged,
  } = props;
  const dispatchGetHoliday = useDispatchHolidayGetList();
  const dispatchUpdateDate = useDispatchUpdateDate();
  const dispatchDateAddUser = useDispatchDateAddUser();

  const [loadingDocument, setLoadingDocument] = React.useState(false);
  const [object, setObject] = React.useState(null);
  const [customer, setCustomer] = React.useState(null);
  const [showDispoError, setShowDispoError] = React.useState(false);
  const [askPartnerNotify, setAskPartnerNotify] = React.useState(false);
  const [changedDateType, setChangedDateType] = React.useState(null);
  const [lastActionType, setLastActionType] = React.useState(null);
  const [lastActionParam, setLastActionParam] = React.useState(null);

  const { objectId } = useParams();
  const editable = useAccessIsObjectEditable(object);
  const dispatchToggleAttachToMail = useDispatchToggleAttachToMail();

  React.useEffect(() => {
    if (order) {
      if (client.clientId === order.clientId) {
        if (order.state === ORDERSTATE_WAITING_APPROVEMENT) {
          history.push(`/create/${order.orderId}/approve`);
          return;
        }
        if (order.state === ORDERSTATE_INCOMPLETE) {
          history.push(`/create/${order.orderId}/edit`);
          return;
        }
      }

      const startDate = moment(order.startDate).startOf('month').format('YYYY-MM-DD');
      const endDate = moment(order.startDate).endOf('year').format('YYYY-MM-DD');
      dispatchGetHoliday(startDate, endDate).then(() => {});
      setObject(order.objects.filter((item) => item.objectId === parseInt(objectId, 0))[0]);
    }
  }, [order, client, dispatchGetHoliday, objectId, object, history]);

  React.useEffect(() => {
    if (object) {
      setCustomer(object.customer);
    }
  }, [object]);

  const handleShowAskNotifyPartner = (dateType) => {
    if (order.clientId === client.clientId && order.mandantId) {
      setChangedDateType(dateType);
      setAskPartnerNotify(true);
    }
  };

  const handleAddTeam = (date, disableDispositionChecks = false) => {
    dispatchUpdateDate(object, date, disableDispositionChecks).then(() => {}).catch((error) => {
      if (error.data.errorCode === ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE) {
        setLastActionType(LAST_ACTION_TYPE_OBJECT_TEAM_ADD);
        setLastActionParam(date);
        setTimeout(() => setShowDispoError(true), 300);
      }
    });
  };

  const handleAddPartner = (date) => {
    dispatchUpdateDate(object, date).then(() => {});
  };

  const handleAddUser = (date, user, disableDispositionChecks = false) => {
    dispatchDateAddUser(object, date, user, disableDispositionChecks).then(() => {}).catch((error) => {
      if (error.data.errorCode === ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE) {
        setLastActionType(LAST_ACTION_TYPE_OBJECT_USER_ADD);
        setLastActionParam({ date, user });
        setTimeout(() => setShowDispoError(true), 300);
      }
    });
  };

  const handleUpdateDate = (date, changedKey, dateType, disableDispositionChecks = false) => {
    dispatchUpdateDate(object, date, disableDispositionChecks).then(() => {
      if (changedKey === 'date') {
        handleShowAskNotifyPartner(dateType);
      }
    }).catch((error) => {
      if (error.data.errorCode === ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE) {
        setLastActionType(LAST_ACTION_TYPE_OBJECT_DATE_UPDATE);
        setLastActionParam({ date, changedKey, dateType });
        setTimeout(() => setShowDispoError(true), 300);
      }
    });
  };

  const handleDisableDispositionChecks = () => {
    switch (lastActionType) {
      case LAST_ACTION_TYPE_OBJECT_DATE_UPDATE: {
        const { date, changedKey, dateType } = lastActionParam;
        handleUpdateDate(date, changedKey, dateType, true);
        break;
      }
      case LAST_ACTION_TYPE_OBJECT_USER_ADD: {
        const { date, user } = lastActionParam;
        handleAddUser(date, user, true);
        break;
      }
      case LAST_ACTION_TYPE_OBJECT_TEAM_ADD: {
        handleAddTeam(lastActionParam, true);
        break;
      }
      default:
        setShowDispoError(false);
    }

    setShowDispoError(false);
  };

  const handleAddDocument = (typeParam, doc) => {
    setLoadingDocument(true);
    dispatchAddDocument(object, doc, typeParam).then(() => setLoadingDocument(false));
  };

  const handleSendNotificationForPartner = () => {
    if (order.clientId === client.clientId && order.mandantId) {
      setAskPartnerNotify(false);
      dispatchSendDateChanged(objectId, changedDateType).then(() => {}).catch(() => {});
    }
  };

  const renderAddressAndDocuments = () => {
    if (customer.hasInvoiceAddress && (order.clientId === client.clientId || order.mandantId === client.clientId)) {
      return (
        <>
          <div className="row">
            <div className="col-xl-4 col-lg-12 d-flex">
              <LoadingAddress order={order} client={client} object={object} editable={editable} />
            </div>
            <div className="col-xl-4 col-lg-12 d-flex">
              <CustomerAddress customer={customer} client={client} order={order} object={object} editable={editable} />
            </div>
            <div className="col-xl-4 col-lg-12 d-flex">
              <CustomerInvoiceAddress
                customer={customer}
                client={client}
                order={order}
                object={object}
              />
            </div>
            <div className="col-xl-12 col-lg-12 d-flex">
              <DocumentListPanel
                documents={object.documents}
                order={order}
                onAdd={handleAddDocument}
                onDelete={(doc) => dispatchDeleteDocument(object, doc)}
                onAttachToMail={(doc) => dispatchToggleAttachToMail(object, doc)}
                additionalButton={(
                  <ObjectDocumentsFinishedButton onPress={() => {}} client={client} order={order} object={object} />
                )}
                type="object"
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="row">
          <div className="col-xl-6 col-lg-12 d-flex">
            <div className="w-50 d-flex" style={{ marginRight: 15 }}>
              <LoadingAddress order={order} client={client} object={object} editable={editable} />
            </div>
            <div className="w-50 d-flex" style={{ marginLeft: 15 }}>
              <CustomerAddress customer={customer} client={client} order={order} object={object} editable={editable} />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 d-flex">
            <DocumentListPanel
              documents={object.documents}
              order={order}
              onAdd={handleAddDocument}
              onDelete={(doc) => dispatchDeleteDocument(object, doc)}
              onAttachToMail={(doc) => dispatchToggleAttachToMail(object, doc)}
              additionalButton={(
                <ObjectDocumentsFinishedButton onPress={() => {}} client={client} order={order} object={object} />
              )}
              type="object"
            />
          </div>
        </div>
      </>
    );
  };

  if (order && object && customer) {
    return (
      <>
        <ObjectHeadline order={order} object={object} />

        <MonteurSplittedInfo object={object} client={client} order={order} />
        <PartnerHint order={order} client={client} />
        <ApproveMandant />
        <MakeOfferHandler order={order} visible={(order.type === ORDERTYPE_SINGLE)} />
        <PickOrder order={order} client={client} object={object} visible={(order.type === ORDERTYPE_SINGLE)} />

        <div className="row">
          <div className="col-lg-12">
            <ObjectInformationPanel
              order={order}
              object={object}
              storeUsers={storeUsers}
              storeTeams={storeTeams}
              showEstimation
            />
          </div>
        </div>

        {renderAddressAndDocuments()}

        <div className="row">
          <div className="col-md-6 d-flex">
            <ObjectInformation
              object={object}
              order={order}
              editable={editable}
              onUpdateDate={handleUpdateDate}
            />
          </div>

          <div className="col-md-6 d-flex">
            <DetailsMapView object={object} client={client} order={order} />
          </div>
        </div>

        <ShowInternalNextMontageDates
          order={order}
          object={object}
          visible={
            (client.clientId === order.clientId && !object.splitted && order.state !== ORDERSTATE_PARTNER_CREATED)
          }
        />

        <AssignStorageContainer object={object} />
        <AssignRemovalContainer
          object={object}
          onTeamAdd={handleAddTeam}
          onUserAdd={handleAddUser}
          onPartnerAdd={handleAddPartner}
          onUpdate={handleUpdateDate}
        />
        <AssignDeliveryContainer
          object={object}
          onTeamAdd={handleAddTeam}
          onUserAdd={handleAddUser}
          onPartnerAdd={handleAddPartner}
          onUpdate={handleUpdateDate}
        />
        <AssignMontageContainer
          object={object}
          onTeamAdd={handleAddTeam}
          onUserAdd={handleAddUser}
          onPartnerAdd={handleAddPartner}
          onUpdate={handleUpdateDate}
        />
        <AssignContainer
          object={object}
          onTeamAdd={handleAddTeam}
          onUserAdd={handleAddUser}
          onPartnerAdd={handleAddPartner}
          onUpdate={handleUpdateDate}
        />

        <AskSendDateChangedNotificationToPartner
          object={object}
          dateType={changedDateType}
          visible={askPartnerNotify}
          onClose={() => setAskPartnerNotify(false)}
          onSend={handleSendNotificationForPartner}
        />

        <DispositionMonteurError
          onClose={() => setShowDispoError(false)}
          visible={showDispoError}
          onDisableDispoChecks={handleDisableDispositionChecks}
        />
        <LoadingModal visible={loadingDocument} text="Dokument wird hochgeladen..." />
      </>
    );
  }
  return null;
}

ObjectDetails.propTypes = {
  dispatchAddDocument: PropTypes.func.isRequired,
  dispatchDeleteDocument: PropTypes.func.isRequired,
  dispatchSendDateChanged: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  storeTeams: PropTypes.instanceOf(Array),
  storeUsers: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object),
};

ObjectDetails.defaultProps = {
  storeTeams: null,
  storeUsers: null,
  order: null,
};

function mapStoreToProps(store) {
  return {
    storeTeams: store.teams.teams,
    storeUsers: store.user.users,
    client: store.client.client,
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchAddDocument: (object, file, typeParam) => dispatch(addDocument(object, file, typeParam)),
    dispatchDeleteDocument: (object, document) => dispatch(deleteDocument(object, document)),
    dispatchSendDateChanged: (objectId, dateType) => dispatch(notifyPartnerOrderDateChanged(objectId, dateType)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ObjectDetails));
