import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { allowedImageExtensions } from '../../../../Library/Types';

import './style.css';

/**
 * AddImage()
 * @param props
 * @returns {*}
 * @constructor
 */
function AddImage(props) {
  const { onAdd } = props;
  const fileInputRef = useRef();

  const handleOnChange = (e) => {
    const file = e.target.files[0];
    const extension = file.name.split('.').pop().toLowerCase();

    if (allowedImageExtensions.indexOf(extension) > -1) {
      onAdd(e.target.files[0]);
    }
  };

  return (
    <div
      className="Item Add"
      onClick={() => fileInputRef.current.click()}
      tabIndex={-1}
      role="button"
      onKeyPress={() => {}}
    >
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(e) => handleOnChange(e)}
      />

      <i className="fas fa-plus" />
    </div>
  );
}

AddImage.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

/**
 * Image()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function Image(props) {
  const { image } = props;
  const [imageSrc, setImageSrc] = useState();

  useEffect(() => {
    if (image) {
      const fileData = image.file;
      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onloadend = () => {
        setImageSrc([reader.result]);
      };
    }
  }, [image]);

  if (imageSrc) {
    return (
      <div className="Item">
        <img src={imageSrc} alt="Gerätebild" />
      </div>
    );
  }
  return null;
}

Image.propTypes = {
  image: PropTypes.instanceOf(Object).isRequired,
};

/**
 * AddManualDeviceImages()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AddManualDeviceImages(props) {
  const { onChange } = props;
  const [files, setFiles] = useState([]);

  const handleOnAdd = (file) => {
    const newFiles = [...files, { file, id: Math.random() }];
    setFiles(newFiles);
    onChange(newFiles);
  };

  return (
    <div className="AddManualDeviceImages">
      {files.map((image) => <Image image={image} key={image.id} />)}
      <AddImage onAdd={handleOnAdd} />
    </div>
  );
}

AddManualDeviceImages.propTypes = {
  onChange: PropTypes.func.isRequired,
};
