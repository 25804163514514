import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalDialog from '../../../../Components/ModalDialog';
import SelectBox from '../../../../Components/SelectBox';

/**
 * PickOrderApproveDialog()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function PickOrderApproveDialog(props) {
  const {
    visible, onSave, onCancel, addresses, order,
  } = props;
  const [addressId, setAddressId] = useState(0);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (order && order.optionStorage && addresses && addresses.length === 1) {
      setAddressId(addresses[0].addressId);
    }
  }, [addresses, order]);

  const handleOnClose = () => {
    setHasError(false);
    onCancel();
  };

  const handleSave = () => {
    setHasError(false);
    if (order.optionStorage && addressId === 0) {
      setHasError(true);
    } else {
      onSave(addressId);
    }
  };

  const renderAddressInfo = () => {
    if (order && order.optionStorage && addresses && addresses.length === 1) {
      const address = addresses[0];

      return (
        <>
          <p>
            Der Auftrag erfordert eine Lagerung der Lieferung. Dem Auftraggeber wird die unten angezeigte Lageradresse
            unverzüglich mitgeteilt.
          </p>
          <address style={{ marginLeft: 40, marginTop: 20 }}>
            <strong>Lageradresse:</strong>
            <br />
            {`${address.street} ${address.streetNo}`}
            <br />
            {`${address.zip} ${address.city}`}
            <br />
            {`Kontaktperson: ${address.contactName}`}
            <br />
            {`Telefonnummer: ${address.contactPhone}`}
          </address>
        </>
      );
    }
    return null;
  };

  const renderAddressSelect = () => {
    if (order && order.optionStorage && addresses && addresses.length > 1) {
      const options = addresses.map((item) => ({ key: item.addressId, caption: item.name }));
      options.unshift({ key: 0, caption: 'Bitte Lagerort wählen...' });

      return (
        <>
          <p>
            Der Auftrag erfordert eine Lagerung der Lieferung. Bitte wählen Sie einen passenden Lagerorte damit der
            Auftraggeber die Küche dort hinliefern kann. Dem Auftraggeber wird der Lagerort unverzüglich mitgeteilt.
          </p>
          <div className="row">
            <SelectBox
              onChange={(data) => setAddressId(data)}
              data={options}
              label="Lageradresse wählen"
              hasError={hasError}
              rowClass="col-lg-12"
            />
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <ModalDialog
      onClose={handleOnClose}
      onConfirm={handleSave}
      visible={visible}
      confirmCaption="Auftrag jetzt verbindlich annehmen!"
      confirmClass="btn-success"
    >
      <h4 className="hk-text-primary">
        AUFTRAGSANNAHME
      </h4>
      <p>
        Mit Betätigung der Schaltfläche erklären Sie die Annahme des gewählten Auftrags zum angezeigten Preis und
        bestätigen die termingerechte Ausführung. Im Falle einer Stornierung des Auftrags fallen Gebühren in Höhe
        von 10% des Auftragspreises an. Bei verspäteter Ausführung des Auftrags wird der vollständige Auftragspreis
        berechnet.
        <br />
        <br />
        Mit Auftragsübernahme bestätigen Sie, dass Sie die hier angegebenen Vertragsinhalte auf Ihre Richtigkeit und
        Vollständigkeit kontrolliert haben. Eine spätere Reklamation oder Erstattung ist ausgeschlossen.
      </p>
      {renderAddressSelect()}
      {renderAddressInfo()}
    </ModalDialog>
  );
}

PickOrderApproveDialog.propTypes = {
  visible: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  addresses: PropTypes.instanceOf(Array),
  order: PropTypes.instanceOf(Object),
};

PickOrderApproveDialog.defaultProps = {
  visible: false,
  addresses: null,
  order: null,
};

function mapStoreToProps(store) {
  return {
    addresses: store.clientAddress.addresses,
  };
}

export default connect(mapStoreToProps, () => ({}))(PickOrderApproveDialog);
