/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const HeadlineBox = styled.div`
  display: block;
  width: fit-content;
  background-color: #b0b0b0;
  color: #ffffff;
  padding: 6px 15px;
  border-radius: 6px;
  font-family: "DIN Next LT W01 Bold", sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 1.3px;
  margin-bottom: 10px;
  
  &.Red {
    font-size: 30px;
    background-color: ${(props) => props.theme.color.primaryRedColor};
    margin-bottom: 40px;
  }
  
  &.Smaller {
    font-size: 18px;
  }
`;
