import React, { useReducer } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import ModalDialog from '../../../Components/ModalDialog';
import Input from '../../../Components/Input';
import SelectBox from '../../../Components/SelectBox';
import Textarea from '../../../Components/Textarea';
import DatePickerInput from '../../../Components/DatePickerInput';
import {
  CLIENTTYPE_DEALER,
  clientTypeOptions, VOUCHERTYPE_RUNTIME,
  VOUCHERTYPE_SINGLE,
  voucherTypeOptions,
} from '../../../Library/Types';
import { create, getList } from '../Redux/Action/VoucherAction';

const DISCOUNTTYPE_PERCENT = 'percent';
const DISCOUNTTYPE_PRICE = 'price';

export const discountOptions = [
  { caption: 'Prozent (%)', key: DISCOUNTTYPE_PERCENT },
  { caption: 'Betrag (€)', key: DISCOUNTTYPE_PRICE },
];

const initialState = {
  type: VOUCHERTYPE_SINGLE,
  clientType: CLIENTTYPE_DEALER,
  discountType: DISCOUNTTYPE_PRICE,
  discountValue: 0,
  validFrom: moment().format('YYYY-MM-DD'),
  validUntil: null,
  amount: 1,
  comment: '',
  validDays: 30,
  errors: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'type':
      return { ...state, type: action.value };
    case 'clientType':
      return { ...state, clientType: action.value };
    case 'discountType':
      return { ...state, discountType: action.value };
    case 'discountValue':
      return { ...state, discountValue: action.value.replace(',', '.') };
    case 'validFrom':
      return { ...state, validFrom: action.value };
    case 'validUntil':
      return { ...state, validUntil: action.value };
    case 'amount':
      return { ...state, amount: action.value };
    case 'comment':
      return { ...state, comment: action.value };
    case 'validDays':
      return { ...state, validDays: action.value };
    case 'errors':
      return { ...state, errors: action.payload };
    case 'resetErrors':
      return { ...state, errors: {} };

    default:
      throw new Error();
  }
}

/**
 * CreateDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
function CreateDialog(props) {
  const {
    onClose, visible, onCreated, dispatchCreate, dispatchGetList,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const isValid = () => {
    let hasError = false;
    let errors = {};

    if (state.validUntil === null) {
      hasError = true;
      errors = { ...errors, validUntil: true };
    }
    if (!state.amount || state.amount <= 0) {
      hasError = true;
      errors = { ...errors, amount: true };
    }

    if (state.discountType === DISCOUNTTYPE_PERCENT) {
      if (!state.discountValue || state.discountValue <= 0 || state.discountValue > 100) {
        hasError = true;
        errors = { ...errors, discountValue: true };
      }
    } else if (!state.discountValue || state.discountValue <= 0) {
      hasError = true;
      errors = { ...errors, discountValue: true };
    }

    if (state.type === VOUCHERTYPE_RUNTIME) {
      if (!state.validDays || state.validDays <= 0) {
        hasError = true;
        errors = { ...errors, validDays: true };
      }
    }

    dispatch({ type: 'errors', payload: errors });
    return !hasError;
  };

  const onHandleSave = () => {
    if (isValid()) {
      const saveData = {
        clientType: state.clientType,
        type: state.type,
        discountPercent: (state.discountType === DISCOUNTTYPE_PERCENT) ? state.discountValue : null,
        discountPrice: (state.discountType === DISCOUNTTYPE_PRICE) ? state.discountValue : null,
        validDays: (state.type === VOUCHERTYPE_RUNTIME) ? state.validDays : null,
        validFrom: state.validFrom,
        validUntil: state.validUntil,
        comment: state.comment,
        amount: state.amount,
      };

      dispatchCreate(saveData).then((response) => {
        dispatchGetList();
        if (onCreated) {
          onCreated(response);
        }
      });
    }
  };

  const renderValidDays = () => {
    if (state.type === VOUCHERTYPE_RUNTIME) {
      return (
        <>
          <Input
            rowClass="col-lg-6"
            onChange={(value) => dispatch({ type: 'validDays', value })}
            value={state.validDays}
            type="number"
            label="Gültigkeitstage ab der Einlösung"
            hasError={!!(state.errors.validDays)}
          />
        </>
      );
    }
    return null;
  };

  const renderAmount = () => {
    if (state.type === VOUCHERTYPE_SINGLE) {
      return (
        <Input
          rowClass="col-lg-12"
          onChange={(value) => dispatch({ type: 'amount', value })}
          value={state.amount}
          type="number"
          label="Anzahl benötigter Gutscheine"
          hasError={!!(state.errors.amount)}
        />
      );
    }
    return (
      <>
        {renderValidDays()}
        <Input
          rowClass="col-lg-6"
          onChange={(value) => dispatch({ type: 'amount', value })}
          value={state.amount}
          type="number"
          label="Anzahl benötigter Gutscheine"
          hasError={!!(state.errors.amount)}
        />
      </>
    );
  };

  const renderComment = () => (
    <>
      <Textarea
        rowClass="col-lg-12"
        onChange={(value) => dispatch({ type: 'comment', value })}
        value={state.comment}
        label="Kommentar"
        rows={3}
      />
    </>
  );

  const renderValidDates = () => (
    <>
      <DatePickerInput
        onChange={(value) => dispatch({ type: 'validFrom', value })}
        rowClass="col-lg-6"
        initialValue={state.validFrom}
        label="Einlösbar ab"
      />
      <DatePickerInput
        onChange={(value) => dispatch({ type: 'validUntil', value })}
        rowClass="col-lg-6"
        minDate={moment(state.validFrom).toDate()}
        label="Einlösbar bis"
        hasError={!!(state.errors.validUntil)}
      />
    </>
  );

  const renderDiscount = () => (
    <>
      <SelectBox
        rowClass="col-lg-6"
        onChange={(value) => dispatch({ type: 'discountType', value })}
        selected={state.discountType}
        data={discountOptions}
        label="Rabattart"
      />
      <Input
        onChange={(value) => dispatch({ type: 'discountValue', value })}
        value={state.discountValue}
        label="Rabatt"
        type="number"
        rowClass="col-lg-6"
        rightIcon={(state.discountType === DISCOUNTTYPE_PRICE) ? 'fas fa-euro-sign' : 'fas fa-percentage'}
        hasError={!!(state.errors.discountValue)}
      />
    </>
  );

  return (
    <ModalDialog onClose={onClose} visible={visible} confirmCaption="Erstellen" onConfirm={onHandleSave}>
      <h5>Gutschein erstellen</h5>
      <p>
        Bitte füllen Sie alle benötigten Felder aus!
      </p>

      <div>
        <hr />
      </div>

      <div className="row">
        <SelectBox
          rowClass="col-lg-6"
          onChange={(value) => dispatch({ type: 'type', value })}
          data={voucherTypeOptions}
          label="Gutscheintyp"
          selected={state.type}
        />
        <SelectBox
          rowClass="col-lg-6"
          onChange={(value) => dispatch({ type: 'clientType', value })}
          data={clientTypeOptions}
          label="Für Kundentyp"
          selected={state.clientType}
        />

        {renderDiscount()}
        {renderValidDates()}
        {renderAmount()}
        {renderComment()}
      </div>
    </ModalDialog>
  );
}

CreateDialog.propTypes = {
  dispatchCreate: PropTypes.func.isRequired,
  dispatchGetList: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

CreateDialog.defaultProps = {
  visible: false,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreate: (data) => dispatch(create(data)),
    dispatchGetList: () => dispatch(getList()),
  };
}

export default connect(() => ({}), mapDispatchToProps)(CreateDialog);
