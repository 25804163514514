/** APPOINTMENT STATES */
import React from 'react';
import * as Redux from 'react-redux';
import { CLIENTTYPE_MONTEUR } from '../Types';

export const APPOINTMENT_CREATED = 401;
export const APPOINTMENT_WAITING_FOR_APPROVAL = 402;
export const APPOINTMENT_HAS_APPROVAL = 403;
export const APPOINTMENT_WAITING_FOR_PROPOSED_DATES = 404;
export const APPOINTMENT_HAS_PROPOSED_DATES = 405;
export const APPOINTMENT_WAITING_FOR_CONFIRMATION = 406;
export const APPOINTMENT_SCHEDULED = 407;

const appointmentStateCaption = {
  [APPOINTMENT_CREATED]: 'Erstellt', // shouldn't appear
  [APPOINTMENT_WAITING_FOR_APPROVAL]: 'Startdatum eintragen', // shouldn't appear
  [APPOINTMENT_HAS_APPROVAL]: 'Hat Startdatum', // shouldn't appear
  [APPOINTMENT_WAITING_FOR_PROPOSED_DATES]: 'Wartet auf Terminvorschläge vom Monteur',
  [APPOINTMENT_HAS_PROPOSED_DATES]: 'Hat genug TerminVorschläge', // shouldn't appear
  [APPOINTMENT_WAITING_FOR_CONFIRMATION]: 'Termin auswählen',
  [APPOINTMENT_SCHEDULED]: 'Abgeschlossen',
};

const appointmentStateCaptionMonteur = {
  ...appointmentStateCaption,
  [APPOINTMENT_WAITING_FOR_APPROVAL]: 'Wartet auf Termin von Verkäufer',
  [APPOINTMENT_WAITING_FOR_PROPOSED_DATES]: '2 Terminvorschläge hinterlegen',
  [APPOINTMENT_WAITING_FOR_CONFIRMATION]: 'Wartet auf Terminbestätigung vom Verkäufer',
};

const appointmentStateIcon = {
  [APPOINTMENT_CREATED]: 'fas fa-calendar', // shouldn't appear
  [APPOINTMENT_WAITING_FOR_APPROVAL]: 'fas fa-calendar-plus',
  [APPOINTMENT_HAS_APPROVAL]: 'fas fa-calendar', // shouldn't appear
  [APPOINTMENT_WAITING_FOR_PROPOSED_DATES]: 'fas fa-hourglass-half',
  [APPOINTMENT_HAS_PROPOSED_DATES]: 'fas fa-calendar', // shouldn't appear
  [APPOINTMENT_WAITING_FOR_CONFIRMATION]: 'fas fa-calendar-check',
  [APPOINTMENT_SCHEDULED]: 'fas fa-calendar-check',
};

const appointmentStateIconMonteur = {
  ...appointmentStateIcon,
  [APPOINTMENT_WAITING_FOR_APPROVAL]: 'fas fa-hourglass-half',
  [APPOINTMENT_WAITING_FOR_PROPOSED_DATES]: 'fas fa-calendar-plus',
  [APPOINTMENT_WAITING_FOR_CONFIRMATION]: 'fas fa-hourglass-half',
};

const appointmentStateType = {
  [APPOINTMENT_CREATED]: 'Danger', // shouldn't appear
  [APPOINTMENT_WAITING_FOR_APPROVAL]: 'Danger', // shouldn't appear
  [APPOINTMENT_HAS_APPROVAL]: 'Danger', // shouldn't appear
  [APPOINTMENT_WAITING_FOR_PROPOSED_DATES]: 'Primary',
  [APPOINTMENT_HAS_PROPOSED_DATES]: 'Danger', // shouldn't appear
  [APPOINTMENT_WAITING_FOR_CONFIRMATION]: 'Danger',
  [APPOINTMENT_SCHEDULED]: 'Success',
};

const appointmentStateTypeMonteur = {
  ...appointmentStateType,
  [APPOINTMENT_WAITING_FOR_APPROVAL]: 'Primary',
  [APPOINTMENT_WAITING_FOR_PROPOSED_DATES]: 'Danger',
  [APPOINTMENT_WAITING_FOR_CONFIRMATION]: 'Primary',
};

export function useGetAppointmentCaption() {
  const clientType = Redux.useSelector((state) => state.client.client.type);
  const isMonteur = (clientType === CLIENTTYPE_MONTEUR);

  return React.useCallback((state) => {
    const type = isMonteur ? appointmentStateCaptionMonteur[state] : appointmentStateCaption[state];
    return type || 'Unbekannt';
  }, [isMonteur]);
}

export function useGetAppointmentIcon() {
  const clientType = Redux.useSelector((state) => state.client.client.type);
  const isMonteur = (clientType === CLIENTTYPE_MONTEUR);

  return React.useCallback((state) => {
    const type = isMonteur ? appointmentStateIconMonteur[state] : appointmentStateIcon[state];
    return type || 'fas fa-times';
  }, [isMonteur]);
}

export function useGetAppointmentType() {
  const clientType = Redux.useSelector((state) => state.client.client.type);
  const isMonteur = (clientType === CLIENTTYPE_MONTEUR);

  return React.useCallback((state) => {
    const type = isMonteur ? appointmentStateTypeMonteur[state] : appointmentStateType[state];
    return type || 'Primary';
  }, [isMonteur]);
}
