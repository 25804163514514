import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import moment from 'moment';
import { get, singleUpdate as singleUpdateOrder } from '../../../../Redux/Action/OrderAction';
import CustomerAddress from '../../../../Components/SystemComponents/CustomerAddress';
import ObjectInformation from '../../../../Components/OrderComponents/ObjectInformation';
import LoadingAddress from '../../../../Components/SystemComponents/LoadingAddress';
import Panel from '../../../../Components/Panel';
import ClientInformation from '../../../../Components/SystemComponents/ClientInformation';
import PriceInformation from '../../../../Components/SystemComponents/PriceInformation';
import {
  NAVIGATION_SYSTEM_CLIENT_DETAILS,
  ORDERTYPE_SINGLE,
} from '../../../../Library/Types';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import {
  formatDate, formatDateTime, isOrderClosed,
} from '../../../../Library/Functions';
import { calculate } from '../../Redux/Action/OrderAction';
import OrderPriceChangeAlert from '../../../../Components/SystemComponents/OrderPriceChangeAlert';
import AssignedClientAddress from '../../../../Components/SystemComponents/AssignedClientAddress';
import OrderCalculationLog from '../../Component/OrderCalculationLog';
import DocumentListPanel from '../../../../Components/Documents/DocumentListPanel';
import {
  addDocument,
  deleteDocument,
  useDispatchToggleAttachToMail,
  useDispatchUpdateDate,
} from '../../../../Redux/Action/ObjectAction';
import LoadingModal from '../../../../Components/LoadingModal';
import {
  ORDERSTATE_OPEN,
  ORDERSTATE_WAITING_CALCULATION,
  ORDERSTATE_WAITING_APPROVEMENT,
} from '../../../../Library/StateTypes';

/**
 * Details()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function Details(props) {
  const {
    dispatchGetDetails, order, dispatchUpdateOrder, dispatchCalculate, history, dispatchAddDocument,
    dispatchDeleteDocument,
  } = props;
  const dispatchUpdateDate = useDispatchUpdateDate();

  const { orderId, objectId } = useParams();
  const [loadingDocument, setLoadingDocument] = React.useState(false);
  const [object, setObject] = React.useState(null);
  const [calculationResult, setCalculationResult] = React.useState(null);
  const dispatchToggleAttachToMail = useDispatchToggleAttachToMail();

  React.useEffect(() => {
    if (order) {
      const obj = order.objects.filter((item) => item.objectId === parseInt(objectId, 0))[0];
      setObject(obj);
    }
  }, [order, objectId]);

  const handleUpdatePrice = () => {
    if (calculationResult) {
      dispatchUpdateOrder(order.orderId, 'dealerPrice', calculationResult.dealerPrice).then(() => {
        dispatchUpdateOrder(order.orderId, 'mechanicPrice', calculationResult.monteurPrice).then(() => {
          setCalculationResult(null);
          dispatchGetDetails(orderId);
        });
      });
    }
  };

  const handleOnUpdate = () => {
    if (!order.internal) {
      dispatchCalculate(orderId).then((response) => {
        if (parseFloat(response.dealerPrice) !== parseFloat(order.dealerPrice)) {
          setCalculationResult(response);
        }
      });
    }
  };

  const navigateClient = (clientData) => {
    const uri = NAVIGATION_SYSTEM_CLIENT_DETAILS.replace('{clientId}', clientData.clientId);
    history.push(uri);
  };

  const handleAddDocument = (typeParam, doc) => {
    setLoadingDocument(true);
    dispatchAddDocument(object, doc, typeParam).then(() => setLoadingDocument(false));
  };

  const handleUpdateDate = (date) => {
    const disableDispositionChecks = true;
    dispatchUpdateDate(object, date, disableDispositionChecks).then(() => {});
  };

  const renderPriceInformationAndDocuments = () => {
    if (!order.internal && order.type === ORDERTYPE_SINGLE) {
      return (
        <div className="row">
          <div className="col-md-6 d-flex">
            <DocumentListPanel
              documents={object.documents}
              order={order}
              isSystemContext
              onAdd={handleAddDocument}
              onDelete={(doc) => dispatchDeleteDocument(object, doc)}
              onAttachToMail={(doc) => dispatchToggleAttachToMail(object, doc)}
              type="object"
            />
          </div>
          <div className="col-md-6 d-flex">
            <PriceInformation order={order} editable={!isOrderClosed(order)} />
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-md-12 d-flex">
          <DocumentListPanel
            documents={object.documents}
            order={order}
            isSystemContext
            onAdd={handleAddDocument}
            onDelete={(doc) => dispatchDeleteDocument(object, doc)}
            onAttachToMail={(doc) => dispatchToggleAttachToMail(object, doc)}
            type="object"
          />
        </div>
      </div>
    );
  };

  const renderExternalAdditionals = () => {
    if (order.internal) {
      return null;
    }

    const returnNodes = [];

    if (order.state === ORDERSTATE_WAITING_APPROVEMENT) {
      const expirationDate = (
        <KeyValueEditRow
          key="expirationDate"
          title="Angebot gültig bis"
          type="date"
          initialValue={order.expirationDate}
          displayText={(order.expirationDate) ? formatDateTime(order.expirationDate) : ''}
          onChange={
            (date) => dispatchUpdateOrder(
              orderId,
              'expirationDate',
              `${date} ${moment().format('HH:mm:ss')}`,
            )
          }
        />
      );
      returnNodes.push(expirationDate);
    }

    if (order.state === ORDERSTATE_OPEN
      || order.state === ORDERSTATE_WAITING_APPROVEMENT
      || order.state === ORDERSTATE_WAITING_CALCULATION
    ) {
      const montageDate = (
        <KeyValueEditRow
          key="deliveryDateStart"
          title="Ausführungsdatum / Montagedatum"
          type="date"
          initialValue={object.montageDate.date}
          displayText={(object.montageDate.date) ? formatDate(object.montageDate.date) : ''}
          onChange={(date) => dispatchUpdateDate(object, { ...object.montageDate, date })}
        />
      );
      returnNodes.push(montageDate);

      const assemblyTime = (
        <KeyValueEditRow
          key="assemblyTime"
          title="Berechnete Monatgedauer"
          type="text"
          isNumeric
          initialValue={order.assemblyTime}
          displayText={(order.assemblyTime) ? `${order.assemblyTime} Minuten` : 'Unbekannt'}
          onChange={(date) => dispatchUpdateOrder(orderId, 'assemblyTime', date)}
        />
      );
      returnNodes.push(assemblyTime);
    }

    if (returnNodes.length > 0) {
      return (
        <Panel marginBottom={25}>
          <h6>Zusätzliche Auftragsinformationen</h6>
          <p>
            Diese Informationen sind nur hier im Backend sichtbar und können bearbeitet werden!
          </p>
          {returnNodes}
        </Panel>
      );
    }
    return null;
  };

  const renderAddressRow = () => {
    if (order.internal) {
      return (
        <>
          <div className="col-xl-4 col-lg-12 d-flex">
            <ClientInformation order={order} onClientClick={navigateClient} />
          </div>
          <div className="col-xl-4 col-lg-12 d-flex">
            <LoadingAddress object={object} order={order} />
          </div>
          <div className="col-xl-4 col-lg-12 d-flex">
            <CustomerAddress
              customer={object.customer}
              order={order}
              object={object}
              editable={!isOrderClosed(order)}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="col-xl-3 col-lg-12 d-flex">
          <ClientInformation order={order} onClientClick={navigateClient} />
        </div>
        <div className="col-xl-3 col-lg-12 d-flex">
          <LoadingAddress object={object} order={order} />
        </div>
        <div className="col-xl-3 col-lg-12 d-flex">
          <CustomerAddress
            customer={object.customer}
            order={order}
            object={object}
            editable={!isOrderClosed(order)}
          />
        </div>
        <div className="col-xl-3 col-lg-12 d-flex">
          <AssignedClientAddress object={object} onClientClick={navigateClient} />
        </div>
      </>
    );
  };

  if (object && order) {
    return (
      <>
        <div className="row">
          <div className="col-md-12 d-flex">
            <Panel marginBottom={20}>
              <h4>{`Auftragsdetails (${object.externalId})`}</h4>
            </Panel>
          </div>

          {renderAddressRow()}
        </div>

        {renderPriceInformationAndDocuments()}

        <div className="row">
          <div className="col-md-12 d-flex">
            <ObjectInformation
              object={object}
              order={order}
              isSystemView
              onChanged={handleOnUpdate}
              onUpdateDate={handleUpdateDate}
            />
          </div>
        </div>

        {renderExternalAdditionals()}

        <OrderCalculationLog order={order} visible={(order.type === ORDERTYPE_SINGLE)} />

        <OrderPriceChangeAlert
          onClose={() => setCalculationResult(null)}
          onConfirm={handleUpdatePrice}
          visible={(calculationResult !== null)}
          calculation={calculationResult}
          order={order}
        />

        <LoadingModal visible={loadingDocument} text="Dokument wird hochgeladen..." />
      </>
    );
  }
  return null;
}

Details.propTypes = {
  dispatchGetDetails: PropTypes.func.isRequired,
  dispatchUpdateOrder: PropTypes.func.isRequired,
  dispatchCalculate: PropTypes.func.isRequired,
  dispatchAddDocument: PropTypes.func.isRequired,
  dispatchDeleteDocument: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
};

Details.defaultProps = {
  order: null,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetDetails: (orderId) => dispatch(get(orderId, true)),
    dispatchUpdateOrder: (orderId, field, value) => dispatch(singleUpdateOrder(orderId, field, value)),
    dispatchCalculate: (orderId) => dispatch(calculate(orderId)),
    dispatchAddDocument: (object, file, typeParam) => dispatch(addDocument(object, file, typeParam)),
    dispatchDeleteDocument: (object, document) => dispatch(deleteDocument(object, document)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Details));
