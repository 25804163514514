export const ORDER_INTERNAL = 'INTERNAL';
export const ORDER_EXTERNAL = 'EXTERNAL';

// ORDER TYPE OPTIONS
export const ORDER_TYPE_SINGLE = 'SINGLE';
export const ORDER_TYPE_OBJECT = 'OBJECT';

const orderTypeCaption = {
  [ORDER_TYPE_SINGLE]: 'Einzelauftrag',
  [ORDER_TYPE_OBJECT]: 'Objektauftrag',
};

const orderTypeIcon = {
  [ORDER_TYPE_SINGLE]: 'fas fa-truck-moving',
  [ORDER_TYPE_OBJECT]: 'fas fa-building',
};

export const getOrderTypeCaption = (type) => (orderTypeCaption[type] || 'Unbekannt');
export const getOrderTypeIcon = (type) => (orderTypeIcon[type] || 'fas fa-question');
export const getOrderTypeOption = (type) => ({
  key: type,
  caption: getOrderTypeCaption(type),
  icon: getOrderTypeIcon(type),
});
export const orderTypeOptions = [
  getOrderTypeOption(ORDER_TYPE_SINGLE),
  getOrderTypeOption(ORDER_TYPE_OBJECT),
];

// ORDER WORK OPTIONS
export const ORDER_OPTION_MONTAGE = 'MONTAGE';
export const ORDER_OPTION_DELIVERY = 'DELIVERY';
export const ORDER_OPTION_STORAGE = 'STORAGE';
export const ORDER_OPTION_REMOVAL = 'REMOVAL';

const orderOptionCaption = {
  [ORDER_OPTION_MONTAGE]: 'Montage',
  [ORDER_OPTION_DELIVERY]: 'Lieferung',
  [ORDER_OPTION_STORAGE]: 'Lagerung',
  [ORDER_OPTION_REMOVAL]: 'Demontage/Entsorgung',
};

const orderOptionIcon = {
  [ORDER_OPTION_MONTAGE]: 'fas fa-tools',
  [ORDER_OPTION_DELIVERY]: 'fas fa-truck-moving',
  [ORDER_OPTION_STORAGE]: 'fas fa-cubes',
  [ORDER_OPTION_REMOVAL]: 'fas fa-trash-alt',
};

export const getOrderOptionCaption = (option) => (orderOptionCaption[option] || 'Unbekannt');
export const getOrderOptionIcon = (option) => (orderOptionIcon[option] || 'fas fa-question');
export const getOrderOptionOption = (option) => ({
  key: option,
  caption: getOrderOptionCaption(option),
  icon: getOrderOptionIcon(option),
});
export const orderOptionOptions = [
  getOrderOptionOption(ORDER_OPTION_MONTAGE),
  getOrderOptionOption(ORDER_OPTION_DELIVERY),
  // getOrderOptionOption(ORDER_OPTION_STORAGE),
  getOrderOptionOption(ORDER_OPTION_REMOVAL),
];
