import React from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../Library/Axios';

const defaultUri = '/team';

export const TEAMS_GET_START = 'TEAMS_GET_START';
export const TEAMS_GET_SUCCESS = 'TEAMS_GET_SUCCESS';
export const TEAMS_GET_ERROR = 'TEAMS_GET_ERROR';

/**
 * get()
 * @param teamId
 * @returns {function(*): Promise<unknown>}
 */
export function get(teamId) {
  return (dispatch) => {
    dispatch({ type: TEAMS_GET_START });

    const uri = `${defaultUri}/${teamId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: TEAMS_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: TEAMS_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const TEAMS_GETLIST_START = 'TEAMS_GETLIST_START';
export const TEAMS_GETLIST_SUCCESS = 'TEAMS_GETLIST_SUCCESS';
export const TEAMS_GETLIST_ERROR = 'TEAMS_GETLIST_ERROR';

/**
 * getList()
 * @returns {function(*): Promise<unknown>}
 */
export function getList(forceReload = false) {
  return (dispatch, getState) => {
    const { teams } = getState().teams;
    if (!forceReload && teams && teams.length > 0) {
      return Promise.resolve(teams);
    }

    dispatch({ type: TEAMS_GETLIST_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: TEAMS_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: TEAMS_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

/**
 * useDispatchTeamsGetList()
 * @returns {function(): *}
 */
export function useDispatchTeamsGetList() {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch({ type: TEAMS_GETLIST_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: TEAMS_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: TEAMS_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const TEAMS_CREATE_START = 'TEAMS_CREATE_START';
export const TEAMS_CREATE_SUCCESS = 'TEAMS_CREATE_SUCCESS';
export const TEAMS_CREATE_ERROR = 'TEAMS_CREATE_ERROR';

/**
 * create()
 * @param teamData
 * @returns {function(*): Promise<unknown>}
 */
export function create(teamData) {
  return (dispatch) => {
    dispatch({ type: TEAMS_CREATE_START });

    return axios().post(defaultUri, teamData).then((response) => {
      const { data } = response.data;
      dispatch({ type: TEAMS_CREATE_SUCCESS, payload: data });
      dispatch(getList(true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: TEAMS_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const TEAMS_UPDATE_START = 'TEAMS_UPDATE_START';
export const TEAMS_UPDATE_SUCCESS = 'TEAMS_UPDATE_SUCCESS';
export const TEAMS_UPDATE_ERROR = 'TEAMS_UPDATE_ERROR';

/**
 * update()
 * @param teamId
 * @param name
 * @param users
 * @returns {function(*): Promise<unknown>}
 */
export function update(teamId, name, users) {
  return (dispatch) => {
    dispatch({ type: TEAMS_UPDATE_START });

    const uri = `${defaultUri}/${teamId}`;

    return axios().put(uri, { name, users }).then((response) => {
      const { data } = response.data;
      dispatch({ type: TEAMS_UPDATE_SUCCESS, payload: data });
      dispatch(getList(true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: TEAMS_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const TEAMS_DELETE_START = 'TEAMS_DELETE_START';
export const TEAMS_DELETE_SUCCESS = 'TEAMS_DELETE_SUCCESS';
export const TEAMS_DELETE_ERROR = 'TEAMS_DELETE_ERROR';

/**
 * remove()
 * @param teamId
 * @returns {function(*): Promise<unknown>}
 */
export function remove(teamId) {
  return (dispatch) => {
    dispatch({ type: TEAMS_DELETE_START });

    const uri = `${defaultUri}/${teamId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: TEAMS_DELETE_SUCCESS, payload: data });
      dispatch(getList(true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: TEAMS_DELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
