import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import { formatDateTime } from '../../Library/Functions';
import ModalDialog from '../ModalDialog';

/**
 * UserTable()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UserTable(props) {
  const {
    users, onDelete, onUpdate, showIds, hideActions,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [toDeleteItem, setToDeleteItem] = useState(null);

  const handleDelete = (value) => {
    setToDeleteItem(value);
    setShowModal(true);
  };

  const forwardOnDelete = (forward) => {
    onDelete(forward);
    setShowModal(false);
  };

  const renderId = () => {
    if (showIds) {
      return (
        <th>ID</th>
      );
    }
    return null;
  };

  return (
    <Panel marginBottom={25}>
      <table className="table table-hover linked table-options" style={{ marginTop: 0 }}>
        <thead>
          <tr>
            <th />
            {renderId()}
            <th>Name (Initialen)</th>
            <th>E-Mail / Login</th>
            <th>Gruppen</th>
            <th>Letzte Anmeldung</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {
            users.map(
              (item) => (
                <Row
                  key={item.userId}
                  item={item}
                  onDelete={(data) => handleDelete(data)}
                  onUpdate={onUpdate}
                  showId={showIds}
                  showDeleteIcon={hideActions}
                />
              ),
            )
          }
        </tbody>
      </table>

      <ModalDialog
        onClose={() => setShowModal(false)}
        onConfirm={() => forwardOnDelete(toDeleteItem)}
        visible={showModal}
        confirmCaption="Löschen"
        centered
      >
        <h5>Benutzer löschen?</h5>
        Bitte bestätigen Sie das die den ausgewählten Benutzer endgültig löschen möchten!
      </ModalDialog>
    </Panel>
  );
}

UserTable.propTypes = {
  users: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showIds: PropTypes.bool,
  hideActions: PropTypes.bool,
};

UserTable.defaultProps = {
  showIds: false,
  hideActions: true,
};

/**
 * Row()
 * @param props
 * @returns {*}
 * @constructor
 */
function Row(props) {
  const {
    item, onDelete, onUpdate, showId, showDeleteIcon,
  } = props;

  const renderLastLogin = () => {
    if (item.lastLoginDate) {
      return (
        <td>{`${formatDateTime(item.lastLoginDate, 'DD.MM.YYYY HH:mm')} Uhr`}</td>
      );
    }

    return <td />;
  };

  const renderIcon = () => {
    if (item.active) {
      return (
        <td width={20}><i className="fas fa-check-circle text-success" /></td>
      );
    }
    return (
      <td><i className="fas fa-times-circle text-danger" /></td>
    );
  };

  const handleOnDelete = (event) => {
    event.stopPropagation();
    onDelete(item);
  };

  const renderDeleteIcon = () => {
    if (showDeleteIcon) {
      return (
        <td className="options" width={80}>
          <i
            className="fas fa-pencil-alt primary"
            onClick={() => {}}
            tabIndex={-1}
            onKeyPress={() => {
            }}
            role="button"
          />
          <i
            className="fas fa-trash danger"
            onClick={handleOnDelete}
            tabIndex={-1}
            onKeyPress={() => {
            }}
            role="button"
          />
        </td>
      );
    }
    return <td />;
  };

  const renderGroups = () => {
    const groupInitials = item.groups.map((group) => group.groupInitials);

    return (
      <td>
        {groupInitials.join(', ')}
      </td>
    );
  };

  const renderId = () => {
    if (showId) {
      return <td>{item.userId}</td>;
    }
    return null;
  };

  const handleRowOnClick = (event, receivedItem) => {
    if (showDeleteIcon) {
      event.stopPropagation();
      onUpdate(receivedItem);
    }
    return null;
  };

  return (
    <tr onClick={(event) => handleRowOnClick(event, item)} tabIndex={-1}>
      {renderIcon()}
      {renderId()}
      <td>
        {(item.subcontractorId > 0 && showDeleteIcon)
          ? `${item.lastname}, ${item.firstname} (${item.initials}) *`
          : `${item.lastname}, ${item.firstname} (${item.initials})`}
      </td>
      <td>{item.mail}</td>
      {renderGroups()}
      {renderLastLogin()}
      {renderDeleteIcon()}
    </tr>
  );
}

Row.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showId: PropTypes.bool.isRequired,
  showDeleteIcon: PropTypes.bool.isRequired,
};
