import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import styled, { useTheme } from 'styled-components';
import { lighten } from 'polished';
import moment from 'moment';

const Container = styled.div`
  display: flex;
  color: #fff;
  min-width: ${(props) => props.theme.cellWidth}px;
  max-width: ${(props) => props.theme.cellWidth}px;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-grow: 1;
  height: ${(props) => props.theme.height}px;
  border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  ${(props) => ((!props.theme.isInWorkTime) ? props.theme.linearGradient.noWorkHour : '')};
  cursor: pointer;
  
  &:hover {
    background-color: ${() => lighten(0.45, 'gray')} !important;
  }
  
  &:last-child {
    border-right: none;
  }
`;

/**
 * UserTimeLineRow()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UserHourCell(props) {
  const {
    hour, cellHeight, onDrop, data, selectedDate, isInWorkTime, onClick,
  } = props;
  const theme = useTheme();

  const [collectedProps, drop] = useDrop(() => ({
    accept: ['rightBoxItem'],
    options: hour,
    drop: (item) => onDrop({
      droppedSource: item, droppedTarget: data, date: selectedDate, hour,
    }),
    collect: (monitor) => ({
      hover: monitor.isOver({ shallow: true }),
    }),
  }));

  return (
    <Container
      theme={{ ...theme, height: cellHeight, isInWorkTime }}
      ref={drop}
      key={hour}
      className="HourCell"
      style={{ backgroundColor: (collectedProps.hover) ? '#ededed' : '#fff' }}
      onClick={() => onClick(moment(hour, 'H').format('HH:00'))}
    />
  );
}

UserHourCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDrop: PropTypes.func,
  hour: PropTypes.number.isRequired,
  cellHeight: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  selectedDate: PropTypes.string.isRequired,
  isInWorkTime: PropTypes.bool,
};

UserHourCell.defaultProps = {
  onDrop: null,
  isInWorkTime: true,
};
