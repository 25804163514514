import React from 'react';
import PropTypes from 'prop-types';
import FilterCard from './FilterCard';

const defaultState = {
  selected: null,
  counts: null,
};

function initReducer(initSelected) {
  const selected = initSelected;
  return { ...defaultState, selected };
}

function reducer(state, action) {
  switch (action.type) {
    case 'update': {
      return { ...state, ...action.payload };
    }

    case 'switch': {
      const { selected } = state;
      selected[action.payload] = !selected[action.payload];
      return { ...state, selected };
    }

    default: return { ...state };
  }
}

/**
 * ObjectArrayFilter(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectArrayFilter(props) {
  const {
    objectsArray, getFilterParameter, getCaption,
    filter, onFilterChange,
  } = props;

  const [state, dispatch] = React.useReducer(reducer, {}, initReducer);
  const [prevSelected, setPrevSelected] = React.useState('');

  // count objects per filter option
  React.useEffect(() => {
    const counts = {};

    objectsArray.forEach((object) => {
      const key = getFilterParameter(object);
      if (counts[key]) {
        counts[key] += 1;
      } else {
        counts[key] = 1;
      }
    });

    dispatch({ type: 'update', payload: { counts } });
  }, [objectsArray, getFilterParameter]);

  React.useEffect(() => {
    const { selected, counts } = state;
    if (counts) {
      const values = Object.keys(counts).map((key) => selected[key]);
      const newSelected = {};
      if (values.every((e) => !e) || (values.every((e) => e) && values.length > 1)) {
        newSelected[filter] = false;
      } else {
        newSelected[filter] = { getFilterParameter, selected: state.selected };
      }
      const newSelectedString = JSON.stringify(newSelected);
      if (prevSelected !== newSelectedString) {
        onFilterChange(newSelected);
      }
      setPrevSelected(newSelectedString);
    }
  }, [state, prevSelected, getFilterParameter, filter, onFilterChange]);

  const renderFilter = () => {
    const { counts, selected } = state;

    if (counts && Object.keys(counts).length > 0) {
      return Object.keys(counts).map((option) => (
        <FilterCard
          key={option}
          caption={getCaption(option, counts[option])}
          selected={selected[option]}
          onClick={() => dispatch({ type: 'switch', payload: option })}
        />
      ));
    }
    return null;
  };

  return (
    <div>
      <div className="d-flex flex-wrap">
        {renderFilter()}
      </div>
    </div>
  );
}

ObjectArrayFilter.propTypes = {
  objectsArray: PropTypes.instanceOf(Array).isRequired,
  getFilterParameter: PropTypes.func.isRequired,
  getCaption: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

ObjectArrayFilter.defaultProps = {};
