import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import ModalDialog from '../ModalDialog';
import { getAll } from '../../Redux/Action/UserAction';
import { getList } from '../../Redux/Action/TeamsAction';
import { getList as getListPartners } from '../../Redux/Action/MandantAction';
import { CLIENTTYPE_MONTEUR } from '../../Library/Types';

const ScrollContainer = styled.div`
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

/**
 * SelectUserTeamPartner()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function SelectUserTeamPartner(props) {
  const {
    visible, users, onClose, onAddUser, alreadyAddedUsers, teams, onAddTeam, getAllUsers, getAllTeams,
    holidayData, executionDate, partners, showPartners, onAddPartner, dispatchGetPartners, client, showTeams,
  } = props;
  const [selected, setSelected] = useState('monteur');
  const [ignoredUserIds, setIgnoredUserIds] = useState([]);
  const [monteurPartners, setMonteurPartners] = useState(null);

  useEffect(() => {
    getAllTeams();
    getAllUsers();
  }, [getAllTeams, getAllUsers]);

  useEffect(() => {
    if (visible) {
      dispatchGetPartners(client);
    }
  }, [visible, client, dispatchGetPartners]);

  useEffect(() => {
    const filtered = partners.filter((item) => (
      item.partner.type === CLIENTTYPE_MONTEUR && item.active && item.approved
    )).map((item) => item.partner);
    if (filtered && filtered.length > 0) {
      setMonteurPartners(filtered);
    } else {
      setMonteurPartners(null);
    }
  }, [partners]);

  useEffect(() => {
    if (holidayData && executionDate) {
      if (holidayData[executionDate]) {
        const userIds = holidayData[executionDate].map((item) => item.userId);
        setIgnoredUserIds(userIds);
      } else {
        setIgnoredUserIds([]);
      }
    }
  }, [executionDate, holidayData]);

  const isMonteur = (user) => {
    const data = user.groups.filter((item) => item.groupInitials === 'MON');
    return (data.length > 0);
  };

  const renderUser = () => {
    if (selected === 'monteur') {
      const userIdArray = alreadyAddedUsers.map((user) => user.userId);
      const filteredUsers = users.filter((user) => (
        userIdArray.indexOf(user.userId) < 0) && (ignoredUserIds.indexOf(user.userId) < 0
      ));

      return filteredUsers.map((user) => {
        if (user.active && isMonteur(user)) {
          return (
            <tr key={Math.random()} onClick={() => onAddUser(user)}>
              <td style={{ width: '16px' }}><i className="fas fa-user" /></td>
              <td>{`${user.lastname}, ${user.firstname}`}</td>
            </tr>
          );
        }
        return null;
      });
    }
    return null;
  };

  const renderPartners = () => {
    if (selected === 'partner') {
      return monteurPartners.map((partner) => (
        <tr key={partner.clientId} onClick={() => onAddPartner(partner)}>
          <td style={{ width: '16px' }}><i className="fas fa-building" /></td>
          <td>{`${partner.name}`}</td>
        </tr>
      ));
    }
    return null;
  };

  const getTeamMembers = (team) => team.users.map((item) => (
    `${item.firstname} ${item.lastname}${item.isMaster ? '*' : ''}`
  )).join(', ');

  const renderTeams = () => {
    if (selected === 'team' && teams) {
      return (
        teams.map((team) => {
          const hasIgnoredUser = team.users.filter((item) => ignoredUserIds.indexOf(item.userId) > -1);

          if (hasIgnoredUser.length === 0) {
            return (
              <tr key={team.teamId} onClick={() => onAddTeam(team)}>
                <td style={{ width: '16px' }}><i className="fas fa-users" /></td>
                <td>
                  <div>{team.name}</div>
                  <small className="text-muted">{getTeamMembers(team)}</small>
                </td>
              </tr>
            );
          }
          return null;
        })
      );
    }
    return null;
  };

  const renderPartnerTab = () => {
    if (showPartners && monteurPartners && monteurPartners.length > 0 && onAddPartner) {
      return (
        <button
          type="button"
          className={`btn ${(selected === 'partner') ? 'btn-hk-primary' : 'btn-light'}`}
          onClick={() => setSelected('partner')}
        >
          Partner
        </button>
      );
    }
    return null;
  };

  const renderTeamsTab = () => {
    if (showTeams && teams && teams.length > 0) {
      return (
        <button
          type="button"
          className={`btn ${(selected === 'team') ? 'btn-hk-primary' : 'btn-light'}`}
          onClick={() => setSelected('team')}
        >
          Teams
        </button>
      );
    }
    return null;
  };

  if (users) {
    return (
      <ModalDialog onClose={() => onClose()} visible={visible} hideConfirmButton>
        <p>
          Wählen Sie einen verantwortlichen Monteur oder ein entsprechendes Montageteam für diesen Auftrag aus der
          Liste aus! Wenn Sie weitere Monteure benötigen, können Sie diese in der &nbsp;
          <a href="/admin/users">Benutzerverwaltung</a>
          &nbsp; hinzufügen.
        </p>
        <p>
          Hinweis: Es werden nur bereits erstellte Benutzer und Montageteams angezeigt, die an dem ausgewählten
          Termin verfügbar sind!
        </p>

        <div className="btn-group btn-block">
          <button
            type="button"
            className={`btn ${(selected === 'monteur') ? 'btn-hk-primary' : 'btn-light'}`}
            onClick={() => setSelected('monteur')}
          >
            Monteure
          </button>
          {renderTeamsTab()}
          {renderPartnerTab()}
        </div>

        <ScrollContainer>
          <table className="table table-borderless table-hover table-linked">
            <tbody>
              {renderUser()}
              {renderTeams()}
              {renderPartners()}
            </tbody>
          </table>
        </ScrollContainer>
      </ModalDialog>
    );
  }
  return null;
}

SelectUserTeamPartner.propTypes = {
  visible: PropTypes.bool.isRequired,
  users: PropTypes.instanceOf(Array),
  alreadyAddedUsers: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
  teams: PropTypes.instanceOf(Array),
  onAddTeam: PropTypes.func.isRequired,
  onAddPartner: PropTypes.func,
  getAllUsers: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  dispatchGetPartners: PropTypes.func.isRequired,
  holidayData: PropTypes.instanceOf(Object),
  executionDate: PropTypes.string,
  partners: PropTypes.instanceOf(Array),
  showPartners: PropTypes.bool,
  showTeams: PropTypes.bool,
  client: PropTypes.instanceOf(Object).isRequired,
};

SelectUserTeamPartner.defaultProps = {
  users: [],
  teams: [],
  onAddPartner: null,
  partners: null,
  holidayData: null,
  showPartners: false,
  showTeams: true,
  executionDate: null,
};

function mapStoreToProps(store) {
  return {
    users: store.user.users,
    teams: store.teams.teams,
    partners: store.client.partners,
    holidayData: store.holiday.tileData,
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllUsers: () => dispatch(getAll()),
    getAllTeams: () => dispatch(getList()),
    dispatchGetPartners: (client) => dispatch(getListPartners(client)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(SelectUserTeamPartner));
