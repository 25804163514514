import {
  ORDERMESSAGE_GETLIST_START, ORDERMESSAGE_GETLIST_SUCCESS, ORDERMESSAGE_GETLIST_ERROR,
} from '../Action/OrderMessagesAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  messages: null,
  lastOrderId: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /**
     * GETLIST
     */
    case ORDERMESSAGE_GETLIST_START: {
      const { payload } = action;
      if (payload !== state.lastOrderId) {
        return { ...state, isLoading: true };
      }
      return {
        ...state, isLoading: true, messages: null, lastOrderId: payload,
      };
    }
    case ORDERMESSAGE_GETLIST_SUCCESS: {
      return { ...state, messages: action.payload, isLoading: false };
    }
    case ORDERMESSAGE_GETLIST_ERROR: {
      return { ...state, isLoading: false, messages: null };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
