import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ObjectRow from './ObjectList/ObjectRow';

/**
 * ObjectList(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectList(props) {
  const {
    objects, variant, onClick,
  } = props;

  const renderHeader = () => {
    if (variant === 'internal') {
      return (
        <thead>
          <tr>
            <th>ObjectId</th>
            <th>Kommissions-Nr.</th>
            <th>Firma</th>
            <th>Ausführungsdatum</th>
            <th>Status</th>
          </tr>
        </thead>
      );
    }
    return (
      <thead>
        <tr>
          <th>ObjectId</th>
          <th>Kommissions-Nr.</th>
          <th>Firma</th>
          <th>Ausführungsdatum</th>
          <th>Preis Verkäufer</th>
          <th>Preis Monteur</th>
          <th>Zugewiesen an</th>
          <th>Status</th>
        </tr>
      </thead>
    );
  };

  const renderRows = () => {
    if (objects && objects.length > 0) {
      return objects.map((object) => (
        <ObjectRow
          objectView={object}
          isInternal={variant === 'internal'}
          key={`${object.orderId}${object.objectId}${object.dateId}`}
          onClick={onClick}
        />
      ));
    }
    return (<center>Das sollte nicht passieren o_o</center>);
  };

  const renderBody = () => {
    if (objects && objects.length > 0) {
      return (
        <tbody>
          {renderRows()}
        </tbody>
      );
    }
    return null;
  };

  return (
    <table className="table table-hover">
      {renderHeader()}
      {renderBody()}
    </table>
  );
}

ObjectList.propTypes = {
  objects: PropTypes.instanceOf(Array).isRequired,
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ObjectList.defaultProps = { };
