import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../ModalDialog';
import HelpContext from './HelpContext';

/**
 * HelpDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function HelpDialog(props) {
  const { context, visible, onClose } = props;
  const [item, setItem] = React.useState(null);

  React.useEffect(() => {
    if (context) {
      const data = HelpContext[context];
      setItem(data);
    }
  }, [context]);

  const renderImage = () => {
    if (item && item.image) {
      return (
        <img src={item.image} alt="Infografik" style={{ width: '100%', marginTop: 20 }} />
      );
    }
    return null;
  };

  if (item) {
    return (
      <ModalDialog onClose={onClose} closeCaption="Schließen" hideConfirmButton visible={visible}>
        <h5 className="hk-text-primary">
          <i className="fas fa-question-circle" style={{ marginRight: 10 }} />
          {item.headline}
        </h5>
        <p>{item.text}</p>
        {renderImage()}
      </ModalDialog>
    );
  }
  return null;
}

HelpDialog.propTypes = {
  context: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

HelpDialog.defaultProps = {
  context: null,
};
