import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import de from 'date-fns/locale/de';
import styled, { createGlobalStyle } from 'styled-components';

const ClearableDatePickerStyles = createGlobalStyle`
  .ClearableDatePicker {
  display:flex;
  background-color: transparent;
  cursor: pointer;
}
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 8px 15px;
    background-color: ${(props) => props.theme.color.grayBackgroundColor};
    border-radius: ${(props) => props.theme.border.radius};
    margin-right: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
    max-height: 3em;
   &:hover {
      ${(props) => props.theme.boxShadowDarkHover};
    }
`;
const Text = styled.span`
  margin-right: 5px;
  width: 2em;
  color: #c6c6c6;
`;

const Icon = styled.i`
  margin-left: 5px;
   cursor: pointer;
   &:hover {
      color: ${(props) => props.theme.color.primaryRedColor};
    }
`;

export default function ClearableDatePicker(props) {
  const {
    selected, minDate, onChange, label, onClear,
  } = props;

  const renderClearButton = () => {
    if (selected) {
      return (
        <Icon className="fas fa-times" onClick={onClear} />
      );
    }
    return (
      <Icon className="fas fa-times" style={{ color: 'transparent', cursor: 'auto' }} />
    );
  };

  return (
    <>
      <Container>
        <Text>{label}</Text>
        <ClearableDatePickerStyles />
        <DatePicker
          onChange={(date) => onChange(date)}
          locale={de}
          selected={selected}
          minDate={minDate}
          dateFormat="iiii, dd.MM.yyyy"
          onChangeRaw={(e) => e.preventDefault()}
          showWeekNumbers
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          className="ClearableDatePicker"
        />
        {renderClearButton()}
      </Container>
    </>
  );
}

ClearableDatePicker.propTypes = {
  selected: PropTypes.instanceOf(Object),
  minDate: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

ClearableDatePicker.defaultProps = {
  selected: null,
  label: '',
  onChange: () => {},
  onClear: () => {},
  minDate: null,
};
