import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReclamationTableView from './ReclamationTableView';
import Panel from '../../../Components/Panel';
import BoxSettingsIcon from './BoxSettingsIcon';
import BoxLoadingIcon from './BoxLoadingIcon';
import OpenOffersTableView from './OpenOffersTableView';
import {
  OBJECT_DATESTATE_ASSIGNED,
  OBJECT_DATESTATE_SCHEDULED,
  RECLAMATIONSTATE_ASSIGNED,
  RECLAMATIONSTATE_OPEN,
  RECLAMATIONSTATE_SCHEDULED,
  RECLAMATIONSTATE_WAITING_APPOINTMENT,
  RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT,
} from '../../../Library/StateTypes';
import {
  SERVICE_APPOINTMENT_WAITING_FOR_APPROVAL,
  SERVICE_APPOINTMENT_WAITING_FOR_CONFIRMATION,
  SERVICE_APPOINTMENT_WAITING_FOR_PROPOSED_DATES,
  SERVICESTATE_ASSIGNED,
  SERVICESTATE_SCHEDULED,
} from '../../../Library/States/ServiceStates';
import ExternalMonteurObjectStateBox from './OrderTableBox/ExternalMonteurObjectStateBox';
import ExternalObjectWaitingBox from './OrderTableBox/ExternalObjectWaitingBox';
import ExternalObjectWaitingApprovementBox from './OrderTableBox/ExternalObjectWaitingApprovementBox';
import ExternalDealerObjectStateBox from './OrderTableBox/ExternalDealerObjectStateBox';

/**
 * ExternalBoxes()
 * @returns {*|null}
 * @constructor
 */
export default function ExternalBoxes(props) {
  const {
    reclamations, orders, client, onOrderClick, onReclamationClick, visible, storageConfig, onHideBox, onRestoreBoxes,
    ordersLoading, reclamationsLoading, openOffers, clientOffers, services,
  } = props;
  const [todayOrLaterReclamations, setTodayOrLaterReclamations] = React.useState(null);
  const [hasHiddenOrderBoxes, setHasHiddenOrderBoxes] = React.useState(false);
  const [hasHiddenReklaBoxes, setHasHiddenReklaBoxes] = React.useState(false);

  React.useEffect(() => {
    if (reclamations) {
      const filtered = reclamations.filter((item) => (
        item.executionDate
        && moment(item.executionDate).isSameOrAfter(moment(), 'day')
      ));

      setTodayOrLaterReclamations(filtered);
    }
  }, [reclamations]);

  React.useEffect(() => {
    setHasHiddenOrderBoxes(false);
    setHasHiddenReklaBoxes(false);

    if (storageConfig) {
      Object.keys(storageConfig).forEach((item) => {
        if (item.indexOf('orderExtern') > -1) {
          setHasHiddenOrderBoxes(true);
        }
        if (item.indexOf('reclamationExtern') > -1) {
          setHasHiddenReklaBoxes(true);
        }
      });
    }
  }, [storageConfig]);

  const renderOrderSettingsIcon = () => {
    if (ordersLoading) {
      return <BoxLoadingIcon />;
    }

    if (hasHiddenOrderBoxes) {
      return <BoxSettingsIcon onPress={() => onRestoreBoxes('orderExtern')} />;
    }
    return <div style={{ height: 40 }} />;
  };

  const renderReclamationSettingsIcon = () => {
    if (reclamationsLoading) {
      return <BoxLoadingIcon />;
    }

    if (hasHiddenReklaBoxes) {
      return <BoxSettingsIcon onPress={() => onRestoreBoxes('reclamationExtern')} />;
    }
    return <div style={{ height: 40 }} />;
  };

  const renderOpenOffers = () => {
    if (openOffers && openOffers.length > 0) {
      return (
        <OpenOffersTableView
          orders={openOffers}
          rowCount={10}
          onHideBox={onHideBox}
          storageDashboardSettings={storageConfig}
          clientOffers={clientOffers}
          onClick={onOrderClick}
        />
      );
    }
    return null;
  };

  if (visible) {
    return (
      <div className="row">
        <div className="col-lg-6">
          <Panel marginBottom={25} withPadding={false}>
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center flex-grow-1">
                <h5 style={{ marginBottom: 0 }}>
                  <i className="fas fa-truck" style={{ marginRight: 12 }} />
                  Externe Aufträge
                </h5>
              </div>
              <div>
                {renderOrderSettingsIcon()}
              </div>
            </div>
          </Panel>

          {renderOpenOffers()}

          <ExternalObjectWaitingBox
            orders={orders}
            headline="Wartet auf Berechnung"
            subHeadline="Die neusten externen Aufträge die auf eine Berechnung warten"
            rowCount={8}
            onClick={onOrderClick}
            identifier="orderExternWaiting"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
            client={client}
          />

          <ExternalObjectWaitingApprovementBox
            orders={orders}
            headline="Wartet auf Bestätigung"
            subHeadline="Die neusten externen Angebote die auf eine Bestätigung durch Sie warten"
            rowCount={8}
            onClick={onOrderClick}
            identifier="orderExternWaitingApprovement"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
            client={client}
          />

          <ExternalDealerObjectStateBox
            orders={orders}
            headline="Offene Aufträge"
            subHeadline="Die als nächstes anstehenden externen offenen Aufträge"
            rowCount={8}
            onClick={onOrderClick}
            client={client}
            identifier="orderExternScheduled"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ExternalMonteurObjectStateBox
            orders={orders}
            headline="Terminierte Aufträge"
            subHeadline="Die als nächstes anstehenden externen terminierten Aufträge"
            rowCount={8}
            onClick={onOrderClick}
            state={OBJECT_DATESTATE_SCHEDULED}
            client={client}
            identifier="orderExternScheduled"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ExternalMonteurObjectStateBox
            orders={orders}
            headline="Zugewiesene Aufträge"
            subHeadline="Die als nächstes anstehenden externen zugewiesenen Aufträge"
            rowCount={8}
            onClick={onOrderClick}
            state={OBJECT_DATESTATE_ASSIGNED}
            client={client}
            identifier="orderExternAssigned"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />
        </div>
        <div className="col-lg-6">
          <Panel marginBottom={25} withPadding={false}>
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center flex-grow-1">
                <h5 style={{ marginBottom: 0 }}>
                  <i className="fas fa-sync" style={{ marginRight: 12 }} />
                  Externe Reklamationen
                </h5>
              </div>
              <div>
                {renderReclamationSettingsIcon()}
              </div>
            </div>
          </Panel>

          <ReclamationTableView
            services={services}
            serviceState={SERVICE_APPOINTMENT_WAITING_FOR_APPROVAL}
            headline="Serviceauftrag wartet auf Startdatum"
            rowCount={8}
            onClick={onReclamationClick}
            identifier="reclamationExternOpen"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ReclamationTableView
            reclamations={reclamations}
            headline="Offene Reklamationen"
            subHeadline="Die zuletzt erstellen externen offenen Reklamationen"
            rowCount={8}
            onClick={onReclamationClick}
            reclamationState={RECLAMATIONSTATE_OPEN}
            identifier="reclamationExternOpen"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ReclamationTableView
            services={services}
            serviceState={SERVICE_APPOINTMENT_WAITING_FOR_PROPOSED_DATES}
            headline="Serviceauftrag wartet auf Terminvorschläge"
            rowCount={8}
            onClick={onReclamationClick}
            identifier="reclamationExternWaitingAppointment"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ReclamationTableView
            reclamations={reclamations}
            headline="Reklamationen warten auf Terminvorschläge"
            subHeadline="Diese zuletzt erstellten Reklamationen warten auf Terminvorschläge"
            rowCount={8}
            onClick={onReclamationClick}
            reclamationState={RECLAMATIONSTATE_WAITING_APPOINTMENT}
            identifier="reclamationExternWaitingAppointment"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ReclamationTableView
            services={services}
            serviceState={SERVICE_APPOINTMENT_WAITING_FOR_CONFIRMATION}
            headline="Serviceauftrag wartet auf Terminbestätigung"
            rowCount={8}
            onClick={onReclamationClick}
            identifier="reclamationExternWaitingAppointmentApproved"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ReclamationTableView
            reclamations={reclamations}
            headline="Reklamationen warten auf Terminbestätigung"
            subCaption="Diese Reklamationen warten auf Terminbestätigung"
            rowCount={8}
            onClick={onReclamationClick}
            reclamationState={RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT}
            identifier="reclamationExternWaitingAppointmentApproved"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ReclamationTableView
            reclamations={todayOrLaterReclamations}
            services={services}
            serviceState={SERVICESTATE_SCHEDULED}
            headline="Terminierte Reklamationen"
            subHeadline="Die als nächstes anstehenden terminierten Reklamationen"
            rowCount={8}
            onClick={onReclamationClick}
            reclamationState={RECLAMATIONSTATE_SCHEDULED}
            identifier="reclamationExternalScheduled"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />

          <ReclamationTableView
            reclamations={todayOrLaterReclamations}
            services={services}
            serviceState={SERVICESTATE_ASSIGNED}
            headline="Zugewiesene Reklamationen"
            subHeadline="Die als nächstes anstehenden zugewiesenen Reklamationen"
            rowCount={8}
            onClick={onReclamationClick}
            reclamationState={RECLAMATIONSTATE_ASSIGNED}
            identifier="reclamationExternalAssigned"
            storageDashboardSettings={storageConfig}
            onHideBox={onHideBox}
          />
        </div>
      </div>
    );
  }
  return null;
}

ExternalBoxes.propTypes = {
  orders: PropTypes.instanceOf(Array),
  reclamations: PropTypes.instanceOf(Array),
  services: PropTypes.instanceOf(Array),
  client: PropTypes.instanceOf(Object).isRequired,
  onReclamationClick: PropTypes.func.isRequired,
  onOrderClick: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onRestoreBoxes: PropTypes.func.isRequired,
  storageConfig: PropTypes.instanceOf(Object),
  onHideBox: PropTypes.func.isRequired,
  ordersLoading: PropTypes.bool,
  reclamationsLoading: PropTypes.bool,
  openOffers: PropTypes.instanceOf(Array),
  clientOffers: PropTypes.instanceOf(Array),
};

ExternalBoxes.defaultProps = {
  orders: null,
  reclamations: null,
  services: null,
  visible: false,
  storageConfig: null,
  ordersLoading: false,
  reclamationsLoading: false,
  openOffers: null,
  clientOffers: null,
};
