import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../../Components/ModalDialog';
import Input from '../../../Components/Input';
import Textarea from '../../../Components/Textarea';
import { isValidFloatNumberFormat } from '../../../Library/Functions';
import Checkbox from '../../../Components/Checkbox';

const initialState = {
  caption: '',
  description: '',
  priceMonteur: '',
  priceDealer: '',
  errors: {},
  hasError: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'caption':
      return { ...state, caption: action.payload };
    case 'description':
      return { ...state, description: action.payload };
    case 'priceMonteur':
      return { ...state, priceMonteur: (action.payload) ? action.payload.replace(',', '.') : null };
    case 'priceDealer':
      return { ...state, priceDealer: (action.payload) ? action.payload.replace(',', '.') : null };
    case 'addError':
      return { ...state, errors: { ...state.errors, [action.payload]: true }, hasError: true };
    case 'resetErrors': {
      return { ...state, errors: {}, hasError: false };
    }
    case 'reset':
      return { ...initialState };
    default:
      return { ...state };
  }
}

/**
 * Add()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Add(props) {
  const { visible, onClose, onSave } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState, (data) => (data));
  const [calcAuto, setCalcAuto] = React.useState(true);

  React.useEffect(() => {
    if (calcAuto) {
      dispatch({
        type: 'priceDealer',
        payload: (state.priceMonteur * 1.25).toFixed(2).toString(),
      });
    }
  }, [calcAuto, state.priceMonteur]);

  const handleClose = () => {
    dispatch({ type: 'reset' });
    setCalcAuto(true);
    onClose();
  };

  const handleSave = () => {
    dispatch({ type: 'resetErrors' });
    let hasError = false;

    if (!state.caption || state.caption.length < 3) {
      dispatch({ type: 'addError', payload: 'caption' });
      hasError = true;
    }
    if (!state.priceMonteur || !isValidFloatNumberFormat(state.priceMonteur)) {
      dispatch({ type: 'addError', payload: 'priceMonteur' });
      hasError = true;
    }
    if (!state.priceDealer || !isValidFloatNumberFormat(state.priceDealer)) {
      dispatch({ type: 'addError', payload: 'priceDealer' });
      hasError = true;
    }

    if (!hasError) {
      onSave({
        caption: state.caption,
        description: state.description,
        priceDealer: state.priceDealer,
        priceMonteur: state.priceMonteur,
      });
      dispatch({ type: 'reset' });
      setCalcAuto(true);
    }
  };

  return (
    <ModalDialog onClose={handleClose} visible={visible} onConfirm={handleSave}>
      <h5 className="hk-text-primary">Material hinzufügen</h5>
      <hr />

      <div className="row">
        <Input
          onChange={(value) => dispatch({ type: 'caption', payload: value })}
          label="Bezeichnung"
          value={state.caption}
          rowClass="col-lg-12"
          hasError={!!(state.errors.caption)}
          errorMessage="Das Material benötigt eine kurze Bezeichnung!"
        />
        <Textarea
          onChange={(value) => dispatch({ type: 'description', payload: value })}
          label="Beschreibung"
          value={state.description}
          rows={4}
          rowClass="col-lg-12"
        />
        <Input
          onChange={(value) => dispatch({ type: 'priceMonteur', payload: value })}
          label="Preis Monteur"
          value={state.priceMonteur}
          rightIcon="fas fa-euro-sign"
          rowClass="col-lg-6"
          hasError={!!(state.errors.priceMonteur)}
          errorMessage="Bitte geben Sie einen gültigen Preis ein!"
        />
        <Input
          onChange={(value) => dispatch({ type: 'priceDealer', payload: value })}
          label="Preis Verkäufer"
          value={state.priceDealer}
          rightIcon="fas fa-euro-sign"
          rowClass="col-lg-6"
          hasError={!!(state.errors.priceDealer)}
          errorMessage="Bitte geben Sie einen gültigen Preis ein!"
          disabled={calcAuto}
        />
        <div className="col-lg-12">
          <Checkbox onChange={() => setCalcAuto(!calcAuto)} value={calcAuto}>
            Verkäuferpreis autom. berechnen (25% Aufschlag)
          </Checkbox>
        </div>
      </div>
    </ModalDialog>
  );
}

Add.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

Add.defaultProps = {
  visible: false,
};
