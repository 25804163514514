export const EMPTY_STRING = '';

export const ERROR_VALUE_MSSING = 'Bitte das Feld ausfüllen';

export const ERROR_ZIP_INVALID = 'Bitte geben Sie eine gültige Postleitzahl ein';

export const ERROR_MAIL_INVALID = 'Bitte geben Sie eine gültige E-Mail Adresse ein';

export const ERROR_PHONE_OR_MOBILE_MSSING = 'Bitte geben Sie mindestens eine Telefonnummer an';

export const ERROR_FILE_EXTENSION_INVALID = 'Dieser Dateityp ist nicht erlaubt';

export const ERROR_PRICE_INVALID = 'Bitte geben Sie einen gültige Preis ein (Beispiel: 2500.50)';

export const ERROR_BALANCE_INVALID = ERROR_PRICE_INVALID;
export const ERROR_BALANCE_TO_BIG = 'Der Rechnungsbetrag darf nicht größer als der Küchenpreis sein!';

export const ERROR_RUNNING_METER_BOTTOM_INVALID = 'Bitte geben Sie eine gültige Länge ein (Beispiel: 2.5)';

export const ERROR_EXTERNAL_ID_DUPLICATE = 'Diese Kommissionsnummer ist bereits vergeben';

export const ERROR_REMOVAL_METER_INVALID = 'Bitte geben Sie eine gültige Länge ein!';

export const ERROR_DELIVERY_DATE_INVALID = 'Bitte geben Sie ein gültiges Datum in der Zukunft ein!';
export const ERROR_DELIVERY_DATE_END_BEFORE_START = 'Das Datum muss nach dem vorherigen Datum liegen!';
