import React from 'react';
import PropTypes from 'prop-types';
import StatusBadge from '../StatusBadge';
import {
  useGetServiceCaption,
  useGetServiceIcon,
  useGetServiceType,
} from '../../Library/States/ServiceStates';

/**
 * ReclamationStatusBadge()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ServiceStatusBadge(props) {
  const {
    serviceState, containerStyle, visible, alignContainer, iconOnly, useMediaQuery,
  } = props;

  const getCaption = useGetServiceCaption();
  const getIcon = useGetServiceIcon();
  const getType = useGetServiceType();

  return (
    <StatusBadge
      caption={getCaption(serviceState)}
      icon={getIcon(serviceState)}
      type={getType(serviceState)}
      visible={visible}
      useMediaQuery={useMediaQuery}
      containerStyle={containerStyle}
      iconOnly={iconOnly}
      alignContainer={alignContainer}
    />
  );
}

ServiceStatusBadge.propTypes = {
  serviceState: PropTypes.number,
  containerStyle: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  alignContainer: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  iconOnly: PropTypes.bool,
  useMediaQuery: PropTypes.bool,
};

ServiceStatusBadge.defaultProps = {
  serviceState: undefined,
  containerStyle: {},
  visible: true,
  alignContainer: 'flex-start',
  iconOnly: false,
  useMediaQuery: false,
};
