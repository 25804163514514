import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomerAddressInput from '../../../../Components/OrderComponents/CustomerAddressInput';
import KitchenInformation from './KitchenInformation';
import ObjectFiles from './ObjectFiles';
import Panel from '../../../../Components/Panel';

function initReducer(data) {
  return { ...data };
}

function reducer(state, action) {
  switch (action.type) {
    case 'customerAddress':
      return { ...state, customer: { ...state.customer, ...action.payload } };
    case 'kitchenOptions':
      return { ...state, kitchenOptions: action.payload };
    case 'objectFiles':
      return { ...state, objectFiles: action.payload };

    default:
      throw new Error();
  }
}

export default function ObjectContainer(props) {
  const {
    onChange, orderType, editableData, errors, isEditMode, isInternal,
  } = props;
  const [state, dispatch] = useReducer(reducer, editableData, initReducer);
  const [prevState, setPrevState] = useState(JSON.stringify(state));

  React.useEffect(() => {
  }, [state]);

  useEffect(() => {
    if (JSON.stringify(state) !== prevState) {
      if (onChange) {
        const mergedState = {
          ...state.kitchenOptions,
          customer: state.customer,
          documents: state.objectFiles,
        };
        onChange(mergedState);
        setPrevState(JSON.stringify(state));
      }
    }
  }, [state, onChange, prevState]);

  return (
    <div>
      <Panel marginBottom={30}>
        <CustomerAddressInput
          orderType={orderType}
          editableData={editableData.customer}
          onChange={(value) => dispatch({ type: 'customerAddress', payload: value })}
          errors={errors.customerErrors}
          isInternal={isInternal}
        />
      </Panel>

      <Panel marginBottom={30}>
        <KitchenInformation
          editableData={editableData}
          orderType={orderType}
          onChange={(value) => dispatch({ type: 'kitchenOptions', payload: value })}
          errors={errors.kitchenInformationErrors}
          isInternal={isInternal}
        />
      </Panel>

      <Panel marginBottom={30}>
        <ObjectFiles
          orderType={orderType}
          editableData={editableData.documents}
          onChange={(value) => dispatch({ type: 'objectFiles', payload: value })}
          errors={errors.fileErrors}
          isEditMode={isEditMode}
        />
      </Panel>
    </div>
  );
}

ObjectContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  orderType: PropTypes.oneOf(['SINGLE', 'OBJECT']),
  editableData: PropTypes.instanceOf(Object),
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isEditMode: PropTypes.bool,
  isInternal: PropTypes.bool.isRequired,
};

ObjectContainer.defaultProps = {
  orderType: 'SINGLE',
  editableData: {},
  errors: {},
  isEditMode: false,
};
