import axios from '../../Library/Axios';

export const METADATA_GETBANKINFORMATION_SUCCESS = 'METADATA_GETBANKINFORMATION_SUCCESS';

/**
 * getBankInformation()
 * @param iban
 * @returns {function(*): Promise<unknown>}
 */
export default function getBankInformation(iban) {
  return (dispatch) => {
    const uri = `/bank/${iban}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: METADATA_GETBANKINFORMATION_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => Promise.reject(error));
  };
}
