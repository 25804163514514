import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

export default function OrderOptionIcons(props) {
  const { order, withMarginRight } = props;

  const renderOptions = () => {
    const optionsArray = [];
    if (order.optionDelivery) {
      optionsArray.push(<i key={Math.random()} className="fas fa-truck-moving" />);
    }
    if (order.optionMontage) {
      optionsArray.push(<i key={Math.random()} className="fas fa-tools" />);
    }
    if (order.optionStorage) {
      optionsArray.push(<i key={Math.random()} className="fas fa-cubes" />);
    }
    if (order.optionRemoval) {
      optionsArray.push(<i key={Math.random()} className="fas fa-trash-alt" />);
    }

    return optionsArray;
  };

  return (
    <div className={`OrderOptionIcons ${(withMarginRight) ? 'WithMarginRight' : ''}`}>
      {renderOptions()}
    </div>
  );
}

OrderOptionIcons.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  withMarginRight: PropTypes.bool,
};

OrderOptionIcons.defaultProps = {
  withMarginRight: false,
};
