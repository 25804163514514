// WORK OPTIONS
export const WORK_OPTION_ELECTRIC = 'ELECTRIC';
export const WORK_OPTION_WATER = 'WATER';
export const WORK_OPTION_AIR = 'AIR';
export const WORK_OPTION_LIGHT = 'LIGHT';
export const WORK_OPTION_QUOOKER = 'QUOOKER';

const workOptionLabel = {
  [WORK_OPTION_ELECTRIC]: 'Elektro',
  [WORK_OPTION_WATER]: 'Wasser',
  [WORK_OPTION_AIR]: 'Abluft',
  [WORK_OPTION_LIGHT]: 'Beleuchtung',
  [WORK_OPTION_QUOOKER]: 'Quooker',
};

const workOptionIcon = {
  [WORK_OPTION_ELECTRIC]: 'fas fa-plug',
  [WORK_OPTION_WATER]: 'fas fa-hand-holding-water',
  [WORK_OPTION_AIR]: 'fas fa-wind',
  [WORK_OPTION_LIGHT]: 'far fa-lightbulb',
  [WORK_OPTION_QUOOKER]: 'fas fa-shower',
};

export const getWorkOptionLabel = (option) => (workOptionLabel[option] || 'Unbekannt');
export const getWorkOptionIcon = (option) => (workOptionIcon[option] || 'fas fa-question');
export const getWorkOption = (option) => ({
  key: option,
  caption: getWorkOptionLabel(option),
  icon: getWorkOptionIcon(option),
});
export const workOptions = [
  getWorkOption(WORK_OPTION_ELECTRIC),
  getWorkOption(WORK_OPTION_WATER),
  getWorkOption(WORK_OPTION_AIR),
  getWorkOption(WORK_OPTION_LIGHT),
  getWorkOption(WORK_OPTION_QUOOKER),
];

// COUNTERTOP OPTIONS
export const COUNTERTOP_OPTION_STONE = 'STONE';
export const COUNTERTOP_OPTION_STEEL = 'STEEL';
export const COUNTERTOP_OPTION_WOOD = 'WOOD';
export const COUNTERTOP_OPTION_CERAMIC = 'CERAMIC';

const countertopOptionLabel = {
  [COUNTERTOP_OPTION_STONE]: 'Stein / Granit',
  [COUNTERTOP_OPTION_STEEL]: 'Edelstahl',
  [COUNTERTOP_OPTION_WOOD]: 'Holz / Schichtstoff',
  [COUNTERTOP_OPTION_CERAMIC]: 'Keramik',
};

const countertopOptionIcon = {
  [COUNTERTOP_OPTION_STONE]: 'fas fa-egg',
  [COUNTERTOP_OPTION_STEEL]: 'fas fa-layer-group',
  [COUNTERTOP_OPTION_WOOD]: 'fas fa-tree',
  [COUNTERTOP_OPTION_CERAMIC]: 'fas fa-clone',
};

export const getCountertopOptionLabel = (option) => (countertopOptionLabel[option] || 'Unbekannt');
export const getCountertopOptionIcon = (option) => (countertopOptionIcon[option] || 'fas fa-question');
export const getCountertopOption = (option) => ({
  key: option,
  value: option,
  caption: getCountertopOptionLabel(option),
  label: getCountertopOptionLabel(option),
  icon: getCountertopOptionIcon(option),
});
export const countertopOptions = [
  getCountertopOption(COUNTERTOP_OPTION_STONE),
  getCountertopOption(COUNTERTOP_OPTION_STEEL),
  getCountertopOption(COUNTERTOP_OPTION_WOOD),
  getCountertopOption(COUNTERTOP_OPTION_CERAMIC),
];

// COUNTERTOP OPTIONS
export const COUNTERTOP_DELIVERY_OPTION_EXTERN = 0;
export const COUNTERTOP_DELIVERY_OPTION_INCLUDED = 1;

const countertopDeliveryOptionLabel = {
  [COUNTERTOP_DELIVERY_OPTION_EXTERN]: 'Arbeitsplatte wird extern geliefert/montiert',
  [COUNTERTOP_DELIVERY_OPTION_INCLUDED]: 'Arbeitsplatte ist Teil der Lieferung/Montage',
};

export const getCountertopDeliveryOptionLabel = (option) => (countertopDeliveryOptionLabel[option] || 'Unbekannt');
export const getCountertopDeliveryOption = (option) => ({
  label: getCountertopDeliveryOptionLabel(option),
  value: option,
});
export const countertopDeliveryOptions = [
  getCountertopDeliveryOption(COUNTERTOP_DELIVERY_OPTION_EXTERN),
  getCountertopDeliveryOption(COUNTERTOP_DELIVERY_OPTION_INCLUDED),
];
