import React from 'react';
import * as Redux from 'react-redux';
import axios from '../../../../Library/Axios';
import { create as createItem } from './Service/ItemAction';
import { get as getOrder } from '../../OrderAction';

export const defaultUri = '/order/{orderId}/object/{objectId}/service';
export const defaultUriId = `${defaultUri}/{serviceId}`;

/**
 * Prepare default uri and replace all placeholders
 * @param object
 * @returns {string}
 */
function prepareUrl(object) {
  return defaultUri.replace('{orderId}', object.orderId)
    .replace('{objectId}', object.objectId);
}

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.orderId {number}
 * @param parameter.objectId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUri(parameter, uri = defaultUri) {
  const { orderId, objectId } = parameter;

  return uri
    .replace('{orderId}', orderId)
    .replace('{objectId}', objectId);
}

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.orderId {number}
 * @param parameter.objectId {number}
 * @param parameter.serviceId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUriId(parameter, uri = defaultUriId) {
  const { serviceId } = parameter;
  return prepareUri(parameter, uri).replace('{serviceId}', serviceId);
}

export const OBJECT_SERVICE_GET_START = 'OBJECT_SERVICE_GET_START';
export const OBJECT_SERVICE_GET_SUCCESS = 'OBJECT_SERVICE_GET_SUCCESS';
export const OBJECT_SERVICE_GET_ERROR = 'OBJECT_SERVICE_GET_ERROR';

/**
 * get()
 * @param serviceId
 * @returns {function(*): *}
 */
export function get(serviceId) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_GET_START, payload: serviceId });

    const uri = `${prepareUrl({ objectId: 999, orderId: 999 })}/${serviceId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_GET_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

/**
 * @param orderId
 * @param objectId
 * @param serviceId
 * @returns {function(): *}
 */
export const useDispatchGetService = (orderId, objectId, serviceId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUriId({ orderId, objectId, serviceId });

  return React.useCallback(() => {
    dispatch({ type: OBJECT_SERVICE_GET_START, payload: serviceId });

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch, serviceId]);
};

export const OBJECT_SERVICE_GETLIST_START = 'OBJECT_SERVICE_GETLIST_START';
export const OBJECT_SERVICE_GETLIST_SUCCESS = 'OBJECT_SERVICE_GETLIST_SUCCESS';
export const OBJECT_SERVICE_GETLIST_ERROR = 'OBJECT_SERVICE_GETLIST_ERROR';

/**
 * getList()
 * @param object
 * @returns {function(*): *}
 */
export function getList(object) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_GETLIST_START, payload: object });

    return axios().get(prepareUrl(object)).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_GETLIST_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

export const OBJECT_SERVICE_CREATE_START = 'OBJECT_SERVICE_CREATE_START';
export const OBJECT_SERVICE_CREATE_SUCCESS = 'OBJECT_SERVICE_CREATE_SUCCESS';
export const OBJECT_SERVICE_CREATE_ERROR = 'OBJECT_SERVICE_CREATE_ERROR';

/**
 * create()
 * @param service
 * @returns {function(*): *}
 */
export function create(service) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_CREATE_START, payload: service });

    return axios().post(prepareUrl(service), service).then((response) => {
      const { data } = response.data;

      const promises = [];
      const sendNotification = false;
      service.items.forEach((item) => {
        const promise = dispatch(createItem(data, item, sendNotification));
        promises.push(promise);
      });

      return Promise.all(promises).then(() => {
        dispatch({ type: OBJECT_SERVICE_CREATE_SUCCESS, payload: data });
        dispatch(getList(service));
        dispatch(getOrder(data.orderId, true));
        return Promise.resolve(data);
      });
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_CREATE_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

export const OBJECT_SERVICE_UPDATE_START = 'OBJECT_SERVICE_UPDATE_START';
export const OBJECT_SERVICE_UPDATE_SUCCESS = 'OBJECT_SERVICE_UPDATE_SUCCESS';
export const OBJECT_SERVICE_UPDATE_ERROR = 'OBJECT_SERVICE_UPDATE_ERROR';

/**
 * useDispatchUpdateService()
 * @returns {function(service:Object, disableDispositionChecks:boolean = false): *}
 */
export function useDispatchUpdateService() {
  const dispatch = Redux.useDispatch();

  return React.useCallback((service, disableDispositionChecks = false) => {
    dispatch({ type: OBJECT_SERVICE_UPDATE_START, payload: service });
    const config = { params: { disableDispositionChecks: (!disableDispositionChecks) ? 0 : 1 } };

    const uri = `${prepareUrl(service)}/${service.serviceId}`;

    return axios().put(uri, service, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_UPDATE_SUCCESS, payload: data });
      dispatch(getOrder(service.orderId, true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const OBJECT_SERVICE_DELETE_START = 'OBJECT_SERVICE_DELETE_START';
export const OBJECT_SERVICE_DELETE_SUCCESS = 'OBJECT_SERVICE_DELETE_SUCCESS';
export const OBJECT_SERVICE_DELETE_ERROR = 'OBJECT_SERVICE_DELETE_ERROR';

/**
 * deleteService()
 * @param service
 * @returns {function(*): *}
 */
export function deleteService(service) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_DELETE_START, payload: service });

    const uri = `${prepareUrl(service)}/${service.serviceId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_DELETE_SUCCESS, payload: data });
      dispatch(getList(service));
      dispatch(getOrder(service.orderId, true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_DELETE_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

export const OBJECT_SERVICE_SENDCUSTOMERMAIL_START = 'OBJECT_SERVICE_SENDCUSTOMERMAIL_START';
export const OBJECT_SERVICE_SENDCUSTOMERMAIL_SUCCESS = 'OBJECT_SERVICE_SENDCUSTOMERMAIL_SUCCESS';
export const OBJECT_SERVICE_SENDCUSTOMERMAIL_ERROR = 'OBJECT_SERVICE_SENDCUSTOMERMAIL_ERROR';

/**
 *
 * @returns {function(*=): *}
 */
export const useDispatchSendCustomerMail = () => {
  const dispatch = Redux.useDispatch();
  return React.useCallback((serviceId) => {
    const uri = '/mailer';
    const config = {
      params: {
        action: 'sendServiceScheduledMail',
      },
    };
    dispatch({ type: OBJECT_SERVICE_SENDCUSTOMERMAIL_START, serviceId });
    return axios().post(uri, { serviceId }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_SENDCUSTOMERMAIL_SUCCESS, payload: data });
      dispatch(get(serviceId));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_SENDCUSTOMERMAIL_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
};

export const OBJECT_SERVICE_GET_REPORT_START = 'OBJECT_SERVICE_GET_REPORT_START';
export const OBJECT_SERVICE_GET_REPORT_SUCCESS = 'OBJECT_SERVICE_GET_REPORT_SUCCESS';
export const OBJECT_SERVICE_GET_REPORT_ERROR = 'OBJECT_SERVICE_GET_REPORT_ERROR';

/**
 * getReport()
 * @param service
 * @returns {function(*): *}
 */
export function getReport(service) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_GET_REPORT_START, payload: service });

    const uri = `${prepareUrl(service)}/${service.serviceId}/report`;

    return axios().put(uri, service).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_GET_REPORT_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_GET_REPORT_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}
