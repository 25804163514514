import React from 'react';
import PropTypes from 'prop-types/prop-types';
import styled from 'styled-components';
import Panel from '../Panel';
import { CLIENTTYPE_DEALER } from '../../Library/Types';
import { formatDateTime } from '../../Library/Functions';
import ModalDialog from '../ModalDialog';
import Button from '../Button';
import Textarea from '../Textarea';

const ViewContainer = styled.div`
  justify-content: flex-end;
  flex-direction: column; 
`;

const MessageContainer = styled.div`
  background-color: #fff;
  ${(props) => props.theme.boxShadowDark};
  border-radius: 6px;
  padding: 10px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  
  .UnapprovedHint {
    border-top: 1px dashed #a7a7a7;
    padding-top: 10px;
    margin-top: 10px;
    font-size: 14px;
    color: #e7184e;
  }
    
  .Headline {
    border-bottom: 1px dashed #a7a7a7;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    
    .Icon {
      display: flex;
      font-size: 12px;
      align-content: center;
      align-items: center;
      justify-content: center;
      
      &.Left {
        margin-right: 10px;
      }
      &.Right {
        margin-left: 10px;
        display: none;
      }
    }
    
    .Actions {
      display: flex;
      font-size: 18px;
      align-content: center;
      align-items: center;
      justify-content: center;
      color: #cdcdcd;
      cursor: pointer;
      
      i {
        margin-right: 10px;
        
        &:last-child {
          margin-right: 0;
        }

        &.fa-tasks {
          &:hover {
            color: #e7184e;
          }
        }
        
        &.fa-trash {
          &:hover {
            color: #e7184e;
          }
        }
        
        &.fa-check-circle {
          &:hover {
            color: green;
          }
        }
      }
     
      
      &.Left {
        margin-right: 10px;
        display: none;
      }
      &.Right {
        margin-left: 10px;
      }
    }
    
    .Text {
      flex-grow: 1;
      
      .Name {
        font-family: "DIN Next LT W01 Bold", sans-serif;
      }
      .Date {
        font-size: 14px;
      }
    }
  
  }
  
  &.Right {  
    background-color: #f8f8f8;
  
    .Headline {
      .Icon {
        &.Left {
          display: none;
        }
        &.Right {
          display: flex;
        }
      }
      
      .Actions {
        &.Left {
          display: flex;
        }
        &.Right {
          display: none;
        }
      }
    
      .Text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: flex-end;
        align-items: flex-end;
      }
    }
  }
  
  &.Left {
    
  }
`;

/**
 * Message()
 * @param props
 * @returns {*}
 * @constructor
 */
function Message(props) {
  const {
    index, message, order, isAdmin, onDelete, onApprove, onAddTask,
  } = props;
  const [askDelete, setAskDelete] = React.useState(false);

  const handleDelete = () => {
    setAskDelete(false);
    onDelete(message);
  };

  const getName = () => {
    if (!isAdmin) {
      if (!message.user.isSystemAdmin) {
        if (order.internal) {
          return `${message.user.firstname} ${message.user.lastname}`;
        }
        if (message.client.type === CLIENTTYPE_DEALER) {
          return 'Verkäufer';
        }
        return 'Monteur';
      }
      return 'Hey.Kitchen Support';
    }
    return `${message.user.firstname} ${message.user.lastname}`;
  };

  const getIcon = () => {
    if (!message.user.isSystemAdmin) {
      if (order.internal) {
        return (
          <span className="fa-stack fa-2x">
            <i className="fas fa-circle fa-stack-2x" />
            <i className="fas fa-user fa-stack-1x fa-inverse" />
          </span>
        );
      }

      if (message.client.type === CLIENTTYPE_DEALER) {
        return (
          <span className="fa-stack fa-2x">
            <i className="fas fa-circle fa-stack-2x text-warning" />
            <i className="fas fa-building fa-stack-1x fa-inverse" />
          </span>
        );
      }
      return (
        <span className="fa-stack fa-2x">
          <i className="fas fa-circle fa-stack-2x text-info" />
          <i className="fas fa-truck fa-stack-1x fa-inverse" />
        </span>
      );
    }
    return (
      <span className="fa-stack fa-2x">
        <i className="fas fa-circle fa-stack-2x text-danger" />
        <i className="fas fa-user-graduate fa-stack-1x fa-inverse" />
      </span>
    );
  };

  const renderActions = () => {
    const actions = [];

    if (onAddTask) {
      actions.push(
        <i
          className="fas fa-tasks"
          onClick={() => onAddTask(message)}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {
          }}
          key={`${message.messageId}task`}
          title="Aufgabe erstellen"
        />,
      );
    }

    if (isAdmin) {
      actions.push(
        <i
          className="fas fa-trash"
          onClick={() => setAskDelete(true)}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
          key={`${message.messageId}trash`}
        />,
      );
      if (!message.approved) {
        actions.push(
          <i
            className="fas fa-check-circle"
            onClick={() => onApprove(message)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
            key={`${message.messageId}check`}
          />,
        );
      }
    }
    return actions;
  };

  const renderUnapprovedHint = () => {
    if (!message.approved && isAdmin) {
      return (
        <div className="UnapprovedHint">
          <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
          Diese Mitteilung ist noch nicht freigegeben und wird aktuell nur Ihnen und dem Ersteller angezeigt!
        </div>
      );
    }
    return null;
  };

  const getClassForContainer = () => {
    const classes = [];

    if (index % 2 === 0) {
      classes.push('Right');
    } else {
      classes.push('Left');
    }

    if (!message.approved) {
      classes.push('Unapproved');
    }
    return classes.join(' ');
  };

  return (
    <MessageContainer className={getClassForContainer()}>
      <div className="Headline">
        <div className="Icon Left">
          {getIcon()}
        </div>
        <div className="Actions Left">
          {renderActions()}
        </div>
        <div className="Text">
          <div className="Name">{`${getName()}`}</div>
          <div className="Date">{formatDateTime(message.createdDate)}</div>
        </div>
        <div className="Icon Right">
          {getIcon()}
        </div>
        <div className="Actions Right">
          {renderActions()}
        </div>
      </div>
      <div className="Message">
        {message.message}
      </div>
      {renderUnapprovedHint()}

      <ModalDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        centered
        confirmCaption="Mitteilung löschen"
        onConfirm={handleDelete}
      >
        <h5>Mitteilung löschen?</h5>
        <p>
          Diese Mitteilung endgültig löschen?
        </p>
      </ModalDialog>
    </MessageContainer>
  );
}

Message.propTypes = {
  index: PropTypes.number.isRequired,
  message: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onAddTask: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
};

Message.defaultProps = {
  onAddTask: null,
};

/**
 * MessageView()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function MessageView(props) {
  const {
    messages, order, object, onSend, isAdmin, onDelete, user, onApprove, onAddTask,
  } = props;
  const [message, setMessage] = React.useState('');
  const [filtered, setFiltered] = React.useState([]);
  const [showAdd, setShowAdd] = React.useState(false);

  React.useEffect(() => {
    if (object && messages) {
      let data = messages.filter((item) => (item.objectId === object.objectId));
      if (!isAdmin) {
        data = data.filter((item) => (item.approved || item.clientId === user.clientId));
      }
      setFiltered(data.reverse());
    } else {
      setFiltered(messages);
    }
  }, [object, messages, user.clientId, isAdmin]);

  const handleCloseAdd = () => {
    setMessage('');
    setShowAdd(false);
  };

  const handleOnSend = () => {
    if (message && message.length > 0) {
      setMessage('');
      handleCloseAdd();
      onSend({ message, orderId: order.orderId, objectId: (object) ? object.objectId : null });
    }
  };

  return (
    <>
      <ViewContainer>
        <Panel marginBottom={20} withPadding={false}>
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShowAdd(true)}>
              <i className="fas fa-comment-medical" style={{ marginRight: 8 }} />
              Neue Mitteilung
            </Button>
          </div>
        </Panel>

        {
          (filtered)
            ? filtered.map((item, index) => (
              <Message
                index={index}
                message={item}
                key={item.messageId}
                order={order}
                isAdmin={isAdmin}
                onDelete={onDelete}
                onApprove={onApprove}
                onAddTask={onAddTask}
              />
            ))
            : null
        }
      </ViewContainer>

      <ModalDialog visible={showAdd} onClose={handleCloseAdd} onConfirm={handleOnSend}>
        <h5>
          <i className="fas fa-comment-medical" style={{ marginRight: 8 }} />
          Neue Mitteilung
        </h5>
        <hr />
        <Textarea rows={10} onChange={(data) => setMessage(data)} value={message} autoFocus />
      </ModalDialog>
    </>
  );
}

MessageView.propTypes = {
  messages: PropTypes.instanceOf(Array),
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object),
  onSend: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  onDelete: PropTypes.func,
  onApprove: PropTypes.func,
  onAddTask: PropTypes.func,
  user: PropTypes.instanceOf(Object).isRequired,
};

MessageView.defaultProps = {
  messages: null,
  object: null,
  isAdmin: false,
  onDelete: () => {},
  onApprove: () => {},
  onAddTask: null,
};
