import React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import * as Redux from 'react-redux';
import {
  useDispatchSystemGetClient,
  useDispatchSystemGetClientObjectView,
} from '../Redux/Action/ClientAction';
import { NAVIGATION_SYSTEM_ORDER_DETAILS } from '../../../Library/Types';
import ObjectPage from '../Component/ObjectPage';

/**
 * Orders()
 * @returns {*}
 * @constructor
 */
export default function Orders() {
  const history = useHistory();
  const { clientId } = useParams();

  const client = Redux.useSelector((state) => state.systemClient.selectedClient);

  const dispatchGetClient = useDispatchSystemGetClient(clientId);
  const dispatchGetObjectView = useDispatchSystemGetClientObjectView(clientId);

  const [orders, setOrders] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [lastUpdated, setLastUpdated] = React.useState(false);

  React.useEffect(() => {
    if (!client) {
      dispatchGetClient();
    }
  }, [dispatchGetClient, client]);

  React.useEffect(() => {
    if (client) {
      setIsLoading(true);
      dispatchGetObjectView(client.clientId).then((response) => {
        setOrders(response);
        setIsLoading(false);
        setLastUpdated(new Date().getTime());
      }).catch(() => setIsLoading(false));
    }
  }, [dispatchGetObjectView, client]);

  const navigateDetails = (order) => {
    const uri = NAVIGATION_SYSTEM_ORDER_DETAILS.replace('{orderId}', order.orderId);
    history.push(uri);
  };

  const getHeadline = () => ((orders) ? `Auftragsübersicht (${orders.length})` : 'Auftragsübersicht');

  return (
    <ObjectPage
      variant="client"
      objects={orders}
      lastUpdated={lastUpdated}
      isLoading={isLoading}
      getHeadline={getHeadline}
      onClick={navigateDetails}
      dispatchGetObjects={dispatchGetObjectView}
    />
  );
}

Orders.propTypes = {};

Orders.defaultProps = {};
