import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Alert from '../../Alert';
import {
  ERRORCODE_ORDER_MONTEUR_NOT_AVAILABLE_HOLIDAY,
  ERRORCODE_ORDER_MONTEUR_NOT_AVAILABLE_ORDER, ERRORCODE_ORDER_MONTEUR_NOT_AVAILABLE_RECLAMATION,
} from '../../../Library/Types';

/**
 * OrderErrorAlert()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function OrderErrorAlert(props) {
  const { errorCode, onClose } = props;

  const getText = () => {
    switch (errorCode) {
      case ERRORCODE_ORDER_MONTEUR_NOT_AVAILABLE_HOLIDAY:
        return 'Einer der zugewiesenen Monteure hat an dem ausgewählten Montagetag bereits Urlaub eingetragen!';
      case ERRORCODE_ORDER_MONTEUR_NOT_AVAILABLE_ORDER:
        return 'Einer der zugewiesenen Monteure hat an dem ausgewählten Montagetag bereits eine Zuweisung zu '
          + 'einem anderen Auftrag!';
      case ERRORCODE_ORDER_MONTEUR_NOT_AVAILABLE_RECLAMATION:
        return 'Einer der zugewiesenen Monteure hat an dem ausgewählten Montagetag bereits eine Reklamationsauftrag '
          + 'zugewiesen!';
      default:
        return 'Unbekannter Fehler!';
    }
  };

  return (
    <Alert onClose={() => onClose()} visible={errorCode !== null} type="danger">
      {getText()}
    </Alert>
  );
}

OrderErrorAlert.propTypes = {
  errorCode: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

OrderErrorAlert.defaultProps = {
  errorCode: null,
};
