import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Panel from '../../Panel';
import KeyValueEditRow from '../../KeyValueEditRow';
import TextLink from '../../TextLink';
import { useDispatchDateDeleteUser } from '../../../Redux/Action/ObjectAction';
import TeamMonteurPartnerRow from '../../TeamMonteurPartner/TeamMonteurPartnerRow';
import SelectUserTeamPartner from '../../SelectUserTeamPartner';
import ObjectDateStatusBadge from '../ObjectDateStatusBadge';
import DispoInformation from './DispoInformation';
import {
  useAccessCanAssignObjects,
  useAccessCanScheduleObjects,
  useAccessIsObjectEditable,
} from '../../../Library/AccessChecks';
import { OBJECT_DATE_TYPE_MONTAGE } from '../../../Library/Types';
import { ORDERSTATE_PARTNER_CREATED } from '../../../Library/StateTypes';

/**
 * AssignMontageContainer()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function AssignMontageContainer(props) {
  const {
    object, onUserAdd, onTeamAdd, onPartnerAdd, onUpdate,
  } = props;
  const { client } = useSelector((state) => state.client);
  const { order } = useSelector((state) => state.order);
  const { serviceReport } = useSelector((state) => state.reports);

  const dispatchDateDeleteUser = useDispatchDateDeleteUser();

  const isEditable = useAccessIsObjectEditable(object);
  const isAssignable = useAccessCanAssignObjects();
  const isTerminable = useAccessCanScheduleObjects();
  const [canUsePartners, setCanUsePartners] = React.useState(false);
  const [allowEdit, setAllowEdit] = React.useState(false);
  const [showSelect, setShowSelect] = React.useState(false);
  const [minDate, setMinDate] = React.useState(null);
  const [montageDate, setMontageDate] = React.useState(null);
  const [demontageDate, setDemontageDate] = React.useState(null);
  const [deliveryDate, setDeliveryDate] = React.useState(null);

  React.useEffect(() => {
    setMontageDate(object.montageDate);
    setDemontageDate(object.removalDate);
    setDeliveryDate(object.deliveryDate);
  }, [object]);

  React.useEffect(() => {
    if ((deliveryDate && deliveryDate.date) || (demontageDate && demontageDate.date)) {
      if (deliveryDate && deliveryDate.date && (!demontageDate || !demontageDate.date)) {
        setMinDate(deliveryDate.date);
      } else if ((!deliveryDate || !deliveryDate.date) && demontageDate.date) {
        setMinDate(demontageDate.date);
      } else if (moment(deliveryDate.date).isAfter(moment(demontageDate.date))) {
        setMinDate(deliveryDate.date);
      } else {
        setMinDate(demontageDate.date);
      }
    } else {
      setMinDate(moment().format('YYYY-MM-DD'));
    }
  }, [deliveryDate, demontageDate]);

  React.useEffect(() => {
    if (montageDate) {
      setCanUsePartners(
        (client.clientId === order.clientId && (!montageDate.users || montageDate.users.length === 0)),
      );
      setAllowEdit((client.clientId === order.clientId && !serviceReport));
    }
  }, [client, order, montageDate, serviceReport]);

  const handleAddTeam = ({ teamId }) => {
    onTeamAdd({ ...montageDate, teamId });
    setShowSelect(false);
  };

  const handleAddPartner = ({ clientId }) => {
    onPartnerAdd({ ...montageDate, clientId });
    setShowSelect(false);
  };

  const handleAddUser = (user) => {
    onUserAdd(montageDate, user);
    setShowSelect(false);
  };

  const handleUpdate = (key, value, disableDispositionChecks = false) => {
    onUpdate({ ...montageDate, [key]: value }, key, OBJECT_DATE_TYPE_MONTAGE, disableDispositionChecks);
  };

  const renderAddUsers = () => {
    if (serviceReport
      || !isAssignable
      || (order.clientId === client.clientId && montageDate.clientId)
      || order.state === ORDERSTATE_PARTNER_CREATED) {
      return null;
    }

    if (montageDate.clientId === client.clientId) {
      let caption = 'Team oder Monteur zuweisen';
      if (!montageDate.teamId) {
        if (montageDate.users && montageDate.users.length > 0) {
          caption = 'Weitere Monteure zuweisen';
        }
        return (
          <TextLink
            caption={caption}
            onPress={() => setShowSelect(true)}
            icon="fas fa-plus-square"
            style={{ marginTop: 20 }}
          />
        );
      }
    }

    if (montageDate.date && (!montageDate.teamId && !montageDate.clientId)) {
      let caption = 'Team/Monteur/Partner zuweisen';
      if (!canUsePartners) {
        caption = 'Team oder Monteur zuweisen';
      }

      if (montageDate.users && montageDate.users.length > 0) {
        caption = 'Weitere Monteure zuweisen';
      }

      return (
        <TextLink
          caption={caption}
          onPress={() => setShowSelect(true)}
          icon="fas fa-plus-square"
          style={{ marginTop: 20 }}
        />
      );
    }
    return null;
  };

  if (montageDate && object.splitted && order && order.internal && order.optionMontage
    && (montageDate.clientId === client.clientId || order.clientId === client.clientId)
  ) {
    return (
      <Panel marginBottom={20} containerStyle={{ display: 'flex' }}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            <h6 className="Headline">
              <i className="fas fa-tools" style={{ marginRight: 10 }} />
              Montage
            </h6>
            <p>
              Bitte geben Sie hier das Lieferdatum an. Sobald Sie ein Datum festgelegt haben, können Sie ein
              Montageteam, Monteure oder einen Partner für die Lieferung zuweisen
            </p>
          </div>
          <div>
            <ObjectDateStatusBadge objectDateState={object.montageDate.state} />
          </div>
        </div>

        <KeyValueEditRow
          title="Montagedatum"
          type="date"
          initialValue={montageDate.date}
          displayText={(montageDate.date) ? moment(montageDate.date).format('dddd DD.MM.YYYY') : ''}
          onChange={(allowEdit) ? (date) => handleUpdate('date', date) : null}
          onClear={(allowEdit) ? () => handleUpdate('date', null) : null}
          datePickerMinDate={minDate}
          editable={(isEditable && isTerminable)}
        />

        <KeyValueEditRow
          title="Montagehinweis"
          type="textarea"
          initialValue={montageDate.comment}
          displayText={montageDate.comment}
          onChange={(allowEdit) ? (comment) => handleUpdate('comment', comment) : null}
        />

        <DispoInformation
          onChange={(key, value) => handleUpdate(key, value)}
          date={object.montageDate}
          object={object}
          order={order}
          client={client}
          editable={(isEditable && isTerminable)}
        />

        <TeamMonteurPartnerRow
          teamId={montageDate.teamId}
          partnerId={montageDate.clientId}
          users={montageDate.users}
          client={client}
          onDeleteTeam={() => handleUpdate('teamId', null, true)}
          onDeletePartner={() => handleUpdate('clientId', null, true)}
          onDeleteUser={(user) => dispatchDateDeleteUser(object, montageDate, user.userId)}
          hideUsersOrTeam={(montageDate.clientId && montageDate.clientId !== client.clientId)}
          disabled={(serviceReport || !isAssignable)}
        />
        {renderAddUsers()}

        <SelectUserTeamPartner
          onAddTeam={handleAddTeam}
          onAddPartner={handleAddPartner}
          onAddUser={handleAddUser}
          visible={showSelect}
          alreadyAddedUsers={montageDate.users}
          showPartners={canUsePartners}
          showTeams={(montageDate.users.length === 0)}
          onClose={() => setShowSelect(false)}
          executionDate={montageDate.date}
        />
      </Panel>
    );
  }
  return null;
}

AssignMontageContainer.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  onUserAdd: PropTypes.func.isRequired,
  onTeamAdd: PropTypes.func.isRequired,
  onPartnerAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
