import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getList } from '../Redux/Action/ReclamationAction';
import Panel from '../../../Components/Panel';
import { formatDate, formatDateTime } from '../../../Library/Functions';
import ReclamationStatusBadge from '../../../Components/ReclamationComponents/ReclamationStatusBadge';
import { get } from '../Redux/Action/ReclamationReportAction';

/**
 * List()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function List(props) {
  const {
    dispatchGetList, reclamations, dispatchGetReport,
  } = props;

  React.useEffect(() => {
    dispatchGetList();
  }, [dispatchGetList]);

  React.useEffect(() => {
    let loaded = false;
    if (reclamations) {
      reclamations.forEach((item) => {
        if (!loaded && item.report === undefined) {
          loaded = true;
          dispatchGetReport(item.reclamationId);
        }
      });
    }
  }, [reclamations, dispatchGetReport]);

  const renderReportInfos = (report) => {
    if (report && Object.keys(report).length > 0) {
      return (
        <small>
          <div>
            {formatDateTime(report.startDateTime, 'HH:mm')}
            {' - '}
            {formatDateTime(report.endDateTime, 'HH:mm')}
          </div>
          <div>{`${moment(report.endDateTime).diff(moment(report.startDateTime), 'minutes')} Minuten`}</div>
        </small>
      );
    }
    return null;
  };

  const renderRows = () => {
    if (reclamations) {
      return reclamations.map((item) => (
        <tr>
          <td>{item.reclamationId}</td>
          <td>{item.objectId}</td>
          <td>{item.title}</td>
          <td>{(item.executionDate) ? formatDate(item.executionDate) : ''}</td>
          <td>
            <ReclamationStatusBadge reclamation={item} />
          </td>
          <td>
            {renderReportInfos(item.report)}
          </td>
        </tr>
      ));
    }
    return null;
  };

  return (
    <>
      <Panel boxPadding={10} marginBottom={25}>
        <h5 style={{ margin: 0 }}>
          Externe Reklamationen
        </h5>
      </Panel>
      <Panel>
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>ObjectId</th>
              <th>Titel</th>
              <th>Ausführungsdatum</th>
              <th>Status</th>
              <th>Bericht</th>
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
        </table>
      </Panel>
    </>
  );
}

List.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGetReport: PropTypes.func.isRequired,
  reclamations: PropTypes.instanceOf(Array),
};

List.defaultProps = {
  reclamations: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    reclamations: store.systemReclamation.reclamations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: () => dispatch(getList()),
    dispatchGetReport: (reclamationId) => dispatch(get(reclamationId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(List);
