import * as Redux from 'react-redux';
import React from 'react';
import { CLIENTTYPE_MONTEUR } from './Types';

/**
 * ORDER STATES
 * !!! Should never collide with OBJECT DATE STATES !!!
 */
export const ORDERSTATE_INCOMPLETE = 100;
export const ORDERSTATE_PARTNER_CREATED = 101;
export const ORDERSTATE_WAITING_CALCULATION = 102;
export const ORDERSTATE_WAITING_APPROVEMENT = 103;
export const ORDERSTATE_OPEN = 104;
export const ORDERSTATE_FINISHED = 105;
export const ORDERSTATE_EXPIRED = 106;

export const orderStateCaption = {
  [ORDERSTATE_INCOMPLETE]: 'Unvollständig',
  [ORDERSTATE_PARTNER_CREATED]: 'Partnerauftrag wartet auf Bestätigung',
  [ORDERSTATE_WAITING_CALCULATION]: 'Wartet auf Berechnung',
  [ORDERSTATE_WAITING_APPROVEMENT]: 'Wartet auf Ihre Bestätigung',
  [ORDERSTATE_OPEN]: 'Offen',
  [ORDERSTATE_FINISHED]: 'Auftrag abgeschlossen',
  [ORDERSTATE_EXPIRED]: 'Angebot abgelaufen',
};

export const orderStateIcon = {
  [ORDERSTATE_INCOMPLETE]: 'fas fa-solid fa-pen',
  [ORDERSTATE_PARTNER_CREATED]: 'fas fa-exclamation-circle',
  [ORDERSTATE_WAITING_CALCULATION]: 'fas fa-calculator',
  [ORDERSTATE_WAITING_APPROVEMENT]: 'fas fa-thumbs-up',
  [ORDERSTATE_OPEN]: 'fas fa-folder-open',
  [ORDERSTATE_FINISHED]: 'fas fa-check-circle',
  [ORDERSTATE_EXPIRED]: 'fas fa-stopwatch',
};

export const orderStateType = {
  [ORDERSTATE_INCOMPLETE]: 'Info',
  [ORDERSTATE_PARTNER_CREATED]: 'Danger',
  [ORDERSTATE_WAITING_CALCULATION]: 'Warning',
  [ORDERSTATE_WAITING_APPROVEMENT]: 'Info',
  [ORDERSTATE_OPEN]: 'Danger',
  [ORDERSTATE_FINISHED]: 'Success',
  [ORDERSTATE_EXPIRED]: 'Primary',
};

/**
 * OBJECT DATE STATES
 * !!! Should never collide with ORDER STATES !!!
 */
export const OBJECT_DATESTATE_OPEN = 1;
export const OBJECT_DATESTATE_SCHEDULED = 2;
export const OBJECT_DATESTATE_ASSIGNED = 3;
export const OBJECT_DATESTATE_FINISHED = 4;
export const OBJECT_DATESTATE_CLOSED = 5;

export const objectDateStateCaption = {
  [OBJECT_DATESTATE_OPEN]: 'Offen',
  [OBJECT_DATESTATE_SCHEDULED]: 'Terminiert',
  [OBJECT_DATESTATE_ASSIGNED]: 'Zugewiesen',
  [OBJECT_DATESTATE_FINISHED]: 'Abgeschlossen',
  [OBJECT_DATESTATE_CLOSED]: 'Geschlossen',
};

export const objectDateStateIcon = {
  [OBJECT_DATESTATE_OPEN]: 'fas fa-folder-open',
  [OBJECT_DATESTATE_SCHEDULED]: 'fas fa-calendar',
  [OBJECT_DATESTATE_ASSIGNED]: 'fas fa-user-check',
  [OBJECT_DATESTATE_FINISHED]: 'fas fa-check-circle',
  [OBJECT_DATESTATE_CLOSED]: 'fas fa-check-circle',
};

export const objectDateStateType = {
  [OBJECT_DATESTATE_OPEN]: 'Danger',
  [OBJECT_DATESTATE_SCHEDULED]: 'Warning',
  [OBJECT_DATESTATE_ASSIGNED]: 'Primary',
  [OBJECT_DATESTATE_FINISHED]: 'Success',
  [OBJECT_DATESTATE_CLOSED]: 'Info',
};

/**
 * Returns Callback to combine the Order and ObjectDate State
 * @returns {(function(*=, *=, *=, *=): (any|null))|*}
 */
export const useCombinedState = () => {
  const { client } = Redux.useSelector((state) => state.client);
  const isSystemAdmin = Redux.useSelector((state) => state.auth.user.isSystemAdmin);

  return React.useCallback((orderState, objectDateState, isInternal, isSystemView = false) => {
    if (isSystemView && isSystemAdmin) {
      if (orderState === ORDERSTATE_OPEN) {
        return objectDateState;
      }
      return orderState;
    }

    if (orderState && orderState === ORDERSTATE_INCOMPLETE) {
      return orderState;
    }

    if (objectDateState && ((isInternal) || client.type === CLIENTTYPE_MONTEUR)) {
      return objectDateState;
    }

    if (orderState) {
      return orderState;
    }

    return null;
  }, [client, isSystemAdmin]);
};

/** Combined Order & ObjectDate State */
export const combinedStateCaption = { ...orderStateCaption, ...objectDateStateCaption };
export const combinedStateIcon = { ...orderStateIcon, ...objectDateStateIcon };
export const combinedStateType = { ...orderStateType, ...objectDateStateType };

/** RECLAMATION STATES */
export const RECLAMATIONSTATE_OPEN = 1;
export const RECLAMATIONSTATE_WAITING_APPOINTMENT = 2;
export const RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT = 3;
export const RECLAMATIONSTATE_APPROVED = 4;
export const RECLAMATIONSTATE_ASSIGNED = 5;
export const RECLAMATIONSTATE_REPORT_FINISHED = 6;
export const RECLAMATIONSTATE_SCHEDULED = 7;
export const RECLAMATIONSTATE_INPROGRESS = 8;
export const RECLAMATIONSTATE_WAITING = 9;
export const RECLAMATIONSTATE_CLOSED = 10;

export const reclamationStateCaption = {
  [RECLAMATIONSTATE_OPEN]: 'Offen',
  [RECLAMATIONSTATE_WAITING_APPOINTMENT]: 'Wartet auf Terminvorschläge',
  [RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT]: 'Wartet auf Terminbestätigung',
  [RECLAMATIONSTATE_ASSIGNED]: 'Zugewiesen',
  [RECLAMATIONSTATE_REPORT_FINISHED]: 'Abgeschlossen',
  [RECLAMATIONSTATE_CLOSED]: 'Erledigt',
  [RECLAMATIONSTATE_SCHEDULED]: 'Terminiert',
};
