import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getList } from '../../Redux/Action/User/TaskAction';
import TaskList from '../../Components/Tasks/TaskList';
import Panel from '../../Components/Panel';
import CreateTaskModal from '../../Components/Tasks/CreateTaskModal';
import Button from '../../Components/Button';

/**
 * TaskOverview()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TaskOverview(props) {
  const { user, dispatchGetList, tasks } = props;
  const [showCreate, setShowCreate] = React.useState(false);

  React.useEffect(() => {
    dispatchGetList(user);
  }, [dispatchGetList, user]);

  return (
    <div>
      <Panel marginBottom={20}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            <h4>Aufgaben</h4>
            <p style={{ marginBottom: 0 }}>
              Hier sehen Sie die Übersicht aller Aufgaben die Sie erstellt haben oder die Ihnen von Mitarbeitern
              Ihres Unternehmens zugewiesen wurden.
            </p>
          </div>
          <div className="d-flex align-items-center">
            <Button onClick={() => setShowCreate(true)}>
              <i className="fas fa-plus" style={{ marginRight: 0 }} />
            </Button>
          </div>
        </div>
      </Panel>
      <TaskList tasks={tasks} user={user} />

      <CreateTaskModal onClose={() => setShowCreate(false)} visible={showCreate} />
    </div>
  );
}

TaskOverview.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object),
  tasks: PropTypes.instanceOf(Object),
};

TaskOverview.defaultProps = {
  user: null,
  tasks: null,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
    tasks: store.auth.tasks,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: (user) => dispatch(getList(user)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(TaskOverview));
