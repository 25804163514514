/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './HolidayRow.css';
import { HOLIDAY_REASON_DISEASE, HOLIDAY_REASON_HOLIDAY, HOLIDAY_REASON_OTHER } from '../../../Library/Types';

/**
 * HolidayRow()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function HolidayRow(props) {
  const {
    item, calendarData, onPress, currentMonth, showSunday, startDay, endDay,
  } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    if (item && calendarData) {
      if (calendarData[item.userId]) {
        setData(calendarData[item.userId]);
      }
    }
  }, [item, calendarData]);

  const renderIcon = (valueData) => {
    if (valueData) {
      switch (valueData.reason) {
        case HOLIDAY_REASON_HOLIDAY: {
          return <i className="fas fa-umbrella-beach" />;
        }
        case HOLIDAY_REASON_DISEASE: {
          return <i className="fas fa-briefcase-medical" />;
        }
        case HOLIDAY_REASON_OTHER: {
          return <i className="fas fa-question-circle" />;
        }
        default:
          return null;
      }
    }
    return null;
  };

  const getBadgeClass = (holidayItem, isCurrentDay) => {
    if (holidayItem) {
      if (!holidayItem.approved) {
        return `IsHolidayPending Day ${(isCurrentDay) ? 'Today' : ''}`;
      }
      return `IsHoliday Day ${(isCurrentDay) ? 'Today' : ''}`;
    }
    return `${(isCurrentDay) ? 'Today' : ''}`;
  };

  const renderCols = () => {
    const resultNodes = [];

    for (let i = startDay; i <= endDay; i += 1) {
      const dateString = moment(currentMonth).format(`${i}.MM.YYYY`);
      const momentDate = moment(dateString, 'D.MM.YYYY');
      const weekDay = momentDate.weekday();
      let holidayItem = null;

      if (data !== null && data[momentDate.format('YYYY-MM-DD')] !== undefined) {
        holidayItem = data[momentDate.format('YYYY-MM-DD')];
      }
      const isCurrentDay = momentDate.isSame(moment(), 'day');

      if (weekDay !== 6 || showSunday) {
        resultNodes.push((
          <td
            tabIndex={-1}
            onKeyPress={() => {}}
            onClick={(holidayItem)
              ? () => onPress(holidayItem)
              : () => onPress({ startDate: momentDate.format('YYYY-MM-DD'), userId: item.userId })}
            className={getBadgeClass(holidayItem, isCurrentDay)}
            key={`${dateString}-${item.userId}`}
          >
            {renderIcon(holidayItem)}
          </td>
        ));
      } else {
        resultNodes.push((
          <td
            className="SundayPlaceholder"
            key={`${dateString}-${item.userId}`}
          />
        ));
      }
    }
    return resultNodes;
  };

  if (calendarData) {
    return (
      <tr className="HolidayRow">
        <td
          tabIndex={-1}
          onKeyPress={() => {}}
          className="Username"
          onClick={() => onPress({ startDate: moment().format('YYYY-MM-DD'), userId: item.userId })}
        >
          {`${item.firstname[0]}. ${item.lastname}`}
        </td>
        {renderCols()}
      </tr>
    );
  }
  return null;
}

HolidayRow.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  calendarData: PropTypes.instanceOf(Object),
  onPress: PropTypes.func.isRequired,
  currentMonth: PropTypes.instanceOf(Object).isRequired,
  showSunday: PropTypes.bool,
  startDay: PropTypes.number,
  endDay: PropTypes.number,
};

HolidayRow.defaultProps = {
  calendarData: null,
  showSunday: false,
  startDay: 1,
  endDay: 31,
};
