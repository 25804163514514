import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Panel from '../../../../Components/Panel';
import TextLink from '../../../../Components/TextLink';
import ImageContainer from '../../../../Components/DeviceList/ImageContainer';
import ImagePreviewOverlay from '../../../../Components/DeviceList/ImagePreviewOverlay';
import {
  OBJECT_SERVICE_TYPE_CHECK,
  OBJECT_SERVICE_TYPE_DELIVERY,
  OBJECT_SERVICE_TYPE_DELIVERY_AND_MONTAGE,
  OBJECT_SERVICE_TYPE_MONTAGE,
  OBJECT_SERVICE_TYPE_RECLAMATION,
} from '../../../../Library/Types';
import ItemModal from './ItemModal';
import { guid } from '../../../../Library/Functions';
import ModalDialog from '../../../../Components/ModalDialog';
import {
  useAccessCanCreateServices,
  useAccessIsServiceEditable,
  useAccessIsServiceItemDeletable,
} from '../../../../Library/AccessChecks';

const DeleteLink = styled.div`
  &:hover {
    color: ${(props) => props.theme.color.primaryRedColor}
  }
`;

const EditLink = styled.div`
  &:hover {
    color: ${(props) => props.theme.color.primaryColor}
  }
`;

/**
 * Item()
 * @param props
 * @returns {*}
 * @constructor
 */
function Item(props) {
  const {
    item, onDelete, disabled, isEditable, onUpdate, onEdit,
  } = props;
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [askDelete, setAskDelete] = React.useState(false);

  const renderDelete = () => {
    if (!disabled) {
      return (
        <DeleteLink
          className="Delete"
          onClick={() => setAskDelete(true)}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
          title="Beanstandung löschen"
        >
          <i className="far fa-trash-alt" style={{ paddingRight: 15 }} />
        </DeleteLink>
      );
    }
    return null;
  };

  const handleIsInStorageStateChange = () => {
    if (item.storageDate) {
      onUpdate({ ...item, storageDate: 'false' });
    } else {
      onUpdate({ ...item, storageDate: 'true' });
    }
  };

  const renderIsInStorage = () => {
    if (!item.deliveryWeek) {
      return null;
    }
    return (
      <div
        onClick={() => handleIsInStorageStateChange()}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
        title="Gerät / Teil ist im Lager"
      >
        <i className="fas fa-cubes" style={{ color: (item.storageDate) ? 'green' : 'grey', paddingRight: 15 }} />
      </div>
    );
  };

  const renderEdit = () => {
    if (!isEditable) {
      return null;
    }
    return (
      <EditLink
        onClick={() => onEdit(item)}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
        title="Bearbeiten"
      >
        <i className="fas fa-pencil-alt primary" style={{ paddingRight: 15 }} />
      </EditLink>
    );
  };

  const renderImageOverlay = () => (
    <ImagePreviewOverlay
      onClose={() => setShowImagePreview(false)}
      visible={showImagePreview}
      documents={item.documents}
    />
  );

  const renderAssemblyTime = () => {
    if (item.assemblyTime && item.assemblyTime > 0) {
      return (
        <div>
          Geschätzte Arbeitszeit:
          <span>{` ${item.assemblyTime} Minuten`}</span>
        </div>
      );
    }
    return null;
  };

  const renderWaterAndElectric = () => {
    if (item.workOptionWater || item.workOptionElectric) {
      const returnData = [];
      if (item.workOptionWater) {
        returnData.push((
          <div key="water">
            <i className="fas fa-info-circle text-info" style={{ marginRight: 6 }} />
            Benötigt erneute Prüfung vom Wasseranschluss
          </div>
        ));
      }
      if (item.workOptionElectric) {
        returnData.push((
          <div key="electric">
            <i className="fas fa-info-circle text-info" style={{ marginRight: 6 }} />
            Benötigt erneute Prüfung von Elektroanschlüssen
          </div>
        ));
      }

      return (
        <div>
          {returnData}
        </div>
      );
    }
    return null;
  };

  const renderAdditionalInfos = () => {
    if ((item.assemblyTime && item.assemblyTime > 0) || item.workOptionWater || item.workOptionElectric) {
      return (
        <div style={{ marginTop: 10, fontSize: 14 }}>
          {renderAssemblyTime()}
          {renderWaterAndElectric()}
        </div>
      );
    }
    return null;
  };

  const fireOnDelete = () => {
    setAskDelete(false);
    onDelete(item);
  };

  return (
    <>
      <div className="Row">
        <ImageContainer documents={item.documents} onClick={() => setShowImagePreview(true)} />
        <div className="Left">
          <div className="Headline">
            {`${item.title} ${(item.number) ? `(${item.number})` : ''}`}
          </div>
          <div>{item.description}</div>
          {renderAdditionalInfos()}
        </div>
        <div className="Right">
          {renderIsInStorage(item)}
          {renderEdit()}
          {renderDelete()}
        </div>
      </div>
      {renderImageOverlay()}

      <ModalDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        onConfirm={fireOnDelete}
        confirmCaption="Löschen"
        centered
      >
        <h5>Löschen bestätigen!</h5>
        <p>
          Bitte bestätigen Sie das Sie den ausgewählten Eintrag
          <span>{` "${item.title}" `}</span>
          löschen möchten.
        </p>
      </ModalDialog>
    </>
  );
}

Item.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

Item.defaultProps = {
  isEditable: false,
};

/**
 * ItemList()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function ItemList(props) {
  const {
    service, onDelete, onAdd, visible, isInCreateDialog, hasError, errorMessage, onUpdate, isInternal,
  } = props;
  const canCreateService = useAccessCanCreateServices();
  const isEditable = useAccessIsServiceEditable(service);
  const isDeletable = useAccessIsServiceItemDeletable(service);

  const [showAddItem, setShowAddItem] = useState(false);
  const [headline, setHeadline] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);

  React.useEffect(() => {
    if (service) {
      if (isInCreateDialog) {
        switch (service.type) {
          case OBJECT_SERVICE_TYPE_RECLAMATION: {
            setHeadline('Beanstandung hinzufügen');
            setDescription(
              'Fügen Sie hier so viele manuelle Beanstandungen hinzu, die alle zum gleichen '
              + 'Termin behoben werden sollen.',
            );
            break;
          }
          case OBJECT_SERVICE_TYPE_DELIVERY_AND_MONTAGE:
          case OBJECT_SERVICE_TYPE_MONTAGE: {
            setHeadline('Nachmontagen hinzufügen');
            setDescription(
              'Fügen Sie hier so viele manuelle Nachmontagen hinzu, die alle zum gleichen Termin '
              + 'behoben werden sollen.',
            );
            break;
          }
          case OBJECT_SERVICE_TYPE_DELIVERY: {
            setHeadline('Nachlieferung hinzufügen');
            setDescription(
              'Fügen Sie hier so viele manuelle Nachlieferungen hinzu, die alle zum gleichen Termin '
              + 'behoben werden sollen.',
            );
            break;
          }
          case OBJECT_SERVICE_TYPE_CHECK: {
            setHeadline('Prüfpunkte hinzufügen');
            setDescription(
              'Fügen Sie hier die zu Prüfende Punkte hinzu, die alle zum gleichen Termin '
              + 'überprüft werden sollen.',
            );
            break;
          }
          default: {
            setHeadline('Item hinzufügen');
            setDescription(
              'Fügen Sie hier so viele manuelle Items hinzu, die alle zum gleichen Termin behoben werden sollen.',
            );
          }
        }
      } else {
        switch (service.type) {
          case OBJECT_SERVICE_TYPE_RECLAMATION: {
            setHeadline('Beanstandungen');
            setDescription('Hier sehen Sie alle Beanstandungen in diesem Serviceauftrag');
            break;
          }
          case OBJECT_SERVICE_TYPE_DELIVERY_AND_MONTAGE:
          case OBJECT_SERVICE_TYPE_MONTAGE: {
            setHeadline('Nachmontagen');
            setDescription('Hier sehen Sie alle Nachmontagen in diesem Serviceauftrag');
            break;
          }
          case OBJECT_SERVICE_TYPE_DELIVERY: {
            setHeadline('Nachlieferung');
            setDescription('Hier sehen Sie alle Nachlieferungen in diesem Serviceauftrag');
            break;
          }
          case OBJECT_SERVICE_TYPE_CHECK: {
            setHeadline('Prüfungen');
            setDescription('Hier sehen Sie alle Prüfungen in diesem Serviceauftrag');
            break;
          }
          default: {
            setHeadline('Items');
            setDescription('Hier sehen Sie alle Items in diesem Serviceauftrag');
          }
        }
      }
    }
  }, [service, isInCreateDialog]);

  const handleItemAdd = (item) => {
    if (onAdd) {
      onAdd({ ...item, internalId: guid() });
    }
    setShowAddItem(false);
  };

  const handleItemUpdate = (item) => {
    onUpdate(item);
    setShowAddItem(false);
  };

  const renderAddLink = () => {
    if ((isEditable || canCreateService) && onAdd) {
      return (
        <TextLink
          caption={headline || ''}
          onPress={() => setShowAddItem(true)}
          icon="fas fa-plus-square"
          style={{ marginTop: 20 }}
        />
      );
    }
    return null;
  };

  const handleEditItem = (item) => {
    setSelectedItem(item);
    setShowAddItem(true);
  };

  const renderContent = () => {
    if (!hasError) {
      return service.items.map((item) => (
        <Item
          item={item}
          onDelete={onDelete}
          onUpdate={onUpdate}
          onEdit={handleEditItem}
          key={item.internalId || item.itemId}
          disabled={!isDeletable || service.items.length === 1}
          isEditable={isEditable}
        />
      ));
    }

    return (
      <div className="alert alert-danger">
        {errorMessage}
      </div>
    );
  };

  if (visible && service) {
    return (
      <Panel marginBottom={20} className="DeviceList">
        <h6>{headline}</h6>
        <p>{description}</p>

        <div className="RowContainer">
          {renderContent()}
        </div>

        {renderAddLink()}

        <ItemModal
          onClose={() => { setSelectedItem(null); setShowAddItem(false); }}
          onSave={handleItemAdd}
          service={service}
          visible={showAddItem}
          editItem={selectedItem}
          onEdit={handleItemUpdate}
          isInternal={isInternal}
        />
      </Panel>
    );
  }
  return null;
}

ItemList.propTypes = {
  service: PropTypes.instanceOf(Object),
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  visible: PropTypes.bool,
  isInCreateDialog: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  isInternal: PropTypes.bool.isRequired,
};

ItemList.defaultProps = {
  service: null,
  onAdd: undefined,
  visible: true,
  isInCreateDialog: true,
  hasError: false,
  errorMessage: '',
};
