import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Redux from 'react-redux';
import { NAVIGATION_SYSTEM_RECLAMATION_DETAILS } from '../../../../../Library/Types';
import ReclamationPage from '../../../Component/ReclamationPage';
import { useDispatchSystemGetClientList } from '../../../Redux/Action/ClientAction';
import { useDispatchGetReclamationView } from '../../../Redux/Action/ReclamationAction';

/**
 * Reclamation()
 * @returns {JSX.Element}
 * @constructor
 */
export default function Reclamation() {
  const history = useHistory();
  const dispatchGetServiceView = useDispatchGetReclamationView();
  const dispatchSystemGetClientList = useDispatchSystemGetClientList();

  const { clients } = Redux.useSelector((state) => state.systemClient);
  const lastUpdated = Redux.useSelector((store) => (store.systemReclamation.lastUpdated));
  const isLoading = Redux.useSelector((store) => (store.systemReclamation.isLoading));

  React.useEffect(() => {
    if (!clients || clients.length < 0) {
      dispatchSystemGetClientList().then();
    }
  }, [clients, dispatchSystemGetClientList]);

  const getHeadline = () => 'Reklamationen';

  const navigateDetails = (reclamation) => {
    const uri = NAVIGATION_SYSTEM_RECLAMATION_DETAILS.replace('{orderId}', reclamation.orderId)
      .replace('{objectId}', reclamation.objectId)
      .replace('{reclamationId}', reclamation.reclamationId);
    history.push(uri);
  };

  return (
    <ReclamationPage
      lastUpdated={lastUpdated}
      isLoading={isLoading}
      getHeadline={getHeadline}
      onClick={navigateDetails}
      dispatchGetObjects={dispatchGetServiceView}
    />
  );
}
