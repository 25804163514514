import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ORDER_TYPE_OBJECT, ORDER_TYPE_SINGLE, orderTypeOptions } from '../../../Library/Types/OrderTypes';
import SelectPanel from '../../SelectPanels';
import Input from '../../Input';
import HelpDialog from '../../HelpDialog';
import { initialObject } from '../../../Pages/Order/Create/Components/OrderObjectPanel';

export const initialOrderType = {
  type: ORDER_TYPE_SINGLE,
  externalId: null,
};

export default function SelectOrderType(props) {
  const {
    state, onChange, headline,
  } = props;

  const [showHelp, setShowHelp] = useState(false);

  const handleTypeChange = (type) => {
    if (onChange) {
      if (type === ORDER_TYPE_OBJECT) {
        onChange({ ...state, type, objects: [] });
      } else {
        onChange({
          ...state, type, externalId: null, objects: initialObject,
        });
      }
    }
  };

  const handleExternalIdChange = (externalId) => {
    if (onChange) {
      onChange({ ...state, externalId });
    }
  };

  const renderExternalId = () => (
    (state.type === ORDER_TYPE_OBJECT) ? (
      <div style={{ marginTop: 10 }}>
        <Input
          value={state.externalId}
          onChange={handleExternalIdChange}
          label="Auftragsbezeichnung"
          placeholder="Maximal 20 Zeichen"
          autoFocus
          maxLength={20}
          showHelpButton
          onHelpPress={() => setShowHelp(true)}
        />
      </div>
    ) : null);

  return (
    <>
      <h5>{headline}</h5>
      <p className="text-muted">
        Wählen Sie die Art Ihres Auftrags
      </p>
      <SelectPanel
        data={orderTypeOptions}
        selected={state.type}
        isMultiSelect
        returnSingleKey
        onChange={handleTypeChange}
      />
      {renderExternalId()}
      <HelpDialog context="objectExternalId" visible={showHelp} onClose={() => setShowHelp(false)} />
    </>
  );
}

SelectOrderType.propTypes = {
  state: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  headline: PropTypes.string,
};

SelectOrderType.defaultProps = {
  state: initialOrderType,
  onChange: undefined,
  headline: 'Auftragsart',
};
