import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import FileSaver from 'file-saver';
import { downloadImages, get as getReport, getPdf } from '../../../Redux/Action/Order/Object/Service/ReportAction';
import { get as getOrder } from '../../../Redux/Action/OrderAction';
import Panel from '../../../Components/Panel';
import DeviceList from '../../../Components/DeviceList';
import { hasServiceAccess } from '../../../Library/Functions';
import Button from '../../../Components/Button';
import LoadingModal from '../../../Components/LoadingModal';
import CompletedImages from '../../../Components/ReportComponents/CompletedImages';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import Overview from './Component/ReportOverview';
import CustomerBalance from './Component/CustomerBalance';
import UsedMaterial from '../../../Components/UsedMaterial';
import {
  CLIENTTYPE_MONTEUR, DEVICETYPE_ADDITIONALWORK,
  DEVICETYPE_DAMAGE, DEVICETYPE_PREDAMAGE,
  DEVICETYPE_RECLAMATION, DEVICETYPE_RETURN, DEVICETYPE_SERVICE,
  NAVIGATION_OBJECT_DETAILS, objectServiceTypeCaptions,
} from '../../../Library/Types';
import Information from './Component/Information';
import StatusInProgress from '../../../Components/ReportComponents/StatusInProgress';
import InventoryImages from '../../../Components/ReportComponents/InventoryImages';
import SignaturesBox from '../../../Components/ReportComponents/SignaturesBox';
import { get } from '../../../Redux/Action/Order/Object/ServiceAction';

/**
 * Report()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Report(props) {
  const {
    report, dispatchGetReport, order, dispatchGetOrder, teams, client, service, dispatchGetPdf,
    history, dispatchGetService, dispatchDownloadImages, isAdmin, isSystemView,
  } = props;

  const [customer, setCustomer] = useState();
  const [users, setUsers] = useState();
  const [services, setServices] = useState([]);
  const [reclamations, setReclamations] = useState([]);
  const [damages, setDamages] = useState([]);
  const [preDamages, setPreDamages] = useState([]);
  const [additionalWork, setAdditionalWork] = useState([]);
  const [returns, setReturns] = useState([]);
  const { serviceId, orderId, objectId } = useParams();
  const [object, setObject] = useState();
  const [downloadingImages, setDownloadingImages] = useState(false);
  const [reportDownloading, setReportDownloading] = useState(false);
  const [visible, setVisible] = React.useState(false);

  const loadData = React.useCallback(() => {
    if (service) {
      dispatchGetOrder(orderId).then((orderResponse) => dispatchGetReport(service).then((reportData) => {
        const selectedObject = orderResponse.objects.filter((item) => item.objectId === reportData.objectId)[0];
        if (selectedObject) {
          setCustomer(selectedObject.customer);
          setObject(selectedObject);
          setUsers(service.users);
        }

        setServices(reportData.items.filter((item) => item.type === DEVICETYPE_SERVICE));
        setReclamations(reportData.items.filter((item) => item.type === DEVICETYPE_RECLAMATION));
        setDamages(reportData.items.filter((item) => item.type === DEVICETYPE_DAMAGE));
        setPreDamages(reportData.items.filter((item) => item.type === DEVICETYPE_PREDAMAGE));
        setReturns(reportData.items.filter((item) => item.type === DEVICETYPE_RETURN));
        setAdditionalWork(reportData.items.filter((item) => item.type === DEVICETYPE_ADDITIONALWORK));
      })).catch(() => {
        const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', orderId).replace('{objectId}', objectId);
        history.push(uri);
      });
    }
  }, [dispatchGetReport, dispatchGetOrder, orderId, objectId, history, service]);

  React.useEffect(() => {
    dispatchGetService(serviceId);
  }, [serviceId, dispatchGetService]);

  React.useEffect(() => {
    if (service) {
      loadData();
    }
  }, [service, loadData]);

  React.useEffect(() => {
    if (order && order.orderId && service && service.serviceId) {
      if (hasServiceAccess(client, order, service) || isAdmin || isSystemView) {
        setVisible(true);
      } else {
        history.push('/dashboard');
      }
    }
  }, [order, history, service, client, isAdmin, isSystemView]);

  useEffect(() => {
    if (object && teams && users && users.length === 0) {
      if (object.teamId) {
        const team = teams.filter((teamData) => teamData.teamId === object.teamId)[0];
        if (team) {
          setUsers(team.users);
        }
      }
    }
  }, [object, users, teams]);

  const handleDownload = () => {
    setReportDownloading(true);
    dispatchGetPdf(service, report).then((data) => {
      setReportDownloading(false);
      FileSaver.saveAs(data, `Servicebericht_${serviceId}.pdf`);
    }).catch(() => setReportDownloading(false));
  };

  const handleDownloadImages = () => {
    setDownloadingImages(true);
    dispatchDownloadImages(service, report).then((data) => {
      FileSaver.saveAs(data, `ServiceberichtAlleBilder${serviceId}.zip`);
      setDownloadingImages(false);
    }).catch(() => setDownloadingImages(false));
  };

  const renderDownloadButton = () => {
    if (report.finished) {
      return (
        <div className="d-flex flex-row" style={{ textAlign: 'right' }}>
          <Button onClick={() => handleDownloadImages()} style={{ marginRight: 8 }}>
            <i className="fas fa-cloud-download-alt" />
            Alle Bilder
          </Button>
          <Button onClick={() => handleDownload()}>
            <i className="fas fa-cloud-download-alt" />
            Bericht als PDF
          </Button>
        </div>
      );
    }
    return null;
  };

  if (report && order && customer && users && service && object && visible) {
    return (
      <div>
        <Panel marginBottom={25}>
          <div className="d-flex">
            <div className="flex-grow-1 d-flex align-items-center align-content-center">
              <h4 className="PrimaryHeadline" style={{ marginBottom: 0 }}>
                Servicebericht
              </h4>
            </div>
            {renderDownloadButton()}
          </div>
        </Panel>

        <StatusInProgress report={report} />

        <div className="row">
          <div className={`d-flex ${(report.customerPaidValue > 0 && report.finished) ? 'col-lg-8' : 'col-lg-12'}`}>
            <Overview report={report} object={object} service={service} />
          </div>

          <CustomerBalance report={report} />
        </div>

        <div className="row">
          <div className="col-lg-6 d-flex">
            <Panel marginBottom={25}>
              <h6>Kundendaten</h6>
              <p>
                Hier sehen Sie die Kontaktdaten des Kunden
              </p>

              <KeyValueEditRow title="Name" type="text" displayText={`${customer.firstname} ${customer.lastname}`} />
              <KeyValueEditRow
                title="Adresse"
                type="text"
                displayText={`${customer.street} ${customer.streetNo}, ${customer.zip} ${customer.city}`}
              />
              <KeyValueEditRow title="E-Mail Adresse" type="text" displayText={customer.mail} />
              <KeyValueEditRow title="Telefon" type="text" displayText={customer.phone} />
              <KeyValueEditRow title="Mobil" type="text" displayText={customer.mobile} />
              <KeyValueEditRow
                title="Kommentar"
                type="text"
                displayText={customer.comment}
                visible={customer.comment !== null && customer.comment.length > 0}
              />
            </Panel>
          </div>
          <div className="col-lg-6 d-flex">
            <Information report={report} teams={teams} service={service} order={order} client={client} />
          </div>
        </div>

        <InventoryImages images={report.inventoryImages} />

        <div className="row">
          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={preDamages}
            caption="Dokumentierte Vorschäden"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={services.map((item) => ({ ...item, description: item.comment || 'erledigt' }))}
            caption={`Bearbeitete ${objectServiceTypeCaptions[service.type]}`}
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={reclamations}
            caption="Neue Beanstandungen"
            isMonteur={(client.type === CLIENTTYPE_MONTEUR)}
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={damages}
            caption="Beschädigungen / Versicherungsfall"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={additionalWork}
            caption="ZUsatzarbeiten"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={returns}
            caption="Retouren"
          />

          <div className="col-lg-12">
            <UsedMaterial materials={report.materials} />
          </div>
        </div>

        <CompletedImages
          images={report.completedImages}
          headline="Bilder des ausgeführten Serviceauftrags"
          subHeadline="Hier sehen Sie die Bilder des fertig ausgeführten Serviceauftrags"
        />

        <SignaturesBox
          customer={customer}
          user={(users) ? users[0] : null}
          customerSignaturePath={(report.signatureCustomer.absolutePath) || null}
          monteurSignaturePath={(report.signatureMonteur.absolutePath) || null}
        />

        <LoadingModal
          visible={reportDownloading}
          headline="Servicebericht wird erstellt"
          text="Der Servicebericht wird im Anschluss automatisch heruntergeladen!"
        />

        <LoadingModal
          visible={downloadingImages}
          headline="Bilder werden komprimiert..."
          text="Der Download wird im Anschluss automatisch gestartet!"
        />

      </div>
    );
  }
  return <LoadingModal visible text="Servicebericht wird geladen..." />;
}

Report.propTypes = {
  report: PropTypes.instanceOf(Object),
  order: PropTypes.instanceOf(Object),
  dispatchGetReport: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchGetService: PropTypes.func.isRequired,
  dispatchGetPdf: PropTypes.func.isRequired,
  dispatchDownloadImages: PropTypes.func.isRequired,
  teams: PropTypes.instanceOf(Array),
  client: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  service: PropTypes.instanceOf(Object),
  isAdmin: PropTypes.bool,
  isSystemView: PropTypes.bool,
};

Report.defaultProps = {
  report: null,
  order: null,
  teams: null,
  isAdmin: false,
  service: null,
  isSystemView: false,
};

function mapStoreToProps(store) {
  return {
    report: store.reports.objectServiceReport,
    service: store.objectService.service,
    order: store.order.order,
    teams: store.teams.teams,
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetOrder: (orderId) => dispatch(getOrder(orderId)),
    dispatchGetReport: (service) => dispatch(getReport(service)),
    dispatchGetService: (serviceId) => dispatch(get(serviceId)),
    dispatchGetPdf: (service, report) => dispatch(getPdf(service, report)),
    dispatchDownloadImages: (service, report) => dispatch(downloadImages(service, report)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Report));
