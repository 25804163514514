import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useRouteMatch, withRouter } from 'react-router';
import Button from '../../../../Components/Button';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import MarkClosedDialog from './MarkClosedDialog';
import {
  deleteService,
  useDispatchSendCustomerMail,
  useDispatchUpdateService,
} from '../../../../Redux/Action/Order/Object/ServiceAction';
import {
  NAVIGATION_OBJECT_DETAILS,

} from '../../../../Library/Types';
import { formatDateTime } from '../../../../Library/Functions';
import AskSendCustomerMailDialog from './AskSendCustomerMailDialog';
import {
  SERVICESTATE_ASSIGNED,
  SERVICESTATE_CLOSED,
  SERVICESTATE_REPORT_FINISHED,
  SERVICESTATE_SCHEDULED,
} from '../../../../Library/States/ServiceStates';
import { useAccessClientIsOwner, useAccessClientIsOwnerOrAssigned } from '../../../../Library/AccessChecks';

/**
 * DetailsActionButtons()
 * @param props
 * @returns {*}
 * @constructor
 */
function DetailsActionButtons(props) {
  const {
    order, object, service, dispatchDelete, history, isSystemView,
  } = props;

  const dispatchUpdateService = useDispatchUpdateService();
  const dispatchSendCustomerMail = useDispatchSendCustomerMail();

  const [showDelete, setShowDelete] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [showAskSendMail, setShowAskSendMail] = useState(false);
  const { url } = useRouteMatch();
  const isOwner = useAccessClientIsOwner(order);
  const isOwnerOrAssigned = useAccessClientIsOwnerOrAssigned(order, service);

  const handleDelete = () => {
    dispatchDelete(service);
    const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', service.orderId)
      .replace('{objectId}', service.objectId);
    history.push(uri);
  };

  const handleMarkClosed = (closedComment) => {
    dispatchUpdateService({ ...service, state: SERVICESTATE_CLOSED, closedComment });
    setShowClosed(false);
  };

  const handleOnSendMail = () => {
    setShowAskSendMail(false);
    dispatchSendCustomerMail(service.serviceId).then(() => {

    }).catch(() => {

    });
  };

  const renderMailIcon = () => {
    if (service.state === SERVICESTATE_SCHEDULED || service.state === SERVICESTATE_ASSIGNED) {
      if (!service.sendCustomerMail) {
        return (
          <Button
            onClick={() => setShowAskSendMail(true)}
            type="btn-primary"
            style={{ marginLeft: 10 }}
          >
            <i className="fas fa-envelope" style={{ margin: 0 }} />
          </Button>
        );
      }
      return (
        <Button
          onClick={() => setShowAskSendMail(true)}
          type="btn-success"
          title={formatDateTime(service.sendCustomerMailDate)}
          style={{ marginLeft: 10 }}
        >
          <i className="fas fa-envelope-open" style={{ margin: 0 }} />
        </Button>
      );
    }
    return null;
  };

  if (service.state === SERVICESTATE_CLOSED) {
    return null;
  }

  if (!isOwner && service.state !== SERVICESTATE_REPORT_FINISHED && !isSystemView) {
    return null;
  }

  if (!isOwnerOrAssigned && !isSystemView) {
    return null;
  }

  if (service) {
    if (service.state === SERVICESTATE_REPORT_FINISHED) {
      return (
        <Button
          onClick={() => history.push(`${url}/report`)}
          title="Servicebericht anzeigen"
        >
          Servicebericht
        </Button>
      );
    }

    return (
      <>
        <Button
          onClick={() => setShowClosed(true)}
          type="btn-success"
          style={{ marginLeft: 10 }}
          title="Serviceauftrag als erledigt markieren"
        >
          <i className="fas fa-check-circle" style={{ margin: 0 }} />
        </Button>
        {renderMailIcon()}
        <Button
          onClick={() => setShowDelete(true)}
          type="btn-danger"
          style={{ marginLeft: 10 }}
          title="Serviceauftrag löschen"
        >
          <i className="fas fa-trash" style={{ margin: 0 }} />
        </Button>

        <ConfirmDeleteDialog
          onClose={() => setShowDelete(false)}
          onConfirm={handleDelete}
          visible={showDelete}
        />

        <MarkClosedDialog
          onClose={() => setShowClosed(false)}
          onSave={handleMarkClosed}
          visible={showClosed}
        />

        <AskSendCustomerMailDialog
          onClose={() => setShowAskSendMail(false)}
          onConfirm={handleOnSendMail}
          visible={showAskSendMail}
          validMail={(object && object.customer.mail)}
        />
      </>
    );
  }
  return null;
}

DetailsActionButtons.propTypes = {
  order: PropTypes.instanceOf(Object),
  object: PropTypes.instanceOf(Object),
  service: PropTypes.instanceOf(Object),
  dispatchDelete: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  isSystemView: PropTypes.bool,
};

DetailsActionButtons.defaultProps = {
  order: null,
  object: null,
  service: null,
  isSystemView: false,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDelete: (service) => dispatch(deleteService(service)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(DetailsActionButtons));
