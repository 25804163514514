import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Button from '../../Button';

/**
 * AddButtonMonthSelector()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AddButtonMonthSelector(props) {
  const { onClick, caption, type } = props;

  const renderContent = () => {
    if (caption) {
      return (
        <>
          <i className="fas fa-plus" />
          {caption}
        </>
      );
    }
    return <i className="fas fa-plus" style={{ margin: 0 }} />;
  };

  return (
    <>
      <Button
        onClick={() => onClick()}
        type={type}
      >
        {renderContent()}
      </Button>
    </>
  );
}

AddButtonMonthSelector.propTypes = {
  onClick: PropTypes.func.isRequired,
  caption: PropTypes.string,
  type: PropTypes.string,
};

AddButtonMonthSelector.defaultProps = {
  caption: null,
  type: 'btn-primary',
};
