import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;

  >.ContentContainer {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
`;

/**
 * DefaultLayout
 * @param props
 * @returns {*}
 * @constructor
 */
export default function DefaultLayout(props) {
  const { children, navigation } = props;

  const renderNavigation = () => {
    if (navigation) {
      return navigation;
    }
    return null;
  };

  return (
    <Container>
      {renderNavigation()}
      <div className="ContentContainer">
        {children}
      </div>
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navigation: PropTypes.node,
};

DefaultLayout.defaultProps = {
  navigation: null,
};
