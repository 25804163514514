import axios from '../../../../../Library/Axios';
import { defaultUri as serviceDefaultUri } from '../ServiceAction';

/**
 * Prepare default uri and replace all placeholders
 * @param service
 * @returns {string}
 */
function prepareUrl(service) {
  const defaultUri = `${serviceDefaultUri}/{serviceId}/report`;
  return defaultUri.replace('{orderId}', service.orderId)
    .replace('{objectId}', service.objectId)
    .replace('{serviceId}', service.serviceId);
}

export const OBJECT_SERVICE_REPORT_GET_START = 'OBJECT_SERVICE_REPORT_GET_START';
export const OBJECT_SERVICE_REPORT_GET_SUCCESS = 'OBJECT_SERVICE_REPORT_GET_SUCCESS';
export const OBJECT_SERVICE_REPORT_GET_ERROR = 'OBJECT_SERVICE_REPORT_GET_ERROR';

/**
 * get()
 * @param service
 * @returns {function(*): *}
 */
export function get(service) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_REPORT_GET_START, payload: service });

    return axios().get(prepareUrl(service)).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SERVICE_REPORT_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_REPORT_GET_ERROR, payload: error });
      return Promise.resolve(error);
    });
  };
}

export const OBJECT_SERVICE_REPORT_GET_PDF_START = 'OBJECT_SERVICE_REPORT_GET_PDF_START';
export const OBJECT_SERVICE_REPORT_GET_PDF_SUCCESS = 'OBJECT_SERVICE_REPORT_GET_PDF_SUCCESS';
export const OBJECT_SERVICE_REPORT_GET_PDF_ERROR = 'OBJECT_SERVICE_REPORT_GET_PDF_ERROR';

/**
 * getPdf()
 * @param service
 * @param report
 * @param withImages
 * @returns {function(*): *}
 */
export function getPdf(service, report, withImages = false) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_REPORT_GET_PDF_START });

    const config = {
      params: { action: 'pdf', withImages },
      responseType: 'arraybuffer',
    };
    const preparedUri = prepareUrl(service);
    const uri = `${preparedUri}/${report.serviceReportId}`;

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/pdf' },
      );
      dispatch({ type: OBJECT_SERVICE_REPORT_GET_PDF_SUCCESS, payload: data });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_REPORT_GET_PDF_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_SERVICE_REPORT_DOWNLOAD_IMAGES_START = 'OBJECT_SERVICE_REPORT_DOWNLOAD_IMAGES_START';
export const OBJECT_SERVICE_REPORT_DOWNLOAD_IMAGES_SUCCESS = 'OBJECT_SERVICE_REPORT_DOWNLOAD_IMAGES_SUCCESS';
export const OBJECT_SERVICE_REPORT_DOWNLOAD_IMAGES_ERROR = 'OBJECT_SERVICE_REPORT_DOWNLOAD_IMAGES_ERROR';

/**
 * downloadImages()
 * @param service
 * @param report
 * @returns {function(*): *}
 */
export function downloadImages(service, report) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SERVICE_REPORT_DOWNLOAD_IMAGES_START });

    const config = {
      params: { action: 'downloadImages' },
      responseType: 'arraybuffer',
    };
    const preparedUri = prepareUrl(service);
    const uri = `${preparedUri}/${report.serviceReportId}`;

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/zip' },
      );
      dispatch({ type: OBJECT_SERVICE_REPORT_DOWNLOAD_IMAGES_SUCCESS, payload: data });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: OBJECT_SERVICE_REPORT_DOWNLOAD_IMAGES_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
