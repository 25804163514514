import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { useParams, withRouter } from 'react-router';
import { connect } from 'react-redux';
import Panel from '../../../Components/Panel';
import Button from '../../../Components/Button';
import CreateTaskModal from '../../../Components/Tasks/CreateTaskModal';
import { getList } from '../../../Redux/Action/User/TaskAction';
import TaskList from '../../../Components/Tasks/TaskList';
import { get } from '../../../Redux/Action/OrderAction';
import TextLink from '../../../Components/TextLink';

/**
 * TaskOverview()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TaskOverview(props) {
  const {
    tasks, dispatchGetList, user, order, dispatchGetOrder, history,
  } = props;
  const { orderId, objectId } = useParams();
  const [showCreate, setShowCreate] = React.useState(false);
  const [object, setObject] = React.useState(false);
  const [filteredTasks, setFilteredTasks] = React.useState(false);

  React.useEffect(() => {
    dispatchGetList(user);
    dispatchGetOrder(orderId);
  }, [dispatchGetList, user, dispatchGetOrder, orderId]);

  React.useEffect(() => {
    if (order) {
      const obj = order.objects.filter((item) => item.objectId === parseInt(objectId, 0))[0];
      setObject(obj);
    }
  }, [objectId, order]);

  React.useEffect(() => {
    if (tasks) {
      const filter = tasks.filter((item) => item.objectId === parseInt(objectId, 0));
      setFilteredTasks(filter);
    }
  }, [objectId, tasks]);

  return (
    <>
      <Panel marginBottom={20}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            <h4>Ihre Aufgaben zum Auftrag</h4>
            <p style={{ marginBottom: 0 }}>
              Hier sehen Sie eine Übersicht aller Auftragsbezogenen Aufgaben die Sie erstellt haben oder die Ihnen
              von Mitarbeitern Ihres Unternehmens zugewiesen wurden.
              <br />
              Eine Übersicht aller Ihrer Aufgaben finden Sie in Ihrem Profil unter
              <TextLink caption="Aufgaben" onPress={() => history.push('/profile/task')} style={{ marginLeft: 6 }} />
            </p>
          </div>
          <div className="d-flex align-items-center">
            <Button onClick={() => setShowCreate(true)}>
              <i className="fas fa-plus" style={{ marginRight: 0 }} />
            </Button>
          </div>
        </div>
      </Panel>

      <TaskList
        tasks={filteredTasks}
        user={user}
        emptyHint="Sie haben keine auftragsbezogenen Aufgaben in Ihrer Liste"
        showObjectExternalId={false}
      />

      <CreateTaskModal onClose={() => setShowCreate(false)} visible={showCreate} object={object} />
    </>
  );
}

TaskOverview.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object),
  tasks: PropTypes.instanceOf(Object),
  order: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

TaskOverview.defaultProps = {
  user: null,
  tasks: null,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
    tasks: store.auth.tasks,
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: (user) => dispatch(getList(user)),
    dispatchGetOrder: (orderId) => dispatch(get(orderId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(TaskOverview));
