import axios from '../../../../Library/Axios';

const defaultUri = '/system/clearing';

export const SYSTEM_CLEARING_GETLIST_START = 'SYSTEM_CLEARING_GETLIST_START';
export const SYSTEM_CLEARING_GETLIST_SUCCESS = 'SYSTEM_CLEARING_GETLIST_SUCCESS';
export const SYSTEM_CLEARING_GETLIST_ERROR = 'SYSTEM_CLEARING_GETLIST_ERROR';

/**
 * getList()
 * @returns {function(*): Promise<unknown>}
 */
export function getList(month, year) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLEARING_GETLIST_START });

    const config = {
      params: { month, year },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLEARING_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLEARING_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_CLEARING_EXECUTE_START = 'SYSTEM_CLEARING_EXECUTE_START';
export const SYSTEM_CLEARING_EXECUTE_SUCCESS = 'SYSTEM_CLEARING_EXECUTE_SUCCESS';
export const SYSTEM_CLEARING_EXECUTE_ERROR = 'SYSTEM_CLEARING_EXECUTE_ERROR';

/**
 * execute()
 * @param item
 * @returns {function(*): *}
 */
export function execute(item) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLEARING_EXECUTE_START });

    return axios().post(defaultUri, item).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLEARING_EXECUTE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLEARING_EXECUTE_ERROR, payload: error.data });
      return Promise.reject(error.data);
    });
  };
}
