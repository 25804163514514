import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Redux from 'react-redux';
import { darken } from 'polished';

const Icon = styled.i`
  margin-right: 15px;

  &.display {
    opacity: 1.0;
  }

  &:hover {
    opacity: 1.0;
  }
  
  &:last-child {
    margin-right: 0;
  }
`;

const DangerIcon = styled(Icon)`
  &:hover {
    color: ${(props) => props.theme.color.primaryRedColor};
  }  
`;

const SelectIcon = styled(Icon)`
  margin-right: 15px;
  font-size: xx-large;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Container = styled.div`
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.color.grayBackgroundColor};
  cursor: pointer;
  border-radius: ${(props) => props.theme.border.radius};
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  ${Icon} {
    flex-shrink: 1;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  ${SelectIcon}.fa-check-square {
    display: none;
  }
  ${SelectIcon}.fa-square {
    display: block;
  }
  
  &:hover {
    ${SelectIcon}.fa-check-square {
      display: block;
    }
    ${SelectIcon}.fa-square {
      display: none;
    }
    
    background-color: ${(props) => darken(0.05, props.theme.color.grayBackgroundColor)};
  }
`;

/**
 * Item()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Item(props) {
  const {
    children, selectable, onClick, onSelect, onEdit, onDelete,
  } = props;

  const { clientId } = Redux.useSelector((state) => state.client.client);
  const dealerClientId = Redux.useSelector((state) => state.order.order.clientId);
  const isDealer = () => clientId === dealerClientId;

  const handleOnClick = (event) => {
    event.stopPropagation();
    if (onClick) {
      onClick();
    } else if (selectable && onSelect) {
      onSelect();
    } else if (onEdit) {
      onEdit();
    }
  };

  const handleOnEdit = (event) => {
    event.stopPropagation();
    if (onEdit) {
      onEdit();
    }
  };

  const handleOnDelete = (event) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };

  const renderSelector = () => {
    if (isDealer()) {
      return (
        <>
          <SelectIcon className="far fa-square" />
          <SelectIcon className="far fa-check-square" />
        </>
      );
    }
    return (
      <SelectIcon className="far fa-calendar-plus" />
    );
  };

  const renderEdit = () => (onEdit ? (
    <Icon
      onClick={handleOnEdit}
      className={`fas fa-pencil-alt${(onClick || (selectable && onSelect)) ? '' : ' main'}`}
    />
  ) : null);

  const renderDelete = () => (onDelete ? (<DangerIcon onClick={handleOnDelete} className="fas fa-trash" />) : null);

  return (
    <Container onClick={handleOnClick}>
      {renderSelector()}
      <Content>
        {children}
      </Content>
      {renderEdit()}
      {renderDelete()}
    </Container>
  );
}

Item.propTypes = {
  children: PropTypes.node.isRequired,
  selectable: PropTypes.bool,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

Item.defaultProps = {
  selectable: false,
  onClick: undefined,
  onSelect: undefined,
  onEdit: undefined,
  onDelete: undefined,
};
