import React from 'react';
import InfoBox from '../InfoBox';

import './style.css';

const softwareArrow = require('../../../../Assets/Images/pfeil_gelb.png');
const screenshot01 = require('../../../../Assets/Images/Backend/hey-kitchen-backend-orders.png');

/**
 * MonteurAdvantages()
 * @returns {*}
 * @constructor
 */
export default function MonteurAdvantages() {
  return (
    <div
      className="OurHeyKitchen FullPageBox Light Center"
      style={{ backgroundImage: `url(${softwareArrow})` }}
    >
      <div className="container d-flex flex-column">
        <h2 className="hk-text-primary" style={{ textAlign: 'center' }}>
          Ihr Vorteile als Monteur
        </h2>
        <p style={{ color: '#000' }}>
          Unsere Webanwendung in Kombination mit der Montage App bildet den gesamten Küchenprozess digital ab.
          <br />
          In der Webanwendung suchen Sie bequem nach neuen Aufträgen. Auf dem Smartphone haben sie alle anstehenden
          <br />
          Aufträge, für die Montageberichte erstellt werden müssen.
        </p>
        <img src={screenshot01} alt="HK Screenshot" style={{ width: '100%', marginTop: 60 }} />

        <div className="Listing" style={{ marginTop: 50 }}>
          <InfoBox headline="Warum hey.kitchen" icon="fas fa-truck" iconColor="#e7184e" withBox>
            hey.kitchen vereint jahrelange Erfahrung für Küchenmontage mit Informationstechnologie. Wir kennen
            Ihr Tagesgeschäft und digitalisieren Ihre Prozesse. So haben Sie mehr Zeit und machen mehr Gewinn!
          </InfoBox>
          <InfoBox headline="Mehr Aufträge" icon="fas fa-tasks" iconColor="#e7184e" withBox>
            Wir benachrichtigen Sie automatisch, sobald neue Aufträge verfügbar sind. Wählen Sie einfach die für
            Ihr Unternehmen, Einsatzgebiet und Ihre Kapazität passenden Aufträge aus und los geht´s. 100% kostenlos!
          </InfoBox>
          <InfoBox headline="Mehr Ordnung" icon="fas fa-sort-amount-up" iconColor="#e7184e" withBox>
            Die Hey.kitchen Montage-App zur Abnahme der Küchenmontage funktioniert ganz einfach und ganz ohne
            Papierkram! Wir übernehmen Kalkulation, Rechnungsstellung, inkasso und lästige Bürokratie.
          </InfoBox>
          <InfoBox headline="Mehr Gewinn" icon="fas fa-euro-sign" iconColor="#e7184e" withBox>
            Schluss mit unbezahlten Kundendiensten und Verwaltungsaufwand. Jede Reklamation bzw. jeder Service
            wird Ihnen vergütet. Außerdem keine neuen Kunden suchen, keine lästigen Angebote und Rechnungen mehr
            schreiben.
          </InfoBox>
        </div>
      </div>
    </div>
  );
}
