import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalDialog from '../../../Components/ModalDialog';
import { update } from '../../../Redux/Action/ClientAddressAction';
import Validator from '../../../Library/Validator/CreateAddressValidator';
import HelpDialog from '../../../Components/HelpDialog';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import { CLIENTTYPE_MONTEUR } from '../../../Library/Types';
import ShowHideContainer from '../../../Components/ShowHideContainer';

const initialState = {
  type: 'storageaddress',
  name: '',
  zip: '',
  city: '',
  street: '',
  streetNo: '',
  contactName: '',
  contactPhone: '',
  comment: '',
  radius: 150,
};

function reducer(state, action) {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload };
    case 'zip':
      return { ...state, zip: action.payload };
    case 'city':
      return { ...state, city: action.payload };
    case 'street':
      return { ...state, street: action.payload };
    case 'streetNo':
      return { ...state, streetNo: action.payload };
    case 'contactName':
      return { ...state, contactName: action.payload };
    case 'contactPhone':
      return { ...state, contactPhone: action.payload };
    case 'comment':
      return { ...state, comment: action.payload };
    case 'radius':
      return { ...state, radius: action.payload };
    case 'reset':
      return { ...initialState };
    case 'init':
      return { ...action.payload };

    default:
      throw new Error();
  }
}

/**
 * Update()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Update(props) {
  const {
    visible, onClose, onChange, initialData, dispatchUpdate, client,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errors, setErrors] = useState({});
  const [showHelp, setShowHelp] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = React.useState(null);

  React.useEffect(() => {
    if (initialData) {
      dispatch({ type: 'init', payload: initialData });
    }
  }, [initialData]);

  const save = () => {
    const validator = new Validator();

    if (validator.isValid(state)) {
      // eslint-disable-next-line react/prop-types
      dispatchUpdate(state).then(() => {
        onChange();
        dispatch({ type: 'reset' });
      });
    } else {
      setErrors(validator.getMessages());
    }
  };

  const onCancel = () => {
    setErrors({});
    dispatch({ type: 'reset' });
    onClose();
  };

  return (
    <ModalDialog onClose={() => onCancel()} onConfirm={() => save()} visible={visible}>
      <div className="row">
        <div className="col-sm-12">
          <h5 className="hk-text-primary">
            Standort oder Lageradresse bearbeiten
          </h5>

          <hr />
          <div role="tablist">
            <KeyValueEditRow
              title="Name / Bezeichnung"
              initialValue={state.name}
              displayText={state.name}
              onChange={(value) => dispatch({ type: 'name', payload: value })}
              containerTabIndex={1}
              startEditMode={(currentTabIndex === 1)}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
              hasError={!!(errors.name)}
            />
            <div className="row">
              <div className="col-lg-8">
                <KeyValueEditRow
                  title="Strasse"
                  initialValue={state.street}
                  displayText={state.street}
                  onChange={(value) => dispatch({ type: 'street', payload: value })}
                  containerTabIndex={2}
                  startEditMode={(currentTabIndex === 2)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.street)}
                />
              </div>
              <div className="col-lg-4">
                <KeyValueEditRow
                  title="Hausnummer"
                  initialValue={state.streetNo}
                  displayText={state.streetNo}
                  onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
                  containerTabIndex={3}
                  startEditMode={(currentTabIndex === 3)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.streetNo)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <KeyValueEditRow
                  title="Postleitzahl"
                  initialValue={state.zip}
                  displayText={state.zip}
                  onChange={(value) => dispatch({ type: 'zip', payload: value })}
                  containerTabIndex={4}
                  startEditMode={(currentTabIndex === 4)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.zip)}
                />
              </div>
              <div className="col-lg-8">
                <KeyValueEditRow
                  title="Ort"
                  initialValue={state.city}
                  displayText={state.city}
                  onChange={(value) => dispatch({ type: 'city', payload: value })}
                  containerTabIndex={5}
                  startEditMode={(currentTabIndex === 5)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.city)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Kontakt Person"
                  initialValue={state.contactName}
                  displayText={state.contactName}
                  onChange={(value) => dispatch({ type: 'contactName', payload: value })}
                  containerTabIndex={6}
                  startEditMode={(currentTabIndex === 6)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.contactName)}
                />
              </div>
              <div className="col-lg-6">
                <KeyValueEditRow
                  title="Kontakt Telefon"
                  initialValue={state.contactPhone}
                  displayText={state.contactPhone}
                  onChange={(value) => dispatch({ type: 'contactPhone', payload: value })}
                  containerTabIndex={7}
                  startEditMode={(currentTabIndex === 7)}
                  onPressEnterGetNextTabIndex={setCurrentTabIndex}
                  hasError={!!(errors.contactPhone)}
                />
              </div>
            </div>

            <ShowHideContainer visible={client.type === CLIENTTYPE_MONTEUR}>
              <KeyValueEditRow
                title="Umkreissuche / Radius"
                initialValue={state.radius}
                displayText={state.radius}
                isNumeric
                onChange={(value) => dispatch({ type: 'radius', payload: value })}
                containerTabIndex={8}
                startEditMode={(currentTabIndex === 8)}
                onPressEnterGetNextTabIndex={setCurrentTabIndex}
                hasError={!!(errors.radius)}
                onHelpAction={() => setShowHelp(true)}
              />
            </ShowHideContainer>
            <KeyValueEditRow
              title="Kommentar"
              initialValue={state.comment}
              displayText={state.comment}
              onChange={(value) => dispatch({ type: 'comment', payload: value })}
              containerTabIndex={9}
              startEditMode={(currentTabIndex === 9)}
              onPressEnterGetNextTabIndex={setCurrentTabIndex}
              hasError={!!(errors.comment)}
            />
          </div>
        </div>
      </div>

      <HelpDialog context="addressRadius" visible={showHelp} onClose={() => setShowHelp(false)} />
    </ModalDialog>
  );
}

Update.propTypes = {
  initialData: PropTypes.instanceOf(Object),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

Update.defaultProps = {
  initialData: null,
};

function mapStoreToProps(store) {
  return {
    client: store.auth.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdate: (data) => dispatch(update(data)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(Update);
