/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const faqs = [
  { id: 'one', caption: 'Bin ich verpflichtet, eine bestimmte Anzahl von Aufträgen/Küchenmontagen durchzuführen?', description: 'Nein, sind Sie nicht. Es steht Ihnen frei, wie viele Aufträge Sie durchführen möchten' },
  { id: 'two', caption: 'Fallen bei der Benutzung von hey.kitchen kosten an?', description: 'Nein, Sie können komplett kostenlos von hey.kitchen profitieren.' },
  { id: 'three', caption: 'Was passiert wenn während der Montage Schwierigkeiten auftreten?', description: 'Sie wenden sich ganz einfach an unseren Kundendienst und wir klären die Angelegenheit während Sie bequem weiter montieren.' },
  { id: 'four', caption: 'Wie erfolgt die Restzahlung seitens des Endkunden an das Montageteam?', description: 'Der Endkunde leistet seine Zahlung ausschließlich per Lastschrift. Hierfür ist in unserer App ein Zahlungssystem integriert, das ähnlich wie ein EC- Gerät funktioniert. Somit entfällt für Sie das Risiko von Bargeldzahlungen.' },
  { id: 'five', caption: 'Wie und wann erhalte ich meine Vergütung für den Auftrag?', description: 'Nach Abschluss des Montageberichtes Ihres Montageberichtes, wird automatisch der Gutschriftsprozess gestartet. Sie müssen keine Rechnung mehr schreiben, sondern Sie erhalten von hey.kitchen im Anschluss eine Gutschrift, die innerhalb von 14 Tagen auf Ihrem Konto gutgeschrieben wird.' },
  { id: 'six', caption: 'Was benötige ich um an hey.kitchen teilnehmen zu können?', description: 'Um Aufträge zu erhalten, müssen Gewerbeanmeldung, Handelsregisterauszug und Versicherungsbestätigungen hochgeladen werden. Ist dies erfolgt, werden Sie, nach Prüfung der Dokumente, für zukünftige Aufträge frei geschaltet.' },
];

function Row(props) {
  const { item } = props;

  return (
    <div className="card">
      <div className="card-header" id="headingOne">
        <h2 className="mb-0">
          <button
            className="btn btn-link"
            type="button"
            data-toggle="collapse"
            data-target={`#${item.id}`}
            aria-expanded={false}
            aria-controls={item.id}
            style={{ textAlign: 'left' }}
          >
            {item.caption}
          </button>
        </h2>
      </div>

      <div id={item.id} className="collapse collapsed" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="card-body">
          {item.description}
        </div>
      </div>
    </div>
  );
}

Row.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

/**
 * MonteurFaqBox()
 * @returns {*}
 * @constructor
 */
export default function MonteurFaqBox() {
  return (
    <div className="FaqBox accordion" id="accordionExample">
      {faqs.map((item) => <Row key={item.id} item={item} />)}
    </div>
  );
}
