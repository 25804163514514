import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ModalDialog from '../../../Components/ModalDialog';
import Input from '../../../Components/Input';
import Textarea from '../../../Components/Textarea';
import CustomReactSelect from '../../../Components/CustomReactSelect';
import { YesNoOption } from '../../../Library/Types';
import { create, update } from '../../../Redux/Action/Client/MaterialAction';

const initialState = {
  caption: '',
  description: '',
  stockAmount: null,
  active: true,
  errors: {},
  hasError: false,
  isEdit: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'caption':
      return { ...state, caption: action.payload, errors: { ...state.errors, caption: false } };
    case 'description':
      return { ...state, description: action.payload };
    case 'stockAmount':
      return { ...state, stockAmount: action.payload };
    case 'active':
      return { ...state, active: action.payload };
    case 'addErrors':
      return { ...state, errors: action.payload, hasError: (Object.keys(action.payload).length > 0) };
    case 'resetErrors': {
      return { ...state, errors: {}, hasError: false };
    }
    case 'reset':
      return { ...initialState };

    case 'initEdit':
      return { ...action.payload, isEdit: true, errors: {} };
    default:
      return { ...state };
  }
}

/**
 * Add()
 * @param props
 * @returns {*}
 * @constructor
 */
function Add(props) {
  const {
    visible, onClose, dispatchCreate, editData, dispatchUpdate,
  } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState, (data) => (data));
  const [loading, setLoading] = React.useState(false);
  const [headline, setHeadline] = React.useState('Material hinzufügen');
  const [buttonCaption, setButtonCaption] = React.useState('Speichern');

  React.useEffect(() => {
    if (editData) {
      dispatch({ type: 'initEdit', payload: editData });
    }
  }, [editData]);

  React.useEffect(() => {
    if (state.isEdit) {
      setHeadline('Material bearbeiten');
      setButtonCaption('Änderungen speichern');
    } else {
      setHeadline('Material hinzufügen');
      setButtonCaption('Speichern');
    }
  }, [state.isEdit]);

  const handleClose = () => {
    dispatch({ type: 'reset' });
    onClose();
  };

  const handleSave = () => {
    dispatch({ type: 'resetErrors' });
    let hasError = false;
    let errors = {};

    if (!state.caption || state.caption.length < 3) {
      errors = { ...errors, caption: true };
      hasError = true;
    }

    dispatch({ type: 'addErrors', payload: errors });

    if (!hasError) {
      setLoading(true);
      if (state.isEdit) {
        dispatchUpdate(state).then(() => {
          dispatch({ type: 'reset' });
          onClose();
          setLoading(false);
        }).catch(() => setLoading(false));
      } else {
        dispatchCreate(state).then(() => {
          dispatch({ type: 'reset' });
          onClose();
          setLoading(false);
        }).catch(() => setLoading(false));
      }
    }
  };

  return (
    <ModalDialog
      onClose={handleClose}
      visible={visible}
      onConfirm={handleSave}
      confirmCaption={buttonCaption}
      disableConfirm={loading}
    >
      <h5 className="hk-text-primary">{headline}</h5>
      <hr />

      <div className="row">
        <Input
          onChange={(value) => dispatch({ type: 'caption', payload: value })}
          label="Bezeichnung"
          value={state.caption}
          rowClass="col-lg-12"
          hasError={!!(state.errors.caption)}
          errorMessage="Das Material benötigt eine kurze Bezeichnung!"
          required
          autoFocus
        />
        <Textarea
          onChange={(value) => dispatch({ type: 'description', payload: value })}
          label="Beschreibung"
          value={state.description}
          rows={4}
          rowClass="col-lg-12"
        />
        <CustomReactSelect
          options={YesNoOption}
          onChange={(value) => dispatch({ type: 'active', payload: value })}
          label="Darf verwendet werden?"
          rowClass="col-lg-12"
          value={state.active}
        />
      </div>
    </ModalDialog>
  );
}

Add.propTypes = {
  dispatchCreate: PropTypes.func.isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.instanceOf(Object),
};

Add.defaultProps = {
  visible: false,
  editData: null,
};

function mapStoreToProps(store) {
  return {
    material: store.client.material,
    client: store.client.client,
    clientSettings: store.auth.client.settings,
    users: store.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreate: (material) => dispatch(create(material)),
    dispatchUpdate: (material) => dispatch(update(material)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Add));
