import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import axios from '../../../../Library/Axios';

const defaultUri = '/system/invoice';

export const SYSTEM_INVOICE_GETLIST_START = 'SYSTEM_INVOICE_GETLIST_START';
export const SYSTEM_INVOICE_GETLIST_SUCCESS = 'SYSTEM_INVOICE_GETLIST_SUCCESS';
export const SYSTEM_INVOICE_GETLIST_ERROR = 'SYSTEM_INVOICE_GETLIST_ERROR';

/**
 * getList()
 * @param action
 * @returns {function(*): Promise<any | never>}
 */
export function getList(action = 'all') {
  return (dispatch) => {
    dispatch({ type: SYSTEM_INVOICE_GETLIST_START });

    const config = {
      params: { action },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_INVOICE_GETLIST_SUCCESS, payload: { data, type: action } });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_INVOICE_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

/**
 * useDispatchGetInvoiceList()
 * @returns {function(*=, *=): *}
 */
export function useDispatchGetInvoiceList() {
  const dispatch = useDispatch();

  return React.useCallback((action = 'all', year = moment().year()) => {
    dispatch({ type: SYSTEM_INVOICE_GETLIST_START, payload: { action, year } });

    const config = {
      params: { action, year },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_INVOICE_GETLIST_SUCCESS, payload: { data, type: action } });

      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_INVOICE_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const SYSTEM_INVOICE_GET_START = 'SYSTEM_INVOICE_GET_START';
export const SYSTEM_INVOICE_GET_SUCCESS = 'SYSTEM_INVOICE_GET_SUCCESS';
export const SYSTEM_INVOICE_GET_ERROR = 'SYSTEM_INVOICE_GET_ERROR';

/**
 * get()
 * @param transactionId
 * @returns {function(*): Promise<any | never>}
 */
export function get(transactionId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_INVOICE_GET_START });

    const uri = `${defaultUri}/${transactionId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_INVOICE_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_INVOICE_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_INVOICE_GENERATEDEBIT_START = 'SYSTEM_INVOICE_GENERATEDEBIT_START';
export const SYSTEM_INVOICE_GENERATEDEBIT_SUCCESS = 'SYSTEM_INVOICE_GENERATEDEBIT_SUCCESS';
export const SYSTEM_INVOICE_GENERATEDEBIT_ERROR = 'SYSTEM_INVOICE_GENERATEDEBIT_ERROR';

/**
 * generateDebits()
 * @param transactions
 * @returns {function(*): *}
 */
export function generateDebits(transactions) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_INVOICE_GENERATEDEBIT_START });

    const config = {
      params: { action: 'generateDebit' },
    };

    return axios().post(defaultUri, { transactions }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_INVOICE_GENERATEDEBIT_SUCCESS, payload: data.xml });
      const blobFile = new Blob(
        [data.xml],
        { type: 'application/xml' },
      );
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: SYSTEM_INVOICE_GENERATEDEBIT_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_INVOICE_GENERATECREDIT_START = 'SYSTEM_INVOICE_GENERATECREDIT_START';
export const SYSTEM_INVOICE_GENERATECREDIT_SUCCESS = 'SYSTEM_INVOICE_GENERATECREDIT_SUCCESS';
export const SYSTEM_INVOICE_GENERATECREDIT_ERROR = 'SYSTEM_INVOICE_GENERATECREDIT_ERROR';

/**
 * generateCredit()
 * @param transactions | An array of all transactionIds to be exported
 * @returns {function(*): *}
 */
export function generateCredit(transactions) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_INVOICE_GENERATECREDIT_START });

    const config = {
      params: { action: 'generateCredit' },
    };

    return axios().post(defaultUri, { transactions }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_INVOICE_GENERATECREDIT_SUCCESS, payload: data.xml });
      const blobFile = new Blob(
        [data.xml],
        { type: 'application/xml' },
      );
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: SYSTEM_INVOICE_GENERATECREDIT_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_INVOICE_UPDATE_START = 'SYSTEM_INVOICE_UPDATE_START';
export const SYSTEM_INVOICE_UPDATE_SUCCESS = 'SYSTEM_INVOICE_UPDATE_SUCCESS';
export const SYSTEM_INVOICE_UPDATE_ERROR = 'SYSTEM_INVOICE_UPDATE_ERROR';

/**
 * update()
 * @param transaction
 * @returns {function(*): Promise<unknown>}
 */
export function update(transaction) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_INVOICE_UPDATE_START });

    const uri = `${defaultUri}/${transaction.transactionId}`;

    return axios().put(uri, transaction).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_INVOICE_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_INVOICE_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_INVOICE_DOWNLOAD_START = 'SYSTEM_INVOICE_DOWNLOAD_START';
export const SYSTEM_INVOICE_DOWNLOAD_SUCCESS = 'SYSTEM_INVOICE_DOWNLOAD_SUCCESS';
export const SYSTEM_INVOICE_DOWNLOAD_ERROR = 'SYSTEM_INVOICE_DOWNLOAD_ERROR';

/**
 * download()
 * @param transaction
 * @returns {function(*): Promise<unknown>}
 */
export function download(transaction) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_INVOICE_DOWNLOAD_START });

    const config = {
      params: { action: 'pdf' },
      responseType: 'arraybuffer',
    };

    const uri = `${defaultUri}/${transaction.transactionId}`;

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/pdf' },
      );
      dispatch({ type: SYSTEM_INVOICE_DOWNLOAD_SUCCESS });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: SYSTEM_INVOICE_DOWNLOAD_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
