import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Panel from '../Panel';
import SelectUserOrTeam from '../SelectUserTeamPartner';
import { getAll } from '../../Redux/Action/UserAction';
import { getList } from '../../Redux/Action/TeamsAction';
import TextLink from '../TextLink';
import { isExternalOrderOwner } from '../../Library/Functions';

import './style.css';

/**
 * UserRow()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function UserRow(props) {
  const {
    item, onDelete, users, disabled, reportUserId,
  } = props;
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (users) {
      const merged = users.filter((data) => data.userId === item.userId)[0];
      setUser(merged);
    }
  }, [users, item.userId]);

  const renderDelete = () => {
    if (!disabled) {
      return (
        <div className="Delete" onClick={() => onDelete(item)} tabIndex={-1} role="button" onKeyPress={() => {}}>
          <i className="far fa-trash-alt" style={{ marginRight: 10 }} />
          Entfernen
        </div>
      );
    }
    return null;
  };

  if (user) {
    return (
      <div className="Row">
        <div className="Left">
          <i className="fas fa-user" />
          {`${user.firstname} ${user.lastname}${(reportUserId === user.userId) ? '*' : ''}`}
        </div>
        <div className="Right">
          {renderDelete()}
        </div>
      </div>
    );
  }
  return null;
}

UserRow.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Array),
  disabled: PropTypes.bool,
  reportUserId: PropTypes.number,
};

UserRow.defaultProps = {
  users: null,
  disabled: true,
  reportUserId: null,
};

/**
 * UserTeamPanel()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function UserTeamPanel(props) {
  const {
    users, teamId, onAddTeam, onAddUser, onDeleteUser, onDeleteTeam, storeTeams, getAllTeams, getAllUsers, storeUsers,
    disabled, disabledHint, order, client, reportUserId, executionDate,
  } = props;
  const [showSelect, setShowSelect] = useState(false);

  useEffect(() => {
    getAllUsers();
    getAllTeams();
  }, [getAllUsers, getAllTeams]);

  const handleUserAdd = (user) => {
    onAddUser(user);
    setShowSelect(false);
  };

  const handleTeamAdd = (team) => {
    onAddTeam(team);
    setShowSelect(false);
  };

  const getTeamMembers = (team) => team.users.map((item) => (
    `${item.firstname} ${item.lastname}${item.isMaster ? '*' : ''}`
  )).join(', ');

  const renderTeamDelete = () => {
    if (!disabled) {
      return (
        <div className="Delete" onClick={() => onDeleteTeam()} tabIndex={-1} role="button" onKeyPress={() => {}}>
          <i className="far fa-trash-alt" style={{ marginRight: 10 }} />
          Entfernen
        </div>
      );
    }
    return null;
  };

  const renderTeamRow = () => {
    if (teamId && storeTeams) {
      const team = storeTeams.filter((item) => item.teamId === teamId)[0];

      if (team) {
        return (
          <div className="Row">
            <div className="Left">
              <i className="fas fa-users" />
              <div>
                {team.name}
                <small className="text-muted">{` (${getTeamMembers(team)})`}</small>
              </div>
            </div>
            <div className="Right">
              {renderTeamDelete()}
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const renderAdd = () => {
    if (disabled === false) {
      return (
        <TextLink
          caption="Team oder Monteur hinzufügen"
          onPress={() => setShowSelect(true)}
          icon="fas fa-plus-square"
          style={{ marginTop: 20 }}
        />
      );
    }
    return null;
  };

  const renderDisabledHint = () => {
    if (disabled && disabledHint) {
      return (
        <div className="alert alert-warning">
          <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
          {disabledHint}
        </div>
      );
    }
    return null;
  };

  if (!order.internal) {
    if (isExternalOrderOwner(client.clientId, order)) {
      return null;
    }
    if (order.state <= 3) {
      return null;
    }
  } else if (order.mandantId && order.mandantId === client.clientId) {
    return null;
  }

  if (storeTeams || storeUsers) {
    return (
      <Panel
        marginBottom={20}
        boxPadding={25}
        onAction={() => setShowSelect(true)}
        className="UserTeamPanel"
        shadow
      >
        <h6>Monteure / Teams</h6>
        <p>
          Wählen Sie die benötigten Monteure oder das benötigte Team aus!
        </p>

        <div className="RowContainer">
          {renderTeamRow()}
          {users.map((item) => (
            <UserRow
              item={item}
              key={item.userId}
              onDelete={onDeleteUser}
              users={storeUsers}
              disabled={disabled}
              reportUserId={reportUserId}
            />
          ))}
        </div>

        {renderDisabledHint()}
        {renderAdd()}

        <small style={{ marginTop: 20 }}>
          *Dieser Monteur ist verantwortlich für die Erstellung des Monatgeberichts.
        </small>

        <SelectUserOrTeam
          onClose={() => setShowSelect(false)}
          visible={showSelect}
          onAddUser={handleUserAdd}
          onAddTeam={handleTeamAdd}
          alreadyAddedUsers={users}
          order={order}
          executionDate={executionDate}
        />
      </Panel>
    );
  }
  return null;
}

function mapStoreToProps(store) {
  return {
    client: store.auth.client,
    storeUsers: store.user.users,
    storeTeams: store.teams.teams,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllUsers: () => dispatch(getAll()),
    getAllTeams: () => dispatch(getList()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(UserTeamPanel);

UserTeamPanel.propTypes = {
  users: PropTypes.instanceOf(Array),
  teamId: PropTypes.number,
  onAddTeam: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onDeleteTeam: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  storeTeams: PropTypes.instanceOf(Array),
  storeUsers: PropTypes.instanceOf(Array),
  disabled: PropTypes.bool,
  disabledHint: PropTypes.string,
  order: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  reportUserId: PropTypes.number,
  executionDate: PropTypes.string,
};

UserTeamPanel.defaultProps = {
  users: null,
  teamId: 0,
  storeTeams: null,
  storeUsers: null,
  disabled: false,
  disabledHint: null,
  reportUserId: null,
  executionDate: null,
};
