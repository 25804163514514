import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import axios from '../../Library/Axios';

const defaultUri = '/holiday';

export const HOLIDAY_GETLIST_START = 'HOLIDAY_GETLIST_START';
export const HOLIDAY_GETLIST_SUCCESS = 'HOLIDAY_GETLIST_SUCCESS';
export const HOLIDAY_GETLIST_ERROR = 'HOLIDAY_GETLIST_ERROR';

/**
 * useDispatchHolidayGetList()
 * @returns {(function(*=, *=): (Promise<{listData: *, tileData: *}>))|*}
 */
export function useDispatchHolidayGetList() {
  const dispatch = useDispatch();

  return React.useCallback((startDate, endDate = null) => {
    let startOfMonth = startDate;
    let endOfMonth = endDate;

    if (!endDate) {
      startOfMonth = moment(startDate).startOf('month').format('YYYY-MM-DD');
      endOfMonth = moment(startDate).endOf('month').format('YYYY-MM-DD');
    }

    dispatch({ type: HOLIDAY_GETLIST_START, payload: { startDate, endDate } });

    const config = {
      params: {
        startDate: startOfMonth,
        endDate: endOfMonth,
      },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: HOLIDAY_GETLIST_SUCCESS, payload: { data, startDate, endDate } });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: HOLIDAY_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
