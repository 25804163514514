/* eslint-disable max-len */
import moment from 'moment';

/**
 * Get tax rate for german tax rate changes in 2020
 * @param dueDate
 * @returns {number}
 */
export const globalTaxRate = (dueDate) => {
  if (moment(dueDate).isAfter(moment('2020-07-01')) && moment(dueDate).isBefore(moment('2021-01-01'))) {
    return 1.16;
  }
  return 1.19;
};

/**
 * Get tax rate string
 * @param dueDate
 * @returns {string}
 */
export const globalTaxRateString = (dueDate) => {
  if (moment(dueDate).isAfter(moment('2020-07-01')) && moment(dueDate).isBefore(moment('2021-01-01'))) {
    return '16,00%';
  }
  return '19,00%';
};

export const orderType = [
  { caption: 'Einzelauftrag', icon: 'fas fa-truck-moving', key: 'SINGLE' },
  {
    caption: 'Objektauftrag', icon: 'far fa-building', key: 'OBJECT', disabled: false,
  },
];

export const workOptions = [
  { caption: 'Elektro', key: 'ELECTRIC', icon: 'fas fa-plug' },
  { caption: 'Wasser', key: 'WATER', icon: 'fas fa-hand-holding-water' },
  { caption: 'Abluft', key: 'AIR', icon: 'fas fa-wind' },
  { caption: 'Beleuchtung', key: 'LIGHT', icon: 'far fa-lightbulb' },
  { caption: 'Quooker', key: 'QUOOKER', icon: 'fas fa-shower' },
];

export const countertopOptions = [
  {
    caption: 'Stein / Granit', label: 'Stein / Granit', value: 'STONE', key: 'STONE', icon: 'fas fa-egg',
  },
  {
    caption: 'Edelstahl', key: 'STEEL', label: 'Edelstahl', value: 'STEEL', icon: 'fas fa-layer-group',
  },
  {
    caption: 'Holz / Schichtstoff', key: 'WOOD', label: 'Holz / Schichtstoff', value: 'WOOD', icon: 'fas fa-tree',
  },
  {
    caption: 'Keramik', key: 'CERAMIC', label: 'Keramik', value: 'CERAMIC', icon: 'fas fa-clone',
  },
];

export const countertopDeliveryOptions = [
  { label: 'Arbeitsplatte wird extern geliefert/montiert', value: 0 },
  { label: 'Arbeitsplatte ist Teil der Lieferung/Montage', value: 1 },
];

export const YesNoOption = [
  { label: 'Ja', value: true },
  { label: 'Nein', value: false },
];

export const floorOption = [
  { label: 'Erdgeschoss', value: 0 },
  { label: '1. Etage', value: 1 },
  { label: '2. Etage', value: 2 },
  { label: '3. Etage', value: 3 },
  { label: '4. Etage', value: 4 },
  { label: '5. Etage', value: 5 },
  { label: '6. Etage', value: 6 },
];

export const parkingOption = [
  { label: 'Keine Angaben/Informationen zur Parkplatzsituation', value: 0 },
  { label: 'Genügend Freifläche als Parkplatz vorhanden', value: 1 },
  { label: 'Kleines Fahrzeug notwendig', value: 2 },
  { label: 'Halteverbotszone muss eingerichtet werden', value: 3 },
];

export const salutationOption = [
  { label: 'Herr', value: 1 },
  { label: 'Frau', value: 2 },
  { label: 'Firma', value: 3 },
  { label: 'An', value: 4 },
];

export const legalFormOptions = [
  { label: 'Natürliche Person / Einzelunternehmen', value: 1 },
  { label: 'GmbH', value: 2 },
  { label: 'AG', value: 3 },
  { label: 'GbR', value: 4 },
  { label: 'UG (haftungsbeschränkt)', value: 5 },
  { label: 'EK (Einzelkaufmann / eU (Einzelunternehmer))', value: 6 },
  { label: 'eingetragener Kaufmann (e.K)', value: 7 },
  { label: 'oHG, KG (einschl. GmbH & Co.KG)', value: 8 },
  { label: 'Ltd.', value: 9 },
  { label: 'Sonstige Rechtsform', value: 10 },
];

/** CLIENT TYPES */
export const CLIENTTYPE_MONTEUR = 'MONTEUR';
export const CLIENTTYPE_DEALER = 'DEALER';

export const clientTypeOptions = [
  { caption: 'Monteur', key: CLIENTTYPE_MONTEUR },
  { caption: 'Dealer', key: CLIENTTYPE_DEALER },
];

export const subscriptionCycleOptions = [
  { label: 'Monatliche Zahlung', value: 'monthly' },
  { label: 'Jährliche Zahlung (10% Rabatt)', value: 'annual' },
];

export const clientType = [
  { caption: 'Ich bin Küchenhändler / Verkäufer', icon: 'far fa-building', key: CLIENTTYPE_DEALER },
  { caption: 'Ich bin Monteur', icon: 'fas fa-tools', key: CLIENTTYPE_MONTEUR },
];

export const clientInviteType = [
  { caption: 'Küchenhändler / Verkäufer', icon: 'far fa-building', key: CLIENTTYPE_DEALER },
  { caption: 'Monteur', icon: 'fas fa-tools', key: CLIENTTYPE_MONTEUR },
];

export const orderState = {
  1: { caption: 'Wartet auf Angebotsberechnung', textClass: 'text-info' },
  2: { caption: 'Wartet auf Angebotsannahme', textClass: 'text-danger' },
  3: { caption: 'Auftrag angenommen', textClass: 'text-success' },
  4: { caption: 'Auftrag zugewiesen', textClass: 'text-warning' },
  5: { caption: 'Montage abgeschlossen', textClass: 'text-success' },
};

export const DEVICE_OTHERDEVICE = 'otherdevice';
export const DEVICE_OTHER = 'others';

export const availableDevices = [
  { label: '', key: null, value: '' },
  { label: 'Backofen', key: 'backofen', value: 'backofen' },
  { label: 'Dampfgarer', key: 'dampfgarer', value: 'dampfgarer' },
  { label: 'Dunstabzug', key: 'dunstabzug', value: 'dunstabzug' },
  { label: 'Kochfeld', key: 'herd', value: 'herd' },
  { label: 'Kochfeldabzug', key: 'kochfeldabzug', value: 'kochfeldabzug' },
  { label: 'Kühlschrank', key: 'kuehlschrank', value: 'kuehlschrank' },
  { label: 'Mikrowelle', key: 'mikrowelle', value: 'mikrowelle' },
  { label: 'Mischbatterie', key: 'mischbatterie', value: 'mischbatterie' },
  { label: 'Spülmaschine', key: 'spuelmaschine', value: 'spuelmaschine' },
  { label: 'Spüle', key: 'spuele', value: 'spuele' },
  { label: 'Untertischgerät', key: 'untertischgeraet', value: 'untertischgeraet' },
  { label: 'Anderes Gerät', key: 'otherdevice', value: 'otherdevice' },
  { label: 'Sonstiges', key: 'others', value: 'others' },
];

export const timeOptions = [
  { label: '', value: null },
  { label: '07:00', value: '07:00' },
  { label: '07:30', value: '07:30' },
  { label: '08:00', value: '08:00' },
  { label: '08:30', value: '08:30' },
  { label: '09:00', value: '09:00' },
  { label: '09:30', value: '09:30' },
  { label: '10:00', value: '10:00' },
  { label: '10:30', value: '10:30' },
  { label: '11:00', value: '11:00' },
  { label: '11:30', value: '11:30' },
  { label: '12:00', value: '12:00' },
  { label: '12:30', value: '12:30' },
  { label: '13:00', value: '13:00' },
  { label: '13:30', value: '13:30' },
  { label: '14:00', value: '14:00' },
  { label: '14:30', value: '14:30' },
  { label: '15:00', value: '15:00' },
  { label: '15:30', value: '15:30' },
  { label: '16:00', value: '16:00' },
  { label: '16:30', value: '16:30' },
  { label: '17:00', value: '17:00' },
  { label: '17:30', value: '17:30' },
  { label: '18:00', value: '18:00' },
  { label: '18:30', value: '18:30' },
  { label: '19:00', value: '19:00' },
  { label: '19:30', value: '19:30' },
  { label: '20:00', value: '20:00' },
];

export const weekIntervalTimeOptions = [
  { label: 'Jede Woche', value: '1' },
  { label: 'Jede 2. Woche', value: '2' },
  { label: 'Jede 3. Woche', value: '3' },
  { label: 'Jede 4. Woche', value: '4' },
];

export const recurringDayOptions = [
  { label: 'Montag', value: 'monday' },
  { label: 'Dienstag', value: 'tuesday' },
  { label: 'Mittwoch', value: 'wednesday' },
  { label: 'Donnerstag', value: 'thursday' },
  { label: 'Freitag', value: 'friday' },
  { label: 'Samstag', value: 'saturday' },
];

export const assemblyTimeOptions = [
  { label: '0,5 Tage', value: '270' },
  { label: '1 Tag', value: '540' },
  { label: '1,5 Tage', value: '810' },
  { label: '2 Tage', value: '1080' },
  { label: '2,5 Tage', value: '1350' },
  { label: '3 Tage', value: '1620' },
  { label: '3,5 Tage', value: '1890' },
  { label: '4 Tage', value: '2160' },
  { label: '4,5 Tage', value: '2430' },
  { label: '5 Tage', value: '2700' },
];

export const assemblyTimeMinuteOptions = [
  { label: 'Keine Angabe', value: 0 },
  { label: '15 Minuten', value: 15 },
  { label: '30 Minuten', value: 30 },
  { label: '45 Minuten', value: 45 },
  { label: '1 Stunde', value: 60 },
  { label: '1,5 Stunden', value: 90 },
  { label: '2 Stunde', value: 120 },
  { label: '2,5 Stunde', value: 150 },
  { label: '3 Stunde', value: 180 },
  { label: '3,5 Stunde', value: 210 },
  { label: '4 Stunde', value: 240 },
  { label: '4,5 Stunde', value: 270 },
  { label: '5 Stunde', value: 300 },
  { label: '5,5 Stunde', value: 330 },
  { label: '6 Stunde', value: 360 },
  { label: '6,5 Stunde', value: 390 },
  { label: '7 Stunde', value: 420 },
  { label: '7,5 Stunde', value: 450 },
  { label: '8 Stunde', value: 480 },
];

export const settlementMethodeOptions = [
  { label: '10€ pro internen Auftrag', value: 0 },
  { label: '0.4% Netto von jedem internen Auftrag', value: 1 },
];

export const DOCUMENTTYPE_OTHER = 'OTHER';
export const DOCUMENTTYPE_ORDER = 'ORDER';
export const DOCUMENTTYPE_INSTALL = 'INSTALL';
export const DOCUMENTTYPE_DELIVERYNOTE = 'DELIVERYNOTE';
export const DOCUMENTTYPE_CARGOLIST = 'CARGOLIST';
export const DOCUMENTTYPE_INVOICE = 'INVOICE';

export const documentTypeOptions = [
  { caption: 'Installationsplan hinzufügen (Aktuelle wird gelöscht)', key: DOCUMENTTYPE_INSTALL },
  { caption: 'Auftragsbestätigung hinzufügen (Aktuelle wird gelöscht)', key: DOCUMENTTYPE_ORDER },
  { caption: 'Ladelistendokument', key: DOCUMENTTYPE_CARGOLIST },
  { caption: 'Rechnungsdokument', key: DOCUMENTTYPE_INVOICE },
  { caption: 'Anderes Dokument', key: DOCUMENTTYPE_OTHER },
];

export const allowedImageExtensions = ['jpg', 'jpeg', 'bmp', 'png', 'gif'];

export const ORDERTYPE_SINGLE = 'SINGLE';
export const ORDERTYPE_OBJECT = 'OBJECT';

export const DEVICETYPE_DEVICE = 'device';
export const DEVICETYPE_PREDAMAGE = 'predamage';
export const DEVICETYPE_RECLAMATION = 'reclamation';
export const DEVICETYPE_DAMAGE = 'damage';
export const DEVICETYPE_RECLAMATIONDEVICE = 'reclamationdevice';
export const DEVICETYPE_SERVICE = 'service';
export const DEVICETYPE_ADDITIONALWORK = 'additionalwork';
export const DEVICETYPE_RETURN = 'return';

export const DEVICEWORKTYPE_PLUGGED = 'plugged';
export const DEVICEWORKTYPE_UNPLUGGED = 'unplugged';
export const DEVICEWORKTYPE_CUSTOMERPLUGGED = 'customerplugged';

export const DEVICETYPEDEVICE_OTHERS = 'others';
export const DEVICETYPEDEVICE_OTHERDEVICE = 'otherdevice';

/** CLIENT DOCUMENT TYPES */
export const CLIENT_DOCUMENTSTYPE_OTHER = 'other';
export const CLIENT_DOCUMENTSTYPE_LOGO = 'logo';
export const CLIENT_DOCUMENTSTYPE_INSURANCE = 'insurance';
export const CLIENT_DOCUMENTSTYPE_WATERCERTIFICATE = 'watercertificate';
export const CLIENT_DOCUMENTSTYPE_ELECTRICCERTIFICATE = 'electriccertificate';
export const CLIENT_DOCUMENTSTYPE_MINIMUMWAGE = 'minimumwage';
export const CLIENT_DOCUMENTSTYPE_BUSINESSREGISTRATION = 'businessregistration';
export const CLIENT_DOCUMENTSTYPE_REGISTRATIONCERTIFICATE = 'registrationcertificate';
export const CLIENT_DOCUMENTSTYPE_EXEMPTIONCERTIFICATE = 'exemptioncertificate';

/** HOLIDAY REASON TYPES */
export const HOLIDAY_REASON_HOLIDAY = 'holiday';
export const HOLIDAY_REASON_DISEASE = 'disease';
export const HOLIDAY_REASON_OTHER = 'other';

export const holidayReasonCaptions = {
  [HOLIDAY_REASON_HOLIDAY]: 'Urlaub',
  [HOLIDAY_REASON_DISEASE]: 'Krank',
  [HOLIDAY_REASON_OTHER]: 'Anderes',
};

export const holidayReasonTypeOptions = [
  { caption: holidayReasonCaptions[HOLIDAY_REASON_HOLIDAY], key: HOLIDAY_REASON_HOLIDAY },
  { caption: holidayReasonCaptions[HOLIDAY_REASON_DISEASE], key: HOLIDAY_REASON_DISEASE },
  { caption: holidayReasonCaptions[HOLIDAY_REASON_OTHER], key: HOLIDAY_REASON_OTHER },
];

/** LOCALSTORAGE CONST VALUES */
export const LOCALSTORAGE_SETINGSHOLIDAYCALENDARVIEW = '@settingsHolidayCalendarView';
export const LOCALSTORAGE_DASHBOARD_CONFIG = '@dashboardConfig';
export const LOCALSTORAGE_DASHBOARD_HIDE_HINT_SHOWN = '@dashboardBoxHideHintShown';
export const LOCALSTORAGE_DASHBOARD_SWITCH_HINT_SHOWN = '@dashboardBoxSwitchHintShown';

/** ERROR CODES */
export const ERRORCODE_USER_HOLIDAY_ALREADY_EXISTING = 'USER_HOLIDAY_ALREADY_EXISTING';
export const ERRORCODE_USER_HOLIDAY_HAS_ORDER_ASSIGNED = 'USER_HOLIDAY_HAS_ORDER_ASSIGNED';
export const ERRORCODE_USER_HOLIDAY_HAS_RECLAMATION_ASSIGNED = 'USER_HOLIDAY_HAS_RECLAMATION_ASSIGNED';
export const ERRORCODE_ORDER_MONTEUR_NOT_AVAILABLE_HOLIDAY = 'ORDER_MONTEUR_NOT_AVAILABLE_HOLIDAY';
export const ERRORCODE_ORDER_MONTEUR_NOT_AVAILABLE_ORDER = 'ORDER_MONTEUR_NOT_AVAILABLE_ORDER';
export const ERRORCODE_ORDER_MONTEUR_NOT_AVAILABLE_RECLAMATION = 'ORDER_MONTEUR_NOT_AVAILABLE_RECLAMATION';
export const ERRORCODE_VOUCHER_WRONG_TYPE = 'VOUCHER_WRONG_TYPE';
export const ERRORCODE_DISPOSITION_USER_NOT_AVAILABLE = 'DISPOSITION_USER_NOT_AVAILABLE';

/** VOUCHER TYPES */
export const VOUCHERTYPE_SINGLE = 'SINGLE';
export const VOUCHERTYPE_RUNTIME = 'RUNTIME';

export const voucherTypeOptions = [
  { caption: 'Einzelgutschein', key: VOUCHERTYPE_SINGLE },
  { caption: 'Laufzeitgutschein', key: VOUCHERTYPE_RUNTIME },
];

/** URL SCHEMES FOR NAVIGATION */
export const NAVIGATION_EXTERNAL_APPROVE = '/create/{orderId}/approve';
export const NAVIGATION_EXTERNAL_PROCESSING = '/create/{orderId}/processing';

export const NAVIGATION_OBJECT_DETAILS = '/order/{orderId}/object/{objectId}';
export const NAVIGATION_OBJECT_MESSAGES = '/order/{orderId}/object/{objectId}/messages';
export const NAVIGATION_OBJECT_SERVICE_DETAILS = '/order/{orderId}/object/{objectId}/service/{serviceId}';
export const NAVIGATION_OBJECT_TASK = '/order/{orderId}/object/{objectId}/task';
export const NAVIGATION_ORDER_DETAILS = '/order/{orderId}';
export const NAVIGATION_RECLAMATION_DETAILS = '/order/{orderId}/object/{objectId}/reclamation/{reclamationId}';
export const NAVIGATION_DEMONTAGE_REPORT_DETAILS = '/order/{orderId}/object/{objectId}/demontagereport/{demontageReportId}';
export const NAVIGATION_DELIVERY_REPORT_DETAILS = '/order/{orderId}/object/{objectId}/deliveryreport/{deliveryReportId}';
export const NAVIGATION_SYSTEM_ORDER_DETAILS = '/system/order/{orderId}';
export const NAVIGATION_SYSTEM_OBJECT_DETAILS = '/system/order/{orderId}/object/{objectId}';
export const NAVIGATION_SYSTEM_RECLAMATION_DETAILS = '/system/order/{orderId}/object/{objectId}/reclamation/{reclamationId}';
export const NAVIGATION_SYSTEM_OBJECT_SERVICE_DETAILS = '/system/order/{orderId}/object/{objectId}/service/{serviceId}';
export const NAVIGATION_SYSTEM_CLIENT_DETAILS = '/system/client/{clientId}/details';
export const NAVIGATION_CLIENT_ADMIN_MANDANT_DETAILS = '/admin/mandant/{mandantId}';
export const NAVIGATION_CLIENT_ADMIN_USER_DETAILS = '/admin/users/{userId}';
export const NAVIGATION_CLIENT_ADMIN_USER_CREATE = '/admin/users/create';
export const NAVIGATION_CLIENT_ADMIN_SUBCONTRACTOR_DETAILS = '/admin/subcontractor/{subcontractorId}';

export const TRANSACTION_PAYMENT_METHOD_DEBIT = 'debit';
export const TRANSACTION_PAYMENT_METHOD_INVOICE = 'invoice';

export const CLIENTNOTE_TYPE_PHONE = 1;
export const CLIENTNOTE_TYPE_MAIL = 2;
export const CLIENTNOTE_TYPE_PERSONAL = 3;
export const CLIENTNOTE_TYPE_OTHER = 4;

export const clientNoteTypeOptions = [
  { label: 'Telefon', value: CLIENTNOTE_TYPE_PHONE, icon: 'fas fa-phone' },
  { label: 'E-Mail', value: CLIENTNOTE_TYPE_MAIL, icon: 'fas fa-envelope' },
  { label: 'Persönlich', value: CLIENTNOTE_TYPE_PERSONAL, icon: 'fas fa-handshake' },
  { label: 'Weitere', value: CLIENTNOTE_TYPE_OTHER, icon: 'fas fa-question' },
];

export const NOTIFICATION_REFERENCETYPE_APPOINTMENT_ADD_DATES = 'appointmentAddDates';
export const NOTIFICATION_REFERENCETYPE_APPOINTMENT_SELECT_DATE = 'appointmentSelectDate';
export const NOTIFICATION_REFERENCETYPE_APPOINTMENT_DATE_SELECTED = 'appointmentDateSelected';
export const NOTIFICATION_REFERENCETYPE_CLIENTNOTEREMINDER = 'clientNoteReminder';
export const NOTIFICATION_REFERENCETYPE_ORDERNEWOFFER = 'orderNewOffer';
export const NOTIFICATION_REFERENCETYPE_ORDEROFFERCANCELED = 'orderOfferCanceled';
export const NOTIFICATION_REFERENCETYPE_ORDERNEWMESSAGE = 'orderNewMessage';
export const NOTIFICATION_REFERENCETYPE_OBJECTNEWMESSAGE = 'objectNewMessage';
export const NOTIFICATION_REFERENCETYPE_OBJECTNEWDOCUMENT = 'objectNewDocument';
export const NOTIFICATION_REFERENCETYPE_OBJECTPARTASSIGNED = 'objectPartAssigned';
export const NOTIFICATION_REFERENCETYPE_OBJECTPARTUNASSIGNED = 'objectPartUnassigned';
export const NOTIFICATION_REFERENCETYPE_PARTNERDATECHANGED = 'objectPartnerDateChanged';
export const NOTIFICATION_REFERENCETYPE_OBJECTALLDOCUMENTSFINISHED = 'objectAllDocumentsFinished';
export const NOTIFICATION_REFERENCETYPE_NEWRECLAMATION = 'newReclamation';
export const NOTIFICATION_REFERENCETYPE_RECLAMATIONAPPROVED = 'reclamationApproved';
export const NOTIFICATION_REFERENCETYPE_RECLAMATIONCREATEDFORPARTNER = 'reclamationCreatedForPartner';
export const NOTIFICATION_REFERENCETYPE_SERVICE_CREATED = 'serviceCreated';
export const NOTIFICATION_REFERENCETYPE_SERVICE_ITEM_ADDED = 'serviceItemAdded';
export const NOTIFICATION_REFERENCETYPE_SERVICE_PARTNER_ASSIGNED = 'servicePartnerAssigned';
export const NOTIFICATION_REFERENCETYPE_SERVICE_PARTNER_UNASSIGNED = 'servicePartnerUnassigned';
export const NOTIFICATION_REFERENCETYPE_SERVICECREATEDFORPARTNER = 'serviceCreatedForPartner';
export const NOTIFICATION_REFERENCETYPE_CLIENTDOCUMENTINSURANCE = 'clientDocumentInsurance';
export const NOTIFICATION_REFERENCETYPE_MANDANT_REQUESTED = 'mandantRequested';
export const NOTIFICATION_REFERENCETYPE_ORDERCREATEDBYMANDANT = 'orderCreatedByMandant';
export const NOTIFICATION_REFERENCETYPE_DEMONTAGEREPORTFINISHED = 'demontageReportFinished';
export const NOTIFICATION_REFERENCETYPE_DELIVERYREPORTFINISHED = 'deliveryReportFinished';
export const NOTIFICATION_REFERENCETYPE_MONTHLY_BILL = 'monthlyBill';
export const NOTIFICATION_REFERENCETYPE_USER_TASK_ASSIGNED = 'userTaskAssigned';
export const NOTIFICATION_REFERENCETYPE_USER_TASK_DUE_DATE = 'userTaskDueDate';
export const NOTIFICATION_REFERENCETYPE_CLIENT_INVITE_ACCEPTED = 'clientInviteAccepted';
export const NOTIFICATION_REFERENCETYPE_CLIENT_INVITE_EXPIRED = 'clientInviteExpired';

export const OBJECT_USER_OPTION_ALL = 'all';
export const OBJECT_USER_OPTION_MONTAGE = 'montage';
export const OBJECT_USER_OPTION_DELIVERY = 'delivery';
export const OBJECT_USER_OPTION_REMOVAL = 'removal';

export const IMPORT_ACTION_CREATE = 'CREATE';
export const IMPORT_ACTION_UPDATE = 'UPDATE';
export const IMPORT_ACTION_NONE = 'NONE';

export const PAYMENTTYPE_CASH = 1;
export const PAYMENTTYPE_DIRECT_DEBIT = 2;

export const EVENTTYPE_PING = 'PING';
export const EVENTTYPE_ORDER_CREATE = 'ORDER_CREATE';
export const EVENTTYPE_ORDER_UPDATE = 'ORDER_UPDATE';
export const EVENTTYPE_ORDER_DELETE = 'ORDER_DELETE';

export const EVENTTYPE_OBJECT_UPDATE = 'OBJECT_UPDATE';
export const EVENTTYPE_OBJECT_DELETE = 'OBJECT_DELETE';
export const EVENTTYPE_OBJECT_MONTEUR_ADD = 'OBJECT_MONTEUR_ADD';
export const EVENTTYPE_OBJECT_MONTEUR_DELETE = 'OBJECT_MONTEUR_DELETE';
export const EVENTTYPE_OBJECT_TEAM_ADD = 'OBJECT_TEAM_ADD';
export const EVENTTYPE_OBJECT_TEAM_DELETE = 'OBJECT_TEAM_DELETE';

export const EVENTTYPE_OBJECT_SERVICE_CREATED = 'OBJECT_SERVICE_CREATED';
export const EVENTTYPE_OBJECT_SERVICE_UPDATE = 'OBJECT_SERVICE_UPDATE';
export const EVENTTYPE_OBJECT_SERVICE_DELETE = 'OBJECT_SERVICE_DELETE';
export const EVENTTYPE_OBJECT_SERVICE_SCHEDULE_APPOINTMENT = 'OBJECT_SERVICE_SCHEDULE_APPOINTMENT';
export const EVENTTYPE_OBJECT_SERVICE_REPORT_START = 'OBJECT_SERVICE_REPORT_START';
export const EVENTTYPE_OBJECT_SERVICE_REPORT_FINISHED = 'OBJECT_SERVICE_REPORT_FINISHED';

export const EVENTTYPE_RECLAMATION_CREATE = 'RECLAMATION_CREATE';
export const EVENTTYPE_RECLAMATION_UPDATE = 'RECLAMATION_UPDATE';
export const EVENTTYPE_RECLAMATION_DELETE = 'RECLAMATION_DELETE';
export const EVENTTYPE_RECLAMATION_SCHEDULE_APPOINTMENT = 'RECLAMATION_SCHEDULE_APPOINTMENT';
export const EVENTTYPE_RECLAMATION_REPORT_START = 'RECLAMATION_REPORT_START';
export const EVENTTYPE_RECLAMATION_REPORT_FINISHED = 'RECLAMATION_REPORT_FINISHED';

export const EVENTTYPE_SERVICE_REPORT_START = 'SERVICE_REPORT_START';
export const EVENTTYPE_SERVICE_REPORT_FINISHED = 'SERVICE_REPORT_FINISHED';
export const EVENTTYPE_DELIVERY_REPORT_START = 'DELIVERY_REPORT_START';
export const EVENTTYPE_DELIVERY_REPORT_FINISHED = 'DELIVERY_REPORT_FINISHED';
export const EVENTTYPE_DEMONTAGE_REPORT_START = 'DEMONTAGE_REPORT_START';
export const EVENTTYPE_DEMONTAGE_REPORT_FINISHED = 'DEMONTAGE_REPORT_FINISHED';

export const eventTypes = [
  { value: EVENTTYPE_ORDER_CREATE, caption: 'Auftrag erstellen' },
  { value: EVENTTYPE_ORDER_UPDATE, caption: 'Auftrag bearbeiten / aktualisieren' },
  { value: EVENTTYPE_ORDER_DELETE, caption: 'Auftrag löschen' },
  { value: EVENTTYPE_OBJECT_UPDATE, caption: 'Objekte bearbeiten / aktualisieren' },
  { value: EVENTTYPE_OBJECT_DELETE, caption: 'Objekt löschen' },
  { value: EVENTTYPE_OBJECT_MONTEUR_ADD, caption: 'Objekt Monteur hinzugefügt' },
  { value: EVENTTYPE_OBJECT_MONTEUR_DELETE, caption: 'Objekt Monteur gelöscht' },
  { value: EVENTTYPE_OBJECT_TEAM_ADD, caption: 'Objekt Team hinzugefügt' },
  { value: EVENTTYPE_OBJECT_TEAM_DELETE, caption: 'Objekt Team gelöscht' },
  { value: EVENTTYPE_OBJECT_SERVICE_CREATED, caption: 'Objekt Serviceauftrag erstellt' },
  { value: EVENTTYPE_OBJECT_SERVICE_UPDATE, caption: 'Objekt Serviceauftrag bearbeitet / aktualisiert' },
  { value: EVENTTYPE_OBJECT_SERVICE_DELETE, caption: 'Objekt Serviceauftrag gelöscht' },
  { value: EVENTTYPE_OBJECT_SERVICE_SCHEDULE_APPOINTMENT, caption: 'Objekt Serviceauftrag Kundentermin versendet' },
  { value: EVENTTYPE_OBJECT_SERVICE_REPORT_START, caption: 'Objekt Servicebericht gestartet' },
  { value: EVENTTYPE_OBJECT_SERVICE_REPORT_FINISHED, caption: 'Objekt Servicebericht abgeschlossen' },
  { value: EVENTTYPE_RECLAMATION_CREATE, caption: 'Reklamation erstellt' },
  { value: EVENTTYPE_RECLAMATION_UPDATE, caption: 'Reklamation bearbeiten / aktualisieren' },
  { value: EVENTTYPE_RECLAMATION_DELETE, caption: 'Reklamation löschen' },
  { value: EVENTTYPE_RECLAMATION_SCHEDULE_APPOINTMENT, caption: 'Reklamationstermin versendet' },
  { value: EVENTTYPE_RECLAMATION_REPORT_START, caption: 'Reklamationsbericht gestartet' },
  { value: EVENTTYPE_RECLAMATION_REPORT_FINISHED, caption: 'Reklamationsbericht abgeschlossen' },
  { value: EVENTTYPE_SERVICE_REPORT_START, caption: 'Montagebericht gestartet' },
  { value: EVENTTYPE_SERVICE_REPORT_FINISHED, caption: 'Montagebericht abgeschlossen' },
  { value: EVENTTYPE_DELIVERY_REPORT_START, caption: 'Lieferbericht gestartet' },
  { value: EVENTTYPE_DELIVERY_REPORT_FINISHED, caption: 'Lieferbericht abgeschlossen' },
  { value: EVENTTYPE_DEMONTAGE_REPORT_START, caption: 'Demontagebericht gestartet' },
  { value: EVENTTYPE_DEMONTAGE_REPORT_FINISHED, caption: 'Demontagebericht abgeschlossen' },
];

export const OBJECT_SERVICE_TYPE_RECLAMATION = 1;
export const OBJECT_SERVICE_TYPE_DELIVERY = 2;
export const OBJECT_SERVICE_TYPE_MONTAGE = 3;
export const OBJECT_SERVICE_TYPE_DELIVERY_AND_MONTAGE = 4;
export const OBJECT_SERVICE_TYPE_CHECK = 5;
export const OBJECT_SERVICE_TYPE_RENTAL_KITCHEN = 6;

export const objectServiceTypeCaptions = {
  [OBJECT_SERVICE_TYPE_RECLAMATION]: 'Beanstandung',
  [OBJECT_SERVICE_TYPE_DELIVERY]: 'Nachlieferung',
  [OBJECT_SERVICE_TYPE_MONTAGE]: 'Nachmontage',
  [OBJECT_SERVICE_TYPE_DELIVERY_AND_MONTAGE]: 'Nachlieferung inkl. Montage',
  [OBJECT_SERVICE_TYPE_CHECK]: 'Kontrolle / Überprüfung',
  [OBJECT_SERVICE_TYPE_RENTAL_KITCHEN]: 'Leihküche',
};

export const objectServiceTypeOptions = [
  { label: objectServiceTypeCaptions[OBJECT_SERVICE_TYPE_RECLAMATION], value: OBJECT_SERVICE_TYPE_RECLAMATION },
  { label: objectServiceTypeCaptions[OBJECT_SERVICE_TYPE_DELIVERY], value: OBJECT_SERVICE_TYPE_DELIVERY },
  { label: objectServiceTypeCaptions[OBJECT_SERVICE_TYPE_MONTAGE], value: OBJECT_SERVICE_TYPE_MONTAGE },
  { label: objectServiceTypeCaptions[OBJECT_SERVICE_TYPE_DELIVERY_AND_MONTAGE], value: OBJECT_SERVICE_TYPE_DELIVERY_AND_MONTAGE },
  { label: objectServiceTypeCaptions[OBJECT_SERVICE_TYPE_CHECK], value: OBJECT_SERVICE_TYPE_CHECK },
  { label: objectServiceTypeCaptions[OBJECT_SERVICE_TYPE_RENTAL_KITCHEN], value: OBJECT_SERVICE_TYPE_RENTAL_KITCHEN },
];

export const TASK_PRIO_HIGHEST = 1;
export const TASK_PRIO_HIGHER = 2;
export const TASK_PRIO_HIGH = 3;
export const TASK_PRIO_NORMAL = 4;
export const TASK_PRIO_LOW = 5;
export const TASK_PRIO_LOWEST = 6;

export const taskPrioCaption = {
  [TASK_PRIO_HIGHEST]: 'Sehr dringend',
  [TASK_PRIO_HIGHER]: 'Dringend',
  [TASK_PRIO_HIGH]: 'Hoch',
  [TASK_PRIO_NORMAL]: 'Normal',
  [TASK_PRIO_LOW]: 'Niedrig',
  [TASK_PRIO_LOWEST]: 'Keine Priorität',
};

export const taskPrioOptions = [
  { value: TASK_PRIO_HIGHEST, label: taskPrioCaption[TASK_PRIO_HIGHEST] },
  { value: TASK_PRIO_HIGHER, label: taskPrioCaption[TASK_PRIO_HIGHER] },
  { value: TASK_PRIO_HIGH, label: taskPrioCaption[TASK_PRIO_HIGH] },
  { value: TASK_PRIO_NORMAL, label: taskPrioCaption[TASK_PRIO_NORMAL] },
  { value: TASK_PRIO_LOW, label: taskPrioCaption[TASK_PRIO_LOW] },
  { value: TASK_PRIO_LOWEST, label: taskPrioCaption[TASK_PRIO_LOWEST] },
];

/** ORIGINATOR TYPES */
export const ORIGINATOR_TYPE_TRANSPORT_DAMAGE = 1;
export const ORIGINATOR_TYPE_PLANNING_ERROR = 2;
export const ORIGINATOR_TYPE_MONTAGE_ERROR = 3;
export const ORIGINATOR_TYPE_MISSING_ITEM = 4;

export const originatorTypeCaptions = {
  [ORIGINATOR_TYPE_TRANSPORT_DAMAGE]: 'Transportschaden',
  [ORIGINATOR_TYPE_PLANNING_ERROR]: 'Planungsfehler',
  [ORIGINATOR_TYPE_MONTAGE_ERROR]: 'Montagefehler',
  [ORIGINATOR_TYPE_MISSING_ITEM]: 'Fehlteil',
};

/** Types for object dates */
export const OBJECT_DATE_TYPE_ALL = 1;
export const OBJECT_DATE_TYPE_MONTAGE = 2;
export const OBJECT_DATE_TYPE_DELIVERY = 3;
export const OBJECT_DATE_TYPE_REMOVAL = 4;
export const OBJECT_DATE_TYPE_STORAGE = 5;

export const objectDateTypeIcons = {
  [OBJECT_DATE_TYPE_ALL]: 'fas fa-tools',
  [OBJECT_DATE_TYPE_REMOVAL]: 'fas fa-trash',
  [OBJECT_DATE_TYPE_DELIVERY]: 'fas fa-truck-moving',
  [OBJECT_DATE_TYPE_MONTAGE]: 'fas fa-tools',
  [OBJECT_DATE_TYPE_STORAGE]: 'fas fa-cubes',
};

export const objectDateTypeText = {
  [OBJECT_DATE_TYPE_ALL]: '', // TODO: Wie sollten wir das benennen?
  [OBJECT_DATE_TYPE_REMOVAL]: 'Demontage',
  [OBJECT_DATE_TYPE_DELIVERY]: 'Lieferung',
  [OBJECT_DATE_TYPE_MONTAGE]: 'Montage',
  [OBJECT_DATE_TYPE_STORAGE]: 'Lagerung',
};

/** Disposition types */
export const DISPOSITION_TYPE_OBJECT_DATE = 1;
export const DISPOSITION_TYPE_SERVICE = 2;
export const DISPOSITION_TYPE_MANUAL = 3;
export const DISPOSITION_TYPE_HOLIDAY = 4;
