import React from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../Library/Axios';
import { getList as getClientAddresses } from './ClientAddressAction';
import {
  CLIENT_DOCUMENTSTYPE_BUSINESSREGISTRATION, CLIENT_DOCUMENTSTYPE_INSURANCE,
  CLIENT_DOCUMENTSTYPE_LOGO, CLIENT_DOCUMENTSTYPE_REGISTRATIONCERTIFICATE, CLIENTTYPE_DEALER,
  CLIENTTYPE_MONTEUR,
} from '../../Library/Types';
import {
  filterMaxTenDayInThePastOrders,
  filterMaxTenDayInThePastService,
  filterRemoveOwnStorageOrders,
} from '../../Library/Functions';

export const defaultUri = '/client';

export const CLIENT_REGISTER_START = 'CLIENT_REGISTER_START';
export const CLIENT_REGISTER_SUCCESS = 'CLIENT_REGISTER_SUCCESS';
export const CLIENT_REGISTER_ERROR = 'CLIENT_REGISTER_ERROR';

/**
 * register()
 * @param formData
 * @param isInvitation
 * @returns {function(*): Promise<any | never>}
 */
export function register(formData, isInvitation = false) {
  return (dispatch) => {
    dispatch({ type: CLIENT_REGISTER_START });
    let config = {};
    if (isInvitation) {
      config = {
        params: { isInvitation: 1 },
      };
    }
    return axios().post(defaultUri, formData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_REGISTER_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENT_REGISTER_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_UPDATE_START = 'CLIENT_UPDATE_START';
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_ERROR = 'CLIENT_UPDATE_ERROR';

export function useDispatchUpdateClient(clientId) {
  const dispatch = useDispatch();

  return React.useCallback((formData) => {
    dispatch({ type: CLIENT_UPDATE_START, payload: formData });

    let requestUri;
    if (!clientId) {
      requestUri = `${defaultUri}/${formData.clientId}`;
    } else {
      requestUri = `${defaultUri}/${clientId}`;
    }

    if (requestUri) {
      return axios().put(requestUri, formData).then((response) => {
        const { data } = response.data;
        dispatch({ type: CLIENT_UPDATE_SUCCESS, payload: data });
        return Promise.resolve(data);
      }).catch((error) => {
        dispatch({ type: CLIENT_UPDATE_ERROR, payload: error });
        return Promise.reject(error);
      });
    }
    return Promise.reject();
  }, [dispatch, clientId]);
}

/**
 * update()
 * @param formData
 * @returns {function(*, *): Promise<any | never>}
 */
export function update(formData) {
  return (dispatch, getStore) => {
    dispatch({ type: CLIENT_UPDATE_START, payload: formData });

    const { clientId } = getStore().auth;
    let requestUri;

    if (!clientId) {
      requestUri = `${defaultUri}/${formData.clientId}`;
    } else {
      requestUri = `${defaultUri}/${clientId}`;
    }

    if (requestUri) {
      return axios().put(requestUri, formData).then((response) => {
        const { data } = response.data;
        dispatch({ type: CLIENT_UPDATE_SUCCESS, payload: data });
        return Promise.resolve(data);
      }).catch((error) => {
        dispatch({ type: CLIENT_UPDATE_ERROR, payload: error });
        return Promise.reject(error);
      });
    }
    return Promise.reject();
  };
}

export const CLIENT_GETADDRESSES_START = 'CLIENT_GETADDRESSES_START';
export const CLIENT_GETADDRESSES_SUCCESS = 'CLIENT_GETADDRESSES_SUCCESS';
export const CLIENT_GETADDRESSES_ERROR = 'CLIENT_GETADDRESSES_ERROR';

/**
 * getAddresses()
 * @returns {function(*, *): Promise<any | never>}
 */
export function getAddresses() {
  return (dispatch, getStore) => {
    dispatch({ type: CLIENT_GETADDRESSES_START });

    const { clientId } = getStore().auth.client;
    const requestUri = `${defaultUri}/${clientId}/address`;

    return axios().get(requestUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_GETADDRESSES_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENT_GETADDRESSES_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_GETORDERS_START = 'CLIENT_GETORDERS_START';
export const CLIENT_GETORDERS_SUCCESS = 'CLIENT_GETORDERS_SUCCESS';
export const CLIENT_GETORDERS_ERROR = 'CLIENT_GETORDERS_ERROR';

/**
 * Return order / objects from the view_objects table. This is much more faster than the normal method to fetch
 * an order because the view is optimized with only the information needed for listView Settings
 * @param startDate
 * @param endDate
 * @returns {function(*, *): *}
 */
export function getOrders(startDate = null, endDate = null) {
  return (dispatch, getState) => {
    const { client } = getState().auth;
    dispatch({ type: CLIENT_GETORDERS_START, payload: startDate, endDate });

    const config = {
      params: {
        action: 'orders',
        mode: 'clientOnly',
        startDate,
        endDate,
      },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;

      let filteredOrders = filterRemoveOwnStorageOrders(data, client);
      filteredOrders = filterMaxTenDayInThePastOrders(filteredOrders);
      const internalOrders = filteredOrders.filter((item) => item.internal).reverse();
      const externalOrders = filteredOrders.filter((item) => !item.internal).reverse();

      const merged = {
        orders: data, internalOrders, externalOrders,
      };
      dispatch({ type: CLIENT_GETORDERS_SUCCESS, payload: (merged) || [] });
      return Promise.resolve(merged);
    }).catch((error) => {
      dispatch({ type: CLIENT_GETORDERS_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_GETRECLAMATIONS_START = 'CLIENT_GETRECLAMATIONS_START';
export const CLIENT_GETRECLAMATIONS_SUCCESS = 'CLIENT_GETRECLAMATIONS_SUCCESS';
export const CLIENT_GETRECLAMATIONS_ERROR = 'CLIENT_GETRECLAMATIONS_ERROR';

/**
 * getReclamations()
 * @param startDate
 * @param endDate
 * @returns {function(*): *}
 */
export function getReclamations(startDate = null, endDate = null) {
  return (dispatch) => {
    dispatch({ type: CLIENT_GETRECLAMATIONS_START, payload: startDate, endDate });

    const config = {
      params: {
        action: 'reclamations',
        startDate,
        endDate,
      },
    };

    return axios().get(defaultUri, config).then((response) => {
      let { data } = response.data;
      if (data) {
        data = data.reverse();
        const filteredReclamations = filterMaxTenDayInThePastService(data);
        const internalReclamations = filteredReclamations.filter((reclamation) => reclamation.internal);
        const externalReclamations = filteredReclamations.filter((reclamation) => !reclamation.internal);
        const merged = {
          ...data, reclamations: data, internalReclamations, externalReclamations,
        };

        dispatch({ type: CLIENT_GETRECLAMATIONS_SUCCESS, payload: (merged) || [] });
        return Promise.resolve(data);
      }
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: CLIENT_GETRECLAMATIONS_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_GETOBJECTSERVICES_START = 'CLIENT_GETOBJECTSERVICES_START';
export const CLIENT_GETOBJECTSERVICES_SUCCESS = 'CLIENT_GETOBJECTSERVICES_SUCCESS';
export const CLIENT_GETOBJECTSERVICES_ERROR = 'CLIENT_GETOBJECTSERVICES_ERROR';

/**
 * getServices()
 * @param startDate
 * @param endDate
 * @returns {function(*): *}
 */
export function getServices(startDate = null, endDate = null) {
  return (dispatch) => {
    dispatch({ type: CLIENT_GETOBJECTSERVICES_START, payload: startDate, endDate });

    const config = {
      params: {
        action: 'objectServices',
        startDate,
        endDate,
      },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      if (data) {
        const filteredServices = filterMaxTenDayInThePastService(data);
        const internalServices = filteredServices.filter((item) => item.internal);
        const externalServices = filteredServices.filter((item) => !item.internal);
        const merged = {
          services: data, internalServices, externalServices,
        };

        dispatch({ type: CLIENT_GETOBJECTSERVICES_SUCCESS, payload: (merged) || [] });
        return Promise.resolve(data);
      }
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: CLIENT_GETOBJECTSERVICES_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_GET_START = 'CLIENT_GET_START';
export const CLIENT_GET_SUCCESS = 'CLIENT_GET_SUCCESS';
export const CLIENT_GET_ERROR = 'CLIENT_GET_ERROR';

/**
 * If no client id ist given the client data of the current authed client will be reloaded
 * get()
 * @param clientId
 * @returns {function(*, *): Promise<unknown>}
 */
export function get(clientId = null) {
  return (dispatch, getState) => {
    dispatch({ type: CLIENT_GET_START, clientId });

    let id = clientId;
    if (!id) {
      id = getState().client.client.clientId;
    }

    return axios().get(`/client/${id}`).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENT_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_GETEXTERNALCLIENT_START = 'CLIENT_GETEXTERNALCLIENT_START';
export const CLIENT_GETEXTERNALCLIENT_SUCCESS = 'CLIENT_GETEXTERNALCLIENT_SUCCESS';
export const CLIENT_GETEXTERNALCLIENT_ERROR = 'CLIENT_GETEXTERNALCLIENT_ERROR';

/**
 * getExternalClient()
 * @param clientId
 * @returns {function(*, *): Promise<unknown>}
 */
export function getExternalClient(clientId) {
  return (dispatch) => {
    dispatch({ type: CLIENT_GETEXTERNALCLIENT_START, clientId });

    return axios().get(`/client/${clientId}`).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_GETEXTERNALCLIENT_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENT_GETEXTERNALCLIENT_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_GETORDEROFFERS_START = 'CLIENT_GETORDEROFFERS_START';
export const CLIENT_GETORDEROFFERS_SUCCESS = 'CLIENT_GETORDEROFFERS_SUCCESS';
export const CLIENT_GETORDEROFFERS_ERROR = 'CLIENT_GETORDEROFFERS_ERROR';

/**
 * Get all pending order offers for the current authed client
 * getOrderOffers()
 * @returns {function(*): Promise<unknown>}
 */
export function getOrderOffers() {
  return (dispatch) => {
    dispatch({ type: CLIENT_GETORDEROFFERS_START });

    const config = {
      params: { action: 'getOrderOffers' },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_GETORDEROFFERS_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENT_GETORDEROFFERS_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENT_GETOPENOFFERS_START = 'CLIENT_GETOPENOFFERS_START';
export const CLIENT_GETOPENOFFERS_SUCCESS = 'CLIENT_GETOPENOFFERS_SUCCESS';
export const CLIENT_GETOPENOFFERS_ERROR = 'CLIENT_GETOPENOFFERS_ERROR';

/**
 * useDispatchGetOpenOffers()
 * @returns {function(): *}
 */
export function useDispatchGetOpenOffers() {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    const config = {
      params: { action: 'openOffers' },
    };

    dispatch({ type: CLIENT_GETOPENOFFERS_START, payload: { defaultUri, config } });

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENT_GETOPENOFFERS_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENT_GETOPENOFFERS_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

/**
 * checkHasAllClientInformation()
 * Check if a client has all mandatory parameters set
 * to the profile
 * @returns {function(...[*]=)}
 */
export function checkClientHasAllInformation() {
  return (dispatch, getStore) => {
    const { client } = getStore().client;

    if (client.type === CLIENTTYPE_DEALER) {
      if (!client.devoutIdentificationNumber) {
        if (
          !client.zip || !client.city || !client.street || !client.streetNo || !client.mail || !client.vat
          || !client.phone
        ) {
          return Promise.reject();
        }
      } else if (
        !client.bankBic || !client.bankIban || !client.bankName || !client.bankOwner || !client.zip
          || !client.city || !client.street || !client.streetNo || !client.mail || !client.vat || !client.phone
      ) {
        return Promise.reject();
      }
    } else if (
      !client.bankBic || !client.bankIban || !client.bankName || !client.bankOwner || !client.zip
        || !client.city || !client.street || !client.streetNo || !client.mail || !client.vat || !client.phone
    ) {
      return Promise.reject();
    }

    return Promise.resolve();
  };
}

/**
 * checkClientHasAllDocuments
 * @returns {function(*, *): *}
 */
export function checkClientHasAllDocuments() {
  return (dispatch, getStore) => {
    const { client } = getStore().client;

    // Set default to available because monteur must not have a logo
    let logo = { available: true };
    if (client.type === CLIENTTYPE_DEALER) {
      // eslint-disable-next-line prefer-destructuring
      logo = client.documents.filter((item) => item.type === CLIENT_DOCUMENTSTYPE_LOGO)[0];
    }
    const businessRegistration = client.documents.filter(
      (item) => item.type === CLIENT_DOCUMENTSTYPE_BUSINESSREGISTRATION,
    )[0];
    const registrationCertificate = client.documents.filter(
      (item) => item.type === CLIENT_DOCUMENTSTYPE_REGISTRATIONCERTIFICATE,
    )[0];

    let hasInsurance = true;
    if (client.type === CLIENTTYPE_MONTEUR) {
      // eslint-disable-next-line prefer-destructuring
      hasInsurance = client.documents.filter(
        (item) => item.type === CLIENT_DOCUMENTSTYPE_INSURANCE,
      )[0];
    }

    if (client.legalForm > 1) {
      if (logo && registrationCertificate && hasInsurance) {
        return Promise.resolve();
      }
      return Promise.reject();
    }
    if (logo && businessRegistration && hasInsurance) {
      return Promise.resolve();
    }
    return Promise.reject();
  };
}

/**
 * checkClientHasLoadingAddress()
 * Check if the client has a loading address assigned
 * @returns {function(*): *}
 */
export function checkClientHasLoadingAddress() {
  return (dispatch) => dispatch(getClientAddresses()).then((addresses) => {
    if (addresses && addresses.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject();
  });
}

export const CLIENT_CHECKISALLOWEDTOREQUESTOFFER_START = 'CLIENT_CHECKISALLOWEDTOREQUESTOFFER_START';
export const CLIENT_CHECKISALLOWEDTOREQUESTOFFER_SUCCESS = 'CLIENT_CHECKISALLOWEDTOREQUESTOFFER_SUCCESS';
export const CLIENT_CHECKISALLOWEDTOREQUESTOFFER_ERROR = 'CLIENT_CHECKISALLOWEDTOREQUESTOFFER_ERROR';

/**
 * checkIsAllowedToRequestOffer()
 * Check if a dealer is allowed to request an offer and has all mandatory documents and informations added
 * to the profile
 * @returns {function(...[*]=)}
 */
export function checkIsAllowedToRequestOffer() {
  return (dispatch) => {
    dispatch({ type: CLIENT_CHECKISALLOWEDTOREQUESTOFFER_START });

    return dispatch(checkClientHasAllInformation()).then(() => dispatch(checkClientHasAllDocuments()).then(() => {
      dispatch({ type: CLIENT_CHECKISALLOWEDTOREQUESTOFFER_SUCCESS });
      return Promise.resolve();
    }).catch(() => {
      dispatch({ type: CLIENT_CHECKISALLOWEDTOREQUESTOFFER_ERROR });
      return Promise.reject();
    })).catch(() => {
      dispatch({ type: CLIENT_CHECKISALLOWEDTOREQUESTOFFER_ERROR });
      return Promise.reject();
    });
  };
}

export const CLIENT_CHECKISALLOWEDTOPICKORDER_START = 'CLIENT_CHECKISALLOWEDTOPICKORDER_START';
export const CLIENT_CHECKISALLOWEDTOPICKORDER_SUCCESS = 'CLIENT_CHECKISALLOWEDTOPICKORDER_SUCCESS';
export const CLIENT_CHECKISALLOWEDTOPICKORDER_ERROR = 'CLIENT_CHECKISALLOWEDTOPICKORDER_ERROR';

/**
 * checkIsAllowedToPickOrder()
 * Check if a monteur is allowed to pick an order and has all mandatory documents and informations added to the profile
 * @returns {function(...[*]=)}
 */
export function checkIsAllowedToPickOrder() {
  return (dispatch) => {
    dispatch({ type: CLIENT_CHECKISALLOWEDTOPICKORDER_START });

    return dispatch(checkClientHasAllInformation())
      .then(() => dispatch(checkClientHasAllDocuments())
        .then(() => dispatch(checkClientHasLoadingAddress()).then(() => {
          dispatch({ type: CLIENT_CHECKISALLOWEDTOPICKORDER_SUCCESS });
          return Promise.resolve();
        }).catch(() => {
          dispatch({ type: CLIENT_CHECKISALLOWEDTOPICKORDER_ERROR });
          return Promise.reject();
        })).catch(() => {
          dispatch({ type: CLIENT_CHECKISALLOWEDTOPICKORDER_ERROR });
          return Promise.reject();
        }))
      .catch(() => {
        dispatch({ type: CLIENT_CHECKISALLOWEDTOPICKORDER_ERROR });
        return Promise.reject();
      });
  };
}
