import React from 'react';
import PropTypes from 'prop-types';
import { useGetServiceIcon, useGetServiceType } from '../../Library/States/ServiceStates';

/**
 * ReclamationStatusBadge()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ServiceStatusIcon(props) {
  const { serviceState } = props;

  const getIcon = useGetServiceIcon();
  const getType = useGetServiceType();

  const getTextType = (state) => {
    const type = getType(state);
    return `text-${type.toLowerCase()}`;
  };

  return (
    <i className={`${getIcon(serviceState)} ${getTextType(serviceState)}`} />
  );
}

ServiceStatusIcon.propTypes = {
  serviceState: PropTypes.number,
};

ServiceStatusIcon.defaultProps = {
  serviceState: undefined,
};
