import React, { useReducer } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import Panel from '../../../../Components/Panel';
import Button from '../../../../Components/Button';
import { createInvoice, getInvoices, getList } from '../../Redux/Action/ClientAction';
import CustomReactSelect from '../../../../Components/CustomReactSelect';
import DatePickerInput from '../../../../Components/DatePickerInput';
import PositionList from './PositionList';
import ModalDialog from '../../../../Components/ModalDialog';
import Alert from '../../../../Components/Alert';
import LoadingModal from '../../../../Components/LoadingModal';
import { formatCurrency } from '../../../../Library/Functions';

import './style.css';
import { globalTaxRateString } from '../../../../Library/Types';

const paymentMethodOptions = [
  { value: 'debit', label: 'Bankeinzug / Überweisung' },
  { value: 'invoice', label: 'Rechnung' },
];

const paymentTypeOptions = [
  { value: 'debit', label: 'Lastschrift' },
  { value: 'voucher', label: 'Gutschrift' },
];

const initialState = {
  clientId: null,
  referenceId: null,
  paymentMethod: 'debit',
  paymentType: 'debit',
  isDebit: true,
  isVoucher: false,
  dueDate: moment().add(7, 'day'),
  performanceDate: moment().format('YYYY-MM-DD'),
  positions: [],
  clientError: false,
  positionsError: false,
  netto: '0.00',
  brutto: '0.00',
};

function reducer(state, action) {
  switch (action.type) {
    case 'clientId': return { ...state, clientId: action.payload, clientError: false };
    case 'referenceId': return { ...state, referenceId: action.payload };
    case 'paymentMethod': return { ...state, paymentMethod: action.payload };
    case 'dueDate': return { ...state, dueDate: action.payload };
    case 'performanceDate': return { ...state, performanceDate: action.payload };
    case 'paymentType': {
      return {
        ...state,
        paymentType: action.payload,
        isDebit: (action.payload === 'debit'),
        isVoucher: (action.payload === 'voucher'),
        dueDate: (action.payload === 'voucher') ? moment().add(14, 'day') : moment().add(7, 'day'),
        paymentMethod: (action.payload === 'voucher') ? 'debit' : 'invoice',
      };
    }

    case 'addPosition': {
      const mapped = state.positions.map((item, index) => ({ ...item, id: index + 1 }));
      mapped.push({ ...action.payload, id: mapped.length + 1 });

      const price = { netto: 0, brutto: 0 };
      mapped.filter((item) => {
        price.netto = parseFloat(price.netto) + parseFloat(item.priceNetto);
        price.brutto = parseFloat(price.brutto) + parseFloat(item.priceBrutto);
        return true;
      });

      return {
        ...state, positions: mapped, positionsError: false, ...price,
      };
    }
    case 'deletePosition': {
      const filtered = state.positions.filter((item) => item.id !== action.payload.id);
      const mapped = filtered.map((item, index) => ({ ...item, id: index + 1 }));

      const price = { netto: 0, brutto: 0 };
      mapped.filter((item) => {
        price.netto = parseFloat(price.netto) + parseFloat(item.priceNetto);
        price.brutto = parseFloat(price.brutto) + parseFloat(item.priceBrutto);
        return true;
      });

      return { ...state, positions: mapped, ...price };
    }

    case 'clientError': return { ...state, clientError: true };
    case 'positionsError': return { ...state, positionsError: true };

    default:
      throw new Error();
  }
}

/**
 * Create()
 * @param props
 * @returns {*}
 * @constructor
 */
function Create(props) {
  const {
    clients, dispatchGetClients, dispatchGetInvoices, dispatchCreateInvoice, history,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clientOption, setClientOption] = React.useState([]);
  const [invoices, setInvoices] = React.useState(null);
  const [invoiceOptions, setInvoiceOptions] = React.useState([]);
  const [showAskSave, setShowAskSave] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [error, setError] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [newInvoiceNumber, setNewInvoiceNumber] = React.useState(null);

  React.useEffect(() => {
    if (!clients) {
      dispatchGetClients();
    }
  }, [dispatchGetClients, clients]);

  React.useEffect(() => {
    if (clients) {
      const options = clients.map((item) => ({ value: item.clientId, label: item.name }));
      setClientOption(options);
    }
  }, [clients]);

  React.useEffect(() => {
    if (state.clientId) {
      dispatchGetInvoices(state.clientId).then((response) => setInvoices(response));
    }
  }, [state.clientId, dispatchGetInvoices]);

  React.useEffect(() => {
    if (invoices) {
      const mapped = invoices.map((item) => ({ value: item.transactionId, label: item.invoiceNumber }));
      setInvoiceOptions(mapped);
      dispatch({ type: 'referenceId', payload: null });
    } else {
      setInvoiceOptions([]);
      dispatch({ type: 'referenceId', payload: null });
    }
  }, [invoices]);

  const handleAskSave = () => {
    let hasError = false;
    if (!state.clientId) {
      dispatch({ type: 'clientError' });
      hasError = true;
    }
    if (!state.positions || state.positions.length === 0) {
      dispatch({ type: 'positionsError' });
      hasError = true;
    }

    if (!hasError) {
      setShowAskSave(true);
    }
  };

  const handleSave = () => {
    setShowAskSave(false);
    setIsLoading(true);
    dispatchCreateInvoice(state.clientId, state)
      .then((response) => {
        setNewInvoiceNumber(response.invoiceNumber);
        setShowSuccess(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.errorCode);
        setIsLoading(false);
      });
  };

  const closePage = () => {
    setShowSuccess(false);
    history.goBack();
  };

  return (
    <>
      <LoadingModal visible={isLoading} text="Rechnung wird erstellt und versendet..." />

      <Panel marginBottom={25}>
        <h5>
          Rechnung / Gutschrift erstellen
        </h5>
        <p style={{ marginBottom: 0 }}>
          Erstellen Sie eine Rechnung oder eine Gutschrift für einen Kunden.
        </p>
      </Panel>

      <Panel marginBottom={25}>
        <h6>Kopfdaten</h6>
        <div>
          <hr />
        </div>

        <div className="row">
          <CustomReactSelect
            onChange={(value) => dispatch({ type: 'clientId', payload: value })}
            options={clientOption}
            label="Kunde wählen"
            rowClass="col-lg-6"
            hasError={state.clientError}
          />
          <CustomReactSelect
            onChange={(value) => dispatch({ type: 'referenceId', payload: value })}
            options={invoiceOptions}
            label="Referenzrechnung"
            value={state.referenceId}
            rowClass="col-lg-6"
            noOptionsMessage={() => 'Keine Rechnungen vorhanden!'}
          />
          <CustomReactSelect
            onChange={(value) => dispatch({ type: 'paymentType', payload: value })}
            options={paymentTypeOptions}
            label="Rechnungstyp"
            rowClass="col-lg-6"
            value={state.paymentType}
          />
          <CustomReactSelect
            onChange={(value) => dispatch({ type: 'paymentMethod', payload: value })}
            options={paymentMethodOptions}
            label="Rechnungsart"
            rowClass="col-lg-6"
            value={state.paymentMethod}
            disabled={(state.paymentType === 'voucher')}
          />
          <DatePickerInput
            onChange={(value) => dispatch({ type: 'dueDate', payload: value })}
            label="Fälligkeitsdatum"
            rowClass="col-lg-6"
            initialValue={state.dueDate}
          />
          <DatePickerInput
            onChange={(value) => dispatch({ type: 'performanceDate', payload: value })}
            label="Leistungsdatum"
            rowClass="col-lg-6"
            initialValue={state.performanceDate}
          />
        </div>
      </Panel>

      <PositionList
        positions={state.positions}
        onAdd={(value) => dispatch({ type: 'addPosition', payload: value })}
        onDelete={(value) => dispatch({ type: 'deletePosition', payload: value })}
        hasError={state.positionsError}
        performanceDate={state.performanceDate}
      />

      <Panel boxPadding={10} marginBottom={25}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">Netto Gesamt</div>
          <div>{formatCurrency(state.netto)}</div>
        </div>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">{`zzgl. ${globalTaxRateString(state.performanceDate)} Umsatzsteuer`}</div>
          <div><div>{formatCurrency(state.brutto - state.netto)}</div></div>
        </div>
        <div className="d-flex flex-row OverallPrice">
          <div className="flex-grow-1">Brutto Gesamt</div>
          <div>{formatCurrency(state.brutto)}</div>
        </div>
      </Panel>

      <Panel boxPadding={10}>
        <div className="justify-content-end d-flex">
          <Button onClick={() => history.goBack()} type="btn-light" style={{ marginRight: 10 }}>
            Abbrechen
          </Button>
          <Button onClick={handleAskSave}>
            Rechnung speichern
          </Button>
        </div>
      </Panel>

      <ModalDialog
        onClose={() => setShowAskSave(false)}
        visible={showAskSave}
        confirmCaption="Rechnung jetzt erstellen"
        onConfirm={handleSave}
      >
        <h5>Rechnung erstellen und versenden?</h5>
        <p>
          Bitte bestätigen Sie das Sie die Rechnung jetzt erstellen möchten.
          <br />
          Der Kunde erhält umgehend eine E-Mail mit der erstellten Rechnung.
          <br />
          Bitte beachten Sie das die Löschung der Rechnung danach nicht mehr möglich ist!
        </p>
      </ModalDialog>

      <Alert visible={showSuccess} onClose={closePage} type="success">
        Die Rechnung wurde erfolgreich gespeichert und an den Kunden versendet! Die Rechnungsnummer lautet:
        {` ${(newInvoiceNumber) || ''}`}
      </Alert>
      <Alert visible={error} onClose={() => setError(undefined)} type="danger">
        {`Ein Fehler ist aufgetreten. Fehlermeldung: ${error}`}
      </Alert>
    </>
  );
}

Create.propTypes = {
  dispatchGetInvoices: PropTypes.func.isRequired,
  dispatchGetClients: PropTypes.func.isRequired,
  dispatchCreateInvoice: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
};

Create.defaultProps = {
  clients: null,
};

function mapStoreToProps(store) {
  return {
    clients: store.systemClient.clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetClients: () => dispatch(getList()),
    dispatchGetInvoices: (clientId) => dispatch(getInvoices(clientId)),
    dispatchCreateInvoice: (clientId, invoiceData) => dispatch(createInvoice(clientId, invoiceData)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Create));
