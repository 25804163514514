/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import {
  NavLink, useParams, useRouteMatch, withRouter,
} from 'react-router-dom';
import Panel from '../../Panel';
import {
  NAVIGATION_SYSTEM_ORDER_DETAILS,
  ORDERTYPE_OBJECT,
  ORDERTYPE_SINGLE,
} from '../../../Library/Types';
import ModalDialog from '../../ModalDialog';
import { remove, restoreExpired } from '../../../Pages/System/Redux/Action/OrderAction';
import { get, singleUpdate } from '../../../Redux/Action/OrderAction';
import StatusBadge from '../../StatusBadge';
import { formatDate } from '../../../Library/Functions';
import ResetOrderState from '../../../Pages/System/Component/ResetOrderState';
import { getList } from '../../../Redux/Action/ServiceReportAction';
import LoadingModal from '../../LoadingModal';
import { deleteObject } from '../../../Redux/Action/ObjectAction';
import {
  ORDERSTATE_EXPIRED, ORDERSTATE_OPEN,
  ORDERSTATE_WAITING_CALCULATION,
  ORDERSTATE_WAITING_APPROVEMENT,
} from '../../../Library/StateTypes';
import ReclamationMenu from '../ObjectDetailsRightNavigation/ReclamationMenu';
import ServiceMenu from '../ObjectDetailsRightNavigation/ServiceMenu';
import { getList as getReclamations } from '../../../Redux/Action/ReclamationAction';
import { getList as getServices } from '../../../Redux/Action/Order/Object/ServiceAction';

/**
 * ObjectDetailsNavigation()
 * @returns {*}
 * @constructor
 */
function ObjectDetailsNavigation(props) {
  const {
    order, dispatchDeleteOrder, history, dispatchGetOrder, dispatchRestoreExpired, dispatchUpdateOrder, messages,
    dispatchGetReport, report, dispatchDeleteObject, dispatchGetReclamations, client, reclamations, dispatchGetServices,
    services,
  } = props;
  const { orderId, objectId } = useParams();
  const { url } = useRouteMatch();
  const [askDelete, setAskDelete] = React.useState(false);
  const [askRestore, setAskRestore] = React.useState(false);
  const [showResetState, setShowResetState] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [object, setObject] = React.useState(null);

  React.useEffect(() => {
    if (order && order.objects && order.objects.length > 0) {
      const filtered = order.objects.filter((item) => item.objectId === parseInt(objectId, 10));
      if (filtered.length > 0) {
        setObject(filtered[0]);
      }
    } else {
      setObject(null);
    }
  }, [order, objectId, setObject]);

  React.useEffect(() => {
    setLoading(true);
    dispatchGetOrder(orderId).then(() => setLoading(false));
  }, [orderId, dispatchGetOrder]);

  React.useEffect(() => {
    if (object) {
      dispatchGetReclamations(orderId, objectId).then(() => {
        dispatchGetServices(object).then(() => {
        });
      });
    }
  }, [object, orderId, objectId, dispatchGetReclamations, dispatchGetServices]);

  React.useEffect(() => {
    dispatchGetReport(orderId, objectId).catch(() => {});
  }, [order, orderId, objectId, dispatchGetReport]);

  const renderDelete = () => {
    if (order) {
      if ((order.state === ORDERSTATE_WAITING_CALCULATION
        || order.state === ORDERSTATE_WAITING_APPROVEMENT
        || order.state === ORDERSTATE_OPEN
        || order.state === ORDERSTATE_EXPIRED) || order.type === ORDERTYPE_OBJECT
      ) {
        return (
          <li>
            {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
            <a className="nav-link" href={null} onClick={() => setAskDelete(true)} style={{ cursor: 'pointer' }}>
              <i className="fas fa-trash-alt" />
              Löschen
            </a>
          </li>
        );
      }
    }
    return null;
  };

  const renderReactivate = () => {
    if (order && !order.internal && order.state === ORDERSTATE_EXPIRED) {
      return (
        <li>
          {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
          <a className="nav-link" href={null} onClick={() => setAskRestore(true)} style={{ cursor: 'pointer' }}>
            <i className="fas fa-undo" />
            Angebot wiederherstellen
          </a>
        </li>
      );
    }
    return null;
  };

  const renderResetEditCounter = () => {
    if (order && !order.internal && order.state === ORDERSTATE_WAITING_APPROVEMENT && order.editCount === 3) {
      return (
        <li>
          {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
          <a
            className="nav-link"
            href={null}
            onClick={() => dispatchUpdateOrder(orderId, 'editCount', 0)}
            style={{ cursor: 'pointer' }}
          >
            <i className="fas fa-sort-numeric-down-alt" />
            Bearbeitungszähler zurücksetzen
          </a>
        </li>
      );
    }
    return null;
  };

  const renderTypeBadge = () => {
    if (order) {
      return (
        <StatusBadge type="Primary" caption={(order.internal) ? 'Interner Auftrag' : 'Externer Auftrag'} />
      );
    }
    return null;
  };

  const renderDateBadge = () => {
    if (object && object.montageDate && object.montageDate.date) {
      return (
        <StatusBadge type="Primary" caption={formatDate(object.montageDate.date)} containerStyle={{ marginTop: 10 }} />
      );
    }
    return null;
  };

  const handleDelete = () => {
    if (order.type === ORDERTYPE_SINGLE) {
      dispatchDeleteOrder(order.orderId);
      history.push('/system');
    } else {
      dispatchDeleteObject(orderId, objectId);
      const uri = NAVIGATION_SYSTEM_ORDER_DETAILS.replace('{orderId}', orderId);
      history.push(uri);
    }
  };

  const handleRestore = () => {
    dispatchRestoreExpired(order.orderId).then(() => {
      dispatchGetOrder(order.orderId);
      setAskRestore(false);
    });
  };

  const renderOffer = () => {
    if (order && !order.internal && order.offers && order.offers.length > 0 && order.type === ORDERTYPE_SINGLE) {
      return (
        <li>
          <NavLink className="nav-link" to={`${url}/offers`}>
            <i className="fas fa-gavel" />
            {`Individuelle Angebote (${order.offers.length})`}
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderResetOrderState = () => {
    if (order && !order.internal
      && (
        order.state === ORDERSTATE_WAITING_APPROVEMENT
        || order.state === ORDERSTATE_OPEN
      )
    ) {
      return (
        <li>
          <a
            className="nav-link"
            onClick={() => setShowResetState(true)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
          >
            <i className="fas fa-satellite-dish" />
            Auftragsstatus ändern
          </a>
        </li>
      );
    }
    return null;
  };

  const renderReport = () => {
    if (report) {
      return (
        <li key="report">
          <NavLink
            className="nav-link"
            to={`${url}/report/${report.serviceReportId}`}
          >
            <i className="fas fa-file-pdf" />
            Montagebericht
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderMessages = () => {
    let name = 'Mitteilungen';
    if (messages && messages.length > 0) {
      if (messages.length === 1) {
        name = '1 Mitteilung';
      } else {
        name = `${messages.length} Mitteilungen`;
      }
    }
    return (
      <li>
        <NavLink className="nav-link" to={`${url}/messages`}>
          <i className="fas fa-comments" />
          {name}
        </NavLink>
      </li>
    );
  };

  const getBackUrl = () => {
    if (order) {
      if (order.type === ORDERTYPE_SINGLE) {
        if (order.internal) {
          return '/system/order/internal';
        }
        return '/system/order/external';
      }
      return NAVIGATION_SYSTEM_ORDER_DETAILS.replace('{orderId}', order.orderId);
    }
    return '';
  };

  const renderReclamationAndServiceMenu = () => {
    if (order) {
      return (
        <>
          <ReclamationMenu
            order={order}
            reclamations={reclamations}
          />
          <ServiceMenu
            client={client}
            order={order}
            object={object}
            services={services}
            isSystemView
          />
        </>
      );
    }
    return null;
  };

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            Auftragsdetails
          </h6>

          {renderTypeBadge()}
          {renderDateBadge()}
        </div>

        <hr />

        <ul>
          <li>
            <NavLink className="nav-link" exact to={`${url}`}>
              <i className="fas fa-info-circle" />
              Details
            </NavLink>
          </li>
          {renderOffer()}
          {renderMessages()}
          {renderReport()}
          {renderDelete()}
          <li>
            <NavLink className="nav-link" to={`${url}/history`}>
              <i className="fas fa-history" />
              Änderungshistorie
            </NavLink>
          </li>
          {renderReactivate()}
          {renderResetEditCounter()}
          {renderResetOrderState()}
          {renderReclamationAndServiceMenu()}
          <hr />
          <li>
            <NavLink
              className="nav-link"
              to={getBackUrl()}
              activeClassName={null}
            >
              <i className="fas fa-angle-double-left" />
              Zurück
            </NavLink>
          </li>
        </ul>
      </div>

      <ModalDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        centered
        confirmCaption="Auftrag endgültig löschen"
        onConfirm={handleDelete}
      >
        <h5 className="hk-text-primary">
          <i className="fas fa-trash-alt" style={{ marginRight: 10 }} />
          Auftrag endgültig löschen?
        </h5>
        <p>
          Bitte bestätigen Sie das Sie diesen Auftrag endgültig löschen möchten. Eine Wiederherstellung des Auftrags
          ist nicht möglich!
        </p>
      </ModalDialog>

      <ModalDialog
        onClose={() => setAskRestore(false)}
        visible={askRestore}
        centered
        confirmCaption="Auftrag wiederherstellen"
        onConfirm={handleRestore}
      >
        <h5 className="hk-text-primary">
          <i className="fas fa-undo" style={{ marginRight: 10 }} />
          Auftrag wiederherstellen?
        </h5>
        <p>
          Bitte bestätigen Sie das Sie diesen Auftrag wiederherstellen möchten. Der Auftrag wird im Status
          "Wartet auf Bestätigung" wiederhergestellt und der Kunde kann den Auftrag direkt wieder annehmen oder
          bearbeiten. Das Ablaufdatum des Angebots wird wieder auf 3 Tage zurückgestellt!
        </p>
      </ModalDialog>

      <ResetOrderState visible={showResetState} onClose={() => setShowResetState(false)} order={order} />
      <LoadingModal visible={loading} text="Auftrag wird geladen..." />
    </Panel>
  );
}

ObjectDetailsNavigation.propTypes = {
  dispatchGetReport: PropTypes.func.isRequired,
  dispatchDeleteOrder: PropTypes.func.isRequired,
  dispatchRestoreExpired: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchGetServices: PropTypes.func.isRequired,
  dispatchGetReclamations: PropTypes.func.isRequired,
  dispatchUpdateOrder: PropTypes.func.isRequired,
  dispatchDeleteObject: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object),
  reclamations: PropTypes.instanceOf(Object),
  services: PropTypes.instanceOf(Object),
  order: PropTypes.instanceOf(Object),
  report: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  messages: PropTypes.instanceOf(Array),
};

ObjectDetailsNavigation.defaultProps = {
  client: null,
  order: null,
  report: null,
  messages: null,
  reclamations: null,
  services: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    order: store.order.order,
    messages: store.objectMessage.messages,
    report: store.reports.serviceReport,
    reclamations: store.reclamation.reclamations,
    services: store.objectService.services,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDeleteOrder: (orderId) => dispatch(remove(orderId)),
    dispatchDeleteObject: (orderId, objectId) => dispatch(deleteObject(orderId, objectId)),
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
    dispatchUpdateOrder: (orderId, field, value) => dispatch(singleUpdate(orderId, field, value)),
    dispatchRestoreExpired: (orderId) => dispatch(restoreExpired(orderId)),
    dispatchGetReport: (orderId, objectId) => dispatch(getList(orderId, objectId)),
    dispatchGetReclamations: (orderId, objectId) => dispatch(getReclamations(orderId, objectId)),
    dispatchGetServices: (object) => dispatch(getServices(object)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ObjectDetailsNavigation));
