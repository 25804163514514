import axios from 'axios';
import Config from '../Assets/Config';
import store from '../Redux/Store';
import { localStorageStoreName } from '../Redux/LocalStorage';

export const unknownError = { status: 500, data: { error: 'unknown' } };

/**
 * Global configuration for all ajax request to the middleware of the app
 * @returns {any}
 */
export default () => {
  let userToken = '';
  if (store.getState().auth.token) {
    userToken = store.getState().auth.token;
  }

  const defaultConfig = {
    baseURL: Config.apiGateway.url,
    timeout: 180000,
    headers: {
      Authorization: userToken,
      'Content-Type': 'application/json',
      'login-device': 'web',
    },
  };

  const axiosCreated = axios.create(defaultConfig);

  axiosCreated.interceptors.response.use((response) => (response), (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 403 || status === 401 || status === 402) {
        localStorage.removeItem(localStorageStoreName);
        window.location.reload();
      }
    }
    return Promise.reject(error.response);
  });

  /** Log all request URLs to console */
  /*
  axiosCreated.interceptors.request.use(function (config) {
      console.log('Request URL', config.baseURL + config.url);
      console.log(config);
      return config;
  }, function (error) {
      console.log('Request Error', error.response);
      return Promise.reject(error);
  });
  */

  return axiosCreated;
};
