import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../../../../Components/Panel';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import { formatCurrency } from '../../../../Library/Functions';

/**
 * OrderCalculationLog()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function OrderCalculationLog(props) {
  const { order, visible } = props;
  const [log, setLog] = React.useState(null);

  React.useEffect(() => {
    if (order && order.lastCalculationLog) {
      setLog(order.lastCalculationLog);
    }
  }, [order]);

  if (!order.internal && log && visible) {
    return (
      <Panel>
        <h6>Informationen zur letzten Berechnung</h6>
        <p>
          Dies zeigt die Informationen der zuletzt ausgeführten Berechnung zu diesem Auftrag!
        </p>

        <div className="row">
          <div className="col-lg-4">
            <KeyValueEditRow type="text" title="Montagepreis" displayText={formatCurrency(log.montagePrice)} />
          </div>
          <div className="col-lg-4">
            <KeyValueEditRow type="text" title="Lieferpreis" displayText={formatCurrency(log.deliveryPrice)} />
          </div>
          <div className="col-lg-4">
            <KeyValueEditRow type="text" title="Lagerpreis" displayText={formatCurrency(log.storagePrice)} />
          </div>
          <div className="col-lg-4">
            <KeyValueEditRow
              type="text"
              title="Entfernung"
              displayText={(log.distance) ? `${log.distance}km` : 'Keine Angabe'}
            />
          </div>
          <div className="col-lg-4">
            <KeyValueEditRow type="text" title="Montagezeit" displayText={`${log.montageTime} Minuten`} />
          </div>
          <div className="col-lg-4">
            <KeyValueEditRow type="text" title="Gesamtpreis" displayText={formatCurrency(log.overall)} />
          </div>
        </div>
      </Panel>
    );
  }
  return null;
}

OrderCalculationLog.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool,
};

OrderCalculationLog.defaultProps = {
  visible: true,
};
