import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../ModalDialog';

/**
 * ConfirmDeleteDialog()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ConfirmAttachToMailDialog(props) {
  const {
    visible, onClose, onConfirm, item, type,
  } = props;

  const getText = () => {
    const typeName = (type === 'service') ? 'Servicebericht' : 'Montagebericht';

    return (
      <>
        Bitte bestätigen Sie, dass Sie das Dokument
        <strong>{` ${item.filename} `}</strong>
        als E-Mail Anhang an den Kunden senden möchten. Der Kunde erhält dieses Dokument als Anhang
        zum
        <span>{` ${typeName}`}</span>
        !
      </>
    );
  };

  return (
    <ModalDialog
      visible={visible}
      onClose={onClose}
      confirmCaption="OK, verstanden!"
      confirmClass="btn-success"
      onConfirm={() => onConfirm()}
      centered
    >
      <h5 style={{ marginBottom: 20 }}>Dokument an Kunden senden?</h5>
      <p style={{ fontSize: 15 }}>
        {getText()}
      </p>
    </ModalDialog>
  );
}

ConfirmAttachToMailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['object', 'service']).isRequired,
};
