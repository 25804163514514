import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../../../Components/ModalDialog';
import Textarea from '../../../../Components/Textarea';
import CustomReactSelect from '../../../../Components/CustomReactSelect';
import { CLIENTNOTE_TYPE_PHONE, clientNoteTypeOptions } from '../../../../Library/Types';

/**
 * UpdateNote()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UpdateNote(props) {
  const {
    visible, onClose, onUpdate, note,
  } = props;
  const [text, setText] = React.useState();
  const [type, setType] = React.useState(CLIENTNOTE_TYPE_PHONE);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    if (note) {
      setText(note.text);
      setType(note.type);
    }
  }, [note]);

  const handleSave = () => {
    setHasError(false);
    if (text && text.length > 0) {
      onUpdate({ ...note, text, type });
    } else {
      setHasError(true);
    }
  };

  return (
    <ModalDialog
      onClose={onClose}
      visible={visible}
      confirmCaption="Änderungen speichern"
      onConfirm={handleSave}
    >
      <h5>Notiz Bearbeiten</h5>
      <p>Neue Notiz zum Kunden bearbeiten</p>
      <hr />

      <div className="row">
        <CustomReactSelect onChange={setType} options={clientNoteTypeOptions} label="Kontaktoption" value={type} />
        <Textarea
          onChange={setText}
          value={text}
          rows={6}
          rowClass="col-lg-12"
          label="Notiz / Bemerkung"
          autoFocus
          hasError={hasError}
        />
      </div>

    </ModalDialog>
  );
}

UpdateNote.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  note: PropTypes.instanceOf(Object),
};

UpdateNote.defaultProps = {
  visible: false,
  note: null,
};
