/**
 * CreateServiceValidator
 */
import { OBJECT_SERVICE_TYPE_CHECK, OBJECT_SERVICE_TYPE_RENTAL_KITCHEN } from '../Types';

export default class CreateServiceValidator {
  constructor() {
    this.data = {
      type: false,
      title: false,
      items: false,
    };
  }

  hasError() {
    let hasError = false;

    Object.keys(this.data).forEach((value) => {
      if (this.data[value]) {
        hasError = true;
      }
    });

    return hasError;
  }

  isValid(data) {
    if (!data.type) {
      this.data = { ...this.data, type: true };
    }
    if (data.type !== OBJECT_SERVICE_TYPE_CHECK && data.type !== OBJECT_SERVICE_TYPE_RENTAL_KITCHEN) {
      if (!data.items || data.items.length <= 0) {
        this.data = { ...this.data, items: true };
      }
    }

    return !this.hasError();
  }

  getMessages() {
    return this.data;
  }
}
