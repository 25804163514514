import React from 'react';
import PropTypes from 'prop-types';
import * as Redux from 'react-redux';
import moment from 'moment';
import CustomReactSelect from '../../CustomReactSelect';
import { useDispatchGetClientAddress } from '../../../Redux/Action/ClientAddressAction';
import DatePickerInput from '../../DatePickerInput';

export default function SelectStoragePartner(props) {
  const {
    state, onChange, headline, errors,
  } = props;

  const dispatchGetClientAddress = useDispatchGetClientAddress();

  const partners = Redux.useSelector((store) => store.client.partners);
  const storagePartners = React.useMemo(
    () => (partners ? partners.filter((item) => item.partnerSettings.allowStorage) : null),
    [partners],
  );
  const partnerOptions = React.useMemo(
    () => (
      (storagePartners && storagePartners.length > 0) ? storagePartners.map(
        (item) => ({
          label: item.partner.name,
          value: item.partner.clientId,
        }),
      ) : null),
    [storagePartners],
  );

  const handlePartnerUpdate = (storageClientId) => {
    if (onChange) {
      const partner = partners.find((item) => item.partner.clientId === storageClientId);
      dispatchGetClientAddress(storageClientId, partner.partnerSettings.defaultClientAddressId).then(
        (response) => {
          const loadingAddress = (Object.keys(response).length > 0) ? response : {};
          onChange({ storageClientId, loadingAddress });
        },
      );
    }
  };

  if (partnerOptions) {
    return (
      <div className="row">
        <div className="col-lg-12" style={{ marginBottom: 10 }}>
          <h5>{headline}</h5>
          <p className="text-muted">
            Bitte wählen Sie den Partner bei dem Sie die Ware lagern möchten.
          </p>
        </div>

        <CustomReactSelect
          onChange={handlePartnerUpdate}
          options={partnerOptions}
          rowClass="col-lg-12"
          value={state.storageClientId}
          hasError={!!errors.storageClientId}
        />
        <div className="col-lg-12">
          <DatePickerInput
            onChange={(storageDate) => onChange({ storageDate })}
            value=""
            placeholder="Voraussichtliches Datum der Anlieferung"
            rightIcon="fas fa-calendar-alt"
            minDate={moment().toDate()}
          />
        </div>
      </div>
    );
  }
  return null;
}

SelectStoragePartner.propTypes = {
  state: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  headline: PropTypes.string,
  errors: PropTypes.instanceOf(Object),
};

SelectStoragePartner.defaultProps = {
  state: {
    storageClientId: null,
    loadingAddress: null,
    storageDate: null,
  },
  onChange: undefined,
  headline: 'Partnerauswahl für Externe Lagerung',
  errors: {},
};
