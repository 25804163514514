import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TextArea = styled.textarea`
  &::placeholder {
    color: #cdcdcd !important;
    font-size: 14px;
  }

  &:focus {
    box-shadow: none !important;
    border-color: ${(props) => props.theme.color.primaryTextColor} !important;

    &.is-invalid {
      border-color: ${(props) => props.theme.color.borderColorGray} !important;
    }
  }
`;

/**
 * Textarea()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Textarea(props) {
  const {
    value, onChange, hasError, placeholder, rowClass, disabled, autoComplete, required, readOnly, inputRef, rows,
    autoFocus, style, onBlur,
  } = props;

  const getInputClass = () => {
    if (hasError) {
      return 'form-control is-invalid';
    }
    return 'form-control';
  };

  return (
    <div className={rowClass}>
      <div className="form-group" style={style}>
        <RenderLabel {...props} />
        <div className="input-group">
          <TextArea
            ref={inputRef}
            required={required}
            placeholder={placeholder}
            className={getInputClass()}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            rows={rows}
            disabled={disabled}
            autoComplete={autoComplete}
            readOnly={readOnly}
            autoFocus={autoFocus}
            onBlur={onBlur}
          />
          <RenderErrorMessage {...props} />
          <RenderRightAddon {...props} />
        </div>
      </div>
    </div>
  );
}

Textarea.propTypes = {
  rightIcon: PropTypes.string,
  rightText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  rowClass: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.oneOf(['false', 'true']),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  inputRef: PropTypes.instanceOf(Object),
  rows: PropTypes.number,
  autoFocus: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
};

Textarea.defaultProps = {
  autoFocus: false,
  rightIcon: null,
  rightText: null,
  label: null,
  value: '',
  hasError: false,
  errorMessage: '',
  placeholder: '',
  rowClass: '',
  disabled: false,
  autoComplete: 'true',
  required: false,
  readOnly: false,
  inputRef: null,
  rows: 1,
  style: {},
  onBlur: () => {},
};

/**
 * RenderLabel()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderLabel(props) {
  // eslint-disable-next-line react/prop-types
  const { label, tooltip, required } = props;

  const renderTooltip = () => {
    if (tooltip && tooltip !== '') {
      return (
        <i
          className="fas fa-info-circle"
          data-toggle="tooltip"
          data-placement="right"
          title={tooltip}
        />
      );
    }
    return null;
  };

  if (label) {
    return (
      <label>
        {(required) ? `${label}*` : label}
        {' '}
        {renderTooltip()}
      </label>
    );
  }
  return null;
}

/**
 * RenderRightAddon()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderRightAddon(props) {
  // eslint-disable-next-line react/prop-types
  const { rightIcon, rightText } = props;

  if (rightIcon) {
    return (
      <div className="input-group-append">
        <span className="input-group-text">
          <i className={rightIcon} />
        </span>
      </div>
    );
  }

  if (rightText) {
    return (
      <div className="input-group-append">
        <span className="input-group-text">
          {rightText}
        </span>
      </div>
    );
  }

  return null;
}

/**
 * RenderErrorMessage()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function RenderErrorMessage(props) {
  // eslint-disable-next-line react/prop-types
  const { errorMessage } = props;

  if (errorMessage) {
    return (
      <div className="invalid-tooltip">
        {errorMessage}
      </div>
    );
  }
  return null;
}
