import React, { useState } from 'react';
import Panel from '../../../Components/Panel';
import Input from '../../../Components/Input';
import Textarea from '../../../Components/Textarea';
import Button from '../../../Components/Button';
import ModalDialog from '../../../Components/ModalDialog';
import { useDispatchSendFeedbackMail } from '../../../Redux/Action/MailerAction';

/**
 * Material()
 * @returns {*}
 * @constructor
 */
export default function Feedback() {
  const [subject, setSubject] = useState('');
  const [feedbackContent, setFeedbackContent] = useState('');
  const [showModal, setShowModal] = useState(false);

  const dispatchSendFeedbackMail = useDispatchSendFeedbackMail();

  const handleSendFeedback = () => {
    if (feedbackContent.length < 2) {
      return;
    }
    dispatchSendFeedbackMail(subject, feedbackContent);
    setShowModal(true);
    setSubject('');
    setFeedbackContent('');
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <Panel marginBottom={20}>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h4>Geben Sie uns Ihr ehrliches und konstruktives Feedback</h4>
              <p style={{ marginBottom: 0 }}>
                Ihr Feedback ist uns wichtig. Für uns geht es darum unsere Software und Dienstleistung optimal auf
                unsere Kunden auszurichten, um den Mehrwert, die Qualität und Leistung für Sie kontinuierlich
                zu verbessern. Ein für uns wichtiges Merkmal der der Kundenzufriedenheit besteht darin,
                dass
                <strong> Verbesserungen in enger Zusammenarbeit mit unseren Kunden </strong>
                umgesetzt werden.
                Sie haben wesentlichen Einfluss auf die Entwicklungen von hey.kitchen. Wir arbeiten jeden Tag dafür,
                dass Sie maximale Effizienz für Ihre Prozesse erreichen.
              </p>
            </div>
          </div>
        </Panel>
        <Panel marginBottom={20}>
          <div style={{ margin: 'auto', width: 1000 }}>
            <Input
              placeholder="Betreff"
              onChange={(value) => setSubject(value)}
              value={subject}
              required
              autoFocus
            />
            <Textarea
              rows={10}
              onChange={setFeedbackContent}
              value={feedbackContent}
              placeholder="Ihre Nachricht"
            />
            <Button
              style={{ float: 'right' }}
              onClick={() => handleSendFeedback()}
            >
              Feedback senden
            </Button>
          </div>
        </Panel>
      </div>
      <ModalDialog
        visible={showModal}
        onClose={() => setShowModal(false)}
        hideConfirmButton
        closeCaption="Schließen"
      >
        <h4 style={{ marginBottom: 10 }}>Die Nachricht wurde versendet</h4>
        Vielen Dank für Ihre Nachricht.
        <br />
        Bei Rückfragen werden wir uns gegebenenfalls noch einmal bei Ihnen melden.
      </ModalDialog>
    </div>
  );
}

Feedback.propTypes = {
};
