import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Panel from '../../../../Components/Panel';
import {
  CLIENTTYPE_DEALER, CLIENTTYPE_MONTEUR,
  ORDERTYPE_OBJECT,
  ORDERTYPE_SINGLE,
} from '../../../../Library/Types';
import {
  OBJECT_DATESTATE_ASSIGNED,
  OBJECT_DATESTATE_CLOSED,
  OBJECT_DATESTATE_FINISHED,
  OBJECT_DATESTATE_OPEN,
  OBJECT_DATESTATE_SCHEDULED, ORDERSTATE_EXPIRED,
  ORDERSTATE_FINISHED,
  ORDERSTATE_OPEN,
  ORDERSTATE_WAITING_APPROVEMENT,
  ORDERSTATE_WAITING_CALCULATION,
  RECLAMATIONSTATE_ASSIGNED,
  RECLAMATIONSTATE_CLOSED,
  RECLAMATIONSTATE_OPEN,
  RECLAMATIONSTATE_REPORT_FINISHED,
  RECLAMATIONSTATE_SCHEDULED,
  RECLAMATIONSTATE_WAITING_APPOINTMENT,
  RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT,
} from '../../../../Library/StateTypes';
import {
  SERVICE_APPOINTMENT_WAITING_FOR_APPROVAL,
  SERVICE_APPOINTMENT_WAITING_FOR_CONFIRMATION,
  SERVICE_APPOINTMENT_WAITING_FOR_PROPOSED_DATES,
  SERVICESTATE_ASSIGNED,
  SERVICESTATE_CLOSED,
  SERVICESTATE_OPEN,
  SERVICESTATE_REPORT_FINISHED,
  SERVICESTATE_SCHEDULED, SERVICESTATE_WAITING_APPOINTMENT, SERVICESTATE_WAITING_APPOINTMENT_APPROVEMENT,
} from '../../../../Library/States/ServiceStates';
import PartnerButton from './PartnerButton';
import ClearableDatePicker from '../../../../Components/ClearableDatePicker/ClearableDatePicker';
import { filterRemoveOwnStorageOrders } from '../../../../Library/Functions';
import { useGetAppointmentCaption } from '../../../../Library/States/AppointmentStates';
import ShowHideContainer from '../../../../Components/ShowHideContainer';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;

  .FilterCard {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 8px 15px;
    background-color: ${(props) => props.theme.color.grayBackgroundColor};
    border-radius: ${(props) => props.theme.border.radius};
    margin-right: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
    max-height: 3em;

    &:hover {
      ${(props) => props.theme.boxShadowDarkHover};
    }

    &:last-child {
      margin-right: 0;
    }

    &.Selected {
      background-color: ${(props) => props.theme.color.primaryRedColor};
      color: #fff;
    }
  }
  
  .FilterCardButton {
  padding-left: 3px;
  padding-right: 3px;
  height: 40%;
  background-color: transparent;
  }

  .Spacer {
    border-left: 1px solid #cbcbcb;
    width: 10px;
    margin: -20px 20px;
  }

  .Caption {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 30px;
  }
`;

/** Default getOrders() range */
const GET_ORDERS_DAYS_PAST = 28;
const GET_ORDERS_DAYS_FUTURE = 28;
const STORAGE_ORDER_FILTER_SAVE_KEY = 'lastOrderFilter';

const defaultState = {
  partnerId: null,
  assingedPartnerId: null,
  internal: false,
  external: false,
  scheduled: false,
  orders: false,
  object: false,
  single: false,
  service: false,
  finished: false,
  waitingCalculation: false,
  waitingApprovement: false,
  waitingAppointment: false,
  waitingAppointmentApprovement: false,
  appointmentWaitingForApproval: false,
  appointmentWaitingForProposedDates: false,
  appointmentWaitingForConfirmation: false,
  reclamation: false,
  startDate: moment().subtract(GET_ORDERS_DAYS_PAST, 'days').format('YYYY-MM-DD'),
  endDate: moment().add(GET_ORDERS_DAYS_FUTURE, 'days').format('YYYY-MM-DD'),
};

function initReducer(state) {
  const savedState = JSON.parse(localStorage.getItem(STORAGE_ORDER_FILTER_SAVE_KEY));
  if (savedState) {
    if (!savedState.startDate) {
      savedState.startDate = moment().subtract(GET_ORDERS_DAYS_PAST, 'days').format('YYYY-MM-DD');
    }
    if (!savedState.endDate) {
      savedState.endDate = moment().add(GET_ORDERS_DAYS_FUTURE, 'days').format('YYYY-MM-DD');
    }
  }
  return { ...state, ...savedState };
}

function reducer(state, action) {
  switch (action.type) {
    case 'reset': {
      return { ...defaultState };
    }
    case 'partnerId':
      return {
        ...state,
        partnerId: action.payload,
      };
    case 'assingedPartnerId':
      return {
        ...state,
        assingedPartnerId: action.payload,
      };
    case 'orders':
      if ((!state.service)) {
        return {
          ...state,
          orders: !state.orders,
          waitingAppointment: false,
          waitingAppointmentApprovement: false,
          appointmentWaitingForApproval: false,
          appointmentWaitingForProposedDates: false,
          appointmentWaitingForConfirmation: false,
        };
      }
      return {
        ...state, orders: !state.orders,
      };
    case 'object':
      return {
        ...state, object: !state.object,
      };
    case 'single':
      return {
        ...state, single: !state.single,
      };
    case 'internal':
      if ((!state.external)) {
        return {
          ...state,
          internal: !state.internal,
          waitingApprovement: false,
          waitingCalculation: false,
          waitingAppointment: false,
          waitingAppointmentApprovement: false,
          appointmentWaitingForApproval: false,
          appointmentWaitingForProposedDates: false,
          appointmentWaitingForConfirmation: false,
        };
      }
      return {
        ...state, internal: !state.internal,
      };
    case 'external':
      if ((state.internal && state.external)) {
        return {
          ...state,
          external: !state.external,
          waitingApprovement: false,
          waitingCalculation: false,
          waitingAppointment: false,
          waitingAppointmentApprovement: false,
          appointmentWaitingForApproval: false,
          appointmentWaitingForProposedDates: false,
          appointmentWaitingForConfirmation: false,
        };
      }
      if ((state.internal && !state.external)) {
        return {
          ...state, external: !state.external, waitingApprovement: false, waitingCalculation: false,
        };
      }
      return {
        ...state, external: !state.external,
      };
    case 'service':
      if ((state.orders && state.service)) {
        return {
          ...state,
          service: !state.service,
          waitingAppointment: false,
          waitingAppointmentApprovement: false,
          appointmentWaitingForApproval: false,
          appointmentWaitingForProposedDates: false,
          appointmentWaitingForConfirmation: false,
        };
      }
      return {
        ...state, service: !state.service,
      };
    case 'open':
      return {
        ...state, open: !state.open,
      };
    case 'assigned':
      return {
        ...state, assigned: !state.assigned,
      };
    case 'scheduled':
      return {
        ...state, scheduled: !state.scheduled,
      };
    case 'closed':
      return {
        ...state, closed: !state.closed,
      };
    case 'finished':
      return {
        ...state, finished: !state.finished,
      };
    case 'waitingCalculation':
      return {
        ...state, waitingCalculation: !state.waitingCalculation,
      };
    case 'waitingApprovement':
      return {
        ...state, waitingApprovement: !state.waitingApprovement,
      };
    case 'waitingAppointment':
      return {
        ...state, waitingAppointment: !state.waitingAppointment,
      };
    case 'waitingAppointmentApprovement':
      return {
        ...state, waitingAppointmentApprovement: !state.waitingAppointmentApprovement,
      };
    case 'appointmentWaitingForApproval':
      return {
        ...state, appointmentWaitingForApproval: !state.appointmentWaitingForApproval,
      };
    case 'appointmentWaitingForProposedDates':
      return {
        ...state, appointmentWaitingForProposedDates: !state.appointmentWaitingForProposedDates,
      };
    case 'appointmentWaitingForConfirmation':
      return {
        ...state, appointmentWaitingForConfirmation: !state.appointmentWaitingForConfirmation,
      };
    case 'startDate':
      return {
        ...state, startDate: action.payload,
      };
    case 'endDate':
      return {
        ...state, endDate: action.payload,
      };
    default:
      throw new Error();
  }
}

/**
 * Filter()
 * @param props
 * @returns {*}
 * @constructor
 */
function Filter(props) {
  const {
    orders, reclamations, services, onChange, client, partners, onDateFilterChange,
  } = props;
  const [state, dispatch] = useReducer(reducer, defaultState, initReducer);
  const [prevState, setPrevState] = useState(JSON.stringify(state));
  const [init, setInit] = useState(false);
  const [partnerOption, setPartnerOption] = React.useState([]);
  const [assignedPartnerOption, setAssignedPartnerOption] = React.useState([]);

  const getAppointmentCaption = useGetAppointmentCaption();

  React.useEffect(() => {
    if (partners) {
      const sorted = partners.sort((a, b) => a.partner.name.localeCompare(b.partner.name));
      const partnerOptionArray = sorted.map((item) => ({
        label: item.partner.name,
        value: item.partner.clientId,
      }));
      partnerOptionArray.unshift();
      setPartnerOption([{ label: 'Von Partner', value: null }, ...partnerOptionArray]);
      setAssignedPartnerOption([{ label: 'An Partner', value: null }, ...partnerOptionArray]);
    }
  }, [partners]);

  React.useEffect(() => {
    setInit(false);
  }, [orders, reclamations, services]);

  React.useEffect(() => {
    const getDate = (order) => {
      if (order.date) {
        return order.date;
      }
      return order.executionDate;
    };

    if (JSON.stringify(state) !== prevState || !init) {
      let filterArray = [];

      if (state.service || state.single || state.object || state.orders) {
        if (state.orders || !state.service) {
          filterArray = filterArray.concat(filterRemoveOwnStorageOrders(orders, client));
        }
        if (state.service || !state.orders) {
          filterArray = filterArray.concat(services);
          filterArray = filterArray.concat(reclamations);
        }
        if (state.single || state.object) {
          filterArray = filterArray.filter(
            (item) => {
              if (!state.single && item.orderType === ORDERTYPE_SINGLE) return false;
              return !(!state.object && item.orderType === ORDERTYPE_OBJECT);
            },
          );
        }
      } else {
        filterArray = filterArray.concat(filterRemoveOwnStorageOrders(orders, client));
        filterArray = filterArray.concat(services);
        filterArray = filterArray.concat(reclamations);
      }
      if (state.partnerId) {
        filterArray = filterArray.filter(
          (item) => (item.clientId === state.partnerId || item.mandantId === state.partnerId),
        );
      }
      if (state.assingedPartnerId) {
        filterArray = filterArray.filter(
          (item) => (item.assignedClientId === state.assingedPartnerId),
        );
      }

      filterArray = filterArray.filter((order) => {
        if ((state.single && !state.external) && order.orderType !== ORDERTYPE_SINGLE) return false;
        if ((state.object && !state.single) && order.orderType !== ORDERTYPE_OBJECT) return false;
        if ((state.internal && !state.external) && !order.internal) return false;
        if ((state.external && !state.internal) && order.internal) return false;

        if (state.open || state.assigned || state.scheduled || state.hasReclamations || state.closed
            || state.waitingCalculation || state.waitingApprovement || state.waitingAppointment
          || state.waitingAppointmentApprovement
          || state.appointmentWaitingForApproval
          || state.appointmentWaitingForProposedDates
          || state.appointmentWaitingForConfirmation
          || state.finished
        ) {
          // if order, external and client is dealer, filter by orderState
          if (!order.reclamationId && !order.serviceId && !order.internal && client.type === CLIENTTYPE_DEALER) {
            if (!state.waitingCalculation && order.orderState === ORDERSTATE_WAITING_CALCULATION) return false;
            if (!state.waitingApprovement && order.orderState === ORDERSTATE_WAITING_APPROVEMENT) return false;
            if (!state.open && order.orderState === ORDERSTATE_OPEN) return false;
            if (!state.finished && order.orderState === ORDERSTATE_FINISHED) return false;
            return !(!state.closed && order.orderState === ORDERSTATE_EXPIRED);
          }

          if (
            !state.open && (
              (!order.reclamationId && !order.serviceId && order.state === OBJECT_DATESTATE_OPEN)
              || (order.serviceId && order.state === SERVICESTATE_OPEN)
              || (order.reclamationId && order.state === RECLAMATIONSTATE_OPEN)
            )
          ) return false;
          if (!state.assigned && (
            (!order.reclamationId && !order.serviceId && order.state === OBJECT_DATESTATE_ASSIGNED)
              || (order.serviceId && order.state === SERVICESTATE_ASSIGNED)
              || (order.reclamationId && order.state === RECLAMATIONSTATE_ASSIGNED)
          )
          ) return false;
          if (!state.scheduled && (
            (!order.reclamationId && !order.serviceId && order.state === OBJECT_DATESTATE_SCHEDULED)
              || (order.serviceId && order.state === SERVICESTATE_SCHEDULED)
              || (order.reclamationId && order.state === RECLAMATIONSTATE_SCHEDULED)
          )
          ) return false;
          if (!state.finished && (
            (!order.reclamationId && !order.serviceId && order.state === OBJECT_DATESTATE_FINISHED)
              || (order.serviceId && order.state === SERVICESTATE_REPORT_FINISHED)
              || (order.reclamationId && order.state === RECLAMATIONSTATE_REPORT_FINISHED)
          )
          ) return false;
          if (!state.closed && (
            (!order.reclamationId && !order.serviceId && order.state === OBJECT_DATESTATE_CLOSED)
              || (order.serviceId && order.state === SERVICESTATE_CLOSED)
              || (order.reclamationId && order.state === RECLAMATIONSTATE_CLOSED)
          )
          ) return false;
          if (!state.waitingCalculation
              && order.reclamationId
              && !order.serviceId
              && order.state === ORDERSTATE_WAITING_CALCULATION) {
            return false;
          }
          if (!state.waitingApprovement
              && order.reclamationId
              && !order.serviceId
              && order.state === ORDERSTATE_WAITING_APPROVEMENT) {
            return false;
          }
          if (!state.waitingAppointment
            && ((order.serviceId && order.state === SERVICESTATE_WAITING_APPOINTMENT)
            || (order.reclamationId && order.state === RECLAMATIONSTATE_WAITING_APPOINTMENT))) {
            return false;
          }
          if (!state.waitingAppointmentApprovement
            && ((order.serviceId && order.state === SERVICESTATE_WAITING_APPOINTMENT_APPROVEMENT)
              || (order.reclamationId && order.state === RECLAMATIONSTATE_WAITING_APPOINTMENT_APPROVEMENT))) {
            return false;
          }
          if (
            !state.appointmentWaitingForApproval
            && order.serviceId
            && order.state === SERVICE_APPOINTMENT_WAITING_FOR_APPROVAL
          ) {
            return false;
          }
          if (
            !state.appointmentWaitingForProposedDates
            && order.serviceId
            && order.state === SERVICE_APPOINTMENT_WAITING_FOR_PROPOSED_DATES
          ) {
            return false;
          }
          if (
            !state.appointmentWaitingForConfirmation
            && order.serviceId
            && order.state === SERVICE_APPOINTMENT_WAITING_FOR_CONFIRMATION
          ) {
            return false;
          }
        }
        return true;
      });

      if (state.startDate) {
        filterArray = filterArray.filter((item) => {
          if (getDate(item)) {
            return (moment(state.startDate).isSameOrBefore(getDate(item)));
          }
          return true;
        });
      }

      if (state.endDate) {
        filterArray = filterArray.filter((item) => {
          if (getDate(item)) {
            return (moment(state.endDate).isSameOrAfter(getDate(item)));
          }
          return true;
        });
      }
      setInit(true);
      setPrevState(JSON.stringify(state));
      localStorage.setItem(STORAGE_ORDER_FILTER_SAVE_KEY, JSON.stringify(state));
      if (onChange) {
        if (filterArray) {
          filterArray = filterArray.sort((a, b) => {
            if (!getDate(a)) {
              return -1;
            }
            return (moment(getDate(a)).isAfter(moment(getDate(b))) ? -1 : 1);
          });
        }
        onChange(filterArray, state.orders);
      }
    }
  }, [state, onChange, orders, services, prevState, reclamations, client, init]);

  React.useEffect(() => {
    onDateFilterChange(state.startDate, state.endDate);
  }, [state.startDate, state.endDate, onDateFilterChange]);

  const handleStartDateChange = (startDate) => {
    let formatted = null;
    if (startDate) {
      formatted = moment(startDate).format('YYYY-MM-DD');
    }
    dispatch({ type: 'startDate', payload: formatted });
  };

  const handleEndDateChange = (endDate) => {
    let formatted = null;
    if (endDate) {
      formatted = moment(endDate).format('YYYY-MM-DD');
    }
    dispatch({ type: 'endDate', payload: formatted });
  };

  const renderOrderFilters = () => (
    <>
      <div className="Spacer" />
      <div className="d-flex flex-wrap">
        <FilterCard
          caption="Aufträge"
          selected={state.orders}
          onClick={() => dispatch({ type: 'orders' })}
        />
        <FilterCard
          caption="Services"
          selected={state.service}
          onClick={() => dispatch({ type: 'service' })}
        />
      </div>
      <div className="Spacer" />
      <div className="d-flex flex-wrap">
        <FilterCard
          caption="Einzelaufträge"
          selected={state.single}
          onClick={() => dispatch({ type: 'single' })}
        />
        <FilterCard
          caption="Objektaufträge"
          selected={state.object}
          onClick={() => dispatch({ type: 'object' })}
        />
      </div>
      <div className="Spacer" />
      <div className="d-flex flex-wrap">
        <FilterCard
          caption="Intern"
          selected={state.internal}
          onClick={() => dispatch({ type: 'internal' })}
        />
        <FilterCard
          caption="Extern"
          selected={state.external}
          onClick={() => dispatch({ type: 'external' })}
        />
      </div>
      <div className="Spacer" />
      <div className="d-flex flex-wrap">
        <FilterCard
          caption="Wartet auf Berechnung"
          selected={state.waitingCalculation}
          onClick={() => dispatch({ type: 'waitingCalculation' })}
          visible={client.type
                  && client.type === CLIENTTYPE_DEALER
                  && (!state.internal || (state.internal && state.external))}
        />
        <FilterCard
          caption="Wartet auf Ihre Bestätigung"
          selected={state.waitingApprovement}
          onClick={() => dispatch({ type: 'waitingApprovement' })}
          visible={client.type
          && client.type === CLIENTTYPE_DEALER
          && (!state.internal || (state.internal && state.external))}
        />
        <FilterCard
          caption="Offen"
          selected={state.open}
          onClick={() => dispatch({ type: 'open' })}
        />
        <FilterCard
          caption="Terminiert"
          selected={state.scheduled}
          onClick={() => dispatch({ type: 'scheduled' })}
          visible={(client.type && client.type === CLIENTTYPE_MONTEUR)
                  || (client.type === CLIENTTYPE_DEALER
                  && (!state.external || (state.internal && state.external)))}
        />
        <FilterCard
          caption="Zugewiesen"
          selected={state.assigned}
          onClick={() => dispatch({ type: 'assigned' })}
          visible={(client.type && client.type === CLIENTTYPE_MONTEUR)
            || (client.type === CLIENTTYPE_DEALER
            && (!state.external || (state.internal && state.external)))}
        />
        <FilterCard
          caption="Abgeschlossen"
          selected={state.finished}
          onClick={() => dispatch({ type: 'finished' })}
        />
        <FilterCard
          caption="Erledigt"
          selected={state.closed}
          onClick={() => dispatch({ type: 'closed' })}
        />
        <FilterCard
          caption="Wartet auf Terminvorschlag"
          selected={state.waitingAppointment}
          onClick={() => dispatch({ type: 'waitingAppointment' })}
          visible={(state.service || (state.orders === state.service))
          && (state.external || (state.external === state.internal))}
        />
        <FilterCard
          caption="Wartet auf Terminbestätigung"
          selected={state.waitingAppointmentApprovement}
          onClick={() => dispatch({ type: 'waitingAppointmentApprovement' })}
          visible={(state.service || (state.orders === state.service))
                    && (state.external || (state.external === state.internal))}
        />
        <FilterCard
          caption={getAppointmentCaption(SERVICE_APPOINTMENT_WAITING_FOR_APPROVAL)}
          selected={state.appointmentWaitingForApproval}
          onClick={() => dispatch({ type: 'appointmentWaitingForApproval' })}
          visible={(state.service || (state.orders === state.service))
                    && (state.external || (state.external === state.internal))}
        />
        <FilterCard
          caption={getAppointmentCaption(SERVICE_APPOINTMENT_WAITING_FOR_PROPOSED_DATES)}
          selected={state.appointmentWaitingForProposedDates}
          onClick={() => dispatch({ type: 'appointmentWaitingForProposedDates' })}
          visible={(state.service || (state.orders === state.service))
                    && (state.external || (state.external === state.internal))}
        />
        <FilterCard
          caption={getAppointmentCaption(SERVICE_APPOINTMENT_WAITING_FOR_CONFIRMATION)}
          selected={state.appointmentWaitingForConfirmation}
          onClick={() => dispatch({ type: 'appointmentWaitingForConfirmation' })}
          visible={(state.service || (state.orders === state.service))
                    && (state.external || (state.external === state.internal))}
        />
      </div>
      <div className="Spacer" />
      <div className="d-flex flex-wrap">
        <ClearableDatePicker
          selected={(state.startDate) ? moment(state.startDate).toDate() : null}
          onChange={(startDate) => handleStartDateChange(startDate)}
          label="von"
          onClear={() => handleStartDateChange(null)}
        />
        <ClearableDatePicker
          selected={(state.endDate) ? moment(state.endDate).toDate() : null}
          minDate={(state.startDate) ? moment(state.startDate).toDate() : null}
          onChange={(endDate) => handleEndDateChange(endDate)}
          label="bis"
          onClear={() => handleEndDateChange(null)}
        />
      </div>
      <ShowHideContainer visible={partnerOption && partnerOption.length > 1}>
        <div className="Spacer" />
        <div className="d-flex flex-wrap">
          <PartnerButton
            partner={partnerOption}
            onChange={(item) => dispatch({ type: 'partnerId', payload: item.value })}
            selectedPartnerId={state.partnerId}
          />
          <PartnerButton
            partner={assignedPartnerOption}
            onChange={(item) => dispatch({ type: 'assingedPartnerId', payload: item.value })}
            selectedPartnerId={state.assingedPartnerId}
          />
        </div>
      </ShowHideContainer>
    </>
  );

  return (
    <>
      <Panel marginBottom={20}>
        <FilterContainer>
          <div className="Caption">
            Filter
          </div>
          {renderOrderFilters()}
        </FilterContainer>
      </Panel>
    </>
  );
}

Filter.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  orders: PropTypes.instanceOf(Object).isRequired,
  services: PropTypes.instanceOf(Object).isRequired,
  reclamations: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  onDateFilterChange: PropTypes.func.isRequired,
  partners: PropTypes.instanceOf(Array),
};

Filter.defaultProps = {
  partners: null,
};

function mapStoreToProps(store) {
  return {
    partners: store.client.partners,
  };
}

export default connect(mapStoreToProps, () => ({}))(Filter);

/**
 * FilterCard()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function FilterCard(props) {
  const {
    caption, selected, onClick, visible,
  } = props;

  if (visible) {
    return (
      <div
        className={`FilterCard ${(selected) ? 'Selected' : null}`}
        onClick={onClick}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
      >
        {caption}
      </div>
    );
  }
  return null;
}

FilterCard.propTypes = {
  caption: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

FilterCard.defaultProps = {
  selected: false,
  visible: true,
};
