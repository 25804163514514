import axios from '../../Library/Axios';
import { get as getOrder } from './OrderAction';

export const defaultUri = '/order/{orderId}/object/{objectId}/reclamation';

export const RECLAMATION_GETLIST_START = 'RECLAMATION_GETLIST_START';
export const RECLAMATION_GETLIST_SUCCESS = 'RECLAMATION_GETLIST_SUCCESS';
export const RECLAMATION_GETLIST_ERROR = 'RECLAMATION_GETLIST_ERROR';

/**
 * Helper function to prepareUri
 * @param orderId
 * @param objectId
 * @returns {string}
 */
function prepareUri(orderId, objectId) {
  return defaultUri.replace('{orderId}', orderId).replace('{objectId}', objectId);
}

/**
 * getList()
 * @param orderId
 * @param objectId
 * @returns {function(*): *}
 */
export function getList(orderId, objectId) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_GETLIST_START });

    const uri = defaultUri.replace('{orderId}', orderId).replace('{objectId}', objectId);

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: RECLAMATION_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_UPLAODDEVICEIMAGES_START = 'RECLAMATION_UPLAODDEVICEIMAGES_START';
export const RECLAMATION_UPLAODDEVICEIMAGES_SUCCESS = 'RECLAMATION_UPLAODDEVICEIMAGES_SUCCESS';
export const RECLAMATION_UPLAODDEVICEIMAGES_ERROR = 'RECLAMATION_UPLAODDEVICEIMAGES_ERROR';

/**
 * uploadDeviceImages()
 * @param reclamation
 * @param devices
 * @returns {function(*): Promise<unknown>}
 */
export function uploadDeviceImages(reclamation, devices) {
  return (dispatch) => {
    if (devices && devices.length > 0) {
      dispatch({ type: RECLAMATION_UPLAODDEVICEIMAGES_START, payload: devices });
      const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
      const uri = `${preparedUri}/${reclamation.reclamationId}/document`;

      const formData = new FormData();
      let imagesAvailable = false;

      devices.forEach((device) => {
        if (device.images && device.images.length > 0) {
          device.images.forEach((image) => {
            formData.append(`file[${device.uploadId}][]`, image.file);
            imagesAvailable = true;
          });
        }
      });

      if (imagesAvailable) {
        return axios().post(uri, formData).then((response) => {
          const { data } = response.data;
          dispatch({ type: RECLAMATION_UPLAODDEVICEIMAGES_SUCCESS, payload: data });
          return Promise.resolve(data);
        }).catch((error) => {
          dispatch({ type: RECLAMATION_UPLAODDEVICEIMAGES_ERROR, payload: error.response });
          return Promise.reject(error);
        });
      }
    }
    return Promise.resolve();
  };
}

export const RECLAMATION_GET_START = 'RECLAMATION_GET_START';
export const RECLAMATION_GET_SUCCESS = 'RECLAMATION_GET_SUCCESS';
export const RECLAMATION_GET_ERROR = 'RECLAMATION_GET_ERROR';

/**
 * get()
 * @param orderId
 * @param objectId
 * @param reclamationId
 * @param forceReload
 * @returns {function(*, *): (Promise<unknown>)}
 */
export function get(orderId, objectId, reclamationId, forceReload = false) {
  return (dispatch, getState) => {
    dispatch({ type: RECLAMATION_GET_START });

    if (!forceReload) {
      const { selectedReclamation } = getState().reclamation;
      if (selectedReclamation && selectedReclamation.reclamationId === reclamationId) {
        return Promise.resolve(selectedReclamation);
      }
    }

    const preparedUri = prepareUri(orderId, objectId);
    const uri = `${preparedUri}/${reclamationId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: RECLAMATION_GET_ERROR, payload: error.response });
      return Promise.reject(error.response);
    });
  };
}

export const RECLAMATION_UPDATE_START = 'RECLAMATION_UPDATE_START';
export const RECLAMATION_UPDATE_SUCCESS = 'RECLAMATION_UPDATE_SUCCESS';
export const RECLAMATION_UPDATE_ERROR = 'RECLAMATION_UPDATE_ERROR';

/**
 * update()
 * @param reclamationData
 * @returns {function(*): Promise<unknown>}
 */
export function update(reclamationData) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_UPDATE_START, payload: reclamationData });
    const { reclamationId } = reclamationData;

    const preparedUri = prepareUri(reclamationData.orderId, reclamationData.objectId);
    const uri = `${preparedUri}/${reclamationId}`;

    return axios().put(uri, reclamationData).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_UPDATE_SUCCESS, payload: data });
      dispatch(getOrder(reclamationData.orderId, true));
      dispatch(getList(reclamationData.orderId, reclamationData.objectId));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: RECLAMATION_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_DELETE_START = 'RECLAMATION_DELETE_START';
export const RECLAMATION_DELETE_SUCCESS = 'RECLAMATION_DELETE_SUCCESS';
export const RECLAMATION_DELETE_ERROR = 'RECLAMATION_DELETE_ERROR';

/**
 * remove()
 * @param reclamation
 * @returns {function(*): Promise<void>}
 */
export function remove(reclamation) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_DELETE_START });
    const { reclamationId } = reclamation;

    const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
    const uri = `${preparedUri}/${reclamationId}`;

    return axios().delete(uri).then(() => {
      dispatch({ type: RECLAMATION_DELETE_SUCCESS, payload: reclamationId });
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: RECLAMATION_DELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_GETREPORT_START = 'RECLAMATION_GETREPORT_START';
export const RECLAMATION_GETREPORT_SUCCESS = 'RECLAMATION_GETREPORT_SUCCESS';
export const RECLAMATION_GETREPORT_ERROR = 'RECLAMATION_GETREPORT_ERROR';

/**
 * getReclamationReport
 * @param reclamation
 * @returns {function(*): Promise<unknown>}
 */
export function getReclamationReport(reclamation) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_GETREPORT_START, reclamation });

    const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
    const uri = `${preparedUri}/${reclamation.reclamationId}/report`;

    return axios().get(uri, { params: { action: 'report' } }).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_GETREPORT_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: RECLAMATION_GETREPORT_ERROR, error });
      return Promise.reject();
    });
  };
}

export const RECLAMATIONREPORT_GETPDF_START = 'RECLAMATIONREPORT_GETPDF_START';
export const RECLAMATIONREPORT_GETPDF_SUCCESS = 'RECLAMATIONREPORT_GETPDF_SUCCESS';
export const RECLAMATIONREPORT_GETPDF_ERROR = 'RECLAMATIONREPORT_GETPDF_ERROR';

/**
 * getPdf()
 * @param reclamation
 * @returns {function(*): Promise<Blob | never>}
 */
export function getReportPdf(reclamation) {
  return (dispatch) => {
    dispatch({ type: RECLAMATIONREPORT_GETPDF_START });

    const config = {
      params: { action: 'pdf' },
      responseType: 'arraybuffer',
    };
    const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
    const uri = `${preparedUri}/${reclamation.reclamationId}/report`;

    return dispatch(getReclamationReport(reclamation)).then((report) => {
      const pdfUri = `${uri}/${report.reclamationReportId}`;

      return axios().get(pdfUri, config).then((response) => {
        const pdfData = response.data;
        const blobFile = new Blob(
          [pdfData],
          { type: 'application/pdf' },
        );
        dispatch({ type: RECLAMATIONREPORT_GETPDF_SUCCESS, payload: pdfData });
        return Promise.resolve(blobFile);
      }).catch((error) => {
        dispatch({ type: RECLAMATIONREPORT_GETPDF_ERROR, payload: error });
        return Promise.reject(error);
      });
    }).catch((error) => {
      dispatch({ type: RECLAMATIONREPORT_GETPDF_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_SENDCUSTOMERMAIL_START = 'RECLAMATION_SENDCUSTOMERMAIL_START';
export const RECLAMATION_SENDCUSTOMERMAIL_SUCCESS = 'RECLAMATION_SENDCUSTOMERMAIL_SUCCESS';
export const RECLAMATION_SENDCUSTOMERMAIL_ERROR = 'RECLAMATION_SENDCUSTOMERMAIL_ERROR';

/**
 * sendCustomerMail()
 * @param reclamation
 * @returns {function(*): Promise<unknown>}
 */
export function sendCustomerMail(reclamation) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_SENDCUSTOMERMAIL_START, reclamation });

    const uri = '/mailer';
    const config = {
      params: {
        action: 'sendReclamationMail',
      },
    };
    const postData = { reclamationId: reclamation.reclamationId };

    return axios().post(uri, postData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_SENDCUSTOMERMAIL_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: RECLAMATION_SENDCUSTOMERMAIL_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_CREATEAPPOINTMENT_START = 'RECLAMATION_CREATEAPPOINTMENT_START';
export const RECLAMATION_CREATEAPPOINTMENT_SUCCESS = 'RECLAMATION_CREATEAPPOINTMENT_SUCCESS';
export const RECLAMATION_CREATEAPPOINTMENT_ERROR = 'RECLAMATION_CREATEAPPOINTMENT_ERROR';

/**
 * createAppointment()
 * @param reclamation
 * @param appointmentData
 * @returns {function(*): Promise<unknown>}
 */
export function createAppointment(reclamation, appointmentData) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_CREATEAPPOINTMENT_START, appointmentData });

    const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
    const uri = `${preparedUri}/${reclamation.reclamationId}/appointment`;

    return axios().post(uri, appointmentData).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_CREATEAPPOINTMENT_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: RECLAMATION_CREATEAPPOINTMENT_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_APPOINTMENTGETLIST_START = 'RECLAMATION_APPOINTMENTGETLIST_START';
export const RECLAMATION_APPOINTMENTGETLIST_SUCCESS = 'RECLAMATION_APPOINTMENTGETLIST_SUCCESS';
export const RECLAMATION_APPOINTMENTGETLIST_ERROR = 'RECLAMATION_APPOINTMENTGETLIST_ERROR';

/**
 * getAppointments()
 * @param reclamation
 * @returns {function(*): Promise<unknown>}
 */
export function getAppointments(reclamation) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_APPOINTMENTGETLIST_START, reclamation });

    const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
    const uri = `${preparedUri}/${reclamation.reclamationId}/appointment`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_APPOINTMENTGETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: RECLAMATION_APPOINTMENTGETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_APPOINTMENTDELETE_START = 'RECLAMATION_APPOINTMENTDELETE_START';
export const RECLAMATION_APPOINTMENTDELETE_SUCCESS = 'RECLAMATION_APPOINTMENTDELETE_SUCCESS';
export const RECLAMATION_APPOINTMENTDELETE_ERROR = 'RECLAMATION_APPOINTMENTDELETE_ERROR';

/**
 * deleteAppointments
 * @param reclamation
 * @param appointmentId
 * @returns {function(*): Promise<void>}
 */
export function deleteAppointments(reclamation, appointmentId) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_APPOINTMENTDELETE_START, appointmentId });

    const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
    const uri = `${preparedUri}/${reclamation.reclamationId}/appointment/${appointmentId}`;

    return axios().delete(uri).then(() => {
      dispatch({ type: RECLAMATION_APPOINTMENTDELETE_SUCCESS });
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: RECLAMATION_APPOINTMENTDELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_APPOINTMENTAPPROVE_START = 'RECLAMATION_APPOINTMENTAPPROVE_START';
export const RECLAMATION_APPOINTMENTAPPROVE_SUCCESS = 'RECLAMATION_APPOINTMENTAPPROVE_SUCCESS';
export const RECLAMATION_APPOINTMENTAPPROVE_ERROR = 'RECLAMATION_APPOINTMENTAPPROVE_ERROR';

/**
 * approveAppointment
 * @param reclamation
 * @param appointmentId
 * @returns {function(*): Promise<void>}
 */
export function approveAppointment(reclamation, appointmentId) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_APPOINTMENTAPPROVE_START, appointmentId });

    const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
    const uri = `${preparedUri}/${reclamation.reclamationId}/appointment/${appointmentId}`;
    const config = {
      params: { action: 'approve' },
    };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_APPOINTMENTAPPROVE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: RECLAMATION_APPOINTMENTAPPROVE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_ADDDEVICE_START = 'RECLAMATION_ADDDEVICE_START';
export const RECLAMATION_ADDDEVICE_SUCCESS = 'RECLAMATION_ADDDEVICE_SUCCESS';
export const RECLAMATION_ADDDEVICE_ERROR = 'RECLAMATION_ADDDEVICE_ERROR';

/**
 * addDevice()
 * @param reclamation
 * @param deviceData
 * @returns {function(*): Promise<unknown>}
 */
export function addDevice(reclamation, deviceData) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_ADDDEVICE_START, deviceData });

    const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
    const uri = `${preparedUri}/${reclamation.reclamationId}/device`;

    return axios().post(uri, deviceData).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_ADDDEVICE_SUCCESS, payload: data });

      dispatch(uploadDeviceImages(reclamation, [deviceData]))
        .then(() => Promise.resolve(data))
        .catch((error) => {
          dispatch({ type: RECLAMATION_ADDDEVICE_ERROR });
          return Promise.reject(error);
        });
    }).catch((error) => {
      dispatch({ type: RECLAMATION_ADDDEVICE_ERROR });
      return Promise.reject(error);
    });
  };
}

export const RECLAMATION_DELETEDEVICE_START = 'RECLAMATION_DELETEDEVICE_START';
export const RECLAMATION_DELETEDEVICE_SUCCESS = 'RECLAMATION_DELETEDEVICE_SUCCESS';
export const RECLAMATION_DELETEDEVICE_ERROR = 'RECLAMATION_DELETEDEVICE_ERROR';

/**
 * deleteDevice()
 * @param reclamation
 * @param deviceId
 * @returns {function(*): Promise<unknown>}
 */
export function deleteDevice(reclamation, deviceId) {
  return (dispatch) => {
    dispatch({ type: RECLAMATION_DELETEDEVICE_START, deviceId });

    const preparedUri = prepareUri(reclamation.orderId, reclamation.objectId);
    const uri = `${preparedUri}/${reclamation.reclamationId}/device/${deviceId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: RECLAMATION_DELETEDEVICE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: RECLAMATION_DELETEDEVICE_ERROR, error });
      return Promise.reject();
    });
  };
}
