import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import LeftDetailsBox from './Component/LeftDetailsBox';
import RightDetailsBox from './Component/RightDetailsBox';
import { getAll } from '../../Redux/Action/UserAction';
import { getList } from '../../Redux/Action/TeamsAction';
import { useDispatchGetCalendarOrderServiceData } from '../../Redux/Action/CalendarAction';
import Breadcrumb from '../../Components/Breadcrumb';
import { useDispatchHolidayGetList } from '../../Redux/Action/HolidayAction';

const breadCrumb = [
  { caption: 'Startseite', location: '/dashboard' },
  { caption: 'Disposition', location: '/calendar' },
];

/**
 * Details()
 * @param props
 * @returns {*}
 * @constructor
 */
function Details(props) {
  const {
    match, getAllTeams, getAllUsers, history,
  } = props;
  const dispatchGetHolidays = useDispatchHolidayGetList();
  const dispatchGetCalendarOderServiceData = useDispatchGetCalendarOrderServiceData();
  const [currentDate, setCurrentDate] = useState(match.params.date);
  const [breadcrumb, setBreadcrumb] = useState(breadCrumb);
  const { date } = useParams();

  React.useEffect(() => {
    getAllUsers();
    getAllTeams();
    const startDate = moment(currentDate).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(currentDate).endOf('month').format('YYYY-MM-DD');
    dispatchGetCalendarOderServiceData(date, date);
    dispatchGetHolidays(startDate, endDate).then(() => {});

    const dateParam = moment(currentDate).format('DD. MMMM YYYY');
    setBreadcrumb([...breadCrumb, { caption: dateParam }]);
  }, [currentDate, dispatchGetCalendarOderServiceData, getAllTeams, getAllUsers, dispatchGetHolidays, date]);

  React.useEffect(() => {
    setCurrentDate(date);
  }, [date]);

  const handleChangeDate = (dateParam) => {
    const url = `/calendar/${dateParam}`;
    history.push(url);
  };

  return (
    <div>
      <div>
        <Breadcrumb data={breadcrumb} />
      </div>
      <div className="d-flex">
        <div className="Left d-flex" style={{ marginRight: 20, minWidth: 340 }}>
          <LeftDetailsBox currentDate={currentDate} onChange={handleChangeDate} />
        </div>
        <div className="Right w-100">
          <RightDetailsBox currentDate={currentDate} data={null} />
        </div>
      </div>
    </div>
  );
}

Details.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

function mapStoreToProps(store) {
  return {
    storeTeams: store.teams.teams,
    storeUsers: store.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllUsers: () => dispatch(getAll()),
    getAllTeams: () => dispatch(getList()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Details));
