import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { formatDate, formatTime } from '../../../../Library/Functions';

import './style.css';

/**
 * Item()
 * @param props
 * @returns {*}
 * @constructor
 */
function Item(props) {
  const { item, onDelete, onApprove } = props;

  const renderComment = () => {
    if (item.comment && item.comment.length > 0) {
      return (
        <div style={{ paddingLeft: 24 }}>
          <small>{item.comment}</small>
        </div>
      );
    }
    return null;
  };

  const renderActionIcon = () => {
    if (onDelete) {
      return (
        <div className="ActionIcon">
          <i
            className="fas fa-trash"
            onClick={() => onDelete(item)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
            title="Terminvorschlag löschen"
          />
        </div>
      );
    }
    if (onApprove) {
      return (
        <div
          className="ActionIcon Approve"
          onClick={() => onApprove(item)}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
        >
          <i
            className="fas fa-check-circle"
            style={{ marginRight: 6 }}
          />
          Termin bestätigen
        </div>
      );
    }
    return null;
  };

  return (
    <div className="Appointment d-flex flex-row">
      <div className="ContentContainer">
        <div>
          <i className="far fa-calendar-alt" style={{ marginRight: 10 }} />
          {`${formatDate(item.date)} - 
          Ankunft von ${formatTime(item.executionFromTime)} bis ${formatTime(item.executionToTime)} Uhr`}
        </div>
        {renderComment()}
      </div>
      {renderActionIcon()}
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func,
  onApprove: PropTypes.func,
};

Item.defaultProps = {
  onDelete: null,
  onApprove: null,
};

/**
 * AppointmentListView()
 * @param props
 * @returns {null}
 * @constructor
 */
export default function AppointmentListView(props) {
  const { appointments, onDelete, onApprove } = props;

  if (appointments) {
    return (
      <div className="AppointmentsListView d-flex flex-column">
        {appointments.map((item) => (
          <Item key={item.appointmentId} item={item} onDelete={onDelete} onApprove={onApprove} />
        ))}
      </div>
    );
  }
  return null;
}

AppointmentListView.propTypes = {
  appointments: PropTypes.instanceOf(Array),
  onDelete: PropTypes.func,
  onApprove: PropTypes.func,
};

AppointmentListView.defaultProps = {
  appointments: null,
  onDelete: null,
  onApprove: null,
};
