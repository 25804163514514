/**
 * @author RUT
 * @deprecated Customer will receive the report directly as attachment in mail. Can be deleted after 01.06.2021
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import FileSaver from 'file-saver';
import md5 from 'md5';
import { downloadServiceReport } from '../../Redux/Action/PublicAction';

const image = require('../../Assets/Images/generatepdf.png');

/**
 * DownloadReport()
 * @param props
 * @returns {*}
 * @constructor
 */
function DownloadReport(props) {
  const { dispatchDownloadReport } = props;
  const { reportId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const paramArray = reportId.split('_');
    setIsLoading(true);

    if (md5(paramArray[0]) === paramArray[1]) {
      dispatchDownloadReport(reportId).then((data) => {
        setIsLoading(false);
        FileSaver.saveAs(data, 'Montagebericht.pdf');
      }).catch(() => {
        setIsLoading(false);
        setHasError(true);
      });
    } else {
      setHasError(true);
      setIsLoading(false);
    }
  }, [dispatchDownloadReport, reportId]);

  const renderDownloading = () => {
    if (isLoading) {
      return (
        <>
          <img src={image} alt="PDF download" style={{ marginBottom: 30 }} />
          <h3 className="hk-text-primary text-center">
            Ihr Monatgebericht wird heruntergeladen!
          </h3>
          <p className="text-center">
            Bitte warten Sie, der Download startet automatisch!
          </p>
          <i className="fas fa-spin fa-spinner" style={{ fontSize: 30 }} />
        </>
      );
    }
    return null;
  };

  const renderDownloaded = () => {
    if (!isLoading) {
      return (
        <>
          <i className="fas fa-check-circle text-success" style={{ fontSize: 250, marginBottom: 30 }} />
          <h3 className="hk-text-primary text-center">
            Ihr Monatgebericht wurde erfolgreich heruntergeladen!
          </h3>
          <p className="text-center">
            Der Montagebericht wurde in Ihrem Downloadornder gespeichert!
          </p>
          <p className="text-center">
            Sie können dieses Fenster jetzt schließen.
          </p>
        </>
      );
    }
    return null;
  };

  if (!hasError) {
    return (
      <div className="d-flex justify-content-center flex-column align-items-center container-fluid">
        {renderDownloading()}
        {renderDownloaded()}
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-center flex-column align-items-center container-fluid">
      <i className="fas fa-exclamation-circle text-danger" style={{ fontSize: 250, marginBottom: 30 }} />
      <h3 className="hk-text-primary text-center">
        Oh, hier ist etwas schief gelaufen beim Download.
      </h3>
      <p className="text-center">
        Bitte wenden Sie sich an Ihren Händler!
      </p>
    </div>
  );
}

DownloadReport.propTypes = {
  dispatchDownloadReport: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchDownloadReport: (reportId) => dispatch(downloadServiceReport(reportId)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(withRouter(DownloadReport));
