import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BoxHideHintModal from './BoxHideHintModal';

const HideIcon = styled.i`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  padding: 9px;
  color: #d9d9d9;
  font-size: 14px;
  
  &:hover {
    color: #000;
    border-radius: 6px;
  }
`;

/**
 * BoxHideIcon()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function BoxHideIcon(props) {
  const { onPress } = props;
  const [showHint, setShowHint] = React.useState(false);

  const handleOnClick = () => {
    setShowHint(true);
  };

  const handleHintOnPress = () => {
    onPress();
  };

  return (
    <>
      <HideIcon className="fas fa-eye-slash" onClick={handleOnClick} title="Diese Box ausblenden!" />
      <BoxHideHintModal visible={showHint} onClose={handleHintOnPress} />
    </>
  );
}

BoxHideIcon.propTypes = {
  onPress: PropTypes.func.isRequired,
};
