import {
  SYSTEM_CLIENTNOTE_GETLIST_ERROR, SYSTEM_CLIENTNOTE_GETLIST_START, SYSTEM_CLIENTNOTE_GETLIST_SUCCESS,
  SYSTEM_CLIENTNOTE_CREATE_SUCCESS, SYSTEM_CLIENTNOTE_DELETE_SUCCESS, SYSTEM_CLIENTNOTE_UPDATE_SUCCESS,
} from '../Action/ClientNoteAction';

const defaultValues = {
  notes: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /** GETLIST */
    case SYSTEM_CLIENTNOTE_GETLIST_START: {
      return { ...state };
    }
    case SYSTEM_CLIENTNOTE_GETLIST_SUCCESS: {
      return { ...state, notes: action.payload };
    }
    case SYSTEM_CLIENTNOTE_GETLIST_ERROR: {
      return { ...state, notes: null };
    }

    /** CREATE */
    case SYSTEM_CLIENTNOTE_CREATE_SUCCESS: {
      const { payload } = action;
      let merged = [payload];
      if (state.notes) {
        merged = [...merged, ...state.notes];
      }

      return { ...state, notes: merged };
    }

    /** DELETE */
    case SYSTEM_CLIENTNOTE_DELETE_SUCCESS: {
      const { payload } = action;
      const merged = state.notes.filter((item) => item.id !== parseInt(payload, 0));
      return { ...state, notes: merged };
    }

    /** UPDATE */
    case SYSTEM_CLIENTNOTE_UPDATE_SUCCESS: {
      const { payload } = action;
      const merged = state.notes.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
      return { ...state, notes: merged };
    }

    default: return { ...state };
  }
}
