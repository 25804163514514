import React from 'react';
import PropTypes from 'prop-types/prop-types';
import * as Redux from 'react-redux';
import KeyValueEditRow from '../../KeyValueEditRow';
import { timeOptions } from '../../../Library/Types';
import { formatDate, formatTime } from '../../../Library/Functions';

/**
 * ProposedDateEdit()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProposedDateEdit(props) {
  const {
    initialDate, initialStartTime, initialComment, onChange, showErrorMissingDate, showErrorMissingStartTime,
  } = props;

  const earliestStartDate = Redux.useSelector((state) => state.appointment.current.earliestStartDate);

  const [editDate, setEditDate] = React.useState((initialDate) ? new Date(initialDate) : null);
  const [editStartTime, setEditStartTime] = React.useState(initialStartTime);
  const [editComment, setEditComment] = React.useState(initialComment);

  React.useEffect(() => {
    onChange({
      date: formatDate(editDate, 'YYYY-MM-DD'),
      startTime: formatTime(editStartTime),
      comment: editComment,
    });
  }, [onChange, editDate, editStartTime, editComment]);

  const noDataText = () => (<i className="text-muted">Keine Daten eingetragen</i>);

  const renderFieldDate = () => (
    <KeyValueEditRow
      title="Datum"
      initialValue={editDate}
      displayText={(editDate) ? formatDate(editDate) : null}
      placeholder={noDataText()}
      actionIcon="fas fa-pencil-alt"
      onChange={setEditDate}
      type="date"
      datePickerMinDate={earliestStartDate || null}
      required
      hasError={showErrorMissingDate}
    />
  );

  const renderFieldStartTime = () => (
    <KeyValueEditRow
      title="Ankunft Monteur"
      initialValue={editStartTime}
      displayText={(editStartTime) ? `${formatTime(editStartTime)} Uhr` : null}
      placeholder={noDataText()}
      actionIcon="fas fa-pencil-alt"
      onChange={setEditStartTime}
      type="select"
      selectData={timeOptions}
      required
      hasError={showErrorMissingStartTime}
    />
  );

  const renderFieldComment = () => (
    <KeyValueEditRow
      title="Zusätzliche Informationen / Beschreibung"
      initialValue={editComment}
      displayText={editComment}
      placeholder={noDataText()}
      actionIcon="fas fa-pencil-alt"
      onChange={setEditComment}
      type="textarea"
    />
  );

  return (
    <>
      {renderFieldDate()}
      {renderFieldStartTime()}
      {renderFieldComment()}
    </>
  );
}

ProposedDateEdit.propTypes = {
  initialDate: PropTypes.string,
  initialStartTime: PropTypes.string,
  initialComment: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showErrorMissingDate: PropTypes.bool,
  showErrorMissingStartTime: PropTypes.bool,
};

ProposedDateEdit.defaultProps = {
  initialDate: null,
  initialStartTime: null,
  initialComment: '',
  showErrorMissingDate: false,
  showErrorMissingStartTime: false,
};
