import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getExternalOrdersByMonth } from '../Redux/Action/StatisticAction';
import Panel from '../../../Components/Panel';
import { formatCurrency } from '../../../Library/Functions';
import {
  ORDERSTATE_FINISHED,
  ORDERSTATE_OPEN,
} from '../../../Library/StateTypes';

/**
 * ExternalOrdersByMonth()
 * @param props
 * @returns {*}
 * @constructor
 */
function ExternalOrdersByMonth(props) {
  const { dispatchGet, data } = props;
  const [mapped, setMapped] = React.useState(null);

  React.useEffect(() => {
    dispatchGet();
  }, [dispatchGet]);

  React.useEffect(() => {
    const mappedData = {};
    if (data) {
      data.filter((item) => {
        if (!mappedData[item.month]) {
          mappedData[item.month] = {
            open: 0,
            assigned: 0,
            finished: 0,
            overall: 0,
            dealer: 0.00,
            monteur: 0.00,
            diff: 0.00,
          };
        }
        if (parseInt(item.state, 0) === ORDERSTATE_OPEN) {
          mappedData[item.month].open = parseInt(item.amount, 0);
          mappedData[item.month].overall += parseInt(item.amount, 0);
        }
        if (parseInt(item.state, 0) === ORDERSTATE_FINISHED) {
          mappedData[item.month].finished += parseInt(item.amount, 0);
          mappedData[item.month].dealer += parseFloat(item.dealerPriceSum);
          mappedData[item.month].monteur += parseFloat(item.monteurPriceSum);
          mappedData[item.month].diff = (mappedData[item.month].dealer - mappedData[item.month].monteur);
          mappedData[item.month].overall += parseInt(item.amount, 0);
        }
        return true;
      });

      setMapped(mappedData);
    }
  }, [data]);

  const renderRow = (monthNumber) => (
    <tr>
      <td>{moment(`2020-${monthNumber}-01`).format('MMMM')}</td>
      <td className="text-center">
        {(mapped[monthNumber]) ? mapped[monthNumber].open : 0}
      </td>
      <td className="text-center">
        {(mapped[monthNumber]) ? mapped[monthNumber].assigned : 0}
      </td>
      <td className="text-center">
        {(mapped[monthNumber]) ? mapped[monthNumber].finished : 0}
      </td>
      <td className="text-center">
        {(mapped[monthNumber]) ? mapped[monthNumber].overall : 0}
      </td>
      <td className="text-center">
        {(mapped[monthNumber]) ? formatCurrency(mapped[monthNumber].dealer) : formatCurrency(0)}
      </td>
      <td className="text-center">
        {(mapped[monthNumber]) ? formatCurrency(mapped[monthNumber].monteur) : formatCurrency(0)}
      </td>
      <td className="text-center">
        {(mapped[monthNumber]) ? formatCurrency(mapped[monthNumber].diff) : formatCurrency(0)}
      </td>
    </tr>
  );

  if (mapped) {
    return (
      <>
        <Panel marginBottom={20}>
          <h5 className="hk-text-primary">
            Externe Aufträge nach Monat
          </h5>
          <p style={{ marginBottom: 0 }}>
            Diese Statistik zeigt alle externen Aufträge gruppiert nach Ihrem aktuellen Status in relation zum
            Ausführungsmonat. Abgeschlossen bezeichnen alle Aufträge die druchgeführt wurden und für welche bereits
            eine Rechnung generiert wurde! Die Summen werden nur aus Aufträgen gebildet die abgeschlosen sind, da nur
            für diese Aufträge eine Rechnung generiert wurde. Die Umsätze werden noch nicht aus den tatsächlich
            erstellten Rechnungen generiert. Dies sind die reinen Umsätze die Aufträge erzeugt haben. Sollten GS oder
            LS nachträglich von hand erstellt worden sein, werden diese hier nicht berücksichtigt!
          </p>
        </Panel>
        <Panel boxPadding={10}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Monat</th>
                <th className="text-center">Offen</th>
                <th className="text-center">Zugewiesen</th>
                <th className="text-center">Abgeschlosen</th>
                <th className="text-center">Summe Aufträge</th>
                <th className="text-center">Rechnungen</th>
                <th className="text-center">Gutschriften</th>
                <th className="text-center">Umsatz</th>
              </tr>
            </thead>
            <tbody>
              {renderRow(1)}
              {renderRow(2)}
              {renderRow(3)}
              {renderRow(4)}
              {renderRow(5)}
              {renderRow(6)}
              {renderRow(7)}
              {renderRow(8)}
              {renderRow(9)}
              {renderRow(10)}
              {renderRow(11)}
              {renderRow(12)}
            </tbody>
          </table>
        </Panel>
      </>
    );
  }
  return null;
}

ExternalOrdersByMonth.propTypes = {
  dispatchGet: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array),
};

ExternalOrdersByMonth.defaultProps = {
  data: null,
};

function mapStoreToProps(store) {
  return {
    data: store.systemStatistic.externalOrdersByMonth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: () => dispatch(getExternalOrdersByMonth()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(ExternalOrdersByMonth);
