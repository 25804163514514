import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import * as Redux from 'react-redux';
import Panel from '../../../Components/Panel';
import Input from '../../../Components/Input';
import ReclamationList from './ReclamationPage/ReclamationList';
import ReclamationStateFilter from './ReclamationPage/ReclamationListFilter/ReclamationStateListFilter';
import { updateFilters, useDispatchApplyReclamationFilters } from '../Redux/Action/ReclamationAction';

/**
 * ReclamationPage(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReclamationPage(props) {
  const {
    getHeadline, dispatchGetObjects, onClick,
  } = props;

  const reclamations = Redux.useSelector((store) => (store.systemReclamation.reclamations));
  const lastUpdated = Redux.useSelector((store) => (store.systemReclamation.lastUpdated));
  const isLoading = Redux.useSelector((store) => (store.systemReclamation.isLoading));
  const filters = Redux.useSelector((store) => (store.systemReclamation.filters));
  const filteredReclamations = Redux.useSelector((store) => (store.systemReclamation.filteredReclamations));

  const prevFilteredReclamations = React.useRef(null);
  const dispatch = Redux.useDispatch();
  const dispatchUpdateFilters = (newFilter) => dispatch(updateFilters(newFilter));
  const dispatchApplyFilters = useDispatchApplyReclamationFilters();

  const [searchBuffer, setSearchBuffer] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [all, setAll] = React.useState(null);
  const [today, setToday] = React.useState(null);
  const [yesterday, setYesterday] = React.useState(null);

  const loadObjects = React.useCallback(() => {
    dispatchGetObjects();
  }, [dispatchGetObjects]);

  const updateObjects = React.useCallback(() => {
    const now = new Date().getTime();
    const deltaMilliseconds = now - lastUpdated;
    if (deltaMilliseconds > 300000 && !isLoading) {
      loadObjects();
    }
  }, [lastUpdated, loadObjects, isLoading]);

  React.useEffect(() => {
    prevFilteredReclamations.current = filteredReclamations;
  }, [filteredReclamations]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(searchBuffer);
    }, 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [searchBuffer]);

  React.useEffect(() => {
    updateObjects();
  }, [updateObjects]);

  const dayFilter = (reclamation, day) => moment(reclamation.executionDate).isSame(day, 'day');

  const searchFilter = React.useCallback((reclamation) => {
    if (!searchValue) return true;

    if (reclamation.reclamationId && reclamation.reclamationId.toString().includes(searchValue)) return true;
    return !!(reclamation.title && reclamation.title.includes(searchValue));
  }, [searchValue]);

  React.useEffect(() => {
    if (filteredReclamations && filteredReclamations.length > 0) {
      const allData = filteredReclamations.filter((reclamation) => searchFilter(reclamation));
      setAll(allData);

      const todayDate = moment(new Date());
      const todayData = allData.filter((reclamation) => dayFilter(reclamation, todayDate));
      setToday(todayData);

      const yesterdayDate = todayDate.subtract(1, 'day');
      const yesterdayData = allData.filter((reclamation) => dayFilter(reclamation, yesterdayDate));
      setYesterday(yesterdayData);
    }
  }, [filteredReclamations, setToday, setYesterday, searchFilter]);

  React.useEffect(() => {
    if (reclamations && filters) {
      dispatchApplyFilters(reclamations, filters, prevFilteredReclamations);
    }
  }, [reclamations, filters, prevFilteredReclamations, dispatchApplyFilters]);

  const renderHeadline = () => (
    <h5 style={{ margin: 0 }}>
      {getHeadline()}
    </h5>
  );

  const renderSubHeadline = () => {
    if (all) {
      return (
        <p style={{ margin: 0 }}>
          {`${(all.length > 0) ? all.length : 'Keine'} Reklamationen`}

          {searchValue ? ` für "${searchValue}"` : ''}
        </p>
      );
    }
    return null;
  };

  const renderSearch = () => (
    <div>
      <Input
        placeholder="Suchen..."
        style={{ margin: 0 }}
        rightIcon={`fas ${(isLoading) ? 'fa-spin fa-spinner' : 'fa-search'}`}
        value={searchBuffer}
        onChange={setSearchBuffer}
      />
    </div>
  );

  const renderFilter = () => {
    if (reclamations && reclamations.length > 0) {
      return (
        <Panel marginBottom={25}>
          <div className="FilterCardContainer">
            <div className="Caption">
              Filter
            </div>
            <ReclamationStateFilter
              reclamations={reclamations}
              onFilterChange={(payload) => {
                dispatchUpdateFilters(payload);
              }}
            />
          </div>
        </Panel>
      );
    }
    return null;
  };

  const renderTodayObjects = () => {
    if (today && today.length > 0) {
      return (
        <Panel marginBottom={25}>
          <h6 className="hk-text-primary">
            Reklamationen heute
          </h6>
          <hr />
          <ReclamationList reclamations={today} onClick={onClick} />
        </Panel>
      );
    }
    return null;
  };

  const renderYesterdayObjects = () => {
    if (yesterday && yesterday.length > 0) {
      return (
        <Panel marginBottom={25}>
          <h6 className="hk-text-primary">
            Reklamationen gestern
          </h6>
          <hr />
          <ReclamationList reclamations={yesterday} onClick={onClick} />
        </Panel>
      );
    }
    return null;
  };

  const renderAllObjects = () => {
    if (all && all.length > 0) {
      return (
        <Panel marginBottom={25}>
          <ReclamationList reclamations={all} onClick={onClick} />
        </Panel>
      );
    }
    return null;
  };

  const renderBody = () => {
    if (reclamations && reclamations.length > 0) {
      return (
        <>
          {renderTodayObjects()}
          {renderYesterdayObjects()}
          {renderAllObjects()}
        </>
      );
    }

    if (isLoading) {
      return (
        <Panel marginBottom={25}>
          <center>
            <i className="fas fa-spinner fa-spin fa-5x" />
          </center>
        </Panel>
      );
    }

    return null;
  };

  return (
    <div>
      <Panel boxPadding={10} marginBottom={25}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            {renderHeadline()}
            {renderSubHeadline()}
          </div>
          {renderSearch()}
        </div>
      </Panel>
      {renderFilter()}
      {renderBody()}
    </div>
  );
}

ReclamationPage.propTypes = {
  getHeadline: PropTypes.func.isRequired,
  dispatchGetObjects: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

ReclamationPage.defaultProps = {
  onClick: () => {},
};
