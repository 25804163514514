import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import ModalDialog from '../ModalDialog';
import { split } from '../../Redux/Action/ObjectAction';

/**
 * ConfirmObjectSplitDialog()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ConfirmObjectSplitDialog(props) {
  const {
    object, visible, onClose, dispatchSplitObject,
  } = props;

  const handleConfirm = () => {
    dispatchSplitObject(object).then(() => {
      onClose();
    });
  };

  return (
    <ModalDialog
      visible={visible}
      onClose={onClose}
      centered
      confirmCaption="Auftragsoptionen teilen"
      onConfirm={handleConfirm}
    >
      <h5>
        <i className="fas fa-boxes" style={{ marginRight: 10 }} />
        Auftragsoptionen teilen
      </h5>
      <p>
        Bitte bestätigen Sie das Sie die Optionen dieses Auftrags aufteilen möchten. Die Aufteilung der
        Auftragsoptionen ermöglicht Ihnen einzelne Optionen wie "Lieferung", "Montage" oder "Demontage"
        zu verschiedenen Terminen verschiedenen Monteuren oder Partner zu zuordnen. Sie können die Aufteilung jederzeit
        rückgängig machen, solange noch keine der einzelnen Optionen durchgeführt wurde! Sollten Sie bereits ein
        Datum für den Auftrag festgelegt haben wird dieses Datum für alle Optionen vorbelegt!
      </p>
      <p>
        Bitte beachten Sie das nach der Teilung der Optionen für jede Option ein Auftrag in der Übersicht angezeigt
        wird. Es wird dann, je nach Optionen, ein Auftrag für die Demontage, ein Auftrag für die Lieferung und ein
        Auftrag für die Montage in den Übersichten angezeigt. Jede Option erhält damit auch einen eigenen Status für
        den Fortschritt.
      </p>
    </ModalDialog>
  );
}

ConfirmObjectSplitDialog.propTypes = {
  dispatchSplitObject: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  object: PropTypes.instanceOf(Object),
};

ConfirmObjectSplitDialog.defaultProps = {
  visible: false,
  object: null,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchSplitObject: (object) => dispatch(split(object)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(ConfirmObjectSplitDialog);
