import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useRouteMatch, withRouter } from 'react-router';
import {
  get as getReclamation,
  getList as getReclamations,
  remove,
  update,
  sendCustomerMail, addDevice, deleteDevice,
} from '../../../Redux/Action/ReclamationAction';
import { get as getOrder } from '../../../Redux/Action/OrderAction';
import { getList as getReports } from '../../../Redux/Action/ServiceReportAction';
import { useDispatchHolidayGetList } from '../../../Redux/Action/HolidayAction';
import {
  allowAddMonteurToReclamation,
  formatDateTime, isExternalOrderOwner,
  isObjectIdentical,
  isReclamationClosed,
} from '../../../Library/Functions';
import UserTeamPanel from '../../../Components/UserTeamPanel';
import DeviceList from './Component/DeviceList';
import Button from '../../../Components/Button';
import ObjectInformationPanel from '../../../Components/ObjectComponents/ObjectInformationPanel';
import ReclamationInformation from './Component/ReclamationInformation';
import ConfirmDeleteDialog from './Component';
import ReclamationStatusBadge from '../../../Components/ReclamationComponents/ReclamationStatusBadge';
import Panel from '../../../Components/Panel';
import ModalDialog from '../../../Components/ModalDialog';
import LoadingModal from '../../../Components/LoadingModal';
import MarkClosedDialog from './Component/MarkClosedDialog';
import {
  CLIENTTYPE_DEALER,

} from '../../../Library/Types';
import AppointmentsDealer from './Component/AppointmentsDealer';
import AppointmentsMonteur from './Component/AppointmentsMonteur';
import ClosedHint from './Component/ClosedHint';
import {
  RECLAMATIONSTATE_ASSIGNED,
  RECLAMATIONSTATE_CLOSED,
  RECLAMATIONSTATE_REPORT_FINISHED,
  RECLAMATIONSTATE_SCHEDULED,
} from '../../../Library/StateTypes';

const addMonteurDisabledHintInternal = 'Bitte vergeben Sie erst ein Ausführungsdatum bevor Sie Monteure oder '
  + 'Teams zuweisen!';
const addMonteurDisabledHintExternal = 'Monteure oder Teams können erst nach erfolgreicher Terminvereinbarung '
  + 'zugewiesen werden!';

/**
 * Details()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Details(props) {
  const {
    dispatchGetReclamation, dispatchGetOrder, dispatchGetReports, match, dispatchUpdateReclamation, client,
    dispatchRemoveReclamation, history, dispatchGetReclamations, dispatchSendCustomerMail,
    reclamation, dispatchAddDevice, dispatchDeleteDevice, isSystemView,
  } = props;
  const dispatchGetHolidays = useDispatchHolidayGetList();

  const [orderId, setOrderId] = useState(match.params.orderId);
  const [reclamationId, setReclamationId] = useState(match.params.reclamationId);
  const [objectId] = useState(match.params.objectId);
  const [savedReclamation, setSavedReclamation] = useState(null);
  const [order, setOrder] = useState(null);
  const [object, setObject] = useState(null);
  const [report, setReport] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [showAskSendMail, setShowAskSendMail] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isAddingDevice, setIsAddingDevice] = useState(false);
  const { url } = useRouteMatch();

  const loadReclamation = useCallback(
    () => {
      dispatchGetReclamation(orderId, objectId, reclamationId).then((data) => {
        setSavedReclamation(data);
        dispatchGetOrder(orderId).then((data1) => {
          const filtered = data1.objects.filter((item) => item.objectId === data.objectId)[0];
          setOrder(data1);
          setObject(filtered);
        });
        dispatchGetReports(orderId, objectId).then((reportResponse) => {
          setReport(reportResponse);
        });
      });
    },
    [orderId, objectId, reclamationId, dispatchGetOrder, dispatchGetReclamation, dispatchGetReports],
  );

  useEffect(() => {
    if (reclamation && reclamation.executionDate) {
      dispatchGetHolidays(reclamation.executionDate);
    }
  }, [reclamation, dispatchGetHolidays]);

  useEffect(() => {
    setOrderId(match.params.orderId);
    setReclamationId(match.params.reclamationId);
  }, [match]);

  useEffect(() => {
    loadReclamation();
  }, [orderId, reclamationId, loadReclamation]);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      dispatchGetOrder(orderId, true);
      dispatchGetReclamation(orderId, objectId, reclamationId);
    }, 60000);

    return () => {
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    };
  }, [dispatchGetOrder, dispatchGetReclamation, orderId, reclamationId, objectId]);

  const callUpdate = (reclamationData) => {
    const oldReclamation = { ...reclamation };

    if (!isObjectIdentical(reclamationData, savedReclamation)) {
      const {
        information, executionDate, devices, users, teamId, assemblyTime, title, executionFromTime,
        executionToTime,
      } = reclamationData;
      const saveData = {
        information,
        executionDate,
        devices,
        users,
        teamId,
        reclamationId,
        objectId: reclamationData.objectId,
        orderId,
        assemblyTime,
        title,
        executionFromTime,
        executionToTime,
      };
      dispatchUpdateReclamation(saveData).then((data) => {
        setSavedReclamation(data);

        // Check if we have to ask for resending reclamation customer mail only if the first mail was send!
        if (reclamation.sendCustomerMail) {
          if (order.internal || (client.type === CLIENTTYPE_DEALER && !order.internal)) {
            setTimeout(() => {
              if (object.customer.mail) {
                if (oldReclamation.executionDate !== data.executionDate) {
                  setShowAskSendMail(true);
                }
                if (data.executionFromTime && data.executionToTime) {
                  if (oldReclamation.executionFromTime !== data.executionFromTime
                    || oldReclamation.executionToTime !== data.executionToTime) {
                    setShowAskSendMail(true);
                  }
                }
                if (data.teamId) {
                  if (oldReclamation.teamId !== data.teamId) {
                    setShowAskSendMail(true);
                  }
                }
                if (data.users) {
                  if (oldReclamation.users.length < data.users.length) {
                    setShowAskSendMail(true);
                  }
                }
              }
            }, 2000);
          }
        }
      });
    }
  };

  const handleUserAdd = (user) => {
    const mergedReclamation = { ...reclamation, users: [...reclamation.users, user] };
    callUpdate(mergedReclamation);
  };

  const handleUserDelete = (user) => {
    const merged = reclamation.users.filter((item) => item.userId !== user.userId);
    const mergedReclamation = { ...reclamation, users: merged };
    callUpdate(mergedReclamation);
  };

  const handleTeamDelete = () => {
    const mergedReclamation = { ...reclamation, teamId: 0 };
    callUpdate(mergedReclamation);
  };

  const handleTeamAdd = (team) => {
    const mergedReclamation = { ...reclamation, teamId: team.teamId };
    callUpdate(mergedReclamation);
  };

  const onHandleAddDevice = (data) => {
    setIsAddingDevice(true);
    dispatchAddDevice(reclamation, data)
      .then(() => setIsAddingDevice(false))
      .catch(() => setIsAddingDevice(false));
  };

  const handelOnDeleteDevice = (item) => {
    dispatchDeleteDevice(reclamation, item.deviceId);
  };

  const handleSetValue = (key, value) => {
    let mergedReclamation = { ...reclamation, [key]: value };
    if (key === 'executionDate' && !value) {
      mergedReclamation = {
        ...reclamation, executionDate: null, executionFromTime: null, executionToTime: null,
      };
    }

    callUpdate(mergedReclamation);
  };

  const handleConfirmDelete = () => {
    dispatchRemoveReclamation(reclamation).then(() => {
      dispatchGetReclamations(orderId, object.objectId);
      history.push(`/order/${orderId}/handle`);
    });
  };

  const handleOnSendMail = () => {
    setShowAskSendMail(false);
    setIsSending(true);
    dispatchSendCustomerMail(reclamation).then(() => {
      loadReclamation();
      setIsSending(false);
    }).catch(() => {
      setIsSending(false);
    });
  };

  const renderMailIcon = () => {
    if (reclamation.state === RECLAMATIONSTATE_SCHEDULED || reclamation.state === RECLAMATIONSTATE_ASSIGNED) {
      if (!reclamation.sendCustomerMail) {
        return (
          <Button onClick={() => setShowAskSendMail(true)} type="btn-primary">
            <i className="fas fa-envelope" style={{ margin: 0 }} />
          </Button>
        );
      }
      return (
        <Button
          onClick={() => setShowAskSendMail(true)}
          type="btn-success"
          title={formatDateTime(reclamation.sendCustomerMailDate)}
        >
          <i className="fas fa-envelope-open" style={{ margin: 0 }} />
        </Button>
      );
    }
    return null;
  };

  const handleMarkClosed = (closedComment) => {
    dispatchUpdateReclamation({ ...reclamation, state: RECLAMATIONSTATE_CLOSED, closedComment });
    setShowClosed(false);
  };

  const renderActionButtons = () => {
    const content = [];

    if (reclamation.state === RECLAMATIONSTATE_CLOSED) {
      return null;
    }

    if (reclamation.state === RECLAMATIONSTATE_REPORT_FINISHED) {
      return (
        <div className="float-right">
          <Button onClick={() => history.push(`${url}/report`)}>
            Reklamationsbericht
          </Button>
        </div>
      );
    }

    content.push(renderMailIcon());

    if (order.internal || isExternalOrderOwner(client.clientId, order)) {
      content.push((
        <>
          <Button
            onClick={() => setShowClosed(true)}
            type="btn-success"
            style={{ marginLeft: 10 }}
            title="Reklamation als erledigt markieren"
          >
            <i className="fas fa-check-circle" style={{ margin: 0 }} />
          </Button>
          <Button
            onClick={() => setShowDelete(true)}
            type="btn-danger"
            style={{ marginLeft: 10 }}
            title="Reklamation löschen"
          >
            <i className="fas fa-trash" style={{ margin: 0 }} />
          </Button>
        </>
      ));
    }

    if (content.length > 0) {
      return (
        <div className="float-right d-flex">
          {content}
        </div>
      );
    }
    return null;
  };

  const getUserDisabledHint = () => {
    if (allowAddMonteurToReclamation(reclamation) || isReclamationClosed(reclamation)) {
      return null;
    }

    if (order.internal) {
      return addMonteurDisabledHintInternal;
    }
    return addMonteurDisabledHintExternal;
  };

  const isUserTeamPanelDisabled = () => {
    if (isSystemView) {
      return true;
    }
    return (!allowAddMonteurToReclamation(reclamation) || isReclamationClosed(reclamation));
  };

  if (reclamation && order && object && report) {
    return (
      <div>
        <Panel marginBottom={20}>
          <div className="d-flex">
            <div className="flex-grow-1 d-flex">
              <ReclamationStatusBadge reclamation={reclamation} containerStyle={{ height: 40, borderRadius: 20 }} />
              <h4
                className="PrimaryHeadline d-flex align-items-center"
                style={{ marginLeft: 20, marginBottom: 0, marginRight: 20 }}
              >
                {`Reklamation "${reclamation.title}"`}
              </h4>
            </div>
            <div>
              {renderActionButtons()}
            </div>
          </div>
        </Panel>

        <ClosedHint reclamation={reclamation} order={order} client={client} />

        <div className="row">
          <div className="col-lg-12">
            <ObjectInformationPanel object={object} order={order} />
          </div>
        </div>

        <ReclamationInformation
          reclamation={reclamation}
          onChange={handleSetValue}
          order={order}
          editable={!isReclamationClosed(reclamation)}
        />

        <div className="row">
          <div className="col-lg-12">
            <DeviceList
              devices={reclamation.devices}
              serviceReport={report}
              onDelete={handelOnDeleteDevice}
              onAddDevice={onHandleAddDevice}
              disabled={
                isReclamationClosed(reclamation)
                || (client.clientId !== order.clientId && client.clientId !== order.mandantId)
              }
            />

            <AppointmentsDealer order={order} object={object} reclamation={reclamation} client={client} />
            <AppointmentsMonteur order={order} object={object} reclamation={reclamation} client={client} />

            <UserTeamPanel
              order={order}
              users={reclamation.users}
              teamId={reclamation.teamId}
              onAddUser={handleUserAdd}
              onDeleteUser={handleUserDelete}
              onAddTeam={handleTeamAdd}
              onDeleteTeam={handleTeamDelete}
              disabled={isUserTeamPanelDisabled()}
              disabledHint={getUserDisabledHint()}
              executionDate={reclamation.executionDate}
            />
          </div>
        </div>

        <ConfirmDeleteDialog
          onConfirm={handleConfirmDelete}
          visible={showDelete}
          onClose={() => setShowDelete(false)}
        />

        <ModalDialog
          onClose={() => setShowAskSendMail(false)}
          visible={showAskSendMail}
          confirmCaption="Jetzt senden"
          onConfirm={() => handleOnSendMail()}
        >
          <h6>Kundenbenachrichtigung versenden?</h6>
          <p>
            Möchten Sie den Kunden über die Änderungen an diesem Reklamationsauftrag per E-Mail informiern?
            Der Kunde erhält eine E-Mail mit allen relevanten Informationen zu seinem Reklamationsauftrag.
          </p>
        </ModalDialog>

        <MarkClosedDialog visible={showClosed} onClose={() => setShowClosed(false)} onSave={handleMarkClosed} />

        <LoadingModal visible={isSending} text="Benachrichtigung wird versendet..." />
        <LoadingModal visible={isAddingDevice} text="Reklamation wird hinzugefügt..." />
      </div>
    );
  }
  return <LoadingModal visible text="Reklamation wird geladen..." />;
}

Details.propTypes = {
  dispatchGetReclamation: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  dispatchGetReports: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  dispatchUpdateReclamation: PropTypes.func.isRequired,
  dispatchRemoveReclamation: PropTypes.func.isRequired,
  dispatchAddDevice: PropTypes.func.isRequired,
  dispatchDeleteDevice: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  dispatchGetReclamations: PropTypes.func.isRequired,
  dispatchSendCustomerMail: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  reclamation: PropTypes.instanceOf(Object),
  isSystemView: PropTypes.bool,
};

Details.defaultProps = {
  reclamation: null,
  isSystemView: false,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    reclamation: store.reclamation.selectedReclamation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetReclamation: (orderId, objectId, reclamationId) => dispatch(
      getReclamation(orderId, objectId, reclamationId),
    ),
    dispatchGetOrder: (orderId, forceReload = false) => dispatch(getOrder(orderId, forceReload)),
    dispatchGetReports: (orderId, objectId) => dispatch(getReports(orderId, objectId)),
    dispatchUpdateReclamation: (reclamationData) => dispatch(update(reclamationData)),
    dispatchRemoveReclamation: (reclamation) => dispatch(remove(reclamation)),
    dispatchGetReclamations: (orderId, objectId) => dispatch(getReclamations(orderId, objectId)),
    dispatchSendCustomerMail: (reclamation) => dispatch(sendCustomerMail(reclamation)),
    dispatchAddDevice: (reclamation, deviceData) => dispatch(addDevice(reclamation, deviceData)),
    dispatchDeleteDevice: (reclamation, deviceId) => dispatch(deleteDevice(reclamation, deviceId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Details));
