import React from 'react';
import Panel from '../../../Components/Panel';
import SelectPanel from '../../../Components/SelectPanels';
import {
  SUPPORT_CONTENT_SETUP,
  SUPPORT_CONTENT_CREATE_ORDER,
  SUPPORT_CONTENT_SERVICE,
  SUPPORT_CONTENT_FUNCTIONS,
  SUPPORT_CONTENT_EXECUTE_MONTAGE,
} from './SupportContent';

const categories = [{
  key: 0,
  caption: 'System einrichten',
  icon: 'fas fa-info',
},
{
  key: 1,
  caption: 'Aufträge erstellen',
  icon: 'fas fa-plus-circle',
},
{
  key: 2,
  caption: 'Montage durchführen',
  icon: 'fas fa-tools',
},
{
  key: 3,
  caption: 'Serviceaufträge',
  icon: 'fas fa-sync',
},

{
  key: 4,
  caption: 'Weitere Funktionen',
  icon: 'fas fa-code',
},
];
/**
 * Videos()
 * @returns {*}
 * @constructor
 */
export default function Videos() {
  const [selectedCategory, setSelectedCategory] = React.useState(-1);

  const renderContent = () => {
    let content = null;
    switch (selectedCategory) {
      case 0:
        content = SUPPORT_CONTENT_SETUP;
        break;
      case 1:
        content = SUPPORT_CONTENT_CREATE_ORDER;
        break;
      case 2:
        content = SUPPORT_CONTENT_EXECUTE_MONTAGE;
        break;
      case 3:
        content = SUPPORT_CONTENT_SERVICE;
        break;
      case 4:
        content = SUPPORT_CONTENT_FUNCTIONS;
        break;
      default: break;
    }
    if (content) {
      return (
        <>
          <Panel marginBottom={20}>
            <ol style={{ fontWeight: 'bold' }}>
              {content.map((item) => (
                <li key={item.videoId} style={{ marginBottom: 15 }}>
                  <a href={`#${item.videoId}`} style={{ color: 'inherit', marginLeft: 5 }}>
                    {item.header}
                  </a>
                </li>
              ))}
            </ol>
          </Panel>
          <Panel marginBottom={20}>
            {content.map((item) => (
              <div style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: 100 }} key={item.videoId}>
                <h6 id={item.videoId} style={{ scrollMarginTop: '10em', fontSize: 22, marginBottom: 20 }}>
                  {item.header}
                </h6>
                <iframe
                  width="982"
                  height="752"
                  src={`https://www.youtube.com/embed/${item.videoId}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div style={{ width: 982 }}>
                  {item.content}
                </div>
              </div>
            ))}
          </Panel>
        </>
      );
    }
    return null;
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <Panel marginBottom={20}>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h4>Hilfecenter</h4>
              <p style={{ marginBottom: 0 }}>
                Hier erhalten Sie Hilfestellung zu unserer Software.
              </p>
            </div>
          </div>
        </Panel>
        <Panel marginBottom={20}>
          <SelectPanel
            data={categories}
            onChange={(value) => setSelectedCategory(value)}
            selected={[selectedCategory]}
          />
        </Panel>
        {renderContent()}
      </div>
    </div>
  );
}

Videos.propTypes = {
};
