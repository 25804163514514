import React from 'react';
import {
  Route, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';
import RightNavigationLayout from '../../Components/Layout/RightNavigationLayout';
import SystemOrderNavigation from '../../Components/Navigation/SystemOrderNavigation';
import SystemOrderDetailsRoutes from './SystemOrderDetailsRoutes';
import External from '../../Pages/System/Order/External';
import Internal from '../../Pages/System/Order/Internal';
import SystemObjectDetailsRoutes from './SystemObjectDetailsRoutes';

/**
 * SystemOrderRoutes()
 * @returns {*}
 * @constructor
 */
export default function SystemOrderRoutes() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/internal`}>
        <RightNavigationLayout navigation={<SystemOrderNavigation />}>
          <Internal />
        </RightNavigationLayout>
      </Route>
      <Route exact path={`${path}/external`}>
        <RightNavigationLayout navigation={<SystemOrderNavigation />}>
          <External />
        </RightNavigationLayout>
      </Route>
      <Route path={`${path}/:orderId/object/:objectId`}>
        <SystemObjectDetailsRoutes />
      </Route>
      <Route path={`${path}/:orderId`}>
        <SystemOrderDetailsRoutes />
      </Route>
      <Route>
        <Redirect to={`${url}/external`} />
      </Route>
    </Switch>
  );
}
