export const validationFields = {
  orderConfirmation: false,
  installationPlan: false,
};

const allowedExtensions = ['pdf', 'doc', 'docx', 'jpeg', 'jpg', 'png', 'bmp'];

/**
 * Check if file input is valid
 * @param data
 * @returns {{zip, note, firstname, mail, city, streetNo, phone, street, floor, lastname}|boolean}
 */
export default function filesFormHasError(data) {
  let errorCodes = { ...validationFields };
  let hasError = false;

  const { orderConfirmation, installationPlan } = data;

  if (!orderConfirmation) {
    errorCodes = { ...errorCodes, orderConfirmation: true };
    hasError = true;
  } else {
    const extension = orderConfirmation.name.split('.').pop();
    if (allowedExtensions.indexOf(extension.toLowerCase()) === -1) {
      errorCodes = {
        ...errorCodes,
        orderConfirmation: true,
        orderConfirmationMessage: 'Dieser Dateityp ist nicht erlaubt!',
      };
      hasError = true;
    }
  }

  if (!installationPlan) {
    errorCodes = { ...errorCodes, installationPlan: true };
    hasError = true;
  } else {
    const extension = installationPlan.name.split('.').pop();
    if (allowedExtensions.indexOf(extension.toLowerCase()) === -1) {
      errorCodes = {
        ...errorCodes,
        installationPlan: true,
        installationPlanMessage: 'Dieser Dateityp ist nicht erlaubt!',
      };
      hasError = true;
    }
  }

  if (hasError) {
    return errorCodes;
  }
  return false;
}
