import moment from 'moment/moment';

export const validationFields = {
  deliveryDateStart: false,
  deliveryDateEnd: false,
};

/**
 * Check if delivery date is valid
 * @param data
 * @param orderType
 * @param isInternal
 * @returns {{deliveryDateStart: boolean, deliveryDateEnd: boolean}|boolean}
 */
export default function deliveryDateFormHasErrors(data, orderType, isInternal) {
  let errorCodes = { ...validationFields };
  let hasError = false;

  if (!isInternal) {
    if (orderType === 'OBJECT') {
      if (!data.deliveryDateStart) {
        errorCodes = {
          ...errorCodes,
          deliveryDateStart: true,
          deliveryDateStartMessage: 'Bitte geben Sie ein gültiges Datum in der Zukunft ein!',
        };
        hasError = true;
      }
      if (!data.deliveryDateEnd) {
        errorCodes = {
          ...errorCodes,
          deliveryDateEnd: true,
          deliveryDateEndMessage: 'Bitte geben Sie ein gültiges Datum ein!',
        };
        hasError = true;
      }
    } else if (!data.deliveryDateStart) {
      errorCodes = {
        ...errorCodes,
        deliveryDateStart: true,
        deliveryDateStartMessage: 'Bitte geben Sie ein gültiges Datum in der Zukunft ein!',
      };
      hasError = true;
    }
  }

  if (orderType === 'OBJECT') {
    if (!data.deliveryDateEnd && data.deliveryDateStart) {
      errorCodes = {
        ...errorCodes,
        deliveryDateEnd: true,
        deliveryDateEndMessage: 'Das Datum muss nach dem vorherigen Datum liegen',
      };
      hasError = true;
    }

    if (data.deliveryDateEnd && data.deliveryDateStart) {
      const start = moment(data.deliveryDateStart, 'YYYY-MM-DD');
      const end = moment(data.deliveryDateEnd, 'YYYY-MM-DD');

      if (end.diff(start, 'days') < 1) {
        errorCodes = {
          ...errorCodes,
          deliveryDateEnd: true,
          deliveryDateEndMessage: 'Das Datum muss nach dem vorherigen Datum liegen',
        };
        hasError = true;
      }
    }
  }

  if (hasError) {
    return errorCodes;
  }
  return false;
}
