import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useRouteMatch, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { formatCurrency, isExternalOrderAssignable } from '../../../../Library/Functions';
import Panel from '../../../../Components/Panel';
import Button from '../../../../Components/Button';
import { getList as getClientAddress } from '../../../../Redux/Action/ClientAddressAction';
import { getList as getDocuments } from '../../../../Redux/Action/ClientDocumentAction';
import { assign } from '../../../../Redux/Action/OrderAction';
import PickerOrderApproveDialog from './PickerOrderApproveDialog';
import LoadingModal from '../../../../Components/LoadingModal';
import { ORDERTYPE_OBJECT } from '../../../../Library/Types';

/**
 * MissingInformation()
 * @param props
 * @returns {*}
 * @constructor
 */
function MissingInformation(props) {
  const { client, addresses } = props;

  const renderMissingAddresses = () => {
    if (!addresses || addresses.length === 0) {
      return (
        <li>
          Sie müssen mindestens eine Lageradresse hinterlegt haben an welche eine Küche ggf. geliefert werden kann!
        </li>
      );
    }
    return null;
  };

  const renderMissingBank = () => {
    if (!client.bankBic || !client.bankIban || !client.bankName || !client.bankOwner) {
      return (
        <li>
          Bitte hinterlegen Sie Ihre vollständige Bankverbindung!
        </li>
      );
    }
    return null;
  };

  const renderMissingAddress = () => {
    if (
      !client.zip || !client.city || !client.street || !client.streetNo || !client.mail || !client.vat || !client.phone
    ) {
      return (
        <li>
          Bitte vervollständigen Sie Ihre Adressdaten. Sie müssen eine vollständige Adresse Ihrer Firma hinterlegt haben
          inkl. Umsatzsteuer-Identifikationsnummer, Telefonnummer und gültiger E-Mail Adresse.
        </li>
      );
    }
    return null;
  };

  return (
    <Panel marginBottom={25} className="Danger" shadow={false}>
      <h5 style={{ color: '#fff' }}>
        <i className="fa fa-info-circle" style={{ marginRight: 10 }} />
        Bevor Sie diesen Auftrag übernehmen können müssen Sie noch einige Informationen bereitstellen.
      </h5>
      <div>
        Folgende Informationen müssen bereitgestellt werden:
        <ul style={{ marginTop: 15, marginBottom: 0 }}>
          {renderMissingAddresses()}
          {renderMissingBank()}
          {renderMissingAddress()}
        </ul>
      </div>
      <p style={{ marginBottom: 0, marginTop: 20 }}>
        <NavLink to="/admin/client" style={{ textDecoration: 'underline', color: '#fff' }}>
          <strong>Einstellungen öffnen</strong>
        </NavLink>
      </p>
    </Panel>
  );
}

MissingInformation.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  addresses: PropTypes.instanceOf(Array),
};

MissingInformation.defaultProps = {
  addresses: null,
};

const CMissingInformation = withRouter(MissingInformation);

/**
 * PickOrder()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function PickOrder(props) {
  const {
    order, object, client, assignOrder, dispatchGetAddress, dispatchGetDocuments, addresses, visible,
    history,
  } = props;
  const { url } = useRouteMatch();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatchGetAddress();
    dispatchGetDocuments();
  }, [dispatchGetDocuments, dispatchGetAddress]);

  const handleAssign = (addressId) => {
    setIsLoading(true);
    setShowModal(false);
    assignOrder(order, addressId).then(() => {
      setIsLoading(false);
      history.push(`${url}/1`);
    }).catch(() => setIsLoading(false));
  };

  const renderIsOrderObjectHint = () => {
    if (order.type === ORDERTYPE_OBJECT) {
      return (
        <>
          <br />
          <strong>
            Bitte beachten Sie das es sich bei dem Auftrag um einen Objektauftrag handelt und dieser mehrere
            Objekte zur Montage enthält!
          </strong>
        </>
      );
    }
    return null;
  };

  const isAccountValid = () => (
    !(!addresses || addresses.length === 0 || !client.bankBic || !client.bankIban
      || !client.bankName || !client.bankOwner || !client.zip || !client.city || !client.street || !client.streetNo
      || !client.mail || !client.vat || !client.phone)
  );

  if (visible && isExternalOrderAssignable(order, object, client)) {
    if (!isAccountValid()) {
      return <CMissingInformation client={client} addresses={addresses} />;
    }

    return (
      <Panel marginBottom={20}>
        <div className="text-center">
          <p>
            Sie können diesen Auftrag zum angezeigten Preis übernehmen und durchführen.
            {renderIsOrderObjectHint()}
          </p>

          <h5 style={{ marginTop: '40px' }}>
            Auftragspreis
          </h5>
          <h1 style={{ marginBottom: '40px' }} className="hk-text-primary">
            <strong>
              {formatCurrency(order.mechanicPrice)}
              *
            </strong>
          </h1>

          <div>
            <small style={{ fontSize: '16px' }} className="text-muted">
              *Nacharbeiten / Reklamationsarbeiten: 45 Euro (Anfahrt) + 39 Euro pro Monteur pro Stunde
            </small>
          </div>

          <br />

          <Button onClick={() => setShowModal(true)} className="btn-success">
            Auftrag verbindlich übernehmen
          </Button>
        </div>

        <PickerOrderApproveDialog
          visible={showModal}
          order={order}
          onCancel={() => setShowModal(false)}
          onSave={handleAssign}
        />

        <LoadingModal visible={isLoading} text="Auftrag wird übernommen..." />
      </Panel>
    );
  }
  return null;
}

PickOrder.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object).isRequired,
  dispatchGetAddress: PropTypes.func.isRequired,
  dispatchGetDocuments: PropTypes.func.isRequired,
  assignOrder: PropTypes.func,
  addresses: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool,
};

PickOrder.defaultProps = {
  assignOrder: () => {},
  object: null,
  addresses: null,
  visible: false,
};

function mapStoreToProps(store) {
  return {
    addresses: store.clientAddress.addresses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    assignOrder: (order, addressId) => dispatch(assign(order, addressId)),
    dispatchGetAddress: () => dispatch(getClientAddress()),
    dispatchGetDocuments: () => dispatch(getDocuments()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(PickOrder));
