import React from 'react';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import { ORDER_OPTION_MONTAGE, orderOptionOptions } from '../../../Library/Types/OrderTypes';
import SelectPanel from '../../SelectPanels';
import Input from '../../Input';

export const initialOptions = {
  optionMontage: true,
  optionDelivery: false,
  optionRemoval: false,
  removalMeter: null,
};

export default function SelectOrderOptions(props) {
  const {
    state, onChange, headline, errors,
  } = props;

  const selected = Object.keys(state)
    .filter((key) => key.startsWith('option'))
    .filter((key) => state[key])
    .map((key) => key.slice(6).toUpperCase());

  const handleOptionChange = (value) => {
    if (value === ORDER_OPTION_MONTAGE) {
      return;
    }
    if (onChange) {
      const key = `option${Lodash.capitalize(value.toLowerCase())}`;
      const newState = { ...state, [key]: !state[key] };
      onChange({ ...newState, removalMeter: newState.optionRemoval ? newState.removalMeter : null });
    }
  };

  const handleMeterChange = (removalMeter) => {
    if (onChange) {
      onChange({ ...state, removalMeter });
    }
  };

  const renderRemovalMeter = () => (
    (state.optionRemoval) ? (
      <div style={{ marginTop: 15 }}>
        <Input
          value={state.removalMeter}
          onChange={handleMeterChange}
          label="Länge der zu demontierenden Küche (Hinterkante Arbeitsplatte)"
          rightText="m"
          autoFocus
          hasError={errors.removalMeter}
          errorMessage={errors.removalMeterMessage}
        />
      </div>
    ) : null);

  return (
    <>
      <h5>{headline}</h5>
      <p className="text-muted">
        Wählen Sie mindestens eine oder mehrere Optionen für Ihren Auftrag
      </p>
      <SelectPanel
        data={orderOptionOptions}
        selected={selected}
        isMultiSelect
        returnSingleKey
        onChange={handleOptionChange}
      />
      {renderRemovalMeter()}
    </>
  );
}

SelectOrderOptions.propTypes = {
  state: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  headline: PropTypes.string,
  errors: PropTypes.instanceOf(Object),
};

SelectOrderOptions.defaultProps = {
  state: initialOptions,
  onChange: undefined,
  headline: 'Auftragsoptionen',
  errors: {},
};
