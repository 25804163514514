import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import ModalDialog from '../ModalDialog';
import initialState from '../../Pages/Administration/Subcontractor/Add';
import UserTable from '../UserTable';
import Button from '../Button';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function SubcontractorTable(props) {
  const {
    subcontractors, onDelete, onUpdate, users, updateUser,
  } = props;
  const [showModalAssignment, setShowModalAssignment] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [item, setItem] = useState('');
  const [toDeleteItem, setToDeleteItem] = useState({ initialState });
  const [toDeleteItemHasAssignments, setToDeleteItemHasAssignemnts] = useState(false);
  const [toDeleteConfirmCaption, setToDeleteConfirmCaption] = useState('Löschen');

  const handleDelete = (value) => {
    setToDeleteItem(value);
    setShowModalDelete(true);
  };

  const forwardOnDelete = (forward, deleteAssignedUsers) => {
    onDelete(forward, deleteAssignedUsers);
    setShowModalDelete(false);
  };

  const onShowAssignment = (receivedItem) => {
    setShowModalAssignment(true);
    setItem(receivedItem);
  };

  function unassignAllUsers(receivedDeleteItem) {
    users.filter((user) => user.subcontractorId === receivedDeleteItem.subcontractorId).forEach(
      (element) => {
        const updateEntry = element;
        updateEntry.subcontractorId = 0;
        updateUser(updateEntry.userId, updateEntry);
      },
    );
    forwardOnDelete(toDeleteItem, false);
    setToDeleteItemHasAssignemnts(false);
    setShowModalDelete(false);
    setToDeleteConfirmCaption('Löschen');
  }

  const leftButton = () => {
    if (toDeleteItemHasAssignments) {
      return (
        <Button
          type="btn-primary"
          onClick={() => forwardOnDelete(toDeleteItem, true)}
        >
          Alles löschen
        </Button>
      );
    }
    return null;
  };

  function resetDeleteModal() {
    setShowModalDelete(false);
    setToDeleteItem('');
    setToDeleteItemHasAssignemnts(false);
    setToDeleteConfirmCaption('Löschen');
  }
  function getDeleteDialogString() {
    const text = 'Dem Subunternehmen "{name}" sind noch {users} Benutzer zugeordnet. Sie können nur das '
        + 'Subunternehmen löschen (verbleibende Benutzer verlieren die Zuordnung zum Subunternehmen) oder aber die '
        + 'Tabelle als auch die zugehörigen Benutzer löschen.';

    if (toDeleteItemHasAssignments) {
      return text.replace('{name}', toDeleteItem.name);
    }
    return (
      `Möchten Sie das Subunternehmen "${toDeleteItem.name}" endgültig löschen?`
    );
  }

  const renderDeleteModalDialog = () => {
    if (users.filter((user) => user.subcontractorId === toDeleteItem.subcontractorId).length > 0
    && !toDeleteItemHasAssignments) {
      setToDeleteItemHasAssignemnts(true);
      setToDeleteConfirmCaption('Zuweißungen entfernen, Subunternehmer löschen');
    }
    return (
      <ModalDialog
        onClose={resetDeleteModal}
        onConfirm={() => unassignAllUsers(toDeleteItem)}
        visible={showModalDelete}
        confirmCaption={toDeleteConfirmCaption}
        centered
        leftButtonContent={leftButton()}
      >
        <div>
          <h5>Subunternehmen löschen</h5>
          {getDeleteDialogString()}
        </div>
      </ModalDialog>
    );
  };

  function renderUserTable() {
    const filteredUsers = users.filter((user) => user.subcontractorId === item.subcontractorId);
    if (filteredUsers.length > 0) {
      return (
        <UserTable
          users={filteredUsers}
          onDelete={() => {}}
          onUpdate={() => {}}
          hideActions={false}
        />
      );
    }
    return (
      <div>Sie können in der Benutzerverwaltung diesem Subunternehmen Benutzer zuweisen.</div>
    );
  }

  return (
    <Panel marginBottom={25}>
      <table className="table table-hover linked table-options" style={{ marginTop: 0 }}>
        <thead>
          <tr>
            <th />
            <th>ID</th>
            <th>Name</th>
            <th style={{ textAlign: 'center' }}>Anzahl an Benutzer</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {
            subcontractors.map(
              (currentItem) => (
                <Row
                  key={currentItem.subcontractorId}
                  item={currentItem}
                  onDelete={(data) => handleDelete(data)}
                  onUpdate={onUpdate}
                  onShowAssignment={onShowAssignment}
                  users={users}
                />
              ),
            )
          }
        </tbody>
      </table>
      {renderDeleteModalDialog()}
      <ModalDialog
        onClose={() => setShowModalAssignment(false)}
        hideConfirmButton
        visible={showModalAssignment}
        closeCaption="Schließen"
        width={1200}
      >
        <h5 style={{ marginLeft: 20, marginBottom: 30 }}>Benutzerübersicht</h5>
        <div className="col-sm-12">
          {renderUserTable()}
        </div>
      </ModalDialog>
    </Panel>
  );
}

SubcontractorTable.propTypes = {
  subcontractors: PropTypes.instanceOf(Object).isRequired,
  users: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

SubcontractorTable.defaultProps = {
};

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Row(props) {
  const {
    item, onDelete, onUpdate, onShowAssignment, users,
  } = props;

  const renderIcon = () => {
    if (item.active) {
      return (
        <td width={20}><i className="fas fa-check-circle text-success" /></td>
      );
    }
    return (
      <td><i className="fas fa-times-circle text-danger" /></td>
    );
  };

  const handleOnDelete = (event) => {
    event.stopPropagation();
    onDelete(item);
  };

  const handleOnShowAssignment = (event) => {
    event.stopPropagation();
    onShowAssignment(item);
  };

  const renderDeleteIcon = () => (
    <td className="options" width={120}>
      <i
        className="fas fa-eye primary"
        onClick={handleOnShowAssignment}
        tabIndex={-1}
        onKeyPress={() => {}}
        role="button"
      />
      <i
        className="fas fa-pencil-alt primary"
        onClick={null}
        tabIndex={-1}
        onKeyPress={() => {}}
        role="button"
      />
      <i
        className="fas fa-trash danger"
        onClick={handleOnDelete}
        tabIndex={-1}
        onKeyPress={() => {}}
        role="button"
      />
    </td>
  );

  function renderUserCount(receivedItem) {
    return (
      <td style={{ textAlign: 'center' }}>
        { users.filter((user) => user.subcontractorId === receivedItem.subcontractorId).length }
      </td>
    );
  }

  return (
    <tr onClick={() => onUpdate(item)} tabIndex={-1}>
      {renderIcon()}
      <td>{item.subcontractorId}</td>
      <td>{item.name}</td>
      {renderUserCount(item)}
      {renderDeleteIcon(item)}
    </tr>
  );
}

Row.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  users: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onShowAssignment: PropTypes.func.isRequired,
};
