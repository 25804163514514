import React from 'react';

import './style.css';

const processFullNormal = require('../../../../Assets/Images/process-partner-1.png');
const processFullMobile = require('../../../../Assets/Images/process-partner-2.png');

/**
 * MonteurProcessDescription()
 * @returns {*}
 * @constructor
 */
export default function MonteurProcessDescription() {
  return (
    <div className="MonteurProcessDescription FullPageBox Light Center">
      <div className="container d-flex flex-column">
        <h2 style={{ marginBottom: 10, textAlign: 'center' }}>Sie übernehmen Transport und Montage.</h2>
        <h2 style={{ textAlign: 'center' }}>Wir kümmern uns um den Rest!</h2>
        <p>
          Sie wünschen mehr Aufträge, weniger Verwaltungsaufwand, eine deutlich bessere Produktivität und eine
          bessere Bezahlung? Um das zu erreichen, haben wir Hey.kitchen erfunden.
        </p>
        <img
          src={processFullNormal}
          className="NormalImage"
          alt="HK Prozess Full"
        />
        <img
          src={processFullMobile}
          className="MobileImage"
          alt="HK Prozess Full"
        />
      </div>
    </div>
  );
}
