import React from 'react';
import styled from 'styled-components';
import Panel from '../../../Components/Panel';
import fields from './Component/CsvFieldDescription';
import ShowHideContainer from '../../../Components/ShowHideContainer';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  .Row {
    display: flex;
    flex-direction: row;
    
    .Left {
      width: 200px;
      min-width: 200px;
    }
    
    .FieldName {
      color: #000;
      font-family: "DIN Next LT W01 Bold", sans-serif;
    }
  }
`;

/**
 * CsvDocumentation()
 * @returns {JSX.Element}
 * @constructor
 */
export default function CsvDocumentation() {
  const renderItem = (item) => (
    <Panel marginBottom={20} withPadding={false}>
      <FieldContainer>
        <div className="Row">
          <div className="Left">Feldbezeichnung:</div>
          <div className="FieldName">{item.field}</div>
        </div>
        <div className="Row">
          <div className="Left">Typ:</div>
          <div><code>{item.type}</code></div>
        </div>
        <ShowHideContainer visible={(item.default !== undefined)}>
          <div className="Row">
            <div className="Left">Standardwert:</div>
            <div><code>{item.default}</code></div>
          </div>
        </ShowHideContainer>
        <div className="Row">
          <div className="Left">Pflichtfeld:</div>
          <div>
            {
              (item.mandatory)
                ? <i className="fas fa-check-square text-success" />
                : <i className="fas fa-minus-square text-danger" />
            }
          </div>
        </div>
        <div className="Row">
          <div className="Left">Beschreibung:</div>
          <div>{item.description}</div>
        </div>
      </FieldContainer>
    </Panel>
  );

  return (
    <div className="row">
      <div className="col-xl-12">
        <Panel marginBottom={20}>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              <h4>CSV Dokumentation</h4>
              <p style={{ margin: 0, padding: 0 }}>
                Die CSV-Dokumentation beschreibt welche Felder Sie innerhalb einer CSV-Datei beim Import übergeben
                können und welches Format die Felder haben müssen. Bitte beachten Sie das die erste Zeile der Datei
                immer die Feldbezeichnungen enthalten muss und das die Feldbezeichnungen Case-Sensitive sind. Dies
                bedeutet das die Bezeichnungen exakt so geschrieben sein müssen wie hier in der Dokumentation
                vorgegeben. Die Reihenfolge spielt dabei keine Rolle. Als Trennzeichen muss ein Semikolon verwendet
                werden. Bitte achten Sie ebenfalls bei der Erstellung der Datei auf die passende UTF-8 Kodierung.
                Nur Dateien mit richtiger Kodierung können für den Import ausgewählt und hochgeladen werden.
              </p>
            </div>
          </div>
        </Panel>

        <Panel marginBottom={20} withPadding={false}>
          <h5 className="hk-text-primary" style={{ margin: 0 }}>
            <i className="fas fa-chevron-circle-right" style={{ marginRight: 10 }} />
            Auftragsinformationen
          </h5>
        </Panel>
        {fields.map((item) => ((item.category === 'order') ? renderItem(item) : null))}
        <Panel marginBottom={20} withPadding={false}>
          <h5 className="hk-text-primary" style={{ margin: 0 }}>
            <i className="fas fa-chevron-circle-right" style={{ marginRight: 10 }} />
            Kücheninformationen
          </h5>
        </Panel>
        {fields.map((item) => ((item.category === 'object') ? renderItem(item) : null))}
        <Panel marginBottom={20} withPadding={false}>
          <h5 className="hk-text-primary" style={{ margin: 0 }}>
            <i className="fas fa-chevron-circle-right" style={{ marginRight: 10 }} />
            Kundendaten
          </h5>
        </Panel>
        {fields.map((item) => ((item.category === 'customer') ? renderItem(item) : null))}
        <Panel marginBottom={20} withPadding={false}>
          <h5 className="hk-text-primary" style={{ margin: 0 }}>
            <i className="fas fa-chevron-circle-right" style={{ marginRight: 10 }} />
            Beladestelle / Lageradresse
          </h5>
        </Panel>
        {fields.map((item) => ((item.category === 'storage') ? renderItem(item) : null))}
      </div>
    </div>
  );
}
