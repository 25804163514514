import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Redux from 'react-redux';
import Input from '../../../../Components/Input';
import { get, getList } from '../../../../Redux/Action/ClientAddressAction';
import CustomReactSelect from '../../../../Components/CustomReactSelect';
import { useDispatchMandantAddressList } from '../../../../Redux/Action/MandantAction';

function initReducer(data) {
  return { ...data };
}

function reducer(state, action) {
  switch (action.type) {
    case 'setAddress':
      return { ...action.payload };
    case 'name':
      return { ...state, name: action.payload };
    case 'zip':
      return { ...state, zip: action.payload.trim() };
    case 'city':
      return { ...state, city: action.payload };
    case 'street':
      return { ...state, street: action.payload };
    case 'streetNo':
      return { ...state, streetNo: action.payload };
    case 'contactPhone':
      return { ...state, contactPhone: action.payload };
    case 'contactName':
      return { ...state, contactName: action.payload };
    case 'comment':
      return { ...state, comment: action.payload };
    case 'clear':
      return {};

    default:
      throw new Error();
  }
}

/**
 * LoadingAddress()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function LoadingAddress(props) {
  const {
    onChange, storageSelected, errors, editableData, deliverySelected, getAddressesFnc, mandantId,
    dispatchGetAddress, partners, internal, indexProcessStep,
  } = props;
  const [addressOptions, setAddressOptions] = useState([]);
  const [state, dispatch] = useReducer(reducer, editableData, initReducer);
  const [disabled, setDisabled] = useState(false);
  const [prevState, setPrevState] = useState(JSON.stringify(state));
  const [partnerAddress, setPartnerAddress] = React.useState(null);
  const [partnerName, setPartnerName] = useState(null);
  const [selected, setSelected] = useState(null);

  const clientId = Redux.useSelector((store) => (store.auth.client.clientId));
  const dispatchMandantAddressList = useDispatchMandantAddressList();

  React.useEffect(() => {
    if (!editableData || Object.keys(editableData).length === 0) {
      if (mandantId && mandantId > 0 && partners && partners.length > 0 && storageSelected) {
        const filtered = partners.filter((item) => item.partner.clientId === mandantId)[0];
        if (filtered) {
          const { defaultClientAddressId } = filtered.partnerSettings;
          dispatchGetAddress(mandantId, defaultClientAddressId).then((response) => {
            setPartnerAddress(response);
            setPartnerName(filtered.partner.name);
            dispatch({ type: 'setAddress', payload: response });
          });
        }
      } else {
        setPartnerAddress(null);
        dispatch({ type: 'clear' });
      }
    }
  }, [mandantId, partners, dispatchGetAddress, storageSelected, editableData]);

  React.useEffect(() => {
    if (JSON.stringify(state) !== prevState) {
      if (onChange) {
        onChange(state);
        setPrevState(JSON.stringify(state));
      }
    }
  }, [onChange, state, prevState]);

  React.useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const addressesArray = [];
    addressesArray.push({ label: 'Neue Adresse eingeben', value: null });

    getAddressesFnc().then((data) => {
      if (data && data.length > 0) {
        data.forEach((item) => {
          addressesArray.push({ label: item.name, value: item.addressId });
        });
        dispatchMandantAddressList(clientId).then((mandantAddress) => {
          mandantAddress.forEach((item) => {
            addressesArray.push({ label: `(Partner) ${item.name}`, value: item.addressId });
          });
        });
        setAddressOptions(addressesArray);
      }
    });
  }, [getAddressesFnc, clientId, dispatchMandantAddressList]);

  const handleAddressChange = (id) => {
    if (!id) {
      setDisabled(false);
      return;
    }
    setSelected(id);
    dispatchGetAddress(clientId, id).then((response) => {
      dispatch({ type: 'setAddress', payload: response });
      setDisabled(true);
    });
  };

  if (internal && mandantId === 0 && storageSelected) {
    return null;
  }

  if (!storageSelected && deliverySelected) {
    return (
      <form>
        <div className="row">
          <div className="col-lg-12">
            <h5>{`${indexProcessStep}Beladestelle`}</h5>
            <p className="text-muted">
              Bitte geben Sie an wo die Küche abgeholt werden muss
            </p>
          </div>

          <CustomReactSelect
            onChange={(addressId) => handleAddressChange(addressId)}
            options={addressOptions}
            rowClass="col-lg-12"
            value={selected}
          />

          <div className="col-lg-12">
            <Input
              onChange={(value) => dispatch({ type: 'name', payload: value })}
              value={state.name}
              placeholder="Firma / Name"
              disabled={disabled}
              hasError={!!errors.name}
              visible={(!selected)}
            />
            <div className="row">
              <div className="col-lg-8">
                <Input
                  onChange={(value) => dispatch({ type: 'street', payload: value })}
                  value={state.street}
                  placeholder="Strasse"
                  disabled={disabled}
                  hasError={!!errors.street}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  onChange={(value) => dispatch({
                    type: 'streetNo',
                    payload: value,
                  })}
                  value={state.streetNo}
                  placeholder="Hausnummer"
                  disabled={disabled}
                  hasError={!!errors.streetNo}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <Input
                  onChange={(value) => dispatch({
                    type: 'zip',
                    payload: value,
                  })}
                  value={state.zip}
                  placeholder="Postleitzahl"
                  disabled={disabled}
                  hasError={!!errors.zip}
                  errorMessage={errors.zipMessage}
                />
              </div>
              <div className="col-lg-8">
                <Input
                  onChange={(value) => dispatch({
                    type: 'city',
                    payload: value,
                  })}
                  value={state.city}
                  placeholder="Ort"
                  disabled={disabled}
                  hasError={!!errors.city}
                />
              </div>
            </div>

            <Input
              onChange={(value) => dispatch({
                type: 'contactName',
                payload: value,
              })}
              value={state.contactName}
              placeholder="Ansprechpartner"
              disabled={disabled}
              hasError={!!errors.contactName}
            />
            <Input
              onChange={(value) => dispatch({
                type: 'contactPhone',
                payload: value,
              })}
              value={state.contactPhone}
              placeholder="Kontakt Telefon"
              disabled={disabled}
              hasError={!!errors.contactPhone}
              errorMessage={errors.contactPhoneMessage}
            />
            <Input
              onChange={(value) => dispatch({
                type: 'comment',
                payload: value,
              })}
              value={state.comment}
              placeholder="Platz für Notizen"
            />
          </div>
        </div>
      </form>
    );
  }

  if (storageSelected && mandantId && mandantId > 0 && partnerAddress) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <h5>{`${indexProcessStep}Lageradresse Ihres Partners`}</h5>
          <p>
            Ihr Partner "
            {partnerName}
            " hat Ihnen folgende Lageradresse zugewiesen. Bitte liefern Sie Ihre Ware an folgende Lageradresse.
          </p>

          <div className="text-center" style={{ marginTop: 40 }}>
            <div><strong>{state.name}</strong></div>
            <div>{`${state.street} ${state.streetNo} `}</div>
            <div>{`${state.zip} ${state.city} `}</div>
            <div style={{ marginTop: 8 }}>
              <div>Ansprechpartner:</div>
              <div>{`${state.contactName} (${state.contactPhone})`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (storageSelected && !internal) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <h5>{`${indexProcessStep}Lageradresse`}</h5>
          <div className="alert alert-warning" role="alert" style={{ marginTop: '20px' }}>
            Die Adresse des Lagers, an welches Sie die Küche liefern müssen, werden wir Ihnen
            in kürze per E-Mail mitteilen!
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <h5>{`${indexProcessStep}Lageradresse / Beladestelle`}</h5>
        <div className="alert alert-warning" role="alert" style={{ marginTop: '20px' }}>
          Keine Lageradresse oder Beladestelle für diesen Auftrag notwendig!
        </div>
      </div>
    </div>
  );
}

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    addresses: store.clientAddress.addresses,
    partners: store.client.partners,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAddressesFnc: () => dispatch(getList()),
    dispatchGetAddress: (clientId, addressId) => dispatch(get(clientId, addressId)),
  };
}

LoadingAddress.propTypes = {
  onChange: PropTypes.func.isRequired,
  storageSelected: PropTypes.bool.isRequired,
  deliverySelected: PropTypes.bool.isRequired,
  dispatchGetAddress: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object),
  editableData: PropTypes.instanceOf(Object),
  getAddressesFnc: PropTypes.func.isRequired,
  mandantId: PropTypes.number,
  partners: PropTypes.instanceOf(Array),
  internal: PropTypes.bool.isRequired,
  indexProcessStep: PropTypes.number,
};

LoadingAddress.defaultProps = {
  errors: {},
  editableData: {},
  mandantId: null,
  partners: null,
  indexProcessStep: '3. ',
};

export default connect(mapStoreToProps, mapDispatchToProps)(LoadingAddress);
