import {
  SYSTEM_OBJECTS_GET_LIST_START,
  SYSTEM_OBJECTS_GET_LIST_SUCCESS,
  SYSTEM_OBJECTS_GET_LIST_ERROR,
  SYSTEM_OBJECTS_UPDATE_FILTERS,
  SYSTEM_OBJECTS_APPLY_FILTERS_START,
  SYSTEM_OBJECTS_APPLY_FILTERS_SUCCESS, SYSTEM_OBJECTS_APPLY_FILTERS_FINISHED,
} from '../Action/OrderAction';
import {
  SYSTEM_CLIENT_GETORDERS_ERROR,
  SYSTEM_CLIENT_GETORDERS_START,
  SYSTEM_CLIENT_GETORDERS_SUCCESS,
} from '../Action/ClientAction';

const defaultTargetValues = {
  objects: null,
  filteredObjects: null,
  filters: null,
  lastUpdated: null,
  isLoading: false,
  isWorking: false,
};

const defaultValues = {
  internal: { ...defaultTargetValues },
  external: { ...defaultTargetValues },
  client: { ...defaultTargetValues },
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case SYSTEM_CLIENT_GETORDERS_START:
    case SYSTEM_OBJECTS_GET_LIST_START: {
      const update = {};
      const { target } = action.payload;
      const isLoading = true;
      update[target] = { ...state[target], isLoading };
      return { ...state, ...update };
    }

    case SYSTEM_CLIENT_GETORDERS_SUCCESS:
    case SYSTEM_OBJECTS_GET_LIST_SUCCESS: {
      const update = {};
      const { target, objects, lastUpdated } = action.payload;
      const isLoading = false;
      update[target] = {
        ...state[target], isLoading, objects, lastUpdated,
      };
      return { ...state, ...update };
    }

    case SYSTEM_CLIENT_GETORDERS_ERROR:
    case SYSTEM_OBJECTS_GET_LIST_ERROR: {
      const update = {};
      const { target } = action.payload;
      const isLoading = false;
      update[target] = { ...state[target], isLoading };
      return { ...state, ...update };
    }

    case SYSTEM_OBJECTS_UPDATE_FILTERS: {
      const update = {};
      const { target, newFilter } = action.payload;
      const filters = { ...state[target].filters, ...newFilter };
      update[target] = { ...state[target], filters };
      return { ...state, ...update };
    }

    case SYSTEM_OBJECTS_APPLY_FILTERS_START: {
      const update = {};
      const { target } = action.payload;
      const isWorking = true;
      update[target] = { ...state[target], isWorking };
      return { ...state, ...update };
    }

    case SYSTEM_OBJECTS_APPLY_FILTERS_SUCCESS: {
      const update = {};
      const { target, filteredObjects } = action.payload;
      const isWorking = false;
      update[target] = { ...state[target], isWorking, filteredObjects };
      return { ...state, ...update };
    }

    case SYSTEM_OBJECTS_APPLY_FILTERS_FINISHED: {
      const update = {};
      const { target } = action.payload;
      const isWorking = false;
      update[target] = { ...state[target], isWorking };
      return { ...state, ...update };
    }

    default: return { ...state };
  }
}
