import React from 'react';
import {
  Route, withRouter, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import RightNavigationLayout from '../../Components/Layout/RightNavigationLayout';
import ObjectDetailsNavigation from '../../Components/Navigation/System/ObjectDetailsNavigation';
import Details from '../../Pages/System/Order/Object/Details';
import History from '../../Pages/System/Order/Object/History';
import Offers from '../../Pages/System/Order/Offers';
import Messages from '../../Components/ObjectComponents/Messages';
import ServiceReport from '../../Pages/Order/ServiceReport/ServiceReport';
import ServiceDetails from '../../Pages/Order/Service/Details';
import ObjectServiceReport from '../../Pages/Order/Service/Report';
import ServiceCreate from '../../Pages/Order/Service/Create';
import OrderDetailsReclamation from '../../Pages/Order/Reclamation/Details';
import Report from '../../Pages/Order/Reclamation/Report';

/**
 * SystemOrderDetailsRoutes()
 * @returns {*}
 * @constructor
 */
function SystemObjectDetailsRoutes() {
  const { path, url } = useRouteMatch();

  return (
    <RightNavigationLayout navigation={<ObjectDetailsNavigation />}>
      <Switch>
        <Route exact path={path}><Details /></Route>
        <Route exact path={`${path}/history`}><History /></Route>
        <Route exact path={`${path}/offers`}><Offers /></Route>
        <Route exact path={`${path}/messages`}><Messages isAdmin /></Route>
        <Route exact path={`${path}/report/:reportId`}><ServiceReport /></Route>
        <Route exact path={`${path}/reclamation/:reclamationId`}><OrderDetailsReclamation isSystemView /></Route>
        <Route exact path={`${path}/reclamation/:reclamationId/report`}><Report /></Route>
        <Route exact path={`${path}/service/create`}><ServiceCreate isSystemView /></Route>
        <Route exact path={`${path}/service/:serviceId`}><ServiceDetails isSystemView /></Route>
        <Route exact path={`${path}/service/:serviceId/report`}><ObjectServiceReport isSystemView /></Route>
        <Route><Redirect to={url} /></Route>
      </Switch>
    </RightNavigationLayout>
  );
}

export default connect(() => ({}), () => ({}))(withRouter(SystemObjectDetailsRoutes));
