import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { getMinuteDiff } from '../../../../Library/Functions';
import { objectDateTypeIcons } from '../../../../Library/Types';

/**
 * ObjectDateInformation()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectDateInformation(props) {
  const { appointment, height } = props;

  const renderIcon = () => (
    <i
      className={objectDateTypeIcons[appointment.source.type]}
      style={{ marginRight: 10 }}
    />
  );

  const renderAdditionalInformation = () => {
    if (height > 40) {
      return (
        <>
          <div className="SubCaption">
            {`${appointment.source.zip} ${appointment.source.city}`}
          </div>
          <div className="Time">
            {`Dauer: ${getMinuteDiff(appointment.start, appointment.end)}`}
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div className="Caption">
        <div>
          {renderIcon()}
          <strong>{`${appointment.title}`}</strong>
        </div>
        {renderAdditionalInformation()}
      </div>
    </>
  );
}

ObjectDateInformation.propTypes = {
  appointment: PropTypes.instanceOf(Object).isRequired,
  height: PropTypes.number.isRequired,
};
