import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getOrders, getReclamations, getServices } from '../../Redux/Action/ClientAction';
import { getOrders as getUserOrders, getReclamations as getUserReclamations, getServices as getUserServices }
  from '../../Redux/Action/UserAction';
import Panel from '../../Components/Panel';
import FilterComponent from './Components/OrderFilter';
import Input from '../../Components/Input';
import { NAVIGATION_OBJECT_DETAILS } from '../../Library/Types';
import { ORDERSTATE_WAITING_APPROVEMENT } from '../../Library/StateTypes';
import OrderServiceTable from '../../Components/OrderServiceTable/OrderServiceTable';
import { useAccessCanSeeAllClientOrders } from '../../Library/AccessChecks';
import { useDispatchSearchByClient, useDispatchSearchByUser } from '../../Redux/Action/OrderAction';

/**
 * Filter()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function Filter(props) {
  const {
    clientOrders, dispatchGetAllClientOrders, dispatchGetClientServices,
    dispatchGetClientReclamations, clientReclamations, client, clientServices, history,
    dispatchGetAllUserOrders, dispatchGetUserReclamations, dispatchGetUserServices,
    userOrders, userReclamations, userServices,
  } = props;
  const canSeeAllClientOrders = useAccessCanSeeAllClientOrders();
  const [filtered, setFiltered] = useState(clientOrders || userOrders);
  const [isOrderFilter, setIsOrderFilter] = useState(false);
  const [isReclamationFilter, setIsReclamationFilter] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({ starDate: undefined, endDate: undefined });

  const dispatchSearchByClient = useDispatchSearchByClient();
  const dispatchSearchByUser = useDispatchSearchByUser();

  React.useEffect(() => {
    setSearchResult(null);
    setSearchValue('');
  }, [isOrderFilter, isReclamationFilter]);

  React.useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      const delayedDispatch = setTimeout(() => {
        setLoading(true);
        if (canSeeAllClientOrders) {
          dispatchSearchByClient(searchValue).then((response) => {
            setSearchResult(response);
            setLoading(false);
          });
        } else {
          dispatchSearchByUser(searchValue).then((response) => {
            setSearchResult(response);
            setLoading(false);
          });
        }
      }, 650);
      return () => clearTimeout(delayedDispatch);
    }
    setSearchResult(null);
    return () => {};
  }, [searchValue, filtered, isOrderFilter, canSeeAllClientOrders, dispatchSearchByClient, dispatchSearchByUser]);

  const handleClick = React.useCallback((clickedOrder) => {
    switch (clickedOrder.orderState) {
      case ORDERSTATE_WAITING_APPROVEMENT: {
        history.push(`/create/${clickedOrder.orderId}/approve`);
        return;
      }
      default: {
        const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', clickedOrder.orderId)
          .replace('{objectId}', clickedOrder.objectId);
        history.push(uri);
      }
    }
  }, [history]);

  const handleOnFilterChange = (filteredData, isOrder, isReclamation) => {
    setFiltered(filteredData);
    setIsOrderFilter(isOrder);
    setIsReclamationFilter(isReclamation);
  };

  const onDateFilterChange = React.useCallback((newStartDate, newEndDate) => {
    const { startDate, endDate } = dateRange;
    if ((moment(newStartDate).isBefore(moment(startDate)))
      || (!newStartDate && startDate)
      || (moment(newEndDate).isAfter(moment(endDate)))
      || (!newEndDate && endDate)) {
      setLoading(true);
      if (canSeeAllClientOrders) {
        dispatchGetAllClientOrders(newStartDate, newEndDate)
          .then(() => dispatchGetClientReclamations(newStartDate, newEndDate)
            .then(() => dispatchGetClientServices(newStartDate, newEndDate)
              .then(() => {
                setDateRange({
                  startDate: (startDate !== null) ? newStartDate : startDate,
                  endDate: (endDate !== null) ? newEndDate : endDate,
                });
                setLoading(false);
              })))
          .catch(() => setLoading(false));
      } else {
        dispatchGetAllUserOrders(newStartDate, newEndDate)
          .then(() => dispatchGetUserReclamations(newStartDate, newEndDate)
            .then(() => dispatchGetUserServices(newStartDate, newEndDate)
              .then(() => {
                setDateRange({
                  startDate: (startDate !== null) ? newStartDate : startDate,
                  endDate: (endDate !== null) ? newEndDate : endDate,
                });
                setLoading(false);
              })))
          .catch(() => setLoading(false));
      }
    }
  }, [canSeeAllClientOrders, dispatchGetAllClientOrders, dispatchGetClientServices, dispatchGetClientReclamations,
    dispatchGetAllUserOrders, dispatchGetUserServices, dispatchGetUserReclamations, dateRange]);

  const renderTable = () => (
    <OrderServiceTable
      client={client}
      ordersService={(searchResult) || filtered}
      onClick={handleClick}
    />
  );

  const renderFilter = () => {
    if (canSeeAllClientOrders) {
      return (
        <FilterComponent
          onChange={handleOnFilterChange}
          orders={clientOrders}
          reclamations={clientReclamations}
          services={clientServices}
          client={client}
          onDateFilterChange={onDateFilterChange}
        />
      );
    }
    return (
      <FilterComponent
        onChange={handleOnFilterChange}
        orders={userOrders}
        reclamations={userReclamations}
        services={userServices}
        client={client}
        onDateFilterChange={onDateFilterChange}
      />
    );
  };

  return (
    <>
      <Panel marginBottom={20} boxPadding={10}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1 d-flex align-items-center">
            <h4 className="hk-text-primary">
              Ihre Aufträge
            </h4>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <Input
              onChange={(data) => setSearchValue(data.toLowerCase())}
              value={searchValue}
              placeholder="Suchen..."
              style={{ display: (isReclamationFilter) ? 'none' : 'block', margin: 0 }}
              autoFocus
              rightIcon={`fas ${(loading) ? 'fa-spin fa-spinner' : 'fa-search'}`}
            />
          </div>
        </div>
      </Panel>
      <div className="row">
        <div className="col-lg-12">
          {renderFilter()}
        </div>
        <div className="col-lg-12">
          <Panel>
            {renderTable()}
          </Panel>
        </div>
      </div>
    </>
  );
}

Filter.propTypes = {
  clientOrders: PropTypes.instanceOf(Object),
  clientReclamations: PropTypes.instanceOf(Object),
  clientServices: PropTypes.instanceOf(Object),
  userOrders: PropTypes.instanceOf(Object),
  userReclamations: PropTypes.instanceOf(Object),
  userServices: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  dispatchGetAllClientOrders: PropTypes.func.isRequired,
  dispatchGetClientReclamations: PropTypes.func.isRequired,
  dispatchGetClientServices: PropTypes.func.isRequired,
  dispatchGetAllUserOrders: PropTypes.func.isRequired,
  dispatchGetUserReclamations: PropTypes.func.isRequired,
  dispatchGetUserServices: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  clientOrders: [],
  clientReclamations: [],
  clientServices: [],
  userOrders: [],
  userReclamations: [],
  userServices: [],
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    clientOrders: store.client.orders,
    userOrders: store.user.orders,
    clientReclamations: store.client.reclamations,
    userReclamations: store.user.reclamations,
    clientServices: store.client.services,
    userServices: store.user.services,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetAllClientOrders: (startDate, endDate) => dispatch(getOrders(startDate, endDate)),
    dispatchGetAllUserOrders: (startDate, endDate) => dispatch(getUserOrders(startDate, endDate)),
    dispatchGetClientReclamations: (startDate, endDate) => dispatch(getReclamations(startDate, endDate)),
    dispatchGetUserReclamations: (startDate, endDate) => dispatch(getUserReclamations(startDate, endDate)),
    dispatchGetClientServices: (startDate, endDate) => dispatch(getServices(startDate, endDate)),
    dispatchGetUserServices: (startDate, endDate) => dispatch(getUserServices(startDate, endDate)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Filter));
