import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import axios from '../../Library/Axios';
import { getAll } from './UserAction';

const defaultUri = '/calendar';

export const CALENDAR_GETLIST_START = 'CALENDAR_GETLIST_START';
export const CALENDAR_GETLIST_SUCCESS = 'CALENDAR_GETLIST_SUCCESS';
export const CALENDAR_GETLIST_ERROR = 'CALENDAR_GETLIST_ERROR';

/**
 * useDispatchCalendarGetList()
 * @returns {function(string, string): *}
 */
export function useDispatchCalendarGetList() {
  const dispatch = useDispatch();

  return React.useCallback((startDate, endDate) => {
    dispatch({ type: CALENDAR_GETLIST_START });

    const config = {
      params: { startDate, endDate },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CALENDAR_GETLIST_SUCCESS, payload: data.calendarData });
      return Promise.resolve(data.calendarData);
    }).catch((error) => {
      dispatch({ type: CALENDAR_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const CALENDAR_GETORDERSREVICEDATA_START = 'CALENDAR_GETORDERSREVICEDATA_START';
export const CALENDAR_GETORDERSREVICEDATA_SUCCESS = 'CALENDAR_GETORDERSREVICEDATA_SUCCESS';
export const CALENDAR_GETORDERSREVICEDATA_ERROR = 'CALENDAR_GETORDERSREVICEDATA_ERROR';

/**
 * useDispatchGetCalendarOrderServiceData()
 * @returns {function(*, *): *}
 */
export function useDispatchGetCalendarOrderServiceData() {
  const dispatch = useDispatch();

  return React.useCallback((startDate, endDate) => {
    dispatch({ type: CALENDAR_GETORDERSREVICEDATA_START });

    const config = {
      params: { action: 'getOrderServiceData', startDate, endDate },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CALENDAR_GETORDERSREVICEDATA_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CALENDAR_GETORDERSREVICEDATA_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const CALENDAR_GETTODAY_START = 'CALENDAR_GETTODAY_START';
export const CALENDAR_GETTODAY_SUCCESS = 'CALENDAR_GETTODAY_SUCCESS';
export const CALENDAR_GETTODAY_ERROR = 'CALENDAR_GETTODAY_ERROR';

/**
 * getToday()
 * @returns {function(*): Promise<unknown>}
 */
export function getToday() {
  return (dispatch) => {
    dispatch({ type: CALENDAR_GETTODAY_START });
    const today = moment().format('YYYY-MM-DD');

    const config = {
      params: { startDate: today, endDate: today },
    };

    return dispatch(getAll()).then(() => axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      if (data) {
        dispatch({ type: CALENDAR_GETTODAY_SUCCESS, payload: data[today] });
        return Promise.resolve(data);
      }
      dispatch({ type: CALENDAR_GETTODAY_ERROR, payload: 'NODATA' });
      return Promise.reject();
    }).catch(() => {
      dispatch({ type: CALENDAR_GETTODAY_ERROR });
      return Promise.reject();
    }));
  };
}

export const CALENDAR_NEXTPOSSIBLEMONTAGEDATE_START = 'CALENDAR_NEXTPOSSIBLEMONTAGEDATE_START';
export const CALENDAR_NEXTPOSSIBLEMONTAGEDATE_SUCCESS = 'CALENDAR_NEXTPOSSIBLEMONTAGEDATE_SUCCESS';
export const CALENDAR_NEXTPOSSIBLEMONTAGEDATE_ERROR = 'CALENDAR_NEXTPOSSIBLEMONTAGEDATE_ERROR';

/**
 * useDispatchGetNextPossibleMontageDate()
 * Get next possible free time slot for a montage with the given assembly time
 * @returns {function(*, *=): *}
 */
export function useDispatchGetNextPossibleMontageDate() {
  const dispatch = useDispatch();

  return React.useCallback((assemblyTime, startTime = null) => {
    dispatch({ type: CALENDAR_NEXTPOSSIBLEMONTAGEDATE_START, payload: { assemblyTime, startTime } });

    const config = {
      params: {
        action: 'nextFreeDate',
        assemblyTime,
        startDate: moment().format('YYYY-MM-DD'),
        startTime,
      },
    };

    return axios().get(defaultUri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CALENDAR_NEXTPOSSIBLEMONTAGEDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CALENDAR_NEXTPOSSIBLEMONTAGEDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
