import React from 'react';
import PropTypes from 'prop-types/prop-types';
import styled from 'styled-components';
import * as Redux from 'react-redux';
import {
  OBJECT_DATE_TYPE_DELIVERY,
  OBJECT_DATE_TYPE_MONTAGE,
  OBJECT_DATE_TYPE_REMOVAL,
  OBJECT_DATE_TYPE_STORAGE,
  objectDateTypeIcons,
  objectDateTypeText,
} from '../../Library/Types';

const Option = styled.span`
  margin-right: 5px;
`;

const Icon = styled.i`
  margin-right: 5px;
`;

const Text = styled.span`
  margin-left: 5px;
`;

/**
 * ATTENTION!
 * Can only be used with object from the view_object table!
 * OptionIcons()
 * @param props
 * @returns {null|*[]}
 * @constructor
 */
export default function OptionIcons(props) {
  const { order } = props;
  const { client } = Redux.useSelector((state) => state.client);
  const [options, setOptions] = React.useState(null);

  React.useEffect(() => {
    const optionsArray = [];

    if (order) {
      if (order.splitted
        || (client && client.clientId !== order.clientId && order.type === OBJECT_DATE_TYPE_STORAGE)) {
        optionsArray.push(order.type);
      } else {
        if (order.optionDelivery) {
          optionsArray.push(OBJECT_DATE_TYPE_DELIVERY);
        }
        if (order.optionMontage) {
          optionsArray.push(OBJECT_DATE_TYPE_MONTAGE);
        }
        if (order.optionRemoval) {
          optionsArray.push(OBJECT_DATE_TYPE_REMOVAL);
        }
        if (order.optionStorage) {
          optionsArray.push(OBJECT_DATE_TYPE_STORAGE);
        }
      }
    }

    setOptions(optionsArray);
  }, [order, client, setOptions]);

  const renderText = (text) => {
    if (options && options.length === 1) {
      return <Text>{text}</Text>;
    }
    return null;
  };

  const renderIcon = (type) => {
    const icon = objectDateTypeIcons[type];
    const text = objectDateTypeText[type];

    return (
      <Option key={type}>
        <Icon className={icon} />
        {renderText(text)}
      </Option>
    );
  };

  if (options) {
    return options.map(renderIcon);
  }
  return null;
}

OptionIcons.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
};
