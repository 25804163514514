import React from 'react';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  height: ${(props) => props.theme.cellHeadlineHeight}px;
  
  .Cell {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 12px;
    width: ${(props) => props.theme.width}px;
    border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};

    &.NoWorkHour {
      ${(props) => props.theme.linearGradient.noWorkHour}
    }
    
    &:last-child {
      border-right: none;
    }
  }
`;

/**
 * Timeline()
 * @returns {*}
 * @constructor
 */
export default function Timeline() {
  const theme = useTheme();
  const [width, setWidth] = React.useState(null);

  React.useEffect(() => {
    const { startHour, endHour, cellWeekDayWidth } = theme;

    const cellWidth = (cellWeekDayWidth / (endHour - startHour + 1));
    setWidth(cellWidth);
  }, [theme]);

  const renderTimes = () => {
    const {
      startHour, endHour, workDayStart, workDayEnd,
    } = theme;
    const startHourInt = parseInt(moment(workDayStart, 'HH:mm:ss').format('H'), 0);
    const endHourInt = parseInt(moment(workDayEnd, 'HH:mm:ss').format('H'), 0);
    const result = [];

    for (let i = startHour; i <= endHour; i += 1) {
      result.push((
        <div key={i} className={`Cell ${(i < startHourInt || i >= endHourInt) ? 'NoWorkHour' : null}`}>
          {i}
        </div>
      ));
    }

    return result;
  };

  return (
    <Container theme={{ ...theme, width }}>
      {renderTimes()}
    </Container>
  );
}
