import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Panel from '../../../../Components/Panel';
import { useDispatchUpdateSettings } from '../../../../Redux/Action/ClientSettingsAction';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import HelpDialog from '../../../../Components/HelpDialog';
import { timeOptions } from '../../../../Library/Types';

const daySelectOptions = [
  { value: null, label: 'Immer alle anzeigen' },
  { value: 1, label: '1 Tag in die Zukunft' },
  { value: 2, label: '2 Tage in die Zukunft' },
  { value: 3, label: '3 Tage in die Zukunft' },
  { value: 4, label: '4 Tage in die Zukunft' },
  { value: 5, label: '5 Tage in die Zukunft' },
  { value: 6, label: '6 Tage in die Zukunft' },
  { value: 7, label: '1 Woche in die Zukunft' },
  { value: 14, label: '2 Wochen in die Zukunft' },
  { value: 21, label: '3 Wochen in die Zukunft' },
  { value: 28, label: '4 Wochen in die Zukunft' },
];

/**
 * GeneralSettings()
 * @returns {JSX.Element}
 * @constructor
 */
export default function GeneralSettings() {
  const { client } = useSelector((state) => state.client);
  const dispatchUpdateSettings = useDispatchUpdateSettings();

  const [showHelp, setShowHelp] = useState(false);
  const [helpContext, setHelpContext] = useState(null);

  const openHelp = (context) => {
    setHelpContext(context);
    setShowHelp(true);
  };

  const handleOnChange = (key, value) => {
    const clampValidRange = (value < 1) ? 0 : value;

    return (
      dispatchUpdateSettings({ ...client.settings, [key]: clampValidRange })
    );
  };

  const getDaysOrderShowMonteurDisplayText = () => {
    if (client.settings.globalMonteurShowOrdersInFuture) {
      const result = daySelectOptions.filter(
        (item) => item.value === client.settings.globalMonteurShowOrdersInFuture,
      )[0];
      if (result) {
        return result.label;
      }
    }
    return 'Immer alle anzeigen';
  };

  const formatTime = (time) => {
    if (time) {
      return moment(time, 'HH:mm:ss').format('HH:mm');
    }
    return null;
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <Panel marginBottom={25}>
          <h6>
            Allgemeine Einstellungen
          </h6>
          <p style={{ marginBottom: 40 }}>
            Hier können Sie allgemeine Einstellungen für Web und App verwalten
          </p>

          <div className="row">
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Arbeitstag Startzeit"
                type="select"
                selectData={timeOptions}
                initialValue={formatTime(client.settings.globalWorkDayStart)}
                displayText={`${formatTime(client.settings.globalWorkDayStart)} Uhr`}
                onChange={(value) => handleOnChange('globalWorkDayStart', value)}
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                title="Arbeitstag Endzeit"
                type="select"
                selectData={timeOptions}
                initialValue={formatTime(client.settings.globalWorkDayEnd)}
                displayText={`${formatTime(client.settings.globalWorkDayEnd)} Uhr`}
                onChange={(value) => handleOnChange('globalWorkDayEnd', value)}
              />
            </div>
          </div>

          <KeyValueEditRow
            title="Zugewiesene Aufträge maximal X-Tage in der Zukunft anzeigen"
            type="select"
            selectData={daySelectOptions}
            initialValue={client.settings.globalMonteurShowOrdersInFuture}
            displayText={getDaysOrderShowMonteurDisplayText()}
            onChange={(value) => handleOnChange('globalMonteurShowOrdersInFuture', value)}
            onHelpAction={() => openHelp('daysOrdersShownMonteur')}
          />
        </Panel>
      </div>
      <HelpDialog context={helpContext} visible={showHelp} onClose={() => setShowHelp(false)} />
    </div>
  );
}
