import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Panel from '../../../../Components/Panel';
import { update } from '../../../../Redux/Action/ClientAction';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import HelpDialog from '../../../../Components/HelpDialog';

/**
 * MailSettings()
 * @param props
 * @returns {*}
 * @constructor
 */
function MailSettings(props) {
  const {
    client, dispatchUpdateClient,
  } = props;
  const [showHelp, setShowHelp] = useState(false);
  const [helpContext, setHelpContext] = useState(null);

  const openHelp = (context) => {
    setHelpContext(context);
    setShowHelp(true);
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <Panel marginBottom={25}>
          <h6>
            E-Mail Einstellungen
          </h6>
          <p style={{ marginBottom: 40 }}>
            Hier können Sie die Empfängeradressen für bestimmte E-Mails, die von hey.kitchen versendet werden,
            festlegen. Wenn keine speziellen Adressen eingetragen werden, erfolgt der Versand immer an die vom
            System an der entsprechenden Stelle verwendete E-Mail Adresse.
          </p>

          <KeyValueEditRow
            title="E-Mail Adresse(n) für Rechnungsversand"
            type="text"
            initialValue={client.invoiceMail}
            displayText={(client.invoiceMail) ? client.invoiceMail.replaceAll(';', ' / ') : ''}
            onChange={(value) => dispatchUpdateClient({ ...client, invoiceMail: value })}
            onHelpAction={() => openHelp('invoiceMail')}
            containerTabIndex={10}
          />
          <KeyValueEditRow
            title="E-Mail Adresse(n) für Montageberichte"
            type="text"
            initialValue={client.serviceReportMail}
            displayText={(client.serviceReportMail) ? client.serviceReportMail.replaceAll(';', ' / ') : ''}
            onChange={(value) => dispatchUpdateClient({ ...client, serviceReportMail: value })}
            onHelpAction={() => openHelp('serviceReportMail')}
            containerTabIndex={11}
          />
          <KeyValueEditRow
            title="E-Mail Adresse(n) für Reklamationsberichte"
            type="text"
            initialValue={client.reclamationReportMail}
            displayText={(client.reclamationReportMail) ? client.reclamationReportMail.replaceAll(';', ' / ') : ''}
            onChange={(value) => dispatchUpdateClient({ ...client, reclamationReportMail: value })}
            onHelpAction={() => openHelp('reclamationReportMail')}
            containerTabIndex={11}
          />
        </Panel>
      </div>

      <HelpDialog context={helpContext} visible={showHelp} onClose={() => setShowHelp(false)} />
    </div>
  );
}

MailSettings.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  dispatchUpdateClient: PropTypes.func.isRequired,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateClient: (state) => dispatch(update(state)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(MailSettings));
