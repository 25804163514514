import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Panel from '../../../../Components/Panel';
import { getExternalClient } from '../../../../Redux/Action/ClientAction';

/**
 * MonteurSplittedInfo()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function MonteurSplittedInfo(props) {
  const {
    object, order, client, dispatchGetClient,
  } = props;
  const [isAssigned, setIsAssigned] = React.useState(false);
  const [owner, setOwner] = React.useState(null);

  React.useEffect(() => {
    dispatchGetClient(order.clientId).then((response) => {
      setOwner(response);
    });
  }, [order.clientId, dispatchGetClient]);

  React.useEffect(() => {
    if (object && client) {
      if ((object.deliveryDate && object.deliveryDate.clientId === client.clientId)
        || (object.montageDate && object.montageDate.clientId === client.clientId)
        || (object.removalDate && object.removalDate.clientId === client.clientId)
        || (object.storage && object.storage.clientId === client.clientId)
      ) {
        setIsAssigned(true);
      } else {
        setIsAssigned(false);
      }
    }
  }, [object, client]);

  const renderOwner = () => {
    if (owner) {
      return (
        <>
          <address style={{ marginBottom: 0 }}>
            <div>
              <strong>{owner.name}</strong>
            </div>
            <div>{`${owner.street} ${owner.streetNo}`}</div>
            <div>{`${owner.zip} ${owner.city}`}</div>
            <div>{`Telefon: ${owner.phone}`}</div>
            <div>{`Mail: ${owner.mail}`}</div>
          </address>
        </>
      );
    }
    return (
      <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-center align-content-center">
        <div>
          <i className="fa fa-spin fa-spinner" />
        </div>
        <div>
          <small>Daten werden geladen...</small>
        </div>
      </div>
    );
  };

  if (order.clientId !== client.clientId && isAssigned && order.internal) {
    return (
      <div className="row">
        <div className="col-lg-3 d-flex">
          <Panel marginBottom={20} className="d-flex">
            <h6>Partner / Ersteller</h6>
            {renderOwner()}
          </Panel>
        </div>
        <div className="col-lg-9 d-flex">
          <Panel marginBottom={20}>
            <div className="text-center">
              <i className="fa fa-info-circle text-info" style={{ fontSize: 26, marginBottom: 15 }} />
            </div>
            <div className="text-center">
              <strong>Achtung! Sie wurden für diesen Auftrag als externer Monteur von Ihrem Partner zugewiesen.</strong>
              <br />
              Sie sind evtl. nur für die Ausführung eines Teilbereichs des Auftrags verantwortlich!
              Welche Teilbereiche Ihnen zugewiesen wurden, sehen Sie weiter unten.
              <br />
              Dort können Sie Ihre Monteure oder ein Team für die angezeigten Teilbereiche zuweisen!
            </div>
          </Panel>
        </div>
      </div>
    );
  }
  return null;
}

MonteurSplittedInfo.propTypes = {
  dispatchGetClient: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetClient: (clientId) => dispatch(getExternalClient(clientId)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(MonteurSplittedInfo);
