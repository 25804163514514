import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import FileSaver from 'file-saver';
import { downloadImages, getList as getReport, getPdf } from '../../../Redux/Action/DeliveryReportAction';
import { get as getOrder } from '../../../Redux/Action/OrderAction';
import Panel from '../../../Components/Panel';
import DeviceList from '../../../Components/DeviceList';
import Button from '../../../Components/Button';
import LoadingModal from '../../../Components/LoadingModal';
import CompletedImages from '../../../Components/ReportComponents/CompletedImages';
import KeyValueEditRow from '../../../Components/KeyValueEditRow';
import Overview from './Components/Overview';
import SignaturesBox from '../../../Components/ReportComponents/SignaturesBox';
import {
  DEVICETYPE_DAMAGE, DEVICETYPE_PREDAMAGE,
} from '../../../Library/Types';
import StatusInProgress from '../../../Components/ReportComponents/StatusInProgress';
import InventoryImages from '../../../Components/ReportComponents/InventoryImages';
import Information from './Components/Information';

/**
 * DeliveryReport()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function DeliveryReport(props) {
  const {
    getReportDetails, order, getOrderDetails, dispatchDownloadPdf, teams, client,
    history, dispatchDownloadImages,
  } = props;

  const [customer, setCustomer] = useState();
  const [users, setUsers] = useState();
  const [report, setReport] = useState(null);
  const [damages, setDamages] = useState([]);
  const [preDamages, setPreDamages] = useState([]);
  const { reportId, orderId, objectId } = useParams();
  const [object, setObject] = useState();
  const [downloadingImages, setDownloadingImages] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);

  const loadData = React.useCallback(() => {
    getOrderDetails(orderId).then((orderResponse) => {
      getReportDetails(orderId, objectId).then((reportResponse) => {
        const selectedObject = orderResponse.objects.filter((item) => item.objectId === reportResponse.objectId)[0];
        if (selectedObject) {
          setCustomer(selectedObject.customer);
          if (selectedObject.deliveryDate) {
            setUsers(selectedObject.deliveryDate.users);
          }
        }
        setReport(reportResponse);

        setDamages(reportResponse.devices.filter((item) => item.type === DEVICETYPE_DAMAGE));
        setPreDamages(reportResponse.devices.filter((item) => item.type === DEVICETYPE_PREDAMAGE));
      }).catch(() => {
        history.push('/dashboard');
      });
    });
  }, [getOrderDetails, getReportDetails, orderId, objectId, history]);

  React.useEffect(() => {
    if (report && order) {
      const objectFiltered = order.objects.filter((item) => item.objectId === report.objectId)[0];
      setObject(objectFiltered);
    }
  }, [report, order]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  React.useEffect(() => {
    if (object && teams && users && users.length === 0) {
      if (object.teamId) {
        const team = teams.filter((teamData) => teamData.teamId === object.teamId)[0];
        if (team) {
          setUsers(team.users);
        }
      }
    }
  }, [object, users, teams]);

  const handleDownload = () => {
    setDownloadingReport(true);
    dispatchDownloadPdf(orderId, objectId, reportId).then((data) => {
      setDownloadingReport(false);
      FileSaver.saveAs(data, `Lieferbericht_${orderId}.pdf`);
    }).catch(() => setDownloadingReport(false));
  };

  const handleDownloadImages = () => {
    setDownloadingImages(true);
    dispatchDownloadImages(orderId, objectId, reportId).then((data) => {
      FileSaver.saveAs(data, `LieferberichtAlleBilder_${orderId}.zip`);
      setDownloadingImages(false);
    }).catch(() => setDownloadingImages(false));
  };

  const renderDownloadButton = () => {
    if (report.finished) {
      return (
        <div className="d-flex flex-row" style={{ textAlign: 'right' }}>
          <Button onClick={() => handleDownloadImages()} style={{ marginRight: 8 }}>
            <i className="fas fa-cloud-download-alt" />
            Alle Bilder
          </Button>
          <Button onClick={() => handleDownload()}>
            <i className="fas fa-cloud-download-alt" />
            Bericht als PDF
          </Button>
        </div>
      );
    }
    return null;
  };

  if (report && order && customer && users) {
    return (
      <div>
        <Panel marginBottom={25}>
          <div className="d-flex">
            <div className="flex-grow-1 d-flex align-items-center align-content-center">
              <h4 className="PrimaryHeadline" style={{ marginBottom: 0 }}>
                Lieferbericht
              </h4>
            </div>
            {renderDownloadButton()}
          </div>
        </Panel>

        <StatusInProgress report={report} />

        <div className="row">
          <div className="col-lg-12">
            <Overview report={report} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 d-flex">
            <Panel marginBottom={25}>
              <h6>Kundendaten</h6>
              <p>
                Hier sehen Sie die Kontaktdaten des Kunden
              </p>

              <KeyValueEditRow title="Name" type="text" displayText={`${customer.firstname} ${customer.lastname}`} />
              <KeyValueEditRow
                title="Adresse"
                type="text"
                displayText={`${customer.street} ${customer.streetNo}, ${customer.zip} ${customer.city}`}
              />
              <KeyValueEditRow title="E-Mail Adresse" type="text" displayText={customer.mail} />
              <KeyValueEditRow title="Telefon" type="text" displayText={customer.phone} />
              <KeyValueEditRow title="Mobil" type="text" displayText={customer.mobile} />
              <KeyValueEditRow
                title="Kommentar"
                type="text"
                displayText={customer.comment}
                visible={customer.comment !== null && customer.comment.length > 0}
              />
            </Panel>
          </div>
          <div className="col-lg-6 d-flex">
            <Information report={report} teams={teams} object={object} order={order} client={client} />
          </div>
        </div>

        <InventoryImages images={report.inventoryImages} />

        <div className="row">
          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={preDamages}
            caption="Dokumentierte Vorschäden"
          />

          <DeviceList
            showPreview
            colSize="col-lg-6"
            devices={damages}
            caption="Beschädigungen / Versicherungsfall"
          />
        </div>

        <CompletedImages
          images={report.completedImages}
          headline="Bilder der abgestellten Lieferung"
          subHeadline="Hier sehen Sie die Bilder der abgestellten Lieferung"
        />
        <SignaturesBox
          customer={customer}
          user={(users) ? users[0] : null}
          customerSignaturePath={(report.signatureCustomer.absolutePath) || null}
          monteurSignaturePath={(report.signatureMonteur.absolutePath) || null}
        />

        <LoadingModal
          visible={downloadingReport}
          headline="Lieferbericht als PDF wird erstellt"
          text="Der Lieferbericht wird im Anschluss automatisch heruntergeladen!"
        />

        <LoadingModal
          visible={downloadingImages}
          headline="Bilder werden komprimiert..."
          text="Der Download wird im Anschluss automatisch gestartet!"
        />

      </div>
    );
  }
  return <LoadingModal visible text="Lieferbericht wird geladen..." />;
}

function mapStoreToProps(store) {
  return {
    order: store.order.order,
    teams: store.teams.teams,
    client: store.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrderDetails: (orderId) => dispatch(getOrder(orderId)),
    getReportDetails: (orderId, objectId) => dispatch(getReport(orderId, objectId)),
    dispatchDownloadPdf: (orderId, objectId, reportId) => dispatch(getPdf(orderId, objectId, reportId, true)),
    dispatchDownloadImages: (orderId, objectId, reportId) => dispatch(downloadImages(orderId, objectId, reportId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(DeliveryReport));

DeliveryReport.propTypes = {
  order: PropTypes.instanceOf(Object),
  getReportDetails: PropTypes.func,
  getOrderDetails: PropTypes.func,
  dispatchDownloadImages: PropTypes.func.isRequired,
  dispatchDownloadPdf: PropTypes.func.isRequired,
  teams: PropTypes.instanceOf(Array),
  client: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

DeliveryReport.defaultProps = {
  order: null,
  getReportDetails: () => {},
  getOrderDetails: () => {},
  teams: null,
};
