import * as Redux from 'react-redux';
import React from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../../../Library/Axios';

const defaultUri = '/system/client';

export const SYSTEM_CLIENT_GETLIST_START = 'SYSTEM_CLIENT_GETLIST_START';
export const SYSTEM_CLIENT_GETLIST_SUCCESS = 'SYSTEM_CLIENT_GETLIST_SUCCESS';
export const SYSTEM_CLIENT_GETLIST_ERROR = 'SYSTEM_CLIENT_GETLIST_ERROR';

/**
 * useDispatchSystemGetClientList()
 * Get all clients as an array
 */
export function useDispatchSystemGetClientList() {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch({ type: SYSTEM_CLIENT_GETLIST_START });
    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

/** TODO: find usage and replace with useDispatchSystemGetClientList() */
/**
 * getList()
 * @returns {function(*): Promise<any | never>}
 */
export function getList() {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENT_GETLIST_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export function getListFreeWithOrders() {
  return axios().get(defaultUri, { params: { action: 'freeWithOrders' } })
    .then((response) => Promise.resolve(response.data.data));
}

export const SYSTEM_CLIENT_ADDRESSES_GETLIST_START = 'SYSTEM_CLIENT_ADDRESSES_GETLIST_START';
export const SYSTEM_CLIENT_ADDRESSES_GETLIST_SUCCESS = 'SYSTEM_CLIENT_ADDRESSES_GETLIST_SUCCESS';
export const SYSTEM_CLIENT_ADDRESSES_GETLIST_ERROR = 'SYSTEM_CLIENT_ADDRESSES_GETLIST_ERROR';

/**
 * getAddressesList()
 * @returns {function(*): Promise<any | never>}
 */
export function getAddressesList() {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENT_ADDRESSES_GETLIST_START });

    return axios().get(defaultUri, { params: { action: 'addresses' } }).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_ADDRESSES_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_ADDRESSES_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_CLIENT_LOGINAS_START = 'SYSTEM_CLIENT_LOGINAS_START';
export const SYSTEM_CLIENT_LOGINAS_SUCCESS = 'SYSTEM_CLIENT_LOGINAS_SUCCESS';
export const SYSTEM_CLIENT_LOGINAS_ERROR = 'SYSTEM_CLIENT_LOGINAS_ERROR';

/**
 * loginAsClient()
 * @param clientId
 * @returns {function(*): Promise<unknown>}
 */
export function loginAsClient(clientId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENT_LOGINAS_START });

    const config = {
      params: { action: 'loginAsClient' },
    };

    return axios().post(defaultUri, { clientId }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_LOGINAS_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_LOGINAS_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_CLIENT_GET_START = 'SYSTEM_CLIENT_GET_START';
export const SYSTEM_CLIENT_GET_SUCCESS = 'SYSTEM_CLIENT_GET_SUCCESS';
export const SYSTEM_CLIENT_GET_ERROR = 'SYSTEM_CLIENT_GET_ERROR';

/**
 * get()
 * @param clientId
 * @returns {function(*): Promise<unknown>}
 */
export function get(clientId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENT_GET_START, clientId });
    const uri = `${defaultUri}/${clientId}`;
    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_GET_SUCCESS, data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_GET_ERROR, error });
      return Promise.reject(error.response);
    });
  };
}

/**
 * useDispatchSystemGetClient
 * @param clientId
 * @returns {function(*=): *}
 */
export const useDispatchSystemGetClient = (clientId) => {
  const dispatch = Redux.useDispatch();
  const uri = `${defaultUri}/${clientId}`; // TODO: Create and use prepareUri

  return React.useCallback((clear = false) => {
    dispatch({ type: SYSTEM_CLIENT_GET_START, payload: { clientId, clear } });

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_GET_SUCCESS, data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_GET_ERROR, error });
      return Promise.reject(error.response);
    });
  }, [uri, dispatch, clientId]);
};

export const SYSTEM_CLIENT_UPDATE_START = 'SYSTEM_CLIENT_UPDATE_START';
export const SYSTEM_CLIENT_UPDATE_SUCCESS = 'SYSTEM_CLIENT_UPDATE_SUCCESS';
export const SYSTEM_CLIENT_UPDATE_ERROR = 'SYSTEM_CLIENT_UPDATE_ERROR';

/**
 * update()
 * @param clientId
 * @param updatedFields
 * @returns {function(*): Promise<unknown>}
 */
export function update(clientId, updatedFields) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENT_UPDATE_START, clientId });
    const uri = `${defaultUri}/${clientId}`;
    return axios().put(uri, updatedFields).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_UPDATE_SUCCESS, data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_UPDATE_ERROR, error });
      return Promise.reject(error.response);
    });
  };
}

export const SYSTEM_CLIENT_GETORDERS_START = 'SYSTEM_CLIENT_GETORDERS_START';
export const SYSTEM_CLIENT_GETORDERS_SUCCESS = 'SYSTEM_CLIENT_GETORDERS_SUCCESS';
export const SYSTEM_CLIENT_GETORDERS_ERROR = 'SYSTEM_CLIENT_GETORDERS_ERROR';

/**
 * getOrders()
 * @param clientId
 * @returns {function(*): Promise<unknown>}
 */
export function getOrders(clientId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENT_GETORDERS_START, clientId });
    const uri = `${defaultUri}/${clientId}/order`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_GETORDERS_SUCCESS, data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_GETORDERS_ERROR, error });
      return Promise.reject(error.response);
    });
  };
}

/**
 * useDispatchGetClientObjectView()
 * @param clientId
 * @returns {function(*=): *}
 */
export const useDispatchSystemGetClientObjectView = (clientId) => {
  const dispatch = Redux.useDispatch();
  const target = 'client';
  const uri = `${defaultUri}/${clientId}/order`; // TODO: Create and use prepareUri

  return React.useCallback(() => {
    dispatch({ type: SYSTEM_CLIENT_GETORDERS_START, payload: { target, clientId } });
    return axios().get(uri).then((response) => {
      const objects = response.data.data;
      const lastUpdated = new Date().getTime();
      dispatch({ type: SYSTEM_CLIENT_GETORDERS_SUCCESS, payload: { target, objects, lastUpdated } });
      return Promise.resolve(objects);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_GETORDERS_ERROR, payload: { target, error } });
      return Promise.reject(error);
    });
  }, [uri, dispatch, target, clientId]);
};

export const SYSTEM_CLIENT_GETINVOICES_START = 'SYSTEM_CLIENT_GETINVOICES_START';
export const SYSTEM_CLIENT_GETINVOICES_SUCCESS = 'SYSTEM_CLIENT_GETINVOICES_SUCCESS';
export const SYSTEM_CLIENT_GETINVOICES_ERROR = 'SYSTEM_CLIENT_GETINVOICES_ERROR';

/**
 * getInvoices()
 * @param clientId
 * @returns {function(*): Promise<unknown>}
 */
export function getInvoices(clientId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENT_GETINVOICES_START, clientId });
    const uri = `${defaultUri}/${clientId}/invoice`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_GETINVOICES_SUCCESS, data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_GETINVOICES_ERROR, error });
      return Promise.reject(error.response);
    });
  };
}

export const SYSTEM_CLIENT_CREATEINVOICE_START = 'SYSTEM_CLIENT_CREATEINVOICE_START';
export const SYSTEM_CLIENT_CREATEINVOICE_SUCCESS = 'SYSTEM_CLIENT_CREATEINVOICE_SUCCESS';
export const SYSTEM_CLIENT_CREATEINVOICE_ERROR = 'SYSTEM_CLIENT_CREATEINVOICE_ERROR';

/**
 * createInvoice()
 * @param clientId
 * @param invoiceData
 * @returns {function(*): *}
 */
export function createInvoice(clientId, invoiceData) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_CLIENT_CREATEINVOICE_START, clientId });
    const uri = `${defaultUri}/${clientId}/invoice`;

    return axios().post(uri, invoiceData).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_CLIENT_CREATEINVOICE_SUCCESS, data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_CLIENT_CREATEINVOICE_ERROR, error });
      return Promise.reject(error.data);
    });
  };
}
