import {
  HOLIDAY_GETLIST_ERROR, HOLIDAY_GETLIST_START, HOLIDAY_GETLIST_SUCCESS,
} from '../Action/HolidayAction';
import {
  USERHOLIDAY_CREATE_SUCCESS, USERHOLIDAY_UPDATE_SUCCESS, USERHOLIDAY_DELETE_SUCCESS,
} from '../Action/UserHolidayAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  tileData: {},
  listData: {},
  isLoading: false,
  lastSelectedStartDate: null,
  lastSelectedEndDate: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /** GET LIST */
    case HOLIDAY_GETLIST_START: {
      return { ...state, isLoading: true };
    }
    case HOLIDAY_GETLIST_SUCCESS: {
      const { data, startDate, endDate } = action.payload;
      const { tileData, listData } = data;
      return {
        ...state,
        isLoading: false,
        tileData,
        listData,
        lastSelectedStartDate: startDate,
        lastSelectedEndDate: endDate,
      };
    }
    case HOLIDAY_GETLIST_ERROR: {
      return {
        ...state, isLoading: false, tileData: {}, listData: {},
      };
    }

    /** HANDLE USER HISTORY UPDATES */
    case USERHOLIDAY_UPDATE_SUCCESS:
    case USERHOLIDAY_DELETE_SUCCESS:
    case USERHOLIDAY_CREATE_SUCCESS: {
      return { ...state, lastSelectedEndDate: null, lastSelectedStartDate: null };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
