import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Toolipp from 'react-tooltip-lite';
import moment from 'moment';
import Input from '../Input';
import DatePickerInput from '../DatePickerInput';
import Textarea from '../Textarea';
import CustomReactSelect from '../CustomReactSelect';

import './style.css';
import WeekPickerInput from '../WeekPickerInput';

const floatFilter = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
const numberFilter = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const tooltippText = (
  <div>
    Diese Angabe wird benötigt damit Sie Aufträge
    <br />
    erstellen oder Montagen buchen können.
  </div>
);

/**
 * KeyValueEditRow()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function KeyValueEditRow(props) {
  const {
    title, displayText, actionIcon, actionText, initialValue, type, onChange, className, selectData,
    isNumeric, isFloat, visible, mandatoryForOrder, useCustomAction, onCustomAction, onHelpAction, containerTabIndex,
    onPressEnterGetNextTabIndex, startEditMode, hasError, isMultiSelect, required, onClear, clearTitle,
    datePickerMinDate, datePickerMaxDate, maxLength, placeholder, editable,
  } = props;
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [charFilter, setCharFilter] = useState();
  const inputRef = useRef(null);

  React.useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const startEdit = React.useCallback(() => {
    if (onChange && !useCustomAction && editable) {
      setEdit(true);
    } else if (useCustomAction) {
      onCustomAction();
    }
  }, [onChange, onCustomAction, useCustomAction, editable]);

  useEffect(() => {
    if (startEditMode) {
      startEdit();
    }
  }, [startEditMode, startEdit]);

  useEffect(() => {
    if (isNumeric) {
      setCharFilter(numberFilter);
    }
    if (isFloat) {
      setCharFilter(floatFilter);
    }
  }, [isNumeric, isFloat]);

  const onHandleCancel = () => {
    setEdit(false);
    setValue(initialValue);
  };

  const onHandleSave = () => {
    setEdit(false);
    if (onChange) {
      onChange(value);
      if (onPressEnterGetNextTabIndex) {
        onPressEnterGetNextTabIndex(null);
      }
    }
  };

  const handleOnEnter = (e) => {
    if (e.key === 'Enter') {
      onHandleSave();
      if (onPressEnterGetNextTabIndex && containerTabIndex > 0) {
        onPressEnterGetNextTabIndex(containerTabIndex + 1);
      }
    } else if (e.key === 'Escape') {
      onHandleCancel();
    }
  };

  const checkAndSetValue = (data) => {
    if (charFilter) {
      const filteredValue = data.split('').filter((item) => charFilter.indexOf(item) >= 0);
      const newValue = filteredValue.join('');
      setValue(newValue);
    } else {
      setValue(data);
    }
  };

  const handleOnBlur = () => {
    if (value !== initialValue) {
      onHandleSave();
    } else {
      onHandleCancel();
    }
  };

  const renderMandatoryForOrderHint = () => {
    if (mandatoryForOrder && (!value || value.length === 0)) {
      return (
        <Toolipp className="fas Small" content={tooltippText} useDefaultStyles>
          <i className="fas fa-exclamation-circle hk-text-primary" style={{ marginRight: 20, fontSize: 16 }} />
        </Toolipp>
      );
    }
    return null;
  };

  const onPressHelp = (event) => {
    event.stopPropagation();
    onHelpAction();
  };

  const renderHelpIcon = () => {
    if (onHelpAction) {
      return (
        <Toolipp className="fas Small" content="Klicken um Hilfe an zu zeigen!" useDefaultStyles>
          <i
            className="fas fa-question-circle text-info"
            style={{ marginRight: 20, fontSize: 16 }}
            onClick={onPressHelp}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
          />
        </Toolipp>
      );
    }
    return null;
  };

  const renderAction = () => {
    if (!editable) {
      return null;
    }

    const returnArray = [];

    if (onChange || onCustomAction) {
      if (actionIcon && actionText) {
        returnArray.push((
          <>
            <i className={actionIcon} style={{ marginRight: 10 }} />
            {actionText}
          </>
        ));
      }
      if (actionIcon && !actionText) {
        returnArray.push(<i key="editicon" className={actionIcon} style={{ marginRight: 10 }} />);
      }
      if (!actionIcon && actionText) {
        returnArray.push(actionText);
      }
    }

    return returnArray;
  };

  const renderInput = () => {
    if (type === 'text') {
      return (
        <Input
          onChange={(data) => checkAndSetValue(data)}
          onKeyPress={handleOnEnter}
          style={{ marginBottom: 0 }}
          rowClass="col-lg-12"
          value={value}
          inputRef={inputRef}
          onBlur={handleOnBlur}
          tabIndex={containerTabIndex}
          maxLength={maxLength}
        />
      );
    }
    if (type === 'textarea') {
      return (
        <Textarea
          onChange={(data) => checkAndSetValue(data)}
          onKeyPress={handleOnEnter}
          style={{ marginBottom: 0 }}
          rowClass="col-lg-12"
          value={value}
          inputRef={inputRef}
          onBlur={handleOnBlur}
          rows={4}
          tabIndex={containerTabIndex}
        />
      );
    }
    if (type === 'select') {
      return (
        <CustomReactSelect
          onChange={(data) => setValue(data)}
          style={{ marginBottom: 0 }}
          rowClass="col-lg-12"
          value={value}
          options={selectData}
          onBlur={handleOnBlur}
          inputRef={inputRef}
          tabIndex={containerTabIndex}
          isMulti={isMultiSelect}
        />
      );
    }
    if (type === 'weekSelect') {
      return (
        <div className="col-lg-12" style={{ marginBottom: -16, marginTop: 1 }}>
          <WeekPickerInput
            onChange={(data) => setValue(data)}
            visible={visible}
            initialValue={initialValue}
            onBlur={handleOnBlur}
            inputRef={inputRef}
            tabIndex={containerTabIndex}
            isMulti={isMultiSelect}
            disabled
            value={value}
          />
        </div>
      );
    }
    return (
      <DatePickerInput
        onChange={(data) => setValue(data)}
        initialValue={value}
        rowClass="col-lg-12"
        style={{ marginBottom: 0 }}
        onBlur={handleOnBlur}
        tabIndex={containerTabIndex}
        minDate={(datePickerMinDate) ? moment(datePickerMinDate).toDate() : null}
        maxDate={(datePickerMaxDate) ? moment(datePickerMaxDate).toDate() : null}
      />
    );
  };

  useEffect(() => {
    if (edit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [edit]);

  const renderEmptyText = () => (
    <i style={{ color: '#bbbbbb' }}>{placeholder}</i>
  );

  const renderTitle = () => {
    if (required) {
      return `${title}*`;
    }
    return title;
  };

  const handleOnClearClick = (e) => {
    e.stopPropagation();
    setValue(null);
    onClear();
  };

  const renderClearAction = () => {
    if (onClear && value && (value > 0 || value.length > 0) && editable) {
      return (
        <div
          className="Clear"
          onClick={handleOnClearClick}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
          title={clearTitle}
        >
          <i className="fas fa-trash" style={{ marginLeft: 6 }} />
        </div>
      );
    }
    return null;
  };

  if (visible) {
    if (!edit) {
      return (
        <div
          className="KeyValueEditRow"
          onClick={startEdit}
          tabIndex={containerTabIndex}
          role="tab"
          onKeyPress={() => {}}
        >
          <div className="Left">
            <div className={`Title ${(hasError) ? 'Error' : ''}`}>
              {renderTitle()}
            </div>
            <div className="Value">
              {(displayText) || renderEmptyText()}
            </div>
          </div>
          <div className="Right">
            <div className="Delete" onClick={startEdit} tabIndex={-1} role="button" onKeyPress={() => {}}>
              {renderMandatoryForOrderHint()}
              {renderHelpIcon()}
              {renderAction()}
            </div>
            {renderClearAction()}
          </div>
        </div>
      );
    }
    return (
      <div className={`KeyValueEditRow ${className}`} tabIndex={containerTabIndex} role="tab">
        <div className="Left row WithInput">
          {renderInput()}
        </div>
        <div className="Right Actions">
          <i
            className="fas fa-check-square text-success"
            onClick={onHandleSave}
            tabIndex={0}
            role="button"
            onKeyPress={() => {}}
          />
          <i
            className="fas fa-minus-square text-danger"
            onClick={onHandleCancel}
            tabIndex={0}
            role="button"
            onKeyPress={() => {}}
          />
        </div>
      </div>
    );
  }
  return null;
}

KeyValueEditRow.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  displayText: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  actionIcon: PropTypes.string,
  actionText: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  type: PropTypes.oneOf(['text', 'date', 'select', 'textarea', 'weekSelect']),
  onChange: PropTypes.func,
  className: PropTypes.string,
  selectData: PropTypes.instanceOf(Object),
  isNumeric: PropTypes.bool,
  isFloat: PropTypes.bool,
  visible: PropTypes.bool,
  mandatoryForOrder: PropTypes.bool,
  useCustomAction: PropTypes.bool,
  onCustomAction: PropTypes.func,
  onHelpAction: PropTypes.func,
  containerTabIndex: PropTypes.number,
  onPressEnterGetNextTabIndex: PropTypes.func,
  startEditMode: PropTypes.bool,
  hasError: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  required: PropTypes.bool,
  onClear: PropTypes.func,
  clearTitle: PropTypes.string,
  datePickerMinDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  datePickerMaxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  editable: PropTypes.bool,
};

KeyValueEditRow.defaultProps = {
  actionIcon: 'fas fa-pencil-alt',
  actionText: null,
  initialValue: null,
  displayText: null,
  onChange: null,
  className: null,
  selectData: null,
  isNumeric: false,
  isFloat: false,
  visible: true,
  mandatoryForOrder: false,
  useCustomAction: false,
  onCustomAction: null,
  onHelpAction: null,
  type: 'text',
  containerTabIndex: -1,
  onPressEnterGetNextTabIndex: null,
  startEditMode: false,
  hasError: false,
  isMultiSelect: false,
  required: false,
  onClear: null,
  clearTitle: '',
  datePickerMinDate: null,
  datePickerMaxDate: null,
  maxLength: null,
  placeholder: 'Keine Daten',
  editable: true,
};
