import React from 'react';
import {
  Route, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';
import RightNavigationLayout from '../../Components/Layout/RightNavigationLayout';
import SystemOrderNavigation from '../../Components/Navigation/SystemOrderNavigation';
import ExternalService from '../../Pages/System/Order/Object/Service/ExternalService';
import InternalService from '../../Pages/System/Order/Object/Service/InternalService';

/**
 * SystemServiceRoutes()
 * @returns {*}
 * @constructor
 */
export default function SystemServiceRoutes() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/external`}>
        <RightNavigationLayout navigation={<SystemOrderNavigation />}>
          <ExternalService />
        </RightNavigationLayout>
      </Route>
      <Route exact path={`${path}/internal`}>
        <RightNavigationLayout navigation={<SystemOrderNavigation />}>
          <InternalService />
        </RightNavigationLayout>
      </Route>
      <Route>
        <Redirect to={`${url}/external`} />
      </Route>
    </Switch>
  );
}
