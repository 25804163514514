import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lodash from 'lodash';
import Panel from '../../Panel';
import { get } from '../../../Redux/Action/ClientAddressAction';
import Button from '../../Button';
import UpdateLoadingAddress from '../../OrderComponents/UpdateLoadingAddress';
import { updateLoadingAddress, get as getOrder } from '../../../Redux/Action/OrderAction';

/**
 * AddressContainer()
 * @param props
 * @returns {*}
 * @constructor
 */
function AddressContainer(props) {
  const { address } = props;

  if (address) {
    return (
      <address style={{ marginBottom: 0 }}>
        <div>
          <strong>
            {address.name}
          </strong>
        </div>
        <div>{`${address.street} ${address.streetNo}`}</div>
        <div>{`${address.zip} ${address.city}`}</div>
        <div>{address.contactPhone}</div>
        <div>{address.contactName}</div>
        <div>{address.comment}</div>
      </address>
    );
  }
  return null;
}

AddressContainer.propTypes = {
  address: PropTypes.instanceOf(Object),
};

AddressContainer.defaultProps = {
  address: null,
};

/**
 * LoadingAddress()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function LoadingAddress(props) {
  const {
    order, object, dispatchGet, address, dispatchUpdate, dispatchGetOrder, editable,
  } = props;
  const [showEdit, setShowEdit] = React.useState(false);

  useEffect(() => {
    if (!order.internal
      && object && object.montageDate && object.montageDate.clientId && object.assignedClientAddressId) {
      dispatchGet(object.montageDate.clientId, object.assignedClientAddressId);
    }
  }, [dispatchGet, order, object]);

  const handleUpdateAddress = (addressData) => {
    dispatchUpdate(order.orderId, addressData).then(() => {
      setShowEdit(false);
      dispatchGetOrder(order.orderId);
    });
  };

  const renderEditButton = () => {
    if (editable) {
      return (
        <Button onClick={() => setShowEdit(true)} type="btn-light">
          <i
            className="fas fa-pencil-alt"
            onClick={() => setShowEdit(true)}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
            style={{ margin: 0 }}
          />
        </Button>
      );
    }
    return null;
  };

  const renderAddressDetails = () => {
    const { loadingAddress } = order;

    if (!order.optionDelivery && !order.optionStorage) {
      return (
        <i className="text-muted">
          Für diesen Auftrag ist keine Lieferung oder Lagerung vorgesehen!
        </i>
      );
    }

    if (loadingAddress && !Lodash.isEmpty(loadingAddress)) {
      return (
        <AddressContainer address={loadingAddress} />
      );
    }

    if (address) {
      return (
        <AddressContainer address={address} />
      );
    }

    return (
      <div className="hk-text-primary">
        <small>
          Die Adresse wird angezeigt nachdem der Auftrag vom Monteur übernommen wurde und ein entsprechendes Lager
          ausgewählt hat.
        </small>
      </div>
    );
  };

  return (
    <Panel marginBottom={20}>
      <div className="d-flex">
        <div className="flex-grow-1">
          <h6>Lageradresse</h6>
        </div>
        <div>
          {renderEditButton()}
        </div>
      </div>

      {renderAddressDetails()}

      <UpdateLoadingAddress
        onSave={handleUpdateAddress}
        order={order}
        onClose={() => setShowEdit(false)}
        visible={showEdit}
      />
    </Panel>
  );
}

LoadingAddress.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  dispatchGet: PropTypes.func.isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
  dispatchGetOrder: PropTypes.func.isRequired,
  address: PropTypes.instanceOf(Object),
  editable: PropTypes.bool,
};

LoadingAddress.defaultProps = {
  address: null,
  editable: false,
};

function mapStoreToProps(store) {
  return {
    address: store.clientAddress.address,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: (clientId, addressId) => dispatch(get(clientId, addressId)),
    dispatchUpdate: (orderId, address) => dispatch(updateLoadingAddress(orderId, address)),
    dispatchGetOrder: (orderId) => dispatch(getOrder(orderId, true)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(LoadingAddress);
