import * as Redux from 'react-redux';
import React from 'react';
import axios from '../../Library/Axios';

export const defaultUri = '/client/{clientId}/address';
export const defaultUriId = `${defaultUri}/{addressId}`;

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.clientId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUri(parameter, uri = defaultUri) {
  const { clientId } = parameter;
  return uri.replace('{clientId}', clientId);
}

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.clientId {number}
 * @param parameter.addressId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUriId(parameter, uri = defaultUriId) {
  const { addressId } = parameter;
  return prepareUri(parameter, uri).replace('{addressId}', addressId);
}

export const CLIENTADDRESS_GET_START = 'CLIENTADDRESS_GET_START';
export const CLIENTADDRESS_GET_SUCCESS = 'CLIENTADDRESS_GET_SUCCESS';
export const CLIENTADDRESS_GET_ERROR = 'CLIENTADDRESS_GET_ERROR';

export const useDispatchGetClientAddress = () => {
  const dispatch = Redux.useDispatch();

  return React.useCallback((clientId, addressId) => {
    const uri = prepareUriId({ clientId, addressId });

    dispatch({ type: CLIENTADDRESS_GET_START, payload: { uri } });

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTADDRESS_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTADDRESS_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
};

/**
 * get()
 * @param clientId
 * @param addressId
 * @returns {function(*): Promise<unknown>}
 */
export function get(clientId, addressId) {
  return (dispatch, getState) => {
    const { address } = getState().clientAddress;
    if (address && address.addressId === addressId) {
      return Promise.resolve(address);
    }

    dispatch({ type: CLIENTADDRESS_GET_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}/${addressId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTADDRESS_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTADDRESS_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CLIENTADDRESS_GETLIST_START = 'CLIENTADDRESS_GETLIST_START';
export const CLIENTADDRESS_GETLIST_SUCCESS = 'CLIENTADDRESS_GETLIST_SUCCESS';
export const CLIENTADDRESS_GETLIST_ERROR = 'CLIENTADDRESS_GETLIST_ERROR';

export const useDispatchGetListClientAddress = (clientId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUri({ clientId });

  return React.useCallback(() => {
    dispatch({ type: CLIENTADDRESS_GETLIST_START, payload: { uri } });

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTADDRESS_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTADDRESS_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};

/**
 * getList()
 * @returns {function(*, *): Promise<any | never>}
 */
export function getList() {
  return (dispatch, getState) => {
    const { clientId } = getState().auth.client;
    dispatch({ type: CLIENTADDRESS_GETLIST_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTADDRESS_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTADDRESS_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CLIENTADDRESS_CREATE_START = 'CLIENTADDRESS_CREATE_START';
export const CLIENTADDRESS_CREATE_SUCCESS = 'CLIENTADDRESS_CREATE_SUCCESS';
export const CLIENTADDRESS_CREATE_ERROR = 'CLIENTADDRESS_CREATE_ERROR';

/**
 * create()
 * @returns {function(*, *): Promise<any | never>}
 */
export function create(formData) {
  return (dispatch, getState) => {
    const { clientId } = getState().auth.client;
    dispatch({ type: CLIENTADDRESS_CREATE_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().post(uri, formData).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTADDRESS_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTADDRESS_CREATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CLIENTADDRESS_UDPATE_START = 'CLIENTADDRESS_UDPATE_START';
export const CLIENTADDRESS_UDPATE_SUCCESS = 'CLIENTADDRESS_UDPATE_SUCCESS';
export const CLIENTADDRESS_UDPATE_ERROR = 'CLIENTADDRESS_UDPATE_ERROR';

/**
 * update()
 * @param addressData
 * @returns {function(*, *): Promise<unknown>}
 */
export function update(addressData) {
  return (dispatch, getState) => {
    const { clientId } = getState().auth.client;
    dispatch({ type: CLIENTADDRESS_UDPATE_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}/${addressData.addressId}`;

    return axios().put(uri, addressData).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTADDRESS_UDPATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTADDRESS_UDPATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CLIENTADDRESS_DELETE_START = 'CLIENTADDRESS_DELETE_START';
export const CLIENTADDRESS_DELETE_SUCCESS = 'CLIENTADDRESS_DELETE_SUCCESS';
export const CLIENTADDRESS_DELETE_ERROR = 'CLIENTADDRESS_DELETE_ERROR';

/**
 * remove()
 * @returns {function(*, *): Promise<any | never>}
 */
export function remove(clientAddress) {
  return (dispatch, getState) => {
    const { clientId } = getState().auth.client;
    dispatch({ type: CLIENTADDRESS_DELETE_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}/${clientAddress.addressId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTADDRESS_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTADDRESS_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
