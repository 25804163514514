import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Panel from '../../../Components/Panel';
import MailSettings from './Components/MailSettings';
import { get } from '../../../Redux/Action/ClientAction';
import InternalOrderSettings from './Components/InternalOrderSettings';
import GeneralSettings from './Components/GeneralSettings';
import ExternalApiSettings from './Components/ExternalApiSettings';

/**
 * Settings()
 * @param props
 * @returns {*}
 * @constructor
 */
function Settings(props) {
  const { client, dispatchGetClient } = props;
  const [settings] = React.useState(client.settings);

  React.useEffect(() => {
    dispatchGetClient();
  }, [dispatchGetClient]);

  if (settings) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Panel marginBottom={20}>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                <h4>Einstellungen</h4>
                <p style={{ marginBottom: 0 }}>
                  Hier können Sie verschiedene Optionen und Einstellungen für Ihren Account vornehmen
                </p>
              </div>
            </div>
          </Panel>

          <GeneralSettings client={client} />
          <InternalOrderSettings client={client} />
          <MailSettings client={client} />
          <ExternalApiSettings />
        </div>
      </div>
    );
  }
  return null;
}

Settings.propTypes = {
  dispatchGetClient: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    settings: store.client.client.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetClient: () => dispatch(get()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Settings));
