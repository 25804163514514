import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Item from '../Item/Item';
import { formatDate, formatTime } from '../../../Library/Functions';

const Headline = styled.div`
  font-family: "DIN Next LT W01 Light", sans-serif;
  font-style: normal;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
`;

const Comment = styled.div`
  font-style: normal;
`;

/**
 * ProposedDateView()
 * @param props
 * @returns {null|JSX.Element}
 * @constructor
 */
export default function ProposedDateView(props) {
  const {
    date, startTime, comment, selected, deleted, onDelete, onEdit, onSelect,
  } = props;

  const getHeadline = () => {
    const displayDate = date ? formatDate(date) : null;
    const displayStartTime = startTime ? formatTime(startTime) : null;

    if (!displayDate) {
      return 'Kein Datum hinterlegt';
    }
    if (!displayStartTime) {
      return `Am ${displayDate}`;
    }
    return `Am ${displayDate} ab ${displayStartTime} Uhr`;
  };

  const renderDateComment = () => (comment ? <Comment>{comment}</Comment> : null);

  return (deleted ? null : (
    <Item
      selectable
      selected={selected}
      onSelect={onSelect}
      onEdit={onEdit}
      onDelete={onDelete}
    >
      <Headline>{getHeadline()}</Headline>
      {renderDateComment()}
    </Item>
  ));
}

ProposedDateView.propTypes = {
  date: PropTypes.string,
  startTime: PropTypes.string,
  comment: PropTypes.string,
  selected: PropTypes.bool,
  deleted: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onSelect: PropTypes.func,
};

ProposedDateView.defaultProps = {
  date: undefined,
  startTime: null,
  comment: '',
  selected: false,
  deleted: false,
  onDelete: undefined,
  onEdit: undefined,
  onSelect: undefined,
};
