import {
  DISPOSITION_GETLIST_SUCCESS,
} from '../Action/DispositionAction';

const defaultValues = {
  calendarData: null,
  users: null,
  teams: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case DISPOSITION_GETLIST_SUCCESS: {
      const { data } = action.payload;
      const { users, teams } = data;

      const preparedData = [
        { name: 'Monteure', icon: 'fas fa-user', data: users },
        { name: 'Teams', icon: 'fas fa-users', data: teams },
      ];

      return {
        ...state, calendarData: preparedData, users, teams,
      };
    }

    default: return { ...state };
  }
}
