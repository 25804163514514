import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ConfirmDeleteDialog from '../Dialogs/ConfirmDeleteDialog';
import ConfirmAttachToMailDialog from './ConfirmAttachToMailDialog';
import { ORDERSTATE_EXPIRED, ORDERSTATE_FINISHED } from '../../Library/StateTypes';
import { formatDateTime } from '../../Library/Functions';

/**
 * DocumentItem()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function DocumentItem(props) {
  const {
    item, order, deletable, caption, onDelete, onAttachToMail, type,
  } = props;
  const [askDelete, setAskDelete] = React.useState(false);
  const [confirmAttachToMailModal, setConfirmAttachToMailModal] = React.useState(false);
  const storeAuth = useSelector((state) => state.auth.client);
  const handleOnClickDelete = (e) => {
    setAskDelete(true);
    e.preventDefault();
  };

  const handleOnClickAttachToMail = (e) => {
    if (item.sendToCustomer === false) {
      setConfirmAttachToMailModal(true);
    } else {
      onAttachToMail(item);
    }
    e.preventDefault();
  };

  const confirmAttachToMail = () => {
    onAttachToMail(item);
    setConfirmAttachToMailModal(false);
  };

  const handleOnDelete = () => {
    setAskDelete(false);
    onDelete(item);
  };

  const renderDeleteIcon = () => {
    if (deletable) {
      return (
        <i
          title="Dokument löschen"
          className="fas fa-trash"
          onClick={handleOnClickDelete}
          tabIndex={-1}
          role="button"
          onKeyPress={() => {}}
        />
      );
    }
    return null;
  };

  const renderAttachToCustomerMailIcon = () => {
    if (order && storeAuth && order.state !== ORDERSTATE_FINISHED && order.state !== ORDERSTATE_EXPIRED) {
      if ((order.mandantId && order.mandantId > 0 && order.mandantId === storeAuth.clientId)
        || ((!order.mandantId || order.mandantId === 0) && order.clientId === storeAuth.clientId)) {
        const icon = (item.sendToCustomer) ? 'fas fa-user-check' : 'fas fa-user';

        return (
          <div>
            <i
              title="Dokument an Kundenmail anhängen"
              className={icon}
              onClick={handleOnClickAttachToMail}
              tabIndex={-1}
              role="button"
              onKeyPress={() => {
              }}
            />
          </div>
        );
      }
    }
    return null;
  };

  const getIcon = () => {
    if (item.extension.toLowerCase() === 'pdf') {
      return 'far fa-file-pdf';
    }
    if (item.extension.toLowerCase() === 'png' || item.extension.toLowerCase() === 'jpg'
      || item.extension.toLowerCase() === 'jpeg' || item.extension.toLowerCase() === 'bmp'
    ) {
      return 'fas fa-image';
    }
    return 'fas fa-file';
  };

  const getFileSize = () => {
    if (item.size < 1024 * 1024) {
      return `${(item.size / 1024).toFixed(1)} kb`;
    }
    return `${(item.size / 1024 / 1024).toFixed(1)} MB`;
  };

  return (
    <>
      <a
        href={item.absolutePath}
        className="Document"
        title={(caption) || `${item.filename} ${formatDateTime(item.createdDate)}`}
      >
        <i className={getIcon()} />
        <div className="Filename">
          {`${(caption) || item.filename} `}
          <small className="text-muted">
            {`(${getFileSize()})`}
          </small>
        </div>
        {renderAttachToCustomerMailIcon()}
        {renderDeleteIcon()}
      </a>

      <ConfirmDeleteDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        onConfirm={handleOnDelete}
        confirmCaption="Löschen"
        centered
        headline="Dokument löschen?"
      >
        Bitte bestätigen Sie das Sie dieses Dokument endgültig löschen möchten.
      </ConfirmDeleteDialog>

      <ConfirmAttachToMailDialog
        type={type}
        onClose={() => setConfirmAttachToMailModal(false)}
        onConfirm={confirmAttachToMail}
        visible={confirmAttachToMailModal}
        item={item}
      />
    </>
  );
}

DocumentItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  deletable: PropTypes.bool.isRequired,
  caption: PropTypes.string,
  onDelete: PropTypes.func,
  onAttachToMail: PropTypes.func,
  type: PropTypes.oneOf(['object', 'service']).isRequired,
};

DocumentItem.defaultProps = {
  caption: null,
  onDelete: () => {},
  onAttachToMail: () => {},
};
