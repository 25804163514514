import {
  SYSTEM_CLIENT_GETLIST_ERROR, SYSTEM_CLIENT_GETLIST_START, SYSTEM_CLIENT_GETLIST_SUCCESS,
  SYSTEM_CLIENT_GET_START, SYSTEM_CLIENT_GET_SUCCESS, SYSTEM_CLIENT_GET_ERROR, SYSTEM_CLIENT_UPDATE_SUCCESS,
  SYSTEM_CLIENT_ADDRESSES_GETLIST_ERROR, SYSTEM_CLIENT_ADDRESSES_GETLIST_START, SYSTEM_CLIENT_ADDRESSES_GETLIST_SUCCESS,
} from '../Action/ClientAction';

const defaultValues = {
  clients: null,
  addresses: null,
  selectedClient: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case SYSTEM_CLIENT_GETLIST_START: {
      return { ...state };
    }
    case SYSTEM_CLIENT_GETLIST_SUCCESS: {
      return { ...state, clients: action.payload };
    }
    case SYSTEM_CLIENT_GETLIST_ERROR: {
      return { ...state, clients: null };
    }

    /** GET CLIENT */
    case SYSTEM_CLIENT_GET_START: {
      return { ...state };
    }
    case SYSTEM_CLIENT_UPDATE_SUCCESS:
    case SYSTEM_CLIENT_GET_SUCCESS: {
      return { ...state, selectedClient: action.data };
    }
    case SYSTEM_CLIENT_GET_ERROR: {
      return { ...state, selectedClient: null };
    }

    /** GET CLIENT ADDRESSES */
    case SYSTEM_CLIENT_ADDRESSES_GETLIST_START: {
      return { ...state };
    }
    case SYSTEM_CLIENT_ADDRESSES_GETLIST_SUCCESS: {
      return { ...state, addresses: action.payload };
    }
    case SYSTEM_CLIENT_ADDRESSES_GETLIST_ERROR: {
      return { ...state, addresses: null };
    }

    default: return { ...state };
  }
}
