import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../../Components/ModalDialog';
import { formatCurrency, formatDate } from '../../../Library/Functions';
import Button from '../../../Components/Button';
import { VOUCHERTYPE_RUNTIME } from '../../../Library/Types';

/**
 * ActionButton()
 * @param props
 * @returns {*}
 * @constructor
 */
function ActionButton(props) {
  const { onDelete, onDeactivate, voucher } = props;

  if (voucher && !voucher.expired) {
    if (voucher.clientId && voucher.clientId > 0) {
      return (
        <>
          <Button onClick={onDeactivate}>Deaktivieren</Button>
        </>
      );
    }
    return (
      <>
        <Button onClick={onDelete}>Löschen</Button>
      </>
    );
  }
  return null;
}

ActionButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  voucher: PropTypes.instanceOf(Object),
};

ActionButton.defaultProps = {
  voucher: null,
};

/**
 * DetailsDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function DetailsDialog(props) {
  const {
    onClose, voucher, onDelete, clients, onCustomer, onDeactivate,
  } = props;

  const renderValidDays = () => {
    if (voucher.type === VOUCHERTYPE_RUNTIME) {
      return (
        <tr>
          <td>Gültigkeit ab Einlösetag:</td>
          <td>{`${voucher.validDays} Tage`}</td>
        </tr>
      );
    }
    return null;
  };

  const renderComment = () => {
    if (voucher.comment) {
      return (
        <tr>
          <td>Kommentar:</td>
          <td>{voucher.comment}</td>
        </tr>
      );
    }
    return null;
  };

  const getClient = () => {
    if (clients && voucher.clientId && voucher.clientId > 0) {
      const client = clients.filter((data) => data.clientId === voucher.clientId)[0];
      return (
        <tr onClick={() => onCustomer(client.clientId)}>
          <td>Eingelöst von:</td>
          <td className="text-info" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            {client.name}
          </td>
        </tr>
      );
    }
    return null;
  };

  const renderExpireHint = () => {
    if (voucher.expired) {
      return (
        <div className="alert alert-danger">
          <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
          Der Gutschein ist bereits abgelaufen!
        </div>
      );
    }
    return null;
  };

  if (voucher === null) {
    return null;
  }

  return (
    <ModalDialog
      onClose={onClose}
      visible
      closeCaption="Schließen"
      hideConfirmButton
      leftButtonContent={(
        <ActionButton
          onDelete={() => onDelete(voucher)}
          voucher={voucher}
          onDeactivate={() => onDeactivate(voucher)}
        />
      )}
    >
      <h5>Gutscheindetails</h5>

      <div>
        <hr />
      </div>

      {renderExpireHint()}

      <div className="row">
        <div className="col-lg-12">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td width={200}>Code:</td>
                <td>{voucher.formattedCode}</td>
              </tr>
              <tr>
                <td>Identifier:</td>
                <td>{voucher.identifier}</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>{voucher.type}</td>
              </tr>
              <tr>
                <td>Kundentyp:</td>
                <td>{voucher.clientType}</td>
              </tr>
              <tr>
                <td>Einlösbar von:</td>
                <td>{formatDate(voucher.validFrom)}</td>
              </tr>
              <tr>
                <td>Einlösbar bis:</td>
                <td>{formatDate(voucher.validUntil)}</td>
              </tr>
              {renderValidDays()}
              <tr>
                <td>Rabatt:</td>
                <td>
                  {(voucher.discountPrice) ? formatCurrency(voucher.discountPrice) : `${voucher.discountPercent}%`}
                </td>
              </tr>
              {renderComment()}
              <tr>
                <td>Erstellt am:</td>
                <td>{formatDate(voucher.createdDate)}</td>
              </tr>
              {getClient()}
            </tbody>
          </table>
        </div>
      </div>
    </ModalDialog>
  );
}

DetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onCustomer: PropTypes.func.isRequired,
  voucher: PropTypes.instanceOf(Object),
  clients: PropTypes.instanceOf(Array),
};

DetailsDialog.defaultProps = {
  voucher: null,
  clients: null,
};
