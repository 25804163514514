import React from 'react';
import {
  Route, withRouter, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import RightNavigationLayout from '../../Components/Layout/RightNavigationLayout';
import SystemRightNavigation from '../../Components/Navigation/SystemRightNavigation';
import SystemClients from '../../Pages/System/Clients';
import MaterialOverview from '../../Pages/System/Material/Overview';
import Vouchers from '../../Pages/System/Voucher/Vouchers';
import SystemSettings from '../../Pages/System/SystemSettings';
import ReclamationList from '../../Pages/System/Reclamation/List';

/**
 * SystemDefaultRoutes()
 * @returns {*}
 * @constructor
 */
function SystemDefaultRoutes() {
  const { path } = useRouteMatch();

  return (
    <RightNavigationLayout navigation={<SystemRightNavigation />}>
      <Switch>
        <Route exact path={`${path}/clients`}><SystemClients /></Route>
        <Route exact path={`${path}/reclamations`}><ReclamationList /></Route>
        <Route exact path={`${path}/materials`}><MaterialOverview /></Route>
        <Route exact path={`${path}/vouchers`}><Vouchers /></Route>
        <Route exact path={`${path}/settings`}><SystemSettings /></Route>
        <Route><Redirect to={`${path}/clients`} /></Route>
      </Switch>
    </RightNavigationLayout>
  );
}

SystemDefaultRoutes.propTypes = {

};

SystemDefaultRoutes.defaultProps = {

};

function mapStoreToProps() {
  return {

  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(SystemDefaultRoutes));
