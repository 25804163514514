import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Panel from '../../../../Components/Panel';
import { TRANSACTION_PAYMENT_METHOD_DEBIT, TRANSACTION_PAYMENT_METHOD_INVOICE } from '../../../../Library/Types';
import { useDispatchGetInvoiceList } from '../../Redux/Action/InvoiceAction';

import './style.css';

const defaultState = {
  orders: true,
  reclamations: false,
  internal: false,
  external: false,
  assigne: false,
  object: false,
  single: false,
  withoutUsers: false,
  reclamation: false,
  year: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'reset': {
      return { ...defaultState };
    }
    case 'init':
      return { ...action.payload };
    case 'invoice':
      return {
        ...state,
        invoice: !state.invoice,
        debit: (!state.invoice) ? false : state.debit,
        credit: (!state.invoice) ? false : state.credit,
      };
    case 'debit':
      return {
        ...state,
        debit: !state.debit,
        invoice: (!state.debit) ? false : state.invoice,
        credit: (!state.debit) ? false : state.credit,
      };
    case 'credit':
      return {
        ...state,
        credit: !state.credit,
        invoice: (!state.credit) ? false : state.invoice,
        debit: (!state.credit) ? false : state.debit,
      };
    case 'open':
      return { ...state, open: !state.open, payed: (!state.open) ? false : state.payed };
    case 'payed':
      return { ...state, payed: !state.payed, open: (!state.payed) ? false : state.open };
    case 'year':
      return { ...state, year: action.payload };

    default:
      throw new Error();
  }
}

const storageSaveKey = '@systemInvoiceFilter';

/**
 * InvoiceFilter()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function InvoiceFilter(props) {
  const { onChange } = props;
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [prevState, setPrevState] = useState(JSON.stringify(state));
  const [init, setInit] = useState(false);

  const { invoices } = useSelector((store) => store.systemInvoice);
  const dispatchGetInvoiceList = useDispatchGetInvoiceList();

  React.useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem(storageSaveKey));
    if (savedState) {
      dispatch({ type: 'init', payload: savedState });
    }
  }, []);

  React.useEffect(() => {
    if (state.year) {
      dispatchGetInvoiceList('all', state.year);
    }
  }, [state.year, dispatchGetInvoiceList]);

  React.useEffect(() => {
    setInit(false);
  }, [invoices]);

  React.useEffect(() => {
    if (invoices && (JSON.stringify(state) !== prevState || !init)) {
      let filteredArray = invoices;

      filteredArray = (state.invoice)
        ? filteredArray.filter((item) => (item.paymentMethod === TRANSACTION_PAYMENT_METHOD_INVOICE))
        : filteredArray;
      filteredArray = (state.debit)
        ? filteredArray.filter((item) => (item.paymentMethod === TRANSACTION_PAYMENT_METHOD_DEBIT && item.isDebit))
        : filteredArray;
      filteredArray = (state.credit)
        ? filteredArray.filter((item) => item.isVoucher)
        : filteredArray;

      filteredArray = (state.open)
        ? filteredArray.filter((item) => !item.processed)
        : filteredArray;
      filteredArray = (state.payed)
        ? filteredArray.filter((item) => item.processed)
        : filteredArray;

      setInit(true);
      setPrevState(JSON.stringify(state));
      localStorage.setItem(storageSaveKey, JSON.stringify(state));
      if (onChange) {
        onChange(filteredArray, state.year);
      }
    }
  }, [state, onChange, invoices, prevState, init]);

  const renderYearFilter = () => {
    const startYear = 2020;
    const currentYear = moment().year();
    const content = [];

    for (let processedYear = startYear; processedYear <= currentYear; processedYear += 1) {
      content.push(
        <FilterCard
          key={processedYear}
          caption={`${processedYear}`}
          selected={state.year === processedYear}
          onClick={() => dispatch({ type: 'year', payload: processedYear })}
        />,
      );
    }
    return content;
  };

  const renderOrderFilters = () => (
    <>
      <div className="Spacer" />

      <div className="d-flex flex-wrap">
        <div className="d-flex flex-wrap">
          <FilterCard
            caption="Rechnung"
            selected={state.invoice}
            onClick={() => dispatch({ type: 'invoice' })}
          />
          <FilterCard
            caption="Lastschrift"
            selected={state.debit}
            onClick={() => dispatch({ type: 'debit' })}
          />
          <FilterCard
            caption="Gutschrift"
            selected={state.credit}
            onClick={() => dispatch({ type: 'credit' })}
          />
        </div>

        <div className="Spacer" />

        <FilterCard
          caption="Offen"
          selected={state.open}
          onClick={() => dispatch({ type: 'open' })}
        />
        <FilterCard
          caption="Bezahlt"
          selected={state.payed}
          onClick={() => dispatch({ type: 'payed' })}
        />

        <div className="Spacer" />
        {renderYearFilter()}
      </div>
    </>
  );

  return (
    <Panel marginBottom={20}>
      <div className="FilterCardContainer">
        <div className="Caption">
          Filter
        </div>

        {renderOrderFilters()}
      </div>
    </Panel>
  );
}

InvoiceFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

/**
 * FilterCard()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function FilterCard(props) {
  const {
    caption, selected, onClick, visible,
  } = props;

  if (visible) {
    return (
      <div
        className={`FilterCard ${(selected) ? 'Selected' : null}`}
        onClick={onClick}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
      >
        {caption}
      </div>
    );
  }
  return null;
}

FilterCard.propTypes = {
  caption: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

FilterCard.defaultProps = {
  selected: false,
  visible: true,
};
