import React from 'react';
import PropTypes from 'prop-types/prop-types';

import './style.css';

const headerHk = require('../../../../Assets/Images/apple-header.jpg');
const appAdv = require('../../../../Assets/Images/app-werbung.png');

/**
 * StartpageHeader()
 * @returns {*}
 * @constructor
 */
export default function StartpageHeader(props) {
  const { image, withText, height } = props;

  const renderChildren = () => {
    if (withText) {
      return (
        <div className="container d-flex flex-column">
          <div className="container d-flex flex-row">
            <div>
              <div className="Headline">DIE</div>
              <div className="Headline hk-text-primary">KÜCHEN-</div>
              <div className="Headline hk-text-primary">MONTAGE-</div>
              <div className="Headline hk-text-primary">PLATTFORM</div>
              <div className="Headline">GENIAL DIGITAL</div>
            </div>
            <div className="d-flex justify-content-end flex-fill">
              <div>
                <img src={appAdv} alt="App Werbung" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className="StartpageHeader FullPageBox Light"
      style={{ backgroundImage: `url(${(image) || headerHk})`, height: (height) || 'auto' }}
    >
      {renderChildren()}
    </div>
  );
}

StartpageHeader.propTypes = {
  image: PropTypes.string,
  withText: PropTypes.bool,
  height: PropTypes.number,
};

StartpageHeader.defaultProps = {
  image: null,
  withText: true,
  height: null,
};
