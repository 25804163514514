import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { VIEW_DAY, VIEW_WEEK, VIEW_WEEK_OVERVIEW } from '../index';

export const Container = styled.div`
  min-width: 250px;
  flex-grow: 1;
`;

export const ContentRow = styled.div`
  border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  border-left: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-left: 15px;
  height: ${(props) => props.theme.height}px;
  
  &.TimeCaption {
    height: ${(props) => props.theme.cellHeadlineHeight}px;
    justify-content: flex-start;
    font-size: 17px;
  }

  &.HeadlineWeekDays {
    background-color: ${(props) => ((props.theme.hasBackground) ? props.theme.color.backgroundGrayLight : null)};
    height: ${(props) => props.theme.cellHeadlineHeight}px;
    justify-content: flex-start;
    font-family: "DIN Next LT W01 Bold", sans-serif;
    font-size: 20px;
    padding: 6px 15px;
  }
  
  &.GroupSeparator {
    padding-left: 15px;
    display: flex;
    align-items: center;
    height: ${(props) => props.theme.cellSeparatorHeight}px;
    font-size: ${(props) => props.theme.cellSeparatorFontSize}px;
    border-bottom: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.borderGrayLight};
    border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.backgroundGrayLight};
    background-color: ${(props) => props.theme.color.backgroundGrayLight};
    width: auto;
    
    >i {
      margin-right: 10px;
    }
  }
`;

/**
 * LeftCaptionContainer()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function LeftCaptionContainer(props) {
  const { data, viewType } = props;
  const theme = useTheme();

  const buildName = (item) => {
    const name = (item.lastname && item.firstname)
      ? `${item.lastname}, ${item.firstname}`
      : item.firstname || item.lastname;

    if (item.initials) {
      return `${name} (${item.initials})`;
    }
    return name;
  };

  const renderNames = () => {
    const headerRows = [];

    if (viewType === VIEW_WEEK || viewType === VIEW_WEEK_OVERVIEW) {
      headerRows.push(
        <ContentRow
          key="weekHeadline"
          className="HeadlineWeekDays"
          theme={{ ...theme, hasBackground: (viewType !== VIEW_WEEK_OVERVIEW) }}
        />,
      );
    }

    if (viewType === VIEW_DAY || viewType === VIEW_WEEK) {
      headerRows.push(
        <ContentRow key="timeline" className="TimeCaption">
          Uhrzeit
        </ContentRow>,
      );
    }

    const mappedData = data.map((separator) => {
      const height = separator.rowHeight || theme.cellHeight;

      const sep = (
        <ContentRow theme={{ ...theme, rightBorder: true }} key="Separator" className="GroupSeparator">
          <i className={separator.icon} />
          {`${separator.name} (${separator.data.length})`}
        </ContentRow>
      );
      const result = separator.data.map((item) => (
        <ContentRow
          key={`${item.lastname}${item.firstname}`}
          theme={{ ...theme, height }}
        >
          {buildName(item)}
        </ContentRow>
      ));

      return [sep, ...result];
    });

    return [...headerRows, ...mappedData];
  };

  return (
    <Container>
      {renderNames()}
    </Container>
  );
}

LeftCaptionContainer.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  viewType: PropTypes.oneOf(['day', 'week', 'weekOverview']),
};

LeftCaptionContainer.defaultProps = {
  viewType: 'day',
};
