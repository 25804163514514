import {
  SYSTEM_SERVICES_APPLY_FILTERS_FINISHED,
  SYSTEM_SERVICES_APPLY_FILTERS_START, SYSTEM_SERVICES_APPLY_FILTERS_SUCCESS,
  SYSTEM_SERVICES_GET_LIST_ERROR,
  SYSTEM_SERVICES_GET_LIST_START,
  SYSTEM_SERVICES_GET_LIST_SUCCESS, SYSTEM_SERVICES_UPDATE_FILTERS,
} from '../Action/ServiceAction';

const defaultTargetValues = {
  services: null,
  filteredServices: null,
  filters: null,
  lastUpdated: null,
  isLoading: false,
  isWorking: false,
};

const defaultValues = {
  internal: { ...defaultTargetValues },
  external: { ...defaultTargetValues },
  client: { ...defaultTargetValues },
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case SYSTEM_SERVICES_GET_LIST_START: {
      const update = {};
      const { target } = action.payload;
      const isLoading = true;
      update[target] = { ...state[target], isLoading };
      return { ...state, ...update };
    }

    case SYSTEM_SERVICES_GET_LIST_SUCCESS: {
      const update = {};
      const { target, services, lastUpdated } = action.payload;
      const isLoading = false;
      update[target] = {
        ...state[target], isLoading, services, lastUpdated,
      };
      return { ...state, ...update };
    }

    case SYSTEM_SERVICES_GET_LIST_ERROR: {
      const update = {};
      const { target } = action.payload;
      const isLoading = false;
      update[target] = { ...state[target], isLoading };
      return { ...state, ...update };
    }

    case SYSTEM_SERVICES_UPDATE_FILTERS: {
      const update = {};
      const { target, newFilter } = action.payload;
      const filters = { ...state[target].filters, ...newFilter };
      update[target] = { ...state[target], filters };
      return { ...state, ...update };
    }

    case SYSTEM_SERVICES_APPLY_FILTERS_START: {
      const update = {};
      const { target } = action.payload;
      const isWorking = true;
      update[target] = { ...state[target], isWorking };
      return { ...state, ...update };
    }

    case SYSTEM_SERVICES_APPLY_FILTERS_SUCCESS: {
      const update = {};
      const { target, filteredServices } = action.payload;
      const isWorking = false;
      update[target] = { ...state[target], isWorking, filteredServices };
      return { ...state, ...update };
    }

    case SYSTEM_SERVICES_APPLY_FILTERS_FINISHED: {
      const update = {};
      const { target } = action.payload;
      const isWorking = false;
      update[target] = { ...state[target], isWorking };
      return { ...state, ...update };
    }
    default: return { ...state };
  }
}
