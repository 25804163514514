import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { cancelImport, importOrders, uploadCsv } from '../../../Redux/Action/ImportAction';
import Panel from '../../../Components/Panel';
import Button from '../../../Components/Button';
import LoadingModal from '../../../Components/LoadingModal';
import getErrorMessage from '../../../Library/ErrorCodeMappings';
import { IMPORT_ACTION_CREATE, IMPORT_ACTION_NONE, IMPORT_ACTION_UPDATE } from '../../../Library/Types';
import ImportOrderList from './Component/ImportOrderList';
import Alert from '../../../Components/Alert';

/**
 * CsvImport()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function CsvImport(props) {
  const {
    dispatchUpload, uploading, preparedOrders, lastErrorCode, dispatchImport, dispatchCancel, history, partners,
  } = props;
  const [create, setCreate] = React.useState(null);
  const [update, setUpdate] = React.useState(null);
  const [importing, setImporting] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [none, setNone] = React.useState(null);
  const ref = React.useRef();

  React.useEffect(() => () => {
    dispatchCancel();
  }, [dispatchCancel]);

  React.useEffect(() => {
    if (preparedOrders) {
      setCreate(preparedOrders.filter((item) => item.action === IMPORT_ACTION_CREATE));
      setUpdate(preparedOrders.filter((item) => item.action === IMPORT_ACTION_UPDATE));
      setNone(preparedOrders.filter((item) => item.action === IMPORT_ACTION_NONE));
    } else {
      setCreate(null);
      setUpdate(null);
      setNone(null);
    }
  }, [preparedOrders]);

  const handleUpload = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      setCreate(null);
      setUpdate(null);
      setNone(null);
      dispatchUpload(event.target.files[0]);
    }
  };

  const executeImport = () => {
    setImporting(true);
    dispatchImport([...create, ...update]).then(() => {
      setImporting(false);
      setShowSuccess(true);
    });
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
    history.push('/order');
  };

  const renderError = () => {
    if (lastErrorCode) {
      const caption = getErrorMessage(lastErrorCode);
      return (
        <Panel marginBottom={20} withPadding={false}>
          <div className="alert alert-danger" style={{ marginBottom: 0 }}>
            <i className="fas fa-times-circle" style={{ marginRight: 10 }} />
            {caption}
          </div>
        </Panel>
      );
    }
    return null;
  };

  const renderInfoHeader = () => {
    if (preparedOrders) {
      return (
        <Panel marginBottom={20} containerStyle={{ fontSize: 16 }}>
          <div>
            <i className="fas fa-plus-square text-success" style={{ marginRight: 10 }} />
            {`${(create) ? create.length : 0} Aufträge werden neu erstellt`}
          </div>
          <div>
            <i className="fas fa-pen-square text-warning" style={{ marginRight: 10 }} />
            {`${(update) ? update.length : 0} Aufträge werden ggf. aktualisiert, falls Daten geändert wurden`}
          </div>
          <div>
            <i className="fas fa-minus-square text-danger" style={{ marginRight: 10 }} />
            {`${(none) ? none.length : 0} 
              werden ignoriert weil der Auftragsstaus unzulässig ist oder der Import fehlerhaft war`}
          </div>
        </Panel>
      );
    }
    return null;
  };

  const renderUpload = () => {
    if (!preparedOrders) {
      return (
        <>
          {renderError()}
          <Panel>
            <div className="text-center">
              <strong>Bitte wählen Sie Ihre CSV-Datei mit den Daten für den Auftragsimport.</strong>
              <br />
              Im ersten Schritt werden die Daten nur validiert und Sie erhalten anschließend eine Übersicht aller
              durchzuführenden Aktionen.
              <br />
              Sie können sich alle Importe und Aktualisierungen anschauen und anschließend den finalen Import starten.
              <br />
              <i>In diesem Schritt werden noch keine Daten importiert oder Aufträge aktualisiert!</i>
            </div>
            <div className="text-center" style={{ marginTop: 40 }}>
              <Button onClick={() => ref.current.click()}>
                CSV-Datei auswählen
              </Button>
              <input type="file" style={{ display: 'none' }} ref={ref} onChange={handleUpload} />
            </div>
          </Panel>
        </>
      );
    }
    return null;
  };

  const renderCreateOrders = () => {
    if (create && create.length > 0) {
      return (
        <Panel marginBottom={20}>
          <h5 className="text-success">
            <i className="fas fa-plus-square" style={{ marginRight: 10 }} />
            Diese Aufträge werden neu erstellt
          </h5>
          <br />
          <ImportOrderList orders={create} partners={partners} />
        </Panel>
      );
    }
    return null;
  };

  const renderUpdateOrders = () => {
    if (update && update.length > 0) {
      return (
        <Panel marginBottom={20}>
          <h5 className="text-warning">
            <i className="fas fa-pen-square" style={{ marginRight: 10 }} />
            Diese Aufträge werden aktualisiert
          </h5>
          <p>
            Sollten sich seit dem letzten Import Daten des Aufrags geändert haben werden diese aktualisiert.
          </p>
          <br />
          <ImportOrderList orders={update} />
        </Panel>
      );
    }
    return null;
  };

  const renderNoneOrders = () => {
    if (none && none.length > 0) {
      return (
        <Panel marginBottom={20}>
          <h5 className="text-danger">
            <i className="fas fa-minus-square" style={{ marginRight: 10 }} />
            Diese Aufträge werden nciht importiert
          </h5>
          <br />
          <ImportOrderList orders={none} isError />
        </Panel>
      );
    }
    return null;
  };

  const renderImportButton = () => {
    if ((create || update || none) && (update.length > 0 || create.length > 0 || none.length > 0)) {
      return (
        <Panel withPadding={false}>
          <div className="ButtonContainer d-flex justify-content-end">
            <Button onClick={() => dispatchCancel()} type="btn-light">Abbrechen</Button>
            <Button
              onClick={executeImport}
              disabled={(!update && !create) || (update.length === 0 && create.length === 0)}
            >
              Import / Aktualisierung durchführen
            </Button>
          </div>
        </Panel>
      );
    }
    return null;
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <Panel marginBottom={20}>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              <h4>CSV Auftragsimport</h4>
              <p style={{ margin: 0, padding: 0 }}>
                Mit dem CSV Auftragsimport können Sie schnell und einfach Einzelaufträge über eine CSV-Datei
                erstellen oder aktualisieren. Somit können Sie Daten, die Sie bereits in anderen Systemen
                gepflegt haben, in Ihren hey.kitchen Account importieren. Alle Aufträge werden über die von Ihnen
                angegebene Kommissionsnummer eindeutig identifiziert. Ist eine Kommissionsnummer bereits vorhanden,
                werden die Daten des vorhandenen Auftrags mit den Daten aus dem Import abgeglichen und ggf.
                aktualisiert. Ist die Kommissionsnummer nicht vorhanden, wird der Auftrag erstellt. Grundsätzlich
                können keine Daten für die Verwendung von externen Aufträgen über hey.kitchen importiert werden.
                Der Import ist nur möglich für eigene interne Aufträge.
                <br />
                <br />
                Weitere Informationen zum Format und den Feldbezeichnungen entnehmen Sie bitte der
                <NavLink to="/admin/import/csv/docu"> Dokumentation!</NavLink>
              </p>
            </div>
          </div>
        </Panel>
        {renderUpload()}
        {renderInfoHeader()}
        {renderCreateOrders()}
        {renderUpdateOrders()}
        {renderNoneOrders()}
        {renderImportButton()}
      </div>

      <LoadingModal visible={uploading} text="Datei wird hochgeladen und validiert..." />
      <LoadingModal
        visible={importing}
        headline="Daten werden verarbeitet..."
        text="Bitte schließen Sie nicht das Browserfenster. Der Vorgang kann mehrere Minuten dauern."
      />
      <Alert visible={showSuccess} type="success" onClose={handleCloseSuccess}>
        Die Daten wurden erfolgreich importiert und aktualisiert!
      </Alert>
    </div>
  );
}

CsvImport.propTypes = {
  dispatchUpload: PropTypes.func.isRequired,
  dispatchImport: PropTypes.func.isRequired,
  dispatchCancel: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  preparedOrders: PropTypes.instanceOf(Array),
  lastErrorCode: PropTypes.string,
  history: PropTypes.instanceOf(Object).isRequired,
  partners: PropTypes.instanceOf(Array),
};

CsvImport.defaultProps = {
  preparedOrders: null,
  lastErrorCode: null,
  partners: null,
};

function mapStoreToProps(store) {
  return {
    uploading: store.import.uploading,
    preparedOrders: store.import.preparedOrders,
    lastErrorCode: store.import.errorCode,
    partners: store.client.partners,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpload: (file) => dispatch(uploadCsv(file)),
    dispatchImport: (orders) => dispatch(importOrders(orders)),
    dispatchCancel: () => dispatch(cancelImport()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(CsvImport));
