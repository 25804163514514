import axios from '../../Library/Axios';

export const WEBDEMOCONTACTMAIL_START = 'WEBDEMOCONTACTMAIL_START';
export const WEBDEMOCONTACTMAIL_SUCCESS = 'WEBDEMOCONTACTMAIL_SUCCESS';
export const WEBDEMOCONTACTMAIL_ERROR = 'WEBDEMOCONTACTMAIL_ERROR';

/**
 * sendWebdemoContactMail()
 * @param data
 * @returns {function(*): Promise<AxiosResponse<T>>}
 */
export function sendWebdemoContactMail(data) {
  return (dispatch) => {
    dispatch({ type: WEBDEMOCONTACTMAIL_START, data });

    const config = {
      params: { action: 'webdemoContact' },
    };

    return axios().post('/mailer', data, config).then((response) => {
      dispatch({ type: WEBDEMOCONTACTMAIL_SUCCESS, payload: response });
      return Promise.resolve(response);
    }).catch((error) => {
      dispatch({ type: WEBDEMOCONTACTMAIL_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const CONTACTMAIL_START = 'CONTACTMAIL_START';
export const CONTACTMAIL_SUCCESS = 'CONTACTMAIL_SUCCESS';
export const CONTACTMAIL_ERROR = 'CONTACTMAIL_ERROR';

/**
 * sendContactMail()
 * @param data
 * @returns {function(*): Promise<AxiosResponse<T>>}
 */
export function sendContactMail(data) {
  return (dispatch) => {
    dispatch({ type: CONTACTMAIL_START, data });

    const config = {
      params: { action: 'contact' },
    };

    return axios().post('/mailer', data, config).then((response) => {
      dispatch({ type: CONTACTMAIL_SUCCESS, payload: response });
      return Promise.resolve(response);
    }).catch((error) => {
      dispatch({ type: CONTACTMAIL_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
