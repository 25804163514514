import axios from '../../Library/Axios';

export const defaultUri = '/client/{clientId}/subcontractor';
export const SUBCONTRACTOR_GET_START = 'SUBCONTRACTOR_GET_START';
export const SUBCONTRACTOR_GET_SUCCESS = 'SUBCONTRACTOR_GET_SUCCESS';
export const SUBCONTRACTOR_GET_ERROR = 'SUBCONTRACTOR_GET_ERROR';

/**
 * get()
 * @param subcontractorID
 * @returns {function(*, *): *}
 */
export function get(subcontractorID) {
  return (dispatch) => {
    dispatch({ type: SUBCONTRACTOR_GET_START });
    const uri = `${defaultUri}/${subcontractorID}`;
    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SUBCONTRACTOR_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SUBCONTRACTOR_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const SUBCONTRACTOR_GETALL_START = 'SUBCONTRACTOR_GETALL_START';
export const SUBCONTRACTOR_GETALL_SUCCESS = 'SUBCONTRACTOR_GETALL_SUCCESS';
export const SUBCONTRACTOR_GETALL_ERROR = 'SUBCONTRACTOR_GETALL_ERROR';

/**
 * getAll()
 * @param clientId
 * @param forceReload
 * @returns {Function}
 */
export function getAll(clientId, forceReload = false) {
  return (dispatch, getState) => {
    const { subcontractors } = getState().subcontractor;
    if (!forceReload) {
      if (subcontractors && subcontractors.length > 0) {
        return Promise.resolve(subcontractors);
      }
    }
    dispatch({ type: SUBCONTRACTOR_GETALL_START });
    const uri = `${defaultUri.replace('{clientId}', clientId)}`;
    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SUBCONTRACTOR_GETALL_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SUBCONTRACTOR_GETALL_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const SUBCONTRACTOR_CREATE_START = 'SUBCONTRACTOR_CREATE_START';
export const SUBCONTRACTOR_CREATE_SUCCESS = 'SUBCONTRACTOR_CREATE_SUCCESS';
export const SUBCONTRACTOR_CREATE_ERROR = 'SUBCONTRACTOR_CREATE_ERROR';

/**
 * Create a new subcontractor for the current client
 * create()
 * @param clientId
 * @param subcontractorData
 * @returns {function(*): Promise<any | never>}
 */

export function create(clientId, subcontractorData) {
  return (dispatch) => {
    dispatch({ type: SUBCONTRACTOR_CREATE_START, payload: subcontractorData });
    const uri = `${defaultUri.replace('{clientId}', clientId)}`;
    return axios().post(uri, subcontractorData).then((response) => {
      const { data } = response.data;
      dispatch({ type: SUBCONTRACTOR_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SUBCONTRACTOR_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const SUBCONTRACTOR_UPDATE_START = 'SUBCONTRACTOR_UPDATE_START';
export const SUBCONTRACTOR_UPDATE_SUCCESS = 'SUBCONTRACTOR_UPDATE_SUCCESS';
export const SUBCONTRACTOR_UPDATE_ERROR = 'SUBCONTRACTOR_UPDATE_ERROR';

/**
 * Update a subcontractor for the current client
 * update()
 * @param subcontractorId
 * @param subcontractorData
 * @returns {function(*): Promise<any | never>}
 */
export function update(subcontractorId, subcontractorData) {
  return (dispatch) => {
    dispatch({ type: SUBCONTRACTOR_UPDATE_START, userData: subcontractorData });
    const uri = `${defaultUri}/${subcontractorId}`;
    return axios().put(uri, subcontractorData).then((response) => {
      const { data } = response.data;
      dispatch({ type: SUBCONTRACTOR_UPDATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SUBCONTRACTOR_UPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SUBCONTRACTOR_DELETE_START = 'SUBCONTRACTOR_DELETE_START';
export const SUBCONTRACTOR_DELETE_SUCCESS = 'SUBCONTRACTOR_DELETE_SUCCESS';
export const SUBCONTRACTOR_DELETE_ERROR = 'SUBCONTRACTOR_DELETE_ERROR';

/**
 *
 * @param subcontractorData
 * @param deleteUsersAswell
 * @returns {function(*): *}
 */
export function remove(subcontractorData, deleteUsersAswell) {
  return (dispatch) => {
    dispatch({ type: SUBCONTRACTOR_DELETE_START });

    const uri = `${defaultUri}/${subcontractorData.subcontractorId}`;

    let config;
    if (deleteUsersAswell) {
      config = {
        params: { action: 'deleteAllAssignedUsers' },
      };
    } else {
      config = {
        params: { action: 'deleteLink' },
      };
    }

    return axios().delete(uri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SUBCONTRACTOR_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SUBCONTRACTOR_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
