import React, { useReducer } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import ModalDialog from '../../../Components/ModalDialog';
import SelectBox from '../../../Components/SelectBox';
import DatePickerInput from '../../../Components/DatePickerInput';
import { create } from '../../../Redux/Action/UserHolidayAction';
import {
  ERRORCODE_USER_HOLIDAY_ALREADY_EXISTING,
  ERRORCODE_USER_HOLIDAY_HAS_ORDER_ASSIGNED,
  ERRORCODE_USER_HOLIDAY_HAS_RECLAMATION_ASSIGNED,
  HOLIDAY_REASON_HOLIDAY,
  holidayReasonTypeOptions,
} from '../../../Library/Types';

const initialState = {
  userId: 0,
  startDate: undefined,
  endDate: undefined,
  reason: HOLIDAY_REASON_HOLIDAY,
  endDateError: false,
  startDateError: false,
  userIdError: false,
  hasError: false,
  hasOrderAssigned: false,
  hasReclamationAssigned: false,
  hasHolidayAssigned: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'userId':
      return { ...state, userId: action.payload };
    case 'startDate':
      return { ...state, startDate: action.payload };
    case 'endDate':
      return { ...state, endDate: action.payload };
    case 'reason':
      return { ...state, reason: action.payload };
    case 'userIdError':
      return { ...state, userIdError: true, hasError: true };
    case 'startDateError':
      return { ...state, startDateError: true, hasError: true };
    case 'endDateError':
      return { ...state, endDateError: true, hasError: true };
    case 'hasOrderAssigned':
      return { ...state, hasOrderAssigned: true, hasError: true };
    case 'hasReclamationAssigned':
      return { ...state, hasReclamationAssigned: true, hasError: true };
    case 'hasHolidayAssigned':
      return {
        ...state,
        hasHolidayAssigned: true,
        endDateError: true,
        startDateError: true,
        hasError: true,
      };
    case 'resetErrors':
      return {
        ...state,
        endDateError: false,
        startDateError: false,
        userIdError: false,
        hasError: false,
        hasOrderAssigned: false,
        hasReclamationAssigned: false,
        hasHolidayAssigned: false,
      };
    case 'reset':
      return { ...initialState };
    case 'init':
      return {
        ...initialState,
        startDate: action.payload.startDate,
        userId: action.payload.userId,
      };
    default:
      throw new Error();
  }
}

/**
 * AddModal()
 * @param props
 * @returns {*}
 * @constructor
 */
function AddModal(props) {
  const {
    users, onSave, visible, onClose, dispatchCreate, initialData,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectOptions, setSelectOptions] = React.useState([]);

  React.useEffect(() => {
    if (users) {
      const mapped = users.map((item) => ({
        caption: `${item.lastname}, ${item.firstname}`,
        key: item.userId,
      }));
      mapped.unshift({
        caption: 'Mitarbeiter wählen...',
        key: 0,
      });
      setSelectOptions(mapped);
    }
  }, [users]);

  React.useEffect(() => {
    if (initialData) {
      dispatch({ type: 'init', payload: initialData });
    }
  }, [initialData]);

  const handleClose = () => {
    dispatch({ type: 'reset' });
    onClose();
  };

  const handleOnSave = () => {
    let hasError = false;
    dispatch({ type: 'resetErrors' });

    if (state.userId === 0 || state.userId === undefined) {
      dispatch({ type: 'userIdError' });
      hasError = true;
    }
    if (state.startDate === undefined || state.startDate.length === 0) {
      dispatch({ type: 'startDateError' });
      hasError = true;
    }
    if (state.endDate === undefined || state.endDate.length === 0) {
      dispatch({ type: 'endDateError' });
      hasError = true;
    }
    if ((state.endDate !== undefined && state.endDate.length > 0)
      && ((state.startDate !== undefined) && (state.startDate.length > 0))
    ) {
      const start = moment(state.startDate, 'YYYY-MM-DD');
      const end = moment(state.endDate, 'YYYY-MM-DD');
      if (!end.isSameOrAfter(start)) {
        dispatch({ type: 'endDateError' });
        hasError = true;
      }
    }

    if (!hasError) {
      const start = moment(state.startDate, 'YYYY-MM-DD');
      const end = moment(state.endDate, 'YYYY-MM-DD');
      const days = end.diff(start, 'days') + 1;

      const data = {
        userId: state.userId,
        startDate: state.startDate,
        endDate: state.endDate,
        approved: true,
        reason: state.reason,
        days,
      };
      dispatchCreate(state.userId, data).then(() => {
        dispatch({ type: 'reset' });
        onSave();
      }).catch((error) => {
        if (error.data && error.data.errorCode) {
          if (error.data.errorCode === ERRORCODE_USER_HOLIDAY_ALREADY_EXISTING) {
            dispatch({ type: 'hasHolidayAssigned' });
          } else if (error.data.errorCode === ERRORCODE_USER_HOLIDAY_HAS_ORDER_ASSIGNED) {
            dispatch({ type: 'hasOrderAssigned' });
          } else if (error.data.errorCode === ERRORCODE_USER_HOLIDAY_HAS_RECLAMATION_ASSIGNED) {
            dispatch({ type: 'hasReclamationAssigned' });
          }
        }
      });
    }
  };

  const renderAlreadyAssignedHint = () => {
    if (state.hasHolidayAssigned) {
      return (
        <div className="alert alert-danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          Der Mitarbeiter hat im angegebenen Zeitraum bereits einen Urlaubseintrag!
        </div>
      );
    }
    if (state.hasOrderAssigned) {
      return (
        <div className="alert alert-danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          Der Mitarbeiter hat im angegebenen Zeitraum bereits einen Montageauftrag zugewiesen.
        </div>
      );
    }
    if (state.hasReclamationAssigned) {
      return (
        <div className="alert alert-danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          Der Mitarbeiter hat im angegebenen Zeitraum bereits einen Reklamationsauftrag zugewiesen.
        </div>
      );
    }
    return null;
  };

  return (
    <ModalDialog onClose={handleClose} visible={visible} confirmCaption="Speichern" onConfirm={handleOnSave}>
      <h5>Urlaub hinzufügen</h5>
      <hr />

      <div className="row">
        <SelectBox
          onChange={(value) => dispatch({ type: 'userId', payload: value })}
          data={selectOptions}
          label="Mitarbeiter"
          rowClass="col-lg-6"
          selected={(state.userId) || ''}
          hasError={state.userIdError}
        />
        <SelectBox
          onChange={(value) => dispatch({ type: 'reason', payload: value })}
          data={holidayReasonTypeOptions}
          label="Grund / Typ"
          selected={(state.reason) || ''}
          rowClass="col-lg-6"
        />
        <DatePickerInput
          onChange={(value) => dispatch({ type: 'startDate', payload: value })}
          label="Startdatum (inklusive)"
          rowClass="col-lg-6"
          hasError={state.startDateError}
          minDate={moment().toDate()}
          initialValue={(state.startDate) ? moment(state.startDate).toDate() : null}
        />
        <DatePickerInput
          onChange={(value) => dispatch({ type: 'endDate', payload: value })}
          label="Enddatum (inklusive)"
          rowClass="col-lg-6"
          hasError={state.endDateError}
          minDate={(!state.startDate) ? moment().toDate() : moment(state.startDate).toDate()}
        />
      </div>

      {renderAlreadyAssignedHint()}
    </ModalDialog>
  );
}

AddModal.propTypes = {
  dispatchCreate: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Array),
  onSave: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  initialData: PropTypes.instanceOf(Object),
};

AddModal.defaultProps = {
  visible: false,
  users: [],
  initialData: null,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreate: (userId, data) => dispatch(create(userId, data)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(AddModal);
