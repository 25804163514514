import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatchGetGroups } from '../../../Redux/Action/GroupAction';
import CustomSwitch from '../../../Components/CustomSwitch';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  
  >p {
    margin-bottom: 30px;
  }
  
  .GroupItem {
    margin-bottom: 15px;
    display: flex;
    flex: 1;
    flex-direction: row;
    cursor: pointer;

    .Switch {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding-right: 20px;
    }
    
    .Right {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      
      .Caption {
        color: #000000;
      }
      
      .Description {
        font-size: 14px;
        color: ${(props) => props.theme.color.textGray}
      }
    }
  }
`;

const descriptions = {
  ADM: 'Uneingeschränkter Zugriff auf alle Funktionen',
  MON: 'Dieser Benutzer kann Aufträgen und Services als Monteur hinzugefügt werden und Berichte in der hey.kitchen '
    + 'App ausführen',
  VER: 'Zugriff auf alle relevanten Funktionen der Verwaltung',
  VEK: 'Kann Aufträge erstellen und verwalten. Darf keine Termine eintragen oder Monteure zuweisen',
  REG: 'Hat Zugriff auf Rechnungen, Firmendaten und kann Aufträge einsehen',
  DIS: 'Kann Aufträge sehen, terminieren und Monteure zuweisen',
};

/**
 * GroupItem()
 * @param props
 * @returns {*}
 * @constructor
 */
function GroupItem(props) {
  const {
    item, onChange, selectedItems,
  } = props;
  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => {
    const filtered = selectedItems.filter((param) => param.groupId === item.groupId);
    setSelected(filtered.length > 0);
  }, [item, selectedItems]);

  return (
    <div className="GroupItem">
      <div className="Switch">
        <CustomSwitch
          onChange={(value) => onChange(item, value)}
          checked={selected}
        />
      </div>
      <div
        className="Right"
        onClick={() => onChange(item, !selected)}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
      >
        <div className="Caption">{`${item.name} (${item.initials})`}</div>
        <div className="Description">{descriptions[item.initials]}</div>
      </div>
    </div>
  );
}

GroupItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedItems: PropTypes.instanceOf(Array).isRequired,
};

/**
 * GroupSelect()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function GroupSelect(props) {
  const { onChange, initialSelected } = props;
  const dispatchGetGroups = useDispatchGetGroups();
  const [groups, setGroups] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);

  // Just until all VER Groups are removed. Show only if the user has the group, otherwise don't show group VER
  const setGroupsWithoutVerwaltung = React.useCallback((groupsParams) => {
    const merged = groupsParams.filter((item) => item.initials !== 'VER');
    setGroups(merged);
  }, []);

  useEffect(() => {
    dispatchGetGroups().then((response) => {
      if (initialSelected && initialSelected.length > 0) {
        const hasGroup = initialSelected.filter((item) => item.groupInitials === 'VER');
        if (hasGroup && hasGroup.length > 0) {
          setGroups(response);
        } else {
          setGroupsWithoutVerwaltung(response);
        }
      } else {
        setGroupsWithoutVerwaltung(response);
      }
    });
  }, [dispatchGetGroups, initialSelected, setGroupsWithoutVerwaltung]);

  useEffect(() => {
    setSelectedGroups(initialSelected.map((item) => item));
  }, [initialSelected]);

  const handleGroupChange = (itemData, value) => {
    if (value) {
      const added = [...selectedGroups, itemData];
      setSelectedGroups(added);
      onChange(added);
    } else {
      const removed = selectedGroups.filter((item) => item.groupId !== itemData.groupId);
      setSelectedGroups(removed);
      onChange(removed);
    }
  };

  if (groups) {
    return (
      <Container>
        <h6>Gruppen</h6>
        <p>
          Über die Gruppenzuweisung bestimmen Sie, was der Benutzer im System sehen und verwalten kann.
        </p>

        {groups.map((item) => (
          <GroupItem
            key={item.groupId}
            item={item}
            onChange={(itemData, value) => handleGroupChange(itemData, value)}
            selectedItems={selectedGroups}
          />
        ))}
      </Container>
    );
  }
  return null;
}

GroupSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialSelected: PropTypes.instanceOf(Object),
};

GroupSelect.defaultProps = {
  initialSelected: [],
};
