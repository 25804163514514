import * as Redux from 'react-redux';
import React from 'react';
import axios from '../../../../Library/Axios';
import { OBJECT_DATE_TYPE_STORAGE } from '../../../../Library/Types';
import { applyFiltersToObjectArray } from '../../../../Library/Functions';

const defaultUri = '/system/order';

const TARGET_INTERNAL = 'internal';
const TARGET_EXTERNAL = 'external';

export const SYSTEM_OBJECTS_GET_LIST_START = 'SYSTEM_OBJECTS_GET_LIST_START';
export const SYSTEM_OBJECTS_GET_LIST_SUCCESS = 'SYSTEM_OBJECTS_GET_LIST_SUCCESS';
export const SYSTEM_OBJECTS_GET_LIST_ERROR = 'SYSTEM_OBJECTS_GET_LIST_ERROR';

/**
 * useDispatchGetObjectView()
 * @param target
 * @returns {function(): *}
 */
export const useDispatchSystemGetObjectView = (target) => {
  const dispatch = Redux.useDispatch();
  const uri = 'system/view/object';
  const config = { params: { action: target } };

  return React.useCallback(() => {
    dispatch({ type: SYSTEM_OBJECTS_GET_LIST_START, payload: { target } });
    return axios().get(uri, config).then((response) => {
      const objects = response.data.data.filter((object) => object.dateType !== OBJECT_DATE_TYPE_STORAGE);
      const lastUpdated = new Date().getTime();
      dispatch({ type: SYSTEM_OBJECTS_GET_LIST_SUCCESS, payload: { target, objects, lastUpdated } });
      return Promise.resolve(objects);
    }).catch((error) => {
      dispatch({ type: SYSTEM_OBJECTS_GET_LIST_ERROR, payload: { target, error } });
      return Promise.reject(error);
    });
  }, [uri, dispatch, config, target]);
};

export const SYSTEM_OBJECTS_UPDATE_FILTERS = 'SYSTEM_OBJECTS_UPDATE_FILTERS';

/**
 * updateFilters
 * @param target
 * @param newFilter
 * @returns {(function(*): void)|*}
 */
export function updateFilters(target, newFilter) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_OBJECTS_UPDATE_FILTERS, payload: { target, newFilter } });
  };
}

/**
 * Update internal filters
 * @param filters
 * @returns {(function(*): void)|*}
 */
export function updateInternalFilters(filters) {
  return updateFilters(TARGET_INTERNAL, filters);
}

/**
 * Update internal filters
 * @param filters
 * @returns {(function(*): void)|*}
 */
export function updateExternalFilters(filters) {
  return updateFilters(TARGET_EXTERNAL, filters);
}

export const SYSTEM_OBJECTS_APPLY_FILTERS_START = 'SYSTEM_OBJECTS_APPLY_FILTERS_START';
export const SYSTEM_OBJECTS_APPLY_FILTERS_SUCCESS = 'SYSTEM_OBJECTS_APPLY_FILTERS_SUCCESS';
export const SYSTEM_OBJECTS_APPLY_FILTERS_FINISHED = 'SYSTEM_OBJECTS_APPLY_FILTERS_FINISHED';

/**
 * Apply all filters to objects of target
 * @param target
 * @param objects
 * @param filters
 * @param prevFilteredObjects
 * @returns {(function(*): void)|*}
 */
export function applyFilters(target, objects, filters, prevFilteredObjects) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_OBJECTS_APPLY_FILTERS_START, payload: { target } });
    const filteredObjects = applyFiltersToObjectArray(objects, filters);

    if (filteredObjects
        && prevFilteredObjects
        && filteredObjects.length === prevFilteredObjects.length
        && filteredObjects.every(
          (object, index) => JSON.stringify(object) === JSON.stringify(prevFilteredObjects[index]),
        )) {
      dispatch({ type: SYSTEM_OBJECTS_APPLY_FILTERS_FINISHED, payload: { target } });
    } else {
      dispatch({ type: SYSTEM_OBJECTS_APPLY_FILTERS_SUCCESS, payload: { target, filteredObjects } });
    }
  };
}

/**
 * Apply internal filters to internal objects
 * @param objects
 * @param filters
 * @param prevFilteredObjects
 * @returns {(function(*): void)|*}
 */
export function applyInternalFilters(objects, filters, prevFilteredObjects) {
  return applyFilters(TARGET_INTERNAL, objects, filters, prevFilteredObjects);
}

/**
 * Apply external filters to external objects
 * @param objects
 * @param filters
 * @param prevFilteredObjects
 * @returns {(function(*): void)|*}
 */
export function applyExternalFilters(objects, filters, prevFilteredObjects) {
  return applyFilters(TARGET_EXTERNAL, objects, filters, prevFilteredObjects);
}

export const SYSTEM_ORDER_ASSIGNEPRICE_START = 'SYSTEM_ORDER_ASSIGNEPRICE_START';
export const SYSTEM_ORDER_ASSIGNEPRICE_SUCCESS = 'SYSTEM_ORDER_ASSIGNEPRICE_SUCCESS';
export const SYSTEM_ORDER_ASSIGNEPRICE_ERROR = 'SYSTEM_ORDER_ASSIGNEPRICE_ERROR';

/**
 * assignePrice()
 * @param order
 * @param dealerPrice
 * @param monteurPrice
 * @returns {function(*): Promise<unknown>}
 */
export function assignePrice(order, dealerPrice, monteurPrice) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_ORDER_ASSIGNEPRICE_START });

    const config = {
      params: { action: 'assignePrice' },
    };

    const uri = `${defaultUri}/${order.orderId}`;

    return axios().put(uri, { dealerPrice, monteurPrice }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_ORDER_ASSIGNEPRICE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_ORDER_ASSIGNEPRICE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_ORDER_CALCULATE_START = 'SYSTEM_ORDER_CALCULATE_START';
export const SYSTEM_ORDER_CALCULATE_SUCCESS = 'SYSTEM_ORDER_CALCULATE_SUCCESS';
export const SYSTEM_ORDER_CALCULATE_ERROR = 'SYSTEM_ORDER_CALCULATE_ERROR';

/**
 * calculate()
 * @param orderId
 * @returns {function(*): Promise<unknown>}
 */
export function calculate(orderId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_ORDER_CALCULATE_START });

    const config = {
      params: { action: 'calculate' },
    };

    const uri = `${defaultUri}/${orderId}`;

    return axios().get(uri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_ORDER_CALCULATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_ORDER_CALCULATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_ORDER_DELETE_START = 'SYSTEM_ORDER_DELETE_START';
export const SYSTEM_ORDER_DELETE_SUCCESS = 'SYSTEM_ORDER_DELETE_SUCCESS';
export const SYSTEM_ORDER_DELETE_ERROR = 'SYSTEM_ORDER_DELETE_ERROR';

/**
 * remove()
 * @param orderId
 * @returns {function(*): Promise<unknown>}
 */
export function remove(orderId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_ORDER_DELETE_START });

    const uri = `${defaultUri}/${orderId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_ORDER_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_ORDER_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_ORDER_RESTOREEXIPRED_START = 'SYSTEM_ORDER_RESTOREEXIPRED_START';
export const SYSTEM_ORDER_RESTOREEXIPRED_SUCCESS = 'SYSTEM_ORDER_RESTOREEXIPRED_SUCCESS';
export const SYSTEM_ORDER_RESTOREEXIPRED_ERROR = 'SYSTEM_ORDER_RESTOREEXIPRED_ERROR';

/**
 * restoreExpired()
 * @param orderId
 * @returns {function(*): Promise<unknown>}
 */
export function restoreExpired(orderId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_ORDER_RESTOREEXIPRED_START });

    const config = {
      params: { action: 'restoreExpired' },
    };

    const uri = `${defaultUri}/${orderId}`;

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_ORDER_RESTOREEXIPRED_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_ORDER_RESTOREEXIPRED_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_ORDER_APPROVEOFFER_START = 'SYSTEM_ORDER_APPROVEOFFER_START';
export const SYSTEM_ORDER_APPROVEOFFER_SUCCESS = 'SYSTEM_ORDER_APPROVEOFFER_SUCCESS';
export const SYSTEM_ORDER_APPROVEOFFER_ERROR = 'SYSTEM_ORDER_APPROVEOFFER_ERROR';

/**
 * approveOffer()
 * @param orderId
 * @param offerId
 * @param dealerPrice
 * @returns {function(*): Promise<unknown>}
 */
export function approveOffer(orderId, offerId, dealerPrice) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_ORDER_APPROVEOFFER_START });

    const uri = `${defaultUri}/${orderId}/offer/${offerId}`;

    return axios().put(uri, { dealerPrice }).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_ORDER_APPROVEOFFER_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_ORDER_APPROVEOFFER_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_ORDER_DELETEOFFER_START = 'SYSTEM_ORDER_DELETEOFFER_START';
export const SYSTEM_ORDER_DELETEOFFER_SUCCESS = 'SYSTEM_ORDER_DELETEOFFER_SUCCESS';
export const SYSTEM_ORDER_DELETEOFFER_ERROR = 'SYSTEM_ORDER_DELETEOFFER_ERROR';

/**
 * deleteOffer()
 * @param orderId
 * @param offerId
 * @returns {function(*): Promise<unknown>}
 */
export function deleteOffer(orderId, offerId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_ORDER_DELETEOFFER_START });

    const uri = `${defaultUri}/${orderId}/offer/${offerId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_ORDER_DELETEOFFER_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_ORDER_DELETEOFFER_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_ORDER_RESETSTATE_START = 'SYSTEM_ORDER_RESETSTATE_START';
export const SYSTEM_ORDER_RESETSTATE_SUCCESS = 'SYSTEM_ORDER_RESETSTATE_SUCCESS';
export const SYSTEM_ORDER_RESETSTATE_ERROR = 'SYSTEM_ORDER_RESETSTATE_ERROR';

/**
 * resetState()
 * @param orderId
 * @param state
 * @returns {function(*): *}
 */
export function resetState(orderId, state) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_ORDER_RESETSTATE_START });

    const config = {
      params: {
        action: 'resetState',
      },
    };
    const uri = `${defaultUri}/${orderId}`;

    return axios().put(uri, { state }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_ORDER_RESETSTATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_ORDER_RESETSTATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
