/* eslint-disable jsx-a11y/anchor-is-valid, no-unused-vars */
import React, { useState } from 'react';
import { NavLink, useRouteMatch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import Panel from '../../Panel';
import ChangePasswordDialog from '../../../Pages/Profile/Component/ChangePasswordDialog';
import Alert from '../../Alert';
import { logout } from '../../../Redux/Action/AuthAction';
import ModalDialog from '../../ModalDialog';

/**
 * ProfileNavigation()
 * @returns {*}
 * @constructor
 */
function ProfileNavigation(props) {
  const { user, dispatchLogout } = props;
  const { url } = useRouteMatch();
  const [cookies, setCookie, removeCookie] = useCookies(['hk']);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangePasswordSuccess, setShowChangePasswordSuccess] = useState(false);
  const [askLogout, setAskLogout] = useState(false);

  const handlePasswordSuccess = () => {
    setShowChangePassword(false);
    setShowChangePasswordSuccess(true);
  };

  const getUsername = () => {
    if (user) {
      return `${user.firstname} ${user.lastname}`;
    }
    return null;
  };

  const handleLogout = () => {
    dispatchLogout();
    removeCookie('_ga_token');
  };

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            {getUsername()}
          </h6>
        </div>

        <hr />

        <ul>
          <li>
            <NavLink className="nav-link" to={`${url}/details`}>
              <i className="fas fa-user" />
              Meine Daten
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/holiday`}>
              <i className="fas fa-calendar-alt" />
              Kalender
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/task`}>
              <i className="fas fa-tasks" />
              Aufgaben
            </NavLink>
          </li>
          <li>
            <a
              tabIndex={-1}
              onKeyPress={() => {}}
              href={null}
              onClick={() => setShowChangePassword(true)}
              className="nav-link"
              style={{ cursor: 'pointer' }}
            >
              <i className="fas fa-key" />
              Passwort ändern
            </a>
          </li>
          <li>
            <a
              tabIndex={-1}
              onKeyPress={() => {}}
              href={null}
              onClick={() => setAskLogout(true)}
              className="nav-link"
              style={{ cursor: 'pointer' }}
            >
              <i className="fas fa-sign-out-alt" />
              Abmelden
            </a>
          </li>
        </ul>
      </div>

      <ChangePasswordDialog
        onClose={() => setShowChangePassword(false)}
        visible={showChangePassword}
        user={user}
        onSuccess={handlePasswordSuccess}
      />

      <Alert
        onClose={() => setShowChangePasswordSuccess(false)}
        type="success"
        visible={showChangePasswordSuccess}
      >
        Ihr Passwort wurde erfolgreich geändert.
        <br />
        Ab der nächsten Anmeldung auf der Webseite oder in der App müssen Sie Ihr neues Passwort verwendet!
      </Alert>

      <ModalDialog
        onClose={() => setAskLogout(false)}
        visible={askLogout}
        confirmCaption="Jetzt abmelden"
        centered
        width={600}
        onConfirm={() => handleLogout()}
      >
        <h5>Jetzt abmelden?</h5>
        <p>
          Bitte bestätigen Sie das Sie sich jetzt abmelden möchten. Sie können sich jederzeit wieder
          mit ihren persönlichen Logindaten anmelden.
        </p>
      </ModalDialog>
    </Panel>
  );
}

ProfileNavigation.propTypes = {
  dispatchLogout: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object),
};

ProfileNavigation.defaultProps = {
  user: null,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLogout: () => dispatch(logout()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ProfileNavigation));
