import React from 'react';

import './style.css';

const screenshot01 = require('../../../../Assets/Images/Backend/hey-kitchen-backend-order-details.png');

/**
 * WirStellenVor()
 * @returns {*}
 * @constructor
 */
export default function WirStellenVor() {
  return (
    <div className="WirStellenVor FullPageBox Light Center">
      <div style={{ marginTop: '-120px' }} id="pricing" />
      <div className="container d-flex flex-column">
        <h2 style={{ marginBottom: 0 }}>wir stellen vor: hey.kitchen</h2>
        <p style={{ marginTop: 0 }} className="hk-text-primary">
          DIGITALE KÜCHENMONTAGE PLATTFORM
        </p>
        <p>
          In hey.kitchen ist Ihr täglicher Küchenmontage-Prozess auf ganz einfache Art und Weise digital
          abgebildet. Das heißt: Ihre Mitarbeiter werden z.B. bei der Disposition oder der Ressourcenplanung
          unterstützt. hey.kitchen berechnet für Sie nicht nur die zu erwartende Montagezeit für jeden Auftrag,
          sondern erkennt auch freie Montagetage und zur Verfügung stehende Monteure. Und das Beste: die
          Anwendung funktioniert kinderleicht und kostet nur ein Bruchteil der Zeit, die Sie mir Ihr einsparen!
        </p>

        <div>
          <img src={screenshot01} alt="Backend1" style={{ width: '100%', marginTop: 80 }} />
        </div>

      </div>
    </div>
  );
}
