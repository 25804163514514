import React from 'react';
import PropTypes from 'prop-types';
import ObjectArrayFilter from '../../Filter/ObjectArrayFilter';

/**
 * ObjectTypeFilter(props)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectTypeFilter(props) {
  const {
    objects, onFilterChange,
  } = props;

  const filter = 'orderType';
  const getFilterParameter = (object) => object.orderType;
  const getCaption = (option, count) => `${(option === 'OBJECT') ? 'Objektauftrag' : 'Einzelauftrag'} (${count})`;

  return (
    <ObjectArrayFilter
      objectsArray={objects}
      getFilterParameter={getFilterParameter}
      getCaption={getCaption}
      filter={filter}
      onFilterChange={onFilterChange}
    />
  );
}

ObjectTypeFilter.propTypes = {
  objects: PropTypes.instanceOf(Array).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

ObjectTypeFilter.defaultProps = {};
