import PropTypes from 'prop-types';
import React from 'react';

import './style.css';

/**
 * InfoBox()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function InfoBox(props) {
  const {
    icon, title, text, style,
  } = props;

  const renderIcons = () => {
    if (Array.isArray(icon)) {
      return icon.map((item) => <i className={item} />);
    }
    return <i className={icon} />;
  };

  return (
    <div className="InfoBox" style={style}>
      <div className="Icons">
        {renderIcons()}
      </div>
      <div className="Caption text-center">
        {title}
      </div>
      <div className="Text">
        {text}
      </div>
    </div>
  );
}

InfoBox.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Array)]).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  style: PropTypes.instanceOf(Object),
};

InfoBox.defaultProps = {
  style: {},
  text: null,
};
