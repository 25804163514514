import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import ModalDialog from '../ModalDialog';
import KeyValueEditRow from '../KeyValueEditRow';
import { taskPrioCaption, taskPrioOptions, timeOptions } from '../../Library/Types';
import { getList, update } from '../../Redux/Action/User/TaskAction';
import { getAll } from '../../Redux/Action/UserAction';
import { formatDate, formatTime } from '../../Library/Functions';

const defaultState = {
  createdUserId: null,
  assignedUserId: null,
  title: '',
  description: '',
  dueDate: null,
  dueTime: null,
  priority: 4,
  hasChanges: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'title': return { ...state, title: action.payload, hasChanges: true };
    case 'description': return { ...state, description: action.payload, hasChanges: true };
    case 'dueDate': return { ...state, dueDate: action.payload, hasChanges: true };
    case 'dueTime': return { ...state, dueTime: action.payload, hasChanges: true };
    case 'priority': return { ...state, priority: action.payload, hasChanges: true };
    case 'assignedUserId': {
      if (action.payload === 0) {
        return { ...state, assignedUserId: state.createdUserId, hasChanges: true };
      }
      return { ...state, assignedUserId: action.payload, hasChanges: true };
    }

    case 'clear': return { ...defaultState };

    case 'init': {
      return { ...action.payload, hasChanges: false };
    }

    default:
      throw new Error();
  }
}

/**
 * CreateTaskModal()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function EditTaskModal(props) {
  const {
    visible, onClose, dispatchUpdate, user, dispatchGetList, dispatchGetAllUsers, users, task,
  } = props;
  const [state, dispatch] = React.useReducer(reducer, defaultState, () => (defaultState));
  const [timeOptionsModified, setTimeOptionsModified] = React.useState(timeOptions);
  const [loading, setLoading] = React.useState(false);
  const [selectOptionUsers, setSelectOptionUser] = useState([]);
  const [isEditable, setIsEditable] = React.useState(true);

  React.useEffect(() => {
    if (task) {
      dispatch({ type: 'init', payload: task });
      setIsEditable((task.createdUserId === user.userId));
    }
  }, [task, user.userId]);

  React.useEffect(() => {
    dispatchGetAllUsers();
  }, [dispatchGetAllUsers]);

  React.useEffect(() => {
    if (users && users.length > 0) {
      const options = users.map((item) => ({
        value: item.userId,
        label: `${item.lastname}, ${item.firstname} (${item.initials})`,
      }));
      options.unshift({ label: 'Zuweisung aufheben', value: 0 });
      setSelectOptionUser(options);
    }
  }, [users]);

  React.useEffect(() => {
    const newTime = [...timeOptions];
    newTime.splice(0, 1);
    setTimeOptionsModified(newTime);
  }, []);

  const getAssignedUserName = () => {
    if (users) {
      if (state.assignedUserId !== state.createdUserId) {
        const userParam = users.filter((item) => item.userId === state.assignedUserId)[0];
        if (userParam) {
          return `${userParam.lastname}, ${userParam.firstname}`;
        }
      }
    }
    return null;
  };

  const handleClose = () => {
    dispatch({ type: 'clear' });
    onClose();
  };

  const handleUpdate = () => {
    setLoading(true);
    const mapped = { ...state, createdUserId: user.userId };

    dispatchUpdate(user, mapped).then(() => dispatchGetList(user).then(() => {
      handleClose();
      setLoading(false);
    })).catch(() => setLoading(false));
  };

  const renderAssigneeHint = () => {
    if (users) {
      if (state.assignedUserId !== state.createdUserId && state.assignedUserId === user.userId) {
        const userParam = users.filter((item) => item.userId === state.createdUserId)[0];

        return (
          <div className="col-lg-12">
            <small className="text-muted">
              <i className="fas fa-info-circle" style={{ marginRight: 6 }} />
              Aufgabe zugewiesen von
              <span>{` "${userParam.firstname} ${userParam.lastname}" `}</span>
              . Sie können keine Änderungen an der Aufgabe vornehmen.
            </small>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <ModalDialog
      onClose={handleClose}
      visible={visible}
      confirmCaption="Änderungen speichern"
      confirmClass="btn-success"
      onConfirm={handleUpdate}
      disableConfirm={loading || !state.hasChanges}
      hideConfirmButton={!isEditable}
      closeCaption="Schließen"
    >
      <h5>Aufgabe</h5>

      <hr />

      <div className="row">
        <div className="col-lg-12">
          <KeyValueEditRow
            onChange={(isEditable) ? (data) => dispatch({ type: 'title', payload: data }) : null}
            displayText={state.title}
            initialValue={state.title}
            title="Aufgabentitel"
            autoFocus
            placeholder="Kurzer Titel der Aufgabe"
            className="col-lg-12"
          />
        </div>
        <div className="col-lg-12">
          <KeyValueEditRow
            title="Beschreibung / Weitere Informationen"
            onChange={(isEditable) ? (data) => dispatch({ type: 'description', payload: data }) : null}
            displayText={state.description}
            initialValue={state.description}
            rows={4}
            placeholder="Weitere Informationen oder Beschreibungen zur Aufgabe"
            rowClass="col-lg-12"
            type="textarea"
          />
        </div>

        <div className="col-lg-6">
          <KeyValueEditRow
            title="Fällig am"
            onChange={(isEditable) ? (data) => dispatch({ type: 'dueDate', payload: data }) : null}
            displayText={formatDate(state.dueDate)}
            initialValue={state.dueDate}
            rowClass="col-lg-6"
            placeholder="Aufgabe fällig am..."
            rightIcon="fas fa-calendar-alt"
            type="date"
            datePickerMinDate={moment().toDate()}
          />
        </div>

        <div className="col-lg-6">
          <KeyValueEditRow
            title="Fällig um"
            onChange={(isEditable) ? (data) => dispatch({ type: 'dueTime', payload: data }) : null}
            displayText={(state.dueTime) ? formatTime(state.dueTime) : ''}
            initialValue={state.dueTime}
            placeholder="Aufgabe fällig um..."
            rowClass="col-lg-6"
            type="select"
            selectData={timeOptionsModified}
          />
        </div>

        <div className="col-lg-6">
          <KeyValueEditRow
            title="Priorität"
            displayText={taskPrioCaption[state.priority]}
            initialValue={state.priority}
            onChange={(isEditable) ? (data) => dispatch({ type: 'priority', payload: data }) : null}
            placeholder="Priorität wählen..."
            rowClass="col-lg-6"
            type="select"
            selectData={taskPrioOptions}
          />
        </div>

        <div className="col-lg-6">
          <KeyValueEditRow
            title="Zugewiesen an"
            displayText={getAssignedUserName()}
            initialValue={state.assignedUserId}
            onChange={(isEditable) ? (data) => dispatch({ type: 'assignedUserId', payload: data }) : null}
            placeholder="Benutzer zuweisen..."
            rowClass="col-lg-6"
            type="select"
            selectData={selectOptionUsers}
          />
        </div>

        {renderAssigneeHint()}
      </div>
    </ModalDialog>
  );
}

EditTaskModal.propTypes = {
  dispatchUpdate: PropTypes.func.isRequired,
  dispatchGetList: PropTypes.func.isRequired,
  dispatchGetAllUsers: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  users: PropTypes.instanceOf(Array),
  task: PropTypes.instanceOf(Object),
};

EditTaskModal.defaultProps = {
  visible: false,
  users: null,
  task: null,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
    users: store.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdate: (user, task) => dispatch(update(user, task)),
    dispatchGetList: (user) => dispatch(getList(user)),
    dispatchGetAllUsers: () => dispatch(getAll()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(EditTaskModal));
