import React, { useReducer } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import ModalDialog from '../../../../../Components/ModalDialog';
import Input from '../../../../../Components/Input';
import SelectBox from '../../../../../Components/SelectBox';
import { checkAndFormatPrice, formatDate, isValidFloatNumberFormat } from '../../../../../Library/Functions';
import DatePickerInput from '../../../../../Components/DatePickerInput';
import { calculateWithOrderId } from '../../../../../Redux/Action/OrderAction';
import { ORDERTYPE_OBJECT, ORDERTYPE_SINGLE } from '../../../../../Library/Types';

const initialState = {
  price: null,
  addressId: null,
  dates: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'price': return { ...state, price: checkAndFormatPrice(action.payload) };
    case 'addressId': return { ...state, addressId: action.payload };
    case 'addDate': {
      if (state.dates.indexOf(action.payload) === -1) {
        const merged = [...state.dates];
        merged.push(action.payload);
        return { ...state, dates: merged };
      }
      return { ...state };
    }
    case 'deleteDate': {
      const merged = state.dates.filter((item) => item !== action.payload);
      return { ...state, dates: merged };
    }

    case 'reset': return { ...initialState };
    default:
      throw new Error();
  }
}

/**
 * AddOfferDialog()
 * @param props
 * @constructor
 */
function AddOfferDialog(props) {
  const {
    order, onClose, onSave, visible, addresses, dispatchCalculate,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [priceError, setPriceError] = React.useState(false);
  const [addressIdError, setAddressIdError] = React.useState(false);
  const [addressOptions, setAddressOptions] = React.useState([]);
  const [calculationResult, setCalculationResult] = React.useState(null);

  React.useEffect(() => {
    if (visible && order) {
      dispatchCalculate(order.orderId).then((response) => setCalculationResult(response));
    }
  }, [dispatchCalculate, order, visible]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'reset' });
    }
  }, [visible]);

  React.useEffect(() => {
    if (addresses) {
      const options = addresses.map((item) => ({ key: item.addressId, caption: item.name }));
      options.unshift({
        key: 0,
        caption: 'Bitte Lagerort wählen...',
      });
      setAddressOptions(options);
    }
  }, [addresses]);

  const handleSave = () => {
    setAddressIdError(false);
    setPriceError(false);
    let hasError = false;

    if (order.optionStorage && (!state.addressId || state.addressId === '0')) {
      setAddressIdError(true);
      hasError = true;
    }
    if (!state.price || state.price === '' || !isValidFloatNumberFormat(state.price)) {
      setPriceError(true);
      hasError = true;
    }

    if (!hasError) {
      onSave({
        price: state.price.replace(',', '.'),
        addressId: state.addressId,
        dates: state.dates,
      });
    }
  };

  const renderPriceHint = () => {
    if (order.type === ORDERTYPE_SINGLE && calculationResult) {
      if (state.price > 100) {
        const priceDiff = parseFloat(state.price) - parseFloat(calculationResult.monteurPrice);
        const percent = (priceDiff * 100) / parseFloat(calculationResult.monteurPrice);

        if (percent > 0 && percent < 5) {
          return (
            <div className="alert alert-info align-items-center d-flex">
              <i className="far fa-smile" style={{ marginRight: 15, fontSize: 26 }} />
              Ihr Angebotspreis ist OK!
            </div>
          );
        }
        if (percent >= 5 && percent < 20) {
          return (
            <div className="alert alert-warning align-items-center d-flex">
              <i className="far fa-meh" style={{ marginRight: 15, fontSize: 26 }} />
              Ihr Angebotspreis ist im oberen Durchschnitt
            </div>
          );
        }
        if (percent >= 20) {
          return (
            <div className="alert alert-danger align-items-center d-flex">
              <i className="far fa-flushed" style={{ marginRight: 15, fontSize: 26 }} />
              Ihr Angebotspreis ist weit über dem Durchschnitt
            </div>
          );
        }
        return (
          <div className="alert alert-success align-items-center d-flex">
            <i className="far fa-grin-stars" style={{ marginRight: 15, fontSize: 26 }} />
            Ihr Angebotspreis ist gut!
          </div>
        );
      }
      return (
        <div className="alert alert-secondary align-items-center d-flex">
          <i className="fas fa-question-circle" style={{ marginRight: 15, fontSize: 26 }} />
          Wartet auf die Eingabe Ihres Angebots
        </div>
      );
    }
    return null;
  };

  const renderStorage = () => {
    if (order.optionStorage) {
      return (
        <>
          <p>
            Der Auftrag erfordert eine Lagerung der Lieferung. Bitte wählen Sie einen passenden Lagerorte damit der
            Auftraggeber die Küche dort hinliefern kann, sollte der Auftrag Ihnen zugewiesen werden.
          </p>
          <div className="row">
            <SelectBox
              onChange={(value) => dispatch({ type: 'addressId', payload: value })}
              data={addressOptions}
              hasError={addressIdError}
              selected={(state.addressId === null) ? '' : state.addressId}
              rowClass="col-lg-12"
            />
          </div>
        </>
      );
    }
    return null;
  };

  const renderAlternativeDates = () => {
    if (order.type === ORDERTYPE_OBJECT) {
      return null;
    }

    const pickers = state.dates.map((date) => (
      <DatePickerInput
        onChange={() => {}}
        initialValue={date}
        disabled
        rightIcon="fas fa-trash"
        onAction={() => dispatch({ type: 'deleteDate', payload: date })}
        key={date}
        rowClass="col-lg-6"
      />
    ));

    if (state.dates.length < 4) {
      pickers.push((
        <DatePickerInput
          onChange={(value) => dispatch({ type: 'addDate', payload: value })}
          placeholder="(Optional) Alternatives Datum wählen..."
          minDate={moment().toDate()}
          key={Math.random()}
          rowClass="col-lg-12"
        />
      ));
    }
    return (
      <div style={{ marginTop: 40 }}>
        <h6>Alternative Montagetermine</h6>
        <p>
          Sollten Sie am angefragten Montagedatum keine Zeit haben können Sie bis zu 4 alternative Termine vorschlagen.
          Bitte beachten Sie das der Termin mit Annahme des Angebots für Sie verbindlich wird.
        </p>
        <div className="row">
          {pickers}
        </div>
      </div>
    );
  };

  const renderDate = () => {
    if (order.type === ORDERTYPE_SINGLE) {
      return (
        <div>
          <div>Wunschtermin:</div>
          <div>{formatDate(order.deliveryDateStart)}</div>
        </div>
      );
    }
    return (
      <div className="d-flex flex-row">
        <div className="flex-grow-1">
          Wunschtermin:
        </div>
        <div>
          {`${formatDate(order.deliveryDateStart)} bis ${formatDate(order.deliveryDateEnd)}`}
        </div>
      </div>
    );
  };

  return (
    <ModalDialog
      onClose={onClose}
      visible={visible}
      confirmCaption="Angebot verbindlich abgeben"
      onConfirm={handleSave}
    >
      <h5>Individuelles Angebot / Termin abgeben</h5>
      <p>
        Bitte geben Sie einen Preis für Ihr individuelles Angebot an. Sie können optional Alternativtermine für Ihr
        Angebot hinterlegen, sollten Sie am geforderten Montagetermin keine Zeit haben. Der Auftraggeber kann dann
        einen der Termine auswählen. Geben Sie keinen Alternativtermin an, wird immer der Auftragstermin für Ihr
        Angebot angenommen. Bitte beachten Sie das die Abgabe Ihres Angebots
        verbindlich ist. Sollte Ihr Angebot angenommen werden verpflichten Sie sich zur Durchführung des Auftrags
        zu dem vom Ihnen angegebenen Preis!
      </p>

      <hr />

      <h5>
        <strong>
          {renderDate()}
        </strong>
      </h5>

      <Input
        onChange={(value) => dispatch({ type: 'price', payload: value })}
        label="Ihr Angebotspreis (netto)"
        rightIcon="fas fa-euro-sign"
        autoFocus
        value={state.price}
        hasError={priceError}
        placeholder="Beispiel: 5800.50"
      />
      {renderPriceHint()}
      <div>
        <small style={{ fontSize: '16px' }} className="text-muted">
          *Nacharbeiten / Reklamationsarbeiten: 45 Euro (Anfahrt) + 39 Euro pro Monteur pro Stunde
        </small>
      </div>
      {renderAlternativeDates()}

      {renderStorage()}
    </ModalDialog>
  );
}

AddOfferDialog.propTypes = {
  dispatchCalculate: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  addresses: PropTypes.instanceOf(Array),
};

AddOfferDialog.defaultProps = {
  visible: false,
  addresses: null,
};

function mapStoreToProps(store) {
  return {
    addresses: store.clientAddress.addresses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCalculate: (orderId) => dispatch(calculateWithOrderId(orderId)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(AddOfferDialog);
