import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {
  combinedStateCaption,
  combinedStateIcon,
  combinedStateType,
  useCombinedState,
} from '../../Library/StateTypes';
import StatusBadge from '../StatusBadge';

/**
 * CombinedOrderBadge()
 * This badge show the state of the order or of the object if it is a single order depending on the current order state.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CombinedOrderBadge(props) {
  const {
    orderState, objectDateState, isInternal, containerStyle, visible, alignContainer, iconOnly,
    useMediaQuery, systemView,
  } = props;

  const combineStates = useCombinedState();
  const combinedState = combineStates(orderState, objectDateState, isInternal, systemView);

  const getIcon = () => combinedStateIcon[combinedState];
  const getCaption = () => ((!iconOnly) ? combinedStateCaption[combinedState] : null);
  const getType = () => combinedStateType[combinedState];

  return (
    <StatusBadge
      type={getType()}
      icon={getIcon()}
      visible={visible}
      useMediaQuery={useMediaQuery}
      containerStyle={containerStyle}
      iconOnly={iconOnly}
      alignContainer={alignContainer}
    >
      {getCaption()}
    </StatusBadge>
  );
}

CombinedOrderBadge.propTypes = {
  orderState: PropTypes.number.isRequired,
  objectDateState: PropTypes.number.isRequired,
  isInternal: PropTypes.bool,
  containerStyle: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  alignContainer: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  iconOnly: PropTypes.bool,
  useMediaQuery: PropTypes.bool,
  systemView: PropTypes.bool,
};

CombinedOrderBadge.defaultProps = {
  isInternal: false,
  containerStyle: {},
  visible: true,
  alignContainer: 'flex-start',
  iconOnly: false,
  useMediaQuery: false,
  systemView: false,
};
