/* eslint-disable react/prop-types, jsx-a11y/anchor-is-valid, no-script-url */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { create, getList, remove } from '../../../Redux/Action/ClientAddressAction';
import AddressPanel from '../Component/AddressPanel';
import Button from '../../../Components/Button';
import Add from './Add';
import Panel from '../../../Components/Panel';
import Update from './Update';

/**
 * List()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function List(props) {
  const {
    addresses, deleteAddress, getAddressList, dispatchCreate, client, user,
  } = props;
  const [addVisible, setAddVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    getAddressList();
  }, [getAddressList]);

  const onSaved = () => {
    setAddVisible(false);
  };

  const handleDelete = (address) => {
    deleteAddress(address);
  };

  const handleOnUpdate = () => {
    getAddressList();
    setSelectedItem(null);
  };

  const addCompanyAddress = () => {
    const address = {
      type: 'storageaddress',
      name: client.name,
      zip: client.zip,
      city: client.city,
      street: client.street,
      streetNo: client.streetNo,
      contactName: `${user.firstname} ${user.lastname}`,
      contactPhone: client.phone,
      comment: '',
      radius: 150,
    };

    dispatchCreate(address);
  };

  const renderContent = () => {
    if (addresses && addresses.length > 0) {
      return (
        <AddressPanel
          addresses={addresses}
          onDelete={(data) => handleDelete(data)}
          onEdit={(data) => setSelectedItem(data)}
          client={client}
        />
      );
    }

    if (client.zip && client.city && client.street && client.streetNo && client.phone) {
      return (
        <Panel marginBottom={25}>
          <div className="text-center">
            <i className="fas fa-info-circle text-info" style={{ fontSize: 30, marginBottom: 10 }} />
            <div>Sie haben noch keine Lageradresse angelegt.</div>
            <div>Wollen Sie jetzt Ihren Firmenadresse als Lageradresse hinterlegen?</div>

            <Button onClick={() => addCompanyAddress()} style={{ marginTop: 30 }}>
              Firmenadresse als Lageradresse eintragen
            </Button>
          </div>
        </Panel>
      );
    }

    return null;
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <Panel marginBottom={25}>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h4>
                Standorte / Lageradressen
              </h4>
              <p style={{ marginBottom: 0 }}>
                Hinterlegen Sie hier Ihre Standorte oder Lageradressen, um diese später einfach auswählen zu können!
              </p>
            </div>
            <div className="d-flex align-items-center">
              <Button onClick={() => setAddVisible(true)} className="pull-right">
                <i className="fas fa-plus" />
                Hinzufügen
              </Button>
            </div>
          </div>
        </Panel>

        {renderContent()}
      </div>

      <Add visible={addVisible} onClose={() => setAddVisible(false)} onSaved={() => onSaved()} />
      <Update
        visible={(selectedItem !== null)}
        initialData={selectedItem}
        onClose={() => setSelectedItem(null)}
        onChange={handleOnUpdate}
      />
    </div>
  );
}

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
    client: store.client.client,
    addresses: store.clientAddress.addresses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAddressList: () => dispatch(getList()),
    deleteAddress: (data) => dispatch(remove(data)),
    dispatchCreate: (addressData) => dispatch(create(addressData)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(List));
