import {
  MATERIAL_GETLIST_ERROR, MATERIAL_GETLIST_START, MATERIAL_GETLIST_SUCCESS,
} from '../Action/MaterialAction';

const defaultValues = {
  materials: null,
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case MATERIAL_GETLIST_START: {
      return { ...state, isLoading: true };
    }
    case MATERIAL_GETLIST_SUCCESS: {
      const { payload } = action;
      return { ...state, isLoading: false, materials: payload };
    }
    case MATERIAL_GETLIST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: return { ...state };
  }
}
