import React from 'react';
import * as Redux from 'react-redux';
import Lodash from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import Panel from '../../../Components/Panel';
import LoadingModal from '../../../Components/LoadingModal';
import { ORDER_TYPE_OBJECT, ORDER_TYPE_SINGLE } from '../../../Library/Types/OrderTypes';
import SelectOrderOptions from '../../../Components/Order/Edit/SelectOrderOptions';
import SelectOrderType from '../../../Components/Order/Edit/SelectOrderType';
import OrderObjectPanel, { initialObject } from './Components/OrderObjectPanel';
import SelectPartner from '../../../Components/Order/Edit/SelectPartner';
import { checkCalculation, isValidFloatNumberFormat } from '../../../Library/Functions';
import EditLoadingAddress, { initialLoadingAddress } from '../../../Components/Order/Edit/EditLoadingAddress';
import ShowStorageAddress from '../../../Components/Order/Edit/ShowStorageAddress';
import SelectStoragePartner from '../../../Components/Order/Edit/SelectStoragePartner';
import {
  useDispatchApproveOrder,
  useDispatchCalculateOrder,
  useDispatchCreateOrder,
} from '../../../Redux/Action/OrderAction';
import { validateObjectContainer, validateOrder } from '../../../Library/Validator/FieldValidator';
import { useDispatchGetClientSettings } from '../../../Redux/Action/ClientSettingsAction';
import Button from '../../../Components/Button';
import SelectDeliveryDate from '../../../Components/Order/Edit/SelectDeliveryDate';
import ShowHkEstimation from '../../../Components/Order/Edit/ShowHkEstimation';
import {
  NAVIGATION_OBJECT_DETAILS,
  NAVIGATION_ORDER_DETAILS,
} from '../../../Library/Types';
import ApproveExternalOrderModal from './Components/ApproveExternalOrderModal';
import OrderObjectsPanel from './Components/OrderObjectsPanel';
import SelectDeliveryRange from '../../../Components/Order/Edit/SelectDeliveryRange';
import { useDispatchGetNextPossibleMontageDate } from '../../../Redux/Action/CalendarAction';
import Alert from '../../../Components/Alert';
import ApproveAgb from '../../../Components/Agb/ApproveAgb';

export const initialOrderState = {
  // renderPanelType
  type: ORDER_TYPE_SINGLE,
  externalId: null,

  // renderPanelOptions
  optionMontage: true,
  optionDelivery: false,
  optionRemoval: false,
  removalMeter: null,

  // renderPanelObjects
  objects: initialObject,

  // renderPanelPartner
  internal: null,
  mandantId: null,
  optionStorage: false,

  // renderPanelStoragePartner
  storageClientId: null,
  storageDate: null,
  // renderPanelLoadingAddress && renderPanelStoragePartner
  loadingAddress: {},

  // TODO
  deliveryDate: {
    deliveryDateStart: null,
    deliveryDateEnd: null,
    deliveryWeekStart: null,
  },
  deliveryDateStart: null,
  deliveryDateEnd: null,
  deliveryWeekStart: null,

  // Internal use
  lastAction: null,
};

const initialCalculation = {
  result: null,
  assemblyTime: null,
  monteurPrice: null,
  dealerPrice: null,
  discountPrice: null,
  usedVoucherId: null,
  montagePrice: null,
  deliveryPrice: null,
  storagePrice: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'update':
    case 'sync':
      return { ...state, ...action.payload, lastAction: action.type };

    default:
      throw new Error();
  }
}

function reducerLog(oldState, action) {
  const newState = reducer(oldState, action);

  // console.log('Old State:', oldState);
  // console.log('Action:', action);
  // console.log('New State:', newState);

  return newState;
}

export default function CreateNew() {
  const history = useHistory();
  const location = useLocation();
  const initialOrder = location.state ? location.state.initialOrder : null;

  const dispatchCreateOrder = useDispatchCreateOrder();
  const dispatchApproveOrder = useDispatchApproveOrder();
  const dispatchCalculateOrder = useDispatchCalculateOrder();
  const dispatchGetNextPossibleMontageDate = useDispatchGetNextPossibleMontageDate();
  const dispatchGetClientSettings = useDispatchGetClientSettings();

  const client = Redux.useSelector((s) => s.client.client);
  const clientCommentMandatory = Redux.useSelector((s) => s.client.client.settings.internalMontageInfoMandatory);
  const isLoading = Redux.useSelector((s) => s.order.isLoading);
  const uploadProgress = Redux.useSelector((s) => s.order.uploadProgress);

  const [state, dispatch] = React.useReducer(reducerLog, initialOrder || initialOrderState);
  const [commentMandatory, setCommentMandatory] = React.useState(false);

  const [errors, setErrors] = React.useState({});
  const [checkErrors, setCheckErrors] = React.useState(false);
  const [showErrorPopup, setShowErrorPopup] = React.useState(false);

  const [calculation, setCalculation] = React.useState(initialCalculation);
  const [calculationLoading, setCalculationLoading] = React.useState(false);
  const [calculationUpdate, setCalculationUpdate] = React.useState(false);

  const [possibleDates, setPossibleDates] = React.useState(null);
  const [possibleDatesLoading, setPossibleDatesLoading] = React.useState(false);
  const [possibleDatesShow, setPossibleDatesShow] = React.useState(false);

  const [showExternalModal, setShowExternalModal] = React.useState(false);

  let counter = 0;

  const callbackErrorCheck = React.useCallback((checkState) => {
    let tmpErrors = validateOrder(checkState);

    if (checkState.type === ORDER_TYPE_SINGLE) {
      tmpErrors = {
        ...tmpErrors,
        ...validateObjectContainer(
          checkState.objects,
          null,
          checkState.type,
          checkState.internal,
          commentMandatory,
        ),
      };
    } else if (checkState.objects.length < 2) {
      tmpErrors = { ...tmpErrors, objects: true };
    } else {
      checkState.objects.forEach((object) => {
        tmpErrors = {
          ...tmpErrors,
          ...validateObjectContainer(
            object,
            checkState.objects,
            checkState.type,
            checkState.internal,
            commentMandatory,
          ),
        };
      });
    }

    setErrors(tmpErrors);

    return Object.keys(tmpErrors).length === 0;
  }, [commentMandatory]);

  const callbackRedirect = React.useCallback((orderId, objectId = null) => {
    if (objectId) {
      history.push(
        NAVIGATION_OBJECT_DETAILS.replace('{orderId}', orderId).replace('{objectId}', objectId),
      );
    } else {
      history.push(
        NAVIGATION_ORDER_DETAILS.replace('{orderId}', orderId),
      );
    }
  }, [history]);

  const callbackCreateOrder = React.useCallback((newOrder) => {
    dispatchCreateOrder(newOrder).then((res) => {
      if (res.internal || !res.processed) {
        const objectId = res.type === ORDER_TYPE_SINGLE ? res.objects[0].objectId : null;
        callbackRedirect(res.orderId, objectId);
      } else {
        dispatchApproveOrder(res.orderId).then((resApprove) => {
          const objectId = resApprove.type === ORDER_TYPE_SINGLE ? resApprove.objects[0].objectId : null;
          callbackRedirect(resApprove.orderId, objectId);
        });
      }
    }).catch(() => { /* TODO Error Meldung */ });
  }, [dispatchCreateOrder, dispatchApproveOrder, callbackRedirect]);

  const callbackGetPossibleDates = React.useCallback((value) => {
    if (state.internal && callbackErrorCheck(value)) {
      setPossibleDatesLoading(true);
      dispatchCalculateOrder(value).then((resCalc) => {
        const { assemblyTime } = resCalc;
        dispatchGetNextPossibleMontageDate(assemblyTime).then((resDates) => {
          setPossibleDates(resDates);
          setPossibleDatesLoading(false);
        }).catch(() => {
          setPossibleDates(null);
          setPossibleDatesLoading(false);
        });
      }).catch(() => {
        setPossibleDates(null);
        setPossibleDatesLoading(false);
      });
    }
  },
  [state.internal, dispatchCalculateOrder, callbackErrorCheck, setPossibleDates, dispatchGetNextPossibleMontageDate]);

  React.useEffect(() => {
    if (state.mandantId > 0) {
      dispatchGetClientSettings(state.mandantId).then((response) => {
        setCommentMandatory(response.internalMontageInfoMandatory);
      });
    } else {
      setCommentMandatory(clientCommentMandatory);
    }
  }, [state.mandantId, clientCommentMandatory, dispatchGetClientSettings]);

  // Syncs data into the objects
  React.useEffect(() => {
    if (state.lastAction === 'update') {
      if (state.type === ORDER_TYPE_SINGLE) {
        dispatch({
          type: 'sync',
          payload: {
            ...state.deliveryDate,
            objects: {
              ...state.objects,
              storageClientId: state.storageClientId,
              storageDate: {
                date: state.storageDate,
              },
            },
          },
        });
      } else {
        const syncedObjects = state.objects.map((item) => ({
          ...item,
          storageClientId: state.storageClientId,
          storageDate: {
            date: state.storageDate,
          },
        }));

        dispatch({
          type: 'sync',
          payload: {
            ...state.deliveryDate,
            objects: syncedObjects,
          },
        });
      }

      if (state.internal != null && !state.internal && state.type !== ORDER_TYPE_OBJECT) {
        setCalculationUpdate(true);
      } else {
        setCalculationUpdate(false);
      }
    }
  }, [state, dispatch, setCalculationUpdate]);

  React.useEffect(() => {
    if (checkErrors && state.lastAction === 'sync') {
      const delayed = setTimeout(() => callbackErrorCheck(state), 1000);
      return () => (delayed ? clearTimeout(delayed) : undefined);
    }
    return () => {};
  }, [state, checkErrors, callbackErrorCheck]);

  React.useEffect(() => {
    if (Object.keys(errors).length === 0) {
      if (calculationUpdate && !calculationLoading) {
        setCheckErrors(true);
        setCalculationLoading(true);
        if (callbackErrorCheck(state)) {
          dispatchCalculateOrder(state).then((respone) => {
            setCalculation(respone);
            setCalculationLoading(false);
            setCalculationUpdate(false);
          }).catch(() => {
            setCalculation(initialCalculation);
            setCalculationLoading(false);
          });
        } else {
          setCalculationLoading(false);
        }
      }
    }
  },
  [
    state,
    calculationUpdate,
    setCalculationUpdate,
    calculationLoading,
    errors,
    callbackErrorCheck,
    dispatchCalculateOrder,
  ]);

  React.useEffect(() => {
    if (Object.keys(errors).length === 0) {
      if (possibleDatesShow && !possibleDatesLoading) {
        setCheckErrors(true);
        callbackGetPossibleDates(state);
      }
    }
  }, [state, errors, possibleDatesLoading, callbackGetPossibleDates, setCheckErrors, possibleDatesShow]);

  const renderPanelHeadline = () => (
    <Panel marginBottom={25}>
      <h2>
        <i className="fas fa-plus hk-text-primary" style={{ marginRight: 20 }} />
        Auftrag erstellen
      </h2>
    </Panel>
  );

  const renderPanelType = () => {
    counter += 1;

    const value = Lodash.pick(state, ['type', 'externalId']);

    return (
      <Panel marginBottom={30}>
        <SelectOrderType
          state={value}
          onChange={(newValue) => dispatch({ type: 'update', payload: newValue })}
          headline={`${counter}. Auftragsart`}
        />
      </Panel>
    );
  };

  const renderPanelOptions = () => {
    counter += 1;

    const value = Lodash.pick(
      state,
      ['optionMontage', 'optionDelivery', 'optionRemoval', 'removalMeter'],
    );

    return (
      <Panel marginBottom={30}>
        <SelectOrderOptions
          state={value}
          onChange={(newValue) => dispatch({ type: 'update', payload: newValue })}
          headline={`${counter}. Auftragsoptionen`}
          errors={errors}
        />
      </Panel>
    );
  };

  const handleUpdateSingleObject = React.useCallback((value) => {
    dispatch({ type: 'update', payload: { objects: value } });
  }, [dispatch]);

  const renderPanelOrderObject = () => {
    const initialCounter = counter;
    counter += 3;

    return (
      <OrderObjectPanel
        initialState={state.objects || initialObject}
        initialCounter={initialCounter}
        onChange={handleUpdateSingleObject}
        isOrderTypeSingle
        errors={errors}
      />
    );
  };

  const handleChangeObjects = React.useCallback((objects) => {
    dispatch({ type: 'update', payload: { objects } });
  }, [dispatch]);

  const renderPanelOrderObjects = () => {
    counter += 1;

    return (
      <Panel marginBottom={30}>
        <OrderObjectsPanel
          headline={`${counter}. Küchen`}
          headlineCounter={counter}
          onChange={handleChangeObjects}
          errors={errors}
        />
      </Panel>
    );
  };

  const renderPanelObjects = () => {
    if (state.type === ORDER_TYPE_SINGLE) {
      return renderPanelOrderObject();
    }
    return renderPanelOrderObjects();
  };

  const getBalance = () => {
    if (state.type === ORDER_TYPE_SINGLE) {
      if (
        state.objects[0]
        && state.objects[0].kitchenOptions
        && isValidFloatNumberFormat(state.objects[0].kitchenOptions.balance)
      ) {
        return state.objects[0].kitchenOptions.balance;
      }
    }
    return null;
  };

  const renderPanelEstimatedTime = () => {
    if (state.internal == null || state.internal || state.type === ORDER_TYPE_OBJECT) {
      return null;
    }

    counter += 1;

    return (
      <Panel marginBottom={30}>
        <ShowHkEstimation
          assemblyTime={calculation.assemblyTime}
          price={calculation.dealerPrice}
          montagePrice={calculation.montagePrice}
          deliveryDate={state.deliveryDateStart}
          headline={`${counter}. Schätzung von hey.kitchen`}
          loading={calculationLoading}
          updating={calculationUpdate}
          errors={errors}
        />
      </Panel>
    );
  };

  const renderPanelPartner = () => {
    counter += 1;

    const value = Lodash.pick(state, ['internal', 'mandantId', 'optionStorage']);

    return (
      <SelectPartner
        state={value}
        onChange={(newValue) => dispatch({
          type: 'update',
          payload: { ...newValue, loadingAddress: initialLoadingAddress },
        })}
        headline={`${counter}. Partnerauswahl`}
        balance={getBalance()}
        optionDelivery={state.optionDelivery}
        errors={errors}
      />
    );
  };

  const renderPanelLoadingAddress = () => {
    if (!state.optionDelivery || state.optionStorage) {
      return null;
    }

    counter += 1;
    return (
      <Panel marginBottom={30}>
        <EditLoadingAddress
          loadingAddress={state.loadingAddress || initialLoadingAddress}
          onChange={(loadingAddress) => dispatch({ type: 'update', payload: { loadingAddress } })}
          headline={`${counter}. Beladestelle`}
          errors={errors}
        />
      </Panel>
    );
  };

  const renderPanelPartnerStorageAddress = () => {
    if (!state.optionStorage || state.mandantId < 1) {
      return null;
    }

    counter += 1;
    return (
      <Panel marginBottom={30}>
        <ShowStorageAddress
          mandantId={state.mandantId}
          headline={`${counter}. Lageradresse`}
          onChange={(newValue) => dispatch({ type: 'update', payload: newValue })}
        />
      </Panel>
    );
  };

  const renderPanelStoragePartner = () => {
    if (!state.optionStorage || state.mandantId > 0 || !state.internal) {
      return null;
    }

    counter += 1;

    const value = Lodash.pick(state, ['storageClientId', 'loadingAddress', 'storageDate']);

    // TODO Only show for internal and if there are partner which allow storage
    return (
      <Panel marginBottom={30}>
        <SelectStoragePartner
          state={value}
          onChange={(newValue) => dispatch({ type: 'update', payload: newValue })}
          headline={`${counter}. Partnerauswahl für Externe Lagerung`}
          errors={errors}
        />
      </Panel>
    );
  };

  const renderPanelMontageDate = () => {
    counter += 1;

    const value = { ...state.deliveryDate };

    if (state.type === ORDER_TYPE_SINGLE) {
      return (
        <Panel marginBottom={30}>
          <SelectDeliveryDate
            state={value}
            possibleDates={possibleDates}
            onChange={(deliveryDate) => dispatch({ type: 'update', payload: { deliveryDate } })}
            onClick={() => setPossibleDatesShow(!possibleDatesShow)}
            headlinePrefix={`${counter}.`}
            isInternal={state.internal}
            hasMandant={(state.mandantId > 0)}
            errors={errors}
          />
        </Panel>
      );
    }
    return (
      <Panel marginBottom={30}>
        <SelectDeliveryRange
          state={value}
          onChange={(deliveryDate) => dispatch({ type: 'update', payload: { deliveryDate } })}
          headline={`${counter}. Lieferzeitraum`}
          isInternal={state.internal}
          errors={errors}
        />
      </Panel>
    );
  };

  const handleConfirmation = () => {
    setCheckErrors(true);

    if (!callbackErrorCheck(state)) {
      setShowErrorPopup(true);
      return;
    }

    if (state.internal || !checkCalculation(calculation.montagePrice, state.deliveryDateStart, true)) {
      callbackCreateOrder(state);
    } else if (!state.deliveryDateStart) {
      setErrors({ deliveryDateStart: true });
    } else {
      setShowExternalModal(true);
    }
  };

  const renderConfirmAgb = () => (state.internal != null && !state.internal && !client.externalAgbApproved ? (
    <Panel marginBottom={30}>
      <div className="row">
        <ApproveAgb />
      </div>
    </Panel>
  ) : null);

  const renderPanelConfirmation = () => (
    <Panel>
      <div className="row">
        <div className="col-lg-12">
          <Button
            onClick={handleConfirmation}
            className="float-right"
            disabled={state.lastAction !== 'sync' || (!state.internal && !client.externalAgbApproved)}
          >
            {state.internal ? 'Auftrage erstellen' : 'Angebot jetzt einholen'}
          </Button>
        </div>
      </div>
    </Panel>
  );

  const renderContent = () => (
    <>
      <div className="col-xl-3 d-xl-block" />
      <div className="col-xl-6">
        {renderPanelHeadline()}
        {renderPanelType()}
        {renderPanelOptions()}
        {renderPanelObjects()}
        {renderPanelPartner()}
        {renderConfirmAgb()}
        {renderPanelLoadingAddress()}
        {renderPanelPartnerStorageAddress()}
        {renderPanelStoragePartner()}
        {renderPanelEstimatedTime()}
        {renderPanelMontageDate()}
        {renderPanelConfirmation()}
      </div>
      <div className="col-xl-3 d-xl-block" />
    </>
  );

  const renderLoadingModal = () => (isLoading ? (
    <LoadingModal
      headline="Ihr Auftrag wird erstellt!"
      text="Bitte schließen Sie nicht das Browserfenster.<br/>Sie werden automatisch weitergeleitet."
      progress={uploadProgress}
      visible
    />
  ) : null);

  const renderCreateExternalModal = () => (showExternalModal ? (
    <ApproveExternalOrderModal
      onConfirm={() => { callbackCreateOrder(state); }}
      onClose={() => { setShowExternalModal(false); }}
    />
  ) : null);

  return (
    <div className="row">
      {renderLoadingModal()}
      {renderCreateExternalModal()}
      {renderContent()}

      <Alert onClose={() => setShowErrorPopup(false)} visible={showErrorPopup} type="danger">
        <div>
          Sie haben nicht alle notwendigen Daten angegeben oder Fehler in den eingegebenen Daten! Bitte prüfen Sie die
          <strong className="hk-text-primary"> rot </strong>
          markierten Felder und korrigieren Sie Ihre Eingabe.
        </div>
      </Alert>
    </div>
  );
}

CreateNew.propTypes = {};

CreateNew.defaultProps = {};
