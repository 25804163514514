import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import Button from '../Components/Button';
import Input from '../Components/Input';
import Panel from '../Components/Panel';
import LoadingModal from '../Components/LoadingModal';
import Footer from './Frontend/Components/Footer';
import { resetPassword } from '../Redux/Action/UserAction';

/**
 * ResendPassword()
 * @param props
 * @returns {*}
 * @constructor
 */
function ResendPassword(props) {
  // eslint-disable-next-line no-shadow
  const {
    isLoggedin, history, dispatchResetPassword,
  } = props;
  const [mail, setMail] = useState();
  const [errors, setErrors] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (mail && mail.length > 3 && mail.indexOf('@') > -1 && mail.indexOf('.') > -1) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [mail]);

  const handleResetPassword = () => {
    setErrors(false);

    if (mail && mail.length > 3 && mail.indexOf('@') > -1 && mail.indexOf('.') > -1) {
      setIsLoading(true);
      dispatchResetPassword(mail).then(() => {
        setIsLoading(false);
        history.push('/login?reset=true');
      }).catch(() => {
        setErrors(true);
        setIsLoading(false);
      });
    } else {
      setErrors(true);
    }
  };

  if (!isLoggedin) {
    return (
      <div
        className="d-flex flex-column flex-grow-1"
        style={{ margin: '-40px' }}
        role="button"
        tabIndex={-1}
      >
        <div
          className="d-flex flex-grow-1 justify-content-center align-items-center align-content-center"
          style={{ backgroundColor: '#F1F1F2', padding: 50 }}
        >
          <Panel width="600px">
            <h3 className="hk-text-primary">
              Passwort vergessen?
            </h3>
            <p style={{ margin: 0 }}>
              Bitte geben Sie die E-Mail Adresse ein mit der Sie sich bei hey.kitchen registriert haben bzw. mit
              welcher Ihr hey.kitchen Account verknüpft ist. Sie erhalten dann ein neues Passwort per E-Mail
              zugesendet mit welchem Sie sich sofort wieder anmelden können.
            </p>

            <hr style={{ margin: '40px 0' }} />

            <Input
              rightIcon="fas fa-envelope"
              onChange={(value) => setMail(value)}
              value={mail}
              placeholder="E-Mail Adresse"
              hasError={errors}
              errorMessage="E-Mail ungültig oder nicht bekannt"
            />

            <br />

            <div className="row">
              <div className="col-lg-4">
                <Button className="float-left" type="btn-light" onClick={() => history.push('/login')}>
                  Abbrechen
                </Button>
              </div>
              <div className="col-lg-8">
                <Button className="float-right" onClick={handleResetPassword} disabled={disabled}>
                  Neues Passwort zusenden
                </Button>
              </div>
            </div>
          </Panel>

          <LoadingModal visible={isLoading} text="Ihr neues Passwort wird Ihnen zugesendet..." />
        </div>
        <div>
          <Footer type="Dark" />
        </div>
      </div>
    );
  }

  return <Redirect to="/dashboard" />;
}

ResendPassword.propTypes = {
  dispatchResetPassword: PropTypes.func.isRequired,
  isLoggedin: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetPassword: (mail) => dispatch(resetPassword(mail)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(ResendPassword));
