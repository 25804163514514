import { useDispatch } from 'react-redux';
import React from 'react';
import axios from '../../Library/Axios';

export const SUPPORT_SEND_FEEDBACK_START = 'SUPPORT_SEND_FEEDBACK_START';
export const SUPPORT_SEND_FEEDBACK_SUCCESS = 'SUPPORT_SEND_FEEDBACK_SUCCESS';
export const SUPPORT_SEND_FEEDBACK_ERROR = 'SUPPORT_SEND_FEEDBACK_ERROR';
/**
 * useDispatchSendFeedbackMail()*
 */
export function useDispatchSendFeedbackMail() {
  const dispatch = useDispatch();

  return React.useCallback((subject, content) => {
    dispatch({ type: SUPPORT_SEND_FEEDBACK_START, payload: { subject, content } });

    const config = { params: { action: 'feedback' } };

    const uri = '/mailer';
    const request = {};
    request.subject = subject;
    request.content = content;

    return axios().post(uri, request, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: SUPPORT_SEND_FEEDBACK_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SUPPORT_SEND_FEEDBACK_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
