import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../../ModalDialog';
import LoadingAddress from '../../../Pages/Order/Create/Components/LoadingAddress';

/**
 * UpdateLoadingAddress()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UpdateLoadingAddress(props) {
  const {
    order, visible, onClose, onSave,
  } = props;
  const [data, setData] = React.useState({});

  const handleSave = () => {
    const merged = { ...order.loadingAddress, ...data };
    onSave(merged);
  };

  return (
    <ModalDialog onClose={onClose} visible={visible} onConfirm={handleSave}>
      <LoadingAddress
        onChange={(value) => setData(value)}
        editableData={order.loadingAddress}
        deliverySelected
        indexProcessStep=""
      />
    </ModalDialog>
  );
}

UpdateLoadingAddress.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

UpdateLoadingAddress.defaultProps = {
  visible: false,
};
