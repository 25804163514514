import {
  CLIENTTRANSACTION_GETLIST_ERROR, CLIENTTRANSACTION_GETLIST_START, CLIENTTRANSACTION_GETLIST_SUCCESS,
} from '../Action/ClientTransactionAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  transactions: [],
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /**
     * GETLIST
     */
    case CLIENTTRANSACTION_GETLIST_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTTRANSACTION_GETLIST_SUCCESS: {
      const { payload } = action;
      return {
        ...state, transactions: payload, isLoading: false,
      };
    }
    case CLIENTTRANSACTION_GETLIST_ERROR: {
      return { ...state, transactions: [], isLoading: false };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
