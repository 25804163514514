import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Add from './Add';
import { getAll, remove } from '../../../Redux/Action/ClientSubcontractorAction';
import SubcontractorTable from '../../../Components/SubcontractorTable';
import { getAll as getAllUser, update } from '../../../Redux/Action/UserAction';
import Button from '../../../Components/Button';
import Panel from '../../../Components/Panel';
import { NAVIGATION_CLIENT_ADMIN_SUBCONTRACTOR_DETAILS } from '../../../Library/Types';

/**
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function List(props) {
  const {
    subcontractors, getAllSubcontractors, history, client, users, getAllUsers, dispatchUpdateUser, removeSubcontractor,
  } = props;
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    getAllUsers();
    getAllSubcontractors(client.clientId);
  }, [getAllSubcontractors, getAllUsers, client.clientId]);

  const onDelete = (deletedItem, deleteAssignedUsers) => {
    removeSubcontractor(deletedItem, deleteAssignedUsers).then(() => {
      getAllSubcontractors(client.clientId);
    });
  };

  const navigateUserDetails = (data) => {
    const uri = NAVIGATION_CLIENT_ADMIN_SUBCONTRACTOR_DETAILS.replace('{subcontractorId}', data.subcontractorId);
    history.push(uri);
  };

  function onClose() {
    setShowAdd(false);
  }

  function renderTable() {
    if (subcontractors && users) {
      return (
        <SubcontractorTable
          subcontractors={subcontractors}
          onDelete={(item, deleteAssignedUsers) => onDelete(item, deleteAssignedUsers)}
          onUpdate={navigateUserDetails}
          users={users}
          updateUser={(userId, userData) => dispatchUpdateUser(userId, userData)}
          showIds
        />
      );
    }
    return null;
  }

  if (client.settings.allowSubcontractor === false) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <Panel marginBottom={25}>
          <div className="d-flex flex-row">
            <div className="flex-grow-1">
              <h4>Subunternehmer verwalten</h4>
              <p style={{ marginBottom: 0 }}>
                Zur besseren Verwaltung Ihrer Monteure können Sie eigene erstellte Benutzer einem Subunternehmen
                hinzufügen. Somit haben Sie eine bessere Übersicht über Ihre Benutzer und können die Daten des
                Subunternehmens selber verwalten.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <Button onClick={() => setShowAdd(true)}>
                <i className="fas fa-plus" />
                Erstellen
              </Button>
            </div>
          </div>
        </Panel>
        {renderTable()}
      </div>
      <Add
        client={client}
        visible={showAdd}
        onClose={() => onClose()}
      />
    </div>
  );
}

function mapStoreToProps(store) {
  return {
    isLoading: store.subcontractor.isLoggedin,
    subcontractors: store.subcontractor.subcontractors,
    client: store.client.client,
    users: store.user.users,

  };
}

List.propTypes = {
  subcontractors: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  getAllSubcontractors: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  dispatchUpdateUser: PropTypes.func.isRequired,
  removeSubcontractor: PropTypes.func.isRequired,

};

List.defaultProps = {
  subcontractors: null,
  users: null,
};

function mapDispatchToProps(dispatch) {
  return {
    getAllSubcontractors: (clientId) => dispatch(getAll(clientId, true)),
    getAllUsers: () => dispatch(getAllUser(false)),
    dispatchUpdateUser: (userId, userData) => dispatch(update(userId, userData)),
    removeSubcontractor: (subcontractorData, deleteUsersAswell) => dispatch(
      remove(subcontractorData, deleteUsersAswell),
    ),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(List));
