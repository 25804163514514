import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../../../Components/Panel';
import ClientFilter from './ClientFilter';

/**
 * ClientList()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function ClientList(props) {
  const { clients, onPress } = props;
  const [filtered, setFiltered] = React.useState();

  React.useEffect(() => {
    setFiltered(clients);
  }, [clients]);

  const getAddress = (item) => {
    if (item.zip && item.city && item.street && item.streetNo) {
      return `${item.zip} ${item.city}, ${item.street} ${item.streetNo}`;
    }
    return <i>Adresseangaben unvollständig!</i>;
  };

  const renderTable = () => {
    if (filtered && filtered.length > 0) {
      return (
        <Panel>
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th width={60} />
                <th>Name</th>
                <th width={120}>Typ</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item) => (
                <tr onClick={() => onPress(item)} key={item.clientId}>
                  <td>{item.clientId}</td>
                  <td>
                    <div>{item.name}</div>
                    <small className="text-muted">{getAddress(item)}</small>
                  </td>
                  <td>{item.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel>
      );
    }
    return null;
  };

  return (
    <>
      <ClientFilter clients={clients} onChange={setFiltered} />
      {renderTable()}
    </>
  );
}

ClientList.propTypes = {
  clients: PropTypes.instanceOf(Array),
  onPress: PropTypes.func.isRequired,
};

ClientList.defaultProps = {
  clients: null,
};
