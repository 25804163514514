import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { useSelector } from 'react-redux';
import Panel from '../../Panel';
import TextLink from '../../TextLink';
import { useDispatchDateDeleteUser } from '../../../Redux/Action/ObjectAction';
import TeamMonteurPartnerRow from '../../TeamMonteurPartner/TeamMonteurPartnerRow';
import SelectUserTeamPartner from '../../SelectUserTeamPartner';
import { ORDERSTATE_PARTNER_CREATED } from '../../../Library/StateTypes';
import DispoInformation from './DispoInformation';
import {
  useAccessCanScheduleObjects,
  useAccessClientCanAssignObject,
  useAccessIsObjectEditable,
} from '../../../Library/AccessChecks';
import { OBJECT_DATE_TYPE_ALL } from '../../../Library/Types';

/**
 * AssignContainer()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function AssignContainer(props) {
  const {
    object, onUserAdd, onTeamAdd, onPartnerAdd, onUpdate,
  } = props;
  const { client } = useSelector((state) => state.client);
  const partners = useSelector((state) => state.client.activeMonteurPartners);
  const { order } = useSelector((state) => state.order);
  const { serviceReport, demontageReport } = useSelector((state) => state.reports);

  const dispatchDateDeleteUser = useDispatchDateDeleteUser();

  const isEditable = useAccessIsObjectEditable(object);
  const isAssignable = useAccessClientCanAssignObject(object);

  const isTerminable = useAccessCanScheduleObjects();
  const [canUsePartners, setCanUsePartners] = React.useState(false);
  const [showSelect, setShowSelect] = React.useState(false);
  const [montageDate, setMontageDate] = React.useState(null);

  React.useEffect(() => {
    setMontageDate(object.montageDate);
  }, [object]);

  React.useEffect(() => {
    setCanUsePartners(
      (client.clientId === order.clientId && partners && partners.length > 0 && order.internal),
    );
  }, [client, order, partners]);

  const handleAddTeam = ({ teamId }) => {
    onTeamAdd({ ...montageDate, teamId });
    setShowSelect(false);
  };

  const handleAddPartner = ({ clientId }) => {
    onPartnerAdd({ ...montageDate, clientId });
    setShowSelect(false);
  };

  const handleAddUser = (user) => {
    onUserAdd(montageDate, user);
    setShowSelect(false);
  };

  const handleUpdate = (key, value, disableDispositionChecks = false) => {
    onUpdate({ ...montageDate, [key]: value }, key, OBJECT_DATE_TYPE_ALL, disableDispositionChecks);
    setShowSelect(false);
  };

  const renderAddUsers = () => {
    if ((order.clientId === client.clientId && montageDate.clientId)
      || order.state === ORDERSTATE_PARTNER_CREATED
      || (serviceReport && serviceReport.finished)
      || (demontageReport && demontageReport.finished)
      || !isAssignable
    ) {
      return null;
    }

    if (montageDate.date && !montageDate.teamId) {
      let caption = 'Team/Monteur/Partner zuweisen';
      if (!canUsePartners) {
        caption = 'Team oder Monteur zuweisen';
      }

      if (montageDate.users && montageDate.users.length > 0) {
        caption = 'Weitere Monteure zuweisen';
      }

      return (
        <TextLink
          caption={caption}
          onPress={() => setShowSelect(true)}
          icon="fas fa-plus-square"
          style={{ marginTop: 20 }}
        />
      );
    }
    return null;
  };

  const renderDisabledHint = () => {
    if (order.mandantId && order.state === ORDERSTATE_PARTNER_CREATED) {
      return (
        <div className="alert alert-warning">
          Sie müssen den Auftrag erst bestätigen bevor Sie Monteure oder Teams zuweisen können
        </div>
      );
    }
    if (!montageDate.date) {
      return (
        <div className="alert alert-warning">
          Bitte vergeben Sie erst ein Ausführungsdatum bevor Sie Monteure oder Teams zuweisen!
        </div>
      );
    }
    return null;
  };

  if (!object.splitted && montageDate
    && (
      (order.clientId === client.clientId && order.internal)
      || (montageDate.clientId === client.clientId)
    )
  ) {
    return (
      <Panel marginBottom={20} containerStyle={{ display: 'flex' }}>
        <h6 className="Headline">
          Monteure / Teams
        </h6>
        <p>
          Bitte weisen Sie das Team oder die Monteure zu, die diesen Auftrag durchführen werden.
        </p>

        <DispoInformation
          onChange={(key, value) => handleUpdate(key, value)}
          date={object.montageDate}
          object={object}
          order={order}
          client={client}
          editable={(isEditable && isTerminable)}
        />

        <TeamMonteurPartnerRow
          teamId={montageDate.teamId}
          partnerId={montageDate.clientId}
          users={montageDate.users}
          client={client}
          onDeleteTeam={() => handleUpdate('teamId', null, true)}
          onDeletePartner={() => handleUpdate('clientId', null, true)}
          onDeleteUser={(user) => dispatchDateDeleteUser(object, montageDate, user.userId)}
          hideUsersOrTeam={(montageDate.clientId && montageDate.clientId !== client.clientId)}
          disabled={(serviceReport || demontageReport || !isAssignable)}
        />
        {renderDisabledHint()}
        {renderAddUsers()}

        <SelectUserTeamPartner
          onAddTeam={handleAddTeam}
          onAddPartner={handleAddPartner}
          onAddUser={handleAddUser}
          visible={showSelect}
          alreadyAddedUsers={montageDate.users}
          showPartners={(canUsePartners && (!montageDate.users || montageDate.users.length === 0))}
          showTeams={(montageDate.users.length === 0)}
          onClose={() => setShowSelect(false)}
          executionDate={montageDate.date}
        />
      </Panel>
    );
  }
  return null;
}

AssignContainer.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  onUserAdd: PropTypes.func.isRequired,
  onTeamAdd: PropTypes.func.isRequired,
  onPartnerAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
