import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import ModalDialog from '../../../../Components/ModalDialog';
import Textarea from '../../../../Components/Textarea';
import CustomReactSelect from '../../../../Components/CustomReactSelect';
import { CLIENTNOTE_TYPE_PHONE, clientNoteTypeOptions } from '../../../../Library/Types';

const resubmisionOptions = [
  { value: 0, label: 'Keine Wiedervorlage' },
  { value: 1, label: '1 Tag' },
  { value: 2, label: '2 Tagen' },
  { value: 3, label: '3 Tagen' },
  { value: 4, label: '4 Tagen' },
  { value: 5, label: '5 Tagen' },
  { value: 6, label: '6 Tagen' },
  { value: 7, label: '1 Woche' },
  { value: 14, label: '2 Wochen' },
  { value: 21, label: '3 Wochen' },
  { value: 28, label: '1 Monat' },
  { value: 60, label: '2 Monaten' },
  { value: 90, label: '3 Monaten' },
  { value: 120, label: '4 Monaten' },
  { value: 150, label: '5 Monaten' },
  { value: 180, label: '6 Monaten' },
];

/**
 * AddNote()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AddNote(props) {
  const {
    visible, onClose, onSave,
  } = props;
  const [text, setText] = React.useState();
  const [resubmisionDays, setResubmisionDays] = React.useState(0);
  const [type, setType] = React.useState(CLIENTNOTE_TYPE_PHONE);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    if (!visible) {
      setText('');
      setType(CLIENTNOTE_TYPE_PHONE);
    }
  }, [visible]);

  const handleSave = () => {
    setHasError(false);
    if (text && text.length > 0) {
      let resubmissionDate = null;
      if (resubmisionDays > 0) {
        resubmissionDate = moment().add(resubmisionDays, 'day').format('YYYY-MM-DD 08:00:00');
      }

      onSave({ text, type, resubmissionDate });
    } else {
      setHasError(true);
    }
  };

  return (
    <ModalDialog
      onClose={onClose}
      visible={visible}
      confirmCaption="Speichern"
      onConfirm={handleSave}
    >
      <h5>Notiz erstellen</h5>
      <p>Neue Notiz zum Kunden erstellen</p>
      <hr />

      <div className="row">
        <CustomReactSelect
          rowClass="col-lg-6"
          onChange={setType}
          options={clientNoteTypeOptions}
          label="Kontaktoption"
          value={type}
        />
        <CustomReactSelect
          rowClass="col-lg-6"
          onChange={setResubmisionDays}
          options={resubmisionOptions}
          label="Wiedervorlage in"
          value={resubmisionDays}
        />
        <Textarea
          onChange={setText}
          value={text}
          rows={6}
          rowClass="col-lg-12"
          label="Notiz / Bemerkung"
          autoFocus
          hasError={hasError}
        />
      </div>

    </ModalDialog>
  );
}

AddNote.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

AddNote.defaultProps = {
  visible: false,
};
