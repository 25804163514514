import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import { formatCurrency, formatDateTime } from '../../../../Library/Functions';
import Panel from '../../../../Components/Panel';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import { PAYMENTTYPE_CASH } from '../../../../Library/Types';

/**
 * Overview()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function Information(props) {
  const { report } = props;

  const renderDuration = () => {
    if (report.finished) {
      const start = moment(report.startDateTime);
      const end = moment(report.endDateTime);
      const duration = moment.duration(end.diff(start));
      const minutes = duration.asMinutes().toFixed(0);
      return (
        <KeyValueEditRow title="Lieferdauer" type="text" displayText={`${minutes} Minuten`} />
      );
    }
    return (
      <KeyValueEditRow title="Lieferdauer" type="text" displayText="Bericht in Bearbeitung..." />
    );
  };

  const renderMontageEnd = () => {
    if (report.finished) {
      return (
        <KeyValueEditRow title="Lieferende" type="text" displayText={formatDateTime(report.endDateTime)} />
      );
    }
    return (
      <KeyValueEditRow title="Lieferende" type="text" displayText="Bericht in Bearbeitung..." />
    );
  };

  const renderCustomerPaid = () => {
    if (report.customerPaidValue && report.customerPaidValue > 0) {
      return (
        <div style={{ marginTop: 30 }}>
          <KeyValueEditRow
            title="Bezahlter Restbetrag"
            type="text"
            displayText={formatCurrency(report.customerPaidValue)}
          />
          <KeyValueEditRow
            title="Verbleibender Restbetrag"
            type="text"
            displayText={formatCurrency(report.customerRestValue)}
          />
          <KeyValueEditRow
            title="Zahlungsweise"
            type="text"
            displayText={(report.paymentType === PAYMENTTYPE_CASH) ? 'Bar' : 'Bankeinzug'}
          />
        </div>
      );
    }
    return null;
  };

  if (report) {
    return (
      <Panel marginBottom={25}>
        <h6>Lieferinformationen</h6>
        <p>
          Hier sehen Sie wie lange die Lieferung gedauert hat und ob weiter Informationen hinterlegt wurden.
        </p>

        <KeyValueEditRow title="Lieferbegin" type="text" displayText={formatDateTime(report.startDateTime)} />
        {renderMontageEnd()}
        {renderDuration()}

        <KeyValueEditRow
          title="Weitere Informationen"
          type="text"
          displayText={report.comment}
          visible={(report.comment && report.comment.length > 0)}
        />

        {renderCustomerPaid()}
      </Panel>
    );
  }
  return null;
}

Information.propTypes = {
  report: PropTypes.instanceOf(Object),
};

Information.defaultProps = {
  report: null,
};
