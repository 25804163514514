import React from 'react';
import PropTypes from 'prop-types/prop-types';
import ModalDialog from '../ModalDialog';
import SelectBox from '../SelectBox';
import { DOCUMENTTYPE_OTHER, documentTypeOptions } from '../../Library/Types';
import FileInput from '../FileInput';

/**
 * SelectDocumentDialog()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function SelectDocumentDialog(props) {
  const {
    visible, onClose, onSave, editable,
  } = props;
  const [file, setFile] = React.useState(null);
  const [type, setType] = React.useState(DOCUMENTTYPE_OTHER);
  const [hasError, setHasError] = React.useState(false);

  const handleOnSave = () => {
    setHasError(false);

    if (file && type) {
      onSave(type, file);
      setType(DOCUMENTTYPE_OTHER);
      setFile(null);
    } else {
      setHasError(true);
    }
  };

  const getSelectOptions = () => {
    if (editable) {
      return documentTypeOptions;
    }

    // If not editable remove installation plan and order confirmation from documents types
    const options = [...documentTypeOptions];
    delete options[0];
    delete options[1];
    return options;
  };

  const getDescription = () => {
    if (editable) {
      return 'Sie können beliebig viele "Weitere Dokumente" hinzufügen. Installationsplan und Auftragsbestätigung '
        + 'werden immer überschrieben und können nur einmal vorhanden sein!';
    }
    return 'Sie können beliebig viele "Weitere Dokumente" hinzufügen.';
  };

  return (
    <ModalDialog onClose={onClose} visible={visible} onConfirm={handleOnSave} confirmCaption="Hinzufügen">
      <h5>Dokument hinzufügen</h5>
      <p>
        {getDescription()}
      </p>

      <hr />

      <div className="row">
        <SelectBox
          onChange={(value) => setType(value)}
          selected={type}
          data={getSelectOptions()}
          label="Dokumenttyp"
          rowClass="col-lg-12"
        />
        <FileInput
          onChange={(value) => setFile(value)}
          onDelete={() => setFile(null)}
          value={file}
          rowClass="col-lg-12"
          placeholder="Dokument wählen..."
          hasError={hasError}
        />
      </div>
    </ModalDialog>
  );
}

SelectDocumentDialog.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

SelectDocumentDialog.defaultProps = {
  visible: false,
};
