import React from 'react';
import PropTypes from 'prop-types';
import FileInput from '../../FileInput';

export const initialDocuments = {
  orderConfirmation: null,
  installationPlan: null,
  fileOther: [],
};

export default function EditDocuments(props) {
  const {
    documents, onChange, headline, isEditMode, errors,
  } = props;

  const updateValue = (update) => {
    if (onChange) {
      onChange({
        ...documents,
        ...update,
      });
    }
  };

  const renderDescription = () => ((isEditMode) ? (
    <p className="text-muted">
      Beim bearbeiten des Auftrags müssen Sie nicht zwingend die Dokumente erneut angeben. Die Dokumente wurden
      bereits zum Auftrag gespeichert. Wenn Sie einen neuen Installationsplan oder eine neue Auftragsbestätigung
      hinzufügen möchten, können Sie diese einfach jetzt hinzufügen. Das bereits vorhandene Dokument wird dabei
      überschrieben. Sie können beliebig viele "Weitere Dokumente" hinzufügen. Die Dokumente können auch später
      in den Auftragsdetails weiter bearbeitet oder gelöscht werden.
    </p>
  ) : (
    <p className="text-muted">
      Bitte laden Sie die entsprechenden Dokumente als Bild oder PDF-Datei hoch
    </p>
  ));

  const genKey = (file) => (file ? file.lastModified + file.name : Math.random());
  const compareFiles = (fileA, fileB) => genKey(fileA) === genKey(fileB);
  const handleCreateOtherFile = (value) => {
    const found = documents.fileOther.filter((file) => compareFiles(file, value));

    if (found.length === 0) {
      updateValue({ fileOther: [...documents.fileOther, value] });
    } else {
      updateValue({ fileOther: [...documents.fileOther] });
    }
  };
  const handleChangeOtherFile = (newFile, oldFile) => {
    if (!compareFiles(newFile, oldFile)) {
      const filtered = documents.fileOther.filter((file) => !compareFiles(file, oldFile));
      updateValue({ fileOther: [...filtered, newFile] });
    }
  };
  const handleDeleteOtherFile = (value) => {
    const filtered = documents.fileOther.filter((file) => value.name !== file.name
      || value.size !== file.size
      || value.lastModified !== file.lastModified);
    updateValue({ fileOther: [...filtered] });
  };

  const renderFileOther = () => documents.fileOther.map((file) => (
    <FileInput
      key={genKey(file)}
      value={file}
      onChange={(value) => handleChangeOtherFile(value, file)}
      onDelete={handleDeleteOtherFile}
      placeholder="Auftragsbestätigung / Angebot"
    />
  ));

  return (
    <div>
      <h5>{headline}</h5>
      {renderDescription()}
      <FileInput
        key={`${genKey(documents.orderConfirmation)}-confirm`}
        value={documents.orderConfirmation}
        onChange={(orderConfirmation) => updateValue({ orderConfirmation })}
        onDelete={() => updateValue({ orderConfirmation: null })}
        placeholder="Auftragsbestätigung / Angebot"
        hasError={errors.orderConfirmation}
        errorMessage={errors.orderConfirmationMessage}
      />
      <FileInput
        key={`${genKey(documents.installationPlan)}-install`}
        value={documents.installationPlan}
        onChange={(installationPlan) => updateValue({ installationPlan })}
        onDelete={() => updateValue({ installationPlan: null })}
        placeholder="Küchenplan / Installationsplan"
        hasError={errors.installationPlan}
        errorMessage={errors.installationPlanMessage}
      />
      {renderFileOther()}
      <FileInput
        key={Math.random()}
        value={null}
        onChange={handleCreateOtherFile}
        onDelete={() => {}}
        placeholder="Weitere Dokumente"
      />
    </div>
  );
}

EditDocuments.propTypes = {
  documents: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  headline: PropTypes.string,
  isEditMode: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
};

EditDocuments.defaultProps = {
  documents: initialDocuments,
  onChange: undefined,
  headline: 'Dokumente',
  isEditMode: false,
  errors: {},
};
