import moment from 'moment';
import React from 'react';
import { availableDevices, CLIENTTYPE_MONTEUR, OBJECT_DATE_TYPE_STORAGE } from './Types';
import {
  OBJECT_DATESTATE_CLOSED,
  OBJECT_DATESTATE_FINISHED,
  ORDERSTATE_FINISHED,
  ORDERSTATE_OPEN,
  ORDERSTATE_PARTNER_CREATED,
  ORDERSTATE_WAITING_APPROVEMENT,
  ORDERSTATE_WAITING_CALCULATION,
  RECLAMATIONSTATE_ASSIGNED,
  RECLAMATIONSTATE_CLOSED,
  RECLAMATIONSTATE_REPORT_FINISHED,
  RECLAMATIONSTATE_SCHEDULED,
} from './StateTypes';

/**
 * Generate a spsydo GUID unique for app context
 * @returns {string}
 */
export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

/**
 * Checks if an given object is empty
 * @param object
 * @returns {boolean}
 */
export function isObjectEmpty(object) {
  return (Object.keys(object).length === 0 && object.constructor === Object);
}

const allowedNumberChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.'];

/**
 * Is valid float number
 * @param value
 * @returns {boolean}
 */
export function isValidFloatNumberFormat(value) {
  if (!value) {
    return false;
  }

  for (let i = 0; i < value.length; i += 1) {
    if (allowedNumberChars.indexOf(value.charAt(i)) < 0) {
      return false;
    }
  }

  return true;
}

/**
 * Check the price and format the price for only 2 digits after the first comma !!!
 * We do not want to have thousand separator here. Important!
 * @param price
 * @returns {string|*}
 */
export function checkAndFormatPrice(price) {
  if (price) {
    let mergedPrice = price.replace(' ', '');
    if (mergedPrice.indexOf(',') > -1 || mergedPrice.indexOf('.') > -1) {
      mergedPrice = mergedPrice.replace(',', '.');

      if (mergedPrice.lastIndexOf('.') > mergedPrice.indexOf('.')) {
        return mergedPrice.substring(0, mergedPrice.length - 1);
      }

      const index = mergedPrice.indexOf('.');

      if (mergedPrice.length > (index + 3)) {
        return mergedPrice.substring(0, (index + 3));
      }
      return mergedPrice;
    }
    return mergedPrice;
  }
  return price;
}

/**
 * isValidZip()
 * @param value
 * @returns {boolean}
 */
export function isValidZip(value) {
  if (value.length !== 5) {
    return false;
  }

  try {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(value);
  } catch (e) {
    return false;
  }
}

/**
 * isValidPhoneNumber()
 * @param value
 * @returns {boolean}
 */
export function isValidPhoneNumber(value) {
  // eslint-disable-next-line
  const regEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  return regEx.test(value);
}

/**
 * Validate currency
 * @param value
 * @returns {boolean}
 */
export function isValidateCurrency(value) {
  if (value) {
    let testValue = value;
    if (value.indexOf('.') < 0) {
      testValue = `${testValue}.00`;
    }

    const regex = /^\d+(?:\.\d{0,2})$/;
    return regex.test(testValue);
  }
  return false;
}

/**
 * isValidEmail()
 * @param email
 * @returns {boolean}
 */
export function isValidateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(email).toLowerCase())) {
    if (email.indexOf('à') > -1 || email.indexOf('è') > -1 || email.indexOf('ù') > -1
      || email.indexOf('ò') > -1 || email.indexOf('ì') > -1 || email.toLowerCase().indexOf('ü') > -1
      || email.toLowerCase().indexOf('ä') > -1 || email.toLowerCase().indexOf('ö') > -1
      || email.toLowerCase().indexOf('ß') > -1
    ) {
      return false;
    }
    return true;
  }
  return false;
}

/**
 * formatDate()
 * @param value
 * @param format
 * @returns {string}
 */
export function formatDate(value, format = null) {
  if (format) {
    return moment(value, 'YYYY-MM-DD').format(format);
  }
  return moment(value, 'YYYY-MM-DD').format('dddd, DD.MM.YYYY');
}

/**
 * formatTime()
 * @param value
 * @param format
 * @returns {string}
 */
export function formatTime(value, format = null) {
  if (format) {
    return moment(value, 'HH:mm:ss').format(format);
  }
  return moment(value, 'HH:mm:ss').format('HH:mm');
}

/**
 * formatDateTime()
 * @param value
 * @param format
 * @returns {string}
 */
export function formatDateTime(value, format = null) {
  if (format) {
    return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format);
  }
  return moment(value, 'YYYY-MM-DD HH:mm:ss').format('dddd, DD.MM.YYYY - HH:mm:ss');
}

/**
 * Format currency
 * TODO: Add locale currency to be used in different countries
 * @param price
 * @param nullIfEmpty
 */
export function formatCurrency(price, nullIfEmpty = false) {
  if (price !== undefined && price != null) {
    const formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
    return formatter.format(price);
  }

  if (nullIfEmpty) {
    return null;
  }
  return '0.00 €';
}

export function getDaysOfWeek() {
  const data = [];

  for (let i = 0; i < 7; i += 1) {
    data.push(moment().weekday(i));
  }

  return data;
}

/**
 * getDaysOfMonth()
 * @param month
 * @returns {[]}
 */
export function getDaysOfMonth(month) {
  let daysInMonth = moment(month).daysInMonth();
  const arrDays = [];

  while (daysInMonth) {
    const current = moment(month).date(daysInMonth);
    arrDays.push(current);
    daysInMonth -= 1;
  }

  arrDays.reverse();
  const weekEnd = moment(arrDays[arrDays.length - 1]);

  let startDateOfWeek = moment(arrDays[0]).startOf('week');

  const data = [];
  do {
    for (let j = 0; j < 7; j += 1) {
      data.push(moment(startDateOfWeek).weekday(j));
    }
    startDateOfWeek = moment(startDateOfWeek)
      .add(1, 'weeks')
      .startOf('week');
  } while (startDateOfWeek.isSameOrBefore(weekEnd, 'week'));

  return data;
}

/**
 * Get the device by key from availableDevices type
 * @param key
 * @returns {*}
 */
export function getDeviceType(key) {
  const deviceType = availableDevices.filter((item) => item.key === key);
  return deviceType[0];
}

export function isObjectIdentical(obj1, obj2) {
  return (JSON.stringify(obj1) === JSON.stringify(obj2));
}

/**
 * Check if the order is closed state
 * @param order
 * @returns {boolean}
 */
export function isOrderClosed(order) {
  switch (order.state) {
    case ORDERSTATE_FINISHED: return true;
    default: return false;
  }
}

/**
 * Check if monteur or team can be added to reclamation
 * @param reclamation
 * @returns {boolean}
 */
export function allowAddMonteurToReclamation(reclamation) {
  switch (reclamation.state) {
    case RECLAMATIONSTATE_SCHEDULED:
    case RECLAMATIONSTATE_ASSIGNED: return true;
    default: return false;
  }
}

/**
 * Check if reclamation is closed / finished
 * @param reclamation
 * @returns {boolean}
 */
export function isReclamationClosed(reclamation) {
  switch (reclamation.state) {
    case RECLAMATIONSTATE_CLOSED:
    case RECLAMATIONSTATE_REPORT_FINISHED: return true;
    default: return false;
  }
}

/**
 * Check if the current loggedin user is the owner of an external order
 * @param clientId
 * @param order
 * @returns {boolean}
 */
export function isExternalOrderOwner(clientId, order) {
  if (!order.internal) {
    return (order.clientId === clientId);
  }
  return true;
}

/**
 * Check if the current logged in user is the assignee for a external object of an order
 * @param clientId
 * @param order
 * @param object
 * @returns {boolean}
 */
export function isExternalObjectAssignee(clientId, order, object) {
  if (!order.internal) {
    return (object.assignedClientId === clientId);
  }
  return false;
}

/**
 * Check if the user / client has access to the given object
 * @param order
 * @param object
 * @param client
 * @returns {boolean}
 */
export function hasObjectAccess(order, object, client) {
  if (!order.internal) {
    // Orderstatus muss ungleich wartet auf bestätigung sein da ein Monteur für einen Auftrag der auf Berechnung wartet
    // ein Angebot abgeben kann!!!
    if (client.type === CLIENTTYPE_MONTEUR && order.state !== ORDERSTATE_WAITING_APPROVEMENT) {
      if ((!object.montageDate.clientId && (order.state === ORDERSTATE_OPEN
        || order.state === ORDERSTATE_WAITING_CALCULATION))
        || object.montageDate.clientId === client.clientId
      ) {
        return true;
      }
    }
    if (order.clientId === client.clientId) {
      return true;
    }
  } else if (
    order.clientId === client.clientId
    || (order.mandantId && order.mandantId === client.clientId)
    || ((object.montageDate.clientId === client.clientId)
      || (object.deliveryDate && object.deliveryDate.clientId === client.clientId)
      || (object.removalDate && object.removalDate.clientId === client.clientId)
      || (object.storageDate && object.storageDate.clientId === client.clientId)
    )
  ) {
    return true;
  }

  return false;
}

/**
 * Is external order. Monteur only has access if assignedClientId is not assigned or if the monteur is the
 * assignedClientId. If no assignedClientId is available the order is OPEN or WAITING
 * @param client
 * @param order
 * @returns {boolean}
 */
export function hasOrderAccess(client, order) {
  if (!order.internal) {
    // Orderstatus muss ungleich wartet auf bestätigung sein da ein Monteur für einen Auftrag der auf Berechnung wartet
    // ein Angebot abgeben kann!!!
    if (client.type === CLIENTTYPE_MONTEUR && order.state !== ORDERSTATE_WAITING_APPROVEMENT) {
      const firstObject = order.objects[0];
      if ((!firstObject.montageDate.clientId && (order.state === ORDERSTATE_OPEN
        || order.state === ORDERSTATE_WAITING_CALCULATION))
        || firstObject.montageDate.clientId === client.clientId
      ) {
        return true;
      }
    } else if (order.clientId === client.clientId) {
      return true;
    }
  } else if (order.clientId === client.clientId || (order.mandantId && order.mandantId === client.clientId)) {
    return true;
  }

  return false;
}

/**
 * @param client
 * @param order
 * @param service
 * @returns {boolean}
 */
export function hasServiceAccess(client, order, service) {
  return !!(order.clientId === client.clientId
    || (order.mandantId && order.mandantId === client.clientId)
    || (service.assignedClientId === client.clientId));
}

/**
 * Get monteur array for order / object
 * @param object
 * @param teams
 * @returns {*}
 */
export function getMonteureFromObject(object, teams) {
  let monteurArray = object.montageDate.users.map((item) => (`${item.firstname} ${item.lastname}`));
  if (object.montageDate.teamId && teams) {
    const team = teams.filter((item) => item.teamId === object.montageDate.teamId)[0];
    if (team && team.users) {
      const teamUsers = team.users.map((item) => `${item.firstname} ${item.lastname}`);
      monteurArray = [...monteurArray, ...teamUsers];
    }
  }

  return monteurArray;
}

/**
 * Get monteur array for order / object
 * @param service
 * @param teams
 * @returns {*}
 */
export function getMonteureFromService(service, teams) {
  let monteurArray = [];

  monteurArray = service.users.map((item) => (`${item.firstname} ${item.lastname}`));

  if (service.teamId && teams) {
    const team = teams.filter((item) => item.teamId === service.teamId)[0];
    if (team && team.users) {
      const teamUsers = team.users.map((item) => `${item.firstname} ${item.lastname}`);
      monteurArray = [...monteurArray, ...teamUsers];
    }
  }

  return monteurArray;
}

/**
 * Get the distance between two geo locations
 * @param latStart
 * @param lngStart
 * @param latEnd
 * @param lngEnd
 * @returns {string} Distance in km
 */
export function getDistanceFromGeoPoints(latStart, lngStart, latEnd, lngEnd) {
  // Radius of earth in KM
  const R = 6378.137;
  const dLat = ((latEnd * Math.PI) / 180) - ((latStart * Math.PI) / 180);
  const dLon = ((lngEnd * Math.PI) / 180) - ((lngStart * Math.PI) / 180);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos((latStart * Math.PI) / 180) * Math.cos((latEnd * Math.PI) / 180)
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d.toFixed(2);
}

/**
 * minutesToTimeString()
 * @param minutes
 * @returns {string}
 */
export function minutesToTimeString(minutes) {
  if (minutes) {
    if (minutes < 60) {
      return `${minutes} Min.`;
    }
    const rest = ((minutes % 60) / 0.6).toFixed(0);
    const hours = Math.floor(minutes / 60);
    return `${hours}.${rest} Std`;
  }
  return '';
}

/**
 * minutesToTimeString()
 * @param minutes
 * @returns {string}
 */
export function minutesToDaysString(minutes) {
  if (!minutes) {
    return 'Keine Zeit!';
  }

  const hours = Math.ceil(minutes / 60);
  const days = Math.ceil(hours / 4) * 0.5;

  return `${days} Tag${days !== 1 ? 'e' : ''}`.replace('.', ',');
}

/**
 * Get the default icons for orders.
 * @param order
 * @param client
 * @returns {JSX.Element}
 */
export function getOrderIcon(order, client) {
  let { type } = order;
  if (order.orderType) {
    type = order.orderType;
  }

  if (order.mandantId) {
    if (type === 'SINGLE') {
      return (
        <i className="fas fa-user-check text-info" title="Einzelauftrag erstellt von einem Partner" />
      );
    }
    return (
      <i className="fas fa-building text-info" title="Objektauftrag erstellt von einem Partner" />
    );
  }
  if (type === 'SINGLE') {
    if (!order.internal) {
      return <i className="fas fa-truck-moving" title="Externer Einzelauftrag" />;
    }
    if (order.clientId === client.clientId) {
      return <i className="fas fa-truck-moving" title="Interner Einzelauftrag" />;
    }
    return <i className="fas fa-hands-helping text-warning" title="Teilauftrag von einem Partner" />;
  }
  return <i className="far fa-building" title="Objektauftrag" />;
}

/**
 * Get the object from an order by the objectId
 * @param order
 * @param objectId
 * @returns {null|*}
 */
export function getObjectFromOrder(order, objectId) {
  const filtered = order.objects.filter((item) => item.objectId === parseInt(objectId, 0))[0];
  if (filtered) {
    return filtered;
  }
  return null;
}

/**
 * Check if an object is allowed to be splitted
 * @param order
 * @param object
 * @param clientId
 * @returns {boolean}
 */
export function canObjectBeSplitted(order, object, clientId) {
  if (object && order) {
    if (order.internal && order.clientId === clientId) {
      if (!object.splitted) {
        const option = [];
        if (order.optionRemoval) {
          option.push('removal');
        }
        if (order.optionDelivery) {
          option.push('delivery');
        }
        if (order.optionMontage) {
          option.push('montage');
        }

        if (option.length > 1) {
          return (
            object.montageDate.state !== OBJECT_DATESTATE_FINISHED
            && object.montageDate.state !== OBJECT_DATESTATE_CLOSED
          );
        }
      }
    }
  }
  return false;
}

/**
 * Check if an object can be unsplitted
 * @param order
 * @param object
 * @param clientId
 * @returns {boolean}
 */
export function canObjectBeUnsplitted(order, object, clientId) {
  if (object && order) {
    if (order.internal && order.clientId === clientId) {
      if (object.splitted) {
        const removalState = (object.removalDate) ? object.removalDate.state : 999;
        const deliveryState = (object.deliveryDate) ? object.deliveryDate.state : 999;
        const montageState = (object.montageDate) ? object.montageDate.state : 999;

        if (montageState === OBJECT_DATESTATE_CLOSED || montageState === OBJECT_DATESTATE_FINISHED) {
          return false;
        }
        if (removalState === OBJECT_DATESTATE_CLOSED || removalState === OBJECT_DATESTATE_FINISHED) {
          return false;
        }
        return !(deliveryState === OBJECT_DATESTATE_CLOSED || deliveryState === OBJECT_DATESTATE_FINISHED);
      }
    }
  }
  return false;
}

/**
 * Check if an order could be assigned / picked by / to an monteur
 * @param order
 * @param object
 * @param client
 * @returns {boolean}
 */
export function isExternalOrderAssignable(order, object, client) {
  if (order && object && client) {
    return (
      order
      && !order.internal
      && !object.montageDate.clientId
      && order.state === ORDERSTATE_OPEN
      && client.type === 'MONTEUR'
      && !isExternalOrderOwner(client.clientId, order)
    );
  }
  return false;
}

/**
 * Checks if an object is allowed to be deleted
 * @param order
 * @param object
 * @param client
 * @returns {boolean}
 */
export function isObjectDeletable(order, object, client) {
  if (order.internal) {
    if (order.state === ORDERSTATE_PARTNER_CREATED && order.mandantId === client.clientId) {
      return true;
    }
    if (order.clientId === client.clientId) {
      return !((object.deliveryDate && object.deliveryDate.state === OBJECT_DATESTATE_FINISHED)
        || (object.removalDate && object.removalDate.state === OBJECT_DATESTATE_FINISHED)
        || (object.montageDate && object.montageDate.state === OBJECT_DATESTATE_FINISHED));
    }
  }

  return false;
}

/**
 * get time difference in minutes formatted as hour string
 * @param startTime
 * @param endTime
 * @param inputFormat
 * @returns {`${string}h`}
 */
export const getMinuteDiff = (startTime, endTime, inputFormat = 'HH:mm') => {
  const result = moment(endTime, inputFormat)
    .diff(moment(startTime, inputFormat), 'minute');

  const minutes = result % 60;
  const hour = ((result - minutes) / 60).toFixed(0);
  return `${moment(`${hour}:${minutes}`, 'H:m').format('H:mm')}h`;
};

/**
 * Apply all filters to the objectArray
 * @param objects
 * @param filters
 * @returns {*}
 */
export function applyFiltersToObjectArray(objects, filters) {
  let filteredObjects = objects;
  Object.values(filters).forEach((filter) => {
    if (filter) {
      filteredObjects = objects.filter((object) => filter.selected[filter.getFilterParameter(object)]);
    }
  });
  return filteredObjects;
}

export const filterRemoveOwnStorageOrders = (orderArray, client) => orderArray.filter(
  (item) => !(item.type === OBJECT_DATE_TYPE_STORAGE
    && (item.clientId === client.clientId || item.mandantId === client.clientId)),
);

/**
 * filter orders that are more than 10 days in the past
 * @param orderArray
 */
export const filterMaxTenDayInThePastOrders = (orderArray) => orderArray.filter((item) => {
  if (item.date) {
    return moment(item.date, 'YYYY-MM-DD').isSameOrAfter(moment().subtract(10, 'days'));
  }
  return true;
});

/**
 * filter services that are more than 10 days in the past
 * @param serviceArray
 */
export const filterMaxTenDayInThePastService = (serviceArray) => serviceArray.filter((item) => {
  if (item.executionDate) {
    return moment(item.executionDate, 'YYYY-MM-DD').isSameOrAfter(moment().subtract(10, 'days'));
  }
  return true;
});

/**
 * check if the calculation results can be used to auto create an order
 * @param montagePrice
 * @param date
 * @param dateIsMandatory
 * @returns {false|boolean|boolean}
 */
export const checkCalculation = (montagePrice, date, dateIsMandatory = false) => {
  const priceCheck = montagePrice > 600;
  const dateCheck = date ? moment().add(35, 'days').isBefore(moment(date, 'YYYY-MM-DD')) : !dateIsMandatory;

  return priceCheck && dateCheck;
};
