import axios from '../../Library/Axios';

export const defaultUri = 'client/{clientId}/invite';

export const CLIENTINVITE_CREATE_START = 'CLIENTINVITE_CREATE_START';
export const CLIENTINVITE_CREATE_SUCCESS = 'CLIENTINVITE_CREATE_SUCCESS';
export const CLIENTINVITE_CREATE_ERROR = 'CLIENTINVITE_CREATE_ERROR';

/**
 * Create a new clientInvite
 * create()
 * @param userData
 * @param createdClientId
 * @returns {function(*): Promise<any | never>}
 */
export function create(userData, createdClientId) {
  return (dispatch) => {
    dispatch({ type: CLIENTINVITE_CREATE_START, payload: userData });
    const config = {
      params: { action: 'create' },
    };
    const uri = `${defaultUri.replace('{clientId}', createdClientId)}`;
    return axios().post(uri, userData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTINVITE_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTINVITE_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENTINVITEAUTH_CREATE_START = 'CLIENTINVITEAUTH_CREATE_START';
export const CLIENTINVITEAUTH_CREATE_SUCCESS = 'CLIENTINVITEAUTH_CREATE_SUCCESS';
export const CLIENTINVITEAUTH_CREATE_ERROR = 'CLIENTINVITEAUTH_CREATE_ERROR';

/**
 * authenticate clientInvite login
 * @param mail
 * @param password
 * @returns {function(*): Promise<any | never>}
 */
export function auth(mail, password) {
  return (dispatch) => {
    dispatch({ type: CLIENTINVITEAUTH_CREATE_START, payload: mail + password });
    const uri = '/auth';
    const config = {
      params: { action: 'clientInvite' },
    };
    return axios().post(uri, { mail, password }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTINVITEAUTH_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTINVITEAUTH_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENTINVITEGETMAIL_CREATE_START = 'CLIENTINVITEGETMAIL_CREATE_START';
export const CLIENTINVITEGETMAIL_CREATE_SUCCESS = 'CLIENTINVITEGETMAIL_CREATE_SUCCESS';
export const CLIENTINVITEGETMAIL_CREATE_ERROR = 'CLIENTINVITEGETMAIL_CREATE_ERROR';
/**
   * authenticate clientInvite login
   * @returns {function(*): Promise<any | never>}
   * @param hash
   *
   */
export function getMail(hash) {
  return (dispatch) => {
    dispatch({ type: CLIENTINVITEGETMAIL_CREATE_START, payload: hash });
    const uri = `${defaultUri.replace('{clientId}', 0)}`;
    const config = {
      params: { action: 'mail' },
    };
    return axios().post(uri, { hash }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTINVITEGETMAIL_CREATE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTINVITEGETMAIL_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}
