import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { checkCalculation, formatCurrency, minutesToDaysString } from '../../../Library/Functions';

export default function ShowHkEstimation(props) {
  const {
    assemblyTime, price, deliveryDate, headline, loading, updating, errors, montagePrice,
  } = props;

  const getDate = (date) => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
  const getValidDate = () => moment().add(36, 'days').format('DD.MM.YYYY');

  const renderLoadingInfo = () => (
    <div>
      <i className="fas fa-spinner fa-spin" style={{ marginRight: 10 }} />
      Angebot wird aktuallisiert
    </div>
  );

  const renderUpdateInfo = () => (
    <div>
      <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
      Angebot nicht aktuell
    </div>
  );

  const renderInfo = () => {
    if (loading) {
      return renderLoadingInfo();
    }
    if (updating && (assemblyTime && price)) {
      return renderUpdateInfo();
    }
    return null;
  };

  const renderErrorHint = () => ((Object.keys(errors).length > 0) ? (
    <div>
      <br />
      <div className="alert alert-danger">
        <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
        Bitte füllen Sie erst alle benötigten Angaben zum Auftrag aus um eine Schätzung zu erhalten!
      </div>
    </div>
  ) : null);

  const renderLoadingHint = () => (loading ? (
    <div>
      <br />
      <div className="alert alert-info">
        <i className="fas fa-spinner fa-spin" style={{ marginRight: 10 }} />
        Ihr persönliches Angebot wird berechnet.
      </div>
    </div>
  ) : null);

  const renderTimeHint = () => {
    if (assemblyTime > 30 * 8 * 60) {
      return (
        <small style={{ fontSize: '16px' }} className="text-danger">
          Die Montagedauer ist sehr hoch, bitte kontrollieren Sie nocheinmal Ihre Angaben!
        </small>
      );
    }
    return (
      <small style={{ fontSize: '16px' }} className="text-muted">
        Diese Schätzung betrifft nicht die Preisindikation. Sollte die tatsächliche Montagedauer geringer als die
        Schätzung sein, hat dies keine Auswirkung auf den Festpreis.
      </small>
    );
  };

  const renderPriceHeadline = () => (deliveryDate ? (
    <h5 className="text-muted">
      Ihr persönliches Angebot für eine Montage am
      <strong className="text-body">
        {' '}
        {getDate(deliveryDate)}
      </strong>
    </h5>
  ) : (
    <>
      <h5 className="text-muted" style={{ marginBottom: '0px' }}>
        Ihr persönliches Angebot für eine Montage ab dem
        <strong className="text-body">
          {' '}
          {getValidDate()}
        </strong>
      </h5>
      <small style={{ fontSize: '16px' }} className="text-muted">
        Ein frührer Montagetermin ist gegebenfalls zu anderen Konditionen möglich.
      </small>
    </>
  ));

  const renderManualHint = () => {
    if (!checkCalculation(montagePrice, deliveryDate, true)) {
      return ((
        <div className="d-flex flex-row justify-content-center">
          <div style={{ textAlign: 'center', width: '100%' }}>
            <h3>
              <i className="fas fa-user-check text-info" style={{ marginTop: '10px', marginRight: '10px' }} />
              Ihr Angebot wird manuell berechnet!
            </h3>
            <p>
              Vielen Dank für Ihre Anfrage. Aufgrund einiger Parameter müssen wir Ihre Anfrage manuell prüfen.
              Sobald Ihr Angebot berechnet wurde erhalten Sie eine Mitteilung und können das Angebot einsehen!
            </p>
          </div>
        </div>
      ));
    }
    return null;
  };

  const renderPrice = () => (
    <>
      <div className="d-flex flex-column justify-content-center">
        <div style={{ fontSize: '40px' }} className="text-success">
          <strong>
            {formatCurrency(price)}
            *
          </strong>
        </div>
        <small style={{ fontSize: '12px' }} className="text-muted">(zzgl. gesetzlicher MwSt.)</small>
      </div>
      <br />
      <div>
        <small style={{ fontSize: '16px' }} className="text-muted">
          *Nacharbeiten / Reklamationsarbeiten: 50 Euro (Anfahrt) + 45 Euro pro Monteur pro Stunde
        </small>
      </div>
    </>
  );

  const renderEstimation = () => ((assemblyTime && price) ? (
    <div className="d-flex flex-row justify-content-center">
      <div style={{ textAlign: 'center', width: '100%' }}>
        <div>
          <h5 className="text-muted">
            Geschätze Dauer
          </h5>
          <div className="d-flex flex-column justify-content-center">
            <div style={{ fontSize: '40px' }} className="text-body">
              <strong className={`${(assemblyTime > 30 * 8 * 60) ? 'text-danger' : ''}`}>
                {'ca. '}
                {minutesToDaysString(assemblyTime)}
              </strong>
            </div>
          </div>
          {renderTimeHint()}
        </div>
        <hr />
        <br />
        <div>
          {renderPriceHeadline()}
          {renderManualHint() || renderPrice()}
        </div>
      </div>
    </div>
  ) : renderLoadingHint());

  return (
    <>
      <div className="d-flex flex-row">
        <div className="flex-grow-1">
          <h5>{headline}</h5>
        </div>
        {renderInfo()}
      </div>
      {renderErrorHint()}
      {renderEstimation()}
    </>
  );
}

ShowHkEstimation.propTypes = {
  assemblyTime: PropTypes.number,
  price: PropTypes.string,
  montagePrice: PropTypes.string,
  deliveryDate: PropTypes.string,
  headline: PropTypes.string,
  loading: PropTypes.bool,
  updating: PropTypes.bool,
  errors: PropTypes.instanceOf(Object),
};

ShowHkEstimation.defaultProps = {
  assemblyTime: null,
  price: null,
  montagePrice: '',
  deliveryDate: null,
  headline: 'hey.kitchen Schätzung',
  loading: false,
  updating: false,
  errors: {},
};
