import { isValidPhoneNumber, isValidZip } from '../../../../Library/Functions';

export const validationFields = {
  name: false,
  zip: false,
  city: false,
  street: false,
  streetNo: false,
  contactPhone: false,
  contactName: false,
};

/**
 * Check if loadingAddress input is valid
 * @param state
 * @returns {{zip, note, firstname, mail, city, streetNo, phone, street, floor, lastname}|boolean}
 */
export default function loadingAddressFormHasError(state) {
  const data = state.loadingAddress;
  let errorCodes = { ...validationFields };
  let hasError = false;

  if (state.internal && state.optionStorage && !state.storageClientId && state.mandantId === 0) {
    errorCodes = { ...errorCodes, storageClientId: true };
    hasError = true;
  } else {
    if (!data.name) {
      errorCodes = { ...errorCodes, name: true };
      hasError = true;
    }

    if (!data.zip) {
      errorCodes = { ...errorCodes, zip: true };
      hasError = true;
    } else if (!isValidZip(data.zip)) {
      errorCodes = {
        ...errorCodes,
        zip: true,
        zipMessage: 'Bitte geben Sie eine gültige Postleitzahl ein',
      };
      hasError = true;
    }

    if (!data.city) {
      errorCodes = { ...errorCodes, city: true };
      hasError = true;
    }
    if (!data.street) {
      errorCodes = { ...errorCodes, street: true };
      hasError = true;
    }
    if (!data.streetNo) {
      errorCodes = { ...errorCodes, streetNo: true };
      hasError = true;
    }

    if (!data.contactPhone) {
      errorCodes = { ...errorCodes, contactPhone: true };
      hasError = true;
    } else if (!isValidPhoneNumber(data.contactPhone)) {
      errorCodes = {
        ...errorCodes,
        contactPhone: true,
        contactPhoneMessage: 'Bitte geben Sie eine gültige Telefonnummer ein (+491712255668)',
      };
      hasError = true;
    }

    if (!data.contactName) {
      errorCodes = { ...errorCodes, contactName: true };
      hasError = true;
    }
  }

  if (hasError) {
    return errorCodes;
  }
  return false;
}
