import React, { useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import ImagePreviewOverlay from '../../../../Components/DeviceList/ImagePreviewOverlay';

/**
 * Overview()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function Measurement(props) {
  const {
    measurement,
  } = props;

  const [showImagePreview, setShowImagePreview] = useState(false);
  // TODO: const [selectedIndex, setSelectedIndex] = useState(null);

  const renderCutouts = () => {
    if (!measurement.cutouts || measurement.cutouts.length < 1) {
      return null;
    }
    return (
      <>
        {measurement.cutouts.map((cutout, index) => (
          <div key={cutout.cutoutId}>
            <h6>{`${index + 1}. Ausschnitt`}</h6>
            <KeyValueEditRow
              title="Oben"
              displayText={`${cutout.top} mm`}
              visible={(cutout.top)}
            />
            <KeyValueEditRow
              title="Unten"
              displayText={`${cutout.bottom} mm`}
              visible={(cutout.bottom)}
            />
            <KeyValueEditRow
              title="Links"
              displayText={`${cutout.left} mm`}
              visible={(cutout.left)}
            />
            <KeyValueEditRow
              title="Rechts"
              displayText={`${cutout.right} mm`}
              visible={(cutout.right)}
            />
            <KeyValueEditRow
              title="Anzahl der Steckdosen"
              displayText={`${cutout.socketCount}`}
              visible={(cutout.socketCount)}
            />
          </div>
        ))}
      </>
    );
  };

  const renderImage = () => {
    let imageSrc = measurement.documents.filter((document) => document.type === 'RAWIMAGE');
    if (!imageSrc || imageSrc.length < 1) {
      return null;
    }
    imageSrc = imageSrc[0].absolutePath;
    return (
      <div style={{ }}>
        <h6 style={{ textAlign: 'center' }}>Bild der Platte</h6>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <img
          alt="Bild der Platte"
          src={imageSrc}
          onClick={() => setShowImagePreview(true)}
          style={{ maxWidth: '100%' }}
        />
      </div>
    );
  };

  const renderSketch = () => {
    const imageSrc = measurement.documents.filter((document) => document.type === 'SKETCH')[0].absolutePath;
    return (
      <div style={{ paddingTop: 100 }}>
        <h6 style={{ textAlign: 'center' }}>Zeichnung</h6>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <img
          alt="Zeichnung"
          src={imageSrc}
          onClick={() => setShowImagePreview(true)}
          style={{ maxWidth: '100%' }}
        />
      </div>
    );
  };

  if (!measurement) {
    return null;
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ }}>
          {renderImage()}
          {renderSketch()}
        </div>
        <div style={{ width: '50%', minWidth: '50%' }}>
          <KeyValueEditRow
            title="Bezeichnung oder Ort der Nischenrückwand"
            displayText={measurement.name || 'Platte1'}
          />
          <KeyValueEditRow title="Zusätzliche Informationen" displayText={measurement.additionalInformation} />
          <h6>Gesamtmaße der Nischenrückwand</h6>
          <KeyValueEditRow title="Breite der Nische" displayText={`${measurement.width} mm`} />
          <KeyValueEditRow title="Höhe der Nische" displayText={`${measurement.height} mm`} />
          {renderCutouts()}
        </div>
      </div>
      <ImagePreviewOverlay
        visible={showImagePreview}
        onClose={() => setShowImagePreview(false)}
        documents={measurement.documents}
        hideIndex
      />
    </>
  );
}

Measurement.propTypes = {
  measurement: PropTypes.instanceOf(Object).isRequired,
};
