import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

/**
 * Button()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Button(props) {
  const {
    children, onClick, className, style, type, disabled, loading, title, id,
  } = props;

  const renderLoading = () => {
    if (loading) {
      return (
        <i className="fas fa-spin fa-spinner" />
      );
    }
    return null;
  };

  return (
    <button
      id={id}
      type="button"
      className={`ButtonContainer btn ${type} ${className}`}
      onClick={onClick}
      style={style}
      disabled={disabled}
      title={title}
    >
      {renderLoading()}
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  style: {},
  type: 'btn-primary',
  disabled: false,
  loading: false,
  title: '',
  id: null,
};
