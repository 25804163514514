import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../../../Components/Panel';
import { formatDate, formatTime } from '../../../../Library/Functions';
import KeyValueEditRow from '../../../../Components/KeyValueEditRow';
import { objectServiceTypeCaptions, objectServiceTypeOptions, timeOptions } from '../../../../Library/Types';
import { useAccessCanScheduleServices, useAccessIsServiceEditable } from '../../../../Library/AccessChecks';

/**
 * ServiceInformation()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ServiceInformation(props) {
  const {
    service, onChange, order, isExternalMonteur, isSystemView,
  } = props;

  const isEditable = useAccessIsServiceEditable(service) || isSystemView;
  const isTerminable = useAccessCanScheduleServices() || isSystemView;

  const noInfo = () => (<i className="text-muted">Keine Daten eingetragen</i>);

  return (
    <Panel marginBottom={20} className="ProjectInformation">
      <h6>Allgemeine Angaben zum Serviceauftrag</h6>
      <p>
        Hier sehen Sie alle allgemeine Angaben zum Serviceauftrag
      </p>

      <KeyValueEditRow
        title="Typ"
        displayText={(service.type) ? objectServiceTypeCaptions[service.type] : noInfo()}
        initialValue={service.type}
        actionIcon="fas fa-pencil-alt"
        onChange={(data) => onChange({ ...service, type: data })}
        type="select"
        selectData={objectServiceTypeOptions}
        editable={isEditable}
      />
      <KeyValueEditRow
        title="Zusätzliche Informationen / Beschreibung"
        displayText={(service.description) ? service.description : noInfo()}
        initialValue={service.description}
        actionIcon="fas fa-pencil-alt"
        onChange={(data) => onChange({ ...service, description: data })}
        type="textarea"
        editable={isEditable}
      />

      <div className="row">
        <div className="col-lg-6">
          <KeyValueEditRow
            title="Datum der Ausführung"
            displayText={(service.executionDate) ? formatDate(service.executionDate) : noInfo()}
            initialValue={service.executionDate}
            actionIcon="fas fa-pencil-alt"
            onChange={(data) => onChange({ ...service, executionDate: data })}
            onClear={() => onChange({ ...service, executionDate: '' })}
            type="date"
            editable={((order.internal && isEditable && isTerminable) || isSystemView)}
          />
        </div>
        <div className="col-lg-6">
          <KeyValueEditRow
            title="Ankunft Monteur beim Kunden"
            displayText={(service.startTime) ? `${formatTime(service.startTime)} Uhr` : noInfo()}
            initialValue={service.startTime}
            actionIcon="fas fa-pencil-alt"
            onChange={(data) => onChange({ ...service, startTime: data })}
            type="select"
            selectData={timeOptions}
            editable={((order.internal && isEditable && isTerminable) || isSystemView)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <KeyValueEditRow
            title="Geschätzte Arbeitszeit"
            displayText={`${service.assemblyTime || 0} Minuten`}
            initialValue={service.assemblyTime}
            actionIcon="fas fa-pencil-alt"
            onChange={(data) => onChange({ ...service, assemblyTime: data })}
            type="text"
            isNumeric
            editable={((isEditable || isExternalMonteur) && isTerminable)}
          />
        </div>
      </div>
    </Panel>
  );
}

ServiceInformation.propTypes = {
  service: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  isExternalMonteur: PropTypes.bool,
  isSystemView: PropTypes.bool,
};
ServiceInformation.defaultProps = {
  isExternalMonteur: false,
  isSystemView: false,
};
