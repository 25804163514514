import { useDispatch } from 'react-redux';
import React from 'react';
import axios from '../../Library/Axios';

const defaultUri = '/order/{orderId}/object/{objectId}/report';

/**
 * prepareUri()
 * @param orderId
 * @param objectId
 * @returns {string}
 */
function prepareUri(orderId, objectId) {
  return defaultUri.replace('{orderId}', orderId).replace('{objectId}', objectId);
}

export const SERVICEREPORT_GETPDF_START = 'SERVICEREPORT_GETPDF_START';
export const SERVICEREPORT_GETPDF_SUCCESS = 'SERVICEREPORT_GETPDF_SUCCESS';
export const SERVICEREPORT_GETPDF_ERROR = 'SERVICEREPORT_GETPDF_ERROR';

/**
 * getPdf()
 * @param orderId
 * @param objectId
 * @param serviceReportId
 * @param withImages
 * @returns {function(*): *}
 */
export function getPdf(orderId, objectId, serviceReportId, withImages = false) {
  return (dispatch) => {
    dispatch({ type: SERVICEREPORT_GETPDF_START });

    const config = {
      params: { action: 'pdf', withImages },
      responseType: 'arraybuffer',
    };
    const preparedUri = prepareUri(orderId, objectId);
    const uri = `${preparedUri}/${serviceReportId}`;

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/pdf' },
      );
      dispatch({ type: SERVICEREPORT_GETPDF_SUCCESS, payload: data });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: SERVICEREPORT_GETPDF_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const SERVICEREPORT_DOWNLOADIMAGES_START = 'SERVICEREPORT_DOWNLOADIMAGES_START';
export const SERVICEREPORT_DOWNLOADIMAGES_SUCCESS = 'SERVICEREPORT_DOWNLOADIMAGES_SUCCESS';
export const SERVICEREPORT_DOWNLOADIMAGES_ERROR = 'SERVICEREPORT_DOWNLOADIMAGES_ERROR';

/**
 * downloadImages()
 * @param orderId
 * @param objectId
 * @param serviceReportId
 * @returns {function(*): Promise<Blob | never>}
 */
export function downloadImages(orderId, objectId, serviceReportId) {
  return (dispatch) => {
    dispatch({ type: SERVICEREPORT_DOWNLOADIMAGES_START });

    const config = {
      params: { action: 'downloadImages' },
      responseType: 'arraybuffer',
    };
    const preparedUri = prepareUri(orderId, objectId);
    const uri = `${preparedUri}/${serviceReportId}`;

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/zip' },
      );
      dispatch({ type: SERVICEREPORT_DOWNLOADIMAGES_SUCCESS, payload: data });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: SERVICEREPORT_DOWNLOADIMAGES_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const SERVICEREPORT_GETLIST_START = 'SERVICEREPORT_GETLIST_START';
export const SERVICEREPORT_GETLIST_SUCCESS = 'SERVICEREPORT_GETLIST_SUCCESS';
export const SERVICEREPORT_GETLIST_ERROR = 'SERVICEREPORT_GETLIST_ERROR';

/**
 * getList()
 * @param orderId
 * @param objectId
 * @returns {function(*): Promise<Blob | never>}
 */
export function getList(orderId, objectId) {
  return (dispatch) => {
    dispatch({ type: SERVICEREPORT_GETLIST_START });

    const uri = prepareUri(orderId, objectId);

    return axios().get(uri).then((response) => {
      const { status } = response;
      const { data } = response.data;

      if (status === 200) {
        dispatch({ type: SERVICEREPORT_GETLIST_SUCCESS, payload: data });
        return Promise.resolve(data);
      }
      dispatch({ type: SERVICEREPORT_GETLIST_ERROR });
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: SERVICEREPORT_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const SERVICEREPORT_GET_START = 'SERVICEREPORT_GET_START';
export const SERVICEREPORT_GET_SUCCESS = 'SERVICEREPORT_GET_SUCCESS';
export const SERVICEREPORT_GET_ERROR = 'SERVICEREPORT_GET_ERROR';

/**
 * get()
 * @param orderId
 * @param objectId
 * @param serviceReportId
 * @returns {function(*): Promise<Blob | never>}
 */
export function get(orderId, objectId, serviceReportId) {
  return (dispatch) => {
    dispatch({ type: SERVICEREPORT_GET_START });

    const uri = `${prepareUri(orderId, objectId)}/${serviceReportId}`;

    return axios().get(uri).then((response) => {
      const { status } = response;
      const { data } = response.data;

      if (status === 200) {
        dispatch({ type: SERVICEREPORT_GET_SUCCESS, payload: data });
        return Promise.resolve(data);
      }
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: SERVICEREPORT_GET_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const SERVICEREPORT_GET_MEASUREMENTPDF_START = 'SERVICEREPORT_GET_MEASUREMENTPDF_START';
export const SERVICEREPORT_GET_MEASUREMENTPDF_SUCCESS = 'SERVICEREPORT_GET_MEASUREMENTPDF_SUCCESS';
export const SERVICEREPORT_GET_MEASUREMENTPDF_ERROR = 'SERVICEREPORT_GET_MEASUREMENTPDF_ERROR';

/**
 * useDispatchGetMeasurementPdf()
 * @returns {function(*, *=): *}
 */
export function useDispatchGetMeasurementPdf() {
  const dispatch = useDispatch();

  return React.useCallback((objectId) => {
    const config = {
      params: { action: 'pdf' },
      responseType: 'arraybuffer',
    };

    let uri = '/order/{orderId}/object/{objectId}/report/{reportId}/measurement';
    uri = uri.replace('{orderId}', '0')
      .replace('{objectId}', `${objectId}`)
      .replace('{reportId}', '0');

    dispatch({ type: SERVICEREPORT_GET_MEASUREMENTPDF_START, payload: { objectId, uri, config } });

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/pdf' },
      );
      dispatch({ type: SERVICEREPORT_GET_MEASUREMENTPDF_SUCCESS, payload: blobFile });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: SERVICEREPORT_GET_MEASUREMENTPDF_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
