import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { getList } from '../../../../Redux/Action/Client/EventsAction';
import Panel from '../../../../Components/Panel';
import { formatDateTime } from '../../../../Library/Functions';
import EventDetailsModal from './EventDetailsModal';

/**
 * EventList()
 * @param props
 * @returns {*}
 * @constructor
 */
function EventList(props) {
  const { dispatchGetList } = props;
  const [events, setEvents] = React.useState();
  const [selectedEvents, setSelectedEvents] = React.useState();
  const [showDetails, setShowDetails] = React.useState(false);

  React.useEffect(() => {
    dispatchGetList().then((data) => setEvents(data));

    const interval = setInterval(() => {
      dispatchGetList().then((data) => setEvents(data));
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatchGetList]);

  const renderStatus = (item) => {
    if (!item.responseCode) {
      return <i className="fas fa-spin fa-cog text-info" title="Wartet auf den Versand..." />;
    }
    if (parseInt(item.responseCode, 0) === 200) {
      return <i className="fas fa-check-circle text-success" title="Erfolgreich gesendet!" />;
    }
    return <i className="fas fa-ban text-danger" title="Fehler beim senden!" />;
  };

  const handleShowDetails = (item) => {
    if (item.sendDateTime) {
      setSelectedEvents(item);
      setShowDetails(true);
    }
  };

  const renderEvents = () => {
    if (events) {
      return events.map((item) => (
        <tr key={item.id.toString()} onClick={() => handleShowDetails(item)}>
          <td>{renderStatus(item)}</td>
          <td>
            {
              (item.sendDateTime)
                ? formatDateTime(item.sendDateTime)
                : <i className="text-muted">Wartet auf Versand...</i>
            }
          </td>
          <td>{item.eventType}</td>
          <td>
            {(item.sendDateTime) ? <i className="fas fa-info-circle text-muted" title="Details anzeigen" /> : null}
          </td>
        </tr>
      ));
    }
    return null;
  };

  const renderContent = () => {
    if (events && events.length > 0) {
      return (
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Status</th>
              <th>Gesendet am</th>
              <th>Typ</th>
              <th style={{ width: 25 }} />
            </tr>
          </thead>
          <tbody>
            {renderEvents()}
          </tbody>
        </table>
      );
    }
    return (
      <div className="alert alert-info">
        <i className="fas fa-info-circle" style={{ marginRight: 6 }} />
        Es wurden noch keine Events gesendet!
      </div>
    );
  };

  return (
    <Panel>
      <h5>Historie</h5>
      <p>
        Hier sehen Sie eine Übersicht der letzten 50 Events die von hey.kitchen an Ihr System gemeldet wurden.
      </p>
      {renderContent()}
      <EventDetailsModal visible={showDetails} onClose={() => setShowDetails(false)} item={selectedEvents} />
    </Panel>
  );
}

EventList.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
};

function mapStoreToProps(store) {
  return {
    settings: store.auth.client.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: () => dispatch(getList()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(EventList);
