import React from 'react';

import './style.css';

const mac01 = require('../../../../Assets/Images/Backend/macbook01.png');
const mac02 = require('../../../../Assets/Images/Backend/macbook02.png');
const mac03 = require('../../../../Assets/Images/Backend/macbook03.png');
const softwareArrow = require('../../../../Assets/Images/pfeil_gelb.png');

/**
 * HowItWorks()
 * @returns {*}
 * @constructor
 */
export default function HowItWorks() {
  return (
    <div
      className="HowItWorks FullPageBox Light YellowArrow Center"
      style={{ backgroundImage: `url(${softwareArrow})` }}
    >
      <div className="container d-flex flex-column">

        <div className="BoxContainer Right">
          <div className="ImageContainer Top">
            <img src={mac02} alt="Screen1" style={{ width: '100%' }} />
          </div>
          <div className="TextContainer">
            <h2>Auftrag einstellen</h2>
            <p>
              Stelle ganz einfach Montageaufträge ein. Dadurch wird der gesamte Prozess digitalisiert. Alle
              wichtigen Informationen zum Auftrag an einer Stelle von überall einsehbar.
              <br />
              <br />
              Die Monteure vor Ort benötigen kein Stift und Papier mehr. Es kann nichts verloren gehen. Alles
              nachvollziehbar.
            </p>
          </div>
          <div className="ImageContainer Bottom">
            <img src={mac02} alt="Screen1" style={{ width: '100%' }} />
          </div>
        </div>

        <div className="BoxContainer Left">
          <div className="ImageContainer Top">
            <img src={mac01} alt="Screen1" style={{ width: '100%' }} />
          </div>
          <div className="TextContainer">
            <h2>Monteure hinzufügen</h2>
            <p>
              Ganz einfach einem Auftrag einzelne Monteure oder ganze Teams zuordnen. So wissen die Monteure dank
              der Monteurapp immer welche Aufträge erledigt werden müssen.
              <br />
              <br />
              Keine langen Absprachen mehr vor und nach dem Arbeitsalltag. Keine Dikussionen und Missverständnisse
              mehr. Monteure sehen direkt Ihre Aufträge für die nächsten Tage.
            </p>
          </div>
          <div className="ImageContainer Bottom">
            <img src={mac01} alt="Screen1" style={{ width: '100%' }} />
          </div>
        </div>

        <div className="BoxContainer Right">
          <div className="ImageContainer Top">
            <img src={mac03} alt="Screen1" style={{ width: '100%' }} />
          </div>
          <div className="TextContainer">
            <h2>Digitale Abwicklung</h2>
            <p>
              Die komplette Bearbeitung des Auftrags kann in der Monteurapp abgewickelt werden. Für die Monteure
              sind alle Informationen einsehbar. Der Montagebericht wird automatisch generiert und auch die
              Unterschrift ist digital.
              <br />
              <br />
              Keine Rückfragen mehr durch unleserliche Montageberichte oder fehlende Informationen.
            </p>
          </div>
          <div className="ImageContainer Bottom">
            <img src={mac03} alt="Screen1" style={{ width: '100%' }} />
          </div>
        </div>
      </div>
    </div>
  );
}
