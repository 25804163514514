import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

/**
 * TextLink()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function TextLink(props) {
  const {
    icon, caption, onPress, style,
  } = props;

  const renderIcon = () => {
    if (icon) {
      return (
        <i className={icon} />
      );
    }
    return null;
  };

  return (
    <span className="TextLink" onClick={onPress} tabIndex={-1} role="button" onKeyPress={() => {}} style={style}>
      {renderIcon()}
      {caption}
    </span>
  );
}

TextLink.propTypes = {
  icon: PropTypes.string,
  caption: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.instanceOf(Object),
};

TextLink.defaultProps = {
  icon: null,
  style: {},
};
