import axios from '../../../../Library/Axios';

const defaultUri = '/system/order/{orderId}/log';

export const SYSTEM_ORDERLOG_GETLIST_START = 'SYSTEM_ORDERLOG_GETLIST_START';
export const SYSTEM_ORDERLOG_GETLIST_SUCCESS = 'SYSTEM_ORDERLOG_GETLIST_SUCCESS';
export const SYSTEM_ORDERLOG_GETLIST_ERROR = 'SYSTEM_ORDERLOG_GETLIST_ERROR';

/**
 * getList()
 * @param orderId
 * @returns {function(*): Promise<unknown>}
 */
export function getList(orderId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_ORDERLOG_GETLIST_START });

    const uri = defaultUri.replace('{orderId', orderId);

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_ORDERLOG_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_ORDERLOG_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
