import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Panel from '../../../../Components/Panel';
import BoxHideIcon from '../BoxHideIcon';
import { OBJECT_DATESTATE_OPEN, ORDERSTATE_PARTNER_CREATED } from '../../../../Library/StateTypes';
import ObjectDateStatusBadge from '../../../../Components/ObjectComponents/ObjectDateStatusBadge';
import DefaultRow from './DefaultRow';

/**
 * ObjectTableBox()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function InternalObjectStateBox(props) {
  const {
    orders, headline, rowCount, onClick, subHeadline, state, client,
    identifier, storageDashboardSettings, onHideBox,
  } = props;
  const [filtered, setFiltered] = React.useState(null);
  const [visibleByConfig, setVisibleByConfig] = React.useState(false);

  const executeSortOrderDependingOnState = React.useCallback((data) => {
    if (state === OBJECT_DATESTATE_OPEN) {
      return data.sort((a, b) => ((a.orderId > b.orderId) ? 1 : -1));
    }
    return data.sort((a, b) => {
      if (!a.date) {
        return -1;
      }
      if (!b.date) {
        return 1;
      }
      return (moment(a.date).isAfter(moment(b.date))) ? 1 : -1;
    });
  }, [state]);

  React.useEffect(() => {
    setVisibleByConfig((
      !storageDashboardSettings
      || storageDashboardSettings[identifier] === undefined
      || storageDashboardSettings[identifier] === true
    ));
  }, [identifier, storageDashboardSettings]);

  React.useEffect(() => {
    if (orders && state) {
      const filterData = orders.filter((item) => (
        item.state === state && item.orderState !== ORDERSTATE_PARTNER_CREATED
      ));
      setFiltered(executeSortOrderDependingOnState(filterData));
    }
  }, [orders, state, client, executeSortOrderDependingOnState]);

  const renderOrders = () => {
    const result = [];
    let currentCount = 1;

    if (filtered) {
      filtered.forEach((order) => {
        if (currentCount <= rowCount) {
          result.push(
            <DefaultRow
              key={order.dateId}
              order={order}
              onClick={(item) => onClick(item)}
              state={state}
              isIntern
              client={client}
            />,
          );
          currentCount += 1;
        }
      });
    }

    return result;
  };

  const renderSubHeadline = () => {
    if (subHeadline) {
      return (
        <div style={{ fontSize: 16, color: '#9f9f9f' }}>{subHeadline}</div>
      );
    }
    return null;
  };

  const renderHeadline = () => {
    if (React.isValidElement(headline)) {
      return headline;
    }
    return (
      <div className="d-flex flex-row">
        <div className="flex-grow-1">
          <h6 className="SecondaryHeadline" style={{ margin: 0 }}>
            {headline}
          </h6>
          {renderSubHeadline()}
        </div>
        <div>
          <ObjectDateStatusBadge objectDateState={state} />
        </div>
      </div>
    );
  };

  const renderDeliveryDateHeadline = () => {
    if (state !== OBJECT_DATESTATE_OPEN) {
      return (<th>Ausführungsdatum</th>);
    }
    return null;
  };

  if (filtered && filtered.length > 0 && visibleByConfig) {
    return (
      <div style={{ display: 'flex' }}>
        <Panel marginBottom={25} containerStyle={{ flex: 1 }}>
          <BoxHideIcon onPress={() => onHideBox(identifier)} />

          {renderHeadline()}

          <table className="table table-hover linked" style={{ marginTop: 25 }}>
            <thead>
              <tr>
                <th>Typ</th>
                {renderDeliveryDateHeadline()}
                <th>Kommission</th>
                <th>Plz & Ort</th>
                <th className="text-center">Optionen</th>
                <th className="text-right">Aufwand (ca.)</th>
              </tr>
            </thead>
            <tbody>
              {renderOrders()}
            </tbody>
          </table>
        </Panel>
      </div>
    );
  }
  return null;
}

InternalObjectStateBox.propTypes = {
  orders: PropTypes.instanceOf(Array),
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rowCount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  subHeadline: PropTypes.string,
  state: PropTypes.number.isRequired,
  client: PropTypes.instanceOf(Object),
  identifier: PropTypes.string.isRequired,
  storageDashboardSettings: PropTypes.instanceOf(Object),
  onHideBox: PropTypes.func.isRequired,
};

InternalObjectStateBox.defaultProps = {
  orders: [],
  client: null,
  headline: '',
  rowCount: 100,
  subHeadline: null,
  storageDashboardSettings: null,
};
