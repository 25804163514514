import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { formatCurrency } from '../../../../Library/Functions';
import Panel from '../../../../Components/Panel';
import {
  DEVICETYPE_DAMAGE,
  DEVICETYPE_RECLAMATION,
  DEVICEWORKTYPE_CUSTOMERPLUGGED,
  DEVICEWORKTYPE_PLUGGED,
  DEVICEWORKTYPE_UNPLUGGED,
} from '../../../../Library/Types';
import CheckBoxRow from '../../../../Components/ReportComponents/CheckBoxRow';

/**
 * Overview()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function Overview(props) {
  const { report, object } = props;
  const [pluggedDevices, setPluggedDevices] = useState([]);
  const [unpluggedDevices, setUnpluggedDevices] = useState([]);
  const [reclamations, setReclamations] = useState([]);
  const [damages, setDamages] = useState([]);
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    if (report) {
      setPluggedDevices(report.devices.filter((item) => item.workType === DEVICEWORKTYPE_PLUGGED));
      setUnpluggedDevices(report.devices.filter((item) => item.workType === DEVICEWORKTYPE_UNPLUGGED));
      setReclamations(report.devices.filter((item) => item.type === DEVICETYPE_RECLAMATION));
      setDamages(report.devices.filter((item) => item.type === DEVICETYPE_DAMAGE));

      const customerDevices = report.devices.filter((item) => (
        item.workType === DEVICEWORKTYPE_CUSTOMERPLUGGED
      ));
      setCustomer(customerDevices);
    }
  }, [report]);

  const renderCustomerDeviceHint = () => {
    if (customer.length > 0) {
      return (
        <CheckBoxRow visible type="information">
          Der Einbau kundeneigener Geräte erfolgt ohne jegliche Garantieansprüche!
        </CheckBoxRow>
      );
    }
    return null;
  };

  const renderPlugHint = () => {
    if (object) {
      if (object.workOptionWater && !object.workOptionElectric && !object.workOptionLight && !object.workOptionAir) {
        return (
          <CheckBoxRow visible>
            Alle Wasseranschlüsse wurden im Beisein des Kunden auf Dichtigkeit überprüft.
          </CheckBoxRow>
        );
      }
      if (object.workOptionWater || object.workOptionElectric || object.workOptionLight || object.workOptionAir) {
        return (
          <CheckBoxRow visible>
            Alle Anschlüsse wurden im Beisein des Kunden überprüft.
          </CheckBoxRow>
        );
      }
    }
    return null;
  };

  if (report && report.finished) {
    return (
      <Panel marginBottom={25}>
        <h6>Übersicht</h6>
        <p style={{ marginBottom: 30 }}>
          Hier sehen Sie eine Zusammenfassung des Montageberichts!
        </p>
        <table className="table-borderless">
          <tbody>
            {renderPlugHint()}
            <CheckBoxRow visible={pluggedDevices.length === 0}>
              Es wurden keine mitgelieferten Geräte angeschlossen
            </CheckBoxRow>
            <CheckBoxRow visible={pluggedDevices.length > 0 && unpluggedDevices.length === 0}>
              Alle mitgelieferten Geräte wurden ordnungsgemäß angeschlossen
            </CheckBoxRow>
            <CheckBoxRow visible={reclamations.length === 0}>
              Es sind keine Beanstandungen vorhanden
            </CheckBoxRow>
            <CheckBoxRow visible={reclamations.length > 0} type="danger">
              <strong>Es sind Beanstandungen vorhanden</strong>
            </CheckBoxRow>
            <CheckBoxRow visible={damages.length === 0}>
              Es wurde kein Kundeneigentum beschädigt
            </CheckBoxRow>
            <CheckBoxRow visible={damages.length > 0} type="danger">
              <strong>Es wurde Kundeneigentum beschädigt</strong>
            </CheckBoxRow>

            {renderCustomerDeviceHint()}

            <CheckBoxRow visible={(report.customerRestValue === 0 && object.balance > 0)}>
              Der offene Restbetrag wurde komplett beglichen
            </CheckBoxRow>
            <CheckBoxRow visible={(report.customerRestValue > 0 && object.balance > 0)} type="danger">
              <strong>
                {'Es bleibt eine zu zahlender Restbetrag in Höhe '}
                {`von ${formatCurrency(report.customerRestValue)} offen`}
              </strong>
            </CheckBoxRow>
            <CheckBoxRow visible>
              Die einwandfreie Ausführung der Leistung wird hiermit bescheinigt.
            </CheckBoxRow>
          </tbody>
        </table>
      </Panel>
    );
  }
  return null;
}

Overview.propTypes = {
  report: PropTypes.instanceOf(Object),
  object: PropTypes.instanceOf(Object),
};

Overview.defaultProps = {
  report: null,
  object: null,
};
