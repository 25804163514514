import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import LoadingModal from '../../../Components/LoadingModal';
import Panel from '../../../Components/Panel';
import AddNote from '../Component/ClientNotes/AddNote';
import UpdateNote from '../Component/ClientNotes/UpdateNote';
import Button from '../../../Components/Button';
import {
  create, deleteNote, getList, update,
} from '../Redux/Action/ClientNoteAction';
import NoteList from '../Component/ClientNotes/NoteList';

/**
 * Notes()
 * @param props
 * @returns {*}
 * @constructor
 */
function Notes(props) {
  const {
    dispatchGetList, notes, dispatchCreate, dispatchDelete, dispatchUpdate,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [showAdd, setShowAdd] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const { clientId } = useParams();

  React.useEffect(() => {
    if (clientId) {
      setLoading(true);
      dispatchGetList(clientId).then(() => {
        setLoading(false);
      });
    }
  }, [dispatchGetList, clientId]);

  const handleCreate = ({ text, type, resubmissionDate }) => {
    const saveData = {
      text, type, resubmissionDate, clientId,
    };
    dispatchCreate(saveData).then(() => {
      setShowAdd(false);
    });
  };

  const handelUpdate = (noteData) => {
    dispatchUpdate(noteData).then(() => {
      setSelected(null);
    });
  };

  return (
    <div>
      <LoadingModal visible={loading} text="Aufträge werden geladen..." />
      <Panel marginBottom={25}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1 align-items-center d-flex">
            <h5 style={{ margin: 0 }}>
              Notizen / Bemerkungen
            </h5>
          </div>
          <div>
            <Button onClick={() => setShowAdd(true)}>
              <i className="fas fa-plus" style={{ margin: 0 }} />
            </Button>
          </div>
        </div>
      </Panel>

      <Panel>
        <NoteList
          notes={notes}
          onDelete={dispatchDelete}
          onUpdate={setSelected}
        />
      </Panel>

      <AddNote onSave={handleCreate} onClose={() => setShowAdd(false)} visible={showAdd} />
      <UpdateNote
        onClose={() => setSelected(null)}
        onUpdate={handelUpdate}
        visible={(selected !== null)}
        note={selected}
      />
    </div>
  );
}

Notes.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchCreate: PropTypes.func.isRequired,
  dispatchDelete: PropTypes.func.isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
  notes: PropTypes.instanceOf(Array),
};

Notes.defaultProps = {
  notes: null,
};

function mapStoreToProps(store) {
  return {
    notes: store.systemClientNote.notes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: (clientId) => dispatch(getList(clientId)),
    dispatchCreate: (note) => dispatch(create(note)),
    dispatchDelete: (note) => dispatch(deleteNote(note)),
    dispatchUpdate: (note) => dispatch(update(note)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Notes));
