import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import WeekDay from './WeekDay';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.theme.containerWidth}px;
`;

/**
 * UserContent()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function UserContent(props) {
  const {
    data, selectedDays, onClickAppointment, onClickHour, onClickDate,
  } = props;
  const [containerWidth, setContainerWidth] = React.useState();
  const theme = useTheme();

  React.useEffect(() => {
    const width = theme.cellWeekDayWidth * selectedDays.length;
    setContainerWidth(width);
  }, [theme, selectedDays]);

  return (
    <Container theme={{ ...theme, containerWidth }}>
      {selectedDays.map((item) => (
        <WeekDay
          key={item}
          selectedDay={item}
          data={data}
          onClickAppointment={onClickAppointment}
          onClickHour={onClickHour}
          onClickDate={onClickDate}
        />
      ))}
    </Container>
  );
}

UserContent.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  selectedDays: PropTypes.instanceOf(Array).isRequired,
  onClickAppointment: PropTypes.func.isRequired,
  onClickHour: PropTypes.func.isRequired,
  onClickDate: PropTypes.func.isRequired,
};
