import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, useTheme } from 'styled-components';
import moment from 'moment';
import LeftCaptionContainer from '../LeftCaptionContainer';
import UserContent from './UserContent';
import WorkLoadContainer from '../WorkLoadContainer';
import { VIEW_WEEK } from '../../index';
import WeekViewHeadline from '../WeekViewHeadline';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  
  .Row {
    display: flex;
    flex-direction: row;
  }
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidth}px;
  overflow-x: scroll;
  border-right: ${(props) => props.theme.border.width}px solid ${(props) => props.theme.color.backgroundGrayLight};
`;

/**
 * WeekView()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function WeekView(props) {
  const {
    data, selectedRange, onClickAppointment, onRangeChange, onClickHour, onClickDate,
  } = props;
  const [scrollContainerWidth, setScrollContainerWidth] = React.useState(1000);
  const [dates, setDates] = React.useState(null);
  const theme = useTheme();

  React.useEffect(() => {
    const daysBetween = Math.abs(moment(selectedRange[0]).diff(moment(selectedRange[1]), 'days'));
    const datesParam = [];
    for (let i = 0; i <= daysBetween; i += 1) {
      const param = moment(selectedRange[0]).add(i, 'day');
      datesParam.push(param.format('YYYY-MM-DD'));
    }
    setDates(datesParam);
  }, [selectedRange]);

  const updateWindowSize = React.useCallback(() => {
    setScrollContainerWidth(window.innerWidth - 450);
  }, []);

  React.useEffect(() => {
    updateWindowSize();
    window.addEventListener('resize', updateWindowSize);

    return () => window.removeEventListener('resize', updateWindowSize);
  }, [updateWindowSize]);

  if (dates) {
    return (
      <ThemeProvider
        theme={{ ...theme, containerWidth: scrollContainerWidth }}
      >
        <Container>
          <WeekViewHeadline
            onChange={onRangeChange}
            startDateProp={selectedRange[0]}
            endDateProp={selectedRange[1]}
          />
          <div className="Row">
            <LeftCaptionContainer
              data={data}
              viewType={VIEW_WEEK}
            />
            <WorkLoadContainer data={data} startDate={selectedRange[0]} endDate={selectedRange[1]} viewType="week" />
            <div className="test" />
            <ScrollContainer>
              <UserContent
                data={data}
                selectedDays={dates}
                onClickAppointment={onClickAppointment}
                onClickHour={onClickHour}
                onClickDate={onClickDate}
              />
            </ScrollContainer>
          </div>
        </Container>
      </ThemeProvider>
    );
  }
  return null;
}

WeekView.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  selectedRange: PropTypes.instanceOf(Array).isRequired,
  onClickAppointment: PropTypes.func.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  onClickHour: PropTypes.func.isRequired,
  onClickDate: PropTypes.func.isRequired,
};
