import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { getOpenExternal, getWaitingExternal, assign } from '../../Redux/Action/OrderAction';
import { checkIsAllowedToPickOrder, getOrderOffers } from '../../Redux/Action/ClientAction';
import Panel from '../../Components/Panel';
import FindExternalOrderTable from '../../Components/FindExternalOrderTable';
import PickerOrderApproveDialog from './Details/Components/PickerOrderApproveDialog';
import { getList as getClientAddresses } from '../../Redux/Action/ClientAddressAction';
import LoadingModal from '../../Components/LoadingModal';
import { CLIENTTYPE_MONTEUR } from '../../Library/Types';
import FindExternalOrderFilter from '../../Components/FindExternalOrderTable/Filter';
import Button from '../../Components/Button';
import FindExternalOrderMap from '../../Components/FindExternalOrderMap';
import ShowHideContainer from '../../Components/ShowHideContainer';
import { ORDERSTATE_OPEN, ORDERSTATE_WAITING_APPROVEMENT } from '../../Library/StateTypes';
import ApproveAgb from '../../Components/Agb/ApproveAgb';

/**
 * SearchOrders()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function FindExternalOrders(props) {
  const {
    openAndWaitingExternal, client, dispatchGetOpenExternal, history, dispatchIsAllowedToPickOrders,
    dispatchAssignOrder, dispatchGetWaitingExternal, orderOffers, dispatchGetClientAddresses, clientAddresses,
    dispatchGetOrderOffers,
  } = props;
  const [isAllowed, setIsAllowed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  const [filtered, setFiltered] = useState(null);
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    setIsLoadingOrders(true);
    dispatchGetClientAddresses();
    dispatchGetOrderOffers();
    dispatchGetOpenExternal().then(() => {
      dispatchGetWaitingExternal().then(() => {
        dispatchIsAllowedToPickOrders().then(() => {
          setIsLoadingOrders(false);
          setIsAllowed(true);
        }).catch(() => {
          setIsAllowed(false);
          setIsLoadingOrders(false);
        });
      });
    });
  }, [
    dispatchGetOpenExternal,
    dispatchIsAllowedToPickOrders,
    dispatchGetWaitingExternal,
    dispatchGetClientAddresses,
    dispatchGetOrderOffers,
  ]);

  useEffect(() => {
    setFiltered(openAndWaitingExternal);
  }, [openAndWaitingExternal]);

  const handleClick = (clickedOrder) => {
    switch (clickedOrder.state) {
      case ORDERSTATE_WAITING_APPROVEMENT: {
        history.push(`/order/${clickedOrder.orderId}/approve`);
        return;
      }
      default: {
        history.push(`/order/${clickedOrder.orderId}/handle`);
      }
    }
  };

  const handleOnAction = (orderParam) => {
    if (orderParam.state === ORDERSTATE_OPEN) {
      setSelectedOrder(orderParam);
      setShowModal(true);
    } else {
      history.push(`/order/${orderParam.orderId}/handle`);
    }
  };

  const handleOnPickOrder = (addressId) => {
    setIsLoading(true);
    setShowModal(false);
    dispatchAssignOrder(selectedOrder, addressId).then(() => {
      setSelectedOrder(null);
      setIsLoading(false);
      history.push(`/order/${selectedOrder.orderId}/handle`);
    });
  };

  const renderContent = () => {
    if (!showMap) {
      return (
        <>
          <Panel>
            <FindExternalOrderTable
              onClick={handleClick}
              orders={filtered}
              actionCaption={(isAllowed) ? 'Annehmen' : null}
              onAction={handleOnAction}
              client={client}
              openOffers={orderOffers}
            />
          </Panel>
        </>
      );
    }
    return (
      <FindExternalOrderMap
        client={client}
        orders={filtered}
        clientAddresses={clientAddresses}
        onPressOrder={handleClick}
      />
    );
  };

  const renderMissingInformationHint = () => {
    if (!isAllowed && !isLoadingOrders) {
      return (
        <div className="alert alert-hk-primary d-flex flex-row">
          <div>
            <i className="fa fa-exclamation-circle" style={{ marginRight: 10 }} />
          </div>
          <div>
            Bevor Sie Aufträge übernehmen können, müssen Sie noch einige Informationen in den Einstellungen
            bereitstellen! Wir benötigen Ihre vollständige Adresse inkl. Ihrer Umsatzsteuer-Identifikationsnummer
            sowie Ihr Firmen-Logo und mindestens eine Lageradresse.
            <br />
            <NavLink className="alert-link" to="/admin/client" style={{ textDecoration: 'underline' }}>
              Einstellungen öffnen
            </NavLink>
          </div>
        </div>
      );
    }
    return null;
  };

  if (isLoadingOrders) {
    return <LoadingModal visible text="Aufträge werden geladen..." />;
  }

  if (client && client.type === CLIENTTYPE_MONTEUR && !client.approved) {
    return (
      <div className="row">
        <div className="col-lg-3" />
        <div className="col-lg-6">
          <div className="alert alert-hk-primary">
            <h5 style={{ color: '#fff' }}>
              <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
              Account noch nicht freigeschaltet!
            </h5>
            <div>
              Ihr Account ist noch nicht für die Nutzung externer Aufträge von hey.kitchen freigeschaltet,
              Die Freischaltung erfolgt nachdem Sie alle benötigen Informationen und Dokumente in den
              Firmeneinstellungen ergänzt und hochgeladen haben. Wir informieren Sie per E-Mail über den aktuellen
              Status der Freischaltung. In der Regel erfolgt die Freischaltung ca. 1-2 Stunden nachdem Sie alle
              benötigenten Informationen und Dokumente bereitgestellt haben.
            </div>
            <p>
              <br />
              <NavLink className="alert-link" to="/admin/client" style={{ textDecoration: 'underline' }}>
                <i className="fas fa-angle-double-right" style={{ marginRight: 10 }} />
                Einstellungen öffnen
              </NavLink>
            </p>
          </div>
        </div>
        <div className="col-lg-3" />
      </div>
    );
  }

  if (client && client.type === CLIENTTYPE_MONTEUR && !client.externalAgbApproved) {
    return (
      <div className="row">
        <div className="col-lg-3" />
        <div className="col-lg-6">
          <Panel marginBottom={30}>
            <div className="row">
              <ApproveAgb />
            </div>
          </Panel>
        </div>
        <div className="col-lg-3" />
      </div>
    );
  }

  if (client.type === CLIENTTYPE_MONTEUR) {
    if (filtered) {
      return (
        <>
          <Panel marginBottom={20}>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                <h4 className="hk-text-primary">Aufträge finden</h4>
                <p style={{ marginBottom: 0, paddingRight: 50 }}>
                  Finden Sie passende Aufträge. Durch die Deaktivierung der Filter werden die Aufträge ausgeblendet,
                  die die deaktivierte Option benötigen. So können Sie die Liste nach Ihren Wünschen passend
                  einstellen und filtern.
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <Button onClick={() => setShowMap(!showMap)} type="btn-lg btn-primary">
                  <ShowHideContainer visible={showMap}>
                    <i className="fas fa-bars" />
                    Listenansicht
                  </ShowHideContainer>
                  <ShowHideContainer visible={!showMap}>
                    <i className="fas fa-map-pin" />
                    Kartenansicht
                  </ShowHideContainer>
                </Button>
              </div>
            </div>
          </Panel>
          {renderMissingInformationHint()}

          <FindExternalOrderFilter
            orders={openAndWaitingExternal}
            onChange={setFiltered}
            clientAddresses={clientAddresses}
          />
          {renderContent()}

          <PickerOrderApproveDialog
            visible={showModal}
            order={selectedOrder}
            onCancel={() => setShowModal(false)}
            onSave={handleOnPickOrder}
          />
          <LoadingModal visible={isLoading} text="Auftrag wird übernommen..." />
        </>
      );
    }
    return null;
  }
  return <Redirect to="/dashboard" />;
}

FindExternalOrders.propTypes = {
  openAndWaitingExternal: PropTypes.instanceOf(Array),
  client: PropTypes.instanceOf(Object),
  dispatchGetOpenExternal: PropTypes.func.isRequired,
  dispatchGetWaitingExternal: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  dispatchIsAllowedToPickOrders: PropTypes.func.isRequired,
  dispatchAssignOrder: PropTypes.func.isRequired,
  dispatchGetClientAddresses: PropTypes.func.isRequired,
  dispatchGetOrderOffers: PropTypes.func.isRequired,
  orderOffers: PropTypes.instanceOf(Array),
  clientAddresses: PropTypes.instanceOf(Array),
};

FindExternalOrders.defaultProps = {
  openAndWaitingExternal: null,
  client: null,
  orderOffers: [],
  clientAddresses: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    orderOffers: store.client.offers,
    openAndWaitingExternal: store.order.openAndWaitingExternal,
    clientAddresses: store.clientAddress.addresses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetOpenExternal: () => dispatch(getOpenExternal()),
    dispatchGetWaitingExternal: () => dispatch(getWaitingExternal()),
    dispatchIsAllowedToPickOrders: () => dispatch(checkIsAllowedToPickOrder()),
    dispatchAssignOrder: (order, addressId) => dispatch(assign(order, addressId)),
    dispatchGetClientAddresses: () => dispatch(getClientAddresses()),
    dispatchGetOrderOffers: () => dispatch(getOrderOffers()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(FindExternalOrders));
