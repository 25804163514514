import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import ModalDialog from '../../../Components/ModalDialog';
import CustomReactSelect from '../../../Components/CustomReactSelect';
import { resetState } from '../Redux/Action/OrderAction';
import { get } from '../../../Redux/Action/OrderAction';
import {
  ORDERSTATE_OPEN,
  ORDERSTATE_WAITING_CALCULATION,
  ORDERSTATE_WAITING_APPROVEMENT,
} from '../../../Library/StateTypes';

const options2 = [
  { value: ORDERSTATE_WAITING_CALCULATION, label: 'Wartet auf Berechnung' },
  { value: ORDERSTATE_WAITING_APPROVEMENT, label: 'Wartet auf Bestätigung' },
];

const options3 = [
  { value: ORDERSTATE_WAITING_CALCULATION, label: 'Wartet auf Berechnung' },
];

/**
 * ResetOrderState()
 * @param props
 * @returns {*}
 * @constructor
 */
function ResetOrderState(props) {
  const {
    visible, onClose, order, dispatchResetState, dispatchGetOrder,
  } = props;
  const [options, setOptions] = React.useState([]);
  const [state, setState] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    if (order) {
      if (order.state === ORDERSTATE_OPEN) {
        setOptions(options2);
      } else if (order.state === ORDERSTATE_WAITING_APPROVEMENT) {
        setOptions(options3);
      } else {
        setOptions([]);
      }
    }
  }, [order]);

  const handleResetState = () => {
    setHasError(false);
    if (state > 0) {
      dispatchResetState(order.orderId, state).then(() => {
        dispatchGetOrder(order.orderId).then(() => {
          onClose();
        });
      });
    } else {
      setHasError(true);
    }
  };

  if (order) {
    return (
      <ModalDialog onClose={onClose} visible={visible} onConfirm={handleResetState}>
        <h5>Auftragsstatus ändern</h5>
        <p>
          Bitte dringend beachten!
          Beim ändern des Auftragsstatus werden alle für den neuen Auftrag nötigen Daten zurückgesetzt! Es werden
          ebenfalls neue Mitteilungen an die Monteure versendet und alte Angebote werden gelöscht. Sollte der Auftrag
          bereist zugewiesen sein wird ebenfalls der zugewiesene Monteur gelöscht und die dazugehörigen ggf. bereits
          zugewiesenen Monteure. Es wird keine Benachrichtigung versendet!
        </p>

        <div className="row" style={{ marginTop: 30 }}>
          <CustomReactSelect
            onChange={setState}
            value={state.toString()}
            options={options}
            rowClass="col-lg-12"
            hasError={hasError}
          />
        </div>
      </ModalDialog>
    );
  }
  return null;
}

ResetOrderState.propTypes = {
  dispatchGetOrder: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  dispatchResetState: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

ResetOrderState.defaultProps = {
  visible: false,
  order: null,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetState: (orderId, state) => dispatch(resetState(orderId, state)),
    dispatchGetOrder: (orderId) => dispatch(get(orderId, true)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(ResetOrderState);
