import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Panel from '../../../../Components/Panel';
import InfoBox from '../../../../Components/InfoBox';
import { isExternalOrderOwner } from '../../../../Library/Functions';

import './style.css';

/**
 * ReclamationMetadata()
 * @param props
 * @returns {*}
 * @constructor
 */
function ReclamationMetadata(props) {
  const {
    reclamation, storeTeams, storeUsers, isCreate, currentUser, order, client,
  } = props;

  const getUsersAndTeam = () => {
    let result;
    if (storeUsers) {
      if (reclamation.users) {
        result = reclamation.users.map((item) => {
          const user = storeUsers.filter((store) => store.userId === item.userId)[0];
          return (user) ? (`${user.firstname} ${user.lastname}`) : null;
        }).filter((data) => data);
      }
      if (storeTeams && reclamation.teamId) {
        const team = storeTeams.filter((item) => item.teamId === reclamation.teamId)[0];
        if (team) {
          result.push(team.name);
        }
      }

      if (result) {
        return result.reverse().join(', ');
      }
    }
    return '';
  };

  const getCreatedBy = () => {
    if (isCreate) {
      return `${currentUser.firstname} ${currentUser.lastname}`;
    }

    if (storeUsers) {
      if (reclamation.createdId) {
        const user = storeUsers.filter((item) => item.userId === reclamation.createdId)[0];
        if (user) {
          return `${user.firstname} ${user.lastname}`;
        }
      }
    }
    return 'System';
  };

  const renderAssemblyTimeBox = () => {
    if (order) {
      if (order.internal || !isExternalOrderOwner(client.clientId, order)) {
        return (
          <InfoBox icon="fas fa-clock" title="Geschätzte Arbeitszeit" text={`${reclamation.assemblyTime} Minuten`} />
        );
      }
    }
    return null;
  };

  const renderMonteurBox = () => {
    if (order) {
      if (order.internal || !isExternalOrderOwner(client.clientId, order)) {
        return <InfoBox icon="fas fa-users" title="Monteure / Team" text={getUsersAndTeam()} />;
      }
    }
    return null;
  };

  const renderCreatedByBox = () => {
    if (order) {
      if (order.internal || isExternalOrderOwner(client.clientId, order)) {
        return <InfoBox icon="fas fa-plus-circle" title="Erstellt von" text={getCreatedBy()} />;
      }
    }
    return null;
  };

  return (
    <Panel marginBottom={20} className="ProjectInformation">
      <h6>Informationen</h6>
      <p>
        Informationen zur Reklamation
      </p>

      <div className="InfoBoxContainer">
        {renderAssemblyTimeBox()}
        {renderMonteurBox()}
      </div>
      <div className="InfoBoxContainer" style={{ marginTop: 15 }}>
        {renderCreatedByBox()}
        <InfoBox icon="fas fa-bullseye" title="Anzahl" text={reclamation.devices.length} />
      </div>
    </Panel>
  );
}

ReclamationMetadata.propTypes = {
  reclamation: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  storeTeams: PropTypes.instanceOf(Array),
  storeUsers: PropTypes.instanceOf(Array),
  isCreate: PropTypes.bool,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object),
};

ReclamationMetadata.defaultProps = {
  storeTeams: null,
  storeUsers: null,
  isCreate: false,
  client: null,
};

function mapStoreToProps(store) {
  return {
    client: store.client.client,
    currentUser: store.auth.user,
    storeTeams: store.teams.teams,
    storeUsers: store.user.users,
  };
}

export default connect(mapStoreToProps, () => ({}))(ReclamationMetadata);
