import React from 'react';
import PropTypes from 'prop-types/prop-types';

/**
 * CheckBoxRow()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function CheckBoxRow(props) {
  const { children, visible, type } = props;

  const renderIcon = () => {
    if (type === 'success') {
      return (
        <i className="far fa-check-circle" style={{ color: 'green' }} />
      );
    }
    if (type === 'information') {
      return (
        <i className="fas fa-exclamation-circle" style={{ color: 'green' }} />
      );
    }
    return (
      <i className="fas fa-exclamation-circle" style={{ color: 'orange' }} />
    );
  };

  if (visible) {
    return (
      <tr>
        <td width={25}>
          {renderIcon()}
        </td>
        <td>{children}</td>
      </tr>
    );
  }
  return null;
}

CheckBoxRow.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['danger', 'success', 'information']),
};

CheckBoxRow.defaultProps = {
  type: 'success',
};
