import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { formatDate, formatDateTime } from '../../Library/Functions';
import { NAVIGATION_OBJECT_DETAILS, taskPrioCaption } from '../../Library/Types';
import { getList, update, deleteTask } from '../../Redux/Action/User/TaskAction';
import ModalDialog from '../ModalDialog';
import CircleContainer from './CircleContainer';
import TextLink from '../TextLink';

const Title = styled.div`
  color: #000;
`;

const SubCaption = styled.div`
  font-size: 13px;
  color: #7b7b7b;
`;

const DeleteIcon = styled.i`
  opacity: 0.15;
  
  &:hover {
    opacity: 0.8;
  }
`;

/**
 * TaskOverview()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TaskListRow(props) {
  const {
    task, user, dispatchUpdate, dispatchGetList, dispatchDelete, users, onPress, history, showObjectExternalId,
  } = props;
  const [askDelete, setAskDelete] = React.useState(false);
  const [isOverdue, setIsOverdue] = React.useState(false);

  React.useEffect(() => {
    let mappedTime = '09:00';
    if (task.dueTime) {
      mappedTime = task.dueTime;
    }

    const mapped = `${task.dueDate} ${mappedTime}`;
    if (moment(mapped).diff(moment(), 'minute') < 0) {
      setIsOverdue(true);
    } else {
      setIsOverdue(false);
    }
  }, [task]);

  const handleUpdate = (taskData) => {
    dispatchUpdate(user, taskData).then(() => dispatchGetList(user).then(() => {
      setTimeout(() => {
        dispatchGetList(user);
      }, 10000);
    }));
  };

  const handleDelete = () => {
    setAskDelete(false);
    dispatchDelete(user, task);
  };

  const getAssignedUser = () => {
    if (users) {
      if (task.assignedUserId !== user.userId && task.createdUserId === user.userId) {
        const userParam = users.filter((item) => item.userId === task.assignedUserId)[0];
        if (userParam) {
          return (
            <>
              <span> - </span>
              <span>{`Zugewiesen an: ${userParam.firstname} ${userParam.lastname}`}</span>
            </>
          );
        }
      }
      if (task.assignedUserId === user.userId && task.createdUserId !== user.userId) {
        const userParam = users.filter((item) => item.userId === task.createdUserId)[0];
        if (userParam) {
          return (
            <>
              <span> - </span>
              <span>{`Zugewiesen von: ${userParam.firstname} ${userParam.lastname}`}</span>
            </>
          );
        }
      }
    }
    return null;
  };

  const getPrio = () => `Prio: ${taskPrioCaption[task.priority]}`;

  const getDueTime = () => {
    if (task.dueTime) {
      const mapped = `${task.dueDate} ${task.dueTime}`;
      const dueTime = moment(mapped).format('HH:mm');
      return `${dueTime} Uhr`;
    }
    return '';
  };

  const getDate = () => {
    const mapped = `${task.dueDate} ${task.dueTime}`;
    const days = moment(task.dueDate).diff(moment(moment().format('YYYY-MM-DD')), 'days', false);

    if (days <= 7 && days >= 0) {
      switch (days) {
        case 0: return `Heute ${getDueTime()}`;
        case 1: return `Morgen ${getDueTime()}`;
        case 2: return `Übermorgen ${getDueTime()}`;
        case 3: return `In 3 Tagen ${getDueTime()}`;
        case 4: return `In 4 Tagen ${getDueTime()}`;
        case 5: return `In 5 Tagen ${getDueTime()}`;
        case 6: return `In 6 Tagen ${getDueTime()}`;
        case 7: return `In einer Woche ${getDueTime()}`;
        default: return '';
      }
    } else {
      if (task.dueTime) {
        return `${formatDateTime(mapped, 'DD.MM.YYYY - HH:mm')} Uhr`;
      }
      return formatDate(task.dueDate);
    }
  };

  const handleAskDelete = (e) => {
    e.stopPropagation();
    setAskDelete(true);
  };

  const renderDelete = () => {
    if (!task.finished && user.userId === task.createdUserId) {
      return <DeleteIcon className="fas fa-trash text-danger" onClick={handleAskDelete} />;
    }
    return null;
  };

  const renderPriority = () => {
    if (task.priority <= 2) {
      const color = (task.priority === 2) ? 'text-info' : 'text-danger';
      return <i className={`fas fa-exclamation ${color}`} style={{ fontSize: 12, marginRight: 3 }} />;
    }
    if (task.priority === 6) {
      return <i className="fas fa-long-arrow-alt-down" style={{ fontSize: 12, marginRight: 3 }} />;
    }
    return null;
  };

  const getTitle = () => {
    if (task.description && task.description.length > 0) {
      return (
        <>
          <span>{renderPriority()}</span>
          <span>{task.title}</span>
          <i
            className="fas fa-info-circle"
            style={{ marginLeft: 6, fontSize: 13, opacity: 0.5 }}
            title="Weitere Beschreibung / Information vorhanden"
          />
        </>
      );
    }
    return (
      <>
        <span>{renderPriority()}</span>
        <span>{task.title}</span>
      </>
    );
  };

  const navigateObject = (e) => {
    e.stopPropagation();
    const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', task.orderId)
      .replace('{objectId}', task.objectId);
    history.push(uri);
  };

  const renderExternalId = () => {
    if (task.objectExternalId && showObjectExternalId) {
      return (
        <small>
          <TextLink caption={task.objectExternalId} onPress={navigateObject} />
        </small>
      );
    }
    return null;
  };

  return (
    <tr onClick={() => onPress(task)} className={(isOverdue) ? 'table-danger' : null}>
      <td width={60} style={{ textAlign: 'center' }}>
        <CircleContainer task={task} onClick={handleUpdate} />
      </td>
      <td>
        <Title>{getTitle()}</Title>
        <SubCaption>
          <span>{`Fälligkeit: ${getDate()}`}</span>
          <span> - </span>
          <span>{getPrio()}</span>
          {getAssignedUser()}
        </SubCaption>
      </td>
      <td style={{ textAlign: 'right' }}>
        {renderExternalId()}
      </td>
      <td width={80} style={{ textAlign: 'right', paddingRight: 20 }}>
        {renderDelete()}
      </td>

      <ModalDialog
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        centered
        confirmCaption="Aufgabe löschen"
        onConfirm={handleDelete}
      >
        <h5>Aufgabe löschen?</h5>
        <p>
          Bitte bestätigen Sie das Sie die Aufgabe "
          <span>{task.title}</span>
          " endgültig löschen möchten!
        </p>
      </ModalDialog>
    </tr>
  );
}

TaskListRow.propTypes = {
  user: PropTypes.instanceOf(Object),
  task: PropTypes.instanceOf(Object).isRequired,
  dispatchUpdate: PropTypes.func.isRequired,
  dispatchGetList: PropTypes.func.isRequired,
  dispatchDelete: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
  showObjectExternalId: PropTypes.bool,
};

TaskListRow.defaultProps = {
  user: null,
  users: null,
  showObjectExternalId: true,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
    users: store.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdate: (user, task) => dispatch(update(user, task)),
    dispatchGetList: (user) => dispatch(getList(user)),
    dispatchDelete: (user, task) => dispatch(deleteTask(user, task)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(TaskListRow));
