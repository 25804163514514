import * as Redux from 'react-redux';
import React from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../../../Library/Axios';
import { applyFiltersToObjectArray } from '../../../../Library/Functions';

const defaultUri = '/system/reclamation';

export const SYSTEM_RECLAMATION_GETLIST_START = 'SYSTEM_RECLAMATION_GETLIST_START';
export const SYSTEM_RECLAMATION_GETLIST_SUCCESS = 'SYSTEM_RECLAMATION_GETLIST_SUCCESS';
export const SYSTEM_RECLAMATION_GETLIST_ERROR = 'SYSTEM_RECLAMATION_GETLIST_ERROR';

/**
 * getList()
 * @returns {function(*): Promise<any | never>}
 */
export function getList() {
  return (dispatch) => {
    dispatch({ type: SYSTEM_RECLAMATION_GETLIST_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_RECLAMATION_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_RECLAMATION_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const SYSTEM_RECLAMATION_GET_LIST_START = 'SYSTEM_RECLAMATION_GET_LIST_START';
export const SYSTEM_RECLAMATION_GET_LIST_SUCCESS = 'SYSTEM_RECLAMATION_GET_LIST_SUCCESS';
export const SYSTEM_RECLAMATION_GET_LIST_ERROR = 'SYSTEM_RECLAMATION_GET_LIST_ERROR';

/**
 * useDispatchGetServiceView()
 * @returns {function(): *}
 */
export const useDispatchGetReclamationView = () => {
  const dispatch = Redux.useDispatch();
  const target = 'reclamation';
  const uri = 'system/view/reclamation';
  return React.useCallback(() => {
    dispatch({ type: SYSTEM_RECLAMATION_GET_LIST_START, payload: { } });
    return axios().get(uri).then((response) => {
      const reclamations = response.data.data;
      const lastUpdated = new Date().getTime();
      dispatch({ type: SYSTEM_RECLAMATION_GET_LIST_SUCCESS, payload: { target, reclamations, lastUpdated } });
      return Promise.resolve(reclamations);
    }).catch((error) => {
      dispatch({ type: SYSTEM_RECLAMATION_GET_LIST_ERROR, payload: { error } });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};

export const SYSTEM_RECLAMATIONS_UPDATE_FILTERS = 'SYSTEM_RECLAMATIONS_UPDATE_FILTERS';

/**
 * updateFilters
 * @param newFilter
 * @returns {(function(*): void)|*}
 */
export function updateFilters(newFilter) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_RECLAMATIONS_UPDATE_FILTERS, payload: { newFilter } });
  };
}

export const SYSTEM_RECLAMATIONS_APPLY_FILTERS_START = 'SYSTEM_RECLAMATIONS_APPLY_FILTERS_START';
export const SYSTEM_RECLAMATIONS_APPLY_FILTERS_FINISHED = 'SYSTEM_RECLAMATIONS_APPLY_FILTERS_FINISHED';
export const SYSTEM_RECLAMATIONS_APPLY_FILTERS_SUCCESS = 'SYSTEM_RECLAMATIONS_APPLY_FILTERS_SUCCESS';

/**
 * Apply all filters to systemReclamations
 * @returns {(function(*): void)|*}
 */
export function useDispatchApplyReclamationFilters() {
  const dispatch = useDispatch();
  return React.useCallback((reclamations, filters, prevFilteredObjects) => {
    dispatch({ type: SYSTEM_RECLAMATIONS_APPLY_FILTERS_START, payload: { } });
    const filteredReclamations = applyFiltersToObjectArray(reclamations, filters);

    if (filteredReclamations
      && prevFilteredObjects
      && filteredReclamations.length === prevFilteredObjects.length
      && filteredReclamations.every(
        (object, index) => JSON.stringify(object) === JSON.stringify(prevFilteredObjects[index]),
      )) {
      dispatch({ type: SYSTEM_RECLAMATIONS_APPLY_FILTERS_FINISHED, payload: { } });
    } else {
      dispatch({ type: SYSTEM_RECLAMATIONS_APPLY_FILTERS_SUCCESS, payload: { filteredReclamations } });
    }
  }, [dispatch]);
}
