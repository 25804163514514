import { AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';
import {
  MANDANT_REQUEST_SUCCESS, MANDANT_DELETE_SUCCESS, MANDANT_UPDATE_SUCCESS, MANDANT_GETLIST_SUCCESS,
  MANDANT_APPROVE_SUCCESS,
} from '../Action/MandantAction';
import { CLIENTDOCUMENT_UPLOAD_SUCCESS, CLIENTDOCUMENT_DELETE_SUCCESS } from '../Action/ClientDocumentAction';
import {
  CLIENT_GETADDRESSES_SUCCESS,
  CLIENT_GETORDERS_START,
  CLIENT_GETORDERS_ERROR,
  CLIENT_GETORDERS_SUCCESS,
  CLIENT_GETRECLAMATIONS_START,
  CLIENT_GETRECLAMATIONS_ERROR,
  CLIENT_GETRECLAMATIONS_SUCCESS,
  CLIENT_UPDATE_START,
  CLIENT_UPDATE_ERROR,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_CHECKISALLOWEDTOPICKORDER_ERROR,
  CLIENT_CHECKISALLOWEDTOPICKORDER_SUCCESS,
  CLIENT_CHECKISALLOWEDTOPICKORDER_START,
  CLIENT_GET_SUCCESS,
  CLIENT_GETORDEROFFERS_START,
  CLIENT_GETORDEROFFERS_SUCCESS,
  CLIENT_GETORDEROFFERS_ERROR,
  CLIENT_GETOBJECTSERVICES_SUCCESS,
  CLIENT_GETOPENOFFERS_START,
  CLIENT_GETOPENOFFERS_SUCCESS,
  CLIENT_GETOPENOFFERS_ERROR,
} from '../Action/ClientAction';
import { CLIENT_MATERIAL_GETLIST_SUCCESS } from '../Action/Client/MaterialAction';
import { SYSTEM_CLIENT_LOGINAS_SUCCESS } from '../../Pages/System/Redux/Action/ClientAction';
import { CLIENTTYPE_MONTEUR } from '../../Library/Types';
import { FACILITY_GET_CLIENT_AUTH_SUCCESS } from '../Action/FacilityAction';

export const clientDefaultValues = {
  client: null,
  partners: null,
  activeMonteurPartners: null,
  orders: [],
  internalOrders: [],
  externalOrders: [],
  openOffers: [],
  reclamations: [],
  internalReclamations: [],
  externalReclamations: [],
  services: [],
  internalServices: [],
  externalServices: [],
  isLoading: false,
  isAllowedToPickOrders: null, // Check if all mandatory information is set to client
  offers: [], // All current open offers
  material: [],
};

export default function reduce(state = clientDefaultValues, action) {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        client: payload.client,
        partners: payload.partners,
      };
    }
    case FACILITY_GET_CLIENT_AUTH_SUCCESS:
    case SYSTEM_CLIENT_LOGINAS_SUCCESS: {
      const { payload } = action;
      return {
        ...clientDefaultValues,
        client: payload.client,
        partners: payload.partners,
        needReload: true,
      };
    }

    case CLIENT_GET_SUCCESS: {
      const { payload } = action;
      return { ...state, client: payload };
    }

    /**
     * Update client data to force some rerenders in navogation on document handling!
     */
    case CLIENTDOCUMENT_UPLOAD_SUCCESS:
    case CLIENTDOCUMENT_DELETE_SUCCESS: {
      return { ...state, client: { ...state.client } };
    }

    /**
     * UPDATE CLIENT
     */
    case CLIENT_UPDATE_START: {
      return { ...state, isLoading: true };
    }
    case CLIENT_UPDATE_SUCCESS: {
      return { ...state, isLoading: false, client: action.payload };
    }
    case CLIENT_UPDATE_ERROR: {
      return { ...state, isLoading: false };
    }

    case CLIENT_GETADDRESSES_SUCCESS: {
      const { payload } = action;
      return { ...state, client: { ...state.client, addresses: payload } };
    }

    /**
     * GET ORDERS
     */
    case CLIENT_GETORDERS_START: {
      return { ...state, isLoading: true };
    }
    case CLIENT_GETORDERS_SUCCESS: {
      const {
        orders, internalOrders, externalOrders,
      } = action.payload;

      if (orders) {
        return {
          ...state,
          isLoading: false,
          orders,
          internalOrders,
          externalOrders,
        };
      }
      return { ...state, internalOrders: [], externalOrders: [] };
    }
    case CLIENT_GETORDERS_ERROR: {
      return { ...state, isLoading: false };
    }

    /**
     * GET RECLAMATIONS
     */
    case CLIENT_GETRECLAMATIONS_START: {
      return { ...state, isLoading: true };
    }
    case CLIENT_GETRECLAMATIONS_SUCCESS: {
      if (action.payload) {
        const {
          reclamations, internalReclamations, externalReclamations,
        } = action.payload;

        return {
          ...state,
          reclamations,
          isLoading: false,
          externalReclamations,
          internalReclamations,
        };
      }
      return {
        ...state,
        reclamations: [],
        isLoading: false,
      };
    }
    case CLIENT_GETRECLAMATIONS_ERROR: {
      return { ...state };
    }

    /**
     * GET SERVICES
     */
    case CLIENT_GETOBJECTSERVICES_SUCCESS: {
      const {
        services, internalServices, externalServices,
      } = action.payload;
      return {
        ...state, services, internalServices, externalServices,
      };
    }

    /**
     * GET ORDER OFFERS
     */
    case CLIENT_GETORDEROFFERS_START: {
      return { ...state };
    }
    case CLIENT_GETORDEROFFERS_SUCCESS: {
      return { ...state, offers: action.payload };
    }
    case CLIENT_GETORDEROFFERS_ERROR: {
      return { ...state, offers: [] };
    }

    /**
     * GET OPEN OFFERS
     */
    case CLIENT_GETOPENOFFERS_START: {
      return { ...state };
    }
    case CLIENT_GETOPENOFFERS_SUCCESS: {
      return { ...state, openOffers: action.payload };
    }
    case CLIENT_GETOPENOFFERS_ERROR: {
      return { ...state, openOffers: [] };
    }

    /**
     * CHECK IF IS ALLOWED TO PICK ORDERS
     */
    case CLIENT_CHECKISALLOWEDTOPICKORDER_START: {
      return { ...state, isAllowedToPickOrders: null };
    }
    case CLIENT_CHECKISALLOWEDTOPICKORDER_ERROR: {
      return { ...state, isAllowedToPickOrders: false };
    }
    case CLIENT_CHECKISALLOWEDTOPICKORDER_SUCCESS: {
      return { ...state, isAllowedToPickOrders: true };
    }

    /**
     * MANDANT REQUEST CREATED FROM MANDANT ACTION
     */
    case MANDANT_DELETE_SUCCESS:
    case MANDANT_UPDATE_SUCCESS:
    case MANDANT_GETLIST_SUCCESS:
    case MANDANT_APPROVE_SUCCESS:
    case MANDANT_REQUEST_SUCCESS: {
      let activeMonteurPartners = null;
      if (action.payload) {
        activeMonteurPartners = action.payload.filter((item) => (
          item.partner.type === CLIENTTYPE_MONTEUR && item.active && item.approved
        ));
      }
      return { ...state, partners: action.payload, activeMonteurPartners };
    }

    /**
     * CLIENT MATERIAL
     */
    case CLIENT_MATERIAL_GETLIST_SUCCESS: {
      return { ...state, material: action.payload };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...clientDefaultValues };
    }

    default: return { ...state };
  }
}
