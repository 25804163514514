import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import * as Redux from 'react-redux';
import Panel from '../../../Components/Panel';
import OrderTable from '../../../Components/OrderTable';
import ReclamationTable from '../../../Components/ReclamationComponents/ReclamationTable';
import {
  NAVIGATION_OBJECT_DETAILS,
  NAVIGATION_OBJECT_SERVICE_DETAILS,
  NAVIGATION_RECLAMATION_DETAILS,
} from '../../../Library/Types';
import ShowHideContainer from '../../../Components/ShowHideContainer';

/**
 * RightDetailsBox()
 * @param props
 * @returns {*}
 * @constructor
 */
export default function RightDetailsBox(props) {
  const { currentDate } = props;
  const calendarData = Redux.useSelector((state) => state.calendar.orderServiceData);
  const { client } = Redux.useSelector((state) => state.client);
  const { monteurOnly } = Redux.useSelector((state) => state.user);
  const { teams } = Redux.useSelector((state) => state.teams);
  const [data, setData] = React.useState(null);
  const [services, setServices] = React.useState(null);
  const [objects, setObjects] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    if (calendarData) {
      const theData = (calendarData[currentDate]) ? calendarData[currentDate] : null;
      setData(theData);

      if (theData) {
        const serviceParam = theData.data.filter((param) => (param.serviceId));
        const objectParam = theData.data.filter((param) => (!param.serviceId));
        setServices(serviceParam || null);
        setObjects(objectParam || null);
      } else {
        setObjects(null);
        setServices(null);
      }
    }
  }, [calendarData, currentDate]);

  const handleReclamationClick = (reclamationOrService) => {
    if (reclamationOrService.serviceId) {
      const url = NAVIGATION_OBJECT_SERVICE_DETAILS.replace('{orderId}', reclamationOrService.orderId)
        .replace('{objectId}', reclamationOrService.objectId)
        .replace('{serviceId}', reclamationOrService.serviceId);
      history.push(url);
    } else {
      const url = NAVIGATION_RECLAMATION_DETAILS.replace('{orderId}', reclamationOrService.orderId)
        .replace('{objectId}', reclamationOrService.objectId)
        .replace('{reclamationId}', reclamationOrService.reclamationId);
      history.push(url);
    }
  };

  const handleOrderClick = (order) => {
    const url = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', order.orderId)
      .replace('{objectId}', order.objectId);
    history.push(url);
  };

  const renderPreviousOrders = () => {
    if (data && data.previousOrders && data.previousOrders.length > 0) {
      return (
        <Panel className="d-flex" marginBottom={20}>
          <h6 style={{ marginBottom: 30 }}>Fortlaufende Aufträge vom Vortag</h6>
          <OrderTable
            orders={data.previousOrders}
            storeTeams={teams}
            onClick={handleOrderClick}
            client={client}
          />
        </Panel>
      );
    }
    return null;
  };

  return (
    <>
      <Panel className="d-flex" marginBottom={20}>
        <h6 style={{ marginBottom: 30 }}>Aufträge</h6>
        <ShowHideContainer visible={(objects && objects.length > 0)}>
          <OrderTable
            orders={objects || []}
            storeTeams={teams}
            onClick={handleOrderClick}
            client={client}
          />
        </ShowHideContainer>
        <ShowHideContainer visible={(!objects || objects.length === 0)}>
          <div className="alert alert-warning">
            <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
            Keine Aufträge an diesem Tag!
          </div>
        </ShowHideContainer>
      </Panel>

      <Panel className="d-flex" marginBottom={20}>
        <h6 style={{ marginBottom: 30 }}>Serviceaufträge</h6>
        <ShowHideContainer visible={(services && services.length > 0)}>
          <ReclamationTable
            reclamations={services || []}
            storeUsers={monteurOnly}
            storeTeams={teams}
            onClick={handleReclamationClick}
          />
        </ShowHideContainer>
        <ShowHideContainer visible={(!services || services.length === 0)}>
          <div className="alert alert-warning">
            <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
            Keine Services an diesem Tag!
          </div>
        </ShowHideContainer>
      </Panel>

      {renderPreviousOrders()}
    </>
  );
}

RightDetailsBox.propTypes = {
  currentDate: PropTypes.string.isRequired,
};
