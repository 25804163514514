import {
  ORDER_CREATE_START, ORDER_CREATE_SUCCESS, ORDER_CREATE_ERROR, ORDER_CREATE_UPLOADFILES_ERROR,
  ORDER_CREATE_UPLOADFILES_START, ORDER_CREATE_UPLOADFILES_SUCCESS, ORDER_CREATE_UPLOADFILES_PROGRESS,
  ORDER_GET_ERROR, ORDER_GET_START, ORDER_GET_SUCCESS, ORDER_SET_EDIT_ORDER, ORDER_GETOPENEXTERNAL_ERROR,
  ORDER_GETOPENEXTERNAL_START, ORDER_GETOPENEXTERNAL_SUCCESS, ORDER_SINGLEUDPATE_SUCCESS, ORDER_UPDATE_START,
  ORDER_UPDATE_ERROR, ORDER_UPDATE_SUCCESS, ORDER_GETWAITINGEXTERNAL_START, ORDER_GETWAITINGEXTERNAL_ERROR,
  ORDER_GETWAITINGEXTERNAL_SUCCESS, ORDER_ASSIGN_SUCCESS, ORDER_APPROVEMANDANT_SUCCESS,
} from '../Action/OrderAction';
import {
  OBJECT_DELETE_SUCCESS,
} from '../Action/ObjectAction';

import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  isLoading: false,
  isUpdating: false,
  order: null, // The current selected order for the details view
  openExternal: null,
  waitingExternal: null,
  openAndWaitingExternal: null,
  uploadProgress: 0,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /**
     * CREATE ORDER
     */
    case ORDER_CREATE_START: {
      return { ...state, isLoading: true };
    }
    case ORDER_CREATE_SUCCESS: {
      return { ...state, isLoading: true };
    }
    case ORDER_CREATE_ERROR: {
      return { ...state, isLoading: false };
    }

    /**
     * GET ORDER
     */
    case ORDER_GET_START: {
      return { ...state, isLoading: true };
    }
    case ORDER_GET_SUCCESS: {
      const order = action.payload;
      return { ...state, isLoading: false, order };
    }
    case ORDER_GET_ERROR: {
      return { ...state, isLoading: false, order: null };
    }

    /**
     * UPDATE ORDER
     */
    case ORDER_UPDATE_START: {
      return { ...state, isUpdating: true };
    }
    case ORDER_UPDATE_SUCCESS: {
      return { ...state, isUpdating: false, order: action.payload };
    }
    case ORDER_UPDATE_ERROR: {
      return { ...state, isUpdating: false };
    }

    /**
     * GET OPEN EXTERNAL ORDER
     */
    case ORDER_GETOPENEXTERNAL_START: {
      return { ...state, isLoading: true, openExternal: null };
    }
    case ORDER_GETOPENEXTERNAL_SUCCESS: {
      const { payload } = action;
      let openAndWaitingExternal = payload;

      if (payload && state.waitingExternal && state.waitingExternal.length > 0) {
        const merged = [...payload, ...state.waitingExternal];
        openAndWaitingExternal = merged.sort((a, b) => {
          if (a.deliveryDateStart > b.deliveryDateStart) return -1;
          if (b.deliveryDateStart > a.deliveryDateStart) return 1;
          return 0;
        });
      } else if (!payload) {
        openAndWaitingExternal = state.waitingExternal;
      }

      return {
        ...state,
        isLoading: false,
        openExternal: action.payload,
        openAndWaitingExternal: (openAndWaitingExternal) ? openAndWaitingExternal.reverse() : null,
      };
    }
    case ORDER_GETOPENEXTERNAL_ERROR: {
      return { ...state, isLoading: false, openExternal: null };
    }

    /**
     * GET WAITING EXTERNAL ORDER
     */
    case ORDER_GETWAITINGEXTERNAL_START: {
      return { ...state, isLoading: true, waitingExternal: null };
    }
    case ORDER_GETWAITINGEXTERNAL_SUCCESS: {
      const { payload } = action;
      let openAndWaitingExternal = payload;

      if (payload && state.openExternal && state.openExternal.length > 0) {
        const merged = [...payload, ...state.openExternal];
        openAndWaitingExternal = merged.sort((a, b) => {
          if (a.deliveryDateStart > b.deliveryDateStart) return -1;
          if (b.deliveryDateStart > a.deliveryDateStart) return 1;
          return 0;
        });
      } else if (!payload) {
        openAndWaitingExternal = state.openExternal;
      }

      return {
        ...state,
        isLoading: false,
        waitingExternal: action.payload,
        openAndWaitingExternal: openAndWaitingExternal.reverse(),
      };
    }
    case ORDER_GETWAITINGEXTERNAL_ERROR: {
      return { ...state, isLoading: false, waitingExternal: null };
    }

    /**
     * UPLOAD FILES
     */
    case ORDER_CREATE_UPLOADFILES_START: {
      return { ...state, isLoading: true, uploadProgress: 0 };
    }
    case ORDER_CREATE_UPLOADFILES_SUCCESS: {
      return { ...state, isLoading: false, uploadProgress: 100 };
    }
    case ORDER_CREATE_UPLOADFILES_ERROR: {
      return { ...state, isLoading: false };
    }
    case ORDER_CREATE_UPLOADFILES_PROGRESS: {
      return { ...state, isLoading: true, uploadProgress: action.percent };
    }

    /**
     * SET EDIT ORDER
     */
    case ORDER_SET_EDIT_ORDER: {
      return { ...state, editOrder: action.payload };
    }

    case OBJECT_DELETE_SUCCESS:
    case ORDER_ASSIGN_SUCCESS:
    case ORDER_APPROVEMANDANT_SUCCESS:
    case ORDER_SINGLEUDPATE_SUCCESS: {
      return { ...state, order: action.payload };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
