import Axios from '../../Library/Axios';

export const defaultUri = '/import';

export const IMPORT_UPLOAD_CSV_START = 'IMPORT_UPLOAD_CSV_START';
export const IMPORT_UPLOAD_CSV_SUCCESS = 'IMPORT_UPLOAD_CSV_SUCCESS';
export const IMPORT_UPLOAD_CSV_ERROR = 'IMPORT_UPLOAD_CSV_ERROR';

/**
 * uploadCsv
 * @param file
 * @returns {function(*): Promise<unknown>}
 */
export function uploadCsv(file) {
  return (dispatch) => {
    dispatch({ type: IMPORT_UPLOAD_CSV_START });

    const config = { params: { action: 'prepare' } };
    const formData = new FormData();
    formData.append('file', file);

    return Axios().post(defaultUri, formData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: IMPORT_UPLOAD_CSV_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: IMPORT_UPLOAD_CSV_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const IMPORT_EXECUTE_START = 'IMPORT_EXECUTE_START';
export const IMPORT_EXECUTE_SUCCESS = 'IMPORT_EXECUTE_SUCCESS';
export const IMPORT_EXECUTE_ERROR = 'IMPORT_EXECUTE_ERROR';

/**
 * importOrders
 * @param orders
 * @returns {function(*): Promise<unknown>}
 */
export function importOrders(orders) {
  return (dispatch) => {
    dispatch({ type: IMPORT_EXECUTE_START });

    const config = { params: { action: 'import' }, timeout: 200000 };

    return Axios().post(defaultUri, orders, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: IMPORT_EXECUTE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: IMPORT_EXECUTE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const IMPORT_CANCEL_SUCCESS = 'IMPORT_CANCEL_SUCCESS';

/**
 * cancelImport
 * @returns {function(*): Promise<unknown>}
 */
export function cancelImport() {
  return (dispatch) => dispatch({ type: IMPORT_CANCEL_SUCCESS });
}
