import {
  CLIENTINVITE_CREATE_ERROR,
  CLIENTINVITE_CREATE_START,
  CLIENTINVITEAUTH_CREATE_START,
  CLIENTINVITEAUTH_CREATE_SUCCESS,
  CLIENTINVITEGETMAIL_CREATE_ERROR,
  CLIENTINVITEGETMAIL_CREATE_START,
} from '../Action/ClientInviteAction';

const defaultValues = {
  usersInvite: null,
  userData: null,
  isLoading: false,
  statusCode: null,
  errorCode: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case CLIENTINVITEAUTH_CREATE_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTINVITEAUTH_CREATE_SUCCESS: {
      return {
        ...state,
      };
    }

    case CLIENTINVITEGETMAIL_CREATE_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTINVITEGETMAIL_CREATE_ERROR: {
      return {
        ...state,
      };
    }

    case CLIENTINVITE_CREATE_START: {
      const { payload } = action;

      return {
        ...state,
        userData: payload,
      };
    }
    case CLIENTINVITE_CREATE_ERROR: {
      if (action.payload) {
        const { status, data } = action.payload;
        return {
          ...state, isLoading: false, statusCode: status, errorCode: data.errorCode,
        };
      }
      return { ...state, isLoading: false, statusCode: 500 };
    }

    default: return { ...state };
  }
}
