import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import './DayBadge.css';
import { DISPOSITION_TYPE_OBJECT_DATE, DISPOSITION_TYPE_SERVICE } from '../../../Library/Types';

/**
 * DayBadge()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function DayBadge(props) {
  const {
    item, calendarData, onPress, currentMonth, showSunday, overallAssemblyTime,
  } = props;
  const holidayData = useSelector((state) => state.holiday.tileData);
  const { monteureOnly } = useSelector((state) => state.user);

  const [formattedDate] = useState(moment(item).format('YYYY-MM-DD'));
  const [percent, setPercent] = useState(0);
  const [data, setData] = useState(null);
  const [isSunday, setIsSunday] = useState(false);
  const [userHavingHoliday, setUsersHavingHoliday] = useState([]);
  const [services, setServices] = React.useState(0);
  const [objects, setObjects] = React.useState(0);

  React.useEffect(() => {
    if (holidayData && formattedDate && monteureOnly) {
      if (holidayData[formattedDate]) {
        const filteredMonteuere = holidayData[formattedDate].filter((param) => {
          const found = monteureOnly.filter((itemStore) => itemStore.userId === param.userId);
          return (found && found.length > 0);
        });
        const userIds = filteredMonteuere.map((itemData) => itemData.userId);
        setUsersHavingHoliday(userIds);
      } else {
        setUsersHavingHoliday([]);
      }
    }
  }, [formattedDate, holidayData, monteureOnly]);

  React.useEffect(() => {
    if (calendarData) {
      const filteredData = (calendarData[formattedDate]) ? calendarData[formattedDate] : null;
      setData(filteredData);
      const day = item.day();
      setIsSunday((day === 0));

      if (filteredData) {
        const serviceParam = filteredData.data.filter((param) => (param.type === DISPOSITION_TYPE_SERVICE));
        const objectParam = filteredData.data.filter((param) => (param.type === DISPOSITION_TYPE_OBJECT_DATE));
        setServices((serviceParam) ? serviceParam.length : 0);
        setObjects((objectParam) ? objectParam.length : 0);
      }
    }
  }, [calendarData, formattedDate, item]);

  React.useEffect(() => {
    if (data) {
      if (overallAssemblyTime) {
        const calculatedOverallAssemblyTime = overallAssemblyTime - (userHavingHoliday.length * 270);
        const percentData = parseFloat(((data.assemblyTime * 100) / calculatedOverallAssemblyTime).toFixed(1));
        setPercent(percentData);
      } else {
        setPercent(100);
      }
    }
  }, [overallAssemblyTime, data, userHavingHoliday]);

  const getChartColor = () => {
    if (percent === 0) {
      return '';
    } if (percent <= 25) {
      return 'text-success';
    } if (percent <= 50) {
      return 'text-warning';
    } if (percent <= 75) {
      return 'text-orange';
    }
    return 'text-danger';
  };

  const getStyle = () => {
    if (!moment(item).isSame(moment(currentMonth), 'month')) {
      return { opacity: 0.2 };
    }
    return {};
  };

  const getClass = () => {
    let classNames = 'DayBadge';
    if (moment(item).isSame(moment(), 'day')) {
      classNames = `${classNames} Today`;
    }
    if (!showSunday) {
      classNames = `${classNames} WithoutSunday`;
    }
    return classNames;
  };

  if (!showSunday && isSunday) {
    return null;
  }

  const renderInformation = () => (
    <>
      <br />
      <div className="Row">{`Aufträge: ${objects}`}</div>
      <div className="Row">{`Services: ${services}`}</div>
    </>
  );

  if (calendarData) {
    return (
      <div
        className={getClass()}
        onClick={() => onPress(moment(item).format('YYYY-MM-DD'))}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
        style={getStyle()}
      >
        <div className="TurnAround">

          <div className="Container Front">
            <div className="Headline">
              <div className="Left" />
              <div className="DayNumber">
                {moment(item).format('D')}
              </div>
            </div>
            <div className="Content">
              <i className={`fas fa-chart-pie ${getChartColor()}`} />
              {`Auslastung: ${percent}%`}
            </div>
            <div className="CalendarWeek">
              {`KW ${moment(item).format('WW')}`}
            </div>
          </div>

          <div className="Container Back">
            <div className="Headline">
              <div className="Left">
                <i className={`fas fa-chart-pie ${getChartColor()}`} />
                {`Auslastung: ${percent}%`}
              </div>
              <div className="DayNumber">
                {moment(item).format('D')}
              </div>
            </div>
            <div className="Content">
              {renderInformation()}
              <div className="Details">
                Details
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
  return null;
}

DayBadge.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  calendarData: PropTypes.instanceOf(Object),
  onPress: PropTypes.func.isRequired,
  currentMonth: PropTypes.instanceOf(Object).isRequired,
  showSunday: PropTypes.bool,
  overallAssemblyTime: PropTypes.number.isRequired,
};

DayBadge.defaultProps = {
  calendarData: null,
  showSunday: false,
};
