import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { useSelector } from 'react-redux';
import Panel from '../Panel';
import TextLink from '../TextLink';
import TeamMonteurPartnerRow from '../TeamMonteurPartner/TeamMonteurPartnerRow';
import SelectUserTeamPartner from '../SelectUserTeamPartner';
import { useDispatchServiceDeleteUser } from '../../Redux/Action/Order/Object/Service/UserAction';
import { useAccessClientCanAssignService } from '../../Library/AccessChecks';

/**
 * AssignContainer()
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function AssignContainer(props) {
  const { onUpdate, onAddUser } = props;

  const { client } = useSelector((state) => state.client);
  const { order } = useSelector((state) => state.order);
  const partners = useSelector((state) => state.client.activeMonteurPartners);
  const { service } = useSelector((state) => state.objectService);

  const dispatchDeleteUser = useDispatchServiceDeleteUser();
  const isAssignable = useAccessClientCanAssignService(service);

  const [canUsePartners, setCanUsePartners] = React.useState(false);
  const [showSelect, setShowSelect] = React.useState(false);

  React.useEffect(() => {
    setCanUsePartners(
      (client.clientId === order.clientId && partners && partners.length > 0 && order.internal),
    );
  }, [client, order, partners]);

  const handleAddTeam = (team) => {
    onUpdate({ ...service, assignedTeamId: team.teamId });
    setShowSelect(false);
  };

  const handleAddPartner = (partner) => {
    onUpdate({ ...service, assignedClientId: partner.clientId });
    setShowSelect(false);
  };

  const handleAddUser = (user) => {
    onAddUser(user);
    setShowSelect(false);
  };

  const renderAddUsers = () => {
    if (order.clientId === client.clientId && service.assignedClientId) {
      return null;
    }
    if (!isAssignable) {
      return null;
    }

    if (service.executionDate && !service.assignedTeamId) {
      let caption = 'Team/Monteur/Partner zuweisen';
      if (!canUsePartners) {
        caption = 'Team oder Monteur zuweisen';
      }

      if (service.users && service.users.length > 0) {
        caption = 'Weitere Monteure zuweisen';
      }

      return (
        <TextLink
          caption={caption}
          onPress={() => setShowSelect(true)}
          icon="fas fa-plus-square"
          style={{ marginTop: 20 }}
        />
      );
    }
    return null;
  };

  const renderDisabledHint = () => {
    if (!service.executionDate) {
      return (
        <div className="alert alert-warning">
          Bitte vergeben Sie erst ein Ausführungsdatum bevor Sie Monteure oder Teams zuweisen!
        </div>
      );
    }
    return null;
  };

  if ((order.clientId === client.clientId && order.internal)
      || (service.assignedClientId === client.clientId)
  ) {
    return (
      <Panel marginBottom={20} containerStyle={{ display: 'flex' }}>
        <h6 className="Headline">
          Monteure / Teams
        </h6>
        <p>
          Bitte weisen Sie das Team oder die Monteure zu, die diesen Auftrag durchführen werden.
        </p>

        <TeamMonteurPartnerRow
          teamId={service.assignedTeamId}
          partnerId={service.assignedClientId}
          users={service.users}
          client={client}
          onDeleteTeam={() => onUpdate({ ...service, assignedTeamId: null })}
          onDeletePartner={() => onUpdate({ ...service, assignedClientId: null })}
          onDeleteUser={(user) => dispatchDeleteUser(service, user.userId)}
          hideUsersOrTeam={(service.assignedClientId && service.assignedClientId !== client.clientId)}
          disabled={!isAssignable}
        />
        {renderDisabledHint()}
        {renderAddUsers()}

        <SelectUserTeamPartner
          onAddTeam={handleAddTeam}
          onAddPartner={handleAddPartner}
          onAddUser={handleAddUser}
          visible={showSelect}
          alreadyAddedUsers={service.users}
          showTeams={(service.users.length === 0)}
          onClose={() => setShowSelect(false)}
          executionDate={service.executionDate}
          showPartners={(order.clientId === client.clientId)}
        />
      </Panel>
    );
  }
  return null;
}

AssignContainer.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
};
