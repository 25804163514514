import React from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../Library/Axios';

const defaultUri = '/group';

export const GROUP_GETLIST_START = 'GROUP_GETLIST_START';
export const GROUP_GETLIST_SUCCESS = 'GROUP_GETLIST_SUCCESS';
export const GROUP_GETLIST_ERROR = 'GROUP_GETLIST_ERROR';

/**
 * useDispatchGetGroups()
 * @returns {function(): *}
 */
export function useDispatchGetGroups() {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch({ type: GROUP_GETLIST_START });

    return axios().get(defaultUri).then((response) => {
      const { data } = response.data;
      dispatch({ type: GROUP_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: GROUP_GETLIST_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
