import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { useRouteMatch } from 'react-router';
import * as Redux from 'react-redux';
import { getUnread } from '../../../Redux/Action/NotificationAction';
import Config from '../../../Assets/Config';

import '../style.css';
import {
  useAccessCanCreateOrders,
  useAccessCanUseCalendar,
  useAccessCanUseDisposition,
} from '../../../Library/AccessChecks';
import {
  useDispatchFacilityLoginClient,
  useDispatchGetFacilityList,
} from '../../../Redux/Action/FacilityAction';
import { saveState } from '../../../Redux/LocalStorage';
import { CLIENTTYPE_MONTEUR } from '../../../Library/Types';

const MessageIconContainer = styled.div`
  .Icon {
    display: ${(props) => ((props.theme.iconVisible) ? 'block' : 'none')};
  }
  .Image {
    display: ${(props) => ((props.theme.iconVisible) ? 'none' : 'block')};
  }
  
  &:hover {
    .Icon {
      display: block;
    }
    .Image {
      display: none;
    }
  }
`;

const FacilityButtonStyles = createGlobalStyle`
  .FacilityButton {
  border: none;
  background-color: white;
  cursor: pointer;
}
`;

const DropDownListStyles = createGlobalStyle`
  .dropdownList {
   width: 220px;
   box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);
   border-radius: 4px;
    }
`;

const DropDownListItem = styled.li`
  font-size: 16px;
  font-family: 'DIN Next LT W01 Light', sans-serif;
  
  .icon {
   color: ${(props) => props.theme.color.primaryRedColor};
  }
 
  .entry {
  padding-left: 5px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 4px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
  
  &:hover{
    background: ${(props) => props.theme.color.primaryRedColor};
    color: ${(props) => props.theme.color.textGrayLight};
     cursor: pointer;
    }
  }
 
`;

const hkLogo = require('../../../Assets/Images/hk_logo_full.jpg');
const hkLogoStaging = require('../../../Assets/Images/hk_logo_full_staging.png');
const bell = require('../../../Assets/Images/bell_transparent.gif');

/**
 * AuthedNavigation()
 * @param props
 * @returns {*}
 * @constructor
 */
function AuthedNavigation(props) {
  const {
    user, client, userGroups, notifications, dispatchNotifications, unreadCount, isMonteurOnly,
  } = props;
  const isAllowedToCreateOrders = useAccessCanCreateOrders();
  const isAllowedToUseDisposition = useAccessCanUseDisposition();
  const isAllowedToUseCalendar = useAccessCanUseCalendar();
  const [image, setImage] = React.useState(hkLogo);
  const { path } = useRouteMatch();

  const state = Redux.useSelector((store) => store);
  const facilities = Redux.useSelector((store) => (store.facility.facilities));
  const dispatchGetFacilityList = useDispatchGetFacilityList();
  const dispatchFacilityLoginClient = useDispatchFacilityLoginClient();

  React.useEffect(() => {
    if (Config.env !== 'production') {
      setImage(hkLogoStaging);
    }
  }, []);

  React.useEffect(() => {
    dispatchGetFacilityList().then(() => {
    });
  }, [dispatchGetFacilityList]);

  React.useEffect(() => {
    if (state.auth.needsReload) {
      const newState = state;
      newState.auth.needsReload = false;
      saveState(newState);
      window.location.reload();
    }
  }, [state]);

  React.useEffect(() => {
    dispatchNotifications();
    const interval = setInterval(() => {
      dispatchNotifications();
    }, 180000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatchNotifications]);

  const renderSystemBackend = () => {
    if (user.isSystemAdmin) {
      return (
        <li className="nav-item">
          <NavLink className="nav-link" to="/system" exact={false}>
            <div><i className="fas fa-cogs" /></div>
            <div className="d-none d-xl-block Text">
              System
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderNotifications = () => {
    if (notifications && notifications.length > 0) {
      return (
        <li className="nav-item">
          <MessageIconContainer theme={{ iconVisible: (unreadCount === 0) || (path === '/notifications') }}>
            <NavLink className="nav-link Messages" to="/notifications" exact={false}>
              <div className="Image"><img src={bell} style={{ width: 26 }} alt="Glocken" /></div>
              <div className="Icon"><i className="fas fa-bell" /></div>
              <div className="d-none d-xl-block ClientName Text">
                {
                  `${(unreadCount > 0)
                    ? `${unreadCount} ${(unreadCount > 1) ? ' Benachrichtigungen' : ' Benachrichtigung'}`
                    : 'Benachrichtigungen'}`
                }
              </div>
            </NavLink>
          </MessageIconContainer>
        </li>
      );
    }
    return null;
  };

  const renderCompanySettings = () => {
    if (!userGroups) {
      return null;
    }

    if (userGroups.indexOf('ADM') > -1) {
      return (
        <li className="nav-item">
          <NavLink className="nav-link" to="/admin" exact={false}>
            <div><i className="fas fa-cogs" /></div>
            <div className="d-none d-xl-block ClientName Text">
              Einstellungen
            </div>
          </NavLink>
        </li>
      );
    }
    if (userGroups.indexOf('REG') > -1) {
      return (
        <li className="nav-item">
          <NavLink className="nav-link" to="/admin" exact={false}>
            <div><i className="fas fa-cogs" /></div>
            <div className="d-none d-xl-block ClientName Text">
              Einstellungen
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderOrderMenu = () => {
    if (!isMonteurOnly && client.type === CLIENTTYPE_MONTEUR) {
      return (
        <li className="nav-item">
          <NavLink className="nav-link" to="/find">
            <div><i className="fas fa-search" /></div>
            <div className="d-none d-xl-block Text">
              Aufträge finden
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderCreateOrder = () => {
    if (isAllowedToCreateOrders) {
      return (
        <li className="nav-item">
          <NavLink className="nav-link" to="/create">
            <div><i className="fas fa-plus-circle" /></div>
            <div className="d-none d-xl-block Text">
              Auftrag erstellen
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderDisposition = () => {
    if (client.settings.allowUseDisposition && isAllowedToUseDisposition) {
      return (
        <li className="nav-item">
          <NavLink className="nav-link" to="/disposition" exact={false}>
            <div><i className="far fa-list-alt" /></div>
            <div className="d-none d-xl-block Text">
              Disposition
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderCalendar = () => {
    if (isAllowedToUseCalendar) {
      return (
        <li className="nav-item">
          <NavLink className="nav-link" to="/calendar" exact={false}>
            <div><i className="far fa-calendar-alt" /></div>
            <div className="d-none d-xl-block Text">
              Kalender
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  };

  const renderProfile = () => {
    if (facilities && facilities.length > 1) {
      return (
        <li className="nav-item">
          <MessageIconContainer>
            <FacilityButtonStyles />
            <DropDownListStyles />
            <div className="dropdown">
              <button
                className="nav-link nav-item FacilityButton "
                type="button"
                data-bs-toggle="dropdown"
              >
                <div>
                  <i className="fas fa-user" />
                </div>
                <div style={{
                  maxWidth: 180, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                }}
                >
                  {client.name}
                </div>
              </button>
              <ul className="dropdown-menu dropdown-menu-right dropdownList">
                <DropDownListItem>
                  <p style={{ fontWeight: 'bold', marginBottom: 10, marginLeft: 15 }}>
                    Benutzer
                  </p>
                  <NavLink to="/profile" exact={false} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className="entry">
                      {`${user.firstname} ${user.lastname}`}
                    </div>
                  </NavLink>
                </DropDownListItem>
                <hr />
                <DropDownListItem>
                  <p style={{ fontWeight: 'bold', marginBottom: 10, marginLeft: 15 }}>
                    Betriebsstätten
                  </p>
                  {facilities.map((facility) => (
                    <div
                      className="entry"
                      role="button"
                      onClick={() => { dispatchFacilityLoginClient(facility.clientId); }}
                      style={{ marginTop: 10 }}
                      tabIndex={0}
                      onKeyPress={() => {}}
                      key={facility.clientId}
                    >
                      {facility.clientName}
                    </div>
                  ))}
                </DropDownListItem>
              </ul>
            </div>
          </MessageIconContainer>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <NavLink className="nav-link" to="/profile" exact={false}>
          <div><i className="fas fa-user" /></div>
          <div className="d-none d-xl-block Text">
            {`${user.firstname} ${user.lastname}`}
          </div>
        </NavLink>
      </li>
    );
  };

  const renderSupport = () => (
    <li className="nav-item">
      <NavLink className="nav-link" to="/support" exact={false}>
        <div><i className="fa fa-question-circle" /></div>
        <div className="d-none d-xl-block Text">
          Hilfe
        </div>
      </NavLink>
    </li>
  );

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light Shaddow">
        <div className="navbar-brand">
          <NavLink to="/dashboard">
            <img src={image} alt="HK Logo" style={{ height: 40 }} />
          </NavLink>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/dashboard">
                <div><i className="fas fa-th-large" /></div>
                <div className="d-none d-xl-block Text">
                  Dashboard
                </div>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/order" exact={false}>
                <div><i className="fas fa-cubes" /></div>
                <div className="d-none d-xl-block Text">
                  Aufträge
                </div>
              </NavLink>
            </li>
            {renderOrderMenu()}
            {renderCalendar()}
            {renderDisposition()}
            {renderCreateOrder()}
          </ul>

          <ul className="navbar-nav justify-content-end Right">
            {renderSystemBackend()}
            {renderNotifications()}
            {renderCompanySettings()}
            {renderProfile()}
            {renderSupport()}
          </ul>
        </div>
      </nav>
    </>
  );
}

AuthedNavigation.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  userGroups: PropTypes.instanceOf(Array).isRequired,
  notifications: PropTypes.instanceOf(Array),
  dispatchNotifications: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
  isMonteurOnly: PropTypes.bool.isRequired,
};

AuthedNavigation.defaultProps = {
  notifications: null,
};

function mapStoreToProps(store) {
  return {
    user: store.auth.user,
    client: store.auth.client,
    userGroups: store.auth.groups,
    notifications: store.notification.notifications,
    unreadCount: store.notification.unreadCount,
    isMonteurOnly: store.auth.isMonteurOnly,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchNotifications: () => dispatch(getUnread()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(AuthedNavigation));
