import React from 'react';
import * as Redux from 'react-redux';
import axios from '../../../../../../Library/Axios';
import * as AppointmentAction from '../AppointmentAction';

export const defaultUri = `${AppointmentAction.defaultUriId}/date`;
export const defaultUriId = `${defaultUri}/{dateId}`;

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.orderId {number}
 * @param parameter.objectId {number}
 * @param parameter.serviceId {number}
 * @param parameter.appointmentId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUri(parameter, uri = defaultUri) {
  return AppointmentAction.prepareUriId(parameter, uri);
}

/**
 * Prepares uri for requests
 * @param parameter {object}
 * @param parameter.orderId {number}
 * @param parameter.objectId {number}
 * @param parameter.serviceId {number}
 * @param parameter.appointmentId {number}
 * @param parameter.dateId {number}
 * @param uri
 * @returns {string}
 */
export function prepareUriId(parameter, uri = defaultUriId) {
  const { dateId } = parameter;
  return prepareUri(parameter, uri).replace('{dateId}', dateId);
}

export const PROPOSED_DATE_GET_LIST_START = 'PROPOSED_DATE_GET_LIST_START';
export const PROPOSED_DATE_GET_LIST_SUCCESS = 'PROPOSED_DATE_GET_LIST_SUCCESS';
export const PROPOSED_DATE_GET_LIST_ERROR = 'PROPOSED_DATE_GET_LIST_ERROR';
/**
 * @param orderId
 * @param objectId
 * @param serviceId
 * @param appointmentId
 * @returns {function(): *}
 */
export const useDispatchGetProposedDates = (orderId, objectId, serviceId, appointmentId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUri({
    orderId, objectId, serviceId, appointmentId,
  });

  return React.useCallback(() => {
    dispatch({ type: PROPOSED_DATE_GET_LIST_START });
    return axios().get(uri).then((response) => {
      dispatch({ type: PROPOSED_DATE_GET_LIST_SUCCESS, payload: response.data.data });
      return Promise.resolve(response.data.data);
    }).catch((error) => {
      dispatch({ type: PROPOSED_DATE_GET_LIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};

export const PROPOSED_DATE_CREATE_START = 'PROPOSED_DATE_CREATE_START';
export const PROPOSED_DATE_CREATE_SUCCESS = 'PROPOSED_DATE_CREATE_SUCCESS';
export const PROPOSED_DATE_CREATE_ERROR = 'PROPOSED_DATE_CREATE_ERROR';
/**
 * @param orderId
 * @param objectId
 * @param serviceId
 * @param appointmentId
 * @returns {function(*, *, *): *}
 */
export const useDispatchCreateProposedDate = (orderId, objectId, serviceId, appointmentId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUri({
    orderId, objectId, serviceId, appointmentId,
  });

  return React.useCallback((date, startTime, comment) => {
    dispatch({ type: PROPOSED_DATE_CREATE_START });
    return axios().post(uri, {
      date, startTime, comment,
    }).then((response) => {
      dispatch({ type: PROPOSED_DATE_CREATE_SUCCESS, payload: response.data.data });
      return Promise.resolve(response.data.data);
    }).catch((error) => {
      dispatch({ type: PROPOSED_DATE_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};

export const PROPOSED_DATE_UPDATE_START = 'PROPOSED_DATE_UPDATE_START';
export const PROPOSED_DATE_UPDATE_SUCCESS = 'PROPOSED_DATE_UPDATE_SUCCESS';
export const PROPOSED_DATE_UPDATE_ERROR = 'PROPOSED_DATE_UPDATE_ERROR';
/**
 * @param orderId
 * @param objectId
 * @param serviceId
 * @param appointmentId
 * @param dateId
 * @returns {function(*, *, *): *}
 */
export const useDispatchUpdateProposedDate = (orderId, objectId, serviceId, appointmentId, dateId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUriId({
    orderId, objectId, serviceId, appointmentId, dateId,
  });

  return React.useCallback((date, startTime, comment) => {
    dispatch({ type: PROPOSED_DATE_CREATE_START });

    return axios().put(uri, {
      date, startTime, comment,
    }).then((response) => {
      dispatch({ type: PROPOSED_DATE_CREATE_SUCCESS, payload: response.data.data });
      return Promise.resolve(response.data.data);
    }).catch((error) => {
      dispatch({ type: PROPOSED_DATE_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};

/**
 * @param orderId
 * @param objectId
 * @param serviceId
 * @param appointmentId
 * @param dateId
 * @returns {function(*): *}
 */
export const useDispatchSelectProposedDate = (orderId, objectId, serviceId, appointmentId, dateId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUriId({
    orderId, objectId, serviceId, appointmentId, dateId,
  });

  return React.useCallback((selected) => {
    dispatch({ type: PROPOSED_DATE_CREATE_START });

    return axios().put(uri, { selected }).then((response) => {
      dispatch({ type: PROPOSED_DATE_CREATE_SUCCESS, payload: response.data.data });
      return Promise.resolve(response.data.data);
    }).catch((error) => {
      dispatch({ type: PROPOSED_DATE_CREATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};

export const PROPOSED_DATE_DELETE_START = 'PROPOSED_DATE_DELETE_START';
export const PROPOSED_DATE_DELETE_SUCCESS = 'PROPOSED_DATE_DELETE_SUCCESS';
export const PROPOSED_DATE_DELETE_ERROR = 'PROPOSED_DATE_DELETE_ERROR';
/**
 * @param orderId
 * @param objectId
 * @param serviceId
 * @param appointmentId
 * @param dateId
 * @returns {function(): *}
 */
export const useDispatchDeleteProposedDate = (orderId, objectId, serviceId, appointmentId, dateId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUriId({
    orderId, objectId, serviceId, appointmentId, dateId,
  });

  return React.useCallback(() => {
    dispatch({ type: PROPOSED_DATE_DELETE_START });
    return axios().delete(uri).then((response) => {
      dispatch({ type: PROPOSED_DATE_DELETE_SUCCESS, payload: response.data.data });
      return Promise.resolve(response.data.data);
    }).catch((error) => {
      dispatch({ type: PROPOSED_DATE_DELETE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};
