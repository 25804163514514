import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Panel from '../../../Components/Panel';
import ShowHideContainer from '../../../Components/ShowHideContainer';

import './LeftDetailsBox.css';

/**
 * Users()
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Users(props) {
  const { type, users } = props;

  const getIcon = () => {
    if (type === 'available') {
      return 'fas fa-user-check text-warning';
    }
    return 'fas fa-user-slash text-muted';
  };

  if (users) {
    return (
      <div style={{ paddingTop: 10 }}>
        {users.map((user) => (
          <div style={{ marginTop: 6 }} key={user.userId}>
            <i className={getIcon()} style={{ marginRight: 10 }} />
            {`${user.firstname} ${user.lastname}`}
          </div>
        ))}
      </div>
    );
  }
  return null;
}

Users.propTypes = {
  users: PropTypes.instanceOf(Array),
  type: PropTypes.oneOf(['available', 'notAvailable']),
};

Users.defaultProps = {
  users: null,
  type: null,
};

/**
 * DayNavigator()
 * @param props
 * @returns {*}
 * @constructor
 */
function DayNavigator(props) {
  const { currentDate, onChange } = props;

  const handleClick = (type) => {
    if (type === 'next') {
      const date = moment(currentDate).add(1, 'day').format('YYYY-MM-DD');
      onChange(date);
    } else {
      const date = moment(currentDate).subtract(1, 'day').format('YYYY-MM-DD');
      onChange(date);
    }
  };

  return (
    <div className="DayNavigator" style={{ fontSize: 20 }}>
      <div
        className="Button"
        onClick={() => handleClick('prev')}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
      >
        <i className="fas fa-angle-left" />
      </div>
      <div className="DateString">
        <div>{moment(currentDate).format('dd, DD. MMMM YYYY')}</div>
      </div>
      <div
        className="Button Right"
        onClick={() => handleClick('next')}
        tabIndex={-1}
        role="button"
        onKeyPress={() => {}}
      >
        <i className="fas fa-angle-right" />
      </div>
    </div>
  );
}

DayNavigator.propTypes = {
  currentDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * LeftDetailsBox()
 * @param props
 * @returns {*}
 * @constructor
 */
function LeftDetailsBox(props) {
  const {
    currentDate, storeUsers, calendarData, onChange, overallAssemblyTime, holidayData,
  } = props;
  const [data, setData] = useState([]);
  const [percent, setPercent] = useState(null);
  const [userHavingHoliday, setUsersHavingHoliday] = useState([]);

  useEffect(() => {
    if (data) {
      if (overallAssemblyTime) {
        const calculatedOverallAssemblyTime = overallAssemblyTime - (userHavingHoliday.length * 270);
        const percentData = ((data.assemblyTime * 100) / calculatedOverallAssemblyTime).toFixed(1);
        setPercent(percentData || 0);
      } else {
        setPercent(100);
      }
    } else {
      setPercent(0);
    }
  }, [overallAssemblyTime, data, userHavingHoliday.length]);

  useEffect(() => {
    if (holidayData && currentDate && storeUsers) {
      if (holidayData[currentDate]) {
        const filteredMonteure = holidayData[currentDate].filter((item) => {
          const found = storeUsers.filter((itemStore) => itemStore.userId === item.userId);
          return (found && found.length > 0);
        });
        const userIds = filteredMonteure.map((item) => storeUsers.filter((test) => test.userId === item.userId)[0]);
        setUsersHavingHoliday(userIds);
      } else {
        setUsersHavingHoliday([]);
      }
    }
  }, [currentDate, holidayData, storeUsers]);

  useEffect(() => {
    const theData = (calendarData && calendarData[currentDate]) ? calendarData[currentDate] : null;
    setData(theData);
  }, [calendarData, currentDate]);

  const getChartColor = () => {
    if (percent === 0) {
      return '';
    } if (percent <= 25) {
      return 'text-success';
    } if (percent <= 50) {
      return 'text-warning';
    } if (percent <= 75) {
      return 'text-orange';
    }
    return 'text-danger';
  };

  return (
    <Panel className="d-flex LeftDetailsBox">
      <DayNavigator currentDate={currentDate} onChange={onChange} />

      <div className="d-flex flex-column" style={{ padding: '0 10px' }}>
        <div className="d-flex flex-column align-items-center" style={{ marginTop: 40 }}>
          <h6>Auslastung</h6>
          <i className={`fas fa-chart-pie fa-10x ${getChartColor()}`} style={{ marginTop: 20 }} />
          <div style={{ fontSize: 30, marginTop: 10, color: '#000' }}>
            {`${percent}%`}
          </div>

          <ShowHideContainer visible={(userHavingHoliday && userHavingHoliday.length > 0)}>
            <div style={{ marginTop: 60, marginBottom: 40 }}>
              <h6>Abwesende Monteure</h6>
              <Users users={userHavingHoliday} />
            </div>
          </ShowHideContainer>

        </div>
      </div>
    </Panel>
  );
}

LeftDetailsBox.propTypes = {
  currentDate: PropTypes.string.isRequired,
  storeUsers: PropTypes.instanceOf(Array),
  calendarData: PropTypes.instanceOf(Object),
  onChange: PropTypes.func.isRequired,
  overallAssemblyTime: PropTypes.number.isRequired,
  holidayData: PropTypes.instanceOf(Object),
};

LeftDetailsBox.defaultProps = {
  storeUsers: null,
  calendarData: null,
  holidayData: [],
};

function mapStoreToProps(store) {
  return {
    storeUsers: store.user.monteureOnly,
    calendarData: store.calendar.data,
    overallAssemblyTime: store.user.overallAssemblyTime,
    holidayData: store.holiday.tileData,
  };
}

export default connect(mapStoreToProps, () => ({}))(LeftDetailsBox);
