import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FileSaver from 'file-saver';
import Panel from '../../../Components/Panel';
import {
  download, get, useDispatchGetInvoiceList,
} from '../Redux/Action/InvoiceAction';
import InvoiceFilter from '../Component/InvoiceFilter';
import InvoiceTable from '../../../Components/InvoiceTable';
import DetailsModal from './DetailsModal';
import LoadingModal from '../../../Components/LoadingModal';

/**
 * Overview()
 * @param props
 * @returns {*}
 * @constructor
 */
function Overview(props) {
  const {
    dispatchGet, dispatchDownload,
  } = props;
  const [filtered, setFiltered] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState(null);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const dispatchGetInvoiceList = useDispatchGetInvoiceList();

  const handleOnAction = (data) => {
    dispatchGet(data.transactionId).then((response) => {
      setSelectedInvoice(response);
    });
  };

  const handleDownload = (data) => {
    setIsDownloading(true);
    dispatchDownload(data).then((response) => {
      setIsDownloading(false);
      FileSaver.saveAs(response, `Rechnung_${data.invoiceNumber}.pdf`);
    }).catch(() => setIsDownloading(false));
  };

  const handleCloseDetails = () => {
    setSelectedInvoice(null);
  };

  const handleFilterChange = (filteredInvoices, year) => {
    setFiltered(filteredInvoices);
    setSelectedYear(year);
  };

  return (
    <>
      <LoadingModal visible={isDownloading} text="Rechnung wird erstellt und heruntergeladen..." />

      <Panel marginBottom={25}>
        <h5 style={{ margin: 0 }}>
          Rechnungsübersicht
        </h5>
      </Panel>
      <InvoiceFilter onChange={(filteredInvoices, year) => handleFilterChange(filteredInvoices, year)} />

      <Panel>
        <InvoiceTable
          transactions={filtered}
          onAction={handleOnAction}
          actionIcon="fas fa-info-circle"
          onDownload={handleDownload}
        />
      </Panel>

      <DetailsModal
        transactionId={(selectedInvoice) ? selectedInvoice.transactionId : null}
        onClose={handleCloseDetails}
        onChange={() => { dispatchGetInvoiceList('all', selectedYear); }}
      />
    </>
  );
}

Overview.propTypes = {
  dispatchGet: PropTypes.func.isRequired,
  dispatchDownload: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGet: (id) => dispatch(get(id)),
    dispatchDownload: (transaction) => dispatch(download(transaction)),
  };
}

export default connect(null, mapDispatchToProps)(withRouter(Overview));
