import {
  SERVICEREPORT_GETLIST_ERROR, SERVICEREPORT_GETLIST_START, SERVICEREPORT_GETLIST_SUCCESS,
  SERVICEREPORT_GET_ERROR, SERVICEREPORT_GET_START, SERVICEREPORT_GET_SUCCESS,
} from '../Action/ServiceReportAction';
import {
  DELIVERYREPORT_GETLIST_ERROR, DELIVERYREPORT_GETLIST_START, DELIVERYREPORT_GETLIST_SUCCESS,
  DELIVERYREPORT_GET_ERROR, DELIVERYREPORT_GET_START, DELIVERYREPORT_GET_SUCCESS,
} from '../Action/DeliveryReportAction';
import {
  DEMONTAGEREPORT_GETLIST_ERROR, DEMONTAGEREPORT_GETLIST_START, DEMONTAGEREPORT_GETLIST_SUCCESS,
  DEMONTAGEREPORT_GET_ERROR, DEMONTAGEREPORT_GET_START, DEMONTAGEREPORT_GET_SUCCESS,
} from '../Action/DemontageReportAction';
import {
  OBJECT_SERVICE_REPORT_GET_SUCCESS,
} from '../Action/Order/Object/Service/ReportAction';
import { ORDER_GET_START } from '../Action/OrderAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  isLoading: false,
  orderId: null,
  objectId: null,
  serviceReport: null,
  demontageReport: null,
  deliveryReport: null,
  objectServiceReport: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case ORDER_GET_START: {
      const { payload } = action;
      if (parseInt(payload, 0) !== parseInt(state.orderId, 0)) {
        return { ...defaultValues };
      }
      return { ...state };
    }

    /** GET SERVICE REPORT */
    case SERVICEREPORT_GETLIST_START:
    case SERVICEREPORT_GET_START: {
      return { ...state, isLoading: true };
    }
    case SERVICEREPORT_GETLIST_SUCCESS:
    case SERVICEREPORT_GET_SUCCESS: {
      const { payload } = action;
      const { orderId, objectId } = payload;
      return {
        ...state, serviceReport: payload, orderId, objectId, isLoading: false,
      };
    }
    case SERVICEREPORT_GETLIST_ERROR:
    case SERVICEREPORT_GET_ERROR: {
      return { ...state, serviceReport: null, isLoading: false };
    }

    /** GET DELIVERY REPORT */
    case DELIVERYREPORT_GETLIST_START:
    case DELIVERYREPORT_GET_START: {
      return { ...state, isLoading: true };
    }
    case DELIVERYREPORT_GETLIST_SUCCESS:
    case DELIVERYREPORT_GET_SUCCESS: {
      const { payload } = action;
      const { orderId, objectId } = payload;
      return {
        ...state, deliveryReport: payload, orderId, objectId, isLoading: false,
      };
    }
    case DELIVERYREPORT_GETLIST_ERROR:
    case DELIVERYREPORT_GET_ERROR: {
      return { ...state, deliveryReport: null, isLoading: false };
    }

    /** GET DEMONTAGE REPORT */
    case DEMONTAGEREPORT_GETLIST_START:
    case DEMONTAGEREPORT_GET_START: {
      return { ...state, isLoading: true };
    }
    case DEMONTAGEREPORT_GETLIST_SUCCESS:
    case DEMONTAGEREPORT_GET_SUCCESS: {
      const { payload } = action;
      const { orderId, objectId } = payload;
      return {
        ...state, demontageReport: payload, orderId, objectId, isLoading: false,
      };
    }
    case DEMONTAGEREPORT_GETLIST_ERROR:
    case DEMONTAGEREPORT_GET_ERROR: {
      return { ...state, demontageReport: null, isLoading: false };
    }

    /** GET OBJECT SERVICE REPORT */
    case OBJECT_SERVICE_REPORT_GET_SUCCESS: {
      return { ...state, objectServiceReport: action.payload };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
