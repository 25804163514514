import React from 'react';
import PropTypes from 'prop-types/prop-types';
import Panel from '../Panel';

/**
 * UsedMaterial()
 * @param props
 * @returns {null}
 * @constructor
 */
export default function UsedMaterial(props) {
  const { materials } = props;

  if (materials && materials.length > 0) {
    return (
      <Panel marginBottom={25}>
        <h6>Verwendetes Material</h6>
        <p>
          Hier sehen Sie das Material, welches der Monteur bei der Montage zusätzlich benötigt hat
        </p>

        <table className="table table-bordered">
          <tbody>
            {materials.map((item) => (
              <tr>
                <td>
                  <div>{`${item.amount}x ${item.caption}`}</div>
                  <small>{item.description}</small>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel>
    );
  }
  return null;
}

UsedMaterial.propTypes = {
  materials: PropTypes.instanceOf(Array),
};

UsedMaterial.defaultProps = {
  materials: null,
};
