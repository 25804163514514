import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import ModalDialog from '../ModalDialog';
import Button from '../Button';

/**
 * DispositionMonteurError()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function DispositionMonteurError(props) {
  const { visible, onClose, onDisableDispoChecks } = props;
  const { settings } = useSelector((state) => state.client.client);
  const history = useHistory();

  const handleNavigateDispo = () => {
    history.push('/disposition');
  };

  const leftButton = () => {
    if (onDisableDispoChecks) {
      return (
        <Button onClick={onDisableDispoChecks} type="btn-light">
          Verstanden, trotzdem speichern!
        </Button>
      );
    }

    return null;
  };

  const renderOverlapHint = () => {
    if (onDisableDispoChecks) {
      return (
        <>
          <br />
          <br />
          Wenn Sie jedoch bewusst eine Überschneidung erzwingen möchten klicken Sie auf die Option
          "Verstanden, trotzdem speichern".
        </>
      );
    }

    return null;
  };

  return (
    <ModalDialog
      visible={visible}
      onClose={onClose}
      centered
      confirmCaption="Disposition öffnen"
      confirmClass="btn-success"
      onConfirm={handleNavigateDispo}
      hideConfirmButton={(!settings.allowUseDisposition)}
      leftButtonContent={leftButton()}
    >
      <h5>
        <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
        Achtung, Überschneidung der Planung!
      </h5>
      <p>
        <strong>Die Aktualisierung konnte nicht durchgeführt werden. </strong>
        Mindestens ein zugewiesener Monteur hat bereits einen verplanten Auftrag der genau in die geplante
        Ausführungszeit dieses Auftrag fällt! Um die Überschneidung zu beheben, passen Sie das Datum oder die
        Ausführungszeit an.
        {renderOverlapHint()}
      </p>
    </ModalDialog>
  );
}

DispositionMonteurError.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDisableDispoChecks: PropTypes.func,
  visible: PropTypes.bool,
};

DispositionMonteurError.defaultProps = {
  visible: false,
  onDisableDispoChecks: null,
};
