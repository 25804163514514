import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { approveMandant } from '../../../../Redux/Action/OrderAction';
import Panel from '../../../../Components/Panel';
import Button from '../../../../Components/Button';
import ModalDialog from '../../../../Components/ModalDialog';
import { ORDERSTATE_PARTNER_CREATED } from '../../../../Library/StateTypes';

/**
 * ApproveMandant()
 * @param props
 * @returns {*}
 * @constructor
 */
function ApproveMandant(props) {
  const { order, dispatchApproveMandant, partners } = props;
  const [partner, setPartner] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (order && partners) {
      const filtered = partners.filter((item) => item.partner.clientId === order.mandantId)[0];
      if (filtered) {
        setPartner(filtered.partner);
      }
    }
  }, [order, partners]);

  const onApprove = () => {
    dispatchApproveMandant(order);
  };

  if (order.state === ORDERSTATE_PARTNER_CREATED && partner) {
    return (
      <Panel marginBottom={20}>
        <div className="text-center">
          <div>Dieser Auftrag wurde für Sie erstellt von Ihrem Partner</div>
          <h5 style={{ textTransform: 'none', marginTop: 20, marginBottom: 20 }}>{partner.name}</h5>
          <div>
            Zur weiteren Bearbeitung müssen Sie die Übernahme des Auftrags bestätigen.
            Nach der Bestätigung kann der Auftrag nur noch von Ihnen weiter bearbeitet werden.
            <br />
            Sie können diesen Auftrag dann entweder mit Ihren eigenen Monteuren bearbeiten
            oder zur weiteren Bearbeitung an einen Ihrer Partnermonteure zuweisen.
          </div>

          <Button style={{ marginTop: 25 }} onClick={() => setShowModal(true)}>
            Auftragsübernahme bestätigen
          </Button>
        </div>

        <ModalDialog
          visible={showModal}
          onClose={() => setShowModal(false)}
          confirmCaption="Auftrag übernehmen"
          onConfirm={onApprove}
        >
          <h5>Auftragsübernahme bestätigen</h5>
          <p>
            Mit der Übernahme des Auftrags geht die weitere Bearbeitung in Ihre Verantwortung. Der Ersteller kann
            nach der Übernahme den Auftrag nicht mehr selber bearbeiten oder ändern!
            <br />
            {`Bitte bestätigen Sie, das Sie diesen Auftrag von "${partner.name}" jetzt übernehmen möchten.`}
          </p>
        </ModalDialog>
      </Panel>
    );
  }
  return null;
}

ApproveMandant.propTypes = {
  dispatchApproveMandant: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  partners: PropTypes.instanceOf(Array),
};

ApproveMandant.defaultProps = {
  order: null,
  partners: null,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
    partners: store.client.partners,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchApproveMandant: (order) => dispatch(approveMandant(order)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(ApproveMandant);
