import {
  CLIENTDOCUMENT_GETLIST_ERROR, CLIENTDOCUMENT_GETLIST_START, CLIENTDOCUMENT_GETLIST_SUCCESS,
  CLIENTDOCUMENT_UPLOAD_SUCCESS, CLIENTDOCUMENT_UPLOAD_ERROR, CLIENTDOCUMENT_UPLOAD_START,
  CLIENTDOCUMENT_DELETE_ERROR, CLIENTDOCUMENT_DELETE_START, CLIENTDOCUMENT_DELETE_SUCCESS,
} from '../Action/ClientDocumentAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  documents: [],
  companyLogo: null,
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /**
     * GETLIST
     */
    case CLIENTDOCUMENT_GETLIST_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTDOCUMENT_GETLIST_SUCCESS: {
      const { payload } = action;
      const companyLogo = payload.filter((item) => item.type === 'logo')[0];
      return {
        ...state, documents: [...payload] || [], isLoading: false, companyLogo,
      };
    }
    case CLIENTDOCUMENT_GETLIST_ERROR: {
      return { ...state, documents: null, isLoading: false };
    }

    /**
     * UPLOAD
     */
    case CLIENTDOCUMENT_UPLOAD_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTDOCUMENT_UPLOAD_SUCCESS: {
      const { payload } = action;
      return { ...state, documents: [...payload] || [], isLoading: false };
    }
    case CLIENTDOCUMENT_UPLOAD_ERROR: {
      return { ...state, isLoading: false };
    }

    /**
     * DELETE
     */
    case CLIENTDOCUMENT_DELETE_START: {
      return { ...state, isLoading: true };
    }
    case CLIENTDOCUMENT_DELETE_SUCCESS: {
      const { payload } = action;
      if (payload) {
        const companyLogo = payload.filter((item) => item.type === 'logo')[0];
        return {
          ...state, documents: [...payload] || [], companyLogo, isLoading: false,
        };
      }
      return { ...state, documents: [], companyLogo: null };
    }
    case CLIENTDOCUMENT_DELETE_ERROR: {
      return { ...state, isLoading: false };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
