/* eslint-disable max-len */

export default {
  showSundays: false,
  cellHeadlineHeight: 50, // Height of the headline with the hours inside
  cellSeparatorHeight: 70, // Height of group separators
  cellSeparatorFontSize: 20,
  cellHeight: 70, // Height of each user / resource cell
  cellWeekDayWidth: 500, // Width of the week day in week view
  cellWeekDayOverviewWidth: 120, // Width of the week day in week view
  cellWidth: 100, // Width of a user / resource cell in day view
  startHour: 6,
  endHour: 20,

  color: {
    text: '#4b4b4b',
    gray: '#c1c1c1',
    primary: '#df252b',
    danger: '#df252b',
    green: '#28a745',
    borderGrayLight: '#e4e4e4',
    backgroundGrayLight: '#eeeeee',
  },

  font: {
    sizeSmall: '13px',
  },

  boxShadowDark: '-webkit-box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);'
    + '-moz-box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);'
    + 'box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);',

  boxShadowDarkHover: '-webkit-box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);'
    + '-moz-box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);'
    + 'box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);',

  linearGradient: {
    unavailable: 'background: repeating-linear-gradient(-50deg, #f5f5f5, #f5f5f5 2px, #d3d3d3 2px, #d3d3d3 8px);',
    noWorkHour: 'background: repeating-linear-gradient(-50deg, #ffffff, #ffffff 2px, #ededed 2px, #ededed 4px) !important;',
  },

  border: {
    radius: '6px',
    width: 1,
  },

  appointment: {
    borderLeftWidth: 6,
  },
};
