import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatCurrency, formatDate } from '../../../Library/Functions';
import Panel from '../../../Components/Panel';
import BoxHideIcon from './BoxHideIcon';
import StatusBadge from '../../../Components/StatusBadge';

/**
 * OpenOffersTableView()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function OpenOffersTableView(props) {
  const {
    orders, rowCount, onClick, storageDashboardSettings, onHideBox, clientOffers,
  } = props;
  const [visibleByConfig, setVisibleByConfig] = React.useState(false);

  React.useEffect(() => {
    setVisibleByConfig((
      !storageDashboardSettings
      || storageDashboardSettings.orderExternOpenOffers === undefined
      || storageDashboardSettings.orderExternOpenOffers === true
    ));
  }, [storageDashboardSettings]);

  const renderOrders = () => {
    const result = [];
    let currentCount = 1;

    if (orders) {
      orders.forEach((order) => {
        if (currentCount <= rowCount) {
          result.push(
            <Row
              key={`${order.orderId}${order.objectId}`}
              order={order}
              onClick={(item) => onClick(item)}
              clientOffers={clientOffers}
            />,
          );
          currentCount += 1;
        }
      });
    }

    return result;
  };

  const renderHeadline = () => (
    <div className="d-flex flex-row">
      <div className="flex-grow-1">
        <h6 className="SecondaryHeadline" style={{ margin: 0 }}>
          Abgegebene Angebote
        </h6>
        <div style={{ fontSize: 16, color: '#9f9f9f' }}>
          Zu diesen Aufträgen haben Sie ein Angebot abgegeben
        </div>
      </div>
      <div>
        <StatusBadge type="Success" icon="fas fa-euro-sign" caption="Angebot abgegeben" />
      </div>
    </div>
  );

  if (orders && orders.length > 0 && visibleByConfig) {
    return (
      <div style={{ display: 'flex' }}>
        <Panel marginBottom={25} containerStyle={{ flex: 1 }}>
          <BoxHideIcon onPress={() => onHideBox('orderExternOpenOffers')} />

          {renderHeadline()}

          <table className="table table-hover linked" style={{ marginTop: 25 }}>
            <thead>
              <tr>
                <th>Typ</th>
                <th>Lieferdatum</th>
                <th>Plz & Ort</th>
                <th className="text-center">Optionen</th>
                <th className="text-right">Ihr Angebot</th>
              </tr>
            </thead>
            <tbody>
              {renderOrders()}
            </tbody>
          </table>
        </Panel>
      </div>
    );
  }
  return null;
}

OpenOffersTableView.propTypes = {
  orders: PropTypes.instanceOf(Array),
  rowCount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  storageDashboardSettings: PropTypes.instanceOf(Object),
  onHideBox: PropTypes.func.isRequired,
  clientOffers: PropTypes.instanceOf(Array),
};

OpenOffersTableView.defaultProps = {
  orders: [],
  rowCount: 100,
  storageDashboardSettings: null,
  clientOffers: null,
};

/**
 * Row()
 * @param props
 * @returns {*}
 * @constructor
 */
function Row(props) {
  const {
    order, onClick, clientOffers,
  } = props;
  const [offer, setOffer] = React.useState(null);

  React.useEffect(() => {
    if (order && clientOffers) {
      const offerData = clientOffers.filter((item) => item.orderId === order.orderId)[0];
      setOffer(offerData);
    }
  }, [order, clientOffers]);

  const renderType = () => {
    if (order.type === 'SINGLE') {
      return (
        <i className="fas fa-truck-moving" />
      );
    }
    return (
      <i className="far fa-building" />
    );
  };

  const renderOptions = () => {
    const optionsArray = [];
    if (order.optionMontage) {
      optionsArray.push(<i key="optionMontage" className="fas fa-tools" />);
    }
    if (order.optionDelivery) {
      optionsArray.push(<i key="optionDelivery" className="fas fa-truck-moving" />);
    }
    if (order.optionStorage) {
      optionsArray.push(<i key="optionStorage" className="fas fa-layer-group" />);
    }
    if (order.optionRemoval) {
      optionsArray.push(<i key="optionRemoval" className="fas fa-trash-alt" />);
    }

    return optionsArray;
  };

  const calcDeliveryDays = () => {
    const deliverDate = moment(order.deliveryDateStart, 'YYYY-MM-DD').endOf('day');
    const days = deliverDate.diff(moment(new Date()).endOf('day'), 'days');
    if (days === 0) {
      return 'Heute';
    }
    return `${days} Tage`;
  };

  const renderDate = () => {
    if (order.deliveryDateStart) {
      return `${formatDate(order.deliveryDateStart, 'DD.MM.YYYY')} (${calcDeliveryDays()})`;
    }
    return '';
  };

  const renderDeliveryDate = () => <td>{renderDate()}</td>;

  const renderAddress = () => {
    const address = `${order.zip} ${order.city}`;
    return (<td>{address}</td>);
  };

  const renderOffer = () => {
    if (offer) {
      return (
        <td className="text-right">{formatCurrency(offer.price)}</td>
      );
    }
    return <td />;
  };

  return (
    <tr onClick={() => onClick(order)}>
      <td>{renderType()}</td>
      {renderDeliveryDate()}
      {renderAddress()}
      <td className="text-center">
        {renderOptions().map((item) => item)}
      </td>
      {renderOffer()}
    </tr>
  );
}

Row.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  clientOffers: PropTypes.instanceOf(Array),
};

Row.defaultProps = {
  clientOffers: null,
};
