import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  margin-right: -8px;

  i {
    margin-right: 8px;    
  }
`;

/**
 * WorkOptionIcons()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function WorkOptionIcons(props) {
  const { object } = props;

  const renderOptions = () => {
    const optionsArray = [];
    if (object.workOptionElectric) {
      optionsArray.push(<i key={Math.random()} className="fas fa-plug" title="Elektro" />);
    }
    if (object.workOptionWater) {
      optionsArray.push(<i key={Math.random()} className="fas fa-hand-holding-water" title="Wasser" />);
    }
    if (object.workOptionAir) {
      optionsArray.push(<i key={Math.random()} className="fas fa-wind" title="Abluft" />);
    }
    if (object.workOptionLight) {
      optionsArray.push(<i key={Math.random()} className="fas fa-lightbulb" title="Beleuchtung" />);
    }
    if (object.workOptionQuooker) {
      optionsArray.push(<i key={Math.random()} className="fas fa-shower" title="Quooker" />);
    }

    return optionsArray;
  };

  return (
    <Container>
      {renderOptions()}
    </Container>
  );
}

WorkOptionIcons.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
};
