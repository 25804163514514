import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Button from '../Button';
import { formatDateTime } from '../../Library/Functions';
import ModalDialog from '../ModalDialog';
import { setDocumentsFinished } from '../../Redux/Action/ObjectAction';
import LoadingModal from '../LoadingModal';

/**
 * ObjectDocumentsFinishedButton()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function ObjectDocumentsFinishedButton(props) {
  const {
    client, order, object, isSystemContext, dispatchDocumentsFinished,
  } = props;
  const [caption, setCaption] = React.useState(null);
  const [showDocsFinished, setShowDocsFinished] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let captionParam = 'Mitteilung an Partner das alle nötigen Dokumente hochgeladen wurden!';
    if (object.documentsFinishedDateTime) {
      captionParam = 'Mitteilung an Partner das alle nötigen Dokumente hochgeladen wurden! '
        + `Letzte Mitteilung ${formatDateTime(object.documentsFinishedDateTime)} Uhr`;
    }
    setCaption(captionParam);
  }, [object]);

  const handleSetDocumentsFinished = () => {
    setLoading(true);
    setShowDocsFinished(false);
    setTimeout(() => {
      dispatchDocumentsFinished(object).then(() => {
        setLoading(false);
      }).catch(() => setLoading(false));
    }, 500);
  };

  if (client.clientId === order.clientId && order.mandantId && !isSystemContext) {
    return (
      <>
        <Button
          onClick={() => setShowDocsFinished(true)}
          type="btn-outline-success"
          style={{ marginRight: 10 }}
          title={caption}
          disabled={object.documentsFinished}
        >
          <i className="fas fa-check-circle" style={{ margin: 0 }} />
        </Button>

        <ModalDialog
          onClose={() => setShowDocsFinished(false)}
          visible={showDocsFinished}
          onConfirm={handleSetDocumentsFinished}
          confirmClass="btn-success"
          confirmCaption="Partner jetzt informieren"
          centered
        >
          <h5>Mitteilung an Partner - Dokumente vollständig!</h5>
          <p>
            Sie können für Ihren Kunden nachträglich weitere Dokumente hochladen und Ihn über diesen Button
            darüber informieren, dass alle notwendigen Dokumente vollständig sind. Der Partner erhält eine
            Mitteilung im System. Sie können diese Funktion jederzeit erneut aufrufen, wenn Sie neue Dokumente
            hinzugefügt haben.
          </p>
        </ModalDialog>
        <LoadingModal visible={loading} text="Mitteilung wird gesendet..." />
      </>
    );
  }
  return null;
}

ObjectDocumentsFinishedButton.propTypes = {
  dispatchDocumentsFinished: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  object: PropTypes.instanceOf(Object).isRequired,
  isSystemContext: PropTypes.bool,
};

ObjectDocumentsFinishedButton.defaultProps = {
  isSystemContext: false,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchDocumentsFinished: (object) => dispatch(setDocumentsFinished(object)),
  };
}

export default connect(() => {}, mapDispatchToProps)(ObjectDocumentsFinishedButton);
