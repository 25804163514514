/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types/prop-types';
import styled, { useTheme } from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  
  .dropdown-item {
    cursor: pointer;
  }
  
  .btn {
    background-color: ${(props) => ((props.theme.isFiltered) ? props.theme.color.primaryRedColor : props.theme.color.grayBackgroundColor)};
    color: ${(props) => ((props.theme.isFiltered) ? '#ffffff' : props.theme.color.primaryTextColor)};
    border-radius: ${(props) => props.theme.border.radius};
    
    >i {
      margin-left: 10px;
    }
    
    &:hover {
      ${(props) => props.theme.boxShadowDarkHover};
    }
  }
`;

/**
 * PartnerButton()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function PartnerButton(props) {
  const { partner, onChange, selectedPartnerId } = props;
  const theme = useTheme();

  const renderOptions = () => partner.map((item) => {
    const result = [];
    result.push((
      <li key={item.label}>
        <div
          className="dropdown-item"
          onClick={() => onChange(item)}
          role="button"
          tabIndex={-1}
          onKeyPress={() => {}}
        >
          {item.label}
        </div>
      </li>
    ));
    if (!item.value) {
      result.push((
        <li key={Math.random()}>
          <hr className="dropdown-divider" />
        </li>
      ));
    }

    return result;
  });

  const renderSelected = () => {
    const selected = partner.filter((item) => item.value === selectedPartnerId)[0];
    if (selected) {
      if (selectedPartnerId) {
        return `Partner: ${selected.label}`;
      }
      return selected.label;
    }
    return '';
  };

  if (partner && partner.length > 1) {
    return (
      <Container className="dropdown" theme={{ ...theme, isFiltered: (selectedPartnerId !== null) }}>
        <span
          className="btn dropdown-toggle"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {renderSelected()}
          <i className="fas fa-caret-down" />
        </span>

        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          {renderOptions()}
        </ul>
      </Container>
    );
  }
  return null;
}

PartnerButton.propTypes = {
  partner: PropTypes.instanceOf(Array),
  onChange: PropTypes.func.isRequired,
  selectedPartnerId: PropTypes.number,
};

PartnerButton.defaultProps = {
  partner: null,
  selectedPartnerId: null,
};
