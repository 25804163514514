import {
  NOTIFICATION_GETUNREAD_ERROR, NOTIFICATION_GETUNREAD_START, NOTIFICATION_GETUNREAD_SUCCESS,
  NOTIFICATION_DELETE_SUCCESS,
} from '../Action/NotificationAction';

const defaultValues = {
  notifications: null,
  unread: null,
  unreadCount: 0,
  isLoading: false,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case NOTIFICATION_GETUNREAD_START: {
      return { ...state, isLoading: true };
    }
    case NOTIFICATION_DELETE_SUCCESS:
    case NOTIFICATION_GETUNREAD_SUCCESS: {
      const { payload } = action;
      const unread = (payload) ? payload.filter((item) => !item.read) : 0;
      return {
        ...state,
        isLoading: false,
        notifications: payload,
        unread,
        unreadCount: (unread) ? unread.length : 0,
      };
    }
    case NOTIFICATION_GETUNREAD_ERROR: {
      return {
        ...state,
        isLoading: false,
        notifications: null,
        unread: null,
        unreadCount: 0,
      };
    }

    default: return { ...state };
  }
}
