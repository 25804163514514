import React, { useState } from 'react';
import Input from '../../../Components/Input';
import Button from '../../../Components/Button';
import Panel from '../../../Components/Panel';
import { useDispatchCreateFacility } from '../../../Redux/Action/FacilityAction';
import ModalDialog from '../../../Components/ModalDialog';
/**
 * Facility()
 * @returns {*}
 * @constructor
 */
export default function Facility() {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [errorAuth, setErrorAuth] = useState(false);
  const [error, setError] = useState('');

  const dispatchCreateFacility = useDispatchCreateFacility();

  const handleConnectClients = () => {
    if (!userName || !password) {
      setErrorAuth(true);
      return;
    }
    dispatchCreateFacility(userName, password)
      .then(() => {
        setAuthModalVisible(false);
        setConfirmModalVisible(true);
      })
      .catch((errorResponse) => {
        const { errorCode } = errorResponse.data;
        if (errorCode === 'AUTH_PASSWORD_INVALID') {
          setErrorAuth(true);
        } else {
          setError(errorCode);
          setErrorModalVisible(true);
          setAuthModalVisible(false);
        }
      });
  };

  const renderConfirmModal = () => (
    <ModalDialog
      onClose={() => window.location.reload()}
      visible={confirmModalVisible}
      hideConfirmButton
      closeCaption="Verstanden"
    >
      <h4>Betriebsstätten erfolgreich verbunden</h4>
      Die Betriebsstätten wurden erfolgreich miteinander verbunden.
      <br />
      Nach dem Neuladen der Seite können Sie in der Navigationsleiste zwischen Betriebsstätten wechseln.
    </ModalDialog>
  );

  const renderAuthModal = () => (
    <ModalDialog
      onClose={() => setAuthModalVisible(false)}
      visible={authModalVisible}
      hideConfirmButton
    >
      <Panel width="500px" containerStyle={{ marginRight: 'auto', marginLeft: 'auto' }}>
        <h3 className="hk-text-primary">
          Anmeldung / Login
        </h3>

        <hr style={{ margin: '40px 0' }} />

        <Input
          rightIcon="fas fa-user"
          onChange={(value) => setUserName(value)}
          value={userName}
          placeholder="Benutzername / E-Mail"
          inputClassName="form-control-lg"
          hasError={errorAuth}
        />
        <Input
          rightIcon="fas fa-key"
          type="password"
          onChange={(value) => setPassword(value)}
          value={password}
          placeholder="Passwort"
          inputClassName="form-control-lg"
          hasError={errorAuth}
        />

        <br />

        <div className="row">
          <div className="col-lg-12">
            <Button
              className="float-right"
              onClick={() => handleConnectClients()}
            >
              Betriebsstätten verbinden
            </Button>
          </div>
        </div>
      </Panel>
    </ModalDialog>
  );

  const getErrorString = () => {
    switch (error) {
      case 'CLIENT_FACILITIES_BOTH_CLIENTS_ARE_THE_SAME':
        return 'Die Beiden Clients dürfen nicht identisch sein.';
      case 'CLIENT_FACILITIES_CANT_BE_MERGED':
        return 'Die Beiden Clients gehören schon bereits zu unterschiedlichen Betriebsstätten.';
      default:
        return 'Ein Fehler ist aufgetreten.';
    }
  };

  const renderErrorModal = () => (
    <ModalDialog
      onClose={() => setErrorModalVisible(false)}
      visible={errorModalVisible}
      closeCaption="Schließen"
      hideConfirmButton
    >
      <h4>Fehler bei der Verbindung der Betriebsstätten</h4>
      {getErrorString()}
    </ModalDialog>
  );

  return (
    <div>
      <Panel marginBottom={20}>
        <div className="d-flex flex-row">
          <div>
            <h4 style={{ marginBottom: 20 }}>
              Betriebsstätten
            </h4>
            <p style={{ marginBottom: 0 }}>
              Wenn Sie mehrere unterschiedliche Filialen bzw. Betriebsstätten haben und Ihre Mitarbeiter Einblick
              in die Aufträge der anderen Filialen erhalten sollen, können Sie hier unterschiedliche Standorte
              miteinander verbinden.
              Damit haben Sie und Ihre Mitarbeiter nur noch einen Benutzer für mehrere Accounts / Standorte / Filialen.
              Um sich mit anderen Filialen zu verknüpfen, klicken Sie auf den Knopf "Betriebsstätten verbinden" und
              melden Sie sich daraufhin in den Adminaccount der anderen Filiale an.
              Damit werden beide Accounts miteinander verknüpft.
            </p>
            <p style={{ marginBottom: 80 }}>
              Bitte beachten Sie, dass die Benutzer aus der Benutzerverwaltung zusammengeführt werden und dann
              zentral über alle Betriebsstätten hinweg verwaltet werden.
            </p>
            <Button className="float-right" onClick={() => { setAuthModalVisible(true); }}>
              Betriebsstätten verbinden
            </Button>
          </div>
        </div>
      </Panel>

      {renderAuthModal()}
      {renderErrorModal()}
      {renderConfirmModal()}
    </div>
  );
}
