import {
  IMPORT_UPLOAD_CSV_ERROR, IMPORT_UPLOAD_CSV_START, IMPORT_UPLOAD_CSV_SUCCESS, IMPORT_EXECUTE_SUCCESS,
  IMPORT_CANCEL_SUCCESS,
} from '../Action/ImportAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  uploading: false,
  preparedOrders: null,
  errorCode: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /** GET ORDERS */
    case IMPORT_UPLOAD_CSV_START: {
      return { ...state, uploading: true, errorCode: null };
    }
    case IMPORT_UPLOAD_CSV_SUCCESS: {
      return {
        ...state, uploading: false, preparedOrders: action.payload, errorCode: null,
      };
    }
    case IMPORT_UPLOAD_CSV_ERROR: {
      return {
        ...state, uploading: false, preparedOrders: null, errorCode: action.payload.data.errorCode,
      };
    }

    case IMPORT_EXECUTE_SUCCESS: {
      return { ...state, preparedOrders: null };
    }

    case IMPORT_CANCEL_SUCCESS: {
      return {
        ...state, preparedOrders: null, uploading: false, errorCode: null,
      };
    }

    /** HANDLE LOGOUT * */
    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
