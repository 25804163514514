import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { Redirect, useParams, withRouter } from 'react-router';
import { get } from '../../../Redux/Action/OrderAction';
import Panel from '../../../Components/Panel';
import OrderStatusBadge from '../../../Components/OrderComponents/OrderStatusBadge';
import LoadingModal from '../../../Components/LoadingModal';
import ObjectPreviewContainer from '../../../Components/ObjectComponents/ObjectPreviewContainer';
import { NAVIGATION_SYSTEM_OBJECT_DETAILS, ORDERTYPE_SINGLE } from '../../../Library/Types';
import PriceInformation from '../../../Components/SystemComponents/PriceInformation';
import { isOrderClosed } from '../../../Library/Functions';
import OrderInformationPanel from '../../../Components/OrderComponents/OrderInformationPanel';

/**
 * Details()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function Details(props) {
  const {
    dispatchGetDetails, order, history,
  } = props;
  const { orderId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (orderId) {
      setLoading(true);
      dispatchGetDetails(parseInt(orderId, 0))
        .then(() => {
          setLoading(false);
          setLoaded(true);
        })
        .catch(() => setLoading(false));
    }
  }, [orderId, dispatchGetDetails]);

  const navigateDetails = (object) => {
    const uri = NAVIGATION_SYSTEM_OBJECT_DETAILS.replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);
    history.push(uri);
  };

  if (!loading && order && loaded) {
    if (order.type === ORDERTYPE_SINGLE) {
      const uri = NAVIGATION_SYSTEM_OBJECT_DETAILS.replace('{orderId}', orderId)
        .replace('{objectId}', order.objects[0].objectId);
      return (
        <Redirect to={uri} />
      );
    }

    return (
      <>
        <div className="row">
          <div className="col-md-12 d-flex">
            <Panel marginBottom={20}>
              <h4>
                {`Objektauftrag - ${(order.externalId) || order.orderId}`}
              </h4>
              <OrderStatusBadge orderState={order.state} containerStyle={{ marginRight: 10 }} />
            </Panel>
          </div>

          <div className="col-md-6 d-flex">
            <OrderInformationPanel order={order} />
          </div>
          <div className="col-md-6 d-flex">
            <PriceInformation order={order} editable={!isOrderClosed(order)} />
          </div>

          <div className="col-lg-12">
            {order.objects.map((item, index) => (
              <ObjectPreviewContainer
                object={item}
                order={order}
                key={item.objectId}
                onClick={navigateDetails}
                index={index}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
  return <LoadingModal visible={loading} text="Auftrag wird geladen..." />;
}

Details.propTypes = {
  dispatchGetDetails: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
};

Details.defaultProps = {
  order: null,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetDetails: (orderId) => dispatch(get(orderId, true)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Details));
