import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { create, getList, remove } from '../Redux/Action/MaterialAction';
import Panel from '../../../Components/Panel';
import Button from '../../../Components/Button';
import Add from './Add';
import { formatCurrency } from '../../../Library/Functions';
import ModalDialog from '../../../Components/ModalDialog';

/**
 * MaterialItem()
 * @param props
 * @returns {*}
 * @constructor
 */
function MaterialItem(props) {
  const { item, onDelete } = props;
  const [askDelete, setAskDelete] = React.useState(false);

  const handleDelete = () => {
    setAskDelete(false);
    onDelete(item);
  };

  return (
    <tr key={item.materialId}>
      <td>
        <div>{item.caption}</div>
        <div className="text-muted">
          <small>{item.description}</small>
        </div>
      </td>
      <td width={150}>{ formatCurrency(item.priceMonteur)}</td>
      <td width={150}>{ formatCurrency(item.priceDealer)}</td>
      <td>
        <Button onClick={() => setAskDelete(true)} className="btn-sm" type="btn-light">
          <i className="fas fa-trash-alt" />
        </Button>

        <ModalDialog
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          confirmCaption="Löschen"
          centered
          onConfirm={handleDelete}
        >
          <h5 className="hk-text-primary">
            Material löschen?
          </h5>
          <p>
            Bitte bestätigen Sie das Sie das ausgewählte Material endgültig löschen möchten!
          </p>
        </ModalDialog>
      </td>
    </tr>
  );
}

MaterialItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
};

/**
 * Overview()
 * @param props
 * @returns {*}
 * @constructor
 */
function Overview(props) {
  const {
    dispatchGetList, materials, dispatchCreate, dispatchDelete,
  } = props;
  const [showAdd, setShowAdd] = React.useState(false);

  React.useEffect(() => {
    dispatchGetList();
  }, [dispatchGetList]);

  const handleCreate = (data) => {
    dispatchCreate(data).then(() => {
      dispatchGetList();
      setShowAdd(false);
    });
  };

  const handleDelete = (data) => {
    dispatchDelete(data.materialId).then(() => {
      dispatchGetList();
    });
  };

  const renderMaterials = () => {
    if (materials && materials.length > 0) {
      return (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Monteurpreis</th>
              <th>Verkäuferpreis</th>
              <th width={50} />
            </tr>
          </thead>
          <tbody>
            {materials.map((item) => (
              <MaterialItem key={item.materialId} item={item} onDelete={handleDelete} />
            ))}
          </tbody>
        </table>
      );
    }
    return null;
  };

  return (
    <>
      <Panel marginBottom={25} boxPadding={10}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <h5 style={{ margin: 0 }}>Materialverwaltung</h5>
          </div>
          <div>
            <Button onClick={() => setShowAdd(true)}>
              <i className="fas fa-plus" style={{ margin: 0 }} />
            </Button>
          </div>
        </div>
      </Panel>
      <Panel>
        <div>
          {renderMaterials()}
        </div>
      </Panel>

      <Add onSave={handleCreate} onClose={() => setShowAdd(false)} visible={showAdd} />
    </>
  );
}

Overview.propTypes = {
  dispatchGetList: PropTypes.func.isRequired,
  dispatchCreate: PropTypes.func.isRequired,
  dispatchDelete: PropTypes.func.isRequired,
  materials: PropTypes.instanceOf(Array),
};

Overview.defaultProps = {
  materials: null,
};

function mapStoreToProps(store) {
  return {
    materials: store.systemMaterial.materials,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetList: () => dispatch(getList()),
    dispatchCreate: (data) => dispatch(create(data)),
    dispatchDelete: (id) => dispatch(remove(id)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Overview));
