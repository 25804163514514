import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import ModalDialog from '../../../Components/ModalDialog';
import DatePickerInput from '../../../Components/DatePickerInput';
import { update, remove } from '../../../Redux/Action/UserHolidayAction';
import Input from '../../../Components/Input';
import Button from '../../../Components/Button';
import {
  ERRORCODE_USER_HOLIDAY_ALREADY_EXISTING,
  ERRORCODE_USER_HOLIDAY_HAS_ORDER_ASSIGNED,
  ERRORCODE_USER_HOLIDAY_HAS_RECLAMATION_ASSIGNED,
  HOLIDAY_REASON_HOLIDAY,
  holidayReasonTypeOptions,
} from '../../../Library/Types';
import SelectBox from '../../../Components/SelectBox';

const approveOptions = [
  { caption: 'Urlaub freigegeben', key: true },
  { caption: 'Urlaub nicht freigegeben', key: false },
];

/**
 * DeleteButton()
 * @param props
 * @returns {*}
 * @constructor
 */
function DeleteButton(props) {
  const { onClick } = props;

  return (
    <>
      <Button onClick={() => onClick()} type="btn-danger-light">
        Eintrag löschen
      </Button>
    </>
  );
}

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const initialState = {
  id: null,
  userId: null,
  startDate: '',
  endDate: '',
  approved: false,
  comment: null,
  reason: HOLIDAY_REASON_HOLIDAY,
  endDateError: false,
  startDateError: false,
  userIdError: false,
  hasError: false,
  hasChanges: false,
  hasOrderAssigned: false,
  hasReclamationAssigned: false,
  hasHolidayAssigned: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'startDate':
      return { ...state, startDate: action.payload, hasChanges: true };
    case 'endDate':
      return { ...state, endDate: action.payload, hasChanges: true };
    case 'reason':
      return { ...state, reason: action.payload, hasChanges: true };
    case 'approved':
      return { ...state, approved: (action.payload === 'true'), hasChanges: true };
    case 'userIdError':
      return { ...state, userIdError: true, hasError: true };
    case 'startDateError':
      return { ...state, startDateError: true, hasError: true };
    case 'endDateError':
      return { ...state, endDateError: true, hasError: true };
    case 'hasOrderAssigned':
      return { ...state, hasOrderAssigned: true, hasError: true };
    case 'hasReclamationAssigned':
      return { ...state, hasReclamationAssigned: true, hasError: true };
    case 'hasHolidayAssigned':
      return {
        ...state,
        hasHolidayAssigned: true,
        hasError: true,
        endDateError: true,
        startDateError: true,
      };
    case 'resetErrors':
      return {
        ...state,
        endDateError: false,
        startDateError: false,
        userIdError: false,
        hasError: false,
        hasOrderAssigned: false,
        hasReclamationAssigned: false,
        hasHolidayAssigned: false,
      };
    case 'reset':
      return { ...initialState };
    case 'init':
      return {
        id: action.payload.id,
        userId: action.payload.userId,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        reason: action.payload.reason,
        approved: action.payload.approved,
        comment: action.payload.comment,
        hasError: false,
        hasChanges: false,
      };
    default:
      throw new Error();
  }
}

/**
 * UpdateModal()
 * @param props
 * @returns {*}
 * @constructor
 */
function UpdateModal(props) {
  const {
    onSave, visible, onClose, dispatchUpdate, initialData, users, dispatchDelete,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (initialData) {
      dispatch({ type: 'init', payload: { ...initialData } });
    }
  }, [initialData]);

  const handleClose = () => {
    dispatch({ type: 'reset' });
    onClose();
  };

  const handleOnDelete = () => {
    dispatchDelete(state.userId, state.id).then(() => {
      dispatch({ type: 'reset' });
      onSave();
    });
  };

  const handleOnSave = () => {
    let hasError = false;
    dispatch({ type: 'resetErrors' });

    if (state.startDate.length === 0) {
      dispatch({ type: 'startDateError' });
      hasError = true;
    }
    if (state.endDate.length === 0) {
      dispatch({ type: 'endDateError' });
      hasError = true;
    }
    if (state.endDate.length > 0 && state.startDate.length > 0) {
      const start = moment(state.startDate, 'YYYY-MM-DD');
      const end = moment(state.endDate, 'YYYY-MM-DD');
      if (!end.isSameOrAfter(start)) {
        dispatch({ type: 'endDateError' });
        hasError = true;
      }
    }

    if (!hasError) {
      const start = moment(state.startDate, 'YYYY-MM-DD');
      const end = moment(state.endDate, 'YYYY-MM-DD');
      const days = end.diff(start, 'days') + 1;

      const data = {
        id: state.id,
        userId: state.userId,
        startDate: state.startDate,
        endDate: state.endDate,
        reason: state.reason,
        approved: state.approved,
        days,
      };
      dispatchUpdate(state.userId, data).then(() => {
        dispatch({ type: 'reset' });
        onSave();
      }).catch((error) => {
        if (error.data && error.data.errorCode) {
          if (error.data.errorCode === ERRORCODE_USER_HOLIDAY_ALREADY_EXISTING) {
            dispatch({ type: 'hasHolidayAssigned' });
          } else if (error.data.errorCode === ERRORCODE_USER_HOLIDAY_HAS_ORDER_ASSIGNED) {
            dispatch({ type: 'hasOrderAssigned' });
          } else if (error.data.errorCode === ERRORCODE_USER_HOLIDAY_HAS_RECLAMATION_ASSIGNED) {
            dispatch({ type: 'hasReclamationAssigned' });
          }
        }
      });
    }
  };

  const getName = () => {
    if (users && initialData) {
      const user = users.filter((itemData) => itemData.userId === initialData.userId)[0];
      if (user) {
        return `${user.lastname}, ${user.firstname}`;
      }
    }
    return null;
  };

  const renderAlreadyAssignedHint = () => {
    if (state.hasHolidayAssigned) {
      return (
        <div className="alert alert-danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          Der Mitarbeiter hat im angegebenen Zeitraum bereits einen Urlaubseintrag!
        </div>
      );
    }
    if (state.hasOrderAssigned) {
      return (
        <div className="alert alert-danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          Der Mitarbeiter hat im angegebenen Zeitraum bereits einen Montageauftrag zugewiesen.
        </div>
      );
    }
    if (state.hasReclamationAssigned) {
      return (
        <div className="alert alert-danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          Der Mitarbeiter hat im angegebenen Zeitraum bereits einen Reklamationsauftrag zugewiesen.
        </div>
      );
    }
    return null;
  };

  const renderComment = () => {
    if (state.comment) {
      return (
        <div className="alert alert-info">
          <div>
            <strong>
              <i className="fas fa-comment" style={{ marginRight: 10 }} />
              {`Information/Kommentar von ${getName()}`}
            </strong>
          </div>
          <p>
            {state.comment}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <ModalDialog
      onClose={handleClose}
      visible={visible}
      confirmCaption="Änderung speichern"
      onConfirm={handleOnSave}
      disableConfirm={!state.hasChanges}
      leftButtonContent={<DeleteButton onClick={handleOnDelete} />}
    >
      <h5>Urlaubseintrag bearbeiten</h5>
      <hr />

      <div className="row">
        <Input
          onChange={() => {}}
          value={getName()}
          label="Mitarbeiter"
          disabled
          hasError={state.userIdError}
          rowClass="col-lg-6"
        />
        <SelectBox
          onChange={(value) => dispatch({ type: 'reason', payload: value })}
          data={holidayReasonTypeOptions}
          label="Grund / Typ"
          selected={(state.reason) || ''}
          rowClass="col-lg-6"
        />
        <DatePickerInput
          onChange={(value) => dispatch({ type: 'startDate', payload: value })}
          label="Startdatum (inklusive)"
          rowClass="col-lg-6"
          hasError={state.startDateError}
          initialValue={state.startDate}
        />
        <DatePickerInput
          onChange={(value) => dispatch({ type: 'endDate', payload: value })}
          label="Enddatum (inklusive)"
          rowClass="col-lg-6"
          hasError={state.endDateError}
          minDate={moment(state.startDate).toDate()}
          initialValue={state.endDate}
        />
        <SelectBox
          onChange={(value) => dispatch({ type: 'approved', payload: value })}
          data={approveOptions}
          label="Freigabe"
          selected={(state.approved) ? 'true' : 'false'}
          rowClass="col-lg-12"
        />
      </div>

      {renderComment()}
      {renderAlreadyAssignedHint()}
    </ModalDialog>
  );
}

UpdateModal.propTypes = {
  dispatchUpdate: PropTypes.func.isRequired,
  dispatchDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  initialData: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Array),
};

UpdateModal.defaultProps = {
  visible: false,
  initialData: null,
  users: null,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdate: (userId, data) => dispatch(update(userId, data)),
    dispatchDelete: (userId, holidayId) => dispatch(remove(userId, holidayId)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(UpdateModal);
