import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import Panel from '../../Components/Panel';
import { get, update } from './Redux/Action/SettingsAction';
import KeyValueEditRow from '../../Components/KeyValueEditRow';
import { formatCurrency } from '../../Library/Functions';

/**
 * SystemSettings()
 * @param props
 * @returns {*}
 * @constructor
 */
function SystemSettings(props) {
  const {
    dispatchGetSettings, dispatchUpdateSettings,
  } = props;
  const [settings, setSettings] = React.useState();

  React.useEffect(() => {
    dispatchGetSettings().then((response) => setSettings(response));
  }, [dispatchGetSettings]);

  const updateSetting = (field, value) => {
    dispatchUpdateSettings(field, value).then((response) => setSettings(response));
  };

  if (settings) {
    return (
      <div>
        <Panel marginBottom={25} boxPadding={10}>
          <h5 style={{ margin: 0 }}>Systemeinstellungen</h5>
        </Panel>
        <Panel>
          <h6 style={{ marginBottom: 40 }}>Einstellungen für Auftragsberechnung</h6>

          <div className="row">
            <div className="col-lg-12" style={{ marginBottom: 20 }}>
              Hier können Sie festlegen ob ein Aufschlag auf den für den Verkäufer berechneten Preis erfolgen soll.
              Sie können den Aufschlag in einem Eurowert oder einem Prozenwert definieren. Ist ein Eurowert größer 0
              gesetzt wird immer der Eurowert verwendet. Ansonsten der Prozentwert. Sind beide Werte auf 0 eingestellt,
              wird der ursprüngliche, vom Algorithmus berechnete Preis genommen!
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                type="text"
                title="Verkäuferaufschlag in Euro"
                displayText={formatCurrency(settings.calculationDealerIncreaseEuro)}
                isNumeric
                initialValue={settings.calculationDealerIncreaseEuro}
                onChange={(value) => updateSetting('calculationDealerIncreaseEuro', value)}
              />
            </div>
            <div className="col-lg-6">
              <KeyValueEditRow
                type="text"
                isNumeric
                title="Verkäuferaufschlag in Prozent"
                displayText={`${settings.calculationDealerIncreasePercent}%`}
                initialValue={settings.calculationDealerIncreasePercent}
                onChange={(value) => updateSetting('calculationDealerIncreasePercent', value)}
              />
            </div>

            <div className="col-lg-12" style={{ marginBottom: 20, marginTop: 30 }}>
              Hier können Sie festlegen welche Marge für Aufträge verschiedener Preisstufen einbehalten werden soll.
              Beispiel: Ist der Preis für den Verkäufer 800,00€, wird von den 800,00€ die Summe abgezogen die definiert
              ist, z.B. 120,00€. Dies macht dann einen Preis für den Monteur von 680,00€. Es bleibt also eine
              Marge für diesen Auftrag von 120,00€ übrig. Die Grundlage ist immer der Verkäuferpreis
              {' '}
              <strong>nach</strong>
              {' '}
              den ggf. oben definierten Aufschlägen!
            </div>
            <div className="col-lg-12">
              <KeyValueEditRow
                type="text"
                isNumeric
                title="Anteilige Abzug der Kosten für den Monteur bei Aufträgen zwischen 600,00€ und 1000,00€"
                displayText={formatCurrency(settings.calculationMonteurDiscount600)}
                initialValue={settings.calculationMonteurDiscount600}
                onChange={(value) => updateSetting('calculationMonteurDiscount600', value)}
              />
            </div>
            <div className="col-lg-12">
              <KeyValueEditRow
                type="text"
                isNumeric
                title="Anteilige Abzug der Kosten für den Monteur bei Aufträgen zwischen 1000,01€ und 1500,00€"
                displayText={formatCurrency(settings.calculationMonteurDiscount1000)}
                onChange={(value) => updateSetting('calculationMonteurDiscount1000', value)}
              />
            </div>
            <div className="col-lg-12">
              <KeyValueEditRow
                type="text"
                isNumeric
                title="Anteilige Abzug der Kosten für den Monteur bei Aufträgen größer 1500,00€"
                displayText={formatCurrency(settings.calculationMonteurDiscount1500)}
                onChange={(value) => updateSetting('calculationMonteurDiscount1500', value)}
              />
            </div>
          </div>
        </Panel>
      </div>
    );
  }
  return null;
}

SystemSettings.propTypes = {
  dispatchGetSettings: PropTypes.func.isRequired,
  dispatchUpdateSettings: PropTypes.func.isRequired,
};

SystemSettings.defaultProps = {

};

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetSettings: () => dispatch(get()),
    dispatchUpdateSettings: (field, value) => dispatch(update(field, value)),
  };
}

export default connect(() => ({}), mapDispatchToProps)(SystemSettings);
