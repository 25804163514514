import React from 'react';
import PropTypes from 'prop-types/prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import ShowHideContainer from '../ShowHideContainer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Headline = styled.div`
  margin-bottom: 6px;
  font-family: "DIN Next LT W01 Bold", sans-serif;
  color: black;
`;

const Row = styled.div`
  background-color: ${(props) => props.theme.color.grayBackgroundColor};
  border-radius: ${(props) => props.theme.border.radius};
  padding: 10px 20px;
  color: ${(props) => props.theme.color.darkTextColor};
  margin-bottom: 10px;
  flex-direction: row;
  display: flex;

  .Left {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    align-content: center;

    .Usernames {
      font-size: 14px;
      color: #7c7c7c;
    }
  }

  .Right {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    
    i {
      cursor: pointer;
      font-size: 14px;
      color: #dddddd;

      &:hover {
        color: ${(props) => props.theme.color.primaryRedColor};
      }
    }
  }
}
`;

/**
 * TeamMonteurPartnerRow()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TeamMonteurPartnerRow(props) {
  const {
    users, teamId, partnerId, onDeleteTeam, onDeletePartner, onDeleteUser, client, hideUsersOrTeam,
    disabled,
  } = props;
  const { teams } = useSelector((state) => state.teams);
  const { partners } = useSelector((state) => state.client);

  const renderDeleteTeam = (team) => {
    if (((team.clientId === client.clientId)
      || (client.mainFacilityId && team.clientId === client.mainFacilityId))
      && !disabled) {
      return (
        <div className="Right">
          <i
            className="fas fa-trash"
            onClick={() => onDeleteTeam(team.teamId)}
            tabIndex={-1}
            onKeyPress={() => {
            }}
            role="button"
          />
        </div>
      );
    }
    return null;
  };

  const renderDeleteUser = (user) => {
    if (((user.clientId === client.clientId)
      || (client.mainFacilityId && user.clientId === client.mainFacilityId))
      && !disabled) {
      return (
        <div className="Right">
          <i
            className="fas fa-trash"
            onClick={() => onDeleteUser(user)}
            tabIndex={-1}
            onKeyPress={() => {
            }}
            role="button"
          />
        </div>
      );
    }
    return null;
  };

  const renderTeam = () => {
    if (teamId && teams && !hideUsersOrTeam) {
      const team = teams.filter((item) => item.teamId === teamId)[0];
      if (team) {
        return (
          <>
            <Headline>
              Zugewiesenes Team
            </Headline>
            <Row>
              <div className="Left">
                <div>
                  <i className="fas fa-users" style={{ marginRight: 10 }} />
                </div>
                <div>
                  <div>
                    {team.name}
                  </div>
                  <div className="Usernames">
                    {team.users.map((item) => `${item.firstname} ${item.lastname}`).join(', ')}
                  </div>
                </div>
              </div>
              {renderDeleteTeam(team)}
            </Row>
          </>
        );
      }
    }
    return null;
  };

  const renderUsers = () => {
    if (users && users.length > 0 && !hideUsersOrTeam) {
      const content = users.map((user) => (
        <Row>
          <div className="Left">
            <i className="fas fa-user" style={{ marginRight: 10 }} />
            {`${user.firstname} ${user.lastname}`}
          </div>
          {renderDeleteUser(user)}
        </Row>
      ));
      return (
        <>
          <Headline>
            Zugewiesene Monteure
          </Headline>
          {content}
        </>
      );
    }
    return null;
  };

  const renderPartnerAssignedHint = () => {
    if ((users && users.length > 0) || teamId) {
      return (
        <Tooltip
          className="fas Small"
          content="Der Partner hat bereits ein Team oder Monteure zugewiesen!"
          useDefaultStyles
          direction="left"
        >
          <i
            className="fas fa-user-check text-success"
            style={{ marginRight: 15 }}
          />
        </Tooltip>
      );
    }
    return null;
  };

  const renderPartner = () => {
    if (partnerId && partners && partners.length > 0) {
      const partner = partners.filter((item) => item.partner.clientId === partnerId)[0];
      if (partner) {
        return (
          <>
            <Headline>
              Zugewiesener Partner (externe Durchführung)
            </Headline>
            <Row>
              <div className="Left">
                <i className="fas fa-building" style={{ marginRight: 10 }} />
                {partner.partner.name}
              </div>
              <div className="Right">
                {renderPartnerAssignedHint()}
                <ShowHideContainer visible={!disabled}>
                  <i
                    className="fas fa-trash"
                    onClick={() => onDeletePartner(partnerId)}
                    tabIndex={-1}
                    onKeyPress={() => {}}
                    role="button"
                  />
                </ShowHideContainer>
              </div>
            </Row>
          </>
        );
      }
    }
    return null;
  };

  return (
    <Container>
      {renderPartner()}
      {renderTeam()}
      {renderUsers()}
    </Container>
  );
}

TeamMonteurPartnerRow.propTypes = {
  onDeleteTeam: PropTypes.func.isRequired,
  onDeletePartner: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  teamId: PropTypes.number,
  partnerId: PropTypes.number,
  users: PropTypes.instanceOf(Array),
  hideUsersOrTeam: PropTypes.bool,
  client: PropTypes.instanceOf(Object).isRequired,
  disabled: PropTypes.bool,
};

TeamMonteurPartnerRow.defaultProps = {
  teamId: null,
  partnerId: null,
  users: null,
  hideUsersOrTeam: false,
  disabled: false,
};
