import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { connect } from 'react-redux';
import {
  NavLink, useRouteMatch, withRouter,
} from 'react-router-dom';
import Panel from '../../Panel';
import { getList } from '../../../Pages/System/Redux/Action/InvoiceAction';

/**
 * SystemInvoiceNavigation()
 * @returns {*}
 * @constructor
 */
function SystemInvoiceNavigation(props) {
  const {
    dispatchOpenCredits, dispatchOpenDebits, openCredits, openDebits,
  } = props;
  const { url } = useRouteMatch();

  React.useEffect(() => {
    dispatchOpenDebits();
    dispatchOpenCredits();
  }, [dispatchOpenDebits, dispatchOpenCredits]);

  const renderGenerateDebits = () => {
    let caption = 'Lastschriften generieren';
    if (openDebits && openDebits.length > 0) {
      caption = `Lastschriften generieren (${openDebits.length})`;
    }

    return (
      <li>
        <NavLink className="nav-link" to={`${url}/generatedebits`}>
          <i className="fas fa-donate" />
          {caption}
        </NavLink>
      </li>
    );
  };

  const renderGenerateCredits = () => {
    let caption = 'Gutschriften generieren';
    if (openCredits && openCredits.length > 0) {
      caption = `Gutschriften generieren (${openCredits.length})`;
    }

    return (
      <li>
        <NavLink className="nav-link" to={`${url}/generatecredits`}>
          <i className="fas fa-hand-holding-usd" />
          {caption}
        </NavLink>
      </li>
    );
  };

  return (
    <Panel boxPadding={15}>
      <div className="Navigation">
        <div className="Header">
          <h6 className="Headline">
            Rechnungen
          </h6>
        </div>

        <hr />

        <ul>
          <li>
            <NavLink className="nav-link" to={`${url}/open`}>
              <i className="fas fa-folder-open" />
              Übersicht
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={`${url}/create`}>
              <i className="fas fa-plus" />
              Erstellen
            </NavLink>
          </li>
          {renderGenerateDebits()}
          {renderGenerateCredits()}
          <hr />
          <li>
            <NavLink className="nav-link" to={`${url}/clearing`}>
              <i className="fas fa-dollar-sign" />
              Monatliche Abrechnungen
            </NavLink>
          </li>
          <hr />
          <li>
            <NavLink className="nav-link" to="/system" activeClassName={null}>
              <i className="fas fa-angle-double-left" />
              Zurück
            </NavLink>
          </li>
        </ul>
      </div>
    </Panel>
  );
}

SystemInvoiceNavigation.propTypes = {
  dispatchOpenDebits: PropTypes.func.isRequired,
  dispatchOpenCredits: PropTypes.func.isRequired,
  openDebits: PropTypes.instanceOf(Array),
  openCredits: PropTypes.instanceOf(Array),
};

SystemInvoiceNavigation.defaultProps = {
  openDebits: null,
  openCredits: null,
};

function mapStoreToProps(store) {
  return {
    invoices: store.systemInvoice.invoices,
    openDebits: store.systemInvoice.openDebits,
    openCredits: store.systemInvoice.openCredits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchOpenDebits: () => dispatch(getList('openDebits')),
    dispatchOpenCredits: () => dispatch(getList('openCredits')),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(SystemInvoiceNavigation));
