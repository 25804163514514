import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { formatDate } from '../../../Library/Functions';
import KeyValueEditRow from '../../KeyValueEditRow';

const Container = styled.div`
    margin-bottom: 5px;
`;

/**
 * AppointmentView()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function AppointmentView(props) {
  const {
    earliestStartDate, earliestStartComment, onDateChange, onCommentChange,
  } = props;

  const noInfo = () => (<i className="text-muted">Keine Daten eingetragen</i>);

  const getFormattedDate = () => (earliestStartDate ? formatDate(earliestStartDate) : null);

  return (
    <Container>
      <div className="row">
        <div className="col-lg-12">
          <KeyValueEditRow
            title="Frühestes Startdatum"
            initialValue={getFormattedDate()}
            displayText={getFormattedDate()}
            placeholder={noInfo()}
            actionIcon={onDateChange ? 'fas fa-pencil-alt' : null}
            onChange={onDateChange}
            type="date"
            datePickerMinDate={moment().toDate()}
          />
        </div>
        <div className="col-lg-12">
          <KeyValueEditRow
            title="Zusätzliche Informationen / Beschreibung"
            initialValue={earliestStartComment}
            displayText={earliestStartComment}
            placeholder={noInfo()}
            actionIcon={onCommentChange ? 'fas fa-pencil-alt' : null}
            onChange={onCommentChange}
            type="textarea"
          />
        </div>
      </div>
    </Container>
  );
}

AppointmentView.propTypes = {
  earliestStartDate: PropTypes.string,
  earliestStartComment: PropTypes.string,
  onDateChange: PropTypes.func,
  onCommentChange: PropTypes.func,
};

AppointmentView.defaultProps = {
  earliestStartDate: null,
  earliestStartComment: '',
  onDateChange: undefined,
  onCommentChange: undefined,
};
