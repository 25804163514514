import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Redux from 'react-redux';
import moment from 'moment';
import { NAVIGATION_SYSTEM_OBJECT_SERVICE_DETAILS } from '../../../../../Library/Types';
import ServicePage from '../../../Component/ServicePage';
import { useDispatchSystemGetClientList } from '../../../Redux/Action/ClientAction';
import { useDispatchGetServiceView } from '../../../Redux/Action/ServiceAction';

/**
 * ExternalService()
 * @returns {JSX.Element}
 * @constructor
 */
export default function ExternalService() {
  const history = useHistory();

  const variant = 'external';
  const startDate = moment().subtract(3, 'weeks');
  const dispatchGetServiceView = useDispatchGetServiceView(variant, startDate, null);
  const dispatchSystemGetClientList = useDispatchSystemGetClientList();

  const { clients } = Redux.useSelector((state) => state.systemClient);
  const lastUpdated = Redux.useSelector((store) => (store.systemService[variant].lastUpdated));
  const isLoading = Redux.useSelector((store) => (store.systemService[variant].isLoading));

  React.useEffect(() => {
    if (!clients || clients.length < 0) {
      dispatchSystemGetClientList().then();
    }
  }, [clients, dispatchSystemGetClientList]);

  const getHeadline = () => 'Externe Services';

  const navigateDetails = (service) => {
    const uri = NAVIGATION_SYSTEM_OBJECT_SERVICE_DETAILS.replace('{orderId}', service.orderId)
      .replace('{objectId}', service.objectId)
      .replace('{serviceId}', service.serviceId);
    history.push(uri);
  };

  return (
    <ServicePage
      variant="external"
      lastUpdated={lastUpdated}
      isLoading={isLoading}
      getHeadline={getHeadline}
      onClick={navigateDetails}
      dispatchGetObjects={dispatchGetServiceView}
    />
  );
}
