import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { darken, lighten } from 'polished';

const lightenFactor = 0.4;

const Container = styled.div`
  height: auto;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 14px;
  padding: 8px 20px;
  line-height: 16px;

  > i {
    margin-right: 8px !important;
    font-size: 12px !important;
  }

  &.Primary {
    color: ${(props) => props.theme.color.primaryColor};
    background-color: ${(props) => lighten(lightenFactor, props.theme.color.primaryColor)};
  }

  &.Danger {
    background-color: ${(props) => lighten(lightenFactor, props.theme.color.primaryRedColor)};
    color: ${(props) => props.theme.color.primaryRedColor};
  }

  &.Default {
    background-color: ${(props) => lighten(lightenFactor, props.theme.color.primaryTextColor)};
    color: ${(props) => props.theme.color.primaryTextColor};
  }

  &.Success {
    background-color: ${(props) => lighten(lightenFactor, props.theme.color.primaryGreenColor)};
    color: ${(props) => props.theme.color.primaryGreenColor};
  }

  &.Warning {
    background-color: ${(props) => lighten(0.3, props.theme.color.primaryYellowColor)};
    color: ${(props) => darken(0.15, props.theme.color.primaryYellowColor)};
  }

  &.Info {
    background-color: ${(props) => lighten(lightenFactor, props.theme.color.primaryOrangeColor)};
    color: ${(props) => props.theme.color.primaryOrangeColor};
  }

  @media (max-width: 1700px) {
    &.WithMediaQuery {
      i {
        margin-right: 0 !important;
      }
      > .Caption {
        display: none;
      }
    }
  }
`;

/**
 * StatusBadge()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function StatusBadge(props) {
  const {
    type, icon, caption, containerStyle, visible, alignContainer, iconOnly, useMediaQuery, children,
  } = props;
  const theme = useTheme();

  const renderIcon = () => {
    if (icon) {
      return <i className={icon} />;
    }
    return null;
  };

  const renderCaption = () => {
    if (iconOnly || (!children && !caption)) {
      return null;
    }
    return (caption) || children;
  };

  if (visible) {
    return (
      <div style={{ display: 'flex', justifyContent: alignContainer }}>
        <Container
          className={`${(useMediaQuery) ? 'WithMediaQuery' : ''} ${type}`}
          style={containerStyle}
          title={renderCaption()}
          theme={{ ...theme }}
        >
          {renderIcon()}
          <span className="Caption">
            {renderCaption()}
          </span>
        </Container>
      </div>
    );
  }
  return null;
}

StatusBadge.propTypes = {
  type: PropTypes.oneOf(['Danger', 'Primary', 'Warning', 'Default', 'Success', 'Info']),
  containerStyle: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  alignContainer: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  iconOnly: PropTypes.bool,
  useMediaQuery: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  caption: PropTypes.string,
};

StatusBadge.defaultProps = {
  type: 'Primary',
  containerStyle: {},
  visible: true,
  alignContainer: 'flex-start',
  iconOnly: false,
  useMediaQuery: false,
  icon: null,
  children: null,
  caption: null,
};
