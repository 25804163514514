/* eslint-disable jsx-a11y/media-has-caption,max-len */
import React from 'react';
import { Waypoint } from 'react-waypoint';

const videoPreview = require('../../../../Assets/Images/videopreview.png');

const video = 'https://firebasestorage.googleapis.com/v0/b/hey-kitchen-production.appspot.com/o/videos%2Fhk-video-01.mp4?alt=media&token=23ebd07c-b511-4140-98f0-b8362406ba3a';

/**
 * ErklaerVideo()
 * @returns {*}
 * @constructor
 */
export default function ErklaerVideo() {
  const videoRef = React.useRef();

  return (
    <Waypoint onEnter={() => {}} onLeave={() => videoRef.current.pause()}>
      <div style={{ width: '100%' }}>
        <video controls ref={videoRef} poster={videoPreview} style={{ width: '100%' }}>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </Waypoint>
  );
}
