import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Route, withRouter, Redirect, useHistory,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import DefaultLayout from '../Components/Layout/DefaultLayout';
import AuthedNavigation from '../Components/Navigation/AuthedNavigation';
import MobileDeactivatedHint from '../Components/MobileDeactivatedHint';

/**
 * ProtectedRoute()
 * @param props
 * @returns {*}
 * @constructor
 */
function ProtectedRoute(props) {
  const {
    path, children, isLoggedin, location,
  } = props;
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(history.location.pathname);
  }, [history.location]);

  const renderChildren = () => {
    if (isMobileOnly) {
      return <MobileDeactivatedHint />;
    }
    return children;
  };

  if (isLoggedin) {
    return (
      <Route
        exact
        path={path}
        render={() => (
          <DefaultLayout navigation={<AuthedNavigation />}>
            {renderChildren()}
          </DefaultLayout>
        )}
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: location,
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLoggedin: PropTypes.bool,
  location: PropTypes.instanceOf(Object).isRequired,
};

ProtectedRoute.defaultProps = {
  isLoggedin: null,
};

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
    auth: store.auth,
  };
}

export default connect(mapStoreToProps, () => ({}))(withRouter(ProtectedRoute));
