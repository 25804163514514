import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter, Prompt } from 'react-router';
import moment from 'moment';
import Panel from '../../../Components/Panel';
import Textarea from '../../../Components/Textarea';
import Button from '../../../Components/Button';
import DatePickerInput from '../../../Components/DatePickerInput';
import ObjectInformationPanel from '../../../Components/ObjectComponents/ObjectInformationPanel';
import {
  NAVIGATION_OBJECT_DETAILS,
  NAVIGATION_OBJECT_SERVICE_DETAILS, NAVIGATION_SYSTEM_OBJECT_SERVICE_DETAILS,
  OBJECT_SERVICE_TYPE_RENTAL_KITCHEN,
  objectServiceTypeOptions,
  timeOptions,
} from '../../../Library/Types';
import LoadingModal from '../../../Components/LoadingModal';
import CustomReactSelect from '../../../Components/CustomReactSelect';
import { create } from '../../../Redux/Action/Order/Object/ServiceAction';
import ItemList from './Component/ItemList';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import CreateServiceValidator from '../../../Library/Validator/CreateServiceValidator';
import { useAccessCanScheduleServices } from '../../../Library/AccessChecks';
import { useDispatchUpdateObjectServiceItem } from '../../../Redux/Action/Order/Object/Service/ItemAction';

const initialState = {
  type: 1,
  description: '',
  items: [],
  assemblyTime: 0,
  hasChanges: false,
  startTime: '08:00',
};

function reducer(state, action) {
  switch (action.type) {
    case 'type':
      return { ...state, type: action.payload, hasChanges: true };
    case 'description':
      return { ...state, description: action.payload, hasChanges: true };
    case 'items':
      return { ...state, items: action.payload, hasChanges: true };
    case 'executionDate':
      return { ...state, executionDate: action.payload, hasChanges: true };
    case 'startTime':
      return { ...state, startTime: action.payload, hasChanges: true };
    default:
      return { ...state };
  }
}

/**
 * Create()
 * @param props
 * @returns {*|null}
 * @constructor
 */
function Create(props) {
  const {
    order, history, dispatchCreate, isSystemView,
  } = props;
  const isTerminable = useAccessCanScheduleServices();
  const { objectId } = useParams();
  const [object, setObject] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const dispatchUpdateObjectServiceItem = useDispatchUpdateObjectServiceItem();

  React.useEffect(() => {
    if (order) {
      if (order.objects.length > 1) {
        const obj = order.objects.filter((item) => item.objectId === parseInt(objectId, 0))[0];
        setObject(obj);
      } else {
        const obj = order.objects[0];
        setObject(obj);
      }
    }
  }, [objectId, order]);

  const handleSave = () => {
    setErrors({});
    const validator = new CreateServiceValidator();
    const isValid = validator.isValid(state);

    if (isValid) {
      setIsSaving(true);
      let assemblyTime = 0;
      state.items.forEach((item) => {
        if (item.assemblyTime && item.assemblyTime > 0) {
          assemblyTime += parseInt(item.assemblyTime, 0);
        }
      });

      if (state.type === OBJECT_SERVICE_TYPE_RENTAL_KITCHEN) {
        assemblyTime = 120;
      }

      const service = {
        ...state, objectId, orderId: order.orderId, assemblyTime,
      };

      dispatchCreate(service).then((response) => {
        let uri = (isSystemView) ? NAVIGATION_SYSTEM_OBJECT_SERVICE_DETAILS : NAVIGATION_OBJECT_SERVICE_DETAILS;
        uri = uri.replace('{orderId}', order.orderId)
          .replace('{objectId}', object.objectId)
          .replace('{serviceId}', response.serviceId);
        history.push(uri);
      });
    } else {
      setErrors(validator.getMessages());
    }
  };

  const handleOnAddItem = (item) => {
    setErrors({ ...errors, items: false });
    const newItems = [...state.items, item];
    dispatch({ type: 'items', payload: newItems });
  };

  const handleOnDeleteItem = (item) => {
    const newItems = state.items.filter((itemParam) => itemParam.internalId !== item.internalId);
    dispatch({ type: 'items', payload: newItems });
  };

  const handleOnCancel = () => {
    const uri = NAVIGATION_OBJECT_DETAILS.replace('{orderId}', order.orderId)
      .replace('{objectId}', object.objectId);
    history.push(uri);
  };

  const renderDispoInformation = () => {
    if (isTerminable) {
      return (
        <>
          <DatePickerInput
            onChange={(value) => dispatch({ type: 'executionDate', payload: value })}
            label="Datum der Ausführung"
            minDate={moment().toDate()}
            rowClass="col-lg-6"
            visible={order.internal}
            rightIcon="far fa-calendar-alt"
          />
          <CustomReactSelect
            onChange={(value) => dispatch({ type: 'startTime', payload: value })}
            value={state.startTime}
            options={timeOptions}
            label="Ankunftszeit Monteur beim Kunden"
            hasError={!!(errors.startTime)}
            rowClass="col-lg-6"
            visible={order.internal}
          />
        </>
      );
    }
    return null;
  };

  if (object && order) {
    return (
      <div>
        <LoadingModal visible={isSaving} text="Wird gespeichert..." />

        <Panel marginBottom={20}>
          <h4>Serviceauftrag erstellen</h4>
          <p style={{ margin: 0 }}>
            Erstellen Sie einen Serviceauftrag zum ausgewählten Auftrag. Je nach Typ des Serviceauftrags können
            verschiedenen Daten und Informationen erfasst werden. Der Typ bestimmt ebenfalls welche Art von Bericht
            für den Serviceauftrag erstellt werden muss.
          </p>
        </Panel>

        <ObjectInformationPanel object={object} order={order} />

        <Panel marginBottom={20}>
          <h6 style={{ marginBottom: 30 }}>
            Allgemeine Angaben zum Serviceauftrag
          </h6>

          <div className="row">
            <CustomReactSelect
              onChange={(value) => dispatch({ type: 'type', payload: value })}
              value={state.type}
              options={objectServiceTypeOptions}
              label="Typ des Serviceauftrags"
              rowClass="col-lg-12"
              required
              rightText="Hallo"
            />
            <Textarea
              onChange={(id) => dispatch({ type: 'description', payload: id })}
              value={state.description}
              rows={4}
              label="Zusätzliche Informationen oder Beschreibungen"
              rowClass="col-lg-12"
            />
            {renderDispoInformation()}
          </div>
        </Panel>

        <ItemList
          service={state}
          onDelete={handleOnDeleteItem}
          onUpdate={(item) => dispatchUpdateObjectServiceItem(item)}
          onAdd={handleOnAddItem}
          visible={(state.type !== OBJECT_SERVICE_TYPE_RENTAL_KITCHEN)}
          hasError={!!(errors.items)}
          errorMessage="Bitte fügen Sie mindestens ein Beanstandung hinzu!"
          isInternal={order.internal}
        />

        <Panel boxPadding={0}>
          <div className="clearfix">
            <div className="float-right">
              <div className="ButtonContainer">
                <Button onClick={handleOnCancel} type="btn-light">
                  Abbrechen
                </Button>
                <Button onClick={handleSave} disabled={!state.hasChanges}>
                  Serviceauftrag anlegen
                </Button>
              </div>
            </div>
          </div>
        </Panel>

        <ShowHideContainer visible={state.hasChanges && !isSaving}>
          <Prompt
            when
            message="Sie haben die aktuellen Änderungen nicht gespeichert! Wenn Sie die Seite jetzt
            verlassen gehen alle Eingaben verloren. Seite jetzt wirklich verlassen?"
          />
        </ShowHideContainer>
      </div>
    );
  }
  return null;
}

Create.propTypes = {
  dispatchCreate: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  isSystemView: PropTypes.bool,
};

Create.defaultProps = {
  order: null,
  isSystemView: false,
};

function mapStoreToProps(store) {
  return {
    order: store.order.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreate: (service) => dispatch(create(service)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Create));
