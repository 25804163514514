import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../Components/ModalDialog';
import { LOCALSTORAGE_DASHBOARD_HIDE_HINT_SHOWN } from '../../../Library/Types';

/**
 * BoxHideIcon()
 * @param props
 * @returns {null|*}
 * @constructor
 */
export default function BoxHideModal(props) {
  const { visible, onClose } = props;
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (visible) {
      const storageData = localStorage.getItem(LOCALSTORAGE_DASHBOARD_HIDE_HINT_SHOWN);
      if (!storageData) {
        setShow(true);
      } else {
        onClose();
      }
    }
  }, [visible, onClose]);

  const handleClose = () => {
    localStorage.setItem(LOCALSTORAGE_DASHBOARD_HIDE_HINT_SHOWN, 'true');
    setShow(false);
    onClose();
  };

  return (
    <ModalDialog onClose={handleClose} hideConfirmButton closeCaption="OK, verstanden" visible={show} centered>
      <h5>
        <i className="fas fa-eye-slash" style={{ marginRight: 8 }} />
        Startseitenbox ausgeblendet
      </h5>
      <p>
        Sie haben eine Box auf der Startseite ausgeblendet. Die Box wird Ihnen jetzt nicht mehr angezeigt. Damit können
        Sie Ihre Startseite individuell gestalten, so wie Sie die Informationen benötigen. Sie können die Boxen
        jederzeit wieder über das
        <i className="fas fa-eye" style={{ marginLeft: 8, marginRight: 8 }} />
        Symbol neben der Überschrift einblenden!
      </p>
    </ModalDialog>
  );
}

BoxHideModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

BoxHideModal.defaultProps = {
  visible: false,
};
