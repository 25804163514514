import axios from '../../../../Library/Axios';

const defaultUri = '/system/reclamation/{reclamationId}/report';

export const SYSTEM_RECLAMATIONREPORT_GET_START = 'SYSTEM_RECLAMATIONREPORT_GET_START';
export const SYSTEM_RECLAMATIONREPORT_GET_SUCCESS = 'SYSTEM_RECLAMATIONREPORT_GET_SUCCESS';
export const SYSTEM_RECLAMATIONREPORT_GET_ERROR = 'SYSTEM_RECLAMATIONREPORT_GET_ERROR';

/**
 * get()
 * @returns {function(*): Promise<any | never>}
 */
export function get(reclamationId) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_RECLAMATIONREPORT_GET_START });

    const uri = defaultUri.replace('{reclamationId}', reclamationId);

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_RECLAMATIONREPORT_GET_SUCCESS, payload: { data, reclamationId } });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_RECLAMATIONREPORT_GET_ERROR, payload: { reclamationId } });
      return Promise.reject(error);
    });
  };
}
