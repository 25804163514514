import axios from '../../Library/Axios';

const defaultUri = '/client/{clientId}/transaction';

export const CLIENTTRANSACTION_GETLIST_START = 'CLIENTTRANSACTION_GETLIST_START';
export const CLIENTTRANSACTION_GETLIST_SUCCESS = 'CLIENTTRANSACTION_GETLIST_SUCCESS';
export const CLIENTTRANSACTION_GETLIST_ERROR = 'CLIENTTRANSACTION_GETLIST_ERROR';

/**
 * get()
 * @returns {function(*, *): Promise<unknown>}
 */
export function getList() {
  return (dispatch, getState) => {
    const { clientId } = getState().client.client;

    dispatch({ type: CLIENTTRANSACTION_GETLIST_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: CLIENTTRANSACTION_GETLIST_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: CLIENTTRANSACTION_GETLIST_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const CLIENTTRANSACTION_GETPDF_START = 'CLIENTTRANSACTION_GETPDF_START';
export const CLIENTTRANSACTION_GETPDF_SUCCESS = 'CLIENTTRANSACTION_GETPDF_SUCCESS';
export const CLIENTTRANSACTION_GETPDF_ERROR = 'CLIENTTRANSACTION_GETPDF_ERROR';

/**
 * getPdf()
 * @param transactionId
 * @returns {function(*, *): Promise<unknown>}
 */
export function getPdf(transactionId) {
  return (dispatch, getState) => {
    const { clientId } = getState().client.client;

    dispatch({ type: CLIENTTRANSACTION_GETPDF_START });

    const uri = `${defaultUri.replace('{clientId}', clientId)}/${transactionId}`;
    const config = {
      params: { action: 'pdf' },
      responseType: 'arraybuffer',
    };

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/pdf' },
      );
      dispatch({ type: CLIENTTRANSACTION_GETPDF_SUCCESS });
      return Promise.resolve(blobFile);
    }).catch((error) => {
      dispatch({ type: CLIENTTRANSACTION_GETPDF_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}
