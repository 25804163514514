import React from 'react';
import * as Redux from 'react-redux';
import { useDispatch } from 'react-redux';
import axios from '../../Library/Axios';
import { get as getOrder } from './OrderAction';

export const objectDefaultUri = '/order/{orderId}/object';
export const objectNoteDefaultUri = '/order/{orderId}/object/{objectId}/note';
export const objectDocumentDefaultUri = '/order/{orderId}/object/{objectId}/document';
export const objectDateDefaultUri = '/order/{orderId}/object/{objectId}/date';
export const objectDateUserDefaultUri = '/order/{orderId}/object/{objectId}/date/{dateId}/user';

export const OBJECT_GET_START = 'OBJECT_GET_START';
export const OBJECT_GET_SUCCESS = 'OBJECT_GET_SUCCESS';
export const OBJECT_GET_ERROR = 'OBJECT_GET_ERROR';

/**
 * get()
 * @param objectId
 * @returns {function(*): Promise<any | never>}
 */
export function get(objectId) {
  return (dispatch) => {
    dispatch({ type: OBJECT_GET_START });

    // Use fix orderId here! Not nice but this function is used by message / notification clicks
    // We only have the objectId there in as referenceId so we have no orderId. The orderId ist not used in the API!
    const uri = `${objectDefaultUri.replace('{orderId}', '4711')}/${objectId}`;

    return axios().get(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_GET_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_GET_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_SPLIT_START = 'OBJECT_SPLIT_START';
export const OBJECT_SPLIT_SUCCESS = 'OBJECT_SPLIT_SUCCESS';
export const OBJECT_SPLIT_ERROR = 'OBJECT_SPLIT_ERROR';

/**
 * split()
 * @param object
 * @returns {function(*): *}
 */
export function split(object) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SPLIT_START });

    const uri = `${objectDefaultUri.replace('{orderId}', object.orderId)}/${object.objectId}`;
    const config = { params: { action: 'split' } };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SPLIT_SUCCESS, payload: data });
      dispatch(getOrder(object.orderId, true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SPLIT_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_UNSPLIT_START = 'OBJECT_UNSPLIT_START';
export const OBJECT_UNSPLIT_SUCCESS = 'OBJECT_UNSPLIT_SUCCESS';
export const OBJECT_UNSPLIT_ERROR = 'OBJECT_UNSPLIT_ERROR';

/**
 * unsplit()
 * @param object
 * @returns {function(*): *}
 */
export function unsplit(object) {
  return (dispatch) => {
    dispatch({ type: OBJECT_UNSPLIT_START });

    const uri = `${objectDefaultUri.replace('{orderId}', object.orderId)}/${object.objectId}`;
    const config = { params: { action: 'unsplit' } };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_UNSPLIT_SUCCESS, payload: data });
      dispatch(getOrder(object.orderId, true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_UNSPLIT_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_SENDCUSTOMERMAIL_START = 'OBJECT_SENDCUSTOMERMAIL_START';
export const OBJECT_SENDCUSTOMERMAIL_SUCCESS = 'OBJECT_SENDCUSTOMERMAIL_SUCCESS';
export const OBJECT_SENDCUSTOMERMAIL_ERROR = 'OBJECT_SENDCUSTOMERMAIL_ERROR';

/**
 * sendCustomerMail()
 * @param object
 * @returns {function(*): Promise<unknown>}
 */
export function sendCustomerMail(object) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SENDCUSTOMERMAIL_START, object });

    const uri = '/mailer';
    const config = {
      params: {
        action: 'sendOrderMail',
      },
    };
    const postData = {
      orderId: object.orderId,
      objectId: object.objectId,
    };

    return axios().post(uri, postData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SENDCUSTOMERMAIL_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SENDCUSTOMERMAIL_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_SINGLEUPDATE_START = 'OBJECT_SINGLEUPDATE_START';
export const OBJECT_SINGLEUPDATE_SUCCESS = 'OBJECT_SINGLEUPDATE_SUCCESS';
export const OBJECT_SINGLEUPDATE_ERROR = 'OBJECT_SINGLEUPDATE_ERROR';

/**
 * singleUpdate()
 * @param object
 * @param field
 * @param value
 * @returns {function(*): Promise<unknown>}
 */
export function singleUpdate(object, field, value) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SINGLEUPDATE_START, payload: { field, value } });

    const uri = `${objectDefaultUri.replace('{orderId}', object.orderId)}/${object.objectId}`;
    const config = {
      params: { action: 'singleUpdate' },
    };

    return axios().put(uri, { field, value }, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_SINGLEUPDATE_SUCCESS, payload: data });
      dispatch(getOrder(object.orderId, true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_SINGLEUPDATE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_UPDATEVALUES_START = 'OBJECT_UPDATEVALUES_START';
export const OBJECT_UPDATEVALUES_SUCCESS = 'OBJECT_UPDATEVALUES_SUCCESS';
export const OBJECT_UPDATEVALUES_ERROR = 'OBJECT_UPDATEVALUES_ERROR';

/**
 * Updates only the given values
 * updateWorkOptions()
 * @param object
 * @param valuesToUpdate
 * @returns {function(*): Promise<unknown>}
 */
export function updateValues(object, valuesToUpdate) {
  return (dispatch) => {
    dispatch({ type: OBJECT_UPDATEVALUES_START, payload: { object, valuesToUpdate } });

    const uri = `${objectDefaultUri.replace('{orderId}', object.orderId)}/${object.objectId}`;
    const config = {
      params: { action: 'updateValues' },
    };

    return axios().put(uri, valuesToUpdate, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_UPDATEVALUES_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_UPDATEVALUES_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_DELETE_START = 'OBJECT_DELETE_START';
export const OBJECT_DELETE_SUCCESS = 'OBJECT_DELETE_SUCCESS';
export const OBJECT_DELETE_ERROR = 'OBJECT_DELETE_ERROR';

/**
 * Delete an object from an object order. Function failed if order is not object order!
 * deleteObject()
 * @param orderId
 * @param objectId
 * @returns {function(*): *}
 */
export function deleteObject(orderId, objectId) {
  return (dispatch) => {
    dispatch({ type: OBJECT_DELETE_START, payload: { orderId, objectId } });

    const uri = `${objectDefaultUri.replace('{orderId}', orderId)}/${objectId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_DELETE_SUCCESS, payload: data });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_DELETE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_SETDOCUMENTSFINISHED_START = 'OBJECT_SETDOCUMENTSFINISHED_START';
export const OBJECT_SETDOCUMENTSFINISHED_SUCCESS = 'OBJECT_SETDOCUMENTSFINISHED_SUCCESS';
export const OBJECT_SETDOCUMENTSFINISHED_ERROR = 'OBJECT_SETDOCUMENTSFINISHED_ERROR';

/**
 * setDocumentsFinished()
 * @param object
 * @returns {function(*): Promise<unknown>}
 */
export function setDocumentsFinished(object) {
  return (dispatch) => {
    dispatch({ type: OBJECT_SETDOCUMENTSFINISHED_START, payload: { object } });

    const uri = `${objectDefaultUri.replace('{orderId}', object.orderId)}/${object.objectId}`;
    const config = {
      params: { action: 'documentsFinished' },
    };

    return axios().put(uri, {}, config).then((response) => {
      const { data } = response.data;
      return dispatch(getOrder(object.orderId, true)).then(() => {
        dispatch({ type: OBJECT_SETDOCUMENTSFINISHED_SUCCESS, payload: data });
        return Promise.resolve(data);
      });
    }).catch((error) => {
      dispatch({ type: OBJECT_SETDOCUMENTSFINISHED_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_UPLOADDELIVERYNOTE_START = 'OBJECT_UPLOADDELIVERYNOTE_START';
export const OBJECT_UPLOADDELIVERYNOTE_SUCCESS = 'OBJECT_UPLOADDELIVERYNOTE_SUCCESS';
export const OBJECT_UPLOADDELIVERYNOTE_ERROR = 'OBJECT_UPLOADDELIVERYNOTE_ERROR';

/**
 * uploadDeliveryNote()
 * @param order
 * @param object
 * @param file
 * @returns {function(*): Promise<unknown>}
 */
export function uploadDeliveryNote(order, object, file) {
  return (dispatch) => {
    dispatch({ type: OBJECT_UPLOADDELIVERYNOTE_START });

    const uri = `/order/${object.orderId}/file`;

    const formData = new FormData();
    if (order.type === 'SINGLE') {
      formData.append('file[deliveryNote]', file);
    } else {
      formData.append(`file[${object.objectId}][deliveryNote]`, file);
    }

    return axios().post(uri, formData).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_UPLOADDELIVERYNOTE_SUCCESS, payload: data });
      dispatch(getOrder(order.orderId, true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_UPLOADDELIVERYNOTE_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_GETSEPAMANDATE_START = 'OBJECT_GETSEPAMANDATE_START';
export const OBJECT_GETSEPAMANDATE_SUCCESS = 'OBJECT_GETSEPAMANDATE_SUCCESS';
export const OBJECT_GETSEPAMANDATE_ERROR = 'OBJECT_GETSEPAMANDATE_ERROR';

/**
 * getSepaMandate()
 * @param object
 * @returns {function(*): Promise<unknown>}
 */
export function getSepaMandate(object) {
  return (dispatch) => {
    dispatch({ type: OBJECT_GETSEPAMANDATE_START });

    const uri = `/order/${object.orderId}/object/${object.objectId}`;

    const config = {
      params: { action: 'sepamandate' },
      responseType: 'arraybuffer',
    };

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/pdf' },
      );

      dispatch({ type: OBJECT_GETSEPAMANDATE_SUCCESS });
      return Promise.resolve(blobFile);
    }).catch(() => {
      dispatch({ type: OBJECT_GETSEPAMANDATE_ERROR });
      return Promise.reject();
    });
  };
}

export const OBJECT_GETALLDOCUMENTS_START = 'OBJECT_GETALLDOCUMENTS_START';
export const OBJECT_GETALLDOCUMENTS_SUCCESS = 'OBJECT_GETALLDOCUMENTS_SUCCESS';
export const OBJECT_GETALLDOCUMENTS_ERROR = 'OBJECT_GETALLDOCUMENTS_ERROR';

/**
 * getAllDocuments()
 * @param object
 * @returns {function(*): Promise<unknown>}
 */
export function getAllDocuments(object) {
  return (dispatch) => {
    dispatch({ type: OBJECT_GETALLDOCUMENTS_START });

    const uri = `/order/${object.orderId}/object/${object.objectId}`;

    const config = {
      params: { action: 'allDocuments' },
      responseType: 'arraybuffer',
    };

    return axios().get(uri, config).then((response) => {
      const { data } = response;
      const blobFile = new Blob(
        [data],
        { type: 'application/pdf' },
      );

      dispatch({ type: OBJECT_GETALLDOCUMENTS_SUCCESS });
      return Promise.resolve(blobFile);
    }).catch(() => {
      dispatch({ type: OBJECT_GETALLDOCUMENTS_ERROR });
      return Promise.reject();
    });
  };
}

export const OBJECT_DELETEDOCUMENT_START = 'OBJECT_DELETEDOCUMENT_START';
export const OBJECT_DELETEDOCUMENT_SUCCESS = 'OBJECT_DELETEDOCUMENT_SUCCESS';
export const OBJECT_DELETEDOCUMENT_ERROR = 'OBJECT_DELETEDOCUMENT_ERROR';

/**
 * updateCustomer()
 * @param object
 * @param document
 * @returns {function(*): Promise<unknown>}
 */
export function deleteDocument(object, document) {
  return (dispatch) => {
    dispatch({ type: OBJECT_DELETEDOCUMENT_START, payload: { object, document } });

    const preparedDefaultUri = objectDocumentDefaultUri
      .replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);
    const uri = `${preparedDefaultUri}/${document.documentId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_DELETEDOCUMENT_SUCCESS, payload: data });
      dispatch(getOrder(object.orderId, true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_DELETEDOCUMENT_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_ADDDOCUMENT_START = 'OBJECT_ADDDOCUMENT_START';
export const OBJECT_ADDDOCUMENT_SUCCESS = 'OBJECT_ADDDOCUMENT_SUCCESS';
export const OBJECT_ADDDOCUMENT_ERROR = 'OBJECT_ADDDOCUMENT_ERROR';

/**
 * addDocument()
 * @param object
 * @param file
 * @param type
 * @returns {function(*): Promise<unknown>}
 */
export function addDocument(object, file, type) {
  return (dispatch) => {
    dispatch({ type: OBJECT_ADDDOCUMENT_START, payload: { object, file, type } });

    const config = {
      params: { type },
    };

    const uri = objectDocumentDefaultUri
      .replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);

    const formData = new FormData();
    formData.append('file', file);

    return axios().post(uri, formData, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_ADDDOCUMENT_SUCCESS, payload: data });
      dispatch(getOrder(object.orderId, true));
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_ADDDOCUMENT_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_CREATE_NOTE_START = 'OBJECT_CREATE_NOTE_START';
export const OBJECT_CREATE_NOTE_SUCCESS = 'OBJECT_CREATE_NOTE_SUCCESS';
export const OBJECT_CREATE_NOTE_ERROR = 'OBJECT_CREATE_NOTE_ERROR';

/**
 * addNote()
 * @param object
 * @param note
 * @returns {function(*): Promise<unknown>}
 */
export function createNote(object, note) {
  return (dispatch) => {
    dispatch({ type: OBJECT_CREATE_NOTE_START, payload: { object, note } });

    const uri = objectNoteDefaultUri
      .replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);

    return axios().post(uri, { note }).then((response) => {
      const { data } = response.data;

      return dispatch(getOrder(object.orderId, true)).then(() => {
        dispatch({ type: OBJECT_CREATE_NOTE_SUCCESS, payload: data });
        return Promise.resolve(data);
      });
    }).catch((error) => {
      dispatch({ type: OBJECT_CREATE_NOTE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_DELETE_NOTE_START = 'OBJECT_DELETE_NOTE_START';
export const OBJECT_DELETE_NOTE_SUCCESS = 'OBJECT_DELETE_NOTE_SUCCESS';
export const OBJECT_DELETE_NOTE_ERROR = 'OBJECT_DELETE_NOTE_ERROR';

/**
 * deleteNote()
 * @param object
 * @param note
 * @returns {function(*): Promise<unknown>}
 */
export function deleteNote(object, note) {
  return (dispatch) => {
    dispatch({ type: OBJECT_DELETE_NOTE_START, payload: { object, note } });

    const uri = objectNoteDefaultUri
      .replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);

    return axios().delete(`${uri}/${note.noteId}`).then((response) => {
      const { data } = response.data;

      return dispatch(getOrder(object.orderId, true)).then(() => {
        dispatch({ type: OBJECT_DELETE_NOTE_SUCCESS, payload: data });
        return Promise.resolve(data);
      });
    }).catch((error) => {
      dispatch({ type: OBJECT_DELETE_NOTE_ERROR, payload: error });
      return Promise.reject(error);
    });
  };
}

export const OBJECT_DOCUMENT_ATTACH_TO_MAIL_START = 'OBJECT_DOCUMENT_ATTACH_TO_MAIL_START';
export const OBJECT_DOCUMENT_ATTACH_TO_MAIL_SUCCESS = 'OBJECT_DOCUMENT_ATTACH_TO_MAIL_SUCCESS';
export const OBJECT_DOCUMENT_ATTACH_TO_MAIL_ERROR = 'OBJECT_DOCUMENT_ATTACH_TO_MAIL_ERROR';

/**
 * useDispatchToggleAttachToMail()
 * @returns {function(*, *): *}
 */
export function useDispatchToggleAttachToMail() {
  const dispatch = Redux.useDispatch();

  return React.useCallback((object, document) => {
    dispatch({ type: OBJECT_DOCUMENT_ATTACH_TO_MAIL_START, payload: { object, document } });

    const uri = objectDocumentDefaultUri
      .replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);

    const changedDoc = { ...document, sendToCustomer: (document.sendToCustomer !== true) };

    return axios().put(`${uri}/${document.documentId}`, changedDoc).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_DOCUMENT_ATTACH_TO_MAIL_SUCCESS, payload: data });
      return dispatch(getOrder(object.orderId, true)).then(() => Promise.resolve(data));
    }).catch((error) => {
      dispatch({ type: OBJECT_DOCUMENT_ATTACH_TO_MAIL_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const OBJECT_DATE_UPDATE_START = 'OBJECT_DATE_UPDATE_START';
export const OBJECT_DATE_UPDATE_SUCCESS = 'OBJECT_DATE_UPDATE_SUCCESS';
export const OBJECT_DATE_UPDATE_ERROR = 'OBJECT_DATE_UPDATE_ERROR';

/**
 * useDispatchUpdateDate()
 * Update a given date for the object
 * @returns {function(object, date, boolean = false): Promise}
 */
export function useDispatchUpdateDate() {
  const dispatch = useDispatch();

  return React.useCallback((object, date, disableDispositionChecks = false) => {
    dispatch({ type: OBJECT_DATE_UPDATE_START, payload: { object, date } });

    const config = { params: { disableDispositionChecks: (!disableDispositionChecks) ? 0 : 1 } };

    const preparedUri = objectDateDefaultUri
      .replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId);
    const uri = `${preparedUri}/${date.dateId}`;

    return axios().put(uri, date, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_DATE_UPDATE_SUCCESS, payload: data });
      dispatch(getOrder(object.orderId, true));

      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_DATE_UPDATE_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const OBJECT_DATE_ADD_USER_START = 'OBJECT_DATE_ADD_USER_START';
export const OBJECT_DATE_ADD_USER_SUCCESS = 'OBJECT_DATE_ADD_USER_SUCCESS';
export const OBJECT_DATE_ADD_USER_ERROR = 'OBJECT_DATE_ADD_USER_ERROR';

/**
 * useDispatchDateAddUser()
 * Add user / monteur to given date of object
 * @returns {function(object, date, userId, boolean = false): Promise}
 */
export function useDispatchDateAddUser() {
  const dispatch = useDispatch();

  return React.useCallback((object, date, user, disableDispositionChecks = false) => {
    dispatch({ type: OBJECT_DATE_ADD_USER_START, payload: { object, date } });

    const config = { params: { disableDispositionChecks: (!disableDispositionChecks) ? 0 : 1 } };

    const uri = objectDateUserDefaultUri
      .replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId)
      .replace('{dateId}', date.dateId);

    const userEntity = { dateId: date.dateId, userId: user.userId };

    return axios().post(uri, userEntity, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_DATE_ADD_USER_SUCCESS, payload: data });
      dispatch(getOrder(object.orderId, true));

      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_DATE_ADD_USER_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const OBJECT_DATE_DELETE_USER_START = 'OBJECT_DATE_DELETE_USER_START';
export const OBJECT_DATE_DELETE_USER_SUCCESS = 'OBJECT_DATE_DELETE_USER_SUCCESS';
export const OBJECT_DATE_DELETE_USER_ERROR = 'OBJECT_DATE_DELETE_USER_ERROR';

/**
 * useDispatchDateDeleteUser()
 * Delete user / monteur to given date of object
 * @returns {function(object, date, userId): Promise}
 */
export function useDispatchDateDeleteUser() {
  const dispatch = useDispatch();

  return React.useCallback((object, date, userId) => {
    dispatch({ type: OBJECT_DATE_DELETE_USER_START, payload: { object, date } });

    const preparedUri = objectDateUserDefaultUri
      .replace('{orderId}', object.orderId)
      .replace('{objectId}', object.objectId)
      .replace('{dateId}', date.dateId);
    const uri = `${preparedUri}/${userId}`;

    return axios().delete(uri).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_DATE_DELETE_USER_SUCCESS, payload: data });
      dispatch(getOrder(object.orderId, true));

      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: OBJECT_DATE_DELETE_USER_ERROR, payload: error });
      return Promise.reject(error);
    });
  }, [dispatch]);
}

export const OBJECT_DEBIT_PAID_SERVICES_COUNT_START = 'OBJECT_DEBIT_PAID_SERVICES_COUNT_START';
export const OBJECT_DEBIT_PAID_SERVICES_COUNT_SUCCESS = 'OBJECT_DEBIT_PAID_SERVICES_COUNT_SUCCESS';
export const OBJECT_DEBIT_PAID_SERVICES_COUNT_ERROR = 'OBJECT_DEBIT_PAID_SERVICES_COUNT_ERROR';

/**
 * returns how many debit paid services an object has
 * @returns {function(*): *}
 */
export function useDispatchDebitPaidObjectServicesCount() {
  const dispatch = useDispatch();
  return React.useCallback((object) => {
    const uri = `${objectDefaultUri.replace('{orderId}', object.orderId)}/${object.objectId}`;
    const config = {
      params: {
        action: 'debitPaidServicesCount',
      },
    };

    dispatch({ type: OBJECT_DEBIT_PAID_SERVICES_COUNT_START, payload: { uri, config } });

    return axios().get(uri, config).then((response) => {
      const { data } = response.data;
      dispatch({ type: OBJECT_DEBIT_PAID_SERVICES_COUNT_SUCCESS, payload: { data } });
      return Promise.resolve(data.result);
    }).catch((error) => {
      dispatch({ type: OBJECT_DEBIT_PAID_SERVICES_COUNT_ERROR, payload: error.response });
      return Promise.reject(error);
    });
  }, [dispatch]);
}
