import {
  SYSTEM_MATERIAL_GETLIST_SUCCESS, SYSTEM_MATERIAL_GETLIST_ERROR, SYSTEM_MATERIAL_GETLIST_START,
  SYSTEM_MATERIAL_DELETE_SUCCESS,
} from '../Action/MaterialAction';

const defaultValues = {
  materials: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    case SYSTEM_MATERIAL_GETLIST_START: {
      return { ...state };
    }
    case SYSTEM_MATERIAL_DELETE_SUCCESS:
    case SYSTEM_MATERIAL_GETLIST_SUCCESS: {
      return { ...state, materials: action.payload };
    }
    case SYSTEM_MATERIAL_GETLIST_ERROR: {
      return { ...state, materials: null };
    }

    default: return { ...state };
  }
}
