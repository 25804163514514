import React from 'react';
import PropTypes from 'prop-types/prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Panel from '../../Panel';
import KeyValueEditRow from '../../KeyValueEditRow';
import { useDispatchUpdateDate } from '../../../Redux/Action/ObjectAction';
import ObjectDateStatusBadge from '../ObjectDateStatusBadge';

/**
 * AssignStorageContainer()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function AssignStorageContainer(props) {
  const { object } = props;
  const { client, partners } = useSelector((state) => state.client);
  const { order } = useSelector((state) => state.order);
  const dispatchUpdateDate = useDispatchUpdateDate();

  const [partner, setPartner] = React.useState(null);

  React.useEffect(() => {
    if (object.storageDate) {
      if (partners && object) {
        const filter = partners.filter((item) => item.partner.clientId === object.storageDate.clientId)[0];
        if (filter) {
          setPartner(filter.partner);
        }
      }
    }
  }, [object, partners]);

  const getAddressString = () => {
    const { loadingAddress } = order;
    return `${loadingAddress.street} ${loadingAddress.streetNo}, ${loadingAddress.zip} ${loadingAddress.city}`;
  };

  const getContactString = () => {
    const { loadingAddress } = order;
    return `${loadingAddress.contactName} (${loadingAddress.contactPhone})`;
  };

  if (order && order.internal && order.optionStorage && order.clientId === client.clientId && partner) {
    return (
      <Panel marginBottom={20} containerStyle={{ display: 'flex' }}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            <h6 className="Headline">
              <i className="fas fa-cubes" style={{ marginRight: 10 }} />
              Externe Lagerung
            </h6>
            <p>
              Hier können Sie das Datum der Anlieferung für die externe Lagerung Ihrer Ware verwalten
            </p>
          </div>
          <div>
            <ObjectDateStatusBadge objectDateState={object.storageDate.state} />
          </div>
        </div>

        <KeyValueEditRow
          title="Gewählter Partner für die Lagerung"
          displayText={partner.name}
        />

        <KeyValueEditRow
          title="Lageradresse"
          displayText={getAddressString()}
        />

        <KeyValueEditRow
          title="Ansprechpartner für das Lager"
          displayText={getContactString()}
        />

        <KeyValueEditRow
          title="Voraussichtliches Lieferdatum an das Lager"
          type="date"
          initialValue={object.storageDate.date}
          displayText={(object.storageDate.date) ? moment(object.storageDate.date).format('dddd DD.MM.YYYY') : ''}
          onChange={(date) => dispatchUpdateDate(object, { ...object.storageDate, date })}
          onClear={() => dispatchUpdateDate(object, { ...object.storageDate, date: null })}
          datePickerMinDate={moment().format('YYYY-MM-DD')}
        />
      </Panel>
    );
  }
  return null;
}

AssignStorageContainer.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
};
