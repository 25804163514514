import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useGetWorkTimeString } from '../../Library/HelperHooks';
import { formatDate, getOrderIcon } from '../../Library/Functions';
import { objectServiceTypeCaptions, ORDERTYPE_OBJECT } from '../../Library/Types';
import CombinedOrderBadge from '../OrderComponents/CombinedOrderBadge';
import ReclamationStatusBadge from '../ReclamationComponents/ReclamationStatusBadge';
import ServiceStatusBadge from '../ServiceComponents/ServiceStatusBadge';
import OptionIcons from '../ObjectComponents/OptionIcons';

/**
 * OrderRow()
 * @param props
 * @returns {*}
 * @constructor
 */
function OrderServiceRow(props) {
  const {
    data, onClick, client,
  } = props;
  const getWorkTimeString = useGetWorkTimeString();
  const getAddress = () => {
    if (data.zip && data.city) {
      return `${data.zip} ${data.city}`;
    }
    return '';
  };

  const renderExternalId = () => {
    if (data.type === ORDERTYPE_OBJECT) {
      if (data.orderExternalId) {
        return `${data.orderExternalId} (${data.objectExternalId})`;
      }
      return `${data.orderId} (${data.objectExternalId})`;
    }
    return data.objectExternalId;
  };

  const renderServiceBadge = (order) => {
    if (order.reclamationId) {
      return (
        <ReclamationStatusBadge
          reclamation={order}
          alignContainer="flex-end"
          useMediaQuery
        />
      );
    }
    return (
      <ServiceStatusBadge serviceState={order.state} alignContainer="flex-end" useMediaQuery />
    );
  };

  if (!data.serviceId && !data.reclamationId) {
    return (
      <>
        <tr onClick={() => onClick(data)}>
          <td>
            {getOrderIcon(data, client)}
          </td>
          <td>
            {renderExternalId()}
          </td>
          <td>
            <OptionIcons order={data} />
          </td>
          <td>
            {(data.date) ? formatDate(data.date) : ''}
          </td>
          <td>
            {getWorkTimeString(data.internal, data.executionTime)}
          </td>
          <td>
            {getAddress()}
          </td>
          <td>
            <CombinedOrderBadge
              orderState={data.orderState}
              objectDateState={data.state}
              isInternal={data.internal}
              useMediaQuery
              alignContainer="flex-end"
            />
          </td>
        </tr>
      </>
    );
  }
  return (
    <>
      <tr onClick={() => onClick(data)}>
        <td>
          <i className="fas fa-sync" />
        </td>
        <td>
          {data.objectExternalId}
        </td>
        <td>
          {(data.serviceType) ? objectServiceTypeCaptions[data.serviceType] : 'Reklamation'}
        </td>
        <td>
          {(data.executionDate) ? formatDate(data.executionDate) : ''}
        </td>
        <td>
          {getWorkTimeString(data.internal, data.assemblyTime)}
        </td>
        <td>
          {getAddress()}
        </td>
        <td>
          {renderServiceBadge(data)}
        </td>
      </tr>
    </>
  );
}

OrderServiceRow.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

function OrderServiceTable(props) {
  const {
    ordersService, onClick, client,
  } = props;

  const getKey = (order) => {
    if (order.dateId) {
      return `date ${order.dateId}`;
    }
    if (order.reclamationId) {
      return `reclamation ${order.reclamationId}`;
    }
    return `service ${order.serviceId}`;
  };

  if (ordersService && ordersService.length > 0) {
    return (
      <div className="OrderListView">
        <table className="table table-linked table-hover">
          <thead>
            <tr>
              <th>Typ</th>
              <th>Kommission</th>
              <th>Optionen</th>
              <th>Montagedatum</th>
              <th>Montagedauer</th>
              <th>Adresse</th>
              <th style={{ textAlign: 'end', paddingRight: 20 }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {ordersService.map((order) => (
              <OrderServiceRow
                data={order}
                key={getKey(order)}
                onClick={onClick}
                client={client}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  return null;
}

OrderServiceTable.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  ordersService: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(OrderServiceTable);
