import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import Button from '../Button';

import './style.css';

export default function Alert(props) {
  const {
    onClose, visible, type, children,
  } = props;

  const renderIcon = () => {
    switch (type) {
      case 'success': return <i className="far fa-check-circle text-success" />;
      case 'info': return <i className="fas fa-info-circle text-primary" />;
      case 'exclamation': return <i className="fas fa-exclamation text-warning" />;
      case 'danger': return <i className="fas fa-exclamation-circle text-danger" />;
      default: return <i className="far fa-check-circle text-success" />;
    }
  };

  return (
    <ModalDialog onClose={onClose} width={500} visible={visible} hideButtons centered>
      <div className="Alert">
        <div className="Icon">
          {renderIcon()}
        </div>
        <div className="Caption">
          {children}
        </div>
        <div className="Button">
          <Button onClick={onClose} type="btn-light">
            Schließen
          </Button>
        </div>
      </div>
    </ModalDialog>
  );
}

Alert.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['success', 'info', 'exclamation', 'danger']).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.instanceOf(Object)]).isRequired,
};
