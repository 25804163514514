import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Panel from '../Panel';
import { getList, remove } from '../../Redux/Action/TeamsAction';
import ModalDialog from '../ModalDialog';

/**
 * TeamsTable()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function TeamsTable(props) {
  const { teams, onUpdate } = props;

  if (teams) {
    return (
      <Panel marginBottom={25}>
        <table className="table table-hover linked table-options" style={{ marginTop: 0 }}>
          <thead>
            <tr>
              <th style={{ width: 100 }}>ID</th>
              <th>Name</th>
              <th>Mitglieder</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {teams.map((item) => (
              <CRow
                key={item.teamId}
                item={item}
                onUpdate={onUpdate}
              />
            ))}
          </tbody>
        </table>
      </Panel>
    );
  }
  return null;
}

TeamsTable.propTypes = {
  teams: PropTypes.instanceOf(Array),
  onUpdate: PropTypes.func.isRequired,
};

TeamsTable.defaultProps = {
  teams: null,
};

/**
 * Row()
 * @param props
 * @returns {*}
 * @constructor
 */
function Row(props) {
  const {
    item, onUpdate, deleteTeam, getAll,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const { settings } = useSelector((state) => state.client.client);
  const history = useHistory();

  const handleOnDelete = () => {
    deleteTeam(item.teamId).then(() => {
      setShowModal(false);
      getAll();
    }).catch((error) => {
      setShowModal(false);
      if (error && error.data && error.data.errorCode) {
        if (error.data.errorCode === 'TEAM_DELETE_ERROR_OPEN_ASSIGNMENTS') {
          setErrorModal(true);
        }
      }
    });
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const renderDeleteIcon = () => (
    <td className="options">
      <i
        className="fas fa-pencil-alt primary"
        onClick={() => onUpdate(item)}
        tabIndex={-1}
        onKeyPress={() => {}}
        role="button"
      />
      <i
        className="fas fa-trash danger"
        onClick={handleDeleteClick}
        tabIndex={-1}
        onKeyPress={() => {}}
        role="button"
      />
    </td>
  );

  const getNames = () => (
    item.users.map((data) => `${data.firstname} ${data.lastname}`).join(', ')
  );

  const handleNavigateDispo = () => {
    history.push('/disposition');
  };

  return (
    <tr>
      <td>{item.teamId}</td>
      <td>{item.name}</td>
      <td>{getNames()}</td>
      {renderDeleteIcon()}

      <ModalDialog
        onClose={() => setShowModal(false)}
        onConfirm={() => handleOnDelete()}
        visible={showModal}
        confirmCaption="Löschen"
        centered
      >
        <h5>Montageteam löschen?</h5>
        Bitte bestätigen Sie das Sie das Montageteam endgültig löschen möchten!
      </ModalDialog>

      <ModalDialog
        onClose={() => setErrorModal(false)}
        visible={errorModal}
        confirmCaption="Disposition öffnen"
        confirmClass="btn-success"
        onConfirm={handleNavigateDispo}
        hideConfirmButton={(!settings.allowUseDisposition)}
        centered
      >
        <h5>Das Teams kann nicht gelöscht werden!</h5>
        Solange dem Team noch offene Aufträge zugewiesen sind, kann das Team nicht gelöscht werden.
      </ModalDialog>
    </tr>
  );
}

function mapStoreToProps(store) {
  return {
    isLoading: store.teams.isLoggedin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAll: () => dispatch(getList()),
    deleteTeam: (teamId) => dispatch(remove(teamId)),
  };
}

const CRow = connect(mapStoreToProps, mapDispatchToProps)(Row);

Row.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  onUpdate: PropTypes.func.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  getAll: PropTypes.func.isRequired,
};
