import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { formatDate, hasServiceAccess } from '../../../Library/Functions';
import { objectServiceTypeCaptions } from '../../../Library/Types';
import { useAccessCanCreateServices } from '../../../Library/AccessChecks';
import ServiceStatusIcon from '../../ServiceComponents/ServiceStatusIcon';

/**
 * ServiceMenu()
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ServiceMenu(props) {
  const {
    services, object, order, client, isSystemView,
  } = props;
  const isAllowedToCreate = useAccessCanCreateServices();
  const { url } = useRouteMatch();

  const renderAddService = () => {
    if (isSystemView
      || (isAllowedToCreate
    && (order.internal || (!order.internal && object && object.montageDate && object.montageDate.clientId !== null))
    && (client.clientId === order.clientId || client.clientId === order.mandantId))
    ) {
      return (
        <>
          <hr />
          <li>
            <NavLink className="nav-link" to={`${url}/service/create`}>
              <i className="fas fa-plus" />
              Serviceauftrag anlegen
            </NavLink>
          </li>
        </>
      );
    }
    return null;
  };

  const renderServices = () => {
    let returnResult = [];

    if (services) {
      returnResult = services.map((service) => {
        if (!hasServiceAccess(client, order, service) && !isSystemView) {
          return null;
        }
        return (
          <li key={service.serviceId}>
            <NavLink className="nav-link" to={`${url}/service/${service.serviceId}`}>
              <div className="d-flex">
                <div style={{ paddingTop: 2 }}>
                  <ServiceStatusIcon serviceState={service.state} />
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <div className="ServiceTitle">
                    {objectServiceTypeCaptions[service.type]}
                  </div>
                  <div className="Date">
                    {
                      (service.executionDate)
                        ? formatDate(service.executionDate)
                        : <i className="text-muted">Kein Ausführungsdatum</i>
                    }
                  </div>
                </div>
              </div>
            </NavLink>
          </li>
        );
      });
    }

    if (returnResult.length > 0) {
      return (
        <div className="ServiceItems">
          <hr />
          <h6 className="Headline">
            Serviceaufträge
          </h6>
          {returnResult}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {renderAddService()}
      {renderServices()}
    </>
  );
}

ServiceMenu.propTypes = {
  services: PropTypes.instanceOf(Array),
  object: PropTypes.instanceOf(Object),
  order: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object).isRequired,
  isSystemView: PropTypes.bool,
};

ServiceMenu.defaultProps = {
  services: null,
  object: null,
  order: null,
  isSystemView: false,
};
