import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Panel from '../../Panel';
import { getList } from '../../../Pages/System/Redux/Action/ClientAction';

const ClientName = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

/**
 * ClientInformation
 * @param props
 * @returns {*}
 * @constructor
 */
function ClientInformation(props) {
  const {
    order, dispatchGetClients, clients, onClientClick,
  } = props;
  const [client, setClient] = React.useState(null);

  React.useEffect(() => {
    if (!clients) {
      dispatchGetClients();
    }
  }, [clients, dispatchGetClients]);

  React.useEffect(() => {
    if (clients) {
      const filtered = clients.filter((item) => item.clientId === order.clientId)[0];
      setClient(filtered);
    }
  }, [clients, order.clientId]);

  const renderPhone = () => {
    if (client.phone && client.phone.length > 0) {
      return <div>{`Telefon: ${client.phone}`}</div>;
    }
    return null;
  };

  const renderMobile = () => {
    if (client.mobile && client.mobile.length > 0) {
      return <div>{`Mobil: ${client.mobile}`}</div>;
    }
    return null;
  };

  if (client) {
    return (
      <Panel marginBottom={20} className="CustomerAddress">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6>Kundendaten</h6>
          </div>
        </div>

        <address style={{ marginBottom: 0 }}>
          <ClientName onClick={() => onClientClick(client)}>
            <strong>
              {client.name}
            </strong>
          </ClientName>
          <div>{`${client.street} ${client.streetNo}`}</div>
          <div>{`${client.zip} ${client.city}`}</div>
          {renderPhone()}
          {renderMobile()}
          <div>{client.mail}</div>
        </address>
      </Panel>
    );
  }
  return (
    <Panel marginBottom={20} className="CustomerAddress">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h6>Kundendaten</h6>
        </div>
      </div>
    </Panel>
  );
}

ClientInformation.propTypes = {
  dispatchGetClients: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  clients: PropTypes.instanceOf(Array),
  onClientClick: PropTypes.func.isRequired,
};

ClientInformation.defaultProps = {
  clients: null,
};

function mapStoreToProps(store) {
  return {
    clients: store.systemClient.clients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetClients: () => dispatch(getList()),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(ClientInformation);
