import * as Redux from 'react-redux';
import React from 'react';
import axios from '../../../../Library/Axios';

const defaultUri = '/system/order/{orderId}/object/{objectId}';

function prepareUri(orderId, objectId) {
  return defaultUri.replace('{orderId}', orderId).replace('{objectId}', objectId);
}

export const SYSTEM_OBJECT_UPDATE_ASSIGNED_CLIENT_START = 'SYSTEM_OBJECT_UPDATE_ASSIGNED_CLIENT_START';
export const SYSTEM_OBJECT_UPDATE_ASSIGNED_CLIENT_SUCCESS = 'SYSTEM_OBJECT_UPDATE_ASSIGNED_CLIENT_SUCCESS';
export const SYSTEM_OBJECT_UPDATE_ASSIGNED_CLIENT_ERROR = 'SYSTEM_OBJECT_UPDATE_ASSIGNED_CLIENT_ERROR';

/**
 * useDispatchUpdatedAssignedClient
 * @param orderId
 * @param objectId
 * @returns {function(*=): *}
 */
export const useDispatchUpdatedAssignedClient = (orderId, objectId) => {
  const dispatch = Redux.useDispatch();
  const uri = prepareUri(orderId, objectId);

  return React.useCallback((clientId) => {
    dispatch({ type: SYSTEM_OBJECT_UPDATE_ASSIGNED_CLIENT_START, payload: { uri, clientId } });
    return axios().put(uri, clientId).then((response) => {
      const { data } = response.data;
      dispatch({ type: SYSTEM_OBJECT_UPDATE_ASSIGNED_CLIENT_SUCCESS, payload: { data } });
      return Promise.resolve(data);
    }).catch((error) => {
      dispatch({ type: SYSTEM_OBJECT_UPDATE_ASSIGNED_CLIENT_ERROR, payload: { uri, clientId, error } });
      return Promise.reject(error);
    });
  }, [uri, dispatch]);
};
