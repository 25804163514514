/* eslint-disable react/prop-types, no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import Button from '../Components/Button';
import Input from '../Components/Input';
import Panel from '../Components/Panel';
import { login } from '../Redux/Action/AuthAction';
import LoadingModal from '../Components/LoadingModal';
import StatusBadge from '../Components/StatusBadge';
import Footer from './Frontend/Components/Footer';
import MobileDeactivatedHint from '../Components/MobileDeactivatedHint';
import Config from '../Assets/Config';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Login()
 * @param props
 * @returns {*}
 * @constructor
 */
function Login(props) {
  // eslint-disable-next-line no-shadow
  const {
    dispatchLogin, isLoggedin, isLoading, statusCode, errorCode,
  } = props;
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState({});
  const [cookies, setCookie] = useCookies(['hk']);
  const [disabled, setDisabled] = useState(true);
  const query = useQuery();
  const location = useLocation();
  const directToUrl = (location.state) ? location.state : '/dashboard';

  useEffect(() => {
    if (errorCode) {
      setErrors({});
      switch (errorCode) {
        case 'AUTH_USER_INVALID': {
          setErrors({ username: true });
          break;
        }
        case 'AUTH_PASSWORD_INVALID': {
          setErrors({ password: true });
          break;
        }
        default: {
          setErrors({ username: true });
          break;
        }
      }
    }
  }, [errorCode]);

  useEffect(() => {
    setDisabled(!(userName && userName.length > 3 && password && password.length > 0));
    setErrors({});
  }, [userName, password]);

  const renderError500 = () => {
    if (statusCode && statusCode === 500) {
      return (
        <div className="d-flex justify-content-center">
          <StatusBadge caption="Anmeldung fehlgeschlagen!" type="Danger" icon="fas fa-exclamation-triangle" />
        </div>
      );
    }
    return null;
  };

  const handleLogin = () => {
    if (userName && userName.length > 3 && password && password.length > 0) {
      dispatchLogin(userName, password).then((data) => {
        setCookie(
          '_ga_token',
          data.token,
          {
            expires: moment().add(1, 'year').toDate(),
            path: '/',
            domain: Config.apiGateway.cookieDomain,
          },
        );
      });
    }
  };

  const handleOnEnter = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const renderPasswordResetHint = () => {
    if (query.has('reset') && query.get('reset') === 'true') {
      return (
        <div className="alert alert-success" style={{ marginTop: 20, marginBottom: -10 }}>
          <i className="fas fa-check-circle" style={{ marginRight: 10 }} />
          Wir haben Ihnen soeben ein neues Passwort an Ihre E-Mail Adresse gesendet.
          Bitte verwenden Sie dieses Passwort für die nächste Anmeldung!
        </div>
      );
    }
    return null;
  };

  if (isMobileOnly) {
    return <MobileDeactivatedHint />;
  }

  if (!isLoggedin) {
    return (
      <div
        className="d-flex flex-column flex-grow-1"
        style={{ margin: '-40px' }}
        onKeyPress={handleOnEnter}
        role="button"
        tabIndex={-1}
      >
        <div
          className="d-flex flex-grow-1 justify-content-center align-items-center align-content-center"
          style={{ backgroundColor: '#F1F1F2', padding: 50 }}
        >
          <Panel width="500px">
            <h3 className="hk-text-primary">
              Anmeldung / Login
            </h3>

            {renderPasswordResetHint()}

            <hr style={{ margin: '40px 0' }} />

            <Input
              id="mailInput"
              rightIcon="fas fa-user"
              onChange={(value) => setUserName(value)}
              value={userName}
              placeholder="Benutzername / E-Mail"
              hasError={!!(errors.username)}
              errorMessage="Benutzername oder E-Mail ungültig"
              inputClassName="form-control-lg"
            />
            <Input
              id="cryptedInput"
              rightIcon="fas fa-key"
              type="password"
              onChange={(value) => setPassword(value)}
              value={password}
              placeholder="Passwort"
              hasError={!!(errors.password)}
              errorMessage="Passwort ungültig"
              inputClassName="form-control-lg"
            />

            {renderError500()}

            <br />

            <div className="row">
              <div className="col-lg-6">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Anmeldung speichern
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <Button
                  id="loginButton"
                  className="float-right"
                  onClick={handleLogin}
                  disabled={disabled}
                >
                  Anmelden
                </Button>
              </div>
            </div>

            <hr style={{ margin: '30px 0' }} />

            <small className="d-flex flex-column justify-content-center">
              <div className="d-flex justify-content-center">
                Noch keinen Account?
              </div>
              <div className="d-flex justify-content-center">
                <NavLink to="/register">Jetzt kostenlos registrieren</NavLink>
              </div>
            </small>

            <small className="d-flex flex-column justify-content-center" style={{ marginTop: 20 }}>
              <div className="d-flex justify-content-center">
                <NavLink to="/newpassword">Passwort vergessen?</NavLink>
              </div>
            </small>

          </Panel>
          <LoadingModal visible={isLoading} text="Sie werden angemeldet..." />
        </div>
        <div>
          <Footer type="Dark" />
        </div>
      </div>
    );
  }

  return <Redirect to={directToUrl} />;
}

function mapStoreToProps(store) {
  return {
    isLoggedin: store.auth.isLoggedin,
    isLoading: store.auth.isLoading,
    statusCode: store.auth.statusCode,
    errorCode: store.auth.errorCode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLogin: (username, password) => dispatch(login(username, password)),
  };
}

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(Login));
