import React from 'react';
import PropTypes from 'prop-types/prop-types';
import styled, { useTheme } from 'styled-components';
import WorkOptionIcons from '../WorkOptionIcons';
import StatusBadge from '../StatusBadge';
import CombinedOrderBadge from '../OrderComponents/CombinedOrderBadge';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  ${(props) => props.theme.boxShadowDark};
  margin-bottom: 30px;
  border-radius: ${(props) => props.theme.border.radius};
  padding: 20px;
  cursor: pointer;
  background-color: ${(props) => ((props.theme.index % 2 === 0) ? '#f1f1f1' : '#fff')};
  
  &:hover {
    ${(props) => props.theme.boxShadowDarkHover};
  }
  
  >.LeftContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  
    .Headline {
      font-size: 20px;
      margin-bottom: 15px;
    }
    
    .AdditionalInfos {
      display: flex;
      flex-direction: row;
    }
  }
  
  >.RightContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
`;

/**
 * ObjectPreviewContainer()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ObjectPreviewContainer(props) {
  const {
    order, object, containerStyle, onClick, index,
  } = props;
  const theme = useTheme();

  const renderStatusBadge = () => {
    if (object.splitted) {
      return <StatusBadge caption="Auftragsoptionen geteilt" icon="fas fa-boxes" type="Danger" />;
    }
    if (object.montageDate) {
      return (
        <CombinedOrderBadge
          orderState={order.state}
          objectDateState={object.montageDate.state}
          isInternal={order.internal}
        />
      );
    }
    return null;
  };

  return (
    <Container style={containerStyle} onClick={() => onClick(object)} theme={{ ...theme, index }}>
      <div className="LeftContainer">
        <div>
          <div className="Headline">
            {'Kommissionsnummer: '}
            <strong>
              {object.externalId}
            </strong>
          </div>
        </div>
        <div className="AdditionalInfos">
          <StatusBadge type="Default" containerStyle={{ marginRight: 6 }}>
            <WorkOptionIcons object={object} />
          </StatusBadge>
        </div>
      </div>
      <div className="RightContainer">
        {renderStatusBadge()}
      </div>
    </Container>
  );
}

ObjectPreviewContainer.propTypes = {
  object: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  containerStyle: PropTypes.instanceOf(Object),
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number,
};

ObjectPreviewContainer.defaultProps = {
  containerStyle: {},
  index: 0,
};
