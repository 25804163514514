import {
  OBJECTMESSAGE_GETLIST_START, OBJECTMESSAGE_GETLIST_SUCCESS, OBJECTMESSAGE_GETLIST_ERROR,
} from '../Action/ObjectMessagesAction';
import { AUTH_LOGOUT_SUCCESS } from '../Action/AuthAction';

const defaultValues = {
  messages: null,
  lastObjectId: null,
};

export default function reduce(state = defaultValues, action) {
  switch (action.type) {
    /**
     * GETLIST
     */
    case OBJECTMESSAGE_GETLIST_START: {
      const { payload } = action;
      if (payload === state.lastObjectId) {
        return { ...state, isLoading: true };
      }
      return {
        ...state, isLoading: true, lastObjectId: payload, messages: null,
      };
    }
    case OBJECTMESSAGE_GETLIST_SUCCESS: {
      return { ...state, messages: action.payload, isLoading: false };
    }
    case OBJECTMESSAGE_GETLIST_ERROR: {
      return { ...state, isLoading: false, messages: null };
    }

    case AUTH_LOGOUT_SUCCESS: {
      return { ...defaultValues };
    }

    default: return { ...state };
  }
}
